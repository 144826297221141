import { useEffect, useMemo, useRef } from 'react';
import Plotly from 'plotly.js-dist';

interface Ai_ChartProps {
  ai_chart: string;
}

export const Ai_Chart_V1 = ({ ai_chart }: Ai_ChartProps) => {
  const plotContainer = useRef<HTMLDivElement>(null);
  const { x, y, config, layout } = JSON.parse(ai_chart);


  const data = useMemo(() => {

    return y.map((yValue, idx) => {
      const chartData = {
        x: x,
        y: yValue,
        ...config[idx],
      };

      return chartData;
    });
  }, [x, y, config]);

  useEffect(() => {
    if (plotContainer.current) {
      Plotly.newPlot(plotContainer.current, data, layout);
    }
  }, [data, layout]);

  return (
    <div>
      <div ref={plotContainer} />
    </div>
  );
};
