import { useState } from 'react';
import { forgetPassword } from '../../../apis/auth/apis';
import Swal from 'sweetalert2';

export const ForgetPassword = () => {
  const [email, setEmail] = useState('');

  function sendResetEmail(): void {
    if (!email) {
      Swal.fire('Error', "email can't be null", 'error');
      return;
    }

    forgetPassword({ email: email }).then((res) => {
      if (res.success) {
        Swal.fire('Success', 'Please check your email', 'success');
      }
    });
  }

  return (
    <div className="grid justify-items-center justify-self-center self-center w-1/3 h-auto bg-layer2 max-w-md">
      <div className="text-xl font-bold text-text_selected w-10/12 my-10 text-center flex flex-col items-center">
        <span>Forget Password</span>
        <input
          className="mt-8 w-full h-12 rounded border-solid border-active_hover border bg-transparent pl-6 text-sm font-normal outline-none"
          placeholder="Email"
          type="text"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <div
          className="mt-6 w-full h-12 bg-text_selected grid items-center cursor-pointer rounded select-none"
          onClick={sendResetEmail}
        >
          <span className="text-sm text-text_unselected_secondary font-semibold">Send Email</span>
        </div>
      </div>
    </div>
  );
};
