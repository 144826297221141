import { instance } from '../base/instance';
import {
  EmailVerificationDto,
  CreateUserDto,
  RegisterStatusDto,
  LoginDto,
  LoginStatusDto,
  InviteDto,
  InviteStatusDto,
  EmailBodyDto,
  ForgetPasswordStatus,
  PasswordDto,
  ResetPasswordStatus,
} from './interfaces';

export const loginWithGoogle = async (
  access_token: string
): Promise<LoginStatusDto> => {
  const res = await instance.post('/auth/loginWithGoogle', {
    access_token: access_token,
  });
  return res.data;
};

export const getEmailVerificationCode = async (
  email: string
): Promise<EmailVerificationDto> => {
  const res = await instance.post('/auth/send-verify', { email: email });
  return res.data;
};

export const registerUser = async (
  createUserDto: CreateUserDto
): Promise<RegisterStatusDto> => {
  const res = await instance.post('/auth/register', createUserDto);
  return res.data;
};

export const loginWithCreds = async (
  loginUserDto: LoginDto
): Promise<LoginStatusDto> => {
  const res = await instance.post('/auth/login', loginUserDto);
  return res.data;
};

export const inviteUser = async (
  InviteDto: InviteDto
): Promise<InviteStatusDto> => {
  const res = await instance.post('/auth/invite', InviteDto);
  return res.data;
};

export const forgetPassword = async (
  email: EmailBodyDto
): Promise<ForgetPasswordStatus> => {
  const res = await instance.post('/auth/forget-password', email);
  return res.data;
};

export const resetPassword = async (
  userId: string,
  oneTimeToken: string,
  passwordDto: PasswordDto
): Promise<ResetPasswordStatus> => {
  const res = await instance.post(
    `/auth/reset-password/${userId}/${oneTimeToken}`,
    passwordDto
  );
  return res.data;
};
