import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const timeout = 8000;

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState<number>(Math.ceil(timeout / 1000));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(intervalId);
      navigate('/');
    }, timeout);

    return () => {
      clearInterval(intervalId);
    };
  }, [navigate, timeout]);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-bgdark text-tiwhite">
      <h1 className="text-4xl font-semibold mb-4">404 - Page Not Found</h1>
      <p>The page you&apos;re looking for doesn&apos;t exist. </p>
      <p>Redirecting in {countdown} seconds...</p>
    </div>
  );
};

export default NotFound;
