export const Step1 = () => {
  return (
    <div className="flex flex-col gap-5">
      <div className="h-[230px] dark:bg-step1 bg-brightstep1" />
      <span className="text-text_selected font-semibold text-2xl text-center">
        Welcome to Legion AI
      </span>
      <div className="text-text_unselected font-semibold text-sm w-[490px] m-auto">
        Our conversational all-in-one platform is designed to make data
        solutions easier and faster for you and your team. Let&apos;s
        revolutionize the way you with your data and achieve remarkable results
        together!
      </div>
    </div>
  );
};
