// backend string to frontend string
export const datasetTypeMapping: { [key: string]: string } = {
  confluence: 'Confluence',
  pdf: 'PDF',
  github: 'Github',
  s3file: 'S3 File',
};

// frontend string to backend string
export const ConnectionMapping: { [key: string]: string } = {
  // Confluence
  Username: 'username',
  'API Token': 'accessToken',
  'Base Url': 'baseUrl',
  'Space Key': 'spaceKey',

  // GitHub
  Url: 'url',
  'Branch Name': 'branch',
};
