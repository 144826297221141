import {
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  Fragment,
  KeyboardEvent,
} from 'react';

interface OTPInputProps {
  /** Value of the OTP input */
  value?: string;
  /** Number of OTP inputs to be rendered */
  numInputs?: number;
  /** Callback to be called when the OTP value changes */
  onChange: (otp: string) => void;

  /** Whether the first input should be auto focused */
  shouldAutoFocus?: boolean;
}

export const OTPInput = ({
  value = '',
  numInputs = 6,
  onChange,
  shouldAutoFocus = true,
}: OTPInputProps) => {
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const getOTPValue = () => (value ? value.toString().split('') : []);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, numInputs);
  }, [numInputs]);

  useEffect(() => {
    if (shouldAutoFocus) {
      inputRefs.current[0]?.focus();
    }
  }, [shouldAutoFocus]);

  const isInputValueValid = (value: string) => {
    return !isNaN(Number(value)) && value.trim().length === 1;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (isInputValueValid(value)) {
      changeCodeAtFocus(value);
      focusInput(activeInput + 1);
    } else {
      const nativeEvent = event.nativeEvent as InputEvent;

      if (
        nativeEvent.data === null &&
        nativeEvent.inputType === 'deleteContentBackward'
      ) {
        event.preventDefault();
        changeCodeAtFocus('');
        focusInput(activeInput - 1);
      }
    }
  };

  const handleFocus = (event) => (index: number) => {
    setActiveInput(index);
    event.target.select();
  };

  const handleBlur = () => {
    setActiveInput((activeInput) => activeInput - 1);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const otp = getOTPValue();
    if ([event.code, event.key].includes('Backspace')) {
      event.preventDefault();
      changeCodeAtFocus('');
      focusInput(activeInput - 1);
    } else if (event.code === 'Delete') {
      event.preventDefault();
      changeCodeAtFocus('');
    } else if (event.code === 'ArrowLeft') {
      event.preventDefault();
      focusInput(activeInput - 1);
    } else if (event.code === 'ArrowRight') {
      event.preventDefault();
      focusInput(activeInput + 1);
    }
    // React does not trigger onChange when the same value is entered
    // again. So we need to focus the next input manually in this case.
    else if (event.key === otp[activeInput]) {
      event.preventDefault();
      focusInput(activeInput + 1);
    } else if (
      event.code === 'Spacebar' ||
      event.code === 'Space' ||
      event.code === 'ArrowUp' ||
      event.code === 'ArrowDown'
    ) {
      event.preventDefault();
    } else if (!event.ctrlKey && !isInputValueValid(event.key)) {
      event.preventDefault();
    }
  };

  const focusInput = (index: number) => {
    const activeInput = Math.max(Math.min(numInputs - 1, index), 0);

    if (inputRefs.current[activeInput]) {
      inputRefs.current[activeInput]?.focus();
      inputRefs.current[activeInput]?.select();
      setActiveInput(activeInput);
    }
  };

  const changeCodeAtFocus = (value: string) => {
    const otp = getOTPValue();
    otp[activeInput] = value[0];
    handleOTPChange(otp);
  };

  const handleOTPChange = (otp: Array<string>) => {
    const otpValue = otp.join('');
    onChange(otpValue);
  };

  const handlePaste = (event) => {
    event.preventDefault();

    const otp = getOTPValue();
    let nextActiveInput = activeInput;

    // Get pastedData in an array of max size (num of inputs - current position)
    const pastedData = event.clipboardData
      .getData('text/plain')
      .slice(0, numInputs - activeInput)
      .split('');

    // Prevent pasting if the clipboard data contains non-numeric values for number inputs
    if (pastedData.some((value) => isNaN(Number(value)))) {
      return;
    }

    // Paste data from focused input onwards
    for (let pos = 0; pos < numInputs; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        otp[pos] = pastedData.shift() ?? '';
        nextActiveInput++;
      }
    }

    focusInput(nextActiveInput);
    handleOTPChange(otp);
  };

  return (
    <div className={'mt-10 w-full h-16 flex justify-stretch'}>
      {Array.from({ length: numInputs }, (_, index) => index).map((index) => (
        <Fragment key={index}>
          <input
            title={(index + 1).toString()}
            type="number"
            className="grow mx-1 h-full w-12 border rounded border-text_selected bg-transparent grid items-center text-center select-none"
            maxLength={1}
            autoComplete="off"
            onPaste={handlePaste}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onFocus={(event) => handleFocus(event)(index)}
            onChange={handleChange}
            ref={(element) => (inputRefs.current[index] = element)}
            value={getOTPValue()[index] ?? ''}
          />
        </Fragment>
      ))}
    </div>
  );
};
