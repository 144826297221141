import React from 'react';
import { get } from 'lodash';
import getFieldLabel from './getFieldLabel';

import {
  CheckboxField,
  ContentField,
  FileField,
  InputField,
  NumberField,
  SelectField,
  TextAreaField,
} from './fields';

interface FieldProps {
  name: string;
  title?: string;
  type: 'ace' | 'text' | 'textarea' | 'email' | 'password' | 'number' | 'checkbox' | 'file' | 'select' | 'content';
  initialValue?: string | number | boolean | string[] | number[];
  content?: React.ReactNode;
  mode?: string;
  required?: boolean;
  extra?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  minLength?: number;
  placeholder?: string;
  contentAfter?: React.ReactNode | ((value: any) => React.ReactNode);
  loading?: boolean;
  props?: Record<string, any>;
}

const FieldTypeComponent: Record<string, React.ComponentType<any>> = {
  checkbox: CheckboxField,
  file: FileField,
  select: SelectField,
  number: NumberField,
  textarea: TextAreaField,
  content: ContentField,
};

interface DynamicFormFieldProps {
  form: any; // Replace 'any' with the actual type of 'form'
  field: FieldProps;
}

export default function DynamicFormField({ form, field, ...otherProps }: DynamicFormFieldProps) {
  const { name, type, readOnly, autoFocus } = field;
  const fieldLabel = getFieldLabel(field);

  const fieldProps = {
    ...field.props,
    className: 'w-100',
    name,
    type,
    readOnly,
    autoFocus,
    placeholder: field.placeholder,
    'data-test': fieldLabel,
    ...otherProps,
  };

  const FieldComponent = get(FieldTypeComponent, type, InputField);
  return <FieldComponent {...fieldProps} form={form} field={field} />;
}
