export const DatabaseSvg = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 12C16.5563 12 20.25 9.98528 20.25 7.5C20.25 5.01472 16.5563 3 12 3C7.44365 3 3.75 5.01472 3.75 7.5C3.75 9.98528 7.44365 12 12 12Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 7.5V12C3.75 14.4844 7.44375 16.5 12 16.5C16.5562 16.5 20.25 14.4844 20.25 12V7.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 12V16.5C3.75 18.9844 7.44375 21 12 21C16.5562 21 20.25 18.9844 20.25 16.5V12"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
