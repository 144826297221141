import { useSetRecoilState } from 'recoil';
import { FeatureOption } from '../../navigation';
import { featureAtom } from '../../../../recoil/atoms/atoms';

export const Intro = () => {
  const setOption = useSetRecoilState(featureAtom);

  return (
    <div className="flex-1 flex flex-col w-full h-auto items-center justify-center text-text_selected">
      <span className="text-2xl font-extrabold">
        Explore Different Features
      </span>
      <span className="text-center mt-4 text-sm text-text_unselected_secondary font-mono">
        Utilize different features we offer to optimize your
        <br />
        experience with Legion AI
      </span>
      <div className="flex flex-row mt-12 w-5/6 justify-center items-stretch space-x-5">
        <div
          className="bg-layer3 hover:bg-layer2 border-outline_unselected border rounded-2xl overflow-hidden shadow-xl min-w-[200px] pb-8 cursor-pointer"
          onClick={() => {
            setOption(FeatureOption.ConversationalBI);
          }}
        >
          <div className="w-full h-[168.69px] flex bg-[#224932] justify-center items-center">
            <svg
              width="54"
              height="57"
              viewBox="0 0 54 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.4366 43.4219H18.304V28.3457H12.4366V43.4219ZM35.696 43.4219H41.5634V13.2695H35.696V43.4219ZM24.0663 43.4219H29.9337V34.3762H24.0663V43.4219ZM24.0663 28.3457H29.9337V22.3152H24.0663V28.3457ZM6.67433 56.0957C4.8269 56.0957 3.25266 55.4271 1.9516 54.0899C0.650532 52.7527 0 51.1347 0 49.236V7.45543C0 5.55669 0.650532 3.93872 1.9516 2.60151C3.25266 1.26431 4.8269 0.595703 6.67433 0.595703H47.3257C49.1731 0.595703 50.7473 1.26431 52.0484 2.60151C53.3495 3.93872 54 5.55669 54 7.45543V49.236C54 51.1347 53.3495 52.7527 52.0484 54.0899C50.7473 55.4271 49.1731 56.0957 47.3257 56.0957H6.67433Z"
                fill="#72F1A5"
              />
            </svg>
          </div>
          <div className="flex flex-col items-center justify-center gap-5 px-8">
            <div className="flex flex-col w-auto mt-5">
              <span className="text-[21px] font-extrabold text-text_selected py-3">
                Conversational B.I.
              </span>
              <span className="text-text_unselected text-sm">
                Generate various query and data based on your dataset
              </span>
            </div>
            {/* <hr className="border border-outline_unselected w-full" />
            <div className="w-full flex flex-col gap-2 text-sm">
              <span className="text-text_selected">Output</span>
              <div className="flex flex-row gap-8 flex-wrap">
                <div className="text-text_unselected flex gap-x-1 items-center">
                  <div className="w-6 h-6 bg-analytics" />
                  Diagram
                </div>
                <div className="text-text_unselected flex gap-x-1 items-center">
                  <div className="w-6 h-6 bg-ad" />
                  Query
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div
          className="bg-layer3 hover:bg-layer2 border-outline_unselected border rounded-2xl overflow-hidden shadow-xl min-w-[200px] pb-8 cursor-pointer"
          onClick={() => {
            setOption(FeatureOption.GENERALQA);
          }}
        >
          <div className="w-full h-[168.69px] bg-[#183647] flex justify-center items-center">
            <svg
              width="32"
              height="39"
              viewBox="0 0 32 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 38.3457V34.7267H28.4444V32.9171H21.3333V18.4409H28.4444V16.6314C28.4444 13.133 27.2296 10.1473 24.8 7.67427C22.3704 5.20126 19.437 3.96475 16 3.96475C12.563 3.96475 9.62963 5.20126 7.2 7.67427C4.77037 10.1473 3.55556 13.133 3.55556 16.6314V18.4409H10.6667V32.9171H3.55556C2.57778 32.9171 1.74074 32.5628 1.04444 31.854C0.348148 31.1453 0 30.2933 0 29.2981V16.6314C0 14.3997 0.422222 12.2961 1.26667 10.3207C2.11111 8.34531 3.25926 6.61872 4.71111 5.14094C6.16296 3.66316 7.85926 2.49451 9.8 1.63499C11.7407 0.775465 13.8074 0.345703 16 0.345703C18.1926 0.345703 20.2593 0.775465 22.2 1.63499C24.1407 2.49451 25.837 3.66316 27.2889 5.14094C28.7407 6.61872 29.8889 8.34531 30.7333 10.3207C31.5778 12.2961 32 14.3997 32 16.6314V34.7267C32 35.7219 31.6519 36.5739 30.9556 37.2826C30.2593 37.9913 29.4222 38.3457 28.4444 38.3457H16Z"
                fill="#50B2EC"
              />
            </svg>
          </div>
          <div className="flex flex-col items-center justify-center gap-5 px-8">
            <div className="flex flex-col w-auto mt-5">
              <span className="text-[21px] font-extrabold text-text_selected py-3">
                General Chat
              </span>
              <span className="text-text_unselected text-sm">
                Generate various query and data based on your dataset
              </span>
            </div>
            {/* <hr className="border border-outline_unselected w-full" />
            <div className="w-full flex flex-col gap-2 text-sm">
              <span className="text-text_selected">Output</span>
              <div className="flex flex-row gap-8 flex-wrap">
                <div className="text-text_unselected flex gap-x-1 items-center">
                  <div className="w-6 h-6 bg-analytics" />
                  Responses
                </div>
                <div className="text-text_unselected flex gap-x-1 items-center">
                  <div className="w-6 h-6 bg-ad" />
                  Suggestions
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div
          className="bg-layer3 hover:bg-layer2 border-outline_unselected border-2 rounded-2xl overflow-hidden shadow-xl min-w-[200px] pb-8 cursor-pointer"
          onClick={() => {
            setOption(FeatureOption.DATAENTRY);
          }}
        >
          <div className="w-full h-[168.69px] bg-[#43274D] flex justify-center items-center">
            <svg
              width="54"
              height="57"
              viewBox="0 0 54 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.4366 43.4219H18.304V28.3457H12.4366V43.4219ZM35.696 43.4219H41.5634V13.2695H35.696V43.4219ZM24.0663 43.4219H29.9337V34.3762H24.0663V43.4219ZM24.0663 28.3457H29.9337V22.3152H24.0663V28.3457ZM6.67433 56.0957C4.8269 56.0957 3.25266 55.4271 1.9516 54.0899C0.650532 52.7527 0 51.1347 0 49.236V7.45543C0 5.55669 0.650532 3.93872 1.9516 2.60151C3.25266 1.26431 4.8269 0.595703 6.67433 0.595703H47.3257C49.1731 0.595703 50.7473 1.26431 52.0484 2.60151C53.3495 3.93872 54 5.55669 54 7.45543V49.236C54 51.1347 53.3495 52.7527 52.0484 54.0899C50.7473 55.4271 49.1731 56.0957 47.3257 56.0957H6.67433Z"
                fill="#DE82FF"
              />
            </svg>
          </div>
          <div className="flex flex-col items-center justify-center gap-5 px-8">
            <div className="flex flex-col w-auto mt-5">
              <span className="text-[21px] font-extrabold text-text_selected py-3">
                Data Entry
              </span>
              <span className="text-text_unselected text-sm">
                Generate various query and data based on your dataset
              </span>
            </div>
            {/* <hr className="border border-outline_unselected w-full" />
            <div className="w-full flex flex-col gap-2 text-sm">
              <span className="text-text_selected">Output</span>
              <div className="flex flex-row gap-8 flex-wrap">
                <div className="text-text_unselected flex gap-x-1 items-center">
                  <div className="w-6 h-6 bg-analytics" />
                  Diagram
                </div>
                <div className="text-text_unselected flex gap-x-1 items-center">
                  <div className="w-6 h-6 bg-ad" />
                  Query
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div
          className="bg-layer3 hover:bg-layer2 border-outline_unselected border-2 rounded-2xl overflow-hidden shadow-xl min-w-[200px] pb-8 cursor-pointer"
          onClick={() => {
            setOption(FeatureOption.WEBSCRAPER);
          }}
        >
          <div className="w-full h-[168.69px] bg-yellow-700 flex justify-center items-center">
            <div className="w-14 h-14 bg-webscraper bg-cover" />
          </div>
          <div className="flex flex-col items-center justify-center gap-5 px-8">
            <div className="flex flex-col w-auto mt-5">
              <span className="text-[21px] font-extrabold text-text_selected py-3">
                Web Scraper
              </span>
              <span className="text-text_unselected text-sm">
                Generate various query and data based on your dataset
              </span>
            </div>
            {/* <hr className="border border-outline_unselected w-full" />
            <div className="w-full flex flex-col gap-2 text-sm">
              <span className="text-text_selected">Output</span>
              <div className="flex flex-row gap-8 flex-wrap">
                <div className="text-text_unselected flex gap-x-1 items-center">
                  <div className="w-6 h-6 bg-analytics" />
                  Diagram
                </div>
                <div className="text-text_unselected flex gap-x-1 items-center">
                  <div className="w-6 h-6 bg-ad" />
                  Query
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
