import { PricingCardProps } from './components/pricing_card';

export const PRICING_CARDS_DATA = (isProd: boolean): PricingCardProps[] => {return [
  {
    title: 'Free',
    price: '$0',
    features: [
      '100 calls per month',
      '1 database connection',
      '1 team members',
      '5 files (total size < 50MB)',
      'Supported database: MySQL, SQL Server, PostgreSQL',
    ],
  },
  {
    title: 'Starter',
    price: '$49',
    features: [
      '3000 calls per month',
      '1 database connection',
      '1 team members',
      '10 files (total size < 50MB)',
      'Supported database: MySQL, SQL Server, PostgreSQL',
    ],
    paymentLink: isProd ? 'https://buy.stripe.com/test_7sIbKZ2Lz1cV2aY8wB' : 'https://buy.stripe.com/cN25mb5nf13DdgIbIN',
  },
  {
    title: 'Team',
    price: '$349',
    features: [
      '3000 calls per month',
      '3 database connections',
      '5 team members',
      '10 files + 3 types of dataset connection (total size < 200MB)',
      'Supported database: Starter Plan + Oracle PL/SQL, NoSQL, MongoDB, BigQuery, Redshift, SnowSQL',
      'High-performance proprietary self-hosted models',
    ],
    paymentLink: isProd ? 'https://buy.stripe.com/test_3csbKZae1aNvdTGaEG' : 'https://buy.stripe.com/6oE6qfeXP8w52C46op',
  },
  {
    title: 'Team Pro',
    price: '$799',
    features: [
      '15k calls per day',
      '6 databases connection',
      '15 team members',
      '10 files + 3 types of dataset connection (total size < 500MB)',
      'Supported database: Team Plan + customize database integration',
      'High-performance proprietary self-hosted models',
      'Customized on-demand fine-tuned models'
    ],
    paymentLink: isProd ? 'https://buy.stripe.com/test_aEU2ap4TH8FndTG4gj' : 'https://buy.stripe.com/9AQ9Cr7vnfYx3G84gi',
  },
  {
    title: 'Enterprise',
    price: 'Contact Us',
    features: [
      'Unlimited calls per day',
      'Unlimited databases connections',
      'Full features access',
      'And more...',
    ],
  },
];
};
