// community-modules/core/src/localEventService.ts
var LocalEventService = class {
  constructor() {
    this.allSyncListeners = /* @__PURE__ */ new Map();
    this.allAsyncListeners = /* @__PURE__ */ new Map();
    this.globalSyncListeners = /* @__PURE__ */ new Set();
    this.globalAsyncListeners = /* @__PURE__ */ new Set();
    this.asyncFunctionsQueue = [];
    this.scheduled = false;
    // using an object performs better than a Set for the number of different events we have
    this.firedEvents = {};
  }
  setFrameworkOverrides(frameworkOverrides) {
    this.frameworkOverrides = frameworkOverrides;
  }
  getListeners(eventType, async, autoCreateListenerCollection) {
    const listenerMap = async ? this.allAsyncListeners : this.allSyncListeners;
    let listeners = listenerMap.get(eventType);
    if (!listeners && autoCreateListenerCollection) {
      listeners = /* @__PURE__ */ new Set();
      listenerMap.set(eventType, listeners);
    }
    return listeners;
  }
  noRegisteredListenersExist() {
    return this.allSyncListeners.size === 0 && this.allAsyncListeners.size === 0 && this.globalSyncListeners.size === 0 && this.globalAsyncListeners.size === 0;
  }
  addEventListener(eventType, listener, async = false) {
    this.getListeners(eventType, async, true).add(listener);
  }
  removeEventListener(eventType, listener, async = false) {
    const listeners = this.getListeners(eventType, async, false);
    if (!listeners) {
      return;
    }
    listeners.delete(listener);
    if (listeners.size === 0) {
      const listenerMap = async ? this.allAsyncListeners : this.allSyncListeners;
      listenerMap.delete(eventType);
    }
  }
  addGlobalListener(listener, async = false) {
    (async ? this.globalAsyncListeners : this.globalSyncListeners).add(listener);
  }
  removeGlobalListener(listener, async = false) {
    (async ? this.globalAsyncListeners : this.globalSyncListeners).delete(listener);
  }
  dispatchEvent(event) {
    const agEvent = event;
    this.dispatchToListeners(agEvent, true);
    this.dispatchToListeners(agEvent, false);
    this.firedEvents[agEvent.type] = true;
  }
  dispatchEventOnce(event) {
    if (!this.firedEvents[event.type]) {
      this.dispatchEvent(event);
    }
  }
  dispatchToListeners(event, async) {
    const eventType = event.type;
    if (async && "event" in event) {
      const browserEvent = event.event;
      if (browserEvent instanceof Event) {
        event.eventPath = browserEvent.composedPath();
      }
    }
    const processEventListeners = (listeners2, originalListeners2) => listeners2.forEach((listener) => {
      if (!originalListeners2.has(listener)) {
        return;
      }
      const callback = this.frameworkOverrides ? () => this.frameworkOverrides.wrapIncoming(() => listener(event)) : () => listener(event);
      if (async) {
        this.dispatchAsync(callback);
      } else {
        callback();
      }
    });
    const originalListeners = this.getListeners(eventType, async, false) ?? /* @__PURE__ */ new Set();
    const listeners = new Set(originalListeners);
    if (listeners.size > 0) {
      processEventListeners(listeners, originalListeners);
    }
    const globalListeners = new Set(
      async ? this.globalAsyncListeners : this.globalSyncListeners
    );
    globalListeners.forEach((listener) => {
      const callback = this.frameworkOverrides ? () => this.frameworkOverrides.wrapIncoming(() => listener(eventType, event)) : () => listener(eventType, event);
      if (async) {
        this.dispatchAsync(callback);
      } else {
        callback();
      }
    });
  }
  // this gets called inside the grid's thread, for each event that it
  // wants to set async. the grid then batches the events into one setTimeout()
  // because setTimeout() is an expensive operation. ideally we would have
  // each event in it's own setTimeout(), but we batch for performance.
  dispatchAsync(func) {
    this.asyncFunctionsQueue.push(func);
    if (!this.scheduled) {
      const flush = () => {
        window.setTimeout(this.flushAsyncQueue.bind(this), 0);
      };
      this.frameworkOverrides ? this.frameworkOverrides.wrapIncoming(flush) : flush();
      this.scheduled = true;
    }
  }
  // this happens in the next VM turn only, and empties the queue of events
  flushAsyncQueue() {
    this.scheduled = false;
    const queueCopy = this.asyncFunctionsQueue.slice();
    this.asyncFunctionsQueue = [];
    queueCopy.forEach((func) => func());
  }
};

// community-modules/core/src/utils/array.ts
function _existsAndNotEmpty(value) {
  return value != null && value.length > 0;
}
function _last(arr) {
  if (!arr || !arr.length) {
    return;
  }
  return arr[arr.length - 1];
}
function _areEqual(a, b, comparator) {
  if (a == null && b == null) {
    return true;
  }
  return a != null && b != null && a.length === b.length && a.every((value, index) => comparator ? comparator(value, b[index]) : b[index] === value);
}
function _shallowCompare(arr1, arr2) {
  return _areEqual(arr1, arr2);
}
function _sortNumerically(array) {
  return array.sort((a, b) => a - b);
}
function _removeRepeatsFromArray(array, object) {
  if (!array) {
    return;
  }
  for (let index = array.length - 2; index >= 0; index--) {
    const thisOneMatches = array[index] === object;
    const nextOneMatches = array[index + 1] === object;
    if (thisOneMatches && nextOneMatches) {
      array.splice(index + 1, 1);
    }
  }
}
function _removeFromUnorderedArray(array, object) {
  const index = array.indexOf(object);
  if (index >= 0) {
    array[index] = array[array.length - 1];
    array.pop();
  }
}
function _removeFromArray(array, object) {
  const index = array.indexOf(object);
  if (index >= 0) {
    array.splice(index, 1);
  }
}
function _removeAllFromUnorderedArray(array, toRemove) {
  for (let i = 0; i < toRemove.length; i++) {
    _removeFromUnorderedArray(array, toRemove[i]);
  }
}
function _removeAllFromArray(array, toRemove) {
  for (let i = 0; i < toRemove.length; i++) {
    _removeFromArray(array, toRemove[i]);
  }
}
function _insertIntoArray(array, object, toIndex) {
  array.splice(toIndex, 0, object);
}
function _insertArrayIntoArray(dest, src, toIndex) {
  if (dest == null || src == null) {
    return;
  }
  for (let i = src.length - 1; i >= 0; i--) {
    const item = src[i];
    _insertIntoArray(dest, item, toIndex);
  }
}
function _moveInArray(array, objectsToMove, toIndex) {
  _removeAllFromArray(array, objectsToMove);
  objectsToMove.slice().reverse().forEach((obj) => _insertIntoArray(array, obj, toIndex));
}
function _includes(array, value) {
  return array.indexOf(value) > -1;
}
function _flatten(arrayOfArrays) {
  return [].concat.apply([], arrayOfArrays);
}
function _pushAll(target, source) {
  if (source == null || target == null) {
    return;
  }
  source.forEach((value) => target.push(value));
}
function _forEachReverse(list, action) {
  if (list == null) {
    return;
  }
  for (let i = list.length - 1; i >= 0; i--) {
    action(list[i], i);
  }
}

// community-modules/core/src/utils/event.ts
var AG_GRID_STOP_PROPAGATION = "__ag_Grid_Stop_Propagation";
var PASSIVE_EVENTS = ["touchstart", "touchend", "touchmove", "touchcancel", "scroll"];
var supports = {};
function _stopPropagationForAgGrid(event) {
  event[AG_GRID_STOP_PROPAGATION] = true;
}
function _isStopPropagationForAgGrid(event) {
  return event[AG_GRID_STOP_PROPAGATION] === true;
}
var _isEventSupported = /* @__PURE__ */ (() => {
  const tags = {
    select: "input",
    change: "input",
    submit: "form",
    reset: "form",
    error: "img",
    load: "img",
    abort: "img"
  };
  const eventChecker = (eventName) => {
    if (typeof supports[eventName] === "boolean") {
      return supports[eventName];
    }
    const el = document.createElement(tags[eventName] || "div");
    eventName = "on" + eventName;
    return supports[eventName] = eventName in el;
  };
  return eventChecker;
})();
function _getCtrlForEventTarget(gos, eventTarget, type) {
  let sourceElement = eventTarget;
  while (sourceElement) {
    const renderedComp = gos.getDomData(sourceElement, type);
    if (renderedComp) {
      return renderedComp;
    }
    sourceElement = sourceElement.parentElement;
  }
  return null;
}
function _isElementInEventPath(element, event) {
  if (!event || !element) {
    return false;
  }
  return _getEventPath(event).indexOf(element) >= 0;
}
function _createEventPath(event) {
  const res = [];
  let pointer = event.target;
  while (pointer) {
    res.push(pointer);
    pointer = pointer.parentElement;
  }
  return res;
}
function _getEventPath(event) {
  const eventNoType = event;
  if (eventNoType.path) {
    return eventNoType.path;
  }
  if (eventNoType.composedPath) {
    return eventNoType.composedPath();
  }
  return _createEventPath(eventNoType);
}
function _addSafePassiveEventListener(frameworkOverrides, eElement, event, listener) {
  const isPassive = _includes(PASSIVE_EVENTS, event);
  const options = isPassive ? { passive: true } : void 0;
  if (frameworkOverrides && frameworkOverrides.addEventListener) {
    frameworkOverrides.addEventListener(eElement, event, listener, options);
  }
}

// community-modules/core/src/context/beanStub.ts
var BeanStub = class {
  constructor() {
    // not named context to allow children to use 'context' as a variable name
    this.destroyFunctions = [];
    this.destroyed = false;
    // for vue 3 - prevents Vue from trying to make this (and obviously any sub classes) from being reactive
    // prevents vue from creating proxies for created objects and prevents identity related issues
    this.__v_skip = true;
    this.propertyListenerId = 0;
    // Enable multiple grid properties to be updated together by the user but only trigger shared logic once.
    // Closely related to logic in ComponentUtil.ts
    this.lastChangeSetIdLookup = {};
    this.isAlive = () => !this.destroyed;
  }
  preWireBeans(beans) {
    this.gridId = beans.context.getGridId();
    this.frameworkOverrides = beans.frameworkOverrides;
    this.stubContext = beans.context;
    this.eventService = beans.eventService;
    this.gos = beans.gos;
    this.localeService = beans.localeService;
  }
  // this was a test constructor niall built, when active, it prints after 5 seconds all beans/components that are
  // not destroyed. to use, create a new grid, then api.destroy() before 5 seconds. then anything that gets printed
  // points to a bean or component that was not properly disposed of.
  // constructor() {
  //     setTimeout(()=> {
  //         if (this.isAlive()) {
  //             let prototype: any = Object.getPrototypeOf(this);
  //             const constructor: any = prototype.constructor;
  //             const constructorString = constructor.toString();
  //             const beanName = constructorString.substring(9, constructorString.indexOf("("));
  //             console.log('is alive ' + beanName);
  //         }
  //     }, 5000);
  // }
  // CellComp and GridComp and override this because they get the FrameworkOverrides from the Beans bean
  getFrameworkOverrides() {
    return this.frameworkOverrides;
  }
  destroy() {
    for (let i = 0; i < this.destroyFunctions.length; i++) {
      this.destroyFunctions[i]();
    }
    this.destroyFunctions.length = 0;
    this.destroyed = true;
    this.dispatchLocalEvent({ type: "destroyed" });
  }
  // The typing of AgEventListener<any, any, any> is not ideal, but it's the best we can do at the moment to enable
  // eventService to have the best typing at the expense of BeanStub local events
  /** Add a local event listener against this BeanStub */
  addEventListener(eventType, listener) {
    if (!this.localEventService) {
      this.localEventService = new LocalEventService();
    }
    this.localEventService.addEventListener(eventType, listener);
  }
  /** Remove a local event listener from this BeanStub */
  removeEventListener(eventType, listener) {
    if (this.localEventService) {
      this.localEventService.removeEventListener(eventType, listener);
    }
  }
  dispatchLocalEvent(event) {
    if (this.localEventService) {
      this.localEventService.dispatchEvent(event);
    }
  }
  addManagedElementListeners(object, handlers) {
    return this._setupListeners(object, handlers);
  }
  addManagedEventListeners(handlers) {
    return this._setupListeners(this.eventService, handlers);
  }
  addManagedListeners(object, handlers) {
    return this._setupListeners(object, handlers);
  }
  _setupListeners(object, handlers) {
    const destroyFuncs = [];
    for (const k in handlers) {
      const handler = handlers[k];
      if (handler) {
        destroyFuncs.push(this._setupListener(object, k, handler));
      }
    }
    return destroyFuncs;
  }
  _setupListener(object, event, listener) {
    if (this.destroyed) {
      return () => null;
    }
    if (object instanceof HTMLElement) {
      _addSafePassiveEventListener(this.getFrameworkOverrides(), object, event, listener);
    } else {
      object.addEventListener(event, listener);
    }
    const destroyFunc = () => {
      object.removeEventListener(event, listener);
      return null;
    };
    this.destroyFunctions.push(destroyFunc);
    return () => {
      destroyFunc();
      this.destroyFunctions = this.destroyFunctions.filter((fn) => fn !== destroyFunc);
      return null;
    };
  }
  /**
   * Setup a managed property listener for the given GridOption property.
   * However, stores the destroy function in the beanStub so that if this bean
   * is a component the destroy function will be called when the component is destroyed
   * as opposed to being cleaned up only when the GridOptionsService is destroyed.
   */
  setupGridOptionListener(event, listener) {
    this.gos.addPropertyEventListener(event, listener);
    const destroyFunc = () => {
      this.gos.removePropertyEventListener(event, listener);
      return null;
    };
    this.destroyFunctions.push(destroyFunc);
    return () => {
      destroyFunc();
      this.destroyFunctions = this.destroyFunctions.filter((fn) => fn !== destroyFunc);
      return null;
    };
  }
  /**
   * Setup a managed property listener for the given GridOption property.
   * @param event GridOption property to listen to changes for.
   * @param listener Listener to run when property value changes
   */
  addManagedPropertyListener(event, listener) {
    if (this.destroyed) {
      return () => null;
    }
    return this.setupGridOptionListener(event, listener);
  }
  /**
   * Setup managed property listeners for the given set of GridOption properties.
   * The listener will be run if any of the property changes but will only run once if
   * multiple of the properties change within the same framework lifecycle event.
   * Works on the basis that GridOptionsService updates all properties *before* any property change events are fired.
   * @param events Array of GridOption properties to listen for changes too.
   * @param listener Shared listener to run if any of the properties change
   */
  addManagedPropertyListeners(events, listener) {
    if (this.destroyed) {
      return;
    }
    const eventsKey = events.join("-") + this.propertyListenerId++;
    const wrappedListener = (event) => {
      if (event.changeSet) {
        if (event.changeSet && event.changeSet.id === this.lastChangeSetIdLookup[eventsKey]) {
          return;
        }
        this.lastChangeSetIdLookup[eventsKey] = event.changeSet.id;
      }
      const propertiesChangeEvent = {
        type: "gridPropertyChanged",
        changeSet: event.changeSet,
        source: event.source
      };
      listener(propertiesChangeEvent);
    };
    events.forEach((event) => this.setupGridOptionListener(event, wrappedListener));
  }
  addDestroyFunc(func) {
    if (this.isAlive()) {
      this.destroyFunctions.push(func);
    } else {
      func();
    }
  }
  createManagedBean(bean, context) {
    const res = this.createBean(bean, context);
    this.addDestroyFunc(this.destroyBean.bind(this, bean, context));
    return res;
  }
  createBean(bean, context, afterPreCreateCallback) {
    return (context || this.stubContext).createBean(bean, afterPreCreateCallback);
  }
  /**
   * Destroys a bean and returns undefined to support destruction and clean up in a single line.
   * this.dateComp = this.context.destroyBean(this.dateComp);
   */
  destroyBean(bean, context) {
    return (context || this.stubContext).destroyBean(bean);
  }
  /**
   * Destroys an array of beans and returns an empty array to support destruction and clean up in a single line.
   * this.dateComps = this.context.destroyBeans(this.dateComps);
   */
  destroyBeans(beans, context) {
    return (context || this.stubContext).destroyBeans(beans);
  }
};

// community-modules/core/src/misc/frameworkEventListenerService.ts
var FrameworkEventListenerService = class {
  constructor(frameworkOverrides) {
    this.frameworkOverrides = frameworkOverrides;
    // Map from user listener to wrapped listener so we can remove listener provided by user
    this.wrappedListeners = /* @__PURE__ */ new Map();
    this.wrappedGlobalListeners = /* @__PURE__ */ new Map();
  }
  wrap(userListener) {
    let listener = userListener;
    if (this.frameworkOverrides.shouldWrapOutgoing) {
      listener = (event) => {
        this.frameworkOverrides.wrapOutgoing(() => userListener(event));
      };
      this.wrappedListeners.set(userListener, listener);
    }
    return listener;
  }
  wrapGlobal(userListener) {
    let listener = userListener;
    if (this.frameworkOverrides.shouldWrapOutgoing) {
      listener = (eventType, event) => {
        this.frameworkOverrides.wrapOutgoing(() => userListener(eventType, event));
      };
      this.wrappedGlobalListeners.set(userListener, listener);
    }
    return listener;
  }
  unwrap(userListener) {
    return this.wrappedListeners.get(userListener) ?? userListener;
  }
  unwrapGlobal(userListener) {
    return this.wrappedGlobalListeners.get(userListener) ?? userListener;
  }
};

// community-modules/core/src/utils/generic.ts
function _makeNull(value) {
  if (value == null || value === "") {
    return null;
  }
  return value;
}
function _exists(value, allowEmptyString = false) {
  return value != null && (value !== "" || allowEmptyString);
}
function _missing(value) {
  return !_exists(value);
}
function _missingOrEmpty(value) {
  return value == null || value.length === 0;
}
function _toStringOrNull(value) {
  return value != null && typeof value.toString === "function" ? value.toString() : null;
}
function _attrToNumber(value) {
  if (value === void 0) {
    return;
  }
  if (value === null || value === "") {
    return null;
  }
  if (typeof value === "number") {
    return isNaN(value) ? void 0 : value;
  }
  const valueParsed = parseInt(value, 10);
  return isNaN(valueParsed) ? void 0 : valueParsed;
}
function _attrToBoolean(value) {
  if (value === void 0) {
    return;
  }
  if (value === null || value === "") {
    return false;
  }
  return toBoolean(value);
}
function toBoolean(value) {
  if (typeof value === "boolean") {
    return value;
  }
  if (typeof value === "string") {
    return value.toUpperCase() === "TRUE" || value == "";
  }
  return false;
}
function _jsonEquals(val1, val2) {
  const val1Json = val1 ? JSON.stringify(val1) : null;
  const val2Json = val2 ? JSON.stringify(val2) : null;
  return val1Json === val2Json;
}
function _defaultComparator(valueA, valueB, accentedCompare = false) {
  const valueAMissing = valueA == null;
  const valueBMissing = valueB == null;
  if (valueA && valueA.toNumber) {
    valueA = valueA.toNumber();
  }
  if (valueB && valueB.toNumber) {
    valueB = valueB.toNumber();
  }
  if (valueAMissing && valueBMissing) {
    return 0;
  }
  if (valueAMissing) {
    return -1;
  }
  if (valueBMissing) {
    return 1;
  }
  function doQuickCompare(a, b) {
    return a > b ? 1 : a < b ? -1 : 0;
  }
  if (typeof valueA !== "string") {
    return doQuickCompare(valueA, valueB);
  }
  if (!accentedCompare) {
    return doQuickCompare(valueA, valueB);
  }
  try {
    return valueA.localeCompare(valueB);
  } catch (e) {
    return doQuickCompare(valueA, valueB);
  }
}
function _values(object) {
  if (object instanceof Set || object instanceof Map) {
    const arr = [];
    object.forEach((value) => arr.push(value));
    return arr;
  }
  return Object.values(object);
}

// community-modules/core/src/utils/object.ts
function _iterateObject(object, callback) {
  if (object == null) {
    return;
  }
  if (Array.isArray(object)) {
    for (let i = 0; i < object.length; i++) {
      callback(i.toString(), object[i]);
    }
    return;
  }
  for (const [key, value] of Object.entries(object)) {
    callback(key, value);
  }
}
function _cloneObject(object) {
  const copy = {};
  const keys = Object.keys(object);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = object[key];
    copy[key] = value;
  }
  return copy;
}
function _deepCloneDefinition(object, keysToSkip) {
  if (!object) {
    return;
  }
  const obj = object;
  const res = {};
  Object.keys(obj).forEach((key) => {
    if (keysToSkip && keysToSkip.indexOf(key) >= 0) {
      return;
    }
    const value = obj[key];
    const sourceIsSimpleObject = _isNonNullObject(value) && value.constructor === Object;
    if (sourceIsSimpleObject) {
      res[key] = _deepCloneDefinition(value);
    } else {
      res[key] = value;
    }
  });
  return res;
}
function _getAllValuesInObject(obj) {
  if (!obj) {
    return [];
  }
  const anyObject = Object;
  if (typeof anyObject.values === "function") {
    return anyObject.values(obj);
  }
  const ret = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj.propertyIsEnumerable(key)) {
      ret.push(obj[key]);
    }
  }
  return ret;
}
function _mergeDeep(dest, source, copyUndefined = true, makeCopyOfSimpleObjects = false) {
  if (!_exists(source)) {
    return;
  }
  _iterateObject(source, (key, sourceValue) => {
    let destValue = dest[key];
    if (destValue === sourceValue) {
      return;
    }
    if (makeCopyOfSimpleObjects) {
      const objectIsDueToBeCopied = destValue == null && sourceValue != null;
      if (objectIsDueToBeCopied) {
        const sourceIsSimpleObject = typeof sourceValue === "object" && sourceValue.constructor === Object;
        const dontCopy = sourceIsSimpleObject;
        if (dontCopy) {
          destValue = {};
          dest[key] = destValue;
        }
      }
    }
    if (_isNonNullObject(sourceValue) && _isNonNullObject(destValue) && !Array.isArray(destValue)) {
      _mergeDeep(destValue, sourceValue, copyUndefined, makeCopyOfSimpleObjects);
    } else if (copyUndefined || sourceValue !== void 0) {
      dest[key] = sourceValue;
    }
  });
}
function _getValueUsingField(data, field, fieldContainsDots) {
  if (!field || !data) {
    return;
  }
  if (!fieldContainsDots) {
    return data[field];
  }
  const fields = field.split(".");
  let currentObject = data;
  for (let i = 0; i < fields.length; i++) {
    if (currentObject == null) {
      return void 0;
    }
    currentObject = currentObject[fields[i]];
  }
  return currentObject;
}
function _isNonNullObject(value) {
  return typeof value === "object" && value !== null;
}

// community-modules/core/src/entities/agColumn.ts
var COL_DEF_DEFAULTS = {
  resizable: true,
  sortable: true
};
var instanceIdSequence = 0;
function getNextColInstanceId() {
  return instanceIdSequence++;
}
function isColumn(col) {
  return col instanceof AgColumn;
}
var DEFAULT_COLUMN_MIN_WIDTH = 20;
var AgColumn = class extends BeanStub {
  constructor(colDef, userProvidedColDef, colId, primary) {
    super();
    this.isColumn = true;
    // used by React (and possibly other frameworks) as key for rendering. also used to
    // identify old vs new columns for destroying cols when no longer used.
    this.instanceId = getNextColInstanceId();
    // The measured height of this column's header when autoHeaderHeight is enabled
    this.autoHeaderHeight = null;
    this.moving = false;
    this.menuVisible = false;
    this.lastLeftPinned = false;
    this.firstRightPinned = false;
    this.filterActive = false;
    this.columnEventService = new LocalEventService();
    this.tooltipEnabled = false;
    this.rowGroupActive = false;
    this.pivotActive = false;
    this.aggregationActive = false;
    this.colDef = colDef;
    this.userProvidedColDef = userProvidedColDef;
    this.colId = colId;
    this.primary = primary;
    this.setState(colDef);
  }
  wireBeans(beans) {
    this.columnHoverService = beans.columnHoverService;
  }
  getInstanceId() {
    return this.instanceId;
  }
  setState(colDef) {
    if (colDef.sort !== void 0) {
      if (colDef.sort === "asc" || colDef.sort === "desc") {
        this.sort = colDef.sort;
      }
    } else {
      if (colDef.initialSort === "asc" || colDef.initialSort === "desc") {
        this.sort = colDef.initialSort;
      }
    }
    const sortIndex = colDef.sortIndex;
    const initialSortIndex = colDef.initialSortIndex;
    if (sortIndex !== void 0) {
      if (sortIndex !== null) {
        this.sortIndex = sortIndex;
      }
    } else {
      if (initialSortIndex !== null) {
        this.sortIndex = initialSortIndex;
      }
    }
    const hide = colDef.hide;
    const initialHide = colDef.initialHide;
    if (hide !== void 0) {
      this.visible = !hide;
    } else {
      this.visible = !initialHide;
    }
    if (colDef.pinned !== void 0) {
      this.setPinned(colDef.pinned);
    } else {
      this.setPinned(colDef.initialPinned);
    }
    const flex = colDef.flex;
    const initialFlex = colDef.initialFlex;
    if (flex !== void 0) {
      this.flex = flex;
    } else if (initialFlex !== void 0) {
      this.flex = initialFlex;
    }
  }
  // gets called when user provides an alternative colDef, eg
  setColDef(colDef, userProvidedColDef, source) {
    this.colDef = colDef;
    this.userProvidedColDef = userProvidedColDef;
    this.initMinAndMaxWidths();
    this.initDotNotation();
    this.initTooltip();
    this.columnEventService.dispatchEvent(this.createColumnEvent("colDefChanged", source));
  }
  getUserProvidedColDef() {
    return this.userProvidedColDef;
  }
  setParent(parent) {
    this.parent = parent;
  }
  getParent() {
    return this.parent;
  }
  setOriginalParent(originalParent) {
    this.originalParent = originalParent;
  }
  getOriginalParent() {
    return this.originalParent;
  }
  // this is done after constructor as it uses gridOptionsService
  postConstruct() {
    this.initMinAndMaxWidths();
    this.resetActualWidth("gridInitializing");
    this.initDotNotation();
    this.initTooltip();
  }
  initDotNotation() {
    const suppressDotNotation = this.gos.get("suppressFieldDotNotation");
    this.fieldContainsDots = _exists(this.colDef.field) && this.colDef.field.indexOf(".") >= 0 && !suppressDotNotation;
    this.tooltipFieldContainsDots = _exists(this.colDef.tooltipField) && this.colDef.tooltipField.indexOf(".") >= 0 && !suppressDotNotation;
  }
  initMinAndMaxWidths() {
    const colDef = this.colDef;
    this.minWidth = colDef.minWidth ?? DEFAULT_COLUMN_MIN_WIDTH;
    this.maxWidth = colDef.maxWidth ?? Number.MAX_SAFE_INTEGER;
  }
  initTooltip() {
    this.tooltipEnabled = _exists(this.colDef.tooltipField) || _exists(this.colDef.tooltipValueGetter) || _exists(this.colDef.tooltipComponent);
  }
  resetActualWidth(source) {
    const initialWidth = this.calculateColInitialWidth(this.colDef);
    this.setActualWidth(initialWidth, source, true);
  }
  calculateColInitialWidth(colDef) {
    let width;
    const colDefWidth = _attrToNumber(colDef.width);
    const colDefInitialWidth = _attrToNumber(colDef.initialWidth);
    if (colDefWidth != null) {
      width = colDefWidth;
    } else if (colDefInitialWidth != null) {
      width = colDefInitialWidth;
    } else {
      width = 200;
    }
    return Math.max(Math.min(width, this.maxWidth), this.minWidth);
  }
  isEmptyGroup() {
    return false;
  }
  isRowGroupDisplayed(colId) {
    if (_missing(this.colDef) || _missing(this.colDef.showRowGroup)) {
      return false;
    }
    const showingAllGroups = this.colDef.showRowGroup === true;
    const showingThisGroup = this.colDef.showRowGroup === colId;
    return showingAllGroups || showingThisGroup;
  }
  isPrimary() {
    return this.primary;
  }
  isFilterAllowed() {
    const filterDefined = !!this.colDef.filter;
    return filterDefined;
  }
  isFieldContainsDots() {
    return this.fieldContainsDots;
  }
  isTooltipEnabled() {
    return this.tooltipEnabled;
  }
  isTooltipFieldContainsDots() {
    return this.tooltipFieldContainsDots;
  }
  addEventListener(eventType, userListener) {
    if (this.frameworkOverrides.shouldWrapOutgoing && !this.frameworkEventListenerService) {
      this.columnEventService.setFrameworkOverrides(this.frameworkOverrides);
      this.frameworkEventListenerService = new FrameworkEventListenerService(this.frameworkOverrides);
    }
    const listener = this.frameworkEventListenerService?.wrap(userListener) ?? userListener;
    this.columnEventService.addEventListener(eventType, listener);
  }
  removeEventListener(eventType, userListener) {
    const listener = this.frameworkEventListenerService?.unwrap(userListener) ?? userListener;
    this.columnEventService.removeEventListener(eventType, listener);
  }
  createColumnFunctionCallbackParams(rowNode) {
    return this.gos.addGridCommonParams({
      node: rowNode,
      data: rowNode.data,
      column: this,
      colDef: this.colDef
    });
  }
  isSuppressNavigable(rowNode) {
    if (typeof this.colDef.suppressNavigable === "boolean") {
      return this.colDef.suppressNavigable;
    }
    if (typeof this.colDef.suppressNavigable === "function") {
      const params = this.createColumnFunctionCallbackParams(rowNode);
      const userFunc = this.colDef.suppressNavigable;
      return userFunc(params);
    }
    return false;
  }
  isCellEditable(rowNode) {
    if (rowNode.group && !this.gos.get("enableGroupEdit")) {
      return false;
    }
    return this.isColumnFunc(rowNode, this.colDef.editable);
  }
  isSuppressFillHandle() {
    return !!this.colDef.suppressFillHandle;
  }
  isAutoHeight() {
    return !!this.colDef.autoHeight;
  }
  isAutoHeaderHeight() {
    return !!this.colDef.autoHeaderHeight;
  }
  isRowDrag(rowNode) {
    return this.isColumnFunc(rowNode, this.colDef.rowDrag);
  }
  isDndSource(rowNode) {
    return this.isColumnFunc(rowNode, this.colDef.dndSource);
  }
  isCellCheckboxSelection(rowNode) {
    return this.isColumnFunc(rowNode, this.colDef.checkboxSelection);
  }
  isSuppressPaste(rowNode) {
    return this.isColumnFunc(rowNode, this.colDef ? this.colDef.suppressPaste : null);
  }
  isResizable() {
    return !!this.getColDefValue("resizable");
  }
  /** Get value from ColDef or default if it exists. */
  getColDefValue(key) {
    return this.colDef[key] ?? COL_DEF_DEFAULTS[key];
  }
  isColumnFunc(rowNode, value) {
    if (typeof value === "boolean") {
      return value;
    }
    if (typeof value === "function") {
      const params = this.createColumnFunctionCallbackParams(rowNode);
      const editableFunc = value;
      return editableFunc(params);
    }
    return false;
  }
  setMoving(moving, source) {
    this.moving = moving;
    this.columnEventService.dispatchEvent(this.createColumnEvent("movingChanged", source));
  }
  createColumnEvent(type, source) {
    return this.gos.addGridCommonParams({
      type,
      column: this,
      columns: [this],
      source
    });
  }
  isMoving() {
    return this.moving;
  }
  getSort() {
    return this.sort;
  }
  setSort(sort, source) {
    if (this.sort !== sort) {
      this.sort = sort;
      this.columnEventService.dispatchEvent(this.createColumnEvent("sortChanged", source));
    }
    this.dispatchStateUpdatedEvent("sort");
  }
  isSortable() {
    return !!this.getColDefValue("sortable");
  }
  /** @deprecated v32 use col.getSort() === 'asc */
  isSortAscending() {
    return this.sort === "asc";
  }
  /** @deprecated v32 use col.getSort() === 'desc */
  isSortDescending() {
    return this.sort === "desc";
  }
  /** @deprecated v32 use col.getSort() === undefined */
  isSortNone() {
    return _missing(this.sort);
  }
  /** @deprecated v32 use col.getSort() !== undefined */
  isSorting() {
    return _exists(this.sort);
  }
  getSortIndex() {
    return this.sortIndex;
  }
  setSortIndex(sortOrder) {
    this.sortIndex = sortOrder;
    this.dispatchStateUpdatedEvent("sortIndex");
  }
  setMenuVisible(visible, source) {
    if (this.menuVisible !== visible) {
      this.menuVisible = visible;
      this.columnEventService.dispatchEvent(this.createColumnEvent("menuVisibleChanged", source));
    }
  }
  isMenuVisible() {
    return this.menuVisible;
  }
  setAggFunc(aggFunc) {
    this.aggFunc = aggFunc;
    this.dispatchStateUpdatedEvent("aggFunc");
  }
  getAggFunc() {
    return this.aggFunc;
  }
  getLeft() {
    return this.left;
  }
  getOldLeft() {
    return this.oldLeft;
  }
  getRight() {
    return this.left + this.actualWidth;
  }
  setLeft(left, source) {
    this.oldLeft = this.left;
    if (this.left !== left) {
      this.left = left;
      this.columnEventService.dispatchEvent(this.createColumnEvent("leftChanged", source));
    }
  }
  isFilterActive() {
    return this.filterActive;
  }
  // additionalEventAttributes is used by provided simple floating filter, so it can add 'floatingFilter=true' to the event
  setFilterActive(active, source, additionalEventAttributes) {
    if (this.filterActive !== active) {
      this.filterActive = active;
      this.columnEventService.dispatchEvent(this.createColumnEvent("filterActiveChanged", source));
    }
    const filterChangedEvent = this.createColumnEvent("filterChanged", source);
    if (additionalEventAttributes) {
      _mergeDeep(filterChangedEvent, additionalEventAttributes);
    }
    this.columnEventService.dispatchEvent(filterChangedEvent);
  }
  isHovered() {
    return this.columnHoverService.isHovered(this);
  }
  setPinned(pinned) {
    if (pinned === true || pinned === "left") {
      this.pinned = "left";
    } else if (pinned === "right") {
      this.pinned = "right";
    } else {
      this.pinned = null;
    }
    this.dispatchStateUpdatedEvent("pinned");
  }
  setFirstRightPinned(firstRightPinned, source) {
    if (this.firstRightPinned !== firstRightPinned) {
      this.firstRightPinned = firstRightPinned;
      this.columnEventService.dispatchEvent(this.createColumnEvent("firstRightPinnedChanged", source));
    }
  }
  setLastLeftPinned(lastLeftPinned, source) {
    if (this.lastLeftPinned !== lastLeftPinned) {
      this.lastLeftPinned = lastLeftPinned;
      this.columnEventService.dispatchEvent(this.createColumnEvent("lastLeftPinnedChanged", source));
    }
  }
  isFirstRightPinned() {
    return this.firstRightPinned;
  }
  isLastLeftPinned() {
    return this.lastLeftPinned;
  }
  isPinned() {
    return this.pinned === "left" || this.pinned === "right";
  }
  isPinnedLeft() {
    return this.pinned === "left";
  }
  isPinnedRight() {
    return this.pinned === "right";
  }
  getPinned() {
    return this.pinned;
  }
  setVisible(visible, source) {
    const newValue = visible === true;
    if (this.visible !== newValue) {
      this.visible = newValue;
      this.columnEventService.dispatchEvent(this.createColumnEvent("visibleChanged", source));
    }
    this.dispatchStateUpdatedEvent("hide");
  }
  isVisible() {
    return this.visible;
  }
  isSpanHeaderHeight() {
    const colDef = this.getColDef();
    return !colDef.suppressSpanHeaderHeight && !colDef.autoHeaderHeight;
  }
  getColumnGroupPaddingInfo() {
    let parent = this.getParent();
    if (!parent || !parent.isPadding()) {
      return { numberOfParents: 0, isSpanningTotal: false };
    }
    const numberOfParents = parent.getPaddingLevel() + 1;
    let isSpanningTotal = true;
    while (parent) {
      if (!parent.isPadding()) {
        isSpanningTotal = false;
        break;
      }
      parent = parent.getParent();
    }
    return { numberOfParents, isSpanningTotal };
  }
  getColDef() {
    return this.colDef;
  }
  getDefinition() {
    return this.colDef;
  }
  getColumnGroupShow() {
    return this.colDef.columnGroupShow;
  }
  getColId() {
    return this.colId;
  }
  getId() {
    return this.colId;
  }
  getUniqueId() {
    return this.colId;
  }
  getActualWidth() {
    return this.actualWidth;
  }
  getAutoHeaderHeight() {
    return this.autoHeaderHeight;
  }
  /** Returns true if the header height has changed */
  setAutoHeaderHeight(height) {
    const changed = height !== this.autoHeaderHeight;
    this.autoHeaderHeight = height;
    return changed;
  }
  createBaseColDefParams(rowNode) {
    const params = this.gos.addGridCommonParams({
      node: rowNode,
      data: rowNode.data,
      colDef: this.colDef,
      column: this
    });
    return params;
  }
  getColSpan(rowNode) {
    if (_missing(this.colDef.colSpan)) {
      return 1;
    }
    const params = this.createBaseColDefParams(rowNode);
    const colSpan = this.colDef.colSpan(params);
    return Math.max(colSpan, 1);
  }
  getRowSpan(rowNode) {
    if (_missing(this.colDef.rowSpan)) {
      return 1;
    }
    const params = this.createBaseColDefParams(rowNode);
    const rowSpan = this.colDef.rowSpan(params);
    return Math.max(rowSpan, 1);
  }
  setActualWidth(actualWidth, source, silent = false) {
    actualWidth = Math.max(actualWidth, this.minWidth);
    actualWidth = Math.min(actualWidth, this.maxWidth);
    if (this.actualWidth !== actualWidth) {
      this.actualWidth = actualWidth;
      if (this.flex && source !== "flex" && source !== "gridInitializing") {
        this.flex = null;
      }
      if (!silent) {
        this.fireColumnWidthChangedEvent(source);
      }
    }
    this.dispatchStateUpdatedEvent("width");
  }
  fireColumnWidthChangedEvent(source) {
    this.columnEventService.dispatchEvent(this.createColumnEvent("widthChanged", source));
  }
  isGreaterThanMax(width) {
    return width > this.maxWidth;
  }
  getMinWidth() {
    return this.minWidth;
  }
  getMaxWidth() {
    return this.maxWidth;
  }
  getFlex() {
    return this.flex || 0;
  }
  // this method should only be used by the columnModel to
  // change flex when required by the applyColumnState method.
  setFlex(flex) {
    if (this.flex !== flex) {
      this.flex = flex;
    }
    this.dispatchStateUpdatedEvent("flex");
  }
  setMinimum(source) {
    this.setActualWidth(this.minWidth, source);
  }
  setRowGroupActive(rowGroup, source) {
    if (this.rowGroupActive !== rowGroup) {
      this.rowGroupActive = rowGroup;
      this.columnEventService.dispatchEvent(this.createColumnEvent("columnRowGroupChanged", source));
    }
    this.dispatchStateUpdatedEvent("rowGroup");
  }
  isRowGroupActive() {
    return this.rowGroupActive;
  }
  setPivotActive(pivot, source) {
    if (this.pivotActive !== pivot) {
      this.pivotActive = pivot;
      this.columnEventService.dispatchEvent(this.createColumnEvent("columnPivotChanged", source));
    }
    this.dispatchStateUpdatedEvent("pivot");
  }
  isPivotActive() {
    return this.pivotActive;
  }
  isAnyFunctionActive() {
    return this.isPivotActive() || this.isRowGroupActive() || this.isValueActive();
  }
  isAnyFunctionAllowed() {
    return this.isAllowPivot() || this.isAllowRowGroup() || this.isAllowValue();
  }
  setValueActive(value, source) {
    if (this.aggregationActive !== value) {
      this.aggregationActive = value;
      this.columnEventService.dispatchEvent(this.createColumnEvent("columnValueChanged", source));
    }
  }
  isValueActive() {
    return this.aggregationActive;
  }
  isAllowPivot() {
    return this.colDef.enablePivot === true;
  }
  isAllowValue() {
    return this.colDef.enableValue === true;
  }
  isAllowRowGroup() {
    return this.colDef.enableRowGroup === true;
  }
  dispatchStateUpdatedEvent(key) {
    this.columnEventService.dispatchEvent({
      type: "columnStateUpdated",
      key
    });
  }
};

// community-modules/core/src/entities/agProvidedColumnGroup.ts
function isProvidedColumnGroup(col) {
  return col instanceof AgProvidedColumnGroup;
}
var AgProvidedColumnGroup = class extends BeanStub {
  constructor(colGroupDef, groupId, padding, level) {
    super();
    this.isColumn = false;
    this.expandable = false;
    // used by React (and possibly other frameworks) as key for rendering. also used to
    // identify old vs new columns for destroying cols when no longer used.
    this.instanceId = getNextColInstanceId();
    this.expandableListenerRemoveCallback = null;
    this.colGroupDef = colGroupDef;
    this.groupId = groupId;
    this.expanded = !!colGroupDef && !!colGroupDef.openByDefault;
    this.padding = padding;
    this.level = level;
  }
  destroy() {
    if (this.expandableListenerRemoveCallback) {
      this.reset(null, void 0);
    }
    super.destroy();
  }
  reset(colGroupDef, level) {
    this.colGroupDef = colGroupDef;
    this.level = level;
    this.originalParent = null;
    if (this.expandableListenerRemoveCallback) {
      this.expandableListenerRemoveCallback();
    }
    this.children = void 0;
    this.expandable = void 0;
  }
  getInstanceId() {
    return this.instanceId;
  }
  setOriginalParent(originalParent) {
    this.originalParent = originalParent;
  }
  getOriginalParent() {
    return this.originalParent;
  }
  getLevel() {
    return this.level;
  }
  isVisible() {
    if (this.children) {
      return this.children.some((child) => child.isVisible());
    }
    return false;
  }
  isPadding() {
    return this.padding;
  }
  setExpanded(expanded) {
    this.expanded = expanded === void 0 ? false : expanded;
    this.dispatchLocalEvent({ type: "expandedChanged" });
  }
  isExpandable() {
    return this.expandable;
  }
  isExpanded() {
    return this.expanded;
  }
  getGroupId() {
    return this.groupId;
  }
  getId() {
    return this.getGroupId();
  }
  setChildren(children) {
    this.children = children;
  }
  getChildren() {
    return this.children;
  }
  getColGroupDef() {
    return this.colGroupDef;
  }
  getLeafColumns() {
    const result = [];
    this.addLeafColumns(result);
    return result;
  }
  addLeafColumns(leafColumns) {
    if (!this.children) {
      return;
    }
    this.children.forEach((child) => {
      if (isColumn(child)) {
        leafColumns.push(child);
      } else if (isProvidedColumnGroup(child)) {
        child.addLeafColumns(leafColumns);
      }
    });
  }
  getColumnGroupShow() {
    const colGroupDef = this.colGroupDef;
    if (!colGroupDef) {
      return;
    }
    return colGroupDef.columnGroupShow;
  }
  // need to check that this group has at least one col showing when both expanded and contracted.
  // if not, then we don't allow expanding and contracting on this group
  setupExpandable() {
    this.setExpandable();
    if (this.expandableListenerRemoveCallback) {
      this.expandableListenerRemoveCallback();
    }
    const listener = this.onColumnVisibilityChanged.bind(this);
    this.getLeafColumns().forEach((col) => col.addEventListener("visibleChanged", listener));
    this.expandableListenerRemoveCallback = () => {
      this.getLeafColumns().forEach((col) => col.removeEventListener("visibleChanged", listener));
      this.expandableListenerRemoveCallback = null;
    };
  }
  setExpandable() {
    if (this.isPadding()) {
      return;
    }
    let atLeastOneShowingWhenOpen = false;
    let atLeastOneShowingWhenClosed = false;
    let atLeastOneChangeable = false;
    const children = this.findChildrenRemovingPadding();
    for (let i = 0, j = children.length; i < j; i++) {
      const abstractColumn = children[i];
      if (!abstractColumn.isVisible()) {
        continue;
      }
      const headerGroupShow = abstractColumn.getColumnGroupShow();
      if (headerGroupShow === "open") {
        atLeastOneShowingWhenOpen = true;
        atLeastOneChangeable = true;
      } else if (headerGroupShow === "closed") {
        atLeastOneShowingWhenClosed = true;
        atLeastOneChangeable = true;
      } else {
        atLeastOneShowingWhenOpen = true;
        atLeastOneShowingWhenClosed = true;
      }
    }
    const expandable = atLeastOneShowingWhenOpen && atLeastOneShowingWhenClosed && atLeastOneChangeable;
    if (this.expandable !== expandable) {
      this.expandable = expandable;
      this.dispatchLocalEvent({ type: "expandableChanged" });
    }
  }
  findChildrenRemovingPadding() {
    const res = [];
    const process = (items) => {
      items.forEach((item) => {
        const skipBecausePadding = isProvidedColumnGroup(item) && item.isPadding();
        if (skipBecausePadding) {
          process(item.children);
        } else {
          res.push(item);
        }
      });
    };
    process(this.children);
    return res;
  }
  onColumnVisibilityChanged() {
    this.setExpandable();
  }
};

// community-modules/core/src/entities/defaultColumnTypes.ts
var DefaultColumnTypes = {
  numericColumn: {
    headerClass: "ag-right-aligned-header",
    cellClass: "ag-right-aligned-cell"
  },
  rightAligned: {
    headerClass: "ag-right-aligned-header",
    cellClass: "ag-right-aligned-cell"
  }
};

// community-modules/core/src/utils/function.ts
var doOnceFlags = {};
function _doOnce(func, key) {
  if (doOnceFlags[key]) {
    return;
  }
  func();
  doOnceFlags[key] = true;
}
function _log(message, ...args) {
  console.log("AG Grid: " + message, ...args);
}
function _warnOnce(msg, ...args) {
  _doOnce(() => console.warn("AG Grid: " + msg, ...args), msg);
}
function _errorOnce(msg, ...args) {
  _doOnce(() => console.error("AG Grid: " + msg, ...args), msg);
}
function _isFunction(val) {
  return !!(val && val.constructor && val.call && val.apply);
}
function _executeInAWhile(funcs) {
  _executeAfter(funcs, 400);
}
var executeNextVMTurnFuncs = [];
var executeNextVMTurnPending = false;
function _executeNextVMTurn(func) {
  executeNextVMTurnFuncs.push(func);
  if (executeNextVMTurnPending) {
    return;
  }
  executeNextVMTurnPending = true;
  window.setTimeout(() => {
    const funcsCopy = executeNextVMTurnFuncs.slice();
    executeNextVMTurnFuncs.length = 0;
    executeNextVMTurnPending = false;
    funcsCopy.forEach((func2) => func2());
  }, 0);
}
function _executeAfter(funcs, milliseconds = 0) {
  if (funcs.length > 0) {
    window.setTimeout(() => funcs.forEach((func) => func()), milliseconds);
  }
}
function _debounce(func, delay) {
  let timeout;
  return function(...args) {
    const context = this;
    window.clearTimeout(timeout);
    timeout = window.setTimeout(function() {
      func.apply(context, args);
    }, delay);
  };
}
function _throttle(func, wait) {
  let previousCall = 0;
  return function(...args) {
    const context = this;
    const currentCall = (/* @__PURE__ */ new Date()).getTime();
    if (currentCall - previousCall < wait) {
      return;
    }
    previousCall = currentCall;
    func.apply(context, args);
  };
}
function _waitUntil(condition, callback, timeout = 100, timeoutMessage) {
  const timeStamp = (/* @__PURE__ */ new Date()).getTime();
  let interval = null;
  let executed = false;
  const internalCallback = () => {
    const reachedTimeout = (/* @__PURE__ */ new Date()).getTime() - timeStamp > timeout;
    if (condition() || reachedTimeout) {
      callback();
      executed = true;
      if (interval != null) {
        window.clearInterval(interval);
        interval = null;
      }
      if (reachedTimeout && timeoutMessage) {
        _warnOnce(timeoutMessage);
      }
    }
  };
  internalCallback();
  if (!executed) {
    interval = window.setInterval(internalCallback, 10);
  }
}
function _compose(...fns) {
  return (arg) => fns.reduce((composed, f) => f(composed), arg);
}

// community-modules/core/src/columns/columnKeyCreator.ts
var ColumnKeyCreator = class {
  constructor() {
    this.existingKeys = {};
  }
  addExistingKeys(keys) {
    for (let i = 0; i < keys.length; i++) {
      this.existingKeys[keys[i]] = true;
    }
  }
  getUniqueKey(colId, colField) {
    colId = _toStringOrNull(colId);
    let count = 0;
    while (true) {
      let idToTry;
      if (colId) {
        idToTry = colId;
        if (count !== 0) {
          idToTry += "_" + count;
        }
      } else if (colField) {
        idToTry = colField;
        if (count !== 0) {
          idToTry += "_" + count;
        }
      } else {
        idToTry = count;
      }
      if (!this.existingKeys[idToTry]) {
        this.existingKeys[idToTry] = true;
        return String(idToTry);
      }
      count++;
    }
  }
};

// community-modules/core/src/columns/columnUtils.ts
var GROUP_AUTO_COLUMN_ID = "ag-Grid-AutoColumn";
function getColumnsFromTree(rootColumns) {
  const result = [];
  const recursiveFindColumns = (childColumns) => {
    for (let i = 0; i < childColumns.length; i++) {
      const child = childColumns[i];
      if (isColumn(child)) {
        result.push(child);
      } else if (isProvidedColumnGroup(child)) {
        recursiveFindColumns(child.getChildren());
      }
    }
  };
  recursiveFindColumns(rootColumns);
  return result;
}
function getWidthOfColsInList(columnList) {
  return columnList.reduce((width, col) => width + col.getActualWidth(), 0);
}
function destroyColumnTree(context, oldTree, newTree) {
  const oldObjectsById = {};
  if (!oldTree) {
    return;
  }
  depthFirstOriginalTreeSearch(null, oldTree, (child) => {
    oldObjectsById[child.getInstanceId()] = child;
  });
  if (newTree) {
    depthFirstOriginalTreeSearch(null, newTree, (child) => {
      oldObjectsById[child.getInstanceId()] = null;
    });
  }
  const colsToDestroy = Object.values(oldObjectsById).filter((item) => item != null);
  context.destroyBeans(colsToDestroy);
}
function isColumnGroupAutoCol(col) {
  const colId = col.getId();
  return colId.startsWith(GROUP_AUTO_COLUMN_ID);
}
function convertColumnTypes(type) {
  let typeKeys = [];
  if (type instanceof Array) {
    const invalidArray = type.some((a) => typeof a !== "string");
    if (invalidArray) {
      _warnOnce("if colDef.type is supplied an array it should be of type 'string[]'");
    } else {
      typeKeys = type;
    }
  } else if (typeof type === "string") {
    typeKeys = type.split(",");
  } else {
    _warnOnce("colDef.type should be of type 'string' | 'string[]'");
  }
  return typeKeys;
}

// community-modules/core/src/columns/columnFactory.ts
var ColumnFactory = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "columnFactory";
  }
  wireBeans(beans) {
    this.dataTypeService = beans.dataTypeService;
  }
  createColumnTree(defs, primaryColumns, existingTree, source) {
    const columnKeyCreator = new ColumnKeyCreator();
    const { existingCols, existingGroups, existingColKeys } = this.extractExistingTreeData(existingTree);
    columnKeyCreator.addExistingKeys(existingColKeys);
    const unbalancedTree = this.recursivelyCreateColumns(
      defs,
      0,
      primaryColumns,
      existingCols,
      columnKeyCreator,
      existingGroups,
      source
    );
    const treeDept = this.findMaxDept(unbalancedTree, 0);
    const columnTree = this.balanceColumnTree(unbalancedTree, 0, treeDept, columnKeyCreator);
    const deptFirstCallback = (child, parent) => {
      if (isProvidedColumnGroup(child)) {
        child.setupExpandable();
      }
      child.setOriginalParent(parent);
    };
    depthFirstOriginalTreeSearch(null, columnTree, deptFirstCallback);
    return {
      columnTree,
      treeDept
    };
  }
  extractExistingTreeData(existingTree) {
    const existingCols = [];
    const existingGroups = [];
    const existingColKeys = [];
    if (existingTree) {
      depthFirstOriginalTreeSearch(null, existingTree, (item) => {
        if (isProvidedColumnGroup(item)) {
          const group = item;
          existingGroups.push(group);
        } else {
          const col = item;
          existingColKeys.push(col.getId());
          existingCols.push(col);
        }
      });
    }
    return { existingCols, existingGroups, existingColKeys };
  }
  createForAutoGroups(autoGroupCols, liveTree) {
    const tree = [];
    const dept = this.findDepth(liveTree);
    autoGroupCols.forEach((col) => {
      let nextChild = col;
      for (let i = dept - 1; i >= 0; i--) {
        const autoGroup = new AgProvidedColumnGroup(null, `FAKE_PATH_${col.getId()}}_${i}`, true, i);
        this.createBean(autoGroup);
        autoGroup.setChildren([nextChild]);
        nextChild.setOriginalParent(autoGroup);
        nextChild = autoGroup;
      }
      if (dept === 0) {
        col.setOriginalParent(null);
      }
      tree.push(nextChild);
    });
    return [tree, dept];
  }
  findDepth(balancedColumnTree) {
    let dept = 0;
    let pointer = balancedColumnTree;
    while (pointer && pointer[0] && isProvidedColumnGroup(pointer[0])) {
      dept++;
      pointer = pointer[0].getChildren();
    }
    return dept;
  }
  balanceColumnTree(unbalancedTree, currentDept, columnDept, columnKeyCreator) {
    const result = [];
    for (let i = 0; i < unbalancedTree.length; i++) {
      const child = unbalancedTree[i];
      if (isProvidedColumnGroup(child)) {
        const originalGroup = child;
        const newChildren = this.balanceColumnTree(
          originalGroup.getChildren(),
          currentDept + 1,
          columnDept,
          columnKeyCreator
        );
        originalGroup.setChildren(newChildren);
        result.push(originalGroup);
      } else {
        let firstPaddedGroup;
        let currentPaddedGroup;
        for (let j = columnDept - 1; j >= currentDept; j--) {
          const newColId = columnKeyCreator.getUniqueKey(null, null);
          const colGroupDefMerged = this.createMergedColGroupDef(null);
          const paddedGroup = new AgProvidedColumnGroup(colGroupDefMerged, newColId, true, currentDept);
          this.createBean(paddedGroup);
          if (currentPaddedGroup) {
            currentPaddedGroup.setChildren([paddedGroup]);
          }
          currentPaddedGroup = paddedGroup;
          if (!firstPaddedGroup) {
            firstPaddedGroup = currentPaddedGroup;
          }
        }
        if (firstPaddedGroup && currentPaddedGroup) {
          result.push(firstPaddedGroup);
          const hasGroups = unbalancedTree.some((leaf) => isProvidedColumnGroup(leaf));
          if (hasGroups) {
            currentPaddedGroup.setChildren([child]);
            continue;
          } else {
            currentPaddedGroup.setChildren(unbalancedTree);
            break;
          }
        }
        result.push(child);
      }
    }
    return result;
  }
  findMaxDept(treeChildren, dept) {
    let maxDeptThisLevel = dept;
    for (let i = 0; i < treeChildren.length; i++) {
      const abstractColumn = treeChildren[i];
      if (isProvidedColumnGroup(abstractColumn)) {
        const originalGroup = abstractColumn;
        const newDept = this.findMaxDept(originalGroup.getChildren(), dept + 1);
        if (maxDeptThisLevel < newDept) {
          maxDeptThisLevel = newDept;
        }
      }
    }
    return maxDeptThisLevel;
  }
  recursivelyCreateColumns(defs, level, primaryColumns, existingColsCopy, columnKeyCreator, existingGroups, source) {
    if (!defs)
      return [];
    const result = new Array(defs.length);
    for (let i = 0; i < result.length; i++) {
      const def = defs[i];
      if (this.isColumnGroup(def)) {
        result[i] = this.createColumnGroup(
          primaryColumns,
          def,
          level,
          existingColsCopy,
          columnKeyCreator,
          existingGroups,
          source
        );
      } else {
        result[i] = this.createColumn(
          primaryColumns,
          def,
          existingColsCopy,
          columnKeyCreator,
          source
        );
      }
    }
    return result;
  }
  createColumnGroup(primaryColumns, colGroupDef, level, existingColumns, columnKeyCreator, existingGroups, source) {
    const colGroupDefMerged = this.createMergedColGroupDef(colGroupDef);
    const groupId = columnKeyCreator.getUniqueKey(colGroupDefMerged.groupId || null, null);
    const providedGroup = new AgProvidedColumnGroup(colGroupDefMerged, groupId, false, level);
    this.createBean(providedGroup);
    const existingGroupAndIndex = this.findExistingGroup(colGroupDef, existingGroups);
    if (existingGroupAndIndex) {
      existingGroups.splice(existingGroupAndIndex.idx, 1);
    }
    const existingGroup = existingGroupAndIndex?.group;
    if (existingGroup) {
      providedGroup.setExpanded(existingGroup.isExpanded());
    }
    const children = this.recursivelyCreateColumns(
      colGroupDefMerged.children,
      level + 1,
      primaryColumns,
      existingColumns,
      columnKeyCreator,
      existingGroups,
      source
    );
    providedGroup.setChildren(children);
    return providedGroup;
  }
  createMergedColGroupDef(colGroupDef) {
    const colGroupDefMerged = {};
    Object.assign(colGroupDefMerged, this.gos.get("defaultColGroupDef"));
    Object.assign(colGroupDefMerged, colGroupDef);
    return colGroupDefMerged;
  }
  createColumn(primaryColumns, colDef, existingColsCopy, columnKeyCreator, source) {
    const existingColAndIndex = this.findExistingColumn(colDef, existingColsCopy);
    if (existingColAndIndex) {
      existingColsCopy?.splice(existingColAndIndex.idx, 1);
    }
    let column = existingColAndIndex?.column;
    if (!column) {
      const colId = columnKeyCreator.getUniqueKey(colDef.colId, colDef.field);
      const colDefMerged = this.addColumnDefaultAndTypes(colDef, colId);
      column = new AgColumn(colDefMerged, colDef, colId, primaryColumns);
      this.createBean(column);
    } else {
      const colDefMerged = this.addColumnDefaultAndTypes(colDef, column.getColId());
      column.setColDef(colDefMerged, colDef, source);
      this.applyColumnState(column, colDefMerged, source);
    }
    this.dataTypeService?.addColumnListeners(column);
    return column;
  }
  applyColumnState(column, colDef, source) {
    const flex = _attrToNumber(colDef.flex);
    if (flex !== void 0) {
      column.setFlex(flex);
    }
    const noFlexThisCol = column.getFlex() <= 0;
    if (noFlexThisCol) {
      const width = _attrToNumber(colDef.width);
      if (width != null) {
        column.setActualWidth(width, source);
      } else {
        const widthBeforeUpdate = column.getActualWidth();
        column.setActualWidth(widthBeforeUpdate, source);
      }
    }
    if (colDef.sort !== void 0) {
      if (colDef.sort == "asc" || colDef.sort == "desc") {
        column.setSort(colDef.sort, source);
      } else {
        column.setSort(void 0, source);
      }
    }
    const sortIndex = _attrToNumber(colDef.sortIndex);
    if (sortIndex !== void 0) {
      column.setSortIndex(sortIndex);
    }
    const hide = _attrToBoolean(colDef.hide);
    if (hide !== void 0) {
      column.setVisible(!hide, source);
    }
    if (colDef.pinned !== void 0) {
      column.setPinned(colDef.pinned);
    }
  }
  findExistingColumn(newColDef, existingColsCopy) {
    if (!existingColsCopy)
      return void 0;
    for (let i = 0; i < existingColsCopy.length; i++) {
      const def = existingColsCopy[i].getUserProvidedColDef();
      if (!def)
        continue;
      const newHasId = newColDef.colId != null;
      if (newHasId) {
        if (existingColsCopy[i].getId() === newColDef.colId) {
          return { idx: i, column: existingColsCopy[i] };
        }
        continue;
      }
      const newHasField = newColDef.field != null;
      if (newHasField) {
        if (def.field === newColDef.field) {
          return { idx: i, column: existingColsCopy[i] };
        }
        continue;
      }
      if (def === newColDef) {
        return { idx: i, column: existingColsCopy[i] };
      }
    }
    return void 0;
  }
  findExistingGroup(newGroupDef, existingGroups) {
    const newHasId = newGroupDef.groupId != null;
    if (!newHasId) {
      return void 0;
    }
    for (let i = 0; i < existingGroups.length; i++) {
      const existingGroup = existingGroups[i];
      const existingDef = existingGroup.getColGroupDef();
      if (!existingDef) {
        continue;
      }
      if (existingGroup.getId() === newGroupDef.groupId) {
        return { idx: i, group: existingGroup };
      }
    }
    return void 0;
  }
  addColumnDefaultAndTypes(colDef, colId) {
    const res = {};
    const defaultColDef = this.gos.get("defaultColDef");
    _mergeDeep(res, defaultColDef, false, true);
    const columnType = this.updateColDefAndGetColumnType(res, colDef, colId);
    if (columnType) {
      this.assignColumnTypes(columnType, res);
    }
    _mergeDeep(res, colDef, false, true);
    const autoGroupColDef = this.gos.get("autoGroupColumnDef");
    const isSortingCoupled = this.gos.isColumnsSortingCoupledToGroup();
    if (colDef.rowGroup && autoGroupColDef && isSortingCoupled) {
      _mergeDeep(
        res,
        { sort: autoGroupColDef.sort, initialSort: autoGroupColDef.initialSort },
        false,
        true
      );
    }
    this.dataTypeService?.validateColDef(res);
    return res;
  }
  updateColDefAndGetColumnType(colDef, userColDef, colId) {
    const dataTypeDefinitionColumnType = this.dataTypeService?.updateColDefAndGetColumnType(
      colDef,
      userColDef,
      colId
    );
    const columnTypes = userColDef.type ?? dataTypeDefinitionColumnType ?? colDef.type;
    colDef.type = columnTypes;
    return columnTypes ? convertColumnTypes(columnTypes) : void 0;
  }
  assignColumnTypes(typeKeys, colDefMerged) {
    if (!typeKeys.length) {
      return;
    }
    const allColumnTypes = Object.assign({}, DefaultColumnTypes);
    const userTypes = this.gos.get("columnTypes") || {};
    _iterateObject(userTypes, (key, value) => {
      if (key in allColumnTypes) {
        _warnOnce(`the column type '${key}' is a default column type and cannot be overridden.`);
      } else {
        const colType = value;
        if (colType.type) {
          _warnOnce(
            `Column type definitions 'columnTypes' with a 'type' attribute are not supported because a column type cannot refer to another column type. Only column definitions 'columnDefs' can use the 'type' attribute to refer to a column type.`
          );
        }
        allColumnTypes[key] = value;
      }
    });
    typeKeys.forEach((t) => {
      const typeColDef = allColumnTypes[t.trim()];
      if (typeColDef) {
        _mergeDeep(colDefMerged, typeColDef, false, true);
      } else {
        _warnOnce("colDef.type '" + t + "' does not correspond to defined gridOptions.columnTypes");
      }
    });
  }
  // if object has children, we assume it's a group
  isColumnGroup(abstractColDef) {
    return abstractColDef.children !== void 0;
  }
};
function depthFirstOriginalTreeSearch(parent, tree, callback) {
  if (!tree) {
    return;
  }
  for (let i = 0; i < tree.length; i++) {
    const child = tree[i];
    if (isProvidedColumnGroup(child)) {
      depthFirstOriginalTreeSearch(child, child.getChildren(), callback);
    }
    callback(child, parent);
  }
}

// community-modules/core/src/columns/columnModel.ts
var ColumnModel = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "columnModel";
    // if pivotMode is on, however pivot results are NOT shown if no pivot columns are set
    this.pivotMode = false;
    this.autoHeightActiveAtLeastOnce = false;
    this.ready = false;
    this.changeEventsDispatching = false;
    // when we're waiting for cell data types to be inferred, we need to defer column resizing
    this.shouldQueueResizeOperations = false;
    this.resizeOperationQueue = [];
  }
  wireBeans(beans) {
    this.context = beans.context;
    this.columnFactory = beans.columnFactory;
    this.columnSizeService = beans.columnSizeService;
    this.visibleColsService = beans.visibleColsService;
    this.columnViewportService = beans.columnViewportService;
    this.pivotResultColsService = beans.pivotResultColsService;
    this.columnAnimationService = beans.columnAnimationService;
    this.autoColService = beans.autoColService;
    this.valueCache = beans.valueCache;
    this.columnDefFactory = beans.columnDefFactory;
    this.columnApplyStateService = beans.columnApplyStateService;
    this.columnGroupStateService = beans.columnGroupStateService;
    this.eventDispatcher = beans.columnEventDispatcher;
    this.columnMoveService = beans.columnMoveService;
    this.columnAutosizeService = beans.columnAutosizeService;
    this.funcColsService = beans.funcColsService;
    this.quickFilterService = beans.quickFilterService;
    this.showRowGroupColsService = beans.showRowGroupColsService;
    this.environment = beans.environment;
  }
  postConstruct() {
    const pivotMode = this.gos.get("pivotMode");
    if (this.isPivotSettingAllowed(pivotMode)) {
      this.pivotMode = pivotMode;
    }
    this.addManagedPropertyListeners(
      ["groupDisplayType", "treeData", "treeDataDisplayType", "groupHideOpenParents"],
      (event) => this.refreshAll(convertSourceType(event.source))
    );
    this.addManagedPropertyListener(
      "autoGroupColumnDef",
      (event) => this.onAutoGroupColumnDefChanged(convertSourceType(event.source))
    );
    this.addManagedPropertyListeners(
      ["defaultColDef", "columnTypes", "suppressFieldDotNotation"],
      (event) => this.recreateColumnDefs(convertSourceType(event.source))
    );
    this.addManagedPropertyListener(
      "pivotMode",
      (event) => this.setPivotMode(this.gos.get("pivotMode"), convertSourceType(event.source))
    );
    this.addManagedEventListeners({ firstDataRendered: () => this.onFirstDataRendered() });
  }
  // called from SyncService, when grid has finished initialising
  createColsFromColDefs(colsPreviouslyExisted, source) {
    const dispatchEventsFunc = colsPreviouslyExisted ? this.columnApplyStateService.compareColumnStatesAndDispatchEvents(source) : void 0;
    this.valueCache.expire();
    const oldCols = this.colDefCols && this.colDefCols.list;
    const oldTree = this.colDefCols && this.colDefCols.tree;
    const newTree = this.columnFactory.createColumnTree(this.colDefs, true, oldTree, source);
    destroyColumnTree(this.context, this.colDefCols?.tree, newTree.columnTree);
    const tree = newTree.columnTree;
    const treeDepth = newTree.treeDept;
    const list = getColumnsFromTree(tree);
    const map = {};
    list.forEach((col) => map[col.getId()] = col);
    this.colDefCols = { tree, treeDepth, list, map };
    this.funcColsService.extractCols(source, oldCols);
    this.ready = true;
    this.refreshCols();
    const maintainColOrder = colsPreviouslyExisted && !this.showingPivotResult && !this.gos.get("maintainColumnOrder");
    if (maintainColOrder) {
      this.orderColsLikeColDefCols();
    }
    this.visibleColsService.refresh(source);
    this.columnViewportService.checkViewportColumns();
    this.eventDispatcher.everythingChanged(source);
    if (dispatchEventsFunc) {
      this.changeEventsDispatching = true;
      dispatchEventsFunc();
      this.changeEventsDispatching = false;
    }
    this.eventDispatcher.newColumnsLoaded(source);
    if (source === "gridInitializing") {
      this.columnSizeService.applyAutosizeStrategy();
    }
  }
  // called from: buildAutoGroupColumns (events 'groupDisplayType', 'treeData', 'treeDataDisplayType', 'groupHideOpenParents')
  // createColsFromColDefs (recreateColumnDefs, setColumnsDefs),
  // setPivotMode, applyColumnState,
  // functionColsService.setPrimaryColList, functionColsService.updatePrimaryColList,
  // pivotResultColsService.setPivotResultCols
  refreshCols() {
    if (!this.colDefCols) {
      return;
    }
    const prevColTree = this.cols?.tree;
    this.saveColOrder();
    this.selectCols();
    this.createAutoCols();
    this.addAutoCols();
    this.restoreColOrder();
    this.positionLockedCols();
    this.showRowGroupColsService?.refresh();
    this.quickFilterService?.refreshQuickFilterCols();
    this.setColSpanActive();
    this.setAutoHeightActive();
    this.visibleColsService.clear();
    this.columnViewportService.clear();
    const dispatchChangedEvent = !_areEqual(prevColTree, this.cols.tree);
    if (dispatchChangedEvent) {
      this.eventDispatcher.gridColumns();
    }
  }
  selectCols() {
    const pivotResultCols = this.pivotResultColsService.getPivotResultCols();
    this.showingPivotResult = pivotResultCols != null;
    if (pivotResultCols) {
      const { map, list, tree, treeDepth } = pivotResultCols;
      this.cols = {
        list: list.slice(),
        map: { ...map },
        tree: tree.slice(),
        treeDepth
      };
      const hasSameColumns = pivotResultCols.list.some((col) => this.cols?.map[col.getColId()] !== void 0);
      if (!hasSameColumns) {
        this.lastPivotOrder = null;
      }
    } else {
      const { map, list, tree, treeDepth } = this.colDefCols;
      this.cols = {
        list: list.slice(),
        map: { ...map },
        tree: tree.slice(),
        treeDepth
      };
    }
  }
  getColsToShow() {
    const showAutoGroupAndValuesOnly = this.isPivotMode() && !this.isShowingPivotResult();
    const valueColumns = this.funcColsService.getValueColumns();
    const res = this.cols.list.filter((col) => {
      const isAutoGroupCol = isColumnGroupAutoCol(col);
      if (showAutoGroupAndValuesOnly) {
        const isValueCol = valueColumns && _includes(valueColumns, col);
        return isAutoGroupCol || isValueCol;
      } else {
        return isAutoGroupCol || col.isVisible();
      }
    });
    return res;
  }
  addAutoCols() {
    if (this.autoCols == null) {
      return;
    }
    this.cols.list = this.autoCols.list.concat(this.cols.list);
    this.cols.tree = this.autoCols.tree.concat(this.cols.tree);
    updateColsMap(this.cols);
  }
  createAutoCols() {
    const groupFullWidthRow = this.gos.isGroupUseEntireRow(this.pivotMode);
    const suppressAutoColumn = this.pivotMode ? this.gos.get("pivotSuppressAutoColumn") : this.isSuppressAutoCol();
    const rowGroupCols = this.funcColsService.getRowGroupColumns();
    const groupingActive = rowGroupCols.length > 0 || this.gos.get("treeData");
    const noAutoCols = !groupingActive || suppressAutoColumn || groupFullWidthRow;
    const destroyPrevious = () => {
      if (this.autoCols) {
        destroyColumnTree(this.context, this.autoCols.tree);
        this.autoCols = null;
      }
    };
    if (noAutoCols || !this.autoColService) {
      destroyPrevious();
      return;
    }
    const list = this.autoColService.createAutoCols(rowGroupCols) ?? [];
    const autoColsSame = areColIdsEqual(list, this.autoCols?.list || null);
    const newTreeDepth = this.cols.treeDepth;
    const oldTreeDepth = this.autoCols ? this.autoCols.treeDepth : -1;
    const treeDeptSame = oldTreeDepth == newTreeDepth;
    if (autoColsSame && treeDeptSame) {
      return;
    }
    destroyPrevious();
    const [tree, treeDepth] = this.columnFactory.createForAutoGroups(list, this.cols?.tree);
    this.autoCols = {
      list,
      tree,
      treeDepth,
      map: {}
    };
    const putAutocolsFirstInList = (cols) => {
      if (!cols) {
        return null;
      }
      const colsFiltered = cols.filter((col) => !isColumnGroupAutoCol(col));
      return [...list, ...colsFiltered];
    };
    this.lastOrder = putAutocolsFirstInList(this.lastOrder);
    this.lastPivotOrder = putAutocolsFirstInList(this.lastPivotOrder);
  }
  // on events 'groupDisplayType', 'treeData', 'treeDataDisplayType', 'groupHideOpenParents'
  refreshAll(source) {
    if (!this.isReady()) {
      return;
    }
    this.refreshCols();
    this.visibleColsService.refresh(source);
  }
  setColsVisible(keys, visible = false, source) {
    this.columnApplyStateService.applyColumnState(
      {
        state: keys.map((key) => ({
          colId: typeof key === "string" ? key : key.getColId(),
          hide: !visible
        }))
      },
      source
    );
  }
  setColsPinned(keys, pinned, source) {
    if (!this.cols) {
      return;
    }
    if (_missingOrEmpty(keys)) {
      return;
    }
    if (this.gos.isDomLayout("print")) {
      _warnOnce(`Changing the column pinning status is not allowed with domLayout='print'`);
      return;
    }
    this.columnAnimationService.start();
    let actualPinned;
    if (pinned === true || pinned === "left") {
      actualPinned = "left";
    } else if (pinned === "right") {
      actualPinned = "right";
    } else {
      actualPinned = null;
    }
    const updatedCols = [];
    keys.forEach((key) => {
      if (!key) {
        return;
      }
      const column = this.getCol(key);
      if (!column) {
        return;
      }
      if (column.getPinned() !== actualPinned) {
        column.setPinned(actualPinned);
        updatedCols.push(column);
      }
    });
    if (updatedCols.length) {
      this.visibleColsService.refresh(source);
      this.eventDispatcher.columnPinned(updatedCols, source);
    }
    this.columnAnimationService.finish();
  }
  // called by headerRenderer - when a header is opened or closed
  setColumnGroupOpened(key, newValue, source) {
    let keyAsString;
    if (isProvidedColumnGroup(key)) {
      keyAsString = key.getId();
    } else {
      keyAsString = key || "";
    }
    this.columnGroupStateService.setColumnGroupState([{ groupId: keyAsString, open: newValue }], source);
  }
  getProvidedColGroup(key) {
    let res = null;
    depthFirstOriginalTreeSearch(null, this.cols?.tree, (node) => {
      if (isProvidedColumnGroup(node)) {
        if (node.getId() === key) {
          res = node;
        }
      }
    });
    return res;
  }
  isColGroupLocked(column) {
    const groupLockGroupColumns = this.gos.get("groupLockGroupColumns");
    if (!column.isRowGroupActive() || groupLockGroupColumns === 0) {
      return false;
    }
    if (groupLockGroupColumns === -1) {
      return true;
    }
    const rowGroupCols = this.funcColsService.getRowGroupColumns();
    const colIndex = rowGroupCols.findIndex((groupCol) => groupCol.getColId() === column.getColId());
    return groupLockGroupColumns > colIndex;
  }
  isSuppressAutoCol() {
    const groupDisplayType = this.gos.get("groupDisplayType");
    const isCustomRowGroups = groupDisplayType === "custom";
    if (isCustomRowGroups) {
      return true;
    }
    const treeDataDisplayType = this.gos.get("treeDataDisplayType");
    return treeDataDisplayType === "custom";
  }
  setAutoHeightActive() {
    this.autoHeightActive = this.cols.list.some((col) => col.isAutoHeight());
    if (this.autoHeightActive) {
      this.autoHeightActiveAtLeastOnce = true;
      const supportedRowModel = this.gos.isRowModelType("clientSide") || this.gos.isRowModelType("serverSide");
      if (!supportedRowModel) {
        _warnOnce("autoHeight columns only work with Client Side Row Model and Server Side Row Model.");
      }
    }
  }
  restoreColOrder() {
    const lastOrder = this.showingPivotResult ? this.lastPivotOrder : this.lastOrder;
    if (!lastOrder) {
      return;
    }
    const lastOrderMapped = new Map(lastOrder.map((col, index) => [col, index]));
    const noColsFound = !this.cols.list.some((col) => lastOrderMapped.has(col));
    if (noColsFound) {
      return;
    }
    const colsMap = new Map(this.cols.list.map((col) => [col, true]));
    const lastOrderFiltered = lastOrder.filter((col) => colsMap.has(col));
    const lastOrderFilteredMap = new Map(lastOrderFiltered.map((col) => [col, true]));
    const missingFromLastOrder = this.cols.list.filter((col) => !lastOrderFilteredMap.has(col));
    const res = lastOrderFiltered.slice();
    missingFromLastOrder.forEach((newCol) => {
      let parent = newCol.getOriginalParent();
      if (!parent) {
        res.push(newCol);
        return;
      }
      const siblings = [];
      while (!siblings.length && parent) {
        const leafCols = parent.getLeafColumns();
        leafCols.forEach((leafCol) => {
          const presentInNewCols = res.indexOf(leafCol) >= 0;
          const notYetInSiblings = siblings.indexOf(leafCol) < 0;
          if (presentInNewCols && notYetInSiblings) {
            siblings.push(leafCol);
          }
        });
        parent = parent.getOriginalParent();
      }
      if (!siblings.length) {
        res.push(newCol);
        return;
      }
      const indexes = siblings.map((col) => res.indexOf(col));
      const lastIndex = Math.max(...indexes);
      _insertIntoArray(res, newCol, lastIndex + 1);
    });
    this.cols.list = res;
  }
  orderColsLikeColDefCols() {
    if (!this.colDefCols || !this.cols) {
      return;
    }
    const colsOrdered = this.colDefCols.list.filter((col) => this.cols.list.indexOf(col) >= 0);
    const otherCols = this.cols.list.filter((col) => colsOrdered.indexOf(col) < 0);
    this.cols.list = [...otherCols, ...colsOrdered];
    this.cols.list = this.columnMoveService.placeLockedColumns(this.cols.list);
  }
  sortColsLikeKeys(colIds) {
    if (this.cols == null) {
      return;
    }
    let newOrder = [];
    const processedColIds = {};
    colIds.forEach((colId) => {
      if (processedColIds[colId]) {
        return;
      }
      const col = this.cols.map[colId];
      if (col) {
        newOrder.push(col);
        processedColIds[colId] = true;
      }
    });
    let autoGroupInsertIndex = 0;
    this.cols.list.forEach((col) => {
      const colId = col.getColId();
      const alreadyProcessed = processedColIds[colId] != null;
      if (alreadyProcessed) {
        return;
      }
      const isAutoGroupCol = colId.startsWith(GROUP_AUTO_COLUMN_ID);
      if (isAutoGroupCol) {
        _insertIntoArray(newOrder, col, autoGroupInsertIndex++);
      } else {
        newOrder.push(col);
      }
    });
    newOrder = this.columnMoveService.placeLockedColumns(newOrder);
    if (!this.columnMoveService.doesMovePassMarryChildren(newOrder)) {
      _warnOnce(
        "Applying column order broke a group where columns should be married together. Applying new order has been discarded."
      );
      return;
    }
    this.cols.list = newOrder;
  }
  // returns the provided cols sorted in same order as they appear in this.cols, eg if this.cols
  // contains [a,b,c,d,e] and col passed is [e,a] then the passed cols are sorted into [a,e]
  sortColsLikeCols(cols) {
    if (!cols || cols.length <= 1) {
      return;
    }
    const notAllColsPresent = cols.filter((c) => this.cols.list.indexOf(c) < 0).length > 0;
    if (notAllColsPresent) {
      return;
    }
    cols.sort((a, b) => {
      const indexA = this.cols.list.indexOf(a);
      const indexB = this.cols.list.indexOf(b);
      return indexA - indexB;
    });
  }
  resetColDefIntoCol(column, source) {
    const userColDef = column.getUserProvidedColDef();
    if (!userColDef) {
      return false;
    }
    const newColDef = this.columnFactory.addColumnDefaultAndTypes(userColDef, column.getColId());
    column.setColDef(newColDef, userColDef, source);
    return true;
  }
  queueResizeOperations() {
    this.shouldQueueResizeOperations = true;
  }
  isShouldQueueResizeOperations() {
    return this.shouldQueueResizeOperations;
  }
  processResizeOperations() {
    this.shouldQueueResizeOperations = false;
    this.resizeOperationQueue.forEach((resizeOperation) => resizeOperation());
    this.resizeOperationQueue = [];
  }
  pushResizeOperation(func) {
    this.resizeOperationQueue.push(func);
  }
  moveInCols(movedColumns, toIndex, source) {
    _moveInArray(this.cols?.list, movedColumns, toIndex);
    this.visibleColsService.refresh(source);
  }
  positionLockedCols() {
    this.cols.list = this.columnMoveService.placeLockedColumns(this.cols.list);
  }
  saveColOrder() {
    if (this.showingPivotResult) {
      this.lastPivotOrder = this.cols?.list;
    } else {
      this.lastOrder = this.cols?.list;
    }
  }
  getColumnDefs() {
    if (!this.colDefCols) {
      return;
    }
    const cols = this.colDefCols.list.slice();
    if (this.showingPivotResult) {
      cols.sort((a, b) => this.lastOrder.indexOf(a) - this.lastOrder.indexOf(b));
    } else if (this.lastOrder) {
      cols.sort((a, b) => this.cols.list.indexOf(a) - this.cols.list.indexOf(b));
    }
    const rowGroupColumns = this.funcColsService.getRowGroupColumns();
    const pivotColumns = this.funcColsService.getPivotColumns();
    return this.columnDefFactory.buildColumnDefs(cols, rowGroupColumns, pivotColumns);
  }
  isShowingPivotResult() {
    return this.showingPivotResult;
  }
  // called by clientSideRowModel.refreshModel
  isChangeEventsDispatching() {
    return this.changeEventsDispatching;
  }
  isColSpanActive() {
    return this.colSpanActive;
  }
  // used by Column Tool Panel
  isProvidedColGroupsPresent() {
    return this.colDefCols?.treeDepth > 0;
  }
  setColSpanActive() {
    this.colSpanActive = this.cols.list.some((col) => col.getColDef().colSpan != null);
  }
  isAutoRowHeightActive() {
    return this.autoHeightActive;
  }
  wasAutoRowHeightEverActive() {
    return this.autoHeightActiveAtLeastOnce;
  }
  // + gridPanel -> for resizing the body and setting top margin
  getHeaderRowCount() {
    return this.cols ? this.cols.treeDepth + 1 : -1;
  }
  isReady() {
    return this.ready;
  }
  isPivotMode() {
    return this.pivotMode;
  }
  setPivotMode(pivotMode, source) {
    if (pivotMode === this.pivotMode || !this.isPivotSettingAllowed(this.pivotMode)) {
      return;
    }
    this.pivotMode = pivotMode;
    if (!this.ready) {
      return;
    }
    this.refreshCols();
    this.visibleColsService.refresh(source);
    this.eventDispatcher.pivotModeChanged();
  }
  isPivotSettingAllowed(pivot) {
    if (pivot && this.gos.get("treeData")) {
      _warnOnce("Pivot mode not available with treeData.");
      return false;
    }
    return true;
  }
  // + clientSideRowModel
  isPivotActive() {
    const pivotColumns = this.funcColsService.getPivotColumns();
    return this.pivotMode && !_missingOrEmpty(pivotColumns);
  }
  // called when dataTypes change
  recreateColumnDefs(source) {
    if (!this.cols) {
      return;
    }
    if (this.autoCols) {
      this.autoColService.updateAutoCols(this.autoCols.list, source);
    }
    this.createColsFromColDefs(true, source);
  }
  setColumnDefs(columnDefs, source) {
    const colsPreviouslyExisted = !!this.colDefs;
    this.colDefs = columnDefs;
    this.createColsFromColDefs(colsPreviouslyExisted, source);
  }
  destroy() {
    destroyColumnTree(this.context, this.colDefCols?.tree);
    destroyColumnTree(this.context, this.autoCols?.tree);
    super.destroy();
  }
  getColTree() {
    return this.cols.tree;
  }
  // + columnSelectPanel
  getColDefColTree() {
    return this.colDefCols.tree;
  }
  // + clientSideRowController -> sorting, building quick filter text
  // + headerRenderer -> sorting (clearing icon)
  getColDefCols() {
    return this.colDefCols?.list ? this.colDefCols.list : null;
  }
  // + moveColumnController
  getCols() {
    return this.cols?.list ?? [];
  }
  // returns colDefCols, pivotResultCols and autoCols
  getAllCols() {
    const pivotResultCols = this.pivotResultColsService.getPivotResultCols();
    const pivotResultColsList = pivotResultCols?.list;
    return [].concat(
      ...[this.colDefCols?.list || [], this.autoCols?.list || [], pivotResultColsList || []]
    );
  }
  getColsForKeys(keys) {
    if (!keys) {
      return [];
    }
    const res = keys.map((key) => this.getCol(key)).filter((col) => col != null);
    return res;
  }
  getColDefCol(key) {
    if (!this.colDefCols?.list) {
      return null;
    }
    return this.getColFromCollection(key, this.colDefCols);
  }
  getCol(key) {
    if (key == null) {
      return null;
    }
    return this.getColFromCollection(key, this.cols);
  }
  getColFromCollection(key, cols) {
    if (cols == null) {
      return null;
    }
    const { map, list } = cols;
    if (typeof key == "string" && map[key]) {
      return map[key];
    }
    for (let i = 0; i < list.length; i++) {
      if (columnsMatch(list[i], key)) {
        return list[i];
      }
    }
    return this.getAutoCol(key);
  }
  getAutoCol(key) {
    if (this.autoCols == null)
      return null;
    return this.autoCols.list.find((groupCol) => columnsMatch(groupCol, key)) || null;
  }
  getAutoCols() {
    return this.autoCols?.list || null;
  }
  setColHeaderHeight(col, height) {
    const changed = col.setAutoHeaderHeight(height);
    if (changed) {
      this.eventDispatcher.headerHeight(col);
    }
  }
  getColumnGroupHeaderRowHeight() {
    if (this.isPivotMode()) {
      return this.getPivotGroupHeaderHeight();
    }
    return this.getGroupHeaderHeight();
  }
  getColumnHeaderRowHeight() {
    const defaultHeight = this.isPivotMode() ? this.getPivotHeaderHeight() : this.getHeaderHeight();
    const allDisplayedCols = this.visibleColsService.getAllCols();
    const displayedHeights = allDisplayedCols.filter((col) => col.isAutoHeaderHeight()).map((col) => col.getAutoHeaderHeight() || 0);
    return Math.max(defaultHeight, ...displayedHeights);
  }
  getHeaderHeight() {
    return this.gos.get("headerHeight") ?? this.environment.getDefaultHeaderHeight();
  }
  getFloatingFiltersHeight() {
    return this.gos.get("floatingFiltersHeight") ?? this.getHeaderHeight();
  }
  getGroupHeaderHeight() {
    return this.gos.get("groupHeaderHeight") ?? this.getHeaderHeight();
  }
  getPivotHeaderHeight() {
    return this.gos.get("pivotHeaderHeight") ?? this.getHeaderHeight();
  }
  getPivotGroupHeaderHeight() {
    return this.gos.get("pivotGroupHeaderHeight") ?? this.getGroupHeaderHeight();
  }
  onFirstDataRendered() {
    const autoSizeStrategy = this.gos.get("autoSizeStrategy");
    if (autoSizeStrategy?.type !== "fitCellContents") {
      return;
    }
    const { colIds: columns, skipHeader } = autoSizeStrategy;
    setTimeout(() => {
      if (columns) {
        this.columnAutosizeService.autoSizeCols({
          colKeys: columns,
          skipHeader,
          source: "autosizeColumns"
        });
      } else {
        this.columnAutosizeService.autoSizeAllColumns("autosizeColumns", skipHeader);
      }
    });
  }
  onAutoGroupColumnDefChanged(source) {
    if (this.autoCols) {
      this.autoColService.updateAutoCols(this.autoCols.list, source);
    }
  }
};
function convertSourceType(source) {
  return source === "gridOptionsUpdated" ? "gridOptionsChanged" : source;
}
function updateColsMap(cols) {
  cols.map = {};
  cols.list.forEach((col) => cols.map[col.getId()] = col);
}
function columnsMatch(column, key) {
  const columnMatches = column === key;
  const colDefMatches = column.getColDef() === key;
  const idMatches = column.getColId() == key;
  return columnMatches || colDefMatches || idMatches;
}
function areColIdsEqual(colsA, colsB) {
  return _areEqual(colsA, colsB, (a, b) => a.getColId() === b.getColId());
}

// community-modules/core/src/columns/columnAutosizeService.ts
var ColumnAutosizeService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "columnAutosizeService";
    this.timesDelayed = 0;
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.visibleColsService = beans.visibleColsService;
    this.animationFrameService = beans.animationFrameService;
    this.autoWidthCalculator = beans.autoWidthCalculator;
    this.eventDispatcher = beans.columnEventDispatcher;
    this.ctrlsService = beans.ctrlsService;
    this.renderStatusService = beans.renderStatusService;
  }
  autoSizeCols(params) {
    if (this.columnModel.isShouldQueueResizeOperations()) {
      this.columnModel.pushResizeOperation(() => this.autoSizeCols(params));
      return;
    }
    const { colKeys, skipHeader, skipHeaderGroups, stopAtGroup, source = "api" } = params;
    this.animationFrameService.flushAllFrames();
    if (this.timesDelayed < 5 && this.renderStatusService && !this.renderStatusService.areHeaderCellsRendered()) {
      this.timesDelayed++;
      setTimeout(() => this.autoSizeCols(params));
      return;
    }
    this.timesDelayed = 0;
    const columnsAutosized = [];
    let changesThisTimeAround = -1;
    const shouldSkipHeader = skipHeader != null ? skipHeader : this.gos.get("skipHeaderOnAutoSize");
    const shouldSkipHeaderGroups = skipHeaderGroups != null ? skipHeaderGroups : shouldSkipHeader;
    while (changesThisTimeAround !== 0) {
      changesThisTimeAround = 0;
      const updatedColumns = [];
      colKeys.forEach((key) => {
        if (!key) {
          return;
        }
        const column = this.columnModel.getCol(key);
        if (!column) {
          return;
        }
        if (columnsAutosized.indexOf(column) >= 0) {
          return;
        }
        const preferredWidth = this.autoWidthCalculator.getPreferredWidthForColumn(column, shouldSkipHeader);
        if (preferredWidth > 0) {
          const newWidth = this.normaliseColumnWidth(column, preferredWidth);
          column.setActualWidth(newWidth, source);
          columnsAutosized.push(column);
          changesThisTimeAround++;
        }
        updatedColumns.push(column);
      });
      if (!updatedColumns.length) {
        continue;
      }
      this.visibleColsService.refresh(source);
    }
    if (!shouldSkipHeaderGroups) {
      this.autoSizeColumnGroupsByColumns(colKeys, source, stopAtGroup);
    }
    this.eventDispatcher.columnResized(columnsAutosized, true, "autosizeColumns");
  }
  autoSizeColumn(key, source, skipHeader) {
    if (key) {
      this.autoSizeCols({ colKeys: [key], skipHeader, skipHeaderGroups: true, source });
    }
  }
  autoSizeColumnGroupsByColumns(keys, source, stopAtGroup) {
    const columnGroups = /* @__PURE__ */ new Set();
    const columns = this.columnModel.getColsForKeys(keys);
    columns.forEach((col) => {
      let parent = col.getParent();
      while (parent && parent != stopAtGroup) {
        if (!parent.isPadding()) {
          columnGroups.add(parent);
        }
        parent = parent.getParent();
      }
    });
    let headerGroupCtrl;
    const resizedColumns = [];
    for (const columnGroup of columnGroups) {
      for (const headerContainerCtrl of this.ctrlsService.getHeaderRowContainerCtrls()) {
        headerGroupCtrl = headerContainerCtrl.getHeaderCtrlForColumn(columnGroup);
        if (headerGroupCtrl) {
          break;
        }
      }
      if (headerGroupCtrl) {
        headerGroupCtrl.resizeLeafColumnsToFit(source);
      }
    }
    return resizedColumns;
  }
  autoSizeAllColumns(source, skipHeader) {
    if (this.columnModel.isShouldQueueResizeOperations()) {
      this.columnModel.pushResizeOperation(() => this.autoSizeAllColumns(source, skipHeader));
      return;
    }
    const allDisplayedColumns = this.visibleColsService.getAllCols();
    this.autoSizeCols({ colKeys: allDisplayedColumns, skipHeader, source });
  }
  // returns the width we can set to this col, taking into consideration min and max widths
  normaliseColumnWidth(column, newWidth) {
    const minWidth = column.getMinWidth();
    if (newWidth < minWidth) {
      newWidth = minWidth;
    }
    const maxWidth = column.getMaxWidth();
    if (column.isGreaterThanMax(newWidth)) {
      newWidth = maxWidth;
    }
    return newWidth;
  }
};

// community-modules/core/src/columns/funcColsService.ts
var FuncColsService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "funcColsService";
    this.rowGroupCols = [];
    this.valueCols = [];
    this.pivotCols = [];
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.eventDispatcher = beans.columnEventDispatcher;
    this.aggFuncService = beans.aggFuncService;
    this.visibleColsService = beans.visibleColsService;
  }
  getModifyColumnsNoEventsCallbacks() {
    return {
      addGroupCol: (column) => this.rowGroupCols.push(column),
      removeGroupCol: (column) => _removeFromArray(this.rowGroupCols, column),
      addPivotCol: (column) => this.pivotCols.push(column),
      removePivotCol: (column) => _removeFromArray(this.pivotCols, column),
      addValueCol: (column) => this.valueCols.push(column),
      removeValueCol: (column) => _removeFromArray(this.valueCols, column)
    };
  }
  getSourceColumnsForGroupColumn(groupCol) {
    const sourceColumnId = groupCol.getColDef().showRowGroup;
    if (!sourceColumnId) {
      return null;
    }
    if (sourceColumnId === true) {
      return this.rowGroupCols.slice(0);
    }
    const column = this.columnModel.getColDefCol(sourceColumnId);
    return column ? [column] : null;
  }
  sortRowGroupColumns(compareFn) {
    this.rowGroupCols.sort(compareFn);
  }
  sortPivotColumns(compareFn) {
    this.pivotCols.sort(compareFn);
  }
  // + rowController
  getValueColumns() {
    return this.valueCols ? this.valueCols : [];
  }
  // + rowController
  getPivotColumns() {
    return this.pivotCols ? this.pivotCols : [];
  }
  // + toolPanel
  getRowGroupColumns() {
    return this.rowGroupCols ? this.rowGroupCols : [];
  }
  isRowGroupEmpty() {
    return _missingOrEmpty(this.rowGroupCols);
  }
  setColumnAggFunc(key, aggFunc, source) {
    if (!key) {
      return;
    }
    const column = this.columnModel.getColDefCol(key);
    if (!column) {
      return;
    }
    column.setAggFunc(aggFunc);
    this.eventDispatcher.columnChanged("columnValueChanged", [column], source);
  }
  setRowGroupColumns(colKeys, source) {
    this.setColList(
      colKeys,
      this.rowGroupCols,
      "columnRowGroupChanged",
      true,
      true,
      (added, column) => this.setRowGroupActive(added, column, source),
      source
    );
  }
  setRowGroupActive(active, column, source) {
    if (active === column.isRowGroupActive()) {
      return;
    }
    column.setRowGroupActive(active, source);
    if (active && !this.gos.get("suppressRowGroupHidesColumns")) {
      this.columnModel.setColsVisible([column], false, source);
    }
    if (!active && !this.gos.get("suppressMakeColumnVisibleAfterUnGroup")) {
      this.columnModel.setColsVisible([column], true, source);
    }
  }
  addRowGroupColumns(keys, source) {
    this.updateColList(
      keys,
      this.rowGroupCols,
      true,
      true,
      (column) => this.setRowGroupActive(true, column, source),
      "columnRowGroupChanged",
      source
    );
  }
  removeRowGroupColumns(keys, source) {
    this.updateColList(
      keys,
      this.rowGroupCols,
      false,
      true,
      (column) => this.setRowGroupActive(false, column, source),
      "columnRowGroupChanged",
      source
    );
  }
  addPivotColumns(keys, source) {
    this.updateColList(
      keys,
      this.pivotCols,
      true,
      false,
      (column) => column.setPivotActive(true, source),
      "columnPivotChanged",
      source
    );
  }
  setPivotColumns(colKeys, source) {
    this.setColList(
      colKeys,
      this.pivotCols,
      "columnPivotChanged",
      true,
      false,
      (added, column) => {
        column.setPivotActive(added, source);
      },
      source
    );
  }
  removePivotColumns(keys, source) {
    this.updateColList(
      keys,
      this.pivotCols,
      false,
      false,
      (column) => column.setPivotActive(false, source),
      "columnPivotChanged",
      source
    );
  }
  setValueColumns(colKeys, source) {
    this.setColList(
      colKeys,
      this.valueCols,
      "columnValueChanged",
      false,
      false,
      (added, column) => this.setValueActive(added, column, source),
      source
    );
  }
  setValueActive(active, column, source) {
    if (active === column.isValueActive()) {
      return;
    }
    column.setValueActive(active, source);
    if (active && !column.getAggFunc() && this.aggFuncService) {
      const initialAggFunc = this.aggFuncService.getDefaultAggFunc(column);
      column.setAggFunc(initialAggFunc);
    }
  }
  addValueColumns(keys, source) {
    this.updateColList(
      keys,
      this.valueCols,
      true,
      false,
      (column) => this.setValueActive(true, column, source),
      "columnValueChanged",
      source
    );
  }
  removeValueColumns(keys, source) {
    this.updateColList(
      keys,
      this.valueCols,
      false,
      false,
      (column) => this.setValueActive(false, column, source),
      "columnValueChanged",
      source
    );
  }
  moveRowGroupColumn(fromIndex, toIndex, source) {
    if (this.isRowGroupEmpty()) {
      return;
    }
    const column = this.rowGroupCols[fromIndex];
    const impactedColumns = this.rowGroupCols.slice(fromIndex, toIndex);
    this.rowGroupCols.splice(fromIndex, 1);
    this.rowGroupCols.splice(toIndex, 0, column);
    this.eventDispatcher.rowGroupChanged(impactedColumns, source);
  }
  setColList(colKeys, masterList, eventName, detectOrderChange, autoGroupsNeedBuilding, columnCallback, source) {
    const gridColumns = this.columnModel.getCols();
    if (_missingOrEmpty(gridColumns)) {
      return;
    }
    const changes = /* @__PURE__ */ new Map();
    masterList.forEach((col, idx) => changes.set(col, idx));
    masterList.length = 0;
    if (_exists(colKeys)) {
      colKeys.forEach((key) => {
        const column = this.columnModel.getColDefCol(key);
        if (column) {
          masterList.push(column);
        }
      });
    }
    masterList.forEach((col, idx) => {
      const oldIndex = changes.get(col);
      if (oldIndex === void 0) {
        changes.set(col, 0);
        return;
      }
      if (detectOrderChange && oldIndex !== idx) {
        return;
      }
      changes.delete(col);
    });
    const primaryCols = this.columnModel.getColDefCols();
    (primaryCols || []).forEach((column) => {
      const added = masterList.indexOf(column) >= 0;
      columnCallback(added, column);
    });
    autoGroupsNeedBuilding && this.columnModel.refreshCols();
    this.visibleColsService.refresh(source);
    this.eventDispatcher.columnChanged(eventName, [...changes.keys()], source);
  }
  updateColList(keys, masterList, actionIsAdd, autoGroupsNeedBuilding, columnCallback, eventType, source) {
    if (!keys || _missingOrEmpty(keys)) {
      return;
    }
    let atLeastOne = false;
    keys.forEach((key) => {
      if (!key) {
        return;
      }
      const columnToAdd = this.columnModel.getColDefCol(key);
      if (!columnToAdd) {
        return;
      }
      if (actionIsAdd) {
        if (masterList.indexOf(columnToAdd) >= 0) {
          return;
        }
        masterList.push(columnToAdd);
      } else {
        if (masterList.indexOf(columnToAdd) < 0) {
          return;
        }
        _removeFromArray(masterList, columnToAdd);
      }
      columnCallback(columnToAdd);
      atLeastOne = true;
    });
    if (!atLeastOne) {
      return;
    }
    if (autoGroupsNeedBuilding) {
      this.columnModel.refreshCols();
    }
    this.visibleColsService.refresh(source);
    this.eventDispatcher.genericColumnEvent(eventType, masterList, source);
  }
  extractCols(source, oldProvidedCols) {
    this.extractRowGroupCols(source, oldProvidedCols);
    this.extractPivotCols(source, oldProvidedCols);
    this.extractValueCols(source, oldProvidedCols);
  }
  extractValueCols(source, oldProvidedCols) {
    this.valueCols = this.extractColsCommon(
      oldProvidedCols,
      this.valueCols,
      (col, flag) => col.setValueActive(flag, source),
      // aggFunc doesn't have index variant, cos order of value cols doesn't matter, so always return null
      () => void 0,
      () => void 0,
      // aggFunc is a string, so return it's existence
      (colDef) => {
        const aggFunc = colDef.aggFunc;
        if (aggFunc === null || aggFunc === "") {
          return null;
        }
        if (aggFunc === void 0) {
          return;
        }
        return !!aggFunc;
      },
      (colDef) => {
        return colDef.initialAggFunc != null && colDef.initialAggFunc != "";
      }
    );
    this.valueCols.forEach((col) => {
      const colDef = col.getColDef();
      if (colDef.aggFunc != null && colDef.aggFunc != "") {
        col.setAggFunc(colDef.aggFunc);
      } else {
        if (!col.getAggFunc()) {
          col.setAggFunc(colDef.initialAggFunc);
        }
      }
    });
  }
  extractRowGroupCols(source, oldProvidedCols) {
    this.rowGroupCols = this.extractColsCommon(
      oldProvidedCols,
      this.rowGroupCols,
      (col, flag) => col.setRowGroupActive(flag, source),
      (colDef) => colDef.rowGroupIndex,
      (colDef) => colDef.initialRowGroupIndex,
      (colDef) => colDef.rowGroup,
      (colDef) => colDef.initialRowGroup
    );
  }
  extractPivotCols(source, oldProvidedCols) {
    this.pivotCols = this.extractColsCommon(
      oldProvidedCols,
      this.pivotCols,
      (col, flag) => col.setPivotActive(flag, source),
      (colDef) => colDef.pivotIndex,
      (colDef) => colDef.initialPivotIndex,
      (colDef) => colDef.pivot,
      (colDef) => colDef.initialPivot
    );
  }
  extractColsCommon(oldProvidedCols = [], previousCols = [], setFlagFunc, getIndexFunc, getInitialIndexFunc, getValueFunc, getInitialValueFunc) {
    const colsWithIndex = [];
    const colsWithValue = [];
    const primaryCols = this.columnModel.getColDefCols() || [];
    primaryCols.forEach((col) => {
      const colIsNew = oldProvidedCols.indexOf(col) < 0;
      const colDef = col.getColDef();
      const value = _attrToBoolean(getValueFunc(colDef));
      const initialValue = _attrToBoolean(getInitialValueFunc(colDef));
      const index = _attrToNumber(getIndexFunc(colDef));
      const initialIndex = _attrToNumber(getInitialIndexFunc(colDef));
      let include;
      const valuePresent = value !== void 0;
      const indexPresent = index !== void 0;
      const initialValuePresent = initialValue !== void 0;
      const initialIndexPresent = initialIndex !== void 0;
      if (valuePresent) {
        include = value;
      } else if (indexPresent) {
        if (index === null) {
          include = false;
        } else {
          include = index >= 0;
        }
      } else {
        if (colIsNew) {
          if (initialValuePresent) {
            include = initialValue;
          } else if (initialIndexPresent) {
            include = initialIndex != null && initialIndex >= 0;
          } else {
            include = false;
          }
        } else {
          include = previousCols.indexOf(col) >= 0;
        }
      }
      if (include) {
        const useIndex = colIsNew ? index != null || initialIndex != null : index != null;
        useIndex ? colsWithIndex.push(col) : colsWithValue.push(col);
      }
    });
    const getIndexForCol = (col) => {
      const index = getIndexFunc(col.getColDef());
      const defaultIndex = getInitialIndexFunc(col.getColDef());
      return index != null ? index : defaultIndex;
    };
    colsWithIndex.sort((colA, colB) => {
      const indexA = getIndexForCol(colA);
      const indexB = getIndexForCol(colB);
      if (indexA === indexB) {
        return 0;
      }
      if (indexA < indexB) {
        return -1;
      }
      return 1;
    });
    const res = [].concat(colsWithIndex);
    previousCols.forEach((col) => {
      if (colsWithValue.indexOf(col) >= 0) {
        res.push(col);
      }
    });
    colsWithValue.forEach((col) => {
      if (res.indexOf(col) < 0) {
        res.push(col);
      }
    });
    previousCols.forEach((col) => {
      if (res.indexOf(col) < 0) {
        setFlagFunc(col, false);
      }
    });
    res.forEach((col) => {
      if (previousCols.indexOf(col) < 0) {
        setFlagFunc(col, true);
      }
    });
    return res;
  }
  generateColumnStateForRowGroupAndPivotIndexes(updatedRowGroupColumnState, updatedPivotColumnState) {
    const existingColumnStateUpdates = {};
    const orderColumns = (updatedColumnState, colList, enableProp, initialEnableProp, indexProp, initialIndexProp) => {
      const primaryCols = this.columnModel.getColDefCols();
      if (!colList.length || !primaryCols) {
        return [];
      }
      const updatedColIdArray = Object.keys(updatedColumnState);
      const updatedColIds = new Set(updatedColIdArray);
      const newColIds = new Set(updatedColIdArray);
      const allColIds = new Set(
        colList.map((column) => {
          const colId = column.getColId();
          newColIds.delete(colId);
          return colId;
        }).concat(updatedColIdArray)
      );
      const colIdsInOriginalOrder = [];
      const originalOrderMap = {};
      let orderIndex = 0;
      for (let i = 0; i < primaryCols.length; i++) {
        const colId = primaryCols[i].getColId();
        if (allColIds.has(colId)) {
          colIdsInOriginalOrder.push(colId);
          originalOrderMap[colId] = orderIndex++;
        }
      }
      let index = 1e3;
      let hasAddedNewCols = false;
      let lastIndex = 0;
      const processPrecedingNewCols = (colId) => {
        const originalOrderIndex = originalOrderMap[colId];
        for (let i = lastIndex; i < originalOrderIndex; i++) {
          const newColId = colIdsInOriginalOrder[i];
          if (newColIds.has(newColId)) {
            updatedColumnState[newColId][indexProp] = index++;
            newColIds.delete(newColId);
          }
        }
        lastIndex = originalOrderIndex;
      };
      colList.forEach((column) => {
        const colId = column.getColId();
        if (updatedColIds.has(colId)) {
          processPrecedingNewCols(colId);
          updatedColumnState[colId][indexProp] = index++;
        } else {
          const colDef = column.getColDef();
          const missingIndex = colDef[indexProp] === null || colDef[indexProp] === void 0 && colDef[initialIndexProp] == null;
          if (missingIndex) {
            if (!hasAddedNewCols) {
              const propEnabled = colDef[enableProp] || colDef[enableProp] === void 0 && colDef[initialEnableProp];
              if (propEnabled) {
                processPrecedingNewCols(colId);
              } else {
                newColIds.forEach((newColId) => {
                  updatedColumnState[newColId][indexProp] = index + originalOrderMap[newColId];
                });
                index += colIdsInOriginalOrder.length;
                hasAddedNewCols = true;
              }
            }
            if (!existingColumnStateUpdates[colId]) {
              existingColumnStateUpdates[colId] = { colId };
            }
            existingColumnStateUpdates[colId][indexProp] = index++;
          }
        }
      });
    };
    orderColumns(
      updatedRowGroupColumnState,
      this.rowGroupCols,
      "rowGroup",
      "initialRowGroup",
      "rowGroupIndex",
      "initialRowGroupIndex"
    );
    orderColumns(
      updatedPivotColumnState,
      this.pivotCols,
      "pivot",
      "initialPivot",
      "pivotIndex",
      "initialPivotIndex"
    );
    return Object.values(existingColumnStateUpdates);
  }
};

// community-modules/core/src/columns/columnApplyStateService.ts
var ColumnApplyStateService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "columnApplyStateService";
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.eventDispatcher = beans.columnEventDispatcher;
    this.sortController = beans.sortController;
    this.columnGetStateService = beans.columnGetStateService;
    this.funcColsService = beans.funcColsService;
    this.visibleColsService = beans.visibleColsService;
    this.columnAnimationService = beans.columnAnimationService;
    this.pivotResultColsService = beans.pivotResultColsService;
  }
  applyColumnState(params, source) {
    const providedCols = this.columnModel.getColDefCols() || [];
    if (_missingOrEmpty(providedCols)) {
      return false;
    }
    if (params && params.state && !params.state.forEach) {
      _warnOnce(
        "applyColumnState() - the state attribute should be an array, however an array was not found. Please provide an array of items (one for each col you want to change) for state."
      );
      return false;
    }
    const callbacks = this.funcColsService.getModifyColumnsNoEventsCallbacks();
    const applyStates = (states, existingColumns, getById) => {
      const dispatchEventsFunc = this.compareColumnStatesAndDispatchEvents(source);
      const columnsWithNoState = existingColumns.slice();
      const rowGroupIndexes = {};
      const pivotIndexes = {};
      const autoColStates = [];
      const unmatchedAndAutoStates2 = [];
      let unmatchedCount2 = 0;
      const previousRowGroupCols = this.funcColsService.getRowGroupColumns().slice();
      const previousPivotCols = this.funcColsService.getPivotColumns().slice();
      states.forEach((state) => {
        const colId = state.colId || "";
        const isAutoGroupColumn = colId.startsWith(GROUP_AUTO_COLUMN_ID);
        if (isAutoGroupColumn) {
          autoColStates.push(state);
          unmatchedAndAutoStates2.push(state);
          return;
        }
        const column = getById(colId);
        if (!column) {
          unmatchedAndAutoStates2.push(state);
          unmatchedCount2 += 1;
        } else {
          this.syncColumnWithStateItem(
            column,
            state,
            params.defaultState,
            rowGroupIndexes,
            pivotIndexes,
            false,
            source,
            callbacks
          );
          _removeFromArray(columnsWithNoState, column);
        }
      });
      const applyDefaultsFunc = (col) => this.syncColumnWithStateItem(
        col,
        null,
        params.defaultState,
        rowGroupIndexes,
        pivotIndexes,
        false,
        source,
        callbacks
      );
      columnsWithNoState.forEach(applyDefaultsFunc);
      this.funcColsService.sortRowGroupColumns(
        comparatorByIndex.bind(this, rowGroupIndexes, previousRowGroupCols)
      );
      this.funcColsService.sortPivotColumns(comparatorByIndex.bind(this, pivotIndexes, previousPivotCols));
      this.columnModel.refreshCols();
      const autoCols = this.columnModel.getAutoCols() || [];
      const autoColsCopy = autoCols.slice();
      autoColStates.forEach((stateItem) => {
        const autoCol = this.columnModel.getAutoCol(stateItem.colId);
        _removeFromArray(autoColsCopy, autoCol);
        this.syncColumnWithStateItem(
          autoCol,
          stateItem,
          params.defaultState,
          null,
          null,
          true,
          source,
          callbacks
        );
      });
      autoColsCopy.forEach(applyDefaultsFunc);
      this.orderLiveColsLikeState(params);
      this.visibleColsService.refresh(source);
      this.eventDispatcher.everythingChanged(source);
      dispatchEventsFunc();
      return { unmatchedAndAutoStates: unmatchedAndAutoStates2, unmatchedCount: unmatchedCount2 };
    };
    this.columnAnimationService.start();
    let { unmatchedAndAutoStates, unmatchedCount } = applyStates(
      params.state || [],
      providedCols,
      (id) => this.columnModel.getColDefCol(id)
    );
    if (unmatchedAndAutoStates.length > 0 || _exists(params.defaultState)) {
      const pivotResultCols = this.pivotResultColsService.getPivotResultCols();
      const pivotResultColsList = pivotResultCols?.list;
      unmatchedCount = applyStates(
        unmatchedAndAutoStates,
        pivotResultColsList || [],
        (id) => this.pivotResultColsService.getPivotResultCol(id)
      ).unmatchedCount;
    }
    this.columnAnimationService.finish();
    return unmatchedCount === 0;
  }
  resetColumnState(source) {
    const primaryCols = this.columnModel.getColDefCols();
    if (_missingOrEmpty(primaryCols)) {
      return;
    }
    const primaryColumnTree = this.columnModel.getColDefColTree();
    const primaryColumns = getColumnsFromTree(primaryColumnTree);
    const columnStates = [];
    let letRowGroupIndex = 1e3;
    let letPivotIndex = 1e3;
    let colsToProcess = [];
    const groupAutoCols = this.columnModel.getAutoCols();
    if (groupAutoCols) {
      colsToProcess = colsToProcess.concat(groupAutoCols);
    }
    if (primaryColumns) {
      colsToProcess = colsToProcess.concat(primaryColumns);
    }
    colsToProcess.forEach((column) => {
      const stateItem = this.getColumnStateFromColDef(column);
      if (_missing(stateItem.rowGroupIndex) && stateItem.rowGroup) {
        stateItem.rowGroupIndex = letRowGroupIndex++;
      }
      if (_missing(stateItem.pivotIndex) && stateItem.pivot) {
        stateItem.pivotIndex = letPivotIndex++;
      }
      columnStates.push(stateItem);
    });
    this.applyColumnState({ state: columnStates, applyOrder: true }, source);
  }
  getColumnStateFromColDef(column) {
    const getValueOrNull = (a, b) => a != null ? a : b != null ? b : null;
    const colDef = column.getColDef();
    const sort = getValueOrNull(colDef.sort, colDef.initialSort);
    const sortIndex = getValueOrNull(colDef.sortIndex, colDef.initialSortIndex);
    const hide = getValueOrNull(colDef.hide, colDef.initialHide);
    const pinned = getValueOrNull(colDef.pinned, colDef.initialPinned);
    const width = getValueOrNull(colDef.width, colDef.initialWidth);
    const flex = getValueOrNull(colDef.flex, colDef.initialFlex);
    let rowGroupIndex = getValueOrNull(
      colDef.rowGroupIndex,
      colDef.initialRowGroupIndex
    );
    let rowGroup = getValueOrNull(colDef.rowGroup, colDef.initialRowGroup);
    if (rowGroupIndex == null && (rowGroup == null || rowGroup == false)) {
      rowGroupIndex = null;
      rowGroup = null;
    }
    let pivotIndex = getValueOrNull(colDef.pivotIndex, colDef.initialPivotIndex);
    let pivot = getValueOrNull(colDef.pivot, colDef.initialPivot);
    if (pivotIndex == null && (pivot == null || pivot == false)) {
      pivotIndex = null;
      pivot = null;
    }
    const aggFunc = getValueOrNull(colDef.aggFunc, colDef.initialAggFunc);
    return {
      colId: column.getColId(),
      sort,
      sortIndex,
      hide,
      pinned,
      width,
      flex,
      rowGroup,
      rowGroupIndex,
      pivot,
      pivotIndex,
      aggFunc
    };
  }
  syncColumnWithStateItem(column, stateItem, defaultState, rowGroupIndexes, pivotIndexes, autoCol, source, callbacks) {
    if (!column) {
      return;
    }
    const getValue2 = (key1, key2) => {
      const obj = {
        value1: void 0,
        value2: void 0
      };
      let calculated = false;
      if (stateItem) {
        if (stateItem[key1] !== void 0) {
          obj.value1 = stateItem[key1];
          calculated = true;
        }
        if (_exists(key2) && stateItem[key2] !== void 0) {
          obj.value2 = stateItem[key2];
          calculated = true;
        }
      }
      if (!calculated && defaultState) {
        if (defaultState[key1] !== void 0) {
          obj.value1 = defaultState[key1];
        }
        if (_exists(key2) && defaultState[key2] !== void 0) {
          obj.value2 = defaultState[key2];
        }
      }
      return obj;
    };
    const hide = getValue2("hide").value1;
    if (hide !== void 0) {
      column.setVisible(!hide, source);
    }
    const pinned = getValue2("pinned").value1;
    if (pinned !== void 0) {
      column.setPinned(pinned);
    }
    const minColWidth = column.getColDef().minWidth ?? DEFAULT_COLUMN_MIN_WIDTH;
    const flex = getValue2("flex").value1;
    if (flex !== void 0) {
      column.setFlex(flex);
    }
    if (flex == null) {
      const width = getValue2("width").value1;
      if (width != null) {
        if (minColWidth != null && width >= minColWidth) {
          column.setActualWidth(width, source);
        }
      }
    }
    const sort = getValue2("sort").value1;
    if (sort !== void 0) {
      if (sort === "desc" || sort === "asc") {
        column.setSort(sort, source);
      } else {
        column.setSort(void 0, source);
      }
    }
    const sortIndex = getValue2("sortIndex").value1;
    if (sortIndex !== void 0) {
      column.setSortIndex(sortIndex);
    }
    if (autoCol || !column.isPrimary()) {
      return;
    }
    const aggFunc = getValue2("aggFunc").value1;
    if (aggFunc !== void 0) {
      if (typeof aggFunc === "string") {
        column.setAggFunc(aggFunc);
        if (!column.isValueActive()) {
          column.setValueActive(true, source);
          callbacks.addValueCol(column);
        }
      } else {
        if (_exists(aggFunc)) {
          _warnOnce(
            "stateItem.aggFunc must be a string. if using your own aggregation functions, register the functions first before using them in get/set state. This is because it is intended for the column state to be stored and retrieved as simple JSON."
          );
        }
        if (column.isValueActive()) {
          column.setValueActive(false, source);
          callbacks.removeValueCol(column);
        }
      }
    }
    const { value1: rowGroup, value2: rowGroupIndex } = getValue2("rowGroup", "rowGroupIndex");
    if (rowGroup !== void 0 || rowGroupIndex !== void 0) {
      if (typeof rowGroupIndex === "number" || rowGroup) {
        if (!column.isRowGroupActive()) {
          column.setRowGroupActive(true, source);
          callbacks.addGroupCol(column);
        }
        if (rowGroupIndexes && typeof rowGroupIndex === "number") {
          rowGroupIndexes[column.getId()] = rowGroupIndex;
        }
      } else {
        if (column.isRowGroupActive()) {
          column.setRowGroupActive(false, source);
          callbacks.removeGroupCol(column);
        }
      }
    }
    const { value1: pivot, value2: pivotIndex } = getValue2("pivot", "pivotIndex");
    if (pivot !== void 0 || pivotIndex !== void 0) {
      if (typeof pivotIndex === "number" || pivot) {
        if (!column.isPivotActive()) {
          column.setPivotActive(true, source);
          callbacks.addPivotCol(column);
        }
        if (pivotIndexes && typeof pivotIndex === "number") {
          pivotIndexes[column.getId()] = pivotIndex;
        }
      } else {
        if (column.isPivotActive()) {
          column.setPivotActive(false, source);
          callbacks.removePivotCol(column);
        }
      }
    }
  }
  orderLiveColsLikeState(params) {
    if (!params.applyOrder || !params.state) {
      return;
    }
    const colIds = [];
    params.state.forEach((item) => {
      if (item.colId != null) {
        colIds.push(item.colId);
      }
    });
    this.columnModel.sortColsLikeKeys(colIds);
  }
  // calculates what events to fire between column state changes. gets used when:
  // a) apply column state
  // b) apply new column definitions (so changes from old cols)
  compareColumnStatesAndDispatchEvents(source) {
    const startState = {
      rowGroupColumns: this.funcColsService.getRowGroupColumns().slice(),
      pivotColumns: this.funcColsService.getPivotColumns().slice(),
      valueColumns: this.funcColsService.getValueColumns().slice()
    };
    const columnStateBefore = this.columnGetStateService.getColumnState();
    const columnStateBeforeMap = {};
    columnStateBefore.forEach((col) => {
      columnStateBeforeMap[col.colId] = col;
    });
    return () => {
      const colsForState = this.columnModel.getAllCols();
      const dispatchWhenListsDifferent = (eventType, colsBefore, colsAfter, idMapper) => {
        const beforeList = colsBefore.map(idMapper);
        const afterList = colsAfter.map(idMapper);
        const unchanged = _areEqual(beforeList, afterList);
        if (unchanged) {
          return;
        }
        const changes = new Set(colsBefore);
        colsAfter.forEach((id) => {
          if (!changes.delete(id)) {
            changes.add(id);
          }
        });
        const changesArr = [...changes];
        const event = {
          type: eventType,
          columns: changesArr,
          column: changesArr.length === 1 ? changesArr[0] : null,
          source
        };
        this.eventService.dispatchEvent(event);
      };
      const getChangedColumns = (changedPredicate) => {
        const changedColumns2 = [];
        colsForState.forEach((column) => {
          const colStateBefore = columnStateBeforeMap[column.getColId()];
          if (colStateBefore && changedPredicate(colStateBefore, column)) {
            changedColumns2.push(column);
          }
        });
        return changedColumns2;
      };
      const columnIdMapper = (c) => c.getColId();
      dispatchWhenListsDifferent(
        "columnRowGroupChanged",
        startState.rowGroupColumns,
        this.funcColsService.getRowGroupColumns(),
        columnIdMapper
      );
      dispatchWhenListsDifferent(
        "columnPivotChanged",
        startState.pivotColumns,
        this.funcColsService.getPivotColumns(),
        columnIdMapper
      );
      const valueChangePredicate = (cs, c) => {
        const oldActive = cs.aggFunc != null;
        const activeChanged = oldActive != c.isValueActive();
        const aggFuncChanged = oldActive && cs.aggFunc != c.getAggFunc();
        return activeChanged || aggFuncChanged;
      };
      const changedValues = getChangedColumns(valueChangePredicate);
      if (changedValues.length > 0) {
        this.eventDispatcher.columnChanged("columnValueChanged", changedValues, source);
      }
      const resizeChangePredicate = (cs, c) => cs.width != c.getActualWidth();
      this.eventDispatcher.columnResized(getChangedColumns(resizeChangePredicate), true, source);
      const pinnedChangePredicate = (cs, c) => cs.pinned != c.getPinned();
      this.eventDispatcher.columnPinned(getChangedColumns(pinnedChangePredicate), source);
      const visibilityChangePredicate = (cs, c) => cs.hide == c.isVisible();
      this.eventDispatcher.columnVisible(getChangedColumns(visibilityChangePredicate), source);
      const sortChangePredicate = (cs, c) => cs.sort != c.getSort() || cs.sortIndex != c.getSortIndex();
      const changedColumns = getChangedColumns(sortChangePredicate);
      if (changedColumns.length > 0) {
        this.sortController.dispatchSortChangedEvents(source, changedColumns);
      }
      this.normaliseColumnMovedEventForColumnState(columnStateBefore, source);
    };
  }
  normaliseColumnMovedEventForColumnState(colStateBefore, source) {
    const colStateAfter = this.columnGetStateService.getColumnState();
    const colStateAfterMapped = {};
    colStateAfter.forEach((s) => colStateAfterMapped[s.colId] = s);
    const colsIntersectIds = {};
    colStateBefore.forEach((s) => {
      if (colStateAfterMapped[s.colId]) {
        colsIntersectIds[s.colId] = true;
      }
    });
    const beforeFiltered = colStateBefore.filter((c) => colsIntersectIds[c.colId]);
    const afterFiltered = colStateAfter.filter((c) => colsIntersectIds[c.colId]);
    const movedColumns = [];
    afterFiltered.forEach((csAfter, index) => {
      const csBefore = beforeFiltered && beforeFiltered[index];
      if (csBefore && csBefore.colId !== csAfter.colId) {
        const gridCol = this.columnModel.getCol(csBefore.colId);
        if (gridCol) {
          movedColumns.push(gridCol);
        }
      }
    });
    if (!movedColumns.length) {
      return;
    }
    this.eventDispatcher.columnMoved({ movedColumns, source, finished: true });
  }
};
var comparatorByIndex = (indexes, oldList, colA, colB) => {
  const indexA = indexes[colA.getId()];
  const indexB = indexes[colB.getId()];
  const aHasIndex = indexA != null;
  const bHasIndex = indexB != null;
  if (aHasIndex && bHasIndex) {
    return indexA - indexB;
  }
  if (aHasIndex) {
    return -1;
  }
  if (bHasIndex) {
    return 1;
  }
  const oldIndexA = oldList.indexOf(colA);
  const oldIndexB = oldList.indexOf(colB);
  const aHasOldIndex = oldIndexA >= 0;
  const bHasOldIndex = oldIndexB >= 0;
  if (aHasOldIndex && bHasOldIndex) {
    return oldIndexA - oldIndexB;
  }
  if (aHasOldIndex) {
    return -1;
  }
  return 1;
};

// community-modules/core/src/columns/columnMoveService.ts
var ColumnMoveService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "columnMoveService";
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.columnAnimationService = beans.columnAnimationService;
    this.eventDispatcher = beans.columnEventDispatcher;
  }
  moveColumnByIndex(fromIndex, toIndex, source) {
    const gridColumns = this.columnModel.getCols();
    if (!gridColumns) {
      return;
    }
    const column = gridColumns[fromIndex];
    this.moveColumns([column], toIndex, source);
  }
  moveColumns(columnsToMoveKeys, toIndex, source, finished = true) {
    const gridColumns = this.columnModel.getCols();
    if (!gridColumns) {
      return;
    }
    this.columnAnimationService.start();
    if (toIndex > gridColumns.length - columnsToMoveKeys.length) {
      _warnOnce("tried to insert columns in invalid location, toIndex = ", toIndex);
      _warnOnce("remember that you should not count the moving columns when calculating the new index");
      return;
    }
    const movedColumns = this.columnModel.getColsForKeys(columnsToMoveKeys);
    const failedRules = !this.doesMovePassRules(movedColumns, toIndex);
    if (failedRules) {
      return;
    }
    this.columnModel.moveInCols(movedColumns, toIndex, source);
    this.eventDispatcher.columnMoved({ movedColumns, source, toIndex, finished });
    this.columnAnimationService.finish();
  }
  doesMovePassRules(columnsToMove, toIndex) {
    const proposedColumnOrder = this.getProposedColumnOrder(columnsToMove, toIndex);
    return this.doesOrderPassRules(proposedColumnOrder);
  }
  doesOrderPassRules(gridOrder) {
    if (!this.doesMovePassMarryChildren(gridOrder)) {
      return false;
    }
    if (!this.doesMovePassLockedPositions(gridOrder)) {
      return false;
    }
    return true;
  }
  getProposedColumnOrder(columnsToMove, toIndex) {
    const gridColumns = this.columnModel.getCols();
    const proposedColumnOrder = gridColumns.slice();
    _moveInArray(proposedColumnOrder, columnsToMove, toIndex);
    return proposedColumnOrder;
  }
  doesMovePassLockedPositions(proposedColumnOrder) {
    let lastPlacement = 0;
    let rulePassed = true;
    const lockPositionToPlacement = (position) => {
      if (!position) {
        return 1;
      }
      if (position === true) {
        return 0;
      }
      return position === "left" ? 0 : 2;
    };
    proposedColumnOrder.forEach((col) => {
      const placement = lockPositionToPlacement(col.getColDef().lockPosition);
      if (placement < lastPlacement) {
        rulePassed = false;
      }
      lastPlacement = placement;
    });
    return rulePassed;
  }
  doesMovePassMarryChildren(allColumnsCopy) {
    let rulePassed = true;
    const gridBalancedTree = this.columnModel.getColTree();
    depthFirstOriginalTreeSearch(null, gridBalancedTree, (child) => {
      if (!isProvidedColumnGroup(child)) {
        return;
      }
      const columnGroup = child;
      const colGroupDef = columnGroup.getColGroupDef();
      const marryChildren = colGroupDef && colGroupDef.marryChildren;
      if (!marryChildren) {
        return;
      }
      const newIndexes = [];
      columnGroup.getLeafColumns().forEach((col) => {
        const newColIndex = allColumnsCopy.indexOf(col);
        newIndexes.push(newColIndex);
      });
      const maxIndex = Math.max.apply(Math, newIndexes);
      const minIndex = Math.min.apply(Math, newIndexes);
      const spread = maxIndex - minIndex;
      const maxSpread = columnGroup.getLeafColumns().length - 1;
      if (spread > maxSpread) {
        rulePassed = false;
      }
    });
    return rulePassed;
  }
  placeLockedColumns(cols) {
    const left = [];
    const normal = [];
    const right = [];
    cols.forEach((col) => {
      const position = col.getColDef().lockPosition;
      if (position === "right") {
        right.push(col);
      } else if (position === "left" || position === true) {
        left.push(col);
      } else {
        normal.push(col);
      }
    });
    const isRtl = this.gos.get("enableRtl");
    if (isRtl) {
      return [...right, ...normal, ...left];
    }
    return [...left, ...normal, ...right];
  }
};

// community-modules/core/src/utils/string.ts
var reUnescapedHtml = /[&<>"']/g;
var HTML_ESCAPES = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "'": "&#39;"
};
function _utf8_encode(s) {
  const stringFromCharCode = String.fromCharCode;
  function ucs2decode(string) {
    const output = [];
    if (!string) {
      return [];
    }
    const len = string.length;
    let counter = 0;
    let value;
    let extra;
    while (counter < len) {
      value = string.charCodeAt(counter++);
      if (value >= 55296 && value <= 56319 && counter < len) {
        extra = string.charCodeAt(counter++);
        if ((extra & 64512) == 56320) {
          output.push(((value & 1023) << 10) + (extra & 1023) + 65536);
        } else {
          output.push(value);
          counter--;
        }
      } else {
        output.push(value);
      }
    }
    return output;
  }
  function checkScalarValue(point) {
    if (point >= 55296 && point <= 57343) {
      throw Error("Lone surrogate U+" + point.toString(16).toUpperCase() + " is not a scalar value");
    }
  }
  function createByte(point, shift) {
    return stringFromCharCode(point >> shift & 63 | 128);
  }
  function encodeCodePoint(point) {
    if ((point & 4294967168) == 0) {
      return stringFromCharCode(point);
    }
    let symbol = "";
    if ((point & 4294965248) == 0) {
      symbol = stringFromCharCode(point >> 6 & 31 | 192);
    } else if ((point & 4294901760) == 0) {
      checkScalarValue(point);
      symbol = stringFromCharCode(point >> 12 & 15 | 224);
      symbol += createByte(point, 6);
    } else if ((point & 4292870144) == 0) {
      symbol = stringFromCharCode(point >> 18 & 7 | 240);
      symbol += createByte(point, 12);
      symbol += createByte(point, 6);
    }
    symbol += stringFromCharCode(point & 63 | 128);
    return symbol;
  }
  const codePoints = ucs2decode(s);
  const length = codePoints.length;
  let index = -1;
  let codePoint;
  let byteString = "";
  while (++index < length) {
    codePoint = codePoints[index];
    byteString += encodeCodePoint(codePoint);
  }
  return byteString;
}
function _capitalise(str) {
  return str[0].toUpperCase() + str.substring(1).toLowerCase();
}
function _escapeString(toEscape, skipEscapingHtmlChars) {
  if (toEscape == null) {
    return null;
  }
  const stringResult = toEscape.toString().toString();
  if (skipEscapingHtmlChars) {
    return stringResult;
  }
  return stringResult.replace(reUnescapedHtml, (chr) => HTML_ESCAPES[chr]);
}
function _camelCaseToHumanText(camelCase) {
  if (!camelCase || camelCase == null) {
    return null;
  }
  const rex = /([a-z])([A-Z])/g;
  const rexCaps = /([A-Z]+)([A-Z])([a-z])/g;
  const words = camelCase.replace(rex, "$1 $2").replace(rexCaps, "$1 $2$3").replace(/\./g, " ").split(" ");
  return words.map((word) => word.substring(0, 1).toUpperCase() + (word.length > 1 ? word.substring(1, word.length) : "")).join(" ");
}
function _camelCaseToHyphenated(camelCase) {
  return camelCase.replace(/[A-Z]/g, (s) => `-${s.toLocaleLowerCase()}`);
}

// community-modules/core/src/columns/columnNameService.ts
var ColumnNameService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "columnNameService";
  }
  wireBeans(beans) {
    this.expressionService = beans.expressionService;
    this.funcColsService = beans.funcColsService;
    this.columnModel = beans.columnModel;
  }
  getDisplayNameForColumn(column, location, includeAggFunc = false) {
    if (!column) {
      return null;
    }
    const headerName = this.getHeaderName(column.getColDef(), column, null, null, location);
    if (includeAggFunc) {
      return this.wrapHeaderNameWithAggFunc(column, headerName);
    }
    return headerName;
  }
  getDisplayNameForProvidedColumnGroup(columnGroup, providedColumnGroup, location) {
    const colGroupDef = providedColumnGroup ? providedColumnGroup.getColGroupDef() : null;
    if (colGroupDef) {
      return this.getHeaderName(colGroupDef, null, columnGroup, providedColumnGroup, location);
    }
    return null;
  }
  getDisplayNameForColumnGroup(columnGroup, location) {
    return this.getDisplayNameForProvidedColumnGroup(columnGroup, columnGroup.getProvidedColumnGroup(), location);
  }
  // location is where the column is going to appear, ie who is calling us
  getHeaderName(colDef, column, columnGroup, providedColumnGroup, location) {
    const headerValueGetter = colDef.headerValueGetter;
    if (headerValueGetter) {
      const params = this.gos.addGridCommonParams({
        colDef,
        column,
        columnGroup,
        providedColumnGroup,
        location
      });
      if (typeof headerValueGetter === "function") {
        return headerValueGetter(params);
      } else if (typeof headerValueGetter === "string") {
        return this.expressionService.evaluate(headerValueGetter, params);
      }
      _warnOnce("headerValueGetter must be a function or a string");
      return "";
    } else if (colDef.headerName != null) {
      return colDef.headerName;
    } else if (colDef.field) {
      return _camelCaseToHumanText(colDef.field);
    }
    return "";
  }
  wrapHeaderNameWithAggFunc(column, headerName) {
    if (this.gos.get("suppressAggFuncInHeader")) {
      return headerName;
    }
    const pivotValueColumn = column.getColDef().pivotValueColumn;
    const pivotActiveOnThisColumn = _exists(pivotValueColumn);
    let aggFunc = null;
    let aggFuncFound;
    if (pivotActiveOnThisColumn) {
      const valueColumns = this.funcColsService.getValueColumns();
      const isCollapsedHeaderEnabled = this.gos.get("removePivotHeaderRowWhenSingleValueColumn") && valueColumns.length === 1;
      const isTotalColumn = column.getColDef().pivotTotalColumnIds !== void 0;
      if (isCollapsedHeaderEnabled && !isTotalColumn) {
        return headerName;
      }
      aggFunc = pivotValueColumn ? pivotValueColumn.getAggFunc() : null;
      aggFuncFound = true;
    } else {
      const measureActive = column.isValueActive();
      const aggregationPresent = this.columnModel.isPivotMode() || !this.funcColsService.isRowGroupEmpty();
      if (measureActive && aggregationPresent) {
        aggFunc = column.getAggFunc();
        aggFuncFound = true;
      } else {
        aggFuncFound = false;
      }
    }
    if (aggFuncFound) {
      const aggFuncString = typeof aggFunc === "string" ? aggFunc : "func";
      const localeTextFunc = this.localeService.getLocaleTextFunc();
      const aggFuncStringTranslated = localeTextFunc(aggFuncString, aggFuncString);
      return `${aggFuncStringTranslated}(${headerName})`;
    }
    return headerName;
  }
};

// community-modules/core/src/columns/pivotResultColsService.ts
var PivotResultColsService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "pivotResultColsService";
  }
  wireBeans(beans) {
    this.context = beans.context;
    this.columnModel = beans.columnModel;
    this.columnFactory = beans.columnFactory;
    this.visibleColsService = beans.visibleColsService;
  }
  destroy() {
    destroyColumnTree(this.context, this.pivotResultCols?.tree);
    super.destroy();
  }
  isPivotResultColsPresent() {
    return this.pivotResultCols != null;
  }
  lookupPivotResultCol(pivotKeys, valueColKey) {
    if (this.pivotResultCols == null) {
      return null;
    }
    const valueColumnToFind = this.columnModel.getColDefCol(valueColKey);
    let foundColumn = null;
    this.pivotResultCols.list.forEach((column) => {
      const thisPivotKeys = column.getColDef().pivotKeys;
      const pivotValueColumn = column.getColDef().pivotValueColumn;
      const pivotKeyMatches = _areEqual(thisPivotKeys, pivotKeys);
      const pivotValueMatches = pivotValueColumn === valueColumnToFind;
      if (pivotKeyMatches && pivotValueMatches) {
        foundColumn = column;
      }
    });
    return foundColumn;
  }
  getPivotResultCols() {
    return this.pivotResultCols;
  }
  getPivotResultCol(key) {
    if (!this.pivotResultCols) {
      return null;
    }
    return this.columnModel.getColFromCollection(key, this.pivotResultCols);
  }
  setPivotResultCols(colDefs, source) {
    if (!this.columnModel.isReady()) {
      return;
    }
    if (colDefs == null && this.pivotResultCols == null) {
      return;
    }
    if (colDefs) {
      this.processPivotResultColDef(colDefs);
      const balancedTreeResult = this.columnFactory.createColumnTree(
        colDefs,
        false,
        this.pivotResultCols?.tree || this.previousPivotResultCols || void 0,
        source
      );
      destroyColumnTree(this.context, this.pivotResultCols?.tree, balancedTreeResult.columnTree);
      const tree = balancedTreeResult.columnTree;
      const treeDepth = balancedTreeResult.treeDept;
      const list = getColumnsFromTree(tree);
      const map = {};
      this.pivotResultCols = { tree, treeDepth, list, map };
      this.pivotResultCols.list.forEach((col) => this.pivotResultCols.map[col.getId()] = col);
      this.previousPivotResultCols = null;
    } else {
      this.previousPivotResultCols = this.pivotResultCols ? this.pivotResultCols.tree : null;
      this.pivotResultCols = null;
    }
    this.columnModel.refreshCols();
    this.visibleColsService.refresh(source);
  }
  processPivotResultColDef(colDefs) {
    const columnCallback = this.gos.get("processPivotResultColDef");
    const groupCallback = this.gos.get("processPivotResultColGroupDef");
    if (!columnCallback && !groupCallback) {
      return void 0;
    }
    const searchForColDefs = (colDefs2) => {
      colDefs2.forEach((abstractColDef) => {
        const isGroup = _exists(abstractColDef.children);
        if (isGroup) {
          const colGroupDef = abstractColDef;
          if (groupCallback) {
            groupCallback(colGroupDef);
          }
          searchForColDefs(colGroupDef.children);
        } else {
          const colDef = abstractColDef;
          if (columnCallback) {
            columnCallback(colDef);
          }
        }
      });
    };
    if (colDefs) {
      searchForColDefs(colDefs);
    }
  }
};

// community-modules/core/src/columns/columnSizeService.ts
var ColumnSizeService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "columnSizeService";
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.columnViewportService = beans.columnViewportService;
    this.eventDispatcher = beans.columnEventDispatcher;
    this.visibleColsService = beans.visibleColsService;
    this.ctrlsService = beans.ctrlsService;
  }
  setColumnWidths(columnWidths, shiftKey, finished, source) {
    const sets = [];
    columnWidths.forEach((columnWidth) => {
      const col = this.columnModel.getColDefCol(columnWidth.key) || this.columnModel.getCol(columnWidth.key);
      if (!col) {
        return;
      }
      sets.push({
        width: columnWidth.newWidth,
        ratios: [1],
        columns: [col]
      });
      const defaultIsShift = this.gos.get("colResizeDefault") === "shift";
      if (defaultIsShift) {
        shiftKey = !shiftKey;
      }
      if (shiftKey) {
        const otherCol = this.visibleColsService.getColAfter(col);
        if (!otherCol) {
          return;
        }
        const widthDiff = col.getActualWidth() - columnWidth.newWidth;
        const otherColWidth = otherCol.getActualWidth() + widthDiff;
        sets.push({
          width: otherColWidth,
          ratios: [1],
          columns: [otherCol]
        });
      }
    });
    if (sets.length === 0) {
      return;
    }
    this.resizeColumnSets({
      resizeSets: sets,
      finished,
      source
    });
  }
  // method takes sets of columns and resizes them. either all sets will be resized, or nothing
  // be resized. this is used for example when user tries to resize a group and holds shift key,
  // then both the current group (grows), and the adjacent group (shrinks), will get resized,
  // so that's two sets for this method.
  resizeColumnSets(params) {
    const { resizeSets, finished, source } = params;
    const passMinMaxCheck = !resizeSets || resizeSets.every((columnResizeSet) => this.checkMinAndMaxWidthsForSet(columnResizeSet));
    if (!passMinMaxCheck) {
      if (finished) {
        const columns = resizeSets && resizeSets.length > 0 ? resizeSets[0].columns : null;
        this.eventDispatcher.columnResized(columns, finished, source);
      }
      return;
    }
    const changedCols = [];
    const allResizedCols = [];
    resizeSets.forEach((set) => {
      const { width, columns, ratios } = set;
      const newWidths = {};
      const finishedCols = {};
      columns.forEach((col) => allResizedCols.push(col));
      let finishedColsGrew = true;
      let loopCount = 0;
      while (finishedColsGrew) {
        loopCount++;
        if (loopCount > 1e3) {
          _errorOnce("infinite loop in resizeColumnSets");
          break;
        }
        finishedColsGrew = false;
        const subsetCols = [];
        let subsetRatioTotal = 0;
        let pixelsToDistribute = width;
        columns.forEach((col, index) => {
          const thisColFinished = finishedCols[col.getId()];
          if (thisColFinished) {
            pixelsToDistribute -= newWidths[col.getId()];
          } else {
            subsetCols.push(col);
            const ratioThisCol = ratios[index];
            subsetRatioTotal += ratioThisCol;
          }
        });
        const ratioScale = 1 / subsetRatioTotal;
        subsetCols.forEach((col, index) => {
          const lastCol = index === subsetCols.length - 1;
          let colNewWidth;
          if (lastCol) {
            colNewWidth = pixelsToDistribute;
          } else {
            colNewWidth = Math.round(ratios[index] * width * ratioScale);
            pixelsToDistribute -= colNewWidth;
          }
          const minWidth = col.getMinWidth();
          const maxWidth = col.getMaxWidth();
          if (colNewWidth < minWidth) {
            colNewWidth = minWidth;
            finishedCols[col.getId()] = true;
            finishedColsGrew = true;
          } else if (maxWidth > 0 && colNewWidth > maxWidth) {
            colNewWidth = maxWidth;
            finishedCols[col.getId()] = true;
            finishedColsGrew = true;
          }
          newWidths[col.getId()] = colNewWidth;
        });
      }
      columns.forEach((col) => {
        const newWidth = newWidths[col.getId()];
        const actualWidth = col.getActualWidth();
        if (actualWidth !== newWidth) {
          col.setActualWidth(newWidth, source);
          changedCols.push(col);
        }
      });
    });
    const atLeastOneColChanged = changedCols.length > 0;
    let flexedCols = [];
    if (atLeastOneColChanged) {
      flexedCols = this.refreshFlexedColumns({ resizingCols: allResizedCols, skipSetLeft: true });
      this.visibleColsService.setLeftValues(source);
      this.visibleColsService.updateBodyWidths();
      this.columnViewportService.checkViewportColumns();
    }
    const colsForEvent = allResizedCols.concat(flexedCols);
    if (atLeastOneColChanged || finished) {
      this.eventDispatcher.columnResized(colsForEvent, finished, source, flexedCols);
    }
  }
  checkMinAndMaxWidthsForSet(columnResizeSet) {
    const { columns, width } = columnResizeSet;
    let minWidthAccumulated = 0;
    let maxWidthAccumulated = 0;
    let maxWidthActive = true;
    columns.forEach((col) => {
      const minWidth = col.getMinWidth();
      minWidthAccumulated += minWidth || 0;
      const maxWidth = col.getMaxWidth();
      if (maxWidth > 0) {
        maxWidthAccumulated += maxWidth;
      } else {
        maxWidthActive = false;
      }
    });
    const minWidthPasses = width >= minWidthAccumulated;
    const maxWidthPasses = !maxWidthActive || width <= maxWidthAccumulated;
    return minWidthPasses && maxWidthPasses;
  }
  refreshFlexedColumns(params = {}) {
    const source = params.source ? params.source : "flex";
    if (params.viewportWidth != null) {
      this.flexViewportWidth = params.viewportWidth;
    }
    if (!this.flexViewportWidth) {
      return [];
    }
    const displayedCenterCols = this.visibleColsService.getCenterCols();
    let flexAfterDisplayIndex = -1;
    if (params.resizingCols) {
      const allResizingCols = new Set(params.resizingCols);
      for (let i = displayedCenterCols.length - 1; i >= 0; i--) {
        if (allResizingCols.has(displayedCenterCols[i])) {
          flexAfterDisplayIndex = i;
          break;
        }
      }
    }
    let knownColumnsWidth = 0;
    let flexingColumns = [];
    let minimumFlexedWidth = 0;
    let totalFlex = 0;
    for (let i = 0; i < displayedCenterCols.length; i++) {
      const isFlex = displayedCenterCols[i].getFlex() && i > flexAfterDisplayIndex;
      if (isFlex) {
        flexingColumns.push(displayedCenterCols[i]);
        totalFlex += displayedCenterCols[i].getFlex();
        minimumFlexedWidth += displayedCenterCols[i].getMinWidth();
      } else {
        knownColumnsWidth += displayedCenterCols[i].getActualWidth();
      }
    }
    if (!flexingColumns.length) {
      return [];
    }
    let changedColumns = [];
    if (knownColumnsWidth + minimumFlexedWidth > this.flexViewportWidth) {
      flexingColumns.forEach((col) => col.setActualWidth(col.getMinWidth(), source));
      changedColumns = flexingColumns;
      flexingColumns = [];
    }
    const flexingColumnSizes = [];
    let spaceForFlexingColumns;
    outer:
      while (true) {
        spaceForFlexingColumns = this.flexViewportWidth - knownColumnsWidth;
        const spacePerFlex = spaceForFlexingColumns / totalFlex;
        for (let i = 0; i < flexingColumns.length; i++) {
          const col = flexingColumns[i];
          const widthByFlexRule = spacePerFlex * col.getFlex();
          let constrainedWidth = 0;
          const minWidth = col.getMinWidth();
          const maxWidth = col.getMaxWidth();
          if (widthByFlexRule < minWidth) {
            constrainedWidth = minWidth;
          } else if (widthByFlexRule > maxWidth) {
            constrainedWidth = maxWidth;
          }
          if (constrainedWidth) {
            col.setActualWidth(constrainedWidth, source);
            _removeFromUnorderedArray(flexingColumns, col);
            totalFlex -= col.getFlex();
            changedColumns.push(col);
            knownColumnsWidth += col.getActualWidth();
            continue outer;
          }
          flexingColumnSizes[i] = Math.round(widthByFlexRule);
        }
        break;
      }
    let remainingSpace = spaceForFlexingColumns;
    flexingColumns.forEach((col, i) => {
      col.setActualWidth(Math.min(flexingColumnSizes[i], remainingSpace), source);
      changedColumns.push(col);
      remainingSpace -= flexingColumnSizes[i];
    });
    if (!params.skipSetLeft) {
      this.visibleColsService.setLeftValues(source);
    }
    if (params.updateBodyWidths) {
      this.visibleColsService.updateBodyWidths();
    }
    if (params.fireResizedEvent) {
      this.eventDispatcher.columnResized(changedColumns, true, source, flexingColumns);
    }
    return flexingColumns;
  }
  // called from api
  sizeColumnsToFit(gridWidth, source = "sizeColumnsToFit", silent, params) {
    if (this.columnModel.isShouldQueueResizeOperations()) {
      this.columnModel.pushResizeOperation(() => this.sizeColumnsToFit(gridWidth, source, silent, params));
      return;
    }
    const limitsMap = {};
    if (params) {
      params?.columnLimits?.forEach(({ key, ...dimensions }) => {
        limitsMap[typeof key === "string" ? key : key.getColId()] = dimensions;
      });
    }
    const allDisplayedColumns = this.visibleColsService.getAllCols();
    const doColumnsAlreadyFit = gridWidth === getWidthOfColsInList(allDisplayedColumns);
    if (gridWidth <= 0 || !allDisplayedColumns.length || doColumnsAlreadyFit) {
      return;
    }
    const colsToSpread = [];
    const colsToNotSpread = [];
    allDisplayedColumns.forEach((column) => {
      if (column.getColDef().suppressSizeToFit === true) {
        colsToNotSpread.push(column);
      } else {
        colsToSpread.push(column);
      }
    });
    const colsToDispatchEventFor = colsToSpread.slice(0);
    let finishedResizing = false;
    const moveToNotSpread = (column) => {
      _removeFromArray(colsToSpread, column);
      colsToNotSpread.push(column);
    };
    colsToSpread.forEach((column) => {
      column.resetActualWidth(source);
      const widthOverride = limitsMap?.[column.getId()];
      const minOverride = widthOverride?.minWidth ?? params?.defaultMinWidth;
      const maxOverride = widthOverride?.maxWidth ?? params?.defaultMaxWidth;
      const colWidth = column.getActualWidth();
      if (typeof minOverride === "number" && colWidth < minOverride) {
        column.setActualWidth(minOverride, source, true);
      } else if (typeof maxOverride === "number" && colWidth > maxOverride) {
        column.setActualWidth(maxOverride, source, true);
      }
    });
    while (!finishedResizing) {
      finishedResizing = true;
      const availablePixels = gridWidth - getWidthOfColsInList(colsToNotSpread);
      if (availablePixels <= 0) {
        colsToSpread.forEach((column) => {
          const widthOverride = limitsMap?.[column.getId()]?.minWidth ?? params?.defaultMinWidth;
          if (typeof widthOverride === "number") {
            column.setActualWidth(widthOverride, source, true);
            return;
          }
          column.setMinimum(source);
        });
      } else {
        const scale = availablePixels / getWidthOfColsInList(colsToSpread);
        let pixelsForLastCol = availablePixels;
        for (let i = colsToSpread.length - 1; i >= 0; i--) {
          const column = colsToSpread[i];
          const widthOverride = limitsMap?.[column.getId()];
          const minOverride = widthOverride?.minWidth ?? params?.defaultMinWidth;
          const maxOverride = widthOverride?.maxWidth ?? params?.defaultMaxWidth;
          const colMinWidth = column.getMinWidth();
          const colMaxWidth = column.getMaxWidth();
          const minWidth = typeof minOverride === "number" && minOverride > colMinWidth ? minOverride : colMinWidth;
          const maxWidth = typeof maxOverride === "number" && maxOverride < colMaxWidth ? maxOverride : colMaxWidth;
          let newWidth = Math.round(column.getActualWidth() * scale);
          if (newWidth < minWidth) {
            newWidth = minWidth;
            moveToNotSpread(column);
            finishedResizing = false;
          } else if (newWidth > maxWidth) {
            newWidth = maxWidth;
            moveToNotSpread(column);
            finishedResizing = false;
          } else if (i === 0) {
            newWidth = pixelsForLastCol;
          }
          column.setActualWidth(newWidth, source, true);
          pixelsForLastCol -= newWidth;
        }
      }
    }
    colsToDispatchEventFor.forEach((col) => {
      col.fireColumnWidthChangedEvent(source);
    });
    this.visibleColsService.setLeftValues(source);
    this.visibleColsService.updateBodyWidths();
    if (silent) {
      return;
    }
    this.eventDispatcher.columnResized(colsToDispatchEventFor, true, source);
  }
  applyAutosizeStrategy() {
    const autoSizeStrategy = this.gos.get("autoSizeStrategy");
    if (!autoSizeStrategy) {
      return;
    }
    const { type } = autoSizeStrategy;
    setTimeout(() => {
      if (type === "fitGridWidth") {
        const { columnLimits: propColumnLimits, defaultMinWidth, defaultMaxWidth } = autoSizeStrategy;
        const columnLimits = propColumnLimits?.map(({ colId: key, minWidth, maxWidth }) => ({
          key,
          minWidth,
          maxWidth
        }));
        this.ctrlsService.getGridBodyCtrl().sizeColumnsToFit({
          defaultMinWidth,
          defaultMaxWidth,
          columnLimits
        });
      } else if (type === "fitProvidedWidth") {
        this.sizeColumnsToFit(autoSizeStrategy.width, "sizeColumnsToFit");
      }
    });
  }
};

// community-modules/core/src/entities/agColumnGroup.ts
function createUniqueColumnGroupId(groupId, instanceId) {
  return groupId + "_" + instanceId;
}
function isColumnGroup(col) {
  return col instanceof AgColumnGroup;
}
var AgColumnGroup = class extends BeanStub {
  constructor(providedColumnGroup, groupId, partId, pinned) {
    super();
    this.isColumn = false;
    // depends on the open/closed state of the group, only displaying columns are stored here
    this.displayedChildren = [];
    this.parent = null;
    this.groupId = groupId;
    this.partId = partId;
    this.providedColumnGroup = providedColumnGroup;
    this.pinned = pinned;
  }
  // as the user is adding and removing columns, the groups are recalculated.
  // this reset clears out all children, ready for children to be added again
  reset() {
    this.parent = null;
    this.children = null;
    this.displayedChildren = null;
  }
  getParent() {
    return this.parent;
  }
  setParent(parent) {
    this.parent = parent;
  }
  getUniqueId() {
    return createUniqueColumnGroupId(this.groupId, this.partId);
  }
  isEmptyGroup() {
    return this.displayedChildren.length === 0;
  }
  isMoving() {
    const allLeafColumns = this.getProvidedColumnGroup().getLeafColumns();
    if (!allLeafColumns || allLeafColumns.length === 0) {
      return false;
    }
    return allLeafColumns.every((col) => col.isMoving());
  }
  checkLeft() {
    this.displayedChildren.forEach((child) => {
      if (isColumnGroup(child)) {
        child.checkLeft();
      }
    });
    if (this.displayedChildren.length > 0) {
      if (this.gos.get("enableRtl")) {
        const lastChild = _last(this.displayedChildren);
        const lastChildLeft = lastChild.getLeft();
        this.setLeft(lastChildLeft);
      } else {
        const firstChildLeft = this.displayedChildren[0].getLeft();
        this.setLeft(firstChildLeft);
      }
    } else {
      this.setLeft(null);
    }
  }
  getLeft() {
    return this.left;
  }
  getOldLeft() {
    return this.oldLeft;
  }
  setLeft(left) {
    this.oldLeft = this.left;
    if (this.left !== left) {
      this.left = left;
      this.dispatchLocalEvent({ type: "leftChanged" });
    }
  }
  getPinned() {
    return this.pinned;
  }
  getGroupId() {
    return this.groupId;
  }
  getPartId() {
    return this.partId;
  }
  getActualWidth() {
    let groupActualWidth = 0;
    if (this.displayedChildren) {
      this.displayedChildren.forEach((child) => {
        groupActualWidth += child.getActualWidth();
      });
    }
    return groupActualWidth;
  }
  isResizable() {
    if (!this.displayedChildren) {
      return false;
    }
    let result = false;
    this.displayedChildren.forEach((child) => {
      if (child.isResizable()) {
        result = true;
      }
    });
    return result;
  }
  getMinWidth() {
    let result = 0;
    this.displayedChildren.forEach((groupChild) => {
      result += groupChild.getMinWidth();
    });
    return result;
  }
  addChild(child) {
    if (!this.children) {
      this.children = [];
    }
    this.children.push(child);
  }
  getDisplayedChildren() {
    return this.displayedChildren;
  }
  getLeafColumns() {
    const result = [];
    this.addLeafColumns(result);
    return result;
  }
  getDisplayedLeafColumns() {
    const result = [];
    this.addDisplayedLeafColumns(result);
    return result;
  }
  getDefinition() {
    return this.providedColumnGroup.getColGroupDef();
  }
  getColGroupDef() {
    return this.providedColumnGroup.getColGroupDef();
  }
  isPadding() {
    return this.providedColumnGroup.isPadding();
  }
  isExpandable() {
    return this.providedColumnGroup.isExpandable();
  }
  isExpanded() {
    return this.providedColumnGroup.isExpanded();
  }
  setExpanded(expanded) {
    this.providedColumnGroup.setExpanded(expanded);
  }
  addDisplayedLeafColumns(leafColumns) {
    this.displayedChildren.forEach((child) => {
      if (isColumn(child)) {
        leafColumns.push(child);
      } else if (isColumnGroup(child)) {
        child.addDisplayedLeafColumns(leafColumns);
      }
    });
  }
  addLeafColumns(leafColumns) {
    this.children.forEach((child) => {
      if (isColumn(child)) {
        leafColumns.push(child);
      } else if (isColumnGroup(child)) {
        child.addLeafColumns(leafColumns);
      }
    });
  }
  getChildren() {
    return this.children;
  }
  getColumnGroupShow() {
    return this.providedColumnGroup.getColumnGroupShow();
  }
  getProvidedColumnGroup() {
    return this.providedColumnGroup;
  }
  getPaddingLevel() {
    const parent = this.getParent();
    if (!this.isPadding() || !parent || !parent.isPadding()) {
      return 0;
    }
    return 1 + parent.getPaddingLevel();
  }
  calculateDisplayedColumns() {
    this.displayedChildren = [];
    let parentWithExpansion = this;
    while (parentWithExpansion != null && parentWithExpansion.isPadding()) {
      parentWithExpansion = parentWithExpansion.getParent();
    }
    const isExpandable = parentWithExpansion ? parentWithExpansion.getProvidedColumnGroup().isExpandable() : false;
    if (!isExpandable) {
      this.displayedChildren = this.children;
      this.dispatchLocalEvent({ type: "displayedChildrenChanged" });
      return;
    }
    this.children.forEach((child) => {
      const emptyGroup = isColumnGroup(child) && (!child.displayedChildren || !child.displayedChildren.length);
      if (emptyGroup) {
        return;
      }
      const headerGroupShow = child.getColumnGroupShow();
      switch (headerGroupShow) {
        case "open":
          if (parentWithExpansion.getProvidedColumnGroup().isExpanded()) {
            this.displayedChildren.push(child);
          }
          break;
        case "closed":
          if (!parentWithExpansion.getProvidedColumnGroup().isExpanded()) {
            this.displayedChildren.push(child);
          }
          break;
        default:
          this.displayedChildren.push(child);
          break;
      }
    });
    this.dispatchLocalEvent({ type: "displayedChildrenChanged" });
  }
};

// community-modules/core/src/columns/groupInstanceIdCreator.ts
var GroupInstanceIdCreator = class {
  constructor() {
    // this map contains keys to numbers, so we remember what the last call was
    this.existingIds = {};
  }
  getInstanceIdForKey(key) {
    const lastResult = this.existingIds[key];
    let result;
    if (typeof lastResult !== "number") {
      result = 0;
    } else {
      result = lastResult + 1;
    }
    this.existingIds[key] = result;
    return result;
  }
};

// community-modules/core/src/columns/visibleColsService.ts
var VisibleColsService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "visibleColsService";
    // for fast lookup, to see if a column or group is still visible
    this.colsAndGroupsMap = {};
    // leave level columns of the displayed trees
    this.columnsLeft = [];
    this.columnsRight = [];
    this.columnsCenter = [];
    // all three lists above combined
    this.columns = [];
    this.bodyWidth = 0;
    this.leftWidth = 0;
    this.rightWidth = 0;
    this.bodyWidthDirty = true;
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.columnSizeService = beans.columnSizeService;
    this.columnViewportService = beans.columnViewportService;
    this.eventDispatcher = beans.columnEventDispatcher;
  }
  refresh(source, skipTreeBuild = false) {
    if (!skipTreeBuild) {
      this.buildTrees();
    }
    this.updateOpenClosedVisibilityInColumnGroups();
    this.columnsLeft = pickDisplayedCols(this.treeLeft);
    this.columnsCenter = pickDisplayedCols(this.treeCenter);
    this.columnsRight = pickDisplayedCols(this.treeRight);
    this.joinColsAriaOrder();
    this.joinCols();
    this.setLeftValues(source);
    this.autoHeightCols = this.columns.filter((col) => col.isAutoHeight());
    this.columnSizeService.refreshFlexedColumns();
    this.updateBodyWidths();
    this.columnViewportService.checkViewportColumns(false);
    this.setFirstRightAndLastLeftPinned(source);
    this.eventDispatcher.visibleCols();
  }
  // after setColumnWidth or updateGroupsAndPresentedCols
  updateBodyWidths() {
    const newBodyWidth = getWidthOfColsInList(this.columnsCenter);
    const newLeftWidth = getWidthOfColsInList(this.columnsLeft);
    const newRightWidth = getWidthOfColsInList(this.columnsRight);
    this.bodyWidthDirty = this.bodyWidth !== newBodyWidth;
    const atLeastOneChanged = this.bodyWidth !== newBodyWidth || this.leftWidth !== newLeftWidth || this.rightWidth !== newRightWidth;
    if (atLeastOneChanged) {
      this.bodyWidth = newBodyWidth;
      this.leftWidth = newLeftWidth;
      this.rightWidth = newRightWidth;
      const evt = {
        type: "columnContainerWidthChanged"
      };
      this.eventService.dispatchEvent(evt);
      const event = {
        type: "displayedColumnsWidthChanged"
      };
      this.eventService.dispatchEvent(event);
    }
  }
  // sets the left pixel position of each column
  setLeftValues(source) {
    this.setLeftValuesOfCols(source);
    this.setLeftValuesOfGroups();
  }
  setFirstRightAndLastLeftPinned(source) {
    let lastLeft;
    let firstRight;
    if (this.gos.get("enableRtl")) {
      lastLeft = this.columnsLeft ? this.columnsLeft[0] : null;
      firstRight = this.columnsRight ? _last(this.columnsRight) : null;
    } else {
      lastLeft = this.columnsLeft ? _last(this.columnsLeft) : null;
      firstRight = this.columnsRight ? this.columnsRight[0] : null;
    }
    this.columnModel.getCols().forEach((col) => {
      col.setLastLeftPinned(col === lastLeft, source);
      col.setFirstRightPinned(col === firstRight, source);
    });
  }
  buildTrees() {
    const cols = this.columnModel.getColsToShow();
    const leftCols = cols.filter((col) => col.getPinned() == "left");
    const rightCols = cols.filter((col) => col.getPinned() == "right");
    const centerCols = cols.filter((col) => col.getPinned() != "left" && col.getPinned() != "right");
    const idCreator = new GroupInstanceIdCreator();
    this.treeLeft = this.createGroups({
      columns: leftCols,
      idCreator,
      pinned: "left",
      oldDisplayedGroups: this.treeLeft
    });
    this.treeRight = this.createGroups({
      columns: rightCols,
      idCreator,
      pinned: "right",
      oldDisplayedGroups: this.treeRight
    });
    this.treeCenter = this.createGroups({
      columns: centerCols,
      idCreator,
      pinned: null,
      oldDisplayedGroups: this.treeCenter
    });
    this.updateColsAndGroupsMap();
  }
  clear() {
    this.columnsLeft = [];
    this.columnsRight = [];
    this.columnsCenter = [];
    this.columns = [];
    this.ariaOrderColumns = [];
  }
  joinColsAriaOrder() {
    const allColumns = this.columnModel.getCols();
    const pinnedLeft = [];
    const center = [];
    const pinnedRight = [];
    for (const col of allColumns) {
      const pinned = col.getPinned();
      if (!pinned) {
        center.push(col);
      } else if (pinned === true || pinned === "left") {
        pinnedLeft.push(col);
      } else {
        pinnedRight.push(col);
      }
    }
    this.ariaOrderColumns = pinnedLeft.concat(center).concat(pinnedRight);
  }
  getAriaColIndex(colOrGroup) {
    let col;
    if (isColumnGroup(colOrGroup)) {
      col = colOrGroup.getLeafColumns()[0];
    } else {
      col = colOrGroup;
    }
    return this.ariaOrderColumns.indexOf(col) + 1;
  }
  getAllAutoHeightCols() {
    return this.autoHeightCols;
  }
  setLeftValuesOfGroups() {
    [this.treeLeft, this.treeRight, this.treeCenter].forEach((columns) => {
      columns.forEach((column) => {
        if (isColumnGroup(column)) {
          const columnGroup = column;
          columnGroup.checkLeft();
        }
      });
    });
  }
  setLeftValuesOfCols(source) {
    const primaryCols = this.columnModel.getColDefCols();
    if (!primaryCols) {
      return;
    }
    const allColumns = this.columnModel.getCols().slice(0);
    const doingRtl = this.gos.get("enableRtl");
    [this.columnsLeft, this.columnsRight, this.columnsCenter].forEach((columns) => {
      if (doingRtl) {
        let left = getWidthOfColsInList(columns);
        columns.forEach((column) => {
          left -= column.getActualWidth();
          column.setLeft(left, source);
        });
      } else {
        let left = 0;
        columns.forEach((column) => {
          column.setLeft(left, source);
          left += column.getActualWidth();
        });
      }
      _removeAllFromUnorderedArray(allColumns, columns);
    });
    allColumns.forEach((column) => {
      column.setLeft(null, source);
    });
  }
  joinCols() {
    if (this.gos.get("enableRtl")) {
      this.columns = this.columnsRight.concat(this.columnsCenter).concat(this.columnsLeft);
    } else {
      this.columns = this.columnsLeft.concat(this.columnsCenter).concat(this.columnsRight);
    }
  }
  getColsCenter() {
    return this.columnsCenter;
  }
  getAllTrees() {
    if (this.treeLeft && this.treeRight && this.treeCenter) {
      return this.treeLeft.concat(this.treeCenter).concat(this.treeRight);
    }
    return null;
  }
  // + headerRenderer -> setting pinned body width
  getTreeLeft() {
    return this.treeLeft;
  }
  // + headerRenderer -> setting pinned body width
  getTreeRight() {
    return this.treeRight;
  }
  // + headerRenderer -> setting pinned body width
  getTreeCenter() {
    return this.treeCenter;
  }
  // + csvCreator
  getAllCols() {
    return this.columns;
  }
  // gridPanel -> ensureColumnVisible
  isColDisplayed(column) {
    return this.getAllCols().indexOf(column) >= 0;
  }
  getLeftColsForRow(rowNode) {
    const colSpanActive = this.columnModel.isColSpanActive();
    if (!colSpanActive) {
      return this.columnsLeft;
    }
    return this.getColsForRow(rowNode, this.columnsLeft);
  }
  getRightColsForRow(rowNode) {
    const colSpanActive = this.columnModel.isColSpanActive();
    if (!colSpanActive) {
      return this.columnsRight;
    }
    return this.getColsForRow(rowNode, this.columnsRight);
  }
  getColsForRow(rowNode, displayedColumns, filterCallback, emptySpaceBeforeColumn) {
    const result = [];
    let lastConsideredCol = null;
    for (let i = 0; i < displayedColumns.length; i++) {
      const col = displayedColumns[i];
      const maxAllowedColSpan = displayedColumns.length - i;
      const colSpan = Math.min(col.getColSpan(rowNode), maxAllowedColSpan);
      const columnsToCheckFilter = [col];
      if (colSpan > 1) {
        const colsToRemove = colSpan - 1;
        for (let j = 1; j <= colsToRemove; j++) {
          columnsToCheckFilter.push(displayedColumns[i + j]);
        }
        i += colsToRemove;
      }
      let filterPasses;
      if (filterCallback) {
        filterPasses = false;
        columnsToCheckFilter.forEach((colForFilter) => {
          if (filterCallback(colForFilter)) {
            filterPasses = true;
          }
        });
      } else {
        filterPasses = true;
      }
      if (filterPasses) {
        if (result.length === 0 && lastConsideredCol) {
          const gapBeforeColumn = emptySpaceBeforeColumn ? emptySpaceBeforeColumn(col) : false;
          if (gapBeforeColumn) {
            result.push(lastConsideredCol);
          }
        }
        result.push(col);
      }
      lastConsideredCol = col;
    }
    return result;
  }
  // used by:
  // + angularGrid -> for setting body width
  // + rowController -> setting main row widths (when inserting and resizing)
  // need to cache this
  getBodyContainerWidth() {
    return this.bodyWidth;
  }
  getContainerWidth(pinned) {
    switch (pinned) {
      case "left":
        return this.leftWidth;
      case "right":
        return this.rightWidth;
      default:
        return this.bodyWidth;
    }
  }
  // + rowController -> while inserting rows
  getCenterCols() {
    return this.columnsCenter;
  }
  // + rowController -> while inserting rows
  getLeftCols() {
    return this.columnsLeft;
  }
  getRightCols() {
    return this.columnsRight;
  }
  getColBefore(col) {
    const allDisplayedColumns = this.getAllCols();
    const oldIndex = allDisplayedColumns.indexOf(col);
    if (oldIndex > 0) {
      return allDisplayedColumns[oldIndex - 1];
    }
    return null;
  }
  getGroupAtDirection(columnGroup, direction) {
    const requiredLevel = columnGroup.getProvidedColumnGroup().getLevel() + columnGroup.getPaddingLevel();
    const colGroupLeafColumns = columnGroup.getDisplayedLeafColumns();
    const col = direction === "After" ? _last(colGroupLeafColumns) : colGroupLeafColumns[0];
    const getDisplayColMethod = `getCol${direction}`;
    while (true) {
      const column = this[getDisplayColMethod](col);
      if (!column) {
        return null;
      }
      const groupPointer = this.getColGroupAtLevel(column, requiredLevel);
      if (groupPointer !== columnGroup) {
        return groupPointer;
      }
    }
  }
  getColGroupAtLevel(column, level) {
    let groupPointer = column.getParent();
    let originalGroupLevel;
    let groupPointerLevel;
    while (true) {
      const groupPointerProvidedColumnGroup = groupPointer.getProvidedColumnGroup();
      originalGroupLevel = groupPointerProvidedColumnGroup.getLevel();
      groupPointerLevel = groupPointer.getPaddingLevel();
      if (originalGroupLevel + groupPointerLevel <= level) {
        break;
      }
      groupPointer = groupPointer.getParent();
    }
    return groupPointer;
  }
  isPinningLeft() {
    return this.columnsLeft.length > 0;
  }
  isPinningRight() {
    return this.columnsRight.length > 0;
  }
  updateColsAndGroupsMap() {
    this.colsAndGroupsMap = {};
    const func = (child) => {
      this.colsAndGroupsMap[child.getUniqueId()] = child;
    };
    depthFirstAllColumnTreeSearch(this.treeCenter, false, func);
    depthFirstAllColumnTreeSearch(this.treeLeft, false, func);
    depthFirstAllColumnTreeSearch(this.treeRight, false, func);
  }
  isVisible(item) {
    const fromMap = this.colsAndGroupsMap[item.getUniqueId()];
    return fromMap === item;
  }
  updateOpenClosedVisibilityInColumnGroups() {
    const allColumnGroups = this.getAllTrees();
    depthFirstAllColumnTreeSearch(allColumnGroups, false, (child) => {
      if (isColumnGroup(child)) {
        child.calculateDisplayedColumns();
      }
    });
  }
  getFirstColumn() {
    const isRtl = this.gos.get("enableRtl");
    const queryOrder = [
      "getLeftCols",
      "getCenterCols",
      "getRightCols"
    ];
    if (isRtl) {
      queryOrder.reverse();
    }
    for (let i = 0; i < queryOrder.length; i++) {
      const container = this[queryOrder[i]]();
      if (container.length) {
        return isRtl ? _last(container) : container[0];
      }
    }
    return null;
  }
  // returns the group with matching colId and instanceId. If instanceId is missing,
  // matches only on the colId.
  getColumnGroup(colId, partId) {
    if (!colId) {
      return null;
    }
    if (isColumnGroup(colId)) {
      return colId;
    }
    const allColumnGroups = this.getAllTrees();
    const checkPartId = typeof partId === "number";
    let result = null;
    depthFirstAllColumnTreeSearch(allColumnGroups, false, (child) => {
      if (isColumnGroup(child)) {
        const columnGroup = child;
        let matched;
        if (checkPartId) {
          matched = colId === columnGroup.getGroupId() && partId === columnGroup.getPartId();
        } else {
          matched = colId === columnGroup.getGroupId();
        }
        if (matched) {
          result = columnGroup;
        }
      }
    });
    return result;
  }
  // used by:
  // + rowRenderer -> for navigation
  getColAfter(col) {
    const allDisplayedColumns = this.getAllCols();
    const oldIndex = allDisplayedColumns.indexOf(col);
    if (oldIndex < allDisplayedColumns.length - 1) {
      return allDisplayedColumns[oldIndex + 1];
    }
    return null;
  }
  isBodyWidthDirty() {
    return this.bodyWidthDirty;
  }
  setBodyWidthDirty() {
    this.bodyWidthDirty = true;
  }
  // used by:
  // + angularGrid -> setting pinned body width
  // note: this should be cached
  getColsLeftWidth() {
    return getWidthOfColsInList(this.columnsLeft);
  }
  // note: this should be cached
  getDisplayedColumnsRightWidth() {
    return getWidthOfColsInList(this.columnsRight);
  }
  isColAtEdge(col, edge) {
    const allColumns = this.getAllCols();
    if (!allColumns.length) {
      return false;
    }
    const isFirst = edge === "first";
    let columnToCompare;
    if (isColumnGroup(col)) {
      const leafColumns = col.getDisplayedLeafColumns();
      if (!leafColumns.length) {
        return false;
      }
      columnToCompare = isFirst ? leafColumns[0] : _last(leafColumns);
    } else {
      columnToCompare = col;
    }
    return (isFirst ? allColumns[0] : _last(allColumns)) === columnToCompare;
  }
  createGroups(params) {
    const { columns, idCreator, pinned, oldDisplayedGroups, isStandaloneStructure } = params;
    const oldColumnsMapped = this.mapOldGroupsById(oldDisplayedGroups);
    const topLevelResultCols = [];
    let groupsOrColsAtCurrentLevel = columns;
    while (groupsOrColsAtCurrentLevel.length) {
      const currentlyIterating = groupsOrColsAtCurrentLevel;
      groupsOrColsAtCurrentLevel = [];
      let lastGroupedColIdx = 0;
      const createGroupToIndex = (to) => {
        const from = lastGroupedColIdx;
        lastGroupedColIdx = to;
        const previousNode = currentlyIterating[from];
        const previousNodeProvided = isColumnGroup(previousNode) ? previousNode.getProvidedColumnGroup() : previousNode;
        const previousNodeParent = previousNodeProvided.getOriginalParent();
        if (previousNodeParent == null) {
          for (let i = from; i < to; i++) {
            topLevelResultCols.push(currentlyIterating[i]);
          }
          return;
        }
        const newGroup = this.createColGroup(
          previousNodeParent,
          idCreator,
          oldColumnsMapped,
          pinned,
          isStandaloneStructure
        );
        for (let i = from; i < to; i++) {
          newGroup.addChild(currentlyIterating[i]);
        }
        groupsOrColsAtCurrentLevel.push(newGroup);
      };
      for (let i = 1; i < currentlyIterating.length; i++) {
        const thisNode = currentlyIterating[i];
        const thisNodeProvided = isColumnGroup(thisNode) ? thisNode.getProvidedColumnGroup() : thisNode;
        const thisNodeParent = thisNodeProvided.getOriginalParent();
        const previousNode = currentlyIterating[lastGroupedColIdx];
        const previousNodeProvided = isColumnGroup(previousNode) ? previousNode.getProvidedColumnGroup() : previousNode;
        const previousNodeParent = previousNodeProvided.getOriginalParent();
        if (thisNodeParent !== previousNodeParent) {
          createGroupToIndex(i);
        }
      }
      if (lastGroupedColIdx < currentlyIterating.length) {
        createGroupToIndex(currentlyIterating.length);
      }
    }
    if (!isStandaloneStructure) {
      this.setupParentsIntoCols(topLevelResultCols, null);
    }
    return topLevelResultCols;
  }
  createColGroup(providedGroup, groupInstanceIdCreator, oldColumnsMapped, pinned, isStandaloneStructure) {
    const groupId = providedGroup.getGroupId();
    const instanceId = groupInstanceIdCreator.getInstanceIdForKey(groupId);
    const uniqueId = createUniqueColumnGroupId(groupId, instanceId);
    let columnGroup = oldColumnsMapped[uniqueId];
    if (columnGroup && columnGroup.getProvidedColumnGroup() !== providedGroup) {
      columnGroup = null;
    }
    if (_exists(columnGroup)) {
      columnGroup.reset();
    } else {
      columnGroup = new AgColumnGroup(providedGroup, groupId, instanceId, pinned);
      if (!isStandaloneStructure) {
        this.createBean(columnGroup);
      }
    }
    return columnGroup;
  }
  // returns back a 2d map of ColumnGroup as follows: groupId -> instanceId -> ColumnGroup
  mapOldGroupsById(displayedGroups) {
    const result = {};
    const recursive = (columnsOrGroups) => {
      columnsOrGroups.forEach((columnOrGroup) => {
        if (isColumnGroup(columnOrGroup)) {
          const columnGroup = columnOrGroup;
          result[columnOrGroup.getUniqueId()] = columnGroup;
          recursive(columnGroup.getChildren());
        }
      });
    };
    if (displayedGroups) {
      recursive(displayedGroups);
    }
    return result;
  }
  setupParentsIntoCols(columnsOrGroups, parent) {
    columnsOrGroups.forEach((columnsOrGroup) => {
      columnsOrGroup.setParent(parent);
      if (isColumnGroup(columnsOrGroup)) {
        const columnGroup = columnsOrGroup;
        this.setupParentsIntoCols(columnGroup.getChildren(), columnGroup);
      }
    });
  }
};
function depthFirstAllColumnTreeSearch(tree, useDisplayedChildren, callback) {
  if (!tree) {
    return;
  }
  for (let i = 0; i < tree.length; i++) {
    const child = tree[i];
    if (isColumnGroup(child)) {
      const childTree = useDisplayedChildren ? child.getDisplayedChildren() : child.getChildren();
      depthFirstAllColumnTreeSearch(childTree, useDisplayedChildren, callback);
    }
    callback(child);
  }
}
function pickDisplayedCols(tree) {
  const res = [];
  depthFirstAllColumnTreeSearch(tree, true, (child) => {
    if (isColumn(child)) {
      res.push(child);
    }
  });
  return res;
}

// community-modules/core/src/eventTypes.ts
var PUBLIC_EVENTS = [
  "columnEverythingChanged",
  "newColumnsLoaded",
  "columnPivotModeChanged",
  "pivotMaxColumnsExceeded",
  "columnRowGroupChanged",
  "expandOrCollapseAll",
  "columnPivotChanged",
  "gridColumnsChanged",
  "columnValueChanged",
  "columnMoved",
  "columnVisible",
  "columnPinned",
  "columnGroupOpened",
  "columnResized",
  "displayedColumnsChanged",
  "virtualColumnsChanged",
  "columnHeaderMouseOver",
  "columnHeaderMouseLeave",
  "columnHeaderClicked",
  "columnHeaderContextMenu",
  "asyncTransactionsFlushed",
  "rowGroupOpened",
  "rowDataUpdated",
  "pinnedRowDataChanged",
  "rangeSelectionChanged",
  "chartCreated",
  "chartRangeSelectionChanged",
  "chartOptionsChanged",
  "chartDestroyed",
  "toolPanelVisibleChanged",
  "toolPanelSizeChanged",
  "modelUpdated",
  "cutStart",
  "cutEnd",
  "pasteStart",
  "pasteEnd",
  "fillStart",
  "fillEnd",
  "rangeDeleteStart",
  "rangeDeleteEnd",
  "undoStarted",
  "undoEnded",
  "redoStarted",
  "redoEnded",
  "cellClicked",
  "cellDoubleClicked",
  "cellMouseDown",
  "cellContextMenu",
  "cellValueChanged",
  "cellEditRequest",
  "rowValueChanged",
  "headerFocused",
  "cellFocused",
  "rowSelected",
  "selectionChanged",
  "tooltipShow",
  "tooltipHide",
  "cellKeyDown",
  "cellMouseOver",
  "cellMouseOut",
  "filterChanged",
  "filterModified",
  "filterOpened",
  "advancedFilterBuilderVisibleChanged",
  "sortChanged",
  "virtualRowRemoved",
  "rowClicked",
  "rowDoubleClicked",
  "gridReady",
  "gridPreDestroyed",
  "gridSizeChanged",
  "viewportChanged",
  "firstDataRendered",
  "dragStarted",
  "dragStopped",
  "rowEditingStarted",
  "rowEditingStopped",
  "cellEditingStarted",
  "cellEditingStopped",
  "bodyScroll",
  "bodyScrollEnd",
  "paginationChanged",
  "componentStateChanged",
  "storeRefreshed",
  "stateUpdated",
  "columnMenuVisibleChanged",
  "contextMenuVisibleChanged",
  "rowDragEnter",
  "rowDragMove",
  "rowDragLeave",
  "rowDragEnd"
];
var INTERNAL_EVENTS = [
  "scrollbarWidthChanged",
  "keyShortcutChangedCellStart",
  "keyShortcutChangedCellEnd",
  "pinnedHeightChanged",
  "cellFocusCleared",
  "fullWidthRowFocused",
  "checkboxChanged",
  "heightScaleChanged",
  "suppressMovableColumns",
  "suppressMenuHide",
  "suppressFieldDotNotation",
  "columnPanelItemDragStart",
  "columnPanelItemDragEnd",
  "bodyHeightChanged",
  "columnContainerWidthChanged",
  "displayedColumnsWidthChanged",
  "scrollVisibilityChanged",
  "columnHoverChanged",
  "flashCells",
  "paginationPixelOffsetChanged",
  "displayedRowsChanged",
  "leftPinnedWidthChanged",
  "rightPinnedWidthChanged",
  "rowContainerHeightChanged",
  "headerHeightChanged",
  "columnHeaderHeightChanged",
  "gridStylesChanged",
  "storeUpdated",
  "filterDestroyed",
  "rowDataUpdateStarted",
  "rowCountReady",
  "advancedFilterEnabledChanged",
  "dataTypesInferred",
  "fieldValueChanged",
  "fieldPickerValueSelected",
  "richSelectListRowSelected",
  "sideBarUpdated",
  "alignedGridScroll",
  "alignedGridColumn",
  "gridOptionsChanged",
  "chartTitleEdit",
  "recalculateRowBounds",
  "stickyTopOffsetChanged"
];
var ALL_EVENTS = [...PUBLIC_EVENTS, ...INTERNAL_EVENTS];

// community-modules/core/src/propertyKeys.ts
var INITIAL_GRID_OPTION_KEYS = {
  enableBrowserTooltips: true,
  tooltipTrigger: true,
  tooltipMouseTrack: true,
  tooltipShowMode: true,
  tooltipInteraction: true,
  defaultColGroupDef: true,
  suppressAutoSize: true,
  skipHeaderOnAutoSize: true,
  autoSizeStrategy: true,
  components: true,
  stopEditingWhenCellsLoseFocus: true,
  undoRedoCellEditing: true,
  undoRedoCellEditingLimit: true,
  excelStyles: true,
  cacheQuickFilter: true,
  advancedFilterModel: true,
  customChartThemes: true,
  chartThemeOverrides: true,
  chartToolPanelsDef: true,
  loadingCellRendererSelector: true,
  localeText: true,
  keepDetailRows: true,
  keepDetailRowsCount: true,
  detailRowHeight: true,
  detailRowAutoHeight: true,
  tabIndex: true,
  valueCache: true,
  valueCacheNeverExpires: true,
  enableCellExpressions: true,
  suppressTouch: true,
  suppressAsyncEvents: true,
  suppressBrowserResizeObserver: true,
  suppressPropertyNamesCheck: true,
  debug: true,
  loadingOverlayComponent: true,
  suppressLoadingOverlay: true,
  noRowsOverlayComponent: true,
  paginationPageSizeSelector: true,
  paginateChildRows: true,
  pivotPanelShow: true,
  pivotSuppressAutoColumn: true,
  suppressExpandablePivotGroups: true,
  aggFuncs: true,
  suppressAggFuncInHeader: true,
  removePivotHeaderRowWhenSingleValueColumn: true,
  allowShowChangeAfterFilter: true,
  ensureDomOrder: true,
  enableRtl: true,
  suppressColumnVirtualisation: true,
  suppressMaxRenderedRowRestriction: true,
  suppressRowVirtualisation: true,
  rowDragText: true,
  suppressGroupMaintainValueType: true,
  groupLockGroupColumns: true,
  rowGroupPanelSuppressSort: true,
  suppressGroupRowsSticky: true,
  rowModelType: true,
  cacheOverflowSize: true,
  infiniteInitialRowCount: true,
  serverSideInitialRowCount: true,
  suppressServerSideInfiniteScroll: true,
  maxBlocksInCache: true,
  maxConcurrentDatasourceRequests: true,
  blockLoadDebounceMillis: true,
  serverSideOnlyRefreshFilteredGroups: true,
  serverSidePivotResultFieldSeparator: true,
  viewportRowModelPageSize: true,
  viewportRowModelBufferSize: true,
  debounceVerticalScrollbar: true,
  suppressAnimationFrame: true,
  suppressPreventDefaultOnMouseWheel: true,
  scrollbarWidth: true,
  icons: true,
  suppressRowTransform: true,
  gridId: true,
  enableGroupEdit: true,
  initialState: true,
  processUnpinnedColumns: true,
  createChartContainer: true,
  getLocaleText: true,
  getRowId: true,
  reactiveCustomComponents: true,
  columnMenu: true
};
var _PropertyKeys = class _PropertyKeys {
};
_PropertyKeys.STRING_PROPERTIES = [
  "rowSelection",
  "overlayLoadingTemplate",
  "overlayNoRowsTemplate",
  "gridId",
  "quickFilterText",
  "rowModelType",
  "editType",
  "domLayout",
  "clipboardDelimiter",
  "rowGroupPanelShow",
  "multiSortKey",
  "pivotColumnGroupTotals",
  "pivotRowTotals",
  "pivotPanelShow",
  "fillHandleDirection",
  "groupDisplayType",
  "treeDataDisplayType",
  "colResizeDefault",
  "tooltipTrigger",
  "serverSidePivotResultFieldSeparator",
  "columnMenu",
  "tooltipShowMode",
  "grandTotalRow"
];
_PropertyKeys.OBJECT_PROPERTIES = [
  "components",
  "rowStyle",
  "context",
  "autoGroupColumnDef",
  "localeText",
  "icons",
  "datasource",
  "serverSideDatasource",
  "viewportDatasource",
  "groupRowRendererParams",
  "aggFuncs",
  "fullWidthCellRendererParams",
  "defaultColGroupDef",
  "defaultColDef",
  "defaultCsvExportParams",
  "defaultExcelExportParams",
  "columnTypes",
  "rowClassRules",
  "detailCellRendererParams",
  "loadingCellRendererParams",
  "loadingOverlayComponentParams",
  "noRowsOverlayComponentParams",
  "popupParent",
  "statusBar",
  "sideBar",
  "chartThemeOverrides",
  "customChartThemes",
  "chartToolPanelsDef",
  "dataTypeDefinitions",
  "advancedFilterModel",
  "advancedFilterParent",
  "advancedFilterBuilderParams",
  "initialState",
  "autoSizeStrategy"
];
_PropertyKeys.ARRAY_PROPERTIES = [
  "sortingOrder",
  "alignedGrids",
  "rowData",
  "columnDefs",
  "excelStyles",
  "pinnedTopRowData",
  "pinnedBottomRowData",
  "chartThemes",
  "rowClass",
  "paginationPageSizeSelector"
];
// These properties are coerced at runtime, do not do union types
_PropertyKeys.NUMBER_PROPERTIES = [
  "rowHeight",
  "detailRowHeight",
  "rowBuffer",
  "headerHeight",
  "groupHeaderHeight",
  "groupLockGroupColumns",
  "floatingFiltersHeight",
  "pivotHeaderHeight",
  "pivotGroupHeaderHeight",
  "groupDefaultExpanded",
  "pivotDefaultExpanded",
  "viewportRowModelPageSize",
  "viewportRowModelBufferSize",
  "autoSizePadding",
  "maxBlocksInCache",
  "maxConcurrentDatasourceRequests",
  "tooltipShowDelay",
  "tooltipHideDelay",
  "cacheOverflowSize",
  "paginationPageSize",
  "cacheBlockSize",
  "infiniteInitialRowCount",
  "serverSideInitialRowCount",
  "scrollbarWidth",
  "asyncTransactionWaitMillis",
  "blockLoadDebounceMillis",
  "keepDetailRowsCount",
  "undoRedoCellEditingLimit",
  "cellFlashDelay",
  "cellFadeDelay",
  "cellFlashDuration",
  "cellFadeDuration",
  "tabIndex",
  "pivotMaxGeneratedColumns"
];
// These properties are coerced at runtime, do not do union types
_PropertyKeys.BOOLEAN_PROPERTIES = [
  "suppressMakeColumnVisibleAfterUnGroup",
  "suppressRowClickSelection",
  "suppressCellFocus",
  "suppressHeaderFocus",
  "suppressHorizontalScroll",
  "groupSelectsChildren",
  "alwaysShowHorizontalScroll",
  "alwaysShowVerticalScroll",
  "debug",
  "enableBrowserTooltips",
  "enableCellExpressions",
  "groupIncludeTotalFooter",
  "groupSuppressBlankHeader",
  "suppressMenuHide",
  "suppressRowDeselection",
  "unSortIcon",
  "suppressMultiSort",
  "alwaysMultiSort",
  "singleClickEdit",
  "suppressLoadingOverlay",
  "suppressNoRowsOverlay",
  "suppressAutoSize",
  "skipHeaderOnAutoSize",
  "suppressColumnMoveAnimation",
  "suppressMovableColumns",
  "suppressFieldDotNotation",
  "enableRangeSelection",
  "enableRangeHandle",
  "enableFillHandle",
  "suppressClearOnFillReduction",
  "deltaSort",
  "suppressTouch",
  "suppressAsyncEvents",
  "allowContextMenuWithControlKey",
  "suppressContextMenu",
  "enableCellChangeFlash",
  "suppressDragLeaveHidesColumns",
  "suppressRowGroupHidesColumns",
  "suppressMiddleClickScrolls",
  "suppressPreventDefaultOnMouseWheel",
  "suppressCopyRowsToClipboard",
  "copyHeadersToClipboard",
  "copyGroupHeadersToClipboard",
  "pivotMode",
  "suppressAggFuncInHeader",
  "suppressColumnVirtualisation",
  "alwaysAggregateAtRootLevel",
  "suppressFocusAfterRefresh",
  "functionsReadOnly",
  "animateRows",
  "groupSelectsFiltered",
  "groupRemoveSingleChildren",
  "groupRemoveLowestSingleChildren",
  "enableRtl",
  "suppressClickEdit",
  "rowDragEntireRow",
  "rowDragManaged",
  "suppressRowDrag",
  "suppressMoveWhenRowDragging",
  "rowDragMultiRow",
  "enableGroupEdit",
  "embedFullWidthRows",
  "suppressPaginationPanel",
  "groupHideOpenParents",
  "groupAllowUnbalanced",
  "pagination",
  "paginationAutoPageSize",
  "suppressScrollOnNewData",
  "suppressScrollWhenPopupsAreOpen",
  "purgeClosedRowNodes",
  "cacheQuickFilter",
  "includeHiddenColumnsInQuickFilter",
  "ensureDomOrder",
  "accentedSort",
  "suppressChangeDetection",
  "valueCache",
  "valueCacheNeverExpires",
  "aggregateOnlyChangedColumns",
  "suppressAnimationFrame",
  "suppressExcelExport",
  "suppressCsvExport",
  "includeHiddenColumnsInAdvancedFilter",
  "suppressMultiRangeSelection",
  "enterNavigatesVerticallyAfterEdit",
  "enterNavigatesVertically",
  "suppressPropertyNamesCheck",
  "rowMultiSelectWithClick",
  "suppressRowHoverHighlight",
  "suppressRowTransform",
  "suppressClipboardPaste",
  "suppressLastEmptyLineOnPaste",
  "enableCharts",
  "suppressMaintainUnsortedOrder",
  "enableCellTextSelection",
  "suppressBrowserResizeObserver",
  "suppressMaxRenderedRowRestriction",
  "excludeChildrenWhenTreeDataFiltering",
  "tooltipMouseTrack",
  "tooltipInteraction",
  "keepDetailRows",
  "paginateChildRows",
  "preventDefaultOnContextMenu",
  "undoRedoCellEditing",
  "allowDragFromColumnsToolPanel",
  "pivotSuppressAutoColumn",
  "suppressExpandablePivotGroups",
  "debounceVerticalScrollbar",
  "detailRowAutoHeight",
  "serverSideSortAllLevels",
  "serverSideEnableClientSideSort",
  "serverSideOnlyRefreshFilteredGroups",
  "serverSideSortOnServer",
  "serverSideFilterOnServer",
  "suppressAggFilteredOnly",
  "showOpenedGroup",
  "suppressClipboardApi",
  "suppressModelUpdateAfterUpdateTransaction",
  "stopEditingWhenCellsLoseFocus",
  "maintainColumnOrder",
  "groupMaintainOrder",
  "columnHoverHighlight",
  "readOnlyEdit",
  "suppressRowVirtualisation",
  "enableCellEditingOnBackspace",
  "resetRowDataOnUpdate",
  "removePivotHeaderRowWhenSingleValueColumn",
  "suppressCopySingleCellRanges",
  "suppressGroupRowsSticky",
  "suppressCutToClipboard",
  "suppressServerSideInfiniteScroll",
  "rowGroupPanelSuppressSort",
  "allowShowChangeAfterFilter",
  "enableAdvancedFilter",
  "masterDetail",
  "treeData",
  "suppressGroupMaintainValueType",
  "reactiveCustomComponents",
  "applyQuickFilterBeforePivotOrAgg",
  "suppressServerSideFullWidthLoadingRow",
  "suppressAdvancedFilterEval",
  "loading"
];
// If property does not fit above, i.e union that should not be coerced.
_PropertyKeys.OTHER_PROPERTIES = ["suppressStickyTotalRow"];
_PropertyKeys.FUNCTION_PROPERTIES = [
  "doesExternalFilterPass",
  "processPivotResultColDef",
  "processPivotResultColGroupDef",
  "getBusinessKeyForNode",
  "isRowSelectable",
  "rowDragText",
  "groupRowRenderer",
  "fullWidthCellRenderer",
  "loadingCellRenderer",
  "loadingOverlayComponent",
  "noRowsOverlayComponent",
  "detailCellRenderer",
  "quickFilterParser",
  "quickFilterMatcher",
  "getLocaleText",
  "isExternalFilterPresent",
  "getRowHeight",
  "getRowClass",
  "getRowStyle",
  "getContextMenuItems",
  "getMainMenuItems",
  "processRowPostCreate",
  "processCellForClipboard",
  "getGroupRowAgg",
  "isFullWidthRow",
  "sendToClipboard",
  "focusGridInnerElement",
  "navigateToNextHeader",
  "tabToNextHeader",
  "navigateToNextCell",
  "tabToNextCell",
  "processCellFromClipboard",
  "getDocument",
  "postProcessPopup",
  "getChildCount",
  "getDataPath",
  "isRowMaster",
  "postSortRows",
  "processHeaderForClipboard",
  "processUnpinnedColumns",
  "processGroupHeaderForClipboard",
  "paginationNumberFormatter",
  "processDataFromClipboard",
  "getServerSideGroupKey",
  "isServerSideGroup",
  "createChartContainer",
  "getChartToolbarItems",
  "fillOperation",
  "isApplyServerSideTransaction",
  "getServerSideGroupLevelParams",
  "isServerSideGroupOpenByDefault",
  "isGroupOpenByDefault",
  "initialGroupOrderComparator",
  "groupIncludeFooter",
  "loadingCellRendererSelector",
  "getRowId",
  "groupAggFiltering",
  "chartMenuItems",
  "groupTotalRow"
];
_PropertyKeys.ALL_PROPERTIES = [
  ..._PropertyKeys.ARRAY_PROPERTIES,
  ..._PropertyKeys.OBJECT_PROPERTIES,
  ..._PropertyKeys.STRING_PROPERTIES,
  ..._PropertyKeys.NUMBER_PROPERTIES,
  ..._PropertyKeys.FUNCTION_PROPERTIES,
  ..._PropertyKeys.BOOLEAN_PROPERTIES,
  ..._PropertyKeys.OTHER_PROPERTIES
];
var PropertyKeys = _PropertyKeys;

// community-modules/core/src/components/componentUtil.ts
var _ComponentUtil = class _ComponentUtil {
  static getCallbackForEvent(eventName) {
    if (!eventName || eventName.length < 2) {
      return eventName;
    }
    return "on" + eventName[0].toUpperCase() + eventName.substring(1);
  }
};
_ComponentUtil.VUE_OMITTED_PROPERTY = "AG-VUE-OMITTED-PROPERTY";
_ComponentUtil.PUBLIC_EVENTS = PUBLIC_EVENTS;
// onXXX methods, based on the above events
_ComponentUtil.EVENT_CALLBACKS = ALL_EVENTS.map((event) => _ComponentUtil.getCallbackForEvent(event));
_ComponentUtil.BOOLEAN_PROPERTIES = PropertyKeys.BOOLEAN_PROPERTIES;
_ComponentUtil.ALL_PROPERTIES = PropertyKeys.ALL_PROPERTIES;
_ComponentUtil.ALL_PROPERTIES_AND_CALLBACKS = [..._ComponentUtil.ALL_PROPERTIES, ..._ComponentUtil.EVENT_CALLBACKS];
_ComponentUtil.ALL_PROPERTIES_AND_CALLBACKS_SET = new Set(_ComponentUtil.ALL_PROPERTIES_AND_CALLBACKS);
var ComponentUtil = _ComponentUtil;
function _combineAttributesAndGridOptions(gridOptions, component) {
  if (typeof gridOptions !== "object") {
    gridOptions = {};
  }
  const mergedOptions = { ...gridOptions };
  const keys = ComponentUtil.ALL_PROPERTIES_AND_CALLBACKS;
  keys.forEach((key) => {
    const value = component[key];
    if (typeof value !== "undefined" && value !== ComponentUtil.VUE_OMITTED_PROPERTY) {
      mergedOptions[key] = value;
    }
  });
  return mergedOptions;
}
function _processOnChange(changes, api) {
  if (!changes) {
    return;
  }
  const gridChanges = {};
  let hasChanges = false;
  Object.keys(changes).filter((key) => ComponentUtil.ALL_PROPERTIES_AND_CALLBACKS_SET.has(key)).forEach((key) => {
    gridChanges[key] = changes[key];
    hasChanges = true;
  });
  if (!hasChanges) {
    return;
  }
  const internalUpdateEvent = {
    type: "gridOptionsChanged",
    options: gridChanges
  };
  api.dispatchEvent(internalUpdateEvent);
  const event = {
    type: "componentStateChanged"
  };
  _iterateObject(gridChanges, (key, value) => {
    event[key] = value;
  });
  api.dispatchEvent(event);
}

// community-modules/core/src/rendering/cssClassManager.ts
var CssClassManager = class {
  constructor(getGui) {
    // to minimise DOM hits, we only apply CSS classes if they have changed. as adding a CSS class that is already
    // there, or removing one that wasn't present, all takes CPU.
    this.cssClassStates = {};
    this.getGui = getGui;
  }
  addCssClass(className) {
    const list = (className || "").split(" ");
    if (list.length > 1) {
      list.forEach((cls) => this.addCssClass(cls));
      return;
    }
    const updateNeeded = this.cssClassStates[className] !== true;
    if (updateNeeded && className.length) {
      const eGui = this.getGui();
      if (eGui) {
        eGui.classList.add(className);
      }
      this.cssClassStates[className] = true;
    }
  }
  removeCssClass(className) {
    const list = (className || "").split(" ");
    if (list.length > 1) {
      list.forEach((cls) => this.removeCssClass(cls));
      return;
    }
    const updateNeeded = this.cssClassStates[className] !== false;
    if (updateNeeded && className.length) {
      const eGui = this.getGui();
      if (eGui) {
        eGui.classList.remove(className);
      }
      this.cssClassStates[className] = false;
    }
  }
  containsCssClass(className) {
    const eGui = this.getGui();
    if (!eGui) {
      return false;
    }
    return eGui.classList.contains(className);
  }
  addOrRemoveCssClass(className, addOrRemove) {
    if (!className) {
      return;
    }
    if (className.indexOf(" ") >= 0) {
      const list = (className || "").split(" ");
      if (list.length > 1) {
        list.forEach((cls) => this.addOrRemoveCssClass(cls, addOrRemove));
        return;
      }
    }
    const updateNeeded = this.cssClassStates[className] !== addOrRemove;
    if (updateNeeded && className.length) {
      const eGui = this.getGui();
      if (eGui) {
        eGui.classList.toggle(className, addOrRemove);
      }
      this.cssClassStates[className] = addOrRemove;
    }
  }
};

// community-modules/core/src/utils/aria.ts
function _toggleAriaAttribute(element, attribute, value) {
  if (value == null || typeof value === "string" && value == "") {
    _removeAriaAttribute(element, attribute);
  } else {
    _setAriaAttribute(element, attribute, value);
  }
}
function _setAriaAttribute(element, attribute, value) {
  element.setAttribute(_ariaAttributeName(attribute), value.toString());
}
function _removeAriaAttribute(element, attribute) {
  element.removeAttribute(_ariaAttributeName(attribute));
}
function _ariaAttributeName(attribute) {
  return `aria-${attribute}`;
}
function _setAriaRole(element, role) {
  if (role) {
    element.setAttribute("role", role);
  } else {
    element.removeAttribute("role");
  }
}
function _getAriaSortState(sortDirection) {
  let sort;
  if (sortDirection === "asc") {
    sort = "ascending";
  } else if (sortDirection === "desc") {
    sort = "descending";
  } else if (sortDirection === "mixed") {
    sort = "other";
  } else {
    sort = "none";
  }
  return sort;
}
function _getAriaPosInSet(element) {
  return parseInt(element.getAttribute("aria-posinset"), 10);
}
function _getAriaLabel(element) {
  return element.getAttribute("aria-label");
}
function _setAriaLabel(element, label) {
  _toggleAriaAttribute(element, "label", label);
}
function _setAriaLabelledBy(element, labelledBy) {
  _toggleAriaAttribute(element, "labelledby", labelledBy);
}
function _setAriaDescribedBy(element, describedby) {
  _toggleAriaAttribute(element, "describedby", describedby);
}
function _setAriaLive(element, live) {
  _toggleAriaAttribute(element, "live", live);
}
function _setAriaAtomic(element, atomic) {
  _toggleAriaAttribute(element, "atomic", atomic);
}
function _setAriaRelevant(element, relevant) {
  _toggleAriaAttribute(element, "relevant", relevant);
}
function _setAriaLevel(element, level) {
  _toggleAriaAttribute(element, "level", level);
}
function _setAriaDisabled(element, disabled) {
  _toggleAriaAttribute(element, "disabled", disabled);
}
function _setAriaHidden(element, hidden) {
  _toggleAriaAttribute(element, "hidden", hidden);
}
function _setAriaActiveDescendant(element, descendantId) {
  _toggleAriaAttribute(element, "activedescendant", descendantId);
}
function _setAriaExpanded(element, expanded) {
  _setAriaAttribute(element, "expanded", expanded);
}
function _removeAriaExpanded(element) {
  _removeAriaAttribute(element, "expanded");
}
function _setAriaSetSize(element, setsize) {
  _setAriaAttribute(element, "setsize", setsize);
}
function _setAriaPosInSet(element, position) {
  _setAriaAttribute(element, "posinset", position);
}
function _setAriaMultiSelectable(element, multiSelectable) {
  _setAriaAttribute(element, "multiselectable", multiSelectable);
}
function _setAriaRowCount(element, rowCount) {
  _setAriaAttribute(element, "rowcount", rowCount);
}
function _setAriaRowIndex(element, rowIndex) {
  _setAriaAttribute(element, "rowindex", rowIndex);
}
function _setAriaColCount(element, colCount) {
  _setAriaAttribute(element, "colcount", colCount);
}
function _setAriaColIndex(element, colIndex) {
  _setAriaAttribute(element, "colindex", colIndex);
}
function _setAriaColSpan(element, colSpan) {
  _setAriaAttribute(element, "colspan", colSpan);
}
function _setAriaSort(element, sort) {
  _setAriaAttribute(element, "sort", sort);
}
function _removeAriaSort(element) {
  _removeAriaAttribute(element, "sort");
}
function _setAriaSelected(element, selected) {
  _toggleAriaAttribute(element, "selected", selected);
}
function _setAriaChecked(element, checked) {
  _setAriaAttribute(element, "checked", checked === void 0 ? "mixed" : checked);
}
function _setAriaControls(controllerElement, controlledElement) {
  _toggleAriaAttribute(controllerElement, "controls", controlledElement.id);
  _setAriaLabelledBy(controlledElement, controllerElement.id);
}
function _getAriaCheckboxStateName(translate, state) {
  return state === void 0 ? translate("ariaIndeterminate", "indeterminate") : state === true ? translate("ariaChecked", "checked") : translate("ariaUnchecked", "unchecked");
}

// community-modules/core/src/utils/browser.ts
var isSafari;
var safariVersion;
var isChrome;
var isFirefox;
var isMacOs;
var isIOS;
var invisibleScrollbar;
var browserScrollbarWidth;
function _isBrowserSafari() {
  if (isSafari === void 0) {
    isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }
  return isSafari;
}
function _getSafariVersion() {
  if (safariVersion === void 0) {
    if (_isBrowserSafari()) {
      const versionMatch = navigator.userAgent.match(/version\/(\d+)/i);
      if (versionMatch) {
        safariVersion = versionMatch[1] != null ? parseFloat(versionMatch[1]) : 0;
      }
    } else {
      safariVersion = 0;
    }
  }
  return safariVersion;
}
function _isBrowserChrome() {
  if (isChrome === void 0) {
    const win = window;
    isChrome = !!win.chrome && (!!win.chrome.webstore || !!win.chrome.runtime) || /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  }
  return isChrome;
}
function _isBrowserFirefox() {
  if (isFirefox === void 0) {
    isFirefox = /(firefox)/i.test(navigator.userAgent);
  }
  return isFirefox;
}
function _isMacOsUserAgent() {
  if (isMacOs === void 0) {
    isMacOs = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
  }
  return isMacOs;
}
function _isIOSUserAgent() {
  if (isIOS === void 0) {
    isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;
  }
  return isIOS;
}
function _browserSupportsPreventScroll() {
  return !_isBrowserSafari() || _getSafariVersion() >= 15;
}
function _getTabIndex(el) {
  if (!el) {
    return null;
  }
  const numberTabIndex = el.tabIndex;
  const tabIndex = el.getAttribute("tabIndex");
  if (numberTabIndex === -1 && (tabIndex === null || tabIndex === "" && !_isBrowserFirefox())) {
    return null;
  }
  return numberTabIndex.toString();
}
function _getMaxDivHeight() {
  if (!document.body) {
    return -1;
  }
  let res = 1e6;
  const testUpTo = navigator.userAgent.toLowerCase().match(/firefox/) ? 6e6 : 1e9;
  const div = document.createElement("div");
  document.body.appendChild(div);
  while (true) {
    const test = res * 2;
    div.style.height = test + "px";
    if (test > testUpTo || div.clientHeight !== test) {
      break;
    } else {
      res = test;
    }
  }
  document.body.removeChild(div);
  return res;
}
function _getBodyWidth() {
  return document.body?.clientWidth ?? (window.innerHeight || document.documentElement?.clientWidth || -1);
}
function _getBodyHeight() {
  return document.body?.clientHeight ?? (window.innerHeight || document.documentElement?.clientHeight || -1);
}
function _getScrollbarWidth() {
  if (browserScrollbarWidth == null) {
    initScrollbarWidthAndVisibility();
  }
  return browserScrollbarWidth;
}
function initScrollbarWidthAndVisibility() {
  const body = document.body;
  const div = document.createElement("div");
  div.style.width = div.style.height = "100px";
  div.style.opacity = "0";
  div.style.overflow = "scroll";
  div.style.msOverflowStyle = "scrollbar";
  div.style.position = "absolute";
  body.appendChild(div);
  let width = div.offsetWidth - div.clientWidth;
  if (width === 0 && div.clientWidth === 0) {
    width = null;
  }
  if (div.parentNode) {
    div.parentNode.removeChild(div);
  }
  if (width != null) {
    browserScrollbarWidth = width;
    invisibleScrollbar = width === 0;
  }
}
function _isInvisibleScrollbar() {
  if (invisibleScrollbar == null) {
    initScrollbarWidthAndVisibility();
  }
  return invisibleScrollbar;
}

// community-modules/core/src/utils/dom.ts
var rtlNegativeScroll;
function _radioCssClass(element, elementClass, otherElementClass) {
  const parent = element.parentElement;
  let sibling = parent && parent.firstChild;
  while (sibling) {
    if (elementClass) {
      sibling.classList.toggle(elementClass, sibling === element);
    }
    if (otherElementClass) {
      sibling.classList.toggle(otherElementClass, sibling !== element);
    }
    sibling = sibling.nextSibling;
  }
}
var FOCUSABLE_SELECTOR = "[tabindex], input, select, button, textarea, [href]";
var FOCUSABLE_EXCLUDE = "[disabled], .ag-disabled:not(.ag-button), .ag-disabled *";
function _isFocusableFormField(element) {
  const matches = Element.prototype.matches || Element.prototype.msMatchesSelector;
  const inputSelector = "input, select, button, textarea";
  const isFocusable = matches.call(element, inputSelector);
  const isNotFocusable = matches.call(element, FOCUSABLE_EXCLUDE);
  const isElementVisible = _isVisible(element);
  const focusable = isFocusable && !isNotFocusable && isElementVisible;
  return focusable;
}
function _setDisplayed(element, displayed, options = {}) {
  const { skipAriaHidden } = options;
  element.classList.toggle("ag-hidden", !displayed);
  if (!skipAriaHidden) {
    _setAriaHidden(element, !displayed);
  }
}
function _setVisible(element, visible, options = {}) {
  const { skipAriaHidden } = options;
  element.classList.toggle("ag-invisible", !visible);
  if (!skipAriaHidden) {
    _setAriaHidden(element, !visible);
  }
}
function _setDisabled(element, disabled) {
  const attributeName = "disabled";
  const addOrRemoveDisabledAttribute = disabled ? (e) => e.setAttribute(attributeName, "") : (e) => e.removeAttribute(attributeName);
  addOrRemoveDisabledAttribute(element);
  _nodeListForEach(element.querySelectorAll("input"), (input) => addOrRemoveDisabledAttribute(input));
}
function _isElementChildOfClass(element, cls, maxNest) {
  let counter = 0;
  while (element) {
    if (element.classList.contains(cls)) {
      return true;
    }
    element = element.parentElement;
    if (typeof maxNest == "number") {
      if (++counter > maxNest) {
        break;
      }
    } else if (element === maxNest) {
      break;
    }
  }
  return false;
}
function _getElementSize(el) {
  const {
    height,
    width,
    borderTopWidth,
    borderRightWidth,
    borderBottomWidth,
    borderLeftWidth,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    boxSizing
  } = window.getComputedStyle(el);
  return {
    height: parseFloat(height || "0"),
    width: parseFloat(width || "0"),
    borderTopWidth: parseFloat(borderTopWidth || "0"),
    borderRightWidth: parseFloat(borderRightWidth || "0"),
    borderBottomWidth: parseFloat(borderBottomWidth || "0"),
    borderLeftWidth: parseFloat(borderLeftWidth || "0"),
    paddingTop: parseFloat(paddingTop || "0"),
    paddingRight: parseFloat(paddingRight || "0"),
    paddingBottom: parseFloat(paddingBottom || "0"),
    paddingLeft: parseFloat(paddingLeft || "0"),
    marginTop: parseFloat(marginTop || "0"),
    marginRight: parseFloat(marginRight || "0"),
    marginBottom: parseFloat(marginBottom || "0"),
    marginLeft: parseFloat(marginLeft || "0"),
    boxSizing
  };
}
function _getInnerHeight(el) {
  const size = _getElementSize(el);
  if (size.boxSizing === "border-box") {
    return size.height - size.paddingTop - size.paddingBottom;
  }
  return size.height;
}
function _getInnerWidth(el) {
  const size = _getElementSize(el);
  if (size.boxSizing === "border-box") {
    return size.width - size.paddingLeft - size.paddingRight;
  }
  return size.width;
}
function _getAbsoluteHeight(el) {
  const { height, marginBottom, marginTop } = _getElementSize(el);
  return Math.floor(height + marginBottom + marginTop);
}
function _getAbsoluteWidth(el) {
  const { width, marginLeft, marginRight } = _getElementSize(el);
  return Math.floor(width + marginLeft + marginRight);
}
function _getElementRectWithOffset(el) {
  const offsetElementRect = el.getBoundingClientRect();
  const { borderTopWidth, borderLeftWidth, borderRightWidth, borderBottomWidth } = _getElementSize(el);
  return {
    top: offsetElementRect.top + (borderTopWidth || 0),
    left: offsetElementRect.left + (borderLeftWidth || 0),
    right: offsetElementRect.right + (borderRightWidth || 0),
    bottom: offsetElementRect.bottom + (borderBottomWidth || 0)
  };
}
function _isRtlNegativeScroll() {
  if (typeof rtlNegativeScroll === "boolean") {
    return rtlNegativeScroll;
  }
  const template = document.createElement("div");
  template.style.direction = "rtl";
  template.style.width = "1px";
  template.style.height = "1px";
  template.style.position = "fixed";
  template.style.top = "0px";
  template.style.overflow = "hidden";
  template.dir = "rtl";
  template.innerHTML = /* html */
  `<div style="width: 2px">
            <span style="display: inline-block; width: 1px"></span>
            <span style="display: inline-block; width: 1px"></span>
        </div>`;
  document.body.appendChild(template);
  template.scrollLeft = 1;
  rtlNegativeScroll = Math.floor(template.scrollLeft) === 0;
  document.body.removeChild(template);
  return rtlNegativeScroll;
}
function _getScrollLeft(element, rtl) {
  let scrollLeft = element.scrollLeft;
  if (rtl) {
    scrollLeft = Math.abs(scrollLeft);
    if (_isBrowserChrome() && !_isRtlNegativeScroll()) {
      scrollLeft = element.scrollWidth - element.clientWidth - scrollLeft;
    }
  }
  return scrollLeft;
}
function _setScrollLeft(element, value, rtl) {
  if (rtl) {
    if (_isRtlNegativeScroll()) {
      value *= -1;
    } else if (_isBrowserSafari() || _isBrowserChrome()) {
      value = element.scrollWidth - element.clientWidth - value;
    }
  }
  element.scrollLeft = value;
}
function _clearElement(el) {
  while (el && el.firstChild) {
    el.removeChild(el.firstChild);
  }
}
function _removeFromParent(node) {
  if (node && node.parentNode) {
    node.parentNode.removeChild(node);
  }
}
function _isInDOM(element) {
  return !!element.offsetParent;
}
function _isVisible(element) {
  const el = element;
  if (el.checkVisibility) {
    return el.checkVisibility({ checkVisibilityCSS: true });
  }
  const isHidden = !_isInDOM(element) || window.getComputedStyle(element).visibility !== "visible";
  return !isHidden;
}
function _loadTemplate(template) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = (template || "").trim();
  return tempDiv.firstChild;
}
function _ensureDomOrder(eContainer, eChild, eChildBefore) {
  if (eChildBefore && eChildBefore.nextSibling === eChild) {
    return;
  }
  if (eChildBefore) {
    if (eChildBefore.nextSibling) {
      eContainer.insertBefore(eChild, eChildBefore.nextSibling);
    } else {
      eContainer.appendChild(eChild);
    }
  } else {
    if (eContainer.firstChild && eContainer.firstChild !== eChild) {
      eContainer.insertAdjacentElement("afterbegin", eChild);
    }
  }
}
function _setDomChildOrder(eContainer, orderedChildren) {
  for (let i = 0; i < orderedChildren.length; i++) {
    const correctCellAtIndex = orderedChildren[i];
    const actualCellAtIndex = eContainer.children[i];
    if (actualCellAtIndex !== correctCellAtIndex) {
      eContainer.insertBefore(correctCellAtIndex, actualCellAtIndex);
    }
  }
}
function _insertWithDomOrder(eContainer, eToInsert, eChildBefore) {
  if (eChildBefore) {
    eChildBefore.insertAdjacentElement("afterend", eToInsert);
  } else {
    if (eContainer.firstChild) {
      eContainer.insertAdjacentElement("afterbegin", eToInsert);
    } else {
      eContainer.appendChild(eToInsert);
    }
  }
}
function _addStylesToElement(eElement, styles) {
  if (!styles) {
    return;
  }
  for (const [key, value] of Object.entries(styles)) {
    if (!key || !key.length || value == null) {
      continue;
    }
    const parsedKey = _camelCaseToHyphenated(key);
    const valueAsString = value.toString();
    const parsedValue = valueAsString.replace(/\s*!important/g, "");
    const priority = parsedValue.length != valueAsString.length ? "important" : void 0;
    eElement.style.setProperty(parsedKey, parsedValue, priority);
  }
}
function _isHorizontalScrollShowing(element) {
  return element.clientWidth < element.scrollWidth;
}
function _isVerticalScrollShowing(element) {
  return element.clientHeight < element.scrollHeight;
}
function _setElementWidth(element, width) {
  if (width === "flex") {
    element.style.removeProperty("width");
    element.style.removeProperty("minWidth");
    element.style.removeProperty("maxWidth");
    element.style.flex = "1 1 auto";
  } else {
    _setFixedWidth(element, width);
  }
}
function _setFixedWidth(element, width) {
  width = _formatSize(width);
  element.style.width = width.toString();
  element.style.maxWidth = width.toString();
  element.style.minWidth = width.toString();
}
function _setFixedHeight(element, height) {
  height = _formatSize(height);
  element.style.height = height.toString();
  element.style.maxHeight = height.toString();
  element.style.minHeight = height.toString();
}
function _formatSize(size) {
  if (typeof size === "number") {
    return `${size}px`;
  }
  return size;
}
function _isNodeOrElement(o) {
  return o instanceof Node || o instanceof HTMLElement;
}
function _copyNodeList(nodeList) {
  if (nodeList == null) {
    return [];
  }
  const result = [];
  _nodeListForEach(nodeList, (node) => result.push(node));
  return result;
}
function _iterateNamedNodeMap(map, callback) {
  if (!map) {
    return;
  }
  for (let i = 0; i < map.length; i++) {
    const attr = map[i];
    callback(attr.name, attr.value);
  }
}
function _addOrRemoveAttribute(element, name, value) {
  if (value == null) {
    element.removeAttribute(name);
  } else {
    element.setAttribute(name, value.toString());
  }
}
function _nodeListForEach(nodeList, action) {
  if (nodeList == null) {
    return;
  }
  for (let i = 0; i < nodeList.length; i++) {
    action(nodeList[i]);
  }
}
function _bindCellRendererToHtmlElement(cellRendererPromise, eTarget) {
  cellRendererPromise.then((cellRenderer) => {
    const gui = cellRenderer.getGui();
    if (gui != null) {
      if (typeof gui === "object") {
        eTarget.appendChild(gui);
      } else {
        eTarget.innerHTML = gui;
      }
    }
  });
}

// community-modules/core/src/utils/numberSequence.ts
var NumberSequence = class {
  constructor(initValue = 0, step = 1) {
    this.nextValue = initValue;
    this.step = step;
  }
  next() {
    const valToReturn = this.nextValue;
    this.nextValue += this.step;
    return valToReturn;
  }
  peek() {
    return this.nextValue;
  }
  skip(count) {
    this.nextValue += count;
  }
};

// community-modules/core/src/widgets/tooltipStateManager.ts
var SHOW_QUICK_TOOLTIP_DIFF = 1e3;
var FADE_OUT_TOOLTIP_TIMEOUT = 1e3;
var INTERACTIVE_HIDE_DELAY = 100;
var _TooltipStateManager = class _TooltipStateManager extends BeanStub {
  constructor(parentComp, tooltipShowDelayOverride, tooltipHideDelayOverride, shouldDisplayTooltip) {
    super();
    this.parentComp = parentComp;
    this.tooltipShowDelayOverride = tooltipShowDelayOverride;
    this.tooltipHideDelayOverride = tooltipHideDelayOverride;
    this.shouldDisplayTooltip = shouldDisplayTooltip;
    this.interactionEnabled = false;
    this.isInteractingWithTooltip = false;
    this.state = 0 /* NOTHING */;
    // when showing the tooltip, we need to make sure it's the most recent instance we request, as due to
    // async we could request two tooltips before the first instance returns, in which case we should
    // disregard the second instance.
    this.tooltipInstanceCount = 0;
    this.tooltipMouseTrack = false;
  }
  wireBeans(beans) {
    this.popupService = beans.popupService;
    this.userComponentFactory = beans.userComponentFactory;
  }
  postConstruct() {
    if (this.gos.get("tooltipInteraction")) {
      this.interactionEnabled = true;
    }
    this.tooltipTrigger = this.getTooltipTrigger();
    this.tooltipMouseTrack = this.gos.get("tooltipMouseTrack");
    const el = this.parentComp.getGui();
    if (this.tooltipTrigger === 0 /* HOVER */) {
      this.addManagedListeners(el, {
        mouseenter: this.onMouseEnter.bind(this),
        mouseleave: this.onMouseLeave.bind(this)
      });
    }
    if (this.tooltipTrigger === 1 /* FOCUS */) {
      this.addManagedListeners(el, {
        focusin: this.onFocusIn.bind(this),
        focusout: this.onFocusOut.bind(this)
      });
    }
    this.addManagedListeners(el, { mousemove: this.onMouseMove.bind(this) });
    if (!this.interactionEnabled) {
      this.addManagedListeners(el, {
        mousedown: this.onMouseDown.bind(this),
        keydown: this.onKeyDown.bind(this)
      });
    }
  }
  getGridOptionsTooltipDelay(delayOption) {
    const delay = this.gos.get(delayOption);
    if (delay < 0) {
      _warnOnce(`${delayOption} should not be lower than 0`);
    }
    return Math.max(200, delay);
  }
  getTooltipDelay(type) {
    if (type === "show") {
      return this.tooltipShowDelayOverride ?? this.getGridOptionsTooltipDelay("tooltipShowDelay");
    }
    return this.tooltipHideDelayOverride ?? this.getGridOptionsTooltipDelay("tooltipHideDelay");
  }
  destroy() {
    this.setToDoNothing();
    super.destroy();
  }
  getTooltipTrigger() {
    const trigger = this.gos.get("tooltipTrigger");
    if (!trigger || trigger === "hover") {
      return 0 /* HOVER */;
    }
    return 1 /* FOCUS */;
  }
  onMouseEnter(e) {
    if (this.interactionEnabled && this.interactiveTooltipTimeoutId) {
      this.unlockService();
      this.startHideTimeout();
    }
    if (_isIOSUserAgent()) {
      return;
    }
    if (_TooltipStateManager.isLocked) {
      this.showTooltipTimeoutId = window.setTimeout(() => {
        this.prepareToShowTooltip(e);
      }, INTERACTIVE_HIDE_DELAY);
    } else {
      this.prepareToShowTooltip(e);
    }
  }
  onMouseMove(e) {
    if (this.lastMouseEvent) {
      this.lastMouseEvent = e;
    }
    if (this.tooltipMouseTrack && this.state === 2 /* SHOWING */ && this.tooltipComp) {
      this.positionTooltip();
    }
  }
  onMouseDown() {
    this.setToDoNothing();
  }
  onMouseLeave() {
    if (this.interactionEnabled) {
      this.lockService();
    } else {
      this.setToDoNothing();
    }
  }
  onFocusIn() {
    this.prepareToShowTooltip();
  }
  onFocusOut(e) {
    const relatedTarget = e.relatedTarget;
    const parentCompGui = this.parentComp.getGui();
    const tooltipGui = this.tooltipComp?.getGui();
    if (this.isInteractingWithTooltip || parentCompGui.contains(relatedTarget) || this.interactionEnabled && tooltipGui?.contains(relatedTarget)) {
      return;
    }
    this.setToDoNothing();
  }
  onKeyDown() {
    this.setToDoNothing();
  }
  prepareToShowTooltip(mouseEvent) {
    if (this.state != 0 /* NOTHING */ || _TooltipStateManager.isLocked) {
      return;
    }
    let delay = 0;
    if (mouseEvent) {
      delay = this.isLastTooltipHiddenRecently() ? 200 : this.getTooltipDelay("show");
    }
    this.lastMouseEvent = mouseEvent || null;
    this.showTooltipTimeoutId = window.setTimeout(this.showTooltip.bind(this), delay);
    this.state = 1 /* WAITING_TO_SHOW */;
  }
  isLastTooltipHiddenRecently() {
    const now = (/* @__PURE__ */ new Date()).getTime();
    const then = _TooltipStateManager.lastTooltipHideTime;
    return now - then < SHOW_QUICK_TOOLTIP_DIFF;
  }
  setToDoNothing() {
    if (this.state === 2 /* SHOWING */) {
      this.hideTooltip();
    }
    if (this.onBodyScrollEventCallback) {
      this.onBodyScrollEventCallback();
      this.onBodyScrollEventCallback = void 0;
    }
    if (this.onColumnMovedEventCallback) {
      this.onColumnMovedEventCallback();
      this.onColumnMovedEventCallback = void 0;
    }
    this.clearTimeouts();
    this.state = 0 /* NOTHING */;
    this.lastMouseEvent = null;
  }
  showTooltip() {
    const params = {
      ...this.parentComp.getTooltipParams()
    };
    if (!_exists(params.value) || this.shouldDisplayTooltip && !this.shouldDisplayTooltip()) {
      this.setToDoNothing();
      return;
    }
    this.state = 2 /* SHOWING */;
    this.tooltipInstanceCount++;
    const callback = this.newTooltipComponentCallback.bind(this, this.tooltipInstanceCount);
    const userDetails = this.userComponentFactory.getTooltipCompDetails(params);
    userDetails.newAgStackInstance().then(callback);
  }
  hideTooltip(forceHide) {
    if (!forceHide && this.isInteractingWithTooltip) {
      return;
    }
    if (this.tooltipComp) {
      this.destroyTooltipComp();
      _TooltipStateManager.lastTooltipHideTime = (/* @__PURE__ */ new Date()).getTime();
    }
    const event = {
      type: "tooltipHide",
      parentGui: this.parentComp.getGui()
    };
    this.eventService.dispatchEvent(event);
    if (forceHide) {
      this.isInteractingWithTooltip = false;
    }
    this.state = 0 /* NOTHING */;
  }
  newTooltipComponentCallback(tooltipInstanceCopy, tooltipComp) {
    const compNoLongerNeeded = this.state !== 2 /* SHOWING */ || this.tooltipInstanceCount !== tooltipInstanceCopy;
    if (compNoLongerNeeded) {
      this.destroyBean(tooltipComp);
      return;
    }
    const eGui = tooltipComp.getGui();
    this.tooltipComp = tooltipComp;
    if (!eGui.classList.contains("ag-tooltip")) {
      eGui.classList.add("ag-tooltip-custom");
    }
    if (this.tooltipTrigger === 0 /* HOVER */) {
      eGui.classList.add("ag-tooltip-animate");
    }
    if (this.interactionEnabled) {
      eGui.classList.add("ag-tooltip-interactive");
    }
    const translate = this.localeService.getLocaleTextFunc();
    const addPopupRes = this.popupService.addPopup({
      eChild: eGui,
      ariaLabel: translate("ariaLabelTooltip", "Tooltip")
    });
    if (addPopupRes) {
      this.tooltipPopupDestroyFunc = addPopupRes.hideFunc;
    }
    this.positionTooltip();
    if (this.tooltipTrigger === 1 /* FOCUS */) {
      const listener = this.setToDoNothing.bind(this);
      [this.onBodyScrollEventCallback, this.onColumnMovedEventCallback] = this.addManagedEventListeners({
        bodyScroll: listener,
        columnMoved: listener
      });
    }
    if (this.interactionEnabled) {
      if (this.tooltipTrigger === 0 /* HOVER */) {
        [this.tooltipMouseEnterListener, this.tooltipMouseLeaveListener] = this.addManagedElementListeners(
          eGui,
          {
            mouseenter: this.onTooltipMouseEnter.bind(this),
            mouseleave: this.onTooltipMouseLeave.bind(this)
          }
        );
      } else {
        [this.tooltipFocusInListener, this.tooltipFocusOutListener] = this.addManagedElementListeners(eGui, {
          focusin: this.onTooltipFocusIn.bind(this),
          focusout: this.onTooltipFocusOut.bind(this)
        });
      }
    }
    const event = {
      type: "tooltipShow",
      tooltipGui: eGui,
      parentGui: this.parentComp.getGui()
    };
    this.eventService.dispatchEvent(event);
    this.startHideTimeout();
  }
  onTooltipMouseEnter() {
    this.isInteractingWithTooltip = true;
    this.unlockService();
  }
  onTooltipMouseLeave() {
    this.isInteractingWithTooltip = false;
    this.lockService();
  }
  onTooltipFocusIn() {
    this.isInteractingWithTooltip = true;
  }
  onTooltipFocusOut(e) {
    const parentGui = this.parentComp.getGui();
    const tooltipGui = this.tooltipComp?.getGui();
    const relatedTarget = e.relatedTarget;
    if (tooltipGui?.contains(relatedTarget)) {
      return;
    }
    this.isInteractingWithTooltip = false;
    if (parentGui.contains(relatedTarget)) {
      this.startHideTimeout();
    } else {
      this.hideTooltip();
    }
  }
  positionTooltip() {
    const params = {
      type: "tooltip",
      ePopup: this.tooltipComp.getGui(),
      nudgeY: 18,
      skipObserver: this.tooltipMouseTrack
    };
    if (this.lastMouseEvent) {
      this.popupService.positionPopupUnderMouseEvent({
        ...params,
        mouseEvent: this.lastMouseEvent
      });
    } else {
      this.popupService.positionPopupByComponent({
        ...params,
        eventSource: this.parentComp.getGui(),
        position: "under",
        keepWithinBounds: true,
        nudgeY: 5
      });
    }
  }
  destroyTooltipComp() {
    this.tooltipComp.getGui().classList.add("ag-tooltip-hiding");
    const tooltipPopupDestroyFunc = this.tooltipPopupDestroyFunc;
    const tooltipComp = this.tooltipComp;
    const delay = this.tooltipTrigger === 0 /* HOVER */ ? FADE_OUT_TOOLTIP_TIMEOUT : 0;
    window.setTimeout(() => {
      tooltipPopupDestroyFunc();
      this.destroyBean(tooltipComp);
    }, delay);
    this.clearTooltipListeners();
    this.tooltipPopupDestroyFunc = void 0;
    this.tooltipComp = void 0;
  }
  clearTooltipListeners() {
    [
      this.tooltipMouseEnterListener,
      this.tooltipMouseLeaveListener,
      this.tooltipFocusInListener,
      this.tooltipFocusOutListener
    ].forEach((listener) => {
      if (listener) {
        listener();
      }
    });
    this.tooltipMouseEnterListener = this.tooltipMouseLeaveListener = this.tooltipFocusInListener = this.tooltipFocusOutListener = null;
  }
  lockService() {
    _TooltipStateManager.isLocked = true;
    this.interactiveTooltipTimeoutId = window.setTimeout(() => {
      this.unlockService();
      this.setToDoNothing();
    }, INTERACTIVE_HIDE_DELAY);
  }
  unlockService() {
    _TooltipStateManager.isLocked = false;
    this.clearInteractiveTimeout();
  }
  startHideTimeout() {
    this.clearHideTimeout();
    this.hideTooltipTimeoutId = window.setTimeout(this.hideTooltip.bind(this), this.getTooltipDelay("hide"));
  }
  clearShowTimeout() {
    if (!this.showTooltipTimeoutId) {
      return;
    }
    window.clearTimeout(this.showTooltipTimeoutId);
    this.showTooltipTimeoutId = void 0;
  }
  clearHideTimeout() {
    if (!this.hideTooltipTimeoutId) {
      return;
    }
    window.clearTimeout(this.hideTooltipTimeoutId);
    this.hideTooltipTimeoutId = void 0;
  }
  clearInteractiveTimeout() {
    if (!this.interactiveTooltipTimeoutId) {
      return;
    }
    window.clearTimeout(this.interactiveTooltipTimeoutId);
    this.interactiveTooltipTimeoutId = void 0;
  }
  clearTimeouts() {
    this.clearShowTimeout();
    this.clearHideTimeout();
    this.clearInteractiveTimeout();
  }
};
_TooltipStateManager.isLocked = false;
var TooltipStateManager = _TooltipStateManager;

// community-modules/core/src/widgets/tooltipFeature.ts
var TooltipFeature = class extends BeanStub {
  constructor(ctrl, beans) {
    super();
    this.ctrl = ctrl;
    if (beans) {
      this.beans = beans;
    }
  }
  wireBeans(beans) {
    this.beans = beans;
  }
  postConstruct() {
    this.refreshToolTip();
  }
  setBrowserTooltip(tooltip) {
    const name = "title";
    const eGui = this.ctrl.getGui();
    if (!eGui) {
      return;
    }
    if (tooltip != null && tooltip != "") {
      eGui.setAttribute(name, tooltip);
    } else {
      eGui.removeAttribute(name);
    }
  }
  updateTooltipText() {
    this.tooltip = this.ctrl.getTooltipValue();
  }
  createTooltipFeatureIfNeeded() {
    if (this.tooltipManager != null) {
      return;
    }
    const parent = {
      getTooltipParams: () => this.getTooltipParams(),
      getGui: () => this.ctrl.getGui()
    };
    this.tooltipManager = this.createBean(
      new TooltipStateManager(
        parent,
        this.ctrl.getTooltipShowDelayOverride?.(),
        this.ctrl.getTooltipHideDelayOverride?.(),
        this.ctrl.shouldDisplayTooltip
      ),
      this.beans.context
    );
  }
  refreshToolTip() {
    this.browserTooltips = this.beans.gos.get("enableBrowserTooltips");
    this.updateTooltipText();
    if (this.browserTooltips) {
      this.setBrowserTooltip(this.tooltip);
      if (this.tooltipManager) {
        this.tooltipManager = this.destroyBean(this.tooltipManager, this.beans.context);
      }
    } else {
      this.setBrowserTooltip(null);
      this.createTooltipFeatureIfNeeded();
    }
  }
  getTooltipParams() {
    const ctrl = this.ctrl;
    const column = ctrl.getColumn ? ctrl.getColumn() : void 0;
    const colDef = ctrl.getColDef ? ctrl.getColDef() : void 0;
    const rowNode = ctrl.getRowNode ? ctrl.getRowNode() : void 0;
    return {
      location: ctrl.getLocation(),
      //'cell',
      colDef,
      column,
      rowIndex: ctrl.getRowIndex ? ctrl.getRowIndex() : void 0,
      node: rowNode,
      data: rowNode ? rowNode.data : void 0,
      value: this.getTooltipText(),
      valueFormatted: ctrl.getValueFormatted ? ctrl.getValueFormatted() : void 0,
      hideTooltipCallback: () => this.tooltipManager?.hideTooltip(true)
    };
  }
  getTooltipText() {
    return this.tooltip;
  }
  // overriding to make public, as we don't dispose this bean via context
  destroy() {
    if (this.tooltipManager) {
      this.tooltipManager = this.destroyBean(this.tooltipManager, this.beans.context);
    }
    super.destroy();
  }
};

// community-modules/core/src/widgets/component.ts
var compIdSequence = new NumberSequence();
var RefPlaceholder = null;
var Component = class _Component extends BeanStub {
  constructor(template, componentSelectors) {
    super();
    this.suppressDataRefValidation = false;
    // if false, then CSS class "ag-hidden" is applied, which sets "display: none"
    this.displayed = true;
    // if false, then CSS class "ag-invisible" is applied, which sets "visibility: hidden"
    this.visible = true;
    // unique id for this row component. this is used for getting a reference to the HTML dom.
    // we cannot use the RowNode id as this is not unique (due to animation, old rows can be lying
    // around as we create a new rowComp instance for the same row node).
    this.compId = compIdSequence.next();
    this.cssClassManager = new CssClassManager(() => this.eGui);
    this.componentSelectors = new Map((componentSelectors ?? []).map((comp) => [comp.selector, comp]));
    if (template) {
      this.setTemplate(template);
    }
  }
  preWireBeans(beans) {
    super.preWireBeans(beans);
  }
  preConstruct() {
    this.usingBrowserTooltips = this.gos.get("enableBrowserTooltips");
    this.wireTemplate(this.getGui());
  }
  wireTemplate(element, paramsMap) {
    if (element && this.gos) {
      this.applyElementsToComponent(element);
      this.createChildComponentsFromTags(element, paramsMap);
    }
  }
  getCompId() {
    return this.compId;
  }
  getTooltipParams() {
    return {
      value: this.tooltipText,
      location: "UNKNOWN"
    };
  }
  setTooltip(params) {
    const { newTooltipText, showDelayOverride, hideDelayOverride, location, shouldDisplayTooltip } = params || {};
    if (this.tooltipFeature) {
      this.tooltipFeature = this.destroyBean(this.tooltipFeature);
    }
    if (this.tooltipText !== newTooltipText) {
      this.tooltipText = newTooltipText;
    }
    const getTooltipValue = () => this.tooltipText;
    if (newTooltipText != null) {
      this.tooltipFeature = this.createBean(
        new TooltipFeature({
          getTooltipValue,
          getGui: () => this.getGui(),
          getLocation: () => location ?? "UNKNOWN",
          getColDef: params?.getColDef,
          getColumn: params?.getColumn,
          getTooltipShowDelayOverride: showDelayOverride != null ? () => showDelayOverride : void 0,
          getTooltipHideDelayOverride: hideDelayOverride != null ? () => hideDelayOverride : void 0,
          shouldDisplayTooltip
        })
      );
    }
  }
  applyElementsToComponent(element, elementRef, paramsMap, newComponent = null) {
    if (elementRef === void 0) {
      elementRef = element.getAttribute("data-ref");
    }
    if (elementRef) {
      const current = this[elementRef];
      if (current === RefPlaceholder) {
        this[elementRef] = newComponent ?? element;
      } else {
        const usedAsParamRef = paramsMap && paramsMap[elementRef];
        if (!this.suppressDataRefValidation && !usedAsParamRef) {
          _warnOnce(`Issue with data-ref: ${elementRef} on ${this.constructor.name} with ${current}`);
        }
      }
    }
  }
  // for registered components only, eg creates AgCheckbox instance from ag-checkbox HTML tag
  createChildComponentsFromTags(parentNode, paramsMap) {
    const childNodeList = _copyNodeList(parentNode.childNodes);
    childNodeList.forEach((childNode) => {
      if (!(childNode instanceof HTMLElement)) {
        return;
      }
      const childComp = this.createComponentFromElement(
        childNode,
        (childComp2) => {
          const childGui = childComp2.getGui();
          if (childGui) {
            this.copyAttributesFromNode(childNode, childComp2.getGui());
          }
        },
        paramsMap
      );
      if (childComp) {
        if (childComp.addItems && childNode.children.length) {
          this.createChildComponentsFromTags(childNode, paramsMap);
          const items = Array.prototype.slice.call(childNode.children);
          childComp.addItems(items);
        }
        this.swapComponentForNode(childComp, parentNode, childNode);
      } else if (childNode.childNodes) {
        this.createChildComponentsFromTags(childNode, paramsMap);
      }
    });
  }
  createComponentFromElement(element, afterPreCreateCallback, paramsMap) {
    const key = element.nodeName;
    const elementRef = element.getAttribute("data-ref");
    const isAgGridComponent = key.indexOf("AG-") === 0;
    const componentSelector = isAgGridComponent ? this.componentSelectors.get(key) : null;
    let newComponent = null;
    if (componentSelector) {
      _Component.elementGettingCreated = element;
      const componentParams = paramsMap && elementRef ? paramsMap[elementRef] : void 0;
      newComponent = new componentSelector.component(componentParams);
      newComponent.setParentComponent(this);
      this.createBean(newComponent, null, afterPreCreateCallback);
    } else if (isAgGridComponent) {
      _warnOnce(`Missing selector: ${key}`);
    }
    this.applyElementsToComponent(element, elementRef, paramsMap, newComponent);
    return newComponent;
  }
  copyAttributesFromNode(source, dest) {
    _iterateNamedNodeMap(source.attributes, (name, value) => dest.setAttribute(name, value));
  }
  swapComponentForNode(newComponent, parentNode, childNode) {
    const eComponent = newComponent.getGui();
    parentNode.replaceChild(eComponent, childNode);
    parentNode.insertBefore(document.createComment(childNode.nodeName), eComponent);
    this.addDestroyFunc(this.destroyBean.bind(this, newComponent));
  }
  activateTabIndex(elements) {
    const tabIndex = this.gos.get("tabIndex");
    if (!elements) {
      elements = [];
    }
    if (!elements.length) {
      elements.push(this.getGui());
    }
    elements.forEach((el) => el.setAttribute("tabindex", tabIndex.toString()));
  }
  setTemplate(template, componentSelectors, paramsMap) {
    const eGui = _loadTemplate(template);
    this.setTemplateFromElement(eGui, componentSelectors, paramsMap);
  }
  setTemplateFromElement(element, components, paramsMap, suppressDataRefValidation = false) {
    this.eGui = element;
    this.suppressDataRefValidation = suppressDataRefValidation;
    if (components) {
      for (let i = 0; i < components.length; i++) {
        const component = components[i];
        this.componentSelectors.set(component.selector, component);
      }
    }
    this.wireTemplate(element, paramsMap);
  }
  getGui() {
    return this.eGui;
  }
  getFocusableElement() {
    return this.eGui;
  }
  getAriaElement() {
    return this.getFocusableElement();
  }
  setParentComponent(component) {
    this.parentComponent = component;
  }
  getParentComponent() {
    return this.parentComponent;
  }
  // this method is for older code, that wants to provide the gui element,
  // it is not intended for this to be in ag-Stack
  setGui(eGui) {
    this.eGui = eGui;
  }
  queryForHtmlElement(cssSelector) {
    return this.eGui.querySelector(cssSelector);
  }
  getContainerAndElement(newChild, container) {
    let parent = container;
    if (newChild == null) {
      return null;
    }
    if (!parent) {
      parent = this.eGui;
    }
    if (_isNodeOrElement(newChild)) {
      return {
        element: newChild,
        parent
      };
    }
    return {
      element: newChild.getGui(),
      parent
    };
  }
  prependChild(newChild, container) {
    const { element, parent } = this.getContainerAndElement(newChild, container) || {};
    if (!element || !parent) {
      return;
    }
    parent.insertAdjacentElement("afterbegin", element);
  }
  appendChild(newChild, container) {
    const { element, parent } = this.getContainerAndElement(newChild, container) || {};
    if (!element || !parent) {
      return;
    }
    parent.appendChild(element);
  }
  isDisplayed() {
    return this.displayed;
  }
  setVisible(visible, options = {}) {
    if (visible !== this.visible) {
      this.visible = visible;
      const { skipAriaHidden } = options;
      _setVisible(this.eGui, visible, { skipAriaHidden });
    }
  }
  setDisplayed(displayed, options = {}) {
    if (displayed !== this.displayed) {
      this.displayed = displayed;
      const { skipAriaHidden } = options;
      _setDisplayed(this.eGui, displayed, { skipAriaHidden });
      const event = {
        type: "displayChanged",
        visible: this.displayed
      };
      this.dispatchLocalEvent(event);
    }
  }
  destroy() {
    if (this.parentComponent) {
      this.parentComponent = void 0;
    }
    if (this.tooltipFeature) {
      this.tooltipFeature = this.destroyBean(this.tooltipFeature);
    }
    super.destroy();
  }
  addGuiEventListener(event, listener, options) {
    this.eGui.addEventListener(event, listener, options);
    this.addDestroyFunc(() => this.eGui.removeEventListener(event, listener));
  }
  addCssClass(className) {
    this.cssClassManager.addCssClass(className);
  }
  removeCssClass(className) {
    this.cssClassManager.removeCssClass(className);
  }
  containsCssClass(className) {
    return this.cssClassManager.containsCssClass(className);
  }
  addOrRemoveCssClass(className, addOrRemove) {
    this.cssClassManager.addOrRemoveCssClass(className, addOrRemove);
  }
};

// community-modules/core/src/utils/icon.ts
var iconNameClassMap = {
  // header column group shown when expanded (click to contract)
  columnGroupOpened: "expanded",
  // header column group shown when contracted (click to expand)
  columnGroupClosed: "contracted",
  // tool panel column group contracted (click to expand)
  columnSelectClosed: "tree-closed",
  // tool panel column group expanded (click to contract)
  columnSelectOpen: "tree-open",
  // column tool panel header expand/collapse all button, shown when some children are expanded and
  //     others are collapsed
  columnSelectIndeterminate: "tree-indeterminate",
  // shown on ghost icon while dragging column to the side of the grid to pin
  columnMovePin: "pin",
  // shown on ghost icon while dragging over part of the page that is not a drop zone
  columnMoveHide: "eye-slash",
  // shown on ghost icon while dragging columns to reorder
  columnMoveMove: "arrows",
  // animating icon shown when dragging a column to the right of the grid causes horizontal scrolling
  columnMoveLeft: "left",
  // animating icon shown when dragging a column to the left of the grid causes horizontal scrolling
  columnMoveRight: "right",
  // shown on ghost icon while dragging over Row Groups drop zone
  columnMoveGroup: "group",
  // shown on ghost icon while dragging over Values drop zone
  columnMoveValue: "aggregation",
  // shown on ghost icon while dragging over pivot drop zone
  columnMovePivot: "pivot",
  // shown on ghost icon while dragging over drop zone that doesn't support it, e.g.
  //     string column over aggregation drop zone
  dropNotAllowed: "not-allowed",
  // shown on row group when contracted (click to expand)
  groupContracted: "tree-closed",
  // shown on row group when expanded (click to contract)
  groupExpanded: "tree-open",
  // set filter tree list group contracted (click to expand)
  setFilterGroupClosed: "tree-closed",
  // set filter tree list group expanded (click to contract)
  setFilterGroupOpen: "tree-open",
  // set filter tree list expand/collapse all button, shown when some children are expanded and
  //     others are collapsed
  setFilterGroupIndeterminate: "tree-indeterminate",
  // context menu chart item
  chart: "chart",
  // chart window title bar
  close: "cross",
  // X (remove) on column 'pill' after adding it to a drop zone list
  cancel: "cancel",
  // indicates the currently active pin state in the "Pin column" sub-menu of the column menu
  check: "tick",
  // "go to first" button in pagination controls
  first: "first",
  // "go to previous" button in pagination controls
  previous: "previous",
  // "go to next" button in pagination controls
  next: "next",
  // "go to last" button in pagination controls
  last: "last",
  // shown on top right of chart when chart is linked to range data (click to unlink)
  linked: "linked",
  // shown on top right of chart when chart is not linked to range data (click to link)
  unlinked: "unlinked",
  // "Choose colour" button on chart settings tab
  colorPicker: "color-picker",
  // rotating spinner shown by the loading cell renderer
  groupLoading: "loading",
  // button to launch enterprise column menu
  menu: "menu",
  menuAlt: "menu-alt",
  // filter tool panel tab
  filter: "filter",
  // column tool panel tab
  columns: "columns",
  // button in chart regular size window title bar (click to maximise)
  maximize: "maximize",
  // button in chart maximised window title bar (click to make regular size)
  minimize: "minimize",
  // "Pin column" item in column header menu
  menuPin: "pin",
  // "Value aggregation" column menu item (shown on numeric columns when grouping is active)"
  menuValue: "aggregation",
  // "Group by {column-name}" item in column header menu
  menuAddRowGroup: "group",
  // "Un-Group by {column-name}" item in column header menu
  menuRemoveRowGroup: "group",
  // context menu copy item
  clipboardCopy: "copy",
  // context menu cut item
  clipboardCut: "cut",
  // context menu paste item
  clipboardPaste: "paste",
  // identifies the pivot drop zone
  pivotPanel: "pivot",
  // "Row groups" drop zone in column tool panel
  rowGroupPanel: "group",
  // columns tool panel Values drop zone
  valuePanel: "aggregation",
  // drag handle used to pick up draggable columns
  columnDrag: "grip",
  // drag handle used to pick up draggable rows
  rowDrag: "grip",
  // context menu export item
  save: "save",
  // csv export
  csvExport: "csv",
  // excel export,
  excelExport: "excel",
  // icon on dropdown editors
  smallDown: "small-down",
  // version of small-right used in RTL mode
  smallLeft: "small-left",
  // separater between column 'pills' when you add multiple columns to the header drop zone
  smallRight: "small-right",
  smallUp: "small-up",
  // show on column header when column is sorted ascending
  sortAscending: "asc",
  // show on column header when column is sorted descending
  sortDescending: "desc",
  // show on column header when column has no sort, only when enabled with gridOptions.unSortIcon=true
  sortUnSort: "none",
  // Builder button in Advanced Filter
  advancedFilterBuilder: "group",
  // drag handle used to pick up Advanced Filter Builder rows
  advancedFilterBuilderDrag: "grip",
  // Advanced Filter Builder row validation error
  advancedFilterBuilderInvalid: "not-allowed",
  // shown on Advanced Filter Builder rows to move them up
  advancedFilterBuilderMoveUp: "up",
  // shown on Advanced Filter Builder rows to move them down
  advancedFilterBuilderMoveDown: "down",
  // shown on Advanced Filter Builder rows to add new rows
  advancedFilterBuilderAdd: "plus",
  // shown on Advanced Filter Builder rows to remove row
  advancedFilterBuilderRemove: "minus",
  // Edit Chart menu item shown in Integrated Charts menu
  chartsMenuEdit: "chart",
  // Advanced Settings menu item shown in Integrated Charts menu
  chartsMenuAdvancedSettings: "settings",
  // shown in Integrated Charts menu add fields
  chartsMenuAdd: "plus",
  // checked checkbox
  checkboxChecked: "checkbox-checked",
  // indeterminate checkbox
  checkboxIndeterminate: "checkbox-indeterminate",
  // unchecked checkbox
  checkboxUnchecked: "checkbox-unchecked",
  // radio button on
  radioButtonOn: "radio-button-on",
  // radio button off
  radioButtonOff: "radio-button-off"
};
function _createIcon(iconName, gos, column) {
  const iconContents = _createIconNoSpan(iconName, gos, column);
  if (iconContents) {
    const { className } = iconContents;
    if (typeof className === "string" && className.indexOf("ag-icon") > -1 || typeof className === "object" && className["ag-icon"]) {
      return iconContents;
    }
  }
  const eResult = document.createElement("span");
  eResult.appendChild(iconContents);
  return eResult;
}
function _createIconNoSpan(iconName, gos, column, forceCreate) {
  let userProvidedIcon = null;
  const icons = column && column.getColDef().icons;
  if (icons) {
    userProvidedIcon = icons[iconName];
  }
  if (gos && !userProvidedIcon) {
    const optionsIcons = gos.get("icons");
    if (optionsIcons) {
      userProvidedIcon = optionsIcons[iconName];
    }
  }
  if (userProvidedIcon) {
    let rendererResult;
    if (typeof userProvidedIcon === "function") {
      rendererResult = userProvidedIcon();
    } else if (typeof userProvidedIcon === "string") {
      rendererResult = userProvidedIcon;
    } else {
      throw new Error("icon from grid options needs to be a string or a function");
    }
    if (typeof rendererResult === "string") {
      return _loadTemplate(rendererResult);
    }
    if (_isNodeOrElement(rendererResult)) {
      return rendererResult;
    }
    _warnOnce("iconRenderer should return back a string or a dom object");
  } else {
    const span = document.createElement("span");
    let cssClass = iconNameClassMap[iconName];
    if (!cssClass) {
      if (!forceCreate) {
        _warnOnce(`Did not find icon ${iconName}`);
        cssClass = "";
      } else {
        cssClass = iconName;
      }
    }
    span.setAttribute("class", `ag-icon ag-icon-${cssClass}`);
    span.setAttribute("unselectable", "on");
    _setAriaRole(span, "presentation");
    return span;
  }
}

// community-modules/core/src/utils/mouse.ts
function _areEventsNear(e1, e2, pixelCount) {
  if (pixelCount === 0) {
    return false;
  }
  const diffX = Math.abs(e1.clientX - e2.clientX);
  const diffY = Math.abs(e1.clientY - e2.clientY);
  return Math.max(diffX, diffY) <= pixelCount;
}

// community-modules/core/src/widgets/touchListener.ts
var TouchListener = class {
  constructor(eElement, preventMouseClick = false) {
    this.DOUBLE_TAP_MILLIS = 500;
    this.destroyFuncs = [];
    this.touching = false;
    this.localEventService = new LocalEventService();
    this.eElement = eElement;
    this.preventMouseClick = preventMouseClick;
    const startListener = this.onTouchStart.bind(this);
    const moveListener = this.onTouchMove.bind(this);
    const endListener = this.onTouchEnd.bind(this);
    this.eElement.addEventListener("touchstart", startListener, { passive: true });
    this.eElement.addEventListener("touchmove", moveListener, { passive: true });
    this.eElement.addEventListener("touchend", endListener, { passive: false });
    this.destroyFuncs.push(() => {
      this.eElement.removeEventListener("touchstart", startListener, { passive: true });
      this.eElement.removeEventListener("touchmove", moveListener, { passive: true });
      this.eElement.removeEventListener("touchend", endListener, { passive: false });
    });
  }
  getActiveTouch(touchList) {
    for (let i = 0; i < touchList.length; i++) {
      const matches = touchList[i].identifier === this.touchStart.identifier;
      if (matches) {
        return touchList[i];
      }
    }
    return null;
  }
  addEventListener(eventType, listener) {
    this.localEventService.addEventListener(eventType, listener);
  }
  removeEventListener(eventType, listener) {
    this.localEventService.removeEventListener(eventType, listener);
  }
  onTouchStart(touchEvent) {
    if (this.touching) {
      return;
    }
    this.touchStart = touchEvent.touches[0];
    this.touching = true;
    this.moved = false;
    const touchStartCopy = this.touchStart;
    window.setTimeout(() => {
      const touchesMatch = this.touchStart === touchStartCopy;
      if (this.touching && touchesMatch && !this.moved) {
        this.moved = true;
        const event = {
          type: "longTap",
          touchStart: this.touchStart,
          touchEvent
        };
        this.localEventService.dispatchEvent(event);
      }
    }, 500);
  }
  onTouchMove(touchEvent) {
    if (!this.touching) {
      return;
    }
    const touch = this.getActiveTouch(touchEvent.touches);
    if (!touch) {
      return;
    }
    const eventIsFarAway = !_areEventsNear(touch, this.touchStart, 4);
    if (eventIsFarAway) {
      this.moved = true;
    }
  }
  onTouchEnd(touchEvent) {
    if (!this.touching) {
      return;
    }
    if (!this.moved) {
      const event = {
        type: "tap",
        touchStart: this.touchStart
      };
      this.localEventService.dispatchEvent(event);
      this.checkForDoubleTap();
    }
    if (this.preventMouseClick && touchEvent.cancelable) {
      touchEvent.preventDefault();
    }
    this.touching = false;
  }
  checkForDoubleTap() {
    const now = (/* @__PURE__ */ new Date()).getTime();
    if (this.lastTapTime && this.lastTapTime > 0) {
      const interval = now - this.lastTapTime;
      if (interval > this.DOUBLE_TAP_MILLIS) {
        const event = {
          type: "doubleTap",
          touchStart: this.touchStart
        };
        this.localEventService.dispatchEvent(event);
        this.lastTapTime = null;
      } else {
        this.lastTapTime = now;
      }
    } else {
      this.lastTapTime = now;
    }
  }
  destroy() {
    this.destroyFuncs.forEach((func) => func());
  }
};

// community-modules/core/src/headerRendering/cells/column/sortIndicatorComp.ts
var SortIndicatorTemplate = (
  /* html */
  `<span class="ag-sort-indicator-container">
        <span data-ref="eSortOrder" class="ag-sort-indicator-icon ag-sort-order ag-hidden" aria-hidden="true"></span>
        <span data-ref="eSortAsc" class="ag-sort-indicator-icon ag-sort-ascending-icon ag-hidden" aria-hidden="true"></span>
        <span data-ref="eSortDesc" class="ag-sort-indicator-icon ag-sort-descending-icon ag-hidden" aria-hidden="true"></span>
        <span data-ref="eSortMixed" class="ag-sort-indicator-icon ag-sort-mixed-icon ag-hidden" aria-hidden="true"></span>
        <span data-ref="eSortNone" class="ag-sort-indicator-icon ag-sort-none-icon ag-hidden" aria-hidden="true"></span>
    </span>`
);
var SortIndicatorComp = class extends Component {
  constructor(skipTemplate) {
    super();
    this.eSortOrder = RefPlaceholder;
    this.eSortAsc = RefPlaceholder;
    this.eSortDesc = RefPlaceholder;
    this.eSortMixed = RefPlaceholder;
    this.eSortNone = RefPlaceholder;
    if (!skipTemplate) {
      this.setTemplate(SortIndicatorTemplate);
    }
  }
  wireBeans(beans) {
    this.sortController = beans.sortController;
  }
  attachCustomElements(eSortOrder, eSortAsc, eSortDesc, eSortMixed, eSortNone) {
    this.eSortOrder = eSortOrder;
    this.eSortAsc = eSortAsc;
    this.eSortDesc = eSortDesc;
    this.eSortMixed = eSortMixed;
    this.eSortNone = eSortNone;
  }
  setupSort(column, suppressOrder = false) {
    this.column = column;
    this.suppressOrder = suppressOrder;
    this.setupMultiSortIndicator();
    if (!this.column.isSortable() && !this.column.getColDef().showRowGroup) {
      return;
    }
    this.addInIcon("sortAscending", this.eSortAsc, column);
    this.addInIcon("sortDescending", this.eSortDesc, column);
    this.addInIcon("sortUnSort", this.eSortNone, column);
    this.addManagedPropertyListener("unSortIcon", () => this.updateIcons());
    this.addManagedEventListeners({
      newColumnsLoaded: this.updateIcons.bind(this),
      // Watch global events, as row group columns can effect their display column.
      sortChanged: this.onSortChanged.bind(this),
      // when grouping changes so can sort indexes and icons
      columnRowGroupChanged: this.onSortChanged.bind(this)
    });
    this.onSortChanged();
  }
  addInIcon(iconName, eParent, column) {
    if (eParent == null) {
      return;
    }
    const eIcon = _createIconNoSpan(iconName, this.gos, column);
    if (eIcon) {
      eParent.appendChild(eIcon);
    }
  }
  onSortChanged() {
    this.updateIcons();
    if (!this.suppressOrder) {
      this.updateSortOrder();
    }
  }
  updateIcons() {
    const sortDirection = this.sortController.getDisplaySortForColumn(this.column);
    if (this.eSortAsc) {
      const isAscending = sortDirection === "asc";
      _setDisplayed(this.eSortAsc, isAscending, { skipAriaHidden: true });
    }
    if (this.eSortDesc) {
      const isDescending = sortDirection === "desc";
      _setDisplayed(this.eSortDesc, isDescending, { skipAriaHidden: true });
    }
    if (this.eSortNone) {
      const alwaysHideNoSort = !this.column.getColDef().unSortIcon && !this.gos.get("unSortIcon");
      const isNone = sortDirection === null || sortDirection === void 0;
      _setDisplayed(this.eSortNone, !alwaysHideNoSort && isNone, { skipAriaHidden: true });
    }
  }
  setupMultiSortIndicator() {
    this.addInIcon("sortUnSort", this.eSortMixed, this.column);
    const isColumnShowingRowGroup = this.column.getColDef().showRowGroup;
    const areGroupsCoupled = this.gos.isColumnsSortingCoupledToGroup();
    if (areGroupsCoupled && isColumnShowingRowGroup) {
      this.addManagedEventListeners({
        // Watch global events, as row group columns can effect their display column.
        sortChanged: this.updateMultiSortIndicator.bind(this),
        // when grouping changes so can sort indexes and icons
        columnRowGroupChanged: this.updateMultiSortIndicator.bind(this)
      });
      this.updateMultiSortIndicator();
    }
  }
  updateMultiSortIndicator() {
    if (this.eSortMixed) {
      const isMixedSort = this.sortController.getDisplaySortForColumn(this.column) === "mixed";
      _setDisplayed(this.eSortMixed, isMixedSort, { skipAriaHidden: true });
    }
  }
  // we listen here for global sort events, NOT column sort events, as we want to do this
  // when sorting has been set on all column (if we listened just for our col (where we
  // set the asc / desc icons) then it's possible other cols are yet to get their sorting state.
  updateSortOrder() {
    if (!this.eSortOrder) {
      return;
    }
    const allColumnsWithSorting = this.sortController.getColumnsWithSortingOrdered();
    const indexThisCol = this.sortController.getDisplaySortIndexForColumn(this.column) ?? -1;
    const moreThanOneColSorting = allColumnsWithSorting.some(
      (col) => this.sortController.getDisplaySortIndexForColumn(col) ?? -1 >= 1
    );
    const showIndex = indexThisCol >= 0 && moreThanOneColSorting;
    _setDisplayed(this.eSortOrder, showIndex, { skipAriaHidden: true });
    if (indexThisCol >= 0) {
      this.eSortOrder.textContent = (indexThisCol + 1).toString();
    } else {
      _clearElement(this.eSortOrder);
    }
  }
};
var SortIndicatorSelector = {
  selector: "AG-SORT-INDICATOR",
  component: SortIndicatorComp
};

// community-modules/core/src/headerRendering/cells/column/headerComp.ts
var HeaderCompTemplate = (
  /* html */
  `<div class="ag-cell-label-container" role="presentation">
        <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
        <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button" aria-hidden="true"></span>
        <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
            <span data-ref="eText" class="ag-header-cell-text"></span>
            <span data-ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
            <ag-sort-indicator data-ref="eSortIndicator"></ag-sort-indicator>
        </div>
    </div>`
);
var HeaderComp = class extends Component {
  constructor() {
    super(...arguments);
    this.eFilter = RefPlaceholder;
    this.eFilterButton = RefPlaceholder;
    this.eSortIndicator = RefPlaceholder;
    this.eMenu = RefPlaceholder;
    this.eLabel = RefPlaceholder;
    this.eText = RefPlaceholder;
    /**
     * Selectors for custom headers templates
     */
    this.eSortOrder = RefPlaceholder;
    this.eSortAsc = RefPlaceholder;
    this.eSortDesc = RefPlaceholder;
    this.eSortMixed = RefPlaceholder;
    this.eSortNone = RefPlaceholder;
    this.lastMovingChanged = 0;
  }
  wireBeans(beans) {
    this.sortController = beans.sortController;
    this.menuService = beans.menuService;
    this.funcColsService = beans.funcColsService;
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
  refresh(params) {
    const oldParams = this.params;
    this.params = params;
    if (this.workOutTemplate() != this.currentTemplate || this.workOutShowMenu() != this.currentShowMenu || this.workOutSort() != this.currentSort || this.shouldSuppressMenuHide() != this.currentSuppressMenuHide || oldParams.enableFilterButton != params.enableFilterButton || oldParams.enableFilterIcon != params.enableFilterIcon) {
      return false;
    }
    this.setDisplayName(params);
    return true;
  }
  workOutTemplate() {
    let template = this.params.template ?? HeaderCompTemplate;
    template = template && template.trim ? template.trim() : template;
    return template;
  }
  init(params) {
    this.params = params;
    this.currentTemplate = this.workOutTemplate();
    this.setTemplate(this.currentTemplate, [SortIndicatorSelector]);
    this.setupTap();
    this.setMenu();
    this.setupSort();
    this.setupFilterIcon();
    this.setupFilterButton();
    this.setDisplayName(params);
  }
  setDisplayName(params) {
    if (this.currentDisplayName != params.displayName) {
      this.currentDisplayName = params.displayName;
      const displayNameSanitised = _escapeString(this.currentDisplayName, true);
      if (this.eText) {
        this.eText.textContent = displayNameSanitised;
      }
    }
  }
  addInIcon(iconName, eParent, column) {
    if (eParent == null) {
      return;
    }
    const eIcon = _createIconNoSpan(iconName, this.gos, column);
    if (eIcon) {
      eParent.appendChild(eIcon);
    }
  }
  setupTap() {
    const { gos } = this;
    if (gos.get("suppressTouch")) {
      return;
    }
    const touchListener = new TouchListener(this.getGui(), true);
    const suppressMenuHide = this.shouldSuppressMenuHide();
    const tapMenuButton = suppressMenuHide && _exists(this.eMenu);
    const menuTouchListener = tapMenuButton ? new TouchListener(this.eMenu, true) : touchListener;
    if (this.params.enableMenu) {
      const eventType = tapMenuButton ? "tap" : "longTap";
      const showMenuFn = (event) => this.params.showColumnMenuAfterMouseClick(event.touchStart);
      this.addManagedListeners(menuTouchListener, { [eventType]: showMenuFn });
    }
    if (this.params.enableSorting) {
      const tapListener = (event) => {
        const target = event.touchStart.target;
        if (suppressMenuHide && (this.eMenu?.contains(target) || this.eFilterButton?.contains(target))) {
          return;
        }
        this.sortController.progressSort(this.params.column, false, "uiColumnSorted");
      };
      this.addManagedListeners(touchListener, { tap: tapListener });
    }
    if (this.params.enableFilterButton) {
      const filterButtonTouchListener = new TouchListener(this.eFilterButton, true);
      this.addManagedListeners(filterButtonTouchListener, {
        tap: () => this.params.showFilter(this.eFilterButton)
      });
      this.addDestroyFunc(() => filterButtonTouchListener.destroy());
    }
    this.addDestroyFunc(() => touchListener.destroy());
    if (tapMenuButton) {
      this.addDestroyFunc(() => menuTouchListener.destroy());
    }
  }
  workOutShowMenu() {
    return this.params.enableMenu && this.menuService.isHeaderMenuButtonEnabled();
  }
  shouldSuppressMenuHide() {
    return this.menuService.isHeaderMenuButtonAlwaysShowEnabled();
  }
  setMenu() {
    if (!this.eMenu) {
      return;
    }
    this.currentShowMenu = this.workOutShowMenu();
    if (!this.currentShowMenu) {
      _removeFromParent(this.eMenu);
      this.eMenu = void 0;
      return;
    }
    const isLegacyMenu = this.menuService.isLegacyMenuEnabled();
    this.addInIcon(isLegacyMenu ? "menu" : "menuAlt", this.eMenu, this.params.column);
    this.eMenu.classList.toggle("ag-header-menu-icon", !isLegacyMenu);
    this.currentSuppressMenuHide = this.shouldSuppressMenuHide();
    this.addManagedElementListeners(this.eMenu, { click: () => this.params.showColumnMenu(this.eMenu) });
    this.eMenu.classList.toggle("ag-header-menu-always-show", this.currentSuppressMenuHide);
  }
  onMenuKeyboardShortcut(isFilterShortcut) {
    const column = this.params.column;
    const isLegacyMenuEnabled = this.menuService.isLegacyMenuEnabled();
    if (isFilterShortcut && !isLegacyMenuEnabled) {
      if (this.menuService.isFilterMenuInHeaderEnabled(column)) {
        this.params.showFilter(this.eFilterButton ?? this.eMenu ?? this.getGui());
        return true;
      }
    } else if (this.params.enableMenu) {
      this.params.showColumnMenu(this.eMenu ?? this.eFilterButton ?? this.getGui());
      return true;
    }
    return false;
  }
  workOutSort() {
    return this.params.enableSorting;
  }
  setupSort() {
    this.currentSort = this.params.enableSorting;
    if (!this.eSortIndicator) {
      this.eSortIndicator = this.createBean(new SortIndicatorComp(true));
      this.eSortIndicator.attachCustomElements(
        this.eSortOrder,
        this.eSortAsc,
        this.eSortDesc,
        this.eSortMixed,
        this.eSortNone
      );
    }
    this.eSortIndicator.setupSort(this.params.column);
    if (!this.currentSort) {
      return;
    }
    this.addManagedListeners(this.params.column, {
      movingChanged: () => {
        this.lastMovingChanged = (/* @__PURE__ */ new Date()).getTime();
      }
    });
    if (this.eLabel) {
      this.addManagedElementListeners(this.eLabel, {
        click: (event) => {
          const moving = this.params.column.isMoving();
          const nowTime = (/* @__PURE__ */ new Date()).getTime();
          const movedRecently = nowTime - this.lastMovingChanged < 50;
          const columnMoving = moving || movedRecently;
          if (!columnMoving) {
            const sortUsingCtrl = this.gos.get("multiSortKey") === "ctrl";
            const multiSort = sortUsingCtrl ? event.ctrlKey || event.metaKey : event.shiftKey;
            this.params.progressSort(multiSort);
          }
        }
      });
    }
    const onSortingChanged = () => {
      const sort = this.params.column.getSort();
      this.addOrRemoveCssClass("ag-header-cell-sorted-asc", sort === "asc");
      this.addOrRemoveCssClass("ag-header-cell-sorted-desc", sort === "desc");
      this.addOrRemoveCssClass("ag-header-cell-sorted-none", !sort);
      if (this.params.column.getColDef().showRowGroup) {
        const sourceColumns = this.funcColsService.getSourceColumnsForGroupColumn(
          this.params.column
        );
        const sortDirectionsMatch = sourceColumns?.every(
          (sourceCol) => this.params.column.getSort() == sourceCol.getSort()
        );
        const isMultiSorting = !sortDirectionsMatch;
        this.addOrRemoveCssClass("ag-header-cell-sorted-mixed", isMultiSorting);
      }
    };
    this.addManagedEventListeners({
      sortChanged: onSortingChanged,
      columnRowGroupChanged: onSortingChanged
    });
  }
  setupFilterIcon() {
    if (!this.eFilter) {
      return;
    }
    this.configureFilter(this.params.enableFilterIcon, this.eFilter, this.onFilterChangedIcon.bind(this));
  }
  setupFilterButton() {
    if (!this.eFilterButton) {
      return;
    }
    const configured = this.configureFilter(
      this.params.enableFilterButton,
      this.eFilterButton,
      this.onFilterChangedButton.bind(this)
    );
    if (configured) {
      this.addManagedElementListeners(this.eFilterButton, {
        click: () => this.params.showFilter(this.eFilterButton)
      });
    } else {
      this.eFilterButton = void 0;
    }
  }
  configureFilter(enabled, element, filterChangedCallback) {
    if (!enabled) {
      _removeFromParent(element);
      return false;
    }
    const column = this.params.column;
    this.addInIcon("filter", element, column);
    this.addManagedListeners(column, { filterChanged: filterChangedCallback });
    filterChangedCallback();
    return true;
  }
  onFilterChangedIcon() {
    const filterPresent = this.params.column.isFilterActive();
    _setDisplayed(this.eFilter, filterPresent, { skipAriaHidden: true });
  }
  onFilterChangedButton() {
    const filterPresent = this.params.column.isFilterActive();
    this.eFilterButton.classList.toggle("ag-filter-active", filterPresent);
  }
  getAnchorElementForMenu(isFilter) {
    if (isFilter) {
      return this.eFilterButton ?? this.eMenu ?? this.getGui();
    }
    return this.eMenu ?? this.eFilterButton ?? this.getGui();
  }
};

// community-modules/core/src/headerRendering/cells/columnGroup/headerGroupComp.ts
var HeaderGroupComp = class extends Component {
  constructor() {
    super(
      /* html */
      `<div class="ag-header-group-cell-label" role="presentation">
            <span data-ref="agLabel" class="ag-header-group-text" role="presentation"></span>
            <span data-ref="agOpened" class="ag-header-icon ag-header-expand-icon ag-header-expand-icon-expanded"></span>
            <span data-ref="agClosed" class="ag-header-icon ag-header-expand-icon ag-header-expand-icon-collapsed"></span>
        </div>`
    );
    this.agOpened = RefPlaceholder;
    this.agClosed = RefPlaceholder;
    this.agLabel = RefPlaceholder;
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
  init(params) {
    this.params = params;
    this.checkWarnings();
    this.setupLabel();
    this.addGroupExpandIcon();
    this.setupExpandIcons();
  }
  checkWarnings() {
    const paramsAny = this.params;
    if (paramsAny.template) {
      _warnOnce(
        `A template was provided for Header Group Comp - templates are only supported for Header Comps (not groups)`
      );
    }
  }
  setupExpandIcons() {
    this.addInIcon("columnGroupOpened", this.agOpened);
    this.addInIcon("columnGroupClosed", this.agClosed);
    const expandAction = (event) => {
      if (_isStopPropagationForAgGrid(event)) {
        return;
      }
      const newExpandedValue = !this.params.columnGroup.isExpanded();
      this.columnModel.setColumnGroupOpened(
        this.params.columnGroup.getProvidedColumnGroup(),
        newExpandedValue,
        "uiColumnExpanded"
      );
    };
    this.addTouchAndClickListeners(this.agClosed, expandAction);
    this.addTouchAndClickListeners(this.agOpened, expandAction);
    const stopPropagationAction = (event) => {
      _stopPropagationForAgGrid(event);
    };
    this.addManagedElementListeners(this.agClosed, { dblclick: stopPropagationAction });
    this.addManagedElementListeners(this.agOpened, { dblclick: stopPropagationAction });
    this.addManagedElementListeners(this.getGui(), { dblclick: expandAction });
    this.updateIconVisibility();
    const providedColumnGroup = this.params.columnGroup.getProvidedColumnGroup();
    const updateIcon = this.updateIconVisibility.bind(this);
    this.addManagedListeners(providedColumnGroup, {
      expandedChanged: updateIcon,
      expandableChanged: updateIcon
    });
  }
  addTouchAndClickListeners(eElement, action) {
    const touchListener = new TouchListener(eElement, true);
    this.addManagedListeners(touchListener, { tap: action });
    this.addDestroyFunc(() => touchListener.destroy());
    this.addManagedElementListeners(eElement, { click: action });
  }
  updateIconVisibility() {
    const columnGroup = this.params.columnGroup;
    if (columnGroup.isExpandable()) {
      const expanded = this.params.columnGroup.isExpanded();
      _setDisplayed(this.agOpened, expanded);
      _setDisplayed(this.agClosed, !expanded);
    } else {
      _setDisplayed(this.agOpened, false);
      _setDisplayed(this.agClosed, false);
    }
  }
  addInIcon(iconName, element) {
    const eIcon = _createIconNoSpan(iconName, this.gos, null);
    if (eIcon) {
      element.appendChild(eIcon);
    }
  }
  addGroupExpandIcon() {
    if (!this.params.columnGroup.isExpandable()) {
      _setDisplayed(this.agOpened, false);
      _setDisplayed(this.agClosed, false);
      return;
    }
  }
  setupLabel() {
    const { displayName, columnGroup } = this.params;
    if (_exists(displayName)) {
      const displayNameSanitised = _escapeString(displayName, true);
      this.agLabel.textContent = displayNameSanitised;
    }
    this.addOrRemoveCssClass("ag-sticky-label", !columnGroup.getColGroupDef()?.suppressStickyLabel);
  }
};

// community-modules/core/src/modules/moduleNames.ts
var ModuleNames = /* @__PURE__ */ ((ModuleNames2) => {
  ModuleNames2["CommunityCoreModule"] = "@ag-grid-community/core";
  ModuleNames2["InfiniteRowModelModule"] = "@ag-grid-community/infinite-row-model";
  ModuleNames2["ClientSideRowModelModule"] = "@ag-grid-community/client-side-row-model";
  ModuleNames2["CsvExportModule"] = "@ag-grid-community/csv-export";
  ModuleNames2["EnterpriseCoreModule"] = "@ag-grid-enterprise/core";
  ModuleNames2["RowGroupingModule"] = "@ag-grid-enterprise/row-grouping";
  ModuleNames2["ColumnsToolPanelModule"] = "@ag-grid-enterprise/column-tool-panel";
  ModuleNames2["FiltersToolPanelModule"] = "@ag-grid-enterprise/filter-tool-panel";
  ModuleNames2["MenuModule"] = "@ag-grid-enterprise/menu";
  ModuleNames2["SetFilterModule"] = "@ag-grid-enterprise/set-filter";
  ModuleNames2["MultiFilterModule"] = "@ag-grid-enterprise/multi-filter";
  ModuleNames2["StatusBarModule"] = "@ag-grid-enterprise/status-bar";
  ModuleNames2["SideBarModule"] = "@ag-grid-enterprise/side-bar";
  ModuleNames2["RangeSelectionModule"] = "@ag-grid-enterprise/range-selection";
  ModuleNames2["MasterDetailModule"] = "@ag-grid-enterprise/master-detail";
  ModuleNames2["RichSelectModule"] = "@ag-grid-enterprise/rich-select";
  ModuleNames2["GridChartsModule"] = "@ag-grid-enterprise/charts";
  ModuleNames2["ViewportRowModelModule"] = "@ag-grid-enterprise/viewport-row-model";
  ModuleNames2["ServerSideRowModelModule"] = "@ag-grid-enterprise/server-side-row-model";
  ModuleNames2["ExcelExportModule"] = "@ag-grid-enterprise/excel-export";
  ModuleNames2["ClipboardModule"] = "@ag-grid-enterprise/clipboard";
  ModuleNames2["SparklinesModule"] = "@ag-grid-enterprise/sparklines";
  ModuleNames2["AdvancedFilterModule"] = "@ag-grid-enterprise/advanced-filter";
  ModuleNames2["AngularModule"] = "@ag-grid-community/angular";
  ModuleNames2["ReactModule"] = "@ag-grid-community/react";
  ModuleNames2["VueModule"] = "@ag-grid-community/vue";
  return ModuleNames2;
})(ModuleNames || {});

// community-modules/core/src/modules/moduleRegistry.ts
var _ModuleRegistry = class _ModuleRegistry {
  /**
   * Globally register the given module for all grids.
   * @param module - module to register
   */
  static register(module) {
    _ModuleRegistry.__register(module, true, void 0);
  }
  /**
   * Globally register the given modules for all grids.
   * @param modules - modules to register
   */
  static registerModules(modules) {
    _ModuleRegistry.__registerModules(modules, true, void 0);
  }
  /** AG GRID INTERNAL - Module registration helper. */
  static __register(module, moduleBased, gridId) {
    _ModuleRegistry.runVersionChecks(module);
    if (gridId !== void 0) {
      _ModuleRegistry.areGridScopedModules = true;
      if (_ModuleRegistry.gridModulesMap[gridId] === void 0) {
        _ModuleRegistry.gridModulesMap[gridId] = {};
      }
      _ModuleRegistry.gridModulesMap[gridId][module.moduleName] = module;
    } else {
      _ModuleRegistry.globalModulesMap[module.moduleName] = module;
    }
    _ModuleRegistry.setModuleBased(moduleBased);
  }
  /** AG GRID INTERNAL - Unregister grid scoped module. */
  static __unRegisterGridModules(gridId) {
    delete _ModuleRegistry.gridModulesMap[gridId];
  }
  /** AG GRID INTERNAL - Module registration helper. */
  static __registerModules(modules, moduleBased, gridId) {
    _ModuleRegistry.setModuleBased(moduleBased);
    if (!modules) {
      return;
    }
    modules.forEach((module) => _ModuleRegistry.__register(module, moduleBased, gridId));
  }
  static isValidModuleVersion(module) {
    const [moduleMajor, moduleMinor] = module.version.split(".") || [];
    const [currentModuleMajor, currentModuleMinor] = _ModuleRegistry.currentModuleVersion.split(".") || [];
    return moduleMajor === currentModuleMajor && moduleMinor === currentModuleMinor;
  }
  static runVersionChecks(module) {
    if (!_ModuleRegistry.currentModuleVersion) {
      _ModuleRegistry.currentModuleVersion = module.version;
    }
    const errorMsg = (details) => `You are using incompatible versions of AG Grid modules. Major and minor versions should always match across modules. ${details} Please update all modules to the same version.`;
    if (!module.version) {
      _errorOnce(errorMsg(`'${module.moduleName}' is incompatible.`));
    } else if (!_ModuleRegistry.isValidModuleVersion(module)) {
      _errorOnce(
        errorMsg(
          `'${module.moduleName}' is version ${module.version} but the other modules are version ${_ModuleRegistry.currentModuleVersion}.`
        )
      );
    }
    if (module.validate) {
      const result = module.validate();
      if (!result.isValid) {
        const errorResult = result;
        _errorOnce(`${errorResult.message}`);
      }
    }
  }
  static setModuleBased(moduleBased) {
    if (_ModuleRegistry.moduleBased === void 0) {
      _ModuleRegistry.moduleBased = moduleBased;
    } else {
      if (_ModuleRegistry.moduleBased !== moduleBased) {
        _errorOnce(
          `AG Grid: You are mixing modules (i.e. @ag-grid-community/core) and packages (ag-grid-community) - you can only use one or the other of these mechanisms.`
        );
        _errorOnce("Please see https://www.ag-grid.com/javascript-grid/modules/ for more information.");
      }
    }
  }
  /**
   * AG GRID INTERNAL - Set if files are being served from a single UMD bundle to provide accurate enterprise upgrade steps.
   */
  static __setIsBundled() {
    _ModuleRegistry.isBundled = true;
  }
  /** AG GRID INTERNAL - Assert a given module has been register, globally or individually with this grid. */
  static __assertRegistered(moduleName, reason, gridId) {
    if (this.__isRegistered(moduleName, gridId)) {
      return true;
    }
    let warningMessage;
    if (_ModuleRegistry.isBundled) {
      {
        warningMessage = `AG Grid: unable to use ${reason} as 'ag-grid-enterprise' has not been loaded. Check you are using the Enterprise bundle:
        
        <script src="https://cdn.jsdelivr.net/npm/ag-grid-enterprise@AG_GRID_VERSION/dist/ag-grid-enterprise.min.js"><\/script>
        
For more info see: https://ag-grid.com/javascript-data-grid/getting-started/#getting-started-with-ag-grid-enterprise`;
      }
    } else if (_ModuleRegistry.moduleBased || _ModuleRegistry.moduleBased === void 0) {
      const modName = Object.entries(ModuleNames).find(([k, v]) => v === moduleName)?.[0];
      warningMessage = `AG Grid: unable to use ${reason} as the ${modName} is not registered${_ModuleRegistry.areGridScopedModules ? ` for gridId: ${gridId}` : ""}. Check if you have registered the module:
           
    import { ModuleRegistry } from '@ag-grid-community/core';
    import { ${modName} } from '${moduleName}';
    
    ModuleRegistry.registerModules([ ${modName} ]);

For more info see: https://www.ag-grid.com/javascript-grid/modules/`;
    } else {
      warningMessage = `AG Grid: unable to use ${reason} as package 'ag-grid-enterprise' has not been imported. Check that you have imported the package:
            
    import 'ag-grid-enterprise';`;
    }
    _errorOnce(warningMessage);
    return false;
  }
  /** AG GRID INTERNAL - Is the given module registered, globally or individually with this grid. */
  static __isRegistered(moduleName, gridId) {
    return !!_ModuleRegistry.globalModulesMap[moduleName] || !!_ModuleRegistry.gridModulesMap[gridId]?.[moduleName];
  }
  /** AG GRID INTERNAL - Get all registered modules globally / individually for this grid. */
  static __getRegisteredModules(gridId) {
    return [..._values(_ModuleRegistry.globalModulesMap), ..._values(_ModuleRegistry.gridModulesMap[gridId] || {})];
  }
  /** AG GRID INTERNAL - Get the list of modules registered individually for this grid. */
  static __getGridRegisteredModules(gridId) {
    return _values(_ModuleRegistry.gridModulesMap[gridId] ?? {}) || [];
  }
  /** INTERNAL */
  static __isPackageBased() {
    return !_ModuleRegistry.moduleBased;
  }
};
// having in a map a) removes duplicates and b) allows fast lookup
_ModuleRegistry.globalModulesMap = {};
_ModuleRegistry.gridModulesMap = {};
_ModuleRegistry.areGridScopedModules = false;
var ModuleRegistry = _ModuleRegistry;

// community-modules/core/src/rendering/cellRenderers/animateShowChangeCellRenderer.ts
var ARROW_UP = "\u2191";
var ARROW_DOWN = "\u2193";
var AnimateShowChangeCellRenderer = class extends Component {
  constructor() {
    super();
    this.refreshCount = 0;
    const template = document.createElement("span");
    const delta = document.createElement("span");
    delta.setAttribute("class", "ag-value-change-delta");
    const value = document.createElement("span");
    value.setAttribute("class", "ag-value-change-value");
    template.appendChild(delta);
    template.appendChild(value);
    this.setTemplateFromElement(template);
  }
  wireBeans(beans) {
    this.filterManager = beans.filterManager;
  }
  init(params) {
    this.eValue = this.queryForHtmlElement(".ag-value-change-value");
    this.eDelta = this.queryForHtmlElement(".ag-value-change-delta");
    this.refresh(params, true);
  }
  showDelta(params, delta) {
    const absDelta = Math.abs(delta);
    const valueFormatted = params.formatValue(absDelta);
    const valueToUse = _exists(valueFormatted) ? valueFormatted : absDelta;
    const deltaUp = delta >= 0;
    if (deltaUp) {
      this.eDelta.textContent = ARROW_UP + valueToUse;
    } else {
      this.eDelta.textContent = ARROW_DOWN + valueToUse;
    }
    this.eDelta.classList.toggle("ag-value-change-delta-up", deltaUp);
    this.eDelta.classList.toggle("ag-value-change-delta-down", !deltaUp);
  }
  setTimerToRemoveDelta() {
    this.refreshCount++;
    const refreshCountCopy = this.refreshCount;
    this.getFrameworkOverrides().wrapIncoming(() => {
      window.setTimeout(() => {
        if (refreshCountCopy === this.refreshCount) {
          this.hideDeltaValue();
        }
      }, 2e3);
    });
  }
  hideDeltaValue() {
    this.eValue.classList.remove("ag-value-change-value-highlight");
    _clearElement(this.eDelta);
  }
  refresh(params, isInitialRender = false) {
    const value = params.value;
    if (value === this.lastValue) {
      return false;
    }
    if (_exists(params.valueFormatted)) {
      this.eValue.textContent = params.valueFormatted;
    } else if (_exists(params.value)) {
      this.eValue.textContent = value;
    } else {
      _clearElement(this.eValue);
    }
    if (this.filterManager?.isSuppressFlashingCellsBecauseFiltering()) {
      return false;
    }
    if (typeof value === "number" && typeof this.lastValue === "number") {
      const delta = value - this.lastValue;
      this.showDelta(params, delta);
    }
    if (this.lastValue) {
      this.eValue.classList.add("ag-value-change-value-highlight");
    }
    if (!isInitialRender) {
      this.setTimerToRemoveDelta();
    }
    this.lastValue = value;
    return true;
  }
};

// community-modules/core/src/rendering/cellRenderers/animateSlideCellRenderer.ts
var AnimateSlideCellRenderer = class extends Component {
  constructor() {
    super();
    this.refreshCount = 0;
    const template = document.createElement("span");
    const slide = document.createElement("span");
    slide.setAttribute("class", "ag-value-slide-current");
    template.appendChild(slide);
    this.setTemplateFromElement(template);
    this.eCurrent = this.queryForHtmlElement(".ag-value-slide-current");
  }
  wireBeans(beans) {
    this.filterManager = beans.filterManager;
  }
  init(params) {
    this.refresh(params, true);
  }
  addSlideAnimation() {
    this.refreshCount++;
    const refreshCountCopy = this.refreshCount;
    if (this.ePrevious) {
      this.getGui().removeChild(this.ePrevious);
    }
    const prevElement = document.createElement("span");
    prevElement.setAttribute("class", "ag-value-slide-previous ag-value-slide-out");
    this.ePrevious = prevElement;
    this.ePrevious.textContent = this.eCurrent.textContent;
    this.getGui().insertBefore(this.ePrevious, this.eCurrent);
    this.getFrameworkOverrides().wrapIncoming(() => {
      window.setTimeout(() => {
        if (refreshCountCopy !== this.refreshCount) {
          return;
        }
        this.ePrevious.classList.add("ag-value-slide-out-end");
      }, 50);
      window.setTimeout(() => {
        if (refreshCountCopy !== this.refreshCount) {
          return;
        }
        this.getGui().removeChild(this.ePrevious);
        this.ePrevious = null;
      }, 3e3);
    });
  }
  refresh(params, isInitialRender = false) {
    let value = params.value;
    if (_missing(value)) {
      value = "";
    }
    if (value === this.lastValue) {
      return false;
    }
    if (this.filterManager?.isSuppressFlashingCellsBecauseFiltering()) {
      return false;
    }
    if (!isInitialRender) {
      this.addSlideAnimation();
    }
    this.lastValue = value;
    if (_exists(params.valueFormatted)) {
      this.eCurrent.textContent = params.valueFormatted;
    } else if (_exists(params.value)) {
      this.eCurrent.textContent = value;
    } else {
      _clearElement(this.eCurrent);
    }
    return true;
  }
};

// community-modules/core/src/constants/keyCode.ts
var KeyCode = class {
};
KeyCode.BACKSPACE = "Backspace";
KeyCode.TAB = "Tab";
KeyCode.ENTER = "Enter";
KeyCode.ESCAPE = "Escape";
KeyCode.SPACE = " ";
KeyCode.LEFT = "ArrowLeft";
KeyCode.UP = "ArrowUp";
KeyCode.RIGHT = "ArrowRight";
KeyCode.DOWN = "ArrowDown";
KeyCode.DELETE = "Delete";
KeyCode.F2 = "F2";
KeyCode.PAGE_UP = "PageUp";
KeyCode.PAGE_DOWN = "PageDown";
KeyCode.PAGE_HOME = "Home";
KeyCode.PAGE_END = "End";
// these should be used with `event.code` instead of `event.key`
// as `event.key` changes when non-latin keyboards are used
KeyCode.A = "KeyA";
KeyCode.C = "KeyC";
KeyCode.D = "KeyD";
KeyCode.V = "KeyV";
KeyCode.X = "KeyX";
KeyCode.Y = "KeyY";
KeyCode.Z = "KeyZ";

// community-modules/core/src/widgets/agAbstractLabel.ts
var AgAbstractLabel = class extends Component {
  constructor(config, template, components) {
    super(template, components);
    this.labelSeparator = "";
    this.labelAlignment = "left";
    this.disabled = false;
    this.label = "";
    this.config = config || {};
  }
  postConstruct() {
    this.addCssClass("ag-labeled");
    this.eLabel.classList.add("ag-label");
    const { labelSeparator, label, labelWidth, labelAlignment, disabled } = this.config;
    if (disabled != null) {
      this.setDisabled(disabled);
    }
    if (labelSeparator != null) {
      this.setLabelSeparator(labelSeparator);
    }
    if (label != null) {
      this.setLabel(label);
    }
    if (labelWidth != null) {
      this.setLabelWidth(labelWidth);
    }
    this.setLabelAlignment(labelAlignment || this.labelAlignment);
    this.refreshLabel();
  }
  refreshLabel() {
    _clearElement(this.eLabel);
    if (typeof this.label === "string") {
      this.eLabel.innerText = this.label + this.labelSeparator;
    } else if (this.label) {
      this.eLabel.appendChild(this.label);
    }
    if (this.label === "") {
      _setDisplayed(this.eLabel, false);
      _setAriaRole(this.eLabel, "presentation");
    } else {
      _setDisplayed(this.eLabel, true);
      _setAriaRole(this.eLabel, null);
    }
  }
  setLabelSeparator(labelSeparator) {
    if (this.labelSeparator === labelSeparator) {
      return this;
    }
    this.labelSeparator = labelSeparator;
    if (this.label != null) {
      this.refreshLabel();
    }
    return this;
  }
  getLabelId() {
    this.eLabel.id = this.eLabel.id || `ag-${this.getCompId()}-label`;
    return this.eLabel.id;
  }
  getLabel() {
    return this.label;
  }
  setLabel(label) {
    if (this.label === label) {
      return this;
    }
    this.label = label;
    this.refreshLabel();
    return this;
  }
  setLabelAlignment(alignment) {
    const eGui = this.getGui();
    const eGuiClassList = eGui.classList;
    eGuiClassList.toggle("ag-label-align-left", alignment === "left");
    eGuiClassList.toggle("ag-label-align-right", alignment === "right");
    eGuiClassList.toggle("ag-label-align-top", alignment === "top");
    return this;
  }
  setLabelEllipsis(hasEllipsis) {
    this.eLabel.classList.toggle("ag-label-ellipsis", hasEllipsis);
    return this;
  }
  setLabelWidth(width) {
    if (this.label == null) {
      return this;
    }
    _setElementWidth(this.eLabel, width);
    return this;
  }
  setDisabled(disabled) {
    disabled = !!disabled;
    const element = this.getGui();
    _setDisabled(element, disabled);
    element.classList.toggle("ag-disabled", disabled);
    this.disabled = disabled;
    return this;
  }
  isDisabled() {
    return !!this.disabled;
  }
};

// community-modules/core/src/widgets/agAbstractField.ts
var AgAbstractField = class extends AgAbstractLabel {
  constructor(config, template, components, className) {
    super(config, template, components);
    this.className = className;
  }
  postConstruct() {
    super.postConstruct();
    const { width, value, onValueChange } = this.config;
    if (width != null) {
      this.setWidth(width);
    }
    if (value != null) {
      this.setValue(value);
    }
    if (onValueChange != null) {
      this.onValueChange(onValueChange);
    }
    if (this.className) {
      this.addCssClass(this.className);
    }
    this.refreshAriaLabelledBy();
  }
  setLabel(label) {
    super.setLabel(label);
    this.refreshAriaLabelledBy();
    return this;
  }
  refreshAriaLabelledBy() {
    const ariaEl = this.getAriaElement();
    const labelId = this.getLabelId();
    const label = this.getLabel();
    if (label == null || label == "" || _getAriaLabel(ariaEl) !== null) {
      _setAriaLabelledBy(ariaEl, "");
    } else {
      _setAriaLabelledBy(ariaEl, labelId ?? "");
    }
  }
  setAriaLabel(label) {
    _setAriaLabel(this.getAriaElement(), label);
    this.refreshAriaLabelledBy();
    return this;
  }
  onValueChange(callbackFn) {
    this.addManagedListeners(this, { fieldValueChanged: () => callbackFn(this.getValue()) });
    return this;
  }
  getWidth() {
    return this.getGui().clientWidth;
  }
  setWidth(width) {
    _setFixedWidth(this.getGui(), width);
    return this;
  }
  getPreviousValue() {
    return this.previousValue;
  }
  getValue() {
    return this.value;
  }
  setValue(value, silent) {
    if (this.value === value) {
      return this;
    }
    this.previousValue = this.value;
    this.value = value;
    if (!silent) {
      this.dispatchLocalEvent({ type: "fieldValueChanged" });
    }
    return this;
  }
};

// community-modules/core/src/widgets/agAbstractInputField.ts
var AgAbstractInputField = class extends AgAbstractField {
  constructor(config, className, inputType = "text", displayFieldTag = "input") {
    super(
      config,
      config?.template ?? /* html */
      `
            <div role="presentation">
                <div data-ref="eLabel" class="ag-input-field-label"></div>
                <div data-ref="eWrapper" class="ag-wrapper ag-input-wrapper" role="presentation">
                    <${displayFieldTag} data-ref="eInput" class="ag-input-field-input"></${displayFieldTag}>
                </div>
            </div>`,
      [],
      className
    );
    this.inputType = inputType;
    this.displayFieldTag = displayFieldTag;
    this.eLabel = RefPlaceholder;
    this.eWrapper = RefPlaceholder;
    this.eInput = RefPlaceholder;
  }
  postConstruct() {
    super.postConstruct();
    this.setInputType();
    this.eLabel.classList.add(`${this.className}-label`);
    this.eWrapper.classList.add(`${this.className}-input-wrapper`);
    this.eInput.classList.add(`${this.className}-input`);
    this.addCssClass("ag-input-field");
    this.eInput.id = this.eInput.id || `ag-${this.getCompId()}-input`;
    const { inputName, inputWidth } = this.config;
    if (inputName != null) {
      this.setInputName(inputName);
    }
    if (inputWidth != null) {
      this.setInputWidth(inputWidth);
    }
    this.addInputListeners();
    this.activateTabIndex([this.eInput]);
  }
  addInputListeners() {
    this.addManagedElementListeners(this.eInput, { input: (e) => this.setValue(e.target.value) });
  }
  setInputType() {
    if (this.displayFieldTag === "input") {
      this.eInput.setAttribute("type", this.inputType);
    }
  }
  getInputElement() {
    return this.eInput;
  }
  setInputWidth(width) {
    _setElementWidth(this.eWrapper, width);
    return this;
  }
  setInputName(name) {
    this.getInputElement().setAttribute("name", name);
    return this;
  }
  getFocusableElement() {
    return this.eInput;
  }
  setMaxLength(length) {
    const eInput = this.eInput;
    eInput.maxLength = length;
    return this;
  }
  setInputPlaceholder(placeholder) {
    _addOrRemoveAttribute(this.eInput, "placeholder", placeholder);
    return this;
  }
  setInputAriaLabel(label) {
    _setAriaLabel(this.eInput, label);
    this.refreshAriaLabelledBy();
    return this;
  }
  setDisabled(disabled) {
    _setDisabled(this.eInput, disabled);
    return super.setDisabled(disabled);
  }
  setAutoComplete(value) {
    if (value === true) {
      _addOrRemoveAttribute(this.eInput, "autocomplete", null);
    } else {
      const autoCompleteValue = typeof value === "string" ? value : "off";
      _addOrRemoveAttribute(this.eInput, "autocomplete", autoCompleteValue);
    }
    return this;
  }
};

// community-modules/core/src/widgets/agCheckbox.ts
var AgCheckbox = class extends AgAbstractInputField {
  constructor(config, className = "ag-checkbox", inputType = "checkbox") {
    super(config, className, inputType);
    this.labelAlignment = "right";
    this.selected = false;
    this.readOnly = false;
    this.passive = false;
  }
  postConstruct() {
    super.postConstruct();
    const { readOnly, passive } = this.config;
    if (typeof readOnly === "boolean")
      this.setReadOnly(readOnly);
    if (typeof passive === "boolean")
      this.setPassive(passive);
  }
  addInputListeners() {
    this.addManagedElementListeners(this.eInput, { click: this.onCheckboxClick.bind(this) });
    this.addManagedElementListeners(this.eLabel, { click: this.toggle.bind(this) });
  }
  getNextValue() {
    return this.selected === void 0 ? true : !this.selected;
  }
  setPassive(passive) {
    this.passive = passive;
  }
  isReadOnly() {
    return this.readOnly;
  }
  setReadOnly(readOnly) {
    this.eWrapper.classList.toggle("ag-disabled", readOnly);
    this.eInput.disabled = readOnly;
    this.readOnly = readOnly;
  }
  setDisabled(disabled) {
    this.eWrapper.classList.toggle("ag-disabled", disabled);
    return super.setDisabled(disabled);
  }
  toggle() {
    if (this.eInput.disabled) {
      return;
    }
    const previousValue = this.isSelected();
    const nextValue = this.getNextValue();
    if (this.passive) {
      this.dispatchChange(nextValue, previousValue);
    } else {
      this.setValue(nextValue);
    }
  }
  getValue() {
    return this.isSelected();
  }
  setValue(value, silent) {
    this.refreshSelectedClass(value);
    this.setSelected(value, silent);
    return this;
  }
  setName(name) {
    const input = this.getInputElement();
    input.name = name;
    return this;
  }
  isSelected() {
    return this.selected;
  }
  setSelected(selected, silent) {
    if (this.isSelected() === selected) {
      return;
    }
    this.previousValue = this.isSelected();
    selected = this.selected = typeof selected === "boolean" ? selected : void 0;
    this.eInput.checked = selected;
    this.eInput.indeterminate = selected === void 0;
    if (!silent) {
      this.dispatchChange(this.selected, this.previousValue);
    }
  }
  dispatchChange(selected, previousValue, event) {
    this.dispatchLocalEvent({ type: "fieldValueChanged", selected, previousValue, event });
    const input = this.getInputElement();
    const checkboxChangedEvent = {
      type: "checkboxChanged",
      id: input.id,
      name: input.name,
      selected,
      previousValue
    };
    this.eventService.dispatchEvent(checkboxChangedEvent);
  }
  onCheckboxClick(e) {
    if (this.passive || this.eInput.disabled) {
      return;
    }
    const previousValue = this.isSelected();
    const selected = this.selected = e.target.checked;
    this.refreshSelectedClass(selected);
    this.dispatchChange(selected, previousValue, e);
  }
  refreshSelectedClass(value) {
    this.eWrapper.classList.toggle("ag-checked", value === true);
    this.eWrapper.classList.toggle("ag-indeterminate", value == null);
  }
};
var AgCheckboxSelector = {
  selector: "AG-CHECKBOX",
  component: AgCheckbox
};

// community-modules/core/src/rendering/cellRenderers/checkboxCellRenderer.ts
var CheckboxCellRenderer = class extends Component {
  constructor() {
    super(
      /* html*/
      `
            <div class="ag-cell-wrapper ag-checkbox-cell" role="presentation">
                <ag-checkbox role="presentation" data-ref="eCheckbox"></ag-checkbox>
            </div>`,
      [AgCheckboxSelector]
    );
    this.eCheckbox = RefPlaceholder;
  }
  init(params) {
    this.params = params;
    this.updateCheckbox(params);
    const inputEl = this.eCheckbox.getInputElement();
    inputEl.setAttribute("tabindex", "-1");
    _setAriaLive(inputEl, "polite");
    this.addManagedListeners(inputEl, {
      click: (event) => {
        _stopPropagationForAgGrid(event);
        if (this.eCheckbox.isDisabled()) {
          return;
        }
        const isSelected = this.eCheckbox.getValue();
        this.onCheckboxChanged(isSelected);
      },
      dblclick: (event) => {
        _stopPropagationForAgGrid(event);
      }
    });
    this.addManagedElementListeners(this.params.eGridCell, {
      keydown: (event) => {
        if (event.key === KeyCode.SPACE && !this.eCheckbox.isDisabled()) {
          if (this.params.eGridCell === this.gos.getActiveDomElement()) {
            this.eCheckbox.toggle();
          }
          const isSelected = this.eCheckbox.getValue();
          this.onCheckboxChanged(isSelected);
          event.preventDefault();
        }
      }
    });
  }
  refresh(params) {
    this.params = params;
    this.updateCheckbox(params);
    return true;
  }
  updateCheckbox(params) {
    let isSelected;
    let displayed = true;
    if (params.node.group && params.column) {
      const colId = params.column.getColId();
      if (colId.startsWith(GROUP_AUTO_COLUMN_ID)) {
        isSelected = params.value == null || params.value === "" ? void 0 : params.value === "true";
      } else if (params.node.aggData && params.node.aggData[colId] !== void 0) {
        isSelected = params.value ?? void 0;
      } else {
        displayed = false;
      }
    } else {
      isSelected = params.value ?? void 0;
    }
    if (!displayed) {
      this.eCheckbox.setDisplayed(false);
      return;
    }
    this.eCheckbox.setValue(isSelected);
    const disabled = params.disabled != null ? params.disabled : !params.column?.isCellEditable(params.node);
    this.eCheckbox.setDisabled(disabled);
    const translate = this.localeService.getLocaleTextFunc();
    const stateName = _getAriaCheckboxStateName(translate, isSelected);
    const ariaLabel = disabled ? stateName : `${translate("ariaToggleCellValue", "Press SPACE to toggle cell value")} (${stateName})`;
    this.eCheckbox.setInputAriaLabel(ariaLabel);
  }
  onCheckboxChanged(isSelected) {
    const { column, node, value } = this.params;
    const eventStarted = {
      type: "cellEditingStarted",
      column,
      colDef: column?.getColDef(),
      data: node.data,
      node,
      rowIndex: node.rowIndex,
      rowPinned: node.rowPinned,
      value
    };
    this.eventService.dispatchEvent(eventStarted);
    const valueChanged = this.params.node.setDataValue(this.params.column, isSelected, "edit");
    const eventStopped = {
      type: "cellEditingStopped",
      column,
      colDef: column?.getColDef(),
      data: node.data,
      node,
      rowIndex: node.rowIndex,
      rowPinned: node.rowPinned,
      value,
      oldValue: value,
      newValue: isSelected,
      valueChanged
    };
    this.eventService.dispatchEvent(eventStopped);
  }
};

// community-modules/core/src/rendering/cellRenderers/loadingCellRenderer.ts
var LoadingCellRenderer = class extends Component {
  constructor() {
    super(
      /* html */
      `<div class="ag-loading">
            <span class="ag-loading-icon" data-ref="eLoadingIcon"></span>
            <span class="ag-loading-text" data-ref="eLoadingText"></span>
        </div>`
    );
    this.eLoadingIcon = RefPlaceholder;
    this.eLoadingText = RefPlaceholder;
  }
  init(params) {
    params.node.failedLoad ? this.setupFailed() : this.setupLoading();
  }
  setupFailed() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    this.eLoadingText.innerText = localeTextFunc("loadingError", "ERR");
  }
  setupLoading() {
    const eLoadingIcon = _createIconNoSpan("groupLoading", this.gos, null);
    if (eLoadingIcon) {
      this.eLoadingIcon.appendChild(eLoadingIcon);
    }
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    this.eLoadingText.innerText = localeTextFunc("loadingOoo", "Loading");
  }
  refresh(params) {
    return false;
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
};

// community-modules/core/src/rendering/cellRenderers/skeletonCellRenderer.ts
var SkeletonCellRenderer = class extends Component {
  constructor() {
    super(
      /* html */
      `<div class="ag-skeleton-container"></div>`
    );
  }
  init(params) {
    const id = `ag-cell-skeleton-renderer-${this.getCompId()}`;
    this.getGui().setAttribute("id", id);
    this.addDestroyFunc(() => _setAriaLabelledBy(params.eParentOfValue));
    _setAriaLabelledBy(params.eParentOfValue, id);
    params.node.failedLoad ? this.setupFailed() : this.setupLoading(params);
  }
  setupFailed() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    this.getGui().innerText = localeTextFunc("loadingError", "ERR");
    const ariaFailed = localeTextFunc("ariaSkeletonCellLoadingFailed", "Row failed to load");
    _setAriaLabel(this.getGui(), ariaFailed);
  }
  setupLoading(params) {
    const eDocument = this.gos.getDocument();
    const skeletonEffect = eDocument.createElement("div");
    skeletonEffect.classList.add("ag-skeleton-effect");
    const rowIndex = params.node.rowIndex;
    if (rowIndex != null) {
      const width = 75 + 25 * (rowIndex % 2 === 0 ? Math.sin(rowIndex) : Math.cos(rowIndex));
      skeletonEffect.style.width = `${width}%`;
    }
    this.getGui().appendChild(skeletonEffect);
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const ariaLoading = localeTextFunc("ariaSkeletonCellLoading", "Row data is loading");
    _setAriaLabel(this.getGui(), ariaLoading);
  }
  refresh(params) {
    return false;
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
};

// community-modules/core/src/rendering/overlays/overlayComponent.ts
var OverlayComponent = class extends Component {
  constructor() {
    super();
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
};

// community-modules/core/src/rendering/overlays/loadingOverlayComponent.ts
var LoadingOverlayComponent = class extends OverlayComponent {
  init() {
    const customTemplate = this.gos.get("overlayLoadingTemplate");
    this.setTemplate(
      customTemplate ?? /* html */
      `<span aria-live="polite" aria-atomic="true" class="ag-overlay-loading-center"></span>`
    );
    if (!customTemplate) {
      const localeTextFunc = this.localeService.getLocaleTextFunc();
      setTimeout(() => {
        this.getGui().textContent = localeTextFunc("loadingOoo", "Loading...");
      });
    }
  }
};

// community-modules/core/src/rendering/overlays/noRowsOverlayComponent.ts
var NoRowsOverlayComponent = class extends OverlayComponent {
  init() {
    const customTemplate = this.gos.get("overlayNoRowsTemplate");
    this.setTemplate(customTemplate ?? /* html */
    `<span class="ag-overlay-no-rows-center"></span>`);
    if (!customTemplate) {
      const localeTextFunc = this.localeService.getLocaleTextFunc();
      setTimeout(() => {
        this.getGui().textContent = localeTextFunc("noRowsToShow", "No Rows To Show");
      });
    }
  }
};

// community-modules/core/src/widgets/popupComponent.ts
var PopupComponent = class extends Component {
  isPopup() {
    return true;
  }
  setParentComponent(container) {
    container.addCssClass("ag-has-popup");
    super.setParentComponent(container);
  }
  destroy() {
    const parentComp = this.parentComponent;
    const hasParent = parentComp && parentComp.isAlive();
    if (hasParent) {
      parentComp.getGui().classList.remove("ag-has-popup");
    }
    super.destroy();
  }
};

// community-modules/core/src/rendering/tooltipComponent.ts
var TooltipComponent = class extends PopupComponent {
  constructor() {
    super(
      /* html */
      `<div class="ag-tooltip"></div>`
    );
  }
  // will need to type params
  init(params) {
    const { value } = params;
    this.getGui().textContent = _escapeString(value, true);
  }
};

// community-modules/core/src/utils/fuzzyMatch.ts
function _fuzzyCheckStrings(inputValues, validValues, allSuggestions) {
  const fuzzyMatches = {};
  const invalidInputs = inputValues.filter(
    (inputValue) => !validValues.some((validValue) => validValue === inputValue)
  );
  if (invalidInputs.length > 0) {
    invalidInputs.forEach(
      (invalidInput) => fuzzyMatches[invalidInput] = _fuzzySuggestions(invalidInput, allSuggestions).values
    );
  }
  return fuzzyMatches;
}
function _fuzzySuggestions(inputValue, allSuggestions, hideIrrelevant, filterByPercentageOfBestMatch) {
  let thisSuggestions = allSuggestions.map((text, idx) => ({
    value: text,
    relevance: stringWeightedDistances(inputValue.toLowerCase(), text.toLocaleLowerCase()),
    idx
  }));
  thisSuggestions.sort((a, b) => b.relevance - a.relevance);
  if (hideIrrelevant) {
    thisSuggestions = thisSuggestions.filter((suggestion) => suggestion.relevance !== 0);
  }
  if (thisSuggestions.length > 0 && filterByPercentageOfBestMatch && filterByPercentageOfBestMatch > 0) {
    const bestMatch = thisSuggestions[0].relevance;
    const limit = bestMatch * filterByPercentageOfBestMatch;
    thisSuggestions = thisSuggestions.filter((suggestion) => limit - suggestion.relevance < 0);
  }
  const values = [];
  const indices = [];
  for (const suggestion of thisSuggestions) {
    values.push(suggestion.value);
    indices.push(suggestion.idx);
  }
  return { values, indices };
}
function stringWeightedDistances(str1, str2) {
  const a = str1.replace(/\s/g, "");
  const b = str2.replace(/\s/g, "");
  let weight = 0;
  let lastIndex = -1;
  for (let i = 0; i < a.length; i++) {
    const idx = b.indexOf(a[i], lastIndex + 1);
    if (idx === -1) {
      continue;
    }
    lastIndex = idx;
    weight += 100 - lastIndex * 100 / 1e4 * 100;
  }
  return weight;
}

// community-modules/core/src/components/framework/userComponentRegistry.ts
var UserComponentRegistry = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "userComponentRegistry";
    this.agGridDefaults = {
      //header
      agColumnHeader: HeaderComp,
      agColumnGroupHeader: HeaderGroupComp,
      agSortIndicator: SortIndicatorComp,
      // renderers
      agAnimateShowChangeCellRenderer: AnimateShowChangeCellRenderer,
      agAnimateSlideCellRenderer: AnimateSlideCellRenderer,
      agLoadingCellRenderer: LoadingCellRenderer,
      agSkeletonCellRenderer: SkeletonCellRenderer,
      agCheckboxCellRenderer: CheckboxCellRenderer,
      //overlays
      agLoadingOverlay: LoadingOverlayComponent,
      agNoRowsOverlay: NoRowsOverlayComponent,
      // tooltips
      agTooltipComponent: TooltipComponent
    };
    /** Used to provide useful error messages if a user is trying to use an enterprise component without loading the module. */
    this.enterpriseAgDefaultCompsModule = {
      agSetColumnFilter: "@ag-grid-enterprise/set-filter" /* SetFilterModule */,
      agSetColumnFloatingFilter: "@ag-grid-enterprise/set-filter" /* SetFilterModule */,
      agMultiColumnFilter: "@ag-grid-enterprise/multi-filter" /* MultiFilterModule */,
      agMultiColumnFloatingFilter: "@ag-grid-enterprise/multi-filter" /* MultiFilterModule */,
      agGroupColumnFilter: "@ag-grid-enterprise/row-grouping" /* RowGroupingModule */,
      agGroupColumnFloatingFilter: "@ag-grid-enterprise/row-grouping" /* RowGroupingModule */,
      agGroupCellRenderer: "@ag-grid-enterprise/row-grouping" /* RowGroupingModule */,
      // Actually in enterprise core as used by MasterDetail too but best guess is they are grouping
      agGroupRowRenderer: "@ag-grid-enterprise/row-grouping" /* RowGroupingModule */,
      // Actually in enterprise core as used by MasterDetail but best guess is they are grouping
      agRichSelect: "@ag-grid-enterprise/rich-select" /* RichSelectModule */,
      agRichSelectCellEditor: "@ag-grid-enterprise/rich-select" /* RichSelectModule */,
      agDetailCellRenderer: "@ag-grid-enterprise/master-detail" /* MasterDetailModule */,
      agSparklineCellRenderer: "@ag-grid-enterprise/sparklines" /* SparklinesModule */
    };
    this.jsComps = {};
  }
  postConstruct() {
    const comps = this.gos.get("components");
    if (comps != null) {
      _iterateObject(comps, (key, component) => this.registerJsComponent(key, component));
    }
  }
  registerDefaultComponent(name, component) {
    this.agGridDefaults[name] = component;
  }
  registerJsComponent(name, component) {
    this.jsComps[name] = component;
  }
  retrieve(propertyName, name) {
    const createResult = (component, componentFromFramework) => ({
      componentFromFramework,
      component
    });
    const registeredViaFrameworkComp = this.getFrameworkOverrides().frameworkComponent(
      name,
      this.gos.get("components")
    );
    if (registeredViaFrameworkComp != null) {
      return createResult(registeredViaFrameworkComp, true);
    }
    const jsComponent = this.jsComps[name];
    if (jsComponent) {
      const isFwkComp = this.getFrameworkOverrides().isFrameworkComponent(jsComponent);
      return createResult(jsComponent, isFwkComp);
    }
    const defaultComponent = this.agGridDefaults[name];
    if (defaultComponent) {
      return createResult(defaultComponent, false);
    }
    const moduleForComponent = this.enterpriseAgDefaultCompsModule[name];
    if (moduleForComponent) {
      ModuleRegistry.__assertRegistered(
        moduleForComponent,
        `AG Grid '${propertyName}' component: ${name}`,
        this.gridId
      );
    } else {
      _doOnce(() => {
        this.warnAboutMissingComponent(propertyName, name);
      }, "MissingComp" + name);
    }
    return null;
  }
  warnAboutMissingComponent(propertyName, componentName) {
    const validComponents = [
      // Don't include the old names / internals in potential suggestions
      ...Object.keys(this.agGridDefaults).filter(
        (k) => !["agCellEditor", "agGroupRowRenderer", "agSortIndicator"].includes(k)
      ),
      ...Object.keys(this.jsComps)
    ];
    const suggestions = _fuzzySuggestions(componentName, validComponents, true, 0.8).values;
    _warnOnce(
      `Could not find '${componentName}' component. It was configured as "${propertyName}: '${componentName}'" but it wasn't found in the list of registered components.`
    );
    if (suggestions.length > 0) {
      _warnOnce(`         Did you mean: [${suggestions.slice(0, 3)}]?`);
    }
    _warnOnce(
      `If using a custom component check it has been registered as described in: ${this.getFrameworkOverrides().getDocLink("components/")}`
    );
  }
};

// community-modules/core/src/utils/promise.ts
var AgPromise = class _AgPromise {
  constructor(callback) {
    this.status = 0 /* IN_PROGRESS */;
    this.resolution = null;
    this.waiters = [];
    callback(
      (value) => this.onDone(value),
      (params) => this.onReject(params)
    );
  }
  static all(promises) {
    return promises.length ? new _AgPromise((resolve) => {
      let remainingToResolve = promises.length;
      const combinedValues = new Array(remainingToResolve);
      promises.forEach((promise, index) => {
        promise.then((value) => {
          combinedValues[index] = value;
          remainingToResolve--;
          if (remainingToResolve === 0) {
            resolve(combinedValues);
          }
        });
      });
    }) : _AgPromise.resolve();
  }
  static resolve(value = null) {
    return new _AgPromise((resolve) => resolve(value));
  }
  then(func) {
    return new _AgPromise((resolve) => {
      if (this.status === 1 /* RESOLVED */) {
        resolve(func(this.resolution));
      } else {
        this.waiters.push((value) => resolve(func(value)));
      }
    });
  }
  onDone(value) {
    this.status = 1 /* RESOLVED */;
    this.resolution = value;
    this.waiters.forEach((waiter) => waiter(value));
  }
  onReject(params) {
  }
};

// community-modules/core/src/components/framework/componentTypes.ts
var DateComponent = {
  propertyName: "dateComponent",
  cellRenderer: false
};
var HeaderComponent = {
  propertyName: "headerComponent",
  cellRenderer: false
};
var HeaderGroupComponent = {
  propertyName: "headerGroupComponent",
  cellRenderer: false
};
var CellRendererComponent = {
  propertyName: "cellRenderer",
  cellRenderer: true
};
var EditorRendererComponent = {
  propertyName: "cellRenderer",
  cellRenderer: false
};
var LoadingCellRendererComponent = {
  propertyName: "loadingCellRenderer",
  cellRenderer: true
};
var CellEditorComponent = {
  propertyName: "cellEditor",
  cellRenderer: false
};
var InnerRendererComponent = {
  propertyName: "innerRenderer",
  cellRenderer: true
};
var LoadingOverlayComponent2 = {
  propertyName: "loadingOverlayComponent",
  cellRenderer: false
};
var NoRowsOverlayComponent2 = {
  propertyName: "noRowsOverlayComponent",
  cellRenderer: false
};
var TooltipComponent2 = {
  propertyName: "tooltipComponent",
  cellRenderer: false
};
var FilterComponent = {
  propertyName: "filter",
  cellRenderer: false
};
var FloatingFilterComponent = {
  propertyName: "floatingFilterComponent",
  cellRenderer: false
};
var ToolPanelComponent = {
  propertyName: "toolPanel",
  cellRenderer: false
};
var StatusPanelComponent = {
  propertyName: "statusPanel",
  cellRenderer: false
};
var FullWidth = {
  propertyName: "fullWidthCellRenderer",
  cellRenderer: true
};
var FullWidthLoading = {
  propertyName: "loadingCellRenderer",
  cellRenderer: true
};
var FullWidthGroup = {
  propertyName: "groupRowRenderer",
  cellRenderer: true
};
var FullWidthDetail = {
  propertyName: "detailCellRenderer",
  cellRenderer: true
};
var MenuItemComponent = {
  propertyName: "menuItem",
  cellRenderer: false
};

// community-modules/core/src/components/framework/userComponentFactory.ts
var UserComponentFactory = class _UserComponentFactory extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "userComponentFactory";
  }
  wireBeans(beans) {
    this.agComponentUtils = beans.agComponentUtils;
    this.componentMetadataProvider = beans.componentMetadataProvider;
    this.userComponentRegistry = beans.userComponentRegistry;
    this.frameworkComponentWrapper = beans.frameworkComponentWrapper;
    this.gridOptions = beans.gridOptions;
  }
  getHeaderCompDetails(colDef, params) {
    return this.getCompDetails(colDef, HeaderComponent, "agColumnHeader", params);
  }
  getHeaderGroupCompDetails(params) {
    const colGroupDef = params.columnGroup.getColGroupDef();
    return this.getCompDetails(colGroupDef, HeaderGroupComponent, "agColumnGroupHeader", params);
  }
  // this one is unusual, as it can be LoadingCellRenderer, DetailCellRenderer, FullWidthCellRenderer or GroupRowRenderer.
  // so we have to pass the type in.
  getFullWidthCellRendererDetails(params) {
    return this.getCompDetails(this.gridOptions, FullWidth, null, params, true);
  }
  getFullWidthLoadingCellRendererDetails(params) {
    return this.getCompDetails(this.gridOptions, FullWidthLoading, "agLoadingCellRenderer", params, true);
  }
  getFullWidthGroupCellRendererDetails(params) {
    return this.getCompDetails(this.gridOptions, FullWidthGroup, "agGroupRowRenderer", params, true);
  }
  getFullWidthDetailCellRendererDetails(params) {
    return this.getCompDetails(this.gridOptions, FullWidthDetail, "agDetailCellRenderer", params, true);
  }
  // CELL RENDERER
  getInnerRendererDetails(def, params) {
    return this.getCompDetails(def, InnerRendererComponent, null, params);
  }
  getFullWidthGroupRowInnerCellRenderer(def, params) {
    return this.getCompDetails(def, InnerRendererComponent, null, params);
  }
  getCellRendererDetails(def, params) {
    return this.getCompDetails(def, CellRendererComponent, null, params);
  }
  getEditorRendererDetails(def, params) {
    return this.getCompDetails(def, EditorRendererComponent, null, params);
  }
  getLoadingCellRendererDetails(def, params) {
    return this.getCompDetails(def, LoadingCellRendererComponent, "agSkeletonCellRenderer", params, true);
  }
  // CELL EDITOR
  getCellEditorDetails(def, params) {
    return this.getCompDetails(def, CellEditorComponent, "agCellEditor", params, true);
  }
  // FILTER
  getFilterDetails(def, params, defaultFilter) {
    return this.getCompDetails(def, FilterComponent, defaultFilter, params, true);
  }
  getDateCompDetails(params) {
    return this.getCompDetails(this.gridOptions, DateComponent, "agDateInput", params, true);
  }
  getLoadingOverlayCompDetails(params) {
    return this.getCompDetails(this.gridOptions, LoadingOverlayComponent2, "agLoadingOverlay", params, true);
  }
  getNoRowsOverlayCompDetails(params) {
    return this.getCompDetails(this.gridOptions, NoRowsOverlayComponent2, "agNoRowsOverlay", params, true);
  }
  getTooltipCompDetails(params) {
    return this.getCompDetails(params.colDef, TooltipComponent2, "agTooltipComponent", params, true);
  }
  getSetFilterCellRendererDetails(def, params) {
    return this.getCompDetails(def, CellRendererComponent, null, params);
  }
  getFloatingFilterCompDetails(def, params, defaultFloatingFilter) {
    return this.getCompDetails(def, FloatingFilterComponent, defaultFloatingFilter, params);
  }
  getToolPanelCompDetails(toolPanelDef, params) {
    return this.getCompDetails(toolPanelDef, ToolPanelComponent, null, params, true);
  }
  getStatusPanelCompDetails(def, params) {
    return this.getCompDetails(def, StatusPanelComponent, null, params, true);
  }
  getMenuItemCompDetails(def, params) {
    return this.getCompDetails(def, MenuItemComponent, "agMenuItem", params, true);
  }
  getCompDetails(defObject, type, defaultName, params, mandatory = false) {
    const { propertyName, cellRenderer } = type;
    let { compName, jsComp, fwComp, paramsFromSelector, popupFromSelector, popupPositionFromSelector } = _UserComponentFactory.getCompKeys(this.frameworkOverrides, defObject, type, params);
    const lookupFromRegistry = (key) => {
      const item = this.userComponentRegistry.retrieve(propertyName, key);
      if (item) {
        jsComp = !item.componentFromFramework ? item.component : void 0;
        fwComp = item.componentFromFramework ? item.component : void 0;
      }
    };
    if (compName != null) {
      lookupFromRegistry(compName);
    }
    if (jsComp == null && fwComp == null && defaultName != null) {
      lookupFromRegistry(defaultName);
    }
    if (jsComp && cellRenderer && !this.agComponentUtils.doesImplementIComponent(jsComp)) {
      jsComp = this.agComponentUtils.adaptFunction(propertyName, jsComp);
    }
    if (!jsComp && !fwComp) {
      if (mandatory) {
        _errorOnce(`Could not find component ${compName}, did you forget to configure this component?`);
      }
      return;
    }
    const paramsMerged = this.mergeParamsWithApplicationProvidedParams(defObject, type, params, paramsFromSelector);
    const componentFromFramework = jsComp == null;
    const componentClass = jsComp ? jsComp : fwComp;
    return {
      componentFromFramework,
      componentClass,
      params: paramsMerged,
      type,
      popupFromSelector,
      popupPositionFromSelector,
      newAgStackInstance: () => this.newAgStackInstance(componentClass, componentFromFramework, paramsMerged, type)
    };
  }
  static getCompKeys(frameworkOverrides, defObject, type, params) {
    const { propertyName } = type;
    let compName;
    let jsComp;
    let fwComp;
    let paramsFromSelector;
    let popupFromSelector;
    let popupPositionFromSelector;
    if (defObject) {
      const defObjectAny = defObject;
      const selectorFunc = defObjectAny[propertyName + "Selector"];
      const selectorRes = selectorFunc ? selectorFunc(params) : null;
      const assignComp = (providedJsComp) => {
        if (typeof providedJsComp === "string") {
          compName = providedJsComp;
        } else if (providedJsComp != null && providedJsComp !== true) {
          const isFwkComp = frameworkOverrides.isFrameworkComponent(providedJsComp);
          if (isFwkComp) {
            fwComp = providedJsComp;
          } else {
            jsComp = providedJsComp;
          }
        }
      };
      if (selectorRes) {
        assignComp(selectorRes.component);
        paramsFromSelector = selectorRes.params;
        popupFromSelector = selectorRes.popup;
        popupPositionFromSelector = selectorRes.popupPosition;
      } else {
        assignComp(defObjectAny[propertyName]);
      }
    }
    return { compName, jsComp, fwComp, paramsFromSelector, popupFromSelector, popupPositionFromSelector };
  }
  newAgStackInstance(ComponentClass, componentFromFramework, params, type) {
    const propertyName = type.propertyName;
    const jsComponent = !componentFromFramework;
    let instance;
    if (jsComponent) {
      instance = new ComponentClass();
    } else {
      const thisComponentConfig = this.componentMetadataProvider.retrieve(propertyName);
      instance = this.frameworkComponentWrapper.wrap(
        ComponentClass,
        thisComponentConfig.mandatoryMethodList,
        thisComponentConfig.optionalMethodList,
        type
      );
    }
    const deferredInit = this.initComponent(instance, params);
    if (deferredInit == null) {
      return AgPromise.resolve(instance);
    }
    return deferredInit.then(() => instance);
  }
  // used by Floating Filter
  mergeParamsWithApplicationProvidedParams(defObject, type, paramsFromGrid, paramsFromSelector = null) {
    const params = this.gos.getGridCommonParams();
    _mergeDeep(params, paramsFromGrid);
    const defObjectAny = defObject;
    const userParams = defObjectAny && defObjectAny[type.propertyName + "Params"];
    if (typeof userParams === "function") {
      const userParamsFromFunc = userParams(paramsFromGrid);
      _mergeDeep(params, userParamsFromFunc);
    } else if (typeof userParams === "object") {
      _mergeDeep(params, userParams);
    }
    _mergeDeep(params, paramsFromSelector);
    return params;
  }
  initComponent(component, params) {
    this.createBean(component);
    if (component.init == null) {
      return;
    }
    return component.init(params);
  }
};

// community-modules/core/src/components/framework/unwrapUserComp.ts
function _unwrapUserComp(comp) {
  const compAsAny = comp;
  const isProxy = compAsAny != null && compAsAny.getFrameworkComponentInstance != null;
  return isProxy ? compAsAny.getFrameworkComponentInstance() : comp;
}

// community-modules/core/src/context/genericContext.ts
var GenericContext = class {
  constructor(params) {
    this.beans = {};
    this.createdBeans = [];
    this.destroyed = false;
    if (!params || !params.beanClasses) {
      return;
    }
    this.beanDestroyComparator = params.beanDestroyComparator;
    this.init(params);
  }
  init(params) {
    Object.entries(params.providedBeanInstances).forEach(([beanName, beanInstance]) => {
      this.beans[beanName] = beanInstance;
    });
    params.beanClasses.forEach((BeanClass) => {
      const instance = new BeanClass();
      if (instance.beanName) {
        this.beans[instance.beanName] = instance;
      } else {
        console.error(`Bean ${BeanClass.name} is missing beanName`);
      }
      this.createdBeans.push(instance);
    });
    params.derivedBeans?.forEach((beanFunc) => {
      const { beanName, bean } = beanFunc(this);
      this.beans[beanName] = bean;
      this.createdBeans.push(bean);
    });
    if (params.beanInitComparator) {
      this.createdBeans.sort(params.beanInitComparator);
    }
    this.initBeans(this.createdBeans);
  }
  getBeanInstances() {
    return Object.values(this.beans);
  }
  createBean(bean, afterPreCreateCallback) {
    if (!bean) {
      throw Error(`Can't wire to bean since it is null`);
    }
    this.initBeans([bean], afterPreCreateCallback);
    return bean;
  }
  initBeans(beanInstances, afterPreCreateCallback) {
    beanInstances.forEach((instance) => {
      instance.preWireBeans?.(this.beans);
      instance.wireBeans?.(this.beans);
    });
    beanInstances.forEach((instance) => instance.preConstruct?.());
    if (afterPreCreateCallback) {
      beanInstances.forEach(afterPreCreateCallback);
    }
    beanInstances.forEach((instance) => instance.postConstruct?.());
  }
  getBeans() {
    return this.beans;
  }
  getBean(name) {
    return this.beans[name];
  }
  destroy() {
    if (this.destroyed) {
      return;
    }
    this.destroyed = true;
    const beanInstances = this.getBeanInstances();
    if (this.beanDestroyComparator) {
      beanInstances.sort(this.beanDestroyComparator);
    }
    this.destroyBeans(beanInstances);
    this.beans = {};
    this.createdBeans = [];
  }
  /**
   * Destroys a bean and returns undefined to support destruction and clean up in a single line.
   * this.dateComp = this.context.destroyBean(this.dateComp);
   */
  destroyBean(bean) {
    bean?.destroy?.();
  }
  /**
   * Destroys an array of beans and returns an empty array to support destruction and clean up in a single line.
   * this.dateComps = this.context.destroyBeans(this.dateComps);
   */
  destroyBeans(beans) {
    if (beans) {
      for (let i = 0; i < beans.length; i++) {
        this.destroyBean(beans[i]);
      }
    }
    return [];
  }
  isDestroyed() {
    return this.destroyed;
  }
};

// community-modules/core/src/context/context.ts
var Context = class extends GenericContext {
  init(params) {
    this.gridId = params.gridId;
    this.beans.context = this;
    super.init(params);
  }
  destroy() {
    super.destroy();
    ModuleRegistry.__unRegisterGridModules(this.gridId);
  }
  getGridId() {
    return this.gridId;
  }
};

// community-modules/core/src/interfaces/iExcelCreator.ts
var ExcelFactoryMode = /* @__PURE__ */ ((ExcelFactoryMode2) => {
  ExcelFactoryMode2[ExcelFactoryMode2["SINGLE_SHEET"] = 0] = "SINGLE_SHEET";
  ExcelFactoryMode2[ExcelFactoryMode2["MULTI_SHEET"] = 1] = "MULTI_SHEET";
  return ExcelFactoryMode2;
})(ExcelFactoryMode || {});

// community-modules/core/src/constants/direction.ts
var VerticalDirection = /* @__PURE__ */ ((VerticalDirection2) => {
  VerticalDirection2[VerticalDirection2["Up"] = 0] = "Up";
  VerticalDirection2[VerticalDirection2["Down"] = 1] = "Down";
  return VerticalDirection2;
})(VerticalDirection || {});
var HorizontalDirection = /* @__PURE__ */ ((HorizontalDirection2) => {
  HorizontalDirection2[HorizontalDirection2["Left"] = 0] = "Left";
  HorizontalDirection2[HorizontalDirection2["Right"] = 1] = "Right";
  return HorizontalDirection2;
})(HorizontalDirection || {});

// community-modules/core/src/dragAndDrop/dragAndDropService.ts
var DragSourceType = /* @__PURE__ */ ((DragSourceType2) => {
  DragSourceType2[DragSourceType2["ToolPanel"] = 0] = "ToolPanel";
  DragSourceType2[DragSourceType2["HeaderCell"] = 1] = "HeaderCell";
  DragSourceType2[DragSourceType2["RowDrag"] = 2] = "RowDrag";
  DragSourceType2[DragSourceType2["ChartPanel"] = 3] = "ChartPanel";
  DragSourceType2[DragSourceType2["AdvancedFilterBuilder"] = 4] = "AdvancedFilterBuilder";
  return DragSourceType2;
})(DragSourceType || {});
var GHOST_TEMPLATE = (
  /* html */
  `<div class="ag-dnd-ghost ag-unselectable">
<span class="ag-dnd-ghost-icon ag-shake-left-to-right"></span>
<div class="ag-dnd-ghost-label"></div>
</div>`
);
var DragAndDropService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "dragAndDropService";
    this.dragSourceAndParamsList = [];
    this.dropTargets = [];
  }
  wireBeans(beans) {
    this.dragService = beans.dragService;
    this.mouseEventService = beans.mouseEventService;
    this.environment = beans.environment;
  }
  postConstruct() {
    this.dropIconMap = {
      pinned: _createIcon("columnMovePin", this.gos, null),
      hide: _createIcon("columnMoveHide", this.gos, null),
      move: _createIcon("columnMoveMove", this.gos, null),
      left: _createIcon("columnMoveLeft", this.gos, null),
      right: _createIcon("columnMoveRight", this.gos, null),
      group: _createIcon("columnMoveGroup", this.gos, null),
      aggregate: _createIcon("columnMoveValue", this.gos, null),
      pivot: _createIcon("columnMovePivot", this.gos, null),
      notAllowed: _createIcon("dropNotAllowed", this.gos, null)
    };
  }
  addDragSource(dragSource, allowTouch = false) {
    const params = {
      eElement: dragSource.eElement,
      dragStartPixels: dragSource.dragStartPixels,
      onDragStart: this.onDragStart.bind(this, dragSource),
      onDragStop: this.onDragStop.bind(this),
      onDragging: this.onDragging.bind(this),
      includeTouch: allowTouch
    };
    this.dragSourceAndParamsList.push({ params, dragSource });
    this.dragService.addDragSource(params);
  }
  removeDragSource(dragSource) {
    const sourceAndParams = this.dragSourceAndParamsList.find((item) => item.dragSource === dragSource);
    if (sourceAndParams) {
      this.dragService.removeDragSource(sourceAndParams.params);
      _removeFromArray(this.dragSourceAndParamsList, sourceAndParams);
    }
  }
  destroy() {
    this.dragSourceAndParamsList.forEach(
      (sourceAndParams) => this.dragService.removeDragSource(sourceAndParams.params)
    );
    this.dragSourceAndParamsList.length = 0;
    this.dropTargets.length = 0;
    super.destroy();
  }
  nudge() {
    if (this.dragging) {
      this.onDragging(this.eventLastTime, true);
    }
  }
  onDragStart(dragSource, mouseEvent) {
    this.dragging = true;
    this.dragSource = dragSource;
    this.eventLastTime = mouseEvent;
    this.dragItem = this.dragSource.getDragItem();
    if (this.dragSource.onDragStarted) {
      this.dragSource.onDragStarted();
    }
    this.createGhost();
  }
  onDragStop(mouseEvent) {
    this.eventLastTime = null;
    this.dragging = false;
    if (this.dragSource.onDragStopped) {
      this.dragSource.onDragStopped();
    }
    if (this.lastDropTarget && this.lastDropTarget.onDragStop) {
      const draggingEvent = this.createDropTargetEvent(this.lastDropTarget, mouseEvent, null, null, false);
      this.lastDropTarget.onDragStop(draggingEvent);
    }
    this.lastDropTarget = void 0;
    this.dragItem = null;
    this.removeGhost();
  }
  onDragging(mouseEvent, fromNudge) {
    const hDirection = this.getHorizontalDirection(mouseEvent);
    const vDirection = this.getVerticalDirection(mouseEvent);
    this.eventLastTime = mouseEvent;
    this.positionGhost(mouseEvent);
    const validDropTargets = this.dropTargets.filter((target) => this.isMouseOnDropTarget(mouseEvent, target));
    const dropTarget = this.findCurrentDropTarget(mouseEvent, validDropTargets);
    if (dropTarget !== this.lastDropTarget) {
      this.leaveLastTargetIfExists(mouseEvent, hDirection, vDirection, fromNudge);
      if (this.lastDropTarget !== null && dropTarget === null) {
        this.dragSource.onGridExit?.(this.dragItem);
      }
      if (this.lastDropTarget === null && dropTarget !== null) {
        this.dragSource.onGridEnter?.(this.dragItem);
      }
      this.enterDragTargetIfExists(dropTarget, mouseEvent, hDirection, vDirection, fromNudge);
      this.lastDropTarget = dropTarget;
    } else if (dropTarget && dropTarget.onDragging) {
      const draggingEvent = this.createDropTargetEvent(dropTarget, mouseEvent, hDirection, vDirection, fromNudge);
      dropTarget.onDragging(draggingEvent);
    }
  }
  getAllContainersFromDropTarget(dropTarget) {
    const secondaryContainers = dropTarget.getSecondaryContainers ? dropTarget.getSecondaryContainers() : null;
    const containers = [[dropTarget.getContainer()]];
    return secondaryContainers ? containers.concat(secondaryContainers) : containers;
  }
  allContainersIntersect(mouseEvent, containers) {
    for (const container of containers) {
      const rect = container.getBoundingClientRect();
      if (rect.width === 0 || rect.height === 0) {
        return false;
      }
      const horizontalFit = mouseEvent.clientX >= rect.left && mouseEvent.clientX < rect.right;
      const verticalFit = mouseEvent.clientY >= rect.top && mouseEvent.clientY < rect.bottom;
      if (!horizontalFit || !verticalFit) {
        return false;
      }
    }
    return true;
  }
  // checks if the mouse is on the drop target. it checks eContainer and eSecondaryContainers
  isMouseOnDropTarget(mouseEvent, dropTarget) {
    const allContainersFromDropTarget = this.getAllContainersFromDropTarget(dropTarget);
    let mouseOverTarget = false;
    for (const currentContainers of allContainersFromDropTarget) {
      if (this.allContainersIntersect(mouseEvent, currentContainers)) {
        mouseOverTarget = true;
        break;
      }
    }
    if (dropTarget.targetContainsSource && !dropTarget.getContainer().contains(this.dragSource.eElement)) {
      return false;
    }
    return mouseOverTarget && dropTarget.isInterestedIn(this.dragSource.type, this.dragSource.eElement);
  }
  findCurrentDropTarget(mouseEvent, validDropTargets) {
    const len = validDropTargets.length;
    if (len === 0) {
      return null;
    }
    if (len === 1) {
      return validDropTargets[0];
    }
    const rootNode = this.gos.getRootNode();
    const elementStack = rootNode.elementsFromPoint(mouseEvent.clientX, mouseEvent.clientY);
    for (const el of elementStack) {
      for (const dropTarget of validDropTargets) {
        const containers = _flatten(this.getAllContainersFromDropTarget(dropTarget));
        if (containers.indexOf(el) !== -1) {
          return dropTarget;
        }
      }
    }
    return null;
  }
  enterDragTargetIfExists(dropTarget, mouseEvent, hDirection, vDirection, fromNudge) {
    if (!dropTarget) {
      return;
    }
    if (dropTarget.onDragEnter) {
      const dragEnterEvent = this.createDropTargetEvent(
        dropTarget,
        mouseEvent,
        hDirection,
        vDirection,
        fromNudge
      );
      dropTarget.onDragEnter(dragEnterEvent);
    }
    this.setGhostIcon(dropTarget.getIconName ? dropTarget.getIconName() : null);
  }
  leaveLastTargetIfExists(mouseEvent, hDirection, vDirection, fromNudge) {
    if (!this.lastDropTarget) {
      return;
    }
    if (this.lastDropTarget.onDragLeave) {
      const dragLeaveEvent = this.createDropTargetEvent(
        this.lastDropTarget,
        mouseEvent,
        hDirection,
        vDirection,
        fromNudge
      );
      this.lastDropTarget.onDragLeave(dragLeaveEvent);
    }
    this.setGhostIcon(null);
  }
  addDropTarget(dropTarget) {
    this.dropTargets.push(dropTarget);
  }
  removeDropTarget(dropTarget) {
    this.dropTargets = this.dropTargets.filter((target) => target.getContainer() !== dropTarget.getContainer());
  }
  hasExternalDropZones() {
    return this.dropTargets.some((zones) => zones.external);
  }
  findExternalZone(params) {
    const externalTargets = this.dropTargets.filter((target) => target.external);
    return externalTargets.find((zone) => zone.getContainer() === params.getContainer()) || null;
  }
  getHorizontalDirection(event) {
    const clientX = this.eventLastTime && this.eventLastTime.clientX;
    const eClientX = event.clientX;
    if (clientX === eClientX) {
      return null;
    }
    return clientX > eClientX ? 0 /* Left */ : 1 /* Right */;
  }
  getVerticalDirection(event) {
    const clientY = this.eventLastTime && this.eventLastTime.clientY;
    const eClientY = event.clientY;
    if (clientY === eClientY) {
      return null;
    }
    return clientY > eClientY ? 0 /* Up */ : 1 /* Down */;
  }
  createDropTargetEvent(dropTarget, event, hDirection, vDirection, fromNudge) {
    const dropZoneTarget = dropTarget.getContainer();
    const rect = dropZoneTarget.getBoundingClientRect();
    const { dragItem, dragSource } = this;
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    return this.gos.addGridCommonParams({
      event,
      x,
      y,
      vDirection,
      hDirection,
      dragSource,
      fromNudge,
      dragItem,
      dropZoneTarget
    });
  }
  positionGhost(event) {
    const ghost = this.eGhost;
    if (!ghost) {
      return;
    }
    const ghostRect = ghost.getBoundingClientRect();
    const ghostHeight = ghostRect.height;
    const browserWidth = _getBodyWidth() - 2;
    const browserHeight = _getBodyHeight() - 2;
    const offsetParentSize = _getElementRectWithOffset(ghost.offsetParent);
    const { clientY, clientX } = event;
    let top = clientY - offsetParentSize.top - ghostHeight / 2;
    let left = clientX - offsetParentSize.left - 10;
    const eDocument = this.gos.getDocument();
    const win = eDocument.defaultView || window;
    const windowScrollY = win.pageYOffset || eDocument.documentElement.scrollTop;
    const windowScrollX = win.pageXOffset || eDocument.documentElement.scrollLeft;
    if (browserWidth > 0 && left + ghost.clientWidth > browserWidth + windowScrollX) {
      left = browserWidth + windowScrollX - ghost.clientWidth;
    }
    if (left < 0) {
      left = 0;
    }
    if (browserHeight > 0 && top + ghost.clientHeight > browserHeight + windowScrollY) {
      top = browserHeight + windowScrollY - ghost.clientHeight;
    }
    if (top < 0) {
      top = 0;
    }
    ghost.style.left = `${left}px`;
    ghost.style.top = `${top}px`;
  }
  removeGhost() {
    if (this.eGhost && this.eGhostParent) {
      this.eGhostParent.removeChild(this.eGhost);
    }
    this.eGhost = null;
  }
  createGhost() {
    this.eGhost = _loadTemplate(GHOST_TEMPLATE);
    this.mouseEventService.stampTopLevelGridCompWithGridInstance(this.eGhost);
    this.environment.applyThemeClasses(this.eGhost);
    this.eGhostIcon = this.eGhost.querySelector(".ag-dnd-ghost-icon");
    this.setGhostIcon(null);
    const eText = this.eGhost.querySelector(".ag-dnd-ghost-label");
    let dragItemName = this.dragSource.dragItemName;
    if (_isFunction(dragItemName)) {
      dragItemName = dragItemName();
    }
    eText.innerHTML = _escapeString(dragItemName) || "";
    this.eGhost.style.height = "25px";
    this.eGhost.style.top = "20px";
    this.eGhost.style.left = "20px";
    const eDocument = this.gos.getDocument();
    let rootNode = null;
    let targetEl = null;
    try {
      rootNode = eDocument.fullscreenElement;
    } catch (e) {
    } finally {
      if (!rootNode) {
        rootNode = this.gos.getRootNode();
      }
      const body = rootNode.querySelector("body");
      if (body) {
        targetEl = body;
      } else if (rootNode instanceof ShadowRoot) {
        targetEl = rootNode;
      } else if (rootNode instanceof Document) {
        targetEl = rootNode?.documentElement;
      } else {
        targetEl = rootNode;
      }
    }
    this.eGhostParent = targetEl;
    if (!this.eGhostParent) {
      _warnOnce("Could not find document body, it is needed for dragging columns");
    } else {
      this.eGhostParent.appendChild(this.eGhost);
    }
  }
  setGhostIcon(iconName, shake = false) {
    _clearElement(this.eGhostIcon);
    let eIcon = null;
    if (!iconName) {
      iconName = this.dragSource.getDefaultIconName ? this.dragSource.getDefaultIconName() : "notAllowed";
    }
    eIcon = this.dropIconMap[iconName];
    this.eGhostIcon.classList.toggle("ag-shake-left-to-right", shake);
    if (eIcon === this.dropIconMap["hide"] && this.gos.get("suppressDragLeaveHidesColumns")) {
      return;
    }
    if (eIcon) {
      this.eGhostIcon.appendChild(eIcon);
    }
  }
};

// community-modules/core/src/autoScrollService.ts
var AutoScrollService = class {
  constructor(params) {
    this.tickingInterval = null;
    this.onScrollCallback = null;
    this.scrollContainer = params.scrollContainer;
    this.scrollHorizontally = params.scrollAxis.indexOf("x") !== -1;
    this.scrollVertically = params.scrollAxis.indexOf("y") !== -1;
    this.scrollByTick = params.scrollByTick != null ? params.scrollByTick : 20;
    if (params.onScrollCallback) {
      this.onScrollCallback = params.onScrollCallback;
    }
    if (this.scrollVertically) {
      this.getVerticalPosition = params.getVerticalPosition;
      this.setVerticalPosition = params.setVerticalPosition;
    }
    if (this.scrollHorizontally) {
      this.getHorizontalPosition = params.getHorizontalPosition;
      this.setHorizontalPosition = params.setHorizontalPosition;
    }
    this.shouldSkipVerticalScroll = params.shouldSkipVerticalScroll || (() => false);
    this.shouldSkipHorizontalScroll = params.shouldSkipHorizontalScroll || (() => false);
  }
  check(mouseEvent, forceSkipVerticalScroll = false) {
    const skipVerticalScroll = forceSkipVerticalScroll || this.shouldSkipVerticalScroll();
    if (skipVerticalScroll && this.shouldSkipHorizontalScroll()) {
      return;
    }
    const rect = this.scrollContainer.getBoundingClientRect();
    const scrollTick = this.scrollByTick;
    this.tickLeft = mouseEvent.clientX < rect.left + scrollTick;
    this.tickRight = mouseEvent.clientX > rect.right - scrollTick;
    this.tickUp = mouseEvent.clientY < rect.top + scrollTick && !skipVerticalScroll;
    this.tickDown = mouseEvent.clientY > rect.bottom - scrollTick && !skipVerticalScroll;
    if (this.tickLeft || this.tickRight || this.tickUp || this.tickDown) {
      this.ensureTickingStarted();
    } else {
      this.ensureCleared();
    }
  }
  ensureTickingStarted() {
    if (this.tickingInterval === null) {
      this.tickingInterval = window.setInterval(this.doTick.bind(this), 100);
      this.tickCount = 0;
    }
  }
  doTick() {
    this.tickCount++;
    const tickAmount = this.tickCount > 20 ? 200 : this.tickCount > 10 ? 80 : 40;
    if (this.scrollVertically) {
      const vScrollPosition = this.getVerticalPosition();
      if (this.tickUp) {
        this.setVerticalPosition(vScrollPosition - tickAmount);
      }
      if (this.tickDown) {
        this.setVerticalPosition(vScrollPosition + tickAmount);
      }
    }
    if (this.scrollHorizontally) {
      const hScrollPosition = this.getHorizontalPosition();
      if (this.tickLeft) {
        this.setHorizontalPosition(hScrollPosition - tickAmount);
      }
      if (this.tickRight) {
        this.setHorizontalPosition(hScrollPosition + tickAmount);
      }
    }
    if (this.onScrollCallback) {
      this.onScrollCallback();
    }
  }
  ensureCleared() {
    if (this.tickingInterval) {
      window.clearInterval(this.tickingInterval);
      this.tickingInterval = null;
    }
  }
};

// community-modules/core/src/interfaces/iRowNode.ts
var RowHighlightPosition = /* @__PURE__ */ ((RowHighlightPosition2) => {
  RowHighlightPosition2[RowHighlightPosition2["Above"] = 0] = "Above";
  RowHighlightPosition2[RowHighlightPosition2["Below"] = 1] = "Below";
  return RowHighlightPosition2;
})(RowHighlightPosition || {});

// community-modules/core/src/gridBodyComp/rowDragFeature.ts
var RowDragFeature = class extends BeanStub {
  wireBeans(beans) {
    this.dragAndDropService = beans.dragAndDropService;
    this.rowModel = beans.rowModel;
    this.pageBoundsService = beans.pageBoundsService;
    this.focusService = beans.focusService;
    this.sortController = beans.sortController;
    this.filterManager = beans.filterManager;
    this.selectionService = beans.selectionService;
    this.mouseEventService = beans.mouseEventService;
    this.ctrlsService = beans.ctrlsService;
    this.funcColsService = beans.funcColsService;
    this.rangeService = beans.rangeService;
  }
  constructor(eContainer) {
    super();
    this.eContainer = eContainer;
  }
  postConstruct() {
    if (this.gos.isRowModelType("clientSide")) {
      this.clientSideRowModel = this.rowModel;
    }
    this.ctrlsService.whenReady((p) => {
      const gridBodyCon = p.gridBodyCtrl;
      this.autoScrollService = new AutoScrollService({
        scrollContainer: gridBodyCon.getBodyViewportElement(),
        scrollAxis: "y",
        getVerticalPosition: () => gridBodyCon.getScrollFeature().getVScrollPosition().top,
        setVerticalPosition: (position) => gridBodyCon.getScrollFeature().setVerticalScrollPosition(position),
        onScrollCallback: () => {
          this.onDragging(this.lastDraggingEvent);
        }
      });
    });
  }
  getContainer() {
    return this.eContainer;
  }
  isInterestedIn(type) {
    return type === 2 /* RowDrag */;
  }
  getIconName() {
    const managedDrag = this.gos.get("rowDragManaged");
    if (managedDrag && this.shouldPreventRowMove()) {
      return "notAllowed";
    }
    return "move";
  }
  shouldPreventRowMove() {
    const rowGroupCols = this.funcColsService.getRowGroupColumns();
    if (rowGroupCols.length) {
      return true;
    }
    const isFilterPresent = this.filterManager?.isAnyFilterPresent();
    if (isFilterPresent) {
      return true;
    }
    const isSortActive = this.sortController.isSortActive();
    if (isSortActive) {
      return true;
    }
    return false;
  }
  getRowNodes(draggingEvent) {
    if (!this.isFromThisGrid(draggingEvent)) {
      return draggingEvent.dragItem.rowNodes || [];
    }
    const currentNode = draggingEvent.dragItem.rowNode;
    const isRowDragMultiRow = this.gos.get("rowDragMultiRow");
    if (isRowDragMultiRow) {
      const selectedNodes = [...this.selectionService.getSelectedNodes()].sort((a, b) => {
        if (a.rowIndex == null || b.rowIndex == null) {
          return 0;
        }
        return this.getRowIndexNumber(a) - this.getRowIndexNumber(b);
      });
      if (selectedNodes.indexOf(currentNode) !== -1) {
        return selectedNodes;
      }
    }
    return [currentNode];
  }
  onDragEnter(draggingEvent) {
    draggingEvent.dragItem.rowNodes = this.getRowNodes(draggingEvent);
    this.dispatchGridEvent("rowDragEnter", draggingEvent);
    this.getRowNodes(draggingEvent).forEach((rowNode) => {
      rowNode.setDragging(true);
    });
    this.onEnterOrDragging(draggingEvent);
  }
  onDragging(draggingEvent) {
    this.onEnterOrDragging(draggingEvent);
  }
  isFromThisGrid(draggingEvent) {
    const { dragSourceDomDataKey } = draggingEvent.dragSource;
    return dragSourceDomDataKey === this.gos.getDomDataKey();
  }
  isDropZoneWithinThisGrid(draggingEvent) {
    const gridBodyCon = this.ctrlsService.getGridBodyCtrl();
    const gridGui = gridBodyCon.getGui();
    const { dropZoneTarget } = draggingEvent;
    return !gridGui.contains(dropZoneTarget);
  }
  onEnterOrDragging(draggingEvent) {
    this.dispatchGridEvent("rowDragMove", draggingEvent);
    this.lastDraggingEvent = draggingEvent;
    const pixel = this.mouseEventService.getNormalisedPosition(draggingEvent).y;
    const managedDrag = this.gos.get("rowDragManaged");
    if (managedDrag) {
      this.doManagedDrag(draggingEvent, pixel);
    }
    this.autoScrollService.check(draggingEvent.event);
  }
  doManagedDrag(draggingEvent, pixel) {
    const isFromThisGrid = this.isFromThisGrid(draggingEvent);
    const managedDrag = this.gos.get("rowDragManaged");
    const rowNodes = draggingEvent.dragItem.rowNodes;
    if (managedDrag && this.shouldPreventRowMove()) {
      return;
    }
    if (this.gos.get("suppressMoveWhenRowDragging") || !isFromThisGrid) {
      if (!this.isDropZoneWithinThisGrid(draggingEvent)) {
        this.clientSideRowModel.highlightRowAtPixel(rowNodes[0], pixel);
      }
    } else {
      this.moveRows(rowNodes, pixel);
    }
  }
  getRowIndexNumber(rowNode) {
    const rowIndexStr = rowNode.getRowIndexString();
    return parseInt(_last(rowIndexStr.split("-")), 10);
  }
  moveRowAndClearHighlight(draggingEvent) {
    const lastHighlightedRowNode = this.clientSideRowModel.getLastHighlightedRowNode();
    const isBelow = lastHighlightedRowNode && lastHighlightedRowNode.highlighted === 1 /* Below */;
    const pixel = this.mouseEventService.getNormalisedPosition(draggingEvent).y;
    const rowNodes = draggingEvent.dragItem.rowNodes;
    let increment = isBelow ? 1 : 0;
    if (this.isFromThisGrid(draggingEvent)) {
      rowNodes.forEach((rowNode) => {
        if (rowNode.rowTop < pixel) {
          increment -= 1;
        }
      });
      this.moveRows(rowNodes, pixel, increment);
    } else {
      const getRowIdFunc = this.gos.getRowIdCallback();
      let addIndex = this.clientSideRowModel.getRowIndexAtPixel(pixel) + 1;
      if (this.clientSideRowModel.getHighlightPosition(pixel) === 0 /* Above */) {
        addIndex--;
      }
      this.clientSideRowModel.updateRowData({
        add: rowNodes.map((node) => node.data).filter(
          (data) => !this.clientSideRowModel.getRowNode(getRowIdFunc?.({ data, level: 0 }) ?? data.id)
        ),
        addIndex
      });
    }
    this.clearRowHighlight();
  }
  clearRowHighlight() {
    this.clientSideRowModel.highlightRowAtPixel(null);
  }
  moveRows(rowNodes, pixel, increment = 0) {
    const rowWasMoved = this.clientSideRowModel.ensureRowsAtPixel(rowNodes, pixel, increment);
    if (rowWasMoved) {
      this.focusService.clearFocusedCell();
      this.rangeService?.removeAllCellRanges();
    }
  }
  addRowDropZone(params) {
    if (!params.getContainer()) {
      _warnOnce("addRowDropZone - A container target needs to be provided");
      return;
    }
    if (this.dragAndDropService.findExternalZone(params)) {
      _warnOnce(
        "addRowDropZone - target already exists in the list of DropZones. Use `removeRowDropZone` before adding it again."
      );
      return;
    }
    let processedParams = {
      getContainer: params.getContainer
    };
    if (params.fromGrid) {
      processedParams = params;
    } else {
      if (params.onDragEnter) {
        processedParams.onDragEnter = (e) => {
          params.onDragEnter(this.draggingToRowDragEvent("rowDragEnter", e));
        };
      }
      if (params.onDragLeave) {
        processedParams.onDragLeave = (e) => {
          params.onDragLeave(this.draggingToRowDragEvent("rowDragLeave", e));
        };
      }
      if (params.onDragging) {
        processedParams.onDragging = (e) => {
          params.onDragging(this.draggingToRowDragEvent("rowDragMove", e));
        };
      }
      if (params.onDragStop) {
        processedParams.onDragStop = (e) => {
          params.onDragStop(this.draggingToRowDragEvent("rowDragEnd", e));
        };
      }
    }
    this.dragAndDropService.addDropTarget({
      isInterestedIn: (type) => type === 2 /* RowDrag */,
      getIconName: () => "move",
      external: true,
      ...processedParams
    });
  }
  getRowDropZone(events) {
    const getContainer = this.getContainer.bind(this);
    const onDragEnter = this.onDragEnter.bind(this);
    const onDragLeave = this.onDragLeave.bind(this);
    const onDragging = this.onDragging.bind(this);
    const onDragStop = this.onDragStop.bind(this);
    if (!events) {
      return {
        getContainer,
        onDragEnter,
        onDragLeave,
        onDragging,
        onDragStop,
        /* @private */
        fromGrid: true
      };
    }
    return {
      getContainer,
      onDragEnter: events.onDragEnter ? (e) => {
        onDragEnter(e);
        events.onDragEnter(this.draggingToRowDragEvent("rowDragEnter", e));
      } : onDragEnter,
      onDragLeave: events.onDragLeave ? (e) => {
        onDragLeave(e);
        events.onDragLeave(this.draggingToRowDragEvent("rowDragLeave", e));
      } : onDragLeave,
      onDragging: events.onDragging ? (e) => {
        onDragging(e);
        events.onDragging(this.draggingToRowDragEvent("rowDragMove", e));
      } : onDragging,
      onDragStop: events.onDragStop ? (e) => {
        onDragStop(e);
        events.onDragStop(this.draggingToRowDragEvent("rowDragEnd", e));
      } : onDragStop,
      fromGrid: true
    };
  }
  draggingToRowDragEvent(type, draggingEvent) {
    const yNormalised = this.mouseEventService.getNormalisedPosition(draggingEvent).y;
    const mouseIsPastLastRow = yNormalised > this.pageBoundsService.getCurrentPageHeight();
    let overIndex = -1;
    let overNode;
    if (!mouseIsPastLastRow) {
      overIndex = this.rowModel.getRowIndexAtPixel(yNormalised);
      overNode = this.rowModel.getRow(overIndex);
    }
    let vDirectionString;
    switch (draggingEvent.vDirection) {
      case 1 /* Down */:
        vDirectionString = "down";
        break;
      case 0 /* Up */:
        vDirectionString = "up";
        break;
      default:
        vDirectionString = null;
        break;
    }
    const event = this.gos.addGridCommonParams({
      type,
      event: draggingEvent.event,
      node: draggingEvent.dragItem.rowNode,
      nodes: draggingEvent.dragItem.rowNodes,
      overIndex,
      overNode,
      y: yNormalised,
      vDirection: vDirectionString
    });
    return event;
  }
  dispatchGridEvent(type, draggingEvent) {
    const event = this.draggingToRowDragEvent(type, draggingEvent);
    this.eventService.dispatchEvent(event);
  }
  onDragLeave(draggingEvent) {
    this.dispatchGridEvent("rowDragLeave", draggingEvent);
    this.stopDragging(draggingEvent);
    if (this.gos.get("rowDragManaged")) {
      this.clearRowHighlight();
    }
  }
  onDragStop(draggingEvent) {
    this.dispatchGridEvent("rowDragEnd", draggingEvent);
    this.stopDragging(draggingEvent);
    if (this.gos.get("rowDragManaged") && (this.gos.get("suppressMoveWhenRowDragging") || !this.isFromThisGrid(draggingEvent)) && !this.isDropZoneWithinThisGrid(draggingEvent)) {
      this.moveRowAndClearHighlight(draggingEvent);
    }
  }
  stopDragging(draggingEvent) {
    this.autoScrollService.ensureCleared();
    this.getRowNodes(draggingEvent).forEach((rowNode) => {
      rowNode.setDragging(false);
    });
  }
};

// community-modules/core/src/dragAndDrop/dragService.ts
var DragService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "dragService";
    this.dragEndFunctions = [];
    this.dragSources = [];
  }
  wireBeans(beans) {
    this.mouseEventService = beans.mouseEventService;
  }
  destroy() {
    this.dragSources.forEach(this.removeListener.bind(this));
    this.dragSources.length = 0;
    super.destroy();
  }
  removeListener(dragSourceAndListener) {
    const element = dragSourceAndListener.dragSource.eElement;
    const mouseDownListener = dragSourceAndListener.mouseDownListener;
    element.removeEventListener("mousedown", mouseDownListener);
    if (dragSourceAndListener.touchEnabled) {
      const touchStartListener = dragSourceAndListener.touchStartListener;
      element.removeEventListener("touchstart", touchStartListener, { passive: true });
    }
  }
  removeDragSource(params) {
    const dragSourceAndListener = this.dragSources.find((item) => item.dragSource === params);
    if (!dragSourceAndListener) {
      return;
    }
    this.removeListener(dragSourceAndListener);
    _removeFromArray(this.dragSources, dragSourceAndListener);
  }
  isDragging() {
    return this.dragging;
  }
  addDragSource(params) {
    const mouseListener = this.onMouseDown.bind(this, params);
    const { eElement, includeTouch, stopPropagationForTouch } = params;
    eElement.addEventListener("mousedown", mouseListener);
    let touchListener = null;
    const suppressTouch = this.gos.get("suppressTouch");
    if (includeTouch && !suppressTouch) {
      touchListener = (touchEvent) => {
        if (_isFocusableFormField(touchEvent.target)) {
          return;
        }
        if (touchEvent.cancelable) {
          touchEvent.preventDefault();
          if (stopPropagationForTouch) {
            touchEvent.stopPropagation();
          }
        }
        this.onTouchStart(params, touchEvent);
      };
      eElement.addEventListener("touchstart", touchListener, { passive: false });
    }
    this.dragSources.push({
      dragSource: params,
      mouseDownListener: mouseListener,
      touchStartListener: touchListener,
      touchEnabled: !!includeTouch
    });
  }
  getStartTarget() {
    return this.startTarget;
  }
  // gets called whenever mouse down on any drag source
  onTouchStart(params, touchEvent) {
    this.currentDragParams = params;
    this.dragging = false;
    const touch = touchEvent.touches[0];
    this.touchLastTime = touch;
    this.touchStart = touch;
    const touchMoveEvent = (e) => this.onTouchMove(e, params.eElement);
    const touchEndEvent = (e) => this.onTouchUp(e, params.eElement);
    const documentTouchMove = (e) => {
      if (e.cancelable) {
        e.preventDefault();
      }
    };
    const target = touchEvent.target;
    const events = [
      // Prevents the page document from moving while we are dragging items around.
      // preventDefault needs to be called in the touchmove listener and never inside the
      // touchstart, because using touchstart causes the click event to be cancelled on touch devices.
      {
        target: this.gos.getRootNode(),
        type: "touchmove",
        listener: documentTouchMove,
        options: { passive: false }
      },
      { target, type: "touchmove", listener: touchMoveEvent, options: { passive: true } },
      { target, type: "touchend", listener: touchEndEvent, options: { passive: true } },
      { target, type: "touchcancel", listener: touchEndEvent, options: { passive: true } }
    ];
    this.addTemporaryEvents(events);
    if (params.dragStartPixels === 0) {
      this.onCommonMove(touch, this.touchStart, params.eElement);
    }
  }
  // gets called whenever mouse down on any drag source
  onMouseDown(params, mouseEvent) {
    const e = mouseEvent;
    if (params.skipMouseEvent && params.skipMouseEvent(mouseEvent)) {
      return;
    }
    if (e._alreadyProcessedByDragService) {
      return;
    }
    e._alreadyProcessedByDragService = true;
    if (mouseEvent.button !== 0) {
      return;
    }
    if (this.shouldPreventMouseEvent(mouseEvent)) {
      mouseEvent.preventDefault();
    }
    this.currentDragParams = params;
    this.dragging = false;
    this.mouseStartEvent = mouseEvent;
    this.startTarget = mouseEvent.target;
    const mouseMoveEvent = (event) => this.onMouseMove(event, params.eElement);
    const mouseUpEvent = (event) => this.onMouseUp(event, params.eElement);
    const contextEvent = (event) => event.preventDefault();
    const target = this.gos.getRootNode();
    const events = [
      { target, type: "mousemove", listener: mouseMoveEvent },
      { target, type: "mouseup", listener: mouseUpEvent },
      { target, type: "contextmenu", listener: contextEvent }
    ];
    this.addTemporaryEvents(events);
    if (params.dragStartPixels === 0) {
      this.onMouseMove(mouseEvent, params.eElement);
    }
  }
  addTemporaryEvents(events) {
    events.forEach((currentEvent) => {
      const { target, type, listener, options } = currentEvent;
      target.addEventListener(type, listener, options);
    });
    this.dragEndFunctions.push(() => {
      events.forEach((currentEvent) => {
        const { target, type, listener, options } = currentEvent;
        target.removeEventListener(type, listener, options);
      });
    });
  }
  // returns true if the event is close to the original event by X pixels either vertically or horizontally.
  // we only start dragging after X pixels so this allows us to know if we should start dragging yet.
  isEventNearStartEvent(currentEvent, startEvent) {
    const { dragStartPixels } = this.currentDragParams;
    const requiredPixelDiff = _exists(dragStartPixels) ? dragStartPixels : 4;
    return _areEventsNear(currentEvent, startEvent, requiredPixelDiff);
  }
  getFirstActiveTouch(touchList) {
    for (let i = 0; i < touchList.length; i++) {
      if (touchList[i].identifier === this.touchStart.identifier) {
        return touchList[i];
      }
    }
    return null;
  }
  onCommonMove(currentEvent, startEvent, el) {
    if (!this.dragging) {
      if (!this.dragging && this.isEventNearStartEvent(currentEvent, startEvent)) {
        return;
      }
      this.dragging = true;
      const event = {
        type: "dragStarted",
        target: el
      };
      this.eventService.dispatchEvent(event);
      this.currentDragParams.onDragStart(startEvent);
      this.currentDragParams.onDragging(startEvent);
    }
    this.currentDragParams.onDragging(currentEvent);
  }
  onTouchMove(touchEvent, el) {
    const touch = this.getFirstActiveTouch(touchEvent.touches);
    if (!touch) {
      return;
    }
    this.onCommonMove(touch, this.touchStart, el);
  }
  // only gets called after a mouse down - as this is only added after mouseDown
  // and is removed when mouseUp happens
  onMouseMove(mouseEvent, el) {
    if (_isBrowserSafari()) {
      const eDocument = this.gos.getDocument();
      eDocument.getSelection()?.removeAllRanges();
    }
    if (this.shouldPreventMouseEvent(mouseEvent)) {
      mouseEvent.preventDefault();
    }
    this.onCommonMove(mouseEvent, this.mouseStartEvent, el);
  }
  shouldPreventMouseEvent(mouseEvent) {
    const isEnableCellTextSelect = this.gos.get("enableCellTextSelection");
    const isMouseMove = mouseEvent.type === "mousemove";
    return (
      // when `isEnableCellTextSelect` is `true`, we need to preventDefault on mouseMove
      // to avoid the grid text being selected while dragging components.
      isEnableCellTextSelect && isMouseMove && mouseEvent.cancelable && this.mouseEventService.isEventFromThisGrid(mouseEvent) && !this.isOverFormFieldElement(mouseEvent)
    );
  }
  isOverFormFieldElement(mouseEvent) {
    const el = mouseEvent.target;
    const tagName = el?.tagName.toLocaleLowerCase();
    return !!tagName?.match("^a$|textarea|input|select|button");
  }
  onTouchUp(touchEvent, el) {
    let touch = this.getFirstActiveTouch(touchEvent.changedTouches);
    if (!touch) {
      touch = this.touchLastTime;
    }
    this.onUpCommon(touch, el);
  }
  onMouseUp(mouseEvent, el) {
    this.onUpCommon(mouseEvent, el);
  }
  onUpCommon(eventOrTouch, el) {
    if (this.dragging) {
      this.dragging = false;
      this.currentDragParams.onDragStop(eventOrTouch);
      const event = {
        type: "dragStopped",
        target: el
      };
      this.eventService.dispatchEvent(event);
    }
    this.mouseStartEvent = null;
    this.startTarget = null;
    this.touchStart = null;
    this.touchLastTime = null;
    this.currentDragParams = null;
    this.dragEndFunctions.forEach((func) => func());
    this.dragEndFunctions.length = 0;
  }
};

// community-modules/core/src/rendering/row/rowDragComp.ts
var RowDragComp = class extends Component {
  constructor(cellValueFn, rowNode, column, customGui, dragStartPixels, suppressVisibilityChange) {
    super();
    this.cellValueFn = cellValueFn;
    this.rowNode = rowNode;
    this.column = column;
    this.customGui = customGui;
    this.dragStartPixels = dragStartPixels;
    this.suppressVisibilityChange = suppressVisibilityChange;
    this.dragSource = null;
  }
  wireBeans(beans) {
    this.beans = beans;
  }
  isCustomGui() {
    return this.customGui != null;
  }
  postConstruct() {
    if (!this.customGui) {
      this.setTemplate(
        /* html */
        `<div class="ag-drag-handle ag-row-drag" aria-hidden="true"></div>`
      );
      this.getGui().appendChild(_createIconNoSpan("rowDrag", this.gos, null));
      this.addDragSource();
    } else {
      this.setDragElement(this.customGui, this.dragStartPixels);
    }
    this.checkCompatibility();
    if (!this.suppressVisibilityChange) {
      const strategy = this.gos.get("rowDragManaged") ? new ManagedVisibilityStrategy(this, this.beans, this.rowNode, this.column) : new NonManagedVisibilityStrategy(this, this.beans, this.rowNode, this.column);
      this.createManagedBean(strategy, this.beans.context);
    }
  }
  setDragElement(dragElement, dragStartPixels) {
    this.setTemplateFromElement(dragElement);
    this.addDragSource(dragStartPixels);
  }
  getSelectedNodes() {
    const isRowDragMultiRow = this.gos.get("rowDragMultiRow");
    if (!isRowDragMultiRow) {
      return [this.rowNode];
    }
    const selection = this.beans.selectionService.getSelectedNodes();
    return selection.indexOf(this.rowNode) !== -1 ? selection : [this.rowNode];
  }
  // returns true if all compatibility items work out
  checkCompatibility() {
    const managed = this.gos.get("rowDragManaged");
    const treeData = this.gos.get("treeData");
    if (treeData && managed) {
      _warnOnce("If using row drag with tree data, you cannot have rowDragManaged=true");
    }
  }
  getDragItem() {
    return {
      rowNode: this.rowNode,
      rowNodes: this.getSelectedNodes(),
      columns: this.column ? [this.column] : void 0,
      defaultTextValue: this.cellValueFn()
    };
  }
  getRowDragText(column) {
    if (column) {
      const colDef = column.getColDef();
      if (colDef.rowDragText) {
        return colDef.rowDragText;
      }
    }
    return this.gos.get("rowDragText");
  }
  addDragSource(dragStartPixels = 4) {
    if (this.dragSource) {
      this.removeDragSource();
    }
    const translate = this.localeService.getLocaleTextFunc();
    this.dragSource = {
      type: 2 /* RowDrag */,
      eElement: this.getGui(),
      dragItemName: () => {
        const dragItem = this.getDragItem();
        const dragItemCount = dragItem.rowNodes?.length || 1;
        const rowDragText = this.getRowDragText(this.column);
        if (rowDragText) {
          return rowDragText(dragItem, dragItemCount);
        }
        return dragItemCount === 1 ? this.cellValueFn() : `${dragItemCount} ${translate("rowDragRows", "rows")}`;
      },
      getDragItem: () => this.getDragItem(),
      dragStartPixels,
      dragSourceDomDataKey: this.gos.getDomDataKey()
    };
    this.beans.dragAndDropService.addDragSource(this.dragSource, true);
  }
  destroy() {
    this.removeDragSource();
    super.destroy();
  }
  removeDragSource() {
    if (this.dragSource) {
      this.beans.dragAndDropService.removeDragSource(this.dragSource);
    }
    this.dragSource = null;
  }
};
var VisibilityStrategy = class extends BeanStub {
  constructor(parent, rowNode, column) {
    super();
    this.parent = parent;
    this.rowNode = rowNode;
    this.column = column;
  }
  setDisplayedOrVisible(neverDisplayed) {
    const displayedOptions = { skipAriaHidden: true };
    if (neverDisplayed) {
      this.parent.setDisplayed(false, displayedOptions);
    } else {
      let shown = true;
      let isShownSometimes = false;
      if (this.column) {
        shown = this.column.isRowDrag(this.rowNode) || this.parent.isCustomGui();
        isShownSometimes = _isFunction(this.column.getColDef().rowDrag);
      }
      if (isShownSometimes) {
        this.parent.setDisplayed(true, displayedOptions);
        this.parent.setVisible(shown, displayedOptions);
      } else {
        this.parent.setDisplayed(shown, displayedOptions);
        this.parent.setVisible(true, displayedOptions);
      }
    }
  }
};
var NonManagedVisibilityStrategy = class extends VisibilityStrategy {
  constructor(parent, beans, rowNode, column) {
    super(parent, rowNode, column);
    this.beans = beans;
  }
  postConstruct() {
    this.addManagedPropertyListener("suppressRowDrag", this.onSuppressRowDrag.bind(this));
    const listener = this.workOutVisibility.bind(this);
    this.addManagedListeners(this.rowNode, {
      dataChanged: listener,
      cellChanged: listener
    });
    this.addManagedListeners(this.beans.eventService, { newColumnsLoaded: listener });
    this.workOutVisibility();
  }
  onSuppressRowDrag() {
    this.workOutVisibility();
  }
  workOutVisibility() {
    const neverDisplayed = this.gos.get("suppressRowDrag");
    this.setDisplayedOrVisible(neverDisplayed);
  }
};
var ManagedVisibilityStrategy = class extends VisibilityStrategy {
  constructor(parent, beans, rowNode, column) {
    super(parent, rowNode, column);
    this.beans = beans;
  }
  postConstruct() {
    const listener = this.workOutVisibility.bind(this);
    this.addManagedListeners(this.beans.eventService, {
      sortChanged: listener,
      filterChanged: listener,
      columnRowGroupChanged: listener,
      newColumnsLoaded: listener
    });
    this.addManagedListeners(this.rowNode, {
      dataChanged: listener,
      cellChanged: listener
    });
    this.addManagedPropertyListener("suppressRowDrag", this.onSuppressRowDrag.bind(this));
    this.workOutVisibility();
  }
  onSuppressRowDrag() {
    this.workOutVisibility();
  }
  workOutVisibility() {
    const gridBodyCon = this.beans.ctrlsService.getGridBodyCtrl();
    const rowDragFeature = gridBodyCon.getRowDragFeature();
    const shouldPreventRowMove = rowDragFeature && rowDragFeature.shouldPreventRowMove();
    const suppressRowDrag = this.gos.get("suppressRowDrag");
    const hasExternalDropZones = this.beans.dragAndDropService.hasExternalDropZones();
    const neverDisplayed = shouldPreventRowMove && !hasExternalDropZones || suppressRowDrag;
    this.setDisplayedOrVisible(neverDisplayed);
  }
};

// community-modules/core/src/entities/rowNode.ts
var _RowNode = class _RowNode {
  constructor(beans) {
    /** The current row index. If the row is filtered out or in a collapsed group, this value will be `null`. */
    this.rowIndex = null;
    /** The key for the group eg Ireland, UK, USA */
    this.key = null;
    /** Children mapped by the pivot columns. */
    this.childrenMapped = {};
    /**
     * This will be `true` if it has a rowIndex assigned, otherwise `false`.
     */
    this.displayed = false;
    /** The row top position in pixels. */
    this.rowTop = null;
    /** The top pixel for this row last time, makes sense if data set was ordered or filtered,
     * it is used so new rows can animate in from their old position. */
    this.oldRowTop = null;
    /** `true` by default - can be overridden via gridOptions.isRowSelectable(rowNode) */
    this.selectable = true;
    /** Used by sorting service - to give deterministic sort to groups. Previously we
     * just id for this, however id is a string and had slower sorting compared to numbers. */
    this.__objectId = _RowNode.OBJECT_ID_SEQUENCE++;
    /** When one or more Columns are using autoHeight, this keeps track of height of each autoHeight Cell,
     * indexed by the Column ID. */
    this.__autoHeights = {};
    /** `true` when nodes with the same id are being removed and added as part of the same batch transaction */
    this.alreadyRendered = false;
    this.highlighted = null;
    this.hovered = false;
    this.selected = false;
    this.beans = beans;
  }
  /**
   * Replaces the data on the `rowNode`. When this method is called, the grid will refresh the entire rendered row if it is displayed.
   */
  setData(data) {
    this.setDataCommon(data, false);
  }
  // similar to setRowData, however it is expected that the data is the same data item. this
  // is intended to be used with Redux type stores, where the whole data can be changed. we are
  // guaranteed that the data is the same entity (so grid doesn't need to worry about the id of the
  // underlying data changing, hence doesn't need to worry about selection). the grid, upon receiving
  // dataChanged event, will refresh the cells rather than rip them all out (so user can show transitions).
  /**
   * Updates the data on the `rowNode`. When this method is called, the grid will refresh the entire rendered row if it is displayed.
   */
  updateData(data) {
    this.setDataCommon(data, true);
  }
  setDataCommon(data, update) {
    const oldData = this.data;
    this.data = data;
    this.beans.valueCache.onDataChanged();
    this.updateDataOnDetailNode();
    this.checkRowSelectable();
    this.resetQuickFilterAggregateText();
    const event = this.createDataChangedEvent(data, oldData, update);
    this.localEventService?.dispatchEvent(event);
  }
  // when we are doing master / detail, the detail node is lazy created, but then kept around.
  // so if we show / hide the detail, the same detail rowNode is used. so we need to keep the data
  // in sync, otherwise expand/collapse of the detail would still show the old values.
  updateDataOnDetailNode() {
    if (this.detailNode) {
      this.detailNode.data = this.data;
    }
  }
  createDataChangedEvent(newData, oldData, update) {
    return {
      type: "dataChanged",
      node: this,
      oldData,
      newData,
      update
    };
  }
  getRowIndexString() {
    if (this.rowIndex == null) {
      _errorOnce(
        "Could not find rowIndex, this means tasks are being executed on a rowNode that has been removed from the grid."
      );
      return null;
    }
    if (this.rowPinned === "top") {
      return "t-" + this.rowIndex;
    }
    if (this.rowPinned === "bottom") {
      return "b-" + this.rowIndex;
    }
    return this.rowIndex.toString();
  }
  createDaemonNode() {
    const oldNode = new _RowNode(this.beans);
    oldNode.id = this.id;
    oldNode.data = this.data;
    oldNode.__daemon = true;
    oldNode.selected = this.selected;
    oldNode.level = this.level;
    return oldNode;
  }
  setDataAndId(data, id) {
    const oldNode = _exists(this.id) ? this.createDaemonNode() : null;
    const oldData = this.data;
    this.data = data;
    this.updateDataOnDetailNode();
    this.setId(id);
    this.checkRowSelectable();
    this.beans.selectionService.syncInRowNode(this, oldNode);
    const event = this.createDataChangedEvent(data, oldData, false);
    this.localEventService?.dispatchEvent(event);
  }
  checkRowSelectable() {
    const isRowSelectableFunc = this.beans.gos.get("isRowSelectable");
    this.setRowSelectable(isRowSelectableFunc ? isRowSelectableFunc(this) : true);
  }
  setRowSelectable(newVal, suppressSelectionUpdate) {
    if (this.selectable !== newVal) {
      this.selectable = newVal;
      this.dispatchRowEvent("selectableChanged");
      if (suppressSelectionUpdate) {
        return;
      }
      const isGroupSelectsChildren = this.beans.gos.get("groupSelectsChildren");
      if (isGroupSelectsChildren) {
        const selected = this.calculateSelectedFromChildren();
        this.setSelectedParams({
          newValue: selected ?? false,
          source: "selectableChanged"
        });
        return;
      }
      if (this.isSelected() && !this.selectable) {
        this.setSelectedParams({
          newValue: false,
          source: "selectableChanged"
        });
      }
    }
  }
  setId(id) {
    const getRowIdFunc = this.beans.gos.getRowIdCallback();
    if (getRowIdFunc) {
      if (this.data) {
        const parentKeys = this.getGroupKeys(true);
        this.id = getRowIdFunc({
          data: this.data,
          parentKeys: parentKeys.length > 0 ? parentKeys : void 0,
          level: this.level
        });
        if (this.id.startsWith(_RowNode.ID_PREFIX_ROW_GROUP)) {
          _errorOnce(
            `Row IDs cannot start with ${_RowNode.ID_PREFIX_ROW_GROUP}, this is a reserved prefix for AG Grid's row grouping feature.`
          );
        }
      } else {
        this.id = void 0;
      }
    } else {
      this.id = id;
    }
  }
  getGroupKeys(excludeSelf = false) {
    const keys = [];
    let pointer = this;
    if (excludeSelf) {
      pointer = pointer.parent;
    }
    while (pointer && pointer.level >= 0) {
      keys.push(pointer.key);
      pointer = pointer.parent;
    }
    keys.reverse();
    return keys;
  }
  isPixelInRange(pixel) {
    if (!_exists(this.rowTop) || !_exists(this.rowHeight)) {
      return false;
    }
    return pixel >= this.rowTop && pixel < this.rowTop + this.rowHeight;
  }
  updateIfDifferent(key, value, eventName) {
    if (this[key] === value) {
      return;
    }
    this[key] = value;
    this.dispatchRowEvent(eventName);
  }
  setFirstChild(firstChild) {
    this.updateIfDifferent("firstChild", firstChild, "firstChildChanged");
  }
  setLastChild(lastChild) {
    this.updateIfDifferent("lastChild", lastChild, "lastChildChanged");
  }
  setChildIndex(childIndex) {
    this.updateIfDifferent("childIndex", childIndex, "childIndexChanged");
  }
  setRowTop(rowTop) {
    this.oldRowTop = this.rowTop;
    if (this.rowTop === rowTop) {
      return;
    }
    this.rowTop = rowTop;
    this.dispatchRowEvent("topChanged");
    this.setDisplayed(rowTop !== null);
  }
  clearRowTopAndRowIndex() {
    this.oldRowTop = null;
    this.setRowTop(null);
    this.setRowIndex(null);
  }
  setDisplayed(displayed) {
    this.updateIfDifferent("displayed", displayed, "displayedChanged");
  }
  setDragging(dragging) {
    this.updateIfDifferent("dragging", dragging, "draggingChanged");
  }
  setHighlighted(highlighted) {
    this.updateIfDifferent("highlighted", highlighted, "rowHighlightChanged");
  }
  setHovered(hovered) {
    if (this.hovered === hovered) {
      return;
    }
    this.hovered = hovered;
  }
  isHovered() {
    return this.hovered;
  }
  setAllChildrenCount(allChildrenCount) {
    this.updateIfDifferent("allChildrenCount", allChildrenCount, "allChildrenCountChanged");
  }
  setMaster(master) {
    if (this.master === master) {
      return;
    }
    if (this.master && !master) {
      this.expanded = false;
    }
    this.master = master;
    this.dispatchRowEvent("masterChanged");
  }
  setGroup(group) {
    if (this.group === group) {
      return;
    }
    if (this.group && !group) {
      this.expanded = false;
    }
    this.group = group;
    this.updateHasChildren();
    this.checkRowSelectable();
    this.dispatchRowEvent("groupChanged");
  }
  /**
   * Sets the row height.
   * Call if you want to change the height initially assigned to the row.
   * After calling, you must call `api.onRowHeightChanged()` so the grid knows it needs to work out the placement of the rows. */
  setRowHeight(rowHeight, estimated = false) {
    this.rowHeight = rowHeight;
    this.rowHeightEstimated = estimated;
    this.dispatchRowEvent("heightChanged");
  }
  setRowAutoHeight(cellHeight, column) {
    if (!this.__autoHeights) {
      this.__autoHeights = {};
    }
    this.__autoHeights[column.getId()] = cellHeight;
    if (cellHeight != null) {
      if (this.checkAutoHeightsDebounced == null) {
        this.checkAutoHeightsDebounced = _debounce(this.checkAutoHeights.bind(this), 1);
      }
      this.checkAutoHeightsDebounced();
    }
  }
  checkAutoHeights() {
    let notAllPresent = false;
    let nonePresent = true;
    let newRowHeight = 0;
    const autoHeights = this.__autoHeights;
    if (autoHeights == null) {
      return;
    }
    const displayedAutoHeightCols = this.beans.visibleColsService.getAllAutoHeightCols();
    displayedAutoHeightCols.forEach((col) => {
      let cellHeight = autoHeights[col.getId()];
      if (cellHeight == null) {
        if (this.beans.columnModel.isColSpanActive()) {
          let activeColsForRow = [];
          switch (col.getPinned()) {
            case "left":
              activeColsForRow = this.beans.visibleColsService.getLeftColsForRow(this);
              break;
            case "right":
              activeColsForRow = this.beans.visibleColsService.getRightColsForRow(this);
              break;
            case null:
              activeColsForRow = this.beans.columnViewportService.getColsWithinViewport(this);
              break;
          }
          if (activeColsForRow.includes(col)) {
            notAllPresent = true;
            return;
          }
          cellHeight = -1;
        } else {
          notAllPresent = true;
          return;
        }
      } else {
        nonePresent = false;
      }
      if (cellHeight > newRowHeight) {
        newRowHeight = cellHeight;
      }
    });
    if (notAllPresent) {
      return;
    }
    if (nonePresent || newRowHeight < 10) {
      newRowHeight = this.beans.gos.getRowHeightForNode(this).height;
    }
    if (newRowHeight == this.rowHeight) {
      return;
    }
    this.setRowHeight(newRowHeight);
    const rowModel = this.beans.rowModel;
    if (rowModel.onRowHeightChangedDebounced) {
      rowModel.onRowHeightChangedDebounced();
    }
  }
  setRowIndex(rowIndex) {
    this.updateIfDifferent("rowIndex", rowIndex, "rowIndexChanged");
  }
  setUiLevel(uiLevel) {
    this.updateIfDifferent("uiLevel", uiLevel, "uiLevelChanged");
  }
  /**
   * Set the expanded state of this rowNode. Pass `true` to expand and `false` to collapse.
   */
  setExpanded(expanded, e, forceSync) {
    if (this.expanded === expanded) {
      return;
    }
    this.expanded = expanded;
    this.dispatchRowEvent("expandedChanged");
    const event = { ...this.createGlobalRowEvent("rowGroupOpened"), expanded, event: e || null };
    this.beans.rowNodeEventThrottle.dispatchExpanded(event, forceSync);
    this.beans.rowRenderer.refreshCells({ rowNodes: [this] });
  }
  createGlobalRowEvent(type) {
    return this.beans.gos.addGridCommonParams({
      type,
      node: this,
      data: this.data,
      rowIndex: this.rowIndex,
      rowPinned: this.rowPinned
    });
  }
  /**
   * Replaces the value on the `rowNode` for the specified column. When complete,
   * the grid will refresh the rendered cell on the required row only.
   * **Note**: This method only fires `onCellEditRequest` when the Grid is in **Read Only** mode.
   *
   * @param colKey The column where the value should be updated
   * @param newValue The new value
   * @param eventSource The source of the event
   * @returns `true` if the value was changed, otherwise `false`.
   */
  setDataValue(colKey, newValue, eventSource) {
    const getColumnFromKey = () => {
      if (typeof colKey !== "string") {
        return colKey;
      }
      return this.beans.columnModel.getCol(colKey) ?? this.beans.columnModel.getColDefCol(colKey);
    };
    const column = getColumnFromKey();
    const oldValue = this.getValueFromValueService(column);
    if (this.beans.gos.get("readOnlyEdit")) {
      this.dispatchEventForSaveValueReadOnly(column, oldValue, newValue, eventSource);
      return false;
    }
    const valueChanged = this.beans.valueService.setValue(this, column, newValue, eventSource);
    this.dispatchCellChangedEvent(column, newValue, oldValue);
    this.checkRowSelectable();
    return valueChanged;
  }
  getValueFromValueService(column) {
    const lockedClosedGroup = this.leafGroup && this.beans.columnModel.isPivotMode();
    const isOpenGroup = this.group && this.expanded && !this.footer && !lockedClosedGroup;
    let includeFooter = false;
    const groupIncludeFooterOpt = this.beans.gos.get("groupTotalRow") ?? this.beans.gos.get("groupIncludeFooter");
    if (typeof groupIncludeFooterOpt !== "function") {
      includeFooter = !!groupIncludeFooterOpt;
    } else {
      const groupIncludeFooterCb = this.beans.gos.getCallback("groupTotalRow") ?? this.beans.gos.getCallback("groupIncludeFooter");
      includeFooter = !!groupIncludeFooterCb({ node: this });
    }
    const groupAlwaysShowAggData = this.beans.gos.get("groupSuppressBlankHeader");
    const ignoreAggData = isOpenGroup && includeFooter && !groupAlwaysShowAggData;
    const value = this.beans.valueService.getValue(column, this, false, ignoreAggData);
    return value;
  }
  dispatchEventForSaveValueReadOnly(column, oldValue, newValue, eventSource) {
    const event = this.beans.gos.addGridCommonParams({
      type: "cellEditRequest",
      event: null,
      rowIndex: this.rowIndex,
      rowPinned: this.rowPinned,
      column,
      colDef: column.getColDef(),
      data: this.data,
      node: this,
      oldValue,
      newValue,
      value: newValue,
      source: eventSource
    });
    this.beans.eventService.dispatchEvent(event);
  }
  setGroupValue(colKey, newValue) {
    const column = this.beans.columnModel.getCol(colKey);
    if (_missing(this.groupData)) {
      this.groupData = {};
    }
    const columnId = column.getColId();
    const oldValue = this.groupData[columnId];
    if (oldValue === newValue) {
      return;
    }
    this.groupData[columnId] = newValue;
    this.dispatchCellChangedEvent(column, newValue, oldValue);
  }
  // sets the data for an aggregation
  setAggData(newAggData) {
    const oldAggData = this.aggData;
    this.aggData = newAggData;
    if (this.localEventService) {
      const eventFunc = (colId) => {
        const value = this.aggData ? this.aggData[colId] : void 0;
        const oldValue = oldAggData ? oldAggData[colId] : void 0;
        if (value === oldValue) {
          return;
        }
        const column = this.beans.columnModel.getCol(colId);
        if (!column) {
          return;
        }
        this.dispatchCellChangedEvent(column, value, oldValue);
      };
      for (const key in this.aggData) {
        eventFunc(key);
      }
      for (const key in newAggData) {
        if (key in this.aggData) {
          continue;
        }
        eventFunc(key);
      }
    }
  }
  updateHasChildren() {
    let newValue = this.group && !this.footer || this.childrenAfterGroup && this.childrenAfterGroup.length > 0;
    const isSsrm = this.beans.gos.isRowModelType("serverSide");
    if (isSsrm) {
      const isTreeData = this.beans.gos.get("treeData");
      const isGroupFunc = this.beans.gos.get("isServerSideGroup");
      newValue = !this.stub && !this.footer && (isTreeData ? !!isGroupFunc && isGroupFunc(this.data) : !!this.group);
    }
    if (newValue !== this.__hasChildren) {
      this.__hasChildren = !!newValue;
      this.dispatchRowEvent("hasChildrenChanged");
    }
  }
  hasChildren() {
    if (this.__hasChildren == null) {
      this.updateHasChildren();
    }
    return this.__hasChildren;
  }
  isEmptyRowGroupNode() {
    return (this.group && _missingOrEmpty(this.childrenAfterGroup)) ?? false;
  }
  dispatchCellChangedEvent(column, newValue, oldValue) {
    const cellChangedEvent = {
      type: "cellChanged",
      node: this,
      column,
      newValue,
      oldValue
    };
    this.localEventService?.dispatchEvent(cellChangedEvent);
  }
  /**
   * The first time `quickFilter` runs, the grid creates a one-off string representation of the row.
   * This string is then used for the quick filter instead of hitting each column separately.
   * When you edit, using grid editing, this string gets cleared down.
   * However if you edit without using grid editing, you will need to clear this string down for the row to be updated with the new values.
   * Otherwise new values will not work with the `quickFilter`. */
  resetQuickFilterAggregateText() {
    this.quickFilterAggregateText = null;
  }
  /** Returns:
   * - `true` if the node can be expanded, i.e it is a group or master row.
   * - `false` if the node cannot be expanded
   */
  isExpandable() {
    if (this.footer) {
      return false;
    }
    if (this.beans.columnModel.isPivotMode()) {
      return this.hasChildren() && !this.leafGroup;
    }
    return this.hasChildren() || !!this.master;
  }
  /** Returns:
   * - `true` if node is selected,
   * - `false` if the node isn't selected
   * - `undefined` if it's partially selected (group where not all children are selected). */
  isSelected() {
    if (this.footer) {
      return this.sibling.isSelected();
    }
    return this.selected;
  }
  /** Perform a depth-first search of this node and its children. */
  depthFirstSearch(callback) {
    if (this.childrenAfterGroup) {
      this.childrenAfterGroup.forEach((child) => child.depthFirstSearch(callback));
    }
    callback(this);
  }
  // + selectionController.calculatedSelectedForAllGroupNodes()
  calculateSelectedFromChildren() {
    let atLeastOneSelected = false;
    let atLeastOneDeSelected = false;
    let atLeastOneMixed = false;
    if (!this.childrenAfterGroup?.length) {
      return this.selectable ? this.selected : null;
    }
    for (let i = 0; i < this.childrenAfterGroup.length; i++) {
      const child = this.childrenAfterGroup[i];
      let childState = child.isSelected();
      if (!child.selectable) {
        const selectable = child.calculateSelectedFromChildren();
        if (selectable === null) {
          continue;
        }
        childState = selectable;
      }
      switch (childState) {
        case true:
          atLeastOneSelected = true;
          break;
        case false:
          atLeastOneDeSelected = true;
          break;
        default:
          atLeastOneMixed = true;
          break;
      }
    }
    if (atLeastOneMixed || atLeastOneSelected && atLeastOneDeSelected) {
      return void 0;
    }
    if (atLeastOneSelected) {
      return true;
    }
    if (atLeastOneDeSelected) {
      return false;
    }
    if (!this.selectable) {
      return null;
    }
    return this.selected;
  }
  setSelectedInitialValue(selected) {
    this.selected = selected;
  }
  dispatchRowEvent(type) {
    const event = {
      type,
      node: this
    };
    this.localEventService?.dispatchEvent(event);
  }
  selectThisNode(newValue, e, source = "api") {
    const selectionNotAllowed = !this.selectable && newValue;
    const selectionNotChanged = this.selected === newValue;
    if (selectionNotAllowed || selectionNotChanged) {
      return false;
    }
    this.selected = newValue;
    this.dispatchRowEvent("rowSelected");
    const sibling = this.sibling;
    if (sibling && sibling.footer && sibling.localEventService) {
      sibling.dispatchRowEvent("rowSelected");
    }
    const event = {
      ...this.createGlobalRowEvent("rowSelected"),
      event: e || null,
      source
    };
    this.beans.eventService.dispatchEvent(event);
    return true;
  }
  /**
   * Select (or deselect) the node.
   * @param newValue -`true` for selection, `false` for deselection.
   * @param clearSelection - If selecting, then passing `true` will select the node exclusively (i.e. NOT do multi select). If doing deselection, `clearSelection` has no impact.
   * @param source - Source property that will appear in the `selectionChanged` event.
   */
  setSelected(newValue, clearSelection = false, source = "api") {
    if (typeof source === "boolean") {
      _warnOnce(
        "since version v30, rowNode.setSelected() property `suppressFinishActions` has been removed, please use `gridApi.setNodesSelected()` for bulk actions, and the event `source` property for ignoring events instead."
      );
      return;
    }
    this.setSelectedParams({
      newValue,
      clearSelection,
      rangeSelect: false,
      source
    });
  }
  // this is for internal use only. To make calling code more readable, this is the same method as setSelected except it takes names parameters
  setSelectedParams(params) {
    if (this.rowPinned) {
      _warnOnce("cannot select pinned rows");
      return 0;
    }
    if (this.id === void 0) {
      _warnOnce("cannot select node until id for node is known");
      return 0;
    }
    return this.beans.selectionService.setNodesSelected({ ...params, nodes: [this.footer ? this.sibling : this] });
  }
  /**
   * Returns:
   * - `true` if node is either pinned to the `top` or `bottom`
   * - `false` if the node isn't pinned
   */
  isRowPinned() {
    return this.rowPinned === "top" || this.rowPinned === "bottom";
  }
  isParentOfNode(potentialParent) {
    let parentNode = this.parent;
    while (parentNode) {
      if (parentNode === potentialParent) {
        return true;
      }
      parentNode = parentNode.parent;
    }
    return false;
  }
  /** Add an event listener. */
  addEventListener(eventType, userListener) {
    if (!this.localEventService) {
      this.localEventService = new LocalEventService();
    }
    if (this.beans.frameworkOverrides.shouldWrapOutgoing && !this.frameworkEventListenerService) {
      this.localEventService.setFrameworkOverrides(this.beans.frameworkOverrides);
      this.frameworkEventListenerService = new FrameworkEventListenerService(this.beans.frameworkOverrides);
    }
    const listener = this.frameworkEventListenerService?.wrap(userListener) ?? userListener;
    this.localEventService.addEventListener(eventType, listener);
  }
  /** Remove event listener. */
  removeEventListener(eventType, userListener) {
    if (!this.localEventService) {
      return;
    }
    const listener = this.frameworkEventListenerService?.unwrap(userListener) ?? userListener;
    this.localEventService.removeEventListener(eventType, listener);
    if (this.localEventService.noRegisteredListenersExist()) {
      this.localEventService = null;
    }
  }
  onMouseEnter() {
    this.dispatchRowEvent("mouseEnter");
  }
  onMouseLeave() {
    this.dispatchRowEvent("mouseLeave");
  }
  getFirstChildOfFirstChild(rowGroupColumn) {
    let currentRowNode = this;
    let isCandidate = true;
    let foundFirstChildPath = false;
    let nodeToSwapIn = null;
    while (isCandidate && !foundFirstChildPath) {
      const parentRowNode = currentRowNode.parent;
      const firstChild = _exists(parentRowNode) && currentRowNode.firstChild;
      if (firstChild) {
        if (parentRowNode.rowGroupColumn === rowGroupColumn) {
          foundFirstChildPath = true;
          nodeToSwapIn = parentRowNode;
        }
      } else {
        isCandidate = false;
      }
      currentRowNode = parentRowNode;
    }
    return foundFirstChildPath ? nodeToSwapIn : null;
  }
  /**
   * Returns:
   * - `true` if the node is a full width cell
   * - `false` if the node is not a full width cell
   */
  isFullWidthCell() {
    if (this.detail) {
      return true;
    }
    const isFullWidthCellFunc = this.beans.gos.getCallback("isFullWidthRow");
    return isFullWidthCellFunc ? isFullWidthCellFunc({ rowNode: this }) : false;
  }
  /**
   * Returns the route of the row node. If the Row Node is a group, it returns the route to that Row Node.
   * If the Row Node is not a group, it returns `undefined`.
   */
  getRoute() {
    if (this.key == null) {
      return;
    }
    const res = [];
    let pointer = this;
    while (pointer && pointer.key != null) {
      res.push(pointer.key);
      pointer = pointer.parent;
    }
    return res.reverse();
  }
  createFooter() {
    if (this.sibling) {
      return;
    }
    const ignoredProperties = /* @__PURE__ */ new Set(["eventService", "__objectId", "sticky"]);
    const footerNode = new _RowNode(this.beans);
    Object.keys(this).forEach((key) => {
      if (ignoredProperties.has(key)) {
        return;
      }
      footerNode[key] = this[key];
    });
    footerNode.footer = true;
    footerNode.setRowTop(null);
    footerNode.setRowIndex(null);
    footerNode.oldRowTop = null;
    footerNode.id = "rowGroupFooter_" + this.id;
    footerNode.sibling = this;
    this.sibling = footerNode;
  }
  // Only used by SSRM. In CSRM this is never used as footers should always be present for
  // the purpose of exporting collapsed groups. In SSRM it is not possible to export collapsed
  // groups anyway, so can destroy footers.
  destroyFooter() {
    if (!this.sibling) {
      return;
    }
    this.sibling.setRowTop(null);
    this.sibling.setRowIndex(null);
    this.sibling = void 0;
  }
};
_RowNode.ID_PREFIX_ROW_GROUP = "row-group-";
_RowNode.ID_PREFIX_TOP_PINNED = "t-";
_RowNode.ID_PREFIX_BOTTOM_PINNED = "b-";
_RowNode.OBJECT_ID_SEQUENCE = 0;
var RowNode = _RowNode;

// community-modules/core/src/filter/filterManager.ts
var FilterManager = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "filterManager";
    // when we're waiting for cell data types to be inferred, we need to defer filter model updates
    this.advancedFilterModelUpdateQueue = [];
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.dataTypeService = beans.dataTypeService;
    this.quickFilterService = beans.quickFilterService;
    this.advancedFilterService = beans.advancedFilterService;
    this.columnFilterService = beans.columnFilterService;
  }
  postConstruct() {
    this.addManagedEventListeners({
      columnValueChanged: this.refreshFiltersForAggregations.bind(this),
      columnPivotChanged: this.refreshFiltersForAggregations.bind(this),
      columnPivotModeChanged: this.refreshFiltersForAggregations.bind(this),
      newColumnsLoaded: this.updateAdvancedFilterColumns.bind(this),
      columnVisible: this.updateAdvancedFilterColumns.bind(this),
      advancedFilterEnabledChanged: ({ enabled }) => this.onAdvancedFilterEnabledChanged(enabled),
      dataTypesInferred: this.processFilterModelUpdateQueue.bind(this)
    });
    this.externalFilterPresent = this.isExternalFilterPresentCallback();
    this.addManagedPropertyListeners(["isExternalFilterPresent", "doesExternalFilterPass"], () => {
      this.onFilterChanged({ source: "api" });
    });
    this.updateAggFiltering();
    this.addManagedPropertyListener("groupAggFiltering", () => {
      this.updateAggFiltering();
      this.onFilterChanged();
    });
    this.addManagedPropertyListener(
      "advancedFilterModel",
      (event) => this.setAdvancedFilterModel(event.currentValue)
    );
    if (this.quickFilterService) {
      this.addManagedListeners(this.quickFilterService, {
        quickFilterChanged: () => this.onFilterChanged({ source: "quickFilter" })
      });
    }
  }
  isExternalFilterPresentCallback() {
    const isFilterPresent = this.gos.getCallback("isExternalFilterPresent");
    if (typeof isFilterPresent === "function") {
      return isFilterPresent({});
    }
    return false;
  }
  doesExternalFilterPass(node) {
    const doesFilterPass = this.gos.get("doesExternalFilterPass");
    if (typeof doesFilterPass === "function") {
      return doesFilterPass(node);
    }
    return false;
  }
  setFilterModel(model, source = "api") {
    if (this.isAdvancedFilterEnabled()) {
      this.warnAdvancedFilters();
      return;
    }
    this.columnFilterService?.setFilterModel(model, source);
  }
  getFilterModel() {
    return this.columnFilterService?.getFilterModel() ?? {};
  }
  isColumnFilterPresent() {
    return !!this.columnFilterService?.isColumnFilterPresent();
  }
  isAggregateFilterPresent() {
    return !!this.columnFilterService?.isAggregateFilterPresent();
  }
  isExternalFilterPresent() {
    return this.externalFilterPresent;
  }
  isChildFilterPresent() {
    return this.isColumnFilterPresent() || this.isQuickFilterPresent() || this.isExternalFilterPresent() || this.isAdvancedFilterPresent();
  }
  isAdvancedFilterPresent() {
    return this.isAdvancedFilterEnabled() && this.advancedFilterService.isFilterPresent();
  }
  onAdvancedFilterEnabledChanged(enabled) {
    if (enabled) {
      if (this.columnFilterService?.disableColumnFilters()) {
        this.onFilterChanged({ source: "advancedFilter" });
      }
    } else {
      if (this.advancedFilterService?.isFilterPresent()) {
        this.advancedFilterService.setModel(null);
        this.onFilterChanged({ source: "advancedFilter" });
      }
    }
  }
  isAdvancedFilterEnabled() {
    return !!this.advancedFilterService?.isEnabled();
  }
  isAdvancedFilterHeaderActive() {
    return this.isAdvancedFilterEnabled() && this.advancedFilterService.isHeaderActive();
  }
  isAnyFilterPresent() {
    return this.isQuickFilterPresent() || this.isColumnFilterPresent() || this.isAggregateFilterPresent() || this.isExternalFilterPresent() || this.isAdvancedFilterPresent();
  }
  resetQuickFilterCache() {
    this.quickFilterService?.resetQuickFilterCache();
  }
  refreshFiltersForAggregations() {
    const isAggFiltering = this.gos.getGroupAggFiltering();
    if (isAggFiltering) {
      this.onFilterChanged();
    }
  }
  onFilterChanged(params = {}) {
    const { source, additionalEventAttributes, columns = [] } = params;
    this.externalFilterPresent = this.isExternalFilterPresentCallback();
    (this.columnFilterService ? this.columnFilterService.updateBeforeFilterChanged(params) : AgPromise.resolve()).then(() => {
      const filterChangedEvent = {
        source,
        type: "filterChanged",
        columns
      };
      if (additionalEventAttributes) {
        _mergeDeep(filterChangedEvent, additionalEventAttributes);
      }
      this.eventService.dispatchEvent(filterChangedEvent);
      this.columnFilterService?.updateAfterFilterChanged();
    });
  }
  isSuppressFlashingCellsBecauseFiltering() {
    return !!this.columnFilterService?.isSuppressFlashingCellsBecauseFiltering();
  }
  isQuickFilterPresent() {
    return !!this.quickFilterService?.isQuickFilterPresent();
  }
  updateAggFiltering() {
    this.aggFiltering = !!this.gos.getGroupAggFiltering();
  }
  isAggregateQuickFilterPresent() {
    return this.isQuickFilterPresent() && this.shouldApplyQuickFilterAfterAgg();
  }
  isNonAggregateQuickFilterPresent() {
    return this.isQuickFilterPresent() && !this.shouldApplyQuickFilterAfterAgg();
  }
  shouldApplyQuickFilterAfterAgg() {
    return (this.aggFiltering || this.columnModel.isPivotMode()) && !this.gos.get("applyQuickFilterBeforePivotOrAgg");
  }
  doesRowPassOtherFilters(filterToSkip, node) {
    return this.doesRowPassFilter({ rowNode: node, filterInstanceToSkip: filterToSkip });
  }
  doesRowPassAggregateFilters(params) {
    if (this.isAggregateQuickFilterPresent() && !this.quickFilterService.doesRowPassQuickFilter(params.rowNode)) {
      return false;
    }
    if (this.isAggregateFilterPresent() && !this.columnFilterService.doAggregateFiltersPass(params.rowNode, params.filterInstanceToSkip)) {
      return false;
    }
    return true;
  }
  doesRowPassFilter(params) {
    if (this.isNonAggregateQuickFilterPresent() && !this.quickFilterService.doesRowPassQuickFilter(params.rowNode)) {
      return false;
    }
    if (this.isExternalFilterPresent() && !this.doesExternalFilterPass(params.rowNode)) {
      return false;
    }
    if (this.isColumnFilterPresent() && !this.columnFilterService.doColumnFiltersPass(params.rowNode, params.filterInstanceToSkip)) {
      return false;
    }
    if (this.isAdvancedFilterPresent() && !this.advancedFilterService.doesFilterPass(params.rowNode)) {
      return false;
    }
    return true;
  }
  isFilterActive(column) {
    return !!this.columnFilterService?.isFilterActive(column);
  }
  getOrCreateFilterWrapper(column) {
    return this.columnFilterService?.getOrCreateFilterWrapper(column) ?? null;
  }
  getDefaultFloatingFilter(column) {
    return this.columnFilterService.getDefaultFloatingFilter(column);
  }
  createFilterParams(column, colDef) {
    return this.columnFilterService.createFilterParams(column, colDef);
  }
  // for group filters, can change dynamically whether they are allowed or not
  isFilterAllowed(column) {
    if (this.isAdvancedFilterEnabled()) {
      return false;
    }
    return !!this.columnFilterService?.isFilterAllowed(column);
  }
  getFloatingFilterCompDetails(column, showParentFilter) {
    return this.columnFilterService?.getFloatingFilterCompDetails(column, showParentFilter);
  }
  getCurrentFloatingFilterParentModel(column) {
    return this.columnFilterService?.getCurrentFloatingFilterParentModel(column);
  }
  // destroys the filter, so it no longer takes part
  destroyFilter(column, source = "api") {
    this.columnFilterService?.destroyFilter(column, source);
  }
  areFilterCompsDifferent(oldCompDetails, newCompDetails) {
    return !!this.columnFilterService?.areFilterCompsDifferent(oldCompDetails, newCompDetails);
  }
  getAdvancedFilterModel() {
    return this.isAdvancedFilterEnabled() ? this.advancedFilterService.getModel() : null;
  }
  setAdvancedFilterModel(expression) {
    if (!this.isAdvancedFilterEnabled()) {
      return;
    }
    if (this.dataTypeService?.isPendingInference()) {
      this.advancedFilterModelUpdateQueue.push(expression);
      return;
    }
    this.advancedFilterService.setModel(expression ?? null);
    this.onFilterChanged({ source: "advancedFilter" });
  }
  toggleAdvancedFilterBuilder(show, source) {
    if (!this.isAdvancedFilterEnabled()) {
      return;
    }
    this.advancedFilterService.getCtrl().toggleFilterBuilder(source, show);
  }
  updateAdvancedFilterColumns() {
    if (!this.isAdvancedFilterEnabled()) {
      return;
    }
    if (this.advancedFilterService.updateValidity()) {
      this.onFilterChanged({ source: "advancedFilter" });
    }
  }
  hasFloatingFilters() {
    if (this.isAdvancedFilterEnabled()) {
      return false;
    }
    return !!this.columnFilterService?.hasFloatingFilters();
  }
  getFilterInstance(key, callback) {
    if (this.isAdvancedFilterEnabled()) {
      this.warnAdvancedFilters();
      return void 0;
    }
    return this.columnFilterService?.getFilterInstance(key, callback);
  }
  getColumnFilterInstance(key) {
    if (this.isAdvancedFilterEnabled()) {
      this.warnAdvancedFilters();
      return Promise.resolve(void 0);
    }
    return this.columnFilterService?.getColumnFilterInstance(key) ?? Promise.resolve(void 0);
  }
  warnAdvancedFilters() {
    _warnOnce("Column Filter API methods have been disabled as Advanced Filters are enabled.");
  }
  setupAdvancedFilterHeaderComp(eCompToInsertBefore) {
    this.advancedFilterService?.getCtrl().setupHeaderComp(eCompToInsertBefore);
  }
  getHeaderRowCount() {
    return this.isAdvancedFilterHeaderActive() ? 1 : 0;
  }
  getHeaderHeight() {
    return this.isAdvancedFilterHeaderActive() ? this.advancedFilterService.getCtrl().getHeaderHeight() : 0;
  }
  processFilterModelUpdateQueue() {
    this.advancedFilterModelUpdateQueue.forEach((model) => this.setAdvancedFilterModel(model));
    this.advancedFilterModelUpdateQueue = [];
  }
  getColumnFilterModel(key) {
    return this.columnFilterService?.getColumnFilterModel(key);
  }
  setColumnFilterModel(key, model) {
    if (this.isAdvancedFilterEnabled()) {
      this.warnAdvancedFilters();
      return Promise.resolve();
    }
    return this.columnFilterService?.setColumnFilterModel(key, model) ?? Promise.resolve();
  }
};

// community-modules/core/src/filter/filterWrapperComp.ts
var FilterWrapperComp = class extends Component {
  constructor(column, source) {
    super(
      /* html */
      `<div class="ag-filter"></div>`
    );
    this.column = column;
    this.source = source;
    this.filterWrapper = null;
  }
  wireBeans(beans) {
    this.filterManager = beans.filterManager;
    this.columnModel = beans.columnModel;
  }
  postConstruct() {
    this.createFilter(true);
    this.addManagedEventListeners({ filterDestroyed: this.onFilterDestroyed.bind(this) });
  }
  hasFilter() {
    return !!this.filterWrapper;
  }
  getFilter() {
    return this.filterWrapper?.filterPromise ?? null;
  }
  afterInit() {
    return this.filterWrapper?.filterPromise?.then(() => {
    }) ?? AgPromise.resolve();
  }
  afterGuiAttached(params) {
    this.filterWrapper?.filterPromise?.then((filter) => {
      filter?.afterGuiAttached?.(params);
    });
  }
  afterGuiDetached() {
    this.filterWrapper?.filterPromise?.then((filter) => {
      filter?.afterGuiDetached?.();
    });
  }
  createFilter(init) {
    const { column, source } = this;
    this.filterWrapper = this.filterManager?.getOrCreateFilterWrapper(column) ?? null;
    if (!this.filterWrapper?.filterPromise) {
      return;
    }
    this.filterWrapper.filterPromise.then((filter) => {
      const guiFromFilter = filter.getGui();
      if (!_exists(guiFromFilter)) {
        _warnOnce(`getGui method from filter returned ${guiFromFilter}; it should be a DOM element.`);
      }
      this.appendChild(guiFromFilter);
      if (init) {
        const event = {
          type: "filterOpened",
          column,
          source,
          eGui: this.getGui()
        };
        this.eventService.dispatchEvent(event);
      }
    });
  }
  onFilterDestroyed(event) {
    if ((event.source === "api" || event.source === "paramsUpdated") && event.column.getId() === this.column.getId() && this.columnModel.getColDefCol(this.column)) {
      _clearElement(this.getGui());
      this.createFilter();
    }
  }
  destroy() {
    this.filterWrapper = null;
    super.destroy();
  }
};

// community-modules/core/src/rendering/features/positionableFeature.ts
var RESIZE_CONTAINER_STYLE = "ag-resizer-wrapper";
var RESIZE_TEMPLATE = (
  /* html */
  `<div class="${RESIZE_CONTAINER_STYLE}">
        <div data-ref="eTopLeftResizer" class="ag-resizer ag-resizer-topLeft"></div>
        <div data-ref="eTopResizer" class="ag-resizer ag-resizer-top"></div>
        <div data-ref="eTopRightResizer" class="ag-resizer ag-resizer-topRight"></div>
        <div data-ref="eRightResizer" class="ag-resizer ag-resizer-right"></div>
        <div data-ref="eBottomRightResizer" class="ag-resizer ag-resizer-bottomRight"></div>
        <div data-ref="eBottomResizer" class="ag-resizer ag-resizer-bottom"></div>
        <div data-ref="eBottomLeftResizer" class="ag-resizer ag-resizer-bottomLeft"></div>
        <div data-ref="eLeftResizer" class="ag-resizer ag-resizer-left"></div>
    </div>`
);
var PositionableFeature = class extends BeanStub {
  constructor(element, config) {
    super();
    this.element = element;
    this.dragStartPosition = {
      x: 0,
      y: 0
    };
    this.position = {
      x: 0,
      y: 0
    };
    this.lastSize = {
      width: -1,
      height: -1
    };
    this.positioned = false;
    this.resizersAdded = false;
    this.resizeListeners = [];
    this.boundaryEl = null;
    this.isResizing = false;
    this.isMoving = false;
    this.resizable = {};
    this.movable = false;
    this.currentResizer = null;
    this.config = Object.assign({}, { popup: false }, config);
  }
  wireBeans(beans) {
    this.popupService = beans.popupService;
    this.resizeObserverService = beans.resizeObserverService;
    this.dragService = beans.dragService;
  }
  center() {
    const { clientHeight, clientWidth } = this.offsetParent;
    const x = clientWidth / 2 - this.getWidth() / 2;
    const y = clientHeight / 2 - this.getHeight() / 2;
    this.offsetElement(x, y);
  }
  initialisePosition() {
    if (this.positioned) {
      return;
    }
    const { centered, forcePopupParentAsOffsetParent, minWidth, width, minHeight, height, x, y } = this.config;
    if (!this.offsetParent) {
      this.setOffsetParent();
    }
    let computedMinHeight = 0;
    let computedMinWidth = 0;
    const isElementVisible = _isVisible(this.element);
    if (isElementVisible) {
      const boundaryEl = this.findBoundaryElement();
      const offsetParentComputedStyles = window.getComputedStyle(boundaryEl);
      if (offsetParentComputedStyles.minWidth != null) {
        const paddingWidth = boundaryEl.offsetWidth - this.element.offsetWidth;
        computedMinWidth = parseInt(offsetParentComputedStyles.minWidth, 10) - paddingWidth;
      }
      if (offsetParentComputedStyles.minHeight != null) {
        const paddingHeight = boundaryEl.offsetHeight - this.element.offsetHeight;
        computedMinHeight = parseInt(offsetParentComputedStyles.minHeight, 10) - paddingHeight;
      }
    }
    this.minHeight = minHeight || computedMinHeight;
    this.minWidth = minWidth || computedMinWidth;
    if (width) {
      this.setWidth(width);
    }
    if (height) {
      this.setHeight(height);
    }
    if (!width || !height) {
      this.refreshSize();
    }
    if (centered) {
      this.center();
    } else if (x || y) {
      this.offsetElement(x, y);
    } else if (isElementVisible && forcePopupParentAsOffsetParent) {
      let boundaryEl = this.boundaryEl;
      let initialisedDuringPositioning = true;
      if (!boundaryEl) {
        boundaryEl = this.findBoundaryElement();
        initialisedDuringPositioning = false;
      }
      if (boundaryEl) {
        const top = parseFloat(boundaryEl.style.top);
        const left = parseFloat(boundaryEl.style.left);
        if (initialisedDuringPositioning) {
          this.offsetElement(isNaN(left) ? 0 : left, isNaN(top) ? 0 : top);
        } else {
          this.setPosition(left, top);
        }
      }
    }
    this.positioned = !!this.offsetParent;
  }
  isPositioned() {
    return this.positioned;
  }
  getPosition() {
    return this.position;
  }
  setMovable(movable, moveElement) {
    if (!this.config.popup || movable === this.movable) {
      return;
    }
    this.movable = movable;
    const params = this.moveElementDragListener || {
      eElement: moveElement,
      onDragStart: this.onMoveStart.bind(this),
      onDragging: this.onMove.bind(this),
      onDragStop: this.onMoveEnd.bind(this)
    };
    if (movable) {
      this.dragService.addDragSource(params);
      this.moveElementDragListener = params;
    } else {
      this.dragService.removeDragSource(params);
      this.moveElementDragListener = void 0;
    }
  }
  setResizable(resizable) {
    this.clearResizeListeners();
    if (resizable) {
      this.addResizers();
    } else {
      this.removeResizers();
    }
    if (typeof resizable === "boolean") {
      if (resizable === false) {
        return;
      }
      resizable = {
        topLeft: resizable,
        top: resizable,
        topRight: resizable,
        right: resizable,
        bottomRight: resizable,
        bottom: resizable,
        bottomLeft: resizable,
        left: resizable
      };
    }
    Object.keys(resizable).forEach((side) => {
      const resizableStructure = resizable;
      const isSideResizable = !!resizableStructure[side];
      const resizerEl = this.getResizerElement(side);
      const params = {
        dragStartPixels: 0,
        eElement: resizerEl,
        onDragStart: (e) => this.onResizeStart(e, side),
        onDragging: this.onResize.bind(this),
        onDragStop: (e) => this.onResizeEnd(e, side)
      };
      if (isSideResizable || !this.isAlive() && !isSideResizable) {
        if (isSideResizable) {
          this.dragService.addDragSource(params);
          this.resizeListeners.push(params);
          resizerEl.style.pointerEvents = "all";
        } else {
          resizerEl.style.pointerEvents = "none";
        }
        this.resizable[side] = isSideResizable;
      }
    });
  }
  removeSizeFromEl() {
    this.element.style.removeProperty("height");
    this.element.style.removeProperty("width");
    this.element.style.removeProperty("flex");
  }
  restoreLastSize() {
    this.element.style.flex = "0 0 auto";
    const { height, width } = this.lastSize;
    if (width !== -1) {
      this.element.style.width = `${width}px`;
    }
    if (height !== -1) {
      this.element.style.height = `${height}px`;
    }
  }
  getHeight() {
    return this.element.offsetHeight;
  }
  setHeight(height) {
    const { popup } = this.config;
    const eGui = this.element;
    let isPercent = false;
    if (typeof height === "string" && height.indexOf("%") !== -1) {
      _setFixedHeight(eGui, height);
      height = _getAbsoluteHeight(eGui);
      isPercent = true;
    } else {
      height = Math.max(this.minHeight, height);
      if (this.positioned) {
        const availableHeight = this.getAvailableHeight();
        if (availableHeight && height > availableHeight) {
          height = availableHeight;
        }
      }
    }
    if (this.getHeight() === height) {
      return;
    }
    if (!isPercent) {
      if (popup) {
        _setFixedHeight(eGui, height);
      } else {
        eGui.style.height = `${height}px`;
        eGui.style.flex = "0 0 auto";
        this.lastSize.height = typeof height === "number" ? height : parseFloat(height);
      }
    } else {
      eGui.style.maxHeight = "unset";
      eGui.style.minHeight = "unset";
    }
  }
  getAvailableHeight() {
    const { popup, forcePopupParentAsOffsetParent } = this.config;
    if (!this.positioned) {
      this.initialisePosition();
    }
    const { clientHeight } = this.offsetParent;
    if (!clientHeight) {
      return null;
    }
    const elRect = this.element.getBoundingClientRect();
    const offsetParentRect = this.offsetParent.getBoundingClientRect();
    const yPosition = popup ? this.position.y : elRect.top;
    const parentTop = popup ? 0 : offsetParentRect.top;
    let additionalHeight = 0;
    if (forcePopupParentAsOffsetParent) {
      const parentEl = this.element.parentElement;
      if (parentEl) {
        const { bottom } = parentEl.getBoundingClientRect();
        additionalHeight = bottom - elRect.bottom;
      }
    }
    const availableHeight = clientHeight + parentTop - yPosition - additionalHeight;
    return availableHeight;
  }
  getWidth() {
    return this.element.offsetWidth;
  }
  setWidth(width) {
    const eGui = this.element;
    const { popup } = this.config;
    let isPercent = false;
    if (typeof width === "string" && width.indexOf("%") !== -1) {
      _setFixedWidth(eGui, width);
      width = _getAbsoluteWidth(eGui);
      isPercent = true;
    } else if (this.positioned) {
      width = Math.max(this.minWidth, width);
      const { clientWidth } = this.offsetParent;
      const xPosition = popup ? this.position.x : this.element.getBoundingClientRect().left;
      if (clientWidth && width + xPosition > clientWidth) {
        width = clientWidth - xPosition;
      }
    }
    if (this.getWidth() === width) {
      return;
    }
    if (!isPercent) {
      if (this.config.popup) {
        _setFixedWidth(eGui, width);
      } else {
        eGui.style.width = `${width}px`;
        eGui.style.flex = " unset";
        this.lastSize.width = typeof width === "number" ? width : parseFloat(width);
      }
    } else {
      eGui.style.maxWidth = "unset";
      eGui.style.minWidth = "unset";
    }
  }
  offsetElement(x = 0, y = 0) {
    const { forcePopupParentAsOffsetParent } = this.config;
    const ePopup = forcePopupParentAsOffsetParent ? this.boundaryEl : this.element;
    if (!ePopup) {
      return;
    }
    this.popupService.positionPopup({
      ePopup,
      keepWithinBounds: true,
      skipObserver: this.movable || this.isResizable(),
      updatePosition: () => ({ x, y })
    });
    this.setPosition(parseFloat(ePopup.style.left), parseFloat(ePopup.style.top));
  }
  constrainSizeToAvailableHeight(constrain) {
    if (!this.config.forcePopupParentAsOffsetParent) {
      return;
    }
    const applyMaxHeightToElement = () => {
      const availableHeight = this.getAvailableHeight();
      this.element.style.setProperty("max-height", `${availableHeight}px`);
    };
    if (constrain) {
      this.resizeObserverSubscriber = this.resizeObserverService.observeResize(
        this.popupService.getPopupParent(),
        applyMaxHeightToElement
      );
    } else {
      this.element.style.removeProperty("max-height");
      if (this.resizeObserverSubscriber) {
        this.resizeObserverSubscriber();
        this.resizeObserverSubscriber = void 0;
      }
    }
  }
  setPosition(x, y) {
    this.position.x = x;
    this.position.y = y;
  }
  updateDragStartPosition(x, y) {
    this.dragStartPosition = { x, y };
  }
  calculateMouseMovement(params) {
    const { e, isLeft, isTop, anywhereWithin, topBuffer } = params;
    const xDiff = e.clientX - this.dragStartPosition.x;
    const yDiff = e.clientY - this.dragStartPosition.y;
    const movementX = this.shouldSkipX(e, !!isLeft, !!anywhereWithin, xDiff) ? 0 : xDiff;
    const movementY = this.shouldSkipY(e, !!isTop, topBuffer, yDiff) ? 0 : yDiff;
    return { movementX, movementY };
  }
  shouldSkipX(e, isLeft, anywhereWithin, diff) {
    const elRect = this.element.getBoundingClientRect();
    const parentRect = this.offsetParent.getBoundingClientRect();
    const boundaryElRect = this.boundaryEl.getBoundingClientRect();
    const xPosition = this.config.popup ? this.position.x : elRect.left;
    let skipX = xPosition <= 0 && parentRect.left >= e.clientX || parentRect.right <= e.clientX && parentRect.right <= boundaryElRect.right;
    if (skipX) {
      return true;
    }
    if (isLeft) {
      skipX = // skip if we are moving to the left and the cursor
      // is positioned to the right of the left side anchor
      diff < 0 && e.clientX > xPosition + parentRect.left || // skip if we are moving to the right and the cursor
      // is positioned to the left of the dialog
      diff > 0 && e.clientX < xPosition + parentRect.left;
    } else {
      if (anywhereWithin) {
        skipX = diff < 0 && e.clientX > boundaryElRect.right || diff > 0 && e.clientX < xPosition + parentRect.left;
      } else {
        skipX = // if the movement is bound to the right side of the dialog
        // we skip if we are moving to the left and the cursor
        // is to the right of the dialog
        diff < 0 && e.clientX > boundaryElRect.right || // or skip if we are moving to the right and the cursor
        // is to the left of the right side anchor
        diff > 0 && e.clientX < boundaryElRect.right;
      }
    }
    return skipX;
  }
  shouldSkipY(e, isTop, topBuffer = 0, diff) {
    const elRect = this.element.getBoundingClientRect();
    const parentRect = this.offsetParent.getBoundingClientRect();
    const boundaryElRect = this.boundaryEl.getBoundingClientRect();
    const yPosition = this.config.popup ? this.position.y : elRect.top;
    let skipY = yPosition <= 0 && parentRect.top >= e.clientY || parentRect.bottom <= e.clientY && parentRect.bottom <= boundaryElRect.bottom;
    if (skipY) {
      return true;
    }
    if (isTop) {
      skipY = // skip if we are moving to towards top and the cursor is
      // below the top anchor + topBuffer
      // note: topBuffer is used when moving the dialog using the title bar
      diff < 0 && e.clientY > yPosition + parentRect.top + topBuffer || // skip if we are moving to the bottom and the cursor is
      // above the top anchor
      diff > 0 && e.clientY < yPosition + parentRect.top;
    } else {
      skipY = // skip if we are moving towards the top and the cursor
      // is below the bottom anchor
      diff < 0 && e.clientY > boundaryElRect.bottom || // skip if we are moving towards the bottom and the cursor
      // is above the bottom anchor
      diff > 0 && e.clientY < boundaryElRect.bottom;
    }
    return skipY;
  }
  createResizeMap() {
    const eGui = this.element;
    this.resizerMap = {
      topLeft: { element: eGui.querySelector("[data-ref=eTopLeftResizer]") },
      top: { element: eGui.querySelector("[data-ref=eTopResizer]") },
      topRight: { element: eGui.querySelector("[data-ref=eTopRightResizer]") },
      right: { element: eGui.querySelector("[data-ref=eRightResizer]") },
      bottomRight: { element: eGui.querySelector("[data-ref=eBottomRightResizer]") },
      bottom: { element: eGui.querySelector("[data-ref=eBottomResizer]") },
      bottomLeft: { element: eGui.querySelector("[data-ref=eBottomLeftResizer]") },
      left: { element: eGui.querySelector("[data-ref=eLeftResizer]") }
    };
  }
  addResizers() {
    if (this.resizersAdded) {
      return;
    }
    const eGui = this.element;
    if (!eGui) {
      return;
    }
    const parser = new DOMParser();
    const resizers = parser.parseFromString(RESIZE_TEMPLATE, "text/html").body;
    eGui.appendChild(resizers.firstChild);
    this.createResizeMap();
    this.resizersAdded = true;
  }
  removeResizers() {
    this.resizerMap = void 0;
    const resizerEl = this.element.querySelector(`.${RESIZE_CONTAINER_STYLE}`);
    if (resizerEl) {
      this.element.removeChild(resizerEl);
    }
    this.resizersAdded = false;
  }
  getResizerElement(side) {
    return this.resizerMap[side].element;
  }
  onResizeStart(e, side) {
    this.boundaryEl = this.findBoundaryElement();
    if (!this.positioned) {
      this.initialisePosition();
    }
    this.currentResizer = {
      isTop: !!side.match(/top/i),
      isRight: !!side.match(/right/i),
      isBottom: !!side.match(/bottom/i),
      isLeft: !!side.match(/left/i)
    };
    this.element.classList.add("ag-resizing");
    this.resizerMap[side].element.classList.add("ag-active");
    const { popup, forcePopupParentAsOffsetParent } = this.config;
    if (!popup && !forcePopupParentAsOffsetParent) {
      this.applySizeToSiblings(this.currentResizer.isBottom || this.currentResizer.isTop);
    }
    this.isResizing = true;
    this.updateDragStartPosition(e.clientX, e.clientY);
  }
  getSiblings() {
    const element = this.element;
    const parent = element.parentElement;
    if (!parent) {
      return null;
    }
    return Array.prototype.slice.call(parent.children).filter((el) => !el.classList.contains("ag-hidden"));
  }
  getMinSizeOfSiblings() {
    const siblings = this.getSiblings() || [];
    let height = 0;
    let width = 0;
    for (let i = 0; i < siblings.length; i++) {
      const currentEl = siblings[i];
      const isFlex = !!currentEl.style.flex && currentEl.style.flex !== "0 0 auto";
      if (currentEl === this.element) {
        continue;
      }
      let nextHeight = this.minHeight || 0;
      let nextWidth = this.minWidth || 0;
      if (isFlex) {
        const computedStyle = window.getComputedStyle(currentEl);
        if (computedStyle.minHeight) {
          nextHeight = parseInt(computedStyle.minHeight, 10);
        }
        if (computedStyle.minWidth) {
          nextWidth = parseInt(computedStyle.minWidth, 10);
        }
      } else {
        nextHeight = currentEl.offsetHeight;
        nextWidth = currentEl.offsetWidth;
      }
      height += nextHeight;
      width += nextWidth;
    }
    return { height, width };
  }
  applySizeToSiblings(vertical) {
    let containerToFlex = null;
    const siblings = this.getSiblings();
    if (!siblings) {
      return;
    }
    for (let i = 0; i < siblings.length; i++) {
      const el = siblings[i];
      if (el === containerToFlex) {
        continue;
      }
      if (vertical) {
        el.style.height = `${el.offsetHeight}px`;
      } else {
        el.style.width = `${el.offsetWidth}px`;
      }
      el.style.flex = "0 0 auto";
      if (el === this.element) {
        containerToFlex = siblings[i + 1];
      }
    }
    if (containerToFlex) {
      containerToFlex.style.removeProperty("height");
      containerToFlex.style.removeProperty("min-height");
      containerToFlex.style.removeProperty("max-height");
      containerToFlex.style.flex = "1 1 auto";
    }
  }
  isResizable() {
    return Object.values(this.resizable).some((value) => value);
  }
  onResize(e) {
    if (!this.isResizing || !this.currentResizer) {
      return;
    }
    const { popup, forcePopupParentAsOffsetParent } = this.config;
    const { isTop, isRight, isBottom, isLeft } = this.currentResizer;
    const isHorizontal = isRight || isLeft;
    const isVertical = isBottom || isTop;
    const { movementX, movementY } = this.calculateMouseMovement({ e, isLeft, isTop });
    const xPosition = this.position.x;
    const yPosition = this.position.y;
    let offsetLeft = 0;
    let offsetTop = 0;
    if (isHorizontal && movementX) {
      const direction = isLeft ? -1 : 1;
      const oldWidth = this.getWidth();
      const newWidth = oldWidth + movementX * direction;
      let skipWidth = false;
      if (isLeft) {
        offsetLeft = oldWidth - newWidth;
        if (xPosition + offsetLeft <= 0 || newWidth <= this.minWidth) {
          skipWidth = true;
          offsetLeft = 0;
        }
      }
      if (!skipWidth) {
        this.setWidth(newWidth);
      }
    }
    if (isVertical && movementY) {
      const direction = isTop ? -1 : 1;
      const oldHeight = this.getHeight();
      const newHeight = oldHeight + movementY * direction;
      let skipHeight = false;
      if (isTop) {
        offsetTop = oldHeight - newHeight;
        if (yPosition + offsetTop <= 0 || newHeight <= this.minHeight) {
          skipHeight = true;
          offsetTop = 0;
        }
      } else {
        if (!this.config.popup && !this.config.forcePopupParentAsOffsetParent && oldHeight < newHeight && this.getMinSizeOfSiblings().height + newHeight > this.element.parentElement.offsetHeight) {
          skipHeight = true;
        }
      }
      if (!skipHeight) {
        this.setHeight(newHeight);
      }
    }
    this.updateDragStartPosition(e.clientX, e.clientY);
    if ((popup || forcePopupParentAsOffsetParent) && offsetLeft || offsetTop) {
      this.offsetElement(xPosition + offsetLeft, yPosition + offsetTop);
    }
  }
  onResizeEnd(e, side) {
    this.isResizing = false;
    this.currentResizer = null;
    this.boundaryEl = null;
    this.element.classList.remove("ag-resizing");
    this.resizerMap[side].element.classList.remove("ag-active");
    this.dispatchLocalEvent({ type: "resize" });
  }
  refreshSize() {
    const eGui = this.element;
    if (this.config.popup) {
      if (!this.config.width) {
        this.setWidth(eGui.offsetWidth);
      }
      if (!this.config.height) {
        this.setHeight(eGui.offsetHeight);
      }
    }
  }
  onMoveStart(e) {
    this.boundaryEl = this.findBoundaryElement();
    if (!this.positioned) {
      this.initialisePosition();
    }
    this.isMoving = true;
    this.element.classList.add("ag-moving");
    this.updateDragStartPosition(e.clientX, e.clientY);
  }
  onMove(e) {
    if (!this.isMoving) {
      return;
    }
    const { x, y } = this.position;
    let topBuffer;
    if (this.config.calculateTopBuffer) {
      topBuffer = this.config.calculateTopBuffer();
    }
    const { movementX, movementY } = this.calculateMouseMovement({
      e,
      isTop: true,
      anywhereWithin: true,
      topBuffer
    });
    this.offsetElement(x + movementX, y + movementY);
    this.updateDragStartPosition(e.clientX, e.clientY);
  }
  onMoveEnd() {
    this.isMoving = false;
    this.boundaryEl = null;
    this.element.classList.remove("ag-moving");
  }
  setOffsetParent() {
    if (this.config.forcePopupParentAsOffsetParent) {
      this.offsetParent = this.popupService.getPopupParent();
    } else {
      this.offsetParent = this.element.offsetParent;
    }
  }
  findBoundaryElement() {
    let el = this.element;
    while (el) {
      if (window.getComputedStyle(el).position !== "static") {
        return el;
      }
      el = el.parentElement;
    }
    return this.element;
  }
  clearResizeListeners() {
    while (this.resizeListeners.length) {
      const params = this.resizeListeners.pop();
      this.dragService.removeDragSource(params);
    }
  }
  destroy() {
    super.destroy();
    if (this.moveElementDragListener) {
      this.dragService.removeDragSource(this.moveElementDragListener);
    }
    this.constrainSizeToAvailableHeight(false);
    this.clearResizeListeners();
    this.removeResizers();
  }
};

// community-modules/core/src/widgets/managedFocusFeature.ts
var _ManagedFocusFeature = class _ManagedFocusFeature extends BeanStub {
  constructor(eFocusableElement, callbacks = {}) {
    super();
    this.eFocusableElement = eFocusableElement;
    this.callbacks = callbacks;
    this.callbacks = {
      shouldStopEventPropagation: () => false,
      onTabKeyDown: (e) => {
        if (e.defaultPrevented) {
          return;
        }
        const nextRoot = this.focusService.findNextFocusableElement(this.eFocusableElement, false, e.shiftKey);
        if (!nextRoot) {
          return;
        }
        nextRoot.focus();
        e.preventDefault();
      },
      ...callbacks
    };
  }
  wireBeans(beans) {
    this.focusService = beans.focusService;
  }
  postConstruct() {
    this.eFocusableElement.classList.add(_ManagedFocusFeature.FOCUS_MANAGED_CLASS);
    this.addKeyDownListeners(this.eFocusableElement);
    if (this.callbacks.onFocusIn) {
      this.addManagedElementListeners(this.eFocusableElement, { focusin: this.callbacks.onFocusIn });
    }
    if (this.callbacks.onFocusOut) {
      this.addManagedElementListeners(this.eFocusableElement, { focusout: this.callbacks.onFocusOut });
    }
  }
  addKeyDownListeners(eGui) {
    this.addManagedElementListeners(eGui, {
      keydown: (e) => {
        if (e.defaultPrevented || _isStopPropagationForAgGrid(e)) {
          return;
        }
        if (this.callbacks.shouldStopEventPropagation(e)) {
          _stopPropagationForAgGrid(e);
          return;
        }
        if (e.key === KeyCode.TAB) {
          this.callbacks.onTabKeyDown(e);
        } else if (this.callbacks.handleKeyDown) {
          this.callbacks.handleKeyDown(e);
        }
      }
    });
  }
};
_ManagedFocusFeature.FOCUS_MANAGED_CLASS = "ag-focus-managed";
var ManagedFocusFeature = _ManagedFocusFeature;

// community-modules/core/src/filter/filterLocaleText.ts
var FILTER_LOCALE_TEXT = {
  applyFilter: "Apply",
  clearFilter: "Clear",
  resetFilter: "Reset",
  cancelFilter: "Cancel",
  textFilter: "Text Filter",
  numberFilter: "Number Filter",
  dateFilter: "Date Filter",
  setFilter: "Set Filter",
  filterOoo: "Filter...",
  empty: "Choose one",
  equals: "Equals",
  notEqual: "Does not equal",
  lessThan: "Less than",
  greaterThan: "Greater than",
  inRange: "Between",
  inRangeStart: "From",
  inRangeEnd: "To",
  lessThanOrEqual: "Less than or equal to",
  greaterThanOrEqual: "Greater than or equal to",
  contains: "Contains",
  notContains: "Does not contain",
  startsWith: "Begins with",
  endsWith: "Ends with",
  blank: "Blank",
  notBlank: "Not blank",
  before: "Before",
  after: "After",
  andCondition: "AND",
  orCondition: "OR",
  dateFormatOoo: "yyyy-mm-dd"
};

// community-modules/core/src/filter/floating/provided/providedFilterUtils.ts
function getDebounceMs(params, debounceDefault) {
  if (isUseApplyButton(params)) {
    if (params.debounceMs != null) {
      _warnOnce("debounceMs is ignored when apply button is present");
    }
    return 0;
  }
  return params.debounceMs != null ? params.debounceMs : debounceDefault;
}
function isUseApplyButton(params) {
  return !!params.buttons && params.buttons.indexOf("apply") >= 0;
}

// community-modules/core/src/filter/provided/providedFilter.ts
var ProvidedFilter = class extends Component {
  constructor(filterNameKey) {
    super();
    this.filterNameKey = filterNameKey;
    this.applyActive = false;
    this.hidePopup = null;
    this.debouncePending = false;
    // after the user hits 'apply' the model gets copied to here. this is then the model that we use for
    // all filtering. so if user changes UI but doesn't hit apply, then the UI will be out of sync with this model.
    // this is what we want, as the UI should only become the 'active' filter once it's applied. when apply is
    // inactive, this model will be in sync (following the debounce ms). if the UI is not a valid filter
    // (eg the value is missing so nothing to filter on, or for set filter all checkboxes are checked so filter
    // not active) then this appliedModel will be null/undefined.
    this.appliedModel = null;
    this.eFilterBody = RefPlaceholder;
    this.buttonListeners = [];
  }
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
  }
  postConstruct() {
    this.resetTemplate();
    this.createManagedBean(
      new ManagedFocusFeature(this.getFocusableElement(), {
        handleKeyDown: this.handleKeyDown.bind(this)
      })
    );
    this.positionableFeature = new PositionableFeature(this.getPositionableElement(), {
      forcePopupParentAsOffsetParent: true
    });
    this.createBean(this.positionableFeature);
  }
  // override
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleKeyDown(e) {
  }
  getFilterTitle() {
    return this.translate(this.filterNameKey);
  }
  isFilterActive() {
    return !!this.appliedModel;
  }
  resetTemplate(paramsMap) {
    let eGui = this.getGui();
    if (eGui) {
      eGui.removeEventListener("submit", this.onFormSubmit);
    }
    const templateString = (
      /* html */
      `
            <form class="ag-filter-wrapper">
                <div class="ag-filter-body-wrapper ag-${this.getCssIdentifier()}-body-wrapper" data-ref="eFilterBody">
                    ${this.createBodyTemplate()}
                </div>
            </form>`
    );
    this.setTemplate(templateString, this.getAgComponents(), paramsMap);
    eGui = this.getGui();
    if (eGui) {
      eGui.addEventListener("submit", this.onFormSubmit);
    }
  }
  isReadOnly() {
    return !!this.providedFilterParams.readOnly;
  }
  init(params) {
    this.setParams(params);
    this.resetUiToDefaults(true).then(() => {
      this.updateUiVisibility();
      this.setupOnBtApplyDebounce();
    });
  }
  setParams(params) {
    this.providedFilterParams = params;
    this.applyActive = isUseApplyButton(params);
    this.resetButtonsPanel();
  }
  updateParams(params) {
    this.providedFilterParams = params;
    this.applyActive = isUseApplyButton(params);
    this.resetUiToActiveModel(this.getModel(), () => {
      this.updateUiVisibility();
      this.setupOnBtApplyDebounce();
    });
  }
  resetButtonsPanel() {
    const { buttons } = this.providedFilterParams;
    const hasButtons = buttons && buttons.length > 0 && !this.isReadOnly();
    if (!this.eButtonsPanel) {
      if (hasButtons) {
        this.eButtonsPanel = document.createElement("div");
        this.eButtonsPanel.classList.add("ag-filter-apply-panel");
      }
    } else {
      _clearElement(this.eButtonsPanel);
      this.buttonListeners.forEach((destroyFunc) => destroyFunc());
      this.buttonListeners = [];
    }
    if (!hasButtons) {
      if (this.eButtonsPanel) {
        _removeFromParent(this.eButtonsPanel);
      }
      return;
    }
    const fragment = document.createDocumentFragment();
    const addButton = (type) => {
      let text;
      let clickListener;
      switch (type) {
        case "apply":
          text = this.translate("applyFilter");
          clickListener = (e) => this.onBtApply(false, false, e);
          break;
        case "clear":
          text = this.translate("clearFilter");
          clickListener = () => this.onBtClear();
          break;
        case "reset":
          text = this.translate("resetFilter");
          clickListener = () => this.onBtReset();
          break;
        case "cancel":
          text = this.translate("cancelFilter");
          clickListener = (e) => {
            this.onBtCancel(e);
          };
          break;
        default:
          _warnOnce("Unknown button type specified");
          return;
      }
      const buttonType = type === "apply" ? "submit" : "button";
      const button = _loadTemplate(
        /* html */
        `<button
                    type="${buttonType}"
                    data-ref="${type}FilterButton"
                    class="ag-button ag-standard-button ag-filter-apply-panel-button"
                >${text}
                </button>`
      );
      this.buttonListeners.push(...this.addManagedElementListeners(button, { click: clickListener }));
      fragment.append(button);
    };
    buttons.forEach((type) => addButton(type));
    this.eButtonsPanel.append(fragment);
    this.getGui().appendChild(this.eButtonsPanel);
  }
  // subclasses can override this to provide alternative debounce defaults
  getDefaultDebounceMs() {
    return 0;
  }
  setupOnBtApplyDebounce() {
    const debounceMs = getDebounceMs(this.providedFilterParams, this.getDefaultDebounceMs());
    const debounceFunc = _debounce(this.checkApplyDebounce.bind(this), debounceMs);
    this.onBtApplyDebounce = () => {
      this.debouncePending = true;
      debounceFunc();
    };
  }
  checkApplyDebounce() {
    if (this.debouncePending) {
      this.debouncePending = false;
      this.onBtApply();
    }
  }
  getModel() {
    return this.appliedModel ? this.appliedModel : null;
  }
  setModel(model) {
    const promise = model != null ? this.setModelIntoUi(model) : this.resetUiToDefaults();
    return promise.then(() => {
      this.updateUiVisibility();
      this.applyModel("api");
    });
  }
  onBtCancel(e) {
    this.resetUiToActiveModel(this.getModel(), () => {
      this.handleCancelEnd(e);
    });
  }
  handleCancelEnd(e) {
    if (this.providedFilterParams.closeOnApply) {
      this.close(e);
    }
  }
  resetUiToActiveModel(currentModel, afterUiUpdatedFunc) {
    const afterAppliedFunc = () => {
      this.onUiChanged(false, "prevent");
      afterUiUpdatedFunc?.();
    };
    if (currentModel != null) {
      this.setModelIntoUi(currentModel).then(afterAppliedFunc);
    } else {
      this.resetUiToDefaults().then(afterAppliedFunc);
    }
  }
  onBtClear() {
    this.resetUiToDefaults().then(() => this.onUiChanged());
  }
  onBtReset() {
    this.onBtClear();
    this.onBtApply();
  }
  /**
   * Applies changes made in the UI to the filter, and returns true if the model has changed.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  applyModel(source = "api") {
    const newModel = this.getModelFromUi();
    if (!this.isModelValid(newModel)) {
      return false;
    }
    const previousModel = this.appliedModel;
    this.appliedModel = newModel;
    return !this.areModelsEqual(previousModel, newModel);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isModelValid(model) {
    return true;
  }
  onFormSubmit(e) {
    e.preventDefault();
  }
  onBtApply(afterFloatingFilter = false, afterDataChange = false, e) {
    if (e) {
      e.preventDefault();
    }
    if (this.applyModel(afterDataChange ? "rowDataUpdated" : "ui")) {
      const source = "columnFilter";
      this.providedFilterParams.filterChangedCallback({ afterFloatingFilter, afterDataChange, source });
    }
    const { closeOnApply } = this.providedFilterParams;
    if (closeOnApply && this.applyActive && !afterFloatingFilter && !afterDataChange) {
      this.close(e);
    }
  }
  onNewRowsLoaded() {
  }
  close(e) {
    if (!this.hidePopup) {
      return;
    }
    const keyboardEvent = e;
    const key = keyboardEvent && keyboardEvent.key;
    let params;
    if (key === "Enter" || key === "Space") {
      params = { keyboardEvent };
    }
    this.hidePopup(params);
    this.hidePopup = null;
  }
  /**
   * By default, if the change came from a floating filter it will be applied immediately, otherwise if there is no
   * apply button it will be applied after a debounce, otherwise it will not be applied at all. This behaviour can
   * be adjusted by using the apply parameter.
   */
  onUiChanged(fromFloatingFilter = false, apply) {
    this.updateUiVisibility();
    this.providedFilterParams.filterModifiedCallback();
    if (this.applyActive && !this.isReadOnly()) {
      const isValid = this.isModelValid(this.getModelFromUi());
      const applyFilterButton = this.queryForHtmlElement(`[data-ref="applyFilterButton"]`);
      if (applyFilterButton) {
        _setDisabled(applyFilterButton, !isValid);
      }
    }
    if (fromFloatingFilter && !apply || apply === "immediately") {
      this.onBtApply(fromFloatingFilter);
    } else if (!this.applyActive && !apply || apply === "debounce") {
      this.onBtApplyDebounce();
    }
  }
  afterGuiAttached(params) {
    if (params) {
      this.hidePopup = params.hidePopup;
    }
    this.refreshFilterResizer(params?.container);
  }
  refreshFilterResizer(containerType) {
    if (!this.positionableFeature || containerType === "toolPanel") {
      return;
    }
    const isResizable = containerType === "floatingFilter" || containerType === "columnFilter";
    const { positionableFeature, gos } = this;
    if (isResizable) {
      positionableFeature.restoreLastSize();
      positionableFeature.setResizable(
        gos.get("enableRtl") ? { bottom: true, bottomLeft: true, left: true } : { bottom: true, bottomRight: true, right: true }
      );
    } else {
      this.positionableFeature.removeSizeFromEl();
      this.positionableFeature.setResizable(false);
    }
    this.positionableFeature.constrainSizeToAvailableHeight(true);
  }
  afterGuiDetached() {
    this.checkApplyDebounce();
    if (this.positionableFeature) {
      this.positionableFeature.constrainSizeToAvailableHeight(false);
    }
  }
  refresh(newParams) {
    this.providedFilterParams = newParams;
    return true;
  }
  destroy() {
    const eGui = this.getGui();
    if (eGui) {
      eGui.removeEventListener("submit", this.onFormSubmit);
    }
    this.hidePopup = null;
    if (this.positionableFeature) {
      this.positionableFeature = this.destroyBean(this.positionableFeature);
    }
    this.appliedModel = null;
    super.destroy();
  }
  translate(key) {
    const translate = this.localeService.getLocaleTextFunc();
    return translate(key, FILTER_LOCALE_TEXT[key]);
  }
  getCellValue(rowNode) {
    return this.providedFilterParams.getValue(rowNode);
  }
  // override to control positionable feature
  getPositionableElement() {
    return this.eFilterBody;
  }
};

// community-modules/core/src/widgets/agRadioButton.ts
var AgRadioButton = class extends AgCheckbox {
  constructor(config) {
    super(config, "ag-radio-button", "radio");
  }
  isSelected() {
    return this.eInput.checked;
  }
  toggle() {
    if (this.eInput.disabled) {
      return;
    }
    if (!this.isSelected()) {
      this.setValue(true);
    }
  }
  addInputListeners() {
    super.addInputListeners();
    this.addManagedEventListeners({ checkboxChanged: this.onChange.bind(this) });
  }
  /**
   * This ensures that if another radio button in the same named group is selected, we deselect this radio button.
   * By default the browser does this for you, but we are managing classes ourselves in order to ensure input
   * elements are styled correctly in IE11, and the DOM 'changed' event is only fired when a button is selected,
   * not deselected, so we need to use our own event.
   */
  onChange(event) {
    if (event.selected && event.name && this.eInput.name && this.eInput.name === event.name && event.id && this.eInput.id !== event.id) {
      this.setValue(false, true);
    }
  }
};

// community-modules/core/src/widgets/agList.ts
var AgList = class extends Component {
  constructor(cssIdentifier = "default", unFocusable = false) {
    super(
      /* html */
      `<div class="ag-list ag-${cssIdentifier}-list" role="listbox"></div>`
    );
    this.cssIdentifier = cssIdentifier;
    this.unFocusable = unFocusable;
    this.activeClass = "ag-active-item";
    this.options = [];
    this.itemEls = [];
  }
  postConstruct() {
    const eGui = this.getGui();
    this.addManagedElementListeners(eGui, { mouseleave: () => this.clearHighlighted() });
    if (this.unFocusable) {
      return;
    }
    this.addManagedElementListeners(eGui, { keydown: this.handleKeyDown.bind(this) });
  }
  handleKeyDown(e) {
    const key = e.key;
    switch (key) {
      case KeyCode.ENTER:
        if (!this.highlightedEl) {
          this.setValue(this.getValue());
        } else {
          const pos = this.itemEls.indexOf(this.highlightedEl);
          this.setValueByIndex(pos);
        }
        break;
      case KeyCode.DOWN:
      case KeyCode.UP:
        e.preventDefault();
        this.navigate(key);
        break;
      case KeyCode.PAGE_DOWN:
      case KeyCode.PAGE_UP:
      case KeyCode.PAGE_HOME:
      case KeyCode.PAGE_END:
        e.preventDefault();
        this.navigateToPage(key);
        break;
    }
  }
  navigate(key) {
    const isDown = key === KeyCode.DOWN;
    let itemToHighlight;
    if (!this.highlightedEl) {
      itemToHighlight = this.itemEls[isDown ? 0 : this.itemEls.length - 1];
    } else {
      const currentIdx = this.itemEls.indexOf(this.highlightedEl);
      let nextPos = currentIdx + (isDown ? 1 : -1);
      nextPos = Math.min(Math.max(nextPos, 0), this.itemEls.length - 1);
      itemToHighlight = this.itemEls[nextPos];
    }
    this.highlightItem(itemToHighlight);
  }
  navigateToPage(key) {
    if (!this.highlightedEl || this.itemEls.length === 0) {
      return;
    }
    const currentIdx = this.itemEls.indexOf(this.highlightedEl);
    const rowCount = this.options.length - 1;
    const itemHeight = this.itemEls[0].clientHeight;
    const pageSize = Math.floor(this.getGui().clientHeight / itemHeight);
    let newIndex = -1;
    if (key === KeyCode.PAGE_HOME) {
      newIndex = 0;
    } else if (key === KeyCode.PAGE_END) {
      newIndex = rowCount;
    } else if (key === KeyCode.PAGE_DOWN) {
      newIndex = Math.min(currentIdx + pageSize, rowCount);
    } else if (key === KeyCode.PAGE_UP) {
      newIndex = Math.max(currentIdx - pageSize, 0);
    }
    if (newIndex === -1) {
      return;
    }
    this.highlightItem(this.itemEls[newIndex]);
  }
  addOptions(listOptions) {
    listOptions.forEach((listOption) => this.addOption(listOption));
    return this;
  }
  addOption(listOption) {
    const { value, text } = listOption;
    const valueToRender = text || value;
    this.options.push({ value, text: valueToRender });
    this.renderOption(value, valueToRender);
    this.updateIndices();
    return this;
  }
  clearOptions() {
    this.options = [];
    this.reset(true);
    this.itemEls.forEach((itemEl) => {
      _removeFromParent(itemEl);
    });
    this.itemEls = [];
  }
  updateIndices() {
    const options = this.getGui().querySelectorAll(".ag-list-item");
    options.forEach((option, idx) => {
      _setAriaPosInSet(option, idx + 1);
      _setAriaSetSize(option, options.length);
    });
  }
  renderOption(value, text) {
    const eDocument = this.gos.getDocument();
    const itemEl = eDocument.createElement("div");
    _setAriaRole(itemEl, "option");
    itemEl.classList.add("ag-list-item", `ag-${this.cssIdentifier}-list-item`);
    const span = eDocument.createElement("span");
    itemEl.appendChild(span);
    span.textContent = text;
    if (!this.unFocusable) {
      itemEl.tabIndex = -1;
    }
    this.itemEls.push(itemEl);
    this.addManagedListeners(itemEl, {
      mouseover: () => this.highlightItem(itemEl),
      mousedown: (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setValue(value);
      }
    });
    this.createManagedBean(
      new TooltipFeature({
        getTooltipValue: () => text,
        getGui: () => itemEl,
        getLocation: () => "UNKNOWN",
        // only show tooltips for items where the text cannot be fully displayed
        shouldDisplayTooltip: () => span.scrollWidth > span.clientWidth
      })
    );
    this.getGui().appendChild(itemEl);
  }
  setValue(value, silent) {
    if (this.value === value) {
      this.fireItemSelected();
      return this;
    }
    if (value == null) {
      this.reset(silent);
      return this;
    }
    const idx = this.options.findIndex((option) => option.value === value);
    if (idx !== -1) {
      const option = this.options[idx];
      this.value = option.value;
      this.displayValue = option.text;
      this.highlightItem(this.itemEls[idx]);
      if (!silent) {
        this.fireChangeEvent();
      }
    }
    return this;
  }
  setValueByIndex(idx) {
    return this.setValue(this.options[idx].value);
  }
  getValue() {
    return this.value;
  }
  getDisplayValue() {
    return this.displayValue;
  }
  refreshHighlighted() {
    this.clearHighlighted();
    const idx = this.options.findIndex((option) => option.value === this.value);
    if (idx !== -1) {
      this.highlightItem(this.itemEls[idx]);
    }
  }
  reset(silent) {
    this.value = null;
    this.displayValue = null;
    this.clearHighlighted();
    if (!silent) {
      this.fireChangeEvent();
    }
  }
  highlightItem(el) {
    if (!_isVisible(el)) {
      return;
    }
    this.clearHighlighted();
    this.highlightedEl = el;
    this.highlightedEl.classList.add(this.activeClass);
    _setAriaSelected(this.highlightedEl, true);
    const eGui = this.getGui();
    const { scrollTop, clientHeight } = eGui;
    const { offsetTop, offsetHeight } = el;
    if (offsetTop + offsetHeight > scrollTop + clientHeight || offsetTop < scrollTop) {
      this.highlightedEl.scrollIntoView({ block: "nearest" });
    }
    if (!this.unFocusable) {
      this.highlightedEl.focus();
    }
  }
  clearHighlighted() {
    if (!this.highlightedEl || !_isVisible(this.highlightedEl)) {
      return;
    }
    this.highlightedEl.classList.remove(this.activeClass);
    _setAriaSelected(this.highlightedEl, false);
    this.highlightedEl = null;
  }
  fireChangeEvent() {
    this.dispatchLocalEvent({ type: "fieldValueChanged" });
    this.fireItemSelected();
  }
  fireItemSelected() {
    this.dispatchLocalEvent({ type: "selectedItem" });
  }
};

// community-modules/core/src/widgets/agPickerField.ts
var AgPickerField = class extends AgAbstractField {
  constructor(config) {
    super(
      config,
      config?.template || /* html */
      `
            <div class="ag-picker-field" role="presentation">
                <div data-ref="eLabel"></div>
                    <div data-ref="eWrapper" class="ag-wrapper ag-picker-field-wrapper ag-picker-collapsed">
                    <div data-ref="eDisplayField" class="ag-picker-field-display"></div>
                    <div data-ref="eIcon" class="ag-picker-field-icon" aria-hidden="true"></div>
                </div>
            </div>`,
      config?.agComponents || [],
      config?.className
    );
    this.isPickerDisplayed = false;
    this.skipClick = false;
    this.pickerGap = 4;
    this.hideCurrentPicker = null;
    this.eLabel = RefPlaceholder;
    this.eWrapper = RefPlaceholder;
    this.eDisplayField = RefPlaceholder;
    this.eIcon = RefPlaceholder;
    this.ariaRole = config?.ariaRole;
    this.onPickerFocusIn = this.onPickerFocusIn.bind(this);
    this.onPickerFocusOut = this.onPickerFocusOut.bind(this);
    if (!config) {
      return;
    }
    const { pickerGap, maxPickerHeight, variableWidth, minPickerWidth, maxPickerWidth } = config;
    if (pickerGap != null) {
      this.pickerGap = pickerGap;
    }
    this.variableWidth = !!variableWidth;
    if (maxPickerHeight != null) {
      this.setPickerMaxHeight(maxPickerHeight);
    }
    if (minPickerWidth != null) {
      this.setPickerMinWidth(minPickerWidth);
    }
    if (maxPickerWidth != null) {
      this.setPickerMaxWidth(maxPickerWidth);
    }
  }
  wireBeans(beans) {
    this.popupService = beans.popupService;
  }
  postConstruct() {
    super.postConstruct();
    this.setupAria();
    const displayId = `ag-${this.getCompId()}-display`;
    this.eDisplayField.setAttribute("id", displayId);
    const ariaEl = this.getAriaElement();
    this.addManagedElementListeners(ariaEl, { keydown: this.onKeyDown.bind(this) });
    this.addManagedElementListeners(this.eLabel, { mousedown: this.onLabelOrWrapperMouseDown.bind(this) });
    this.addManagedElementListeners(this.eWrapper, { mousedown: this.onLabelOrWrapperMouseDown.bind(this) });
    const { pickerIcon, inputWidth } = this.config;
    if (pickerIcon) {
      const icon = _createIconNoSpan(pickerIcon, this.gos);
      if (icon) {
        this.eIcon.appendChild(icon);
      }
    }
    if (inputWidth != null) {
      this.setInputWidth(inputWidth);
    }
  }
  setupAria() {
    const ariaEl = this.getAriaElement();
    ariaEl.setAttribute("tabindex", this.gos.get("tabIndex").toString());
    _setAriaExpanded(ariaEl, false);
    if (this.ariaRole) {
      _setAriaRole(ariaEl, this.ariaRole);
    }
  }
  onLabelOrWrapperMouseDown(e) {
    if (e) {
      const focusableEl = this.getFocusableElement();
      if (focusableEl !== this.eWrapper && e?.target === focusableEl) {
        return;
      }
      e.preventDefault();
      this.getFocusableElement().focus();
    }
    if (this.skipClick) {
      this.skipClick = false;
      return;
    }
    if (this.isDisabled()) {
      return;
    }
    if (this.isPickerDisplayed) {
      this.hidePicker();
    } else {
      this.showPicker();
    }
  }
  onKeyDown(e) {
    switch (e.key) {
      case KeyCode.UP:
      case KeyCode.DOWN:
      case KeyCode.ENTER:
      case KeyCode.SPACE:
        e.preventDefault();
        this.onLabelOrWrapperMouseDown();
        break;
      case KeyCode.ESCAPE:
        if (this.isPickerDisplayed) {
          e.preventDefault();
          e.stopPropagation();
          if (this.hideCurrentPicker) {
            this.hideCurrentPicker();
          }
        }
        break;
    }
  }
  showPicker() {
    this.isPickerDisplayed = true;
    if (!this.pickerComponent) {
      this.pickerComponent = this.createPickerComponent();
    }
    const pickerGui = this.pickerComponent.getGui();
    pickerGui.addEventListener("focusin", this.onPickerFocusIn);
    pickerGui.addEventListener("focusout", this.onPickerFocusOut);
    this.hideCurrentPicker = this.renderAndPositionPicker();
    this.toggleExpandedStyles(true);
  }
  renderAndPositionPicker() {
    const eDocument = this.gos.getDocument();
    const ePicker = this.pickerComponent.getGui();
    if (!this.gos.get("suppressScrollWhenPopupsAreOpen")) {
      [this.destroyMouseWheelFunc] = this.addManagedEventListeners({
        bodyScroll: () => {
          this.hidePicker();
        }
      });
    }
    const translate = this.localeService.getLocaleTextFunc();
    const { pickerAriaLabelKey, pickerAriaLabelValue, modalPicker = true } = this.config;
    const popupParams = {
      modal: modalPicker,
      eChild: ePicker,
      closeOnEsc: true,
      closedCallback: () => {
        const activeEl = this.gos.getActiveDomElement();
        const shouldRestoreFocus = !activeEl || activeEl === eDocument.body;
        this.beforeHidePicker();
        if (shouldRestoreFocus && this.isAlive()) {
          this.getFocusableElement().focus();
        }
      },
      ariaLabel: translate(pickerAriaLabelKey, pickerAriaLabelValue)
    };
    const addPopupRes = this.popupService.addPopup(popupParams);
    const { maxPickerHeight, minPickerWidth, maxPickerWidth, variableWidth } = this;
    if (variableWidth) {
      if (minPickerWidth) {
        ePicker.style.minWidth = minPickerWidth;
      }
      ePicker.style.width = _formatSize(_getAbsoluteWidth(this.eWrapper));
      if (maxPickerWidth) {
        ePicker.style.maxWidth = maxPickerWidth;
      }
    } else {
      _setElementWidth(ePicker, maxPickerWidth ?? _getAbsoluteWidth(this.eWrapper));
    }
    const maxHeight = maxPickerHeight ?? `${_getInnerHeight(this.popupService.getPopupParent())}px`;
    ePicker.style.setProperty("max-height", maxHeight);
    ePicker.style.position = "absolute";
    this.alignPickerToComponent();
    return addPopupRes.hideFunc;
  }
  alignPickerToComponent() {
    if (!this.pickerComponent) {
      return;
    }
    const { pickerType } = this.config;
    const { pickerGap } = this;
    const alignSide = this.gos.get("enableRtl") ? "right" : "left";
    this.popupService.positionPopupByComponent({
      type: pickerType,
      eventSource: this.eWrapper,
      ePopup: this.pickerComponent.getGui(),
      position: "under",
      alignSide,
      keepWithinBounds: true,
      nudgeY: pickerGap
    });
  }
  beforeHidePicker() {
    if (this.destroyMouseWheelFunc) {
      this.destroyMouseWheelFunc();
      this.destroyMouseWheelFunc = void 0;
    }
    this.toggleExpandedStyles(false);
    const pickerGui = this.pickerComponent.getGui();
    pickerGui.removeEventListener("focusin", this.onPickerFocusIn);
    pickerGui.removeEventListener("focusout", this.onPickerFocusOut);
    this.isPickerDisplayed = false;
    this.pickerComponent = void 0;
    this.hideCurrentPicker = null;
  }
  toggleExpandedStyles(expanded) {
    if (!this.isAlive()) {
      return;
    }
    const ariaEl = this.getAriaElement();
    _setAriaExpanded(ariaEl, expanded);
    this.eWrapper.classList.toggle("ag-picker-expanded", expanded);
    this.eWrapper.classList.toggle("ag-picker-collapsed", !expanded);
  }
  onPickerFocusIn() {
    this.togglePickerHasFocus(true);
  }
  onPickerFocusOut(e) {
    if (!this.pickerComponent?.getGui().contains(e.relatedTarget)) {
      this.togglePickerHasFocus(false);
    }
  }
  togglePickerHasFocus(focused) {
    if (!this.pickerComponent) {
      return;
    }
    this.eWrapper.classList.toggle("ag-picker-has-focus", focused);
  }
  hidePicker() {
    if (this.hideCurrentPicker) {
      this.hideCurrentPicker();
    }
  }
  setInputWidth(width) {
    _setElementWidth(this.eWrapper, width);
    return this;
  }
  getFocusableElement() {
    return this.eWrapper;
  }
  setPickerGap(gap) {
    this.pickerGap = gap;
    return this;
  }
  setPickerMinWidth(width) {
    if (typeof width === "number") {
      width = `${width}px`;
    }
    this.minPickerWidth = width == null ? void 0 : width;
    return this;
  }
  setPickerMaxWidth(width) {
    if (typeof width === "number") {
      width = `${width}px`;
    }
    this.maxPickerWidth = width == null ? void 0 : width;
    return this;
  }
  setPickerMaxHeight(height) {
    if (typeof height === "number") {
      height = `${height}px`;
    }
    this.maxPickerHeight = height == null ? void 0 : height;
    return this;
  }
  destroy() {
    this.hidePicker();
    super.destroy();
  }
};

// community-modules/core/src/widgets/agSelect.ts
var AgSelect = class extends AgPickerField {
  constructor(config) {
    super({
      pickerAriaLabelKey: "ariaLabelSelectField",
      pickerAriaLabelValue: "Select Field",
      pickerType: "ag-list",
      className: "ag-select",
      pickerIcon: "smallDown",
      ariaRole: "combobox",
      ...config
    });
  }
  postConstruct() {
    super.postConstruct();
    this.createListComponent();
    this.eWrapper.tabIndex = this.gos.get("tabIndex");
    const { options, value, placeholder } = this.config;
    if (options != null) {
      this.addOptions(options);
    }
    if (value != null) {
      this.setValue(value, true);
    }
    if (placeholder && value == null) {
      this.eDisplayField.textContent = placeholder;
    }
    this.addManagedElementListeners(this.eWrapper, { focusout: this.onWrapperFocusOut.bind(this) });
  }
  onWrapperFocusOut(e) {
    if (!this.eWrapper.contains(e.relatedTarget)) {
      this.hidePicker();
    }
  }
  createListComponent() {
    this.listComponent = this.createBean(new AgList("select", true));
    this.listComponent.setParentComponent(this);
    const eListAriaEl = this.listComponent.getAriaElement();
    const listId = `ag-select-list-${this.listComponent.getCompId()}`;
    eListAriaEl.setAttribute("id", listId);
    _setAriaControls(this.getAriaElement(), eListAriaEl);
    this.listComponent.addManagedListeners(this.listComponent, {
      selectedItem: () => {
        this.hidePicker();
        this.dispatchLocalEvent({ type: "selectedItem" });
      }
    });
    this.listComponent.addManagedListeners(this.listComponent, {
      fieldValueChanged: () => {
        if (!this.listComponent) {
          return;
        }
        this.setValue(this.listComponent.getValue(), false, true);
        this.hidePicker();
      }
    });
  }
  createPickerComponent() {
    return this.listComponent;
  }
  onKeyDown(e) {
    const { key } = e;
    if (key === KeyCode.TAB) {
      this.hidePicker();
    }
    switch (key) {
      case KeyCode.ENTER:
      case KeyCode.UP:
      case KeyCode.DOWN:
      case KeyCode.PAGE_UP:
      case KeyCode.PAGE_DOWN:
      case KeyCode.PAGE_HOME:
      case KeyCode.PAGE_END:
        e.preventDefault();
        if (this.isPickerDisplayed) {
          this.listComponent?.handleKeyDown(e);
        } else {
          super.onKeyDown(e);
        }
        break;
      case KeyCode.ESCAPE:
        super.onKeyDown(e);
        break;
    }
  }
  showPicker() {
    if (!this.listComponent) {
      return;
    }
    super.showPicker();
    this.listComponent.refreshHighlighted();
  }
  addOptions(options) {
    options.forEach((option) => this.addOption(option));
    return this;
  }
  addOption(option) {
    this.listComponent.addOption(option);
    return this;
  }
  clearOptions() {
    this.listComponent?.clearOptions();
    return this;
  }
  setValue(value, silent, fromPicker) {
    if (this.value === value || !this.listComponent) {
      return this;
    }
    if (!fromPicker) {
      this.listComponent.setValue(value, true);
    }
    const newValue = this.listComponent.getValue();
    if (newValue === this.getValue()) {
      return this;
    }
    let displayValue = this.listComponent.getDisplayValue();
    if (displayValue == null && this.config.placeholder) {
      displayValue = this.config.placeholder;
    }
    this.eDisplayField.textContent = displayValue;
    this.setTooltip({
      newTooltipText: displayValue ?? null,
      shouldDisplayTooltip: () => this.eDisplayField.scrollWidth > this.eDisplayField.clientWidth
    });
    return super.setValue(value, silent);
  }
  destroy() {
    if (this.listComponent) {
      this.listComponent = this.destroyBean(this.listComponent);
    }
    super.destroy();
  }
};
var AgSelectSelector = {
  selector: "AG-SELECT",
  component: AgSelect
};

// community-modules/core/src/filter/provided/optionsFactory.ts
var OptionsFactory = class {
  constructor() {
    this.customFilterOptions = {};
  }
  init(params, defaultOptions) {
    this.filterOptions = params.filterOptions || defaultOptions;
    this.mapCustomOptions();
    this.selectDefaultItem(params);
  }
  getFilterOptions() {
    return this.filterOptions;
  }
  mapCustomOptions() {
    if (!this.filterOptions) {
      return;
    }
    this.filterOptions.forEach((filterOption) => {
      if (typeof filterOption === "string") {
        return;
      }
      const requiredProperties = [["displayKey"], ["displayName"], ["predicate", "test"]];
      const propertyCheck = (keys) => {
        if (!keys.some((key) => filterOption[key] != null)) {
          _warnOnce(`ignoring FilterOptionDef as it doesn't contain one of '${keys}'`);
          return false;
        }
        return true;
      };
      if (!requiredProperties.every(propertyCheck)) {
        this.filterOptions = this.filterOptions.filter((v) => v === filterOption) || [];
        return;
      }
      this.customFilterOptions[filterOption.displayKey] = filterOption;
    });
  }
  selectDefaultItem(params) {
    if (params.defaultOption) {
      this.defaultOption = params.defaultOption;
    } else if (this.filterOptions.length >= 1) {
      const firstFilterOption = this.filterOptions[0];
      if (typeof firstFilterOption === "string") {
        this.defaultOption = firstFilterOption;
      } else if (firstFilterOption.displayKey) {
        this.defaultOption = firstFilterOption.displayKey;
      } else {
        _warnOnce(`invalid FilterOptionDef supplied as it doesn't contain a 'displayKey'`);
      }
    } else {
      _warnOnce("no filter options for filter");
    }
  }
  getDefaultOption() {
    return this.defaultOption;
  }
  getCustomOption(name) {
    return this.customFilterOptions[name];
  }
};

// community-modules/core/src/filter/provided/simpleFilter.ts
var SimpleFilter = class extends ProvidedFilter {
  constructor() {
    super(...arguments);
    this.eTypes = [];
    this.eJoinOperatorPanels = [];
    this.eJoinOperatorsAnd = [];
    this.eJoinOperatorsOr = [];
    this.eConditionBodies = [];
    this.listener = () => this.onUiChanged();
    this.lastUiCompletePosition = null;
    this.joinOperatorId = 0;
  }
  getNumberOfInputs(type) {
    const customOpts = this.optionsFactory.getCustomOption(type);
    if (customOpts) {
      const { numberOfInputs } = customOpts;
      return numberOfInputs != null ? numberOfInputs : 1;
    }
    const zeroInputTypes = ["empty", "notBlank", "blank"];
    if (type && zeroInputTypes.indexOf(type) >= 0) {
      return 0;
    } else if (type === "inRange") {
      return 2;
    }
    return 1;
  }
  // floating filter calls this when user applies filter from floating filter
  onFloatingFilterChanged(type, value) {
    this.setTypeFromFloatingFilter(type);
    this.setValueFromFloatingFilter(value);
    this.onUiChanged(true);
  }
  setTypeFromFloatingFilter(type) {
    this.eTypes.forEach((eType, position) => {
      if (position === 0) {
        eType.setValue(type, true);
      } else {
        eType.setValue(this.optionsFactory.getDefaultOption(), true);
      }
    });
  }
  getModelFromUi() {
    const conditions = this.getUiCompleteConditions();
    if (conditions.length === 0) {
      return null;
    }
    if (this.maxNumConditions > 1 && conditions.length > 1) {
      return {
        filterType: this.getFilterType(),
        operator: this.getJoinOperator(),
        conditions
      };
    }
    return conditions[0];
  }
  getConditionTypes() {
    return this.eTypes.map((eType) => eType.getValue());
  }
  getConditionType(position) {
    return this.eTypes[position].getValue();
  }
  getJoinOperator() {
    if (this.eJoinOperatorsOr.length === 0) {
      return this.defaultJoinOperator;
    }
    return this.eJoinOperatorsOr[0].getValue() === true ? "OR" : "AND";
  }
  areModelsEqual(a, b) {
    if (!a && !b) {
      return true;
    }
    if (!a && b || a && !b) {
      return false;
    }
    const aIsSimple = !a.operator;
    const bIsSimple = !b.operator;
    const oneSimpleOneCombined = !aIsSimple && bIsSimple || aIsSimple && !bIsSimple;
    if (oneSimpleOneCombined) {
      return false;
    }
    let res;
    if (aIsSimple) {
      const aSimple = a;
      const bSimple = b;
      res = this.areSimpleModelsEqual(aSimple, bSimple);
    } else {
      const aCombined = a;
      const bCombined = b;
      res = aCombined.operator === bCombined.operator && _areEqual(
        aCombined.conditions,
        bCombined.conditions,
        (aModel, bModel) => this.areSimpleModelsEqual(aModel, bModel)
      );
    }
    return res;
  }
  shouldRefresh(newParams) {
    const model = this.getModel();
    const conditions = model ? model.conditions ?? [model] : null;
    const newOptionsList = newParams.filterOptions?.map((option) => typeof option === "string" ? option : option.displayKey) ?? this.getDefaultFilterOptions();
    const allConditionsExistInNewOptionsList = !conditions || conditions.every((condition) => newOptionsList.find((option) => option === condition.type) !== void 0);
    if (!allConditionsExistInNewOptionsList) {
      return false;
    }
    if (typeof newParams.maxNumConditions === "number" && conditions && conditions.length > newParams.maxNumConditions) {
      return false;
    }
    return true;
  }
  refresh(newParams) {
    if (!this.shouldRefresh(newParams)) {
      return false;
    }
    const parentRefreshed = super.refresh(newParams);
    if (!parentRefreshed) {
      return false;
    }
    this.setParams(newParams);
    this.removeConditionsAndOperators(0);
    this.createOption();
    this.setModel(this.getModel());
    return true;
  }
  setModelIntoUi(model) {
    const isCombined = model.operator;
    if (isCombined) {
      const combinedModel = model;
      let conditions = combinedModel.conditions;
      if (conditions == null) {
        conditions = [];
        _warnOnce(`Filter model is missing 'conditions'`);
      }
      const numConditions = this.validateAndUpdateConditions(conditions);
      const numPrevConditions = this.getNumConditions();
      if (numConditions < numPrevConditions) {
        this.removeConditionsAndOperators(numConditions);
      } else if (numConditions > numPrevConditions) {
        for (let i = numPrevConditions; i < numConditions; i++) {
          this.createJoinOperatorPanel();
          this.createOption();
        }
      }
      const orChecked = combinedModel.operator === "OR";
      this.eJoinOperatorsAnd.forEach((eJoinOperatorAnd) => eJoinOperatorAnd.setValue(!orChecked, true));
      this.eJoinOperatorsOr.forEach((eJoinOperatorOr) => eJoinOperatorOr.setValue(orChecked, true));
      conditions.forEach((condition, position) => {
        this.eTypes[position].setValue(condition.type, true);
        this.setConditionIntoUi(condition, position);
      });
    } else {
      const simpleModel = model;
      if (this.getNumConditions() > 1) {
        this.removeConditionsAndOperators(1);
      }
      this.eTypes[0].setValue(simpleModel.type, true);
      this.setConditionIntoUi(simpleModel, 0);
    }
    this.lastUiCompletePosition = this.getNumConditions() - 1;
    this.createMissingConditionsAndOperators();
    this.onUiChanged();
    return AgPromise.resolve();
  }
  validateAndUpdateConditions(conditions) {
    let numConditions = conditions.length;
    if (numConditions > this.maxNumConditions) {
      conditions.splice(this.maxNumConditions);
      _warnOnce(
        'Filter Model contains more conditions than "filterParams.maxNumConditions". Additional conditions have been ignored.'
      );
      numConditions = this.maxNumConditions;
    }
    return numConditions;
  }
  doesFilterPass(params) {
    const model = this.getModel();
    if (model == null) {
      return true;
    }
    const { operator } = model;
    const models = [];
    if (operator) {
      const combinedModel = model;
      models.push(...combinedModel.conditions ?? []);
    } else {
      models.push(model);
    }
    const combineFunction = operator && operator === "OR" ? "some" : "every";
    return models[combineFunction]((m) => this.individualConditionPasses(params, m));
  }
  setParams(params) {
    super.setParams(params);
    this.setNumConditions(params);
    this.defaultJoinOperator = this.getDefaultJoinOperator(params.defaultJoinOperator);
    this.filterPlaceholder = params.filterPlaceholder;
    this.optionsFactory = new OptionsFactory();
    this.optionsFactory.init(params, this.getDefaultFilterOptions());
    this.createFilterListOptions();
    this.createOption();
    this.createMissingConditionsAndOperators();
    if (this.isReadOnly()) {
      this.eFilterBody.setAttribute("tabindex", "-1");
    }
  }
  setNumConditions(params) {
    this.maxNumConditions = params.maxNumConditions ?? 2;
    if (this.maxNumConditions < 1) {
      _warnOnce('"filterParams.maxNumConditions" must be greater than or equal to zero.');
      this.maxNumConditions = 1;
    }
    this.numAlwaysVisibleConditions = params.numAlwaysVisibleConditions ?? 1;
    if (this.numAlwaysVisibleConditions < 1) {
      _warnOnce('"filterParams.numAlwaysVisibleConditions" must be greater than or equal to zero.');
      this.numAlwaysVisibleConditions = 1;
    }
    if (this.numAlwaysVisibleConditions > this.maxNumConditions) {
      _warnOnce(
        '"filterParams.numAlwaysVisibleConditions" cannot be greater than "filterParams.maxNumConditions".'
      );
      this.numAlwaysVisibleConditions = this.maxNumConditions;
    }
  }
  createOption() {
    const eType = this.createManagedBean(new AgSelect());
    this.eTypes.push(eType);
    eType.addCssClass("ag-filter-select");
    this.eFilterBody.appendChild(eType.getGui());
    const eConditionBody = this.createValueElement();
    this.eConditionBodies.push(eConditionBody);
    this.eFilterBody.appendChild(eConditionBody);
    this.putOptionsIntoDropdown(eType);
    this.resetType(eType);
    const position = this.getNumConditions() - 1;
    this.forEachPositionInput(position, (element) => this.resetInput(element));
    this.addChangedListeners(eType, position);
  }
  createJoinOperatorPanel() {
    const eJoinOperatorPanel = document.createElement("div");
    this.eJoinOperatorPanels.push(eJoinOperatorPanel);
    eJoinOperatorPanel.classList.add("ag-filter-condition");
    const eJoinOperatorAnd = this.createJoinOperator(this.eJoinOperatorsAnd, eJoinOperatorPanel, "and");
    const eJoinOperatorOr = this.createJoinOperator(this.eJoinOperatorsOr, eJoinOperatorPanel, "or");
    this.eFilterBody.appendChild(eJoinOperatorPanel);
    const index = this.eJoinOperatorPanels.length - 1;
    const uniqueGroupId = this.joinOperatorId++;
    this.resetJoinOperatorAnd(eJoinOperatorAnd, index, uniqueGroupId);
    this.resetJoinOperatorOr(eJoinOperatorOr, index, uniqueGroupId);
    if (!this.isReadOnly()) {
      eJoinOperatorAnd.onValueChange(this.listener);
      eJoinOperatorOr.onValueChange(this.listener);
    }
  }
  createJoinOperator(eJoinOperators, eJoinOperatorPanel, andOr) {
    const eJoinOperator = this.createManagedBean(new AgRadioButton());
    eJoinOperators.push(eJoinOperator);
    eJoinOperator.addCssClass("ag-filter-condition-operator");
    eJoinOperator.addCssClass(`ag-filter-condition-operator-${andOr}`);
    eJoinOperatorPanel.appendChild(eJoinOperator.getGui());
    return eJoinOperator;
  }
  getDefaultJoinOperator(defaultJoinOperator) {
    return defaultJoinOperator === "AND" || defaultJoinOperator === "OR" ? defaultJoinOperator : "AND";
  }
  createFilterListOptions() {
    const filterOptions = this.optionsFactory.getFilterOptions();
    this.filterListOptions = filterOptions.map(
      (option) => typeof option === "string" ? this.createBoilerplateListOption(option) : this.createCustomListOption(option)
    );
  }
  putOptionsIntoDropdown(eType) {
    this.filterListOptions.forEach((listOption) => {
      eType.addOption(listOption);
    });
    eType.setDisabled(this.filterListOptions.length <= 1);
  }
  createBoilerplateListOption(option) {
    return { value: option, text: this.translate(option) };
  }
  createCustomListOption(option) {
    const { displayKey } = option;
    const customOption = this.optionsFactory.getCustomOption(option.displayKey);
    return {
      value: displayKey,
      text: customOption ? this.localeService.getLocaleTextFunc()(customOption.displayKey, customOption.displayName) : this.translate(displayKey)
    };
  }
  createBodyTemplate() {
    return "";
  }
  getAgComponents() {
    return [];
  }
  getCssIdentifier() {
    return "simple-filter";
  }
  updateUiVisibility() {
    const joinOperator = this.getJoinOperator();
    this.updateNumConditions();
    this.updateConditionStatusesAndValues(this.lastUiCompletePosition, joinOperator);
  }
  updateNumConditions() {
    let lastUiCompletePosition = -1;
    let areAllConditionsUiComplete = true;
    for (let position = 0; position < this.getNumConditions(); position++) {
      if (this.isConditionUiComplete(position)) {
        lastUiCompletePosition = position;
      } else {
        areAllConditionsUiComplete = false;
      }
    }
    if (this.shouldAddNewConditionAtEnd(areAllConditionsUiComplete)) {
      this.createJoinOperatorPanel();
      this.createOption();
    } else {
      const activePosition = this.lastUiCompletePosition ?? this.getNumConditions() - 2;
      if (lastUiCompletePosition < activePosition) {
        this.removeConditionsAndOperators(activePosition + 1);
        const removeStartPosition = lastUiCompletePosition + 1;
        const numConditionsToRemove = activePosition - removeStartPosition;
        if (numConditionsToRemove > 0) {
          this.removeConditionsAndOperators(removeStartPosition, numConditionsToRemove);
        }
        this.createMissingConditionsAndOperators();
      }
    }
    this.lastUiCompletePosition = lastUiCompletePosition;
  }
  updateConditionStatusesAndValues(lastUiCompletePosition, joinOperator) {
    this.eTypes.forEach((eType, position) => {
      const disabled = this.isConditionDisabled(position, lastUiCompletePosition);
      eType.setDisabled(disabled || this.filterListOptions.length <= 1);
      if (position === 1) {
        _setDisabled(this.eJoinOperatorPanels[0], disabled);
        this.eJoinOperatorsAnd[0].setDisabled(disabled);
        this.eJoinOperatorsOr[0].setDisabled(disabled);
      }
    });
    this.eConditionBodies.forEach((element, index) => {
      _setDisplayed(element, this.isConditionBodyVisible(index));
    });
    const orChecked = (joinOperator ?? this.getJoinOperator()) === "OR";
    this.eJoinOperatorsAnd.forEach((eJoinOperatorAnd) => {
      eJoinOperatorAnd.setValue(!orChecked, true);
    });
    this.eJoinOperatorsOr.forEach((eJoinOperatorOr) => {
      eJoinOperatorOr.setValue(orChecked, true);
    });
    this.forEachInput((element, index, position, numberOfInputs) => {
      this.setElementDisplayed(element, index < numberOfInputs);
      this.setElementDisabled(element, this.isConditionDisabled(position, lastUiCompletePosition));
    });
    this.resetPlaceholder();
  }
  shouldAddNewConditionAtEnd(areAllConditionsUiComplete) {
    return areAllConditionsUiComplete && this.getNumConditions() < this.maxNumConditions && !this.isReadOnly();
  }
  removeConditionsAndOperators(startPosition, deleteCount) {
    if (startPosition >= this.getNumConditions()) {
      return;
    }
    this.removeComponents(this.eTypes, startPosition, deleteCount);
    this.removeElements(this.eConditionBodies, startPosition, deleteCount);
    this.removeValueElements(startPosition, deleteCount);
    const joinOperatorIndex = Math.max(startPosition - 1, 0);
    this.removeElements(this.eJoinOperatorPanels, joinOperatorIndex, deleteCount);
    this.removeComponents(this.eJoinOperatorsAnd, joinOperatorIndex, deleteCount);
    this.removeComponents(this.eJoinOperatorsOr, joinOperatorIndex, deleteCount);
  }
  removeElements(elements, startPosition, deleteCount) {
    const removedElements = this.removeItems(elements, startPosition, deleteCount);
    removedElements.forEach((element) => _removeFromParent(element));
  }
  removeComponents(components, startPosition, deleteCount) {
    const removedComponents = this.removeItems(components, startPosition, deleteCount);
    removedComponents.forEach((comp) => {
      _removeFromParent(comp.getGui());
      this.destroyBean(comp);
    });
  }
  removeItems(items, startPosition, deleteCount) {
    return deleteCount == null ? items.splice(startPosition) : items.splice(startPosition, deleteCount);
  }
  afterGuiAttached(params) {
    super.afterGuiAttached(params);
    this.resetPlaceholder();
    if (!params?.suppressFocus) {
      if (this.isReadOnly()) {
        this.eFilterBody.focus();
      } else {
        const firstInput = this.getInputs(0)[0];
        if (!firstInput) {
          return;
        }
        if (firstInput instanceof AgAbstractInputField) {
          firstInput.getInputElement().focus();
        }
      }
    }
  }
  afterGuiDetached() {
    super.afterGuiDetached();
    const appliedModel = this.getModel();
    this.resetUiToActiveModel(appliedModel);
    let lastUiCompletePosition = -1;
    let updatedLastUiCompletePosition = -1;
    let conditionsRemoved = false;
    const joinOperator = this.getJoinOperator();
    for (let position = this.getNumConditions() - 1; position >= 0; position--) {
      if (this.isConditionUiComplete(position)) {
        if (lastUiCompletePosition === -1) {
          lastUiCompletePosition = position;
          updatedLastUiCompletePosition = position;
        }
      } else {
        const shouldRemovePositionAtEnd = position >= this.numAlwaysVisibleConditions && !this.isConditionUiComplete(position - 1);
        const positionBeforeLastUiCompletePosition = position < lastUiCompletePosition;
        if (shouldRemovePositionAtEnd || positionBeforeLastUiCompletePosition) {
          this.removeConditionsAndOperators(position, 1);
          conditionsRemoved = true;
          if (positionBeforeLastUiCompletePosition) {
            updatedLastUiCompletePosition--;
          }
        }
      }
    }
    let shouldUpdateConditionStatusesAndValues = false;
    if (this.getNumConditions() < this.numAlwaysVisibleConditions) {
      this.createMissingConditionsAndOperators();
      shouldUpdateConditionStatusesAndValues = true;
    }
    if (this.shouldAddNewConditionAtEnd(updatedLastUiCompletePosition === this.getNumConditions() - 1)) {
      this.createJoinOperatorPanel();
      this.createOption();
      shouldUpdateConditionStatusesAndValues = true;
    }
    if (shouldUpdateConditionStatusesAndValues) {
      this.updateConditionStatusesAndValues(updatedLastUiCompletePosition, joinOperator);
    }
    if (conditionsRemoved) {
      this.updateJoinOperatorsDisabled();
    }
    this.lastUiCompletePosition = updatedLastUiCompletePosition;
  }
  getPlaceholderText(defaultPlaceholder, position) {
    let placeholder = this.translate(defaultPlaceholder);
    if (_isFunction(this.filterPlaceholder)) {
      const filterPlaceholderFn = this.filterPlaceholder;
      const filterOptionKey = this.eTypes[position].getValue();
      const filterOption = this.translate(filterOptionKey);
      placeholder = filterPlaceholderFn({
        filterOptionKey,
        filterOption,
        placeholder
      });
    } else if (typeof this.filterPlaceholder === "string") {
      placeholder = this.filterPlaceholder;
    }
    return placeholder;
  }
  // allow sub-classes to reset HTML placeholders after UI update.
  resetPlaceholder() {
    const globalTranslate = this.localeService.getLocaleTextFunc();
    this.forEachInput((element, index, position, numberOfInputs) => {
      if (!(element instanceof AgAbstractInputField)) {
        return;
      }
      const placeholder = index === 0 && numberOfInputs > 1 ? "inRangeStart" : index === 0 ? "filterOoo" : "inRangeEnd";
      const ariaLabel = index === 0 && numberOfInputs > 1 ? globalTranslate("ariaFilterFromValue", "Filter from value") : index === 0 ? globalTranslate("ariaFilterValue", "Filter Value") : globalTranslate("ariaFilterToValue", "Filter to Value");
      element.setInputPlaceholder(this.getPlaceholderText(placeholder, position));
      element.setInputAriaLabel(ariaLabel);
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setElementValue(element, value, fromFloatingFilter) {
    if (element instanceof AgAbstractInputField) {
      element.setValue(value != null ? String(value) : null, true);
    }
  }
  setElementDisplayed(element, displayed) {
    if (element instanceof Component) {
      _setDisplayed(element.getGui(), displayed);
    }
  }
  setElementDisabled(element, disabled) {
    if (element instanceof Component) {
      _setDisabled(element.getGui(), disabled);
    }
  }
  attachElementOnChange(element, listener) {
    if (element instanceof AgAbstractInputField) {
      element.onValueChange(listener);
    }
  }
  forEachInput(cb) {
    this.getConditionTypes().forEach((type, position) => {
      this.forEachPositionTypeInput(position, type, cb);
    });
  }
  forEachPositionInput(position, cb) {
    const type = this.getConditionType(position);
    this.forEachPositionTypeInput(position, type, cb);
  }
  forEachPositionTypeInput(position, type, cb) {
    const numberOfInputs = this.getNumberOfInputs(type);
    const inputs = this.getInputs(position);
    for (let index = 0; index < inputs.length; index++) {
      const input = inputs[index];
      if (input != null) {
        cb(input, index, position, numberOfInputs);
      }
    }
  }
  isConditionDisabled(position, lastUiCompletePosition) {
    if (this.isReadOnly()) {
      return true;
    }
    if (position === 0) {
      return false;
    }
    return position > lastUiCompletePosition + 1;
  }
  isConditionBodyVisible(position) {
    const type = this.getConditionType(position);
    const numberOfInputs = this.getNumberOfInputs(type);
    return numberOfInputs > 0;
  }
  // returns true if the UI represents a working filter, eg all parts are filled out.
  // eg if text filter and textfield blank then returns false.
  isConditionUiComplete(position) {
    if (position >= this.getNumConditions()) {
      return false;
    }
    const type = this.getConditionType(position);
    if (type === "empty") {
      return false;
    }
    if (this.getValues(position).some((v) => v == null)) {
      return false;
    }
    return true;
  }
  getNumConditions() {
    return this.eTypes.length;
  }
  getUiCompleteConditions() {
    const conditions = [];
    for (let position = 0; position < this.getNumConditions(); position++) {
      if (this.isConditionUiComplete(position)) {
        conditions.push(this.createCondition(position));
      }
    }
    return conditions;
  }
  createMissingConditionsAndOperators() {
    if (this.isReadOnly()) {
      return;
    }
    for (let i = this.getNumConditions(); i < this.numAlwaysVisibleConditions; i++) {
      this.createJoinOperatorPanel();
      this.createOption();
    }
  }
  resetUiToDefaults(silent) {
    this.removeConditionsAndOperators(this.isReadOnly() ? 1 : this.numAlwaysVisibleConditions);
    this.eTypes.forEach((eType) => this.resetType(eType));
    this.eJoinOperatorsAnd.forEach(
      (eJoinOperatorAnd, index) => this.resetJoinOperatorAnd(eJoinOperatorAnd, index, this.joinOperatorId + index)
    );
    this.eJoinOperatorsOr.forEach(
      (eJoinOperatorOr, index) => this.resetJoinOperatorOr(eJoinOperatorOr, index, this.joinOperatorId + index)
    );
    this.joinOperatorId++;
    this.forEachInput((element) => this.resetInput(element));
    this.resetPlaceholder();
    this.createMissingConditionsAndOperators();
    this.lastUiCompletePosition = null;
    if (!silent) {
      this.onUiChanged();
    }
    return AgPromise.resolve();
  }
  resetType(eType) {
    const translate = this.localeService.getLocaleTextFunc();
    const filteringLabel = translate("ariaFilteringOperator", "Filtering operator");
    eType.setValue(this.optionsFactory.getDefaultOption(), true).setAriaLabel(filteringLabel).setDisabled(this.isReadOnly() || this.filterListOptions.length <= 1);
  }
  resetJoinOperatorAnd(eJoinOperatorAnd, index, uniqueGroupId) {
    this.resetJoinOperator(
      eJoinOperatorAnd,
      index,
      this.isDefaultOperator("AND"),
      this.translate("andCondition"),
      uniqueGroupId
    );
  }
  resetJoinOperatorOr(eJoinOperatorOr, index, uniqueGroupId) {
    this.resetJoinOperator(
      eJoinOperatorOr,
      index,
      this.isDefaultOperator("OR"),
      this.translate("orCondition"),
      uniqueGroupId
    );
  }
  resetJoinOperator(eJoinOperator, index, value, label, uniqueGroupId) {
    this.updateJoinOperatorDisabled(
      eJoinOperator.setValue(value, true).setName(`ag-simple-filter-and-or-${this.getCompId()}-${uniqueGroupId}`).setLabel(label),
      index
    );
  }
  updateJoinOperatorsDisabled() {
    this.eJoinOperatorsAnd.forEach((eJoinOperator, index) => this.updateJoinOperatorDisabled(eJoinOperator, index));
    this.eJoinOperatorsOr.forEach((eJoinOperator, index) => this.updateJoinOperatorDisabled(eJoinOperator, index));
  }
  updateJoinOperatorDisabled(eJoinOperator, index) {
    eJoinOperator.setDisabled(this.isReadOnly() || index > 0);
  }
  resetInput(element) {
    this.setElementValue(element, null);
    this.setElementDisabled(element, this.isReadOnly());
  }
  // puts model values into the UI
  setConditionIntoUi(model, position) {
    const values = this.mapValuesFromModel(model);
    this.forEachInput((element, index, elPosition) => {
      if (elPosition !== position) {
        return;
      }
      this.setElementValue(element, values[index] != null ? values[index] : null);
    });
  }
  // after floating filter changes, this sets the 'value' section. this is implemented by the base class
  // (as that's where value is controlled), the 'type' part from the floating filter is dealt with in this class.
  setValueFromFloatingFilter(value) {
    this.forEachInput((element, index, position) => {
      this.setElementValue(element, index === 0 && position === 0 ? value : null, true);
    });
  }
  isDefaultOperator(operator) {
    return operator === this.defaultJoinOperator;
  }
  addChangedListeners(eType, position) {
    if (this.isReadOnly()) {
      return;
    }
    eType.onValueChange(this.listener);
    this.forEachPositionInput(position, (element) => {
      this.attachElementOnChange(element, this.listener);
    });
  }
  /** returns true if the row passes the said condition */
  individualConditionPasses(params, filterModel) {
    const cellValue = this.getCellValue(params.node);
    const values = this.mapValuesFromModel(filterModel);
    const customFilterOption = this.optionsFactory.getCustomOption(filterModel.type);
    const customFilterResult = this.evaluateCustomFilter(customFilterOption, values, cellValue);
    if (customFilterResult != null) {
      return customFilterResult;
    }
    if (cellValue == null) {
      return this.evaluateNullValue(filterModel.type);
    }
    return this.evaluateNonNullValue(values, cellValue, filterModel, params);
  }
  evaluateCustomFilter(customFilterOption, values, cellValue) {
    if (customFilterOption == null) {
      return;
    }
    const { predicate } = customFilterOption;
    if (predicate != null && !values.some((v) => v == null)) {
      return predicate(values, cellValue);
    }
    return;
  }
  isBlank(cellValue) {
    return cellValue == null || typeof cellValue === "string" && cellValue.trim().length === 0;
  }
  hasInvalidInputs() {
    return false;
  }
};

// community-modules/core/src/filter/provided/scalarFilter.ts
var ScalarFilter = class extends SimpleFilter {
  setParams(params) {
    super.setParams(params);
    this.scalarFilterParams = params;
  }
  evaluateNullValue(filterType) {
    switch (filterType) {
      case "equals":
      case "notEqual":
        if (this.scalarFilterParams.includeBlanksInEquals) {
          return true;
        }
        break;
      case "greaterThan":
      case "greaterThanOrEqual":
        if (this.scalarFilterParams.includeBlanksInGreaterThan) {
          return true;
        }
        break;
      case "lessThan":
      case "lessThanOrEqual":
        if (this.scalarFilterParams.includeBlanksInLessThan) {
          return true;
        }
        break;
      case "inRange":
        if (this.scalarFilterParams.includeBlanksInRange) {
          return true;
        }
        break;
      case "blank":
        return true;
      case "notBlank":
        return false;
    }
    return false;
  }
  evaluateNonNullValue(values, cellValue, filterModel) {
    const comparator = this.comparator();
    const compareResult = values[0] != null ? comparator(values[0], cellValue) : 0;
    switch (filterModel.type) {
      case "equals":
        return compareResult === 0;
      case "notEqual":
        return compareResult !== 0;
      case "greaterThan":
        return compareResult > 0;
      case "greaterThanOrEqual":
        return compareResult >= 0;
      case "lessThan":
        return compareResult < 0;
      case "lessThanOrEqual":
        return compareResult <= 0;
      case "inRange": {
        const compareToResult = comparator(values[1], cellValue);
        return this.scalarFilterParams.inRangeInclusive ? compareResult >= 0 && compareToResult <= 0 : compareResult > 0 && compareToResult < 0;
      }
      case "blank":
        return this.isBlank(cellValue);
      case "notBlank":
        return !this.isBlank(cellValue);
      default:
        _warnOnce(
          'Unexpected type of filter "' + filterModel.type + '", it looks like the filter was configured with incorrect Filter Options'
        );
        return true;
    }
  }
};

// community-modules/core/src/utils/keyboard.ts
var A_KEYCODE = 65;
var C_KEYCODE = 67;
var V_KEYCODE = 86;
var D_KEYCODE = 68;
var Z_KEYCODE = 90;
var Y_KEYCODE = 89;
function _isEventFromPrintableCharacter(event) {
  if (event.altKey || event.ctrlKey || event.metaKey) {
    return false;
  }
  const printableCharacter = event.key.length === 1;
  return printableCharacter;
}
function _isUserSuppressingKeyboardEvent(gos, keyboardEvent, rowNode, column, editing) {
  const colDefFunc = column ? column.getColDef().suppressKeyboardEvent : void 0;
  if (!colDefFunc) {
    return false;
  }
  const params = gos.addGridCommonParams({
    event: keyboardEvent,
    editing,
    column,
    node: rowNode,
    data: rowNode.data,
    colDef: column.getColDef()
  });
  if (colDefFunc) {
    const colDefFuncResult = colDefFunc(params);
    if (colDefFuncResult) {
      return true;
    }
  }
  return false;
}
function _isUserSuppressingHeaderKeyboardEvent(gos, keyboardEvent, headerRowIndex, column) {
  const colDef = column.getDefinition();
  const colDefFunc = colDef && colDef.suppressHeaderKeyboardEvent;
  if (!_exists(colDefFunc)) {
    return false;
  }
  const params = gos.addGridCommonParams({
    colDef,
    column,
    headerRowIndex,
    event: keyboardEvent
  });
  return !!colDefFunc(params);
}
function _normaliseQwertyAzerty(keyboardEvent) {
  const { keyCode } = keyboardEvent;
  let code;
  switch (keyCode) {
    case A_KEYCODE:
      code = KeyCode.A;
      break;
    case C_KEYCODE:
      code = KeyCode.C;
      break;
    case V_KEYCODE:
      code = KeyCode.V;
      break;
    case D_KEYCODE:
      code = KeyCode.D;
      break;
    case Z_KEYCODE:
      code = KeyCode.Z;
      break;
    case Y_KEYCODE:
      code = KeyCode.Y;
      break;
    default:
      code = keyboardEvent.code;
  }
  return code;
}
function _isDeleteKey(key, alwaysReturnFalseOnBackspace = false) {
  if (key === KeyCode.DELETE) {
    return true;
  }
  if (!alwaysReturnFalseOnBackspace && key === KeyCode.BACKSPACE) {
    return _isMacOsUserAgent();
  }
  return false;
}

// community-modules/core/src/widgets/agInputTextField.ts
var AgInputTextField = class extends AgAbstractInputField {
  constructor(config, className = "ag-text-field", inputType = "text") {
    super(config, className, inputType);
  }
  postConstruct() {
    super.postConstruct();
    if (this.config.allowedCharPattern) {
      this.preventDisallowedCharacters();
    }
  }
  setValue(value, silent) {
    if (this.eInput.value !== value) {
      this.eInput.value = _exists(value) ? value : "";
    }
    return super.setValue(value, silent);
  }
  /** Used to set an initial value into the input without necessarily setting `this.value` or triggering events (e.g. to set an invalid value) */
  setStartValue(value) {
    this.setValue(value, true);
  }
  preventDisallowedCharacters() {
    const pattern = new RegExp(`[${this.config.allowedCharPattern}]`);
    const preventCharacters = (event) => {
      if (!_isEventFromPrintableCharacter(event)) {
        return;
      }
      if (event.key && !pattern.test(event.key)) {
        event.preventDefault();
      }
    };
    this.addManagedListeners(this.eInput, {
      keydown: preventCharacters,
      paste: (e) => {
        const text = e.clipboardData?.getData("text");
        if (text && text.split("").some((c) => !pattern.test(c))) {
          e.preventDefault();
        }
      }
    });
  }
};
var AgInputTextFieldSelector = {
  selector: "AG-INPUT-TEXT-FIELD",
  component: AgInputTextField
};

// community-modules/core/src/widgets/agInputNumberField.ts
var AgInputNumberField = class extends AgInputTextField {
  constructor(config) {
    super(config, "ag-number-field", "number");
  }
  postConstruct() {
    super.postConstruct();
    this.addManagedListeners(this.eInput, {
      blur: () => {
        const floatedValue = parseFloat(this.eInput.value);
        const value = isNaN(floatedValue) ? "" : this.normalizeValue(floatedValue.toString());
        if (this.value !== value) {
          this.setValue(value);
        }
      },
      wheel: this.onWheel.bind(this)
    });
    this.eInput.step = "any";
    const { precision, min, max, step } = this.config;
    if (typeof precision === "number")
      this.setPrecision(precision);
    if (typeof min === "number")
      this.setMin(min);
    if (typeof max === "number")
      this.setMax(max);
    if (typeof step === "number")
      this.setStep(step);
  }
  onWheel(e) {
    if (this.gos.getActiveDomElement() === this.eInput) {
      e.preventDefault();
    }
  }
  normalizeValue(value) {
    if (value === "") {
      return "";
    }
    if (this.precision != null) {
      value = this.adjustPrecision(value);
    }
    const val = parseFloat(value);
    if (this.min != null && val < this.min) {
      value = this.min.toString();
    } else if (this.max != null && val > this.max) {
      value = this.max.toString();
    }
    return value;
  }
  adjustPrecision(value, isScientificNotation) {
    if (this.precision == null) {
      return value;
    }
    if (isScientificNotation) {
      const floatString = parseFloat(value).toFixed(this.precision);
      return parseFloat(floatString).toString();
    }
    const parts = String(value).split(".");
    if (parts.length > 1) {
      if (parts[1].length <= this.precision) {
        return value;
      } else if (this.precision > 0) {
        return `${parts[0]}.${parts[1].slice(0, this.precision)}`;
      }
    }
    return parts[0];
  }
  setMin(min) {
    if (this.min === min) {
      return this;
    }
    this.min = min;
    _addOrRemoveAttribute(this.eInput, "min", min);
    return this;
  }
  setMax(max) {
    if (this.max === max) {
      return this;
    }
    this.max = max;
    _addOrRemoveAttribute(this.eInput, "max", max);
    return this;
  }
  setPrecision(precision) {
    this.precision = precision;
    return this;
  }
  setStep(step) {
    if (this.step === step) {
      return this;
    }
    this.step = step;
    _addOrRemoveAttribute(this.eInput, "step", step);
    return this;
  }
  setValue(value, silent) {
    return this.setValueOrInputValue(
      (v) => super.setValue(v, silent),
      () => this,
      value
    );
  }
  setStartValue(value) {
    return this.setValueOrInputValue(
      (v) => super.setValue(v, true),
      (v) => {
        this.eInput.value = v;
      },
      value
    );
  }
  setValueOrInputValue(setValueFunc, setInputValueOnlyFunc, value) {
    if (_exists(value)) {
      let setInputValueOnly = this.isScientificNotation(value);
      if (setInputValueOnly && this.eInput.validity.valid) {
        return setValueFunc(value);
      }
      if (!setInputValueOnly) {
        value = this.adjustPrecision(value);
        const normalizedValue = this.normalizeValue(value);
        setInputValueOnly = value != normalizedValue;
      }
      if (setInputValueOnly) {
        return setInputValueOnlyFunc(value);
      }
    }
    return setValueFunc(value);
  }
  getValue() {
    if (!this.eInput.validity.valid) {
      return void 0;
    }
    const inputValue = this.eInput.value;
    if (this.isScientificNotation(inputValue)) {
      return this.adjustPrecision(inputValue, true);
    }
    return super.getValue();
  }
  isScientificNotation(value) {
    return typeof value === "string" && value.includes("e");
  }
};
var AgInputNumberFieldSelector = {
  selector: "AG-INPUT-NUMBER-FIELD",
  component: AgInputNumberField
};

// community-modules/core/src/filter/provided/number/numberFilterConstants.ts
var DEFAULT_NUMBER_FILTER_OPTIONS = [
  "equals",
  "notEqual",
  "greaterThan",
  "greaterThanOrEqual",
  "lessThan",
  "lessThanOrEqual",
  "inRange",
  "blank",
  "notBlank"
];

// community-modules/core/src/filter/provided/simpleFilterModelFormatter.ts
var SimpleFilterModelFormatter = class {
  constructor(localeService, optionsFactory, valueFormatter) {
    this.localeService = localeService;
    this.optionsFactory = optionsFactory;
    this.valueFormatter = valueFormatter;
  }
  // used by:
  // 1) NumberFloatingFilter & TextFloatingFilter: Always, for both when editable and read only.
  // 2) DateFloatingFilter: Only when read only (as we show text rather than a date picker when read only)
  getModelAsString(model) {
    if (!model) {
      return null;
    }
    const isCombined = model.operator != null;
    const translate = this.localeService.getLocaleTextFunc();
    if (isCombined) {
      const combinedModel = model;
      const conditions = combinedModel.conditions ?? [];
      const customOptions = conditions.map((condition) => this.getModelAsString(condition));
      const joinOperatorTranslateKey = combinedModel.operator === "AND" ? "andCondition" : "orCondition";
      return customOptions.join(
        ` ${translate(joinOperatorTranslateKey, FILTER_LOCALE_TEXT[joinOperatorTranslateKey])} `
      );
    } else if (model.type === "blank" || model.type === "notBlank") {
      return translate(model.type, model.type);
    } else {
      const condition = model;
      const customOption = this.optionsFactory.getCustomOption(condition.type);
      const { displayKey, displayName, numberOfInputs } = customOption || {};
      if (displayKey && displayName && numberOfInputs === 0) {
        translate(displayKey, displayName);
        return displayName;
      }
      return this.conditionToString(condition, customOption);
    }
  }
  updateParams(params) {
    this.optionsFactory = params.optionsFactory;
  }
  formatValue(value) {
    return this.valueFormatter ? this.valueFormatter(value ?? null) ?? "" : String(value);
  }
};

// community-modules/core/src/filter/provided/number/numberFilterModelFormatter.ts
var NumberFilterModelFormatter = class extends SimpleFilterModelFormatter {
  conditionToString(condition, options) {
    const { numberOfInputs } = options || {};
    const isRange = condition.type == "inRange" || numberOfInputs === 2;
    if (isRange) {
      return `${this.formatValue(condition.filter)}-${this.formatValue(condition.filterTo)}`;
    }
    if (condition.filter != null) {
      return this.formatValue(condition.filter);
    }
    return `${condition.type}`;
  }
};

// community-modules/core/src/filter/provided/number/numberFilterUtils.ts
function getAllowedCharPattern(filterParams) {
  const { allowedCharPattern } = filterParams ?? {};
  return allowedCharPattern ?? null;
}

// community-modules/core/src/filter/provided/number/numberFilter.ts
var NumberFilter = class extends ScalarFilter {
  constructor() {
    super("numberFilter");
    this.eValuesFrom = [];
    this.eValuesTo = [];
  }
  refresh(params) {
    if (this.numberFilterParams.allowedCharPattern !== params.allowedCharPattern) {
      return false;
    }
    return super.refresh(params);
  }
  mapValuesFromModel(filterModel) {
    const { filter, filterTo, type } = filterModel || {};
    return [this.processValue(filter), this.processValue(filterTo)].slice(0, this.getNumberOfInputs(type));
  }
  getDefaultDebounceMs() {
    return 500;
  }
  comparator() {
    return (left, right) => {
      if (left === right) {
        return 0;
      }
      return left < right ? 1 : -1;
    };
  }
  setParams(params) {
    this.numberFilterParams = params;
    super.setParams(params);
    this.filterModelFormatter = new NumberFilterModelFormatter(
      this.localeService,
      this.optionsFactory,
      this.numberFilterParams.numberFormatter
    );
  }
  getDefaultFilterOptions() {
    return DEFAULT_NUMBER_FILTER_OPTIONS;
  }
  setElementValue(element, value, fromFloatingFilter) {
    const valueToSet = !fromFloatingFilter && this.numberFilterParams.numberFormatter ? this.numberFilterParams.numberFormatter(value ?? null) : value;
    super.setElementValue(element, valueToSet);
  }
  createValueElement() {
    const allowedCharPattern = getAllowedCharPattern(this.numberFilterParams);
    const eCondition = document.createElement("div");
    eCondition.classList.add("ag-filter-body");
    _setAriaRole(eCondition, "presentation");
    this.createFromToElement(eCondition, this.eValuesFrom, "from", allowedCharPattern);
    this.createFromToElement(eCondition, this.eValuesTo, "to", allowedCharPattern);
    return eCondition;
  }
  createFromToElement(eCondition, eValues, fromTo, allowedCharPattern) {
    const eValue = this.createManagedBean(
      allowedCharPattern ? new AgInputTextField({ allowedCharPattern }) : new AgInputNumberField()
    );
    eValue.addCssClass(`ag-filter-${fromTo}`);
    eValue.addCssClass("ag-filter-filter");
    eValues.push(eValue);
    eCondition.appendChild(eValue.getGui());
  }
  removeValueElements(startPosition, deleteCount) {
    this.removeComponents(this.eValuesFrom, startPosition, deleteCount);
    this.removeComponents(this.eValuesTo, startPosition, deleteCount);
  }
  getValues(position) {
    const result = [];
    this.forEachPositionInput(position, (element, index, _elPosition, numberOfInputs) => {
      if (index < numberOfInputs) {
        result.push(this.processValue(this.stringToFloat(element.getValue())));
      }
    });
    return result;
  }
  areSimpleModelsEqual(aSimple, bSimple) {
    return aSimple.filter === bSimple.filter && aSimple.filterTo === bSimple.filterTo && aSimple.type === bSimple.type;
  }
  getFilterType() {
    return "number";
  }
  processValue(value) {
    if (value == null) {
      return null;
    }
    return isNaN(value) ? null : value;
  }
  stringToFloat(value) {
    if (typeof value === "number") {
      return value;
    }
    let filterText = _makeNull(value);
    if (filterText != null && filterText.trim() === "") {
      filterText = null;
    }
    if (this.numberFilterParams.numberParser) {
      return this.numberFilterParams.numberParser(filterText);
    }
    return filterText == null || filterText.trim() === "-" ? null : parseFloat(filterText);
  }
  createCondition(position) {
    const type = this.getConditionType(position);
    const model = {
      filterType: this.getFilterType(),
      type
    };
    const values = this.getValues(position);
    if (values.length > 0) {
      model.filter = values[0];
    }
    if (values.length > 1) {
      model.filterTo = values[1];
    }
    return model;
  }
  getInputs(position) {
    if (position >= this.eValuesFrom.length) {
      return [null, null];
    }
    return [this.eValuesFrom[position], this.eValuesTo[position]];
  }
  getModelAsString(model) {
    return this.filterModelFormatter.getModelAsString(model) ?? "";
  }
  hasInvalidInputs() {
    let invalidInputs = false;
    this.forEachInput((element) => {
      if (!element.getInputElement().validity.valid) {
        invalidInputs = true;
        return;
      }
    });
    return invalidInputs;
  }
};

// community-modules/core/src/filter/provided/text/textFilterConstants.ts
var DEFAULT_TEXT_FILTER_OPTIONS = [
  "contains",
  "notContains",
  "equals",
  "notEqual",
  "startsWith",
  "endsWith",
  "blank",
  "notBlank"
];

// community-modules/core/src/filter/provided/text/textFilterModelFormatter.ts
var TextFilterModelFormatter = class extends SimpleFilterModelFormatter {
  conditionToString(condition, options) {
    const { numberOfInputs } = options || {};
    const isRange = condition.type == "inRange" || numberOfInputs === 2;
    if (isRange) {
      return `${condition.filter}-${condition.filterTo}`;
    }
    if (condition.filter != null) {
      return `${condition.filter}`;
    }
    return `${condition.type}`;
  }
};

// community-modules/core/src/filter/provided/text/textFilterUtils.ts
function trimInputForFilter(value) {
  const trimmedInput = value && value.trim();
  return trimmedInput === "" ? value : trimmedInput;
}

// community-modules/core/src/filter/provided/text/textFilter.ts
var TextFilter = class extends SimpleFilter {
  constructor() {
    super("textFilter");
    this.defaultFormatter = (from) => from;
    this.defaultLowercaseFormatter = (from) => from == null ? null : from.toString().toLowerCase();
    this.defaultMatcher = ({ filterOption, value, filterText }) => {
      if (filterText == null) {
        return false;
      }
      switch (filterOption) {
        case "contains":
          return value.indexOf(filterText) >= 0;
        case "notContains":
          return value.indexOf(filterText) < 0;
        case "equals":
          return value === filterText;
        case "notEqual":
          return value != filterText;
        case "startsWith":
          return value.indexOf(filterText) === 0;
        case "endsWith": {
          const index = value.lastIndexOf(filterText);
          return index >= 0 && index === value.length - filterText.length;
        }
        default:
          return false;
      }
    };
    this.eValuesFrom = [];
    this.eValuesTo = [];
  }
  getDefaultDebounceMs() {
    return 500;
  }
  setParams(params) {
    this.textFilterParams = params;
    super.setParams(params);
    this.matcher = this.getTextMatcher();
    this.formatter = this.textFilterParams.textFormatter || (this.textFilterParams.caseSensitive ? this.defaultFormatter : this.defaultLowercaseFormatter);
    this.filterModelFormatter = new TextFilterModelFormatter(this.localeService, this.optionsFactory);
  }
  getTextMatcher() {
    const legacyComparator = this.textFilterParams.textCustomComparator;
    if (legacyComparator) {
      _warnOnce("textCustomComparator is deprecated, use textMatcher instead.");
      return ({ filterOption, value, filterText }) => legacyComparator(filterOption, value, filterText);
    }
    return this.textFilterParams.textMatcher || this.defaultMatcher;
  }
  createCondition(position) {
    const type = this.getConditionType(position);
    const model = {
      filterType: this.getFilterType(),
      type
    };
    const values = this.getValuesWithSideEffects(position, true);
    if (values.length > 0) {
      model.filter = values[0];
    }
    if (values.length > 1) {
      model.filterTo = values[1];
    }
    return model;
  }
  getFilterType() {
    return "text";
  }
  areSimpleModelsEqual(aSimple, bSimple) {
    return aSimple.filter === bSimple.filter && aSimple.filterTo === bSimple.filterTo && aSimple.type === bSimple.type;
  }
  getInputs(position) {
    if (position >= this.eValuesFrom.length) {
      return [null, null];
    }
    return [this.eValuesFrom[position], this.eValuesTo[position]];
  }
  getValues(position) {
    return this.getValuesWithSideEffects(position, false);
  }
  getValuesWithSideEffects(position, applySideEffects) {
    const result = [];
    this.forEachPositionInput(position, (element, index, _elPosition, numberOfInputs) => {
      if (index < numberOfInputs) {
        let value = _makeNull(element.getValue());
        if (applySideEffects && this.textFilterParams.trimInput) {
          value = trimInputForFilter(value) ?? null;
          element.setValue(value, true);
        }
        result.push(value);
      }
    });
    return result;
  }
  getDefaultFilterOptions() {
    return DEFAULT_TEXT_FILTER_OPTIONS;
  }
  createValueElement() {
    const eCondition = document.createElement("div");
    eCondition.classList.add("ag-filter-body");
    _setAriaRole(eCondition, "presentation");
    this.createFromToElement(eCondition, this.eValuesFrom, "from");
    this.createFromToElement(eCondition, this.eValuesTo, "to");
    return eCondition;
  }
  createFromToElement(eCondition, eValues, fromTo) {
    const eValue = this.createManagedBean(new AgInputTextField());
    eValue.addCssClass(`ag-filter-${fromTo}`);
    eValue.addCssClass("ag-filter-filter");
    eValues.push(eValue);
    eCondition.appendChild(eValue.getGui());
  }
  removeValueElements(startPosition, deleteCount) {
    this.removeComponents(this.eValuesFrom, startPosition, deleteCount);
    this.removeComponents(this.eValuesTo, startPosition, deleteCount);
  }
  mapValuesFromModel(filterModel) {
    const { filter, filterTo, type } = filterModel || {};
    return [filter || null, filterTo || null].slice(0, this.getNumberOfInputs(type));
  }
  evaluateNullValue(filterType) {
    const filterTypesAllowNulls = ["notEqual", "notContains", "blank"];
    return filterType ? filterTypesAllowNulls.indexOf(filterType) >= 0 : false;
  }
  evaluateNonNullValue(values, cellValue, filterModel, params) {
    const formattedValues = values.map((v) => this.formatter(v)) || [];
    const cellValueFormatted = this.formatter(cellValue);
    const { api, colDef, column, context, textFormatter } = this.textFilterParams;
    if (filterModel.type === "blank") {
      return this.isBlank(cellValue);
    } else if (filterModel.type === "notBlank") {
      return !this.isBlank(cellValue);
    }
    const matcherParams = {
      api,
      colDef,
      column,
      context,
      node: params.node,
      data: params.data,
      filterOption: filterModel.type,
      value: cellValueFormatted,
      textFormatter
    };
    return formattedValues.some((v) => this.matcher({ ...matcherParams, filterText: v }));
  }
  getModelAsString(model) {
    return this.filterModelFormatter.getModelAsString(model) ?? "";
  }
};

// community-modules/core/src/utils/number.ts
function toNumber(value) {
  if (typeof value === "number") {
    return value;
  }
  if (typeof value === "string") {
    const parsed = parseInt(value);
    if (isNaN(parsed)) {
      return void 0;
    }
    return parsed;
  }
  return void 0;
}
function toConstrainedNum(min, max = Number.MAX_VALUE) {
  return (value) => {
    const num = toNumber(value);
    if (num == null || num < min || num > max) {
      return void 0;
    }
    return num;
  };
}
function _padStartWidthZeros(value, totalStringSize) {
  return value.toString().padStart(totalStringSize, "0");
}
function _createArrayOfNumbers(first, last) {
  const result = [];
  for (let i = first; i <= last; i++) {
    result.push(i);
  }
  return result;
}
function _formatNumberTwoDecimalPlacesAndCommas(value, thousandSeparator, decimalSeparator) {
  if (typeof value !== "number") {
    return "";
  }
  return _formatNumberCommas(Math.round(value * 100) / 100, thousandSeparator, decimalSeparator);
}
function _formatNumberCommas(value, thousandSeparator, decimalSeparator) {
  if (typeof value !== "number") {
    return "";
  }
  return value.toString().replace(".", decimalSeparator).replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandSeparator}`);
}

// community-modules/core/src/utils/date.ts
function _serialiseDate(date, includeTime = true, separator = "-") {
  if (!date) {
    return null;
  }
  let serialised = [date.getFullYear(), date.getMonth() + 1, date.getDate()].map((part) => _padStartWidthZeros(part, 2)).join(separator);
  if (includeTime) {
    serialised += " " + [date.getHours(), date.getMinutes(), date.getSeconds()].map((part) => _padStartWidthZeros(part, 2)).join(":");
  }
  return serialised;
}
var calculateOrdinal = (value) => {
  if (value > 3 && value < 21) {
    return "th";
  }
  const remainder = value % 10;
  switch (remainder) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
  }
  return "th";
};
function _dateToFormattedString(date, format = "YYYY-MM-DD") {
  const fullYear = _padStartWidthZeros(date.getFullYear(), 4);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const replace = {
    YYYY: () => fullYear.slice(fullYear.length - 4, fullYear.length),
    YY: () => fullYear.slice(fullYear.length - 2, fullYear.length),
    Y: () => `${date.getFullYear()}`,
    MMMM: () => months[date.getMonth()],
    MMM: () => months[date.getMonth()].slice(0, 3),
    MM: () => _padStartWidthZeros(date.getMonth() + 1, 2),
    Mo: () => `${date.getMonth() + 1}${calculateOrdinal(date.getMonth() + 1)}`,
    M: () => `${date.getMonth() + 1}`,
    Do: () => `${date.getDate()}${calculateOrdinal(date.getDate())}`,
    DD: () => _padStartWidthZeros(date.getDate(), 2),
    D: () => `${date.getDate()}`,
    dddd: () => days[date.getDay()],
    ddd: () => days[date.getDay()].slice(0, 3),
    dd: () => days[date.getDay()].slice(0, 2),
    do: () => `${date.getDay()}${calculateOrdinal(date.getDay())}`,
    d: () => `${date.getDay()}`
  };
  const regexp = new RegExp(Object.keys(replace).join("|"), "g");
  return format.replace(regexp, (match) => {
    if (match in replace) {
      return replace[match]();
    }
    return match;
  });
}
function _parseDateTimeFromString(value) {
  if (!value) {
    return null;
  }
  const [dateStr, timeStr] = value.split(" ");
  if (!dateStr) {
    return null;
  }
  const fields = dateStr.split("-").map((f) => parseInt(f, 10));
  if (fields.filter((f) => !isNaN(f)).length !== 3) {
    return null;
  }
  const [year, month, day] = fields;
  const date = new Date(year, month - 1, day);
  if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
    return null;
  }
  if (!timeStr || timeStr === "00:00:00") {
    return date;
  }
  const [hours, minutes, seconds] = timeStr.split(":").map((part) => parseInt(part, 10));
  if (hours >= 0 && hours < 24) {
    date.setHours(hours);
  }
  if (minutes >= 0 && minutes < 60) {
    date.setMinutes(minutes);
  }
  if (seconds >= 0 && seconds < 60) {
    date.setSeconds(seconds);
  }
  return date;
}

// community-modules/core/src/filter/provided/date/dateCompWrapper.ts
var DateCompWrapper = class {
  constructor(context, userComponentFactory, dateComponentParams, eParent, onReady) {
    this.alive = true;
    this.context = context;
    this.eParent = eParent;
    const compDetails = userComponentFactory.getDateCompDetails(dateComponentParams);
    const promise = compDetails.newAgStackInstance();
    promise.then((dateComp) => {
      if (!this.alive) {
        context.destroyBean(dateComp);
        return;
      }
      this.dateComp = dateComp;
      if (!dateComp) {
        return;
      }
      eParent.appendChild(dateComp.getGui());
      if (dateComp.afterGuiAttached) {
        dateComp.afterGuiAttached();
      }
      if (this.tempValue) {
        dateComp.setDate(this.tempValue);
      }
      if (this.disabled != null) {
        this.setDateCompDisabled(this.disabled);
      }
      onReady?.(this);
    });
  }
  destroy() {
    this.alive = false;
    this.dateComp = this.context.destroyBean(this.dateComp);
  }
  getDate() {
    return this.dateComp ? this.dateComp.getDate() : this.tempValue;
  }
  setDate(value) {
    if (this.dateComp) {
      this.dateComp.setDate(value);
    } else {
      this.tempValue = value;
    }
  }
  setDisabled(disabled) {
    if (this.dateComp) {
      this.setDateCompDisabled(disabled);
    } else {
      this.disabled = disabled;
    }
  }
  setDisplayed(displayed) {
    _setDisplayed(this.eParent, displayed);
  }
  setInputPlaceholder(placeholder) {
    if (this.dateComp && this.dateComp.setInputPlaceholder) {
      this.dateComp.setInputPlaceholder(placeholder);
    }
  }
  setInputAriaLabel(label) {
    if (this.dateComp && this.dateComp.setInputAriaLabel) {
      this.dateComp.setInputAriaLabel(label);
    }
  }
  afterGuiAttached(params) {
    if (this.dateComp && typeof this.dateComp.afterGuiAttached === "function") {
      this.dateComp.afterGuiAttached(params);
    }
  }
  updateParams(params) {
    let hasRefreshed = false;
    if (this.dateComp?.refresh && typeof this.dateComp.refresh === "function") {
      const result = this.dateComp.refresh(params);
      if (result !== null) {
        hasRefreshed = true;
      }
    }
    if (!hasRefreshed && this.dateComp?.onParamsUpdated && typeof this.dateComp.onParamsUpdated === "function") {
      const result = this.dateComp.onParamsUpdated(params);
      if (result !== null) {
        _warnOnce(`Custom date component method 'onParamsUpdated' is deprecated. Use 'refresh' instead.`);
      }
    }
  }
  setDateCompDisabled(disabled) {
    if (this.dateComp == null) {
      return;
    }
    if (this.dateComp.setDisabled == null) {
      return;
    }
    this.dateComp.setDisabled(disabled);
  }
};

// community-modules/core/src/filter/provided/date/dateFilterConstants.ts
var DEFAULT_DATE_FILTER_OPTIONS = [
  "equals",
  "notEqual",
  "lessThan",
  "greaterThan",
  "inRange",
  "blank",
  "notBlank"
];

// community-modules/core/src/filter/provided/date/dateFilterModelFormatter.ts
var DateFilterModelFormatter = class extends SimpleFilterModelFormatter {
  constructor(dateFilterParams, localeService, optionsFactory) {
    super(localeService, optionsFactory);
    this.dateFilterParams = dateFilterParams;
  }
  conditionToString(condition, options) {
    const { type } = condition;
    const { numberOfInputs } = options || {};
    const isRange = type == "inRange" || numberOfInputs === 2;
    const dateFrom = _parseDateTimeFromString(condition.dateFrom);
    const dateTo = _parseDateTimeFromString(condition.dateTo);
    const format = this.dateFilterParams.inRangeFloatingFilterDateFormat;
    if (isRange) {
      const formattedFrom = dateFrom !== null ? _dateToFormattedString(dateFrom, format) : "null";
      const formattedTo = dateTo !== null ? _dateToFormattedString(dateTo, format) : "null";
      return `${formattedFrom}-${formattedTo}`;
    }
    if (dateFrom != null) {
      return _dateToFormattedString(dateFrom, format);
    }
    return `${type}`;
  }
  updateParams(params) {
    super.updateParams(params);
    this.dateFilterParams = params.dateFilterParams;
  }
};

// community-modules/core/src/filter/provided/date/dateFilter.ts
var DEFAULT_MIN_YEAR = 1e3;
var DEFAULT_MAX_YEAR = Infinity;
var DateFilter = class extends ScalarFilter {
  constructor() {
    super("dateFilter");
    this.eConditionPanelsFrom = [];
    this.eConditionPanelsTo = [];
    this.dateConditionFromComps = [];
    this.dateConditionToComps = [];
    this.minValidYear = DEFAULT_MIN_YEAR;
    this.maxValidYear = DEFAULT_MAX_YEAR;
    this.minValidDate = null;
    this.maxValidDate = null;
  }
  wireBeans(beans) {
    super.wireBeans(beans);
    this.context = beans.context;
    this.userComponentFactory = beans.userComponentFactory;
  }
  afterGuiAttached(params) {
    super.afterGuiAttached(params);
    this.dateConditionFromComps[0].afterGuiAttached(params);
  }
  mapValuesFromModel(filterModel) {
    const { dateFrom, dateTo, type } = filterModel || {};
    return [
      dateFrom && _parseDateTimeFromString(dateFrom) || null,
      dateTo && _parseDateTimeFromString(dateTo) || null
    ].slice(0, this.getNumberOfInputs(type));
  }
  comparator() {
    return this.dateFilterParams.comparator ? this.dateFilterParams.comparator : this.defaultComparator.bind(this);
  }
  defaultComparator(filterDate, cellValue) {
    const cellAsDate = cellValue;
    if (cellValue == null || cellAsDate < filterDate) {
      return -1;
    }
    if (cellAsDate > filterDate) {
      return 1;
    }
    return 0;
  }
  setParams(params) {
    this.dateFilterParams = params;
    super.setParams(params);
    const yearParser = (param, fallback) => {
      if (params[param] != null) {
        if (!isNaN(params[param])) {
          return params[param] == null ? fallback : Number(params[param]);
        } else {
          _warnOnce(`DateFilter ${param} is not a number`);
        }
      }
      return fallback;
    };
    this.minValidYear = yearParser("minValidYear", DEFAULT_MIN_YEAR);
    this.maxValidYear = yearParser("maxValidYear", DEFAULT_MAX_YEAR);
    if (this.minValidYear > this.maxValidYear) {
      _warnOnce(`DateFilter minValidYear should be <= maxValidYear`);
    }
    if (params.minValidDate) {
      this.minValidDate = params.minValidDate instanceof Date ? params.minValidDate : _parseDateTimeFromString(params.minValidDate);
    } else {
      this.minValidDate = null;
    }
    if (params.maxValidDate) {
      this.maxValidDate = params.maxValidDate instanceof Date ? params.maxValidDate : _parseDateTimeFromString(params.maxValidDate);
    } else {
      this.maxValidDate = null;
    }
    if (this.minValidDate && this.maxValidDate && this.minValidDate > this.maxValidDate) {
      _warnOnce(`DateFilter minValidDate should be <= maxValidDate`);
    }
    this.filterModelFormatter = new DateFilterModelFormatter(
      this.dateFilterParams,
      this.localeService,
      this.optionsFactory
    );
  }
  createDateCompWrapper(element) {
    const dateCompWrapper = new DateCompWrapper(
      this.context,
      this.userComponentFactory,
      {
        onDateChanged: () => this.onUiChanged(),
        filterParams: this.dateFilterParams
      },
      element
    );
    this.addDestroyFunc(() => dateCompWrapper.destroy());
    return dateCompWrapper;
  }
  setElementValue(element, value) {
    element.setDate(value);
  }
  setElementDisplayed(element, displayed) {
    element.setDisplayed(displayed);
  }
  setElementDisabled(element, disabled) {
    element.setDisabled(disabled);
  }
  getDefaultFilterOptions() {
    return DEFAULT_DATE_FILTER_OPTIONS;
  }
  createValueElement() {
    const eDocument = this.gos.getDocument();
    const eCondition = eDocument.createElement("div");
    eCondition.classList.add("ag-filter-body");
    this.createFromToElement(eCondition, this.eConditionPanelsFrom, this.dateConditionFromComps, "from");
    this.createFromToElement(eCondition, this.eConditionPanelsTo, this.dateConditionToComps, "to");
    return eCondition;
  }
  createFromToElement(eCondition, eConditionPanels, dateConditionComps, fromTo) {
    const eDocument = this.gos.getDocument();
    const eConditionPanel = eDocument.createElement("div");
    eConditionPanel.classList.add(`ag-filter-${fromTo}`);
    eConditionPanel.classList.add(`ag-filter-date-${fromTo}`);
    eConditionPanels.push(eConditionPanel);
    eCondition.appendChild(eConditionPanel);
    dateConditionComps.push(this.createDateCompWrapper(eConditionPanel));
  }
  removeValueElements(startPosition, deleteCount) {
    this.removeDateComps(this.dateConditionFromComps, startPosition, deleteCount);
    this.removeDateComps(this.dateConditionToComps, startPosition, deleteCount);
    this.removeItems(this.eConditionPanelsFrom, startPosition, deleteCount);
    this.removeItems(this.eConditionPanelsTo, startPosition, deleteCount);
  }
  removeDateComps(components, startPosition, deleteCount) {
    const removedComponents = this.removeItems(components, startPosition, deleteCount);
    removedComponents.forEach((comp) => comp.destroy());
  }
  isValidDateValue(value) {
    if (value === null) {
      return false;
    }
    if (this.minValidDate) {
      if (value < this.minValidDate) {
        return false;
      }
    } else {
      if (value.getUTCFullYear() < this.minValidYear) {
        return false;
      }
    }
    if (this.maxValidDate) {
      if (value > this.maxValidDate) {
        return false;
      }
    } else {
      if (value.getUTCFullYear() > this.maxValidYear) {
        return false;
      }
    }
    return true;
  }
  isConditionUiComplete(position) {
    if (!super.isConditionUiComplete(position)) {
      return false;
    }
    let valid = true;
    this.forEachInput((element, index, elPosition, numberOfInputs) => {
      if (elPosition !== position || !valid || index >= numberOfInputs) {
        return;
      }
      valid = valid && this.isValidDateValue(element.getDate());
    });
    return valid;
  }
  areSimpleModelsEqual(aSimple, bSimple) {
    return aSimple.dateFrom === bSimple.dateFrom && aSimple.dateTo === bSimple.dateTo && aSimple.type === bSimple.type;
  }
  getFilterType() {
    return "date";
  }
  createCondition(position) {
    const type = this.getConditionType(position);
    const model = {};
    const values = this.getValues(position);
    if (values.length > 0) {
      model.dateFrom = _serialiseDate(values[0]);
    }
    if (values.length > 1) {
      model.dateTo = _serialiseDate(values[1]);
    }
    return {
      dateFrom: null,
      dateTo: null,
      filterType: this.getFilterType(),
      type,
      ...model
    };
  }
  resetPlaceholder() {
    const globalTranslate = this.localeService.getLocaleTextFunc();
    const placeholder = this.translate("dateFormatOoo");
    const ariaLabel = globalTranslate("ariaFilterValue", "Filter Value");
    this.forEachInput((element) => {
      element.setInputPlaceholder(placeholder);
      element.setInputAriaLabel(ariaLabel);
    });
  }
  getInputs(position) {
    if (position >= this.dateConditionFromComps.length) {
      return [null, null];
    }
    return [this.dateConditionFromComps[position], this.dateConditionToComps[position]];
  }
  getValues(position) {
    const result = [];
    this.forEachPositionInput(position, (element, index, _elPosition, numberOfInputs) => {
      if (index < numberOfInputs) {
        result.push(element.getDate());
      }
    });
    return result;
  }
  translate(key) {
    if (key === "lessThan") {
      return super.translate("before");
    }
    if (key === "greaterThan") {
      return super.translate("after");
    }
    return super.translate(key);
  }
  getModelAsString(model) {
    return this.filterModelFormatter.getModelAsString(model) ?? "";
  }
};

// community-modules/core/src/rendering/features/setLeftFeature.ts
var SetLeftFeature = class extends BeanStub {
  constructor(columnOrGroup, eCell, beans, colsSpanning) {
    super();
    this.columnOrGroup = columnOrGroup;
    this.columnOrGroup = columnOrGroup;
    this.eCell = eCell;
    this.ariaEl = this.eCell.querySelector("[role=columnheader]") || this.eCell;
    this.colsSpanning = colsSpanning;
    this.beans = beans;
  }
  setColsSpanning(colsSpanning) {
    this.colsSpanning = colsSpanning;
    this.onLeftChanged();
  }
  getColumnOrGroup() {
    if (this.beans.gos.get("enableRtl") && this.colsSpanning) {
      return _last(this.colsSpanning);
    }
    return this.columnOrGroup;
  }
  postConstruct() {
    const onLeftChanged = this.onLeftChanged.bind(this);
    this.addManagedListeners(this.columnOrGroup, { leftChanged: onLeftChanged });
    this.setLeftFirstTime();
    this.addManagedEventListeners({ displayedColumnsWidthChanged: onLeftChanged });
    this.addManagedPropertyListener("domLayout", onLeftChanged);
  }
  setLeftFirstTime() {
    const suppressMoveAnimation = this.beans.gos.get("suppressColumnMoveAnimation");
    const oldLeftExists = _exists(this.columnOrGroup.getOldLeft());
    const animateColumnMove = this.beans.columnAnimationService.isActive() && oldLeftExists && !suppressMoveAnimation;
    if (animateColumnMove) {
      this.animateInLeft();
    } else {
      this.onLeftChanged();
    }
  }
  animateInLeft() {
    const colOrGroup = this.getColumnOrGroup();
    const left = colOrGroup.getLeft();
    const oldLeft = colOrGroup.getOldLeft();
    const oldActualLeft = this.modifyLeftForPrintLayout(colOrGroup, oldLeft);
    const actualLeft = this.modifyLeftForPrintLayout(colOrGroup, left);
    this.setLeft(oldActualLeft);
    this.actualLeft = actualLeft;
    this.beans.columnAnimationService.executeNextVMTurn(() => {
      if (this.actualLeft === actualLeft) {
        this.setLeft(actualLeft);
      }
    });
  }
  onLeftChanged() {
    const colOrGroup = this.getColumnOrGroup();
    const left = colOrGroup.getLeft();
    this.actualLeft = this.modifyLeftForPrintLayout(colOrGroup, left);
    this.setLeft(this.actualLeft);
  }
  modifyLeftForPrintLayout(colOrGroup, leftPosition) {
    const printLayout = this.beans.gos.isDomLayout("print");
    if (!printLayout) {
      return leftPosition;
    }
    if (colOrGroup.getPinned() === "left") {
      return leftPosition;
    }
    const leftWidth = this.beans.visibleColsService.getColsLeftWidth();
    if (colOrGroup.getPinned() === "right") {
      const bodyWidth = this.beans.visibleColsService.getBodyContainerWidth();
      return leftWidth + bodyWidth + leftPosition;
    }
    return leftWidth + leftPosition;
  }
  setLeft(value) {
    if (_exists(value)) {
      this.eCell.style.left = `${value}px`;
    }
    if (isColumnGroup(this.columnOrGroup)) {
      const children = this.columnOrGroup.getLeafColumns();
      if (!children.length) {
        return;
      }
      if (children.length > 1) {
        _setAriaColSpan(this.ariaEl, children.length);
      }
    }
  }
};

// community-modules/core/src/headerRendering/cells/cssClassApplier.ts
var CSS_FIRST_COLUMN = "ag-column-first";
var CSS_LAST_COLUMN = "ag-column-last";
function _getHeaderClassesFromColDef(abstractColDef, gos, column, columnGroup) {
  if (_missing(abstractColDef)) {
    return [];
  }
  return getColumnClassesFromCollDef(abstractColDef.headerClass, abstractColDef, gos, column, columnGroup);
}
function _getToolPanelClassesFromColDef(abstractColDef, gos, column, columnGroup) {
  if (_missing(abstractColDef)) {
    return [];
  }
  return getColumnClassesFromCollDef(abstractColDef.toolPanelClass, abstractColDef, gos, column, columnGroup);
}
function refreshFirstAndLastStyles(comp, column, presentedColsService) {
  comp.addOrRemoveCssClass(CSS_FIRST_COLUMN, presentedColsService.isColAtEdge(column, "first"));
  comp.addOrRemoveCssClass(CSS_LAST_COLUMN, presentedColsService.isColAtEdge(column, "last"));
}
function getClassParams(abstractColDef, gos, column, columnGroup) {
  return gos.addGridCommonParams({
    // bad naming, as colDef here can be a group or a column,
    // however most people won't appreciate the difference,
    // so keeping it as colDef to avoid confusion.
    colDef: abstractColDef,
    column,
    columnGroup
  });
}
function getColumnClassesFromCollDef(classesOrFunc, abstractColDef, gos, column, columnGroup) {
  if (_missing(classesOrFunc)) {
    return [];
  }
  let classToUse;
  if (typeof classesOrFunc === "function") {
    const params = getClassParams(abstractColDef, gos, column, columnGroup);
    classToUse = classesOrFunc(params);
  } else {
    classToUse = classesOrFunc;
  }
  if (typeof classToUse === "string") {
    return [classToUse];
  }
  if (Array.isArray(classToUse)) {
    return [...classToUse];
  }
  return [];
}

// community-modules/core/src/headerRendering/cells/abstractCell/abstractHeaderCellCtrl.ts
var instanceIdSequence2 = 0;
var _AbstractHeaderCellCtrl = class _AbstractHeaderCellCtrl extends BeanStub {
  constructor(columnGroupChild, beans, parentRowCtrl) {
    super();
    this.resizeToggleTimeout = 0;
    this.resizeMultiplier = 1;
    this.resizeFeature = null;
    this.lastFocusEvent = null;
    this.dragSource = null;
    this.columnGroupChild = columnGroupChild;
    this.parentRowCtrl = parentRowCtrl;
    this.beans = beans;
    this.instanceId = columnGroupChild.getUniqueId() + "-" + instanceIdSequence2++;
  }
  wireBeans(beans) {
    this.pinnedWidthService = beans.pinnedWidthService;
    this.focusService = beans.focusService;
    this.userComponentFactory = beans.userComponentFactory;
    this.ctrlsService = beans.ctrlsService;
    this.dragAndDropService = beans.dragAndDropService;
    this.menuService = beans.menuService;
  }
  postConstruct() {
    this.addManagedPropertyListeners(["suppressHeaderFocus"], () => this.refreshTabIndex());
  }
  shouldStopEventPropagation(e) {
    const { headerRowIndex, column } = this.focusService.getFocusedHeader();
    return _isUserSuppressingHeaderKeyboardEvent(this.gos, e, headerRowIndex, column);
  }
  getWrapperHasFocus() {
    const activeEl = this.gos.getActiveDomElement();
    return activeEl === this.eGui;
  }
  setGui(eGui) {
    this.eGui = eGui;
    this.addDomData();
    this.addManagedListeners(this.beans.eventService, {
      displayedColumnsChanged: this.onDisplayedColumnsChanged.bind(this)
    });
    this.addManagedElementListeners(this.eGui, {
      focus: this.onGuiFocus.bind(this)
    });
    this.onDisplayedColumnsChanged();
    this.refreshTabIndex();
  }
  onGuiFocus() {
    const event = {
      type: "headerFocused",
      column: this.column
    };
    this.eventService.dispatchEvent(event);
  }
  onDisplayedColumnsChanged() {
    if (!this.comp || !this.column) {
      return;
    }
    this.refreshFirstAndLastStyles();
    this.refreshAriaColIndex();
  }
  refreshFirstAndLastStyles() {
    const { comp, column, beans } = this;
    refreshFirstAndLastStyles(comp, column, beans.visibleColsService);
  }
  refreshAriaColIndex() {
    const { beans, column } = this;
    const colIdx = beans.visibleColsService.getAriaColIndex(column);
    _setAriaColIndex(this.eGui, colIdx);
  }
  addResizeAndMoveKeyboardListeners() {
    if (!this.resizeFeature) {
      return;
    }
    this.addManagedListeners(this.eGui, {
      keydown: this.onGuiKeyDown.bind(this),
      keyup: this.onGuiKeyUp.bind(this)
    });
  }
  refreshTabIndex() {
    const suppressHeaderFocus = this.gos.get("suppressHeaderFocus");
    if (suppressHeaderFocus) {
      this.eGui.removeAttribute("tabindex");
    } else {
      this.eGui.setAttribute("tabindex", "-1");
    }
  }
  onGuiKeyDown(e) {
    const activeEl = this.gos.getActiveDomElement();
    const isLeftOrRight = e.key === KeyCode.LEFT || e.key === KeyCode.RIGHT;
    if (this.isResizing) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
    if (
      // if elements within the header are focused, we don't process the event
      activeEl !== this.eGui || // if shiftKey and altKey are not pressed, it's cell navigation so we don't process the event
      !e.shiftKey && !e.altKey
    ) {
      return;
    }
    if (this.isResizing || isLeftOrRight) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
    if (!isLeftOrRight) {
      return;
    }
    const isLeft = e.key === KeyCode.LEFT !== this.gos.get("enableRtl");
    const direction = HorizontalDirection[isLeft ? "Left" : "Right"];
    if (e.altKey) {
      this.isResizing = true;
      this.resizeMultiplier += 1;
      const diff = this.getViewportAdjustedResizeDiff(e);
      this.resizeHeader(diff, e.shiftKey);
      this.resizeFeature?.toggleColumnResizing(true);
    } else {
      this.moveHeader(direction);
    }
  }
  getViewportAdjustedResizeDiff(e) {
    let diff = this.getResizeDiff(e);
    const pinned = this.column.getPinned();
    if (pinned) {
      const leftWidth = this.pinnedWidthService.getPinnedLeftWidth();
      const rightWidth = this.pinnedWidthService.getPinnedRightWidth();
      const bodyWidth = _getInnerWidth(this.ctrlsService.getGridBodyCtrl().getBodyViewportElement()) - 50;
      if (leftWidth + rightWidth + diff > bodyWidth) {
        if (bodyWidth > leftWidth + rightWidth) {
          diff = bodyWidth - leftWidth - rightWidth;
        } else {
          return 0;
        }
      }
    }
    return diff;
  }
  getResizeDiff(e) {
    let isLeft = e.key === KeyCode.LEFT !== this.gos.get("enableRtl");
    const pinned = this.column.getPinned();
    const isRtl = this.gos.get("enableRtl");
    if (pinned) {
      if (isRtl !== (pinned === "right")) {
        isLeft = !isLeft;
      }
    }
    return (isLeft ? -1 : 1) * this.resizeMultiplier;
  }
  onGuiKeyUp() {
    if (!this.isResizing) {
      return;
    }
    if (this.resizeToggleTimeout) {
      window.clearTimeout(this.resizeToggleTimeout);
      this.resizeToggleTimeout = 0;
    }
    this.isResizing = false;
    this.resizeMultiplier = 1;
    this.resizeToggleTimeout = window.setTimeout(() => {
      this.resizeFeature?.toggleColumnResizing(false);
    }, 150);
  }
  handleKeyDown(e) {
    const wrapperHasFocus = this.getWrapperHasFocus();
    switch (e.key) {
      case KeyCode.PAGE_DOWN:
      case KeyCode.PAGE_UP:
      case KeyCode.PAGE_HOME:
      case KeyCode.PAGE_END:
        if (wrapperHasFocus) {
          e.preventDefault();
        }
    }
  }
  addDomData() {
    const key = _AbstractHeaderCellCtrl.DOM_DATA_KEY_HEADER_CTRL;
    this.gos.setDomData(this.eGui, key, this);
    this.addDestroyFunc(() => this.gos.setDomData(this.eGui, key, null));
  }
  getGui() {
    return this.eGui;
  }
  focus(event) {
    if (!this.eGui) {
      return false;
    }
    this.lastFocusEvent = event || null;
    this.eGui.focus();
    return true;
  }
  getRowIndex() {
    return this.parentRowCtrl.getRowIndex();
  }
  getParentRowCtrl() {
    return this.parentRowCtrl;
  }
  getPinned() {
    return this.parentRowCtrl.getPinned();
  }
  getInstanceId() {
    return this.instanceId;
  }
  getColumnGroupChild() {
    return this.columnGroupChild;
  }
  removeDragSource() {
    if (this.dragSource) {
      this.dragAndDropService.removeDragSource(this.dragSource);
      this.dragSource = null;
    }
  }
  handleContextMenuMouseEvent(mouseEvent, touchEvent, column) {
    const event = mouseEvent ?? touchEvent;
    if (this.gos.get("preventDefaultOnContextMenu")) {
      event.preventDefault();
    }
    const columnToUse = isColumn(column) ? column : void 0;
    if (this.menuService.isHeaderContextMenuEnabled(columnToUse)) {
      this.menuService.showHeaderContextMenu(columnToUse, mouseEvent, touchEvent);
    }
    this.dispatchColumnMouseEvent("columnHeaderContextMenu", column);
  }
  dispatchColumnMouseEvent(eventType, column) {
    const event = {
      type: eventType,
      column
    };
    this.eventService.dispatchEvent(event);
  }
  destroy() {
    super.destroy();
    this.removeDragSource();
    this.comp = null;
    this.column = null;
    this.resizeFeature = null;
    this.lastFocusEvent = null;
    this.columnGroupChild = null;
    this.parentRowCtrl = null;
    this.eGui = null;
  }
};
_AbstractHeaderCellCtrl.DOM_DATA_KEY_HEADER_CTRL = "headerCtrl";
var AbstractHeaderCellCtrl = _AbstractHeaderCellCtrl;

// community-modules/core/src/headerRendering/cells/hoverFeature.ts
var HoverFeature = class extends BeanStub {
  wireBeans(beans) {
    this.columnHoverService = beans.columnHoverService;
  }
  constructor(columns, element) {
    super();
    this.columns = columns;
    this.element = element;
  }
  postConstruct() {
    if (this.gos.get("columnHoverHighlight")) {
      this.addMouseHoverListeners();
    }
  }
  addMouseHoverListeners() {
    this.addManagedListeners(this.element, {
      mouseout: this.onMouseOut.bind(this),
      mouseover: this.onMouseOver.bind(this)
    });
  }
  onMouseOut() {
    this.columnHoverService.clearMouseOver();
  }
  onMouseOver() {
    this.columnHoverService.setMouseOver(this.columns);
  }
};

// community-modules/core/src/headerRendering/cells/floatingFilter/headerFilterCellCtrl.ts
var HeaderFilterCellCtrl = class extends AbstractHeaderCellCtrl {
  constructor(column, beans, parentRowCtrl) {
    super(column, beans, parentRowCtrl);
    this.iconCreated = false;
    this.column = column;
  }
  setComp(comp, eGui, eButtonShowMainFilter, eFloatingFilterBody) {
    this.comp = comp;
    this.eButtonShowMainFilter = eButtonShowMainFilter;
    this.eFloatingFilterBody = eFloatingFilterBody;
    this.setGui(eGui);
    this.setupActive();
    this.setupWidth();
    this.setupLeft();
    this.setupHover();
    this.setupFocus();
    this.setupAria();
    this.setupFilterButton();
    this.setupUserComp();
    this.setupSyncWithFilter();
    this.setupUi();
    this.addManagedElementListeners(this.eButtonShowMainFilter, { click: this.showParentFilter.bind(this) });
    this.setupFilterChangedListener();
    this.addManagedListeners(this.column, { colDefChanged: this.onColDefChanged.bind(this) });
  }
  // empty abstract method
  resizeHeader() {
  }
  // empty abstract method
  moveHeader() {
  }
  setupActive() {
    const colDef = this.column.getColDef();
    const filterExists = !!colDef.filter;
    const floatingFilterExists = !!colDef.floatingFilter;
    this.active = filterExists && floatingFilterExists;
  }
  setupUi() {
    this.comp.setButtonWrapperDisplayed(!this.suppressFilterButton && this.active);
    this.comp.addOrRemoveBodyCssClass("ag-floating-filter-full-body", this.suppressFilterButton);
    this.comp.addOrRemoveBodyCssClass("ag-floating-filter-body", !this.suppressFilterButton);
    if (!this.active || this.iconCreated) {
      return;
    }
    const eMenuIcon = _createIconNoSpan("filter", this.gos, this.column);
    if (eMenuIcon) {
      this.iconCreated = true;
      this.eButtonShowMainFilter.appendChild(eMenuIcon);
    }
  }
  setupFocus() {
    this.createManagedBean(
      new ManagedFocusFeature(this.eGui, {
        shouldStopEventPropagation: this.shouldStopEventPropagation.bind(this),
        onTabKeyDown: this.onTabKeyDown.bind(this),
        handleKeyDown: this.handleKeyDown.bind(this),
        onFocusIn: this.onFocusIn.bind(this)
      })
    );
  }
  setupAria() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    _setAriaLabel(this.eButtonShowMainFilter, localeTextFunc("ariaFilterMenuOpen", "Open Filter Menu"));
  }
  onTabKeyDown(e) {
    const activeEl = this.gos.getActiveDomElement();
    const wrapperHasFocus = activeEl === this.eGui;
    if (wrapperHasFocus) {
      return;
    }
    const nextFocusableEl = this.focusService.findNextFocusableElement(this.eGui, null, e.shiftKey);
    if (nextFocusableEl) {
      this.beans.headerNavigationService.scrollToColumn(this.column);
      e.preventDefault();
      nextFocusableEl.focus();
      return;
    }
    const nextFocusableColumn = this.findNextColumnWithFloatingFilter(e.shiftKey);
    if (!nextFocusableColumn) {
      return;
    }
    if (this.focusService.focusHeaderPosition({
      headerPosition: {
        headerRowIndex: this.getParentRowCtrl().getRowIndex(),
        column: nextFocusableColumn
      },
      event: e
    })) {
      e.preventDefault();
    }
  }
  findNextColumnWithFloatingFilter(backwards) {
    const presentedColsService = this.beans.visibleColsService;
    let nextCol = this.column;
    do {
      nextCol = backwards ? presentedColsService.getColBefore(nextCol) : presentedColsService.getColAfter(nextCol);
      if (!nextCol) {
        break;
      }
    } while (!nextCol.getColDef().filter || !nextCol.getColDef().floatingFilter);
    return nextCol;
  }
  handleKeyDown(e) {
    super.handleKeyDown(e);
    const wrapperHasFocus = this.getWrapperHasFocus();
    switch (e.key) {
      case KeyCode.UP:
      case KeyCode.DOWN:
        if (!wrapperHasFocus) {
          e.preventDefault();
        }
      case KeyCode.LEFT:
      case KeyCode.RIGHT:
        if (wrapperHasFocus) {
          return;
        }
        e.stopPropagation();
      case KeyCode.ENTER:
        if (wrapperHasFocus) {
          if (this.focusService.focusInto(this.eGui)) {
            e.preventDefault();
          }
        }
        break;
      case KeyCode.ESCAPE:
        if (!wrapperHasFocus) {
          this.eGui.focus();
        }
    }
  }
  onFocusIn(e) {
    const isRelatedWithin = this.eGui.contains(e.relatedTarget);
    if (isRelatedWithin) {
      return;
    }
    const notFromHeaderWrapper = !!e.relatedTarget && !e.relatedTarget.classList.contains("ag-floating-filter");
    const fromWithinHeader = !!e.relatedTarget && _isElementChildOfClass(e.relatedTarget, "ag-floating-filter");
    if (notFromHeaderWrapper && fromWithinHeader && e.target === this.eGui) {
      const lastFocusEvent = this.lastFocusEvent;
      const fromTab = !!(lastFocusEvent && lastFocusEvent.key === KeyCode.TAB);
      if (lastFocusEvent && fromTab) {
        const shouldFocusLast = lastFocusEvent.shiftKey;
        this.focusService.focusInto(this.eGui, shouldFocusLast);
      }
    }
    const rowIndex = this.getRowIndex();
    this.beans.focusService.setFocusedHeader(rowIndex, this.column);
  }
  setupHover() {
    this.createManagedBean(new HoverFeature([this.column], this.eGui));
    const listener = () => {
      if (!this.gos.get("columnHoverHighlight")) {
        return;
      }
      const hovered = this.beans.columnHoverService.isHovered(this.column);
      this.comp.addOrRemoveCssClass("ag-column-hover", hovered);
    };
    this.addManagedEventListeners({ columnHoverChanged: listener });
    listener();
  }
  setupLeft() {
    const setLeftFeature = new SetLeftFeature(this.column, this.eGui, this.beans);
    this.createManagedBean(setLeftFeature);
  }
  setupFilterButton() {
    this.suppressFilterButton = !this.menuService.isFloatingFilterButtonEnabled(this.column);
    this.highlightFilterButtonWhenActive = !this.menuService.isLegacyMenuEnabled();
  }
  setupUserComp() {
    if (!this.active) {
      return;
    }
    const compDetails = this.beans.filterManager?.getFloatingFilterCompDetails(
      this.column,
      () => this.showParentFilter()
    );
    if (compDetails) {
      this.setCompDetails(compDetails);
    }
  }
  setCompDetails(compDetails) {
    this.userCompDetails = compDetails;
    this.comp.setCompDetails(compDetails);
  }
  showParentFilter() {
    const eventSource = this.suppressFilterButton ? this.eFloatingFilterBody : this.eButtonShowMainFilter;
    this.menuService.showFilterMenu({
      column: this.column,
      buttonElement: eventSource,
      containerType: "floatingFilter",
      positionBy: "button"
    });
  }
  setupSyncWithFilter() {
    if (!this.active) {
      return;
    }
    const { filterManager } = this.beans;
    const syncWithFilter = (event) => {
      if (event?.source === "filterDestroyed" && !this.isAlive()) {
        return;
      }
      const compPromise = this.comp.getFloatingFilterComp();
      if (!compPromise) {
        return;
      }
      compPromise.then((comp) => {
        if (comp) {
          const parentModel = filterManager?.getCurrentFloatingFilterParentModel(this.column);
          const filterChangedEvent = event ? {
            // event can have additional params like `afterDataChange` which need to be passed through
            ...event,
            columns: event.columns ?? [],
            source: event.source === "api" ? "api" : "columnFilter"
          } : null;
          comp.onParentModelChanged(parentModel, filterChangedEvent);
        }
      });
    };
    [this.destroySyncListener] = this.addManagedListeners(this.column, { filterChanged: syncWithFilter });
    if (filterManager?.isFilterActive(this.column)) {
      syncWithFilter(null);
    }
  }
  setupWidth() {
    const listener = () => {
      const width = `${this.column.getActualWidth()}px`;
      this.comp.setWidth(width);
    };
    this.addManagedListeners(this.column, { widthChanged: listener });
    listener();
  }
  setupFilterChangedListener() {
    if (this.active) {
      [this.destroyFilterChangedListener] = this.addManagedListeners(this.column, {
        filterChanged: this.updateFilterButton.bind(this)
      });
      this.updateFilterButton();
    }
  }
  updateFilterButton() {
    if (!this.suppressFilterButton && this.comp) {
      const isFilterAllowed = !!this.beans.filterManager?.isFilterAllowed(this.column);
      this.comp.setButtonWrapperDisplayed(isFilterAllowed);
      if (this.highlightFilterButtonWhenActive && isFilterAllowed) {
        this.eButtonShowMainFilter.classList.toggle("ag-filter-active", this.column.isFilterActive());
      }
    }
  }
  onColDefChanged() {
    const wasActive = this.active;
    this.setupActive();
    const becomeActive = !wasActive && this.active;
    if (wasActive && !this.active) {
      this.destroySyncListener();
      this.destroyFilterChangedListener();
    }
    const newCompDetails = this.active ? this.beans.filterManager?.getFloatingFilterCompDetails(this.column, () => this.showParentFilter()) : null;
    const compPromise = this.comp.getFloatingFilterComp();
    if (!compPromise || !newCompDetails) {
      this.updateCompDetails(newCompDetails, becomeActive);
    } else {
      compPromise.then((compInstance) => {
        if (!compInstance || this.beans.filterManager?.areFilterCompsDifferent(this.userCompDetails ?? null, newCompDetails)) {
          this.updateCompDetails(newCompDetails, becomeActive);
        } else {
          this.updateFloatingFilterParams(newCompDetails);
        }
      });
    }
  }
  updateCompDetails(compDetails, becomeActive) {
    if (!this.isAlive()) {
      return;
    }
    this.setCompDetails(compDetails);
    this.setupFilterButton();
    this.setupUi();
    if (becomeActive) {
      this.setupSyncWithFilter();
      this.setupFilterChangedListener();
    }
  }
  updateFloatingFilterParams(userCompDetails) {
    if (!userCompDetails) {
      return;
    }
    const params = userCompDetails.params;
    this.comp.getFloatingFilterComp()?.then((floatingFilter) => {
      let hasRefreshed = false;
      if (floatingFilter?.refresh && typeof floatingFilter.refresh === "function") {
        const result = floatingFilter.refresh(params);
        if (result !== null) {
          hasRefreshed = true;
        }
      }
      if (!hasRefreshed && floatingFilter?.onParamsUpdated && typeof floatingFilter.onParamsUpdated === "function") {
        const result = floatingFilter.onParamsUpdated(params);
        if (result !== null) {
          _warnOnce(`Custom floating filter method 'onParamsUpdated' is deprecated. Use 'refresh' instead.`);
        }
      }
    });
  }
  destroy() {
    super.destroy();
    this.eButtonShowMainFilter = null;
    this.eFloatingFilterBody = null;
    this.userCompDetails = null;
    this.destroySyncListener = null;
    this.destroyFilterChangedListener = null;
  }
};

// community-modules/core/src/version.ts
var VERSION = "32.0.0";

// community-modules/core/src/filter/columnFilterApi.ts
function isColumnFilterPresent(beans) {
  return !!beans.filterManager?.isColumnFilterPresent() || !!beans.filterManager?.isAggregateFilterPresent();
}
function getFilterInstance(beans, key, callback) {
  return beans.filterManager?.getFilterInstance(key, callback);
}
function getColumnFilterInstance(beans, key) {
  return beans.filterManager?.getColumnFilterInstance(key) ?? Promise.resolve(void 0);
}
function destroyFilter(beans, key) {
  const column = beans.columnModel.getColDefCol(key);
  if (column) {
    return beans.filterManager?.destroyFilter(column, "api");
  }
}
function setFilterModel(beans, model) {
  beans.frameworkOverrides.wrapIncoming(() => beans.filterManager?.setFilterModel(model));
}
function getFilterModel(beans) {
  return beans.filterManager?.getFilterModel() ?? {};
}
function getColumnFilterModel(beans, column) {
  return beans.filterManager?.getColumnFilterModel(column) ?? null;
}
function setColumnFilterModel(beans, column, model) {
  return beans.filterManager?.setColumnFilterModel(column, model) ?? Promise.resolve();
}
function showColumnFilter(beans, colKey) {
  const column = beans.columnModel.getCol(colKey);
  if (!column) {
    _errorOnce(`column '${colKey}' not found`);
    return;
  }
  beans.menuService.showFilterMenu({
    column,
    containerType: "columnFilter",
    positionBy: "auto"
  });
}

// community-modules/core/src/filter/floating/floatingFilterMapper.ts
function getDefaultFloatingFilterType(frameworkOverrides, def, getFromDefault) {
  if (def == null) {
    return null;
  }
  let defaultFloatingFilterType = null;
  const { compName, jsComp, fwComp } = UserComponentFactory.getCompKeys(frameworkOverrides, def, FilterComponent);
  if (compName) {
    const floatingFilterTypeMap = {
      set: "agSetColumnFloatingFilter",
      agSetColumnFilter: "agSetColumnFloatingFilter",
      multi: "agMultiColumnFloatingFilter",
      agMultiColumnFilter: "agMultiColumnFloatingFilter",
      group: "agGroupColumnFloatingFilter",
      agGroupColumnFilter: "agGroupColumnFloatingFilter",
      number: "agNumberColumnFloatingFilter",
      agNumberColumnFilter: "agNumberColumnFloatingFilter",
      date: "agDateColumnFloatingFilter",
      agDateColumnFilter: "agDateColumnFloatingFilter",
      text: "agTextColumnFloatingFilter",
      agTextColumnFilter: "agTextColumnFloatingFilter"
    };
    defaultFloatingFilterType = floatingFilterTypeMap[compName];
  } else {
    const usingDefaultFilter = jsComp == null && fwComp == null && def.filter === true;
    if (usingDefaultFilter) {
      defaultFloatingFilterType = getFromDefault();
    }
  }
  return defaultFloatingFilterType;
}

// community-modules/core/src/filter/columnFilterService.ts
var ColumnFilterService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "columnFilterService";
    this.allColumnFilters = /* @__PURE__ */ new Map();
    this.allColumnListeners = /* @__PURE__ */ new Map();
    this.activeAggregateFilters = [];
    this.activeColumnFilters = [];
    // this is true when the grid is processing the filter change. this is used by the cell comps, so that they
    // don't flash when data changes due to filter changes. there is no need to flash when filter changes as the
    // user is in control, so doesn't make sense to show flashing changes. for example, go to main demo where
    // this feature is turned off (hack code to always return false for isSuppressFlashingCellsBecauseFiltering(), put in)
    // 100,000 rows and group by country. then do some filtering. all the cells flash, which is silly.
    this.processingFilterChange = false;
    // when we're waiting for cell data types to be inferred, we need to defer filter model updates
    this.filterModelUpdateQueue = [];
    this.columnFilterModelUpdateQueue = [];
  }
  wireBeans(beans) {
    this.valueService = beans.valueService;
    this.columnModel = beans.columnModel;
    this.rowModel = beans.rowModel;
    this.userComponentFactory = beans.userComponentFactory;
    this.rowRenderer = beans.rowRenderer;
    this.dataTypeService = beans.dataTypeService;
    this.filterManager = beans.filterManager;
  }
  postConstruct() {
    this.addManagedEventListeners({
      gridColumnsChanged: this.onColumnsChanged.bind(this),
      rowDataUpdated: () => this.onNewRowsLoaded("rowDataUpdated"),
      dataTypesInferred: this.processFilterModelUpdateQueue.bind(this)
    });
    this.initialFilterModel = {
      ...this.gos.get("initialState")?.filter?.filterModel ?? {}
    };
  }
  setFilterModel(model, source = "api") {
    if (this.dataTypeService?.isPendingInference()) {
      this.filterModelUpdateQueue.push({ model, source });
      return;
    }
    const allPromises = [];
    const previousModel = this.getFilterModel();
    if (model) {
      const modelKeys = new Set(Object.keys(model));
      this.allColumnFilters.forEach((filterWrapper, colId) => {
        const newModel = model[colId];
        allPromises.push(this.setModelOnFilterWrapper(filterWrapper.filterPromise, newModel));
        modelKeys.delete(colId);
      });
      modelKeys.forEach((colId) => {
        const column = this.columnModel.getColDefCol(colId) || this.columnModel.getCol(colId);
        if (!column) {
          _warnOnce("setFilterModel() - no column found for colId: " + colId);
          return;
        }
        if (!column.isFilterAllowed()) {
          _warnOnce("setFilterModel() - unable to fully apply model, filtering disabled for colId: " + colId);
          return;
        }
        const filterWrapper = this.getOrCreateFilterWrapper(column);
        if (!filterWrapper) {
          _warnOnce(
            "setFilterModel() - unable to fully apply model, unable to create filter for colId: " + colId
          );
          return;
        }
        allPromises.push(this.setModelOnFilterWrapper(filterWrapper.filterPromise, model[colId]));
      });
    } else {
      this.allColumnFilters.forEach((filterWrapper) => {
        allPromises.push(this.setModelOnFilterWrapper(filterWrapper.filterPromise, null));
      });
    }
    AgPromise.all(allPromises).then(() => {
      const currentModel = this.getFilterModel();
      const columns = [];
      this.allColumnFilters.forEach((filterWrapper, colId) => {
        const before = previousModel ? previousModel[colId] : null;
        const after = currentModel ? currentModel[colId] : null;
        if (!_jsonEquals(before, after)) {
          columns.push(filterWrapper.column);
        }
      });
      if (columns.length > 0) {
        this.filterManager?.onFilterChanged({ columns, source });
      }
    });
  }
  setModelOnFilterWrapper(filterPromise, newModel) {
    return new AgPromise((resolve) => {
      filterPromise.then((filter) => {
        if (typeof filter.setModel !== "function") {
          _warnOnce("filter missing setModel method, which is needed for setFilterModel");
          resolve();
        }
        (filter.setModel(newModel) || AgPromise.resolve()).then(() => resolve());
      });
    });
  }
  getFilterModel() {
    const result = {};
    this.allColumnFilters.forEach((filterWrapper, key) => {
      const model = this.getModelFromFilterWrapper(filterWrapper);
      if (_exists(model)) {
        result[key] = model;
      }
    });
    return result;
  }
  getModelFromFilterWrapper(filterWrapper) {
    const { filter } = filterWrapper;
    if (filter) {
      if (typeof filter.getModel !== "function") {
        _warnOnce("filter API missing getModel method, which is needed for getFilterModel");
        return null;
      }
      return filter.getModel();
    } else {
      return this.getModelFromInitialState(filterWrapper.column);
    }
  }
  getModelFromInitialState(column) {
    return this.initialFilterModel[column.getColId()] ?? null;
  }
  isColumnFilterPresent() {
    return this.activeColumnFilters.length > 0;
  }
  isAggregateFilterPresent() {
    return !!this.activeAggregateFilters.length;
  }
  disableColumnFilters() {
    if (this.allColumnFilters.size) {
      this.allColumnFilters.forEach(
        (filterWrapper) => this.disposeFilterWrapper(filterWrapper, "advancedFilterEnabled")
      );
      return true;
    }
    return false;
  }
  doAggregateFiltersPass(node, filterToSkip) {
    return this.doColumnFiltersPass(node, filterToSkip, true);
  }
  updateActiveFilters() {
    const isFilterActive = (filter) => {
      if (!filter) {
        return false;
      }
      if (!filter.isFilterActive) {
        _warnOnce("Filter is missing isFilterActive() method");
        return false;
      }
      return filter.isFilterActive();
    };
    const groupFilterEnabled = !!this.gos.getGroupAggFiltering();
    const isAggFilter = (column) => {
      const isSecondary = !column.isPrimary();
      if (isSecondary) {
        return true;
      }
      const isShowingPrimaryColumns = !this.columnModel.isPivotActive();
      const isValueActive = column.isValueActive();
      if (!isValueActive || !isShowingPrimaryColumns) {
        return false;
      }
      if (this.columnModel.isPivotMode()) {
        return true;
      }
      return groupFilterEnabled;
    };
    const activeAggregateFilters = [];
    const activeColumnFilters = [];
    return this.forEachColumnFilter((filter, filterWrapper) => {
      const filterActive = isFilterActive(filter);
      if (filterActive) {
        if (isAggFilter(filterWrapper.column)) {
          activeAggregateFilters.push(filter);
        } else {
          activeColumnFilters.push(filter);
        }
      }
    }).then(() => {
      this.activeAggregateFilters = activeAggregateFilters;
      this.activeColumnFilters = activeColumnFilters;
    });
  }
  updateFilterFlagInColumns(source, additionalEventAttributes) {
    return this.forEachColumnFilter(
      (filter, filterWrapper) => filterWrapper.column.setFilterActive(filter.isFilterActive(), source, additionalEventAttributes)
    );
  }
  forEachColumnFilter(callback) {
    return AgPromise.all(
      Array.from(this.allColumnFilters.values()).map(
        (filterWrapper) => filterWrapper.filterPromise.then((filter) => callback(filter, filterWrapper))
      )
    );
  }
  doColumnFiltersPass(node, filterToSkip, targetAggregates) {
    const { data, aggData } = node;
    const targetedFilters = targetAggregates ? this.activeAggregateFilters : this.activeColumnFilters;
    const targetedData = targetAggregates ? aggData : data;
    for (let i = 0; i < targetedFilters.length; i++) {
      const filter = targetedFilters[i];
      if (filter == null || filter === filterToSkip) {
        continue;
      }
      if (typeof filter.doesFilterPass !== "function") {
        throw new Error("Filter is missing method doesFilterPass");
      }
      if (!filter.doesFilterPass({ node, data: targetedData })) {
        return false;
      }
    }
    return true;
  }
  // sometimes (especially in React) the filter can call onFilterChanged when we are in the middle
  // of a render cycle. this would be bad, so we wait for render cycle to complete when this happens.
  // this happens in react when we change React State in the grid (eg setting RowCtrl's in RowContainer)
  // which results in React State getting applied in the main application, triggering a useEffect() to
  // be kicked off adn then the application calling the grid's API. in AG-6554, the custom filter was
  // getting it's useEffect() triggered in this way.
  callOnFilterChangedOutsideRenderCycle(params) {
    const action = () => this.filterManager?.onFilterChanged(params);
    if (this.rowRenderer.isRefreshInProgress()) {
      setTimeout(action, 0);
    } else {
      action();
    }
  }
  updateBeforeFilterChanged(params = {}) {
    const { filterInstance, additionalEventAttributes } = params;
    this.updateDependentFilters();
    return this.updateActiveFilters().then(
      () => this.updateFilterFlagInColumns("filterChanged", additionalEventAttributes).then(() => {
        this.allColumnFilters.forEach((filterWrapper) => {
          if (!filterWrapper.filterPromise) {
            return;
          }
          filterWrapper.filterPromise.then((filter) => {
            if (filter && filter !== filterInstance && filter.onAnyFilterChanged) {
              filter.onAnyFilterChanged();
            }
          });
        });
        this.processingFilterChange = true;
      })
    );
  }
  updateAfterFilterChanged() {
    this.processingFilterChange = false;
  }
  isSuppressFlashingCellsBecauseFiltering() {
    const allowShowChangeAfterFilter = this.gos.get("allowShowChangeAfterFilter") ?? false;
    return !allowShowChangeAfterFilter && this.processingFilterChange;
  }
  onNewRowsLoaded(source) {
    this.forEachColumnFilter((filter) => {
      if (filter.onNewRowsLoaded) {
        filter.onNewRowsLoaded();
      }
    }).then(() => this.updateFilterFlagInColumns(source, { afterDataChange: true })).then(() => this.updateActiveFilters());
  }
  createValueGetter(column) {
    return ({ node }) => this.valueService.getValue(column, node, true);
  }
  createGetValue(filterColumn) {
    return (rowNode, column) => {
      const columnToUse = column ? this.columnModel.getCol(column) : filterColumn;
      return columnToUse ? this.valueService.getValue(columnToUse, rowNode, true) : void 0;
    };
  }
  isFilterActive(column) {
    const { filter } = this.cachedFilter(column) ?? {};
    if (filter) {
      return filter.isFilterActive();
    }
    return this.getModelFromInitialState(column) != null;
  }
  getOrCreateFilterWrapper(column) {
    if (!column.isFilterAllowed()) {
      return null;
    }
    let filterWrapper = this.cachedFilter(column);
    if (!filterWrapper) {
      filterWrapper = this.createFilterWrapper(column);
      this.setColumnFilterWrapper(column, filterWrapper);
    }
    return filterWrapper;
  }
  cachedFilter(column) {
    return this.allColumnFilters.get(column.getColId());
  }
  getDefaultFilter(column) {
    let defaultFilter;
    if (ModuleRegistry.__isRegistered("@ag-grid-enterprise/set-filter" /* SetFilterModule */, this.gridId)) {
      defaultFilter = "agSetColumnFilter";
    } else {
      const cellDataType = this.dataTypeService?.getBaseDataType(column);
      if (cellDataType === "number") {
        defaultFilter = "agNumberColumnFilter";
      } else if (cellDataType === "date" || cellDataType === "dateString") {
        defaultFilter = "agDateColumnFilter";
      } else {
        defaultFilter = "agTextColumnFilter";
      }
    }
    return defaultFilter;
  }
  getDefaultFloatingFilter(column) {
    let defaultFloatingFilterType;
    if (ModuleRegistry.__isRegistered("@ag-grid-enterprise/set-filter" /* SetFilterModule */, this.gridId)) {
      defaultFloatingFilterType = "agSetColumnFloatingFilter";
    } else {
      const cellDataType = this.dataTypeService?.getBaseDataType(column);
      if (cellDataType === "number") {
        defaultFloatingFilterType = "agNumberColumnFloatingFilter";
      } else if (cellDataType === "date" || cellDataType === "dateString") {
        defaultFloatingFilterType = "agDateColumnFloatingFilter";
      } else {
        defaultFloatingFilterType = "agTextColumnFloatingFilter";
      }
    }
    return defaultFloatingFilterType;
  }
  createFilterInstance(column, filterWrapper) {
    const defaultFilter = this.getDefaultFilter(column);
    const colDef = column.getColDef();
    let filterInstance;
    const params = {
      ...this.createFilterParams(column, colDef),
      filterModifiedCallback: () => this.filterModifiedCallbackFactory(filterInstance, column)(),
      filterChangedCallback: (additionalEventAttributes) => this.filterChangedCallbackFactory(filterInstance, column)(additionalEventAttributes),
      doesRowPassOtherFilter: (node) => this.filterManager ? this.filterManager.doesRowPassOtherFilters(filterInstance, node) : true
    };
    const compDetails = this.userComponentFactory.getFilterDetails(colDef, params, defaultFilter);
    if (!compDetails) {
      return { filterPromise: null, compDetails: null };
    }
    return {
      filterPromise: () => {
        const filterPromise = compDetails.newAgStackInstance();
        if (filterPromise != null) {
          filterPromise.then((r) => {
            filterInstance = r;
            if (filterWrapper) {
              filterWrapper.filter = r;
            }
          });
        }
        return filterPromise;
      },
      compDetails
    };
  }
  createFilterParams(column, colDef) {
    const params = this.gos.addGridCommonParams({
      column,
      colDef: _cloneObject(colDef),
      rowModel: this.rowModel,
      filterChangedCallback: () => {
      },
      filterModifiedCallback: () => {
      },
      valueGetter: this.createValueGetter(column),
      getValue: this.createGetValue(column),
      doesRowPassOtherFilter: () => true
    });
    return params;
  }
  createFilterWrapper(column) {
    const filterWrapper = {
      column,
      filterPromise: null,
      compiledElement: null,
      compDetails: null
    };
    const { filterPromise, compDetails } = this.createFilterInstance(column, filterWrapper);
    filterWrapper.filterPromise = filterPromise?.() ?? null;
    filterWrapper.compDetails = compDetails;
    return filterWrapper;
  }
  onColumnsChanged() {
    const columns = [];
    this.allColumnFilters.forEach((wrapper, colId) => {
      let currentColumn;
      if (wrapper.column.isPrimary()) {
        currentColumn = this.columnModel.getColDefCol(colId);
      } else {
        currentColumn = this.columnModel.getCol(colId);
      }
      if (currentColumn && currentColumn === wrapper.column) {
        return;
      }
      columns.push(wrapper.column);
      this.disposeFilterWrapper(wrapper, "columnChanged");
      this.disposeColumnListener(colId);
    });
    if (columns.length > 0) {
      this.filterManager?.onFilterChanged({ columns, source: "api" });
    } else {
      this.updateDependentFilters();
    }
  }
  updateDependentFilters() {
    const groupColumns = this.columnModel.getAutoCols();
    groupColumns?.forEach((groupColumn) => {
      if (groupColumn.getColDef().filter === "agGroupColumnFilter") {
        this.getOrCreateFilterWrapper(groupColumn);
      }
    });
  }
  // for group filters, can change dynamically whether they are allowed or not
  isFilterAllowed(column) {
    const isFilterAllowed = column.isFilterAllowed();
    if (!isFilterAllowed) {
      return false;
    }
    const { filter } = this.allColumnFilters.get(column.getColId()) ?? {};
    if (filter) {
      return typeof filter?.isFilterAllowed === "function" ? filter.isFilterAllowed() : true;
    }
    return true;
  }
  getFloatingFilterCompDetails(column, showParentFilter) {
    const parentFilterInstance = (callback) => {
      const filterComponent = this.getOrCreateFilterWrapper(column)?.filterPromise;
      if (filterComponent == null) {
        return;
      }
      filterComponent.then((instance) => {
        callback(_unwrapUserComp(instance));
      });
    };
    const colDef = column.getColDef();
    const filterParams = {
      ...this.createFilterParams(column, colDef),
      filterChangedCallback: () => parentFilterInstance(
        (filterInstance) => this.filterChangedCallbackFactory(filterInstance, column)()
      )
    };
    const finalFilterParams = this.userComponentFactory.mergeParamsWithApplicationProvidedParams(
      colDef,
      FilterComponent,
      filterParams
    );
    let defaultFloatingFilterType = getDefaultFloatingFilterType(
      this.frameworkOverrides,
      colDef,
      () => this.getDefaultFloatingFilter(column)
    );
    if (defaultFloatingFilterType == null) {
      defaultFloatingFilterType = "agReadOnlyFloatingFilter";
    }
    const params = {
      column,
      filterParams: finalFilterParams,
      currentParentModel: () => this.getCurrentFloatingFilterParentModel(column),
      parentFilterInstance,
      showParentFilter,
      suppressFilterButton: false
      // This one might be overridden from the colDef
    };
    return this.userComponentFactory.getFloatingFilterCompDetails(colDef, params, defaultFloatingFilterType);
  }
  getCurrentFloatingFilterParentModel(column) {
    return this.getModelFromFilterWrapper(this.cachedFilter(column) ?? { column });
  }
  // destroys the filter, so it no longer takes part
  destroyFilter(column, source = "api") {
    const colId = column.getColId();
    const filterWrapper = this.allColumnFilters.get(colId);
    this.disposeColumnListener(colId);
    delete this.initialFilterModel[colId];
    if (filterWrapper) {
      this.disposeFilterWrapper(filterWrapper, source);
      this.filterManager?.onFilterChanged({
        columns: [column],
        source: "api"
      });
    }
  }
  disposeColumnListener(colId) {
    const columnListener = this.allColumnListeners.get(colId);
    if (columnListener) {
      this.allColumnListeners.delete(colId);
      columnListener();
    }
  }
  disposeFilterWrapper(filterWrapper, source) {
    filterWrapper.filterPromise.then((filter) => {
      this.destroyBean(filter);
      filterWrapper.column.setFilterActive(false, "filterDestroyed");
      this.allColumnFilters.delete(filterWrapper.column.getColId());
      const event = {
        type: "filterDestroyed",
        source,
        column: filterWrapper.column
      };
      this.eventService.dispatchEvent(event);
    });
  }
  filterModifiedCallbackFactory(filter, column) {
    return () => {
      const event = {
        type: "filterModified",
        column,
        filterInstance: filter
      };
      this.eventService.dispatchEvent(event);
    };
  }
  filterChangedCallbackFactory(filter, column) {
    return (additionalEventAttributes) => {
      const source = additionalEventAttributes?.source ?? "columnFilter";
      const params = {
        filter,
        additionalEventAttributes,
        columns: [column],
        source
      };
      this.callOnFilterChangedOutsideRenderCycle(params);
    };
  }
  checkDestroyFilter(colId) {
    const filterWrapper = this.allColumnFilters.get(colId);
    if (!filterWrapper) {
      return;
    }
    const column = filterWrapper.column;
    const { compDetails } = column.isFilterAllowed() ? this.createFilterInstance(column) : { compDetails: null };
    if (this.areFilterCompsDifferent(filterWrapper.compDetails, compDetails)) {
      this.destroyFilter(column, "paramsUpdated");
      return;
    }
    const newFilterParams = column.getColDef().filterParams;
    if (!filterWrapper.filterPromise) {
      this.destroyFilter(column, "paramsUpdated");
      return;
    }
    filterWrapper.filterPromise.then((filter) => {
      const shouldRefreshFilter = filter?.refresh ? filter.refresh({
        ...this.createFilterParams(column, column.getColDef()),
        filterModifiedCallback: this.filterModifiedCallbackFactory(filter, column),
        filterChangedCallback: this.filterChangedCallbackFactory(filter, column),
        doesRowPassOtherFilter: (node) => this.filterManager ? this.filterManager.doesRowPassOtherFilters(filter, node) : true,
        ...newFilterParams
      }) : true;
      if (shouldRefreshFilter === false) {
        this.destroyFilter(column, "paramsUpdated");
      }
    });
  }
  setColumnFilterWrapper(column, filterWrapper) {
    const colId = column.getColId();
    this.allColumnFilters.set(colId, filterWrapper);
    this.allColumnListeners.set(
      colId,
      this.addManagedListeners(column, { colDefChanged: () => this.checkDestroyFilter(colId) })[0]
    );
  }
  areFilterCompsDifferent(oldCompDetails, newCompDetails) {
    if (!newCompDetails || !oldCompDetails) {
      return true;
    }
    const { componentClass: oldComponentClass } = oldCompDetails;
    const { componentClass: newComponentClass } = newCompDetails;
    const isSameComponentClass = oldComponentClass === newComponentClass || // react hooks returns new wrappers, so check nested render method
    oldComponentClass?.render && newComponentClass?.render && oldComponentClass.render === newComponentClass.render;
    return !isSameComponentClass;
  }
  hasFloatingFilters() {
    const gridColumns = this.columnModel.getCols();
    return gridColumns.some((col) => col.getColDef().floatingFilter);
  }
  getFilterInstance(key, callback) {
    if (!callback) {
      return void 0;
    }
    this.getFilterInstanceImpl(key).then((filter) => {
      const unwrapped = _unwrapUserComp(filter);
      callback(unwrapped);
    });
    return void 0;
  }
  getColumnFilterInstance(key) {
    return new Promise((resolve) => {
      this.getFilterInstanceImpl(key).then((filter) => {
        resolve(_unwrapUserComp(filter));
      });
    });
  }
  getFilterInstanceImpl(key) {
    const column = this.columnModel.getColDefCol(key);
    if (!column) {
      return AgPromise.resolve(void 0);
    }
    const filterPromise = this.getOrCreateFilterWrapper(column)?.filterPromise;
    return filterPromise ?? AgPromise.resolve(null);
  }
  processFilterModelUpdateQueue() {
    this.filterModelUpdateQueue.forEach(({ model, source }) => this.setFilterModel(model, source));
    this.filterModelUpdateQueue = [];
    this.columnFilterModelUpdateQueue.forEach(({ key, model, resolve }) => {
      this.setColumnFilterModel(key, model).then(() => resolve());
    });
    this.columnFilterModelUpdateQueue = [];
  }
  getColumnFilterModel(key) {
    const filterWrapper = this.getFilterWrapper(key);
    return filterWrapper ? this.getModelFromFilterWrapper(filterWrapper) : null;
  }
  setColumnFilterModel(key, model) {
    if (this.dataTypeService?.isPendingInference()) {
      let resolve = () => {
      };
      const promise = new Promise((res) => {
        resolve = res;
      });
      this.columnFilterModelUpdateQueue.push({ key, model, resolve });
      return promise;
    }
    const column = this.columnModel.getColDefCol(key);
    const filterWrapper = column ? this.getOrCreateFilterWrapper(column) : null;
    const convertPromise = (promise) => {
      return new Promise((resolve) => {
        promise.then((result) => resolve(result));
      });
    };
    return filterWrapper ? convertPromise(this.setModelOnFilterWrapper(filterWrapper.filterPromise, model)) : Promise.resolve();
  }
  getFilterWrapper(key) {
    const column = this.columnModel.getColDefCol(key);
    return column ? this.cachedFilter(column) ?? null : null;
  }
  destroy() {
    super.destroy();
    this.allColumnFilters.forEach((filterWrapper) => this.disposeFilterWrapper(filterWrapper, "gridDestroyed"));
    this.allColumnListeners.clear();
  }
};

// community-modules/core/src/filter/filterApi.ts
function isAnyFilterPresent(beans) {
  return !!beans.filterManager?.isAnyFilterPresent();
}
function onFilterChanged(beans, source = "api") {
  beans.filterManager?.onFilterChanged({ source });
}

// community-modules/core/src/filter/floating/provided/readOnlyFloatingFilter.ts
var ReadOnlyFloatingFilter = class extends Component {
  constructor() {
    super(
      /* html */
      `
            <div class="ag-floating-filter-input" role="presentation">
                <ag-input-text-field data-ref="eFloatingFilterText"></ag-input-text-field>
            </div>`,
      [AgInputTextFieldSelector]
    );
    this.eFloatingFilterText = RefPlaceholder;
  }
  wireBeans(beans) {
    this.columnNameService = beans.columnNameService;
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
  init(params) {
    this.params = params;
    const displayName = this.columnNameService.getDisplayNameForColumn(params.column, "header", true);
    const translate = this.localeService.getLocaleTextFunc();
    this.eFloatingFilterText.setDisabled(true).setInputAriaLabel(`${displayName} ${translate("ariaFilterInput", "Filter Input")}`);
  }
  onParentModelChanged(parentModel) {
    if (parentModel == null) {
      this.eFloatingFilterText.setValue("");
      return;
    }
    this.params.parentFilterInstance((filterInstance) => {
      if (filterInstance.getModelAsString) {
        const modelAsString = filterInstance.getModelAsString(parentModel);
        this.eFloatingFilterText.setValue(modelAsString);
      }
    });
  }
  onParamsUpdated(params) {
    this.refresh(params);
  }
  refresh(params) {
    this.init(params);
  }
};

// community-modules/core/src/filter/floating/provided/simpleFloatingFilter.ts
var SimpleFloatingFilter = class extends Component {
  wireBeans(beans) {
    this.columnNameService = beans.columnNameService;
  }
  getDefaultDebounceMs() {
    return 0;
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
  isEventFromFloatingFilter(event) {
    return event && event.afterFloatingFilter;
  }
  isEventFromDataChange(event) {
    return event?.afterDataChange;
  }
  getLastType() {
    return this.lastType;
  }
  isReadOnly() {
    return this.readOnly;
  }
  setLastTypeFromModel(model) {
    if (!model) {
      this.lastType = this.optionsFactory.getDefaultOption();
      return;
    }
    const isCombined = model.operator;
    let condition;
    if (isCombined) {
      const combinedModel = model;
      condition = combinedModel.conditions[0];
    } else {
      condition = model;
    }
    this.lastType = condition.type;
  }
  canWeEditAfterModelFromParentFilter(model) {
    if (!model) {
      return this.isTypeEditable(this.lastType);
    }
    const isCombined = model.operator;
    if (isCombined) {
      return false;
    }
    const simpleModel = model;
    return this.isTypeEditable(simpleModel.type);
  }
  init(params) {
    this.setSimpleParams(params, false);
  }
  setSimpleParams(params, update = true) {
    this.optionsFactory = new OptionsFactory();
    this.optionsFactory.init(params.filterParams, this.getDefaultFilterOptions());
    if (!update) {
      this.lastType = this.optionsFactory.getDefaultOption();
    }
    this.readOnly = !!params.filterParams.readOnly;
    const editable = this.isTypeEditable(this.optionsFactory.getDefaultOption());
    this.setEditable(editable);
  }
  onParamsUpdated(params) {
    this.refresh(params);
  }
  refresh(params) {
    this.setSimpleParams(params);
  }
  doesFilterHaveSingleInput(filterType) {
    const customFilterOption = this.optionsFactory.getCustomOption(filterType);
    const { numberOfInputs } = customFilterOption || {};
    return numberOfInputs == null || numberOfInputs == 1;
  }
  isTypeEditable(type) {
    const uneditableTypes = ["inRange", "empty", "blank", "notBlank"];
    return !!type && !this.isReadOnly() && this.doesFilterHaveSingleInput(type) && uneditableTypes.indexOf(type) < 0;
  }
  getAriaLabel(params) {
    const displayName = this.columnNameService.getDisplayNameForColumn(params.column, "header", true);
    const translate = this.localeService.getLocaleTextFunc();
    return `${displayName} ${translate("ariaFilterInput", "Filter Input")}`;
  }
};

// community-modules/core/src/filter/provided/date/dateFloatingFilter.ts
var DateFloatingFilter = class extends SimpleFloatingFilter {
  constructor() {
    super(
      /* html */
      `
            <div class="ag-floating-filter-input" role="presentation">
                <ag-input-text-field data-ref="eReadOnlyText"></ag-input-text-field>
                <div data-ref="eDateWrapper" style="display: flex;"></div>
            </div>`,
      [AgInputTextFieldSelector]
    );
    this.eReadOnlyText = RefPlaceholder;
    this.eDateWrapper = RefPlaceholder;
  }
  wireBeans(beans) {
    super.wireBeans(beans);
    this.context = beans.context;
    this.userComponentFactory = beans.userComponentFactory;
  }
  getDefaultFilterOptions() {
    return DEFAULT_DATE_FILTER_OPTIONS;
  }
  init(params) {
    super.init(params);
    this.params = params;
    this.filterParams = params.filterParams;
    this.createDateComponent();
    this.filterModelFormatter = new DateFilterModelFormatter(
      this.filterParams,
      this.localeService,
      this.optionsFactory
    );
    const translate = this.localeService.getLocaleTextFunc();
    this.eReadOnlyText.setDisabled(true).setInputAriaLabel(translate("ariaDateFilterInput", "Date Filter Input"));
  }
  onParamsUpdated(params) {
    this.refresh(params);
  }
  refresh(params) {
    super.refresh(params);
    this.params = params;
    this.filterParams = params.filterParams;
    this.updateDateComponent();
    this.filterModelFormatter.updateParams({
      optionsFactory: this.optionsFactory,
      dateFilterParams: this.filterParams
    });
    this.updateCompOnModelChange(params.currentParentModel());
  }
  updateCompOnModelChange(model) {
    const allowEditing = !this.isReadOnly() && this.canWeEditAfterModelFromParentFilter(model);
    this.setEditable(allowEditing);
    if (allowEditing) {
      if (model) {
        const dateModel = model;
        this.dateComp.setDate(_parseDateTimeFromString(dateModel.dateFrom));
      } else {
        this.dateComp.setDate(null);
      }
      this.eReadOnlyText.setValue("");
    } else {
      this.eReadOnlyText.setValue(this.filterModelFormatter.getModelAsString(model));
      this.dateComp.setDate(null);
    }
  }
  setEditable(editable) {
    _setDisplayed(this.eDateWrapper, editable);
    _setDisplayed(this.eReadOnlyText.getGui(), !editable);
  }
  onParentModelChanged(model, event) {
    if (this.isEventFromFloatingFilter(event) || this.isEventFromDataChange(event)) {
      return;
    }
    super.setLastTypeFromModel(model);
    this.updateCompOnModelChange(model);
  }
  onDateChanged() {
    const filterValueDate = this.dateComp.getDate();
    const filterValueText = _serialiseDate(filterValueDate);
    this.params.parentFilterInstance((filterInstance) => {
      if (filterInstance) {
        const date = _parseDateTimeFromString(filterValueText);
        filterInstance.onFloatingFilterChanged(this.getLastType() || null, date);
      }
    });
  }
  getDateComponentParams() {
    const debounceMs = getDebounceMs(this.params.filterParams, this.getDefaultDebounceMs());
    return {
      onDateChanged: _debounce(this.onDateChanged.bind(this), debounceMs),
      filterParams: this.params.column.getColDef().filterParams
    };
  }
  createDateComponent() {
    this.dateComp = new DateCompWrapper(
      this.context,
      this.userComponentFactory,
      this.getDateComponentParams(),
      this.eDateWrapper,
      (dateComp) => {
        dateComp.setInputAriaLabel(this.getAriaLabel(this.params));
      }
    );
    this.addDestroyFunc(() => this.dateComp.destroy());
  }
  updateDateComponent() {
    const params = this.gos.addGridCommonParams(this.getDateComponentParams());
    this.dateComp.updateParams(params);
  }
  getFilterModelFormatter() {
    return this.filterModelFormatter;
  }
};

// community-modules/core/src/filter/provided/date/defaultDateComponent.ts
var DefaultDateComponent = class extends Component {
  constructor() {
    super(
      /* html */
      `
            <div class="ag-filter-filter">
                <ag-input-text-field class="ag-date-filter" data-ref="eDateInput"></ag-input-text-field>
            </div>`,
      [AgInputTextFieldSelector]
    );
    this.eDateInput = RefPlaceholder;
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
  init(params) {
    this.params = params;
    this.setParams(params);
    const inputElement = this.eDateInput.getInputElement();
    this.addManagedListeners(inputElement, {
      // ensures that the input element is focussed when a clear button is clicked,
      // unless using safari as there is no clear button and focus does not work properly
      mouseDown: () => {
        if (this.eDateInput.isDisabled() || this.usingSafariDatePicker) {
          return;
        }
        inputElement.focus();
      },
      input: (e) => {
        if (e.target !== this.gos.getActiveDomElement()) {
          return;
        }
        if (this.eDateInput.isDisabled()) {
          return;
        }
        this.params.onDateChanged();
      }
    });
  }
  setParams(params) {
    const inputElement = this.eDateInput.getInputElement();
    const shouldUseBrowserDatePicker = this.shouldUseBrowserDatePicker(params);
    this.usingSafariDatePicker = shouldUseBrowserDatePicker && _isBrowserSafari();
    inputElement.type = shouldUseBrowserDatePicker ? "date" : "text";
    const { minValidYear, maxValidYear, minValidDate, maxValidDate } = params.filterParams || {};
    if (minValidDate && minValidYear) {
      _warnOnce(
        "DateFilter should not have both minValidDate and minValidYear parameters set at the same time! minValidYear will be ignored."
      );
    }
    if (maxValidDate && maxValidYear) {
      _warnOnce(
        "DateFilter should not have both maxValidDate and maxValidYear parameters set at the same time! maxValidYear will be ignored."
      );
    }
    if (minValidDate && maxValidDate) {
      const [parsedMinValidDate, parsedMaxValidDate] = [minValidDate, maxValidDate].map(
        (v) => v instanceof Date ? v : _parseDateTimeFromString(v)
      );
      if (parsedMinValidDate && parsedMaxValidDate && parsedMinValidDate.getTime() > parsedMaxValidDate.getTime()) {
        _warnOnce(
          "DateFilter parameter minValidDate should always be lower than or equal to parameter maxValidDate."
        );
      }
    }
    if (minValidDate) {
      if (minValidDate instanceof Date) {
        inputElement.min = _dateToFormattedString(minValidDate);
      } else {
        inputElement.min = minValidDate;
      }
    } else {
      if (minValidYear) {
        inputElement.min = `${minValidYear}-01-01`;
      }
    }
    if (maxValidDate) {
      if (maxValidDate instanceof Date) {
        inputElement.max = _dateToFormattedString(maxValidDate);
      } else {
        inputElement.max = maxValidDate;
      }
    } else {
      if (maxValidYear) {
        inputElement.max = `${maxValidYear}-12-31`;
      }
    }
  }
  onParamsUpdated(params) {
    this.refresh(params);
  }
  refresh(params) {
    this.params = params;
    this.setParams(params);
  }
  getDate() {
    return _parseDateTimeFromString(this.eDateInput.getValue());
  }
  setDate(date) {
    this.eDateInput.setValue(_serialiseDate(date, false));
  }
  setInputPlaceholder(placeholder) {
    this.eDateInput.setInputPlaceholder(placeholder);
  }
  setInputAriaLabel(ariaLabel) {
    this.eDateInput.setAriaLabel(ariaLabel);
  }
  setDisabled(disabled) {
    this.eDateInput.setDisabled(disabled);
  }
  afterGuiAttached(params) {
    if (!params || !params.suppressFocus) {
      this.eDateInput.getInputElement().focus();
    }
  }
  shouldUseBrowserDatePicker(params) {
    if (params.filterParams && params.filterParams.browserDatePicker != null) {
      return params.filterParams.browserDatePicker;
    }
    return _isBrowserChrome() || _isBrowserFirefox() || _isBrowserSafari() && _getSafariVersion() >= 14.1;
  }
};

// community-modules/core/src/filter/floating/provided/floatingFilterTextInputService.ts
var FloatingFilterTextInputService = class extends BeanStub {
  constructor(params) {
    super();
    this.params = params;
    this.eFloatingFilterTextInput = RefPlaceholder;
    this.valueChangedListener = () => {
    };
  }
  setupGui(parentElement) {
    this.eFloatingFilterTextInput = this.createManagedBean(new AgInputTextField(this.params?.config));
    const eInput = this.eFloatingFilterTextInput.getGui();
    parentElement.appendChild(eInput);
    const listener = (e) => this.valueChangedListener(e);
    this.addManagedListeners(eInput, {
      input: listener,
      keydown: listener
    });
  }
  setEditable(editable) {
    this.eFloatingFilterTextInput.setDisabled(!editable);
  }
  setAutoComplete(autoComplete) {
    this.eFloatingFilterTextInput.setAutoComplete(autoComplete);
  }
  getValue() {
    return this.eFloatingFilterTextInput.getValue();
  }
  setValue(value, silent) {
    this.eFloatingFilterTextInput.setValue(value, silent);
  }
  setValueChangedListener(listener) {
    this.valueChangedListener = listener;
  }
  setParams(params) {
    this.setAriaLabel(params.ariaLabel);
    if (params.autoComplete !== void 0) {
      this.setAutoComplete(params.autoComplete);
    }
  }
  setAriaLabel(ariaLabel) {
    this.eFloatingFilterTextInput.setInputAriaLabel(ariaLabel);
  }
};

// community-modules/core/src/filter/floating/provided/textInputFloatingFilter.ts
var TextInputFloatingFilter = class extends SimpleFloatingFilter {
  constructor() {
    super(...arguments);
    this.eFloatingFilterInputContainer = RefPlaceholder;
  }
  postConstruct() {
    this.setTemplate(
      /* html */
      `
            <div class="ag-floating-filter-input" role="presentation" data-ref="eFloatingFilterInputContainer"></div>
        `
    );
  }
  getDefaultDebounceMs() {
    return 500;
  }
  onParentModelChanged(model, event) {
    if (this.isEventFromFloatingFilter(event) || this.isEventFromDataChange(event)) {
      return;
    }
    this.setLastTypeFromModel(model);
    this.setEditable(this.canWeEditAfterModelFromParentFilter(model));
    this.floatingFilterInputService.setValue(this.getFilterModelFormatter().getModelAsString(model));
  }
  init(params) {
    this.setupFloatingFilterInputService(params);
    super.init(params);
    this.setTextInputParams(params);
  }
  setupFloatingFilterInputService(params) {
    this.floatingFilterInputService = this.createFloatingFilterInputService(params);
    this.floatingFilterInputService.setupGui(this.eFloatingFilterInputContainer);
  }
  setTextInputParams(params) {
    this.params = params;
    const autoComplete = params.browserAutoComplete ?? false;
    this.floatingFilterInputService.setParams({
      ariaLabel: this.getAriaLabel(params),
      autoComplete
    });
    this.applyActive = isUseApplyButton(this.params.filterParams);
    if (!this.isReadOnly()) {
      const debounceMs = getDebounceMs(this.params.filterParams, this.getDefaultDebounceMs());
      const toDebounce = _debounce(
        this.syncUpWithParentFilter.bind(this),
        debounceMs
      );
      this.floatingFilterInputService.setValueChangedListener(toDebounce);
    }
  }
  onParamsUpdated(params) {
    this.refresh(params);
  }
  refresh(params) {
    super.refresh(params);
    this.setTextInputParams(params);
  }
  recreateFloatingFilterInputService(params) {
    const value = this.floatingFilterInputService.getValue();
    _clearElement(this.eFloatingFilterInputContainer);
    this.destroyBean(this.floatingFilterInputService);
    this.setupFloatingFilterInputService(params);
    this.floatingFilterInputService.setValue(value, true);
  }
  syncUpWithParentFilter(e) {
    const isEnterKey = e.key === KeyCode.ENTER;
    if (this.applyActive && !isEnterKey) {
      return;
    }
    let value = this.floatingFilterInputService.getValue();
    if (this.params.filterParams.trimInput) {
      value = trimInputForFilter(value);
      this.floatingFilterInputService.setValue(value, true);
    }
    this.params.parentFilterInstance((filterInstance) => {
      if (filterInstance) {
        filterInstance.onFloatingFilterChanged(this.getLastType() || null, value || null);
      }
    });
  }
  setEditable(editable) {
    this.floatingFilterInputService.setEditable(editable);
  }
};

// community-modules/core/src/filter/provided/number/numberFloatingFilter.ts
var FloatingFilterNumberInputService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.valueChangedListener = () => {
    };
    this.numberInputActive = true;
  }
  setupGui(parentElement) {
    this.eFloatingFilterNumberInput = this.createManagedBean(new AgInputNumberField());
    this.eFloatingFilterTextInput = this.createManagedBean(new AgInputTextField());
    this.eFloatingFilterTextInput.setDisabled(true);
    const eNumberInput = this.eFloatingFilterNumberInput.getGui();
    const eTextInput = this.eFloatingFilterTextInput.getGui();
    parentElement.appendChild(eNumberInput);
    parentElement.appendChild(eTextInput);
    this.setupListeners(eNumberInput, (e) => this.valueChangedListener(e));
    this.setupListeners(eTextInput, (e) => this.valueChangedListener(e));
  }
  setEditable(editable) {
    this.numberInputActive = editable;
    this.eFloatingFilterNumberInput.setDisplayed(this.numberInputActive);
    this.eFloatingFilterTextInput.setDisplayed(!this.numberInputActive);
  }
  setAutoComplete(autoComplete) {
    this.eFloatingFilterNumberInput.setAutoComplete(autoComplete);
    this.eFloatingFilterTextInput.setAutoComplete(autoComplete);
  }
  getValue() {
    return this.getActiveInputElement().getValue();
  }
  setValue(value, silent) {
    this.getActiveInputElement().setValue(value, silent);
  }
  getActiveInputElement() {
    return this.numberInputActive ? this.eFloatingFilterNumberInput : this.eFloatingFilterTextInput;
  }
  setValueChangedListener(listener) {
    this.valueChangedListener = listener;
  }
  setupListeners(element, listener) {
    this.addManagedListeners(element, {
      input: listener,
      keydown: listener
    });
  }
  setParams(params) {
    this.setAriaLabel(params.ariaLabel);
    if (params.autoComplete !== void 0) {
      this.setAutoComplete(params.autoComplete);
    }
  }
  setAriaLabel(ariaLabel) {
    this.eFloatingFilterNumberInput.setInputAriaLabel(ariaLabel);
    this.eFloatingFilterTextInput.setInputAriaLabel(ariaLabel);
  }
};
var NumberFloatingFilter = class extends TextInputFloatingFilter {
  init(params) {
    super.init(params);
    this.filterModelFormatter = new NumberFilterModelFormatter(
      this.localeService,
      this.optionsFactory,
      params.filterParams?.numberFormatter
    );
  }
  onParamsUpdated(params) {
    this.refresh(params);
  }
  refresh(params) {
    const allowedCharPattern = getAllowedCharPattern(params.filterParams);
    if (allowedCharPattern !== this.allowedCharPattern) {
      this.recreateFloatingFilterInputService(params);
    }
    super.refresh(params);
    this.filterModelFormatter.updateParams({ optionsFactory: this.optionsFactory });
  }
  getDefaultFilterOptions() {
    return DEFAULT_NUMBER_FILTER_OPTIONS;
  }
  getFilterModelFormatter() {
    return this.filterModelFormatter;
  }
  createFloatingFilterInputService(params) {
    this.allowedCharPattern = getAllowedCharPattern(params.filterParams);
    if (this.allowedCharPattern) {
      return this.createManagedBean(
        new FloatingFilterTextInputService({
          config: { allowedCharPattern: this.allowedCharPattern }
        })
      );
    }
    return this.createManagedBean(new FloatingFilterNumberInputService());
  }
};

// community-modules/core/src/filter/provided/text/textFloatingFilter.ts
var TextFloatingFilter = class extends TextInputFloatingFilter {
  init(params) {
    super.init(params);
    this.filterModelFormatter = new TextFilterModelFormatter(this.localeService, this.optionsFactory);
  }
  onParamsUpdated(params) {
    this.refresh(params);
  }
  refresh(params) {
    super.refresh(params);
    this.filterModelFormatter.updateParams({ optionsFactory: this.optionsFactory });
  }
  getDefaultFilterOptions() {
    return DEFAULT_TEXT_FILTER_OPTIONS;
  }
  getFilterModelFormatter() {
    return this.filterModelFormatter;
  }
  createFloatingFilterInputService() {
    return this.createManagedBean(new FloatingFilterTextInputService());
  }
};

// community-modules/core/src/filter/quickFilterApi.ts
function isQuickFilterPresent(beans) {
  return !!beans.filterManager?.isQuickFilterPresent();
}
function getQuickFilter(beans) {
  return beans.gos.get("quickFilterText");
}
function resetQuickFilter(beans) {
  beans.filterManager?.resetQuickFilterCache();
}

// community-modules/core/src/filter/quickFilterService.ts
var QuickFilterService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "quickFilterService";
    this.quickFilter = null;
    this.quickFilterParts = null;
  }
  wireBeans(beans) {
    this.valueService = beans.valueService;
    this.columnModel = beans.columnModel;
    this.rowModel = beans.rowModel;
    this.pivotResultColsService = beans.pivotResultColsService;
  }
  postConstruct() {
    const resetListener = this.resetQuickFilterCache.bind(this);
    this.addManagedEventListeners({
      columnPivotModeChanged: resetListener,
      newColumnsLoaded: resetListener,
      columnRowGroupChanged: resetListener,
      columnVisible: () => {
        if (!this.gos.get("includeHiddenColumnsInQuickFilter")) {
          this.resetQuickFilterCache();
        }
      }
    });
    this.addManagedPropertyListener("quickFilterText", (e) => this.setQuickFilter(e.currentValue));
    this.addManagedPropertyListeners(
      ["includeHiddenColumnsInQuickFilter", "applyQuickFilterBeforePivotOrAgg"],
      () => this.onQuickFilterColumnConfigChanged()
    );
    this.quickFilter = this.parseQuickFilter(this.gos.get("quickFilterText"));
    this.parser = this.gos.get("quickFilterParser");
    this.matcher = this.gos.get("quickFilterMatcher");
    this.setQuickFilterParts();
    this.addManagedPropertyListeners(
      ["quickFilterMatcher", "quickFilterParser"],
      () => this.setQuickFilterParserAndMatcher()
    );
  }
  // if we are using autoGroupCols, then they should be included for quick filter. this covers the
  // following scenarios:
  // a) user provides 'field' into autoGroupCol of normal grid, so now because a valid col to filter leafs on
  // b) using tree data and user depends on autoGroupCol for first col, and we also want to filter on this
  //    (tree data is a bit different, as parent rows can be filtered on, unlike row grouping)
  refreshQuickFilterCols() {
    const pivotMode = this.columnModel.isPivotMode();
    const groupAutoCols = this.columnModel.getAutoCols();
    const providedCols = this.columnModel.getColDefCols();
    let columnsForQuickFilter = (pivotMode && !this.gos.get("applyQuickFilterBeforePivotOrAgg") ? this.pivotResultColsService.getPivotResultCols()?.list : providedCols) ?? [];
    if (groupAutoCols) {
      columnsForQuickFilter = columnsForQuickFilter.concat(groupAutoCols);
    }
    this.colsForQuickFilter = this.gos.get("includeHiddenColumnsInQuickFilter") ? columnsForQuickFilter : columnsForQuickFilter.filter((col) => col.isVisible() || col.isRowGroupActive());
  }
  isQuickFilterPresent() {
    return this.quickFilter !== null;
  }
  doesRowPassQuickFilter(node) {
    const usingCache = this.gos.get("cacheQuickFilter");
    if (this.matcher) {
      return this.doesRowPassQuickFilterMatcher(usingCache, node);
    }
    return this.quickFilterParts.every(
      (part) => usingCache ? this.doesRowPassQuickFilterCache(node, part) : this.doesRowPassQuickFilterNoCache(node, part)
    );
  }
  resetQuickFilterCache() {
    this.rowModel.forEachNode((node) => node.quickFilterAggregateText = null);
  }
  setQuickFilterParts() {
    const { quickFilter, parser } = this;
    if (quickFilter) {
      this.quickFilterParts = parser ? parser(quickFilter) : quickFilter.split(" ");
    } else {
      this.quickFilterParts = null;
    }
  }
  parseQuickFilter(newFilter) {
    if (!_exists(newFilter)) {
      return null;
    }
    if (!this.gos.isRowModelType("clientSide")) {
      _warnOnce("Quick filtering only works with the Client-Side Row Model");
      return null;
    }
    return newFilter.toUpperCase();
  }
  setQuickFilter(newFilter) {
    if (newFilter != null && typeof newFilter !== "string") {
      _warnOnce(`Grid option quickFilterText only supports string inputs, received: ${typeof newFilter}`);
      return;
    }
    const parsedFilter = this.parseQuickFilter(newFilter);
    if (this.quickFilter !== parsedFilter) {
      this.quickFilter = parsedFilter;
      this.setQuickFilterParts();
      this.dispatchLocalEvent({ type: "quickFilterChanged" });
    }
  }
  setQuickFilterParserAndMatcher() {
    const parser = this.gos.get("quickFilterParser");
    const matcher = this.gos.get("quickFilterMatcher");
    const hasChanged = parser !== this.parser || matcher !== this.matcher;
    this.parser = parser;
    this.matcher = matcher;
    if (hasChanged) {
      this.setQuickFilterParts();
      this.dispatchLocalEvent({ type: "quickFilterChanged" });
    }
  }
  onQuickFilterColumnConfigChanged() {
    this.refreshQuickFilterCols();
    this.resetQuickFilterCache();
    if (this.isQuickFilterPresent()) {
      this.dispatchLocalEvent({ type: "quickFilterChanged" });
    }
  }
  doesRowPassQuickFilterNoCache(node, filterPart) {
    return this.colsForQuickFilter.some((column) => {
      const part = this.getQuickFilterTextForColumn(column, node);
      return _exists(part) && part.indexOf(filterPart) >= 0;
    });
  }
  doesRowPassQuickFilterCache(node, filterPart) {
    this.checkGenerateQuickFilterAggregateText(node);
    return node.quickFilterAggregateText.indexOf(filterPart) >= 0;
  }
  doesRowPassQuickFilterMatcher(usingCache, node) {
    let quickFilterAggregateText;
    if (usingCache) {
      this.checkGenerateQuickFilterAggregateText(node);
      quickFilterAggregateText = node.quickFilterAggregateText;
    } else {
      quickFilterAggregateText = this.getQuickFilterAggregateText(node);
    }
    const { quickFilterParts, matcher } = this;
    return matcher(quickFilterParts, quickFilterAggregateText);
  }
  checkGenerateQuickFilterAggregateText(node) {
    if (!node.quickFilterAggregateText) {
      node.quickFilterAggregateText = this.getQuickFilterAggregateText(node);
    }
  }
  getQuickFilterTextForColumn(column, node) {
    let value = this.valueService.getValue(column, node, true);
    const colDef = column.getColDef();
    if (colDef.getQuickFilterText) {
      const params = this.gos.addGridCommonParams({
        value,
        node,
        data: node.data,
        column,
        colDef
      });
      value = colDef.getQuickFilterText(params);
    }
    return _exists(value) ? value.toString().toUpperCase() : null;
  }
  getQuickFilterAggregateText(node) {
    const stringParts = [];
    this.colsForQuickFilter.forEach((column) => {
      const part = this.getQuickFilterTextForColumn(column, node);
      if (_exists(part)) {
        stringParts.push(part);
      }
    });
    return stringParts.join("\n");
  }
};

// community-modules/core/src/filter/filterModule.ts
var FilterCoreModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/filter-core",
  beans: [FilterManager]
};
var FilterApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/filter-api",
  apiFunctions: {
    isAnyFilterPresent,
    onFilterChanged
  },
  dependantModules: [FilterCoreModule]
};
var ColumnFilterModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/column-filter",
  beans: [ColumnFilterService],
  dependantModules: [FilterCoreModule]
};
var ColumnFilterApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/column-filter-api",
  apiFunctions: {
    isColumnFilterPresent,
    getFilterInstance,
    getColumnFilterInstance,
    destroyFilter,
    setFilterModel,
    getFilterModel,
    getColumnFilterModel,
    setColumnFilterModel,
    showColumnFilter
  },
  dependantModules: [ColumnFilterModule, FilterApiModule]
};
var FloatingFilterCoreModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/floating-filter-core",
  controllers: [{ name: "headerFilterCell", classImp: HeaderFilterCellCtrl }],
  dependantModules: [ColumnFilterModule]
};
var FloatingFilterModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/floating-filter",
  dependantModules: [FloatingFilterCoreModule, ColumnFilterModule]
};
var ReadOnlyFloatingFilterModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/read-only-floating-filter",
  userComponents: [{ name: "agReadOnlyFloatingFilter", classImp: ReadOnlyFloatingFilter }],
  dependantModules: [FloatingFilterCoreModule]
};
var SimpleFilterModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/simple-filter",
  dependantModules: [ColumnFilterModule],
  userComponents: [
    { name: "agTextColumnFilter", classImp: TextFilter },
    { name: "agNumberColumnFilter", classImp: NumberFilter },
    { name: "agDateColumnFilter", classImp: DateFilter },
    { name: "agDateInput", classImp: DefaultDateComponent }
  ]
};
var SimpleFloatingFilterModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/simple-floating-filter",
  dependantModules: [SimpleFilterModule, FloatingFilterCoreModule],
  userComponents: [
    { name: "agTextColumnFloatingFilter", classImp: TextFloatingFilter },
    { name: "agNumberColumnFloatingFilter", classImp: NumberFloatingFilter },
    { name: "agDateColumnFloatingFilter", classImp: DateFloatingFilter }
  ]
};
var QuickFilterCoreModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/quick-filter-core",
  beans: [QuickFilterService],
  dependantModules: [FilterCoreModule]
};
var QuickFilterApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/quick-filter-api",
  apiFunctions: {
    isQuickFilterPresent,
    getQuickFilter,
    resetQuickFilter
  },
  dependantModules: [QuickFilterCoreModule]
};
var QuickFilterModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/quick-filter",
  dependantModules: [QuickFilterCoreModule, QuickFilterApiModule]
};
var FilterModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/filter",
  dependantModules: [
    SimpleFloatingFilterModule,
    ReadOnlyFloatingFilterModule,
    QuickFilterModule,
    ColumnFilterApiModule
  ]
};

// community-modules/core/src/headerRendering/cells/abstractCell/abstractHeaderCellComp.ts
var AbstractHeaderCellComp = class extends Component {
  constructor(template, ctrl) {
    super(template);
    this.ctrl = ctrl;
  }
  getCtrl() {
    return this.ctrl;
  }
};

// community-modules/core/src/headerRendering/cells/floatingFilter/headerFilterCellComp.ts
var HeaderFilterCellComp = class extends AbstractHeaderCellComp {
  constructor(ctrl) {
    super(
      /* html */
      `<div class="ag-header-cell ag-floating-filter" role="gridcell">
            <div data-ref="eFloatingFilterBody" role="presentation"></div>
            <div class="ag-floating-filter-button ag-hidden" data-ref="eButtonWrapper" role="presentation">
                <button type="button" class="ag-button ag-floating-filter-button-button" data-ref="eButtonShowMainFilter" tabindex="-1"></button>
            </div>
        </div>`,
      ctrl
    );
    this.eFloatingFilterBody = RefPlaceholder;
    this.eButtonWrapper = RefPlaceholder;
    this.eButtonShowMainFilter = RefPlaceholder;
  }
  postConstruct() {
    const eGui = this.getGui();
    const compProxy = {
      addOrRemoveCssClass: (cssClassName, on) => this.addOrRemoveCssClass(cssClassName, on),
      addOrRemoveBodyCssClass: (cssClassName, on) => this.eFloatingFilterBody.classList.toggle(cssClassName, on),
      setButtonWrapperDisplayed: (displayed) => _setDisplayed(this.eButtonWrapper, displayed),
      setCompDetails: (compDetails) => this.setCompDetails(compDetails),
      getFloatingFilterComp: () => this.compPromise,
      setWidth: (width) => eGui.style.width = width,
      setMenuIcon: (eIcon) => this.eButtonShowMainFilter.appendChild(eIcon)
    };
    this.ctrl.setComp(compProxy, eGui, this.eButtonShowMainFilter, this.eFloatingFilterBody);
  }
  setCompDetails(compDetails) {
    if (!compDetails) {
      this.destroyFloatingFilterComp();
      this.compPromise = null;
      return;
    }
    this.compPromise = compDetails.newAgStackInstance();
    this.compPromise.then((comp) => this.afterCompCreated(comp));
  }
  destroy() {
    this.destroyFloatingFilterComp();
    super.destroy();
  }
  destroyFloatingFilterComp() {
    if (this.floatingFilterComp) {
      this.eFloatingFilterBody.removeChild(this.floatingFilterComp.getGui());
      this.floatingFilterComp = this.destroyBean(this.floatingFilterComp);
    }
  }
  afterCompCreated(comp) {
    if (!comp) {
      return;
    }
    if (!this.isAlive()) {
      this.destroyBean(comp);
      return;
    }
    this.destroyFloatingFilterComp();
    this.floatingFilterComp = comp;
    this.eFloatingFilterBody.appendChild(comp.getGui());
    if (comp.afterGuiAttached) {
      comp.afterGuiAttached();
    }
  }
};

// community-modules/core/src/headerRendering/cells/column/headerCellComp.ts
var HeaderCellComp = class extends AbstractHeaderCellComp {
  constructor(ctrl) {
    super(
      /* html */
      `<div class="ag-header-cell" role="columnheader">
            <div data-ref="eResize" class="ag-header-cell-resize" role="presentation"></div>
            <div data-ref="eHeaderCompWrapper" class="ag-header-cell-comp-wrapper" role="presentation"></div>
        </div>`,
      ctrl
    );
    this.eResize = RefPlaceholder;
    this.eHeaderCompWrapper = RefPlaceholder;
    this.headerCompVersion = 0;
    this.column = ctrl.getColumnGroupChild();
    this.pinned = ctrl.getPinned();
  }
  postConstruct() {
    const eGui = this.getGui();
    const setAttribute = (name, value) => {
      if (value != null && value != "") {
        eGui.setAttribute(name, value);
      } else {
        eGui.removeAttribute(name);
      }
    };
    setAttribute("col-id", this.column.getColId());
    const compProxy = {
      setWidth: (width) => eGui.style.width = width,
      addOrRemoveCssClass: (cssClassName, on) => this.addOrRemoveCssClass(cssClassName, on),
      setAriaSort: (sort) => sort ? _setAriaSort(eGui, sort) : _removeAriaSort(eGui),
      setUserCompDetails: (compDetails) => this.setUserCompDetails(compDetails),
      getUserCompInstance: () => this.headerComp
    };
    this.ctrl.setComp(compProxy, this.getGui(), this.eResize, this.eHeaderCompWrapper);
    const selectAllGui = this.ctrl.getSelectAllGui();
    this.eResize.insertAdjacentElement("afterend", selectAllGui);
  }
  destroy() {
    this.destroyHeaderComp();
    super.destroy();
  }
  destroyHeaderComp() {
    if (this.headerComp) {
      this.eHeaderCompWrapper.removeChild(this.headerCompGui);
      this.headerComp = this.destroyBean(this.headerComp);
      this.headerCompGui = void 0;
    }
  }
  setUserCompDetails(compDetails) {
    this.headerCompVersion++;
    const versionCopy = this.headerCompVersion;
    compDetails.newAgStackInstance().then((comp) => this.afterCompCreated(versionCopy, comp));
  }
  afterCompCreated(version, headerComp) {
    if (version != this.headerCompVersion || !this.isAlive()) {
      this.destroyBean(headerComp);
      return;
    }
    this.destroyHeaderComp();
    this.headerComp = headerComp;
    this.headerCompGui = headerComp.getGui();
    this.eHeaderCompWrapper.appendChild(this.headerCompGui);
    this.ctrl.setDragSource(this.getGui());
  }
};

// community-modules/core/src/headerRendering/cells/columnGroup/headerGroupCellComp.ts
var HeaderGroupCellComp = class extends AbstractHeaderCellComp {
  constructor(ctrl) {
    super(
      /* html */
      `<div class="ag-header-group-cell" role="columnheader">
            <div data-ref="eResize" class="ag-header-cell-resize" role="presentation"></div>
        </div>`,
      ctrl
    );
    this.eResize = RefPlaceholder;
  }
  postConstruct() {
    const eGui = this.getGui();
    const setAttribute = (key, value) => value != void 0 ? eGui.setAttribute(key, value) : eGui.removeAttribute(key);
    eGui.setAttribute("col-id", this.ctrl.getColId());
    const compProxy = {
      addOrRemoveCssClass: (cssClassName, on) => this.addOrRemoveCssClass(cssClassName, on),
      setResizableDisplayed: (displayed) => _setDisplayed(this.eResize, displayed),
      setWidth: (width) => eGui.style.width = width,
      setAriaExpanded: (expanded) => setAttribute("aria-expanded", expanded),
      setUserCompDetails: (details) => this.setUserCompDetails(details),
      getUserCompInstance: () => this.headerGroupComp
    };
    this.ctrl.setComp(compProxy, eGui, this.eResize);
  }
  setUserCompDetails(details) {
    details.newAgStackInstance().then((comp) => this.afterHeaderCompCreated(comp));
  }
  afterHeaderCompCreated(headerGroupComp) {
    const destroyFunc = () => this.destroyBean(headerGroupComp);
    if (!this.isAlive()) {
      destroyFunc();
      return;
    }
    const eGui = this.getGui();
    const eHeaderGroupGui = headerGroupComp.getGui();
    eGui.appendChild(eHeaderGroupGui);
    this.addDestroyFunc(destroyFunc);
    this.headerGroupComp = headerGroupComp;
    this.ctrl.setDragSource(eGui);
  }
};

// community-modules/core/src/headerRendering/row/headerRowComp.ts
var HeaderRowType = /* @__PURE__ */ ((HeaderRowType2) => {
  HeaderRowType2["COLUMN_GROUP"] = "group";
  HeaderRowType2["COLUMN"] = "column";
  HeaderRowType2["FLOATING_FILTER"] = "filter";
  return HeaderRowType2;
})(HeaderRowType || {});
var HeaderRowComp = class extends Component {
  constructor(ctrl) {
    super();
    this.headerComps = {};
    this.ctrl = ctrl;
    this.setTemplate(
      /* html */
      `<div class="${this.ctrl.getHeaderRowClass()}" role="row"></div>`
    );
  }
  postConstruct() {
    _setAriaRowIndex(this.getGui(), this.ctrl.getAriaRowIndex());
    const compProxy = {
      setHeight: (height) => this.getGui().style.height = height,
      setTop: (top) => this.getGui().style.top = top,
      setHeaderCtrls: (ctrls, forceOrder) => this.setHeaderCtrls(ctrls, forceOrder),
      setWidth: (width) => this.getGui().style.width = width
    };
    this.ctrl.setComp(compProxy);
  }
  destroy() {
    this.setHeaderCtrls([], false);
    super.destroy();
  }
  setHeaderCtrls(ctrls, forceOrder) {
    if (!this.isAlive()) {
      return;
    }
    const oldComps = this.headerComps;
    this.headerComps = {};
    ctrls.forEach((ctrl) => {
      const id = ctrl.getInstanceId();
      let comp = oldComps[id];
      delete oldComps[id];
      if (comp == null) {
        comp = this.createHeaderComp(ctrl);
        this.getGui().appendChild(comp.getGui());
      }
      this.headerComps[id] = comp;
    });
    _iterateObject(oldComps, (id, comp) => {
      this.getGui().removeChild(comp.getGui());
      this.destroyBean(comp);
    });
    if (forceOrder) {
      const comps = _getAllValuesInObject(this.headerComps);
      comps.sort(
        (a, b) => {
          const leftA = a.getCtrl().getColumnGroupChild().getLeft();
          const leftB = b.getCtrl().getColumnGroupChild().getLeft();
          return leftA - leftB;
        }
      );
      const elementsInOrder = comps.map((c) => c.getGui());
      _setDomChildOrder(this.getGui(), elementsInOrder);
    }
  }
  createHeaderComp(headerCtrl) {
    let result;
    switch (this.ctrl.getType()) {
      case "group" /* COLUMN_GROUP */:
        result = new HeaderGroupCellComp(headerCtrl);
        break;
      case "filter" /* FLOATING_FILTER */:
        result = new HeaderFilterCellComp(headerCtrl);
        break;
      default:
        result = new HeaderCellComp(headerCtrl);
        break;
    }
    this.createBean(result);
    result.setParentComponent(this);
    return result;
  }
};

// community-modules/core/src/headerRendering/common/headerNavigationService.ts
var HeaderNavigationDirection = /* @__PURE__ */ ((HeaderNavigationDirection2) => {
  HeaderNavigationDirection2[HeaderNavigationDirection2["UP"] = 0] = "UP";
  HeaderNavigationDirection2[HeaderNavigationDirection2["DOWN"] = 1] = "DOWN";
  HeaderNavigationDirection2[HeaderNavigationDirection2["LEFT"] = 2] = "LEFT";
  HeaderNavigationDirection2[HeaderNavigationDirection2["RIGHT"] = 3] = "RIGHT";
  return HeaderNavigationDirection2;
})(HeaderNavigationDirection || {});
var HeaderNavigationService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "headerNavigationService";
    this.currentHeaderRowWithoutSpan = -1;
  }
  wireBeans(beans) {
    this.focusService = beans.focusService;
    this.headerPositionUtils = beans.headerPositionUtils;
    this.ctrlsService = beans.ctrlsService;
    this.columnModel = beans.columnModel;
    this.visibleColService = beans.visibleColsService;
  }
  postConstruct() {
    this.ctrlsService.whenReady((p) => {
      this.gridBodyCon = p.gridBodyCtrl;
    });
    const eDocument = this.gos.getDocument();
    this.addManagedElementListeners(eDocument, { mousedown: () => this.setCurrentHeaderRowWithoutSpan(-1) });
  }
  getHeaderRowCount() {
    const centerHeaderContainer = this.ctrlsService.getHeaderRowContainerCtrl();
    return centerHeaderContainer ? centerHeaderContainer.getRowCount() : 0;
  }
  getHeaderPositionForColumn(colKey, floatingFilter) {
    let column;
    if (typeof colKey === "string") {
      column = this.columnModel.getCol(colKey);
      if (!column) {
        column = this.visibleColService.getColumnGroup(colKey);
      }
    } else {
      column = colKey;
    }
    if (!column) {
      return null;
    }
    const centerHeaderContainer = this.ctrlsService.getHeaderRowContainerCtrl();
    const allCtrls = centerHeaderContainer.getAllCtrls();
    const isFloatingFilterVisible = _last(allCtrls).getType() === "filter" /* FLOATING_FILTER */;
    const headerRowCount = this.getHeaderRowCount() - 1;
    let row = -1;
    let col = column;
    while (col) {
      row++;
      col = col.getParent();
    }
    let headerRowIndex = row;
    if (floatingFilter && isFloatingFilterVisible && headerRowIndex === headerRowCount - 1) {
      headerRowIndex++;
    }
    return headerRowIndex === -1 ? null : {
      headerRowIndex,
      column
    };
  }
  /*
   * This method navigates grid header vertically
   * @return {boolean} true to preventDefault on the event that caused this navigation.
   */
  navigateVertically(direction, fromHeader, event) {
    if (!fromHeader) {
      fromHeader = this.focusService.getFocusedHeader();
    }
    if (!fromHeader) {
      return false;
    }
    const { headerRowIndex } = fromHeader;
    const column = fromHeader.column;
    const rowLen = this.getHeaderRowCount();
    const isUp = direction === 0 /* UP */;
    let {
      headerRowIndex: nextRow,
      column: nextFocusColumn,
      // eslint-disable-next-line prefer-const
      headerRowIndexWithoutSpan
    } = isUp ? this.headerPositionUtils.getColumnVisibleParent(column, headerRowIndex) : this.headerPositionUtils.getColumnVisibleChild(column, headerRowIndex);
    let skipColumn = false;
    if (nextRow < 0) {
      nextRow = 0;
      nextFocusColumn = column;
      skipColumn = true;
    }
    if (nextRow >= rowLen) {
      nextRow = -1;
      this.setCurrentHeaderRowWithoutSpan(-1);
    } else if (headerRowIndexWithoutSpan !== void 0) {
      this.currentHeaderRowWithoutSpan = headerRowIndexWithoutSpan;
    }
    if (!skipColumn && !nextFocusColumn) {
      return false;
    }
    return this.focusService.focusHeaderPosition({
      headerPosition: { headerRowIndex: nextRow, column: nextFocusColumn },
      allowUserOverride: true,
      event
    });
  }
  setCurrentHeaderRowWithoutSpan(row) {
    this.currentHeaderRowWithoutSpan = row;
  }
  /*
   * This method navigates grid header horizontally
   * @return {boolean} true to preventDefault on the event that caused this navigation.
   */
  navigateHorizontally(direction, fromTab = false, event) {
    const focusedHeader = this.focusService.getFocusedHeader();
    const isLeft = direction === 2 /* LEFT */;
    const isRtl = this.gos.get("enableRtl");
    let nextHeader;
    let normalisedDirection;
    if (this.currentHeaderRowWithoutSpan !== -1) {
      focusedHeader.headerRowIndex = this.currentHeaderRowWithoutSpan;
    } else {
      this.currentHeaderRowWithoutSpan = focusedHeader.headerRowIndex;
    }
    if (isLeft !== isRtl) {
      normalisedDirection = "Before";
      nextHeader = this.headerPositionUtils.findHeader(focusedHeader, normalisedDirection);
    } else {
      normalisedDirection = "After";
      nextHeader = this.headerPositionUtils.findHeader(focusedHeader, normalisedDirection);
    }
    if (nextHeader || !fromTab) {
      return this.focusService.focusHeaderPosition({
        headerPosition: nextHeader,
        direction: normalisedDirection,
        fromTab,
        allowUserOverride: true,
        event
      });
    } else if (fromTab) {
      const userFunc = this.gos.getCallback("tabToNextHeader");
      if (userFunc) {
        return this.focusService.focusHeaderPositionFromUserFunc({
          userFunc,
          headerPosition: nextHeader,
          direction: normalisedDirection
        });
      }
    }
    return this.focusNextHeaderRow(focusedHeader, normalisedDirection, event);
  }
  focusNextHeaderRow(focusedHeader, direction, event) {
    const currentIndex = focusedHeader.headerRowIndex;
    let nextPosition = null;
    let nextRowIndex;
    if (direction === "Before") {
      if (currentIndex > 0) {
        nextRowIndex = currentIndex - 1;
        this.currentHeaderRowWithoutSpan -= 1;
        nextPosition = this.headerPositionUtils.findColAtEdgeForHeaderRow(nextRowIndex, "end");
      }
    } else {
      nextRowIndex = currentIndex + 1;
      if (this.currentHeaderRowWithoutSpan < this.getHeaderRowCount()) {
        this.currentHeaderRowWithoutSpan += 1;
      } else {
        this.setCurrentHeaderRowWithoutSpan(-1);
      }
      nextPosition = this.headerPositionUtils.findColAtEdgeForHeaderRow(nextRowIndex, "start");
    }
    if (!nextPosition) {
      return false;
    }
    const { column, headerRowIndex } = this.headerPositionUtils.getHeaderIndexToFocus(
      nextPosition.column,
      nextPosition?.headerRowIndex
    );
    return this.focusService.focusHeaderPosition({
      headerPosition: { column, headerRowIndex },
      direction,
      fromTab: true,
      allowUserOverride: true,
      event
    });
  }
  scrollToColumn(column, direction = "After") {
    if (column.getPinned()) {
      return;
    }
    let columnToScrollTo;
    if (isColumnGroup(column)) {
      const columns = column.getDisplayedLeafColumns();
      columnToScrollTo = direction === "Before" ? _last(columns) : columns[0];
    } else {
      columnToScrollTo = column;
    }
    this.gridBodyCon.getScrollFeature().ensureColumnVisible(columnToScrollTo);
  }
};

// community-modules/core/src/headerRendering/gridHeaderCtrl.ts
var GridHeaderCtrl = class extends BeanStub {
  wireBeans(beans) {
    this.headerNavigationService = beans.headerNavigationService;
    this.focusService = beans.focusService;
    this.columnModel = beans.columnModel;
    this.visibleColsService = beans.visibleColsService;
    this.ctrlsService = beans.ctrlsService;
    this.filterManager = beans.filterManager;
    this.menuService = beans.menuService;
  }
  setComp(comp, eGui, eFocusableElement) {
    this.comp = comp;
    this.eGui = eGui;
    this.createManagedBean(
      new ManagedFocusFeature(eFocusableElement, {
        onTabKeyDown: this.onTabKeyDown.bind(this),
        handleKeyDown: this.handleKeyDown.bind(this),
        onFocusOut: this.onFocusOut.bind(this)
      })
    );
    this.addManagedEventListeners({
      columnPivotModeChanged: this.onPivotModeChanged.bind(this),
      displayedColumnsChanged: this.onDisplayedColumnsChanged.bind(this)
    });
    this.onPivotModeChanged();
    this.setupHeaderHeight();
    const listener = this.onHeaderContextMenu.bind(this);
    this.addManagedElementListeners(this.eGui, { contextmenu: listener });
    this.mockContextMenuForIPad(listener);
    this.ctrlsService.register("gridHeaderCtrl", this);
  }
  setupHeaderHeight() {
    const listener = this.setHeaderHeight.bind(this);
    listener();
    this.addManagedPropertyListener("headerHeight", listener);
    this.addManagedPropertyListener("pivotHeaderHeight", listener);
    this.addManagedPropertyListener("groupHeaderHeight", listener);
    this.addManagedPropertyListener("pivotGroupHeaderHeight", listener);
    this.addManagedPropertyListener("floatingFiltersHeight", listener);
    this.addManagedEventListeners({
      displayedColumnsChanged: listener,
      columnHeaderHeightChanged: listener,
      gridStylesChanged: listener,
      advancedFilterEnabledChanged: listener
    });
  }
  getHeaderHeight() {
    return this.headerHeight;
  }
  setHeaderHeight() {
    const { columnModel } = this;
    let numberOfFloating = 0;
    let headerRowCount = columnModel.getHeaderRowCount();
    let totalHeaderHeight;
    const hasFloatingFilters = this.filterManager?.hasFloatingFilters();
    if (hasFloatingFilters) {
      headerRowCount++;
      numberOfFloating = 1;
    }
    const groupHeight = this.columnModel.getColumnGroupHeaderRowHeight();
    const headerHeight = this.columnModel.getColumnHeaderRowHeight();
    const numberOfNonGroups = 1 + numberOfFloating;
    const numberOfGroups = headerRowCount - numberOfNonGroups;
    totalHeaderHeight = numberOfFloating * columnModel.getFloatingFiltersHeight();
    totalHeaderHeight += numberOfGroups * groupHeight;
    totalHeaderHeight += headerHeight;
    if (this.headerHeight === totalHeaderHeight) {
      return;
    }
    this.headerHeight = totalHeaderHeight;
    const px = `${totalHeaderHeight + 1}px`;
    this.comp.setHeightAndMinHeight(px);
    this.eventService.dispatchEvent({
      type: "headerHeightChanged"
    });
  }
  onPivotModeChanged() {
    const pivotMode = this.columnModel.isPivotMode();
    this.comp.addOrRemoveCssClass("ag-pivot-on", pivotMode);
    this.comp.addOrRemoveCssClass("ag-pivot-off", !pivotMode);
  }
  onDisplayedColumnsChanged() {
    const columns = this.visibleColsService.getAllCols();
    const shouldAllowOverflow = columns.some((col) => col.isSpanHeaderHeight());
    this.comp.addOrRemoveCssClass("ag-header-allow-overflow", shouldAllowOverflow);
  }
  onTabKeyDown(e) {
    const isRtl = this.gos.get("enableRtl");
    const direction = e.shiftKey !== isRtl ? 2 /* LEFT */ : 3 /* RIGHT */;
    if (this.headerNavigationService.navigateHorizontally(direction, true, e) || this.focusService.focusNextGridCoreContainer(e.shiftKey)) {
      e.preventDefault();
    }
  }
  handleKeyDown(e) {
    let direction = null;
    switch (e.key) {
      case KeyCode.LEFT:
        direction = 2 /* LEFT */;
      case KeyCode.RIGHT: {
        if (!_exists(direction)) {
          direction = 3 /* RIGHT */;
        }
        this.headerNavigationService.navigateHorizontally(direction, false, e);
        break;
      }
      case KeyCode.UP:
        direction = 0 /* UP */;
      case KeyCode.DOWN: {
        if (!_exists(direction)) {
          direction = 1 /* DOWN */;
        }
        if (this.headerNavigationService.navigateVertically(direction, null, e)) {
          e.preventDefault();
        }
        break;
      }
      default:
        return;
    }
  }
  onFocusOut(e) {
    const { relatedTarget } = e;
    if (!relatedTarget && this.eGui.contains(this.gos.getActiveDomElement())) {
      return;
    }
    if (!this.eGui.contains(relatedTarget)) {
      this.focusService.clearFocusedHeader();
    }
  }
  onHeaderContextMenu(mouseEvent, touch, touchEvent) {
    if (!mouseEvent && !touchEvent || !this.menuService.isHeaderContextMenuEnabled()) {
      return;
    }
    const { target } = mouseEvent ?? touch;
    if (target === this.eGui || target === this.ctrlsService.getHeaderRowContainerCtrl().getViewport()) {
      this.menuService.showHeaderContextMenu(void 0, mouseEvent, touchEvent);
    }
  }
  mockContextMenuForIPad(listener) {
    if (!_isIOSUserAgent()) {
      return;
    }
    const touchListener = new TouchListener(this.eGui);
    const longTapListener = (event) => {
      listener(void 0, event.touchStart, event.touchEvent);
    };
    this.addManagedListeners(touchListener, { longTap: longTapListener });
    this.addDestroyFunc(() => touchListener.destroy());
  }
};

// community-modules/core/src/gridBodyComp/centerWidthFeature.ts
var CenterWidthFeature = class extends BeanStub {
  constructor(callback, addSpacer = false) {
    super();
    this.callback = callback;
    this.addSpacer = addSpacer;
  }
  wireBeans(beans) {
    this.visibleColsService = beans.visibleColsService;
    this.scrollVisibleService = beans.scrollVisibleService;
  }
  postConstruct() {
    const listener = this.setWidth.bind(this);
    this.addManagedPropertyListener("domLayout", listener);
    this.addManagedEventListeners({
      columnContainerWidthChanged: listener,
      displayedColumnsChanged: listener,
      leftPinnedWidthChanged: listener
    });
    if (this.addSpacer) {
      this.addManagedEventListeners({
        rightPinnedWidthChanged: listener,
        scrollVisibilityChanged: listener,
        scrollbarWidthChanged: listener
      });
    }
    this.setWidth();
  }
  setWidth() {
    const printLayout = this.gos.isDomLayout("print");
    const centerWidth = this.visibleColsService.getBodyContainerWidth();
    const leftWidth = this.visibleColsService.getColsLeftWidth();
    const rightWidth = this.visibleColsService.getDisplayedColumnsRightWidth();
    let totalWidth;
    if (printLayout) {
      totalWidth = centerWidth + leftWidth + rightWidth;
    } else {
      totalWidth = centerWidth;
      if (this.addSpacer) {
        const relevantWidth = this.gos.get("enableRtl") ? leftWidth : rightWidth;
        if (relevantWidth === 0 && this.scrollVisibleService.isVerticalScrollShowing()) {
          totalWidth += this.gos.getScrollbarWidth();
        }
      }
    }
    this.callback(totalWidth);
  }
};

// community-modules/core/src/headerRendering/columnDrag/bodyDropPivotTarget.ts
var BodyDropPivotTarget = class extends BeanStub {
  constructor(pinned) {
    super();
    this.columnsToAggregate = [];
    this.columnsToGroup = [];
    this.columnsToPivot = [];
    this.pinned = pinned;
  }
  wireBeans(beans) {
    this.funcColsService = beans.funcColsService;
  }
  /** Callback for when drag enters */
  onDragEnter(draggingEvent) {
    this.clearColumnsList();
    if (this.gos.get("functionsReadOnly")) {
      return;
    }
    const dragColumns = draggingEvent.dragItem.columns;
    if (!dragColumns) {
      return;
    }
    dragColumns.forEach((column) => {
      if (!column.isPrimary()) {
        return;
      }
      if (column.isAnyFunctionActive()) {
        return;
      }
      if (column.isAllowValue()) {
        this.columnsToAggregate.push(column);
      } else if (column.isAllowRowGroup()) {
        this.columnsToGroup.push(column);
      } else if (column.isAllowPivot()) {
        this.columnsToPivot.push(column);
      }
    });
  }
  getIconName() {
    const totalColumns = this.columnsToAggregate.length + this.columnsToGroup.length + this.columnsToPivot.length;
    if (totalColumns > 0) {
      return this.pinned ? "pinned" : "move";
    }
    return null;
  }
  /** Callback for when drag leaves */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDragLeave(draggingEvent) {
    this.clearColumnsList();
  }
  clearColumnsList() {
    this.columnsToAggregate.length = 0;
    this.columnsToGroup.length = 0;
    this.columnsToPivot.length = 0;
  }
  /** Callback for when dragging */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDragging(draggingEvent) {
  }
  /** Callback for when drag stops */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDragStop(draggingEvent) {
    if (this.columnsToAggregate.length > 0) {
      this.funcColsService.addValueColumns(this.columnsToAggregate, "toolPanelDragAndDrop");
    }
    if (this.columnsToGroup.length > 0) {
      this.funcColsService.addRowGroupColumns(this.columnsToGroup, "toolPanelDragAndDrop");
    }
    if (this.columnsToPivot.length > 0) {
      this.funcColsService.addPivotColumns(this.columnsToPivot, "toolPanelDragAndDrop");
    }
  }
};

// community-modules/core/src/headerRendering/columnMoveHelper.ts
function attemptMoveColumns(params) {
  const {
    isFromHeader,
    hDirection,
    xPosition,
    fromEnter,
    fakeEvent,
    pinned,
    gos,
    columnModel,
    columnMoveService,
    presentedColsService
  } = params;
  const draggingLeft = hDirection === 0 /* Left */;
  const draggingRight = hDirection === 1 /* Right */;
  let { allMovingColumns } = params;
  if (isFromHeader) {
    const newCols = [];
    allMovingColumns.forEach((col) => {
      let movingGroup = null;
      let parent = col.getParent();
      while (parent != null && parent.getDisplayedLeafColumns().length === 1) {
        movingGroup = parent;
        parent = parent.getParent();
      }
      if (movingGroup != null) {
        const isMarryChildren = !!movingGroup.getColGroupDef()?.marryChildren;
        const columnsToMove = isMarryChildren ? (
          // when marry children is true, we also have to move hidden
          // columns within the group, so grab them from the `providedColumnGroup`
          movingGroup.getProvidedColumnGroup().getLeafColumns()
        ) : movingGroup.getLeafColumns();
        columnsToMove.forEach((newCol) => {
          if (!newCols.includes(newCol)) {
            newCols.push(newCol);
          }
        });
      } else if (!newCols.includes(col)) {
        newCols.push(col);
      }
    });
    allMovingColumns = newCols;
  }
  const allMovingColumnsOrdered = allMovingColumns.slice();
  columnModel.sortColsLikeCols(allMovingColumnsOrdered);
  const validMoves = calculateValidMoves({
    movingCols: allMovingColumnsOrdered,
    draggingRight,
    xPosition,
    pinned,
    gos,
    columnModel,
    presentedColsService
  });
  const oldIndex = calculateOldIndex(allMovingColumnsOrdered, columnModel);
  if (validMoves.length === 0) {
    return;
  }
  const firstValidMove = validMoves[0];
  let constrainDirection = oldIndex !== null && !fromEnter;
  if (isFromHeader) {
    constrainDirection = oldIndex !== null;
  }
  if (constrainDirection && !fakeEvent) {
    if (draggingLeft && firstValidMove >= oldIndex) {
      return;
    }
    if (draggingRight && firstValidMove <= oldIndex) {
      return;
    }
  }
  const displayedCols = presentedColsService.getAllCols();
  const potentialMoves = [];
  let targetOrder = null;
  for (let i = 0; i < validMoves.length; i++) {
    const move = validMoves[i];
    const order = columnMoveService.getProposedColumnOrder(allMovingColumnsOrdered, move);
    if (!columnMoveService.doesOrderPassRules(order)) {
      continue;
    }
    const displayedOrder = order.filter((col) => displayedCols.includes(col));
    if (targetOrder === null) {
      targetOrder = displayedOrder;
    } else if (!_areEqual(displayedOrder, targetOrder)) {
      break;
    }
    const fragCount = groupFragCount(order);
    potentialMoves.push({ move, fragCount });
  }
  if (potentialMoves.length === 0) {
    return;
  }
  potentialMoves.sort((a, b) => a.fragCount - b.fragCount);
  return moveColumns(allMovingColumns, potentialMoves[0].move, "uiColumnMoved", false, columnMoveService);
}
function moveColumns(columns, toIndex, source, finished, columnMoveService) {
  columnMoveService.moveColumns(columns, toIndex, source, finished);
  return finished ? null : { columns, toIndex };
}
function calculateOldIndex(movingCols, columnModel) {
  const gridCols = columnModel.getCols();
  const indexes = _sortNumerically(movingCols.map((col) => gridCols.indexOf(col)));
  const firstIndex = indexes[0];
  const lastIndex = _last(indexes);
  const spread = lastIndex - firstIndex;
  const gapsExist = spread !== indexes.length - 1;
  return gapsExist ? null : firstIndex;
}
function groupFragCount(columns) {
  function parents(col) {
    const result = [];
    let parent = col.getOriginalParent();
    while (parent != null) {
      result.push(parent);
      parent = parent.getOriginalParent();
    }
    return result;
  }
  let count = 0;
  for (let i = 0; i < columns.length - 1; i++) {
    let a = parents(columns[i]);
    let b = parents(columns[i + 1]);
    [a, b] = a.length > b.length ? [a, b] : [b, a];
    a.forEach((parent) => {
      if (b.indexOf(parent) === -1) {
        count++;
      }
    });
  }
  return count;
}
function getDisplayedColumns(presentedColsService, type) {
  switch (type) {
    case "left":
      return presentedColsService.getLeftCols();
    case "right":
      return presentedColsService.getRightCols();
    default:
      return presentedColsService.getCenterCols();
  }
}
function calculateValidMoves(params) {
  const { movingCols, draggingRight, xPosition, pinned, gos, columnModel, presentedColsService } = params;
  const isMoveBlocked = gos.get("suppressMovableColumns") || movingCols.some((col) => col.getColDef().suppressMovable);
  if (isMoveBlocked) {
    return [];
  }
  const allDisplayedCols = getDisplayedColumns(presentedColsService, pinned);
  const allGridCols = columnModel.getCols();
  const movingDisplayedCols = allDisplayedCols.filter((col) => _includes(movingCols, col));
  const otherDisplayedCols = allDisplayedCols.filter((col) => !_includes(movingCols, col));
  const otherGridCols = allGridCols.filter((col) => !_includes(movingCols, col));
  let displayIndex = 0;
  let availableWidth = xPosition;
  if (draggingRight) {
    let widthOfMovingDisplayedCols = 0;
    movingDisplayedCols.forEach((col) => widthOfMovingDisplayedCols += col.getActualWidth());
    availableWidth -= widthOfMovingDisplayedCols;
  }
  if (availableWidth > 0) {
    for (let i = 0; i < otherDisplayedCols.length; i++) {
      const col = otherDisplayedCols[i];
      availableWidth -= col.getActualWidth();
      if (availableWidth < 0) {
        break;
      }
      displayIndex++;
    }
    if (draggingRight) {
      displayIndex++;
    }
  }
  let firstValidMove;
  if (displayIndex > 0) {
    const leftColumn = otherDisplayedCols[displayIndex - 1];
    firstValidMove = otherGridCols.indexOf(leftColumn) + 1;
  } else {
    firstValidMove = otherGridCols.indexOf(otherDisplayedCols[0]);
    if (firstValidMove === -1) {
      firstValidMove = 0;
    }
  }
  const validMoves = [firstValidMove];
  const numberComparator = (a, b) => a - b;
  if (draggingRight) {
    let pointer = firstValidMove + 1;
    const lastIndex = allGridCols.length - 1;
    while (pointer <= lastIndex) {
      validMoves.push(pointer);
      pointer++;
    }
    validMoves.sort(numberComparator);
  } else {
    let pointer = firstValidMove;
    const lastIndex = allGridCols.length - 1;
    let displacedCol = allGridCols[pointer];
    while (pointer <= lastIndex && allDisplayedCols.indexOf(displacedCol) < 0) {
      pointer++;
      validMoves.push(pointer);
      displacedCol = allGridCols[pointer];
    }
    pointer = firstValidMove - 1;
    const firstDisplayIndex = 0;
    while (pointer >= firstDisplayIndex) {
      validMoves.push(pointer);
      pointer--;
    }
    validMoves.sort(numberComparator).reverse();
  }
  return validMoves;
}
function normaliseX(x, pinned, fromKeyboard, gos, ctrlsService) {
  const eViewport = ctrlsService.getHeaderRowContainerCtrl(pinned).getViewport();
  if (fromKeyboard) {
    x -= eViewport.getBoundingClientRect().left;
  }
  if (gos.get("enableRtl")) {
    const clientWidth = eViewport.clientWidth;
    x = clientWidth - x;
  }
  if (pinned == null) {
    x += ctrlsService.get("center").getCenterViewportScrollLeft();
  }
  return x;
}

// community-modules/core/src/headerRendering/columnDrag/moveColumnFeature.ts
var MoveColumnFeature = class extends BeanStub {
  constructor(pinned) {
    super();
    this.needToMoveLeft = false;
    this.needToMoveRight = false;
    this.lastMovedInfo = null;
    this.pinned = pinned;
    this.isCenterContainer = !_exists(pinned);
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.visibleColsService = beans.visibleColsService;
    this.columnMoveService = beans.columnMoveService;
    this.dragAndDropService = beans.dragAndDropService;
    this.ctrlsService = beans.ctrlsService;
  }
  postConstruct() {
    this.ctrlsService.whenReady((p) => {
      this.gridBodyCon = p.gridBodyCtrl;
    });
  }
  getIconName() {
    return this.pinned ? "pinned" : "move";
  }
  onDragEnter(draggingEvent) {
    const columns = draggingEvent.dragItem.columns;
    const dragCameFromToolPanel = draggingEvent.dragSource.type === 0 /* ToolPanel */;
    if (dragCameFromToolPanel) {
      this.setColumnsVisible(columns, true, "uiColumnDragged");
    } else {
      const visibleState = draggingEvent.dragItem.visibleState;
      const visibleColumns = (columns || []).filter((column) => visibleState[column.getId()]);
      this.setColumnsVisible(visibleColumns, true, "uiColumnDragged");
    }
    this.setColumnsPinned(columns, this.pinned, "uiColumnDragged");
    this.onDragging(draggingEvent, true, true);
  }
  onDragLeave() {
    this.ensureIntervalCleared();
    this.lastMovedInfo = null;
  }
  setColumnsVisible(columns, visible, source) {
    if (columns) {
      const allowedCols = columns.filter((c) => !c.getColDef().lockVisible);
      this.columnModel.setColsVisible(allowedCols, visible, source);
    }
  }
  setColumnsPinned(columns, pinned, source) {
    if (columns) {
      const allowedCols = columns.filter((c) => !c.getColDef().lockPinned);
      this.columnModel.setColsPinned(allowedCols, pinned, source);
    }
  }
  onDragStop() {
    this.onDragging(this.lastDraggingEvent, false, true, true);
    this.ensureIntervalCleared();
    this.lastMovedInfo = null;
  }
  checkCenterForScrolling(xAdjustedForScroll) {
    if (this.isCenterContainer) {
      const centerCtrl = this.ctrlsService.get("center");
      const firstVisiblePixel = centerCtrl.getCenterViewportScrollLeft();
      const lastVisiblePixel = firstVisiblePixel + centerCtrl.getCenterWidth();
      if (this.gos.get("enableRtl")) {
        this.needToMoveRight = xAdjustedForScroll < firstVisiblePixel + 50;
        this.needToMoveLeft = xAdjustedForScroll > lastVisiblePixel - 50;
      } else {
        this.needToMoveLeft = xAdjustedForScroll < firstVisiblePixel + 50;
        this.needToMoveRight = xAdjustedForScroll > lastVisiblePixel - 50;
      }
      if (this.needToMoveLeft || this.needToMoveRight) {
        this.ensureIntervalStarted();
      } else {
        this.ensureIntervalCleared();
      }
    }
  }
  onDragging(draggingEvent = this.lastDraggingEvent, fromEnter = false, fakeEvent = false, finished = false) {
    if (finished) {
      if (this.lastMovedInfo) {
        const { columns, toIndex } = this.lastMovedInfo;
        moveColumns(columns, toIndex, "uiColumnMoved", true, this.columnMoveService);
      }
      return;
    }
    this.lastDraggingEvent = draggingEvent;
    if (_missing(draggingEvent.hDirection)) {
      return;
    }
    const mouseX = normaliseX(draggingEvent.x, this.pinned, false, this.gos, this.ctrlsService);
    if (!fromEnter) {
      this.checkCenterForScrolling(mouseX);
    }
    const hDirection = this.normaliseDirection(draggingEvent.hDirection);
    const dragSourceType = draggingEvent.dragSource.type;
    const allMovingColumns = draggingEvent.dragSource.getDragItem().columns?.filter((col) => {
      if (col.getColDef().lockPinned) {
        return col.getPinned() == this.pinned;
      }
      return true;
    }) || [];
    const lastMovedInfo = attemptMoveColumns({
      allMovingColumns,
      isFromHeader: dragSourceType === 1 /* HeaderCell */,
      hDirection,
      xPosition: mouseX,
      pinned: this.pinned,
      fromEnter,
      fakeEvent,
      gos: this.gos,
      columnModel: this.columnModel,
      columnMoveService: this.columnMoveService,
      presentedColsService: this.visibleColsService
    });
    if (lastMovedInfo) {
      this.lastMovedInfo = lastMovedInfo;
    }
  }
  normaliseDirection(hDirection) {
    if (this.gos.get("enableRtl")) {
      switch (hDirection) {
        case 0 /* Left */:
          return 1 /* Right */;
        case 1 /* Right */:
          return 0 /* Left */;
        default:
          _errorOnce(`Unknown direction ${hDirection}`);
      }
    } else {
      return hDirection;
    }
  }
  ensureIntervalStarted() {
    if (!this.movingIntervalId) {
      this.intervalCount = 0;
      this.failedMoveAttempts = 0;
      this.movingIntervalId = window.setInterval(this.moveInterval.bind(this), 100);
      this.dragAndDropService.setGhostIcon(this.needToMoveLeft ? "left" : "right", true);
    }
  }
  ensureIntervalCleared() {
    if (this.movingIntervalId) {
      window.clearInterval(this.movingIntervalId);
      this.movingIntervalId = null;
      this.dragAndDropService.setGhostIcon("move");
    }
  }
  moveInterval() {
    let pixelsToMove;
    this.intervalCount++;
    pixelsToMove = 10 + this.intervalCount * 5;
    if (pixelsToMove > 100) {
      pixelsToMove = 100;
    }
    let pixelsMoved = null;
    const scrollFeature = this.gridBodyCon.getScrollFeature();
    if (this.needToMoveLeft) {
      pixelsMoved = scrollFeature.scrollHorizontally(-pixelsToMove);
    } else if (this.needToMoveRight) {
      pixelsMoved = scrollFeature.scrollHorizontally(pixelsToMove);
    }
    if (pixelsMoved !== 0) {
      this.onDragging(this.lastDraggingEvent);
      this.failedMoveAttempts = 0;
    } else {
      this.failedMoveAttempts++;
      const columns = this.lastDraggingEvent.dragItem.columns;
      const columnsThatCanPin = columns.filter((c) => !c.getColDef().lockPinned);
      if (columnsThatCanPin.length > 0) {
        this.dragAndDropService.setGhostIcon("pinned");
        if (this.failedMoveAttempts > 7) {
          const pinType = this.needToMoveLeft ? "left" : "right";
          this.setColumnsPinned(columnsThatCanPin, pinType, "uiColumnDragged");
          this.dragAndDropService.nudge();
        }
      }
    }
  }
};

// community-modules/core/src/headerRendering/columnDrag/bodyDropTarget.ts
var BodyDropTarget = class extends BeanStub {
  wireBeans(beans) {
    this.dragAndDropService = beans.dragAndDropService;
    this.columnModel = beans.columnModel;
    this.ctrlsService = beans.ctrlsService;
  }
  constructor(pinned, eContainer) {
    super();
    this.pinned = pinned;
    this.eContainer = eContainer;
  }
  postConstruct() {
    this.ctrlsService.whenReady((p) => {
      switch (this.pinned) {
        case "left":
          this.eSecondaryContainers = [
            [p.gridBodyCtrl.getBodyViewportElement(), p.left.getContainerElement()],
            [p.bottomLeft.getContainerElement()],
            [p.topLeft.getContainerElement()]
          ];
          break;
        case "right":
          this.eSecondaryContainers = [
            [p.gridBodyCtrl.getBodyViewportElement(), p.right.getContainerElement()],
            [p.bottomRight.getContainerElement()],
            [p.topRight.getContainerElement()]
          ];
          break;
        default:
          this.eSecondaryContainers = [
            [p.gridBodyCtrl.getBodyViewportElement(), p.center.getViewportElement()],
            [p.bottomCenter.getViewportElement()],
            [p.topCenter.getViewportElement()]
          ];
          break;
      }
    });
    this.moveColumnFeature = this.createManagedBean(new MoveColumnFeature(this.pinned));
    this.bodyDropPivotTarget = this.createManagedBean(new BodyDropPivotTarget(this.pinned));
    this.dragAndDropService.addDropTarget(this);
  }
  isInterestedIn(type) {
    return type === 1 /* HeaderCell */ || type === 0 /* ToolPanel */ && this.gos.get("allowDragFromColumnsToolPanel");
  }
  getSecondaryContainers() {
    return this.eSecondaryContainers;
  }
  getContainer() {
    return this.eContainer;
  }
  getIconName() {
    return this.currentDropListener.getIconName();
  }
  // we want to use the bodyPivotTarget if the user is dragging columns in from the toolPanel
  // and we are in pivot mode, as it has to logic to set pivot/value/group on the columns when
  // dropped into the grid's body.
  isDropColumnInPivotMode(draggingEvent) {
    return this.columnModel.isPivotMode() && draggingEvent.dragSource.type === 0 /* ToolPanel */;
  }
  onDragEnter(draggingEvent) {
    this.currentDropListener = this.isDropColumnInPivotMode(draggingEvent) ? this.bodyDropPivotTarget : this.moveColumnFeature;
    this.currentDropListener.onDragEnter(draggingEvent);
  }
  onDragLeave(params) {
    this.currentDropListener.onDragLeave(params);
  }
  onDragging(params) {
    this.currentDropListener.onDragging(params);
  }
  onDragStop(params) {
    this.currentDropListener.onDragStop(params);
  }
};

// community-modules/core/src/headerRendering/cells/column/resizeFeature.ts
var ResizeFeature = class extends BeanStub {
  wireBeans(beans) {
    this.horizontalResizeService = beans.horizontalResizeService;
    this.pinnedWidthService = beans.pinnedWidthService;
    this.ctrlsService = beans.ctrlsService;
    this.columnSizeService = beans.columnSizeService;
    this.columnAutosizeService = beans.columnAutosizeService;
  }
  constructor(pinned, column, eResize, comp, ctrl) {
    super();
    this.pinned = pinned;
    this.column = column;
    this.eResize = eResize;
    this.comp = comp;
    this.ctrl = ctrl;
  }
  postConstruct() {
    const destroyResizeFuncs = [];
    let canResize;
    let canAutosize;
    const addResize = () => {
      _setDisplayed(this.eResize, canResize);
      if (!canResize) {
        return;
      }
      const finishedWithResizeFunc = this.horizontalResizeService.addResizeBar({
        eResizeBar: this.eResize,
        onResizeStart: this.onResizeStart.bind(this),
        onResizing: this.onResizing.bind(this, false),
        onResizeEnd: this.onResizing.bind(this, true)
      });
      destroyResizeFuncs.push(finishedWithResizeFunc);
      if (canAutosize) {
        const skipHeaderOnAutoSize = this.gos.get("skipHeaderOnAutoSize");
        const autoSizeColListener = () => {
          this.columnAutosizeService.autoSizeColumn(this.column, "uiColumnResized", skipHeaderOnAutoSize);
        };
        this.eResize.addEventListener("dblclick", autoSizeColListener);
        const touchListener = new TouchListener(this.eResize);
        touchListener.addEventListener("doubleTap", autoSizeColListener);
        destroyResizeFuncs.push(() => {
          this.eResize.removeEventListener("dblclick", autoSizeColListener);
          touchListener.removeEventListener("doubleTap", autoSizeColListener);
          touchListener.destroy();
        });
      }
    };
    const removeResize = () => {
      destroyResizeFuncs.forEach((f) => f());
      destroyResizeFuncs.length = 0;
    };
    const refresh = () => {
      const resize = this.column.isResizable();
      const autoSize = !this.gos.get("suppressAutoSize") && !this.column.getColDef().suppressAutoSize;
      const propertyChange = resize !== canResize || autoSize !== canAutosize;
      if (propertyChange) {
        canResize = resize;
        canAutosize = autoSize;
        removeResize();
        addResize();
      }
    };
    refresh();
    this.addDestroyFunc(removeResize);
    this.ctrl.addRefreshFunction(refresh);
  }
  onResizing(finished, resizeAmount) {
    const { column: key, lastResizeAmount, resizeStartWidth } = this;
    const resizeAmountNormalised = this.normaliseResizeAmount(resizeAmount);
    const newWidth = resizeStartWidth + resizeAmountNormalised;
    const columnWidths = [{ key, newWidth }];
    if (this.column.getPinned()) {
      const leftWidth = this.pinnedWidthService.getPinnedLeftWidth();
      const rightWidth = this.pinnedWidthService.getPinnedRightWidth();
      const bodyWidth = _getInnerWidth(this.ctrlsService.getGridBodyCtrl().getBodyViewportElement()) - 50;
      if (leftWidth + rightWidth + (resizeAmountNormalised - lastResizeAmount) > bodyWidth) {
        return;
      }
    }
    this.lastResizeAmount = resizeAmountNormalised;
    this.columnSizeService.setColumnWidths(columnWidths, this.resizeWithShiftKey, finished, "uiColumnResized");
    if (finished) {
      this.toggleColumnResizing(false);
    }
  }
  onResizeStart(shiftKey) {
    this.resizeStartWidth = this.column.getActualWidth();
    this.lastResizeAmount = 0;
    this.resizeWithShiftKey = shiftKey;
    this.toggleColumnResizing(true);
  }
  toggleColumnResizing(resizing) {
    this.comp.addOrRemoveCssClass("ag-column-resizing", resizing);
  }
  // optionally inverts the drag, depending on pinned and RTL
  // note - this method is duplicated in RenderedHeaderGroupCell - should refactor out?
  normaliseResizeAmount(dragChange) {
    let result = dragChange;
    const notPinningLeft = this.pinned !== "left";
    const pinningRight = this.pinned === "right";
    if (this.gos.get("enableRtl")) {
      if (notPinningLeft) {
        result *= -1;
      }
    } else {
      if (pinningRight) {
        result *= -1;
      }
    }
    return result;
  }
};

// community-modules/core/src/headerRendering/cells/column/selectAllFeature.ts
var SelectAllFeature = class extends BeanStub {
  constructor(column) {
    super();
    this.cbSelectAllVisible = false;
    this.processingEventFromCheckbox = false;
    this.column = column;
  }
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
    this.selectionService = beans.selectionService;
  }
  onSpaceKeyDown(e) {
    const checkbox = this.cbSelectAll;
    if (checkbox.isDisplayed() && !checkbox.getGui().contains(this.gos.getActiveDomElement())) {
      e.preventDefault();
      checkbox.setValue(!checkbox.getValue());
    }
  }
  getCheckboxGui() {
    return this.cbSelectAll.getGui();
  }
  setComp(ctrl) {
    this.headerCellCtrl = ctrl;
    this.cbSelectAll = this.createManagedBean(new AgCheckbox());
    this.cbSelectAll.addCssClass("ag-header-select-all");
    _setAriaRole(this.cbSelectAll.getGui(), "presentation");
    this.showOrHideSelectAll();
    this.addManagedEventListeners({
      newColumnsLoaded: this.onNewColumnsLoaded.bind(this),
      displayedColumnsChanged: this.onDisplayedColumnsChanged.bind(this),
      selectionChanged: this.onSelectionChanged.bind(this),
      paginationChanged: this.onSelectionChanged.bind(this),
      modelUpdated: this.onModelChanged.bind(this)
    });
    this.addManagedListeners(this.cbSelectAll, { fieldValueChanged: this.onCbSelectAll.bind(this) });
    _setAriaHidden(this.cbSelectAll.getGui(), true);
    this.cbSelectAll.getInputElement().setAttribute("tabindex", "-1");
    this.refreshSelectAllLabel();
  }
  onNewColumnsLoaded() {
    this.showOrHideSelectAll();
  }
  onDisplayedColumnsChanged() {
    if (!this.isAlive()) {
      return;
    }
    this.showOrHideSelectAll();
  }
  showOrHideSelectAll() {
    this.cbSelectAllVisible = this.isCheckboxSelection();
    this.cbSelectAll.setDisplayed(this.cbSelectAllVisible, { skipAriaHidden: true });
    if (this.cbSelectAllVisible) {
      this.checkRightRowModelType("selectAllCheckbox");
      this.checkSelectionType("selectAllCheckbox");
      this.updateStateOfCheckbox();
    }
    this.refreshSelectAllLabel();
  }
  onModelChanged() {
    if (!this.cbSelectAllVisible) {
      return;
    }
    this.updateStateOfCheckbox();
  }
  onSelectionChanged() {
    if (!this.cbSelectAllVisible) {
      return;
    }
    this.updateStateOfCheckbox();
  }
  updateStateOfCheckbox() {
    if (this.processingEventFromCheckbox) {
      return;
    }
    this.processingEventFromCheckbox = true;
    const allSelected = this.selectionService.getSelectAllState(this.isFilteredOnly(), this.isCurrentPageOnly());
    this.cbSelectAll.setValue(allSelected);
    const hasNodesToSelect = this.selectionService.hasNodesToSelect(
      this.isFilteredOnly(),
      this.isCurrentPageOnly()
    );
    this.cbSelectAll.setDisabled(!hasNodesToSelect);
    this.refreshSelectAllLabel();
    this.processingEventFromCheckbox = false;
  }
  refreshSelectAllLabel() {
    const translate = this.localeService.getLocaleTextFunc();
    const checked = this.cbSelectAll.getValue();
    const ariaStatus = checked ? translate("ariaChecked", "checked") : translate("ariaUnchecked", "unchecked");
    const ariaLabel = translate("ariaRowSelectAll", "Press Space to toggle all rows selection");
    if (!this.cbSelectAllVisible) {
      this.headerCellCtrl.setAriaDescriptionProperty("selectAll", null);
    } else {
      this.headerCellCtrl.setAriaDescriptionProperty("selectAll", `${ariaLabel} (${ariaStatus})`);
    }
    this.cbSelectAll.setInputAriaLabel(`${ariaLabel} (${ariaStatus})`);
    this.headerCellCtrl.announceAriaDescription();
  }
  checkSelectionType(feature) {
    const isMultiSelect = this.gos.get("rowSelection") === "multiple";
    if (!isMultiSelect) {
      _warnOnce(`${feature} is only available if using 'multiple' rowSelection.`);
      return false;
    }
    return true;
  }
  checkRightRowModelType(feature) {
    const rowModelType = this.rowModel.getType();
    const rowModelMatches = rowModelType === "clientSide" || rowModelType === "serverSide";
    if (!rowModelMatches) {
      _warnOnce(
        `${feature} is only available if using 'clientSide' or 'serverSide' rowModelType, you are using ${rowModelType}.`
      );
      return false;
    }
    return true;
  }
  onCbSelectAll() {
    if (this.processingEventFromCheckbox) {
      return;
    }
    if (!this.cbSelectAllVisible) {
      return;
    }
    const value = this.cbSelectAll.getValue();
    const justFiltered = this.isFilteredOnly();
    const justCurrentPage = this.isCurrentPageOnly();
    let source = "uiSelectAll";
    if (justCurrentPage) {
      source = "uiSelectAllCurrentPage";
    } else if (justFiltered) {
      source = "uiSelectAllFiltered";
    }
    const params = {
      source,
      justFiltered,
      justCurrentPage
    };
    if (value) {
      this.selectionService.selectAllRowNodes(params);
    } else {
      this.selectionService.deselectAllRowNodes(params);
    }
  }
  isCheckboxSelection() {
    let result = this.column.getColDef().headerCheckboxSelection;
    if (typeof result === "function") {
      const func = result;
      const params = this.gos.addGridCommonParams({
        column: this.column,
        colDef: this.column.getColDef()
      });
      result = func(params);
    }
    if (result) {
      return this.checkRightRowModelType("headerCheckboxSelection") && this.checkSelectionType("headerCheckboxSelection");
    }
    return false;
  }
  isFilteredOnly() {
    return !!this.column.getColDef().headerCheckboxSelectionFilteredOnly;
  }
  isCurrentPageOnly() {
    return !!this.column.getColDef().headerCheckboxSelectionCurrentPageOnly;
  }
};

// community-modules/core/src/headerRendering/cells/column/headerCellCtrl.ts
var HeaderCellCtrl = class extends AbstractHeaderCellCtrl {
  constructor(column, beans, parentRowCtrl) {
    super(column, beans, parentRowCtrl);
    this.refreshFunctions = [];
    this.userHeaderClasses = /* @__PURE__ */ new Set();
    this.ariaDescriptionProperties = /* @__PURE__ */ new Map();
    this.column = column;
  }
  setComp(comp, eGui, eResize, eHeaderCompWrapper) {
    this.comp = comp;
    this.setGui(eGui);
    this.updateState();
    this.setupWidth();
    this.setupMovingCss();
    this.setupMenuClass();
    this.setupSortableClass();
    this.setupWrapTextClass();
    this.refreshSpanHeaderHeight();
    this.setupAutoHeight(eHeaderCompWrapper);
    this.addColumnHoverListener();
    this.setupFilterClass();
    this.setupClassesFromColDef();
    this.setupTooltip();
    this.addActiveHeaderMouseListeners();
    this.setupSelectAll();
    this.setupUserComp();
    this.refreshAria();
    this.resizeFeature = this.createManagedBean(
      new ResizeFeature(this.getPinned(), this.column, eResize, comp, this)
    );
    this.createManagedBean(new HoverFeature([this.column], eGui));
    this.createManagedBean(new SetLeftFeature(this.column, eGui, this.beans));
    this.createManagedBean(
      new ManagedFocusFeature(eGui, {
        shouldStopEventPropagation: (e) => this.shouldStopEventPropagation(e),
        onTabKeyDown: () => null,
        handleKeyDown: this.handleKeyDown.bind(this),
        onFocusIn: this.onFocusIn.bind(this),
        onFocusOut: this.onFocusOut.bind(this)
      })
    );
    this.addResizeAndMoveKeyboardListeners();
    this.addManagedPropertyListeners(
      ["suppressMovableColumns", "suppressMenuHide", "suppressAggFuncInHeader"],
      this.refresh.bind(this)
    );
    this.addManagedListeners(this.column, { colDefChanged: this.refresh.bind(this) });
    this.addManagedEventListeners({
      columnValueChanged: this.onColumnValueChanged.bind(this),
      columnRowGroupChanged: this.onColumnRowGroupChanged.bind(this),
      columnPivotChanged: this.onColumnPivotChanged.bind(this),
      headerHeightChanged: this.onHeaderHeightChanged.bind(this)
    });
  }
  resizeHeader(delta, shiftKey) {
    if (!this.column.isResizable()) {
      return;
    }
    const actualWidth = this.column.getActualWidth();
    const minWidth = this.column.getMinWidth();
    const maxWidth = this.column.getMaxWidth();
    const newWidth = Math.min(Math.max(actualWidth + delta, minWidth), maxWidth);
    this.beans.columnSizeService.setColumnWidths(
      [{ key: this.column, newWidth }],
      shiftKey,
      true,
      "uiColumnResized"
    );
  }
  moveHeader(hDirection) {
    const { eGui, column, gos, ctrlsService } = this;
    const pinned = this.getPinned();
    const left = eGui.getBoundingClientRect().left;
    const width = column.getActualWidth();
    const isRtl = gos.get("enableRtl");
    const isLeft = hDirection === 0 /* Left */ !== isRtl;
    const xPosition = normaliseX(isLeft ? left - 20 : left + width + 20, pinned, true, gos, ctrlsService);
    attemptMoveColumns({
      allMovingColumns: [column],
      isFromHeader: true,
      hDirection,
      xPosition,
      pinned,
      fromEnter: false,
      fakeEvent: false,
      gos,
      columnModel: this.beans.columnModel,
      columnMoveService: this.beans.columnMoveService,
      presentedColsService: this.beans.visibleColsService
    });
    ctrlsService.getGridBodyCtrl().getScrollFeature().ensureColumnVisible(column, "auto");
  }
  setupUserComp() {
    const compDetails = this.lookupUserCompDetails();
    this.setCompDetails(compDetails);
  }
  setCompDetails(compDetails) {
    this.userCompDetails = compDetails;
    this.comp.setUserCompDetails(compDetails);
  }
  lookupUserCompDetails() {
    const params = this.createParams();
    const colDef = this.column.getColDef();
    return this.userComponentFactory.getHeaderCompDetails(colDef, params);
  }
  createParams() {
    const params = this.gos.addGridCommonParams({
      column: this.column,
      displayName: this.displayName,
      enableSorting: this.column.isSortable(),
      enableMenu: this.menuEnabled,
      enableFilterButton: this.openFilterEnabled && this.menuService.isHeaderFilterButtonEnabled(this.column),
      enableFilterIcon: !this.openFilterEnabled || this.menuService.isLegacyMenuEnabled(),
      showColumnMenu: (buttonElement) => {
        this.menuService.showColumnMenu({
          column: this.column,
          buttonElement,
          positionBy: "button"
        });
      },
      showColumnMenuAfterMouseClick: (mouseEvent) => {
        this.menuService.showColumnMenu({
          column: this.column,
          mouseEvent,
          positionBy: "mouse"
        });
      },
      showFilter: (buttonElement) => {
        this.menuService.showFilterMenu({
          column: this.column,
          buttonElement,
          containerType: "columnFilter",
          positionBy: "button"
        });
      },
      progressSort: (multiSort) => {
        this.beans.sortController.progressSort(this.column, !!multiSort, "uiColumnSorted");
      },
      setSort: (sort, multiSort) => {
        this.beans.sortController.setSortForColumn(this.column, sort, !!multiSort, "uiColumnSorted");
      },
      eGridHeader: this.getGui(),
      setTooltip: (value, shouldDisplayTooltip) => {
        this.setupTooltip(value, shouldDisplayTooltip);
      }
    });
    return params;
  }
  setupSelectAll() {
    this.selectAllFeature = this.createManagedBean(new SelectAllFeature(this.column));
    this.selectAllFeature.setComp(this);
  }
  getSelectAllGui() {
    return this.selectAllFeature.getCheckboxGui();
  }
  handleKeyDown(e) {
    super.handleKeyDown(e);
    if (e.key === KeyCode.SPACE) {
      this.selectAllFeature.onSpaceKeyDown(e);
    }
    if (e.key === KeyCode.ENTER) {
      this.onEnterKeyDown(e);
    }
    if (e.key === KeyCode.DOWN && e.altKey) {
      this.showMenuOnKeyPress(e, false);
    }
  }
  onEnterKeyDown(e) {
    if (e.ctrlKey || e.metaKey) {
      this.showMenuOnKeyPress(e, true);
    } else if (this.sortable) {
      const multiSort = e.shiftKey;
      this.beans.sortController.progressSort(this.column, multiSort, "uiColumnSorted");
    }
  }
  showMenuOnKeyPress(e, isFilterShortcut) {
    const headerComp = this.comp.getUserCompInstance();
    if (!headerComp || !(headerComp instanceof HeaderComp)) {
      return;
    }
    if (headerComp.onMenuKeyboardShortcut(isFilterShortcut)) {
      e.preventDefault();
    }
  }
  onFocusIn(e) {
    if (!this.getGui().contains(e.relatedTarget)) {
      const rowIndex = this.getRowIndex();
      this.focusService.setFocusedHeader(rowIndex, this.column);
      this.announceAriaDescription();
    }
    if (this.focusService.isKeyboardMode()) {
      this.setActiveHeader(true);
    }
  }
  onFocusOut(e) {
    if (this.getGui().contains(e.relatedTarget)) {
      return;
    }
    this.setActiveHeader(false);
  }
  setupTooltip(value, shouldDisplayTooltip) {
    if (this.tooltipFeature) {
      this.tooltipFeature = this.destroyBean(this.tooltipFeature);
    }
    const isTooltipWhenTruncated = this.gos.get("tooltipShowMode") === "whenTruncated";
    const eGui = this.eGui;
    const colDef = this.column.getColDef();
    if (!shouldDisplayTooltip && isTooltipWhenTruncated && !colDef.headerComponent) {
      shouldDisplayTooltip = () => {
        const textEl = eGui.querySelector(".ag-header-cell-text");
        if (!textEl) {
          return true;
        }
        return textEl.scrollWidth > textEl.clientWidth;
      };
    }
    const tooltipCtrl = {
      getColumn: () => this.column,
      getColDef: () => this.column.getColDef(),
      getGui: () => eGui,
      getLocation: () => "header",
      getTooltipValue: () => {
        if (value != null) {
          return value;
        }
        const res = this.column.getColDef().headerTooltip;
        return res;
      },
      shouldDisplayTooltip
    };
    const tooltipFeature = this.createManagedBean(new TooltipFeature(tooltipCtrl));
    this.refreshFunctions.push(() => tooltipFeature.refreshToolTip());
  }
  setupClassesFromColDef() {
    const refreshHeaderClasses = () => {
      const colDef = this.column.getColDef();
      const classes = _getHeaderClassesFromColDef(colDef, this.gos, this.column, null);
      const oldClasses = this.userHeaderClasses;
      this.userHeaderClasses = new Set(classes);
      classes.forEach((c) => {
        if (oldClasses.has(c)) {
          oldClasses.delete(c);
        } else {
          this.comp.addOrRemoveCssClass(c, true);
        }
      });
      oldClasses.forEach((c) => this.comp.addOrRemoveCssClass(c, false));
    };
    this.refreshFunctions.push(refreshHeaderClasses);
    refreshHeaderClasses();
  }
  setDragSource(eSource) {
    this.dragSourceElement = eSource;
    this.removeDragSource();
    if (!eSource || !this.draggable) {
      return;
    }
    const { column, beans, displayName, dragAndDropService, gos } = this;
    const { columnModel } = beans;
    let hideColumnOnExit = !this.gos.get("suppressDragLeaveHidesColumns");
    const dragSource = this.dragSource = {
      type: 1 /* HeaderCell */,
      eElement: eSource,
      getDefaultIconName: () => hideColumnOnExit ? "hide" : "notAllowed",
      getDragItem: () => this.createDragItem(column),
      dragItemName: displayName,
      onDragStarted: () => {
        hideColumnOnExit = !gos.get("suppressDragLeaveHidesColumns");
        column.setMoving(true, "uiColumnMoved");
      },
      onDragStopped: () => column.setMoving(false, "uiColumnMoved"),
      onGridEnter: (dragItem) => {
        if (hideColumnOnExit) {
          const unlockedColumns = dragItem?.columns?.filter((col) => !col.getColDef().lockVisible) || [];
          columnModel.setColsVisible(unlockedColumns, true, "uiColumnMoved");
        }
      },
      onGridExit: (dragItem) => {
        if (hideColumnOnExit) {
          const unlockedColumns = dragItem?.columns?.filter((col) => !col.getColDef().lockVisible) || [];
          columnModel.setColsVisible(unlockedColumns, false, "uiColumnMoved");
        }
      }
    };
    dragAndDropService.addDragSource(dragSource, true);
  }
  createDragItem(column) {
    const visibleState = {};
    visibleState[column.getId()] = column.isVisible();
    return {
      columns: [column],
      visibleState
    };
  }
  updateState() {
    this.menuEnabled = this.menuService.isColumnMenuInHeaderEnabled(this.column);
    this.openFilterEnabled = this.menuService.isFilterMenuInHeaderEnabled(this.column);
    this.sortable = this.column.isSortable();
    this.displayName = this.calculateDisplayName();
    this.draggable = this.workOutDraggable();
  }
  addRefreshFunction(func) {
    this.refreshFunctions.push(func);
  }
  refresh() {
    this.updateState();
    this.refreshHeaderComp();
    this.refreshAria();
    this.refreshFunctions.forEach((f) => f());
  }
  refreshHeaderComp() {
    const newCompDetails = this.lookupUserCompDetails();
    const compInstance = this.comp.getUserCompInstance();
    const attemptRefresh = compInstance != null && this.userCompDetails.componentClass == newCompDetails.componentClass;
    const headerCompRefreshed = attemptRefresh ? this.attemptHeaderCompRefresh(newCompDetails.params) : false;
    if (headerCompRefreshed) {
      this.setDragSource(this.dragSourceElement);
    } else {
      this.setCompDetails(newCompDetails);
    }
  }
  attemptHeaderCompRefresh(params) {
    const headerComp = this.comp.getUserCompInstance();
    if (!headerComp) {
      return false;
    }
    if (!headerComp.refresh) {
      return false;
    }
    const res = headerComp.refresh(params);
    return res;
  }
  calculateDisplayName() {
    return this.beans.columnNameService.getDisplayNameForColumn(this.column, "header", true);
  }
  checkDisplayName() {
    if (this.displayName !== this.calculateDisplayName()) {
      this.refresh();
    }
  }
  workOutDraggable() {
    const colDef = this.column.getColDef();
    const isSuppressMovableColumns = this.gos.get("suppressMovableColumns");
    const colCanMove = !isSuppressMovableColumns && !colDef.suppressMovable && !colDef.lockPosition;
    return !!colCanMove || !!colDef.enableRowGroup || !!colDef.enablePivot;
  }
  onColumnRowGroupChanged() {
    this.checkDisplayName();
  }
  onColumnPivotChanged() {
    this.checkDisplayName();
  }
  onColumnValueChanged() {
    this.checkDisplayName();
  }
  setupWidth() {
    const listener = () => {
      const columnWidth = this.column.getActualWidth();
      this.comp.setWidth(`${columnWidth}px`);
    };
    this.addManagedListeners(this.column, { widthChanged: listener });
    listener();
  }
  setupMovingCss() {
    const listener = () => {
      this.comp.addOrRemoveCssClass("ag-header-cell-moving", this.column.isMoving());
    };
    this.addManagedListeners(this.column, { movingChanged: listener });
    listener();
  }
  setupMenuClass() {
    const listener = () => {
      this.comp.addOrRemoveCssClass("ag-column-menu-visible", this.column.isMenuVisible());
    };
    this.addManagedListeners(this.column, { menuVisibleChanged: listener });
    listener();
  }
  setupSortableClass() {
    const updateSortableCssClass = () => {
      this.comp.addOrRemoveCssClass("ag-header-cell-sortable", !!this.sortable);
    };
    updateSortableCssClass();
    this.addRefreshFunction(updateSortableCssClass);
    this.addManagedEventListeners({ sortChanged: this.refreshAriaSort.bind(this) });
  }
  setupFilterClass() {
    const listener = () => {
      const isFilterActive = this.column.isFilterActive();
      this.comp.addOrRemoveCssClass("ag-header-cell-filtered", isFilterActive);
      this.refreshAria();
    };
    this.addManagedListeners(this.column, { filterActiveChanged: listener });
    listener();
  }
  setupWrapTextClass() {
    const listener = () => {
      const wrapText = !!this.column.getColDef().wrapHeaderText;
      this.comp.addOrRemoveCssClass("ag-header-cell-wrap-text", wrapText);
    };
    listener();
    this.addRefreshFunction(listener);
  }
  onDisplayedColumnsChanged() {
    super.onDisplayedColumnsChanged();
    if (!this.isAlive()) {
      return;
    }
    this.onHeaderHeightChanged();
  }
  onHeaderHeightChanged() {
    this.refreshSpanHeaderHeight();
  }
  refreshSpanHeaderHeight() {
    const { eGui, column, comp, beans } = this;
    if (!column.isSpanHeaderHeight()) {
      eGui.style.removeProperty("top");
      eGui.style.removeProperty("height");
      comp.addOrRemoveCssClass("ag-header-span-height", false);
      comp.addOrRemoveCssClass("ag-header-span-total", false);
      return;
    }
    const { numberOfParents, isSpanningTotal } = this.column.getColumnGroupPaddingInfo();
    comp.addOrRemoveCssClass("ag-header-span-height", numberOfParents > 0);
    const { columnModel } = beans;
    const headerHeight = columnModel.getColumnHeaderRowHeight();
    if (numberOfParents === 0) {
      comp.addOrRemoveCssClass("ag-header-span-total", false);
      eGui.style.setProperty("top", `0px`);
      eGui.style.setProperty("height", `${headerHeight}px`);
      return;
    }
    comp.addOrRemoveCssClass("ag-header-span-total", isSpanningTotal);
    const pivotMode = columnModel.isPivotMode();
    const groupHeaderHeight = pivotMode ? columnModel.getPivotGroupHeaderHeight() : columnModel.getGroupHeaderHeight();
    const extraHeight = numberOfParents * groupHeaderHeight;
    eGui.style.setProperty("top", `${-extraHeight}px`);
    eGui.style.setProperty("height", `${headerHeight + extraHeight}px`);
  }
  setupAutoHeight(wrapperElement) {
    const { columnModel, resizeObserverService } = this.beans;
    const measureHeight = (timesCalled) => {
      if (!this.isAlive()) {
        return;
      }
      const { paddingTop, paddingBottom, borderBottomWidth, borderTopWidth } = _getElementSize(this.getGui());
      const extraHeight = paddingTop + paddingBottom + borderBottomWidth + borderTopWidth;
      const wrapperHeight = wrapperElement.offsetHeight;
      const autoHeight = wrapperHeight + extraHeight;
      if (timesCalled < 5) {
        const doc = this.beans.gos.getDocument();
        const notYetInDom = !doc || !doc.contains(wrapperElement);
        const possiblyNoContentYet = autoHeight == 0;
        if (notYetInDom || possiblyNoContentYet) {
          window.setTimeout(() => measureHeight(timesCalled + 1), 0);
          return;
        }
      }
      columnModel.setColHeaderHeight(this.column, autoHeight);
    };
    let isMeasuring = false;
    let stopResizeObserver;
    const checkMeasuring = () => {
      const newValue = this.column.isAutoHeaderHeight();
      if (newValue && !isMeasuring) {
        startMeasuring();
      }
      if (!newValue && isMeasuring) {
        stopMeasuring();
      }
    };
    const startMeasuring = () => {
      isMeasuring = true;
      measureHeight(0);
      this.comp.addOrRemoveCssClass("ag-header-cell-auto-height", true);
      stopResizeObserver = resizeObserverService.observeResize(wrapperElement, () => measureHeight(0));
    };
    const stopMeasuring = () => {
      isMeasuring = false;
      if (stopResizeObserver) {
        stopResizeObserver();
      }
      this.comp.addOrRemoveCssClass("ag-header-cell-auto-height", false);
      stopResizeObserver = void 0;
    };
    checkMeasuring();
    this.addDestroyFunc(() => stopMeasuring());
    this.addManagedListeners(this.column, { widthChanged: () => isMeasuring && measureHeight(0) });
    this.addManagedEventListeners({
      sortChanged: () => {
        if (isMeasuring) {
          window.setTimeout(() => measureHeight(0));
        }
      }
    });
    this.addRefreshFunction(checkMeasuring);
  }
  refreshAriaSort() {
    if (this.sortable) {
      const translate = this.localeService.getLocaleTextFunc();
      const sort = this.beans.sortController.getDisplaySortForColumn(this.column) || null;
      this.comp.setAriaSort(_getAriaSortState(sort));
      this.setAriaDescriptionProperty("sort", translate("ariaSortableColumn", "Press ENTER to sort"));
    } else {
      this.comp.setAriaSort();
      this.setAriaDescriptionProperty("sort", null);
    }
  }
  refreshAriaMenu() {
    if (this.menuEnabled) {
      const translate = this.localeService.getLocaleTextFunc();
      this.setAriaDescriptionProperty("menu", translate("ariaMenuColumn", "Press ALT DOWN to open column menu"));
    } else {
      this.setAriaDescriptionProperty("menu", null);
    }
  }
  refreshAriaFilterButton() {
    if (this.openFilterEnabled && !this.menuService.isLegacyMenuEnabled()) {
      const translate = this.localeService.getLocaleTextFunc();
      this.setAriaDescriptionProperty(
        "filterButton",
        translate("ariaFilterColumn", "Press CTRL ENTER to open filter")
      );
    } else {
      this.setAriaDescriptionProperty("filterButton", null);
    }
  }
  refreshAriaFiltered() {
    const translate = this.localeService.getLocaleTextFunc();
    const isFilterActive = this.column.isFilterActive();
    if (isFilterActive) {
      this.setAriaDescriptionProperty("filter", translate("ariaColumnFiltered", "Column Filtered"));
    } else {
      this.setAriaDescriptionProperty("filter", null);
    }
  }
  setAriaDescriptionProperty(property, value) {
    if (value != null) {
      this.ariaDescriptionProperties.set(property, value);
    } else {
      this.ariaDescriptionProperties.delete(property);
    }
  }
  announceAriaDescription() {
    if (!this.eGui.contains(this.beans.gos.getActiveDomElement())) {
      return;
    }
    const ariaDescription = Array.from(this.ariaDescriptionProperties.keys()).sort((a, b) => a === "filter" ? -1 : b.charCodeAt(0) - a.charCodeAt(0)).map((key) => this.ariaDescriptionProperties.get(key)).join(". ");
    this.beans.ariaAnnouncementService.announceValue(ariaDescription);
  }
  refreshAria() {
    this.refreshAriaSort();
    this.refreshAriaMenu();
    this.refreshAriaFilterButton();
    this.refreshAriaFiltered();
  }
  addColumnHoverListener() {
    const listener = () => {
      if (!this.gos.get("columnHoverHighlight")) {
        return;
      }
      const isHovered = this.beans.columnHoverService.isHovered(this.column);
      this.comp.addOrRemoveCssClass("ag-column-hover", isHovered);
    };
    this.addManagedEventListeners({ columnHoverChanged: listener });
    listener();
  }
  getColId() {
    return this.column.getColId();
  }
  addActiveHeaderMouseListeners() {
    const listener = (e) => this.handleMouseOverChange(e.type === "mouseenter");
    const clickListener = () => this.dispatchColumnMouseEvent("columnHeaderClicked", this.column);
    const contextMenuListener = (event) => this.handleContextMenuMouseEvent(event, void 0, this.column);
    this.addManagedListeners(this.getGui(), {
      mouseenter: listener,
      mouseleave: listener,
      click: clickListener,
      contextmenu: contextMenuListener
    });
  }
  handleMouseOverChange(isMouseOver) {
    this.setActiveHeader(isMouseOver);
    const eventType = isMouseOver ? "columnHeaderMouseOver" : "columnHeaderMouseLeave";
    const event = {
      type: eventType,
      column: this.column
    };
    this.eventService.dispatchEvent(event);
  }
  setActiveHeader(active) {
    this.comp.addOrRemoveCssClass("ag-header-active", active);
  }
  getAnchorElementForMenu(isFilter) {
    const headerComp = this.comp.getUserCompInstance();
    if (headerComp instanceof HeaderComp) {
      return headerComp.getAnchorElementForMenu(isFilter);
    }
    return this.getGui();
  }
  destroy() {
    super.destroy();
    this.refreshFunctions = null;
    this.selectAllFeature = null;
    this.dragSourceElement = null;
    this.userCompDetails = null;
    this.userHeaderClasses = null;
    this.ariaDescriptionProperties = null;
  }
};

// community-modules/core/src/headerRendering/cells/columnGroup/groupResizeFeature.ts
var GroupResizeFeature = class extends BeanStub {
  wireBeans(beans) {
    this.horizontalResizeService = beans.horizontalResizeService;
    this.autoWidthCalculator = beans.autoWidthCalculator;
    this.visibleColsService = beans.visibleColsService;
    this.columnSizeService = beans.columnSizeService;
    this.columnAutosizeService = beans.columnAutosizeService;
  }
  constructor(comp, eResize, pinned, columnGroup) {
    super();
    this.eResize = eResize;
    this.comp = comp;
    this.pinned = pinned;
    this.columnGroup = columnGroup;
  }
  postConstruct() {
    if (!this.columnGroup.isResizable()) {
      this.comp.setResizableDisplayed(false);
      return;
    }
    const finishedWithResizeFunc = this.horizontalResizeService.addResizeBar({
      eResizeBar: this.eResize,
      onResizeStart: this.onResizeStart.bind(this),
      onResizing: this.onResizing.bind(this, false),
      onResizeEnd: this.onResizing.bind(this, true)
    });
    this.addDestroyFunc(finishedWithResizeFunc);
    if (!this.gos.get("suppressAutoSize")) {
      const skipHeaderOnAutoSize = this.gos.get("skipHeaderOnAutoSize");
      this.eResize.addEventListener("dblclick", () => {
        const keys = [];
        const leafCols = this.columnGroup.getDisplayedLeafColumns();
        leafCols.forEach((column) => {
          if (!column.getColDef().suppressAutoSize) {
            keys.push(column.getColId());
          }
        });
        if (keys.length > 0) {
          this.columnAutosizeService.autoSizeCols({
            colKeys: keys,
            skipHeader: skipHeaderOnAutoSize,
            stopAtGroup: this.columnGroup,
            source: "uiColumnResized"
          });
        }
        this.resizeLeafColumnsToFit("uiColumnResized");
      });
    }
  }
  onResizeStart(shiftKey) {
    const initialValues = this.getInitialValues(shiftKey);
    this.storeLocalValues(initialValues);
    this.toggleColumnResizing(true);
  }
  onResizing(finished, resizeAmount, source = "uiColumnResized") {
    const resizeAmountNormalised = this.normaliseDragChange(resizeAmount);
    const width = this.resizeStartWidth + resizeAmountNormalised;
    this.resizeColumnsFromLocalValues(width, source, finished);
  }
  getInitialValues(shiftKey) {
    const columnsToResize = this.getColumnsToResize();
    const resizeStartWidth = this.getInitialSizeOfColumns(columnsToResize);
    const resizeRatios = this.getSizeRatiosOfColumns(columnsToResize, resizeStartWidth);
    const columnSizeAndRatios = {
      columnsToResize,
      resizeStartWidth,
      resizeRatios
    };
    let groupAfter = null;
    if (shiftKey) {
      groupAfter = this.visibleColsService.getGroupAtDirection(this.columnGroup, "After");
    }
    if (groupAfter) {
      const takeFromLeafCols = groupAfter.getDisplayedLeafColumns();
      const groupAfterColumns = columnSizeAndRatios.groupAfterColumns = takeFromLeafCols.filter(
        (col) => col.isResizable()
      );
      const groupAfterStartWidth = columnSizeAndRatios.groupAfterStartWidth = this.getInitialSizeOfColumns(groupAfterColumns);
      columnSizeAndRatios.groupAfterRatios = this.getSizeRatiosOfColumns(groupAfterColumns, groupAfterStartWidth);
    } else {
      columnSizeAndRatios.groupAfterColumns = void 0;
      columnSizeAndRatios.groupAfterStartWidth = void 0;
      columnSizeAndRatios.groupAfterRatios = void 0;
    }
    return columnSizeAndRatios;
  }
  storeLocalValues(initialValues) {
    const {
      columnsToResize,
      resizeStartWidth,
      resizeRatios,
      groupAfterColumns,
      groupAfterStartWidth,
      groupAfterRatios
    } = initialValues;
    this.resizeCols = columnsToResize;
    this.resizeStartWidth = resizeStartWidth;
    this.resizeRatios = resizeRatios;
    this.resizeTakeFromCols = groupAfterColumns;
    this.resizeTakeFromStartWidth = groupAfterStartWidth;
    this.resizeTakeFromRatios = groupAfterRatios;
  }
  clearLocalValues() {
    this.resizeCols = void 0;
    this.resizeRatios = void 0;
    this.resizeTakeFromCols = void 0;
    this.resizeTakeFromRatios = void 0;
  }
  resizeLeafColumnsToFit(source) {
    const preferredSize = this.autoWidthCalculator.getPreferredWidthForColumnGroup(this.columnGroup);
    const initialValues = this.getInitialValues();
    if (preferredSize > initialValues.resizeStartWidth) {
      this.resizeColumns(initialValues, preferredSize, source, true);
    }
  }
  resizeColumnsFromLocalValues(totalWidth, source, finished = true) {
    if (!this.resizeCols || !this.resizeRatios) {
      return;
    }
    const initialValues = {
      columnsToResize: this.resizeCols,
      resizeStartWidth: this.resizeStartWidth,
      resizeRatios: this.resizeRatios,
      groupAfterColumns: this.resizeTakeFromCols ?? void 0,
      groupAfterStartWidth: this.resizeTakeFromStartWidth ?? void 0,
      groupAfterRatios: this.resizeTakeFromRatios ?? void 0
    };
    this.resizeColumns(initialValues, totalWidth, source, finished);
  }
  resizeColumns(initialValues, totalWidth, source, finished = true) {
    const {
      columnsToResize,
      resizeStartWidth,
      resizeRatios,
      groupAfterColumns,
      groupAfterStartWidth,
      groupAfterRatios
    } = initialValues;
    const resizeSets = [];
    resizeSets.push({
      columns: columnsToResize,
      ratios: resizeRatios,
      width: totalWidth
    });
    if (groupAfterColumns) {
      const diff = totalWidth - resizeStartWidth;
      resizeSets.push({
        columns: groupAfterColumns,
        ratios: groupAfterRatios,
        width: groupAfterStartWidth - diff
      });
    }
    this.columnSizeService.resizeColumnSets({
      resizeSets,
      finished,
      source
    });
    if (finished) {
      this.toggleColumnResizing(false);
    }
  }
  toggleColumnResizing(resizing) {
    this.comp.addOrRemoveCssClass("ag-column-resizing", resizing);
  }
  getColumnsToResize() {
    const leafCols = this.columnGroup.getDisplayedLeafColumns();
    return leafCols.filter((col) => col.isResizable());
  }
  getInitialSizeOfColumns(columns) {
    return columns.reduce((totalWidth, column) => totalWidth + column.getActualWidth(), 0);
  }
  getSizeRatiosOfColumns(columns, initialSizeOfColumns) {
    return columns.map((column) => column.getActualWidth() / initialSizeOfColumns);
  }
  // optionally inverts the drag, depending on pinned and RTL
  // note - this method is duplicated in RenderedHeaderCell - should refactor out?
  normaliseDragChange(dragChange) {
    let result = dragChange;
    if (this.gos.get("enableRtl")) {
      if (this.pinned !== "left") {
        result *= -1;
      }
    } else if (this.pinned === "right") {
      result *= -1;
    }
    return result;
  }
  destroy() {
    super.destroy();
    this.clearLocalValues();
  }
};

// community-modules/core/src/headerRendering/cells/columnGroup/groupWidthFeature.ts
var GroupWidthFeature = class extends BeanStub {
  constructor(comp, columnGroup) {
    super();
    // the children can change, we keep destroy functions related to listening to the children here
    this.removeChildListenersFuncs = [];
    this.columnGroup = columnGroup;
    this.comp = comp;
  }
  postConstruct() {
    this.addListenersToChildrenColumns();
    this.addManagedListeners(this.columnGroup, {
      displayedChildrenChanged: this.onDisplayedChildrenChanged.bind(this)
    });
    this.onWidthChanged();
    this.addDestroyFunc(this.removeListenersOnChildrenColumns.bind(this));
  }
  addListenersToChildrenColumns() {
    this.removeListenersOnChildrenColumns();
    const widthChangedListener = this.onWidthChanged.bind(this);
    this.columnGroup.getLeafColumns().forEach((column) => {
      column.addEventListener("widthChanged", widthChangedListener);
      column.addEventListener("visibleChanged", widthChangedListener);
      this.removeChildListenersFuncs.push(() => {
        column.removeEventListener("widthChanged", widthChangedListener);
        column.removeEventListener("visibleChanged", widthChangedListener);
      });
    });
  }
  removeListenersOnChildrenColumns() {
    this.removeChildListenersFuncs.forEach((func) => func());
    this.removeChildListenersFuncs = [];
  }
  onDisplayedChildrenChanged() {
    this.addListenersToChildrenColumns();
    this.onWidthChanged();
  }
  onWidthChanged() {
    const columnWidth = this.columnGroup.getActualWidth();
    this.comp.setWidth(`${columnWidth}px`);
    this.comp.addOrRemoveCssClass("ag-hidden", columnWidth === 0);
  }
};

// community-modules/core/src/headerRendering/cells/columnGroup/headerGroupCellCtrl.ts
var HeaderGroupCellCtrl = class extends AbstractHeaderCellCtrl {
  constructor(columnGroup, beans, parentRowCtrl) {
    super(columnGroup, beans, parentRowCtrl);
    this.onSuppressColMoveChange = () => {
      if (!this.isAlive() || this.isSuppressMoving()) {
        this.removeDragSource();
      } else {
        if (!this.dragSource) {
          const eGui = this.getGui();
          this.setDragSource(eGui);
        }
      }
    };
    this.column = columnGroup;
  }
  setComp(comp, eGui, eResize) {
    this.comp = comp;
    this.setGui(eGui);
    this.displayName = this.beans.columnNameService.getDisplayNameForColumnGroup(this.column, "header");
    this.addClasses();
    this.setupMovingCss();
    this.setupExpandable();
    this.setupTooltip();
    this.addDestroyFunc(() => {
      if (this.tooltipFeature) {
        this.tooltipFeature = this.destroyBean(this.tooltipFeature);
      }
    });
    this.setupUserComp();
    this.addHeaderMouseListeners();
    const pinned = this.getParentRowCtrl().getPinned();
    const leafCols = this.column.getProvidedColumnGroup().getLeafColumns();
    this.createManagedBean(new HoverFeature(leafCols, eGui));
    this.createManagedBean(new SetLeftFeature(this.column, eGui, this.beans));
    this.createManagedBean(new GroupWidthFeature(comp, this.column));
    this.resizeFeature = this.createManagedBean(new GroupResizeFeature(comp, eResize, pinned, this.column));
    this.createManagedBean(
      new ManagedFocusFeature(eGui, {
        shouldStopEventPropagation: this.shouldStopEventPropagation.bind(this),
        onTabKeyDown: () => void 0,
        handleKeyDown: this.handleKeyDown.bind(this),
        onFocusIn: this.onFocusIn.bind(this)
      })
    );
    this.addManagedPropertyListener("suppressMovableColumns", this.onSuppressColMoveChange);
    this.addResizeAndMoveKeyboardListeners();
  }
  resizeHeader(delta, shiftKey) {
    if (!this.resizeFeature) {
      return;
    }
    const initialValues = this.resizeFeature.getInitialValues(shiftKey);
    this.resizeFeature.resizeColumns(
      initialValues,
      initialValues.resizeStartWidth + delta,
      "uiColumnResized",
      true
    );
  }
  moveHeader(hDirection) {
    const { beans, eGui, column, gos, ctrlsService } = this;
    const isRtl = gos.get("enableRtl");
    const isLeft = hDirection === 0 /* Left */;
    const pinned = this.getPinned();
    const rect = eGui.getBoundingClientRect();
    const left = rect.left;
    const width = rect.width;
    const xPosition = normaliseX(isLeft !== isRtl ? left - 20 : left + width + 20, pinned, true, gos, ctrlsService);
    const id = column.getGroupId();
    const headerPosition = this.focusService.getFocusedHeader();
    attemptMoveColumns({
      allMovingColumns: this.column.getLeafColumns(),
      isFromHeader: true,
      hDirection,
      xPosition,
      pinned,
      fromEnter: false,
      fakeEvent: false,
      gos,
      columnModel: beans.columnModel,
      columnMoveService: beans.columnMoveService,
      presentedColsService: beans.visibleColsService
    });
    const displayedLeafColumns = column.getDisplayedLeafColumns();
    const targetColumn = isLeft ? displayedLeafColumns[0] : _last(displayedLeafColumns);
    this.ctrlsService.getGridBodyCtrl().getScrollFeature().ensureColumnVisible(targetColumn, "auto");
    if (!this.isAlive() && headerPosition) {
      this.restoreFocus(id, column, headerPosition);
    }
  }
  restoreFocus(groupId, previousColumnGroup, previousPosition) {
    const leafCols = previousColumnGroup.getLeafColumns();
    if (!leafCols.length) {
      return;
    }
    const parent = leafCols[0].getParent();
    if (!parent) {
      return;
    }
    const newColumnGroup = this.findGroupWidthId(parent, groupId);
    if (newColumnGroup) {
      this.focusService.focusHeaderPosition({
        headerPosition: {
          ...previousPosition,
          column: newColumnGroup
        }
      });
    }
  }
  findGroupWidthId(columnGroup, id) {
    while (columnGroup) {
      if (columnGroup.getGroupId() === id) {
        return columnGroup;
      }
      columnGroup = columnGroup.getParent();
    }
    return null;
  }
  resizeLeafColumnsToFit(source) {
    if (!this.resizeFeature) {
      return;
    }
    this.resizeFeature.resizeLeafColumnsToFit(source);
  }
  setupUserComp() {
    const params = this.gos.addGridCommonParams({
      displayName: this.displayName,
      columnGroup: this.column,
      setExpanded: (expanded) => {
        this.beans.columnModel.setColumnGroupOpened(
          this.column.getProvidedColumnGroup(),
          expanded,
          "gridInitializing"
        );
      },
      setTooltip: (value, shouldDisplayTooltip) => {
        this.setupTooltip(value, shouldDisplayTooltip);
      }
    });
    const compDetails = this.userComponentFactory.getHeaderGroupCompDetails(params);
    this.comp.setUserCompDetails(compDetails);
  }
  addHeaderMouseListeners() {
    const listener = (e) => this.handleMouseOverChange(e.type === "mouseenter");
    const clickListener = () => this.dispatchColumnMouseEvent("columnHeaderClicked", this.column.getProvidedColumnGroup());
    const contextMenuListener = (event) => this.handleContextMenuMouseEvent(event, void 0, this.column.getProvidedColumnGroup());
    this.addManagedListeners(this.getGui(), {
      mouseenter: listener,
      mouseleave: listener,
      click: clickListener,
      contextmenu: contextMenuListener
    });
  }
  handleMouseOverChange(isMouseOver) {
    const eventType = isMouseOver ? "columnHeaderMouseOver" : "columnHeaderMouseLeave";
    const event = {
      type: eventType,
      column: this.column.getProvidedColumnGroup()
    };
    this.eventService.dispatchEvent(event);
  }
  setupTooltip(value, shouldDisplayTooltip) {
    if (this.tooltipFeature) {
      this.tooltipFeature = this.destroyBean(this.tooltipFeature);
    }
    const colGroupDef = this.column.getColGroupDef();
    const isTooltipWhenTruncated = this.gos.get("tooltipShowMode") === "whenTruncated";
    const eGui = this.eGui;
    if (!shouldDisplayTooltip && isTooltipWhenTruncated && !colGroupDef?.headerGroupComponent) {
      shouldDisplayTooltip = () => {
        const textEl = eGui.querySelector(".ag-header-group-text");
        if (!textEl) {
          return true;
        }
        return textEl.scrollWidth > textEl.clientWidth;
      };
    }
    const tooltipCtrl = {
      getColumn: () => this.column,
      getGui: () => eGui,
      getLocation: () => "headerGroup",
      getTooltipValue: () => value ?? (colGroupDef && colGroupDef.headerTooltip),
      shouldDisplayTooltip
    };
    if (colGroupDef) {
      tooltipCtrl.getColDef = () => colGroupDef;
    }
    this.createManagedBean(new TooltipFeature(tooltipCtrl));
  }
  setupExpandable() {
    const providedColGroup = this.column.getProvidedColumnGroup();
    this.refreshExpanded();
    const listener = this.refreshExpanded.bind(this);
    this.addManagedListeners(providedColGroup, {
      expandedChanged: listener,
      expandableChanged: listener
    });
  }
  refreshExpanded() {
    const { column } = this;
    this.expandable = column.isExpandable();
    const expanded = column.isExpanded();
    if (this.expandable) {
      this.comp.setAriaExpanded(expanded ? "true" : "false");
    } else {
      this.comp.setAriaExpanded(void 0);
    }
  }
  getColId() {
    return this.column.getUniqueId();
  }
  addClasses() {
    const colGroupDef = this.column.getColGroupDef();
    const classes = _getHeaderClassesFromColDef(colGroupDef, this.gos, null, this.column);
    if (this.column.isPadding()) {
      classes.push("ag-header-group-cell-no-group");
      const leafCols = this.column.getLeafColumns();
      if (leafCols.every((col) => col.isSpanHeaderHeight())) {
        classes.push("ag-header-span-height");
      }
    } else {
      classes.push("ag-header-group-cell-with-group");
    }
    classes.forEach((c) => this.comp.addOrRemoveCssClass(c, true));
  }
  setupMovingCss() {
    const providedColumnGroup = this.column.getProvidedColumnGroup();
    const leafColumns = providedColumnGroup.getLeafColumns();
    const listener = () => this.comp.addOrRemoveCssClass("ag-header-cell-moving", this.column.isMoving());
    leafColumns.forEach((col) => {
      this.addManagedListeners(col, { movingChanged: listener });
    });
    listener();
  }
  onFocusIn(e) {
    if (!this.eGui.contains(e.relatedTarget)) {
      const rowIndex = this.getRowIndex();
      this.beans.focusService.setFocusedHeader(rowIndex, this.column);
    }
  }
  handleKeyDown(e) {
    super.handleKeyDown(e);
    const wrapperHasFocus = this.getWrapperHasFocus();
    if (!this.expandable || !wrapperHasFocus) {
      return;
    }
    if (e.key === KeyCode.ENTER) {
      const column = this.column;
      const newExpandedValue = !column.isExpanded();
      this.beans.columnModel.setColumnGroupOpened(
        column.getProvidedColumnGroup(),
        newExpandedValue,
        "uiColumnExpanded"
      );
    }
  }
  // unlike columns, this will only get called once, as we don't react on props on column groups
  // (we will always destroy and recreate this comp if something changes)
  setDragSource(eHeaderGroup) {
    if (!this.isAlive() || this.isSuppressMoving()) {
      return;
    }
    this.removeDragSource();
    if (!eHeaderGroup) {
      return;
    }
    const { beans, column, displayName, gos, dragAndDropService } = this;
    const { columnModel } = beans;
    const allLeafColumns = column.getProvidedColumnGroup().getLeafColumns();
    let hideColumnOnExit = !gos.get("suppressDragLeaveHidesColumns");
    const dragSource = this.dragSource = {
      type: 1 /* HeaderCell */,
      eElement: eHeaderGroup,
      getDefaultIconName: () => hideColumnOnExit ? "hide" : "notAllowed",
      dragItemName: displayName,
      // we add in the original group leaf columns, so we move both visible and non-visible items
      getDragItem: () => this.getDragItemForGroup(column),
      onDragStarted: () => {
        hideColumnOnExit = !gos.get("suppressDragLeaveHidesColumns");
        allLeafColumns.forEach((col) => col.setMoving(true, "uiColumnDragged"));
      },
      onDragStopped: () => allLeafColumns.forEach((col) => col.setMoving(false, "uiColumnDragged")),
      onGridEnter: (dragItem) => {
        if (hideColumnOnExit) {
          const unlockedColumns = dragItem?.columns?.filter((col) => !col.getColDef().lockVisible) || [];
          columnModel.setColsVisible(unlockedColumns, true, "uiColumnMoved");
        }
      },
      onGridExit: (dragItem) => {
        if (hideColumnOnExit) {
          const unlockedColumns = dragItem?.columns?.filter((col) => !col.getColDef().lockVisible) || [];
          columnModel.setColsVisible(unlockedColumns, false, "uiColumnMoved");
        }
      }
    };
    dragAndDropService.addDragSource(dragSource, true);
  }
  // when moving the columns, we want to move all the columns (contained within the DragItem) in this group in one go,
  // and in the order they are currently in the screen.
  getDragItemForGroup(columnGroup) {
    const allColumnsOriginalOrder = columnGroup.getProvidedColumnGroup().getLeafColumns();
    const visibleState = {};
    allColumnsOriginalOrder.forEach((column) => visibleState[column.getId()] = column.isVisible());
    const allColumnsCurrentOrder = [];
    this.beans.visibleColsService.getAllCols().forEach((column) => {
      if (allColumnsOriginalOrder.indexOf(column) >= 0) {
        allColumnsCurrentOrder.push(column);
        _removeFromArray(allColumnsOriginalOrder, column);
      }
    });
    allColumnsOriginalOrder.forEach((column) => allColumnsCurrentOrder.push(column));
    return {
      columns: allColumnsCurrentOrder,
      visibleState
    };
  }
  isSuppressMoving() {
    let childSuppressesMoving = false;
    this.column.getLeafColumns().forEach((column) => {
      if (column.getColDef().suppressMovable || column.getColDef().lockPosition) {
        childSuppressesMoving = true;
      }
    });
    const result = childSuppressesMoving || this.gos.get("suppressMovableColumns");
    return result;
  }
};

// community-modules/core/src/headerRendering/row/headerRowCtrl.ts
var instanceIdSequence3 = 0;
var HeaderRowCtrl = class extends BeanStub {
  constructor(rowIndex, pinned, type) {
    super();
    this.instanceId = instanceIdSequence3++;
    this.rowIndex = rowIndex;
    this.pinned = pinned;
    this.type = type;
    const typeClass = type == "group" /* COLUMN_GROUP */ ? `ag-header-row-column-group` : type == "filter" /* FLOATING_FILTER */ ? `ag-header-row-column-filter` : `ag-header-row-column`;
    this.headerRowClass = `ag-header-row ${typeClass}`;
  }
  wireBeans(beans) {
    this.beans = beans;
  }
  postConstruct() {
    this.isPrintLayout = this.gos.isDomLayout("print");
    this.isEnsureDomOrder = this.gos.get("ensureDomOrder");
  }
  getInstanceId() {
    return this.instanceId;
  }
  /** Checks that every header cell that is currently visible has been rendered.
   * Can only be false under some circumstances when using React
   */
  areCellsRendered() {
    if (!this.comp) {
      return false;
    }
    return this.getHeaderCellCtrls().every((ctrl) => ctrl.getGui() != null);
  }
  /**
   *
   * @param comp Proxy to the actual component
   * @param initCompState Should the component be initialised with the current state of the controller. Default: true
   */
  setComp(comp, initCompState = true) {
    this.comp = comp;
    if (initCompState) {
      this.onRowHeightChanged();
      this.onVirtualColumnsChanged();
    }
    this.setWidth();
    this.addEventListeners();
  }
  getHeaderRowClass() {
    return this.headerRowClass;
  }
  getAriaRowIndex() {
    return this.rowIndex + 1;
  }
  addEventListeners() {
    const onHeightChanged = this.onRowHeightChanged.bind(this);
    this.addManagedEventListeners({
      columnResized: this.onColumnResized.bind(this),
      displayedColumnsChanged: this.onDisplayedColumnsChanged.bind(this),
      virtualColumnsChanged: (params) => this.onVirtualColumnsChanged(params.afterScroll),
      columnHeaderHeightChanged: onHeightChanged,
      gridStylesChanged: onHeightChanged,
      advancedFilterEnabledChanged: onHeightChanged
    });
    this.addManagedPropertyListener("domLayout", this.onDisplayedColumnsChanged.bind(this));
    this.addManagedPropertyListener("ensureDomOrder", (e) => this.isEnsureDomOrder = e.currentValue);
    this.addManagedPropertyListeners(
      [
        "headerHeight",
        "pivotHeaderHeight",
        "groupHeaderHeight",
        "pivotGroupHeaderHeight",
        "floatingFiltersHeight"
      ],
      onHeightChanged
    );
  }
  getHeaderCellCtrl(column) {
    if (!this.headerCellCtrls) {
      return;
    }
    return _values(this.headerCellCtrls).find((cellCtrl) => cellCtrl.getColumnGroupChild() === column);
  }
  onDisplayedColumnsChanged() {
    this.isPrintLayout = this.gos.isDomLayout("print");
    this.onVirtualColumnsChanged();
    this.setWidth();
    this.onRowHeightChanged();
  }
  getType() {
    return this.type;
  }
  onColumnResized() {
    this.setWidth();
  }
  setWidth() {
    const width = this.getWidthForRow();
    this.comp.setWidth(`${width}px`);
  }
  getWidthForRow() {
    const { visibleColsService: presentedColsService } = this.beans;
    if (this.isPrintLayout) {
      const pinned = this.pinned != null;
      if (pinned) {
        return 0;
      }
      return presentedColsService.getContainerWidth("right") + presentedColsService.getContainerWidth("left") + presentedColsService.getContainerWidth(null);
    }
    return presentedColsService.getContainerWidth(this.pinned);
  }
  onRowHeightChanged() {
    const { topOffset, rowHeight } = this.getTopAndHeight();
    this.comp.setTop(topOffset + "px");
    this.comp.setHeight(rowHeight + "px");
  }
  getTopAndHeight() {
    const { columnModel, filterManager } = this.beans;
    let headerRowCount = columnModel.getHeaderRowCount();
    const sizes = [];
    let numberOfFloating = 0;
    if (filterManager?.hasFloatingFilters()) {
      headerRowCount++;
      numberOfFloating = 1;
    }
    const groupHeight = columnModel.getColumnGroupHeaderRowHeight();
    const headerHeight = columnModel.getColumnHeaderRowHeight();
    const numberOfNonGroups = 1 + numberOfFloating;
    const numberOfGroups = headerRowCount - numberOfNonGroups;
    for (let i = 0; i < numberOfGroups; i++) {
      sizes.push(groupHeight);
    }
    sizes.push(headerHeight);
    for (let i = 0; i < numberOfFloating; i++) {
      sizes.push(columnModel.getFloatingFiltersHeight());
    }
    let topOffset = 0;
    for (let i = 0; i < this.rowIndex; i++) {
      topOffset += sizes[i];
    }
    const rowHeight = sizes[this.rowIndex];
    return { topOffset, rowHeight };
  }
  getPinned() {
    return this.pinned;
  }
  getRowIndex() {
    return this.rowIndex;
  }
  onVirtualColumnsChanged(afterScroll = false) {
    const ctrlsToDisplay = this.getHeaderCtrls();
    const forceOrder = this.isEnsureDomOrder || this.isPrintLayout;
    this.comp.setHeaderCtrls(ctrlsToDisplay, forceOrder, afterScroll);
  }
  getHeaderCtrls() {
    const oldCtrls = this.headerCellCtrls;
    this.headerCellCtrls = /* @__PURE__ */ new Map();
    const columns = this.getColumnsInViewport();
    for (const child of columns) {
      this.recycleAndCreateHeaderCtrls(child, oldCtrls);
    }
    const isFocusedAndDisplayed = (ctrl) => {
      const { focusService, visibleColsService } = this.beans;
      const isFocused = focusService.isHeaderWrapperFocused(ctrl);
      if (!isFocused) {
        return false;
      }
      const isDisplayed = visibleColsService.isVisible(ctrl.getColumnGroupChild());
      return isDisplayed;
    };
    if (oldCtrls) {
      for (const [id, oldCtrl] of oldCtrls) {
        const keepCtrl = isFocusedAndDisplayed(oldCtrl);
        if (keepCtrl) {
          this.headerCellCtrls.set(id, oldCtrl);
        } else {
          this.destroyBean(oldCtrl);
        }
      }
    }
    return this.getHeaderCellCtrls();
  }
  getHeaderCellCtrls() {
    return Array.from(this.headerCellCtrls?.values() ?? []);
  }
  recycleAndCreateHeaderCtrls(headerColumn, oldCtrls) {
    if (!this.headerCellCtrls) {
      return;
    }
    if (headerColumn.isEmptyGroup()) {
      return;
    }
    const idOfChild = headerColumn.getUniqueId();
    let headerCtrl;
    if (oldCtrls) {
      headerCtrl = oldCtrls.get(idOfChild);
      oldCtrls.delete(idOfChild);
    }
    const forOldColumn = headerCtrl && headerCtrl.getColumnGroupChild() != headerColumn;
    if (forOldColumn) {
      this.destroyBean(headerCtrl);
      headerCtrl = void 0;
    }
    if (headerCtrl == null) {
      switch (this.type) {
        case "filter" /* FLOATING_FILTER */: {
          headerCtrl = this.createBean(
            this.beans.ctrlsFactory.getInstance(
              "headerFilterCell",
              headerColumn,
              this.beans,
              this
            )
          );
          break;
        }
        case "group" /* COLUMN_GROUP */:
          headerCtrl = this.createBean(
            new HeaderGroupCellCtrl(headerColumn, this.beans, this)
          );
          break;
        default:
          headerCtrl = this.createBean(new HeaderCellCtrl(headerColumn, this.beans, this));
          break;
      }
    }
    this.headerCellCtrls.set(idOfChild, headerCtrl);
  }
  getColumnsInViewport() {
    return this.isPrintLayout ? this.getColumnsInViewportPrintLayout() : this.getColumnsInViewportNormalLayout();
  }
  getColumnsInViewportPrintLayout() {
    if (this.pinned != null) {
      return [];
    }
    let viewportColumns = [];
    const actualDepth = this.getActualDepth();
    const { columnViewportService } = this.beans;
    ["left", null, "right"].forEach((pinned) => {
      const items = columnViewportService.getHeadersToRender(pinned, actualDepth);
      viewportColumns = viewportColumns.concat(items);
    });
    return viewportColumns;
  }
  getActualDepth() {
    return this.type == "filter" /* FLOATING_FILTER */ ? this.rowIndex - 1 : this.rowIndex;
  }
  getColumnsInViewportNormalLayout() {
    return this.beans.columnViewportService.getHeadersToRender(this.pinned, this.getActualDepth());
  }
  findHeaderCellCtrl(column) {
    if (!this.headerCellCtrls) {
      return;
    }
    const allCtrls = this.getHeaderCellCtrls();
    const ctrl = allCtrls.find((ctrl2) => ctrl2.getColumnGroupChild() == column);
    return ctrl;
  }
  focusHeader(column, event) {
    const ctrl = this.findHeaderCellCtrl(column);
    if (!ctrl) {
      return false;
    }
    const focused = ctrl.focus(event);
    return focused;
  }
  destroy() {
    if (this.headerCellCtrls) {
      this.headerCellCtrls.forEach((ctrl) => {
        this.destroyBean(ctrl);
      });
    }
    this.headerCellCtrls = void 0;
    super.destroy();
  }
};

// community-modules/core/src/headerRendering/rowContainer/headerRowContainerCtrl.ts
var HeaderRowContainerCtrl = class extends BeanStub {
  constructor(pinned) {
    super();
    this.hidden = false;
    this.includeFloatingFilter = false;
    this.groupsRowCtrls = [];
    this.pinned = pinned;
  }
  wireBeans(beans) {
    this.ctrlsService = beans.ctrlsService;
    this.scrollVisibleService = beans.scrollVisibleService;
    this.pinnedWidthService = beans.pinnedWidthService;
    this.columnModel = beans.columnModel;
    this.focusService = beans.focusService;
    this.filterManager = beans.filterManager;
  }
  setComp(comp, eGui) {
    this.comp = comp;
    this.eViewport = eGui;
    this.setupCenterWidth();
    this.setupPinnedWidth();
    this.setupDragAndDrop(this.eViewport);
    const onDisplayedColsChanged = this.onDisplayedColumnsChanged.bind(this);
    this.addManagedEventListeners({
      gridColumnsChanged: this.onGridColumnsChanged.bind(this),
      displayedColumnsChanged: onDisplayedColsChanged,
      advancedFilterEnabledChanged: onDisplayedColsChanged
    });
    this.ctrlsService.registerHeaderContainer(this, this.pinned);
    if (this.columnModel.isReady()) {
      this.refresh();
    }
  }
  getAllCtrls() {
    const res = [...this.groupsRowCtrls];
    if (this.columnsRowCtrl) {
      res.push(this.columnsRowCtrl);
    }
    if (this.filtersRowCtrl) {
      res.push(this.filtersRowCtrl);
    }
    return res;
  }
  refresh(keepColumns = false) {
    const sequence = new NumberSequence();
    const focusedHeaderPosition = this.focusService.getFocusHeaderToUseAfterRefresh();
    const refreshColumnGroups = () => {
      const groupRowCount = this.columnModel.getHeaderRowCount() - 1;
      this.groupsRowCtrls = this.destroyBeans(this.groupsRowCtrls);
      for (let i = 0; i < groupRowCount; i++) {
        const ctrl = this.createBean(
          new HeaderRowCtrl(sequence.next(), this.pinned, "group" /* COLUMN_GROUP */)
        );
        this.groupsRowCtrls.push(ctrl);
      }
    };
    const refreshColumns = () => {
      const rowIndex = sequence.next();
      const needNewInstance = !this.hidden && (this.columnsRowCtrl == null || !keepColumns || this.columnsRowCtrl.getRowIndex() !== rowIndex);
      const shouldDestroyInstance = needNewInstance || this.hidden;
      if (shouldDestroyInstance) {
        this.columnsRowCtrl = this.destroyBean(this.columnsRowCtrl);
      }
      if (needNewInstance) {
        this.columnsRowCtrl = this.createBean(new HeaderRowCtrl(rowIndex, this.pinned, "column" /* COLUMN */));
      }
    };
    const refreshFilters = () => {
      this.includeFloatingFilter = !!this.filterManager?.hasFloatingFilters() && !this.hidden;
      const destroyPreviousComp = () => {
        this.filtersRowCtrl = this.destroyBean(this.filtersRowCtrl);
      };
      if (!this.includeFloatingFilter) {
        destroyPreviousComp();
        return;
      }
      const rowIndex = sequence.next();
      if (this.filtersRowCtrl) {
        const rowIndexMismatch = this.filtersRowCtrl.getRowIndex() !== rowIndex;
        if (!keepColumns || rowIndexMismatch) {
          destroyPreviousComp();
        }
      }
      if (!this.filtersRowCtrl) {
        this.filtersRowCtrl = this.createBean(
          new HeaderRowCtrl(rowIndex, this.pinned, "filter" /* FLOATING_FILTER */)
        );
      }
    };
    refreshColumnGroups();
    refreshColumns();
    refreshFilters();
    const allCtrls = this.getAllCtrls();
    this.comp.setCtrls(allCtrls);
    this.restoreFocusOnHeader(focusedHeaderPosition);
  }
  getHeaderCtrlForColumn(column) {
    if (isColumn(column)) {
      if (!this.columnsRowCtrl) {
        return;
      }
      return this.columnsRowCtrl.getHeaderCellCtrl(column);
    }
    if (this.groupsRowCtrls.length === 0) {
      return;
    }
    for (let i = 0; i < this.groupsRowCtrls.length; i++) {
      const ctrl = this.groupsRowCtrls[i].getHeaderCellCtrl(column);
      if (ctrl) {
        return ctrl;
      }
    }
  }
  getHtmlElementForColumnHeader(column) {
    const cellCtrl = this.getHeaderCtrlForColumn(column);
    if (!cellCtrl) {
      return null;
    }
    return cellCtrl.getGui();
  }
  getRowType(rowIndex) {
    const allCtrls = this.getAllCtrls();
    const ctrl = allCtrls[rowIndex];
    return ctrl ? ctrl.getType() : void 0;
  }
  focusHeader(rowIndex, column, event) {
    const allCtrls = this.getAllCtrls();
    const ctrl = allCtrls[rowIndex];
    if (!ctrl) {
      return false;
    }
    return ctrl.focusHeader(column, event);
  }
  getViewport() {
    return this.eViewport;
  }
  getRowCount() {
    return this.groupsRowCtrls.length + (this.columnsRowCtrl ? 1 : 0) + (this.filtersRowCtrl ? 1 : 0);
  }
  setHorizontalScroll(offset) {
    this.comp.setViewportScrollLeft(offset);
  }
  destroy() {
    if (this.filtersRowCtrl) {
      this.filtersRowCtrl = this.destroyBean(this.filtersRowCtrl);
    }
    if (this.columnsRowCtrl) {
      this.columnsRowCtrl = this.destroyBean(this.columnsRowCtrl);
    }
    if (this.groupsRowCtrls && this.groupsRowCtrls.length) {
      this.groupsRowCtrls = this.destroyBeans(this.groupsRowCtrls);
    }
    super.destroy();
  }
  setupDragAndDrop(dropContainer) {
    const bodyDropTarget = new BodyDropTarget(this.pinned, dropContainer);
    this.createManagedBean(bodyDropTarget);
  }
  restoreFocusOnHeader(position) {
    if (!position) {
      return;
    }
    const { column } = position;
    if (column.getPinned() != this.pinned) {
      return;
    }
    this.focusService.focusHeaderPosition({ headerPosition: position });
  }
  // grid cols have changed - this also means the number of rows in the header can have
  // changed. so we remove all the old rows and insert new ones for a complete refresh
  onGridColumnsChanged() {
    this.refresh(true);
  }
  onDisplayedColumnsChanged() {
    const includeFloatingFilter = this.filterManager?.hasFloatingFilters() && !this.hidden;
    if (this.includeFloatingFilter !== includeFloatingFilter) {
      this.refresh(true);
    }
  }
  setupCenterWidth() {
    if (this.pinned != null) {
      return;
    }
    this.createManagedBean(new CenterWidthFeature((width) => this.comp.setCenterWidth(`${width}px`), true));
  }
  setupPinnedWidth() {
    if (this.pinned == null) {
      return;
    }
    const pinningLeft = this.pinned === "left";
    const pinningRight = this.pinned === "right";
    this.hidden = true;
    const listener = () => {
      const width = pinningLeft ? this.pinnedWidthService.getPinnedLeftWidth() : this.pinnedWidthService.getPinnedRightWidth();
      if (width == null) {
        return;
      }
      const hidden = width == 0;
      const hiddenChanged = this.hidden !== hidden;
      const isRtl = this.gos.get("enableRtl");
      const scrollbarWidth = this.gos.getScrollbarWidth();
      const addPaddingForScrollbar = this.scrollVisibleService.isVerticalScrollShowing() && (isRtl && pinningLeft || !isRtl && pinningRight);
      const widthWithPadding = addPaddingForScrollbar ? width + scrollbarWidth : width;
      this.comp.setPinnedContainerWidth(`${widthWithPadding}px`);
      this.comp.setDisplayed(!hidden);
      if (hiddenChanged) {
        this.hidden = hidden;
        this.refresh();
      }
    };
    this.addManagedEventListeners({
      leftPinnedWidthChanged: listener,
      rightPinnedWidthChanged: listener,
      scrollVisibilityChanged: listener,
      scrollbarWidthChanged: listener
    });
  }
};

// community-modules/core/src/headerRendering/rowContainer/headerRowContainerComp.ts
var PINNED_LEFT_TEMPLATE = (
  /* html */
  `<div class="ag-pinned-left-header" role="rowgroup"></div>`
);
var PINNED_RIGHT_TEMPLATE = (
  /* html */
  `<div class="ag-pinned-right-header" role="rowgroup"></div>`
);
var CENTER_TEMPLATE = (
  /* html */
  `<div class="ag-header-viewport" role="presentation">
        <div class="ag-header-container" data-ref="eCenterContainer" role="rowgroup"></div>
    </div>`
);
var HeaderRowContainerComp = class extends Component {
  constructor(pinned) {
    super();
    this.eCenterContainer = RefPlaceholder;
    this.headerRowComps = {};
    this.rowCompsList = [];
    this.pinned = pinned;
  }
  postConstruct() {
    this.selectAndSetTemplate();
    const compProxy = {
      setDisplayed: (displayed) => this.setDisplayed(displayed),
      setCtrls: (ctrls) => this.setCtrls(ctrls),
      // only gets called for center section
      setCenterWidth: (width) => this.eCenterContainer.style.width = width,
      setViewportScrollLeft: (left) => this.getGui().scrollLeft = left,
      // only gets called for pinned sections
      setPinnedContainerWidth: (width) => {
        const eGui = this.getGui();
        eGui.style.width = width;
        eGui.style.maxWidth = width;
        eGui.style.minWidth = width;
      }
    };
    const ctrl = this.createManagedBean(new HeaderRowContainerCtrl(this.pinned));
    ctrl.setComp(compProxy, this.getGui());
  }
  selectAndSetTemplate() {
    const pinnedLeft = this.pinned == "left";
    const pinnedRight = this.pinned == "right";
    const template = pinnedLeft ? PINNED_LEFT_TEMPLATE : pinnedRight ? PINNED_RIGHT_TEMPLATE : CENTER_TEMPLATE;
    this.setTemplate(template);
    this.eRowContainer = this.eCenterContainer !== RefPlaceholder ? this.eCenterContainer : this.getGui();
  }
  destroy() {
    this.setCtrls([]);
    super.destroy();
  }
  destroyRowComp(rowComp) {
    this.destroyBean(rowComp);
    this.eRowContainer.removeChild(rowComp.getGui());
  }
  setCtrls(ctrls) {
    const oldRowComps = this.headerRowComps;
    this.headerRowComps = {};
    this.rowCompsList = [];
    let prevGui;
    const appendEnsuringDomOrder = (rowComp) => {
      const eGui = rowComp.getGui();
      const notAlreadyIn = eGui.parentElement != this.eRowContainer;
      if (notAlreadyIn) {
        this.eRowContainer.appendChild(eGui);
      }
      if (prevGui) {
        _ensureDomOrder(this.eRowContainer, eGui, prevGui);
      }
      prevGui = eGui;
    };
    ctrls.forEach((ctrl) => {
      const ctrlId = ctrl.getInstanceId();
      const existingComp = oldRowComps[ctrlId];
      delete oldRowComps[ctrlId];
      const rowComp = existingComp ? existingComp : this.createBean(new HeaderRowComp(ctrl));
      this.headerRowComps[ctrlId] = rowComp;
      this.rowCompsList.push(rowComp);
      appendEnsuringDomOrder(rowComp);
    });
    _getAllValuesInObject(oldRowComps).forEach((c) => this.destroyRowComp(c));
  }
};

// community-modules/core/src/headerRendering/gridHeaderComp.ts
var GridHeaderComp = class extends Component {
  constructor() {
    super(
      /* html */
      `<div class="ag-header" role="presentation"/>`
    );
  }
  postConstruct() {
    const compProxy = {
      addOrRemoveCssClass: (cssClassName, on) => this.addOrRemoveCssClass(cssClassName, on),
      setHeightAndMinHeight: (height) => {
        this.getGui().style.height = height;
        this.getGui().style.minHeight = height;
      }
    };
    const ctrl = this.createManagedBean(new GridHeaderCtrl());
    ctrl.setComp(compProxy, this.getGui(), this.getFocusableElement());
    const addContainer = (container) => {
      this.createManagedBean(container);
      this.appendChild(container);
    };
    addContainer(new HeaderRowContainerComp("left"));
    addContainer(new HeaderRowContainerComp(null));
    addContainer(new HeaderRowContainerComp("right"));
  }
};
var GridHeaderSelector = {
  selector: "AG-HEADER-ROOT",
  component: GridHeaderComp
};

// community-modules/core/src/styling/layoutFeature.ts
var LayoutCssClasses = /* @__PURE__ */ ((LayoutCssClasses2) => {
  LayoutCssClasses2["AUTO_HEIGHT"] = "ag-layout-auto-height";
  LayoutCssClasses2["NORMAL"] = "ag-layout-normal";
  LayoutCssClasses2["PRINT"] = "ag-layout-print";
  return LayoutCssClasses2;
})(LayoutCssClasses || {});
var LayoutFeature = class extends BeanStub {
  constructor(view) {
    super();
    this.view = view;
  }
  postConstruct() {
    this.addManagedPropertyListener("domLayout", this.updateLayoutClasses.bind(this));
    this.updateLayoutClasses();
  }
  updateLayoutClasses() {
    const domLayout = this.getDomLayout();
    const params = {
      autoHeight: domLayout === "autoHeight",
      normal: domLayout === "normal",
      print: domLayout === "print"
    };
    const cssClass = params.autoHeight ? "ag-layout-auto-height" /* AUTO_HEIGHT */ : params.print ? "ag-layout-print" /* PRINT */ : "ag-layout-normal" /* NORMAL */;
    this.view.updateLayoutClasses(cssClass, params);
  }
  // returns either 'print', 'autoHeight' or 'normal' (normal is the default)
  getDomLayout() {
    const domLayout = this.gos.get("domLayout") ?? "normal";
    const validLayouts = ["normal", "print", "autoHeight"];
    if (validLayouts.indexOf(domLayout) === -1) {
      _warnOnce(`${domLayout} is not valid for DOM Layout, valid values are 'normal', 'autoHeight', 'print'.`);
      return "normal";
    }
    return domLayout;
  }
};

// community-modules/core/src/rendering/overlays/overlayWrapperComponent.ts
var OverlayWrapperComponent = class extends Component {
  constructor() {
    super(
      /* html */
      `
            <div class="ag-overlay" role="presentation">
                <div class="ag-overlay-panel" role="presentation">
                    <div class="ag-overlay-wrapper" data-ref="eOverlayWrapper" role="presentation"></div>
                </div>
            </div>`
    );
    this.eOverlayWrapper = RefPlaceholder;
    this.activePromise = null;
    this.activeOverlay = null;
    this.updateListenerDestroyFunc = null;
    this.activeOverlayWrapperCssClass = null;
  }
  wireBeans(beans) {
    this.overlayService = beans.overlayService;
  }
  updateLayoutClasses(cssClass, params) {
    const overlayWrapperClassList = this.eOverlayWrapper.classList;
    overlayWrapperClassList.toggle("ag-layout-auto-height" /* AUTO_HEIGHT */, params.autoHeight);
    overlayWrapperClassList.toggle("ag-layout-normal" /* NORMAL */, params.normal);
    overlayWrapperClassList.toggle("ag-layout-print" /* PRINT */, params.print);
  }
  postConstruct() {
    this.createManagedBean(new LayoutFeature(this));
    this.setDisplayed(false, { skipAriaHidden: true });
    this.overlayService.registerOverlayWrapperComp(this);
  }
  setWrapperTypeClass(overlayWrapperCssClass) {
    const overlayWrapperClassList = this.eOverlayWrapper.classList;
    if (this.activeOverlayWrapperCssClass) {
      overlayWrapperClassList.toggle(this.activeOverlayWrapperCssClass, false);
    }
    this.activeOverlayWrapperCssClass = overlayWrapperCssClass;
    overlayWrapperClassList.toggle(overlayWrapperCssClass, true);
  }
  showOverlay(overlayComponentPromise, overlayWrapperCssClass, gridOption) {
    this.setWrapperTypeClass(overlayWrapperCssClass);
    this.destroyActiveOverlay();
    this.activePromise = overlayComponentPromise;
    overlayComponentPromise?.then((comp) => {
      if (this.activePromise !== overlayComponentPromise) {
        if (this.activeOverlay !== comp) {
          this.destroyBean(comp);
          comp = null;
        }
        return;
      }
      this.activePromise = null;
      if (!comp) {
        return;
      }
      if (this.activeOverlay == comp) {
        return;
      }
      this.eOverlayWrapper.appendChild(comp.getGui());
      this.activeOverlay = comp;
      if (gridOption) {
        const component = comp;
        this.updateListenerDestroyFunc = this.addManagedPropertyListener(gridOption, ({ currentValue }) => {
          component.refresh?.(this.gos.addGridCommonParams({ ...currentValue ?? {} }));
        });
      }
    });
    this.setDisplayed(true, { skipAriaHidden: true });
  }
  destroyActiveOverlay() {
    this.activePromise = null;
    const activeOverlay = this.activeOverlay;
    if (!activeOverlay) {
      return;
    }
    this.activeOverlay = null;
    const updateListenerDestroyFunc = this.updateListenerDestroyFunc;
    if (updateListenerDestroyFunc) {
      updateListenerDestroyFunc();
      this.updateListenerDestroyFunc = null;
    }
    this.destroyBean(activeOverlay);
    _clearElement(this.eOverlayWrapper);
  }
  hideOverlay() {
    this.destroyActiveOverlay();
    this.setDisplayed(false, { skipAriaHidden: true });
  }
  destroy() {
    this.destroyActiveOverlay();
    super.destroy();
  }
};
var OverlayWrapperSelector = {
  selector: "AG-OVERLAY-WRAPPER",
  component: OverlayWrapperComponent
};

// community-modules/core/src/gridBodyComp/abstractFakeScrollComp.ts
var AbstractFakeScrollComp = class extends Component {
  constructor(template, direction) {
    super();
    this.direction = direction;
    this.eViewport = RefPlaceholder;
    this.eContainer = RefPlaceholder;
    this.hideTimeout = null;
    this.setTemplate(template);
  }
  wireBeans(beans) {
    this.animationFrameService = beans.animationFrameService;
  }
  postConstruct() {
    this.addManagedEventListeners({
      scrollVisibilityChanged: this.onScrollVisibilityChanged.bind(this)
    });
    this.onScrollVisibilityChanged();
    this.addOrRemoveCssClass("ag-apple-scrollbar", _isMacOsUserAgent() || _isIOSUserAgent());
  }
  initialiseInvisibleScrollbar() {
    if (this.invisibleScrollbar !== void 0) {
      return;
    }
    this.invisibleScrollbar = _isInvisibleScrollbar();
    if (this.invisibleScrollbar) {
      this.hideAndShowInvisibleScrollAsNeeded();
      this.addActiveListenerToggles();
    }
  }
  addActiveListenerToggles() {
    const eGui = this.getGui();
    const onActivate = () => this.addOrRemoveCssClass("ag-scrollbar-active", true);
    const onDeactivate = () => this.addOrRemoveCssClass("ag-scrollbar-active", false);
    this.addManagedListeners(eGui, {
      mouseenter: onActivate,
      mousedown: onActivate,
      touchstart: onActivate,
      mouseleave: onDeactivate,
      touchend: onDeactivate
    });
  }
  onScrollVisibilityChanged() {
    if (this.invisibleScrollbar === void 0) {
      this.initialiseInvisibleScrollbar();
    }
    this.animationFrameService.requestAnimationFrame(() => this.setScrollVisible());
  }
  hideAndShowInvisibleScrollAsNeeded() {
    this.addManagedEventListeners({
      bodyScroll: (params) => {
        if (params.direction === this.direction) {
          if (this.hideTimeout !== null) {
            window.clearTimeout(this.hideTimeout);
            this.hideTimeout = null;
          }
          this.addOrRemoveCssClass("ag-scrollbar-scrolling", true);
        }
      },
      bodyScrollEnd: () => {
        this.hideTimeout = window.setTimeout(() => {
          this.addOrRemoveCssClass("ag-scrollbar-scrolling", false);
          this.hideTimeout = null;
        }, 400);
      }
    });
  }
  attemptSettingScrollPosition(value) {
    const viewport = this.getViewport();
    _waitUntil(
      () => _isVisible(viewport),
      () => this.setScrollPosition(value),
      100
    );
  }
  getViewport() {
    return this.eViewport;
  }
  getContainer() {
    return this.eContainer;
  }
  onScrollCallback(fn) {
    this.addManagedElementListeners(this.getViewport(), { scroll: fn });
  }
};

// community-modules/core/src/gridBodyComp/fakeHScrollComp.ts
var FakeHScrollComp = class extends AbstractFakeScrollComp {
  constructor() {
    super(
      /* html */
      `<div class="ag-body-horizontal-scroll" aria-hidden="true">
            <div class="ag-horizontal-left-spacer" data-ref="eLeftSpacer"></div>
            <div class="ag-body-horizontal-scroll-viewport" data-ref="eViewport">
                <div class="ag-body-horizontal-scroll-container" data-ref="eContainer"></div>
            </div>
            <div class="ag-horizontal-right-spacer" data-ref="eRightSpacer"></div>
        </div>`,
      "horizontal"
    );
    this.eLeftSpacer = RefPlaceholder;
    this.eRightSpacer = RefPlaceholder;
  }
  wireBeans(beans) {
    super.wireBeans(beans);
    this.visibleColsService = beans.visibleColsService;
    this.pinnedRowModel = beans.pinnedRowModel;
    this.ctrlsService = beans.ctrlsService;
    this.scrollVisibleService = beans.scrollVisibleService;
  }
  postConstruct() {
    super.postConstruct();
    const spacerWidthsListener = this.setFakeHScrollSpacerWidths.bind(this);
    this.addManagedEventListeners({
      displayedColumnsChanged: spacerWidthsListener,
      displayedColumnsWidthChanged: spacerWidthsListener,
      pinnedRowDataChanged: this.onPinnedRowDataChanged.bind(this)
    });
    this.addManagedPropertyListener("domLayout", spacerWidthsListener);
    this.ctrlsService.register("fakeHScrollComp", this);
    this.createManagedBean(new CenterWidthFeature((width) => this.eContainer.style.width = `${width}px`));
    this.addManagedPropertyListeners(["suppressHorizontalScroll"], this.onScrollVisibilityChanged.bind(this));
  }
  initialiseInvisibleScrollbar() {
    if (this.invisibleScrollbar !== void 0) {
      return;
    }
    this.enableRtl = this.gos.get("enableRtl");
    super.initialiseInvisibleScrollbar();
    if (this.invisibleScrollbar) {
      this.refreshCompBottom();
    }
  }
  onPinnedRowDataChanged() {
    this.refreshCompBottom();
  }
  refreshCompBottom() {
    if (!this.invisibleScrollbar) {
      return;
    }
    const bottomPinnedHeight = this.pinnedRowModel.getPinnedBottomTotalHeight();
    this.getGui().style.bottom = `${bottomPinnedHeight}px`;
  }
  onScrollVisibilityChanged() {
    super.onScrollVisibilityChanged();
    this.setFakeHScrollSpacerWidths();
  }
  setFakeHScrollSpacerWidths() {
    const vScrollShowing = this.scrollVisibleService.isVerticalScrollShowing();
    let rightSpacing = this.visibleColsService.getDisplayedColumnsRightWidth();
    const scrollOnRight = !this.enableRtl && vScrollShowing;
    const scrollbarWidth = this.gos.getScrollbarWidth();
    if (scrollOnRight) {
      rightSpacing += scrollbarWidth;
    }
    _setFixedWidth(this.eRightSpacer, rightSpacing);
    this.eRightSpacer.classList.toggle("ag-scroller-corner", rightSpacing <= scrollbarWidth);
    let leftSpacing = this.visibleColsService.getColsLeftWidth();
    const scrollOnLeft = this.enableRtl && vScrollShowing;
    if (scrollOnLeft) {
      leftSpacing += scrollbarWidth;
    }
    _setFixedWidth(this.eLeftSpacer, leftSpacing);
    this.eLeftSpacer.classList.toggle("ag-scroller-corner", leftSpacing <= scrollbarWidth);
  }
  setScrollVisible() {
    const hScrollShowing = this.scrollVisibleService.isHorizontalScrollShowing();
    const invisibleScrollbar2 = this.invisibleScrollbar;
    const isSuppressHorizontalScroll = this.gos.get("suppressHorizontalScroll");
    const scrollbarWidth = hScrollShowing ? this.gos.getScrollbarWidth() || 0 : 0;
    const adjustedScrollbarWidth = scrollbarWidth === 0 && invisibleScrollbar2 ? 16 : scrollbarWidth;
    const scrollContainerSize = !isSuppressHorizontalScroll ? adjustedScrollbarWidth : 0;
    this.addOrRemoveCssClass("ag-scrollbar-invisible", invisibleScrollbar2);
    _setFixedHeight(this.getGui(), scrollContainerSize);
    _setFixedHeight(this.eViewport, scrollContainerSize);
    _setFixedHeight(this.eContainer, scrollContainerSize);
    this.setDisplayed(hScrollShowing, { skipAriaHidden: true });
  }
  getScrollPosition() {
    return _getScrollLeft(this.getViewport(), this.enableRtl);
  }
  setScrollPosition(value) {
    if (!_isVisible(this.getViewport())) {
      this.attemptSettingScrollPosition(value);
    }
    _setScrollLeft(this.getViewport(), value, this.enableRtl);
  }
};
var FakeHScrollSelector = {
  selector: "AG-FAKE-HORIZONTAL-SCROLL",
  component: FakeHScrollComp
};

// community-modules/core/src/gridBodyComp/rowContainer/setHeightFeature.ts
var SetHeightFeature = class extends BeanStub {
  wireBeans(beans) {
    this.maxDivHeightScaler = beans.rowContainerHeightService;
  }
  constructor(eContainer, eViewport) {
    super();
    this.eContainer = eContainer;
    this.eViewport = eViewport;
  }
  postConstruct() {
    this.addManagedEventListeners({ rowContainerHeightChanged: this.onHeightChanged.bind(this) });
  }
  onHeightChanged() {
    const height = this.maxDivHeightScaler.getUiContainerHeight();
    const heightString = height != null ? `${height}px` : ``;
    this.eContainer.style.height = heightString;
    if (this.eViewport) {
      this.eViewport.style.height = heightString;
    }
  }
};

// community-modules/core/src/gridBodyComp/fakeVScrollComp.ts
var FakeVScrollComp = class extends AbstractFakeScrollComp {
  wireBeans(beans) {
    super.wireBeans(beans);
    this.ctrlsService = beans.ctrlsService;
    this.scrollVisibleService = beans.scrollVisibleService;
  }
  constructor() {
    super(
      /* html */
      `<div class="ag-body-vertical-scroll" aria-hidden="true">
            <div class="ag-body-vertical-scroll-viewport" data-ref="eViewport">
                <div class="ag-body-vertical-scroll-container" data-ref="eContainer"></div>
            </div>
        </div>`,
      "vertical"
    );
  }
  postConstruct() {
    super.postConstruct();
    this.createManagedBean(new SetHeightFeature(this.eContainer));
    this.ctrlsService.register("fakeVScrollComp", this);
    this.addManagedEventListeners({ rowContainerHeightChanged: this.onRowContainerHeightChanged.bind(this) });
  }
  setScrollVisible() {
    const vScrollShowing = this.scrollVisibleService.isVerticalScrollShowing();
    const invisibleScrollbar2 = this.invisibleScrollbar;
    const scrollbarWidth = vScrollShowing ? this.gos.getScrollbarWidth() || 0 : 0;
    const adjustedScrollbarWidth = scrollbarWidth === 0 && invisibleScrollbar2 ? 16 : scrollbarWidth;
    this.addOrRemoveCssClass("ag-scrollbar-invisible", invisibleScrollbar2);
    _setFixedWidth(this.getGui(), adjustedScrollbarWidth);
    _setFixedWidth(this.eViewport, adjustedScrollbarWidth);
    _setFixedWidth(this.eContainer, adjustedScrollbarWidth);
    this.setDisplayed(vScrollShowing, { skipAriaHidden: true });
  }
  onRowContainerHeightChanged() {
    const { ctrlsService } = this;
    const gridBodyCtrl = ctrlsService.getGridBodyCtrl();
    const gridBodyViewportEl = gridBodyCtrl.getBodyViewportElement();
    const eViewportScrollTop = this.getScrollPosition();
    const gridBodyViewportScrollTop = gridBodyViewportEl.scrollTop;
    if (eViewportScrollTop != gridBodyViewportScrollTop) {
      this.setScrollPosition(gridBodyViewportScrollTop, true);
    }
  }
  getScrollPosition() {
    return this.getViewport().scrollTop;
  }
  setScrollPosition(value, force) {
    if (!force && !_isVisible(this.getViewport())) {
      this.attemptSettingScrollPosition(value);
    }
    this.getViewport().scrollTop = value;
  }
};
var FakeVScrollSelector = {
  selector: "AG-FAKE-VERTICAL-SCROLL",
  component: FakeVScrollComp
};

// community-modules/core/src/gridBodyComp/gridBodyScrollFeature.ts
var GridBodyScrollFeature = class extends BeanStub {
  constructor(eBodyViewport) {
    super();
    this.lastScrollSource = [null, null];
    this.scrollLeft = -1;
    this.nextScrollTop = -1;
    this.scrollTop = -1;
    // Used to provide approximate values of scrollTop and offsetHeight
    // without forcing the browser to recalculate styles.
    this.lastOffsetHeight = -1;
    this.lastScrollTop = -1;
    this.eBodyViewport = eBodyViewport;
    this.resetLastHScrollDebounced = _debounce(
      () => this.lastScrollSource[1 /* Horizontal */] = null,
      500
    );
    this.resetLastVScrollDebounced = _debounce(() => this.lastScrollSource[0 /* Vertical */] = null, 500);
  }
  wireBeans(beans) {
    this.ctrlsService = beans.ctrlsService;
    this.animationFrameService = beans.animationFrameService;
    this.paginationService = beans.paginationService;
    this.pageBoundsService = beans.pageBoundsService;
    this.rowModel = beans.rowModel;
    this.heightScaler = beans.rowContainerHeightService;
    this.rowRenderer = beans.rowRenderer;
    this.columnModel = beans.columnModel;
    this.visibleColsService = beans.visibleColsService;
  }
  postConstruct() {
    this.enableRtl = this.gos.get("enableRtl");
    this.addManagedEventListeners({
      displayedColumnsWidthChanged: this.onDisplayedColumnsWidthChanged.bind(this)
    });
    this.ctrlsService.whenReady((p) => {
      this.centerRowsCtrl = p.center;
      this.onDisplayedColumnsWidthChanged();
      this.addScrollListener();
    });
  }
  addScrollListener() {
    const { fakeHScrollComp, fakeVScrollComp } = this.ctrlsService.getParams();
    this.addManagedElementListeners(this.centerRowsCtrl.getViewportElement(), {
      scroll: this.onHScroll.bind(this)
    });
    fakeHScrollComp.onScrollCallback(this.onFakeHScroll.bind(this));
    const isDebounce = this.gos.get("debounceVerticalScrollbar");
    const onVScroll = isDebounce ? _debounce(this.onVScroll.bind(this), 100) : this.onVScroll.bind(this);
    const onFakeVScroll = isDebounce ? _debounce(this.onFakeVScroll.bind(this), 100) : this.onFakeVScroll.bind(this);
    this.addManagedElementListeners(this.eBodyViewport, { scroll: onVScroll });
    fakeVScrollComp.onScrollCallback(onFakeVScroll);
  }
  onDisplayedColumnsWidthChanged() {
    if (this.enableRtl) {
      this.horizontallyScrollHeaderCenterAndFloatingCenter();
    }
  }
  horizontallyScrollHeaderCenterAndFloatingCenter(scrollLeft) {
    const notYetInitialised = this.centerRowsCtrl == null;
    if (notYetInitialised) {
      return;
    }
    if (scrollLeft === void 0) {
      scrollLeft = this.centerRowsCtrl.getCenterViewportScrollLeft();
    }
    const offset = this.enableRtl ? scrollLeft : -scrollLeft;
    const { topCenter, stickyTopCenter, stickyBottomCenter, centerHeader, bottomCenter, fakeHScrollComp } = this.ctrlsService.getParams();
    centerHeader.setHorizontalScroll(-offset);
    bottomCenter.setContainerTranslateX(offset);
    topCenter.setContainerTranslateX(offset);
    stickyTopCenter.setContainerTranslateX(offset);
    stickyBottomCenter.setContainerTranslateX(offset);
    const centerViewport = this.centerRowsCtrl.getViewportElement();
    const isCenterViewportLastHorizontal = this.lastScrollSource[1 /* Horizontal */] === 0 /* Container */;
    scrollLeft = Math.abs(scrollLeft);
    if (isCenterViewportLastHorizontal) {
      fakeHScrollComp.setScrollPosition(scrollLeft);
    } else {
      _setScrollLeft(centerViewport, scrollLeft, this.enableRtl);
    }
  }
  isControllingScroll(source, direction) {
    if (this.lastScrollSource[direction] == null) {
      this.lastScrollSource[direction] = source;
      return true;
    }
    return this.lastScrollSource[direction] === source;
  }
  onFakeHScroll() {
    if (!this.isControllingScroll(1 /* FakeContainer */, 1 /* Horizontal */)) {
      return;
    }
    this.onHScrollCommon(1 /* FakeContainer */);
  }
  onHScroll() {
    if (!this.isControllingScroll(0 /* Container */, 1 /* Horizontal */)) {
      return;
    }
    this.onHScrollCommon(0 /* Container */);
  }
  onHScrollCommon(source) {
    const centerContainerViewport = this.centerRowsCtrl.getViewportElement();
    const { scrollLeft } = centerContainerViewport;
    if (this.shouldBlockScrollUpdate(1 /* Horizontal */, scrollLeft, true)) {
      return;
    }
    let newScrollLeft;
    if (source === 0 /* Container */) {
      newScrollLeft = _getScrollLeft(centerContainerViewport, this.enableRtl);
    } else {
      newScrollLeft = this.ctrlsService.get("fakeHScrollComp").getScrollPosition();
    }
    this.doHorizontalScroll(Math.round(newScrollLeft));
    this.resetLastHScrollDebounced();
  }
  onFakeVScroll() {
    if (!this.isControllingScroll(1 /* FakeContainer */, 0 /* Vertical */)) {
      return;
    }
    this.onVScrollCommon(1 /* FakeContainer */);
  }
  onVScroll() {
    if (!this.isControllingScroll(0 /* Container */, 0 /* Vertical */)) {
      return;
    }
    this.onVScrollCommon(0 /* Container */);
  }
  onVScrollCommon(source) {
    let scrollTop;
    if (source === 0 /* Container */) {
      scrollTop = this.eBodyViewport.scrollTop;
    } else {
      scrollTop = this.ctrlsService.get("fakeVScrollComp").getScrollPosition();
    }
    if (this.shouldBlockScrollUpdate(0 /* Vertical */, scrollTop, true)) {
      return;
    }
    this.animationFrameService.setScrollTop(scrollTop);
    this.nextScrollTop = scrollTop;
    if (source === 0 /* Container */) {
      this.ctrlsService.get("fakeVScrollComp").setScrollPosition(scrollTop);
    } else {
      this.eBodyViewport.scrollTop = scrollTop;
    }
    if (this.gos.get("suppressAnimationFrame")) {
      this.scrollGridIfNeeded();
    } else {
      this.animationFrameService.schedule();
    }
    this.resetLastVScrollDebounced();
  }
  doHorizontalScroll(scrollLeft) {
    const fakeScrollLeft = this.ctrlsService.get("fakeHScrollComp").getScrollPosition();
    if (this.scrollLeft === scrollLeft && scrollLeft === fakeScrollLeft) {
      return;
    }
    this.scrollLeft = scrollLeft;
    this.fireScrollEvent(1 /* Horizontal */);
    this.horizontallyScrollHeaderCenterAndFloatingCenter(scrollLeft);
    this.centerRowsCtrl.onHorizontalViewportChanged(true);
  }
  fireScrollEvent(direction) {
    const bodyScrollEvent = {
      type: "bodyScroll",
      direction: direction === 1 /* Horizontal */ ? "horizontal" : "vertical",
      left: this.scrollLeft,
      top: this.scrollTop
    };
    this.eventService.dispatchEvent(bodyScrollEvent);
    window.clearTimeout(this.scrollTimer);
    this.scrollTimer = void 0;
    this.scrollTimer = window.setTimeout(() => {
      const bodyScrollEndEvent = {
        ...bodyScrollEvent,
        type: "bodyScrollEnd"
      };
      this.eventService.dispatchEvent(bodyScrollEndEvent);
    }, 100);
  }
  shouldBlockScrollUpdate(direction, scrollTo, touchOnly = false) {
    if (touchOnly && !_isIOSUserAgent()) {
      return false;
    }
    if (direction === 0 /* Vertical */) {
      return this.shouldBlockVerticalScroll(scrollTo);
    }
    return this.shouldBlockHorizontalScroll(scrollTo);
  }
  shouldBlockVerticalScroll(scrollTo) {
    const clientHeight = _getInnerHeight(this.eBodyViewport);
    const { scrollHeight } = this.eBodyViewport;
    if (scrollTo < 0 || scrollTo + clientHeight > scrollHeight) {
      return true;
    }
    return false;
  }
  shouldBlockHorizontalScroll(scrollTo) {
    const clientWidth = this.centerRowsCtrl.getCenterWidth();
    const { scrollWidth } = this.centerRowsCtrl.getViewportElement();
    if (this.enableRtl && _isRtlNegativeScroll()) {
      if (scrollTo > 0) {
        return true;
      }
    } else if (scrollTo < 0) {
      return true;
    }
    if (Math.abs(scrollTo) + clientWidth > scrollWidth) {
      return true;
    }
    return false;
  }
  redrawRowsAfterScroll() {
    this.fireScrollEvent(0 /* Vertical */);
  }
  // this is to cater for AG-3274, where grid is removed from the dom and then inserted back in again.
  // (which happens with some implementations of tabbing). this can result in horizontal scroll getting
  // reset back to the left, however no scroll event is fired. so we need to get header to also scroll
  // back to the left to be kept in sync.
  // adding and removing the grid from the DOM both resets the scroll position and
  // triggers a resize event, so notify listeners if the scroll position has changed
  checkScrollLeft() {
    if (this.scrollLeft !== this.centerRowsCtrl.getCenterViewportScrollLeft()) {
      this.onHScrollCommon(0 /* Container */);
    }
  }
  scrollGridIfNeeded() {
    const frameNeeded = this.scrollTop != this.nextScrollTop;
    if (frameNeeded) {
      this.scrollTop = this.nextScrollTop;
      this.redrawRowsAfterScroll();
    }
    return frameNeeded;
  }
  // called by scrollHorizontally method and alignedGridsService
  setHorizontalScrollPosition(hScrollPosition, fromAlignedGridsService = false) {
    const minScrollLeft = 0;
    const maxScrollLeft = this.centerRowsCtrl.getViewportElement().scrollWidth - this.centerRowsCtrl.getCenterWidth();
    if (!fromAlignedGridsService && this.shouldBlockScrollUpdate(1 /* Horizontal */, hScrollPosition)) {
      if (this.enableRtl && _isRtlNegativeScroll()) {
        hScrollPosition = hScrollPosition > 0 ? 0 : maxScrollLeft;
      } else {
        hScrollPosition = Math.min(Math.max(hScrollPosition, minScrollLeft), maxScrollLeft);
      }
    }
    _setScrollLeft(this.centerRowsCtrl.getViewportElement(), Math.abs(hScrollPosition), this.enableRtl);
    this.doHorizontalScroll(hScrollPosition);
  }
  setVerticalScrollPosition(vScrollPosition) {
    this.eBodyViewport.scrollTop = vScrollPosition;
  }
  getVScrollPosition() {
    this.lastScrollTop = this.eBodyViewport.scrollTop;
    this.lastOffsetHeight = this.eBodyViewport.offsetHeight;
    const result = {
      top: this.lastScrollTop,
      bottom: this.lastScrollTop + this.lastOffsetHeight
    };
    return result;
  }
  /** Get an approximate scroll position that returns the last real value read.
   * This is useful for avoiding repeated DOM reads that force the browser to recalculate styles.
   * This can have big performance improvements but may not be 100% accurate so only use if this is acceptable.
   */
  getApproximateVScollPosition() {
    if (this.lastScrollTop >= 0 && this.lastOffsetHeight >= 0) {
      return {
        top: this.scrollTop,
        bottom: this.scrollTop + this.lastOffsetHeight
      };
    }
    return this.getVScrollPosition();
  }
  getHScrollPosition() {
    return this.centerRowsCtrl.getHScrollPosition();
  }
  isHorizontalScrollShowing() {
    return this.centerRowsCtrl.isHorizontalScrollShowing();
  }
  // called by the headerRootComp and moveColumnController
  scrollHorizontally(pixels) {
    const oldScrollPosition = this.centerRowsCtrl.getViewportElement().scrollLeft;
    this.setHorizontalScrollPosition(oldScrollPosition + pixels);
    return this.centerRowsCtrl.getViewportElement().scrollLeft - oldScrollPosition;
  }
  // gets called by rowRenderer when new data loaded, as it will want to scroll to the top
  scrollToTop() {
    this.eBodyViewport.scrollTop = 0;
  }
  // Valid values for position are bottom, middle and top
  ensureNodeVisible(comparator, position = null) {
    const rowCount = this.rowModel.getRowCount();
    let indexToSelect = -1;
    for (let i = 0; i < rowCount; i++) {
      const node = this.rowModel.getRow(i);
      if (typeof comparator === "function") {
        const predicate = comparator;
        if (node && predicate(node)) {
          indexToSelect = i;
          break;
        }
      } else {
        if (comparator === node || comparator === node.data) {
          indexToSelect = i;
          break;
        }
      }
    }
    if (indexToSelect >= 0) {
      this.ensureIndexVisible(indexToSelect, position);
    }
  }
  // Valid values for position are bottom, middle and top
  // position should be {'top','middle','bottom', or undefined/null}.
  // if undefined/null, then the grid will to the minimal amount of scrolling,
  // eg if grid needs to scroll up, it scrolls until row is on top,
  //    if grid needs to scroll down, it scrolls until row is on bottom,
  //    if row is already in view, grid does not scroll
  ensureIndexVisible(index, position) {
    if (this.gos.isDomLayout("print")) {
      return;
    }
    const rowCount = this.rowModel.getRowCount();
    if (typeof index !== "number" || index < 0 || index >= rowCount) {
      _warnOnce("Invalid row index for ensureIndexVisible: " + index);
      return;
    }
    const isPaging = this.gos.get("pagination");
    const paginationPanelEnabled = isPaging && !this.gos.get("suppressPaginationPanel");
    this.getFrameworkOverrides().wrapIncoming(() => {
      if (!paginationPanelEnabled) {
        this.paginationService?.goToPageWithIndex(index);
      }
      const gridBodyCtrl = this.ctrlsService.getGridBodyCtrl();
      const stickyTopHeight = gridBodyCtrl.getStickyTopHeight();
      const stickyBottomHeight = gridBodyCtrl.getStickyBottomHeight();
      const rowNode = this.rowModel.getRow(index);
      let rowGotShiftedDuringOperation;
      do {
        const startingRowTop = rowNode.rowTop;
        const startingRowHeight = rowNode.rowHeight;
        const paginationOffset = this.pageBoundsService.getPixelOffset();
        const rowTopPixel = rowNode.rowTop - paginationOffset;
        const rowBottomPixel = rowTopPixel + rowNode.rowHeight;
        const scrollPosition = this.getVScrollPosition();
        const heightOffset = this.heightScaler.getDivStretchOffset();
        const vScrollTop = scrollPosition.top + heightOffset;
        const vScrollBottom = scrollPosition.bottom + heightOffset;
        const viewportHeight = vScrollBottom - vScrollTop;
        const pxTop = this.heightScaler.getScrollPositionForPixel(rowTopPixel);
        const pxBottom = this.heightScaler.getScrollPositionForPixel(rowBottomPixel - viewportHeight);
        const pxMiddle = Math.min((pxTop + pxBottom) / 2, rowTopPixel);
        const rowAboveViewport = vScrollTop + stickyTopHeight > rowTopPixel;
        const rowBelowViewport = vScrollBottom - stickyBottomHeight < rowBottomPixel;
        let newScrollPosition = null;
        if (position === "top") {
          newScrollPosition = pxTop;
        } else if (position === "bottom") {
          newScrollPosition = pxBottom;
        } else if (position === "middle") {
          newScrollPosition = pxMiddle;
        } else if (rowAboveViewport) {
          newScrollPosition = pxTop - stickyTopHeight;
        } else if (rowBelowViewport) {
          newScrollPosition = pxBottom + stickyBottomHeight;
        }
        if (newScrollPosition !== null) {
          this.setVerticalScrollPosition(newScrollPosition);
          this.rowRenderer.redraw({ afterScroll: true });
        }
        rowGotShiftedDuringOperation = startingRowTop !== rowNode.rowTop || startingRowHeight !== rowNode.rowHeight;
      } while (rowGotShiftedDuringOperation);
      this.animationFrameService.flushAllFrames();
    });
  }
  ensureColumnVisible(key, position = "auto") {
    const column = this.columnModel.getCol(key);
    if (!column) {
      return;
    }
    if (column.isPinned()) {
      return;
    }
    if (!this.visibleColsService.isColDisplayed(column)) {
      return;
    }
    const newHorizontalScroll = this.getPositionedHorizontalScroll(column, position);
    this.getFrameworkOverrides().wrapIncoming(() => {
      if (newHorizontalScroll !== null) {
        this.centerRowsCtrl.setCenterViewportScrollLeft(newHorizontalScroll);
      }
      this.centerRowsCtrl.onHorizontalViewportChanged();
      this.animationFrameService.flushAllFrames();
    });
  }
  setScrollPosition(top, left) {
    this.getFrameworkOverrides().wrapIncoming(() => {
      this.centerRowsCtrl.setCenterViewportScrollLeft(left);
      this.setVerticalScrollPosition(top);
      this.rowRenderer.redraw({ afterScroll: true });
      this.animationFrameService.flushAllFrames();
    });
  }
  getPositionedHorizontalScroll(column, position) {
    const { columnBeforeStart, columnAfterEnd } = this.isColumnOutsideViewport(column);
    const viewportTooSmallForColumn = this.centerRowsCtrl.getCenterWidth() < column.getActualWidth();
    const viewportWidth = this.centerRowsCtrl.getCenterWidth();
    const isRtl = this.enableRtl;
    let alignColToStart = (isRtl ? columnBeforeStart : columnAfterEnd) || viewportTooSmallForColumn;
    let alignColToEnd = isRtl ? columnAfterEnd : columnBeforeStart;
    if (position !== "auto") {
      alignColToStart = position === "start";
      alignColToEnd = position === "end";
    }
    const isMiddle = position === "middle";
    if (alignColToStart || alignColToEnd || isMiddle) {
      const { colLeft, colMiddle, colRight } = this.getColumnBounds(column);
      if (isMiddle) {
        return colMiddle - viewportWidth / 2;
      }
      if (alignColToStart) {
        return isRtl ? colRight : colLeft;
      }
      return isRtl ? colLeft - viewportWidth : colRight - viewportWidth;
    }
    return null;
  }
  isColumnOutsideViewport(column) {
    const { start: viewportStart, end: viewportEnd } = this.getViewportBounds();
    const { colLeft, colRight } = this.getColumnBounds(column);
    const isRtl = this.enableRtl;
    const columnBeforeStart = isRtl ? viewportStart > colRight : viewportEnd < colRight;
    const columnAfterEnd = isRtl ? viewportEnd < colLeft : viewportStart > colLeft;
    return { columnBeforeStart, columnAfterEnd };
  }
  getColumnBounds(column) {
    const isRtl = this.enableRtl;
    const bodyWidth = this.visibleColsService.getBodyContainerWidth();
    const colWidth = column.getActualWidth();
    const colLeft = column.getLeft();
    const multiplier = isRtl ? -1 : 1;
    const colLeftPixel = isRtl ? bodyWidth - colLeft : colLeft;
    const colRightPixel = colLeftPixel + colWidth * multiplier;
    const colMidPixel = colLeftPixel + colWidth / 2 * multiplier;
    return { colLeft: colLeftPixel, colMiddle: colMidPixel, colRight: colRightPixel };
  }
  getViewportBounds() {
    const viewportWidth = this.centerRowsCtrl.getCenterWidth();
    const scrollPosition = this.centerRowsCtrl.getCenterViewportScrollLeft();
    const viewportStartPixel = scrollPosition;
    const viewportEndPixel = viewportWidth + scrollPosition;
    return { start: viewportStartPixel, end: viewportEndPixel, width: viewportWidth };
  }
};

// community-modules/core/src/gridBodyComp/gridBodyCtrl.ts
var CSS_CLASS_FORCE_VERTICAL_SCROLL = "ag-force-vertical-scroll";
var CSS_CLASS_CELL_SELECTABLE = "ag-selectable";
var CSS_CLASS_COLUMN_MOVING = "ag-column-moving";
var GridBodyCtrl = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.stickyTopHeight = 0;
    this.stickyBottomHeight = 0;
  }
  wireBeans(beans) {
    this.animationFrameService = beans.animationFrameService;
    this.rowContainerHeightService = beans.rowContainerHeightService;
    this.ctrlsService = beans.ctrlsService;
    this.columnModel = beans.columnModel;
    this.columnSizeService = beans.columnSizeService;
    this.scrollVisibleService = beans.scrollVisibleService;
    this.menuService = beans.menuService;
    this.headerNavigationService = beans.headerNavigationService;
    this.dragAndDropService = beans.dragAndDropService;
    this.pinnedRowModel = beans.pinnedRowModel;
    this.rowRenderer = beans.rowRenderer;
    this.popupService = beans.popupService;
    this.mouseEventService = beans.mouseEventService;
    this.rowModel = beans.rowModel;
    this.filterManager = beans.filterManager;
    this.environment = beans.environment;
  }
  getScrollFeature() {
    return this.bodyScrollFeature;
  }
  getBodyViewportElement() {
    return this.eBodyViewport;
  }
  setComp(comp, eGridBody, eBodyViewport, eTop, eBottom, eStickyTop, eStickyBottom) {
    this.comp = comp;
    this.eGridBody = eGridBody;
    this.eBodyViewport = eBodyViewport;
    this.eTop = eTop;
    this.eBottom = eBottom;
    this.eStickyTop = eStickyTop;
    this.eStickyBottom = eStickyBottom;
    this.setCellTextSelection(this.gos.get("enableCellTextSelection"));
    this.addManagedPropertyListener(
      "enableCellTextSelection",
      (props) => this.setCellTextSelection(props.currentValue)
    );
    this.createManagedBean(new LayoutFeature(this.comp));
    this.bodyScrollFeature = this.createManagedBean(new GridBodyScrollFeature(this.eBodyViewport));
    this.addRowDragListener();
    this.setupRowAnimationCssClass();
    this.addEventListeners();
    this.addFocusListeners([eTop, eBodyViewport, eBottom, eStickyTop, eStickyBottom]);
    this.onGridColumnsChanged();
    this.addBodyViewportListener();
    this.setFloatingHeights();
    this.disableBrowserDragging();
    this.addStopEditingWhenGridLosesFocus();
    this.filterManager?.setupAdvancedFilterHeaderComp(eTop);
    this.ctrlsService.register("gridBodyCtrl", this);
  }
  getComp() {
    return this.comp;
  }
  addEventListeners() {
    this.addManagedEventListeners({
      gridColumnsChanged: this.onGridColumnsChanged.bind(this),
      scrollVisibilityChanged: this.onScrollVisibilityChanged.bind(this),
      pinnedRowDataChanged: this.setFloatingHeights.bind(this),
      pinnedHeightChanged: this.setFloatingHeights.bind(this),
      headerHeightChanged: this.onHeaderHeightChanged.bind(this)
    });
  }
  addFocusListeners(elements) {
    elements.forEach((element) => {
      this.addManagedElementListeners(element, {
        focusin: (e) => {
          const { target } = e;
          const isFocusedElementNested = _isElementChildOfClass(target, "ag-root", element);
          element.classList.toggle("ag-has-focus", !isFocusedElementNested);
        },
        focusout: (e) => {
          const { target, relatedTarget } = e;
          const gridContainRelatedTarget = element.contains(relatedTarget);
          const isNestedRelatedTarget = _isElementChildOfClass(
            relatedTarget,
            "ag-root",
            element
          );
          const isNestedTarget = _isElementChildOfClass(target, "ag-root", element);
          if (isNestedTarget) {
            return;
          }
          if (!gridContainRelatedTarget || isNestedRelatedTarget) {
            element.classList.remove("ag-has-focus");
          }
        }
      });
    });
  }
  // used by ColumnAnimationService
  setColumnMovingCss(moving) {
    this.comp.setColumnMovingCss(CSS_CLASS_COLUMN_MOVING, moving);
  }
  setCellTextSelection(selectable = false) {
    this.comp.setCellSelectableCss(CSS_CLASS_CELL_SELECTABLE, selectable);
  }
  onScrollVisibilityChanged() {
    const visible = this.scrollVisibleService.isVerticalScrollShowing();
    this.setVerticalScrollPaddingVisible(visible);
    this.setStickyWidth(visible);
    this.setStickyBottomOffsetBottom();
    const scrollbarWidth = visible ? this.gos.getScrollbarWidth() || 0 : 0;
    const pad = _isInvisibleScrollbar() ? 16 : 0;
    const width = `calc(100% + ${scrollbarWidth + pad}px)`;
    this.animationFrameService.requestAnimationFrame(() => this.comp.setBodyViewportWidth(width));
  }
  onGridColumnsChanged() {
    const columns = this.columnModel.getCols();
    this.comp.setColumnCount(columns.length);
  }
  // if we do not do this, then the user can select a pic in the grid (eg an image in a custom cell renderer)
  // and then that will start the browser native drag n' drop, which messes up with our own drag and drop.
  disableBrowserDragging() {
    this.addManagedElementListeners(this.eGridBody, {
      dragstart: (event) => {
        if (event.target instanceof HTMLImageElement) {
          event.preventDefault();
          return false;
        }
      }
    });
  }
  addStopEditingWhenGridLosesFocus() {
    if (!this.gos.get("stopEditingWhenCellsLoseFocus")) {
      return;
    }
    const focusOutListener = (event) => {
      const elementWithFocus = event.relatedTarget;
      if (_getTabIndex(elementWithFocus) === null) {
        this.rowRenderer.stopEditing();
        return;
      }
      let clickInsideGrid = (
        // see if click came from inside the viewports
        viewports.some((viewport) => viewport.contains(elementWithFocus)) && // and also that it's not from a detail grid
        this.mouseEventService.isElementInThisGrid(elementWithFocus)
      );
      if (!clickInsideGrid) {
        const popupService = this.popupService;
        clickInsideGrid = popupService.getActivePopups().some((popup) => popup.contains(elementWithFocus)) || popupService.isElementWithinCustomPopup(elementWithFocus);
      }
      if (!clickInsideGrid) {
        this.rowRenderer.stopEditing();
      }
    };
    const viewports = [this.eBodyViewport, this.eBottom, this.eTop, this.eStickyTop, this.eStickyBottom];
    viewports.forEach((viewport) => this.addManagedElementListeners(viewport, { focusout: focusOutListener }));
  }
  updateRowCount() {
    const headerCount = this.headerNavigationService.getHeaderRowCount() + (this.filterManager?.getHeaderRowCount() ?? 0);
    const rowCount = this.rowModel.isLastRowIndexKnown() ? this.rowModel.getRowCount() : -1;
    const total = rowCount === -1 ? -1 : headerCount + rowCount;
    this.comp.setRowCount(total);
  }
  registerBodyViewportResizeListener(listener) {
    this.comp.registerBodyViewportResizeListener(listener);
  }
  setVerticalScrollPaddingVisible(visible) {
    const overflowY = visible ? "scroll" : "hidden";
    this.comp.setPinnedTopBottomOverflowY(overflowY);
  }
  isVerticalScrollShowing() {
    const show = this.gos.get("alwaysShowVerticalScroll");
    const cssClass = show ? CSS_CLASS_FORCE_VERTICAL_SCROLL : null;
    const allowVerticalScroll = this.gos.isDomLayout("normal");
    this.comp.setAlwaysVerticalScrollClass(cssClass, show);
    return show || allowVerticalScroll && _isVerticalScrollShowing(this.eBodyViewport);
  }
  setupRowAnimationCssClass() {
    let initialSizeMeasurementComplete = this.environment.hasMeasuredSizes();
    const updateAnimationClass = () => {
      const animateRows = initialSizeMeasurementComplete && this.gos.isAnimateRows() && !this.rowContainerHeightService.isStretching();
      const animateRowsCssClass = animateRows ? "ag-row-animation" : "ag-row-no-animation";
      this.comp.setRowAnimationCssOnBodyViewport(animateRowsCssClass, animateRows);
    };
    updateAnimationClass();
    this.addManagedEventListeners({ heightScaleChanged: updateAnimationClass });
    this.addManagedPropertyListener("animateRows", updateAnimationClass);
    this.addManagedEventListeners({
      gridStylesChanged: () => {
        if (!initialSizeMeasurementComplete && this.environment.hasMeasuredSizes()) {
          initialSizeMeasurementComplete = true;
          updateAnimationClass();
        }
      }
    });
  }
  getGridBodyElement() {
    return this.eGridBody;
  }
  addBodyViewportListener() {
    const listener = this.onBodyViewportContextMenu.bind(this);
    this.addManagedElementListeners(this.eBodyViewport, { contextmenu: listener });
    this.mockContextMenuForIPad(listener);
    this.addManagedElementListeners(this.eBodyViewport, { wheel: this.onBodyViewportWheel.bind(this) });
    this.addManagedElementListeners(this.eStickyTop, { wheel: this.onStickyWheel.bind(this) });
    this.addManagedElementListeners(this.eStickyBottom, { wheel: this.onStickyWheel.bind(this) });
    this.addFullWidthContainerWheelListener();
  }
  addFullWidthContainerWheelListener() {
    const fullWidthContainer = this.eBodyViewport.querySelector(".ag-full-width-container");
    const eCenterColsViewport = this.eBodyViewport.querySelector(".ag-center-cols-viewport");
    if (fullWidthContainer && eCenterColsViewport) {
      this.addManagedElementListeners(fullWidthContainer, {
        wheel: (e) => this.onFullWidthContainerWheel(e, eCenterColsViewport)
      });
    }
  }
  onFullWidthContainerWheel(e, eCenterColsViewport) {
    if (!e.deltaX || Math.abs(e.deltaY) > Math.abs(e.deltaX) || !this.mouseEventService.isEventFromThisGrid(e)) {
      return;
    }
    e.preventDefault();
    eCenterColsViewport.scrollBy({ left: e.deltaX });
  }
  onBodyViewportContextMenu(mouseEvent, touch, touchEvent) {
    if (!mouseEvent && !touchEvent) {
      return;
    }
    if (this.gos.get("preventDefaultOnContextMenu")) {
      const event = mouseEvent || touchEvent;
      event.preventDefault();
    }
    const { target } = mouseEvent || touch;
    if (target === this.eBodyViewport || target === this.ctrlsService.get("center").getViewportElement()) {
      this.menuService.showContextMenu({
        mouseEvent,
        touchEvent,
        value: null,
        anchorToElement: this.eGridBody
      });
    }
  }
  mockContextMenuForIPad(listener) {
    if (!_isIOSUserAgent()) {
      return;
    }
    const touchListener = new TouchListener(this.eBodyViewport);
    const longTapListener = (event) => {
      listener(void 0, event.touchStart, event.touchEvent);
    };
    this.addManagedListeners(touchListener, { longTap: longTapListener });
    this.addDestroyFunc(() => touchListener.destroy());
  }
  onBodyViewportWheel(e) {
    if (!this.gos.get("suppressScrollWhenPopupsAreOpen")) {
      return;
    }
    if (this.popupService.hasAnchoredPopup()) {
      e.preventDefault();
    }
  }
  onStickyWheel(e) {
    e.preventDefault();
    if (e.offsetY) {
      this.scrollVertically(e.deltaY);
    }
  }
  getGui() {
    return this.eGridBody;
  }
  // called by rowDragFeature
  scrollVertically(pixels) {
    const oldScrollPosition = this.eBodyViewport.scrollTop;
    this.bodyScrollFeature.setVerticalScrollPosition(oldScrollPosition + pixels);
    return this.eBodyViewport.scrollTop - oldScrollPosition;
  }
  addRowDragListener() {
    this.rowDragFeature = this.createManagedBean(new RowDragFeature(this.eBodyViewport));
    this.dragAndDropService.addDropTarget(this.rowDragFeature);
  }
  getRowDragFeature() {
    return this.rowDragFeature;
  }
  setFloatingHeights() {
    const { pinnedRowModel } = this;
    const floatingTopHeight = pinnedRowModel.getPinnedTopTotalHeight();
    const floatingBottomHeight = pinnedRowModel.getPinnedBottomTotalHeight();
    this.comp.setTopHeight(floatingTopHeight);
    this.comp.setBottomHeight(floatingBottomHeight);
    this.comp.setTopDisplay(floatingTopHeight ? "inherit" : "none");
    this.comp.setBottomDisplay(floatingBottomHeight ? "inherit" : "none");
    this.setStickyTopOffsetTop();
    this.setStickyBottomOffsetBottom();
  }
  setStickyTopHeight(height = 0) {
    this.comp.setStickyTopHeight(`${height}px`);
    this.stickyTopHeight = height;
  }
  getStickyTopHeight() {
    return this.stickyTopHeight;
  }
  setStickyBottomHeight(height = 0) {
    this.comp.setStickyBottomHeight(`${height}px`);
    this.stickyBottomHeight = height;
  }
  getStickyBottomHeight() {
    return this.stickyBottomHeight;
  }
  setStickyWidth(vScrollVisible) {
    if (!vScrollVisible) {
      this.comp.setStickyTopWidth("100%");
      this.comp.setStickyBottomWidth("100%");
    } else {
      const scrollbarWidth = this.gos.getScrollbarWidth();
      this.comp.setStickyTopWidth(`calc(100% - ${scrollbarWidth}px)`);
      this.comp.setStickyBottomWidth(`calc(100% - ${scrollbarWidth}px)`);
    }
  }
  onHeaderHeightChanged() {
    this.setStickyTopOffsetTop();
  }
  setStickyTopOffsetTop() {
    const headerCtrl = this.ctrlsService.get("gridHeaderCtrl");
    const headerHeight = headerCtrl.getHeaderHeight() + (this.filterManager?.getHeaderHeight() ?? 0);
    const pinnedTopHeight = this.pinnedRowModel.getPinnedTopTotalHeight();
    let height = 0;
    if (headerHeight > 0) {
      height += headerHeight;
    }
    if (pinnedTopHeight > 0) {
      height += pinnedTopHeight;
    }
    if (height > 0) {
      height += 1;
    }
    this.comp.setStickyTopTop(`${height}px`);
  }
  setStickyBottomOffsetBottom() {
    const pinnedBottomHeight = this.pinnedRowModel.getPinnedBottomTotalHeight();
    const hScrollShowing = this.scrollVisibleService.isHorizontalScrollShowing();
    const scrollbarWidth = hScrollShowing ? this.gos.getScrollbarWidth() || 0 : 0;
    const height = pinnedBottomHeight + scrollbarWidth;
    this.comp.setStickyBottomBottom(`${height}px`);
  }
  // method will call itself if no available width. this covers if the grid
  // isn't visible, but is just about to be visible.
  sizeColumnsToFit(params, nextTimeout) {
    const removeScrollWidth = this.isVerticalScrollShowing();
    const scrollWidthToRemove = removeScrollWidth ? this.gos.getScrollbarWidth() : 0;
    const bodyViewportWidth = _getInnerWidth(this.eGridBody);
    const availableWidth = bodyViewportWidth - scrollWidthToRemove;
    if (availableWidth > 0) {
      this.columnSizeService.sizeColumnsToFit(availableWidth, "sizeColumnsToFit", false, params);
      return;
    }
    if (nextTimeout === void 0) {
      window.setTimeout(() => {
        this.sizeColumnsToFit(params, 100);
      }, 0);
    } else if (nextTimeout === 100) {
      window.setTimeout(() => {
        this.sizeColumnsToFit(params, 500);
      }, 100);
    } else if (nextTimeout === 500) {
      window.setTimeout(() => {
        this.sizeColumnsToFit(params, -1);
      }, 500);
    } else {
      _warnOnce(
        "tried to call sizeColumnsToFit() but the grid is coming back with zero width, maybe the grid is not visible yet on the screen?"
      );
    }
  }
  // + rangeService
  addScrollEventListener(listener) {
    this.eBodyViewport.addEventListener("scroll", listener, { passive: true });
  }
  // + focusService
  removeScrollEventListener(listener) {
    this.eBodyViewport.removeEventListener("scroll", listener);
  }
};

// community-modules/core/src/rendering/cell/cellComp.ts
var CellComp = class extends Component {
  constructor(beans, cellCtrl, printLayout, eRow, editingRow) {
    super();
    // every time we go into edit mode, or back again, this gets incremented.
    // it's the components way of dealing with the async nature of framework components,
    // so if a framework component takes a while to be created, we know if the object
    // is still relevant when creating is finished. eg we could click edit / un-edit 20
    // times before the first React edit component comes back - we should discard
    // the first 19.
    this.rendererVersion = 0;
    this.editorVersion = 0;
    this.beans = beans;
    this.column = cellCtrl.getColumn();
    this.rowNode = cellCtrl.getRowNode();
    this.rowCtrl = cellCtrl.getRowCtrl();
    this.eRow = eRow;
    this.cellCtrl = cellCtrl;
    const cellDiv = document.createElement("div");
    cellDiv.setAttribute("comp-id", `${this.getCompId()}`);
    this.setTemplateFromElement(cellDiv);
    const eGui = this.getGui();
    this.forceWrapper = cellCtrl.isForceWrapper();
    this.refreshWrapper(false);
    const setAttribute = (name, value) => {
      if (value != null && value != "") {
        eGui.setAttribute(name, value);
      } else {
        eGui.removeAttribute(name);
      }
    };
    _setAriaRole(eGui, cellCtrl.getCellAriaRole());
    setAttribute("col-id", cellCtrl.getColumnIdSanitised());
    const tabIndex = cellCtrl.getTabIndex();
    if (tabIndex !== void 0) {
      setAttribute("tabindex", tabIndex.toString());
    }
    const compProxy = {
      addOrRemoveCssClass: (cssClassName, on) => this.addOrRemoveCssClass(cssClassName, on),
      setUserStyles: (styles) => _addStylesToElement(eGui, styles),
      getFocusableElement: () => this.getFocusableElement(),
      setIncludeSelection: (include) => this.includeSelection = include,
      setIncludeRowDrag: (include) => this.includeRowDrag = include,
      setIncludeDndSource: (include) => this.includeDndSource = include,
      setRenderDetails: (compDetails, valueToDisplay, force) => this.setRenderDetails(compDetails, valueToDisplay, force),
      setEditDetails: (compDetails, popup, position) => this.setEditDetails(compDetails, popup, position),
      getCellEditor: () => this.cellEditor || null,
      getCellRenderer: () => this.cellRenderer || null,
      getParentOfValue: () => this.getParentOfValue()
    };
    cellCtrl.setComp(compProxy, this.getGui(), this.eCellWrapper, printLayout, editingRow);
  }
  getParentOfValue() {
    if (this.eCellValue) {
      return this.eCellValue;
    }
    if (this.eCellWrapper) {
      return this.eCellWrapper;
    }
    return this.getGui();
  }
  setRenderDetails(compDetails, valueToDisplay, forceNewCellRendererInstance) {
    const isInlineEditing = this.cellEditor && !this.cellEditorPopupWrapper;
    if (isInlineEditing) {
      return;
    }
    this.firstRender = this.firstRender == null;
    const controlWrapperChanged = this.refreshWrapper(false);
    this.refreshEditStyles(false);
    if (compDetails) {
      const neverRefresh = forceNewCellRendererInstance || controlWrapperChanged;
      const cellRendererRefreshSuccessful = neverRefresh ? false : this.refreshCellRenderer(compDetails);
      if (!cellRendererRefreshSuccessful) {
        this.destroyRenderer();
        this.createCellRendererInstance(compDetails);
      }
    } else {
      this.destroyRenderer();
      this.insertValueWithoutCellRenderer(valueToDisplay);
    }
  }
  setEditDetails(compDetails, popup, position) {
    if (compDetails) {
      this.createCellEditorInstance(compDetails, popup, position);
    } else {
      this.destroyEditor();
    }
  }
  removeControls() {
    this.checkboxSelectionComp = this.beans.context.destroyBean(this.checkboxSelectionComp);
    this.dndSourceComp = this.beans.context.destroyBean(this.dndSourceComp);
    this.rowDraggingComp = this.beans.context.destroyBean(this.rowDraggingComp);
  }
  // returns true if wrapper was changed
  refreshWrapper(editing) {
    const providingControls = this.includeRowDrag || this.includeDndSource || this.includeSelection;
    const usingWrapper = providingControls || this.forceWrapper;
    const putWrapperIn = usingWrapper && this.eCellWrapper == null;
    if (putWrapperIn) {
      const wrapperDiv = document.createElement("div");
      wrapperDiv.setAttribute("role", "presentation");
      wrapperDiv.setAttribute("class", "ag-cell-wrapper");
      this.eCellWrapper = wrapperDiv;
      this.getGui().appendChild(this.eCellWrapper);
    }
    const takeWrapperOut = !usingWrapper && this.eCellWrapper != null;
    if (takeWrapperOut) {
      _removeFromParent(this.eCellWrapper);
      this.eCellWrapper = void 0;
    }
    this.addOrRemoveCssClass("ag-cell-value", !usingWrapper);
    const usingCellValue = !editing && usingWrapper;
    const putCellValueIn = usingCellValue && this.eCellValue == null;
    if (putCellValueIn) {
      const cellSpan = document.createElement("span");
      cellSpan.setAttribute("role", "presentation");
      cellSpan.setAttribute("class", "ag-cell-value");
      this.eCellValue = cellSpan;
      this.eCellWrapper.appendChild(this.eCellValue);
    }
    const takeCellValueOut = !usingCellValue && this.eCellValue != null;
    if (takeCellValueOut) {
      _removeFromParent(this.eCellValue);
      this.eCellValue = void 0;
    }
    const templateChanged = putWrapperIn || takeWrapperOut || putCellValueIn || takeCellValueOut;
    if (templateChanged) {
      this.removeControls();
    }
    if (!editing) {
      if (providingControls) {
        this.addControls();
      }
    }
    return templateChanged;
  }
  addControls() {
    if (this.includeRowDrag) {
      if (this.rowDraggingComp == null) {
        this.rowDraggingComp = this.cellCtrl.createRowDragComp();
        if (this.rowDraggingComp) {
          this.eCellWrapper.insertBefore(this.rowDraggingComp.getGui(), this.eCellValue);
        }
      }
    }
    if (this.includeDndSource) {
      if (this.dndSourceComp == null) {
        this.dndSourceComp = this.cellCtrl.createDndSource();
        this.eCellWrapper.insertBefore(this.dndSourceComp.getGui(), this.eCellValue);
      }
    }
    if (this.includeSelection) {
      if (this.checkboxSelectionComp == null) {
        this.checkboxSelectionComp = this.cellCtrl.createSelectionCheckbox();
        this.eCellWrapper.insertBefore(this.checkboxSelectionComp.getGui(), this.eCellValue);
      }
    }
  }
  createCellEditorInstance(compDetails, popup, position) {
    const versionCopy = this.editorVersion;
    const cellEditorPromise = compDetails.newAgStackInstance();
    if (cellEditorPromise == null) {
      return;
    }
    const { params } = compDetails;
    cellEditorPromise.then((c) => this.afterCellEditorCreated(versionCopy, c, params, popup, position));
    const cellEditorAsync = _missing(this.cellEditor);
    if (cellEditorAsync && params.cellStartedEdit) {
      this.cellCtrl.focusCell(true);
    }
  }
  insertValueWithoutCellRenderer(valueToDisplay) {
    const eParent = this.getParentOfValue();
    _clearElement(eParent);
    const escapedValue = valueToDisplay != null ? _escapeString(valueToDisplay, true) : null;
    if (escapedValue != null) {
      eParent.textContent = escapedValue;
    }
  }
  destroyEditorAndRenderer() {
    this.destroyRenderer();
    this.destroyEditor();
  }
  destroyRenderer() {
    const { context } = this.beans;
    this.cellRenderer = context.destroyBean(this.cellRenderer);
    _removeFromParent(this.cellRendererGui);
    this.cellRendererGui = null;
    this.rendererVersion++;
  }
  destroyEditor() {
    const { context } = this.beans;
    if (this.hideEditorPopup) {
      this.hideEditorPopup();
    }
    this.hideEditorPopup = void 0;
    this.cellEditor = context.destroyBean(this.cellEditor);
    this.cellEditorPopupWrapper = context.destroyBean(this.cellEditorPopupWrapper);
    _removeFromParent(this.cellEditorGui);
    this.cellEditorGui = null;
    this.editorVersion++;
  }
  refreshCellRenderer(compClassAndParams) {
    if (this.cellRenderer == null || this.cellRenderer.refresh == null) {
      return false;
    }
    if (this.cellRendererClass !== compClassAndParams.componentClass) {
      return false;
    }
    const result = this.cellRenderer.refresh(compClassAndParams.params);
    return result === true || result === void 0;
  }
  createCellRendererInstance(compDetails) {
    const suppressAnimationFrame = this.beans.gos.get("suppressAnimationFrame");
    const useTaskService = !suppressAnimationFrame;
    const displayComponentVersionCopy = this.rendererVersion;
    const { componentClass } = compDetails;
    const createCellRendererFunc = () => {
      const staleTask = this.rendererVersion !== displayComponentVersionCopy || !this.isAlive();
      if (staleTask) {
        return;
      }
      const componentPromise = compDetails.newAgStackInstance();
      const callback = this.afterCellRendererCreated.bind(this, displayComponentVersionCopy, componentClass);
      if (componentPromise) {
        componentPromise.then(callback);
      }
    };
    if (useTaskService && this.firstRender) {
      this.beans.animationFrameService.createTask(
        createCellRendererFunc,
        this.rowNode.rowIndex,
        "createTasksP2"
      );
    } else {
      createCellRendererFunc();
    }
  }
  getCtrl() {
    return this.cellCtrl;
  }
  getRowCtrl() {
    return this.rowCtrl;
  }
  getCellRenderer() {
    return this.cellRenderer;
  }
  getCellEditor() {
    return this.cellEditor;
  }
  afterCellRendererCreated(cellRendererVersion, cellRendererClass, cellRenderer) {
    const staleTask = !this.isAlive() || cellRendererVersion !== this.rendererVersion;
    if (staleTask) {
      this.beans.context.destroyBean(cellRenderer);
      return;
    }
    this.cellRenderer = cellRenderer;
    this.cellRendererClass = cellRendererClass;
    this.cellRendererGui = this.cellRenderer.getGui();
    if (this.cellRendererGui != null) {
      const eParent = this.getParentOfValue();
      _clearElement(eParent);
      eParent.appendChild(this.cellRendererGui);
    }
  }
  afterCellEditorCreated(requestVersion, cellEditor, params, popup, position) {
    const staleComp = requestVersion !== this.editorVersion;
    if (staleComp) {
      this.beans.context.destroyBean(cellEditor);
      return;
    }
    const editingCancelledByUserComp = cellEditor.isCancelBeforeStart && cellEditor.isCancelBeforeStart();
    if (editingCancelledByUserComp) {
      this.beans.context.destroyBean(cellEditor);
      this.cellCtrl.stopEditing(true);
      return;
    }
    if (!cellEditor.getGui) {
      _warnOnce(`cellEditor for column ${this.column.getId()} is missing getGui() method`);
      this.beans.context.destroyBean(cellEditor);
      return;
    }
    this.cellEditor = cellEditor;
    this.cellEditorGui = cellEditor.getGui();
    const cellEditorInPopup = popup || cellEditor.isPopup !== void 0 && cellEditor.isPopup();
    if (cellEditorInPopup) {
      this.addPopupCellEditor(params, position);
    } else {
      this.addInCellEditor();
    }
    this.refreshEditStyles(true, cellEditorInPopup);
    if (cellEditor.afterGuiAttached) {
      cellEditor.afterGuiAttached();
    }
  }
  refreshEditStyles(editing, isPopup) {
    this.addOrRemoveCssClass("ag-cell-inline-editing", editing && !isPopup);
    this.addOrRemoveCssClass("ag-cell-popup-editing", editing && !!isPopup);
    this.addOrRemoveCssClass("ag-cell-not-inline-editing", !editing || !!isPopup);
    this.rowCtrl?.setInlineEditingCss(editing);
  }
  addInCellEditor() {
    const eGui = this.getGui();
    if (eGui.contains(this.beans.gos.getActiveDomElement())) {
      eGui.focus();
    }
    this.destroyRenderer();
    this.refreshWrapper(true);
    this.clearParentOfValue();
    if (this.cellEditorGui) {
      const eParent = this.getParentOfValue();
      eParent.appendChild(this.cellEditorGui);
    }
  }
  addPopupCellEditor(params, position) {
    if (this.beans.gos.get("editType") === "fullRow") {
      _warnOnce(
        "popup cellEditor does not work with fullRowEdit - you cannot use them both - either turn off fullRowEdit, or stop using popup editors."
      );
    }
    const cellEditor = this.cellEditor;
    this.cellEditorPopupWrapper = this.beans.context.createBean(
      this.beans.editService.createPopupEditorWrapper(params)
    );
    const ePopupGui = this.cellEditorPopupWrapper.getGui();
    if (this.cellEditorGui) {
      ePopupGui.appendChild(this.cellEditorGui);
    }
    const popupService = this.beans.popupService;
    const useModelPopup = this.beans.gos.get("stopEditingWhenCellsLoseFocus");
    const positionToUse = position != null ? position : cellEditor.getPopupPosition ? cellEditor.getPopupPosition() : "over";
    const isRtl = this.beans.gos.get("enableRtl");
    const positionParams = {
      ePopup: ePopupGui,
      column: this.column,
      rowNode: this.rowNode,
      type: "popupCellEditor",
      eventSource: this.getGui(),
      position: positionToUse,
      alignSide: isRtl ? "right" : "left",
      keepWithinBounds: true
    };
    const positionCallback = popupService.positionPopupByComponent.bind(popupService, positionParams);
    const translate = this.beans.localeService.getLocaleTextFunc();
    const addPopupRes = popupService.addPopup({
      modal: useModelPopup,
      eChild: ePopupGui,
      closeOnEsc: true,
      closedCallback: () => {
        this.cellCtrl.onPopupEditorClosed();
      },
      anchorToElement: this.getGui(),
      positionCallback,
      ariaLabel: translate("ariaLabelCellEditor", "Cell Editor")
    });
    if (addPopupRes) {
      this.hideEditorPopup = addPopupRes.hideFunc;
    }
  }
  detach() {
    this.eRow.removeChild(this.getGui());
  }
  // if the row is also getting destroyed, then we don't need to remove from dom,
  // as the row will also get removed, so no need to take out the cells from the row
  // if the row is going (removing is an expensive operation, so only need to remove
  // the top part)
  //
  // note - this is NOT called by context, as we don't wire / unwire the CellComp for performance reasons.
  destroy() {
    this.cellCtrl.stopEditing();
    this.destroyEditorAndRenderer();
    this.removeControls();
    super.destroy();
  }
  clearParentOfValue() {
    const eGui = this.getGui();
    if (eGui.contains(this.beans.gos.getActiveDomElement()) && _browserSupportsPreventScroll()) {
      eGui.focus({ preventScroll: true });
    }
    _clearElement(this.getParentOfValue());
  }
};

// community-modules/core/src/rendering/row/rowComp.ts
var RowComp = class extends Component {
  constructor(ctrl, beans, containerType) {
    super();
    this.cellComps = {};
    this.beans = beans;
    this.rowCtrl = ctrl;
    const rowDiv = document.createElement("div");
    rowDiv.setAttribute("comp-id", `${this.getCompId()}`);
    rowDiv.setAttribute("style", this.getInitialStyle(containerType));
    this.setTemplateFromElement(rowDiv);
    const eGui = this.getGui();
    const style = eGui.style;
    this.domOrder = this.rowCtrl.getDomOrder();
    _setAriaRole(eGui, "row");
    const tabIndex = this.rowCtrl.getTabIndex();
    if (tabIndex != null) {
      eGui.setAttribute("tabindex", tabIndex.toString());
    }
    const compProxy = {
      setDomOrder: (domOrder) => this.domOrder = domOrder,
      setCellCtrls: (cellCtrls) => this.setCellCtrls(cellCtrls),
      showFullWidth: (compDetails) => this.showFullWidth(compDetails),
      getFullWidthCellRenderer: () => this.getFullWidthCellRenderer(),
      addOrRemoveCssClass: (name, on) => this.addOrRemoveCssClass(name, on),
      setUserStyles: (styles) => _addStylesToElement(eGui, styles),
      setTop: (top) => style.top = top,
      setTransform: (transform) => style.transform = transform,
      setRowIndex: (rowIndex) => eGui.setAttribute("row-index", rowIndex),
      setRowId: (rowId) => eGui.setAttribute("row-id", rowId),
      setRowBusinessKey: (businessKey) => eGui.setAttribute("row-business-key", businessKey),
      refreshFullWidth: (getUpdatedParams) => this.refreshFullWidth(getUpdatedParams)
    };
    ctrl.setComp(compProxy, this.getGui(), containerType);
    this.addDestroyFunc(() => {
      ctrl.unsetComp(containerType);
    });
  }
  getInitialStyle(containerType) {
    const transform = this.rowCtrl.getInitialTransform(containerType);
    return transform ? `transform: ${transform}` : `top: ${this.rowCtrl.getInitialRowTop(containerType)}`;
  }
  showFullWidth(compDetails) {
    const callback = (cellRenderer) => {
      if (this.isAlive()) {
        const eGui = cellRenderer.getGui();
        this.getGui().appendChild(eGui);
        this.rowCtrl.setupDetailRowAutoHeight(eGui);
        this.setFullWidthRowComp(cellRenderer);
      } else {
        this.beans.context.destroyBean(cellRenderer);
      }
    };
    const res = compDetails.newAgStackInstance();
    if (!res) {
      return;
    }
    res.then(callback);
  }
  setCellCtrls(cellCtrls) {
    const cellsToRemove = Object.assign({}, this.cellComps);
    cellCtrls.forEach((cellCtrl) => {
      const key = cellCtrl.getInstanceId();
      const existingCellComp = this.cellComps[key];
      if (existingCellComp == null) {
        this.newCellComp(cellCtrl);
      } else {
        cellsToRemove[key] = null;
      }
    });
    const cellCompsToRemove = _getAllValuesInObject(cellsToRemove).filter((cellComp) => cellComp != null);
    this.destroyCells(cellCompsToRemove);
    this.ensureDomOrder(cellCtrls);
  }
  ensureDomOrder(cellCtrls) {
    if (!this.domOrder) {
      return;
    }
    const elementsInOrder = [];
    cellCtrls.forEach((cellCtrl) => {
      const cellComp = this.cellComps[cellCtrl.getInstanceId()];
      if (cellComp) {
        elementsInOrder.push(cellComp.getGui());
      }
    });
    _setDomChildOrder(this.getGui(), elementsInOrder);
  }
  newCellComp(cellCtrl) {
    const cellComp = new CellComp(
      this.beans,
      cellCtrl,
      this.rowCtrl.isPrintLayout(),
      this.getGui(),
      this.rowCtrl.isEditing()
    );
    this.cellComps[cellCtrl.getInstanceId()] = cellComp;
    this.getGui().appendChild(cellComp.getGui());
  }
  destroy() {
    super.destroy();
    this.destroyAllCells();
  }
  destroyAllCells() {
    const cellsToDestroy = _getAllValuesInObject(this.cellComps).filter((cp) => cp != null);
    this.destroyCells(cellsToDestroy);
  }
  setFullWidthRowComp(fullWidthRowComponent) {
    if (this.fullWidthCellRenderer) {
      _errorOnce("should not be setting fullWidthRowComponent twice");
    }
    this.fullWidthCellRenderer = fullWidthRowComponent;
    this.addDestroyFunc(() => {
      this.fullWidthCellRenderer = this.beans.context.destroyBean(this.fullWidthCellRenderer);
    });
  }
  getFullWidthCellRenderer() {
    return this.fullWidthCellRenderer;
  }
  destroyCells(cellComps) {
    cellComps.forEach((cellComp) => {
      if (!cellComp) {
        return;
      }
      const instanceId = cellComp.getCtrl().getInstanceId();
      if (this.cellComps[instanceId] !== cellComp) {
        return;
      }
      cellComp.detach();
      cellComp.destroy();
      this.cellComps[instanceId] = null;
    });
  }
  refreshFullWidth(getUpdatedParams) {
    const { fullWidthCellRenderer } = this;
    if (!fullWidthCellRenderer || !fullWidthCellRenderer.refresh) {
      return false;
    }
    const params = getUpdatedParams();
    return fullWidthCellRenderer.refresh(params);
  }
};

// community-modules/core/src/gridBodyComp/viewportSizeFeature.ts
var ViewportSizeFeature = class extends BeanStub {
  wireBeans(beans) {
    this.ctrlsService = beans.ctrlsService;
    this.pinnedWidthService = beans.pinnedWidthService;
    this.columnModel = beans.columnModel;
    this.visibleColsService = beans.visibleColsService;
    this.columnSizeService = beans.columnSizeService;
    this.scrollVisibleService = beans.scrollVisibleService;
    this.columnViewportService = beans.columnViewportService;
  }
  constructor(centerContainerCtrl) {
    super();
    this.centerContainerCtrl = centerContainerCtrl;
  }
  postConstruct() {
    this.ctrlsService.whenReady((p) => {
      this.gridBodyCtrl = p.gridBodyCtrl;
      this.listenForResize();
    });
    this.addManagedEventListeners({ scrollbarWidthChanged: this.onScrollbarWidthChanged.bind(this) });
    this.addManagedPropertyListeners(["alwaysShowHorizontalScroll", "alwaysShowVerticalScroll"], () => {
      this.checkViewportAndScrolls();
    });
  }
  listenForResize() {
    const listener = () => this.onCenterViewportResized();
    this.centerContainerCtrl.registerViewportResizeListener(listener);
    this.gridBodyCtrl.registerBodyViewportResizeListener(listener);
  }
  onScrollbarWidthChanged() {
    this.checkViewportAndScrolls();
  }
  onCenterViewportResized() {
    if (this.centerContainerCtrl.isViewportInTheDOMTree()) {
      this.keepPinnedColumnsNarrowerThanViewport();
      this.checkViewportAndScrolls();
      const newWidth = this.centerContainerCtrl.getCenterWidth();
      if (newWidth !== this.centerWidth) {
        this.centerWidth = newWidth;
        this.columnSizeService.refreshFlexedColumns({
          viewportWidth: this.centerWidth,
          updateBodyWidths: true,
          fireResizedEvent: true
        });
      }
    } else {
      this.bodyHeight = 0;
    }
  }
  keepPinnedColumnsNarrowerThanViewport() {
    const eBodyViewport = this.gridBodyCtrl.getBodyViewportElement();
    const bodyWidth = _getInnerWidth(eBodyViewport);
    if (bodyWidth <= 50) {
      return;
    }
    let columnsToRemove = this.getPinnedColumnsOverflowingViewport(bodyWidth - 50);
    const processUnpinnedColumns = this.gos.getCallback("processUnpinnedColumns");
    if (!columnsToRemove.length) {
      return;
    }
    if (processUnpinnedColumns) {
      const params = {
        columns: columnsToRemove,
        viewportWidth: bodyWidth
      };
      columnsToRemove = processUnpinnedColumns(params);
    }
    this.columnModel.setColsPinned(columnsToRemove, null, "viewportSizeFeature");
  }
  getPinnedColumnsOverflowingViewport(viewportWidth) {
    const pinnedRightWidth = this.pinnedWidthService.getPinnedRightWidth();
    const pinnedLeftWidth = this.pinnedWidthService.getPinnedLeftWidth();
    const totalPinnedWidth = pinnedRightWidth + pinnedLeftWidth;
    if (totalPinnedWidth < viewportWidth) {
      return [];
    }
    const pinnedLeftColumns = [...this.visibleColsService.getLeftCols()];
    const pinnedRightColumns = [...this.visibleColsService.getRightCols()];
    let indexRight = 0;
    let indexLeft = 0;
    const totalWidthRemoved = 0;
    const columnsToRemove = [];
    let spaceNecessary = totalPinnedWidth - totalWidthRemoved - viewportWidth;
    while ((indexLeft < pinnedLeftColumns.length || indexRight < pinnedRightColumns.length) && spaceNecessary > 0) {
      if (indexRight < pinnedRightColumns.length) {
        const currentColumn = pinnedRightColumns[indexRight++];
        spaceNecessary -= currentColumn.getActualWidth();
        columnsToRemove.push(currentColumn);
      }
      if (indexLeft < pinnedLeftColumns.length && spaceNecessary > 0) {
        const currentColumn = pinnedLeftColumns[indexLeft++];
        spaceNecessary -= currentColumn.getActualWidth();
        columnsToRemove.push(currentColumn);
      }
    }
    return columnsToRemove;
  }
  // gets called every time the viewport size changes. we use this to check visibility of scrollbars
  // in the grid panel, and also to check size and position of viewport for row and column virtualisation.
  checkViewportAndScrolls() {
    this.updateScrollVisibleService();
    this.checkBodyHeight();
    this.onHorizontalViewportChanged();
    this.gridBodyCtrl.getScrollFeature().checkScrollLeft();
  }
  getBodyHeight() {
    return this.bodyHeight;
  }
  checkBodyHeight() {
    const eBodyViewport = this.gridBodyCtrl.getBodyViewportElement();
    const bodyHeight = _getInnerHeight(eBodyViewport);
    if (this.bodyHeight !== bodyHeight) {
      this.bodyHeight = bodyHeight;
      const event = {
        type: "bodyHeightChanged"
      };
      this.eventService.dispatchEvent(event);
    }
  }
  updateScrollVisibleService() {
    this.updateScrollVisibleServiceImpl();
    setTimeout(this.updateScrollVisibleServiceImpl.bind(this), 500);
  }
  updateScrollVisibleServiceImpl() {
    const params = {
      horizontalScrollShowing: this.isHorizontalScrollShowing(),
      verticalScrollShowing: this.gridBodyCtrl.isVerticalScrollShowing()
    };
    this.scrollVisibleService.setScrollsVisible(params);
  }
  isHorizontalScrollShowing() {
    return this.centerContainerCtrl.isHorizontalScrollShowing();
  }
  // this gets called whenever a change in the viewport, so we can inform column controller it has to work
  // out the virtual columns again. gets called from following locations:
  // + ensureColVisible, scroll, init, layoutChanged, displayedColumnsChanged
  onHorizontalViewportChanged() {
    const scrollWidth = this.centerContainerCtrl.getCenterWidth();
    const scrollPosition = this.centerContainerCtrl.getViewportScrollLeft();
    this.columnViewportService.setScrollPosition(scrollWidth, scrollPosition);
  }
};

// community-modules/core/src/gridBodyComp/rowContainer/dragListenerFeature.ts
var DragListenerFeature = class extends BeanStub {
  wireBeans(beans) {
    this.dragService = beans.dragService;
    this.rangeService = beans.rangeService;
  }
  constructor(eContainer) {
    super();
    this.eContainer = eContainer;
  }
  postConstruct() {
    if (!this.rangeService) {
      return;
    }
    this.params = {
      eElement: this.eContainer,
      onDragStart: this.rangeService.onDragStart.bind(this.rangeService),
      onDragStop: this.rangeService.onDragStop.bind(this.rangeService),
      onDragging: this.rangeService.onDragging.bind(this.rangeService)
    };
    this.addManagedPropertyListener("enableRangeSelection", (props) => {
      const isEnabled = props.currentValue;
      if (isEnabled) {
        this.enableFeature();
        return;
      }
      this.disableFeature();
    });
    this.addDestroyFunc(() => this.disableFeature());
    const isRangeSelection = this.gos.get("enableRangeSelection");
    if (isRangeSelection) {
      this.enableFeature();
    }
  }
  enableFeature() {
    this.dragService.addDragSource(this.params);
  }
  disableFeature() {
    this.dragService.removeDragSource(this.params);
  }
};

// community-modules/core/src/rendering/checkboxSelectionComponent.ts
var CheckboxSelectionComponent = class extends Component {
  constructor() {
    super(
      /* html*/
      `
            <div class="ag-selection-checkbox" role="presentation">
                <ag-checkbox role="presentation" data-ref="eCheckbox"></ag-checkbox>
            </div>`,
      [AgCheckboxSelector]
    );
    this.eCheckbox = RefPlaceholder;
  }
  postConstruct() {
    this.eCheckbox.setPassive(true);
  }
  getCheckboxId() {
    return this.eCheckbox.getInputElement().id;
  }
  onDataChanged() {
    this.onSelectionChanged();
  }
  onSelectableChanged() {
    this.showOrHideSelect();
  }
  onSelectionChanged() {
    const translate = this.localeService.getLocaleTextFunc();
    const state = this.rowNode.isSelected();
    const stateName = _getAriaCheckboxStateName(translate, state);
    const [ariaKey, ariaLabel] = this.rowNode.selectable ? ["ariaRowToggleSelection", "Press Space to toggle row selection"] : ["ariaRowSelectionDisabled", "Row Selection is disabled for this row"];
    const translatedLabel = translate(ariaKey, ariaLabel);
    this.eCheckbox.setValue(state, true);
    this.eCheckbox.setInputAriaLabel(`${translatedLabel} (${stateName})`);
  }
  onClicked(newValue, groupSelectsFiltered, event) {
    return this.rowNode.setSelectedParams({
      newValue,
      rangeSelect: event.shiftKey,
      groupSelectsFiltered,
      event,
      source: "checkboxSelected"
    });
  }
  init(params) {
    this.rowNode = params.rowNode;
    this.column = params.column;
    this.overrides = params.overrides;
    this.onSelectionChanged();
    this.addManagedListeners(this.eCheckbox.getInputElement(), {
      // we don't want double click on this icon to open a group
      dblclick: (event) => _stopPropagationForAgGrid(event),
      click: (event) => {
        _stopPropagationForAgGrid(event);
        const groupSelectsFiltered = this.gos.get("groupSelectsFiltered");
        const isSelected = this.eCheckbox.getValue();
        if (this.shouldHandleIndeterminateState(isSelected, groupSelectsFiltered)) {
          const result = this.onClicked(true, groupSelectsFiltered, event || {});
          if (result === 0) {
            this.onClicked(false, groupSelectsFiltered, event);
          }
        } else if (isSelected) {
          this.onClicked(false, groupSelectsFiltered, event);
        } else {
          this.onClicked(true, groupSelectsFiltered, event || {});
        }
      }
    });
    this.addManagedListeners(this.rowNode, {
      rowSelected: this.onSelectionChanged.bind(this),
      dataChanged: this.onDataChanged.bind(this),
      selectableChanged: this.onSelectableChanged.bind(this)
    });
    const isRowSelectableFunc = this.gos.get("isRowSelectable");
    const checkboxVisibleIsDynamic = isRowSelectableFunc || typeof this.getIsVisible() === "function";
    if (checkboxVisibleIsDynamic) {
      const showOrHideSelectListener = this.showOrHideSelect.bind(this);
      this.addManagedEventListeners({ displayedColumnsChanged: showOrHideSelectListener });
      this.addManagedListeners(this.rowNode, {
        dataChanged: showOrHideSelectListener,
        cellChanged: showOrHideSelectListener
      });
      this.showOrHideSelect();
    }
    this.eCheckbox.getInputElement().setAttribute("tabindex", "-1");
  }
  shouldHandleIndeterminateState(isSelected, groupSelectsFiltered) {
    return groupSelectsFiltered && (this.eCheckbox.getPreviousValue() === void 0 || isSelected === void 0) && this.gos.isRowModelType("clientSide");
  }
  showOrHideSelect() {
    let selectable = this.rowNode.selectable;
    const isVisible = this.getIsVisible();
    if (selectable) {
      if (typeof isVisible === "function") {
        const extraParams = this.overrides?.callbackParams;
        if (!this.column) {
          selectable = isVisible({ ...extraParams, node: this.rowNode, data: this.rowNode.data });
        } else {
          const params = this.column.createColumnFunctionCallbackParams(this.rowNode);
          selectable = isVisible({ ...extraParams, ...params });
        }
      } else {
        selectable = isVisible ?? false;
      }
    }
    const disableInsteadOfHide = this.column?.getColDef().showDisabledCheckboxes;
    if (disableInsteadOfHide) {
      this.eCheckbox.setDisabled(!selectable);
      this.setVisible(true);
      this.setDisplayed(true);
      return;
    }
    if (this.overrides?.removeHidden) {
      this.setDisplayed(selectable);
      return;
    }
    this.setVisible(selectable);
  }
  getIsVisible() {
    if (this.overrides) {
      return this.overrides.isVisible;
    }
    return this.column?.getColDef()?.checkboxSelection;
  }
};

// community-modules/core/src/rendering/dndSourceComp.ts
var DndSourceComp = class extends Component {
  constructor(rowNode, column, eCell) {
    super(
      /* html */
      `<div class="ag-drag-handle ag-row-drag" draggable="true"></div>`
    );
    this.rowNode = rowNode;
    this.column = column;
    this.eCell = eCell;
  }
  postConstruct() {
    const eGui = this.getGui();
    eGui.appendChild(_createIconNoSpan("rowDrag", this.gos, null));
    this.addGuiEventListener("mousedown", (e) => {
      e.stopPropagation();
    });
    this.addDragSource();
    this.checkVisibility();
  }
  addDragSource() {
    this.addGuiEventListener("dragstart", this.onDragStart.bind(this));
  }
  onDragStart(dragEvent) {
    const providedOnRowDrag = this.column.getColDef().dndSourceOnRowDrag;
    dragEvent.dataTransfer.setDragImage(this.eCell, 0, 0);
    const defaultOnRowDrag = () => {
      try {
        const jsonData = JSON.stringify(this.rowNode.data);
        dragEvent.dataTransfer.setData("application/json", jsonData);
        dragEvent.dataTransfer.setData("text/plain", jsonData);
      } catch (e) {
      }
    };
    if (providedOnRowDrag) {
      const params = this.gos.addGridCommonParams({
        rowNode: this.rowNode,
        dragEvent
      });
      providedOnRowDrag(params);
    } else {
      defaultOnRowDrag();
    }
  }
  checkVisibility() {
    const visible = this.column.isDndSource(this.rowNode);
    this.setDisplayed(visible);
  }
};

// community-modules/core/src/rendering/cell/cellCustomStyleFeature.ts
var CellCustomStyleFeature = class extends BeanStub {
  constructor(ctrl, beans) {
    super();
    this.staticClasses = [];
    this.cellCtrl = ctrl;
    this.beans = beans;
    this.column = ctrl.getColumn();
    this.rowNode = ctrl.getRowNode();
  }
  setComp(comp) {
    this.cellComp = comp;
    this.applyUserStyles();
    this.applyCellClassRules();
    this.applyClassesFromColDef();
  }
  applyCellClassRules() {
    const colDef = this.column.getColDef();
    const { cellClassRules } = colDef;
    const cellClassParams = this.beans.gos.addGridCommonParams({
      value: this.cellCtrl.getValue(),
      data: this.rowNode.data,
      node: this.rowNode,
      colDef,
      column: this.column,
      rowIndex: this.rowNode.rowIndex
    });
    this.beans.stylingService.processClassRules(
      // if current was previous, skip
      cellClassRules === this.cellClassRules ? void 0 : this.cellClassRules,
      cellClassRules,
      cellClassParams,
      (className) => this.cellComp.addOrRemoveCssClass(className, true),
      (className) => this.cellComp.addOrRemoveCssClass(className, false)
    );
    this.cellClassRules = cellClassRules;
  }
  applyUserStyles() {
    const colDef = this.column.getColDef();
    if (!colDef.cellStyle) {
      return;
    }
    let styles;
    if (typeof colDef.cellStyle === "function") {
      const cellStyleParams = this.beans.gos.addGridCommonParams({
        column: this.column,
        value: this.cellCtrl.getValue(),
        colDef,
        data: this.rowNode.data,
        node: this.rowNode,
        rowIndex: this.rowNode.rowIndex
      });
      const cellStyleFunc = colDef.cellStyle;
      styles = cellStyleFunc(cellStyleParams);
    } else {
      styles = colDef.cellStyle;
    }
    if (styles) {
      this.cellComp.setUserStyles(styles);
    }
  }
  applyClassesFromColDef() {
    const colDef = this.column.getColDef();
    const cellClassParams = this.beans.gos.addGridCommonParams({
      value: this.cellCtrl.getValue(),
      data: this.rowNode.data,
      node: this.rowNode,
      column: this.column,
      colDef,
      rowIndex: this.rowNode.rowIndex
    });
    if (this.staticClasses.length) {
      this.staticClasses.forEach((className) => this.cellComp.addOrRemoveCssClass(className, false));
    }
    this.staticClasses = this.beans.stylingService.getStaticCellClasses(colDef, cellClassParams);
    if (this.staticClasses.length) {
      this.staticClasses.forEach((className) => this.cellComp.addOrRemoveCssClass(className, true));
    }
  }
  // overriding to make public, as we don't dispose this bean via context
  destroy() {
    super.destroy();
  }
};

// community-modules/core/src/rendering/cell/cellKeyboardListenerFeature.ts
var CellKeyboardListenerFeature = class extends BeanStub {
  constructor(ctrl, beans, column, rowNode, rowCtrl) {
    super();
    this.cellCtrl = ctrl;
    this.beans = beans;
    this.rowNode = rowNode;
    this.rowCtrl = rowCtrl;
  }
  setComp(eGui) {
    this.eGui = eGui;
  }
  onKeyDown(event) {
    const key = event.key;
    switch (key) {
      case KeyCode.ENTER:
        this.onEnterKeyDown(event);
        break;
      case KeyCode.F2:
        this.onF2KeyDown(event);
        break;
      case KeyCode.ESCAPE:
        this.onEscapeKeyDown(event);
        break;
      case KeyCode.TAB:
        this.onTabKeyDown(event);
        break;
      case KeyCode.BACKSPACE:
      case KeyCode.DELETE:
        this.onBackspaceOrDeleteKeyDown(key, event);
        break;
      case KeyCode.DOWN:
      case KeyCode.UP:
      case KeyCode.RIGHT:
      case KeyCode.LEFT:
        this.onNavigationKeyDown(event, key);
        break;
    }
  }
  onNavigationKeyDown(event, key) {
    if (this.cellCtrl.isEditing()) {
      return;
    }
    if (event.shiftKey && this.cellCtrl.isRangeSelectionEnabled()) {
      this.onShiftRangeSelect(event);
    } else {
      this.beans.navigationService.navigateToNextCell(event, key, this.cellCtrl.getCellPosition(), true);
    }
    event.preventDefault();
  }
  onShiftRangeSelect(event) {
    if (!this.beans.rangeService) {
      return;
    }
    const endCell = this.beans.rangeService.extendLatestRangeInDirection(event);
    if (endCell) {
      this.beans.navigationService.ensureCellVisible(endCell);
    }
  }
  onTabKeyDown(event) {
    this.beans.navigationService.onTabKeyDown(this.cellCtrl, event);
  }
  onBackspaceOrDeleteKeyDown(key, event) {
    const { cellCtrl, beans, rowNode } = this;
    const { gos, rangeService, eventService } = beans;
    if (cellCtrl.isEditing()) {
      return;
    }
    eventService.dispatchEvent({ type: "keyShortcutChangedCellStart" });
    if (_isDeleteKey(key, gos.get("enableCellEditingOnBackspace"))) {
      if (rangeService && gos.get("enableRangeSelection")) {
        rangeService.clearCellRangeCellValues({ dispatchWrapperEvents: true, wrapperEventSource: "deleteKey" });
      } else if (cellCtrl.isCellEditable()) {
        const column = cellCtrl.getColumn();
        const emptyValue = this.beans.valueService.parseValue(column, rowNode, "", rowNode.getValueFromValueService(column)) ?? null;
        rowNode.setDataValue(column, emptyValue, "cellClear");
      }
    } else {
      cellCtrl.startRowOrCellEdit(key, event);
    }
    eventService.dispatchEvent({ type: "keyShortcutChangedCellEnd" });
  }
  onEnterKeyDown(e) {
    if (this.cellCtrl.isEditing() || this.rowCtrl.isEditing()) {
      this.cellCtrl.stopEditingAndFocus(false, e.shiftKey);
    } else {
      if (this.beans.gos.get("enterNavigatesVertically")) {
        const key = e.shiftKey ? KeyCode.UP : KeyCode.DOWN;
        this.beans.navigationService.navigateToNextCell(null, key, this.cellCtrl.getCellPosition(), false);
      } else {
        this.cellCtrl.startRowOrCellEdit(KeyCode.ENTER, e);
        if (this.cellCtrl.isEditing()) {
          e.preventDefault();
        }
      }
    }
  }
  onF2KeyDown(event) {
    if (!this.cellCtrl.isEditing()) {
      this.cellCtrl.startRowOrCellEdit(KeyCode.F2, event);
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onEscapeKeyDown(event) {
    if (this.cellCtrl.isEditing()) {
      this.cellCtrl.stopRowOrCellEdit(true);
      this.cellCtrl.focusCell(true);
    }
  }
  processCharacter(event) {
    const eventTarget = event.target;
    const eventOnChildComponent = eventTarget !== this.eGui;
    if (eventOnChildComponent || this.cellCtrl.isEditing()) {
      return;
    }
    const key = event.key;
    if (key === " ") {
      this.onSpaceKeyDown(event);
    } else {
      this.cellCtrl.startRowOrCellEdit(key, event);
      event.preventDefault();
    }
  }
  onSpaceKeyDown(event) {
    const { gos } = this.beans;
    if (!this.cellCtrl.isEditing() && gos.isRowSelection()) {
      const currentSelection = this.rowNode.isSelected();
      const newSelection = !currentSelection;
      if (newSelection || !gos.get("suppressRowDeselection")) {
        const groupSelectsFiltered = this.beans.gos.get("groupSelectsFiltered");
        const updatedCount = this.rowNode.setSelectedParams({
          newValue: newSelection,
          rangeSelect: event.shiftKey,
          groupSelectsFiltered,
          event,
          source: "spaceKey"
        });
        if (currentSelection === void 0 && updatedCount === 0) {
          this.rowNode.setSelectedParams({
            newValue: false,
            rangeSelect: event.shiftKey,
            groupSelectsFiltered,
            event,
            source: "spaceKey"
          });
        }
      }
    }
    event.preventDefault();
  }
  destroy() {
    super.destroy();
  }
};

// community-modules/core/src/rendering/cell/cellMouseListenerFeature.ts
var CellMouseListenerFeature = class extends BeanStub {
  constructor(ctrl, beans, column) {
    super();
    this.cellCtrl = ctrl;
    this.beans = beans;
    this.column = column;
  }
  onMouseEvent(eventName, mouseEvent) {
    if (_isStopPropagationForAgGrid(mouseEvent)) {
      return;
    }
    switch (eventName) {
      case "click":
        this.onCellClicked(mouseEvent);
        break;
      case "mousedown":
      case "touchstart":
        this.onMouseDown(mouseEvent);
        break;
      case "dblclick":
        this.onCellDoubleClicked(mouseEvent);
        break;
      case "mouseout":
        this.onMouseOut(mouseEvent);
        break;
      case "mouseover":
        this.onMouseOver(mouseEvent);
        break;
    }
  }
  onCellClicked(mouseEvent) {
    if (this.isDoubleClickOnIPad()) {
      this.onCellDoubleClicked(mouseEvent);
      mouseEvent.preventDefault();
      return;
    }
    const { eventService, rangeService, gos } = this.beans;
    const isMultiKey = mouseEvent.ctrlKey || mouseEvent.metaKey;
    if (rangeService && isMultiKey) {
      if (rangeService.getCellRangeCount(this.cellCtrl.getCellPosition()) > 1) {
        rangeService.intersectLastRange(true);
      }
    }
    const cellClickedEvent = this.cellCtrl.createEvent(mouseEvent, "cellClicked");
    eventService.dispatchEvent(cellClickedEvent);
    const colDef = this.column.getColDef();
    if (colDef.onCellClicked) {
      window.setTimeout(() => {
        this.beans.frameworkOverrides.wrapOutgoing(() => {
          colDef.onCellClicked(cellClickedEvent);
        });
      }, 0);
    }
    const editOnSingleClick = (gos.get("singleClickEdit") || colDef.singleClickEdit) && !gos.get("suppressClickEdit");
    if (editOnSingleClick && !(mouseEvent.shiftKey && rangeService?.getCellRanges().length != 0)) {
      this.cellCtrl.startRowOrCellEdit();
    }
  }
  // returns true if on iPad and this is second 'click' event in 200ms
  isDoubleClickOnIPad() {
    if (!_isIOSUserAgent() || _isEventSupported("dblclick")) {
      return false;
    }
    const nowMillis = (/* @__PURE__ */ new Date()).getTime();
    const res = nowMillis - this.lastIPadMouseClickEvent < 200;
    this.lastIPadMouseClickEvent = nowMillis;
    return res;
  }
  onCellDoubleClicked(mouseEvent) {
    const colDef = this.column.getColDef();
    const cellDoubleClickedEvent = this.cellCtrl.createEvent(
      mouseEvent,
      "cellDoubleClicked"
    );
    this.beans.eventService.dispatchEvent(cellDoubleClickedEvent);
    if (typeof colDef.onCellDoubleClicked === "function") {
      window.setTimeout(() => {
        this.beans.frameworkOverrides.wrapOutgoing(() => {
          colDef.onCellDoubleClicked(cellDoubleClickedEvent);
        });
      }, 0);
    }
    const editOnDoubleClick = !this.beans.gos.get("singleClickEdit") && !this.beans.gos.get("suppressClickEdit");
    if (editOnDoubleClick) {
      this.cellCtrl.startRowOrCellEdit(null, mouseEvent);
    }
  }
  onMouseDown(mouseEvent) {
    const { ctrlKey, metaKey, shiftKey } = mouseEvent;
    const target = mouseEvent.target;
    const { cellCtrl, beans } = this;
    const { eventService, rangeService, focusService } = beans;
    if (this.isRightClickInExistingRange(mouseEvent)) {
      return;
    }
    const ranges = rangeService && rangeService.getCellRanges().length != 0;
    if (!shiftKey || !ranges) {
      const forceBrowserFocus = _isBrowserSafari() && !cellCtrl.isEditing() && !_isFocusableFormField(target);
      cellCtrl.focusCell(forceBrowserFocus);
    }
    if (shiftKey && ranges && !focusService.isCellFocused(cellCtrl.getCellPosition())) {
      mouseEvent.preventDefault();
      const focusedCellPosition = focusService.getFocusedCell();
      if (focusedCellPosition) {
        const { column, rowIndex, rowPinned } = focusedCellPosition;
        const focusedRowCtrl = beans.rowRenderer.getRowByPosition({ rowIndex, rowPinned });
        const focusedCellCtrl = focusedRowCtrl?.getCellCtrl(column);
        if (focusedCellCtrl?.isEditing()) {
          focusedCellCtrl.stopEditing();
        }
        focusService.setFocusedCell({
          column,
          rowIndex,
          rowPinned,
          forceBrowserFocus: true,
          preventScrollOnBrowserFocus: true
        });
      }
    }
    if (this.containsWidget(target)) {
      return;
    }
    if (rangeService) {
      const thisCell = this.cellCtrl.getCellPosition();
      if (shiftKey) {
        rangeService.extendLatestRangeToCell(thisCell);
      } else {
        const isMultiKey = ctrlKey || metaKey;
        rangeService.setRangeToCell(thisCell, isMultiKey);
      }
    }
    eventService.dispatchEvent(this.cellCtrl.createEvent(mouseEvent, "cellMouseDown"));
  }
  isRightClickInExistingRange(mouseEvent) {
    const { rangeService } = this.beans;
    if (rangeService) {
      const cellInRange = rangeService.isCellInAnyRange(this.cellCtrl.getCellPosition());
      const isRightClick = mouseEvent.button === 2 || mouseEvent.ctrlKey && this.beans.gos.get("allowContextMenuWithControlKey");
      if (cellInRange && isRightClick) {
        return true;
      }
    }
    return false;
  }
  containsWidget(target) {
    return _isElementChildOfClass(target, "ag-selection-checkbox", 3);
  }
  onMouseOut(mouseEvent) {
    if (this.mouseStayingInsideCell(mouseEvent)) {
      return;
    }
    const cellMouseOutEvent = this.cellCtrl.createEvent(mouseEvent, "cellMouseOut");
    this.beans.eventService.dispatchEvent(cellMouseOutEvent);
    this.beans.columnHoverService.clearMouseOver();
  }
  onMouseOver(mouseEvent) {
    if (this.mouseStayingInsideCell(mouseEvent)) {
      return;
    }
    const cellMouseOverEvent = this.cellCtrl.createEvent(mouseEvent, "cellMouseOver");
    this.beans.eventService.dispatchEvent(cellMouseOverEvent);
    this.beans.columnHoverService.setMouseOver([this.column]);
  }
  mouseStayingInsideCell(e) {
    if (!e.target || !e.relatedTarget) {
      return false;
    }
    const eGui = this.cellCtrl.getGui();
    const cellContainsTarget = eGui.contains(e.target);
    const cellContainsRelatedTarget = eGui.contains(e.relatedTarget);
    return cellContainsTarget && cellContainsRelatedTarget;
  }
  destroy() {
    super.destroy();
  }
};

// community-modules/core/src/rendering/cell/cellPositionFeature.ts
var CellPositionFeature = class extends BeanStub {
  constructor(ctrl, beans) {
    super();
    this.cellCtrl = ctrl;
    this.beans = beans;
    this.column = ctrl.getColumn();
    this.rowNode = ctrl.getRowNode();
  }
  setupRowSpan() {
    this.rowSpan = this.column.getRowSpan(this.rowNode);
    this.addManagedListeners(this.beans.eventService, { newColumnsLoaded: () => this.onNewColumnsLoaded() });
  }
  setComp(eGui) {
    this.eGui = eGui;
    this.setupColSpan();
    this.setupRowSpan();
    this.onLeftChanged();
    this.onWidthChanged();
    this.applyRowSpan();
  }
  onNewColumnsLoaded() {
    const rowSpan = this.column.getRowSpan(this.rowNode);
    if (this.rowSpan === rowSpan) {
      return;
    }
    this.rowSpan = rowSpan;
    this.applyRowSpan(true);
  }
  onDisplayColumnsChanged() {
    const colsSpanning = this.getColSpanningList();
    if (!_areEqual(this.colsSpanning, colsSpanning)) {
      this.colsSpanning = colsSpanning;
      this.onWidthChanged();
      this.onLeftChanged();
    }
  }
  setupColSpan() {
    if (this.column.getColDef().colSpan == null) {
      return;
    }
    this.colsSpanning = this.getColSpanningList();
    this.addManagedListeners(this.beans.eventService, {
      // because we are col spanning, a reorder of the cols can change what cols we are spanning over
      displayedColumnsChanged: this.onDisplayColumnsChanged.bind(this),
      // because we are spanning over multiple cols, we check for width any time any cols width changes.
      // this is expensive - really we should be explicitly checking only the cols we are spanning over
      // instead of every col, however it would be tricky code to track the cols we are spanning over, so
      // because hardly anyone will be using colSpan, am favouring this easier way for more maintainable code.
      displayedColumnsWidthChanged: this.onWidthChanged.bind(this)
    });
  }
  onWidthChanged() {
    if (!this.eGui) {
      return;
    }
    const width = this.getCellWidth();
    this.eGui.style.width = `${width}px`;
  }
  getCellWidth() {
    if (!this.colsSpanning) {
      return this.column.getActualWidth();
    }
    return this.colsSpanning.reduce((width, col) => width + col.getActualWidth(), 0);
  }
  getColSpanningList() {
    const colSpan = this.column.getColSpan(this.rowNode);
    const colsSpanning = [];
    if (colSpan === 1) {
      colsSpanning.push(this.column);
    } else {
      let pointer = this.column;
      const pinned = this.column.getPinned();
      for (let i = 0; pointer && i < colSpan; i++) {
        colsSpanning.push(pointer);
        pointer = this.beans.visibleColsService.getColAfter(pointer);
        if (!pointer || _missing(pointer)) {
          break;
        }
        if (pinned !== pointer.getPinned()) {
          break;
        }
      }
    }
    return colsSpanning;
  }
  onLeftChanged() {
    if (!this.eGui) {
      return;
    }
    const left = this.modifyLeftForPrintLayout(this.getCellLeft());
    this.eGui.style.left = left + "px";
  }
  getCellLeft() {
    let mostLeftCol;
    if (this.beans.gos.get("enableRtl") && this.colsSpanning) {
      mostLeftCol = _last(this.colsSpanning);
    } else {
      mostLeftCol = this.column;
    }
    return mostLeftCol.getLeft();
  }
  modifyLeftForPrintLayout(leftPosition) {
    if (!this.cellCtrl.isPrintLayout() || this.column.getPinned() === "left") {
      return leftPosition;
    }
    const leftWidth = this.beans.visibleColsService.getColsLeftWidth();
    if (this.column.getPinned() === "right") {
      const bodyWidth = this.beans.visibleColsService.getBodyContainerWidth();
      return leftWidth + bodyWidth + (leftPosition || 0);
    }
    return leftWidth + (leftPosition || 0);
  }
  applyRowSpan(force) {
    if (this.rowSpan === 1 && !force) {
      return;
    }
    const singleRowHeight = this.beans.gos.getRowHeightAsNumber();
    const totalRowHeight = singleRowHeight * this.rowSpan;
    this.eGui.style.height = `${totalRowHeight}px`;
    this.eGui.style.zIndex = "1";
  }
  // overriding to make public, as we don't dispose this bean via context
  destroy() {
    super.destroy();
  }
};

// community-modules/core/src/interfaces/IRangeService.ts
var SelectionHandleType = /* @__PURE__ */ ((SelectionHandleType2) => {
  SelectionHandleType2[SelectionHandleType2["FILL"] = 0] = "FILL";
  SelectionHandleType2[SelectionHandleType2["RANGE"] = 1] = "RANGE";
  return SelectionHandleType2;
})(SelectionHandleType || {});
var CellRangeType = /* @__PURE__ */ ((CellRangeType2) => {
  CellRangeType2[CellRangeType2["VALUE"] = 0] = "VALUE";
  CellRangeType2[CellRangeType2["DIMENSION"] = 1] = "DIMENSION";
  return CellRangeType2;
})(CellRangeType || {});

// community-modules/core/src/rendering/cell/cellRangeFeature.ts
var CSS_CELL_RANGE_SELECTED = "ag-cell-range-selected";
var CSS_CELL_RANGE_CHART = "ag-cell-range-chart";
var CSS_CELL_RANGE_SINGLE_CELL = "ag-cell-range-single-cell";
var CSS_CELL_RANGE_CHART_CATEGORY = "ag-cell-range-chart-category";
var CSS_CELL_RANGE_HANDLE = "ag-cell-range-handle";
var CSS_CELL_RANGE_TOP = "ag-cell-range-top";
var CSS_CELL_RANGE_RIGHT = "ag-cell-range-right";
var CSS_CELL_RANGE_BOTTOM = "ag-cell-range-bottom";
var CSS_CELL_RANGE_LEFT = "ag-cell-range-left";
var CellRangeFeature = class {
  constructor(beans, ctrl) {
    this.beans = beans;
    this.rangeService = beans.rangeService;
    this.selectionHandleFactory = beans.selectionHandleFactory;
    this.cellCtrl = ctrl;
  }
  setComp(cellComp, eGui) {
    this.cellComp = cellComp;
    this.eGui = eGui;
    this.onRangeSelectionChanged();
  }
  onRangeSelectionChanged() {
    if (!this.cellComp) {
      return;
    }
    this.rangeCount = this.rangeService.getCellRangeCount(this.cellCtrl.getCellPosition());
    this.hasChartRange = this.getHasChartRange();
    this.cellComp.addOrRemoveCssClass(CSS_CELL_RANGE_SELECTED, this.rangeCount !== 0);
    this.cellComp.addOrRemoveCssClass(`${CSS_CELL_RANGE_SELECTED}-1`, this.rangeCount === 1);
    this.cellComp.addOrRemoveCssClass(`${CSS_CELL_RANGE_SELECTED}-2`, this.rangeCount === 2);
    this.cellComp.addOrRemoveCssClass(`${CSS_CELL_RANGE_SELECTED}-3`, this.rangeCount === 3);
    this.cellComp.addOrRemoveCssClass(`${CSS_CELL_RANGE_SELECTED}-4`, this.rangeCount >= 4);
    this.cellComp.addOrRemoveCssClass(CSS_CELL_RANGE_CHART, this.hasChartRange);
    _setAriaSelected(this.eGui, this.rangeCount > 0 ? true : void 0);
    this.cellComp.addOrRemoveCssClass(CSS_CELL_RANGE_SINGLE_CELL, this.isSingleCell());
    this.updateRangeBorders();
    this.refreshHandle();
  }
  updateRangeBorders() {
    const rangeBorders = this.getRangeBorders();
    const isSingleCell = this.isSingleCell();
    const isTop = !isSingleCell && rangeBorders.top;
    const isRight = !isSingleCell && rangeBorders.right;
    const isBottom = !isSingleCell && rangeBorders.bottom;
    const isLeft = !isSingleCell && rangeBorders.left;
    this.cellComp.addOrRemoveCssClass(CSS_CELL_RANGE_TOP, isTop);
    this.cellComp.addOrRemoveCssClass(CSS_CELL_RANGE_RIGHT, isRight);
    this.cellComp.addOrRemoveCssClass(CSS_CELL_RANGE_BOTTOM, isBottom);
    this.cellComp.addOrRemoveCssClass(CSS_CELL_RANGE_LEFT, isLeft);
  }
  isSingleCell() {
    const { rangeService } = this.beans;
    return this.rangeCount === 1 && !!rangeService && !rangeService.isMoreThanOneCell();
  }
  getHasChartRange() {
    const { rangeService } = this.beans;
    if (!this.rangeCount || !rangeService) {
      return false;
    }
    const cellRanges = rangeService.getCellRanges();
    return cellRanges.length > 0 && cellRanges.every((range) => _includes([1 /* DIMENSION */, 0 /* VALUE */], range.type));
  }
  updateRangeBordersIfRangeCount() {
    if (this.rangeCount > 0) {
      this.updateRangeBorders();
      this.refreshHandle();
    }
  }
  getRangeBorders() {
    const isRtl = this.beans.gos.get("enableRtl");
    let top = false;
    let right = false;
    let bottom = false;
    let left = false;
    const thisCol = this.cellCtrl.getCellPosition().column;
    const presentedColsService = this.beans.visibleColsService;
    let leftCol;
    let rightCol;
    if (isRtl) {
      leftCol = presentedColsService.getColAfter(thisCol);
      rightCol = presentedColsService.getColBefore(thisCol);
    } else {
      leftCol = presentedColsService.getColBefore(thisCol);
      rightCol = presentedColsService.getColAfter(thisCol);
    }
    const ranges = this.rangeService.getCellRanges().filter((range) => this.rangeService.isCellInSpecificRange(this.cellCtrl.getCellPosition(), range));
    if (!leftCol) {
      left = true;
    }
    if (!rightCol) {
      right = true;
    }
    for (let i = 0; i < ranges.length; i++) {
      if (top && right && bottom && left) {
        break;
      }
      const range = ranges[i];
      const startRow = this.rangeService.getRangeStartRow(range);
      const endRow = this.rangeService.getRangeEndRow(range);
      if (!top && this.beans.rowPositionUtils.sameRow(startRow, this.cellCtrl.getCellPosition())) {
        top = true;
      }
      if (!bottom && this.beans.rowPositionUtils.sameRow(endRow, this.cellCtrl.getCellPosition())) {
        bottom = true;
      }
      if (!left && leftCol && range.columns.indexOf(leftCol) < 0) {
        left = true;
      }
      if (!right && rightCol && range.columns.indexOf(rightCol) < 0) {
        right = true;
      }
    }
    return { top, right, bottom, left };
  }
  refreshHandle() {
    if (this.beans.context.isDestroyed()) {
      return;
    }
    const shouldHaveSelectionHandle = this.shouldHaveSelectionHandle();
    if (this.selectionHandle && !shouldHaveSelectionHandle) {
      this.selectionHandle = this.beans.context.destroyBean(this.selectionHandle);
    }
    if (shouldHaveSelectionHandle) {
      this.addSelectionHandle();
    }
    this.cellComp.addOrRemoveCssClass(CSS_CELL_RANGE_HANDLE, !!this.selectionHandle);
  }
  shouldHaveSelectionHandle() {
    const gos = this.beans.gos;
    const cellRanges = this.rangeService.getCellRanges();
    const rangesLen = cellRanges.length;
    if (this.rangeCount < 1 || rangesLen < 1) {
      return false;
    }
    const cellRange = _last(cellRanges);
    const cellPosition = this.cellCtrl.getCellPosition();
    const isFillHandleAvailable = gos.get("enableFillHandle") && !this.cellCtrl.isSuppressFillHandle();
    const isRangeHandleAvailable = gos.get("enableRangeHandle");
    let handleIsAvailable = rangesLen === 1 && !this.cellCtrl.isEditing() && (isFillHandleAvailable || isRangeHandleAvailable);
    if (this.hasChartRange) {
      const hasCategoryRange = cellRanges[0].type === 1 /* DIMENSION */;
      const isCategoryCell = hasCategoryRange && this.rangeService.isCellInSpecificRange(cellPosition, cellRanges[0]);
      this.cellComp.addOrRemoveCssClass(CSS_CELL_RANGE_CHART_CATEGORY, isCategoryCell);
      handleIsAvailable = cellRange.type === 0 /* VALUE */;
    }
    return handleIsAvailable && cellRange.endRow != null && this.rangeService.isContiguousRange(cellRange) && this.rangeService.isBottomRightCell(cellRange, cellPosition);
  }
  addSelectionHandle() {
    const gos = this.beans.gos;
    const cellRangeType = _last(this.rangeService.getCellRanges()).type;
    const selectionHandleFill = gos.get("enableFillHandle") && _missing(cellRangeType);
    const type = selectionHandleFill ? 0 /* FILL */ : 1 /* RANGE */;
    if (this.selectionHandle && this.selectionHandle.getType() !== type) {
      this.selectionHandle = this.beans.context.destroyBean(this.selectionHandle);
    }
    if (!this.selectionHandle) {
      this.selectionHandle = this.selectionHandleFactory.createSelectionHandle(type);
    }
    this.selectionHandle.refresh(this.cellCtrl);
  }
  destroy() {
    this.beans.context.destroyBean(this.selectionHandle);
  }
};

// community-modules/core/src/rendering/cell/cellCtrl.ts
var CSS_CELL = "ag-cell";
var CSS_AUTO_HEIGHT = "ag-cell-auto-height";
var CSS_NORMAL_HEIGHT = "ag-cell-normal-height";
var CSS_CELL_FOCUS = "ag-cell-focus";
var CSS_CELL_FIRST_RIGHT_PINNED = "ag-cell-first-right-pinned";
var CSS_CELL_LAST_LEFT_PINNED = "ag-cell-last-left-pinned";
var CSS_CELL_NOT_INLINE_EDITING = "ag-cell-not-inline-editing";
var CSS_COLUMN_HOVER = "ag-column-hover";
var CSS_CELL_WRAP_TEXT = "ag-cell-wrap-text";
var instanceIdSequence4 = 0;
var _CellCtrl = class _CellCtrl extends BeanStub {
  constructor(column, rowNode, beans, rowCtrl) {
    super();
    this.column = column;
    this.rowNode = rowNode;
    this.beans = beans;
    this.rowCtrl = rowCtrl;
    this.cellRangeFeature = null;
    this.cellPositionFeature = null;
    this.cellCustomStyleFeature = null;
    this.tooltipFeature = null;
    this.cellMouseListenerFeature = null;
    this.cellKeyboardListenerFeature = null;
    this.suppressRefreshCell = false;
    this.onCellCompAttachedFuncs = [];
    this.instanceId = column.getId() + "-" + instanceIdSequence4++;
    this.colIdSanitised = _escapeString(this.column.getId());
    if (!beans.gos.get("suppressCellFocus")) {
      this.tabIndex = -1;
    }
    this.createCellPosition();
    this.addFeatures();
    this.updateAndFormatValue(false);
  }
  shouldRestoreFocus() {
    return this.beans.focusService.shouldRestoreFocus(this.cellPosition);
  }
  addFeatures() {
    this.cellPositionFeature = new CellPositionFeature(this, this.beans);
    this.addDestroyFunc(() => {
      this.cellPositionFeature?.destroy();
      this.cellPositionFeature = null;
    });
    this.cellCustomStyleFeature = new CellCustomStyleFeature(this, this.beans);
    this.addDestroyFunc(() => {
      this.cellCustomStyleFeature?.destroy();
      this.cellCustomStyleFeature = null;
    });
    this.cellMouseListenerFeature = new CellMouseListenerFeature(this, this.beans, this.column);
    this.addDestroyFunc(() => {
      this.cellMouseListenerFeature?.destroy();
      this.cellMouseListenerFeature = null;
    });
    this.cellKeyboardListenerFeature = new CellKeyboardListenerFeature(
      this,
      this.beans,
      this.column,
      this.rowNode,
      this.rowCtrl
    );
    this.addDestroyFunc(() => {
      this.cellKeyboardListenerFeature?.destroy();
      this.cellKeyboardListenerFeature = null;
    });
    if (this.column.isTooltipEnabled()) {
      this.enableTooltipFeature();
      this.addDestroyFunc(() => {
        this.disableTooltipFeature();
      });
    }
    const rangeSelectionEnabled = this.beans.rangeService && this.beans.gos.get("enableRangeSelection");
    if (rangeSelectionEnabled) {
      this.cellRangeFeature = new CellRangeFeature(this.beans, this);
      this.addDestroyFunc(() => {
        this.cellRangeFeature?.destroy();
        this.cellRangeFeature = null;
      });
    }
  }
  enableTooltipFeature(value, shouldDisplayTooltip) {
    const getTooltipValue = () => {
      const colDef = this.column.getColDef();
      const data = this.rowNode.data;
      if (colDef.tooltipField && _exists(data)) {
        return _getValueUsingField(data, colDef.tooltipField, this.column.isTooltipFieldContainsDots());
      }
      const valueGetter = colDef.tooltipValueGetter;
      if (valueGetter) {
        return valueGetter(
          this.beans.gos.addGridCommonParams({
            location: "cell",
            colDef: this.column.getColDef(),
            column: this.column,
            rowIndex: this.cellPosition.rowIndex,
            node: this.rowNode,
            data: this.rowNode.data,
            value: this.value,
            valueFormatted: this.valueFormatted
          })
        );
      }
      return null;
    };
    const isTooltipWhenTruncated = this.beans.gos.get("tooltipShowMode") === "whenTruncated";
    if (!shouldDisplayTooltip && isTooltipWhenTruncated && !this.isCellRenderer()) {
      shouldDisplayTooltip = () => {
        const eGui = this.getGui();
        const textEl = eGui.children.length === 0 ? eGui : eGui.querySelector(".ag-cell-value");
        if (!textEl) {
          return true;
        }
        return textEl.scrollWidth > textEl.clientWidth;
      };
    }
    const tooltipCtrl = {
      getColumn: () => this.column,
      getColDef: () => this.column.getColDef(),
      getRowIndex: () => this.cellPosition.rowIndex,
      getRowNode: () => this.rowNode,
      getGui: () => this.getGui(),
      getLocation: () => "cell",
      getTooltipValue: value != null ? () => value : getTooltipValue,
      // this makes no sense, why is the cell formatted value passed to the tooltip???
      getValueFormatted: () => this.valueFormatted,
      shouldDisplayTooltip
    };
    this.tooltipFeature = new TooltipFeature(tooltipCtrl, this.beans);
  }
  disableTooltipFeature() {
    if (!this.tooltipFeature) {
      return;
    }
    this.tooltipFeature.destroy();
    this.tooltipFeature = null;
  }
  setComp(comp, eGui, eCellWrapper, printLayout, startEditing) {
    this.cellComp = comp;
    this.eGui = eGui;
    this.printLayout = printLayout;
    this.addDomData();
    this.onCellFocused(this.focusEventToRestore);
    this.applyStaticCssClasses();
    this.setWrapText();
    this.onFirstRightPinnedChanged();
    this.onLastLeftPinnedChanged();
    this.onColumnHover();
    this.setupControlComps();
    this.setupAutoHeight(eCellWrapper);
    this.refreshFirstAndLastStyles();
    this.refreshAriaColIndex();
    this.cellPositionFeature?.setComp(eGui);
    this.cellCustomStyleFeature?.setComp(comp);
    this.tooltipFeature?.refreshToolTip();
    this.cellKeyboardListenerFeature?.setComp(this.eGui);
    if (this.cellRangeFeature) {
      this.cellRangeFeature.setComp(comp, eGui);
    }
    if (startEditing && this.isCellEditable()) {
      this.startEditing();
    } else {
      this.showValue();
    }
    if (this.onCellCompAttachedFuncs.length) {
      this.onCellCompAttachedFuncs.forEach((func) => func());
      this.onCellCompAttachedFuncs = [];
    }
  }
  setupAutoHeight(eCellWrapper) {
    this.isAutoHeight = this.column.isAutoHeight();
    if (!this.isAutoHeight || !eCellWrapper) {
      return;
    }
    const eParentCell = eCellWrapper.parentElement;
    const minRowHeight = this.beans.gos.getRowHeightForNode(this.rowNode).height;
    const measureHeight = (timesCalled) => {
      if (this.editing) {
        return;
      }
      if (!this.isAlive()) {
        return;
      }
      const { paddingTop, paddingBottom, borderBottomWidth, borderTopWidth } = _getElementSize(eParentCell);
      const extraHeight = paddingTop + paddingBottom + borderBottomWidth + borderTopWidth;
      const wrapperHeight = eCellWrapper.offsetHeight;
      const autoHeight = wrapperHeight + extraHeight;
      if (timesCalled < 5) {
        const doc = this.beans.gos.getDocument();
        const notYetInDom = !doc || !doc.contains(eCellWrapper);
        const possiblyNoContentYet = autoHeight == 0;
        if (notYetInDom || possiblyNoContentYet) {
          window.setTimeout(() => measureHeight(timesCalled + 1), 0);
          return;
        }
      }
      const newHeight = Math.max(autoHeight, minRowHeight);
      this.rowNode.setRowAutoHeight(newHeight, this.column);
    };
    const listener = () => measureHeight(0);
    listener();
    const destroyResizeObserver = this.beans.resizeObserverService.observeResize(eCellWrapper, listener);
    this.addDestroyFunc(() => {
      destroyResizeObserver();
      this.rowNode.setRowAutoHeight(void 0, this.column);
    });
  }
  getCellAriaRole() {
    return this.column.getColDef().cellAriaRole ?? "gridcell";
  }
  getInstanceId() {
    return this.instanceId;
  }
  getColumnIdSanitised() {
    return this.colIdSanitised;
  }
  getTabIndex() {
    return this.tabIndex;
  }
  isCellRenderer() {
    const colDef = this.column.getColDef();
    return colDef.cellRenderer != null || colDef.cellRendererSelector != null;
  }
  getValueToDisplay() {
    return this.valueFormatted ?? this.value;
  }
  showValue(forceNewCellRendererInstance = false) {
    const valueToDisplay = this.getValueToDisplay();
    let compDetails;
    if (this.rowNode.stub) {
      const params = this.createCellRendererParams();
      compDetails = this.beans.userComponentFactory.getLoadingCellRendererDetails(
        this.column.getColDef(),
        params
      );
    } else if (this.isCellRenderer()) {
      const params = this.createCellRendererParams();
      compDetails = this.beans.userComponentFactory.getCellRendererDetails(this.column.getColDef(), params);
    }
    this.cellComp.setRenderDetails(compDetails, valueToDisplay, forceNewCellRendererInstance);
    this.cellRangeFeature?.refreshHandle();
  }
  setupControlComps() {
    const colDef = this.column.getColDef();
    this.includeSelection = this.isIncludeControl(colDef.checkboxSelection);
    this.includeRowDrag = this.isIncludeControl(colDef.rowDrag);
    this.includeDndSource = this.isIncludeControl(colDef.dndSource);
    this.cellComp.setIncludeSelection(this.includeSelection);
    this.cellComp.setIncludeDndSource(this.includeDndSource);
    this.cellComp.setIncludeRowDrag(this.includeRowDrag);
  }
  isForceWrapper() {
    const forceWrapper = this.beans.gos.get("enableCellTextSelection") || this.column.isAutoHeight();
    return forceWrapper;
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  isIncludeControl(value) {
    const rowNodePinned = this.rowNode.rowPinned != null;
    const isFunc = typeof value === "function";
    const res = rowNodePinned ? false : isFunc || value === true;
    return res;
  }
  refreshShouldDestroy() {
    const colDef = this.column.getColDef();
    const selectionChanged = this.includeSelection != this.isIncludeControl(colDef.checkboxSelection);
    const rowDragChanged = this.includeRowDrag != this.isIncludeControl(colDef.rowDrag);
    const dndSourceChanged = this.includeDndSource != this.isIncludeControl(colDef.dndSource);
    const autoHeightChanged = this.isAutoHeight != this.column.isAutoHeight();
    return selectionChanged || rowDragChanged || dndSourceChanged || autoHeightChanged;
  }
  // either called internally if single cell editing, or called by rowRenderer if row editing
  startEditing(key = null, cellStartedEdit = false, event = null) {
    const { editService } = this.beans;
    if (!this.isCellEditable() || this.editing || !editService) {
      return;
    }
    if (!this.cellComp) {
      this.onCellCompAttachedFuncs.push(() => {
        this.startEditing(key, cellStartedEdit, event);
      });
      return;
    }
    editService.startEditing(this, key, cellStartedEdit, event);
  }
  setEditing(editing, compDetails) {
    this.editCompDetails = compDetails;
    if (this.editing === editing) {
      return;
    }
    this.editing = editing;
    this.cellRangeFeature?.refreshHandle();
  }
  // pass in 'true' to cancel the editing.
  stopRowOrCellEdit(cancel = false) {
    if (this.beans.gos.get("editType") === "fullRow") {
      this.rowCtrl.stopEditing(cancel);
    } else {
      this.stopEditing(cancel);
    }
  }
  onPopupEditorClosed() {
    if (!this.editing) {
      return;
    }
    this.stopEditingAndFocus();
  }
  /**
   * Ends the Cell Editing
   * @param cancel `True` if the edit process is being canceled.
   * @returns `True` if the value of the `GridCell` has been updated, otherwise `False`.
   */
  stopEditing(cancel = false) {
    const { editService } = this.beans;
    if (!this.editing || !editService) {
      return false;
    }
    return editService.stopEditing(this, cancel);
  }
  createCellRendererParams() {
    const res = this.beans.gos.addGridCommonParams({
      value: this.value,
      valueFormatted: this.valueFormatted,
      getValue: () => this.rowNode.getValueFromValueService(this.column),
      setValue: (value) => this.beans.valueService.setValue(this.rowNode, this.column, value),
      formatValue: this.formatValue.bind(this),
      data: this.rowNode.data,
      node: this.rowNode,
      pinned: this.column.getPinned(),
      colDef: this.column.getColDef(),
      column: this.column,
      refreshCell: this.refreshCell.bind(this),
      eGridCell: this.getGui(),
      eParentOfValue: this.cellComp.getParentOfValue(),
      registerRowDragger: (rowDraggerElement, dragStartPixels, value, suppressVisibilityChange) => this.registerRowDragger(rowDraggerElement, dragStartPixels, suppressVisibilityChange),
      setTooltip: (value, shouldDisplayTooltip) => {
        if (this.tooltipFeature) {
          this.disableTooltipFeature();
        }
        this.enableTooltipFeature(value, shouldDisplayTooltip);
        this.tooltipFeature?.refreshToolTip();
      }
    });
    return res;
  }
  setFocusOutOnEditor() {
    if (!this.editing) {
      return;
    }
    this.beans.editService?.setFocusOutOnEditor(this);
  }
  setFocusInOnEditor() {
    if (!this.editing) {
      return;
    }
    this.beans.editService?.setFocusInOnEditor(this);
  }
  onCellChanged(event) {
    const eventImpactsThisCell = event.column === this.column;
    if (eventImpactsThisCell) {
      this.refreshCell({});
    }
  }
  refreshOrDestroyCell(params) {
    if (this.refreshShouldDestroy()) {
      this.rowCtrl?.recreateCell(this);
    } else {
      this.refreshCell(params);
    }
  }
  // + stop editing {forceRefresh: true, suppressFlash: true}
  // + event cellChanged {}
  // + cellRenderer.params.refresh() {} -> method passes 'as is' to the cellRenderer, so params could be anything
  // + rowCtrl: event dataChanged {suppressFlash: !update, newData: !update}
  // + rowCtrl: api refreshCells() {animate: true/false}
  // + rowRenderer: api softRefreshView() {}
  refreshCell(params) {
    if (this.suppressRefreshCell || this.editing) {
      return;
    }
    const colDef = this.column.getColDef();
    const newData = params != null && !!params.newData;
    const suppressFlash = params != null && !!params.suppressFlash || !!colDef.suppressCellFlash;
    const noValueProvided = colDef.field == null && colDef.valueGetter == null && colDef.showRowGroup == null;
    const forceRefresh = params && params.forceRefresh || noValueProvided || newData;
    const isCellCompReady = !!this.cellComp;
    const valuesDifferent = this.updateAndFormatValue(isCellCompReady);
    const dataNeedsUpdating = forceRefresh || valuesDifferent;
    if (!isCellCompReady) {
      return;
    }
    if (dataNeedsUpdating) {
      this.showValue(newData);
      const processingFilterChange = this.beans.filterManager?.isSuppressFlashingCellsBecauseFiltering();
      const flashCell = !suppressFlash && !processingFilterChange && (this.beans.gos.get("enableCellChangeFlash") || colDef.enableCellChangeFlash);
      if (flashCell) {
        this.flashCell();
      }
      this.cellCustomStyleFeature?.applyUserStyles();
      this.cellCustomStyleFeature?.applyClassesFromColDef();
    }
    this.tooltipFeature?.refreshToolTip();
    this.cellCustomStyleFeature?.applyCellClassRules();
  }
  // cell editors call this, when they want to stop for reasons other
  // than what we pick up on. eg selecting from a dropdown ends editing.
  stopEditingAndFocus(suppressNavigateAfterEdit = false, shiftKey = false) {
    this.beans.editService?.stopEditingAndFocus(this, suppressNavigateAfterEdit, shiftKey);
  }
  // user can also call this via API
  flashCell(delays) {
    const flashDuration = delays?.flashDuration ?? delays?.flashDelay;
    const fadeDuration = delays?.fadeDuration ?? delays?.fadeDelay;
    this.animateCell("data-changed", flashDuration, fadeDuration);
  }
  animateCell(cssName, flashDuration, fadeDuration) {
    if (!this.cellComp) {
      return;
    }
    const fullName = `ag-cell-${cssName}`;
    const animationFullName = `ag-cell-${cssName}-animation`;
    const { gos } = this.beans;
    if (!flashDuration) {
      flashDuration = gos.get("cellFlashDuration");
    }
    if (!_exists(fadeDuration)) {
      fadeDuration = gos.get("cellFadeDuration");
    }
    this.cellComp.addOrRemoveCssClass(fullName, true);
    this.cellComp.addOrRemoveCssClass(animationFullName, false);
    this.beans.frameworkOverrides.wrapIncoming(() => {
      window.setTimeout(() => {
        if (!this.isAlive()) {
          return;
        }
        this.cellComp.addOrRemoveCssClass(fullName, false);
        this.cellComp.addOrRemoveCssClass(animationFullName, true);
        this.eGui.style.transition = `background-color ${fadeDuration}ms`;
        window.setTimeout(() => {
          if (!this.isAlive()) {
            return;
          }
          this.cellComp.addOrRemoveCssClass(animationFullName, false);
          this.eGui.style.transition = "";
        }, fadeDuration);
      }, flashDuration);
    });
  }
  onFlashCells(event) {
    if (!this.cellComp) {
      return;
    }
    const cellId = this.beans.cellPositionUtils.createId(this.getCellPosition());
    const shouldFlash = event.cells[cellId];
    if (shouldFlash) {
      this.animateCell("highlight");
    }
  }
  isCellEditable() {
    return this.column.isCellEditable(this.rowNode);
  }
  isSuppressFillHandle() {
    return this.column.isSuppressFillHandle();
  }
  formatValue(value) {
    return this.callValueFormatter(value) ?? value;
  }
  callValueFormatter(value) {
    return this.beans.valueService.formatValue(this.column, this.rowNode, value);
  }
  updateAndFormatValue(compareValues) {
    const oldValue = this.value;
    const oldValueFormatted = this.valueFormatted;
    this.value = this.rowNode.getValueFromValueService(this.column);
    this.valueFormatted = this.callValueFormatter(this.value);
    if (compareValues) {
      return !this.valuesAreEqual(oldValue, this.value) || this.valueFormatted != oldValueFormatted;
    }
    return true;
  }
  valuesAreEqual(val1, val2) {
    const colDef = this.column.getColDef();
    return colDef.equals ? colDef.equals(val1, val2) : val1 === val2;
  }
  getComp() {
    return this.cellComp;
  }
  getValue() {
    return this.value;
  }
  addDomData() {
    const element = this.getGui();
    this.beans.gos.setDomData(element, _CellCtrl.DOM_DATA_KEY_CELL_CTRL, this);
    this.addDestroyFunc(() => this.beans.gos.setDomData(element, _CellCtrl.DOM_DATA_KEY_CELL_CTRL, null));
  }
  createEvent(domEvent, eventType) {
    const event = this.beans.gos.addGridCommonParams({
      type: eventType,
      node: this.rowNode,
      data: this.rowNode.data,
      value: this.value,
      column: this.column,
      colDef: this.column.getColDef(),
      rowPinned: this.rowNode.rowPinned,
      event: domEvent,
      rowIndex: this.rowNode.rowIndex
    });
    return event;
  }
  processCharacter(event) {
    this.cellKeyboardListenerFeature?.processCharacter(event);
  }
  onKeyDown(event) {
    this.cellKeyboardListenerFeature?.onKeyDown(event);
  }
  onMouseEvent(eventName, mouseEvent) {
    this.cellMouseListenerFeature?.onMouseEvent(eventName, mouseEvent);
  }
  getGui() {
    return this.eGui;
  }
  getColSpanningList() {
    return this.cellPositionFeature.getColSpanningList();
  }
  onLeftChanged() {
    if (!this.cellComp) {
      return;
    }
    this.cellPositionFeature?.onLeftChanged();
  }
  onDisplayedColumnsChanged() {
    if (!this.eGui) {
      return;
    }
    this.refreshAriaColIndex();
    this.refreshFirstAndLastStyles();
  }
  refreshFirstAndLastStyles() {
    const { cellComp, column, beans } = this;
    refreshFirstAndLastStyles(cellComp, column, beans.visibleColsService);
  }
  refreshAriaColIndex() {
    const colIdx = this.beans.visibleColsService.getAriaColIndex(this.column);
    _setAriaColIndex(this.getGui(), colIdx);
  }
  isSuppressNavigable() {
    return this.column.isSuppressNavigable(this.rowNode);
  }
  onWidthChanged() {
    return this.cellPositionFeature?.onWidthChanged();
  }
  getColumn() {
    return this.column;
  }
  getRowNode() {
    return this.rowNode;
  }
  isPrintLayout() {
    return this.printLayout;
  }
  getCellPosition() {
    return this.cellPosition;
  }
  isEditing() {
    return this.editing;
  }
  // called by rowRenderer when user navigates via tab key
  startRowOrCellEdit(key, event = null) {
    if (!this.cellComp) {
      this.onCellCompAttachedFuncs.push(() => {
        this.startRowOrCellEdit(key, event);
      });
      return;
    }
    if (this.beans.gos.get("editType") === "fullRow") {
      this.rowCtrl.startRowEditing(key, this);
    } else {
      this.startEditing(key, true, event);
    }
  }
  getRowCtrl() {
    return this.rowCtrl;
  }
  getRowPosition() {
    return {
      rowIndex: this.cellPosition.rowIndex,
      rowPinned: this.cellPosition.rowPinned
    };
  }
  updateRangeBordersIfRangeCount() {
    if (!this.cellComp) {
      return;
    }
    if (this.cellRangeFeature) {
      this.cellRangeFeature.updateRangeBordersIfRangeCount();
    }
  }
  onRangeSelectionChanged() {
    if (!this.cellComp) {
      return;
    }
    if (this.cellRangeFeature) {
      this.cellRangeFeature.onRangeSelectionChanged();
    }
  }
  isRangeSelectionEnabled() {
    return this.cellRangeFeature != null;
  }
  focusCell(forceBrowserFocus = false) {
    this.beans.focusService.setFocusedCell({
      rowIndex: this.getCellPosition().rowIndex,
      column: this.column,
      rowPinned: this.rowNode.rowPinned,
      forceBrowserFocus
    });
  }
  onRowIndexChanged() {
    this.createCellPosition();
    this.onCellFocused();
    if (this.cellRangeFeature) {
      this.cellRangeFeature.onRangeSelectionChanged();
    }
  }
  onFirstRightPinnedChanged() {
    if (!this.cellComp) {
      return;
    }
    const firstRightPinned = this.column.isFirstRightPinned();
    this.cellComp.addOrRemoveCssClass(CSS_CELL_FIRST_RIGHT_PINNED, firstRightPinned);
  }
  onLastLeftPinnedChanged() {
    if (!this.cellComp) {
      return;
    }
    const lastLeftPinned = this.column.isLastLeftPinned();
    this.cellComp.addOrRemoveCssClass(CSS_CELL_LAST_LEFT_PINNED, lastLeftPinned);
  }
  onCellFocused(event) {
    if (this.beans.gos.get("suppressCellFocus")) {
      return;
    }
    const cellFocused = this.beans.focusService.isCellFocused(this.cellPosition);
    if (!this.cellComp) {
      if (cellFocused && event?.forceBrowserFocus) {
        this.focusEventToRestore = event;
      }
      return;
    }
    this.focusEventToRestore = void 0;
    this.cellComp.addOrRemoveCssClass(CSS_CELL_FOCUS, cellFocused);
    if (cellFocused && event && event.forceBrowserFocus) {
      let focusEl = this.cellComp.getFocusableElement();
      if (this.editing) {
        const focusableEls = this.beans.focusService.findFocusableElements(focusEl, null, true);
        if (focusableEls.length) {
          focusEl = focusableEls[0];
        }
      }
      focusEl.focus({ preventScroll: !!event.preventScrollOnBrowserFocus });
    }
    const fullRowEdit = this.beans.gos.get("editType") === "fullRow";
    if (!cellFocused && !fullRowEdit && this.editing) {
      this.stopRowOrCellEdit();
    }
    if (cellFocused) {
      this.rowCtrl.announceDescription();
    }
  }
  createCellPosition() {
    this.cellPosition = {
      rowIndex: this.rowNode.rowIndex,
      rowPinned: _makeNull(this.rowNode.rowPinned),
      column: this.column
    };
  }
  // CSS Classes that only get applied once, they never change
  applyStaticCssClasses() {
    this.cellComp.addOrRemoveCssClass(CSS_CELL, true);
    this.cellComp.addOrRemoveCssClass(CSS_CELL_NOT_INLINE_EDITING, true);
    const autoHeight = this.column.isAutoHeight() == true;
    this.cellComp.addOrRemoveCssClass(CSS_AUTO_HEIGHT, autoHeight);
    this.cellComp.addOrRemoveCssClass(CSS_NORMAL_HEIGHT, !autoHeight);
  }
  onColumnHover() {
    if (!this.cellComp) {
      return;
    }
    if (!this.beans.gos.get("columnHoverHighlight")) {
      return;
    }
    const isHovered = this.beans.columnHoverService.isHovered(this.column);
    this.cellComp.addOrRemoveCssClass(CSS_COLUMN_HOVER, isHovered);
  }
  onColDefChanged() {
    if (!this.cellComp) {
      return;
    }
    const isTooltipEnabled = this.column.isTooltipEnabled();
    if (isTooltipEnabled) {
      this.disableTooltipFeature();
      this.enableTooltipFeature();
    } else {
      this.disableTooltipFeature();
    }
    this.setWrapText();
    if (!this.editing) {
      this.refreshOrDestroyCell({ forceRefresh: true, suppressFlash: true });
    } else {
      this.beans.editService?.handleColDefChanged(this);
    }
  }
  setWrapText() {
    const value = this.column.getColDef().wrapText == true;
    this.cellComp.addOrRemoveCssClass(CSS_CELL_WRAP_TEXT, value);
  }
  dispatchCellContextMenuEvent(event) {
    const colDef = this.column.getColDef();
    const cellContextMenuEvent = this.createEvent(event, "cellContextMenu");
    this.beans.eventService.dispatchEvent(cellContextMenuEvent);
    if (colDef.onCellContextMenu) {
      window.setTimeout(() => {
        this.beans.frameworkOverrides.wrapOutgoing(() => {
          colDef.onCellContextMenu(cellContextMenuEvent);
        });
      }, 0);
    }
  }
  getCellRenderer() {
    return this.cellComp ? this.cellComp.getCellRenderer() : null;
  }
  getCellEditor() {
    return this.cellComp ? this.cellComp.getCellEditor() : null;
  }
  destroy() {
    this.onCellCompAttachedFuncs = [];
    super.destroy();
  }
  createSelectionCheckbox() {
    const cbSelectionComponent = new CheckboxSelectionComponent();
    this.beans.context.createBean(cbSelectionComponent);
    cbSelectionComponent.init({ rowNode: this.rowNode, column: this.column });
    return cbSelectionComponent;
  }
  createDndSource() {
    const dndSourceComp = new DndSourceComp(this.rowNode, this.column, this.eGui);
    this.beans.context.createBean(dndSourceComp);
    return dndSourceComp;
  }
  registerRowDragger(customElement, dragStartPixels, suppressVisibilityChange) {
    if (this.customRowDragComp) {
      this.customRowDragComp.setDragElement(customElement, dragStartPixels);
      return;
    }
    const newComp = this.createRowDragComp(customElement, dragStartPixels, suppressVisibilityChange);
    if (newComp) {
      this.customRowDragComp = newComp;
      this.addDestroyFunc(() => {
        this.beans.context.destroyBean(newComp);
        this.customRowDragComp = null;
      });
    }
  }
  createRowDragComp(customElement, dragStartPixels, suppressVisibilityChange) {
    const pagination = this.beans.gos.get("pagination");
    const rowDragManaged = this.beans.gos.get("rowDragManaged");
    const clientSideRowModelActive = this.beans.gos.isRowModelType("clientSide");
    if (rowDragManaged) {
      if (!clientSideRowModelActive) {
        _warnOnce("managed row dragging is only allowed in the Client Side Row Model");
        return;
      }
      if (pagination) {
        _warnOnce("managed row dragging is not possible when doing pagination");
        return;
      }
    }
    const rowDragComp = new RowDragComp(
      () => this.value,
      this.rowNode,
      this.column,
      customElement,
      dragStartPixels,
      suppressVisibilityChange
    );
    this.beans.context.createBean(rowDragComp);
    return rowDragComp;
  }
  setSuppressRefreshCell(suppressRefreshCell) {
    this.suppressRefreshCell = suppressRefreshCell;
  }
  getEditCompDetails() {
    return this.editCompDetails;
  }
};
_CellCtrl.DOM_DATA_KEY_CELL_CTRL = "cellCtrl";
var CellCtrl = _CellCtrl;

// community-modules/core/src/rendering/row/rowCtrl.ts
var instanceIdSequence5 = 0;
var _RowCtrl = class _RowCtrl extends BeanStub {
  constructor(rowNode, beans, animateIn, useAnimationFrameForCreate, printLayout) {
    super();
    this.allRowGuis = [];
    this.active = true;
    this.centerCellCtrls = { list: [], map: {} };
    this.leftCellCtrls = { list: [], map: {} };
    this.rightCellCtrls = { list: [], map: {} };
    this.slideInAnimation = {
      left: false,
      center: false,
      right: false,
      fullWidth: false
    };
    this.fadeInAnimation = {
      left: false,
      center: false,
      right: false,
      fullWidth: false
    };
    this.rowDragComps = [];
    this.lastMouseDownOnDragger = false;
    this.emptyStyle = {};
    this.updateColumnListsPending = false;
    this.rowId = null;
    this.businessKeySanitised = null;
    this.beans = beans;
    this.gos = beans.gos;
    this.rowNode = rowNode;
    this.paginationPage = beans.paginationService?.getCurrentPage() ?? 0;
    this.useAnimationFrameForCreate = useAnimationFrameForCreate;
    this.printLayout = printLayout;
    this.suppressRowTransform = this.gos.get("suppressRowTransform");
    this.instanceId = rowNode.id + "-" + instanceIdSequence5++;
    this.rowId = _escapeString(rowNode.id);
    this.initRowBusinessKey();
    this.rowFocused = beans.focusService.isRowFocused(this.rowNode.rowIndex, this.rowNode.rowPinned);
    this.rowLevel = beans.rowCssClassCalculator.calculateRowLevel(this.rowNode);
    this.setRowType();
    this.setAnimateFlags(animateIn);
    this.rowStyles = this.processStylesFromGridOptions();
    if (this.isFullWidth() && !this.gos.get("suppressCellFocus")) {
      this.tabIndex = -1;
    }
    this.addListeners();
  }
  initRowBusinessKey() {
    this.businessKeyForNodeFunc = this.gos.get("getBusinessKeyForNode");
    this.updateRowBusinessKey();
  }
  updateRowBusinessKey() {
    if (typeof this.businessKeyForNodeFunc !== "function") {
      return;
    }
    const businessKey = this.businessKeyForNodeFunc(this.rowNode);
    this.businessKeySanitised = _escapeString(businessKey);
  }
  getRowId() {
    return this.rowId;
  }
  getRowStyles() {
    return this.rowStyles;
  }
  getTabIndex() {
    return this.tabIndex;
  }
  isSticky() {
    return this.rowNode.sticky;
  }
  getInstanceId() {
    return this.instanceId;
  }
  updateGui(containerType, gui) {
    if (containerType === "left") {
      this.leftGui = gui;
    } else if (containerType === "right") {
      this.rightGui = gui;
    } else if (containerType === "fullWidth") {
      this.fullWidthGui = gui;
    } else {
      this.centerGui = gui;
    }
  }
  setComp(rowComp, element, containerType) {
    const gui = { rowComp, element, containerType };
    this.allRowGuis.push(gui);
    this.updateGui(containerType, gui);
    this.initialiseRowComp(gui);
    if (this.rowType !== "FullWidthLoading" && !this.rowNode.rowPinned) {
      this.beans.rowRenderer.dispatchFirstDataRenderedEvent();
    }
  }
  unsetComp(containerType) {
    this.allRowGuis = this.allRowGuis.filter((rowGui) => rowGui.containerType !== containerType);
    this.updateGui(containerType, void 0);
  }
  isCacheable() {
    return this.rowType === "FullWidthDetail" && this.gos.get("keepDetailRows");
  }
  setCached(cached) {
    const displayValue = cached ? "none" : "";
    this.allRowGuis.forEach((rg) => rg.element.style.display = displayValue);
  }
  initialiseRowComp(gui) {
    const gos = this.gos;
    this.listenOnDomOrder(gui);
    if (this.beans.columnModel.wasAutoRowHeightEverActive()) {
      this.rowNode.checkAutoHeights();
    }
    this.onRowHeightChanged(gui);
    this.updateRowIndexes(gui);
    this.setFocusedClasses(gui);
    this.setStylesFromGridOptions(false, gui);
    if (gos.isRowSelection() && this.rowNode.selectable) {
      this.onRowSelected(gui);
    }
    this.updateColumnLists(!this.useAnimationFrameForCreate);
    const comp = gui.rowComp;
    const initialRowClasses = this.getInitialRowClasses(gui.containerType);
    initialRowClasses.forEach((name) => comp.addOrRemoveCssClass(name, true));
    this.executeSlideAndFadeAnimations(gui);
    if (this.rowNode.group) {
      _setAriaExpanded(gui.element, this.rowNode.expanded == true);
    }
    this.setRowCompRowId(comp);
    this.setRowCompRowBusinessKey(comp);
    gos.setDomData(gui.element, _RowCtrl.DOM_DATA_KEY_ROW_CTRL, this);
    this.addDestroyFunc(() => gos.setDomData(gui.element, _RowCtrl.DOM_DATA_KEY_ROW_CTRL, null));
    if (this.useAnimationFrameForCreate) {
      this.beans.animationFrameService.createTask(
        this.addHoverFunctionality.bind(this, gui.element),
        this.rowNode.rowIndex,
        "createTasksP2"
      );
    } else {
      this.addHoverFunctionality(gui.element);
    }
    if (this.isFullWidth()) {
      this.setupFullWidth(gui);
    }
    if (gos.get("rowDragEntireRow")) {
      this.addRowDraggerToRow(gui);
    }
    if (this.useAnimationFrameForCreate) {
      this.beans.animationFrameService.addDestroyTask(() => {
        if (!this.isAlive()) {
          return;
        }
        gui.rowComp.addOrRemoveCssClass("ag-after-created", true);
      });
    }
    this.executeProcessRowPostCreateFunc();
  }
  setRowCompRowBusinessKey(comp) {
    if (this.businessKeySanitised == null) {
      return;
    }
    comp.setRowBusinessKey(this.businessKeySanitised);
  }
  getBusinessKey() {
    return this.businessKeySanitised;
  }
  setRowCompRowId(comp) {
    this.rowId = _escapeString(this.rowNode.id);
    if (this.rowId == null) {
      return;
    }
    comp.setRowId(this.rowId);
  }
  executeSlideAndFadeAnimations(gui) {
    const { containerType } = gui;
    const shouldSlide = this.slideInAnimation[containerType];
    if (shouldSlide) {
      _executeNextVMTurn(() => {
        this.onTopChanged();
      });
      this.slideInAnimation[containerType] = false;
    }
    const shouldFade = this.fadeInAnimation[containerType];
    if (shouldFade) {
      _executeNextVMTurn(() => {
        gui.rowComp.addOrRemoveCssClass("ag-opacity-zero", false);
      });
      this.fadeInAnimation[containerType] = false;
    }
  }
  addRowDraggerToRow(gui) {
    if (this.gos.get("enableRangeSelection")) {
      _warnOnce(
        "Setting `rowDragEntireRow: true` in the gridOptions doesn't work with `enableRangeSelection: true`"
      );
      return;
    }
    const translate = this.beans.localeService.getLocaleTextFunc();
    const rowDragComp = new RowDragComp(
      () => `1 ${translate("rowDragRow", "row")}`,
      this.rowNode,
      void 0,
      gui.element,
      void 0,
      true
    );
    const rowDragBean = this.createBean(rowDragComp, this.beans.context);
    this.rowDragComps.push(rowDragBean);
  }
  setupFullWidth(gui) {
    const pinned = this.getPinnedForContainer(gui.containerType);
    if (this.rowType == "FullWidthDetail") {
      if (!ModuleRegistry.__assertRegistered(
        "@ag-grid-enterprise/master-detail" /* MasterDetailModule */,
        "cell renderer 'agDetailCellRenderer' (for master detail)",
        this.beans.context.getGridId()
      )) {
        return;
      }
    }
    const compDetails = this.createFullWidthCompDetails(gui.element, pinned);
    gui.rowComp.showFullWidth(compDetails);
  }
  isPrintLayout() {
    return this.printLayout;
  }
  getFullWidthCellRenderers() {
    if (this.gos.get("embedFullWidthRows")) {
      return this.allRowGuis.map((gui) => gui?.rowComp?.getFullWidthCellRenderer());
    }
    return [this.fullWidthGui?.rowComp?.getFullWidthCellRenderer()];
  }
  // use by autoWidthCalculator, as it clones the elements
  getCellElement(column) {
    const cellCtrl = this.getCellCtrl(column);
    return cellCtrl ? cellCtrl.getGui() : null;
  }
  executeProcessRowPostCreateFunc() {
    const func = this.gos.getCallback("processRowPostCreate");
    if (!func || !this.areAllContainersReady()) {
      return;
    }
    const params = {
      // areAllContainersReady asserts that centerGui is not null
      eRow: this.centerGui.element,
      ePinnedLeftRow: this.leftGui ? this.leftGui.element : void 0,
      ePinnedRightRow: this.rightGui ? this.rightGui.element : void 0,
      node: this.rowNode,
      rowIndex: this.rowNode.rowIndex,
      addRenderedRowListener: this.addEventListener.bind(this)
    };
    func(params);
  }
  areAllContainersReady() {
    const isLeftReady = !!this.leftGui || !this.beans.visibleColsService.isPinningLeft();
    const isCenterReady = !!this.centerGui;
    const isRightReady = !!this.rightGui || !this.beans.visibleColsService.isPinningRight();
    return isLeftReady && isCenterReady && isRightReady;
  }
  setRowType() {
    const isStub = this.rowNode.stub && !this.gos.get("suppressServerSideFullWidthLoadingRow");
    const isFullWidthCell = this.rowNode.isFullWidthCell();
    const isDetailCell = this.gos.get("masterDetail") && this.rowNode.detail;
    const pivotMode = this.beans.columnModel.isPivotMode();
    const isGroupRow = !!this.rowNode.group && !this.rowNode.footer;
    const isFullWidthGroup = isGroupRow && this.gos.isGroupUseEntireRow(pivotMode);
    if (isStub) {
      this.rowType = "FullWidthLoading";
    } else if (isDetailCell) {
      this.rowType = "FullWidthDetail";
    } else if (isFullWidthCell) {
      this.rowType = "FullWidth";
    } else if (isFullWidthGroup) {
      this.rowType = "FullWidthGroup";
    } else {
      this.rowType = "Normal";
    }
  }
  updateColumnLists(suppressAnimationFrame = false, useFlushSync = false) {
    if (this.isFullWidth()) {
      return;
    }
    const noAnimation = suppressAnimationFrame || this.gos.get("suppressAnimationFrame") || this.printLayout;
    if (noAnimation) {
      this.updateColumnListsImpl(useFlushSync);
      return;
    }
    if (this.updateColumnListsPending) {
      return;
    }
    this.beans.animationFrameService.createTask(
      () => {
        if (!this.active) {
          return;
        }
        this.updateColumnListsImpl(true);
      },
      this.rowNode.rowIndex,
      "createTasksP1"
    );
    this.updateColumnListsPending = true;
  }
  createCellCtrls(prev, cols, pinned = null) {
    const res = {
      list: [],
      map: {}
    };
    const addCell = (colInstanceId, cellCtrl) => {
      res.list.push(cellCtrl);
      res.map[colInstanceId] = cellCtrl;
    };
    cols.forEach((col) => {
      const colInstanceId = col.getInstanceId();
      let cellCtrl = prev.map[colInstanceId];
      if (!cellCtrl) {
        cellCtrl = new CellCtrl(col, this.rowNode, this.beans, this);
      }
      addCell(colInstanceId, cellCtrl);
    });
    prev.list.forEach((prevCellCtrl) => {
      const cellInResult = res.map[prevCellCtrl.getColumn().getInstanceId()] != null;
      if (cellInResult) {
        return;
      }
      const keepCell = !this.isCellEligibleToBeRemoved(prevCellCtrl, pinned);
      if (keepCell) {
        addCell(prevCellCtrl.getColumn().getInstanceId(), prevCellCtrl);
        return;
      }
      prevCellCtrl.destroy();
    });
    return res;
  }
  updateColumnListsImpl(useFlushSync) {
    this.updateColumnListsPending = false;
    this.createAllCellCtrls();
    this.setCellCtrls(useFlushSync);
  }
  setCellCtrls(useFlushSync) {
    this.allRowGuis.forEach((item) => {
      const cellControls = this.getCellCtrlsForContainer(item.containerType);
      item.rowComp.setCellCtrls(cellControls, useFlushSync);
    });
  }
  getCellCtrlsForContainer(containerType) {
    switch (containerType) {
      case "left":
        return this.leftCellCtrls.list;
      case "right":
        return this.rightCellCtrls.list;
      case "fullWidth":
        return [];
      case "center":
        return this.centerCellCtrls.list;
    }
  }
  createAllCellCtrls() {
    const columnViewportService = this.beans.columnViewportService;
    const presentedColsService = this.beans.visibleColsService;
    if (this.printLayout) {
      this.centerCellCtrls = this.createCellCtrls(this.centerCellCtrls, presentedColsService.getAllCols());
      this.leftCellCtrls = { list: [], map: {} };
      this.rightCellCtrls = { list: [], map: {} };
    } else {
      const centerCols = columnViewportService.getColsWithinViewport(this.rowNode);
      this.centerCellCtrls = this.createCellCtrls(this.centerCellCtrls, centerCols);
      const leftCols = presentedColsService.getLeftColsForRow(this.rowNode);
      this.leftCellCtrls = this.createCellCtrls(this.leftCellCtrls, leftCols, "left");
      const rightCols = presentedColsService.getRightColsForRow(this.rowNode);
      this.rightCellCtrls = this.createCellCtrls(this.rightCellCtrls, rightCols, "right");
    }
  }
  isCellEligibleToBeRemoved(cellCtrl, nextContainerPinned) {
    const REMOVE_CELL = true;
    const KEEP_CELL = false;
    const column = cellCtrl.getColumn();
    if (column.getPinned() != nextContainerPinned) {
      return REMOVE_CELL;
    }
    const editing = cellCtrl.isEditing();
    const focused = this.beans.focusService.isCellFocused(cellCtrl.getCellPosition());
    const mightWantToKeepCell = editing || focused;
    if (mightWantToKeepCell) {
      const column2 = cellCtrl.getColumn();
      const displayedColumns = this.beans.visibleColsService.getAllCols();
      const cellStillDisplayed = displayedColumns.indexOf(column2) >= 0;
      return cellStillDisplayed ? KEEP_CELL : REMOVE_CELL;
    }
    return REMOVE_CELL;
  }
  getDomOrder() {
    const isEnsureDomOrder = this.gos.get("ensureDomOrder");
    return isEnsureDomOrder || this.gos.isDomLayout("print");
  }
  listenOnDomOrder(gui) {
    const listener = () => {
      gui.rowComp.setDomOrder(this.getDomOrder());
    };
    this.addManagedPropertyListener("domLayout", listener);
    this.addManagedPropertyListener("ensureDomOrder", listener);
  }
  setAnimateFlags(animateIn) {
    if (this.isSticky() || !animateIn) {
      return;
    }
    const oldRowTopExists = _exists(this.rowNode.oldRowTop);
    const pinningLeft = this.beans.visibleColsService.isPinningLeft();
    const pinningRight = this.beans.visibleColsService.isPinningRight();
    if (oldRowTopExists) {
      if (this.isFullWidth() && !this.gos.get("embedFullWidthRows")) {
        this.slideInAnimation.fullWidth = true;
        return;
      }
      this.slideInAnimation.center = true;
      this.slideInAnimation.left = pinningLeft;
      this.slideInAnimation.right = pinningRight;
    } else {
      if (this.isFullWidth() && !this.gos.get("embedFullWidthRows")) {
        this.fadeInAnimation.fullWidth = true;
        return;
      }
      this.fadeInAnimation.center = true;
      this.fadeInAnimation.left = pinningLeft;
      this.fadeInAnimation.right = pinningRight;
    }
  }
  isEditing() {
    return this.editingRow;
  }
  isFullWidth() {
    return this.rowType !== "Normal";
  }
  refreshFullWidth() {
    const tryRefresh = (gui, pinned) => {
      if (!gui) {
        return true;
      }
      return gui.rowComp.refreshFullWidth(() => {
        const compDetails = this.createFullWidthCompDetails(gui.element, pinned);
        return compDetails.params;
      });
    };
    const fullWidthSuccess = tryRefresh(this.fullWidthGui, null);
    const centerSuccess = tryRefresh(this.centerGui, null);
    const leftSuccess = tryRefresh(this.leftGui, "left");
    const rightSuccess = tryRefresh(this.rightGui, "right");
    const allFullWidthRowsRefreshed = fullWidthSuccess && centerSuccess && leftSuccess && rightSuccess;
    return allFullWidthRowsRefreshed;
  }
  addListeners() {
    this.addManagedListeners(this.rowNode, {
      heightChanged: () => this.onRowHeightChanged(),
      rowSelected: () => this.onRowSelected(),
      rowIndexChanged: this.onRowIndexChanged.bind(this),
      topChanged: this.onTopChanged.bind(this),
      expandedChanged: this.updateExpandedCss.bind(this),
      hasChildrenChanged: this.updateExpandedCss.bind(this)
    });
    if (this.rowNode.detail) {
      this.addManagedListeners(this.rowNode.parent, { dataChanged: this.onRowNodeDataChanged.bind(this) });
    }
    this.addManagedListeners(this.rowNode, {
      dataChanged: this.onRowNodeDataChanged.bind(this),
      cellChanged: this.postProcessCss.bind(this),
      rowHighlightChanged: this.onRowNodeHighlightChanged.bind(this),
      draggingChanged: this.postProcessRowDragging.bind(this),
      uiLevelChanged: this.onUiLevelChanged.bind(this)
    });
    this.addManagedListeners(this.beans.eventService, {
      paginationPixelOffsetChanged: this.onPaginationPixelOffsetChanged.bind(this),
      heightScaleChanged: this.onTopChanged.bind(this),
      displayedColumnsChanged: this.onDisplayedColumnsChanged.bind(this),
      virtualColumnsChanged: this.onVirtualColumnsChanged.bind(this),
      cellFocused: this.onCellFocusChanged.bind(this),
      cellFocusCleared: this.onCellFocusChanged.bind(this),
      paginationChanged: this.onPaginationChanged.bind(this),
      modelUpdated: this.refreshFirstAndLastRowStyles.bind(this),
      columnMoved: () => this.updateColumnLists()
    });
    this.addDestroyFunc(() => {
      this.destroyBeans(this.rowDragComps, this.beans.context);
      if (this.tooltipFeature) {
        this.tooltipFeature = this.destroyBean(this.tooltipFeature, this.beans.context);
      }
    });
    this.addManagedPropertyListeners(["rowDragEntireRow"], () => {
      const useRowDragEntireRow = this.gos.get("rowDragEntireRow");
      if (useRowDragEntireRow) {
        this.allRowGuis.forEach((gui) => {
          this.addRowDraggerToRow(gui);
        });
        return;
      }
      this.rowDragComps = this.destroyBeans(this.rowDragComps, this.beans.context);
    });
    this.addListenersForCellComps();
  }
  addListenersForCellComps() {
    this.addManagedListeners(this.rowNode, {
      rowIndexChanged: () => {
        this.getAllCellCtrls().forEach((cellCtrl) => cellCtrl.onRowIndexChanged());
      },
      cellChanged: (event) => {
        this.getAllCellCtrls().forEach((cellCtrl) => cellCtrl.onCellChanged(event));
      }
    });
  }
  onRowNodeDataChanged(event) {
    const fullWidthChanged = this.isFullWidth() !== !!this.rowNode.isFullWidthCell();
    if (fullWidthChanged) {
      this.beans.rowRenderer.redrawRow(this.rowNode);
      return;
    }
    if (this.isFullWidth()) {
      const refresh = this.refreshFullWidth();
      if (!refresh) {
        this.beans.rowRenderer.redrawRow(this.rowNode);
      }
      return;
    }
    this.getAllCellCtrls().forEach(
      (cellCtrl) => cellCtrl.refreshCell({
        suppressFlash: !event.update,
        newData: !event.update
      })
    );
    this.allRowGuis.forEach((gui) => {
      this.setRowCompRowId(gui.rowComp);
      this.updateRowBusinessKey();
      this.setRowCompRowBusinessKey(gui.rowComp);
    });
    this.onRowSelected();
    this.postProcessCss();
  }
  postProcessCss() {
    this.setStylesFromGridOptions(true);
    this.postProcessClassesFromGridOptions();
    this.postProcessRowClassRules();
    this.postProcessRowDragging();
  }
  onRowNodeHighlightChanged() {
    const highlighted = this.rowNode.highlighted;
    this.allRowGuis.forEach((gui) => {
      const aboveOn = highlighted === 0 /* Above */;
      const belowOn = highlighted === 1 /* Below */;
      gui.rowComp.addOrRemoveCssClass("ag-row-highlight-above", aboveOn);
      gui.rowComp.addOrRemoveCssClass("ag-row-highlight-below", belowOn);
    });
  }
  postProcessRowDragging() {
    const dragging = this.rowNode.dragging;
    this.allRowGuis.forEach((gui) => gui.rowComp.addOrRemoveCssClass("ag-row-dragging", dragging));
  }
  updateExpandedCss() {
    const expandable = this.rowNode.isExpandable();
    const expanded = this.rowNode.expanded == true;
    this.allRowGuis.forEach((gui) => {
      gui.rowComp.addOrRemoveCssClass("ag-row-group", expandable);
      gui.rowComp.addOrRemoveCssClass("ag-row-group-expanded", expandable && expanded);
      gui.rowComp.addOrRemoveCssClass("ag-row-group-contracted", expandable && !expanded);
      _setAriaExpanded(gui.element, expandable && expanded);
    });
  }
  onDisplayedColumnsChanged() {
    this.updateColumnLists(true);
    if (this.beans.columnModel.wasAutoRowHeightEverActive()) {
      this.rowNode.checkAutoHeights();
    }
  }
  onVirtualColumnsChanged() {
    this.updateColumnLists(false, true);
  }
  getRowPosition() {
    return {
      rowPinned: _makeNull(this.rowNode.rowPinned),
      rowIndex: this.rowNode.rowIndex
    };
  }
  onKeyboardNavigate(keyboardEvent) {
    const currentFullWidthComp = this.allRowGuis.find(
      (c) => c.element.contains(keyboardEvent.target)
    );
    const currentFullWidthContainer = currentFullWidthComp ? currentFullWidthComp.element : null;
    const isFullWidthContainerFocused = currentFullWidthContainer === keyboardEvent.target;
    if (!isFullWidthContainerFocused) {
      return;
    }
    const node = this.rowNode;
    const lastFocusedCell = this.beans.focusService.getFocusedCell();
    const cellPosition = {
      rowIndex: node.rowIndex,
      rowPinned: node.rowPinned,
      column: lastFocusedCell && lastFocusedCell.column
    };
    this.beans.navigationService.navigateToNextCell(keyboardEvent, keyboardEvent.key, cellPosition, true);
    keyboardEvent.preventDefault();
  }
  onTabKeyDown(keyboardEvent) {
    if (keyboardEvent.defaultPrevented || _isStopPropagationForAgGrid(keyboardEvent)) {
      return;
    }
    const currentFullWidthComp = this.allRowGuis.find(
      (c) => c.element.contains(keyboardEvent.target)
    );
    const currentFullWidthContainer = currentFullWidthComp ? currentFullWidthComp.element : null;
    const isFullWidthContainerFocused = currentFullWidthContainer === keyboardEvent.target;
    let nextEl = null;
    if (!isFullWidthContainerFocused) {
      nextEl = this.beans.focusService.findNextFocusableElement(
        currentFullWidthContainer,
        false,
        keyboardEvent.shiftKey
      );
    }
    if (this.isFullWidth() && isFullWidthContainerFocused || !nextEl) {
      this.beans.navigationService.onTabKeyDown(this, keyboardEvent);
    }
  }
  getFullWidthElement() {
    if (this.fullWidthGui) {
      return this.fullWidthGui.element;
    }
    return null;
  }
  getRowYPosition() {
    const displayedEl = this.allRowGuis.find((el) => _isVisible(el.element))?.element;
    if (displayedEl) {
      return displayedEl.getBoundingClientRect().top;
    }
    return 0;
  }
  onFullWidthRowFocused(event) {
    const node = this.rowNode;
    const isFocused = !event ? false : this.isFullWidth() && event.rowIndex === node.rowIndex && event.rowPinned == node.rowPinned;
    const element = this.fullWidthGui ? this.fullWidthGui.element : this.centerGui?.element;
    if (!element) {
      return;
    }
    element.classList.toggle("ag-full-width-focus", isFocused);
    if (isFocused && event?.forceBrowserFocus) {
      element.focus({ preventScroll: true });
    }
  }
  recreateCell(cellCtrl) {
    this.centerCellCtrls = this.removeCellCtrl(this.centerCellCtrls, cellCtrl);
    this.leftCellCtrls = this.removeCellCtrl(this.leftCellCtrls, cellCtrl);
    this.rightCellCtrls = this.removeCellCtrl(this.rightCellCtrls, cellCtrl);
    cellCtrl.destroy();
    this.updateColumnLists();
  }
  removeCellCtrl(prev, cellCtrlToRemove) {
    const res = {
      list: [],
      map: {}
    };
    prev.list.forEach((cellCtrl) => {
      if (cellCtrl === cellCtrlToRemove) {
        return;
      }
      res.list.push(cellCtrl);
      res.map[cellCtrl.getColumn().getInstanceId()] = cellCtrl;
    });
    return res;
  }
  onMouseEvent(eventName, mouseEvent) {
    switch (eventName) {
      case "dblclick":
        this.onRowDblClick(mouseEvent);
        break;
      case "click":
        this.onRowClick(mouseEvent);
        break;
      case "touchstart":
      case "mousedown":
        this.onRowMouseDown(mouseEvent);
        break;
    }
  }
  createRowEvent(type, domEvent) {
    return this.gos.addGridCommonParams({
      type,
      node: this.rowNode,
      data: this.rowNode.data,
      rowIndex: this.rowNode.rowIndex,
      rowPinned: this.rowNode.rowPinned,
      event: domEvent
    });
  }
  createRowEventWithSource(type, domEvent) {
    const event = this.createRowEvent(type, domEvent);
    event.source = this;
    return event;
  }
  onRowDblClick(mouseEvent) {
    if (_isStopPropagationForAgGrid(mouseEvent)) {
      return;
    }
    const agEvent = this.createRowEventWithSource("rowDoubleClicked", mouseEvent);
    this.beans.eventService.dispatchEvent(agEvent);
  }
  onRowMouseDown(mouseEvent) {
    this.lastMouseDownOnDragger = _isElementChildOfClass(mouseEvent.target, "ag-row-drag", 3);
    if (!this.isFullWidth()) {
      return;
    }
    const node = this.rowNode;
    const presentedColsService = this.beans.visibleColsService;
    if (this.beans.rangeService) {
      this.beans.rangeService.removeAllCellRanges();
    }
    const element = this.getFullWidthElement();
    const target = mouseEvent.target;
    let forceBrowserFocus = true;
    if (element && element.contains(target) && _isFocusableFormField(target)) {
      forceBrowserFocus = false;
    }
    this.beans.focusService.setFocusedCell({
      rowIndex: node.rowIndex,
      column: presentedColsService.getAllCols()[0],
      rowPinned: node.rowPinned,
      forceBrowserFocus
    });
  }
  onRowClick(mouseEvent) {
    const stop = _isStopPropagationForAgGrid(mouseEvent) || this.lastMouseDownOnDragger;
    if (stop) {
      return;
    }
    const agEvent = this.createRowEventWithSource("rowClicked", mouseEvent);
    this.beans.eventService.dispatchEvent(agEvent);
    const isMultiKey = mouseEvent.ctrlKey || mouseEvent.metaKey;
    const isShiftKey = mouseEvent.shiftKey;
    const groupSelectsChildren = this.gos.get("groupSelectsChildren");
    if (
      // we do not allow selecting groups by clicking (as the click here expands the group), or if it's a detail row,
      // so return if it's a group row
      groupSelectsChildren && this.rowNode.group || this.isRowSelectionBlocked() || // if click selection suppressed, do nothing
      this.gos.get("suppressRowClickSelection")
    ) {
      return;
    }
    const multiSelectOnClick = this.gos.get("rowMultiSelectWithClick");
    const rowDeselectionWithCtrl = !this.gos.get("suppressRowDeselection");
    const source = "rowClicked";
    if (this.rowNode.isSelected()) {
      if (multiSelectOnClick) {
        this.rowNode.setSelectedParams({ newValue: false, event: mouseEvent, source });
      } else if (isMultiKey) {
        if (rowDeselectionWithCtrl) {
          this.rowNode.setSelectedParams({ newValue: false, event: mouseEvent, source });
        }
      } else {
        this.rowNode.setSelectedParams({
          newValue: true,
          clearSelection: !isShiftKey,
          rangeSelect: isShiftKey,
          event: mouseEvent,
          source
        });
      }
    } else {
      const clearSelection = multiSelectOnClick ? false : !isMultiKey;
      this.rowNode.setSelectedParams({
        newValue: true,
        clearSelection,
        rangeSelect: isShiftKey,
        event: mouseEvent,
        source
      });
    }
  }
  isRowSelectionBlocked() {
    return !this.rowNode.selectable || !!this.rowNode.rowPinned || !this.gos.isRowSelection();
  }
  setupDetailRowAutoHeight(eDetailGui) {
    if (this.rowType !== "FullWidthDetail") {
      return;
    }
    if (!this.gos.get("detailRowAutoHeight")) {
      return;
    }
    const checkRowSizeFunc = () => {
      const clientHeight = eDetailGui.clientHeight;
      if (clientHeight != null && clientHeight > 0) {
        const updateRowHeightFunc = () => {
          this.rowNode.setRowHeight(clientHeight);
          if (this.beans.rowModel.getType() === "clientSide") {
            this.beans.rowModel.onRowHeightChanged();
          } else if (this.beans.rowModel.getType() === "serverSide") {
            this.beans.rowModel.onRowHeightChanged();
          }
        };
        window.setTimeout(updateRowHeightFunc, 0);
      }
    };
    const resizeObserverDestroyFunc = this.beans.resizeObserverService.observeResize(eDetailGui, checkRowSizeFunc);
    this.addDestroyFunc(resizeObserverDestroyFunc);
    checkRowSizeFunc();
  }
  createFullWidthCompDetails(eRow, pinned) {
    const { gos, rowNode } = this;
    const params = gos.addGridCommonParams({
      fullWidth: true,
      data: rowNode.data,
      node: rowNode,
      value: rowNode.key,
      valueFormatted: rowNode.key,
      // these need to be taken out, as part of 'afterAttached' now
      eGridCell: eRow,
      eParentOfValue: eRow,
      pinned,
      addRenderedRowListener: this.addEventListener.bind(this),
      registerRowDragger: (rowDraggerElement, dragStartPixels, value, suppressVisibilityChange) => this.addFullWidthRowDragging(rowDraggerElement, dragStartPixels, value, suppressVisibilityChange),
      setTooltip: (value, shouldDisplayTooltip) => this.refreshRowTooltip(value, shouldDisplayTooltip)
    });
    const compFactory = this.beans.userComponentFactory;
    switch (this.rowType) {
      case "FullWidthDetail":
        return compFactory.getFullWidthDetailCellRendererDetails(params);
      case "FullWidthGroup":
        return compFactory.getFullWidthGroupCellRendererDetails(params);
      case "FullWidthLoading":
        return compFactory.getFullWidthLoadingCellRendererDetails(params);
      default:
        return compFactory.getFullWidthCellRendererDetails(params);
    }
  }
  refreshRowTooltip(value, shouldDisplayTooltip) {
    if (!this.fullWidthGui) {
      return;
    }
    const tooltipParams = {
      getGui: () => this.fullWidthGui.element,
      getTooltipValue: () => value,
      getLocation: () => "fullWidthRow",
      shouldDisplayTooltip
    };
    if (this.tooltipFeature) {
      this.destroyBean(this.tooltipFeature, this.beans.context);
    }
    this.tooltipFeature = this.createBean(new TooltipFeature(tooltipParams, this.beans));
  }
  addFullWidthRowDragging(rowDraggerElement, dragStartPixels, value = "", suppressVisibilityChange) {
    if (!this.isFullWidth()) {
      return;
    }
    const rowDragComp = new RowDragComp(
      () => value,
      this.rowNode,
      void 0,
      rowDraggerElement,
      dragStartPixels,
      suppressVisibilityChange
    );
    this.createBean(rowDragComp, this.beans.context);
    this.addDestroyFunc(() => {
      this.destroyBean(rowDragComp, this.beans.context);
    });
  }
  onUiLevelChanged() {
    const newLevel = this.beans.rowCssClassCalculator.calculateRowLevel(this.rowNode);
    if (this.rowLevel != newLevel) {
      const classToAdd = "ag-row-level-" + newLevel;
      const classToRemove = "ag-row-level-" + this.rowLevel;
      this.allRowGuis.forEach((gui) => {
        gui.rowComp.addOrRemoveCssClass(classToAdd, true);
        gui.rowComp.addOrRemoveCssClass(classToRemove, false);
      });
    }
    this.rowLevel = newLevel;
  }
  isFirstRowOnPage() {
    return this.rowNode.rowIndex === this.beans.pageBoundsService.getFirstRow();
  }
  isLastRowOnPage() {
    return this.rowNode.rowIndex === this.beans.pageBoundsService.getLastRow();
  }
  refreshFirstAndLastRowStyles() {
    const newFirst = this.isFirstRowOnPage();
    const newLast = this.isLastRowOnPage();
    if (this.firstRowOnPage !== newFirst) {
      this.firstRowOnPage = newFirst;
      this.allRowGuis.forEach((gui) => gui.rowComp.addOrRemoveCssClass("ag-row-first", newFirst));
    }
    if (this.lastRowOnPage !== newLast) {
      this.lastRowOnPage = newLast;
      this.allRowGuis.forEach((gui) => gui.rowComp.addOrRemoveCssClass("ag-row-last", newLast));
    }
  }
  stopEditing(cancel = false) {
    if (this.stoppingRowEdit) {
      return;
    }
    this.beans.rowEditService?.stopEditing(this, cancel);
  }
  setInlineEditingCss(editing) {
    this.allRowGuis.forEach((gui) => {
      gui.rowComp.addOrRemoveCssClass("ag-row-inline-editing", editing);
      gui.rowComp.addOrRemoveCssClass("ag-row-not-inline-editing", !editing);
    });
  }
  setEditingRow(value) {
    this.editingRow = value;
  }
  startRowEditing(key = null, sourceRenderedCell = null, event = null) {
    if (this.editingRow) {
      return;
    }
    this.beans.rowEditService?.startEditing(this, key, sourceRenderedCell, event);
  }
  getAllCellCtrls() {
    if (this.leftCellCtrls.list.length === 0 && this.rightCellCtrls.list.length === 0) {
      return this.centerCellCtrls.list;
    }
    const res = [...this.centerCellCtrls.list, ...this.leftCellCtrls.list, ...this.rightCellCtrls.list];
    return res;
  }
  postProcessClassesFromGridOptions() {
    const cssClasses = this.beans.rowCssClassCalculator.processClassesFromGridOptions(this.rowNode);
    if (!cssClasses || !cssClasses.length) {
      return;
    }
    cssClasses.forEach((classStr) => {
      this.allRowGuis.forEach((c) => c.rowComp.addOrRemoveCssClass(classStr, true));
    });
  }
  postProcessRowClassRules() {
    this.beans.rowCssClassCalculator.processRowClassRules(
      this.rowNode,
      (className) => {
        this.allRowGuis.forEach((gui) => gui.rowComp.addOrRemoveCssClass(className, true));
      },
      (className) => {
        this.allRowGuis.forEach((gui) => gui.rowComp.addOrRemoveCssClass(className, false));
      }
    );
  }
  setStylesFromGridOptions(updateStyles, gui) {
    if (updateStyles) {
      this.rowStyles = this.processStylesFromGridOptions();
    }
    this.forEachGui(gui, (gui2) => gui2.rowComp.setUserStyles(this.rowStyles));
  }
  getPinnedForContainer(rowContainerType) {
    if (rowContainerType === "left" || rowContainerType === "right") {
      return rowContainerType;
    }
    return null;
  }
  getInitialRowClasses(rowContainerType) {
    const pinned = this.getPinnedForContainer(rowContainerType);
    const params = {
      rowNode: this.rowNode,
      rowFocused: this.rowFocused,
      fadeRowIn: this.fadeInAnimation[rowContainerType],
      rowIsEven: this.rowNode.rowIndex % 2 === 0,
      rowLevel: this.rowLevel,
      fullWidthRow: this.isFullWidth(),
      firstRowOnPage: this.isFirstRowOnPage(),
      lastRowOnPage: this.isLastRowOnPage(),
      printLayout: this.printLayout,
      expandable: this.rowNode.isExpandable(),
      pinned
    };
    return this.beans.rowCssClassCalculator.getInitialRowClasses(params);
  }
  processStylesFromGridOptions() {
    const rowStyle = this.gos.get("rowStyle");
    if (rowStyle && typeof rowStyle === "function") {
      _warnOnce("rowStyle should be an object of key/value styles, not be a function, use getRowStyle() instead");
      return;
    }
    const rowStyleFunc = this.gos.getCallback("getRowStyle");
    let rowStyleFuncResult;
    if (rowStyleFunc) {
      const params = {
        data: this.rowNode.data,
        node: this.rowNode,
        rowIndex: this.rowNode.rowIndex
      };
      rowStyleFuncResult = rowStyleFunc(params);
    }
    if (rowStyleFuncResult || rowStyle) {
      return Object.assign({}, rowStyle, rowStyleFuncResult);
    }
    return this.emptyStyle;
  }
  onRowSelected(gui) {
    const selected = !!this.rowNode.isSelected();
    this.forEachGui(gui, (gui2) => {
      gui2.rowComp.addOrRemoveCssClass("ag-row-selected", selected);
      _setAriaSelected(gui2.element, selected);
      const hasFocus = gui2.element.contains(this.gos.getActiveDomElement());
      if (hasFocus && (gui2 === this.centerGui || gui2 === this.fullWidthGui)) {
        this.announceDescription();
      }
    });
  }
  announceDescription() {
    if (this.isRowSelectionBlocked()) {
      return;
    }
    const selected = this.rowNode.isSelected();
    if (selected && this.gos.get("suppressRowDeselection")) {
      return;
    }
    const translate = this.beans.localeService.getLocaleTextFunc();
    const label = translate(
      selected ? "ariaRowDeselect" : "ariaRowSelect",
      `Press SPACE to ${selected ? "deselect" : "select"} this row.`
    );
    this.beans.ariaAnnouncementService.announceValue(label);
  }
  addHoverFunctionality(eRow) {
    if (!this.active) {
      return;
    }
    const { rowNode, beans, gos } = this;
    this.addManagedListeners(eRow, {
      mouseenter: () => rowNode.onMouseEnter(),
      mouseleave: () => rowNode.onMouseLeave()
    });
    this.addManagedListeners(rowNode, {
      mouseEnter: () => {
        if (!beans.dragService.isDragging() && !gos.get("suppressRowHoverHighlight")) {
          eRow.classList.add("ag-row-hover");
          rowNode.setHovered(true);
        }
      },
      mouseLeave: () => {
        eRow.classList.remove("ag-row-hover");
        rowNode.setHovered(false);
      }
    });
  }
  // for animation, we don't want to animate entry or exit to a very far away pixel,
  // otherwise the row would move so fast, it would appear to disappear. so this method
  // moves the row closer to the viewport if it is far away, so the row slide in / out
  // at a speed the user can see.
  roundRowTopToBounds(rowTop) {
    const range = this.beans.ctrlsService.getGridBodyCtrl().getScrollFeature().getApproximateVScollPosition();
    const minPixel = this.applyPaginationOffset(range.top, true) - 100;
    const maxPixel = this.applyPaginationOffset(range.bottom, true) + 100;
    return Math.min(Math.max(minPixel, rowTop), maxPixel);
  }
  getFrameworkOverrides() {
    return this.beans.frameworkOverrides;
  }
  forEachGui(gui, callback) {
    if (gui) {
      callback(gui);
    } else {
      this.allRowGuis.forEach(callback);
    }
  }
  onRowHeightChanged(gui) {
    if (this.rowNode.rowHeight == null) {
      return;
    }
    const rowHeight = this.rowNode.rowHeight;
    const defaultRowHeight = this.beans.environment.getDefaultRowHeight();
    const isHeightFromFunc = this.gos.isGetRowHeightFunction();
    const heightFromFunc = isHeightFromFunc ? this.gos.getRowHeightForNode(this.rowNode).height : void 0;
    const lineHeight = heightFromFunc ? `${Math.min(defaultRowHeight, heightFromFunc) - 2}px` : void 0;
    this.forEachGui(gui, (gui2) => {
      gui2.element.style.height = `${rowHeight}px`;
      if (lineHeight) {
        gui2.element.style.setProperty("--ag-line-height", lineHeight);
      }
    });
  }
  addEventListener(eventType, listener) {
    super.addEventListener(eventType, listener);
  }
  removeEventListener(eventType, listener) {
    super.removeEventListener(eventType, listener);
  }
  // note - this is NOT called by context, as we don't wire / unwire the CellComp for performance reasons.
  destroyFirstPass(suppressAnimation = false) {
    this.active = false;
    if (!suppressAnimation && this.gos.isAnimateRows() && !this.isSticky()) {
      const rowStillVisibleJustNotInViewport = this.rowNode.rowTop != null;
      if (rowStillVisibleJustNotInViewport) {
        const rowTop = this.roundRowTopToBounds(this.rowNode.rowTop);
        this.setRowTop(rowTop);
      } else {
        this.allRowGuis.forEach((gui) => gui.rowComp.addOrRemoveCssClass("ag-opacity-zero", true));
      }
    }
    this.rowNode.setHovered(false);
    const event = this.createRowEvent("virtualRowRemoved");
    this.dispatchLocalEvent(event);
    this.beans.eventService.dispatchEvent(event);
    super.destroy();
  }
  destroySecondPass() {
    this.allRowGuis.length = 0;
    this.stopEditing();
    const destroyCellCtrls = (ctrls) => {
      ctrls.list.forEach((c) => c.destroy());
      return { list: [], map: {} };
    };
    this.centerCellCtrls = destroyCellCtrls(this.centerCellCtrls);
    this.leftCellCtrls = destroyCellCtrls(this.leftCellCtrls);
    this.rightCellCtrls = destroyCellCtrls(this.rightCellCtrls);
  }
  setFocusedClasses(gui) {
    this.forEachGui(gui, (gui2) => {
      gui2.rowComp.addOrRemoveCssClass("ag-row-focus", this.rowFocused);
      gui2.rowComp.addOrRemoveCssClass("ag-row-no-focus", !this.rowFocused);
    });
  }
  onCellFocusChanged() {
    const rowFocused = this.beans.focusService.isRowFocused(this.rowNode.rowIndex, this.rowNode.rowPinned);
    if (rowFocused !== this.rowFocused) {
      this.rowFocused = rowFocused;
      this.setFocusedClasses();
    }
    if (!rowFocused && this.editingRow) {
      this.stopEditing(false);
    }
  }
  onPaginationChanged() {
    const currentPage = this.beans.paginationService?.getCurrentPage() ?? 0;
    if (this.paginationPage !== currentPage) {
      this.paginationPage = currentPage;
      this.onTopChanged();
    }
    this.refreshFirstAndLastRowStyles();
  }
  onTopChanged() {
    this.setRowTop(this.rowNode.rowTop);
  }
  onPaginationPixelOffsetChanged() {
    this.onTopChanged();
  }
  // applies pagination offset, eg if on second page, and page height is 500px, then removes
  // 500px from the top position, so a row with rowTop 600px is displayed at location 100px.
  // reverse will take the offset away rather than add.
  applyPaginationOffset(topPx, reverse = false) {
    if (this.rowNode.isRowPinned() || this.rowNode.sticky) {
      return topPx;
    }
    const pixelOffset = this.beans.pageBoundsService.getPixelOffset();
    const multiplier = reverse ? 1 : -1;
    return topPx + pixelOffset * multiplier;
  }
  setRowTop(pixels) {
    if (this.printLayout) {
      return;
    }
    if (_exists(pixels)) {
      const afterPaginationPixels = this.applyPaginationOffset(pixels);
      const skipScaling = this.rowNode.isRowPinned() || this.rowNode.sticky;
      const afterScalingPixels = skipScaling ? afterPaginationPixels : this.beans.rowContainerHeightService.getRealPixelPosition(afterPaginationPixels);
      const topPx = `${afterScalingPixels}px`;
      this.setRowTopStyle(topPx);
    }
  }
  // the top needs to be set into the DOM element when the element is created, not updated afterwards.
  // otherwise the transition would not work, as it would be transitioning from zero (the unset value).
  // for example, suppose a row that is outside the viewport, then user does a filter to remove other rows
  // and this row now appears in the viewport, and the row moves up (ie it was under the viewport and not rendered,
  // but now is in the viewport) then a new RowComp is created, however it should have it's position initialised
  // to below the viewport, so the row will appear to animate up. if we didn't set the initial position at creation
  // time, the row would animate down (ie from position zero).
  getInitialRowTop(rowContainerType) {
    return this.suppressRowTransform ? this.getInitialRowTopShared(rowContainerType) : void 0;
  }
  getInitialTransform(rowContainerType) {
    return this.suppressRowTransform ? void 0 : `translateY(${this.getInitialRowTopShared(rowContainerType)})`;
  }
  getInitialRowTopShared(rowContainerType) {
    if (this.printLayout) {
      return "";
    }
    const rowNode = this.rowNode;
    let rowTop;
    if (this.isSticky()) {
      rowTop = rowNode.stickyRowTop;
    } else {
      const pixels = this.slideInAnimation[rowContainerType] ? this.roundRowTopToBounds(rowNode.oldRowTop) : rowNode.rowTop;
      const afterPaginationPixels = this.applyPaginationOffset(pixels);
      rowTop = rowNode.isRowPinned() ? afterPaginationPixels : this.beans.rowContainerHeightService.getRealPixelPosition(afterPaginationPixels);
    }
    return rowTop + "px";
  }
  setRowTopStyle(topPx) {
    this.allRowGuis.forEach(
      (gui) => this.suppressRowTransform ? gui.rowComp.setTop(topPx) : gui.rowComp.setTransform(`translateY(${topPx})`)
    );
  }
  getRowNode() {
    return this.rowNode;
  }
  getCellCtrl(column) {
    let res = null;
    this.getAllCellCtrls().forEach((cellCtrl) => {
      if (cellCtrl.getColumn() == column) {
        res = cellCtrl;
      }
    });
    if (res != null) {
      return res;
    }
    this.getAllCellCtrls().forEach((cellCtrl) => {
      if (cellCtrl.getColSpanningList().indexOf(column) >= 0) {
        res = cellCtrl;
      }
    });
    return res;
  }
  onRowIndexChanged() {
    if (this.rowNode.rowIndex != null) {
      this.onCellFocusChanged();
      this.updateRowIndexes();
      this.postProcessCss();
    }
  }
  getRowIndex() {
    return this.rowNode.getRowIndexString();
  }
  updateRowIndexes(gui) {
    const rowIndexStr = this.rowNode.getRowIndexString();
    if (rowIndexStr === null) {
      return;
    }
    const headerRowCount = this.beans.headerNavigationService.getHeaderRowCount() + (this.beans.filterManager?.getHeaderRowCount() ?? 0);
    const rowIsEven = this.rowNode.rowIndex % 2 === 0;
    const ariaRowIndex = headerRowCount + this.rowNode.rowIndex + 1;
    this.forEachGui(gui, (c) => {
      c.rowComp.setRowIndex(rowIndexStr);
      c.rowComp.addOrRemoveCssClass("ag-row-even", rowIsEven);
      c.rowComp.addOrRemoveCssClass("ag-row-odd", !rowIsEven);
      _setAriaRowIndex(c.element, ariaRowIndex);
    });
  }
  setStoppingRowEdit(stoppingRowEdit) {
    this.stoppingRowEdit = stoppingRowEdit;
  }
};
_RowCtrl.DOM_DATA_KEY_ROW_CTRL = "renderedRow";
var RowCtrl = _RowCtrl;

// community-modules/core/src/gridBodyComp/rowContainer/rowContainerEventsFeature.ts
var RowContainerEventsFeature = class extends BeanStub {
  wireBeans(beans) {
    this.mouseEventService = beans.mouseEventService;
    this.valueService = beans.valueService;
    this.menuService = beans.menuService;
    this.ctrlsService = beans.ctrlsService;
    this.navigationService = beans.navigationService;
    this.focusService = beans.focusService;
    this.undoRedoService = beans.undoRedoService;
    this.visibleColsService = beans.visibleColsService;
    this.rowModel = beans.rowModel;
    this.pinnedRowModel = beans.pinnedRowModel;
    this.rangeService = beans.rangeService;
    this.clipboardService = beans.clipboardService;
  }
  constructor(element) {
    super();
    this.element = element;
  }
  postConstruct() {
    this.addKeyboardListeners();
    this.addMouseListeners();
    this.mockContextMenuForIPad();
  }
  addKeyboardListeners() {
    const eventName = "keydown";
    const listener = this.processKeyboardEvent.bind(this, eventName);
    this.addManagedElementListeners(this.element, { [eventName]: listener });
  }
  addMouseListeners() {
    const mouseDownEvent = _isEventSupported("touchstart") ? "touchstart" : "mousedown";
    const eventNames = ["dblclick", "contextmenu", "mouseover", "mouseout", "click", mouseDownEvent];
    eventNames.forEach((eventName) => {
      const listener = this.processMouseEvent.bind(this, eventName);
      this.addManagedElementListeners(this.element, { [eventName]: listener });
    });
  }
  processMouseEvent(eventName, mouseEvent) {
    if (!this.mouseEventService.isEventFromThisGrid(mouseEvent) || _isStopPropagationForAgGrid(mouseEvent)) {
      return;
    }
    const rowComp = this.getRowForEvent(mouseEvent);
    const cellCtrl = this.mouseEventService.getRenderedCellForEvent(mouseEvent);
    if (eventName === "contextmenu") {
      this.handleContextMenuMouseEvent(mouseEvent, void 0, rowComp, cellCtrl);
    } else {
      if (cellCtrl) {
        cellCtrl.onMouseEvent(eventName, mouseEvent);
      }
      if (rowComp) {
        rowComp.onMouseEvent(eventName, mouseEvent);
      }
    }
  }
  mockContextMenuForIPad() {
    if (!_isIOSUserAgent()) {
      return;
    }
    const touchListener = new TouchListener(this.element);
    const longTapListener = (event) => {
      const rowComp = this.getRowForEvent(event.touchEvent);
      const cellComp = this.mouseEventService.getRenderedCellForEvent(event.touchEvent);
      this.handleContextMenuMouseEvent(void 0, event.touchEvent, rowComp, cellComp);
    };
    this.addManagedListeners(touchListener, { longTap: longTapListener });
    this.addDestroyFunc(() => touchListener.destroy());
  }
  getRowForEvent(event) {
    let sourceElement = event.target;
    while (sourceElement) {
      const rowCon = this.gos.getDomData(sourceElement, RowCtrl.DOM_DATA_KEY_ROW_CTRL);
      if (rowCon) {
        return rowCon;
      }
      sourceElement = sourceElement.parentElement;
    }
    return null;
  }
  handleContextMenuMouseEvent(mouseEvent, touchEvent, rowComp, cellCtrl) {
    const rowNode = rowComp ? rowComp.getRowNode() : null;
    const column = cellCtrl ? cellCtrl.getColumn() : null;
    let value = null;
    if (column) {
      const event = mouseEvent ? mouseEvent : touchEvent;
      cellCtrl.dispatchCellContextMenuEvent(event ?? null);
      value = this.valueService.getValue(column, rowNode);
    }
    const gridBodyCon = this.ctrlsService.getGridBodyCtrl();
    const anchorToElement = cellCtrl ? cellCtrl.getGui() : gridBodyCon.getGridBodyElement();
    this.menuService.showContextMenu({
      mouseEvent,
      touchEvent,
      rowNode,
      column,
      value,
      anchorToElement
    });
  }
  getControlsForEventTarget(target) {
    return {
      cellCtrl: _getCtrlForEventTarget(this.gos, target, CellCtrl.DOM_DATA_KEY_CELL_CTRL),
      rowCtrl: _getCtrlForEventTarget(this.gos, target, RowCtrl.DOM_DATA_KEY_ROW_CTRL)
    };
  }
  processKeyboardEvent(eventName, keyboardEvent) {
    const { cellCtrl, rowCtrl } = this.getControlsForEventTarget(keyboardEvent.target);
    if (keyboardEvent.defaultPrevented) {
      return;
    }
    if (cellCtrl) {
      this.processCellKeyboardEvent(cellCtrl, eventName, keyboardEvent);
    } else if (rowCtrl && rowCtrl.isFullWidth()) {
      this.processFullWidthRowKeyboardEvent(rowCtrl, eventName, keyboardEvent);
    }
  }
  processCellKeyboardEvent(cellCtrl, eventName, keyboardEvent) {
    const rowNode = cellCtrl.getRowNode();
    const column = cellCtrl.getColumn();
    const editing = cellCtrl.isEditing();
    const gridProcessingAllowed = !_isUserSuppressingKeyboardEvent(
      this.gos,
      keyboardEvent,
      rowNode,
      column,
      editing
    );
    if (gridProcessingAllowed) {
      if (eventName === "keydown") {
        const wasScrollKey = !editing && this.navigationService.handlePageScrollingKey(keyboardEvent);
        if (!wasScrollKey) {
          cellCtrl.onKeyDown(keyboardEvent);
        }
        this.doGridOperations(keyboardEvent, cellCtrl.isEditing());
        if (_isEventFromPrintableCharacter(keyboardEvent)) {
          cellCtrl.processCharacter(keyboardEvent);
        }
      }
    }
    if (eventName === "keydown") {
      const cellKeyDownEvent = cellCtrl.createEvent(keyboardEvent, "cellKeyDown");
      this.eventService.dispatchEvent(cellKeyDownEvent);
    }
  }
  processFullWidthRowKeyboardEvent(rowComp, eventName, keyboardEvent) {
    const rowNode = rowComp.getRowNode();
    const focusedCell = this.focusService.getFocusedCell();
    const column = focusedCell && focusedCell.column;
    const gridProcessingAllowed = !_isUserSuppressingKeyboardEvent(this.gos, keyboardEvent, rowNode, column, false);
    if (gridProcessingAllowed) {
      const key = keyboardEvent.key;
      if (eventName === "keydown") {
        switch (key) {
          case KeyCode.PAGE_HOME:
          case KeyCode.PAGE_END:
          case KeyCode.PAGE_UP:
          case KeyCode.PAGE_DOWN:
            this.navigationService.handlePageScrollingKey(keyboardEvent, true);
            break;
          case KeyCode.UP:
          case KeyCode.DOWN:
            rowComp.onKeyboardNavigate(keyboardEvent);
            break;
          case KeyCode.TAB:
            rowComp.onTabKeyDown(keyboardEvent);
            break;
          default:
        }
      }
    }
    if (eventName === "keydown") {
      const cellKeyDownEvent = rowComp.createRowEvent("cellKeyDown", keyboardEvent);
      this.eventService.dispatchEvent(cellKeyDownEvent);
    }
  }
  doGridOperations(keyboardEvent, editing) {
    if (!keyboardEvent.ctrlKey && !keyboardEvent.metaKey) {
      return;
    }
    if (editing) {
      return;
    }
    if (!this.mouseEventService.isEventFromThisGrid(keyboardEvent)) {
      return;
    }
    const keyCode = _normaliseQwertyAzerty(keyboardEvent);
    if (keyCode === KeyCode.A) {
      return this.onCtrlAndA(keyboardEvent);
    }
    if (keyCode === KeyCode.C) {
      return this.onCtrlAndC(keyboardEvent);
    }
    if (keyCode === KeyCode.D) {
      return this.onCtrlAndD(keyboardEvent);
    }
    if (keyCode === KeyCode.V) {
      return this.onCtrlAndV(keyboardEvent);
    }
    if (keyCode === KeyCode.X) {
      return this.onCtrlAndX(keyboardEvent);
    }
    if (keyCode === KeyCode.Y) {
      return this.onCtrlAndY();
    }
    if (keyCode === KeyCode.Z) {
      return this.onCtrlAndZ(keyboardEvent);
    }
  }
  onCtrlAndA(event) {
    const { pinnedRowModel, rowModel, rangeService } = this;
    if (rangeService && rowModel.isRowsToRender()) {
      const [isEmptyPinnedTop, isEmptyPinnedBottom] = [
        pinnedRowModel.isEmpty("top"),
        pinnedRowModel.isEmpty("bottom")
      ];
      const floatingStart = isEmptyPinnedTop ? null : "top";
      let floatingEnd;
      let rowEnd;
      if (isEmptyPinnedBottom) {
        floatingEnd = null;
        rowEnd = rowModel.getRowCount() - 1;
      } else {
        floatingEnd = "bottom";
        rowEnd = pinnedRowModel.getPinnedBottomRowNodes().length - 1;
      }
      const allDisplayedColumns = this.visibleColsService.getAllCols();
      if (_missingOrEmpty(allDisplayedColumns)) {
        return;
      }
      rangeService.setCellRange({
        rowStartIndex: 0,
        rowStartPinned: floatingStart,
        rowEndIndex: rowEnd,
        rowEndPinned: floatingEnd,
        columnStart: allDisplayedColumns[0],
        columnEnd: _last(allDisplayedColumns)
      });
    }
    event.preventDefault();
  }
  onCtrlAndC(event) {
    if (!this.clipboardService || this.gos.get("enableCellTextSelection")) {
      return;
    }
    const { cellCtrl, rowCtrl } = this.getControlsForEventTarget(event.target);
    if (cellCtrl?.isEditing() || rowCtrl?.isEditing()) {
      return;
    }
    event.preventDefault();
    this.clipboardService.copyToClipboard();
  }
  onCtrlAndX(event) {
    if (!this.clipboardService || this.gos.get("enableCellTextSelection") || this.gos.get("suppressCutToClipboard")) {
      return;
    }
    const { cellCtrl, rowCtrl } = this.getControlsForEventTarget(event.target);
    if (cellCtrl?.isEditing() || rowCtrl?.isEditing()) {
      return;
    }
    event.preventDefault();
    this.clipboardService.cutToClipboard(void 0, "ui");
  }
  onCtrlAndV(event) {
    const { cellCtrl, rowCtrl } = this.getControlsForEventTarget(event.target);
    if (cellCtrl?.isEditing() || rowCtrl?.isEditing()) {
      return;
    }
    if (this.clipboardService && !this.gos.get("suppressClipboardPaste")) {
      this.clipboardService.pasteFromClipboard();
    }
  }
  onCtrlAndD(event) {
    if (this.clipboardService && !this.gos.get("suppressClipboardPaste")) {
      this.clipboardService.copyRangeDown();
    }
    event.preventDefault();
  }
  onCtrlAndZ(event) {
    if (!this.gos.get("undoRedoCellEditing") || !this.undoRedoService) {
      return;
    }
    event.preventDefault();
    if (event.shiftKey) {
      this.undoRedoService.redo("ui");
    } else {
      this.undoRedoService.undo("ui");
    }
  }
  onCtrlAndY() {
    this.undoRedoService?.redo("ui");
  }
};

// community-modules/core/src/gridBodyComp/rowContainer/setPinnedLeftWidthFeature.ts
var SetPinnedLeftWidthFeature = class extends BeanStub {
  wireBeans(beans) {
    this.pinnedWidthService = beans.pinnedWidthService;
  }
  constructor(element) {
    super();
    this.element = element;
  }
  postConstruct() {
    this.addManagedEventListeners({ leftPinnedWidthChanged: this.onPinnedLeftWidthChanged.bind(this) });
  }
  onPinnedLeftWidthChanged() {
    const leftWidth = this.pinnedWidthService.getPinnedLeftWidth();
    const displayed = leftWidth > 0;
    _setDisplayed(this.element, displayed);
    _setFixedWidth(this.element, leftWidth);
  }
  getWidth() {
    return this.pinnedWidthService.getPinnedLeftWidth();
  }
};

// community-modules/core/src/gridBodyComp/rowContainer/setPinnedRightWidthFeature.ts
var SetPinnedRightWidthFeature = class extends BeanStub {
  wireBeans(beans) {
    this.pinnedWidthService = beans.pinnedWidthService;
  }
  constructor(element) {
    super();
    this.element = element;
  }
  postConstruct() {
    this.addManagedEventListeners({
      rightPinnedWidthChanged: this.onPinnedRightWidthChanged.bind(this)
    });
  }
  onPinnedRightWidthChanged() {
    const rightWidth = this.pinnedWidthService.getPinnedRightWidth();
    const displayed = rightWidth > 0;
    _setDisplayed(this.element, displayed);
    _setFixedWidth(this.element, rightWidth);
  }
  getWidth() {
    return this.pinnedWidthService.getPinnedRightWidth();
  }
};

// community-modules/core/src/gridBodyComp/rowContainer/rowContainerCtrl.ts
var getTopRowCtrls = (r) => r.getTopRowCtrls();
var getStickyTopRowCtrls = (r) => r.getStickyTopRowCtrls();
var getStickyBottomRowCtrls = (r) => r.getStickyBottomRowCtrls();
var getBottomRowCtrls = (r) => r.getBottomRowCtrls();
var getCentreRowCtrls = (r) => r.getCentreRowCtrls();
var ContainerCssClasses = {
  center: {
    type: "center",
    container: "ag-center-cols-container",
    viewport: "ag-center-cols-viewport",
    getRowCtrls: getCentreRowCtrls
  },
  left: {
    type: "left",
    container: "ag-pinned-left-cols-container",
    pinnedType: "left",
    getRowCtrls: getCentreRowCtrls
  },
  right: {
    type: "right",
    container: "ag-pinned-right-cols-container",
    pinnedType: "right",
    getRowCtrls: getCentreRowCtrls
  },
  fullWidth: {
    type: "fullWidth",
    container: "ag-full-width-container",
    fullWidth: true,
    getRowCtrls: getCentreRowCtrls
  },
  topCenter: {
    type: "center",
    container: "ag-floating-top-container",
    viewport: "ag-floating-top-viewport",
    getRowCtrls: getTopRowCtrls
  },
  topLeft: {
    type: "left",
    container: "ag-pinned-left-floating-top",
    pinnedType: "left",
    getRowCtrls: getTopRowCtrls
  },
  topRight: {
    type: "right",
    container: "ag-pinned-right-floating-top",
    pinnedType: "right",
    getRowCtrls: getTopRowCtrls
  },
  topFullWidth: {
    type: "fullWidth",
    container: "ag-floating-top-full-width-container",
    fullWidth: true,
    getRowCtrls: getTopRowCtrls
  },
  stickyTopCenter: {
    type: "center",
    container: "ag-sticky-top-container",
    viewport: "ag-sticky-top-viewport",
    getRowCtrls: getStickyTopRowCtrls
  },
  stickyTopLeft: {
    type: "left",
    container: "ag-pinned-left-sticky-top",
    pinnedType: "left",
    getRowCtrls: getStickyTopRowCtrls
  },
  stickyTopRight: {
    type: "right",
    container: "ag-pinned-right-sticky-top",
    pinnedType: "right",
    getRowCtrls: getStickyTopRowCtrls
  },
  stickyTopFullWidth: {
    type: "fullWidth",
    container: "ag-sticky-top-full-width-container",
    fullWidth: true,
    getRowCtrls: getStickyTopRowCtrls
  },
  stickyBottomCenter: {
    type: "center",
    container: "ag-sticky-bottom-container",
    viewport: "ag-sticky-bottom-viewport",
    getRowCtrls: getStickyBottomRowCtrls
  },
  stickyBottomLeft: {
    type: "left",
    container: "ag-pinned-left-sticky-bottom",
    pinnedType: "left",
    getRowCtrls: getStickyBottomRowCtrls
  },
  stickyBottomRight: {
    type: "right",
    container: "ag-pinned-right-sticky-bottom",
    pinnedType: "right",
    getRowCtrls: getStickyBottomRowCtrls
  },
  stickyBottomFullWidth: {
    type: "fullWidth",
    container: "ag-sticky-bottom-full-width-container",
    fullWidth: true,
    getRowCtrls: getStickyBottomRowCtrls
  },
  bottomCenter: {
    type: "center",
    container: "ag-floating-bottom-container",
    viewport: "ag-floating-bottom-viewport",
    getRowCtrls: getBottomRowCtrls
  },
  bottomLeft: {
    type: "left",
    container: "ag-pinned-left-floating-bottom",
    pinnedType: "left",
    getRowCtrls: getBottomRowCtrls
  },
  bottomRight: {
    type: "right",
    container: "ag-pinned-right-floating-bottom",
    pinnedType: "right",
    getRowCtrls: getBottomRowCtrls
  },
  bottomFullWidth: {
    type: "fullWidth",
    container: "ag-floating-bottom-full-width-container",
    fullWidth: true,
    getRowCtrls: getBottomRowCtrls
  }
};
function _getRowContainerOptions(name) {
  return ContainerCssClasses[name];
}
var allTopNoFW = ["topCenter", "topLeft", "topRight"];
var allBottomNoFW = ["bottomCenter", "bottomLeft", "bottomRight"];
var allMiddleNoFW = ["center", "left", "right"];
var allMiddle = ["center", "left", "right", "fullWidth"];
var allCenter = ["stickyTopCenter", "stickyBottomCenter", "center", "topCenter", "bottomCenter"];
var allLeft = ["left", "bottomLeft", "topLeft", "stickyTopLeft", "stickyBottomLeft"];
var allRight = ["right", "bottomRight", "topRight", "stickyTopRight", "stickyBottomRight"];
var allStickyTopNoFW = ["stickyTopCenter", "stickyTopLeft", "stickyTopRight"];
var allStickyBottomNoFW = ["stickyBottomCenter", "stickyBottomLeft", "stickyBottomRight"];
var allStickyContainers = [
  ...allStickyTopNoFW,
  "stickyTopFullWidth",
  ...allStickyBottomNoFW,
  "stickyBottomFullWidth"
];
var allNoFW = [
  ...allTopNoFW,
  ...allBottomNoFW,
  ...allMiddleNoFW,
  ...allStickyTopNoFW,
  ...allStickyBottomNoFW
];
var RowContainerCtrl = class extends BeanStub {
  constructor(name) {
    super();
    this.visible = true;
    // Maintaining a constant reference enables optimization in React.
    this.EMPTY_CTRLS = [];
    this.name = name;
    this.options = _getRowContainerOptions(name);
  }
  wireBeans(beans) {
    this.dragService = beans.dragService;
    this.ctrlsService = beans.ctrlsService;
    this.columnViewportService = beans.columnViewportService;
    this.resizeObserverService = beans.resizeObserverService;
    this.rowRenderer = beans.rowRenderer;
  }
  postConstruct() {
    this.enableRtl = this.gos.get("enableRtl");
    this.forContainers(["center"], () => {
      this.viewportSizeFeature = this.createManagedBean(new ViewportSizeFeature(this));
      this.addManagedEventListeners({
        stickyTopOffsetChanged: this.onStickyTopOffsetChanged.bind(this)
      });
    });
  }
  onStickyTopOffsetChanged(event) {
    this.comp.setOffsetTop(`${event.offset}px`);
  }
  registerWithCtrlsService() {
    if (this.options.fullWidth)
      return;
    this.ctrlsService.register(this.name, this);
  }
  forContainers(names, callback) {
    if (names.indexOf(this.name) >= 0) {
      callback();
    }
  }
  getContainerElement() {
    return this.eContainer;
  }
  getViewportSizeFeature() {
    return this.viewportSizeFeature;
  }
  setComp(view, eContainer, eViewport) {
    this.comp = view;
    this.eContainer = eContainer;
    this.eViewport = eViewport;
    this.createManagedBean(new RowContainerEventsFeature(this.eContainer));
    this.addPreventScrollWhileDragging();
    this.listenOnDomOrder();
    this.stopHScrollOnPinnedRows();
    this.forContainers(allLeft, () => {
      this.pinnedWidthFeature = this.createManagedBean(new SetPinnedLeftWidthFeature(this.eContainer));
      this.addManagedEventListeners({ leftPinnedWidthChanged: () => this.onPinnedWidthChanged() });
    });
    this.forContainers(allRight, () => {
      this.pinnedWidthFeature = this.createManagedBean(new SetPinnedRightWidthFeature(this.eContainer));
      this.addManagedEventListeners({ rightPinnedWidthChanged: () => this.onPinnedWidthChanged() });
    });
    this.forContainers(
      allMiddle,
      () => this.createManagedBean(
        new SetHeightFeature(this.eContainer, this.name === "center" ? eViewport : void 0)
      )
    );
    this.forContainers(allNoFW, () => this.createManagedBean(new DragListenerFeature(this.eContainer)));
    this.forContainers(
      allCenter,
      () => this.createManagedBean(new CenterWidthFeature((width) => this.comp.setContainerWidth(`${width}px`)))
    );
    this.addListeners();
    this.registerWithCtrlsService();
  }
  addListeners() {
    this.addManagedEventListeners({
      displayedColumnsChanged: this.onDisplayedColumnsChanged.bind(this),
      displayedColumnsWidthChanged: this.onDisplayedColumnsWidthChanged.bind(this),
      displayedRowsChanged: (params) => this.onDisplayedRowsChanged(params.afterScroll)
    });
    this.onDisplayedColumnsChanged();
    this.onDisplayedColumnsWidthChanged();
    this.onDisplayedRowsChanged();
  }
  listenOnDomOrder() {
    const isStickContainer = allStickyContainers.indexOf(this.name) >= 0;
    if (isStickContainer) {
      this.comp.setDomOrder(true);
      return;
    }
    const listener = () => {
      const isEnsureDomOrder = this.gos.get("ensureDomOrder");
      const isPrintLayout = this.gos.isDomLayout("print");
      this.comp.setDomOrder(isEnsureDomOrder || isPrintLayout);
    };
    this.addManagedPropertyListener("domLayout", listener);
    listener();
  }
  // when editing a pinned row, if the cell is half outside the scrollable area, the browser can
  // scroll the column into view. we do not want this, the pinned sections should never scroll.
  // so we listen to scrolls on these containers and reset the scroll if we find one.
  stopHScrollOnPinnedRows() {
    this.forContainers(["topCenter", "stickyTopCenter", "bottomCenter", "stickyBottomCenter"], () => {
      const resetScrollLeft = () => this.eViewport.scrollLeft = 0;
      this.addManagedElementListeners(this.eViewport, { scroll: resetScrollLeft });
    });
  }
  onDisplayedColumnsChanged() {
    this.forContainers(["center"], () => this.onHorizontalViewportChanged());
  }
  onDisplayedColumnsWidthChanged() {
    this.forContainers(["center"], () => this.onHorizontalViewportChanged());
  }
  // this methods prevents the grid views from being scrolled while the dragService is being used
  // eg. the view should not scroll up and down while dragging rows using the rowDragComp.
  addPreventScrollWhileDragging() {
    const preventScroll = (e) => {
      if (this.dragService.isDragging()) {
        if (e.cancelable) {
          e.preventDefault();
        }
      }
    };
    this.eContainer.addEventListener("touchmove", preventScroll, { passive: false });
    this.addDestroyFunc(() => this.eContainer.removeEventListener("touchmove", preventScroll));
  }
  // this gets called whenever a change in the viewport, so we can inform column controller it has to work
  // out the virtual columns again. gets called from following locations:
  // + ensureColVisible, scroll, init, layoutChanged, displayedColumnsChanged
  onHorizontalViewportChanged(afterScroll = false) {
    const scrollWidth = this.getCenterWidth();
    const scrollPosition = this.getCenterViewportScrollLeft();
    this.columnViewportService.setScrollPosition(scrollWidth, scrollPosition, afterScroll);
  }
  getCenterWidth() {
    return _getInnerWidth(this.eViewport);
  }
  getCenterViewportScrollLeft() {
    return _getScrollLeft(this.eViewport, this.enableRtl);
  }
  registerViewportResizeListener(listener) {
    const unsubscribeFromResize = this.resizeObserverService.observeResize(this.eViewport, listener);
    this.addDestroyFunc(() => unsubscribeFromResize());
  }
  isViewportInTheDOMTree() {
    return _isInDOM(this.eViewport);
  }
  getViewportScrollLeft() {
    return _getScrollLeft(this.eViewport, this.enableRtl);
  }
  isHorizontalScrollShowing() {
    const isAlwaysShowHorizontalScroll = this.gos.get("alwaysShowHorizontalScroll");
    return isAlwaysShowHorizontalScroll || _isHorizontalScrollShowing(this.eViewport);
  }
  getViewportElement() {
    return this.eViewport;
  }
  setContainerTranslateX(amount) {
    this.eContainer.style.transform = `translateX(${amount}px)`;
  }
  getHScrollPosition() {
    const res = {
      left: this.eViewport.scrollLeft,
      right: this.eViewport.scrollLeft + this.eViewport.offsetWidth
    };
    return res;
  }
  setCenterViewportScrollLeft(value) {
    _setScrollLeft(this.eViewport, value, this.enableRtl);
  }
  isContainerVisible() {
    const pinned = this.options.pinnedType != null;
    return !pinned || !!this.pinnedWidthFeature && this.pinnedWidthFeature.getWidth() > 0;
  }
  onPinnedWidthChanged() {
    const visible = this.isContainerVisible();
    if (this.visible != visible) {
      this.visible = visible;
      this.onDisplayedRowsChanged();
    }
  }
  onDisplayedRowsChanged(afterScroll = false) {
    const rows = this.options.getRowCtrls(this.rowRenderer);
    if (!this.visible || rows.length === 0) {
      this.comp.setRowCtrls({ rowCtrls: this.EMPTY_CTRLS });
      return;
    }
    const printLayout = this.gos.isDomLayout("print");
    const embedFullWidthRows = this.gos.get("embedFullWidthRows");
    const embedFW = embedFullWidthRows || printLayout;
    const rowsThisContainer = rows.filter((rowCtrl) => {
      const fullWidthRow = rowCtrl.isFullWidth();
      const match = this.options.fullWidth ? !embedFW && fullWidthRow : embedFW || !fullWidthRow;
      return match;
    });
    this.comp.setRowCtrls({ rowCtrls: rowsThisContainer, useFlushSync: afterScroll });
  }
};

// community-modules/core/src/gridBodyComp/rowContainer/rowContainerComp.ts
function templateFactory(options) {
  let res;
  if (options.type === "center") {
    res = /* html */
    `<div class="${options.viewport}" data-ref="eViewport" role="presentation">
                <div class="${options.container}" data-ref="eContainer"></div>
            </div>`;
  } else {
    res = /* html */
    `<div class="${options.container}" data-ref="eContainer"></div>`;
  }
  return res;
}
var RowContainerComp = class extends Component {
  constructor() {
    super();
    this.eViewport = RefPlaceholder;
    this.eContainer = RefPlaceholder;
    this.rowComps = {};
    this.name = Component.elementGettingCreated.getAttribute("name");
    this.options = _getRowContainerOptions(this.name);
    this.setTemplate(templateFactory(this.options));
  }
  wireBeans(beans) {
    this.beans = beans;
  }
  postConstruct() {
    const compProxy = {
      setViewportHeight: (height) => this.eViewport.style.height = height,
      setRowCtrls: ({ rowCtrls }) => this.setRowCtrls(rowCtrls),
      setDomOrder: (domOrder) => {
        this.domOrder = domOrder;
      },
      setContainerWidth: (width) => this.eContainer.style.width = width,
      setOffsetTop: (offset) => this.eContainer.style.transform = `translateY(${offset})`
    };
    const ctrl = this.createManagedBean(new RowContainerCtrl(this.name));
    ctrl.setComp(compProxy, this.eContainer, this.eViewport);
  }
  destroy() {
    this.setRowCtrls([]);
    super.destroy();
  }
  setRowCtrls(rowCtrls) {
    const oldRows = { ...this.rowComps };
    this.rowComps = {};
    this.lastPlacedElement = null;
    const processRow = (rowCon) => {
      const instanceId = rowCon.getInstanceId();
      const existingRowComp = oldRows[instanceId];
      if (existingRowComp) {
        this.rowComps[instanceId] = existingRowComp;
        delete oldRows[instanceId];
        this.ensureDomOrder(existingRowComp.getGui());
      } else {
        if (!rowCon.getRowNode().displayed) {
          return;
        }
        const rowComp = new RowComp(rowCon, this.beans, this.options.type);
        this.rowComps[instanceId] = rowComp;
        this.appendRow(rowComp.getGui());
      }
    };
    rowCtrls.forEach(processRow);
    _getAllValuesInObject(oldRows).forEach((oldRowComp) => {
      this.eContainer.removeChild(oldRowComp.getGui());
      oldRowComp.destroy();
    });
    _setAriaRole(this.eContainer, "rowgroup");
  }
  appendRow(element) {
    if (this.domOrder) {
      _insertWithDomOrder(this.eContainer, element, this.lastPlacedElement);
    } else {
      this.eContainer.appendChild(element);
    }
    this.lastPlacedElement = element;
  }
  ensureDomOrder(eRow) {
    if (this.domOrder) {
      _ensureDomOrder(this.eContainer, eRow, this.lastPlacedElement);
      this.lastPlacedElement = eRow;
    }
  }
};
var RowContainerSelector = {
  selector: "AG-ROW-CONTAINER",
  component: RowContainerComp
};

// community-modules/core/src/gridBodyComp/gridBodyComp.ts
function makeRowContainers(names) {
  return names.map((name) => `<ag-row-container name="${name}"></ag-row-container>`).join("");
}
var GRID_BODY_TEMPLATE = (
  /* html */
  `<div class="ag-root ag-unselectable" role="treegrid">
        <ag-header-root></ag-header-root>
        <div class="ag-floating-top" data-ref="eTop" role="presentation">
            ${makeRowContainers(["topLeft", "topCenter", "topRight", "topFullWidth"])}
        </div>
        <div class="ag-body" data-ref="eBody" role="presentation">
            <div class="ag-body-viewport" data-ref="eBodyViewport" role="presentation">
            ${makeRowContainers(["left", "center", "right", "fullWidth"])}
            </div>
            <ag-fake-vertical-scroll></ag-fake-vertical-scroll>
        </div>
        <div class="ag-sticky-top" data-ref="eStickyTop" role="presentation">
            ${makeRowContainers(["stickyTopLeft", "stickyTopCenter", "stickyTopRight", "stickyTopFullWidth"])}
        </div>
        <div class="ag-sticky-bottom" data-ref="eStickyBottom" role="presentation">
            ${makeRowContainers(["stickyBottomLeft", "stickyBottomCenter", "stickyBottomRight", "stickyBottomFullWidth"])}
        </div>
        <div class="ag-floating-bottom" data-ref="eBottom" role="presentation">
            ${makeRowContainers(["bottomLeft", "bottomCenter", "bottomRight", "bottomFullWidth"])}
        </div>
        <ag-fake-horizontal-scroll></ag-fake-horizontal-scroll>
        <ag-overlay-wrapper></ag-overlay-wrapper>
    </div>`
);
var GridBodyComp = class extends Component {
  constructor() {
    super(GRID_BODY_TEMPLATE, [
      OverlayWrapperSelector,
      FakeHScrollSelector,
      FakeVScrollSelector,
      GridHeaderSelector,
      RowContainerSelector
    ]);
    this.eBodyViewport = RefPlaceholder;
    this.eStickyTop = RefPlaceholder;
    this.eStickyBottom = RefPlaceholder;
    this.eTop = RefPlaceholder;
    this.eBottom = RefPlaceholder;
    this.eBody = RefPlaceholder;
  }
  wireBeans(beans) {
    this.resizeObserverService = beans.resizeObserverService;
    this.rangeService = beans.rangeService;
  }
  postConstruct() {
    const setHeight = (height, element) => {
      const heightString = `${height}px`;
      element.style.minHeight = heightString;
      element.style.height = heightString;
    };
    const compProxy = {
      setRowAnimationCssOnBodyViewport: (cssClass, animate) => this.setRowAnimationCssOnBodyViewport(cssClass, animate),
      setColumnCount: (count) => _setAriaColCount(this.getGui(), count),
      setRowCount: (count) => _setAriaRowCount(this.getGui(), count),
      setTopHeight: (height) => setHeight(height, this.eTop),
      setBottomHeight: (height) => setHeight(height, this.eBottom),
      setTopDisplay: (display) => this.eTop.style.display = display,
      setBottomDisplay: (display) => this.eBottom.style.display = display,
      setStickyTopHeight: (height) => this.eStickyTop.style.height = height,
      setStickyTopTop: (top) => this.eStickyTop.style.top = top,
      setStickyTopWidth: (width) => this.eStickyTop.style.width = width,
      setStickyBottomHeight: (height) => this.eStickyBottom.style.height = height,
      setStickyBottomBottom: (bottom) => this.eStickyBottom.style.bottom = bottom,
      setStickyBottomWidth: (width) => this.eStickyBottom.style.width = width,
      setColumnMovingCss: (cssClass, flag) => this.addOrRemoveCssClass(cssClass, flag),
      updateLayoutClasses: (cssClass, params) => {
        const classLists = [this.eBodyViewport.classList, this.eBody.classList];
        classLists.forEach((classList) => {
          classList.toggle("ag-layout-auto-height" /* AUTO_HEIGHT */, params.autoHeight);
          classList.toggle("ag-layout-normal" /* NORMAL */, params.normal);
          classList.toggle("ag-layout-print" /* PRINT */, params.print);
        });
        this.addOrRemoveCssClass("ag-layout-auto-height" /* AUTO_HEIGHT */, params.autoHeight);
        this.addOrRemoveCssClass("ag-layout-normal" /* NORMAL */, params.normal);
        this.addOrRemoveCssClass("ag-layout-print" /* PRINT */, params.print);
      },
      setAlwaysVerticalScrollClass: (cssClass, on) => this.eBodyViewport.classList.toggle(CSS_CLASS_FORCE_VERTICAL_SCROLL, on),
      registerBodyViewportResizeListener: (listener) => {
        const unsubscribeFromResize = this.resizeObserverService.observeResize(this.eBodyViewport, listener);
        this.addDestroyFunc(() => unsubscribeFromResize());
      },
      setPinnedTopBottomOverflowY: (overflow) => this.eTop.style.overflowY = this.eBottom.style.overflowY = overflow,
      setCellSelectableCss: (cssClass, selectable) => {
        [this.eTop, this.eBodyViewport, this.eBottom].forEach(
          (ct) => ct.classList.toggle(cssClass, selectable)
        );
      },
      setBodyViewportWidth: (width) => this.eBodyViewport.style.width = width
    };
    this.ctrl = this.createManagedBean(new GridBodyCtrl());
    this.ctrl.setComp(
      compProxy,
      this.getGui(),
      this.eBodyViewport,
      this.eTop,
      this.eBottom,
      this.eStickyTop,
      this.eStickyBottom
    );
    if (this.rangeService && this.gos.get("enableRangeSelection") || this.gos.get("rowSelection") === "multiple") {
      _setAriaMultiSelectable(this.getGui(), true);
    }
  }
  setRowAnimationCssOnBodyViewport(cssClass, animateRows) {
    const bodyViewportClassList = this.eBodyViewport.classList;
    bodyViewportClassList.toggle("ag-row-animation", animateRows);
    bodyViewportClassList.toggle("ag-row-no-animation", !animateRows);
  }
  getFloatingTopBottom() {
    return [this.eTop, this.eBottom];
  }
};
var GridBodySelector = {
  selector: "AG-GRID-BODY",
  component: GridBodyComp
};

// community-modules/core/src/gridBodyComp/scrollVisibleService.ts
var ScrollVisibleService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "scrollVisibleService";
  }
  wireBeans(beans) {
    this.ctrlsService = beans.ctrlsService;
    this.columnAnimationService = beans.columnAnimationService;
  }
  postConstruct() {
    this.addManagedEventListeners({
      displayedColumnsChanged: this.onDisplayedColumnsChanged.bind(this),
      displayedColumnsWidthChanged: this.onDisplayedColumnsWidthChanged.bind(this)
    });
  }
  onDisplayedColumnsChanged() {
    this.update();
  }
  onDisplayedColumnsWidthChanged() {
    this.update();
  }
  update() {
    if (this.columnAnimationService.isActive()) {
      this.columnAnimationService.executeLaterVMTurn(() => {
        this.columnAnimationService.executeLaterVMTurn(() => this.updateImpl());
      });
    } else {
      this.updateImpl();
    }
  }
  updateImpl() {
    const centerRowCtrl = this.ctrlsService.get("center");
    if (!centerRowCtrl || this.columnAnimationService.isActive()) {
      return;
    }
    const params = {
      horizontalScrollShowing: centerRowCtrl.isHorizontalScrollShowing(),
      verticalScrollShowing: this.isVerticalScrollShowing()
    };
    this.setScrollsVisible(params);
  }
  setScrollsVisible(params) {
    const atLeastOneDifferent = this.horizontalScrollShowing !== params.horizontalScrollShowing || this.verticalScrollShowing !== params.verticalScrollShowing;
    if (atLeastOneDifferent) {
      this.horizontalScrollShowing = params.horizontalScrollShowing;
      this.verticalScrollShowing = params.verticalScrollShowing;
      const event = {
        type: "scrollVisibilityChanged"
      };
      this.eventService.dispatchEvent(event);
    }
  }
  // used by pagination service - to know page height
  isHorizontalScrollShowing() {
    return this.horizontalScrollShowing;
  }
  // used by header container
  isVerticalScrollShowing() {
    return this.verticalScrollShowing;
  }
};

// community-modules/core/src/gridBodyComp/mouseEventService.ts
var GRID_DOM_KEY = "__ag_grid_instance";
var _MouseEventService = class _MouseEventService extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "mouseEventService";
    this.gridInstanceId = _MouseEventService.gridInstanceSequence.next();
  }
  wireBeans(beans) {
    this.ctrlsService = beans.ctrlsService;
  }
  // we put the instance id onto the main DOM element. this is used for events, when grids are inside grids,
  // so the grid can work out if the even came from this grid or a grid inside this one. see the ctrl+v logic
  // for where this is used.
  stampTopLevelGridCompWithGridInstance(eGridDiv) {
    eGridDiv[GRID_DOM_KEY] = this.gridInstanceId;
  }
  getRenderedCellForEvent(event) {
    return _getCtrlForEventTarget(this.gos, event.target, CellCtrl.DOM_DATA_KEY_CELL_CTRL);
  }
  // walks the path of the event, and returns true if this grid is the first one that it finds. if doing
  // master / detail grids, and a child grid is found, then it returns false. this stops things like copy/paste
  // getting executed on many grids at the same time.
  isEventFromThisGrid(event) {
    const res = this.isElementInThisGrid(event.target);
    return res;
  }
  isElementInThisGrid(element) {
    let pointer = element;
    while (pointer) {
      const instanceId = pointer[GRID_DOM_KEY];
      if (_exists(instanceId)) {
        const eventFromThisGrid = instanceId === this.gridInstanceId;
        return eventFromThisGrid;
      }
      pointer = pointer.parentElement;
    }
    return false;
  }
  getCellPositionForEvent(event) {
    const cellComp = this.getRenderedCellForEvent(event);
    return cellComp ? cellComp.getCellPosition() : null;
  }
  getNormalisedPosition(event) {
    const gridPanelHasScrolls = this.gos.isDomLayout("normal");
    const e = event;
    let x;
    let y;
    if (e.clientX != null || e.clientY != null) {
      x = e.clientX;
      y = e.clientY;
    } else {
      x = e.x;
      y = e.y;
    }
    if (gridPanelHasScrolls) {
      const gridBodyCon = this.ctrlsService.getGridBodyCtrl();
      const vRange = gridBodyCon.getScrollFeature().getVScrollPosition();
      const hRange = gridBodyCon.getScrollFeature().getHScrollPosition();
      x += hRange.left;
      y += vRange.top;
    }
    return { x, y };
  }
};
_MouseEventService.gridInstanceSequence = new NumberSequence();
var MouseEventService = _MouseEventService;

// community-modules/core/src/gridBodyComp/navigationService.ts
var NavigationService = class extends BeanStub {
  constructor() {
    super();
    this.beanName = "navigationService";
    this.onPageDown = _throttle(this.onPageDown, 100);
    this.onPageUp = _throttle(this.onPageUp, 100);
  }
  wireBeans(beans) {
    this.mouseEventService = beans.mouseEventService;
    this.pageBoundsService = beans.pageBoundsService;
    this.focusService = beans.focusService;
    this.columnModel = beans.columnModel;
    this.visibleColsService = beans.visibleColsService;
    this.rowModel = beans.rowModel;
    this.ctrlsService = beans.ctrlsService;
    this.rowRenderer = beans.rowRenderer;
    this.headerNavigationService = beans.headerNavigationService;
    this.rowPositionUtils = beans.rowPositionUtils;
    this.cellNavigationService = beans.cellNavigationService;
    this.pinnedRowModel = beans.pinnedRowModel;
    this.rangeService = beans.rangeService;
  }
  postConstruct() {
    this.ctrlsService.whenReady((p) => {
      this.gridBodyCon = p.gridBodyCtrl;
    });
  }
  handlePageScrollingKey(event, fromFullWidth = false) {
    const key = event.key;
    const alt = event.altKey;
    const ctrl = event.ctrlKey || event.metaKey;
    const rangeServiceShouldHandleShift = !!this.rangeService && event.shiftKey;
    const currentCell = this.mouseEventService.getCellPositionForEvent(event);
    let processed = false;
    switch (key) {
      case KeyCode.PAGE_HOME:
      case KeyCode.PAGE_END:
        if (!ctrl && !alt) {
          this.onHomeOrEndKey(key);
          processed = true;
        }
        break;
      case KeyCode.LEFT:
      case KeyCode.RIGHT:
      case KeyCode.UP:
      case KeyCode.DOWN:
        if (!currentCell) {
          return false;
        }
        if (ctrl && !alt && !rangeServiceShouldHandleShift) {
          this.onCtrlUpDownLeftRight(key, currentCell);
          processed = true;
        }
        break;
      case KeyCode.PAGE_DOWN:
      case KeyCode.PAGE_UP:
        if (!ctrl && !alt) {
          processed = this.handlePageUpDown(key, currentCell, fromFullWidth);
        }
        break;
    }
    if (processed) {
      event.preventDefault();
    }
    return processed;
  }
  handlePageUpDown(key, currentCell, fromFullWidth) {
    if (fromFullWidth) {
      currentCell = this.focusService.getFocusedCell();
    }
    if (!currentCell) {
      return false;
    }
    if (key === KeyCode.PAGE_UP) {
      this.onPageUp(currentCell);
    } else {
      this.onPageDown(currentCell);
    }
    return true;
  }
  navigateTo(navigateParams) {
    const { scrollIndex, scrollType, scrollColumn, focusIndex, focusColumn } = navigateParams;
    if (_exists(scrollColumn) && !scrollColumn.isPinned()) {
      this.gridBodyCon.getScrollFeature().ensureColumnVisible(scrollColumn);
    }
    if (_exists(scrollIndex)) {
      this.gridBodyCon.getScrollFeature().ensureIndexVisible(scrollIndex, scrollType);
    }
    if (!navigateParams.isAsync) {
      this.gridBodyCon.getScrollFeature().ensureIndexVisible(focusIndex);
    }
    this.focusService.setFocusedCell({
      rowIndex: focusIndex,
      column: focusColumn,
      rowPinned: null,
      forceBrowserFocus: true
    });
    this.rangeService?.setRangeToCell({ rowIndex: focusIndex, rowPinned: null, column: focusColumn });
  }
  // this method is throttled, see the `constructor`
  onPageDown(gridCell) {
    const gridBodyCon = this.ctrlsService.getGridBodyCtrl();
    const scrollPosition = gridBodyCon.getScrollFeature().getVScrollPosition();
    const pixelsInOnePage = this.getViewportHeight();
    const pagingPixelOffset = this.pageBoundsService.getPixelOffset();
    const currentPageBottomPixel = scrollPosition.top + pixelsInOnePage;
    const currentPageBottomRow = this.rowModel.getRowIndexAtPixel(currentPageBottomPixel + pagingPixelOffset);
    if (this.columnModel.isAutoRowHeightActive()) {
      this.navigateToNextPageWithAutoHeight(gridCell, currentPageBottomRow);
    } else {
      this.navigateToNextPage(gridCell, currentPageBottomRow);
    }
  }
  // this method is throttled, see the `constructor`
  onPageUp(gridCell) {
    const gridBodyCon = this.ctrlsService.getGridBodyCtrl();
    const scrollPosition = gridBodyCon.getScrollFeature().getVScrollPosition();
    const pagingPixelOffset = this.pageBoundsService.getPixelOffset();
    const currentPageTopPixel = scrollPosition.top;
    const currentPageTopRow = this.rowModel.getRowIndexAtPixel(currentPageTopPixel + pagingPixelOffset);
    if (this.columnModel.isAutoRowHeightActive()) {
      this.navigateToNextPageWithAutoHeight(gridCell, currentPageTopRow, true);
    } else {
      this.navigateToNextPage(gridCell, currentPageTopRow, true);
    }
  }
  navigateToNextPage(gridCell, scrollIndex, up = false) {
    const pixelsInOnePage = this.getViewportHeight();
    const firstRow = this.pageBoundsService.getFirstRow();
    const lastRow = this.pageBoundsService.getLastRow();
    const pagingPixelOffset = this.pageBoundsService.getPixelOffset();
    const currentRowNode = this.rowModel.getRow(gridCell.rowIndex);
    const rowPixelDiff = up ? (
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      currentRowNode?.rowHeight - pixelsInOnePage - pagingPixelOffset
    ) : pixelsInOnePage - pagingPixelOffset;
    const nextCellPixel = currentRowNode?.rowTop + rowPixelDiff;
    let focusIndex = this.rowModel.getRowIndexAtPixel(nextCellPixel + pagingPixelOffset);
    if (focusIndex === gridCell.rowIndex) {
      const diff = up ? -1 : 1;
      scrollIndex = focusIndex = gridCell.rowIndex + diff;
    }
    let scrollType;
    if (up) {
      scrollType = "bottom";
      if (focusIndex < firstRow) {
        focusIndex = firstRow;
      }
      if (scrollIndex < firstRow) {
        scrollIndex = firstRow;
      }
    } else {
      scrollType = "top";
      if (focusIndex > lastRow) {
        focusIndex = lastRow;
      }
      if (scrollIndex > lastRow) {
        scrollIndex = lastRow;
      }
    }
    if (this.isRowTallerThanView(focusIndex)) {
      scrollIndex = focusIndex;
      scrollType = "top";
    }
    this.navigateTo({
      scrollIndex,
      scrollType,
      scrollColumn: null,
      focusIndex,
      focusColumn: gridCell.column
    });
  }
  navigateToNextPageWithAutoHeight(gridCell, scrollIndex, up = false) {
    this.navigateTo({
      scrollIndex,
      scrollType: up ? "bottom" : "top",
      scrollColumn: null,
      focusIndex: scrollIndex,
      focusColumn: gridCell.column
    });
    setTimeout(() => {
      const focusIndex = this.getNextFocusIndexForAutoHeight(gridCell, up);
      this.navigateTo({
        scrollIndex,
        scrollType: up ? "bottom" : "top",
        scrollColumn: null,
        focusIndex,
        focusColumn: gridCell.column,
        isAsync: true
      });
    }, 50);
  }
  getNextFocusIndexForAutoHeight(gridCell, up = false) {
    const step = up ? -1 : 1;
    const pixelsInOnePage = this.getViewportHeight();
    const lastRowIndex = this.pageBoundsService.getLastRow();
    let pixelSum = 0;
    let currentIndex = gridCell.rowIndex;
    while (currentIndex >= 0 && currentIndex <= lastRowIndex) {
      const currentCell = this.rowModel.getRow(currentIndex);
      if (currentCell) {
        const currentCellHeight = currentCell.rowHeight ?? 0;
        if (pixelSum + currentCellHeight > pixelsInOnePage) {
          break;
        }
        pixelSum += currentCellHeight;
      }
      currentIndex += step;
    }
    return Math.max(0, Math.min(currentIndex, lastRowIndex));
  }
  getViewportHeight() {
    const { gridBodyCtrl, center } = this.ctrlsService.getParams();
    const scrollPosition = gridBodyCtrl.getScrollFeature().getVScrollPosition();
    const scrollbarWidth = this.gos.getScrollbarWidth();
    let pixelsInOnePage = scrollPosition.bottom - scrollPosition.top;
    if (center.isHorizontalScrollShowing()) {
      pixelsInOnePage -= scrollbarWidth;
    }
    return pixelsInOnePage;
  }
  isRowTallerThanView(rowIndex) {
    const rowNode = this.rowModel.getRow(rowIndex);
    if (!rowNode) {
      return false;
    }
    const rowHeight = rowNode.rowHeight;
    if (typeof rowHeight !== "number") {
      return false;
    }
    return rowHeight > this.getViewportHeight();
  }
  onCtrlUpDownLeftRight(key, gridCell) {
    const cellToFocus = this.cellNavigationService.getNextCellToFocus(key, gridCell, true);
    const { rowIndex } = cellToFocus;
    const column = cellToFocus.column;
    this.navigateTo({
      scrollIndex: rowIndex,
      scrollType: null,
      scrollColumn: column,
      focusIndex: rowIndex,
      focusColumn: column
    });
  }
  // home brings focus to top left cell, end brings focus to bottom right, grid scrolled to bring
  // same cell into view (which means either scroll all the way up, or all the way down).
  onHomeOrEndKey(key) {
    const homeKey = key === KeyCode.PAGE_HOME;
    const allColumns = this.visibleColsService.getAllCols();
    const columnToSelect = homeKey ? allColumns[0] : _last(allColumns);
    const scrollIndex = homeKey ? this.pageBoundsService.getFirstRow() : this.pageBoundsService.getLastRow();
    this.navigateTo({
      scrollIndex,
      scrollType: null,
      scrollColumn: columnToSelect,
      focusIndex: scrollIndex,
      focusColumn: columnToSelect
    });
  }
  // result of keyboard event
  onTabKeyDown(previous, keyboardEvent) {
    const backwards = keyboardEvent.shiftKey;
    const movedToNextCell = this.tabToNextCellCommon(previous, backwards, keyboardEvent);
    if (movedToNextCell !== false) {
      if (movedToNextCell) {
        keyboardEvent.preventDefault();
      }
      return;
    }
    if (backwards) {
      const { rowIndex, rowPinned } = previous.getRowPosition();
      const firstRow = rowPinned ? rowIndex === 0 : rowIndex === this.pageBoundsService.getFirstRow();
      if (firstRow) {
        if (this.gos.get("headerHeight") === 0 || this.gos.get("suppressHeaderFocus")) {
          this.focusService.focusNextGridCoreContainer(true, true);
        } else {
          keyboardEvent.preventDefault();
          this.focusService.focusPreviousFromFirstCell(keyboardEvent);
        }
      }
    } else {
      if (previous instanceof CellCtrl) {
        previous.focusCell(true);
      }
      if (this.focusService.focusNextGridCoreContainer(backwards)) {
        keyboardEvent.preventDefault();
      }
    }
  }
  // comes from API
  tabToNextCell(backwards, event) {
    const focusedCell = this.focusService.getFocusedCell();
    if (!focusedCell) {
      return false;
    }
    let cellOrRow = this.getCellByPosition(focusedCell);
    if (!cellOrRow) {
      cellOrRow = this.rowRenderer.getRowByPosition(focusedCell);
      if (!cellOrRow || !cellOrRow.isFullWidth()) {
        return false;
      }
    }
    return !!this.tabToNextCellCommon(cellOrRow, backwards, event);
  }
  tabToNextCellCommon(previous, backwards, event) {
    let editing = previous.isEditing();
    if (!editing && previous instanceof CellCtrl) {
      const cell = previous;
      const row = cell.getRowCtrl();
      if (row) {
        editing = row.isEditing();
      }
    }
    let res;
    if (editing) {
      if (this.gos.get("editType") === "fullRow") {
        res = this.moveToNextEditingRow(previous, backwards, event);
      } else {
        res = this.moveToNextEditingCell(previous, backwards, event);
      }
    } else {
      res = this.moveToNextCellNotEditing(previous, backwards);
    }
    if (res === null) {
      return res;
    }
    return res || !!this.focusService.getFocusedHeader();
  }
  // returns null if no navigation should be performed
  moveToNextEditingCell(previousCell, backwards, event = null) {
    const previousPos = previousCell.getCellPosition();
    previousCell.getGui().focus();
    previousCell.stopEditing();
    const nextCell = this.findNextCellToFocusOn(previousPos, backwards, true);
    if (nextCell === false) {
      return null;
    }
    if (nextCell == null) {
      return false;
    }
    nextCell.startEditing(null, true, event);
    nextCell.focusCell(false);
    return true;
  }
  // returns null if no navigation should be performed
  moveToNextEditingRow(previousCell, backwards, event = null) {
    const previousPos = previousCell.getCellPosition();
    const nextCell = this.findNextCellToFocusOn(previousPos, backwards, true);
    if (nextCell === false) {
      return null;
    }
    if (nextCell == null) {
      return false;
    }
    const nextPos = nextCell.getCellPosition();
    const previousEditable = this.isCellEditable(previousPos);
    const nextEditable = this.isCellEditable(nextPos);
    const rowsMatch = nextPos && previousPos.rowIndex === nextPos.rowIndex && previousPos.rowPinned === nextPos.rowPinned;
    if (previousEditable) {
      previousCell.setFocusOutOnEditor();
    }
    if (!rowsMatch) {
      const pRow = previousCell.getRowCtrl();
      pRow.stopEditing();
      const nRow = nextCell.getRowCtrl();
      nRow.startRowEditing(void 0, void 0, event);
    }
    if (nextEditable) {
      nextCell.setFocusInOnEditor();
      nextCell.focusCell();
    } else {
      nextCell.focusCell(true);
    }
    return true;
  }
  // returns null if no navigation should be performed
  moveToNextCellNotEditing(previousCell, backwards) {
    const displayedColumns = this.visibleColsService.getAllCols();
    let cellPos;
    if (previousCell instanceof RowCtrl) {
      cellPos = {
        ...previousCell.getRowPosition(),
        column: backwards ? displayedColumns[0] : _last(displayedColumns)
      };
    } else {
      cellPos = previousCell.getCellPosition();
    }
    const nextCell = this.findNextCellToFocusOn(cellPos, backwards, false);
    if (nextCell === false) {
      return null;
    }
    if (nextCell instanceof CellCtrl) {
      nextCell.focusCell(true);
    } else if (nextCell) {
      return this.tryToFocusFullWidthRow(nextCell.getRowPosition(), backwards);
    }
    return _exists(nextCell);
  }
  /**
   * called by the cell, when tab is pressed while editing.
   * @return: RenderedCell when navigation successful, false if navigation should not be performed, otherwise null
   */
  findNextCellToFocusOn(previousPosition, backwards, startEditing) {
    let nextPosition = previousPosition;
    while (true) {
      if (previousPosition !== nextPosition) {
        previousPosition = nextPosition;
      }
      if (!backwards) {
        nextPosition = this.getLastCellOfColSpan(nextPosition);
      }
      nextPosition = this.cellNavigationService.getNextTabbedCell(nextPosition, backwards);
      const userFunc = this.gos.getCallback("tabToNextCell");
      if (_exists(userFunc)) {
        const params = {
          backwards,
          editing: startEditing,
          previousCellPosition: previousPosition,
          nextCellPosition: nextPosition ? nextPosition : null
        };
        const userResult = userFunc(params);
        if (userResult === true || userResult === null) {
          if (userResult === null) {
            _warnOnce(
              "Returning `null` from tabToNextCell is deprecated. Return `true` to stay on the current cell, or `false` to let the browser handle the tab behaviour."
            );
          }
          nextPosition = previousPosition;
        } else if (userResult === false) {
          return false;
        } else {
          nextPosition = {
            rowIndex: userResult.rowIndex,
            column: userResult.column,
            rowPinned: userResult.rowPinned
          };
        }
      }
      if (!nextPosition) {
        return null;
      }
      if (nextPosition.rowIndex < 0) {
        const headerLen = this.headerNavigationService.getHeaderRowCount();
        this.focusService.focusHeaderPosition({
          headerPosition: {
            headerRowIndex: headerLen + nextPosition.rowIndex,
            column: nextPosition.column
          },
          fromCell: true
        });
        return null;
      }
      const fullRowEdit = this.gos.get("editType") === "fullRow";
      if (startEditing && !fullRowEdit) {
        const cellIsEditable = this.isCellEditable(nextPosition);
        if (!cellIsEditable) {
          continue;
        }
      }
      this.ensureCellVisible(nextPosition);
      const nextCell = this.getCellByPosition(nextPosition);
      if (!nextCell) {
        const row = this.rowRenderer.getRowByPosition(nextPosition);
        if (!row || !row.isFullWidth() || startEditing) {
          continue;
        }
        return row;
      }
      if (nextCell.isSuppressNavigable()) {
        continue;
      }
      this.rangeService?.setRangeToCell(nextPosition);
      return nextCell;
    }
  }
  isCellEditable(cell) {
    const rowNode = this.lookupRowNodeForCell(cell);
    if (rowNode) {
      return cell.column.isCellEditable(rowNode);
    }
    return false;
  }
  getCellByPosition(cellPosition) {
    const rowCtrl = this.rowRenderer.getRowByPosition(cellPosition);
    if (!rowCtrl) {
      return null;
    }
    return rowCtrl.getCellCtrl(cellPosition.column);
  }
  lookupRowNodeForCell(cell) {
    if (cell.rowPinned === "top") {
      return this.pinnedRowModel.getPinnedTopRow(cell.rowIndex);
    }
    if (cell.rowPinned === "bottom") {
      return this.pinnedRowModel.getPinnedBottomRow(cell.rowIndex);
    }
    return this.rowModel.getRow(cell.rowIndex);
  }
  // we use index for rows, but column object for columns, as the next column (by index) might not
  // be visible (header grouping) so it's not reliable, so using the column object instead.
  navigateToNextCell(event, key, currentCell, allowUserOverride) {
    let nextCell = currentCell;
    let hitEdgeOfGrid = false;
    while (nextCell && (nextCell === currentCell || !this.isValidNavigateCell(nextCell))) {
      if (this.gos.get("enableRtl")) {
        if (key === KeyCode.LEFT) {
          nextCell = this.getLastCellOfColSpan(nextCell);
        }
      } else if (key === KeyCode.RIGHT) {
        nextCell = this.getLastCellOfColSpan(nextCell);
      }
      nextCell = this.cellNavigationService.getNextCellToFocus(key, nextCell);
      hitEdgeOfGrid = _missing(nextCell);
    }
    if (hitEdgeOfGrid && event && event.key === KeyCode.UP) {
      nextCell = {
        rowIndex: -1,
        rowPinned: null,
        column: currentCell.column
      };
    }
    if (allowUserOverride) {
      const userFunc = this.gos.getCallback("navigateToNextCell");
      if (_exists(userFunc)) {
        const params = {
          key,
          previousCellPosition: currentCell,
          nextCellPosition: nextCell ? nextCell : null,
          event
        };
        const userCell = userFunc(params);
        if (_exists(userCell)) {
          nextCell = {
            rowPinned: userCell.rowPinned,
            rowIndex: userCell.rowIndex,
            column: userCell.column
          };
        } else {
          nextCell = null;
        }
      }
    }
    if (!nextCell) {
      return;
    }
    if (nextCell.rowIndex < 0) {
      const headerLen = this.headerNavigationService.getHeaderRowCount();
      this.focusService.focusHeaderPosition({
        headerPosition: { headerRowIndex: headerLen + nextCell.rowIndex, column: currentCell.column },
        event: event || void 0,
        fromCell: true
      });
      return;
    }
    const normalisedPosition = this.getNormalisedPosition(nextCell);
    if (normalisedPosition) {
      this.focusPosition(normalisedPosition);
    } else {
      this.tryToFocusFullWidthRow(nextCell);
    }
  }
  getNormalisedPosition(cellPosition) {
    this.ensureCellVisible(cellPosition);
    const cellCtrl = this.getCellByPosition(cellPosition);
    if (!cellCtrl) {
      return null;
    }
    cellPosition = cellCtrl.getCellPosition();
    this.ensureCellVisible(cellPosition);
    return cellPosition;
  }
  tryToFocusFullWidthRow(position, backwards = false) {
    const displayedColumns = this.visibleColsService.getAllCols();
    const rowComp = this.rowRenderer.getRowByPosition(position);
    if (!rowComp || !rowComp.isFullWidth()) {
      return false;
    }
    const currentCellFocused = this.focusService.getFocusedCell();
    const cellPosition = {
      rowIndex: position.rowIndex,
      rowPinned: position.rowPinned,
      column: position.column || (backwards ? _last(displayedColumns) : displayedColumns[0])
    };
    this.focusPosition(cellPosition);
    const fromBelow = currentCellFocused != null ? this.rowPositionUtils.before(cellPosition, currentCellFocused) : false;
    const focusEvent = {
      type: "fullWidthRowFocused",
      rowIndex: cellPosition.rowIndex,
      rowPinned: cellPosition.rowPinned,
      column: cellPosition.column,
      isFullWidthCell: true,
      fromBelow
    };
    this.eventService.dispatchEvent(focusEvent);
    return true;
  }
  focusPosition(cellPosition) {
    this.focusService.setFocusedCell({
      rowIndex: cellPosition.rowIndex,
      column: cellPosition.column,
      rowPinned: cellPosition.rowPinned,
      forceBrowserFocus: true
    });
    this.rangeService?.setRangeToCell(cellPosition);
  }
  isValidNavigateCell(cell) {
    const rowNode = this.rowPositionUtils.getRowNode(cell);
    return !!rowNode;
  }
  getLastCellOfColSpan(cell) {
    const cellCtrl = this.getCellByPosition(cell);
    if (!cellCtrl) {
      return cell;
    }
    const colSpanningList = cellCtrl.getColSpanningList();
    if (colSpanningList.length === 1) {
      return cell;
    }
    return {
      rowIndex: cell.rowIndex,
      column: _last(colSpanningList),
      rowPinned: cell.rowPinned
    };
  }
  ensureCellVisible(gridCell) {
    const isGroupStickyEnabled = this.gos.isGroupRowsSticky();
    const rowNode = this.rowModel.getRow(gridCell.rowIndex);
    const skipScrollToRow = isGroupStickyEnabled && rowNode?.sticky;
    if (!skipScrollToRow && _missing(gridCell.rowPinned)) {
      this.gridBodyCon.getScrollFeature().ensureIndexVisible(gridCell.rowIndex);
    }
    if (!gridCell.column.isPinned()) {
      this.gridBodyCon.getScrollFeature().ensureColumnVisible(gridCell.column);
    }
  }
};

// community-modules/core/src/headerRendering/common/horizontalResizeService.ts
var HorizontalResizeService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "horizontalResizeService";
  }
  wireBeans(beans) {
    this.dragService = beans.dragService;
    this.ctrlsService = beans.ctrlsService;
  }
  addResizeBar(params) {
    const dragSource = {
      dragStartPixels: params.dragStartPixels || 0,
      eElement: params.eResizeBar,
      onDragStart: this.onDragStart.bind(this, params),
      onDragStop: this.onDragStop.bind(this, params),
      onDragging: this.onDragging.bind(this, params),
      includeTouch: true,
      stopPropagationForTouch: true
    };
    this.dragService.addDragSource(dragSource);
    const finishedWithResizeFunc = () => this.dragService.removeDragSource(dragSource);
    return finishedWithResizeFunc;
  }
  onDragStart(params, mouseEvent) {
    this.dragStartX = mouseEvent.clientX;
    this.setResizeIcons();
    const shiftKey = mouseEvent instanceof MouseEvent && mouseEvent.shiftKey === true;
    params.onResizeStart(shiftKey);
  }
  setResizeIcons() {
    const ctrl = this.ctrlsService.get("gridCtrl");
    ctrl.setResizeCursor(true);
    ctrl.disableUserSelect(true);
  }
  onDragStop(params, mouseEvent) {
    params.onResizeEnd(this.resizeAmount);
    this.resetIcons();
  }
  resetIcons() {
    const ctrl = this.ctrlsService.get("gridCtrl");
    ctrl.setResizeCursor(false);
    ctrl.disableUserSelect(false);
  }
  onDragging(params, mouseEvent) {
    this.resizeAmount = mouseEvent.clientX - this.dragStartX;
    params.onResizing(this.resizeAmount);
  }
};

// community-modules/core/src/headerRendering/cells/column/standardMenu.ts
var StandardMenuFactory = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "filterMenuFactory";
  }
  wireBeans(beans) {
    this.popupService = beans.popupService;
    this.focusService = beans.focusService;
    this.ctrlsService = beans.ctrlsService;
    this.menuService = beans.menuService;
  }
  hideActiveMenu() {
    if (this.hidePopup) {
      this.hidePopup();
    }
  }
  showMenuAfterMouseEvent(column, mouseEvent, containerType) {
    this.showPopup(
      column,
      (eMenu) => {
        this.popupService.positionPopupUnderMouseEvent({
          column,
          type: containerType,
          mouseEvent,
          ePopup: eMenu
        });
      },
      containerType,
      mouseEvent.target,
      this.menuService.isLegacyMenuEnabled()
    );
  }
  showMenuAfterButtonClick(column, eventSource, containerType) {
    let multiplier = -1;
    let alignSide = "left";
    const isLegacyMenuEnabled = this.menuService.isLegacyMenuEnabled();
    if (!isLegacyMenuEnabled && this.gos.get("enableRtl")) {
      multiplier = 1;
      alignSide = "right";
    }
    const nudgeX = isLegacyMenuEnabled ? void 0 : 4 * multiplier;
    const nudgeY = isLegacyMenuEnabled ? void 0 : 4;
    this.showPopup(
      column,
      (eMenu) => {
        this.popupService.positionPopupByComponent({
          type: containerType,
          eventSource,
          ePopup: eMenu,
          nudgeX,
          nudgeY,
          alignSide,
          keepWithinBounds: true,
          position: "under",
          column
        });
      },
      containerType,
      eventSource,
      isLegacyMenuEnabled
    );
  }
  showPopup(column, positionCallback, containerType, eventSource, isLegacyMenuEnabled) {
    const comp = column ? this.createBean(new FilterWrapperComp(column, "COLUMN_MENU")) : void 0;
    this.activeMenu = comp;
    if (!comp?.hasFilter() || !column) {
      throw new Error("AG Grid - unable to show popup filter, filter instantiation failed");
    }
    const eMenu = document.createElement("div");
    _setAriaRole(eMenu, "presentation");
    eMenu.classList.add("ag-menu");
    if (!isLegacyMenuEnabled) {
      eMenu.classList.add("ag-filter-menu");
    }
    [this.tabListener] = this.addManagedElementListeners(eMenu, {
      keydown: (e) => this.trapFocusWithin(e, eMenu)
    });
    eMenu.appendChild(comp?.getGui());
    let hidePopup;
    const afterGuiDetached = () => comp?.afterGuiDetached();
    const anchorToElement = this.menuService.isColumnMenuAnchoringEnabled() ? eventSource ?? this.ctrlsService.getGridBodyCtrl().getGui() : void 0;
    const closedCallback = (e) => {
      column.setMenuVisible(false, "contextMenu");
      const isKeyboardEvent = e instanceof KeyboardEvent;
      if (this.tabListener) {
        this.tabListener = this.tabListener();
      }
      if (isKeyboardEvent && eventSource && _isVisible(eventSource)) {
        const focusableEl = this.focusService.findTabbableParent(eventSource);
        if (focusableEl) {
          focusableEl.focus();
        }
      }
      afterGuiDetached();
      this.destroyBean(this.activeMenu);
      this.dispatchVisibleChangedEvent(false, containerType, column);
    };
    const translate = this.localeService.getLocaleTextFunc();
    const ariaLabel = isLegacyMenuEnabled && containerType !== "columnFilter" ? translate("ariaLabelColumnMenu", "Column Menu") : translate("ariaLabelColumnFilter", "Column Filter");
    const addPopupRes = this.popupService.addPopup({
      modal: true,
      eChild: eMenu,
      closeOnEsc: true,
      closedCallback,
      positionCallback: () => positionCallback(eMenu),
      anchorToElement,
      ariaLabel
    });
    if (addPopupRes) {
      this.hidePopup = hidePopup = addPopupRes.hideFunc;
    }
    comp.afterInit().then(() => {
      positionCallback(eMenu);
      comp.afterGuiAttached({ container: containerType, hidePopup });
    });
    column.setMenuVisible(true, "contextMenu");
    this.dispatchVisibleChangedEvent(true, containerType, column);
  }
  trapFocusWithin(e, menu) {
    if (e.key !== KeyCode.TAB || e.defaultPrevented || this.focusService.findNextFocusableElement(menu, false, e.shiftKey)) {
      return;
    }
    e.preventDefault();
    this.focusService.focusInto(menu, e.shiftKey);
  }
  dispatchVisibleChangedEvent(visible, containerType, column) {
    const displayedEvent = {
      type: "columnMenuVisibleChanged",
      visible,
      switchingTab: false,
      key: containerType,
      column: column ?? null
    };
    this.eventService.dispatchEvent(displayedEvent);
  }
  isMenuEnabled(column) {
    return column.isFilterAllowed() && (column.getColDef().menuTabs ?? ["filterMenuTab"]).includes("filterMenuTab");
  }
  showMenuAfterContextMenuEvent() {
  }
  destroy() {
    this.destroyBean(this.activeMenu);
    super.destroy();
  }
};

// community-modules/core/src/misc/resizeObserverService.ts
var DEBOUNCE_DELAY = 50;
var ResizeObserverService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "resizeObserverService";
    this.polyfillFunctions = [];
  }
  observeResize(element, callback) {
    const win = this.gos.getWindow();
    const useBrowserResizeObserver = () => {
      const resizeObserver = new win.ResizeObserver(callback);
      resizeObserver.observe(element);
      return () => resizeObserver.disconnect();
    };
    const usePolyfill = () => {
      let widthLastTime = element?.clientWidth ?? 0;
      let heightLastTime = element?.clientHeight ?? 0;
      let running = true;
      const periodicallyCheckWidthAndHeight = () => {
        if (running) {
          const newWidth = element?.clientWidth ?? 0;
          const newHeight = element?.clientHeight ?? 0;
          const changed = newWidth !== widthLastTime || newHeight !== heightLastTime;
          if (changed) {
            widthLastTime = newWidth;
            heightLastTime = newHeight;
            callback();
          }
          this.doNextPolyfillTurn(periodicallyCheckWidthAndHeight);
        }
      };
      periodicallyCheckWidthAndHeight();
      return () => running = false;
    };
    const suppressResize = this.gos.get("suppressBrowserResizeObserver");
    const resizeObserverExists = !!win.ResizeObserver;
    if (resizeObserverExists && !suppressResize) {
      return useBrowserResizeObserver();
    }
    return this.getFrameworkOverrides().wrapIncoming(() => usePolyfill(), "resize-observer");
  }
  doNextPolyfillTurn(func) {
    this.polyfillFunctions.push(func);
    this.schedulePolyfill();
  }
  schedulePolyfill() {
    if (this.polyfillScheduled) {
      return;
    }
    const executeAllFuncs = () => {
      const funcs = this.polyfillFunctions;
      this.polyfillScheduled = false;
      this.polyfillFunctions = [];
      funcs.forEach((f) => f());
    };
    this.polyfillScheduled = true;
    window.setTimeout(executeAllFuncs, DEBOUNCE_DELAY);
  }
};

// community-modules/core/src/misc/animationFrameService.ts
var AnimationFrameService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "animationFrameService";
    // p1 and p2 are create tasks are to do with row and cell creation.
    // for them we want to execute according to row order, so we use
    // TaskItem so we know what index the item is for.
    this.createTasksP1 = { list: [], sorted: false };
    // eg drawing back-ground of rows
    this.createTasksP2 = { list: [], sorted: false };
    // eg cell renderers, adding hover functionality
    // destroy tasks are to do with row removal. they are done after row creation as the user will need to see new
    // rows first (as blank is scrolled into view), when we remove the old rows (no longer in view) is not as
    // important.
    this.destroyTasks = [];
    this.ticking = false;
    // we need to know direction of scroll, to build up rows in the direction of
    // the scroll. eg if user scrolls down, we extend the rows by building down.
    this.scrollGoingDown = true;
    this.lastPage = 0;
    this.lastScrollTop = 0;
    this.taskCount = 0;
    this.cancelledTasks = /* @__PURE__ */ new Set();
  }
  wireBeans(beans) {
    this.ctrlsService = beans.ctrlsService;
    this.paginationService = beans.paginationService;
  }
  setScrollTop(scrollTop) {
    const isPaginationActive = this.gos.get("pagination");
    this.scrollGoingDown = scrollTop >= this.lastScrollTop;
    if (isPaginationActive && scrollTop === 0) {
      const currentPage = this.paginationService?.getCurrentPage() ?? 0;
      if (currentPage !== this.lastPage) {
        this.lastPage = currentPage;
        this.scrollGoingDown = true;
      }
    }
    this.lastScrollTop = scrollTop;
  }
  postConstruct() {
    this.useAnimationFrame = !this.gos.get("suppressAnimationFrame");
  }
  isOn() {
    return this.useAnimationFrame;
  }
  // this method is for our AG Grid sanity only - if animation frames are turned off,
  // then no place in the code should be looking to add any work to be done in animation
  // frames. this stops bugs - where some code is asking for a frame to be executed
  // when it should not.
  verifyAnimationFrameOn(methodName) {
    if (this.useAnimationFrame === false) {
      _warnOnce(`AnimationFrameService.${methodName} called but animation frames are off`);
    }
  }
  createTask(task, index, list) {
    this.verifyAnimationFrameOn(list);
    const taskItem = { task, index, createOrder: ++this.taskCount };
    this.addTaskToList(this[list], taskItem);
    this.schedule();
  }
  cancelTask(task) {
    this.cancelledTasks.add(task);
  }
  addTaskToList(taskList, task) {
    taskList.list.push(task);
    taskList.sorted = false;
  }
  sortTaskList(taskList) {
    if (taskList.sorted) {
      return;
    }
    const sortDirection = this.scrollGoingDown ? 1 : -1;
    taskList.list.sort(
      (a, b) => a.index !== b.index ? sortDirection * (b.index - a.index) : b.createOrder - a.createOrder
    );
    taskList.sorted = true;
  }
  addDestroyTask(task) {
    this.verifyAnimationFrameOn("createTasksP3");
    this.destroyTasks.push(task);
    this.schedule();
  }
  executeFrame(millis) {
    this.verifyAnimationFrameOn("executeFrame");
    const p1TaskList = this.createTasksP1;
    const p1Tasks = p1TaskList.list;
    const p2TaskList = this.createTasksP2;
    const p2Tasks = p2TaskList.list;
    const destroyTasks = this.destroyTasks;
    const frameStart = (/* @__PURE__ */ new Date()).getTime();
    let duration = (/* @__PURE__ */ new Date()).getTime() - frameStart;
    const noMaxMillis = millis <= 0;
    const gridBodyCon = this.ctrlsService.getGridBodyCtrl();
    while (noMaxMillis || duration < millis) {
      const gridBodyDidSomething = gridBodyCon.getScrollFeature().scrollGridIfNeeded();
      if (!gridBodyDidSomething) {
        let task;
        if (p1Tasks.length) {
          this.sortTaskList(p1TaskList);
          task = p1Tasks.pop().task;
        } else if (p2Tasks.length) {
          this.sortTaskList(p2TaskList);
          task = p2Tasks.pop().task;
        } else if (destroyTasks.length) {
          task = destroyTasks.pop();
        } else {
          this.cancelledTasks.clear();
          break;
        }
        if (!this.cancelledTasks.has(task)) {
          task();
        }
      }
      duration = (/* @__PURE__ */ new Date()).getTime() - frameStart;
    }
    if (p1Tasks.length || p2Tasks.length || destroyTasks.length) {
      this.requestFrame();
    } else {
      this.stopTicking();
    }
  }
  stopTicking() {
    this.ticking = false;
  }
  flushAllFrames() {
    if (!this.useAnimationFrame) {
      return;
    }
    this.executeFrame(-1);
  }
  schedule() {
    if (!this.useAnimationFrame) {
      return;
    }
    if (!this.ticking) {
      this.ticking = true;
      this.requestFrame();
    }
  }
  requestFrame() {
    const callback = this.executeFrame.bind(this, 60);
    this.requestAnimationFrame(callback);
  }
  requestAnimationFrame(callback) {
    const win = this.gos.getWindow();
    if (win.requestAnimationFrame) {
      win.requestAnimationFrame(callback);
    } else if (win.webkitRequestAnimationFrame) {
      win.webkitRequestAnimationFrame(callback);
    } else {
      win.setTimeout(callback, 0);
    }
  }
  isQueueEmpty() {
    return !this.ticking;
  }
  // a debounce utility used for parts of the app involved with rendering.
  // the advantage over normal debounce is the client can call flushAllFrames()
  // to make sure all rendering is complete. we don't wait any milliseconds,
  // as this is intended to batch calls in one VM turn.
  debounce(func) {
    let pending = false;
    return () => {
      if (!this.isOn()) {
        window.setTimeout(func, 0);
        return;
      }
      if (pending) {
        return;
      }
      pending = true;
      this.addDestroyTask(() => {
        pending = false;
        func();
      });
    };
  }
};

// community-modules/core/src/interfaces/iClientSideRowModel.ts
var ClientSideRowModelSteps = /* @__PURE__ */ ((ClientSideRowModelSteps2) => {
  ClientSideRowModelSteps2["EVERYTHING"] = "group";
  ClientSideRowModelSteps2["FILTER"] = "filter";
  ClientSideRowModelSteps2["SORT"] = "sort";
  ClientSideRowModelSteps2["MAP"] = "map";
  ClientSideRowModelSteps2["AGGREGATE"] = "aggregate";
  ClientSideRowModelSteps2["FILTER_AGGREGATES"] = "filter_aggregates";
  ClientSideRowModelSteps2["PIVOT"] = "pivot";
  ClientSideRowModelSteps2["NOTHING"] = "nothing";
  return ClientSideRowModelSteps2;
})(ClientSideRowModelSteps || {});

// community-modules/core/src/misc/expansionService.ts
var ExpansionService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "expansionService";
  }
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
  }
  postConstruct() {
    this.isClientSideRowModel = this.rowModel.getType() === "clientSide";
  }
  expandRows(rowIds) {
    if (!this.isClientSideRowModel) {
      return;
    }
    const rowIdSet = new Set(rowIds);
    this.rowModel.forEachNode((node) => {
      if (node.id && rowIdSet.has(node.id)) {
        node.expanded = true;
      }
    });
    this.onGroupExpandedOrCollapsed();
  }
  getExpandedRows() {
    const expandedRows = [];
    this.rowModel.forEachNode(({ expanded, id }) => {
      if (expanded && id) {
        expandedRows.push(id);
      }
    });
    return expandedRows;
  }
  expandAll(value) {
    if (!this.isClientSideRowModel) {
      return;
    }
    this.rowModel.expandOrCollapseAll(value);
  }
  setRowNodeExpanded(rowNode, expanded, expandParents, forceSync) {
    if (rowNode) {
      if (expandParents && rowNode.parent && rowNode.parent.level !== -1) {
        this.setRowNodeExpanded(rowNode.parent, expanded, expandParents, forceSync);
      }
      rowNode.setExpanded(expanded, void 0, forceSync);
    }
  }
  onGroupExpandedOrCollapsed() {
    if (!this.isClientSideRowModel) {
      return;
    }
    this.rowModel.refreshModel({ step: "map" /* MAP */ });
  }
};

// community-modules/core/src/misc/menuService.ts
var MenuService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "menuService";
  }
  wireBeans(beans) {
    this.filterMenuFactory = beans.filterMenuFactory;
    this.ctrlsService = beans.ctrlsService;
    this.animationFrameService = beans.animationFrameService;
    this.filterManager = beans.filterManager;
    this.rowRenderer = beans.rowRenderer;
    this.columnChooserFactory = beans.columnChooserFactory;
    this.contextMenuFactory = beans.contextMenuFactory;
    this.enterpriseMenuFactory = beans.enterpriseMenuFactory;
  }
  postConstruct() {
    this.activeMenuFactory = this.enterpriseMenuFactory ?? this.filterMenuFactory;
  }
  showColumnMenu(params) {
    this.showColumnMenuCommon(this.activeMenuFactory, params, "columnMenu");
  }
  showFilterMenu(params) {
    const menuFactory = this.enterpriseMenuFactory && this.isLegacyMenuEnabled() ? this.enterpriseMenuFactory : this.filterMenuFactory;
    this.showColumnMenuCommon(menuFactory, params, params.containerType, true);
  }
  showHeaderContextMenu(column, mouseEvent, touchEvent) {
    this.activeMenuFactory.showMenuAfterContextMenuEvent(column, mouseEvent, touchEvent);
  }
  getContextMenuPosition(rowNode, column) {
    const rowCtrl = this.getRowCtrl(rowNode);
    const eGui = this.getCellGui(rowCtrl, column);
    if (!eGui) {
      if (rowCtrl) {
        return { x: 0, y: rowCtrl.getRowYPosition() };
      }
      return { x: 0, y: 0 };
    }
    const rect = eGui.getBoundingClientRect();
    return {
      x: rect.x + rect.width / 2,
      y: rect.y + rect.height / 2
    };
  }
  showContextMenu(params) {
    const { rowNode } = params;
    const column = params.column;
    let { anchorToElement, value } = params;
    if (rowNode && column && value == null) {
      value = rowNode.getValueFromValueService(column);
    }
    if (anchorToElement == null) {
      anchorToElement = this.getContextMenuAnchorElement(rowNode, column);
    }
    this.contextMenuFactory?.onContextMenu(
      params.mouseEvent ?? null,
      params.touchEvent ?? null,
      rowNode ?? null,
      column ?? null,
      value,
      anchorToElement
    );
  }
  showColumnChooser(params) {
    this.columnChooserFactory?.showColumnChooser(params);
  }
  hidePopupMenu() {
    this.contextMenuFactory?.hideActiveMenu();
    this.activeMenuFactory.hideActiveMenu();
  }
  hideColumnChooser() {
    this.columnChooserFactory?.hideActiveColumnChooser();
  }
  isColumnMenuInHeaderEnabled(column) {
    const { suppressMenu, suppressHeaderMenuButton } = column.getColDef();
    const isSuppressMenuButton = suppressHeaderMenuButton ?? suppressMenu;
    return !isSuppressMenuButton && this.activeMenuFactory.isMenuEnabled(column) && (this.isLegacyMenuEnabled() || !!this.enterpriseMenuFactory);
  }
  isFilterMenuInHeaderEnabled(column) {
    return !column.getColDef().suppressHeaderFilterButton && !!this.filterManager?.isFilterAllowed(column);
  }
  isHeaderContextMenuEnabled(column) {
    return !column?.getColDef().suppressHeaderContextMenu && this.getColumnMenuType() === "new";
  }
  isHeaderMenuButtonAlwaysShowEnabled() {
    return this.isSuppressMenuHide();
  }
  isHeaderMenuButtonEnabled() {
    const menuHides = !this.isSuppressMenuHide();
    const onIpadAndMenuHides = _isIOSUserAgent() && menuHides;
    return !onIpadAndMenuHides;
  }
  isHeaderFilterButtonEnabled(column) {
    return this.isFilterMenuInHeaderEnabled(column) && !this.isLegacyMenuEnabled() && !this.isFloatingFilterButtonDisplayed(column);
  }
  isFilterMenuItemEnabled(column) {
    return !!this.filterManager?.isFilterAllowed(column) && !this.isLegacyMenuEnabled() && !this.isFilterMenuInHeaderEnabled(column) && !this.isFloatingFilterButtonDisplayed(column);
  }
  isColumnMenuAnchoringEnabled() {
    return !this.isLegacyMenuEnabled();
  }
  areAdditionalColumnMenuItemsEnabled() {
    return this.getColumnMenuType() === "new";
  }
  isLegacyMenuEnabled() {
    return this.getColumnMenuType() === "legacy";
  }
  isFloatingFilterButtonEnabled(column) {
    const colDef = column.getColDef();
    const legacySuppressFilterButton = colDef.floatingFilterComponentParams?.suppressFilterButton;
    if (legacySuppressFilterButton != null) {
      _warnOnce(
        `As of v31.1, 'colDef.floatingFilterComponentParams.suppressFilterButton' is deprecated. Use 'colDef.suppressFloatingFilterButton' instead.`
      );
    }
    return colDef.suppressFloatingFilterButton == null ? !legacySuppressFilterButton : !colDef.suppressFloatingFilterButton;
  }
  getColumnMenuType() {
    return this.gos.get("columnMenu");
  }
  isFloatingFilterButtonDisplayed(column) {
    return !!column.getColDef().floatingFilter && this.isFloatingFilterButtonEnabled(column);
  }
  isSuppressMenuHide() {
    const suppressMenuHide = this.gos.get("suppressMenuHide");
    if (this.isLegacyMenuEnabled()) {
      return this.gos.exists("suppressMenuHide") ? suppressMenuHide : false;
    }
    return suppressMenuHide;
  }
  showColumnMenuCommon(menuFactory, params, containerType, filtersOnly) {
    const { positionBy } = params;
    const column = params.column;
    if (positionBy === "button") {
      const { buttonElement } = params;
      menuFactory.showMenuAfterButtonClick(column, buttonElement, containerType, filtersOnly);
    } else if (positionBy === "mouse") {
      const { mouseEvent } = params;
      menuFactory.showMenuAfterMouseEvent(column, mouseEvent, containerType, filtersOnly);
    } else if (column) {
      this.ctrlsService.getGridBodyCtrl().getScrollFeature().ensureColumnVisible(column, "auto");
      this.animationFrameService.requestAnimationFrame(() => {
        const headerCellCtrl = this.ctrlsService.getHeaderRowContainerCtrl(column.getPinned()).getHeaderCtrlForColumn(column);
        menuFactory.showMenuAfterButtonClick(
          column,
          headerCellCtrl.getAnchorElementForMenu(filtersOnly),
          containerType,
          true
        );
      });
    }
  }
  getRowCtrl(rowNode) {
    const { rowIndex, rowPinned } = rowNode || {};
    if (rowIndex == null) {
      return;
    }
    return this.rowRenderer.getRowByPosition({ rowIndex, rowPinned }) || void 0;
  }
  getCellGui(rowCtrl, column) {
    if (!rowCtrl || !column) {
      return;
    }
    const cellCtrl = rowCtrl.getCellCtrl(column);
    return cellCtrl?.getGui() || void 0;
  }
  getContextMenuAnchorElement(rowNode, column) {
    const gridBodyEl = this.ctrlsService.getGridBodyCtrl().getGridBodyElement();
    const rowCtrl = this.getRowCtrl(rowNode);
    if (!rowCtrl) {
      return gridBodyEl;
    }
    const cellGui = this.getCellGui(rowCtrl, column);
    if (cellGui) {
      return cellGui;
    }
    if (rowCtrl.isFullWidth()) {
      return rowCtrl.getFullWidthElement();
    }
    return gridBodyEl;
  }
};

// community-modules/core/src/widgets/agInputTextArea.ts
var AgInputTextArea = class extends AgAbstractInputField {
  constructor(config) {
    super(config, "ag-text-area", null, "textarea");
  }
  setValue(value, silent) {
    const ret = super.setValue(value, silent);
    this.eInput.value = value;
    return ret;
  }
  setCols(cols) {
    this.eInput.cols = cols;
    return this;
  }
  setRows(rows) {
    this.eInput.rows = rows;
    return this;
  }
};
var AgInputTextAreaSelector = {
  selector: "AG-INPUT-TEXT-AREA",
  component: AgInputTextArea
};

// community-modules/core/src/edit/cellEditors/largeTextCellEditor.ts
var LargeTextCellEditor = class extends PopupComponent {
  constructor() {
    super(
      /* html */
      `<div class="ag-large-text">
        <ag-input-text-area data-ref="eTextArea" class="ag-large-text-input"></ag-input-text-area>
        </div>`,
      [AgInputTextAreaSelector]
    );
    this.eTextArea = RefPlaceholder;
  }
  init(params) {
    this.params = params;
    this.focusAfterAttached = params.cellStartedEdit;
    this.eTextArea.setMaxLength(params.maxLength || 200).setCols(params.cols || 60).setRows(params.rows || 10);
    if (_exists(params.value, true)) {
      this.eTextArea.setValue(params.value.toString(), true);
    }
    this.addGuiEventListener("keydown", this.onKeyDown.bind(this));
    this.activateTabIndex();
  }
  onKeyDown(event) {
    const key = event.key;
    if (key === KeyCode.LEFT || key === KeyCode.UP || key === KeyCode.RIGHT || key === KeyCode.DOWN || event.shiftKey && key === KeyCode.ENTER) {
      event.stopPropagation();
    }
  }
  afterGuiAttached() {
    const translate = this.localeService.getLocaleTextFunc();
    this.eTextArea.setInputAriaLabel(translate("ariaInputEditor", "Input Editor"));
    if (this.focusAfterAttached) {
      this.eTextArea.getFocusableElement().focus();
    }
  }
  getValue() {
    const value = this.eTextArea.getValue();
    if (!_exists(value) && !_exists(this.params.value)) {
      return this.params.value;
    }
    return this.params.parseValue(value);
  }
};

// community-modules/core/src/edit/cellEditors/popupEditorWrapper.ts
var PopupEditorWrapper = class extends PopupComponent {
  constructor(params) {
    super(
      /* html */
      `<div class="ag-popup-editor" tabindex="-1"/>`
    );
    this.params = params;
  }
  postConstruct() {
    this.gos.setDomData(this.getGui(), "popupEditorWrapper", true);
    this.addKeyDownListener();
  }
  addKeyDownListener() {
    const eGui = this.getGui();
    const params = this.params;
    const listener = (event) => {
      if (!_isUserSuppressingKeyboardEvent(this.gos, event, params.node, params.column, true)) {
        params.onKeyDown(event);
      }
    };
    this.addManagedElementListeners(eGui, { keydown: listener });
  }
};

// community-modules/core/src/edit/cellEditors/selectCellEditor.ts
var SelectCellEditor = class extends PopupComponent {
  constructor() {
    super(
      /* html */
      `<div class="ag-cell-edit-wrapper">
                <ag-select class="ag-cell-editor" data-ref="eSelect"></ag-select>
            </div>`,
      [AgSelectSelector]
    );
    this.eSelect = RefPlaceholder;
    this.startedByEnter = false;
  }
  wireBeans(beans) {
    this.valueService = beans.valueService;
  }
  init(params) {
    this.focusAfterAttached = params.cellStartedEdit;
    const { eSelect, valueService, gos } = this;
    const { values, value, eventKey } = params;
    if (_missing(values)) {
      _warnOnce("no values found for select cellEditor");
      return;
    }
    this.startedByEnter = eventKey != null ? eventKey === KeyCode.ENTER : false;
    let hasValue = false;
    values.forEach((currentValue) => {
      const option = { value: currentValue };
      const valueFormatted = valueService.formatValue(params.column, null, currentValue);
      const valueFormattedExits = valueFormatted !== null && valueFormatted !== void 0;
      option.text = valueFormattedExits ? valueFormatted : currentValue;
      eSelect.addOption(option);
      hasValue = hasValue || value === currentValue;
    });
    if (hasValue) {
      eSelect.setValue(params.value, true);
    } else if (params.values.length) {
      eSelect.setValue(params.values[0], true);
    }
    const { valueListGap, valueListMaxWidth, valueListMaxHeight } = params;
    if (valueListGap != null) {
      eSelect.setPickerGap(valueListGap);
    }
    if (valueListMaxHeight != null) {
      eSelect.setPickerMaxHeight(valueListMaxHeight);
    }
    if (valueListMaxWidth != null) {
      eSelect.setPickerMaxWidth(valueListMaxWidth);
    }
    if (gos.get("editType") !== "fullRow") {
      this.addManagedListeners(this.eSelect, { selectedItem: () => params.stopEditing() });
    }
  }
  afterGuiAttached() {
    if (this.focusAfterAttached) {
      this.eSelect.getFocusableElement().focus();
    }
    if (this.startedByEnter) {
      setTimeout(() => {
        if (this.isAlive()) {
          this.eSelect.showPicker();
        }
      });
    }
  }
  focusIn() {
    this.eSelect.getFocusableElement().focus();
  }
  getValue() {
    return this.eSelect.getValue();
  }
  isPopup() {
    return false;
  }
};

// community-modules/core/src/edit/cellEditors/simpleCellEditor.ts
var SimpleCellEditor = class extends PopupComponent {
  constructor(cellEditorInput) {
    super(
      /* html */
      `
            <div class="ag-cell-edit-wrapper">
                ${cellEditorInput.getTemplate()}
            </div>`,
      cellEditorInput.getAgComponents()
    );
    this.cellEditorInput = cellEditorInput;
    this.eInput = RefPlaceholder;
  }
  init(params) {
    this.params = params;
    const eInput = this.eInput;
    this.cellEditorInput.init(eInput, params);
    let startValue;
    if (params.cellStartedEdit) {
      this.focusAfterAttached = true;
      const eventKey = params.eventKey;
      if (eventKey === KeyCode.BACKSPACE || params.eventKey === KeyCode.DELETE) {
        startValue = "";
      } else if (eventKey && eventKey.length === 1) {
        startValue = eventKey;
      } else {
        startValue = this.cellEditorInput.getStartValue();
        if (eventKey !== KeyCode.F2) {
          this.highlightAllOnFocus = true;
        }
      }
    } else {
      this.focusAfterAttached = false;
      startValue = this.cellEditorInput.getStartValue();
    }
    if (startValue != null) {
      eInput.setStartValue(startValue);
    }
    this.addManagedElementListeners(eInput.getGui(), {
      keydown: (event) => {
        const { key } = event;
        if (key === KeyCode.PAGE_UP || key === KeyCode.PAGE_DOWN) {
          event.preventDefault();
        }
      }
    });
  }
  afterGuiAttached() {
    const translate = this.localeService.getLocaleTextFunc();
    const eInput = this.eInput;
    eInput.setInputAriaLabel(translate("ariaInputEditor", "Input Editor"));
    if (!this.focusAfterAttached) {
      return;
    }
    if (!_isBrowserSafari()) {
      eInput.getFocusableElement().focus();
    }
    const inputEl = eInput.getInputElement();
    if (this.highlightAllOnFocus) {
      inputEl.select();
    } else {
      this.cellEditorInput.setCaret?.();
    }
  }
  // gets called when tabbing through cells and in full row edit mode
  focusIn() {
    const eInput = this.eInput;
    const focusEl = eInput.getFocusableElement();
    const inputEl = eInput.getInputElement();
    focusEl.focus();
    inputEl.select();
  }
  getValue() {
    return this.cellEditorInput.getValue();
  }
  isPopup() {
    return false;
  }
};

// community-modules/core/src/edit/cellEditors/textCellEditor.ts
var TextCellEditorInput = class {
  getTemplate() {
    return (
      /* html */
      `<ag-input-text-field class="ag-cell-editor" data-ref="eInput"></ag-input-text-field>`
    );
  }
  getAgComponents() {
    return [AgInputTextFieldSelector];
  }
  init(eInput, params) {
    this.eInput = eInput;
    this.params = params;
    if (params.maxLength != null) {
      eInput.setMaxLength(params.maxLength);
    }
  }
  getValue() {
    const value = this.eInput.getValue();
    if (!_exists(value) && !_exists(this.params.value)) {
      return this.params.value;
    }
    return this.params.parseValue(value);
  }
  getStartValue() {
    const formatValue = this.params.useFormatter || this.params.column.getColDef().refData;
    return formatValue ? this.params.formatValue(this.params.value) : this.params.value;
  }
  setCaret() {
    const value = this.eInput.getValue();
    const len = _exists(value) && value.length || 0;
    if (len) {
      this.eInput.getInputElement().setSelectionRange(len, len);
    }
  }
};
var TextCellEditor = class extends SimpleCellEditor {
  constructor() {
    super(new TextCellEditorInput());
  }
};

// community-modules/core/src/edit/cellEditors/numberCellEditor.ts
var NumberCellEditorInput = class {
  getTemplate() {
    return (
      /* html */
      `<ag-input-number-field class="ag-cell-editor" data-ref="eInput"></ag-input-number-field>`
    );
  }
  getAgComponents() {
    return [AgInputNumberFieldSelector];
  }
  init(eInput, params) {
    this.eInput = eInput;
    this.params = params;
    if (params.max != null) {
      eInput.setMax(params.max);
    }
    if (params.min != null) {
      eInput.setMin(params.min);
    }
    if (params.precision != null) {
      eInput.setPrecision(params.precision);
    }
    if (params.step != null) {
      eInput.setStep(params.step);
    }
    const inputEl = eInput.getInputElement();
    if (params.preventStepping) {
      eInput.addManagedElementListeners(inputEl, { keydown: this.preventStepping });
    } else if (params.showStepperButtons) {
      inputEl.classList.add("ag-number-field-input-stepper");
    }
  }
  preventStepping(e) {
    if (e.key === KeyCode.UP || e.key === KeyCode.DOWN) {
      e.preventDefault();
    }
  }
  getValue() {
    const value = this.eInput.getValue();
    if (!_exists(value) && !_exists(this.params.value)) {
      return this.params.value;
    }
    let parsedValue = this.params.parseValue(value);
    if (parsedValue == null) {
      return parsedValue;
    }
    if (typeof parsedValue === "string") {
      if (parsedValue === "") {
        return null;
      }
      parsedValue = Number(parsedValue);
    }
    return isNaN(parsedValue) ? null : parsedValue;
  }
  getStartValue() {
    return this.params.value;
  }
};
var NumberCellEditor = class extends SimpleCellEditor {
  constructor() {
    super(new NumberCellEditorInput());
  }
};

// community-modules/core/src/widgets/agInputDateField.ts
var AgInputDateField = class extends AgInputTextField {
  constructor(config) {
    super(config, "ag-date-field", "date");
  }
  postConstruct() {
    super.postConstruct();
    const usingSafari = _isBrowserSafari();
    this.addManagedListeners(this.eInput, {
      wheel: this.onWheel.bind(this),
      mousedown: () => {
        if (this.isDisabled() || usingSafari) {
          return;
        }
        this.eInput.focus();
      }
    });
    this.eInput.step = "any";
  }
  onWheel(e) {
    if (this.gos.getActiveDomElement() === this.eInput) {
      e.preventDefault();
    }
  }
  setMin(minDate) {
    const min = minDate instanceof Date ? _serialiseDate(minDate ?? null, false) ?? void 0 : minDate;
    if (this.min === min) {
      return this;
    }
    this.min = min;
    _addOrRemoveAttribute(this.eInput, "min", min);
    return this;
  }
  setMax(maxDate) {
    const max = maxDate instanceof Date ? _serialiseDate(maxDate ?? null, false) ?? void 0 : maxDate;
    if (this.max === max) {
      return this;
    }
    this.max = max;
    _addOrRemoveAttribute(this.eInput, "max", max);
    return this;
  }
  setStep(step) {
    if (this.step === step) {
      return this;
    }
    this.step = step;
    _addOrRemoveAttribute(this.eInput, "step", step);
    return this;
  }
  getDate() {
    if (!this.eInput.validity.valid) {
      return void 0;
    }
    return _parseDateTimeFromString(this.getValue()) ?? void 0;
  }
  setDate(date, silent) {
    this.setValue(_serialiseDate(date ?? null, false), silent);
  }
};
var AgInputDateFieldSelector = {
  selector: "AG-INPUT-DATE-FIELD",
  component: AgInputDateField
};

// community-modules/core/src/edit/cellEditors/dateCellEditor.ts
var DateCellEditorInput = class {
  getTemplate() {
    return (
      /* html */
      `<ag-input-date-field class="ag-cell-editor" data-ref="eInput"></ag-input-date-field>`
    );
  }
  getAgComponents() {
    return [AgInputDateFieldSelector];
  }
  init(eInput, params) {
    this.eInput = eInput;
    this.params = params;
    if (params.min != null) {
      eInput.setMin(params.min);
    }
    if (params.max != null) {
      eInput.setMax(params.max);
    }
    if (params.step != null) {
      eInput.setStep(params.step);
    }
  }
  getValue() {
    const value = this.eInput.getDate();
    if (!_exists(value) && !_exists(this.params.value)) {
      return this.params.value;
    }
    return value ?? null;
  }
  getStartValue() {
    const { value } = this.params;
    if (!(value instanceof Date)) {
      return void 0;
    }
    return _serialiseDate(value, false);
  }
};
var DateCellEditor = class extends SimpleCellEditor {
  constructor() {
    super(new DateCellEditorInput());
  }
};

// community-modules/core/src/edit/cellEditors/dateStringCellEditor.ts
var DateStringCellEditorInput = class {
  constructor(getDataTypeService) {
    this.getDataTypeService = getDataTypeService;
  }
  getTemplate() {
    return (
      /* html */
      `<ag-input-date-field class="ag-cell-editor" data-ref="eInput"></ag-input-date-field>`
    );
  }
  getAgComponents() {
    return [AgInputDateFieldSelector];
  }
  init(eInput, params) {
    this.eInput = eInput;
    this.params = params;
    if (params.min != null) {
      eInput.setMin(params.min);
    }
    if (params.max != null) {
      eInput.setMax(params.max);
    }
    if (params.step != null) {
      eInput.setStep(params.step);
    }
  }
  getValue() {
    const value = this.formatDate(this.eInput.getDate());
    if (!_exists(value) && !_exists(this.params.value)) {
      return this.params.value;
    }
    return this.params.parseValue(value ?? "");
  }
  getStartValue() {
    return _serialiseDate(this.parseDate(this.params.value ?? void 0) ?? null, false);
  }
  parseDate(value) {
    const dataTypeService = this.getDataTypeService();
    return dataTypeService ? dataTypeService.getDateParserFunction(this.params.column)(value) : _parseDateTimeFromString(value) ?? void 0;
  }
  formatDate(value) {
    const dataTypeService = this.getDataTypeService();
    return dataTypeService ? dataTypeService.getDateFormatterFunction(this.params.column)(value) : _serialiseDate(value ?? null, false) ?? void 0;
  }
};
var DateStringCellEditor = class extends SimpleCellEditor {
  wireBeans(beans) {
    this.dataTypeService = beans.dataTypeService;
  }
  constructor() {
    super(new DateStringCellEditorInput(() => this.dataTypeService));
  }
};

// community-modules/core/src/edit/cellEditors/checkboxCellEditor.ts
var CheckboxCellEditor = class extends PopupComponent {
  constructor() {
    super(
      /* html */
      `
            <div class="ag-cell-wrapper ag-cell-edit-wrapper ag-checkbox-edit">
                <ag-checkbox role="presentation" data-ref="eCheckbox"></ag-checkbox>
            </div>`,
      [AgCheckboxSelector]
    );
    this.eCheckbox = RefPlaceholder;
  }
  init(params) {
    this.params = params;
    const isSelected = params.value ?? void 0;
    this.eCheckbox.setValue(isSelected);
    const inputEl = this.eCheckbox.getInputElement();
    inputEl.setAttribute("tabindex", "-1");
    this.setAriaLabel(isSelected);
    this.addManagedListeners(this.eCheckbox, {
      fieldValueChanged: (event) => this.setAriaLabel(event.selected)
    });
  }
  getValue() {
    return this.eCheckbox.getValue();
  }
  focusIn() {
    this.eCheckbox.getFocusableElement().focus();
  }
  afterGuiAttached() {
    if (this.params.cellStartedEdit) {
      this.focusIn();
    }
  }
  isPopup() {
    return false;
  }
  setAriaLabel(isSelected) {
    const translate = this.localeService.getLocaleTextFunc();
    const stateName = _getAriaCheckboxStateName(translate, isSelected);
    const ariaLabel = translate("ariaToggleCellValue", "Press SPACE to toggle cell value");
    this.eCheckbox.setInputAriaLabel(`${ariaLabel} (${stateName})`);
  }
};

// community-modules/core/src/undoRedo/undoRedoStack.ts
var UndoRedoAction = class {
  constructor(cellValueChanges) {
    this.cellValueChanges = cellValueChanges;
  }
};
var RangeUndoRedoAction = class extends UndoRedoAction {
  constructor(cellValueChanges, initialRange, finalRange, ranges) {
    super(cellValueChanges);
    this.initialRange = initialRange;
    this.finalRange = finalRange;
    this.ranges = ranges;
  }
};
var DEFAULT_STACK_SIZE = 10;
var UndoRedoStack = class {
  constructor(maxStackSize) {
    this.actionStack = [];
    this.maxStackSize = maxStackSize ? maxStackSize : DEFAULT_STACK_SIZE;
    this.actionStack = new Array(this.maxStackSize);
  }
  pop() {
    return this.actionStack.pop();
  }
  push(item) {
    const shouldAddActions = item.cellValueChanges && item.cellValueChanges.length > 0;
    if (!shouldAddActions) {
      return;
    }
    if (this.actionStack.length === this.maxStackSize) {
      this.actionStack.shift();
    }
    this.actionStack.push(item);
  }
  clear() {
    this.actionStack = [];
  }
  getCurrentStackSize() {
    return this.actionStack.length;
  }
};

// community-modules/core/src/undoRedo/undoRedoService.ts
var UndoRedoService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "undoRedoService";
    this.cellValueChanges = [];
    this.activeCellEdit = null;
    this.activeRowEdit = null;
    this.isPasting = false;
    this.isRangeInAction = false;
    this.onCellValueChanged = (event) => {
      const eventCell = { column: event.column, rowIndex: event.rowIndex, rowPinned: event.rowPinned };
      const isCellEditing = this.activeCellEdit !== null && this.cellPositionUtils.equals(this.activeCellEdit, eventCell);
      const isRowEditing = this.activeRowEdit !== null && this.rowPositionUtils.sameRow(this.activeRowEdit, eventCell);
      const shouldCaptureAction = isCellEditing || isRowEditing || this.isPasting || this.isRangeInAction;
      if (!shouldCaptureAction) {
        return;
      }
      const { rowPinned, rowIndex, column, oldValue, value } = event;
      const cellValueChange = {
        rowPinned,
        rowIndex,
        columnId: column.getColId(),
        newValue: value,
        oldValue
      };
      this.cellValueChanges.push(cellValueChange);
    };
    this.clearStacks = () => {
      this.undoStack.clear();
      this.redoStack.clear();
    };
  }
  wireBeans(beans) {
    this.focusService = beans.focusService;
    this.ctrlsService = beans.ctrlsService;
    this.cellPositionUtils = beans.cellPositionUtils;
    this.rowPositionUtils = beans.rowPositionUtils;
    this.columnModel = beans.columnModel;
    this.rangeService = beans.rangeService;
  }
  postConstruct() {
    if (!this.gos.get("undoRedoCellEditing")) {
      return;
    }
    const undoRedoLimit = this.gos.get("undoRedoCellEditingLimit");
    if (undoRedoLimit <= 0) {
      return;
    }
    this.undoStack = new UndoRedoStack(undoRedoLimit);
    this.redoStack = new UndoRedoStack(undoRedoLimit);
    this.addListeners();
    const listener = this.clearStacks.bind(this);
    this.addManagedEventListeners({
      cellValueChanged: this.onCellValueChanged.bind(this),
      // undo / redo is restricted to actual editing so we clear the stacks when other operations are
      // performed that change the order of the row / cols.
      modelUpdated: (e) => {
        if (!e.keepUndoRedoStack) {
          this.clearStacks();
        }
      },
      columnPivotModeChanged: listener,
      newColumnsLoaded: listener,
      columnGroupOpened: listener,
      columnRowGroupChanged: listener,
      columnMoved: listener,
      columnPinned: listener,
      columnVisible: listener,
      rowDragEnd: listener
    });
    this.ctrlsService.whenReady((p) => {
      this.gridBodyCtrl = p.gridBodyCtrl;
    });
  }
  getCurrentUndoStackSize() {
    return this.undoStack ? this.undoStack.getCurrentStackSize() : 0;
  }
  getCurrentRedoStackSize() {
    return this.redoStack ? this.redoStack.getCurrentStackSize() : 0;
  }
  undo(source) {
    const startEvent = {
      type: "undoStarted",
      source
    };
    this.eventService.dispatchEvent(startEvent);
    const operationPerformed = this.undoRedo(this.undoStack, this.redoStack, "initialRange", "oldValue", "undo");
    const endEvent = {
      type: "undoEnded",
      source,
      operationPerformed
    };
    this.eventService.dispatchEvent(endEvent);
  }
  redo(source) {
    const startEvent = {
      type: "redoStarted",
      source
    };
    this.eventService.dispatchEvent(startEvent);
    const operationPerformed = this.undoRedo(this.redoStack, this.undoStack, "finalRange", "newValue", "redo");
    const endEvent = {
      type: "redoEnded",
      source,
      operationPerformed
    };
    this.eventService.dispatchEvent(endEvent);
  }
  undoRedo(undoRedoStack, opposingUndoRedoStack, rangeProperty, cellValueChangeProperty, source) {
    if (!undoRedoStack) {
      return false;
    }
    const undoRedoAction = undoRedoStack.pop();
    if (!undoRedoAction || !undoRedoAction.cellValueChanges) {
      return false;
    }
    this.processAction(
      undoRedoAction,
      (cellValueChange) => cellValueChange[cellValueChangeProperty],
      source
    );
    if (undoRedoAction instanceof RangeUndoRedoAction) {
      this.processRange(this.rangeService, undoRedoAction.ranges || [undoRedoAction[rangeProperty]]);
    } else {
      this.processCell(undoRedoAction.cellValueChanges);
    }
    opposingUndoRedoStack.push(undoRedoAction);
    return true;
  }
  processAction(action, valueExtractor, source) {
    action.cellValueChanges.forEach((cellValueChange) => {
      const { rowIndex, rowPinned, columnId } = cellValueChange;
      const rowPosition = { rowIndex, rowPinned };
      const currentRow = this.rowPositionUtils.getRowNode(rowPosition);
      if (!currentRow.displayed) {
        return;
      }
      currentRow.setDataValue(columnId, valueExtractor(cellValueChange), source);
    });
  }
  processRange(rangeService, ranges) {
    let lastFocusedCell;
    rangeService.removeAllCellRanges(true);
    ranges.forEach((range, idx) => {
      if (!range) {
        return;
      }
      const startRow = range.startRow;
      const endRow = range.endRow;
      if (idx === ranges.length - 1) {
        lastFocusedCell = {
          rowPinned: startRow.rowPinned,
          rowIndex: startRow.rowIndex,
          columnId: range.startColumn.getColId()
        };
        this.setLastFocusedCell(lastFocusedCell);
      }
      const cellRangeParams = {
        rowStartIndex: startRow.rowIndex,
        rowStartPinned: startRow.rowPinned,
        rowEndIndex: endRow.rowIndex,
        rowEndPinned: endRow.rowPinned,
        columnStart: range.startColumn,
        columns: range.columns
      };
      rangeService.addCellRange(cellRangeParams);
    });
  }
  processCell(cellValueChanges) {
    const cellValueChange = cellValueChanges[0];
    const { rowIndex, rowPinned } = cellValueChange;
    const rowPosition = { rowIndex, rowPinned };
    const row = this.rowPositionUtils.getRowNode(rowPosition);
    const lastFocusedCell = {
      rowPinned: cellValueChange.rowPinned,
      rowIndex: row.rowIndex,
      columnId: cellValueChange.columnId
    };
    this.setLastFocusedCell(lastFocusedCell, this.rangeService);
  }
  setLastFocusedCell(lastFocusedCell, rangeService) {
    const { rowIndex, columnId, rowPinned } = lastFocusedCell;
    const scrollFeature = this.gridBodyCtrl.getScrollFeature();
    const column = this.columnModel.getCol(columnId);
    if (!column) {
      return;
    }
    scrollFeature.ensureIndexVisible(rowIndex);
    scrollFeature.ensureColumnVisible(column);
    const cellPosition = { rowIndex, column, rowPinned };
    this.focusService.setFocusedCell({ ...cellPosition, forceBrowserFocus: true });
    rangeService?.setRangeToCell(cellPosition);
  }
  addListeners() {
    this.addManagedEventListeners({
      rowEditingStarted: (e) => {
        this.activeRowEdit = { rowIndex: e.rowIndex, rowPinned: e.rowPinned };
      },
      rowEditingStopped: () => {
        const action = new UndoRedoAction(this.cellValueChanges);
        this.pushActionsToUndoStack(action);
        this.activeRowEdit = null;
      },
      cellEditingStarted: (e) => {
        this.activeCellEdit = { column: e.column, rowIndex: e.rowIndex, rowPinned: e.rowPinned };
      },
      cellEditingStopped: (e) => {
        this.activeCellEdit = null;
        const shouldPushAction = e.valueChanged && !this.activeRowEdit && !this.isPasting && !this.isRangeInAction;
        if (shouldPushAction) {
          const action = new UndoRedoAction(this.cellValueChanges);
          this.pushActionsToUndoStack(action);
        }
      },
      pasteStart: () => {
        this.isPasting = true;
      },
      pasteEnd: () => {
        const action = new UndoRedoAction(this.cellValueChanges);
        this.pushActionsToUndoStack(action);
        this.isPasting = false;
      },
      fillStart: () => {
        this.isRangeInAction = true;
      },
      fillEnd: (event) => {
        const action = new RangeUndoRedoAction(this.cellValueChanges, event.initialRange, event.finalRange);
        this.pushActionsToUndoStack(action);
        this.isRangeInAction = false;
      },
      keyShortcutChangedCellStart: () => {
        this.isRangeInAction = true;
      },
      keyShortcutChangedCellEnd: () => {
        let action;
        if (this.rangeService && this.gos.get("enableRangeSelection")) {
          action = new RangeUndoRedoAction(this.cellValueChanges, void 0, void 0, [
            ...this.rangeService.getCellRanges()
          ]);
        } else {
          action = new UndoRedoAction(this.cellValueChanges);
        }
        this.pushActionsToUndoStack(action);
        this.isRangeInAction = false;
      }
    });
  }
  pushActionsToUndoStack(action) {
    this.undoStack.push(action);
    this.cellValueChanges = [];
    this.redoStack.clear();
  }
};

// community-modules/core/src/api/scrollApi.ts
function getVerticalPixelRange(beans) {
  return beans.ctrlsService.getGridBodyCtrl().getScrollFeature().getVScrollPosition();
}
function getHorizontalPixelRange(beans) {
  return beans.ctrlsService.getGridBodyCtrl().getScrollFeature().getHScrollPosition();
}
function ensureColumnVisible(beans, key, position = "auto") {
  beans.frameworkOverrides.wrapIncoming(
    () => beans.ctrlsService.getGridBodyCtrl().getScrollFeature().ensureColumnVisible(key, position),
    "ensureVisible"
  );
}
function ensureIndexVisible(beans, index, position) {
  beans.frameworkOverrides.wrapIncoming(
    () => beans.ctrlsService.getGridBodyCtrl().getScrollFeature().ensureIndexVisible(index, position),
    "ensureVisible"
  );
}
function ensureNodeVisible(beans, nodeSelector, position = null) {
  beans.frameworkOverrides.wrapIncoming(
    () => beans.ctrlsService.getGridBodyCtrl().getScrollFeature().ensureNodeVisible(nodeSelector, position),
    "ensureVisible"
  );
}

// community-modules/core/src/edit/editApi.ts
function undoCellEditing(beans) {
  beans.undoRedoService?.undo("api");
}
function redoCellEditing(beans) {
  beans.undoRedoService?.redo("api");
}
function getCellEditorInstances(beans, params = {}) {
  const res = beans.rowRenderer.getCellEditorInstances(params);
  const unwrapped = res.map(_unwrapUserComp);
  return unwrapped;
}
function getEditingCells(beans) {
  return beans.rowRenderer.getEditingCells();
}
function stopEditing(beans, cancel = false) {
  beans.rowRenderer.stopEditing(cancel);
}
function startEditingCell(beans, params) {
  const column = beans.columnModel.getCol(params.colKey);
  if (!column) {
    _warnOnce(`no column found for ${params.colKey}`);
    return;
  }
  const cellPosition = {
    rowIndex: params.rowIndex,
    rowPinned: params.rowPinned || null,
    column
  };
  const notPinned = params.rowPinned == null;
  if (notPinned) {
    ensureIndexVisible(beans, params.rowIndex);
  }
  ensureColumnVisible(beans, params.colKey);
  const cell = beans.navigationService.getCellByPosition(cellPosition);
  if (!cell) {
    return;
  }
  if (!beans.focusService.isCellFocused(cellPosition)) {
    beans.focusService.setFocusedCell(cellPosition);
  }
  cell.startRowOrCellEdit(params.key);
}
function getCurrentUndoSize(beans) {
  return beans.undoRedoService?.getCurrentUndoStackSize() ?? 0;
}
function getCurrentRedoSize(beans) {
  return beans.undoRedoService?.getCurrentRedoStackSize() ?? 0;
}

// community-modules/core/src/edit/editService.ts
var EditService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "editService";
  }
  wireBeans(beans) {
    this.navigationService = beans.navigationService;
    this.userComponentFactory = beans.userComponentFactory;
    this.valueService = beans.valueService;
  }
  startEditing(cellCtrl, key = null, cellStartedEdit = false, event = null) {
    const editorParams = this.createCellEditorParams(cellCtrl, key, cellStartedEdit);
    const colDef = cellCtrl.getColumn().getColDef();
    const compDetails = this.userComponentFactory.getCellEditorDetails(colDef, editorParams);
    const popup = compDetails?.popupFromSelector != null ? compDetails.popupFromSelector : !!colDef.cellEditorPopup;
    const position = compDetails?.popupPositionFromSelector != null ? compDetails.popupPositionFromSelector : colDef.cellEditorPopupPosition;
    cellCtrl.setEditing(true, compDetails);
    cellCtrl.getComp().setEditDetails(compDetails, popup, position, this.gos.get("reactiveCustomComponents"));
    const e = cellCtrl.createEvent(event, "cellEditingStarted");
    this.eventService.dispatchEvent(e);
  }
  stopEditing(cellCtrl, cancel) {
    const cellComp = cellCtrl.getComp();
    const { newValue, newValueExists } = this.takeValueFromCellEditor(cancel, cellComp);
    const rowNode = cellCtrl.getRowNode();
    const column = cellCtrl.getColumn();
    const oldValue = rowNode.getValueFromValueService(column);
    let valueChanged = false;
    if (newValueExists) {
      valueChanged = this.saveNewValue(cellCtrl, oldValue, newValue, rowNode, column);
    }
    cellCtrl.setEditing(false, void 0);
    cellComp.setEditDetails();
    cellCtrl.updateAndFormatValue(false);
    cellCtrl.refreshCell({ forceRefresh: true, suppressFlash: true });
    const event = {
      ...cellCtrl.createEvent(null, "cellEditingStopped"),
      oldValue,
      newValue,
      valueChanged
    };
    this.eventService.dispatchEvent(event);
    return valueChanged;
  }
  handleColDefChanged(cellCtrl) {
    const cellEditor = cellCtrl.getCellEditor();
    if (cellEditor?.refresh) {
      const { eventKey, cellStartedEdit } = cellCtrl.getEditCompDetails().params;
      const editorParams = this.createCellEditorParams(cellCtrl, eventKey, cellStartedEdit);
      const colDef = cellCtrl.getColumn().getColDef();
      const compDetails = this.userComponentFactory.getCellEditorDetails(colDef, editorParams);
      cellEditor.refresh(compDetails.params);
    }
  }
  setFocusOutOnEditor(cellCtrl) {
    const cellEditor = cellCtrl.getComp().getCellEditor();
    if (cellEditor && cellEditor.focusOut) {
      cellEditor.focusOut();
    }
  }
  setFocusInOnEditor(cellCtrl) {
    const cellEditor = cellCtrl.getComp().getCellEditor();
    if (cellEditor && cellEditor.focusIn) {
      cellEditor.focusIn();
    } else {
      cellCtrl.focusCell(true);
    }
  }
  stopEditingAndFocus(cellCtrl, suppressNavigateAfterEdit = false, shiftKey = false) {
    cellCtrl.stopRowOrCellEdit();
    cellCtrl.focusCell(true);
    if (!suppressNavigateAfterEdit) {
      this.navigateAfterEdit(shiftKey, cellCtrl.getCellPosition());
    }
  }
  createPopupEditorWrapper(params) {
    return new PopupEditorWrapper(params);
  }
  takeValueFromCellEditor(cancel, cellComp) {
    const noValueResult = { newValueExists: false };
    if (cancel) {
      return noValueResult;
    }
    const cellEditor = cellComp.getCellEditor();
    if (!cellEditor) {
      return noValueResult;
    }
    const userWantsToCancel = cellEditor.isCancelAfterEnd && cellEditor.isCancelAfterEnd();
    if (userWantsToCancel) {
      return noValueResult;
    }
    const newValue = cellEditor.getValue();
    return {
      newValue,
      newValueExists: true
    };
  }
  /**
   * @returns `True` if the value changes, otherwise `False`.
   */
  saveNewValue(cellCtrl, oldValue, newValue, rowNode, column) {
    if (newValue === oldValue) {
      return false;
    }
    cellCtrl.setSuppressRefreshCell(true);
    const valueChanged = rowNode.setDataValue(column, newValue, "edit");
    cellCtrl.setSuppressRefreshCell(false);
    return valueChanged;
  }
  createCellEditorParams(cellCtrl, key, cellStartedEdit) {
    const column = cellCtrl.getColumn();
    const rowNode = cellCtrl.getRowNode();
    return this.gos.addGridCommonParams({
      value: rowNode.getValueFromValueService(column),
      eventKey: key,
      column,
      colDef: column.getColDef(),
      rowIndex: cellCtrl.getCellPosition().rowIndex,
      node: rowNode,
      data: rowNode.data,
      cellStartedEdit,
      onKeyDown: cellCtrl.onKeyDown.bind(cellCtrl),
      stopEditing: cellCtrl.stopEditingAndFocus.bind(cellCtrl),
      eGridCell: cellCtrl.getGui(),
      parseValue: (newValue) => this.valueService.parseValue(column, rowNode, newValue, cellCtrl.getValue()),
      formatValue: cellCtrl.formatValue.bind(cellCtrl)
    });
  }
  navigateAfterEdit(shiftKey, cellPosition) {
    const enterNavigatesVerticallyAfterEdit = this.gos.get("enterNavigatesVerticallyAfterEdit");
    if (enterNavigatesVerticallyAfterEdit) {
      const key = shiftKey ? KeyCode.UP : KeyCode.DOWN;
      this.navigationService.navigateToNextCell(null, key, cellPosition, false);
    }
  }
};

// community-modules/core/src/edit/rowEditService.ts
var RowEditService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "rowEditService";
  }
  startEditing(rowCtrl, key = null, sourceRenderedCell = null, event = null) {
    const atLeastOneEditing = rowCtrl.getAllCellCtrls().reduce((prev, cellCtrl) => {
      const cellStartedEdit = cellCtrl === sourceRenderedCell;
      if (cellStartedEdit) {
        cellCtrl.startEditing(key, cellStartedEdit, event);
      } else {
        cellCtrl.startEditing(null, cellStartedEdit, event);
      }
      if (prev) {
        return true;
      }
      return cellCtrl.isEditing();
    }, false);
    if (atLeastOneEditing) {
      this.setEditing(rowCtrl, true);
    }
  }
  stopEditing(rowCtrl, cancel = false) {
    const cellControls = rowCtrl.getAllCellCtrls();
    const isRowEdit = rowCtrl.isEditing();
    rowCtrl.setStoppingRowEdit(true);
    let fireRowEditEvent = false;
    for (const ctrl of cellControls) {
      const valueChanged = ctrl.stopEditing(cancel);
      if (isRowEdit && !cancel && !fireRowEditEvent && valueChanged) {
        fireRowEditEvent = true;
      }
    }
    if (fireRowEditEvent) {
      const event = rowCtrl.createRowEvent("rowValueChanged");
      this.eventService.dispatchEvent(event);
    }
    if (isRowEdit) {
      this.setEditing(rowCtrl, false);
    }
    rowCtrl.setStoppingRowEdit(false);
  }
  setEditing(rowCtrl, value) {
    rowCtrl.setEditingRow(value);
    rowCtrl.forEachGui(void 0, (gui) => gui.rowComp.addOrRemoveCssClass("ag-row-editing", value));
    const event = value ? rowCtrl.createRowEvent("rowEditingStarted") : rowCtrl.createRowEvent("rowEditingStopped");
    this.eventService.dispatchEvent(event);
  }
};

// community-modules/core/src/edit/editModule.ts
var EditCoreModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/edit-core",
  beans: [EditService]
};
var EditApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/edit-api",
  apiFunctions: {
    undoCellEditing,
    redoCellEditing,
    getCellEditorInstances,
    getEditingCells,
    stopEditing,
    startEditingCell,
    getCurrentUndoSize,
    getCurrentRedoSize
  },
  dependantModules: [EditCoreModule]
};
var UndoRedoEditModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/undo-redo-edit",
  beans: [UndoRedoService],
  dependantModules: [EditCoreModule]
};
var FullRowEditModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/full-row-edit",
  beans: [RowEditService],
  dependantModules: [EditCoreModule]
};
var DefaultEditorModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/default-editor",
  userComponents: [{ name: "agCellEditor", classImp: TextCellEditor }],
  dependantModules: [EditCoreModule]
};
var DataTypeEditorsModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/data-type-editors",
  userComponents: [
    { name: "agTextCellEditor", classImp: TextCellEditor },
    { name: "agNumberCellEditor", classImp: NumberCellEditor },
    { name: "agDateCellEditor", classImp: DateCellEditor },
    { name: "agDateStringCellEditor", classImp: DateStringCellEditor },
    { name: "agCheckboxCellEditor", classImp: CheckboxCellEditor }
  ],
  dependantModules: [DefaultEditorModule]
};
var SelectEditorModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/select-editor",
  userComponents: [{ name: "agSelectCellEditor", classImp: SelectCellEditor }],
  dependantModules: [EditCoreModule]
};
var LargeTextEditorModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/large-text-editor",
  userComponents: [{ name: "agLargeTextCellEditor", classImp: LargeTextCellEditor }],
  dependantModules: [EditCoreModule]
};
var AllCommunityEditorsModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/all-editors",
  dependantModules: [DefaultEditorModule, DataTypeEditorsModule, SelectEditorModule, LargeTextEditorModule]
};
var EditModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/editing",
  dependantModules: [EditCoreModule, UndoRedoEditModule, FullRowEditModule, AllCommunityEditorsModule, EditApiModule]
};

// community-modules/core/src/rendering/autoWidthCalculator.ts
var AutoWidthCalculator = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "autoWidthCalculator";
  }
  wireBeans(beans) {
    this.rowRenderer = beans.rowRenderer;
    this.ctrlsService = beans.ctrlsService;
  }
  postConstruct() {
    this.ctrlsService.whenReady((p) => {
      this.centerRowContainerCtrl = p.center;
    });
  }
  // this is the trick: we create a dummy container and clone all the cells
  // into the dummy, then check the dummy's width. then destroy the dummy
  // as we don't need it any more.
  // drawback: only the cells visible on the screen are considered
  getPreferredWidthForColumn(column, skipHeader) {
    const eHeaderCell = this.getHeaderCellForColumn(column);
    if (!eHeaderCell) {
      return -1;
    }
    const elements = this.rowRenderer.getAllCellsForColumn(column);
    if (!skipHeader) {
      elements.push(eHeaderCell);
    }
    return this.addElementsToContainerAndGetWidth(elements);
  }
  getPreferredWidthForColumnGroup(columnGroup) {
    const eHeaderCell = this.getHeaderCellForColumn(columnGroup);
    if (!eHeaderCell) {
      return -1;
    }
    return this.addElementsToContainerAndGetWidth([eHeaderCell]);
  }
  addElementsToContainerAndGetWidth(elements) {
    const eDummyContainer = document.createElement("form");
    eDummyContainer.style.position = "fixed";
    const eBodyContainer = this.centerRowContainerCtrl.getContainerElement();
    elements.forEach((el) => this.cloneItemIntoDummy(el, eDummyContainer));
    eBodyContainer.appendChild(eDummyContainer);
    const dummyContainerWidth = eDummyContainer.offsetWidth;
    eBodyContainer.removeChild(eDummyContainer);
    const autoSizePadding = this.getAutoSizePadding();
    return dummyContainerWidth + autoSizePadding;
  }
  getAutoSizePadding() {
    return this.gos.get("autoSizePadding");
  }
  getHeaderCellForColumn(column) {
    let element = null;
    this.ctrlsService.getHeaderRowContainerCtrls().forEach((container) => {
      const res = container.getHtmlElementForColumnHeader(column);
      if (res != null) {
        element = res;
      }
    });
    return element;
  }
  cloneItemIntoDummy(eCell, eDummyContainer) {
    const eCellClone = eCell.cloneNode(true);
    eCellClone.style.width = "";
    eCellClone.style.position = "static";
    eCellClone.style.left = "";
    const eCloneParent = document.createElement("div");
    const eCloneParentClassList = eCloneParent.classList;
    const isHeader = ["ag-header-cell", "ag-header-group-cell"].some((cls) => eCellClone.classList.contains(cls));
    if (isHeader) {
      eCloneParentClassList.add("ag-header", "ag-header-row");
      eCloneParent.style.position = "static";
    } else {
      eCloneParentClassList.add("ag-row");
    }
    let pointer = eCell.parentElement;
    while (pointer) {
      const isRow = ["ag-header-row", "ag-row"].some((cls) => pointer.classList.contains(cls));
      if (isRow) {
        for (let i = 0; i < pointer.classList.length; i++) {
          const item = pointer.classList[i];
          if (item != "ag-row-position-absolute") {
            eCloneParentClassList.add(item);
          }
        }
        break;
      }
      pointer = pointer.parentElement;
    }
    eCloneParent.appendChild(eCellClone);
    eDummyContainer.appendChild(eCloneParent);
  }
};

// community-modules/core/src/rendering/features/stickyRowFeature.ts
var StickyRowFeature = class extends BeanStub {
  constructor(createRowCon, destroyRowCtrls) {
    super();
    this.createRowCon = createRowCon;
    this.destroyRowCtrls = destroyRowCtrls;
    this.stickyTopRowCtrls = [];
    this.stickyBottomRowCtrls = [];
    this.topContainerHeight = 0;
    this.bottomContainerHeight = 0;
    // sticky rows pulls in extra rows from other pages which impacts row position
    this.extraTopHeight = 0;
    this.extraBottomHeight = 0;
  }
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
    this.rowRenderer = beans.rowRenderer;
    this.ctrlsService = beans.ctrlsService;
    this.pageBoundsService = beans.pageBoundsService;
  }
  postConstruct() {
    this.isClientSide = this.rowModel.getType() === "clientSide";
    this.ctrlsService.whenReady((params) => {
      this.gridBodyCtrl = params.gridBodyCtrl;
    });
  }
  getStickyTopRowCtrls() {
    return this.stickyTopRowCtrls;
  }
  getStickyBottomRowCtrls() {
    return this.stickyBottomRowCtrls;
  }
  setOffsetTop(offset) {
    if (this.extraTopHeight === offset) {
      return;
    }
    this.extraTopHeight = offset;
    const event = {
      type: "stickyTopOffsetChanged",
      offset
    };
    this.eventService.dispatchEvent(event);
  }
  setOffsetBottom(offset) {
    if (this.extraBottomHeight === offset) {
      return;
    }
    this.extraBottomHeight = offset;
  }
  resetOffsets() {
    this.setOffsetBottom(0);
    this.setOffsetTop(0);
  }
  getExtraTopHeight() {
    return this.extraTopHeight;
  }
  getExtraBottomHeight() {
    return this.extraBottomHeight;
  }
  /**
   * Get the last pixel of the group, this pixel is used to push the sticky node up out of the viewport.
   */
  getLastPixelOfGroup(row) {
    return this.isClientSide ? this.getClientSideLastPixelOfGroup(row) : this.getServerSideLastPixelOfGroup(row);
  }
  /**
   * Get the first pixel of the group, this pixel is used to push the sticky node down out of the viewport
   */
  getFirstPixelOfGroup(row) {
    if (row.footer) {
      return row.sibling.rowTop + row.sibling.rowHeight - 1;
    }
    if (row.hasChildren()) {
      return row.rowTop - 1;
    }
    return 0;
  }
  getServerSideLastPixelOfGroup(row) {
    if (this.isClientSide) {
      throw new Error("This func should only be called in server side row model.");
    }
    if (row.isExpandable() || row.footer) {
      if (row.master) {
        return row.detailNode.rowTop + row.detailNode.rowHeight;
      }
      const noOrContiguousSiblings = !row.sibling || Math.abs(row.sibling.rowIndex - row.rowIndex) === 1;
      if (noOrContiguousSiblings) {
        let storeBounds = row.childStore?.getStoreBounds();
        if (row.footer) {
          storeBounds = row.sibling.childStore?.getStoreBounds();
        }
        return (storeBounds?.heightPx ?? 0) + (storeBounds?.topPx ?? 0);
      }
      if (row.footer) {
        return row.rowTop + row.rowHeight;
      }
      return row.sibling.rowTop + row.sibling.rowHeight;
    }
    return Number.MAX_SAFE_INTEGER;
  }
  getClientSideLastPixelOfGroup(row) {
    if (!this.isClientSide) {
      throw new Error("This func should only be called in client side row model.");
    }
    if (row.isExpandable() || row.footer) {
      const grandTotalAtTop = row.footer && row.rowIndex === 0;
      if (grandTotalAtTop) {
        return Number.MAX_SAFE_INTEGER;
      }
      const noOrContiguousSiblings = !row.sibling || Math.abs(row.sibling.rowIndex - row.rowIndex) === 1;
      if (noOrContiguousSiblings) {
        let lastAncestor = row.footer ? row.sibling : row;
        while (lastAncestor.isExpandable() && lastAncestor.expanded) {
          if (lastAncestor.master) {
            lastAncestor = lastAncestor.detailNode;
          } else if (lastAncestor.childrenAfterSort) {
            if (lastAncestor.childrenAfterSort.length === 0) {
              break;
            }
            lastAncestor = _last(lastAncestor.childrenAfterSort);
          }
        }
        return lastAncestor.rowTop + lastAncestor.rowHeight;
      }
      if (row.footer) {
        return row.rowTop + row.rowHeight;
      }
      return row.sibling.rowTop + row.sibling.rowHeight;
    }
    return Number.MAX_SAFE_INTEGER;
  }
  updateStickyRows(container) {
    const isTop = container === "top";
    let newStickyContainerHeight = 0;
    if (!this.canRowsBeSticky()) {
      return this.refreshNodesAndContainerHeight(container, /* @__PURE__ */ new Set(), newStickyContainerHeight);
    }
    const pixelAtContainerBoundary = isTop ? this.rowRenderer.getFirstVisibleVerticalPixel() - this.extraTopHeight : this.rowRenderer.getLastVisibleVerticalPixel() - this.extraTopHeight;
    const newStickyRows = /* @__PURE__ */ new Set();
    const addStickyRow = (stickyRow) => {
      newStickyRows.add(stickyRow);
      if (isTop) {
        const lastChildBottom = this.getLastPixelOfGroup(stickyRow);
        const stickRowBottom = pixelAtContainerBoundary + newStickyContainerHeight + stickyRow.rowHeight;
        if (lastChildBottom < stickRowBottom) {
          stickyRow.stickyRowTop = newStickyContainerHeight + (lastChildBottom - stickRowBottom);
        } else {
          stickyRow.stickyRowTop = newStickyContainerHeight;
        }
      } else {
        const lastChildBottom = this.getFirstPixelOfGroup(stickyRow);
        const stickRowTop = pixelAtContainerBoundary - (newStickyContainerHeight + stickyRow.rowHeight);
        if (lastChildBottom > stickRowTop) {
          stickyRow.stickyRowTop = newStickyContainerHeight - (lastChildBottom - stickRowTop);
        } else {
          stickyRow.stickyRowTop = newStickyContainerHeight;
        }
      }
      newStickyContainerHeight = 0;
      newStickyRows.forEach((rowNode) => {
        const thisRowLastPx = rowNode.stickyRowTop + rowNode.rowHeight;
        if (newStickyContainerHeight < thisRowLastPx) {
          newStickyContainerHeight = thisRowLastPx;
        }
      });
    };
    const suppressFootersSticky = this.areFooterRowsStickySuppressed();
    const suppressGroupsSticky = this.gos.get("suppressGroupRowsSticky");
    const isRowSticky = (row) => {
      if (!row.displayed) {
        return false;
      }
      if (row.footer) {
        if (suppressFootersSticky === true) {
          return false;
        }
        if (suppressFootersSticky === "grand" && row.level === -1) {
          return false;
        }
        if (suppressFootersSticky === "group" && row.level > -1) {
          return false;
        }
        const alreadySticking = newStickyRows.has(row);
        return !alreadySticking;
      }
      if (row.isExpandable()) {
        if (suppressGroupsSticky === true) {
          return false;
        }
        const alreadySticking = newStickyRows.has(row);
        return !alreadySticking && row.expanded;
      }
      return false;
    };
    for (let i = 0; i < 100; i++) {
      let firstPixelAfterStickyRows = pixelAtContainerBoundary + newStickyContainerHeight;
      if (!isTop) {
        firstPixelAfterStickyRows = pixelAtContainerBoundary - newStickyContainerHeight;
      }
      const firstIndex = this.rowModel.getRowIndexAtPixel(firstPixelAfterStickyRows);
      const firstRow = this.rowModel.getRow(firstIndex);
      if (firstRow == null) {
        break;
      }
      const ancestors = this.getStickyAncestors(firstRow);
      const firstMissingParent = ancestors.find(
        (parent) => (isTop ? parent.rowIndex < firstIndex : parent.rowIndex > firstIndex) && isRowSticky(parent)
      );
      if (firstMissingParent) {
        addStickyRow(firstMissingParent);
        continue;
      }
      const isFirstRowOutsideViewport = isTop ? firstRow.rowTop < firstPixelAfterStickyRows : firstRow.rowTop + firstRow.rowHeight > firstPixelAfterStickyRows;
      if (isFirstRowOutsideViewport && isRowSticky(firstRow)) {
        addStickyRow(firstRow);
        continue;
      }
      break;
    }
    if (!isTop) {
      newStickyRows.forEach((rowNode) => {
        rowNode.stickyRowTop = newStickyContainerHeight - (rowNode.stickyRowTop + rowNode.rowHeight);
      });
    }
    return this.refreshNodesAndContainerHeight(container, newStickyRows, newStickyContainerHeight);
  }
  areFooterRowsStickySuppressed() {
    const suppressFootersSticky = this.gos.get("suppressStickyTotalRow");
    if (suppressFootersSticky === true) {
      return true;
    }
    const suppressGroupRows = !!this.gos.get("groupIncludeFooter") || suppressFootersSticky === "group";
    const suppressGrandRows = !!this.gos.get("groupIncludeTotalFooter") || suppressFootersSticky === "grand";
    if (suppressGroupRows && suppressGrandRows) {
      return true;
    }
    if (suppressGrandRows) {
      return "grand";
    }
    if (suppressGroupRows) {
      return "group";
    }
    return false;
  }
  canRowsBeSticky() {
    const isStickyEnabled = this.gos.isGroupRowsSticky();
    const suppressFootersSticky = this.areFooterRowsStickySuppressed();
    const suppressGroupsSticky = this.gos.get("suppressGroupRowsSticky");
    return isStickyEnabled && (!suppressFootersSticky || !suppressGroupsSticky);
  }
  getStickyAncestors(rowNode) {
    const ancestors = [];
    let p = rowNode.footer ? rowNode.sibling : rowNode.parent;
    while (p) {
      if (p.sibling) {
        ancestors.push(p.sibling);
      }
      ancestors.push(p);
      p = p.parent;
    }
    return ancestors.reverse();
  }
  checkStickyRows() {
    const hasTopUpdated = this.updateStickyRows("top");
    const hasBottomUpdated = this.updateStickyRows("bottom");
    return hasTopUpdated || hasBottomUpdated;
  }
  destroyStickyCtrls() {
    this.refreshNodesAndContainerHeight("top", /* @__PURE__ */ new Set(), 0);
    this.refreshNodesAndContainerHeight("bottom", /* @__PURE__ */ new Set(), 0);
  }
  refreshStickyNode(stickRowNode) {
    const allStickyNodes = /* @__PURE__ */ new Set();
    if (this.stickyTopRowCtrls.some((ctrl) => ctrl.getRowNode() === stickRowNode)) {
      for (let i = 0; i < this.stickyTopRowCtrls.length; i++) {
        const currentNode = this.stickyTopRowCtrls[i].getRowNode();
        if (currentNode !== stickRowNode) {
          allStickyNodes.add(currentNode);
        }
      }
      if (this.refreshNodesAndContainerHeight("top", allStickyNodes, this.topContainerHeight)) {
        this.checkStickyRows();
      }
      return;
    }
    for (let i = 0; i < this.stickyBottomRowCtrls.length; i++) {
      const currentNode = this.stickyBottomRowCtrls[i].getRowNode();
      if (currentNode !== stickRowNode) {
        allStickyNodes.add(currentNode);
      }
    }
    if (this.refreshNodesAndContainerHeight("bottom", allStickyNodes, this.bottomContainerHeight)) {
      this.checkStickyRows();
    }
  }
  /**
   * Destroy old ctrls and create new ctrls where necessary.
   */
  refreshNodesAndContainerHeight(container, newStickyNodes, height) {
    const isTop = container === "top";
    const previousCtrls = isTop ? this.stickyTopRowCtrls : this.stickyBottomRowCtrls;
    const removedCtrlsMap = {};
    const remainingCtrls = [];
    for (let i = 0; i < previousCtrls.length; i++) {
      const node = previousCtrls[i].getRowNode();
      const hasBeenRemoved = !newStickyNodes.has(node);
      if (hasBeenRemoved) {
        removedCtrlsMap[node.id] = previousCtrls[i];
        node.sticky = false;
        continue;
      }
      remainingCtrls.push(previousCtrls[i]);
    }
    const existingNodes = /* @__PURE__ */ new Set();
    for (let i = 0; i < remainingCtrls.length; i++) {
      existingNodes.add(remainingCtrls[i].getRowNode());
    }
    const newCtrls = [];
    newStickyNodes.forEach((node) => {
      if (existingNodes.has(node)) {
        return;
      }
      node.sticky = true;
      newCtrls.push(this.createRowCon(node, false, false));
    });
    let hasSomethingChanged = !!newCtrls.length || remainingCtrls.length !== previousCtrls.length;
    if (isTop) {
      if (this.topContainerHeight !== height) {
        this.topContainerHeight = height;
        this.gridBodyCtrl.setStickyTopHeight(height);
        hasSomethingChanged = true;
      }
    } else {
      if (this.bottomContainerHeight !== height) {
        this.bottomContainerHeight = height;
        this.gridBodyCtrl.setStickyBottomHeight(height);
        hasSomethingChanged = true;
      }
    }
    this.destroyRowCtrls(removedCtrlsMap, false);
    const newCtrlsList = [...remainingCtrls, ...newCtrls];
    newCtrlsList.sort((a, b) => b.getRowNode().rowIndex - a.getRowNode().rowIndex);
    if (!isTop) {
      newCtrlsList.reverse();
    }
    newCtrlsList.forEach((ctrl) => ctrl.setRowTop(ctrl.getRowNode().stickyRowTop));
    let extraHeight = 0;
    if (isTop) {
      newStickyNodes.forEach((node) => {
        if (node.rowIndex < this.pageBoundsService.getFirstRow()) {
          extraHeight += node.rowHeight;
        }
      });
      if (extraHeight > this.topContainerHeight) {
        extraHeight = this.topContainerHeight;
      }
      this.setOffsetTop(extraHeight);
    } else {
      newStickyNodes.forEach((node) => {
        if (node.rowIndex > this.pageBoundsService.getLastRow()) {
          extraHeight += node.rowHeight;
        }
      });
      if (extraHeight > this.bottomContainerHeight) {
        extraHeight = this.bottomContainerHeight;
      }
      this.setOffsetBottom(extraHeight);
    }
    if (!hasSomethingChanged) {
      return false;
    }
    if (isTop) {
      this.stickyTopRowCtrls = newCtrlsList;
    } else {
      this.stickyBottomRowCtrls = newCtrlsList;
    }
    return true;
  }
  ensureRowHeightsValid() {
    let anyChange = false;
    const updateRowHeight = (ctrl) => {
      const rowNode = ctrl.getRowNode();
      if (rowNode.rowHeightEstimated) {
        const rowHeight = this.gos.getRowHeightForNode(rowNode);
        rowNode.setRowHeight(rowHeight.height);
        anyChange = true;
      }
    };
    this.stickyTopRowCtrls.forEach(updateRowHeight);
    this.stickyBottomRowCtrls.forEach(updateRowHeight);
    return anyChange;
  }
};

// community-modules/core/src/rendering/rowRenderer.ts
var RowRenderer = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "rowRenderer";
    this.destroyFuncsForColumnListeners = [];
    // map of row ids to row objects. keeps track of which elements
    // are rendered for which rows in the dom.
    this.rowCtrlsByRowIndex = {};
    this.zombieRowCtrls = {};
    this.allRowCtrls = [];
    this.topRowCtrls = [];
    this.bottomRowCtrls = [];
    // we only allow one refresh at a time, otherwise the internal memory structure here
    // will get messed up. this can happen if the user has a cellRenderer, and inside the
    // renderer they call an API method that results in another pass of the refresh,
    // then it will be trying to draw rows in the middle of a refresh.
    this.refreshInProgress = false;
    this.dataFirstRenderedFired = false;
    this.setupRangeSelectionListeners = () => {
      const onRangeSelectionChanged = () => {
        this.getAllCellCtrls().forEach((cellCtrl) => cellCtrl.onRangeSelectionChanged());
      };
      const onColumnMovedPinnedVisible = () => {
        this.getAllCellCtrls().forEach((cellCtrl) => cellCtrl.updateRangeBordersIfRangeCount());
      };
      const addRangeSelectionListeners = () => {
        this.eventService.addEventListener("rangeSelectionChanged", onRangeSelectionChanged);
        this.eventService.addEventListener("columnMoved", onColumnMovedPinnedVisible);
        this.eventService.addEventListener("columnPinned", onColumnMovedPinnedVisible);
        this.eventService.addEventListener("columnVisible", onColumnMovedPinnedVisible);
      };
      const removeRangeSelectionListeners = () => {
        this.eventService.removeEventListener("rangeSelectionChanged", onRangeSelectionChanged);
        this.eventService.removeEventListener("columnMoved", onColumnMovedPinnedVisible);
        this.eventService.removeEventListener("columnPinned", onColumnMovedPinnedVisible);
        this.eventService.removeEventListener("columnVisible", onColumnMovedPinnedVisible);
      };
      this.addDestroyFunc(() => removeRangeSelectionListeners());
      this.addManagedPropertyListener("enableRangeSelection", (params) => {
        const isEnabled = params.currentValue;
        if (isEnabled) {
          addRangeSelectionListeners();
        } else {
          removeRangeSelectionListeners();
        }
      });
      const rangeSelectionEnabled = this.gos.get("enableRangeSelection");
      if (rangeSelectionEnabled) {
        addRangeSelectionListeners();
      }
    };
  }
  wireBeans(beans) {
    this.animationFrameService = beans.animationFrameService;
    this.paginationService = beans.paginationService;
    this.pageBoundsService = beans.pageBoundsService;
    this.columnModel = beans.columnModel;
    this.visibleColsService = beans.visibleColsService;
    this.pinnedRowModel = beans.pinnedRowModel;
    this.rowModel = beans.rowModel;
    this.focusService = beans.focusService;
    this.beans = beans;
    this.rowContainerHeightService = beans.rowContainerHeightService;
    this.ctrlsService = beans.ctrlsService;
    this.environment = beans.environment;
  }
  postConstruct() {
    this.ctrlsService.whenReady((p) => {
      this.gridBodyCtrl = p.gridBodyCtrl;
      this.initialise();
    });
  }
  initialise() {
    this.addManagedEventListeners({
      paginationChanged: this.onPageLoaded.bind(this),
      pinnedRowDataChanged: this.onPinnedRowDataChanged.bind(this),
      displayedColumnsChanged: this.onDisplayedColumnsChanged.bind(this),
      bodyScroll: this.onBodyScroll.bind(this),
      bodyHeightChanged: this.redraw.bind(this)
    });
    this.addManagedPropertyListeners(["domLayout", "embedFullWidthRows"], () => this.onDomLayoutChanged());
    this.addManagedPropertyListeners(["suppressMaxRenderedRowRestriction", "rowBuffer"], () => this.redraw());
    this.addManagedPropertyListeners(
      [
        "suppressCellFocus",
        "getBusinessKeyForNode",
        "fullWidthCellRenderer",
        "fullWidthCellRendererParams",
        "rowStyle",
        "getRowStyle",
        "rowClass",
        "getRowClass",
        "rowClassRules",
        "suppressStickyTotalRow",
        "groupRowRenderer",
        "groupRowRendererParams",
        // maybe only needs to refresh FW rows...
        "loadingCellRenderer",
        "loadingCellRendererParams",
        "detailCellRenderer",
        "detailCellRendererParams",
        "enableRangeSelection",
        "enableCellTextSelection"
      ],
      () => this.redrawRows()
    );
    if (this.gos.isGroupRowsSticky()) {
      const rowModelType = this.rowModel.getType();
      if (rowModelType === "clientSide" || rowModelType === "serverSide") {
        this.stickyRowFeature = this.createManagedBean(
          new StickyRowFeature(this.createRowCon.bind(this), this.destroyRowCtrls.bind(this))
        );
      }
    }
    this.registerCellEventListeners();
    this.initialiseCache();
    this.printLayout = this.gos.isDomLayout("print");
    this.embedFullWidthRows = this.printLayout || this.gos.get("embedFullWidthRows");
    this.redrawAfterModelUpdate();
  }
  initialiseCache() {
    if (this.gos.get("keepDetailRows")) {
      const countProp = this.getKeepDetailRowsCount();
      const count = countProp != null ? countProp : 3;
      this.cachedRowCtrls = new RowCtrlCache(count);
    }
  }
  getKeepDetailRowsCount() {
    return this.gos.get("keepDetailRowsCount");
  }
  getStickyTopRowCtrls() {
    if (!this.stickyRowFeature) {
      return [];
    }
    return this.stickyRowFeature.getStickyTopRowCtrls();
  }
  getStickyBottomRowCtrls() {
    if (!this.stickyRowFeature) {
      return [];
    }
    return this.stickyRowFeature.getStickyBottomRowCtrls();
  }
  updateAllRowCtrls() {
    const liveList = _getAllValuesInObject(this.rowCtrlsByRowIndex);
    const zombieList = _getAllValuesInObject(this.zombieRowCtrls);
    const cachedList = this.cachedRowCtrls ? this.cachedRowCtrls.getEntries() : [];
    if (zombieList.length > 0 || cachedList.length > 0) {
      this.allRowCtrls = [...liveList, ...zombieList, ...cachedList];
    } else {
      this.allRowCtrls = liveList;
    }
  }
  onCellFocusChanged(event) {
    this.getAllCellCtrls().forEach((cellCtrl) => cellCtrl.onCellFocused(event));
    this.getFullWidthRowCtrls().forEach((rowCtrl) => rowCtrl.onFullWidthRowFocused(event));
  }
  // in a clean design, each cell would register for each of these events. however when scrolling, all the cells
  // registering and de-registering for events is a performance bottleneck. so we register here once and inform
  // all active cells.
  registerCellEventListeners() {
    this.addManagedEventListeners({
      cellFocused: (event) => {
        this.onCellFocusChanged(event);
      },
      cellFocusCleared: () => this.onCellFocusChanged(),
      flashCells: (event) => {
        this.getAllCellCtrls().forEach((cellCtrl) => cellCtrl.onFlashCells(event));
      },
      columnHoverChanged: () => {
        this.getAllCellCtrls().forEach((cellCtrl) => cellCtrl.onColumnHover());
      },
      displayedColumnsChanged: () => {
        this.getAllCellCtrls().forEach((cellCtrl) => cellCtrl.onDisplayedColumnsChanged());
      },
      displayedColumnsWidthChanged: () => {
        if (this.printLayout) {
          this.getAllCellCtrls().forEach((cellCtrl) => cellCtrl.onLeftChanged());
        }
      }
    });
    this.setupRangeSelectionListeners();
    this.refreshListenersToColumnsForCellComps();
    this.addManagedEventListeners({
      gridColumnsChanged: this.refreshListenersToColumnsForCellComps.bind(this)
    });
    this.addDestroyFunc(this.removeGridColumnListeners.bind(this));
  }
  // executes all functions in destroyFuncsForColumnListeners and then clears the list
  removeGridColumnListeners() {
    this.destroyFuncsForColumnListeners.forEach((func) => func());
    this.destroyFuncsForColumnListeners.length = 0;
  }
  // this function adds listeners onto all the grid columns, which are the column that we could have cellComps for.
  // when the grid columns change, we add listeners again. in an ideal design, each CellComp would just register to
  // the column it belongs to on creation, however this was a bottleneck with the number of cells, so do it here
  // once instead.
  refreshListenersToColumnsForCellComps() {
    this.removeGridColumnListeners();
    const cols = this.columnModel.getCols();
    cols.forEach((col) => {
      const forEachCellWithThisCol = (callback) => {
        this.getAllCellCtrls().forEach((cellCtrl) => {
          if (cellCtrl.getColumn() === col) {
            callback(cellCtrl);
          }
        });
      };
      const leftChangedListener = () => {
        forEachCellWithThisCol((cellCtrl) => cellCtrl.onLeftChanged());
      };
      const widthChangedListener = () => {
        forEachCellWithThisCol((cellCtrl) => cellCtrl.onWidthChanged());
      };
      const firstRightPinnedChangedListener = () => {
        forEachCellWithThisCol((cellCtrl) => cellCtrl.onFirstRightPinnedChanged());
      };
      const lastLeftPinnedChangedListener = () => {
        forEachCellWithThisCol((cellCtrl) => cellCtrl.onLastLeftPinnedChanged());
      };
      const colDefChangedListener = () => {
        forEachCellWithThisCol((cellCtrl) => cellCtrl.onColDefChanged());
      };
      col.addEventListener("leftChanged", leftChangedListener);
      col.addEventListener("widthChanged", widthChangedListener);
      col.addEventListener("firstRightPinnedChanged", firstRightPinnedChangedListener);
      col.addEventListener("lastLeftPinnedChanged", lastLeftPinnedChangedListener);
      col.addEventListener("colDefChanged", colDefChangedListener);
      this.destroyFuncsForColumnListeners.push(() => {
        col.removeEventListener("leftChanged", leftChangedListener);
        col.removeEventListener("widthChanged", widthChangedListener);
        col.removeEventListener("firstRightPinnedChanged", firstRightPinnedChangedListener);
        col.removeEventListener("lastLeftPinnedChanged", lastLeftPinnedChangedListener);
        col.removeEventListener("colDefChanged", colDefChangedListener);
      });
    });
  }
  onDomLayoutChanged() {
    const printLayout = this.gos.isDomLayout("print");
    const embedFullWidthRows = printLayout || this.gos.get("embedFullWidthRows");
    const destroyRows = embedFullWidthRows !== this.embedFullWidthRows || this.printLayout !== printLayout;
    this.printLayout = printLayout;
    this.embedFullWidthRows = embedFullWidthRows;
    if (destroyRows) {
      this.redrawAfterModelUpdate({ domLayoutChanged: true });
    }
  }
  // for row models that have datasources, when we update the datasource, we need to force the rowRenderer
  // to redraw all rows. otherwise the old rows from the old datasource will stay displayed.
  datasourceChanged() {
    this.firstRenderedRow = 0;
    this.lastRenderedRow = -1;
    const rowIndexesToRemove = Object.keys(this.rowCtrlsByRowIndex);
    this.removeRowCtrls(rowIndexesToRemove);
  }
  onPageLoaded(event) {
    const params = {
      recycleRows: event.keepRenderedRows,
      animate: event.animate,
      newData: event.newData,
      newPage: event.newPage,
      // because this is a model updated event (not pinned rows), we
      // can skip updating the pinned rows. this is needed so that if user
      // is doing transaction updates, the pinned rows are not getting constantly
      // trashed - or editing cells in pinned rows are not refreshed and put into read mode
      onlyBody: true
    };
    this.redrawAfterModelUpdate(params);
  }
  getAllCellsForColumn(column) {
    const res = [];
    this.getAllRowCtrls().forEach((rowCtrl) => {
      const eCell = rowCtrl.getCellElement(column);
      if (eCell) {
        res.push(eCell);
      }
    });
    return res;
  }
  refreshFloatingRowComps() {
    this.refreshFloatingRows(this.topRowCtrls, this.pinnedRowModel.getPinnedTopRowNodes());
    this.refreshFloatingRows(this.bottomRowCtrls, this.pinnedRowModel.getPinnedBottomRowNodes());
  }
  getTopRowCtrls() {
    return this.topRowCtrls;
  }
  getCentreRowCtrls() {
    return this.allRowCtrls;
  }
  getBottomRowCtrls() {
    return this.bottomRowCtrls;
  }
  /**
   * Determines which row controllers need to be destroyed and re-created vs which ones can
   * be re-used.
   *
   * This is operation is to pinned/floating rows as `this.recycleRows` is to normal/body rows.
   *
   * All `RowCtrl` instances in `rowCtrls` that don't correspond to `RowNode` instances in `rowNodes` are destroyed.
   * All `RowNode` instances in `rowNodes` that don't correspond to `RowCtrl` instances in `rowCtrls` are created.
   * All instances in `rowCtrls` must be in the same order as their corresponding nodes in `rowNodes`.
   *
   * @param rowCtrls The list of existing row controllers
   * @param rowNodes The canonical list of row nodes that should have associated controllers
   */
  refreshFloatingRows(rowCtrls, rowNodes) {
    const nodeMap = Object.fromEntries(rowNodes.map((node) => [node.id, node]));
    const rowCtrlMap = Object.fromEntries(rowCtrls.map((ctrl) => [ctrl.getRowNode().id, ctrl]));
    for (let i = 0; i < rowNodes.length; i++) {
      const node = rowNodes[i];
      const rowCtrl = rowCtrls[i];
      if (rowCtrl && nodeMap[rowCtrl.getRowNode().id] === void 0) {
        rowCtrl.destroyFirstPass();
        rowCtrl.destroySecondPass();
      }
      if (node.id in rowCtrlMap) {
        rowCtrls[i] = rowCtrlMap[node.id];
        delete rowCtrlMap[node.id];
      } else {
        rowCtrls[i] = new RowCtrl(node, this.beans, false, false, this.printLayout);
      }
    }
    rowCtrls.length = rowNodes.length;
  }
  onPinnedRowDataChanged() {
    const params = {
      recycleRows: true
    };
    this.redrawAfterModelUpdate(params);
  }
  redrawRow(rowNode, suppressEvent = false) {
    if (rowNode.sticky) {
      this.stickyRowFeature.refreshStickyNode(rowNode);
    } else if (this.cachedRowCtrls?.has(rowNode)) {
      this.cachedRowCtrls.removeRow(rowNode);
      return;
    } else {
      const destroyAndRecreateCtrl = (dataStruct) => {
        const ctrl = dataStruct[rowNode.rowIndex];
        if (!ctrl) {
          return;
        }
        if (ctrl.getRowNode() !== rowNode) {
          return;
        }
        ctrl.destroyFirstPass();
        ctrl.destroySecondPass();
        dataStruct[rowNode.rowIndex] = this.createRowCon(rowNode, false, false);
      };
      switch (rowNode.rowPinned) {
        case "top":
          destroyAndRecreateCtrl(this.topRowCtrls);
          break;
        case "bottom":
          destroyAndRecreateCtrl(this.bottomRowCtrls);
          break;
        default:
          destroyAndRecreateCtrl(this.rowCtrlsByRowIndex);
          this.updateAllRowCtrls();
      }
    }
    if (!suppressEvent) {
      this.dispatchDisplayedRowsChanged(false);
    }
  }
  redrawRows(rowNodes) {
    const partialRefresh = rowNodes != null;
    if (partialRefresh) {
      rowNodes?.forEach((node) => this.redrawRow(node, true));
      this.dispatchDisplayedRowsChanged(false);
      return;
    }
    this.redrawAfterModelUpdate();
  }
  getCellToRestoreFocusToAfterRefresh(params) {
    const focusedCell = params?.suppressKeepFocus ? null : this.focusService.getFocusCellToUseAfterRefresh();
    if (focusedCell == null) {
      return null;
    }
    const activeElement = this.gos.getActiveDomElement();
    const cellDomData = this.gos.getDomData(activeElement, CellCtrl.DOM_DATA_KEY_CELL_CTRL);
    const rowDomData = this.gos.getDomData(activeElement, RowCtrl.DOM_DATA_KEY_ROW_CTRL);
    const gridElementFocused = cellDomData || rowDomData;
    return gridElementFocused ? focusedCell : null;
  }
  // gets called from:
  // +) initialisation (in registerGridComp) params = null
  // +) onDomLayoutChanged, params = null
  // +) onPageLoaded, recycleRows, animate, newData, newPage from event, onlyBody=true
  // +) onPinnedRowDataChanged, recycleRows = true
  // +) redrawRows (from Grid API), recycleRows = true/false
  redrawAfterModelUpdate(params = {}) {
    this.getLockOnRefresh();
    const focusedCell = this.getCellToRestoreFocusToAfterRefresh(params);
    this.updateContainerHeights();
    this.scrollToTopIfNewData(params);
    const recycleRows = !params.domLayoutChanged && !!params.recycleRows;
    const animate = params.animate && this.gos.isAnimateRows();
    const rowsToRecycle = recycleRows ? this.getRowsToRecycle() : null;
    if (!recycleRows) {
      this.removeAllRowComps();
    }
    this.workOutFirstAndLastRowsToRender();
    if (this.stickyRowFeature) {
      this.stickyRowFeature.checkStickyRows();
      const extraHeight = this.stickyRowFeature.getExtraTopHeight() + this.stickyRowFeature.getExtraBottomHeight();
      if (extraHeight) {
        this.updateContainerHeights(extraHeight);
      }
    }
    this.recycleRows(rowsToRecycle, animate);
    this.gridBodyCtrl.updateRowCount();
    if (!params.onlyBody) {
      this.refreshFloatingRowComps();
    }
    this.dispatchDisplayedRowsChanged();
    if (focusedCell != null) {
      this.restoreFocusedCell(focusedCell);
    }
    this.releaseLockOnRefresh();
  }
  scrollToTopIfNewData(params) {
    const scrollToTop = params.newData || params.newPage;
    const suppressScrollToTop = this.gos.get("suppressScrollOnNewData");
    if (scrollToTop && !suppressScrollToTop) {
      this.gridBodyCtrl.getScrollFeature().scrollToTop();
      this.stickyRowFeature?.resetOffsets();
    }
  }
  updateContainerHeights(additionalHeight = 0) {
    if (this.printLayout) {
      this.rowContainerHeightService.setModelHeight(null);
      return;
    }
    let containerHeight = this.pageBoundsService.getCurrentPageHeight();
    if (containerHeight === 0) {
      containerHeight = 1;
    }
    this.rowContainerHeightService.setModelHeight(containerHeight + additionalHeight);
  }
  getLockOnRefresh() {
    if (this.refreshInProgress) {
      const frameworkMessage = this.frameworkOverrides.getLockOnRefreshError?.() ?? "";
      throw new Error(
        "AG Grid: cannot get grid to draw rows when it is in the middle of drawing rows. Your code probably called a grid API method while the grid was in the render stage. To overcome this, put the API call into a timeout, e.g. instead of api.redrawRows(), call setTimeout(function() { api.redrawRows(); }, 0). To see what part of your code that caused the refresh check this stacktrace." + frameworkMessage
      );
    }
    this.refreshInProgress = true;
    this.frameworkOverrides.getLockOnRefresh?.();
  }
  releaseLockOnRefresh() {
    this.refreshInProgress = false;
    this.frameworkOverrides.releaseLockOnRefresh?.();
  }
  isRefreshInProgress() {
    return this.refreshInProgress;
  }
  // sets the focus to the provided cell, if the cell is provided. this way, the user can call refresh without
  // worry about the focus been lost. this is important when the user is using keyboard navigation to do edits
  // and the cellEditor is calling 'refresh' to get other cells to update (as other cells might depend on the
  // edited cell).
  restoreFocusedCell(cellPosition) {
    if (!cellPosition) {
      return;
    }
    setTimeout(() => {
      this.focusService.setRestoreFocusedCell(cellPosition);
      this.onCellFocusChanged(
        this.gos.addGridCommonParams({
          rowIndex: cellPosition.rowIndex,
          column: cellPosition.column,
          rowPinned: cellPosition.rowPinned,
          forceBrowserFocus: true,
          preventScrollOnBrowserFocus: true,
          type: "cellFocused"
        })
      );
    });
  }
  stopEditing(cancel = false) {
    this.getAllRowCtrls().forEach((rowCtrl) => {
      rowCtrl.stopEditing(cancel);
    });
  }
  getAllCellCtrls() {
    const res = [];
    const rowCtrls = this.getAllRowCtrls();
    const rowCtrlsLength = rowCtrls.length;
    for (let i = 0; i < rowCtrlsLength; i++) {
      const cellCtrls = rowCtrls[i].getAllCellCtrls();
      const cellCtrlsLength = cellCtrls.length;
      for (let j = 0; j < cellCtrlsLength; j++) {
        res.push(cellCtrls[j]);
      }
    }
    return res;
  }
  getAllRowCtrls() {
    const stickyTopRowCtrls = this.stickyRowFeature && this.stickyRowFeature.getStickyTopRowCtrls() || [];
    const stickyBottomRowCtrls = this.stickyRowFeature && this.stickyRowFeature.getStickyBottomRowCtrls() || [];
    const res = [...this.topRowCtrls, ...this.bottomRowCtrls, ...stickyTopRowCtrls, ...stickyBottomRowCtrls];
    for (const key in this.rowCtrlsByRowIndex) {
      res.push(this.rowCtrlsByRowIndex[key]);
    }
    return res;
  }
  addRenderedRowListener(eventName, rowIndex, callback) {
    const rowComp = this.rowCtrlsByRowIndex[rowIndex];
    if (rowComp) {
      rowComp.addEventListener(eventName, callback);
    }
  }
  flashCells(params = {}) {
    this.getCellCtrls(params.rowNodes, params.columns).forEach(
      (cellCtrl) => cellCtrl.flashCell(params)
    );
  }
  refreshCells(params = {}) {
    const refreshCellParams = {
      forceRefresh: params.force,
      newData: false,
      suppressFlash: params.suppressFlash
    };
    for (const cellCtrl of this.getCellCtrls(params.rowNodes, params.columns)) {
      cellCtrl.refreshOrDestroyCell(refreshCellParams);
    }
    this.refreshFullWidth(params.rowNodes);
  }
  refreshFullWidth(rowNodes) {
    if (!rowNodes) {
      return;
    }
    let cellFocused = null;
    if (this.stickyRowFeature && _browserSupportsPreventScroll()) {
      cellFocused = this.getCellToRestoreFocusToAfterRefresh() || null;
    }
    for (const rowCtrl of this.getRowCtrls(rowNodes)) {
      if (!rowCtrl.isFullWidth()) {
        continue;
      }
      const refreshed = rowCtrl.refreshFullWidth();
      if (!refreshed) {
        this.redrawRow(rowCtrl.getRowNode(), true);
      }
    }
    this.dispatchDisplayedRowsChanged(false);
    if (cellFocused) {
      this.restoreFocusedCell(cellFocused);
    }
  }
  getCellRendererInstances(params) {
    const cellRenderers = this.getCellCtrls(params.rowNodes, params.columns).map((cellCtrl) => cellCtrl.getCellRenderer()).filter((renderer) => renderer != null);
    if (params.columns?.length) {
      return cellRenderers;
    }
    const fullWidthRenderers = [];
    const rowIdMap = this.mapRowNodes(params.rowNodes);
    this.getAllRowCtrls().forEach((rowCtrl) => {
      if (rowIdMap && !this.isRowInMap(rowCtrl.getRowNode(), rowIdMap)) {
        return;
      }
      if (!rowCtrl.isFullWidth()) {
        return;
      }
      const renderers = rowCtrl.getFullWidthCellRenderers();
      for (let i = 0; i < renderers.length; i++) {
        const renderer = renderers[i];
        if (renderer != null) {
          fullWidthRenderers.push(renderer);
        }
      }
    });
    return [...fullWidthRenderers, ...cellRenderers];
  }
  getCellEditorInstances(params) {
    const res = [];
    this.getCellCtrls(params.rowNodes, params.columns).forEach((cellCtrl) => {
      const cellEditor = cellCtrl.getCellEditor();
      if (cellEditor) {
        res.push(cellEditor);
      }
    });
    return res;
  }
  getEditingCells() {
    const res = [];
    this.getAllCellCtrls().forEach((cellCtrl) => {
      if (cellCtrl.isEditing()) {
        const cellPosition = cellCtrl.getCellPosition();
        res.push(cellPosition);
      }
    });
    return res;
  }
  mapRowNodes(rowNodes) {
    if (!rowNodes) {
      return;
    }
    const res = {
      top: {},
      bottom: {},
      normal: {}
    };
    rowNodes.forEach((rowNode) => {
      const id = rowNode.id;
      switch (rowNode.rowPinned) {
        case "top":
          res.top[id] = rowNode;
          break;
        case "bottom":
          res.bottom[id] = rowNode;
          break;
        default:
          res.normal[id] = rowNode;
          break;
      }
    });
    return res;
  }
  isRowInMap(rowNode, rowIdsMap) {
    const id = rowNode.id;
    const floating = rowNode.rowPinned;
    switch (floating) {
      case "top":
        return rowIdsMap.top[id] != null;
      case "bottom":
        return rowIdsMap.bottom[id] != null;
      default:
        return rowIdsMap.normal[id] != null;
    }
  }
  /**
   * @param rowNodes if provided, returns the RowCtrls for the provided rowNodes. otherwise returns all RowCtrls.
   */
  getRowCtrls(rowNodes) {
    const rowIdsMap = this.mapRowNodes(rowNodes);
    const allRowCtrls = this.getAllRowCtrls();
    if (!rowNodes || !rowIdsMap) {
      return allRowCtrls;
    }
    return allRowCtrls.filter((rowCtrl) => {
      const rowNode = rowCtrl.getRowNode();
      return this.isRowInMap(rowNode, rowIdsMap);
    });
  }
  // returns CellCtrl's that match the provided rowNodes and columns. eg if one row node
  // and two columns provided, that identifies 4 cells, so 4 CellCtrl's returned.
  getCellCtrls(rowNodes, columns) {
    let colIdsMap;
    if (_exists(columns)) {
      colIdsMap = {};
      columns.forEach((colKey) => {
        const column = this.columnModel.getCol(colKey);
        if (_exists(column)) {
          colIdsMap[column.getId()] = true;
        }
      });
    }
    const res = [];
    this.getRowCtrls(rowNodes).forEach((rowCtrl) => {
      rowCtrl.getAllCellCtrls().forEach((cellCtrl) => {
        const colId = cellCtrl.getColumn().getId();
        const excludeColFromRefresh = colIdsMap && !colIdsMap[colId];
        if (excludeColFromRefresh) {
          return;
        }
        res.push(cellCtrl);
      });
    });
    return res;
  }
  destroy() {
    this.removeAllRowComps();
    super.destroy();
  }
  removeAllRowComps() {
    const rowIndexesToRemove = Object.keys(this.rowCtrlsByRowIndex);
    this.removeRowCtrls(rowIndexesToRemove);
    if (this.stickyRowFeature) {
      this.stickyRowFeature.destroyStickyCtrls();
    }
  }
  getRowsToRecycle() {
    const stubNodeIndexes = [];
    _iterateObject(this.rowCtrlsByRowIndex, (index, rowCtrl) => {
      const stubNode = rowCtrl.getRowNode().id == null;
      if (stubNode) {
        stubNodeIndexes.push(index);
      }
    });
    this.removeRowCtrls(stubNodeIndexes);
    const ctrlsByIdMap = {};
    _iterateObject(this.rowCtrlsByRowIndex, (_, rowCtrl) => {
      const rowNode = rowCtrl.getRowNode();
      ctrlsByIdMap[rowNode.id] = rowCtrl;
    });
    this.rowCtrlsByRowIndex = {};
    return ctrlsByIdMap;
  }
  // takes array of row indexes
  removeRowCtrls(rowsToRemove, suppressAnimation = false) {
    rowsToRemove.forEach((indexToRemove) => {
      const rowCtrl = this.rowCtrlsByRowIndex[indexToRemove];
      if (rowCtrl) {
        rowCtrl.destroyFirstPass(suppressAnimation);
        rowCtrl.destroySecondPass();
      }
      delete this.rowCtrlsByRowIndex[indexToRemove];
    });
  }
  onBodyScroll(e) {
    if (e.direction !== "vertical") {
      return;
    }
    this.redraw({ afterScroll: true });
  }
  // gets called when rows don't change, but viewport does, so after:
  // 1) height of grid body changes, ie number of displayed rows has changed
  // 2) grid scrolled to new position
  // 3) ensure index visible (which is a scroll)
  redraw(params = {}) {
    const { afterScroll } = params;
    let cellFocused;
    if (this.stickyRowFeature && _browserSupportsPreventScroll()) {
      cellFocused = this.getCellToRestoreFocusToAfterRefresh() || void 0;
    }
    const oldFirstRow = this.firstRenderedRow;
    const oldLastRow = this.lastRenderedRow;
    this.workOutFirstAndLastRowsToRender();
    let hasStickyRowChanges = false;
    if (this.stickyRowFeature) {
      hasStickyRowChanges = this.stickyRowFeature.checkStickyRows();
      const extraHeight = this.stickyRowFeature.getExtraTopHeight() + this.stickyRowFeature.getExtraBottomHeight();
      if (extraHeight) {
        this.updateContainerHeights(extraHeight);
      }
    }
    const rangeChanged = this.firstRenderedRow !== oldFirstRow || this.lastRenderedRow !== oldLastRow;
    if (afterScroll && !hasStickyRowChanges && !rangeChanged) {
      return;
    }
    this.getLockOnRefresh();
    this.recycleRows(null, false, afterScroll);
    this.releaseLockOnRefresh();
    this.dispatchDisplayedRowsChanged(afterScroll && !hasStickyRowChanges);
    if (cellFocused != null) {
      const newFocusedCell = this.getCellToRestoreFocusToAfterRefresh();
      if (cellFocused != null && newFocusedCell == null) {
        this.animationFrameService.flushAllFrames();
        this.restoreFocusedCell(cellFocused);
      }
    }
  }
  removeRowCompsNotToDraw(indexesToDraw, suppressAnimation) {
    const indexesToDrawMap = {};
    indexesToDraw.forEach((index) => indexesToDrawMap[index] = true);
    const existingIndexes = Object.keys(this.rowCtrlsByRowIndex);
    const indexesNotToDraw = existingIndexes.filter((index) => !indexesToDrawMap[index]);
    this.removeRowCtrls(indexesNotToDraw, suppressAnimation);
  }
  calculateIndexesToDraw(rowsToRecycle) {
    const indexesToDraw = _createArrayOfNumbers(this.firstRenderedRow, this.lastRenderedRow);
    const checkRowToDraw = (indexStr, rowComp) => {
      const index = rowComp.getRowNode().rowIndex;
      if (index == null) {
        return;
      }
      if (index < this.firstRenderedRow || index > this.lastRenderedRow) {
        if (this.doNotUnVirtualiseRow(rowComp)) {
          indexesToDraw.push(index);
        }
      }
    };
    _iterateObject(this.rowCtrlsByRowIndex, checkRowToDraw);
    _iterateObject(rowsToRecycle, checkRowToDraw);
    indexesToDraw.sort((a, b) => a - b);
    const ret = [];
    for (let i = 0; i < indexesToDraw.length; i++) {
      const currRow = indexesToDraw[i];
      const rowNode = this.rowModel.getRow(currRow);
      if (rowNode && !rowNode.sticky) {
        ret.push(currRow);
      }
    }
    return ret;
  }
  recycleRows(rowsToRecycle, animate = false, afterScroll = false) {
    const indexesToDraw = this.calculateIndexesToDraw(rowsToRecycle);
    if (this.printLayout || afterScroll) {
      animate = false;
    }
    this.removeRowCompsNotToDraw(indexesToDraw, !animate);
    const rowCtrls = [];
    indexesToDraw.forEach((rowIndex) => {
      const rowCtrl = this.createOrUpdateRowCtrl(rowIndex, rowsToRecycle, animate, afterScroll);
      if (_exists(rowCtrl)) {
        rowCtrls.push(rowCtrl);
      }
    });
    if (rowsToRecycle) {
      const useAnimationFrame = afterScroll && !this.gos.get("suppressAnimationFrame") && !this.printLayout;
      if (useAnimationFrame) {
        this.beans.animationFrameService.addDestroyTask(() => {
          this.destroyRowCtrls(rowsToRecycle, animate);
          this.updateAllRowCtrls();
          this.dispatchDisplayedRowsChanged();
        });
      } else {
        this.destroyRowCtrls(rowsToRecycle, animate);
      }
    }
    this.updateAllRowCtrls();
  }
  dispatchDisplayedRowsChanged(afterScroll = false) {
    const event = {
      type: "displayedRowsChanged",
      afterScroll
    };
    this.eventService.dispatchEvent(event);
  }
  onDisplayedColumnsChanged() {
    const pinningLeft = this.visibleColsService.isPinningLeft();
    const pinningRight = this.visibleColsService.isPinningRight();
    const atLeastOneChanged = this.pinningLeft !== pinningLeft || pinningRight !== this.pinningRight;
    if (atLeastOneChanged) {
      this.pinningLeft = pinningLeft;
      this.pinningRight = pinningRight;
      if (this.embedFullWidthRows) {
        this.redrawFullWidthEmbeddedRows();
      }
    }
  }
  // when embedding, what gets showed in each section depends on what is pinned. eg if embedding group expand / collapse,
  // then it should go into the pinned left area if pinning left, or the center area if not pinning.
  redrawFullWidthEmbeddedRows() {
    const rowsToRemove = [];
    this.getFullWidthRowCtrls().forEach((fullWidthCtrl) => {
      const rowIndex = fullWidthCtrl.getRowNode().rowIndex;
      rowsToRemove.push(rowIndex.toString());
    });
    this.refreshFloatingRowComps();
    this.removeRowCtrls(rowsToRemove);
    this.redraw({ afterScroll: true });
  }
  getFullWidthRowCtrls(rowNodes) {
    const rowNodesMap = this.mapRowNodes(rowNodes);
    return this.getAllRowCtrls().filter((rowCtrl) => {
      if (!rowCtrl.isFullWidth()) {
        return false;
      }
      const rowNode = rowCtrl.getRowNode();
      if (rowNodesMap != null && !this.isRowInMap(rowNode, rowNodesMap)) {
        return false;
      }
      return true;
    });
  }
  createOrUpdateRowCtrl(rowIndex, rowsToRecycle, animate, afterScroll) {
    let rowNode;
    let rowCtrl = this.rowCtrlsByRowIndex[rowIndex];
    if (!rowCtrl) {
      rowNode = this.rowModel.getRow(rowIndex);
      if (_exists(rowNode) && _exists(rowsToRecycle) && rowsToRecycle[rowNode.id] && rowNode.alreadyRendered) {
        rowCtrl = rowsToRecycle[rowNode.id];
        rowsToRecycle[rowNode.id] = null;
      }
    }
    const creatingNewRowCtrl = !rowCtrl;
    if (creatingNewRowCtrl) {
      if (!rowNode) {
        rowNode = this.rowModel.getRow(rowIndex);
      }
      if (_exists(rowNode)) {
        rowCtrl = this.createRowCon(rowNode, animate, afterScroll);
      } else {
        return;
      }
    }
    if (rowNode) {
      rowNode.alreadyRendered = true;
    }
    this.rowCtrlsByRowIndex[rowIndex] = rowCtrl;
    return rowCtrl;
  }
  destroyRowCtrls(rowCtrlsMap, animate) {
    const executeInAWhileFuncs = [];
    _iterateObject(rowCtrlsMap, (nodeId, rowCtrl) => {
      if (!rowCtrl) {
        return;
      }
      if (this.cachedRowCtrls && rowCtrl.isCacheable()) {
        this.cachedRowCtrls.addRow(rowCtrl);
        return;
      }
      rowCtrl.destroyFirstPass(!animate);
      if (animate) {
        this.zombieRowCtrls[rowCtrl.getInstanceId()] = rowCtrl;
        executeInAWhileFuncs.push(() => {
          rowCtrl.destroySecondPass();
          delete this.zombieRowCtrls[rowCtrl.getInstanceId()];
        });
      } else {
        rowCtrl.destroySecondPass();
      }
    });
    if (animate) {
      executeInAWhileFuncs.push(() => {
        this.updateAllRowCtrls();
        this.dispatchDisplayedRowsChanged();
      });
      _executeInAWhile(executeInAWhileFuncs);
    }
  }
  getRowBuffer() {
    return this.gos.get("rowBuffer");
  }
  getRowBufferInPixels() {
    const rowsToBuffer = this.getRowBuffer();
    const defaultRowHeight = this.gos.getRowHeightAsNumber();
    return rowsToBuffer * defaultRowHeight;
  }
  workOutFirstAndLastRowsToRender() {
    this.rowContainerHeightService.updateOffset();
    let newFirst;
    let newLast;
    if (!this.rowModel.isRowsToRender()) {
      newFirst = 0;
      newLast = -1;
    } else if (this.printLayout) {
      this.environment.refreshRowHeightVariable();
      newFirst = this.pageBoundsService.getFirstRow();
      newLast = this.pageBoundsService.getLastRow();
    } else {
      const bufferPixels = this.getRowBufferInPixels();
      const gridBodyCtrl = this.ctrlsService.getGridBodyCtrl();
      const suppressRowVirtualisation = this.gos.get("suppressRowVirtualisation");
      let rowHeightsChanged = false;
      let firstPixel;
      let lastPixel;
      do {
        const paginationOffset = this.pageBoundsService.getPixelOffset();
        const { pageFirstPixel, pageLastPixel } = this.pageBoundsService.getCurrentPagePixelRange();
        const divStretchOffset = this.rowContainerHeightService.getDivStretchOffset();
        const bodyVRange = gridBodyCtrl.getScrollFeature().getVScrollPosition();
        const bodyTopPixel = bodyVRange.top;
        const bodyBottomPixel = bodyVRange.bottom;
        if (suppressRowVirtualisation) {
          firstPixel = pageFirstPixel + divStretchOffset;
          lastPixel = pageLastPixel + divStretchOffset;
        } else {
          firstPixel = Math.max(bodyTopPixel + paginationOffset - bufferPixels, pageFirstPixel) + divStretchOffset;
          lastPixel = Math.min(bodyBottomPixel + paginationOffset + bufferPixels, pageLastPixel) + divStretchOffset;
        }
        this.firstVisibleVPixel = Math.max(bodyTopPixel + paginationOffset, pageFirstPixel) + divStretchOffset;
        this.lastVisibleVPixel = Math.min(bodyBottomPixel + paginationOffset, pageLastPixel) + divStretchOffset;
        rowHeightsChanged = this.ensureAllRowsInRangeHaveHeightsCalculated(firstPixel, lastPixel);
      } while (rowHeightsChanged);
      let firstRowIndex = this.rowModel.getRowIndexAtPixel(firstPixel);
      let lastRowIndex = this.rowModel.getRowIndexAtPixel(lastPixel);
      const pageFirstRow = this.pageBoundsService.getFirstRow();
      const pageLastRow = this.pageBoundsService.getLastRow();
      if (firstRowIndex < pageFirstRow) {
        firstRowIndex = pageFirstRow;
      }
      if (lastRowIndex > pageLastRow) {
        lastRowIndex = pageLastRow;
      }
      newFirst = firstRowIndex;
      newLast = lastRowIndex;
    }
    const rowLayoutNormal = this.gos.isDomLayout("normal");
    const suppressRowCountRestriction = this.gos.get("suppressMaxRenderedRowRestriction");
    const rowBufferMaxSize = Math.max(this.getRowBuffer(), 500);
    if (rowLayoutNormal && !suppressRowCountRestriction) {
      if (newLast - newFirst > rowBufferMaxSize) {
        newLast = newFirst + rowBufferMaxSize;
      }
    }
    const firstDiffers = newFirst !== this.firstRenderedRow;
    const lastDiffers = newLast !== this.lastRenderedRow;
    if (firstDiffers || lastDiffers) {
      this.firstRenderedRow = newFirst;
      this.lastRenderedRow = newLast;
      const event = {
        type: "viewportChanged",
        firstRow: newFirst,
        lastRow: newLast
      };
      this.eventService.dispatchEvent(event);
    }
  }
  /**
   * This event will only be fired once, and is queued until after the browser next renders.
   * This allows us to fire an event during the start of the render cycle, when we first see data being rendered
   * but not execute the event until all of the data has finished being rendered to the dom.
   */
  dispatchFirstDataRenderedEvent() {
    if (this.dataFirstRenderedFired) {
      return;
    }
    this.dataFirstRenderedFired = true;
    const event = {
      type: "firstDataRendered",
      firstRow: this.firstRenderedRow,
      lastRow: this.lastRenderedRow
    };
    window.requestAnimationFrame(() => {
      this.beans.eventService.dispatchEvent(event);
    });
  }
  ensureAllRowsInRangeHaveHeightsCalculated(topPixel, bottomPixel) {
    const pinnedRowHeightsChanged = this.pinnedRowModel?.ensureRowHeightsValid();
    const stickyHeightsChanged = this.stickyRowFeature?.ensureRowHeightsValid();
    const rowModelHeightsChanged = this.rowModel.ensureRowHeightsValid(
      topPixel,
      bottomPixel,
      this.pageBoundsService.getFirstRow(),
      this.pageBoundsService.getLastRow()
    );
    if (rowModelHeightsChanged || stickyHeightsChanged) {
      this.eventService.dispatchEvent({
        type: "recalculateRowBounds"
      });
    }
    if (stickyHeightsChanged || rowModelHeightsChanged || pinnedRowHeightsChanged) {
      this.updateContainerHeights();
      return true;
    }
    return false;
  }
  getFirstVisibleVerticalPixel() {
    return this.firstVisibleVPixel;
  }
  getLastVisibleVerticalPixel() {
    return this.lastVisibleVPixel;
  }
  getFirstVirtualRenderedRow() {
    return this.firstRenderedRow;
  }
  getLastVirtualRenderedRow() {
    return this.lastRenderedRow;
  }
  // check that none of the rows to remove are editing or focused as:
  // a) if editing, we want to keep them, otherwise the user will loose the context of the edit,
  //    eg user starts editing, enters some text, then scrolls down and then up, next time row rendered
  //    the edit is reset - so we want to keep it rendered.
  // b) if focused, we want ot keep keyboard focus, so if user ctrl+c, it goes to clipboard,
  //    otherwise the user can range select and drag (with focus cell going out of the viewport)
  //    and then ctrl+c, nothing will happen if cell is removed from dom.
  // c) if detail record of master detail, as users complained that the context of detail rows
  //    was getting lost when detail row out of view. eg user expands to show detail row,
  //    then manipulates the detail panel (eg sorts the detail grid), then context is lost
  //    after detail panel is scrolled out of / into view.
  doNotUnVirtualiseRow(rowComp) {
    const REMOVE_ROW = false;
    const KEEP_ROW = true;
    const rowNode = rowComp.getRowNode();
    const rowHasFocus = this.focusService.isRowNodeFocused(rowNode);
    const rowIsEditing = rowComp.isEditing();
    const rowIsDetail = rowNode.detail;
    const mightWantToKeepRow = rowHasFocus || rowIsEditing || rowIsDetail;
    if (!mightWantToKeepRow) {
      return REMOVE_ROW;
    }
    const rowNodePresent = this.isRowPresent(rowNode);
    return rowNodePresent ? KEEP_ROW : REMOVE_ROW;
  }
  isRowPresent(rowNode) {
    if (!this.rowModel.isRowPresent(rowNode)) {
      return false;
    }
    return this.paginationService ? this.paginationService.isRowPresent(rowNode) : true;
  }
  createRowCon(rowNode, animate, afterScroll) {
    const rowCtrlFromCache = this.cachedRowCtrls ? this.cachedRowCtrls.getRow(rowNode) : null;
    if (rowCtrlFromCache) {
      return rowCtrlFromCache;
    }
    const suppressAnimationFrame = this.gos.get("suppressAnimationFrame");
    const useAnimationFrameForCreate = afterScroll && !suppressAnimationFrame && !this.printLayout;
    const res = new RowCtrl(rowNode, this.beans, animate, useAnimationFrameForCreate, this.printLayout);
    return res;
  }
  getRenderedNodes() {
    const renderedRows = this.rowCtrlsByRowIndex;
    return Object.values(renderedRows).map((rowCtrl) => rowCtrl.getRowNode());
  }
  getRowByPosition(rowPosition) {
    let rowCtrl;
    const { rowIndex } = rowPosition;
    switch (rowPosition.rowPinned) {
      case "top":
        rowCtrl = this.topRowCtrls[rowIndex];
        break;
      case "bottom":
        rowCtrl = this.bottomRowCtrls[rowIndex];
        break;
      default:
        rowCtrl = this.rowCtrlsByRowIndex[rowIndex];
        if (!rowCtrl) {
          rowCtrl = this.getStickyTopRowCtrls().find((ctrl) => ctrl.getRowNode().rowIndex === rowIndex) || null;
          if (!rowCtrl) {
            rowCtrl = this.getStickyBottomRowCtrls().find((ctrl) => ctrl.getRowNode().rowIndex === rowIndex) || null;
          }
        }
        break;
    }
    return rowCtrl;
  }
  // returns true if any row between startIndex and endIndex is rendered. used by
  // SSRM or IRM, as they don't want to purge visible blocks from cache.
  isRangeInRenderedViewport(startIndex, endIndex) {
    const parentClosed = startIndex == null || endIndex == null;
    if (parentClosed) {
      return false;
    }
    const blockAfterViewport = startIndex > this.lastRenderedRow;
    const blockBeforeViewport = endIndex < this.firstRenderedRow;
    const blockInsideViewport = !blockBeforeViewport && !blockAfterViewport;
    return blockInsideViewport;
  }
};
var RowCtrlCache = class {
  constructor(maxCount) {
    // map for fast access
    this.entriesMap = {};
    // list for keeping order
    this.entriesList = [];
    this.maxCount = maxCount;
  }
  addRow(rowCtrl) {
    this.entriesMap[rowCtrl.getRowNode().id] = rowCtrl;
    this.entriesList.push(rowCtrl);
    rowCtrl.setCached(true);
    if (this.entriesList.length > this.maxCount) {
      const rowCtrlToDestroy = this.entriesList[0];
      rowCtrlToDestroy.destroyFirstPass();
      rowCtrlToDestroy.destroySecondPass();
      this.removeFromCache(rowCtrlToDestroy);
    }
  }
  getRow(rowNode) {
    if (rowNode == null || rowNode.id == null) {
      return null;
    }
    const res = this.entriesMap[rowNode.id];
    if (!res) {
      return null;
    }
    this.removeFromCache(res);
    res.setCached(false);
    const rowNodeMismatch = res.getRowNode() != rowNode;
    return rowNodeMismatch ? null : res;
  }
  has(rowNode) {
    return this.entriesMap[rowNode.id] != null;
  }
  removeRow(rowNode) {
    const rowNodeId = rowNode.id;
    const ctrl = this.entriesMap[rowNodeId];
    delete this.entriesMap[rowNodeId];
    _removeFromArray(this.entriesList, ctrl);
  }
  removeFromCache(rowCtrl) {
    const rowNodeId = rowCtrl.getRowNode().id;
    delete this.entriesMap[rowNodeId];
    _removeFromArray(this.entriesList, rowCtrl);
  }
  getEntries() {
    return this.entriesList;
  }
};

// community-modules/core/src/pinnedRowModel/pinnedRowModel.ts
var PinnedRowModel = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "pinnedRowModel";
  }
  wireBeans(beans) {
    this.beans = beans;
  }
  postConstruct() {
    this.setPinnedTopRowData();
    this.setPinnedBottomRowData();
    this.addManagedPropertyListener("pinnedTopRowData", () => this.setPinnedTopRowData());
    this.addManagedPropertyListener("pinnedBottomRowData", () => this.setPinnedBottomRowData());
    this.addManagedEventListeners({ gridStylesChanged: this.onGridStylesChanges.bind(this) });
  }
  isEmpty(floating) {
    const rows = floating === "top" ? this.pinnedTopRows : this.pinnedBottomRows;
    return _missingOrEmpty(rows);
  }
  isRowsToRender(floating) {
    return !this.isEmpty(floating);
  }
  getRowAtPixel(pixel, floating) {
    const rows = floating === "top" ? this.pinnedTopRows : this.pinnedBottomRows;
    if (_missingOrEmpty(rows)) {
      return 0;
    }
    for (let i = 0; i < rows.length; i++) {
      const rowNode = rows[i];
      const rowTopPixel = rowNode.rowTop + rowNode.rowHeight - 1;
      if (rowTopPixel >= pixel) {
        return i;
      }
    }
    return rows.length - 1;
  }
  onGridStylesChanges(e) {
    if (e.rowHeightChanged) {
      const estimateRowHeight = (rowNode) => {
        rowNode.setRowHeight(rowNode.rowHeight, true);
      };
      this.pinnedBottomRows.forEach(estimateRowHeight);
      this.pinnedTopRows.forEach(estimateRowHeight);
    }
  }
  ensureRowHeightsValid() {
    let anyChange = false;
    let rowTop = 0;
    const updateRowHeight = (rowNode) => {
      if (rowNode.rowHeightEstimated) {
        const rowHeight = this.gos.getRowHeightForNode(rowNode);
        rowNode.setRowTop(rowTop);
        rowNode.setRowHeight(rowHeight.height);
        rowTop += rowHeight.height;
        anyChange = true;
      }
    };
    this.pinnedBottomRows?.forEach(updateRowHeight);
    rowTop = 0;
    this.pinnedTopRows?.forEach(updateRowHeight);
    const event = {
      type: "pinnedHeightChanged"
    };
    this.eventService.dispatchEvent(event);
    return anyChange;
  }
  setPinnedTopRowData() {
    const rowData = this.gos.get("pinnedTopRowData");
    this.pinnedTopRows = this.createNodesFromData(rowData, true);
    const event = {
      type: "pinnedRowDataChanged"
    };
    this.eventService.dispatchEvent(event);
  }
  setPinnedBottomRowData() {
    const rowData = this.gos.get("pinnedBottomRowData");
    this.pinnedBottomRows = this.createNodesFromData(rowData, false);
    const event = {
      type: "pinnedRowDataChanged"
    };
    this.eventService.dispatchEvent(event);
  }
  createNodesFromData(allData, isTop) {
    const rowNodes = [];
    if (allData) {
      const getRowId = this.gos.getRowIdCallback();
      const idPrefix = isTop ? RowNode.ID_PREFIX_TOP_PINNED : RowNode.ID_PREFIX_BOTTOM_PINNED;
      let nextRowTop = 0;
      allData.forEach((dataItem, index) => {
        const rowNode = new RowNode(this.beans);
        rowNode.data = dataItem;
        rowNode.id = getRowId?.({ data: dataItem, level: 0 }) ?? idPrefix + index;
        rowNode.rowPinned = isTop ? "top" : "bottom";
        rowNode.setRowTop(nextRowTop);
        rowNode.setRowHeight(this.gos.getRowHeightForNode(rowNode).height);
        rowNode.setRowIndex(index);
        nextRowTop += rowNode.rowHeight;
        rowNodes.push(rowNode);
      });
    }
    return rowNodes;
  }
  getPinnedTopRowNodes() {
    return this.pinnedTopRows;
  }
  getPinnedBottomRowNodes() {
    return this.pinnedBottomRows;
  }
  getPinnedTopTotalHeight() {
    return this.getTotalHeight(this.pinnedTopRows);
  }
  getPinnedTopRowCount() {
    return this.pinnedTopRows ? this.pinnedTopRows.length : 0;
  }
  getPinnedBottomRowCount() {
    return this.pinnedBottomRows ? this.pinnedBottomRows.length : 0;
  }
  getPinnedTopRow(index) {
    return this.pinnedTopRows[index];
  }
  getPinnedBottomRow(index) {
    return this.pinnedBottomRows[index];
  }
  forEachPinnedTopRow(callback) {
    if (_missingOrEmpty(this.pinnedTopRows)) {
      return;
    }
    this.pinnedTopRows.forEach(callback);
  }
  forEachPinnedBottomRow(callback) {
    if (_missingOrEmpty(this.pinnedBottomRows)) {
      return;
    }
    this.pinnedBottomRows.forEach(callback);
  }
  getPinnedBottomTotalHeight() {
    return this.getTotalHeight(this.pinnedBottomRows);
  }
  getTotalHeight(rowNodes) {
    if (!rowNodes || rowNodes.length === 0) {
      return 0;
    }
    const lastNode = _last(rowNodes);
    return lastNode.rowTop + lastNode.rowHeight;
  }
};

// community-modules/core/src/interfaces/serverSideTransaction.ts
var ServerSideTransactionResultStatus = /* @__PURE__ */ ((ServerSideTransactionResultStatus2) => {
  ServerSideTransactionResultStatus2["Applied"] = "Applied";
  ServerSideTransactionResultStatus2["StoreNotFound"] = "StoreNotFound";
  ServerSideTransactionResultStatus2["StoreLoading"] = "StoreLoading";
  ServerSideTransactionResultStatus2["StoreWaitingToLoad"] = "StoreWaitingToLoad";
  ServerSideTransactionResultStatus2["StoreLoadingFailed"] = "StoreLoadingFailed";
  ServerSideTransactionResultStatus2["StoreWrongType"] = "StoreWrongType";
  ServerSideTransactionResultStatus2["Cancelled"] = "Cancelled";
  ServerSideTransactionResultStatus2["StoreNotStarted"] = "StoreNotStarted";
  return ServerSideTransactionResultStatus2;
})(ServerSideTransactionResultStatus || {});

// community-modules/core/src/rowNodeCache/rowNodeBlock.ts
var RowNodeBlock = class extends BeanStub {
  constructor(id) {
    super();
    this.state = "needsLoading";
    this.version = 0;
    this.id = id;
  }
  getId() {
    return this.id;
  }
  load() {
    this.state = "loading";
    this.loadFromDatasource();
  }
  getVersion() {
    return this.version;
  }
  setStateWaitingToLoad() {
    this.version++;
    this.state = "needsLoading";
  }
  getState() {
    return this.state;
  }
  pageLoadFailed(version) {
    const requestMostRecentAndLive = this.isRequestMostRecentAndLive(version);
    if (requestMostRecentAndLive) {
      this.state = "failed";
      this.processServerFail();
    }
    this.dispatchLoadCompleted(false);
  }
  success(version, params) {
    this.successCommon(version, params);
  }
  pageLoaded(version, rows, lastRow) {
    this.successCommon(version, { rowData: rows, rowCount: lastRow });
  }
  isRequestMostRecentAndLive(version) {
    const thisIsMostRecentRequest = version === this.version;
    const weAreNotDestroyed = this.isAlive();
    return thisIsMostRecentRequest && weAreNotDestroyed;
  }
  successCommon(version, params) {
    this.dispatchLoadCompleted();
    const requestMostRecentAndLive = this.isRequestMostRecentAndLive(version);
    if (requestMostRecentAndLive) {
      this.state = "loaded";
      this.processServerResult(params);
    }
  }
  dispatchLoadCompleted(success = true) {
    const event = {
      type: "loadComplete",
      success,
      block: this
    };
    this.dispatchLocalEvent(event);
  }
};

// community-modules/core/src/rowNodeCache/rowNodeBlockLoader.ts
var RowNodeBlockLoader = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "rowNodeBlockLoader";
    this.activeBlockLoadsCount = 0;
    this.blocks = [];
    this.active = true;
  }
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
  }
  postConstruct() {
    this.maxConcurrentRequests = this.getMaxConcurrentDatasourceRequests();
    const blockLoadDebounceMillis = this.gos.get("blockLoadDebounceMillis");
    if (blockLoadDebounceMillis && blockLoadDebounceMillis > 0) {
      this.checkBlockToLoadDebounce = _debounce(
        this.performCheckBlocksToLoad.bind(this),
        blockLoadDebounceMillis
      );
    }
  }
  getMaxConcurrentDatasourceRequests() {
    const res = this.gos.get("maxConcurrentDatasourceRequests");
    if (res == null) {
      return 2;
    }
    if (res <= 0) {
      return;
    }
    return res;
  }
  addBlock(block) {
    this.blocks.push(block);
    block.addEventListener("loadComplete", this.loadComplete.bind(this));
    this.checkBlockToLoad();
  }
  removeBlock(block) {
    _removeFromArray(this.blocks, block);
  }
  destroy() {
    super.destroy();
    this.active = false;
  }
  loadComplete() {
    this.activeBlockLoadsCount--;
    this.checkBlockToLoad();
    this.dispatchLocalEvent({ type: "blockLoaded" });
    if (this.activeBlockLoadsCount == 0) {
      this.dispatchLocalEvent({ type: "blockLoaderFinished" });
    }
  }
  checkBlockToLoad() {
    if (this.checkBlockToLoadDebounce) {
      this.checkBlockToLoadDebounce();
    } else {
      this.performCheckBlocksToLoad();
    }
  }
  performCheckBlocksToLoad() {
    if (!this.active) {
      return;
    }
    this.printCacheStatus();
    if (this.maxConcurrentRequests != null && this.activeBlockLoadsCount >= this.maxConcurrentRequests) {
      if (this.gos.get("debug")) {
        _log(`RowNodeBlockLoader - checkBlockToLoad: max loads exceeded`);
      }
      return;
    }
    const loadAvailability = this.getAvailableLoadingCount();
    const blocksToLoad = this.blocks.filter((block) => block.getState() === "needsLoading").slice(0, loadAvailability);
    this.registerLoads(blocksToLoad.length);
    blocksToLoad.forEach((block) => block.load());
    this.printCacheStatus();
  }
  getBlockState() {
    if (this.gos.isRowModelType("serverSide")) {
      const ssrm = this.rowModel;
      return ssrm.getBlockStates();
    }
    const result = {};
    this.blocks.forEach((block) => {
      const { id, state } = block.getBlockStateJson();
      result[id] = state;
    });
    return result;
  }
  printCacheStatus() {
    if (this.gos.get("debug")) {
      _log(
        `RowNodeBlockLoader - printCacheStatus: activePageLoadsCount = ${this.activeBlockLoadsCount}, blocks = ${JSON.stringify(this.getBlockState())}`
      );
    }
  }
  isLoading() {
    return this.activeBlockLoadsCount > 0;
  }
  registerLoads(count) {
    this.activeBlockLoadsCount += count;
  }
  getAvailableLoadingCount() {
    return this.maxConcurrentRequests !== void 0 ? this.maxConcurrentRequests - this.activeBlockLoadsCount : void 0;
  }
};

// community-modules/core/src/rowNodeCache/rowNodeBlockModule.ts
var RowNodeBlockModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/row-node-block",
  beans: [RowNodeBlockLoader]
};

// community-modules/core/src/selection/rowRangeSelectionContext.ts
var RowRangeSelectionContext = class {
  constructor() {
    this.root = null;
    /**
     * Note that the "end" `RowNode` may come before or after the "root" `RowNode` in the
     * actual grid.
     */
    this.end = null;
    this.cachedRange = [];
  }
  init(rowModel) {
    this.rowModel = rowModel;
  }
  reset() {
    this.root = null;
    this.end = null;
    this.cachedRange.length = 0;
  }
  setRoot(node) {
    this.root = node;
    this.end = null;
    this.cachedRange.length = 0;
  }
  setEndRange(end) {
    this.end = end;
    this.cachedRange.length = 0;
  }
  getRange() {
    if (this.cachedRange.length === 0) {
      const root = this.getRoot();
      const end = this.getEnd();
      if (root == null || end == null) {
        return this.cachedRange;
      }
      this.cachedRange = this.rowModel.getNodesInRangeForSelection(root, end);
    }
    return this.cachedRange;
  }
  isInRange(node) {
    if (this.root === null) {
      return false;
    }
    return this.getRange().some((nodeInRange) => nodeInRange.id === node.id);
  }
  getRoot() {
    if (this.root && this.root?.key === null) {
      this.root = this.rowModel.getRowNode(this.root.id) ?? null;
    }
    return this.root;
  }
  getEnd() {
    if (this.end && this.end?.key === null) {
      this.end = this.rowModel.getRowNode(this.end.id) ?? null;
    }
    return this.end;
  }
  /**
   * Truncates the range to the given node (assumed to be within the current range).
   * Returns nodes that remain in the current range and those that should be removed
   *
   * @param node - Node at which to truncate the range
   * @returns Object of nodes to either keep or discard (i.e. deselect) from the range
   */
  truncate(node) {
    const range = this.getRange();
    if (range.length === 0) {
      return { keep: [], discard: [] };
    }
    const discardAfter = range[0].id === this.root.id;
    const idx = range.findIndex((rowNode) => rowNode.id === node.id);
    if (idx > -1) {
      const above = range.slice(0, idx);
      const below = range.slice(idx + 1);
      this.setEndRange(node);
      return discardAfter ? { keep: above, discard: below } : { keep: below, discard: above };
    } else {
      return { keep: range, discard: [] };
    }
  }
  /**
   * Extends the range to the given node. Returns nodes that remain in the current range
   * and those that should be removed.
   *
   * @param node - Node marking the new end of the range
   * @returns Object of nodes to either keep or discard (i.e. deselect) from the range
   */
  extend(node, groupSelectsChildren = false) {
    const root = this.getRoot();
    if (root == null) {
      const keep = this.getRange().slice();
      if (groupSelectsChildren) {
        node.depthFirstSearch((node2) => !node2.group && keep.push(node2));
      }
      keep.push(node);
      this.setRoot(node);
      return { keep, discard: [] };
    }
    const newRange = this.rowModel.getNodesInRangeForSelection(root, node);
    if (newRange.find((newRangeNode) => newRangeNode.id === this.end?.id)) {
      this.setEndRange(node);
      return { keep: this.getRange(), discard: [] };
    } else {
      const discard = this.getRange().slice();
      this.setEndRange(node);
      return { keep: this.getRange(), discard };
    }
  }
};

// community-modules/core/src/styling/stylingService.ts
var StylingService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "stylingService";
  }
  wireBeans(beans) {
    this.expressionService = beans.expressionService;
  }
  processAllCellClasses(colDef, params, onApplicableClass, onNotApplicableClass) {
    this.processClassRules(void 0, colDef.cellClassRules, params, onApplicableClass, onNotApplicableClass);
    this.processStaticCellClasses(colDef, params, onApplicableClass);
  }
  processClassRules(previousClassRules, classRules, params, onApplicableClass, onNotApplicableClass) {
    if (classRules == null && previousClassRules == null) {
      return;
    }
    const classesToApply = {};
    const classesToRemove = {};
    const forEachSingleClass = (className, callback) => {
      className.split(" ").forEach((singleClass) => {
        if (singleClass.trim() == "")
          return;
        callback(singleClass);
      });
    };
    if (classRules) {
      const classNames = Object.keys(classRules);
      for (let i = 0; i < classNames.length; i++) {
        const className = classNames[i];
        const rule = classRules[className];
        let resultOfRule;
        if (typeof rule === "string") {
          resultOfRule = this.expressionService.evaluate(rule, params);
        } else if (typeof rule === "function") {
          resultOfRule = rule(params);
        }
        forEachSingleClass(className, (singleClass) => {
          resultOfRule ? classesToApply[singleClass] = true : classesToRemove[singleClass] = true;
        });
      }
    }
    if (previousClassRules && onNotApplicableClass) {
      Object.keys(previousClassRules).forEach(
        (className) => forEachSingleClass(className, (singleClass) => {
          if (!classesToApply[singleClass]) {
            classesToRemove[singleClass] = true;
          }
        })
      );
    }
    if (onNotApplicableClass) {
      Object.keys(classesToRemove).forEach(onNotApplicableClass);
    }
    Object.keys(classesToApply).forEach(onApplicableClass);
  }
  getStaticCellClasses(colDef, params) {
    const { cellClass } = colDef;
    if (!cellClass) {
      return [];
    }
    let classOrClasses;
    if (typeof cellClass === "function") {
      const cellClassFunc = cellClass;
      classOrClasses = cellClassFunc(params);
    } else {
      classOrClasses = cellClass;
    }
    if (typeof classOrClasses === "string") {
      classOrClasses = [classOrClasses];
    }
    return classOrClasses || [];
  }
  processStaticCellClasses(colDef, params, onApplicableClass) {
    const classOrClasses = this.getStaticCellClasses(colDef, params);
    classOrClasses.forEach((cssClassItem) => {
      onApplicableClass(cssClassItem);
    });
  }
};

// community-modules/core/src/widgets/agToggleButton.ts
var AgToggleButton = class extends AgCheckbox {
  constructor(config) {
    super(config, "ag-toggle-button");
  }
  setValue(value, silent) {
    super.setValue(value, silent);
    this.addOrRemoveCssClass("ag-selected", this.getValue());
    return this;
  }
};
var AgToggleButtonSelector = {
  selector: "AG-TOGGLE-BUTTON",
  component: AgToggleButton
};

// community-modules/core/src/widgets/tabGuardCtrl.ts
var TabGuardClassNames = /* @__PURE__ */ ((TabGuardClassNames2) => {
  TabGuardClassNames2["TAB_GUARD"] = "ag-tab-guard";
  TabGuardClassNames2["TAB_GUARD_TOP"] = "ag-tab-guard-top";
  TabGuardClassNames2["TAB_GUARD_BOTTOM"] = "ag-tab-guard-bottom";
  return TabGuardClassNames2;
})(TabGuardClassNames || {});
var TabGuardCtrl = class extends BeanStub {
  constructor(params) {
    super();
    this.skipTabGuardFocus = false;
    this.forcingFocusOut = false;
    // Used when `isFocusableContainer` enabled
    this.allowFocus = false;
    const {
      comp,
      eTopGuard,
      eBottomGuard,
      focusTrapActive,
      forceFocusOutWhenTabGuardsAreEmpty,
      isFocusableContainer,
      focusInnerElement,
      onFocusIn,
      onFocusOut,
      shouldStopEventPropagation,
      onTabKeyDown,
      handleKeyDown,
      eFocusableElement
    } = params;
    this.comp = comp;
    this.eTopGuard = eTopGuard;
    this.eBottomGuard = eBottomGuard;
    this.providedFocusInnerElement = focusInnerElement;
    this.eFocusableElement = eFocusableElement;
    this.focusTrapActive = !!focusTrapActive;
    this.forceFocusOutWhenTabGuardsAreEmpty = !!forceFocusOutWhenTabGuardsAreEmpty;
    this.isFocusableContainer = !!isFocusableContainer;
    this.providedFocusIn = onFocusIn;
    this.providedFocusOut = onFocusOut;
    this.providedShouldStopEventPropagation = shouldStopEventPropagation;
    this.providedOnTabKeyDown = onTabKeyDown;
    this.providedHandleKeyDown = handleKeyDown;
  }
  wireBeans(beans) {
    this.focusService = beans.focusService;
  }
  postConstruct() {
    this.createManagedBean(
      new ManagedFocusFeature(this.eFocusableElement, {
        shouldStopEventPropagation: () => this.shouldStopEventPropagation(),
        onTabKeyDown: (e) => this.onTabKeyDown(e),
        handleKeyDown: (e) => this.handleKeyDown(e),
        onFocusIn: (e) => this.onFocusIn(e),
        onFocusOut: (e) => this.onFocusOut(e)
      })
    );
    this.activateTabGuards();
    [this.eTopGuard, this.eBottomGuard].forEach(
      (guard) => this.addManagedElementListeners(guard, { focus: this.onFocus.bind(this) })
    );
  }
  handleKeyDown(e) {
    if (this.providedHandleKeyDown) {
      this.providedHandleKeyDown(e);
    }
  }
  tabGuardsAreActive() {
    return !!this.eTopGuard && this.eTopGuard.hasAttribute("tabIndex");
  }
  shouldStopEventPropagation() {
    if (this.providedShouldStopEventPropagation) {
      return this.providedShouldStopEventPropagation();
    }
    return false;
  }
  activateTabGuards() {
    if (this.forcingFocusOut) {
      return;
    }
    const tabIndex = this.gos.get("tabIndex");
    this.comp.setTabIndex(tabIndex.toString());
  }
  deactivateTabGuards() {
    this.comp.setTabIndex();
  }
  onFocus(e) {
    if (this.isFocusableContainer && !this.eFocusableElement.contains(e.relatedTarget)) {
      if (!this.allowFocus) {
        this.findNextElementOutsideAndFocus(e.target === this.eBottomGuard);
        return;
      }
    }
    if (this.skipTabGuardFocus) {
      this.skipTabGuardFocus = false;
      return;
    }
    if (this.forceFocusOutWhenTabGuardsAreEmpty) {
      const isEmpty = this.focusService.findFocusableElements(this.eFocusableElement, ".ag-tab-guard").length === 0;
      if (isEmpty) {
        this.findNextElementOutsideAndFocus(e.target === this.eBottomGuard);
        return;
      }
    }
    if (this.isFocusableContainer && this.eFocusableElement.contains(e.relatedTarget)) {
      return;
    }
    const fromBottom = e.target === this.eBottomGuard;
    if (this.providedFocusInnerElement) {
      this.providedFocusInnerElement(fromBottom);
    } else {
      this.focusInnerElement(fromBottom);
    }
  }
  findNextElementOutsideAndFocus(up) {
    const eDocument = this.gos.getDocument();
    const focusableEls = this.focusService.findFocusableElements(eDocument.body, null, true);
    const index = focusableEls.indexOf(up ? this.eTopGuard : this.eBottomGuard);
    if (index === -1) {
      return;
    }
    let start;
    let end;
    if (up) {
      start = 0;
      end = index;
    } else {
      start = index + 1;
      end = focusableEls.length;
    }
    const focusableRange = focusableEls.slice(start, end);
    const targetTabIndex = this.gos.get("tabIndex");
    focusableRange.sort((a, b) => {
      const indexA = parseInt(a.getAttribute("tabindex") || "0");
      const indexB = parseInt(b.getAttribute("tabindex") || "0");
      if (indexB === targetTabIndex) {
        return 1;
      }
      if (indexA === targetTabIndex) {
        return -1;
      }
      if (indexA === 0) {
        return 1;
      }
      if (indexB === 0) {
        return -1;
      }
      return indexA - indexB;
    });
    focusableRange[up ? focusableRange.length - 1 : 0].focus();
  }
  onFocusIn(e) {
    if (this.focusTrapActive || this.forcingFocusOut) {
      return;
    }
    if (this.providedFocusIn) {
      this.providedFocusIn(e);
    }
    if (!this.isFocusableContainer) {
      this.deactivateTabGuards();
    }
  }
  onFocusOut(e) {
    if (this.focusTrapActive) {
      return;
    }
    if (this.providedFocusOut) {
      this.providedFocusOut(e);
    }
    if (!this.eFocusableElement.contains(e.relatedTarget)) {
      this.activateTabGuards();
    }
  }
  onTabKeyDown(e) {
    if (this.providedOnTabKeyDown) {
      this.providedOnTabKeyDown(e);
      return;
    }
    if (this.focusTrapActive) {
      return;
    }
    if (e.defaultPrevented) {
      return;
    }
    const tabGuardsAreActive = this.tabGuardsAreActive();
    if (tabGuardsAreActive) {
      this.deactivateTabGuards();
    }
    const nextRoot = this.getNextFocusableElement(e.shiftKey);
    if (tabGuardsAreActive) {
      setTimeout(() => this.activateTabGuards(), 0);
    }
    if (!nextRoot) {
      return;
    }
    nextRoot.focus();
    e.preventDefault();
  }
  focusInnerElement(fromBottom = false) {
    const focusable = this.focusService.findFocusableElements(this.eFocusableElement);
    if (this.tabGuardsAreActive()) {
      focusable.splice(0, 1);
      focusable.splice(focusable.length - 1, 1);
    }
    if (!focusable.length) {
      return;
    }
    focusable[fromBottom ? focusable.length - 1 : 0].focus({ preventScroll: true });
  }
  getNextFocusableElement(backwards) {
    return this.focusService.findNextFocusableElement(this.eFocusableElement, false, backwards);
  }
  forceFocusOutOfContainer(up = false) {
    if (this.forcingFocusOut) {
      return;
    }
    const tabGuardToFocus = up ? this.eTopGuard : this.eBottomGuard;
    this.activateTabGuards();
    this.skipTabGuardFocus = true;
    this.forcingFocusOut = true;
    tabGuardToFocus.focus();
    window.setTimeout(() => {
      this.forcingFocusOut = false;
      this.activateTabGuards();
    });
  }
  isTabGuard(element, bottom) {
    return element === this.eTopGuard && !bottom || element === this.eBottomGuard && (bottom ?? true);
  }
  setAllowFocus(allowFocus) {
    this.allowFocus = allowFocus;
  }
};

// community-modules/core/src/widgets/tabGuardFeature.ts
var TabGuardFeature = class extends BeanStub {
  constructor(comp) {
    super();
    this.comp = comp;
  }
  initialiseTabGuard(params) {
    this.eTopGuard = this.createTabGuard("top");
    this.eBottomGuard = this.createTabGuard("bottom");
    this.eFocusableElement = this.comp.getFocusableElement();
    const { eTopGuard, eBottomGuard, eFocusableElement } = this;
    const tabGuards = [eTopGuard, eBottomGuard];
    const compProxy = {
      setTabIndex: (tabIndex) => {
        tabGuards.forEach(
          (tabGuard) => tabIndex != null ? tabGuard.setAttribute("tabindex", tabIndex) : tabGuard.removeAttribute("tabindex")
        );
      }
    };
    this.addTabGuards(eTopGuard, eBottomGuard);
    const {
      focusTrapActive = false,
      onFocusIn,
      onFocusOut,
      focusInnerElement,
      handleKeyDown,
      onTabKeyDown,
      shouldStopEventPropagation,
      forceFocusOutWhenTabGuardsAreEmpty,
      isFocusableContainer
    } = params;
    this.tabGuardCtrl = this.createManagedBean(
      new TabGuardCtrl({
        comp: compProxy,
        focusTrapActive,
        eTopGuard,
        eBottomGuard,
        eFocusableElement,
        onFocusIn,
        onFocusOut,
        focusInnerElement,
        handleKeyDown,
        onTabKeyDown,
        shouldStopEventPropagation,
        forceFocusOutWhenTabGuardsAreEmpty,
        isFocusableContainer
      })
    );
  }
  getTabGuardCtrl() {
    return this.tabGuardCtrl;
  }
  createTabGuard(side) {
    const tabGuard = this.gos.getDocument().createElement("div");
    const cls = side === "top" ? "ag-tab-guard-top" /* TAB_GUARD_TOP */ : "ag-tab-guard-bottom" /* TAB_GUARD_BOTTOM */;
    tabGuard.classList.add("ag-tab-guard" /* TAB_GUARD */, cls);
    _setAriaRole(tabGuard, "presentation");
    return tabGuard;
  }
  addTabGuards(topTabGuard, bottomTabGuard) {
    this.eFocusableElement.insertAdjacentElement("afterbegin", topTabGuard);
    this.eFocusableElement.insertAdjacentElement("beforeend", bottomTabGuard);
  }
  removeAllChildrenExceptTabGuards() {
    const tabGuards = [this.eTopGuard, this.eBottomGuard];
    _clearElement(this.comp.getFocusableElement());
    this.addTabGuards(...tabGuards);
  }
  forceFocusOutOfContainer(up = false) {
    this.tabGuardCtrl.forceFocusOutOfContainer(up);
  }
  appendChild(appendChild, newChild, container) {
    if (!_isNodeOrElement(newChild)) {
      newChild = newChild.getGui();
    }
    const { eBottomGuard: bottomTabGuard } = this;
    if (bottomTabGuard) {
      bottomTabGuard.insertAdjacentElement("beforebegin", newChild);
    } else {
      appendChild(newChild, container);
    }
  }
};

// community-modules/core/src/widgets/tabGuardComp.ts
var TabGuardComp = class extends Component {
  initialiseTabGuard(params) {
    this.tabGuardFeature = this.createManagedBean(new TabGuardFeature(this));
    this.tabGuardFeature.initialiseTabGuard(params);
  }
  forceFocusOutOfContainer(up = false) {
    this.tabGuardFeature.forceFocusOutOfContainer(up);
  }
  appendChild(newChild, container) {
    this.tabGuardFeature.appendChild(super.appendChild.bind(this), newChild, container);
  }
};

// community-modules/core/src/widgets/popupService.ts
var instanceIdSeq = 0;
var WAIT_FOR_POPUP_CONTENT_RESIZE = 200;
var PopupService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "popupService";
    this.popupList = [];
  }
  wireBeans(beans) {
    this.ctrlsService = beans.ctrlsService;
    this.resizeObserverService = beans.resizeObserverService;
    this.environment = beans.environment;
  }
  postConstruct() {
    this.ctrlsService.whenReady((p) => {
      this.gridCtrl = p.gridCtrl;
    });
    this.addManagedEventListeners({ gridStylesChanged: this.handleThemeChange.bind(this) });
  }
  getPopupParent() {
    const ePopupParent = this.gos.get("popupParent");
    if (ePopupParent) {
      return ePopupParent;
    }
    return this.gridCtrl.getGui();
  }
  positionPopupForMenu(params) {
    const { eventSource, ePopup } = params;
    const popupIdx = this.getPopupIndex(ePopup);
    if (popupIdx !== -1) {
      const popup = this.popupList[popupIdx];
      popup.alignedToElement = eventSource;
    }
    const sourceRect = eventSource.getBoundingClientRect();
    const parentRect = this.getParentRect();
    const y = this.keepXYWithinBounds(ePopup, sourceRect.top - parentRect.top, 0 /* vertical */);
    const minWidth = ePopup.clientWidth > 0 ? ePopup.clientWidth : 200;
    ePopup.style.minWidth = `${minWidth}px`;
    const widthOfParent = parentRect.right - parentRect.left;
    const maxX = widthOfParent - minWidth;
    let x;
    if (this.gos.get("enableRtl")) {
      x = xLeftPosition();
      if (x < 0) {
        x = xRightPosition();
        this.setAlignedStyles(ePopup, "left");
      }
      if (x > maxX) {
        x = 0;
        this.setAlignedStyles(ePopup, "right");
      }
    } else {
      x = xRightPosition();
      if (x > maxX) {
        x = xLeftPosition();
        this.setAlignedStyles(ePopup, "right");
      }
      if (x < 0) {
        x = 0;
        this.setAlignedStyles(ePopup, "left");
      }
    }
    ePopup.style.left = `${x}px`;
    ePopup.style.top = `${y}px`;
    function xRightPosition() {
      return sourceRect.right - parentRect.left - 2;
    }
    function xLeftPosition() {
      return sourceRect.left - parentRect.left - minWidth;
    }
  }
  positionPopupUnderMouseEvent(params) {
    const { ePopup, nudgeX, nudgeY, skipObserver } = params;
    this.positionPopup({
      ePopup,
      nudgeX,
      nudgeY,
      keepWithinBounds: true,
      skipObserver,
      updatePosition: () => this.calculatePointerAlign(params.mouseEvent),
      postProcessCallback: () => this.callPostProcessPopup(
        params.type,
        params.ePopup,
        null,
        params.mouseEvent,
        params.column,
        params.rowNode
      )
    });
  }
  calculatePointerAlign(e) {
    const parentRect = this.getParentRect();
    return {
      x: e.clientX - parentRect.left,
      y: e.clientY - parentRect.top
    };
  }
  positionPopupByComponent(params) {
    const {
      ePopup,
      nudgeX,
      nudgeY,
      keepWithinBounds,
      eventSource,
      alignSide = "left",
      position = "over",
      column,
      rowNode,
      type
    } = params;
    const sourceRect = eventSource.getBoundingClientRect();
    const parentRect = this.getParentRect();
    const popupIdx = this.getPopupIndex(ePopup);
    if (popupIdx !== -1) {
      const popup = this.popupList[popupIdx];
      popup.alignedToElement = eventSource;
    }
    const updatePosition = () => {
      let x = sourceRect.left - parentRect.left;
      if (alignSide === "right") {
        x -= ePopup.offsetWidth - sourceRect.width;
      }
      let y;
      if (position === "over") {
        y = sourceRect.top - parentRect.top;
        this.setAlignedStyles(ePopup, "over");
      } else {
        this.setAlignedStyles(ePopup, "under");
        const alignSide2 = this.shouldRenderUnderOrAbove(ePopup, sourceRect, parentRect, params.nudgeY || 0);
        if (alignSide2 === "under") {
          y = sourceRect.top - parentRect.top + sourceRect.height;
        } else {
          y = sourceRect.top - ePopup.offsetHeight - (nudgeY || 0) * 2 - parentRect.top;
        }
      }
      return { x, y };
    };
    this.positionPopup({
      ePopup,
      nudgeX,
      nudgeY,
      keepWithinBounds,
      updatePosition,
      postProcessCallback: () => this.callPostProcessPopup(type, ePopup, eventSource, null, column, rowNode)
    });
  }
  shouldRenderUnderOrAbove(ePopup, targetCompRect, parentRect, nudgeY) {
    const spaceAvailableUnder = parentRect.bottom - targetCompRect.bottom;
    const spaceAvailableAbove = targetCompRect.top - parentRect.top;
    const spaceRequired = ePopup.offsetHeight + nudgeY;
    if (spaceAvailableUnder > spaceRequired) {
      return "under";
    }
    if (spaceAvailableAbove > spaceRequired || spaceAvailableAbove > spaceAvailableUnder) {
      return "above";
    }
    return "under";
  }
  setAlignedStyles(ePopup, positioned) {
    const popupIdx = this.getPopupIndex(ePopup);
    if (popupIdx === -1) {
      return;
    }
    const popup = this.popupList[popupIdx];
    const { alignedToElement } = popup;
    if (!alignedToElement) {
      return;
    }
    const positions = ["right", "left", "over", "above", "under"];
    positions.forEach((position) => {
      alignedToElement.classList.remove(`ag-has-popup-positioned-${position}`);
      ePopup.classList.remove(`ag-popup-positioned-${position}`);
    });
    if (!positioned) {
      return;
    }
    alignedToElement.classList.add(`ag-has-popup-positioned-${positioned}`);
    ePopup.classList.add(`ag-popup-positioned-${positioned}`);
  }
  callPostProcessPopup(type, ePopup, eventSource, mouseEvent, column, rowNode) {
    const callback = this.gos.getCallback("postProcessPopup");
    if (callback) {
      const params = {
        column,
        rowNode,
        ePopup,
        type,
        eventSource,
        mouseEvent
      };
      callback(params);
    }
  }
  positionPopup(params) {
    const { ePopup, keepWithinBounds, nudgeX, nudgeY, skipObserver, updatePosition } = params;
    const lastSize = { width: 0, height: 0 };
    const updatePopupPosition = (fromResizeObserver = false) => {
      let { x, y } = updatePosition();
      if (fromResizeObserver && ePopup.clientWidth === lastSize.width && ePopup.clientHeight === lastSize.height) {
        return;
      }
      lastSize.width = ePopup.clientWidth;
      lastSize.height = ePopup.clientHeight;
      if (nudgeX) {
        x += nudgeX;
      }
      if (nudgeY) {
        y += nudgeY;
      }
      if (keepWithinBounds) {
        x = this.keepXYWithinBounds(ePopup, x, 1 /* horizontal */);
        y = this.keepXYWithinBounds(ePopup, y, 0 /* vertical */);
      }
      ePopup.style.left = `${x}px`;
      ePopup.style.top = `${y}px`;
      if (params.postProcessCallback) {
        params.postProcessCallback();
      }
    };
    updatePopupPosition();
    if (!skipObserver) {
      const resizeObserverDestroyFunc = this.resizeObserverService.observeResize(
        ePopup,
        () => updatePopupPosition(true)
      );
      setTimeout(() => resizeObserverDestroyFunc(), WAIT_FOR_POPUP_CONTENT_RESIZE);
    }
  }
  getActivePopups() {
    return this.popupList.map((popup) => popup.element);
  }
  getPopupList() {
    return this.popupList;
  }
  getParentRect() {
    const eDocument = this.gos.getDocument();
    let popupParent = this.getPopupParent();
    if (popupParent === eDocument.body) {
      popupParent = eDocument.documentElement;
    } else if (getComputedStyle(popupParent).position === "static") {
      popupParent = popupParent.offsetParent;
    }
    return _getElementRectWithOffset(popupParent);
  }
  keepXYWithinBounds(ePopup, position, direction) {
    const isVertical = direction === 0 /* vertical */;
    const sizeProperty = isVertical ? "clientHeight" : "clientWidth";
    const anchorProperty = isVertical ? "top" : "left";
    const offsetProperty = isVertical ? "height" : "width";
    const scrollPositionProperty = isVertical ? "scrollTop" : "scrollLeft";
    const eDocument = this.gos.getDocument();
    const docElement = eDocument.documentElement;
    const popupParent = this.getPopupParent();
    const popupRect = ePopup.getBoundingClientRect();
    const parentRect = popupParent.getBoundingClientRect();
    const documentRect = eDocument.documentElement.getBoundingClientRect();
    const isBody = popupParent === eDocument.body;
    const offsetSize = Math.ceil(popupRect[offsetProperty]);
    const getSize = isVertical ? _getAbsoluteHeight : _getAbsoluteWidth;
    let sizeOfParent = isBody ? getSize(docElement) + docElement[scrollPositionProperty] : popupParent[sizeProperty];
    if (isBody) {
      sizeOfParent -= Math.abs(documentRect[anchorProperty] - parentRect[anchorProperty]);
    }
    const max = sizeOfParent - offsetSize;
    return Math.min(Math.max(position, 0), Math.abs(max));
  }
  addPopup(params) {
    const eDocument = this.gos.getDocument();
    const { eChild, ariaLabel, alwaysOnTop, positionCallback, anchorToElement } = params;
    if (!eDocument) {
      _warnOnce("could not find the document, document is empty");
      return { hideFunc: () => {
      } };
    }
    const pos = this.getPopupIndex(eChild);
    if (pos !== -1) {
      const popup = this.popupList[pos];
      return { hideFunc: popup.hideFunc };
    }
    this.initialisePopupPosition(eChild);
    const wrapperEl = this.createPopupWrapper(eChild, ariaLabel, !!alwaysOnTop);
    const removeListeners = this.addEventListenersToPopup({ ...params, wrapperEl });
    if (positionCallback) {
      positionCallback();
    }
    this.addPopupToPopupList(eChild, wrapperEl, removeListeners, anchorToElement);
    return {
      hideFunc: removeListeners
    };
  }
  initialisePopupPosition(element) {
    const ePopupParent = this.getPopupParent();
    const ePopupParentRect = ePopupParent.getBoundingClientRect();
    if (!_exists(element.style.top)) {
      element.style.top = `${ePopupParentRect.top * -1}px`;
    }
    if (!_exists(element.style.left)) {
      element.style.left = `${ePopupParentRect.left * -1}px`;
    }
  }
  createPopupWrapper(element, ariaLabel, alwaysOnTop) {
    const ePopupParent = this.getPopupParent();
    const eWrapper = document.createElement("div");
    this.environment.applyThemeClasses(eWrapper);
    eWrapper.classList.add("ag-popup");
    element.classList.add(this.gos.get("enableRtl") ? "ag-rtl" : "ag-ltr", "ag-popup-child");
    if (!element.hasAttribute("role")) {
      _setAriaRole(element, "dialog");
    }
    _setAriaLabel(element, ariaLabel);
    eWrapper.appendChild(element);
    ePopupParent.appendChild(eWrapper);
    if (alwaysOnTop) {
      this.setAlwaysOnTop(element, true);
    } else {
      this.bringPopupToFront(element);
    }
    return eWrapper;
  }
  handleThemeChange(e) {
    if (e.themeChanged) {
      for (const popup of this.popupList) {
        this.environment.applyThemeClasses(popup.wrapper);
      }
    }
  }
  addEventListenersToPopup(params) {
    const eDocument = this.gos.getDocument();
    const ePopupParent = this.getPopupParent();
    const { wrapperEl, eChild: popupEl, closedCallback, afterGuiAttached, closeOnEsc, modal } = params;
    let popupHidden = false;
    const hidePopupOnKeyboardEvent = (event) => {
      if (!wrapperEl.contains(this.gos.getActiveDomElement())) {
        return;
      }
      const key = event.key;
      if (key === KeyCode.ESCAPE && !_isStopPropagationForAgGrid(event)) {
        removeListeners({ keyboardEvent: event });
      }
    };
    const hidePopupOnMouseEvent = (event) => removeListeners({ mouseEvent: event });
    const hidePopupOnTouchEvent = (event) => removeListeners({ touchEvent: event });
    const removeListeners = (popupParams = {}) => {
      const { mouseEvent, touchEvent, keyboardEvent, forceHide } = popupParams;
      if (!forceHide && // we don't hide popup if the event was on the child, or any
      // children of this child
      (this.isEventFromCurrentPopup({ mouseEvent, touchEvent }, popupEl) || // this method should only be called once. the client can have different
      // paths, each one wanting to close, so this method may be called multiple times.
      popupHidden)) {
        return;
      }
      popupHidden = true;
      ePopupParent.removeChild(wrapperEl);
      eDocument.removeEventListener("keydown", hidePopupOnKeyboardEvent);
      eDocument.removeEventListener("mousedown", hidePopupOnMouseEvent);
      eDocument.removeEventListener("touchstart", hidePopupOnTouchEvent);
      eDocument.removeEventListener("contextmenu", hidePopupOnMouseEvent);
      this.eventService.removeEventListener("dragStarted", hidePopupOnMouseEvent);
      if (closedCallback) {
        closedCallback(mouseEvent || touchEvent || keyboardEvent);
      }
      this.removePopupFromPopupList(popupEl);
    };
    if (afterGuiAttached) {
      afterGuiAttached({ hidePopup: removeListeners });
    }
    window.setTimeout(() => {
      if (closeOnEsc) {
        eDocument.addEventListener("keydown", hidePopupOnKeyboardEvent);
      }
      if (modal) {
        eDocument.addEventListener("mousedown", hidePopupOnMouseEvent);
        this.eventService.addEventListener("dragStarted", hidePopupOnMouseEvent);
        eDocument.addEventListener("touchstart", hidePopupOnTouchEvent);
        eDocument.addEventListener("contextmenu", hidePopupOnMouseEvent);
      }
    }, 0);
    return removeListeners;
  }
  addPopupToPopupList(element, wrapperEl, removeListeners, anchorToElement) {
    this.popupList.push({
      element,
      wrapper: wrapperEl,
      hideFunc: removeListeners,
      instanceId: instanceIdSeq++,
      isAnchored: !!anchorToElement
    });
    if (anchorToElement) {
      this.setPopupPositionRelatedToElement(element, anchorToElement);
    }
  }
  getPopupIndex(el) {
    return this.popupList.findIndex((p) => p.element === el);
  }
  setPopupPositionRelatedToElement(popupEl, relativeElement) {
    const popupIndex = this.getPopupIndex(popupEl);
    if (popupIndex === -1) {
      return;
    }
    const popup = this.popupList[popupIndex];
    if (popup.stopAnchoringPromise) {
      popup.stopAnchoringPromise.then((destroyFunc) => destroyFunc && destroyFunc());
    }
    popup.stopAnchoringPromise = void 0;
    popup.isAnchored = false;
    if (!relativeElement) {
      return;
    }
    const destroyPositionTracker = this.keepPopupPositionedRelativeTo({
      element: relativeElement,
      ePopup: popupEl,
      hidePopup: popup.hideFunc
    });
    popup.stopAnchoringPromise = destroyPositionTracker;
    popup.isAnchored = true;
    return destroyPositionTracker;
  }
  removePopupFromPopupList(element) {
    this.setAlignedStyles(element, null);
    this.setPopupPositionRelatedToElement(element, null);
    this.popupList = this.popupList.filter((p) => p.element !== element);
  }
  keepPopupPositionedRelativeTo(params) {
    const eParent = this.getPopupParent();
    const parentRect = eParent.getBoundingClientRect();
    const { element, ePopup } = params;
    const sourceRect = element.getBoundingClientRect();
    const initialDiffTop = parentRect.top - sourceRect.top;
    const initialDiffLeft = parentRect.left - sourceRect.left;
    let lastDiffTop = initialDiffTop;
    let lastDiffLeft = initialDiffLeft;
    const topPx = ePopup.style.top;
    const top = parseInt(topPx.substring(0, topPx.length - 1), 10);
    const leftPx = ePopup.style.left;
    const left = parseInt(leftPx.substring(0, leftPx.length - 1), 10);
    const fwOverrides = this.getFrameworkOverrides();
    return new AgPromise((resolve) => {
      fwOverrides.wrapIncoming(() => {
        fwOverrides.setInterval(() => {
          const pRect = eParent.getBoundingClientRect();
          const sRect = element.getBoundingClientRect();
          const elementNotInDom = sRect.top == 0 && sRect.left == 0 && sRect.height == 0 && sRect.width == 0;
          if (elementNotInDom) {
            params.hidePopup();
            return;
          }
          const currentDiffTop = pRect.top - sRect.top;
          if (currentDiffTop != lastDiffTop) {
            const newTop = this.keepXYWithinBounds(
              ePopup,
              top + initialDiffTop - currentDiffTop,
              0 /* vertical */
            );
            ePopup.style.top = `${newTop}px`;
          }
          lastDiffTop = currentDiffTop;
          const currentDiffLeft = pRect.left - sRect.left;
          if (currentDiffLeft != lastDiffLeft) {
            const newLeft = this.keepXYWithinBounds(
              ePopup,
              left + initialDiffLeft - currentDiffLeft,
              1 /* horizontal */
            );
            ePopup.style.left = `${newLeft}px`;
          }
          lastDiffLeft = currentDiffLeft;
        }, 200).then((intervalId) => {
          const result = () => {
            if (intervalId != null) {
              window.clearInterval(intervalId);
            }
          };
          resolve(result);
        });
      }, "popupPositioning");
    });
  }
  hasAnchoredPopup() {
    return this.popupList.some((popup) => popup.isAnchored);
  }
  isEventFromCurrentPopup(params, target) {
    const { mouseEvent, touchEvent } = params;
    const event = mouseEvent ? mouseEvent : touchEvent;
    if (!event) {
      return false;
    }
    const indexOfThisChild = this.getPopupIndex(target);
    if (indexOfThisChild === -1) {
      return false;
    }
    for (let i = indexOfThisChild; i < this.popupList.length; i++) {
      const popup = this.popupList[i];
      if (_isElementInEventPath(popup.element, event)) {
        return true;
      }
    }
    return this.isElementWithinCustomPopup(event.target);
  }
  isElementWithinCustomPopup(el) {
    const eDocument = this.gos.getDocument();
    while (el && el !== eDocument.body) {
      if (el.classList.contains("ag-custom-component-popup") || el.parentElement === null) {
        return true;
      }
      el = el.parentElement;
    }
    return false;
  }
  getWrapper(ePopup) {
    while (!ePopup.classList.contains("ag-popup") && ePopup.parentElement) {
      ePopup = ePopup.parentElement;
    }
    return ePopup.classList.contains("ag-popup") ? ePopup : null;
  }
  setAlwaysOnTop(ePopup, alwaysOnTop) {
    const eWrapper = this.getWrapper(ePopup);
    if (!eWrapper) {
      return;
    }
    eWrapper.classList.toggle("ag-always-on-top", !!alwaysOnTop);
    if (alwaysOnTop) {
      this.bringPopupToFront(eWrapper);
    }
  }
  /** @return true if moved */
  bringPopupToFront(ePopup) {
    const parent = this.getPopupParent();
    const popupList = Array.prototype.slice.call(parent.querySelectorAll(".ag-popup"));
    const popupLen = popupList.length;
    const alwaysOnTopList = Array.prototype.slice.call(
      parent.querySelectorAll(".ag-popup.ag-always-on-top")
    );
    const onTopLength = alwaysOnTopList.length;
    const eWrapper = this.getWrapper(ePopup);
    if (!eWrapper || popupLen <= 1 || !parent.contains(ePopup)) {
      return false;
    }
    const pos = popupList.indexOf(eWrapper);
    const innerEls = eWrapper.querySelectorAll("div");
    const innerElsScrollMap = [];
    innerEls.forEach((el) => {
      if (el.scrollTop !== 0) {
        innerElsScrollMap.push([el, el.scrollTop]);
      }
    });
    let result = false;
    if (onTopLength) {
      const isPopupAlwaysOnTop = eWrapper.classList.contains("ag-always-on-top");
      if (isPopupAlwaysOnTop) {
        if (pos !== popupLen - 1) {
          _last(alwaysOnTopList).insertAdjacentElement("afterend", eWrapper);
          result = true;
        }
      } else if (pos !== popupLen - onTopLength - 1) {
        alwaysOnTopList[0].insertAdjacentElement("beforebegin", eWrapper);
        result = true;
      }
    } else if (pos !== popupLen - 1) {
      _last(popupList).insertAdjacentElement("afterend", eWrapper);
      result = true;
    }
    while (innerElsScrollMap.length) {
      const currentEl = innerElsScrollMap.pop();
      currentEl[0].scrollTop = currentEl[1];
    }
    return result;
  }
};

// community-modules/core/src/vanillaFrameworkOverrides.ts
var PASSIVE_EVENTS2 = ["touchstart", "touchend", "touchmove", "touchcancel"];
var VanillaFrameworkOverrides = class {
  constructor(frameworkName = "javascript") {
    this.frameworkName = frameworkName;
    this.renderingEngine = "vanilla";
    this.wrapIncoming = (callback) => callback();
    this.wrapOutgoing = (callback) => callback();
  }
  setInterval(action, timeout) {
    return new AgPromise((resolve) => {
      resolve(window.setInterval(action, timeout));
    });
  }
  // for Vanilla JS, we just add the event to the element
  addEventListener(element, type, listener, useCapture) {
    const isPassive = _includes(PASSIVE_EVENTS2, type);
    element.addEventListener(type, listener, { capture: !!useCapture, passive: isPassive });
  }
  get shouldWrapOutgoing() {
    return false;
  }
  frameworkComponent(name) {
    return null;
  }
  isFrameworkComponent(comp) {
    return false;
  }
  getDocLink(path) {
    const framework = this.frameworkName === "solid" ? "react" : this.frameworkName;
    return `https://www.ag-grid.com/${framework}-data-grid${path ? `/${path}` : ""}`;
  }
};

// community-modules/core/src/cellNavigationService.ts
var CellNavigationService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "cellNavigationService";
  }
  wireBeans(beans) {
    this.visibleColsService = beans.visibleColsService;
    this.rowModel = beans.rowModel;
    this.rowRenderer = beans.rowRenderer;
    this.pinnedRowModel = beans.pinnedRowModel;
    this.paginationService = beans.paginationService;
    this.pageBoundsService = beans.pageBoundsService;
  }
  // returns null if no cell to focus on, ie at the end of the grid
  getNextCellToFocus(key, focusedCell, ctrlPressed = false) {
    if (ctrlPressed) {
      return this.getNextCellToFocusWithCtrlPressed(key, focusedCell);
    }
    return this.getNextCellToFocusWithoutCtrlPressed(key, focusedCell);
  }
  getNextCellToFocusWithCtrlPressed(key, focusedCell) {
    const upKey = key === KeyCode.UP;
    const downKey = key === KeyCode.DOWN;
    const leftKey = key === KeyCode.LEFT;
    let column;
    let rowIndex;
    if (upKey || downKey) {
      rowIndex = upKey ? this.pageBoundsService.getFirstRow() : this.pageBoundsService.getLastRow();
      column = focusedCell.column;
    } else {
      const allColumns = this.visibleColsService.getAllCols();
      const isRtl = this.gos.get("enableRtl");
      rowIndex = focusedCell.rowIndex;
      column = leftKey !== isRtl ? allColumns[0] : _last(allColumns);
    }
    return {
      rowIndex,
      rowPinned: null,
      column
    };
  }
  getNextCellToFocusWithoutCtrlPressed(key, focusedCell) {
    let pointer = focusedCell;
    let finished = false;
    while (!finished) {
      switch (key) {
        case KeyCode.UP:
          pointer = this.getCellAbove(pointer);
          break;
        case KeyCode.DOWN:
          pointer = this.getCellBelow(pointer);
          break;
        case KeyCode.RIGHT:
          if (this.gos.get("enableRtl")) {
            pointer = this.getCellToLeft(pointer);
          } else {
            pointer = this.getCellToRight(pointer);
          }
          break;
        case KeyCode.LEFT:
          if (this.gos.get("enableRtl")) {
            pointer = this.getCellToRight(pointer);
          } else {
            pointer = this.getCellToLeft(pointer);
          }
          break;
        default:
          pointer = null;
          _warnOnce("unknown key for navigation ", key);
          break;
      }
      if (pointer) {
        finished = this.isCellGoodToFocusOn(pointer);
      } else {
        finished = true;
      }
    }
    return pointer;
  }
  isCellGoodToFocusOn(gridCell) {
    const column = gridCell.column;
    let rowNode;
    switch (gridCell.rowPinned) {
      case "top":
        rowNode = this.pinnedRowModel.getPinnedTopRow(gridCell.rowIndex);
        break;
      case "bottom":
        rowNode = this.pinnedRowModel.getPinnedBottomRow(gridCell.rowIndex);
        break;
      default:
        rowNode = this.rowModel.getRow(gridCell.rowIndex);
        break;
    }
    if (!rowNode) {
      return false;
    }
    const suppressNavigable = column.isSuppressNavigable(rowNode);
    return !suppressNavigable;
  }
  getCellToLeft(lastCell) {
    if (!lastCell) {
      return null;
    }
    const colToLeft = this.visibleColsService.getColBefore(lastCell.column);
    if (!colToLeft) {
      return null;
    }
    return {
      rowIndex: lastCell.rowIndex,
      column: colToLeft,
      rowPinned: lastCell.rowPinned
    };
  }
  getCellToRight(lastCell) {
    if (!lastCell) {
      return null;
    }
    const colToRight = this.visibleColsService.getColAfter(lastCell.column);
    if (!colToRight) {
      return null;
    }
    return {
      rowIndex: lastCell.rowIndex,
      column: colToRight,
      rowPinned: lastCell.rowPinned
    };
  }
  getRowBelow(rowPosition) {
    const index = rowPosition.rowIndex;
    const pinned = rowPosition.rowPinned;
    if (this.isLastRowInContainer(rowPosition)) {
      switch (pinned) {
        case "bottom":
          return null;
        case "top":
          if (this.rowModel.isRowsToRender()) {
            return { rowIndex: this.pageBoundsService.getFirstRow(), rowPinned: null };
          }
          if (this.pinnedRowModel.isRowsToRender("bottom")) {
            return { rowIndex: 0, rowPinned: "bottom" };
          }
          return null;
        default:
          if (this.pinnedRowModel.isRowsToRender("bottom")) {
            return { rowIndex: 0, rowPinned: "bottom" };
          }
          return null;
      }
    }
    const rowNode = this.rowModel.getRow(rowPosition.rowIndex);
    const nextStickyPosition = this.getNextStickyPosition(rowNode);
    if (nextStickyPosition) {
      return nextStickyPosition;
    }
    return { rowIndex: index + 1, rowPinned: pinned };
  }
  getNextStickyPosition(rowNode, up) {
    if (!this.gos.isGroupRowsSticky() || !rowNode || !rowNode.sticky) {
      return;
    }
    const isTopCtrls = this.rowRenderer.getStickyTopRowCtrls().some((ctrl) => ctrl.getRowNode().rowIndex === rowNode.rowIndex);
    let stickyRowCtrls = [];
    if (isTopCtrls) {
      stickyRowCtrls = [...this.rowRenderer.getStickyTopRowCtrls()].sort(
        (a, b) => a.getRowNode().rowIndex - b.getRowNode().rowIndex
      );
    } else {
      stickyRowCtrls = [...this.rowRenderer.getStickyBottomRowCtrls()].sort(
        (a, b) => b.getRowNode().rowIndex - a.getRowNode().rowIndex
      );
    }
    const diff = up ? -1 : 1;
    const idx = stickyRowCtrls.findIndex((ctrl) => ctrl.getRowNode().rowIndex === rowNode.rowIndex);
    const nextCtrl = stickyRowCtrls[idx + diff];
    if (nextCtrl) {
      return { rowIndex: nextCtrl.getRowNode().rowIndex, rowPinned: null };
    }
  }
  getCellBelow(lastCell) {
    if (!lastCell) {
      return null;
    }
    const rowBelow = this.getRowBelow(lastCell);
    if (rowBelow) {
      return {
        rowIndex: rowBelow.rowIndex,
        column: lastCell.column,
        rowPinned: rowBelow.rowPinned
      };
    }
    return null;
  }
  isLastRowInContainer(rowPosition) {
    const pinned = rowPosition.rowPinned;
    const index = rowPosition.rowIndex;
    if (pinned === "top") {
      const lastTopIndex = this.pinnedRowModel.getPinnedTopRowNodes().length - 1;
      return lastTopIndex <= index;
    }
    if (pinned === "bottom") {
      const lastBottomIndex = this.pinnedRowModel.getPinnedBottomRowNodes().length - 1;
      return lastBottomIndex <= index;
    }
    const lastBodyIndex = this.pageBoundsService.getLastRow();
    return lastBodyIndex <= index;
  }
  getRowAbove(rowPosition) {
    const index = rowPosition.rowIndex;
    const pinned = rowPosition.rowPinned;
    const isFirstRow = pinned ? index === 0 : index === this.pageBoundsService.getFirstRow();
    if (isFirstRow) {
      if (pinned === "top") {
        return null;
      }
      if (!pinned) {
        if (this.pinnedRowModel.isRowsToRender("top")) {
          return this.getLastFloatingTopRow();
        }
        return null;
      }
      if (this.rowModel.isRowsToRender()) {
        return this.getLastBodyCell();
      }
      if (this.pinnedRowModel.isRowsToRender("top")) {
        return this.getLastFloatingTopRow();
      }
      return null;
    }
    const rowNode = this.rowModel.getRow(rowPosition.rowIndex);
    const nextStickyPosition = this.getNextStickyPosition(rowNode, true);
    if (nextStickyPosition) {
      return nextStickyPosition;
    }
    return { rowIndex: index - 1, rowPinned: pinned };
  }
  getCellAbove(lastCell) {
    if (!lastCell) {
      return null;
    }
    const rowAbove = this.getRowAbove({ rowIndex: lastCell.rowIndex, rowPinned: lastCell.rowPinned });
    if (rowAbove) {
      return {
        rowIndex: rowAbove.rowIndex,
        column: lastCell.column,
        rowPinned: rowAbove.rowPinned
      };
    }
    return null;
  }
  getLastBodyCell() {
    const lastBodyRow = this.pageBoundsService.getLastRow();
    return { rowIndex: lastBodyRow, rowPinned: null };
  }
  getLastFloatingTopRow() {
    const lastFloatingRow = this.pinnedRowModel.getPinnedTopRowNodes().length - 1;
    return { rowIndex: lastFloatingRow, rowPinned: "top" };
  }
  getNextTabbedCell(gridCell, backwards) {
    if (backwards) {
      return this.getNextTabbedCellBackwards(gridCell);
    }
    return this.getNextTabbedCellForwards(gridCell);
  }
  getNextTabbedCellForwards(gridCell) {
    const displayedColumns = this.visibleColsService.getAllCols();
    let newRowIndex = gridCell.rowIndex;
    let newFloating = gridCell.rowPinned;
    let newColumn = this.visibleColsService.getColAfter(gridCell.column);
    if (!newColumn) {
      newColumn = displayedColumns[0];
      const rowBelow = this.getRowBelow(gridCell);
      if (_missing(rowBelow)) {
        return null;
      }
      if (!rowBelow.rowPinned && !(this.paginationService?.isRowInPage(rowBelow) ?? true)) {
        return null;
      }
      newRowIndex = rowBelow ? rowBelow.rowIndex : null;
      newFloating = rowBelow ? rowBelow.rowPinned : null;
    }
    return { rowIndex: newRowIndex, column: newColumn, rowPinned: newFloating };
  }
  getNextTabbedCellBackwards(gridCell) {
    const displayedColumns = this.visibleColsService.getAllCols();
    let newRowIndex = gridCell.rowIndex;
    let newFloating = gridCell.rowPinned;
    let newColumn = this.visibleColsService.getColBefore(gridCell.column);
    if (!newColumn) {
      newColumn = _last(displayedColumns);
      const rowAbove = this.getRowAbove({ rowIndex: gridCell.rowIndex, rowPinned: gridCell.rowPinned });
      if (_missing(rowAbove)) {
        return null;
      }
      if (!rowAbove.rowPinned && !(this.paginationService?.isRowInPage(rowAbove) ?? true)) {
        return null;
      }
      newRowIndex = rowAbove ? rowAbove.rowIndex : null;
      newFloating = rowAbove ? rowAbove.rowPinned : null;
    }
    return { rowIndex: newRowIndex, column: newColumn, rowPinned: newFloating };
  }
};

// community-modules/core/src/api/apiFunctionService.ts
function dispatchEvent(beans, event) {
  beans.eventService.dispatchEvent(event);
}
var ApiFunctionService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "apiFunctionService";
    this.functions = {
      // this is used by frameworks
      // also used by aligned grids to identify a grid api instance
      dispatchEvent
    };
    this.isDestroyed = false;
  }
  wireBeans(beans) {
    this.beans = beans;
  }
  postConstruct() {
    this.preDestroyLink = this.frameworkOverrides.getDocLink("grid-lifecycle/#grid-pre-destroyed");
  }
  callFunction(functionName, args) {
    const func = this.functions[functionName];
    if (func) {
      return func.apply(func, [this.beans, ...args]);
    }
    if (this.isDestroyed) {
      return this.destroyedHandler(functionName);
    }
    if (this.isFrameworkMethod(functionName)) {
      return void 0;
    }
    this.beans.validationService?.warnMissingApiFunction(functionName);
    return void 0;
  }
  addFunction(functionName, func) {
    const { validationService } = this.beans;
    if (validationService) {
      func = validationService.validateApiFunction(functionName, func);
    }
    this.functions[functionName] = func;
  }
  destroy() {
    this.functions = {};
    this.isDestroyed = true;
    super.destroy();
  }
  destroyedHandler(functionName) {
    if (functionName === "isDestroyed") {
      return true;
    }
    if (functionName === "destroy") {
      return;
    }
    _warnOnce(
      `Grid API function ${functionName}() cannot be called as the grid has been destroyed.
Either clear local references to the grid api, when it is destroyed, or check gridApi.isDestroyed() to avoid calling methods against a destroyed grid.
To run logic when the grid is about to be destroyed use the gridPreDestroy event. See: ${this.preDestroyLink}`
    );
    return;
  }
  isFrameworkMethod(functionName) {
    return ["preWireBeans", "wireBeans", "preConstruct", "postConstruct"].includes(functionName);
  }
};

// community-modules/core/src/api/apiUtils.ts
function createApi(context) {
  const apiFunctionService = context.getBean("apiFunctionService");
  return new Proxy(apiFunctionService, {
    get(target, prop) {
      return (...args) => target.callFunction(prop, args);
    }
  });
}
function createApiProxy(context) {
  return {
    beanName: "gridApi",
    bean: createApi(context)
  };
}

// community-modules/core/src/columns/columnDefFactory.ts
var ColumnDefFactory = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "columnDefFactory";
  }
  buildColumnDefs(cols, rowGroupColumns, pivotColumns) {
    const res = [];
    const colGroupDefs = {};
    cols.forEach((col) => {
      const colDef = this.createDefFromColumn(col, rowGroupColumns, pivotColumns);
      let addToResult = true;
      let childDef = colDef;
      let pointer = col.getOriginalParent();
      let lastPointer = null;
      while (pointer) {
        let parentDef = null;
        if (pointer.isPadding()) {
          pointer = pointer.getOriginalParent();
          continue;
        }
        const existingParentDef = colGroupDefs[pointer.getGroupId()];
        if (existingParentDef) {
          existingParentDef.children.push(childDef);
          addToResult = false;
          break;
        }
        parentDef = this.createDefFromGroup(pointer);
        if (parentDef) {
          parentDef.children = [childDef];
          colGroupDefs[parentDef.groupId] = parentDef;
          childDef = parentDef;
          pointer = pointer.getOriginalParent();
        }
        if (pointer != null && lastPointer === pointer) {
          addToResult = false;
          break;
        }
        lastPointer = pointer;
      }
      if (addToResult) {
        res.push(childDef);
      }
    });
    return res;
  }
  createDefFromGroup(group) {
    const defCloned = _deepCloneDefinition(group.getColGroupDef(), ["children"]);
    if (defCloned) {
      defCloned.groupId = group.getGroupId();
    }
    return defCloned;
  }
  createDefFromColumn(col, rowGroupColumns, pivotColumns) {
    const colDefCloned = _deepCloneDefinition(col.getColDef());
    colDefCloned.colId = col.getColId();
    colDefCloned.width = col.getActualWidth();
    colDefCloned.rowGroup = col.isRowGroupActive();
    colDefCloned.rowGroupIndex = col.isRowGroupActive() ? rowGroupColumns.indexOf(col) : null;
    colDefCloned.pivot = col.isPivotActive();
    colDefCloned.pivotIndex = col.isPivotActive() ? pivotColumns.indexOf(col) : null;
    colDefCloned.aggFunc = col.isValueActive() ? col.getAggFunc() : null;
    colDefCloned.hide = col.isVisible() ? void 0 : true;
    colDefCloned.pinned = col.isPinned() ? col.getPinned() : null;
    colDefCloned.sort = col.getSort() ? col.getSort() : null;
    colDefCloned.sortIndex = col.getSortIndex() != null ? col.getSortIndex() : null;
    return colDefCloned;
  }
};

// community-modules/core/src/columns/columnEventDispatcher.ts
var ColumnEventDispatcher = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "columnEventDispatcher";
  }
  visibleCols() {
    const event = {
      type: "displayedColumnsChanged"
    };
    this.eventService.dispatchEvent(event);
  }
  gridColumns() {
    const event = {
      type: "gridColumnsChanged"
    };
    this.eventService.dispatchEvent(event);
  }
  headerHeight(col) {
    const event = {
      type: "columnHeaderHeightChanged",
      column: col,
      columns: [col],
      source: "autosizeColumnHeaderHeight"
    };
    this.eventService.dispatchEvent(event);
  }
  groupOpened(impactedGroups) {
    const event = {
      type: "columnGroupOpened",
      columnGroup: impactedGroups.length === 1 ? impactedGroups[0] : void 0,
      columnGroups: impactedGroups
    };
    this.eventService.dispatchEvent(event);
  }
  rowGroupChanged(impactedColumns, source) {
    const event = {
      type: "columnRowGroupChanged",
      columns: impactedColumns,
      column: impactedColumns.length === 1 ? impactedColumns[0] : null,
      source
    };
    this.eventService.dispatchEvent(event);
  }
  genericColumnEvent(eventType, masterList, source) {
    const event = {
      type: eventType,
      columns: masterList,
      column: masterList.length === 1 ? masterList[0] : null,
      source
    };
    this.eventService.dispatchEvent(event);
  }
  pivotModeChanged() {
    const event = {
      type: "columnPivotModeChanged"
    };
    this.eventService.dispatchEvent(event);
  }
  virtualColumnsChanged(afterScroll) {
    const event = {
      type: "virtualColumnsChanged",
      afterScroll
    };
    this.eventService.dispatchEvent(event);
  }
  newColumnsLoaded(source) {
    const newColumnsLoadedEvent = {
      type: "newColumnsLoaded",
      source
    };
    this.eventService.dispatchEvent(newColumnsLoadedEvent);
  }
  everythingChanged(source) {
    const eventEverythingChanged = {
      type: "columnEverythingChanged",
      source
    };
    this.eventService.dispatchEvent(eventEverythingChanged);
  }
  columnMoved(params) {
    const { movedColumns, source, toIndex, finished } = params;
    const event = {
      type: "columnMoved",
      columns: movedColumns,
      column: movedColumns && movedColumns.length === 1 ? movedColumns[0] : null,
      toIndex,
      finished,
      source
    };
    this.eventService.dispatchEvent(event);
  }
  columnPinned(changedColumns, source) {
    if (!changedColumns.length) {
      return;
    }
    const column = changedColumns.length === 1 ? changedColumns[0] : null;
    const pinned = this.getCommonValue(changedColumns, (col) => col.getPinned());
    const event = {
      type: "columnPinned",
      // mistake in typing, 'undefined' should be allowed, as 'null' means 'not pinned'
      pinned: pinned != null ? pinned : null,
      columns: changedColumns,
      column,
      source
    };
    this.eventService.dispatchEvent(event);
  }
  columnVisible(changedColumns, source) {
    if (!changedColumns.length) {
      return;
    }
    const column = changedColumns.length === 1 ? changedColumns[0] : null;
    const visible = this.getCommonValue(changedColumns, (col) => col.isVisible());
    const event = {
      type: "columnVisible",
      visible,
      columns: changedColumns,
      column,
      source
    };
    this.eventService.dispatchEvent(event);
  }
  getCommonValue(cols, valueGetter) {
    if (!cols || cols.length == 0) {
      return void 0;
    }
    const firstValue = valueGetter(cols[0]);
    for (let i = 1; i < cols.length; i++) {
      if (firstValue !== valueGetter(cols[i])) {
        return void 0;
      }
    }
    return firstValue;
  }
  columnChanged(type, columns, source) {
    const event = {
      type,
      columns,
      column: columns && columns.length == 1 ? columns[0] : null,
      source
    };
    this.eventService.dispatchEvent(event);
  }
  columnResized(columns, finished, source, flexColumns = null) {
    if (columns && columns.length) {
      const event = {
        type: "columnResized",
        columns,
        column: columns.length === 1 ? columns[0] : null,
        flexColumns,
        finished,
        source
      };
      this.eventService.dispatchEvent(event);
    }
  }
};

// community-modules/core/src/columns/columnGetStateService.ts
var ColumnGetStateService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "columnGetStateService";
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.funcColsService = beans.funcColsService;
  }
  getColumnState() {
    const primaryCols = this.columnModel.getColDefCols();
    if (_missing(primaryCols) || !this.columnModel.isAlive()) {
      return [];
    }
    const colsForState = this.columnModel.getAllCols();
    const res = colsForState.map(this.createStateItemFromColumn.bind(this));
    this.orderColumnStateList(res);
    return res;
  }
  createStateItemFromColumn(column) {
    const rowGorupColumns = this.funcColsService.getRowGroupColumns();
    const pivotColumns = this.funcColsService.getPivotColumns();
    const rowGroupIndex = column.isRowGroupActive() ? rowGorupColumns.indexOf(column) : null;
    const pivotIndex = column.isPivotActive() ? pivotColumns.indexOf(column) : null;
    const aggFunc = column.isValueActive() ? column.getAggFunc() : null;
    const sort = column.getSort() != null ? column.getSort() : null;
    const sortIndex = column.getSortIndex() != null ? column.getSortIndex() : null;
    const flex = column.getFlex() != null && column.getFlex() > 0 ? column.getFlex() : null;
    const res = {
      colId: column.getColId(),
      width: column.getActualWidth(),
      hide: !column.isVisible(),
      pinned: column.getPinned(),
      sort,
      sortIndex,
      aggFunc,
      rowGroup: column.isRowGroupActive(),
      rowGroupIndex,
      pivot: column.isPivotActive(),
      pivotIndex,
      flex
    };
    return res;
  }
  orderColumnStateList(columnStateList) {
    const gridColumns = this.columnModel.getCols();
    const colIdToGridIndexMap = new Map(gridColumns.map((col, index) => [col.getColId(), index]));
    columnStateList.sort((itemA, itemB) => {
      const posA = colIdToGridIndexMap.has(itemA.colId) ? colIdToGridIndexMap.get(itemA.colId) : -1;
      const posB = colIdToGridIndexMap.has(itemB.colId) ? colIdToGridIndexMap.get(itemB.colId) : -1;
      return posA - posB;
    });
  }
};

// community-modules/core/src/columns/columnGroupStateService.ts
var ColumnGroupStateService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "columnGroupStateService";
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.columnAnimationService = beans.columnAnimationService;
    this.eventDispatcher = beans.columnEventDispatcher;
    this.visibleColsService = beans.visibleColsService;
  }
  getColumnGroupState() {
    const columnGroupState = [];
    const gridBalancedTree = this.columnModel.getColTree();
    depthFirstOriginalTreeSearch(null, gridBalancedTree, (node) => {
      if (isProvidedColumnGroup(node)) {
        columnGroupState.push({
          groupId: node.getGroupId(),
          open: node.isExpanded()
        });
      }
    });
    return columnGroupState;
  }
  resetColumnGroupState(source) {
    const primaryColumnTree = this.columnModel.getColDefColTree();
    if (!primaryColumnTree) {
      return;
    }
    const stateItems = [];
    depthFirstOriginalTreeSearch(null, primaryColumnTree, (child) => {
      if (isProvidedColumnGroup(child)) {
        const colGroupDef = child.getColGroupDef();
        const groupState = {
          groupId: child.getGroupId(),
          open: !colGroupDef ? void 0 : colGroupDef.openByDefault
        };
        stateItems.push(groupState);
      }
    });
    this.setColumnGroupState(stateItems, source);
  }
  setColumnGroupState(stateItems, source) {
    const gridBalancedTree = this.columnModel.getColTree();
    if (!gridBalancedTree) {
      return;
    }
    this.columnAnimationService.start();
    const impactedGroups = [];
    stateItems.forEach((stateItem) => {
      const groupKey = stateItem.groupId;
      const newValue = stateItem.open;
      const providedColumnGroup = this.columnModel.getProvidedColGroup(groupKey);
      if (!providedColumnGroup) {
        return;
      }
      if (providedColumnGroup.isExpanded() === newValue) {
        return;
      }
      providedColumnGroup.setExpanded(newValue);
      impactedGroups.push(providedColumnGroup);
    });
    this.visibleColsService.refresh(source, true);
    if (impactedGroups.length) {
      this.eventDispatcher.groupOpened(impactedGroups);
    }
    this.columnAnimationService.finish();
  }
};

// community-modules/core/src/columns/columnViewportService.ts
var ColumnViewportService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "columnViewportService";
    // cols in center that are in the viewport
    this.colsWithinViewport = [];
    // same as colsWithinViewport, except we always include columns with headerAutoHeight
    this.headerColsWithinViewport = [];
    // A hash key to keep track of changes in viewport columns
    this.colsWithinViewportHash = "";
    // all columns & groups to be rendered, index by row.
    // used by header rows to get all items to render for that row.
    this.rowsOfHeadersToRenderLeft = {};
    this.rowsOfHeadersToRenderRight = {};
    this.rowsOfHeadersToRenderCenter = {};
  }
  wireBeans(beans) {
    this.visibleColsService = beans.visibleColsService;
    this.columnModel = beans.columnModel;
    this.eventDispatcher = beans.columnEventDispatcher;
  }
  postConstruct() {
    this.suppressColumnVirtualisation = this.gos.get("suppressColumnVirtualisation");
  }
  setScrollPosition(scrollWidth, scrollPosition, afterScroll = false) {
    const bodyWidthDirty = this.visibleColsService.isBodyWidthDirty();
    const noChange = scrollWidth === this.scrollWidth && scrollPosition === this.scrollPosition && !bodyWidthDirty;
    if (noChange) {
      return;
    }
    this.scrollWidth = scrollWidth;
    this.scrollPosition = scrollPosition;
    this.visibleColsService.setBodyWidthDirty();
    if (this.gos.get("enableRtl")) {
      const bodyWidth = this.visibleColsService.getBodyContainerWidth();
      this.viewportLeft = bodyWidth - this.scrollPosition - this.scrollWidth;
      this.viewportRight = bodyWidth - this.scrollPosition;
    } else {
      this.viewportLeft = this.scrollPosition;
      this.viewportRight = this.scrollWidth + this.scrollPosition;
    }
    if (this.columnModel.isReady()) {
      this.checkViewportColumns(afterScroll);
    }
  }
  getHeadersToRender(type, dept) {
    let result;
    switch (type) {
      case "left":
        result = this.rowsOfHeadersToRenderLeft[dept];
        break;
      case "right":
        result = this.rowsOfHeadersToRenderRight[dept];
        break;
      default:
        result = this.rowsOfHeadersToRenderCenter[dept];
        break;
    }
    return result || [];
  }
  extractViewportColumns() {
    const displayedColumnsCenter = this.visibleColsService.getCenterCols();
    if (this.isColumnVirtualisationSuppressed()) {
      this.colsWithinViewport = displayedColumnsCenter;
      this.headerColsWithinViewport = displayedColumnsCenter;
    } else {
      this.colsWithinViewport = displayedColumnsCenter.filter(this.isColumnInRowViewport.bind(this));
      this.headerColsWithinViewport = displayedColumnsCenter.filter(this.isColumnInHeaderViewport.bind(this));
    }
  }
  isColumnVirtualisationSuppressed() {
    return this.suppressColumnVirtualisation || this.viewportRight === 0;
  }
  clear() {
    this.rowsOfHeadersToRenderLeft = {};
    this.rowsOfHeadersToRenderRight = {};
    this.rowsOfHeadersToRenderCenter = {};
    this.colsWithinViewportHash = "";
  }
  isColumnInHeaderViewport(col) {
    if (col.isAutoHeaderHeight()) {
      return true;
    }
    return this.isColumnInRowViewport(col);
  }
  isColumnInRowViewport(col) {
    if (col.isAutoHeight()) {
      return true;
    }
    const columnLeft = col.getLeft() || 0;
    const columnRight = columnLeft + col.getActualWidth();
    const leftBounds = this.viewportLeft - 200;
    const rightBounds = this.viewportRight + 200;
    const columnToMuchLeft = columnLeft < leftBounds && columnRight < leftBounds;
    const columnToMuchRight = columnLeft > rightBounds && columnRight > rightBounds;
    return !columnToMuchLeft && !columnToMuchRight;
  }
  // used by Grid API only
  getViewportColumns() {
    const leftCols = this.visibleColsService.getLeftCols();
    const rightCols = this.visibleColsService.getRightCols();
    const res = this.colsWithinViewport.concat(leftCols).concat(rightCols);
    return res;
  }
  // + rowRenderer
  // if we are not column spanning, this just returns back the virtual centre columns,
  // however if we are column spanning, then different rows can have different virtual
  // columns, so we have to work out the list for each individual row.
  getColsWithinViewport(rowNode) {
    if (!this.columnModel.isColSpanActive()) {
      return this.colsWithinViewport;
    }
    const emptySpaceBeforeColumn = (col) => {
      const left = col.getLeft();
      return _exists(left) && left > this.viewportLeft;
    };
    const inViewportCallback = this.isColumnVirtualisationSuppressed() ? null : this.isColumnInRowViewport.bind(this);
    const displayedColumnsCenter = this.visibleColsService.getColsCenter();
    return this.visibleColsService.getColsForRow(
      rowNode,
      displayedColumnsCenter,
      inViewportCallback,
      emptySpaceBeforeColumn
    );
  }
  // checks what columns are currently displayed due to column virtualisation. dispatches an event
  // if the list of columns has changed.
  // + setColumnWidth(), setViewportPosition(), setColumnDefs(), sizeColumnsToFit()
  checkViewportColumns(afterScroll = false) {
    const viewportColumnsChanged = this.extractViewport();
    if (viewportColumnsChanged) {
      this.eventDispatcher.virtualColumnsChanged(afterScroll);
    }
  }
  calculateHeaderRows() {
    this.rowsOfHeadersToRenderLeft = {};
    this.rowsOfHeadersToRenderRight = {};
    this.rowsOfHeadersToRenderCenter = {};
    const renderedColIds = {};
    const renderedColsLeft = this.visibleColsService.getLeftCols();
    const renderedColsRight = this.visibleColsService.getRightCols();
    const allRenderedCols = this.headerColsWithinViewport.concat(renderedColsLeft).concat(renderedColsRight);
    allRenderedCols.forEach((col) => renderedColIds[col.getId()] = true);
    const testGroup = (children, result, dept) => {
      let returnValue = false;
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        let addThisItem = false;
        if (isColumn(child)) {
          addThisItem = renderedColIds[child.getId()] === true;
        } else {
          const columnGroup = child;
          const displayedChildren = columnGroup.getDisplayedChildren();
          if (displayedChildren) {
            addThisItem = testGroup(displayedChildren, result, dept + 1);
          }
        }
        if (addThisItem) {
          returnValue = true;
          if (!result[dept]) {
            result[dept] = [];
          }
          result[dept].push(child);
        }
      }
      return returnValue;
    };
    testGroup(this.visibleColsService.getTreeLeft(), this.rowsOfHeadersToRenderLeft, 0);
    testGroup(this.visibleColsService.getTreeRight(), this.rowsOfHeadersToRenderRight, 0);
    testGroup(this.visibleColsService.getTreeCenter(), this.rowsOfHeadersToRenderCenter, 0);
  }
  extractViewport() {
    const hashColumn = (c) => `${c.getId()}-${c.getPinned() || "normal"}`;
    this.extractViewportColumns();
    const newHash = this.getViewportColumns().map(hashColumn).join("#");
    const changed = this.colsWithinViewportHash !== newHash;
    if (changed) {
      this.colsWithinViewportHash = newHash;
      this.calculateHeaderRows();
    }
    return changed;
  }
};

// community-modules/core/src/components/framework/agComponentUtils.ts
var AgComponentUtils = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "agComponentUtils";
  }
  wireBeans(beans) {
    this.componentMetadataProvider = beans.componentMetadataProvider;
  }
  adaptFunction(propertyName, jsCompFunc) {
    const metadata = this.componentMetadataProvider.retrieve(propertyName);
    if (metadata && metadata.functionAdapter) {
      return metadata.functionAdapter(jsCompFunc);
    }
    return null;
  }
  adaptCellRendererFunction(callback) {
    class Adapter {
      refresh() {
        return false;
      }
      getGui() {
        return this.eGui;
      }
      init(params) {
        const callbackResult = callback(params);
        const type = typeof callbackResult;
        if (type === "string" || type === "number" || type === "boolean") {
          this.eGui = _loadTemplate("<span>" + callbackResult + "</span>");
          return;
        }
        if (callbackResult == null) {
          this.eGui = _loadTemplate("<span></span>");
          return;
        }
        this.eGui = callbackResult;
      }
    }
    return Adapter;
  }
  doesImplementIComponent(candidate) {
    if (!candidate) {
      return false;
    }
    return candidate.prototype && "getGui" in candidate.prototype;
  }
};

// community-modules/core/src/components/framework/componentMetadataProvider.ts
var ComponentMetadataProvider = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "componentMetadataProvider";
  }
  wireBeans(beans) {
    this.agComponentUtils = beans.agComponentUtils;
  }
  postConstruct() {
    this.componentMetaData = {
      dateComponent: {
        mandatoryMethodList: ["getDate", "setDate"],
        optionalMethodList: [
          "afterGuiAttached",
          "setInputPlaceholder",
          "setInputAriaLabel",
          "setDisabled",
          "onParamsUpdated",
          "refresh"
        ]
      },
      detailCellRenderer: {
        mandatoryMethodList: [],
        optionalMethodList: ["refresh"],
        functionAdapter: this.agComponentUtils.adaptCellRendererFunction.bind(this.agComponentUtils)
      },
      headerComponent: {
        mandatoryMethodList: [],
        optionalMethodList: ["refresh"]
      },
      headerGroupComponent: {
        mandatoryMethodList: [],
        optionalMethodList: []
      },
      loadingCellRenderer: {
        mandatoryMethodList: [],
        optionalMethodList: [],
        functionAdapter: this.agComponentUtils.adaptCellRendererFunction.bind(this.agComponentUtils)
      },
      loadingOverlayComponent: {
        mandatoryMethodList: [],
        optionalMethodList: ["refresh"]
      },
      noRowsOverlayComponent: {
        mandatoryMethodList: [],
        optionalMethodList: ["refresh"]
      },
      floatingFilterComponent: {
        mandatoryMethodList: ["onParentModelChanged"],
        optionalMethodList: ["afterGuiAttached", "onParamsUpdated", "refresh"]
      },
      cellRenderer: {
        mandatoryMethodList: [],
        optionalMethodList: ["refresh", "afterGuiAttached"],
        functionAdapter: this.agComponentUtils.adaptCellRendererFunction.bind(this.agComponentUtils)
      },
      cellEditor: {
        mandatoryMethodList: ["getValue"],
        optionalMethodList: [
          "isPopup",
          "isCancelBeforeStart",
          "isCancelAfterEnd",
          "getPopupPosition",
          "focusIn",
          "focusOut",
          "afterGuiAttached",
          "refresh"
        ]
      },
      innerRenderer: {
        mandatoryMethodList: [],
        optionalMethodList: ["afterGuiAttached"],
        functionAdapter: this.agComponentUtils.adaptCellRendererFunction.bind(this.agComponentUtils)
      },
      fullWidthCellRenderer: {
        mandatoryMethodList: [],
        optionalMethodList: ["refresh", "afterGuiAttached"],
        functionAdapter: this.agComponentUtils.adaptCellRendererFunction.bind(this.agComponentUtils)
      },
      groupRowRenderer: {
        mandatoryMethodList: [],
        optionalMethodList: ["afterGuiAttached"],
        functionAdapter: this.agComponentUtils.adaptCellRendererFunction.bind(this.agComponentUtils)
      },
      filter: {
        mandatoryMethodList: ["isFilterActive", "doesFilterPass", "getModel", "setModel"],
        optionalMethodList: [
          "afterGuiAttached",
          "afterGuiDetached",
          "onNewRowsLoaded",
          "getModelAsString",
          "onFloatingFilterChanged",
          "onAnyFilterChanged",
          "refresh"
        ]
      },
      statusPanel: {
        mandatoryMethodList: [],
        optionalMethodList: ["refresh"]
      },
      toolPanel: {
        mandatoryMethodList: [],
        optionalMethodList: ["refresh", "getState"]
      },
      tooltipComponent: {
        mandatoryMethodList: [],
        optionalMethodList: []
      },
      menuItem: {
        mandatoryMethodList: [],
        optionalMethodList: ["setActive", "select", "setExpanded", "configureDefaults"]
      }
    };
  }
  retrieve(name) {
    return this.componentMetaData[name];
  }
};

// community-modules/core/src/context/gridBeanComparator.ts
var orderedCoreBeans = [
  // core beans only
  "rowPositionUtils",
  "cellPositionUtils",
  "headerPositionUtils",
  "paginationAutoPageSizeService",
  "apiFunctionService",
  "gridApi",
  "userComponentRegistry",
  "agComponentUtils",
  "componentMetadataProvider",
  "resizeObserverService",
  "userComponentFactory",
  "rowContainerHeightService",
  "horizontalResizeService",
  "localeService",
  "validationService",
  "pinnedRowModel",
  "dragService",
  "visibleColsService",
  "eventService",
  "gos",
  "popupService",
  "selectionService",
  "columnFilterService",
  "quickFilterService",
  "filterManager",
  "columnModel",
  "headerNavigationService",
  "pageBoundsService",
  "paginationService",
  "pageBoundsListener",
  "rowRenderer",
  "expressionService",
  "columnFactory",
  "alignedGridsService",
  "navigationService",
  "valueCache",
  "valueService",
  "loggerFactory",
  "autoWidthCalculator",
  "filterMenuFactory",
  "dragAndDropService",
  "focusService",
  "mouseEventService",
  "environment",
  "cellNavigationService",
  "stylingService",
  "scrollVisibleService",
  "sortController",
  "columnHoverService",
  "columnAnimationService",
  "selectableService",
  "autoColService",
  "changeDetectionService",
  "animationFrameService",
  "undoRedoService",
  "columnDefFactory",
  "rowCssClassCalculator",
  "rowNodeBlockLoader",
  "rowNodeSorter",
  "ctrlsService",
  "pinnedWidthService",
  "rowNodeEventThrottle",
  "ctrlsFactory",
  "dataTypeService",
  "syncService",
  "overlayService",
  "stateService",
  "expansionService",
  "apiEventService",
  "ariaAnnouncementService",
  "menuService",
  "columnApplyStateService",
  "columnEventDispatcher",
  "columnMoveService",
  "columnAutosizeService",
  "columnGetStateService",
  "columnGroupStateService",
  "columnSizeService",
  "funcColsService",
  "columnNameService",
  "columnViewportService",
  "pivotResultColsService",
  "showRowGroupColsService"
];
var beanNamePosition = Object.fromEntries(
  orderedCoreBeans.map((beanName, index) => [beanName, index])
);
function gridBeanInitComparator(bean1, bean2) {
  const index1 = (bean1.beanName ? beanNamePosition[bean1.beanName] : void 0) ?? Number.MAX_SAFE_INTEGER;
  const index2 = (bean2.beanName ? beanNamePosition[bean2.beanName] : void 0) ?? Number.MAX_SAFE_INTEGER;
  return index1 - index2;
}
function gridBeanDestroyComparator(bean1, bean2) {
  return bean1?.beanName === "gridDestroyService" ? -1 : 0;
}

// community-modules/core/src/ctrlsFactory.ts
var CtrlsFactory = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "ctrlsFactory";
    this.registry = {};
  }
  register(meta) {
    this.registry[meta.name] = meta.classImp;
  }
  getInstance(name, ...args) {
    const ControllerClass = this.registry[name];
    if (ControllerClass == null) {
      return void 0;
    }
    return new ControllerClass(...args);
  }
};

// community-modules/core/src/ctrlsService.ts
var CtrlsService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "ctrlsService";
    this.params = {};
    this.ready = false;
    this.readyCallbacks = [];
  }
  checkReady() {
    const params = this.params;
    this.ready = params.gridCtrl != null && params.gridBodyCtrl != null && params.center != null && params.left != null && params.right != null && params.bottomCenter != null && params.bottomLeft != null && params.bottomRight != null && params.topCenter != null && params.topLeft != null && params.topRight != null && params.stickyTopCenter != null && params.stickyTopLeft != null && params.stickyTopRight != null && params.stickyBottomCenter != null && params.stickyBottomLeft != null && params.stickyBottomRight != null && params.centerHeader != null && params.leftHeader != null && params.rightHeader != null && params.fakeHScrollComp != null && params.fakeVScrollComp != null && params.gridHeaderCtrl != null;
    if (this.ready) {
      this.readyCallbacks.forEach((c) => c(params));
      this.readyCallbacks.length = 0;
    }
  }
  whenReady(callback) {
    if (this.ready) {
      callback(this.params);
    } else {
      this.readyCallbacks.push(callback);
    }
  }
  register(ctrlType, ctrl) {
    this.params[ctrlType] = ctrl;
    this.checkReady();
  }
  registerHeaderContainer(ctrl, pinned) {
    const params = this.params;
    switch (pinned) {
      case "left":
        params.leftHeader = ctrl;
        break;
      case "right":
        params.rightHeader = ctrl;
        break;
      default:
        params.centerHeader = ctrl;
        break;
    }
    this.checkReady();
  }
  get(ctrlType) {
    return this.params[ctrlType];
  }
  getParams() {
    return this.params;
  }
  getGridBodyCtrl() {
    return this.params.gridBodyCtrl;
  }
  getHeaderRowContainerCtrls() {
    const { leftHeader, centerHeader, rightHeader } = this.params;
    return [leftHeader, rightHeader, centerHeader];
  }
  getHeaderRowContainerCtrl(pinned) {
    const params = this.params;
    switch (pinned) {
      case "left":
        return params.leftHeader;
      case "right":
        return params.rightHeader;
      default:
        return params.centerHeader;
    }
  }
};

// community-modules/core/src/entities/cellPositionUtils.ts
var CellPositionUtils = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "cellPositionUtils";
  }
  createId(cellPosition) {
    const { rowIndex, rowPinned, column } = cellPosition;
    return this.createIdFromValues({ rowIndex, column, rowPinned });
  }
  createIdFromValues(cellPosition) {
    const { rowIndex, rowPinned, column } = cellPosition;
    return `${rowIndex}.${rowPinned == null ? "null" : rowPinned}.${column.getId()}`;
  }
  equals(cellA, cellB) {
    const colsMatch = cellA.column === cellB.column;
    const floatingMatch = cellA.rowPinned === cellB.rowPinned;
    const indexMatch = cellA.rowIndex === cellB.rowIndex;
    return colsMatch && floatingMatch && indexMatch;
  }
};

// community-modules/core/src/entities/rowNodeEventThrottle.ts
var RowNodeEventThrottle = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "rowNodeEventThrottle";
    this.events = [];
  }
  wireBeans(beans) {
    this.animationFrameService = beans.animationFrameService;
    this.rowModel = beans.rowModel;
  }
  postConstruct() {
    if (this.rowModel.getType() == "clientSide") {
      this.clientSideRowModel = this.rowModel;
    }
  }
  // because the user can call rowNode.setExpanded() many times in one VM turn,
  // we throttle the calls to ClientSideRowModel using animationFrameService. this means for 100
  // row nodes getting expanded, we only update the CSRM once, and then we fire all events after
  // CSRM has updated.
  //
  // if we did not do this, then the user could call setExpanded on 100+ rows, causing the grid
  // to re-render 100+ times, which would be a performance lag.
  //
  // we use animationFrameService
  // rather than debounce() so this will get done if anyone flushes the animationFrameService
  // (eg user calls api.ensureRowVisible(), which in turn flushes ).
  dispatchExpanded(event, forceSync) {
    if (this.clientSideRowModel == null) {
      this.eventService.dispatchEvent(event);
      return;
    }
    this.events.push(event);
    const func = () => {
      if (this.clientSideRowModel) {
        this.clientSideRowModel.onRowGroupOpened();
      }
      this.events.forEach((e) => this.eventService.dispatchEvent(e));
      this.events = [];
    };
    if (forceSync) {
      func();
    } else {
      if (this.dispatchExpandedDebounced == null) {
        this.dispatchExpandedDebounced = this.animationFrameService.debounce(func);
      }
      this.dispatchExpandedDebounced();
    }
  }
};

// community-modules/core/src/entities/rowPositionUtils.ts
var RowPositionUtils = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "rowPositionUtils";
  }
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
    this.pinnedRowModel = beans.pinnedRowModel;
    this.pageBoundsService = beans.pageBoundsService;
  }
  getFirstRow() {
    let rowIndex = 0;
    let rowPinned;
    if (this.pinnedRowModel.getPinnedTopRowCount()) {
      rowPinned = "top";
    } else if (this.rowModel.getRowCount()) {
      rowPinned = null;
      rowIndex = this.pageBoundsService.getFirstRow();
    } else if (this.pinnedRowModel.getPinnedBottomRowCount()) {
      rowPinned = "bottom";
    }
    return rowPinned === void 0 ? null : { rowIndex, rowPinned };
  }
  getLastRow() {
    let rowIndex;
    let rowPinned = null;
    const pinnedBottomCount = this.pinnedRowModel.getPinnedBottomRowCount();
    const pinnedTopCount = this.pinnedRowModel.getPinnedTopRowCount();
    if (pinnedBottomCount) {
      rowPinned = "bottom";
      rowIndex = pinnedBottomCount - 1;
    } else if (this.rowModel.getRowCount()) {
      rowPinned = null;
      rowIndex = this.pageBoundsService.getLastRow();
    } else if (pinnedTopCount) {
      rowPinned = "top";
      rowIndex = pinnedTopCount - 1;
    }
    return rowIndex === void 0 ? null : { rowIndex, rowPinned };
  }
  getRowNode(gridRow) {
    switch (gridRow.rowPinned) {
      case "top":
        return this.pinnedRowModel.getPinnedTopRowNodes()[gridRow.rowIndex];
      case "bottom":
        return this.pinnedRowModel.getPinnedBottomRowNodes()[gridRow.rowIndex];
      default:
        return this.rowModel.getRow(gridRow.rowIndex);
    }
  }
  sameRow(rowA, rowB) {
    if (!rowA && !rowB) {
      return true;
    }
    if (rowA && !rowB || !rowA && rowB) {
      return false;
    }
    return rowA.rowIndex === rowB.rowIndex && rowA.rowPinned == rowB.rowPinned;
  }
  // tests if this row selection is before the other row selection
  before(rowA, rowB) {
    switch (rowA.rowPinned) {
      case "top":
        if (rowB.rowPinned !== "top") {
          return true;
        }
        break;
      case "bottom":
        if (rowB.rowPinned !== "bottom") {
          return false;
        }
        break;
      default:
        if (_exists(rowB.rowPinned)) {
          return rowB.rowPinned !== "top";
        }
        break;
    }
    return rowA.rowIndex < rowB.rowIndex;
  }
};

// community-modules/core/src/environment.ts
var ROW_HEIGHT = {
  cssName: "--ag-row-height",
  changeKey: "rowHeightChanged",
  defaultValue: 42
};
var HEADER_HEIGHT = {
  cssName: "--ag-header-height",
  changeKey: "headerHeightChanged",
  defaultValue: 48
};
var LIST_ITEM_HEIGHT = {
  cssName: "--ag-list-item-height",
  changeKey: "listItemHeightChanged",
  defaultValue: 24
};
var Environment = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "environment";
    this.sizeEls = /* @__PURE__ */ new Map();
    this.lastKnownValues = /* @__PURE__ */ new Map();
    this.themeClasses = [];
    this.eThemeAncestor = null;
    this.eMeasurementContainer = null;
    this.sizesMeasured = false;
  }
  wireBeans(beans) {
    this.resizeObserverService = beans.resizeObserverService;
    this.eGridDiv = beans.eGridDiv;
  }
  postConstruct() {
    this.addManagedPropertyListener("rowHeight", () => this.refreshRowHeightVariable());
    this.themeClasses = this.getAncestorThemeClasses();
    this.setUpThemeClassObservers();
    this.getSizeEl(ROW_HEIGHT);
    this.getSizeEl(HEADER_HEIGHT);
    this.getSizeEl(LIST_ITEM_HEIGHT);
  }
  getDefaultRowHeight() {
    return this.getCSSVariablePixelValue(ROW_HEIGHT);
  }
  getDefaultHeaderHeight() {
    return this.getCSSVariablePixelValue(HEADER_HEIGHT);
  }
  getDefaultListItemHeight() {
    return this.getCSSVariablePixelValue(LIST_ITEM_HEIGHT);
  }
  hasMeasuredSizes() {
    return this.sizesMeasured;
  }
  getThemeClasses() {
    return this.themeClasses;
  }
  applyThemeClasses(el) {
    for (const className of Array.from(el.classList)) {
      if (className.startsWith("ag-theme-") && !this.themeClasses.includes(className)) {
        el.classList.remove(className);
      }
    }
    for (const className of this.themeClasses) {
      if (!el.classList.contains(className)) {
        el.classList.add(className);
      }
    }
  }
  getThemeAncestorElement() {
    return this.eThemeAncestor;
  }
  refreshRowHeightVariable() {
    const oldRowHeight = this.eGridDiv.style.getPropertyValue("--ag-line-height").trim();
    const height = this.gos.get("rowHeight");
    if (height == null || isNaN(height) || !isFinite(height)) {
      if (oldRowHeight !== null) {
        this.eGridDiv.style.setProperty("--ag-line-height", null);
      }
      return -1;
    }
    const newRowHeight = `${height}px`;
    if (oldRowHeight != newRowHeight) {
      this.eGridDiv.style.setProperty("--ag-line-height", newRowHeight);
      return height;
    }
    return oldRowHeight != "" ? parseFloat(oldRowHeight) : -1;
  }
  getCSSVariablePixelValue(variable) {
    const cached = this.lastKnownValues.get(variable);
    if (cached != null) {
      return cached;
    }
    const measurement = this.measureSizeEl(variable);
    if (measurement === "detached" || measurement === "no-styles") {
      return variable.defaultValue;
    }
    this.lastKnownValues.set(variable, measurement);
    return measurement;
  }
  measureSizeEl(variable) {
    const sizeEl = this.getSizeEl(variable);
    if (sizeEl.offsetParent == null) {
      return "detached";
    }
    const newSize = sizeEl.offsetWidth;
    if (newSize === NO_VALUE_SENTINEL)
      return "no-styles";
    this.sizesMeasured = true;
    return newSize;
  }
  getSizeEl(variable) {
    let sizeEl = this.sizeEls.get(variable);
    if (sizeEl) {
      return sizeEl;
    }
    let container = this.eMeasurementContainer;
    if (!container) {
      container = this.eMeasurementContainer = document.createElement("div");
      container.className = "ag-measurement-container";
      this.eGridDiv.appendChild(container);
    }
    sizeEl = document.createElement("div");
    sizeEl.style.width = `var(${variable.cssName}, ${NO_VALUE_SENTINEL}px)`;
    container.appendChild(sizeEl);
    this.sizeEls.set(variable, sizeEl);
    let lastMeasurement = this.measureSizeEl(variable);
    if (lastMeasurement === "no-styles") {
      _warnOnce(
        `no value for ${variable.cssName}. This usually means that the grid has been initialised before styles have been loaded. The default value of ${variable.defaultValue} will be used and updated when styles load.`
      );
    }
    const unsubscribe = this.resizeObserverService.observeResize(sizeEl, () => {
      const newMeasurement = this.measureSizeEl(variable);
      if (newMeasurement === "detached" || newMeasurement === "no-styles") {
        return;
      }
      this.lastKnownValues.set(variable, newMeasurement);
      if (newMeasurement !== lastMeasurement) {
        lastMeasurement = newMeasurement;
        this.fireGridStylesChangedEvent(variable.changeKey);
      }
    });
    this.addDestroyFunc(() => unsubscribe());
    return sizeEl;
  }
  fireGridStylesChangedEvent(change) {
    const event = {
      type: "gridStylesChanged",
      [change]: true
    };
    this.eventService.dispatchEvent(event);
  }
  setUpThemeClassObservers() {
    const observer = new MutationObserver(() => {
      const newThemeClasses = this.getAncestorThemeClasses();
      if (!arraysEqual(newThemeClasses, this.themeClasses)) {
        this.themeClasses = newThemeClasses;
        this.fireGridStylesChangedEvent("themeChanged");
      }
    });
    let node = this.eGridDiv;
    while (node) {
      observer.observe(node || this.eGridDiv, {
        attributes: true,
        attributeFilter: ["class"]
      });
      node = node.parentElement;
    }
  }
  getAncestorThemeClasses() {
    let el = this.eGridDiv;
    const allThemeClasses = [];
    this.eThemeAncestor = null;
    while (el) {
      const themeClasses = Array.from(el.classList).filter((c) => c.startsWith("ag-theme-"));
      for (const themeClass of themeClasses) {
        this.eThemeAncestor = el;
        if (!allThemeClasses.includes(themeClass)) {
          allThemeClasses.unshift(themeClass);
        }
      }
      el = el.parentElement;
    }
    return Object.freeze(allThemeClasses);
  }
};
var arraysEqual = (a, b) => a.length === b.length && a.findIndex((_, i) => a[i] !== b[i]) === -1;
var NO_VALUE_SENTINEL = 15538;

// community-modules/core/src/eventService.ts
var EventService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "eventService";
    this.globalEventService = new LocalEventService();
  }
  wireBeans(beans) {
    this.globalEventListener = beans.globalEventListener;
    this.globalSyncEventListener = beans.globalSyncEventListener;
  }
  postConstruct() {
    if (this.globalEventListener) {
      const async = this.gos.useAsyncEvents();
      this.addGlobalListener(this.globalEventListener, async);
    }
    if (this.globalSyncEventListener) {
      this.addGlobalListener(this.globalSyncEventListener, false);
    }
  }
  addEventListener(eventType, listener, async) {
    this.globalEventService.addEventListener(eventType, listener, async);
  }
  removeEventListener(eventType, listener, async) {
    this.globalEventService.removeEventListener(eventType, listener, async);
  }
  addGlobalListener(listener, async = false) {
    this.globalEventService.addGlobalListener(listener, async);
  }
  removeGlobalListener(listener, async = false) {
    this.globalEventService.removeGlobalListener(listener, async);
  }
  /** @deprecated DO NOT FIRE LOCAL EVENTS OFF THE EVENT SERVICE */
  dispatchLocalEvent() {
  }
  dispatchEvent(event) {
    this.globalEventService.dispatchEvent(this.gos.addGridCommonParams(event));
  }
  dispatchEventOnce(event) {
    this.globalEventService.dispatchEventOnce(this.gos.addGridCommonParams(event));
  }
};

// community-modules/core/src/focusService.ts
var _FocusService = class _FocusService extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "focusService";
  }
  wireBeans(beans) {
    this.eGridDiv = beans.eGridDiv;
    this.columnModel = beans.columnModel;
    this.visibleColsService = beans.visibleColsService;
    this.headerNavigationService = beans.headerNavigationService;
    this.headerPositionUtils = beans.headerPositionUtils;
    this.rowRenderer = beans.rowRenderer;
    this.rowPositionUtils = beans.rowPositionUtils;
    this.cellPositionUtils = beans.cellPositionUtils;
    this.navigationService = beans.navigationService;
    this.ctrlsService = beans.ctrlsService;
    this.filterManager = beans.filterManager;
    this.rangeService = beans.rangeService;
    this.advancedFilterService = beans.advancedFilterService;
  }
  static addKeyboardModeEvents(doc) {
    if (this.instanceCount > 0) {
      return;
    }
    doc.addEventListener("keydown", _FocusService.toggleKeyboardMode);
    doc.addEventListener("mousedown", _FocusService.toggleKeyboardMode);
  }
  static removeKeyboardModeEvents(doc) {
    if (this.instanceCount > 0)
      return;
    doc.addEventListener("keydown", _FocusService.toggleKeyboardMode);
    doc.addEventListener("mousedown", _FocusService.toggleKeyboardMode);
  }
  static toggleKeyboardMode(event) {
    const isKeyboardActive = _FocusService.keyboardModeActive;
    const isKeyboardEvent = event.type === "keydown";
    if (isKeyboardEvent) {
      if (event.ctrlKey || event.metaKey || event.altKey) {
        return;
      }
    }
    if (isKeyboardActive === isKeyboardEvent) {
      return;
    }
    _FocusService.keyboardModeActive = isKeyboardEvent;
  }
  static unregisterGridCompController(doc) {
    _FocusService.removeKeyboardModeEvents(doc);
  }
  postConstruct() {
    const clearFocusedCellListener = this.clearFocusedCell.bind(this);
    this.addManagedEventListeners({
      columnPivotModeChanged: clearFocusedCellListener,
      newColumnsLoaded: this.onColumnEverythingChanged.bind(this),
      columnGroupOpened: clearFocusedCellListener,
      columnRowGroupChanged: clearFocusedCellListener
    });
    this.registerKeyboardFocusEvents();
    this.ctrlsService.whenReady((p) => {
      this.gridCtrl = p.gridCtrl;
    });
  }
  registerKeyboardFocusEvents() {
    const eDocument = this.gos.getDocument();
    _FocusService.addKeyboardModeEvents(eDocument);
    _FocusService.instanceCount++;
    this.addDestroyFunc(() => {
      _FocusService.instanceCount--;
      _FocusService.unregisterGridCompController(eDocument);
    });
  }
  onColumnEverythingChanged() {
    if (!this.focusedCellPosition) {
      return;
    }
    const col = this.focusedCellPosition.column;
    const colFromColumnModel = this.columnModel.getCol(col.getId());
    if (col !== colFromColumnModel) {
      this.clearFocusedCell();
    }
  }
  isKeyboardMode() {
    return _FocusService.keyboardModeActive;
  }
  // we check if the browser is focusing something, and if it is, and
  // it's the cell we think is focused, then return the cell. so this
  // methods returns the cell if a) we think it has focus and b) the
  // browser thinks it has focus. this then returns nothing if we
  // first focus a cell, then second click outside the grid, as then the
  // grid cell will still be focused as far as the grid is concerned,
  // however the browser focus will have moved somewhere else.
  getFocusCellToUseAfterRefresh() {
    if (this.gos.get("suppressFocusAfterRefresh") || !this.focusedCellPosition) {
      return null;
    }
    if (this.isDomDataMissingInHierarchy(this.gos.getActiveDomElement(), RowCtrl.DOM_DATA_KEY_ROW_CTRL)) {
      return null;
    }
    return this.focusedCellPosition;
  }
  getFocusHeaderToUseAfterRefresh() {
    if (this.gos.get("suppressFocusAfterRefresh") || !this.focusedHeaderPosition) {
      return null;
    }
    if (this.isDomDataMissingInHierarchy(
      this.gos.getActiveDomElement(),
      AbstractHeaderCellCtrl.DOM_DATA_KEY_HEADER_CTRL
    )) {
      return null;
    }
    return this.focusedHeaderPosition;
  }
  isDomDataMissingInHierarchy(eBrowserCell, key) {
    let ePointer = eBrowserCell;
    while (ePointer) {
      const data = this.gos.getDomData(ePointer, key);
      if (data) {
        return false;
      }
      ePointer = ePointer.parentNode;
    }
    return true;
  }
  getFocusedCell() {
    return this.focusedCellPosition;
  }
  shouldRestoreFocus(cell) {
    if (this.isCellRestoreFocused(cell)) {
      setTimeout(() => {
        this.restoredFocusedCellPosition = null;
      }, 0);
      return true;
    }
    return false;
  }
  isCellRestoreFocused(cellPosition) {
    if (this.restoredFocusedCellPosition == null) {
      return false;
    }
    return this.cellPositionUtils.equals(cellPosition, this.restoredFocusedCellPosition);
  }
  setRestoreFocusedCell(cellPosition) {
    if (this.getFrameworkOverrides().renderingEngine === "react") {
      this.restoredFocusedCellPosition = cellPosition;
    }
  }
  getFocusEventParams() {
    const { rowIndex, rowPinned, column } = this.focusedCellPosition;
    const params = {
      rowIndex,
      rowPinned,
      column,
      isFullWidthCell: false
    };
    const rowCtrl = this.rowRenderer.getRowByPosition({ rowIndex, rowPinned });
    if (rowCtrl) {
      params.isFullWidthCell = rowCtrl.isFullWidth();
    }
    return params;
  }
  clearFocusedCell() {
    this.restoredFocusedCellPosition = null;
    if (this.focusedCellPosition == null) {
      return;
    }
    const event = {
      type: "cellFocusCleared",
      ...this.getFocusEventParams()
    };
    this.focusedCellPosition = null;
    this.eventService.dispatchEvent(event);
  }
  setFocusedCell(params) {
    const { column, rowIndex, rowPinned, forceBrowserFocus = false, preventScrollOnBrowserFocus = false } = params;
    const gridColumn = this.columnModel.getCol(column);
    if (!gridColumn) {
      this.focusedCellPosition = null;
      return;
    }
    this.focusedCellPosition = gridColumn ? {
      rowIndex,
      rowPinned: _makeNull(rowPinned),
      column: gridColumn
    } : null;
    const event = {
      type: "cellFocused",
      ...this.getFocusEventParams(),
      forceBrowserFocus,
      preventScrollOnBrowserFocus
    };
    this.eventService.dispatchEvent(event);
  }
  isCellFocused(cellPosition) {
    if (this.focusedCellPosition == null) {
      return false;
    }
    return this.cellPositionUtils.equals(cellPosition, this.focusedCellPosition);
  }
  isRowNodeFocused(rowNode) {
    return this.isRowFocused(rowNode.rowIndex, rowNode.rowPinned);
  }
  isHeaderWrapperFocused(headerCtrl) {
    if (this.focusedHeaderPosition == null) {
      return false;
    }
    const column = headerCtrl.getColumnGroupChild();
    const headerRowIndex = headerCtrl.getRowIndex();
    const pinned = headerCtrl.getPinned();
    const { column: focusedColumn, headerRowIndex: focusedHeaderRowIndex } = this.focusedHeaderPosition;
    return column === focusedColumn && headerRowIndex === focusedHeaderRowIndex && pinned == focusedColumn.getPinned();
  }
  clearFocusedHeader() {
    this.focusedHeaderPosition = null;
  }
  getFocusedHeader() {
    return this.focusedHeaderPosition;
  }
  setFocusedHeader(headerRowIndex, column) {
    this.focusedHeaderPosition = { headerRowIndex, column };
  }
  focusHeaderPosition(params) {
    if (this.gos.get("suppressHeaderFocus")) {
      return false;
    }
    const { direction, fromTab, allowUserOverride, event, fromCell, rowWithoutSpanValue } = params;
    let { headerPosition } = params;
    if (fromCell && this.filterManager?.isAdvancedFilterHeaderActive()) {
      return this.focusAdvancedFilter(headerPosition);
    }
    if (allowUserOverride) {
      const currentPosition = this.getFocusedHeader();
      const headerRowCount = this.headerNavigationService.getHeaderRowCount();
      if (fromTab) {
        const userFunc = this.gos.getCallback("tabToNextHeader");
        if (userFunc) {
          headerPosition = this.getHeaderPositionFromUserFunc({
            userFunc,
            direction,
            currentPosition,
            headerPosition,
            headerRowCount
          });
        }
      } else {
        const userFunc = this.gos.getCallback("navigateToNextHeader");
        if (userFunc && event) {
          const params2 = {
            key: event.key,
            previousHeaderPosition: currentPosition,
            nextHeaderPosition: headerPosition,
            headerRowCount,
            event
          };
          headerPosition = userFunc(params2);
        }
      }
    }
    if (!headerPosition) {
      return false;
    }
    return this.focusProvidedHeaderPosition({
      headerPosition,
      direction,
      event,
      fromCell,
      rowWithoutSpanValue
    });
  }
  focusHeaderPositionFromUserFunc(params) {
    if (this.gos.get("suppressHeaderFocus")) {
      return false;
    }
    const { userFunc, headerPosition, direction, event } = params;
    const currentPosition = this.getFocusedHeader();
    const headerRowCount = this.headerNavigationService.getHeaderRowCount();
    const newHeaderPosition = this.getHeaderPositionFromUserFunc({
      userFunc,
      direction,
      currentPosition,
      headerPosition,
      headerRowCount
    });
    return !!newHeaderPosition && this.focusProvidedHeaderPosition({
      headerPosition: newHeaderPosition,
      direction,
      event
    });
  }
  getHeaderPositionFromUserFunc(params) {
    const { userFunc, direction, currentPosition, headerPosition, headerRowCount } = params;
    const userFuncParams = {
      backwards: direction === "Before",
      previousHeaderPosition: currentPosition,
      nextHeaderPosition: headerPosition,
      headerRowCount
    };
    const userResult = userFunc(userFuncParams);
    if (userResult === true || userResult === null) {
      if (userResult === null) {
        _warnOnce(
          "Since v31.3 Returning `null` from tabToNextHeader is deprecated. Return `true` to stay on the current header, or `false` to let the browser handle the tab behaviour."
        );
      }
      return currentPosition;
    }
    if (userResult === false) {
      return null;
    }
    return userResult;
  }
  focusProvidedHeaderPosition(params) {
    const { headerPosition, direction, fromCell, rowWithoutSpanValue, event } = params;
    const { column, headerRowIndex } = headerPosition;
    if (headerRowIndex === -1) {
      if (this.filterManager?.isAdvancedFilterHeaderActive()) {
        return this.focusAdvancedFilter(headerPosition);
      }
      return this.focusGridView(column);
    }
    this.headerNavigationService.scrollToColumn(column, direction);
    const headerRowContainerCtrl = this.ctrlsService.getHeaderRowContainerCtrl(column.getPinned());
    const focusSuccess = headerRowContainerCtrl.focusHeader(
      headerPosition.headerRowIndex,
      column,
      event
    );
    if (focusSuccess && (rowWithoutSpanValue != null || fromCell)) {
      this.headerNavigationService.setCurrentHeaderRowWithoutSpan(rowWithoutSpanValue ?? -1);
    }
    return focusSuccess;
  }
  focusFirstHeader() {
    let firstColumn = this.visibleColsService.getAllCols()[0];
    if (!firstColumn) {
      return false;
    }
    if (firstColumn.getParent()) {
      firstColumn = this.visibleColsService.getColGroupAtLevel(firstColumn, 0);
    }
    const headerPosition = this.headerPositionUtils.getHeaderIndexToFocus(firstColumn, 0);
    return this.focusHeaderPosition({
      headerPosition,
      rowWithoutSpanValue: 0
    });
  }
  focusLastHeader(event) {
    const headerRowIndex = this.headerNavigationService.getHeaderRowCount() - 1;
    const column = _last(this.visibleColsService.getAllCols());
    return this.focusHeaderPosition({
      headerPosition: { headerRowIndex, column },
      rowWithoutSpanValue: -1,
      event
    });
  }
  focusPreviousFromFirstCell(event) {
    if (this.filterManager?.isAdvancedFilterHeaderActive()) {
      return this.focusAdvancedFilter(null);
    }
    return this.focusLastHeader(event);
  }
  isAnyCellFocused() {
    return !!this.focusedCellPosition;
  }
  isRowFocused(rowIndex, rowPinnedType) {
    if (this.focusedCellPosition == null) {
      return false;
    }
    return this.focusedCellPosition.rowIndex === rowIndex && this.focusedCellPosition.rowPinned === _makeNull(rowPinnedType);
  }
  findFocusableElements(rootNode, exclude, onlyUnmanaged = false) {
    const focusableString = FOCUSABLE_SELECTOR;
    let excludeString = FOCUSABLE_EXCLUDE;
    if (exclude) {
      excludeString += ", " + exclude;
    }
    if (onlyUnmanaged) {
      excludeString += ', [tabindex="-1"]';
    }
    const nodes = Array.prototype.slice.apply(rootNode.querySelectorAll(focusableString)).filter((node) => {
      return _isVisible(node);
    });
    const excludeNodes = Array.prototype.slice.apply(rootNode.querySelectorAll(excludeString));
    if (!excludeNodes.length) {
      return nodes;
    }
    const diff = (a, b) => a.filter((element) => b.indexOf(element) === -1);
    return diff(nodes, excludeNodes);
  }
  focusInto(rootNode, up = false, onlyUnmanaged = false) {
    const focusableElements = this.findFocusableElements(rootNode, null, onlyUnmanaged);
    const toFocus = up ? _last(focusableElements) : focusableElements[0];
    if (toFocus) {
      toFocus.focus({ preventScroll: true });
      return true;
    }
    return false;
  }
  findFocusableElementBeforeTabGuard(rootNode, referenceElement) {
    if (!referenceElement) {
      return null;
    }
    const focusableElements = this.findFocusableElements(rootNode);
    const referenceIndex = focusableElements.indexOf(referenceElement);
    if (referenceIndex === -1) {
      return null;
    }
    let lastTabGuardIndex = -1;
    for (let i = referenceIndex - 1; i >= 0; i--) {
      if (focusableElements[i].classList.contains("ag-tab-guard-top" /* TAB_GUARD_TOP */)) {
        lastTabGuardIndex = i;
        break;
      }
    }
    if (lastTabGuardIndex <= 0) {
      return null;
    }
    return focusableElements[lastTabGuardIndex - 1];
  }
  findNextFocusableElement(rootNode = this.eGridDiv, onlyManaged, backwards) {
    const focusable = this.findFocusableElements(rootNode, onlyManaged ? ':not([tabindex="-1"])' : null);
    const activeEl = this.gos.getActiveDomElement();
    let currentIndex;
    if (onlyManaged) {
      currentIndex = focusable.findIndex((el) => el.contains(activeEl));
    } else {
      currentIndex = focusable.indexOf(activeEl);
    }
    const nextIndex = currentIndex + (backwards ? -1 : 1);
    if (nextIndex < 0 || nextIndex >= focusable.length) {
      return null;
    }
    return focusable[nextIndex];
  }
  isTargetUnderManagedComponent(rootNode, target) {
    if (!target) {
      return false;
    }
    const managedContainers = rootNode.querySelectorAll(`.${ManagedFocusFeature.FOCUS_MANAGED_CLASS}`);
    if (!managedContainers.length) {
      return false;
    }
    for (let i = 0; i < managedContainers.length; i++) {
      if (managedContainers[i].contains(target)) {
        return true;
      }
    }
    return false;
  }
  findTabbableParent(node, limit = 5) {
    let counter = 0;
    while (node && _getTabIndex(node) === null && ++counter <= limit) {
      node = node.parentElement;
    }
    if (_getTabIndex(node) === null) {
      return null;
    }
    return node;
  }
  focusGridView(column, backwards) {
    if (this.gos.get("suppressCellFocus")) {
      if (backwards) {
        if (!this.gos.get("suppressHeaderFocus")) {
          return this.focusLastHeader();
        }
        return this.focusNextGridCoreContainer(true, true);
      }
      return this.focusNextGridCoreContainer(false);
    }
    const nextRow = backwards ? this.rowPositionUtils.getLastRow() : this.rowPositionUtils.getFirstRow();
    if (!nextRow) {
      return false;
    }
    const { rowIndex, rowPinned } = nextRow;
    const focusedHeader = this.getFocusedHeader();
    if (!column && focusedHeader) {
      column = focusedHeader.column;
    }
    if (rowIndex == null || !column) {
      return false;
    }
    this.navigationService.ensureCellVisible({ rowIndex, column, rowPinned });
    this.setFocusedCell({
      rowIndex,
      column,
      rowPinned: _makeNull(rowPinned),
      forceBrowserFocus: true
    });
    this.rangeService?.setRangeToCell({ rowIndex, rowPinned, column });
    return true;
  }
  focusNextGridCoreContainer(backwards, forceOut = false) {
    if (!forceOut && this.gridCtrl.focusNextInnerContainer(backwards)) {
      return true;
    }
    if (forceOut || !backwards && !this.gridCtrl.isDetailGrid()) {
      this.gridCtrl.forceFocusOutOfContainer(backwards);
    }
    return false;
  }
  focusAdvancedFilter(position) {
    this.advancedFilterFocusColumn = position?.column;
    return this.advancedFilterService?.getCtrl().focusHeaderComp() ?? false;
  }
  focusNextFromAdvancedFilter(backwards, forceFirstColumn) {
    const column = (forceFirstColumn ? void 0 : this.advancedFilterFocusColumn) ?? this.visibleColsService.getAllCols()?.[0];
    if (backwards) {
      return this.focusHeaderPosition({
        headerPosition: {
          column,
          headerRowIndex: this.headerNavigationService.getHeaderRowCount() - 1
        }
      });
    } else {
      return this.focusGridView(column);
    }
  }
  clearAdvancedFilterColumn() {
    this.advancedFilterFocusColumn = void 0;
  }
  addFocusableContainer(container) {
    this.gridCtrl.addFocusableContainer(container);
  }
  removeFocusableContainer(container) {
    this.gridCtrl.removeFocusableContainer(container);
  }
  focusGridInnerElement(fromBottom) {
    return this.gridCtrl.focusInnerElement(fromBottom);
  }
};
_FocusService.keyboardModeActive = false;
_FocusService.instanceCount = 0;
var FocusService = _FocusService;

// community-modules/core/src/gridBodyComp/pinnedWidthService.ts
var PinnedWidthService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "pinnedWidthService";
  }
  wireBeans(beans) {
    this.visibleColsService = beans.visibleColsService;
  }
  postConstruct() {
    const listener = this.checkContainerWidths.bind(this);
    this.addManagedEventListeners({
      displayedColumnsChanged: listener,
      displayedColumnsWidthChanged: listener
    });
    this.addManagedPropertyListener("domLayout", listener);
  }
  checkContainerWidths() {
    const printLayout = this.gos.isDomLayout("print");
    const newLeftWidth = printLayout ? 0 : this.visibleColsService.getColsLeftWidth();
    const newRightWidth = printLayout ? 0 : this.visibleColsService.getDisplayedColumnsRightWidth();
    if (newLeftWidth != this.leftWidth) {
      this.leftWidth = newLeftWidth;
      this.eventService.dispatchEvent({ type: "leftPinnedWidthChanged" });
    }
    if (newRightWidth != this.rightWidth) {
      this.rightWidth = newRightWidth;
      this.eventService.dispatchEvent({ type: "rightPinnedWidthChanged" });
    }
  }
  getPinnedRightWidth() {
    return this.rightWidth;
  }
  getPinnedLeftWidth() {
    return this.leftWidth;
  }
};

// community-modules/core/src/gridComp/gridCtrl.ts
var GridCtrl = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.additionalFocusableContainers = /* @__PURE__ */ new Set();
  }
  wireBeans(beans) {
    this.beans = beans;
    this.focusService = beans.focusService;
    this.visibleColsService = beans.visibleColsService;
  }
  setComp(view, eGridDiv, eGui) {
    this.view = view;
    this.eGridHostDiv = eGridDiv;
    this.eGui = eGui;
    this.eGui.setAttribute("grid-id", this.gridId);
    const { dragAndDropService, mouseEventService, ctrlsService, resizeObserverService } = this.beans;
    dragAndDropService.addDropTarget({
      getContainer: () => this.eGui,
      isInterestedIn: (type) => type === 1 /* HeaderCell */ || type === 0 /* ToolPanel */,
      getIconName: () => "notAllowed"
    });
    mouseEventService.stampTopLevelGridCompWithGridInstance(eGridDiv);
    this.createManagedBean(new LayoutFeature(this.view));
    this.addRtlSupport();
    const unsubscribeFromResize = resizeObserverService.observeResize(
      this.eGridHostDiv,
      this.onGridSizeChanged.bind(this)
    );
    this.addDestroyFunc(() => unsubscribeFromResize());
    ctrlsService.register("gridCtrl", this);
  }
  isDetailGrid() {
    const el = this.focusService.findTabbableParent(this.getGui());
    return el?.getAttribute("row-id")?.startsWith("detail") || false;
  }
  getOptionalSelectors() {
    const beans = this.beans;
    return {
      paginationSelector: beans.paginationService?.getPaginationSelector(),
      gridHeaderDropZonesSelector: beans.columnDropZonesService?.getDropZoneSelector(),
      sideBarSelector: beans.sideBarService?.getSideBarSelector(),
      statusBarSelector: beans.statusBarService?.getStatusPanelSelector(),
      watermarkSelector: beans.licenseManager?.getWatermarkSelector()
    };
  }
  onGridSizeChanged() {
    const event = {
      type: "gridSizeChanged",
      clientWidth: this.eGridHostDiv.clientWidth,
      clientHeight: this.eGridHostDiv.clientHeight
    };
    this.eventService.dispatchEvent(event);
  }
  addRtlSupport() {
    const cssClass = this.gos.get("enableRtl") ? "ag-rtl" : "ag-ltr";
    this.view.setRtlClass(cssClass);
  }
  destroyGridUi() {
    this.view.destroyGridUi();
  }
  getGui() {
    return this.eGui;
  }
  setResizeCursor(on) {
    this.view.setCursor(on ? "ew-resize" : null);
  }
  disableUserSelect(on) {
    this.view.setUserSelect(on ? "none" : null);
  }
  focusNextInnerContainer(backwards) {
    const focusableContainers = this.getFocusableContainers();
    const activeEl = this.gos.getActiveDomElement();
    const idxWithFocus = focusableContainers.findIndex((container) => container.getGui().contains(activeEl));
    const nextIdx = idxWithFocus + (backwards ? -1 : 1);
    if (nextIdx < 0 || nextIdx >= focusableContainers.length) {
      return false;
    }
    if (nextIdx === 0) {
      if (idxWithFocus > 0) {
        const allColumns = this.visibleColsService.getAllCols();
        const lastColumn = _last(allColumns);
        if (this.focusService.focusGridView(lastColumn, true)) {
          return true;
        }
      }
      return false;
    }
    return this.focusContainer(focusableContainers[nextIdx], backwards);
  }
  focusInnerElement(fromBottom) {
    const focusableContainers = this.getFocusableContainers();
    const allColumns = this.visibleColsService.getAllCols();
    const userCallbackFunction = this.gos.getCallback("focusGridInnerElement");
    if (userCallbackFunction && userCallbackFunction({ fromBottom: !!fromBottom })) {
      return true;
    }
    if (fromBottom) {
      if (focusableContainers.length > 1) {
        return this.focusContainer(_last(focusableContainers), true);
      }
      const lastColumn = _last(allColumns);
      if (this.focusService.focusGridView(lastColumn, true)) {
        return true;
      }
    }
    if (this.gos.get("headerHeight") === 0 || this.gos.get("suppressHeaderFocus")) {
      if (this.focusService.focusGridView(allColumns[0])) {
        return true;
      }
      for (let i = 1; i < focusableContainers.length; i++) {
        if (this.focusService.focusInto(focusableContainers[i].getGui())) {
          return true;
        }
      }
      return false;
    }
    return this.focusService.focusFirstHeader();
  }
  forceFocusOutOfContainer(up = false) {
    this.view.forceFocusOutOfContainer(up);
  }
  addFocusableContainer(container) {
    this.additionalFocusableContainers.add(container);
  }
  removeFocusableContainer(container) {
    this.additionalFocusableContainers.delete(container);
  }
  focusContainer(comp, up) {
    comp?.setAllowFocus?.(true);
    const result = this.focusService.focusInto(comp.getGui(), up);
    comp?.setAllowFocus?.(false);
    return result;
  }
  getFocusableContainers() {
    return [...this.view.getFocusableContainers(), ...this.additionalFocusableContainers.values()];
  }
  destroy() {
    this.additionalFocusableContainers.clear();
    super.destroy();
  }
};

// community-modules/core/src/gridComp/gridComp.ts
var GridComp = class extends TabGuardComp {
  constructor(eGridDiv) {
    super();
    this.gridBody = RefPlaceholder;
    this.sideBar = RefPlaceholder;
    this.pagination = RefPlaceholder;
    this.rootWrapperBody = RefPlaceholder;
    this.eGridDiv = eGridDiv;
  }
  postConstruct() {
    const compProxy = {
      destroyGridUi: () => this.destroyBean(this),
      setRtlClass: (cssClass) => this.addCssClass(cssClass),
      forceFocusOutOfContainer: this.forceFocusOutOfContainer.bind(this),
      updateLayoutClasses: this.updateLayoutClasses.bind(this),
      getFocusableContainers: this.getFocusableContainers.bind(this),
      setUserSelect: (value) => {
        this.getGui().style.userSelect = value != null ? value : "";
        this.getGui().style.webkitUserSelect = value != null ? value : "";
      },
      setCursor: (value) => {
        this.getGui().style.cursor = value != null ? value : "";
      }
    };
    const ctrl = this.createManagedBean(new GridCtrl());
    const comps = ctrl.getOptionalSelectors();
    const template = this.createTemplate(comps);
    const requiredComps = [GridBodySelector, ...Object.values(comps).filter((c) => !!c)];
    this.setTemplate(template, requiredComps);
    ctrl.setComp(compProxy, this.eGridDiv, this.getGui());
    this.insertGridIntoDom();
    this.initialiseTabGuard({
      // we want to override the default behaviour to do nothing for onTabKeyDown
      onTabKeyDown: () => void 0,
      focusInnerElement: (fromBottom) => ctrl.focusInnerElement(fromBottom),
      forceFocusOutWhenTabGuardsAreEmpty: true
    });
  }
  insertGridIntoDom() {
    const eGui = this.getGui();
    this.eGridDiv.appendChild(eGui);
    this.addDestroyFunc(() => {
      this.eGridDiv.removeChild(eGui);
      if (this.gos.get("debug")) {
        _log("Grid removed from DOM");
      }
    });
  }
  updateLayoutClasses(cssClass, params) {
    const eRootWrapperBodyClassList = this.rootWrapperBody.classList;
    eRootWrapperBodyClassList.toggle("ag-layout-auto-height" /* AUTO_HEIGHT */, params.autoHeight);
    eRootWrapperBodyClassList.toggle("ag-layout-normal" /* NORMAL */, params.normal);
    eRootWrapperBodyClassList.toggle("ag-layout-print" /* PRINT */, params.print);
    this.addOrRemoveCssClass("ag-layout-auto-height" /* AUTO_HEIGHT */, params.autoHeight);
    this.addOrRemoveCssClass("ag-layout-normal" /* NORMAL */, params.normal);
    this.addOrRemoveCssClass("ag-layout-print" /* PRINT */, params.print);
  }
  createTemplate(params) {
    const dropZones = params.gridHeaderDropZonesSelector ? "<ag-grid-header-drop-zones></ag-grid-header-drop-zones>" : "";
    const sideBar = params.sideBarSelector ? '<ag-side-bar data-ref="sideBar"></ag-side-bar>' : "";
    const statusBar = params.statusBarSelector ? "<ag-status-bar></ag-status-bar>" : "";
    const watermark = params.watermarkSelector ? "<ag-watermark></ag-watermark>" : "";
    const pagination = params.paginationSelector ? '<ag-pagination data-ref="pagination"></ag-pagination>' : "";
    const template = (
      /* html */
      `<div class="ag-root-wrapper" role="presentation">
                ${dropZones}
                <div class="ag-root-wrapper-body" data-ref="rootWrapperBody" role="presentation">
                    <ag-grid-body data-ref="gridBody"></ag-grid-body>
                    ${sideBar}
                </div>
                ${statusBar}
                ${pagination}
                ${watermark}
            </div>`
    );
    return template;
  }
  getFocusableElement() {
    return this.rootWrapperBody;
  }
  forceFocusOutOfContainer(up = false) {
    if (!up && this.pagination?.isDisplayed()) {
      this.pagination.forceFocusOutOfContainer(up);
      return;
    }
    super.forceFocusOutOfContainer(up);
  }
  getFocusableContainers() {
    const focusableContainers = [this.gridBody];
    [this.sideBar, this.pagination].forEach((comp) => {
      if (comp) {
        focusableContainers.push(comp);
      }
    });
    return focusableContainers.filter((el) => _isVisible(el.getGui()));
  }
};

// community-modules/core/src/alignedGridsService.ts
var AlignedGridsService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "alignedGridsService";
    // flag to mark if we are consuming. to avoid cyclic events (ie other grid firing back to master
    // while processing a master event) we mark this if consuming an event, and if we are, then
    // we don't fire back any events.
    this.consuming = false;
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.columnSizeService = beans.columnSizeService;
    this.ctrlsService = beans.ctrlsService;
    this.columnApplyStateService = beans.columnApplyStateService;
  }
  getAlignedGridApis() {
    let alignedGrids = this.gos.get("alignedGrids") ?? [];
    const isCallbackConfig = typeof alignedGrids === "function";
    if (typeof alignedGrids === "function") {
      alignedGrids = alignedGrids();
    }
    const seeUrl = () => `See ${this.getFrameworkOverrides().getDocLink("aligned-grids")}`;
    const apis = alignedGrids.map((alignedGrid) => {
      if (!alignedGrid) {
        _errorOnce(`alignedGrids contains an undefined option.`);
        if (!isCallbackConfig) {
          _errorOnce(`You may want to configure via a callback to avoid setup race conditions:
                     "alignedGrids: () => [linkedGrid]"`);
        }
        _errorOnce(seeUrl());
        return;
      }
      if (this.isGridApi(alignedGrid)) {
        return alignedGrid;
      }
      const refOrComp = alignedGrid;
      if ("current" in refOrComp) {
        return refOrComp.current?.api;
      }
      if (!refOrComp.api) {
        _errorOnce(
          `alignedGrids - No api found on the linked grid. If you are passing gridOptions to alignedGrids since v31 this is no longer valid. ${seeUrl()}`
        );
      }
      return refOrComp.api;
    }).filter((api) => !!api && !api.isDestroyed());
    return apis;
  }
  isGridApi(ref) {
    return !!ref && !!ref.dispatchEvent;
  }
  postConstruct() {
    const fireColumnEvent = this.fireColumnEvent.bind(this);
    this.addManagedEventListeners({
      columnMoved: fireColumnEvent,
      columnVisible: fireColumnEvent,
      columnPinned: fireColumnEvent,
      columnGroupOpened: fireColumnEvent,
      columnResized: fireColumnEvent,
      bodyScroll: this.fireScrollEvent.bind(this),
      alignedGridColumn: ({ event }) => this.onColumnEvent(event),
      alignedGridScroll: ({ event }) => this.onScrollEvent(event)
    });
  }
  // common logic across all the fire methods
  fireEvent(event) {
    if (this.consuming) {
      return;
    }
    this.getAlignedGridApis().forEach((api) => {
      if (api.isDestroyed()) {
        return;
      }
      api.dispatchEvent(event);
    });
  }
  // common logic across all consume methods. very little common logic, however extracting
  // guarantees consistency across the methods.
  onEvent(callback) {
    this.consuming = true;
    callback();
    this.consuming = false;
  }
  fireColumnEvent(columnEvent) {
    const event = {
      type: "alignedGridColumn",
      event: columnEvent
    };
    this.fireEvent(event);
  }
  fireScrollEvent(scrollEvent) {
    if (scrollEvent.direction !== "horizontal") {
      return;
    }
    const event = {
      type: "alignedGridScroll",
      event: scrollEvent
    };
    this.fireEvent(event);
  }
  onScrollEvent(event) {
    this.onEvent(() => {
      const gridBodyCon = this.ctrlsService.getGridBodyCtrl();
      gridBodyCon.getScrollFeature().setHorizontalScrollPosition(event.left, true);
    });
  }
  getMasterColumns(event) {
    const result = [];
    if (event.columns) {
      event.columns.forEach((column) => {
        result.push(column);
      });
    } else if (event.column) {
      result.push(event.column);
    }
    return result;
  }
  getColumnIds(event) {
    const result = [];
    if (event.columns) {
      event.columns.forEach((column) => {
        result.push(column.getColId());
      });
    } else if (event.column) {
      result.push(event.column.getColId());
    }
    return result;
  }
  onColumnEvent(event) {
    this.onEvent(() => {
      switch (event.type) {
        case "columnMoved":
        case "columnVisible":
        case "columnPinned":
        case "columnResized": {
          const colEvent = event;
          this.processColumnEvent(colEvent);
          break;
        }
        case "columnGroupOpened": {
          const groupOpenedEvent = event;
          this.processGroupOpenedEvent(groupOpenedEvent);
          break;
        }
        case "columnPivotChanged":
          _warnOnce(
            "pivoting is not supported with aligned grids. You can only use one of these features at a time in a grid."
          );
          break;
      }
    });
  }
  processGroupOpenedEvent(groupOpenedEvent) {
    groupOpenedEvent.columnGroups.forEach((masterGroup) => {
      let otherColumnGroup = null;
      if (masterGroup) {
        const groupId = masterGroup.getGroupId();
        otherColumnGroup = this.columnModel.getProvidedColGroup(groupId);
      }
      if (masterGroup && !otherColumnGroup) {
        return;
      }
      this.columnModel.setColumnGroupOpened(otherColumnGroup, masterGroup.isExpanded(), "alignedGridChanged");
    });
  }
  processColumnEvent(colEvent) {
    const masterColumn = colEvent.column;
    let otherColumn = null;
    if (masterColumn) {
      otherColumn = this.columnModel.getColDefCol(masterColumn.getColId());
    }
    if (masterColumn && !otherColumn) {
      return;
    }
    const masterColumns = this.getMasterColumns(colEvent);
    switch (colEvent.type) {
      case "columnMoved":
        {
          const srcColState = colEvent.api.getColumnState();
          const destColState = srcColState.map((s) => ({ colId: s.colId }));
          this.columnApplyStateService.applyColumnState(
            { state: destColState, applyOrder: true },
            "alignedGridChanged"
          );
        }
        break;
      case "columnVisible":
        {
          const srcColState = colEvent.api.getColumnState();
          const destColState = srcColState.map((s) => ({ colId: s.colId, hide: s.hide }));
          this.columnApplyStateService.applyColumnState({ state: destColState }, "alignedGridChanged");
        }
        break;
      case "columnPinned":
        {
          const srcColState = colEvent.api.getColumnState();
          const destColState = srcColState.map((s) => ({ colId: s.colId, pinned: s.pinned }));
          this.columnApplyStateService.applyColumnState({ state: destColState }, "alignedGridChanged");
        }
        break;
      case "columnResized": {
        const resizedEvent = colEvent;
        const columnWidths = {};
        masterColumns.forEach((column) => {
          columnWidths[column.getId()] = { key: column.getColId(), newWidth: column.getActualWidth() };
        });
        resizedEvent.flexColumns?.forEach((col) => {
          if (columnWidths[col.getId()]) {
            delete columnWidths[col.getId()];
          }
        });
        this.columnSizeService.setColumnWidths(
          Object.values(columnWidths),
          false,
          resizedEvent.finished,
          "alignedGridChanged"
        );
        break;
      }
    }
    const gridBodyCon = this.ctrlsService.getGridBodyCtrl();
    const isVerticalScrollShowing = gridBodyCon.isVerticalScrollShowing();
    this.getAlignedGridApis().forEach((api) => {
      api.setGridOption("alwaysShowVerticalScroll", isVerticalScrollShowing);
    });
  }
};

// community-modules/core/src/alignedGridsModule.ts
var AlignedGridsModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/aligned-grid",
  beans: [AlignedGridsService]
};

// community-modules/core/src/columns/columnApi.ts
function getColumnDef(beans, key) {
  const column = beans.columnModel.getColDefCol(key);
  if (column) {
    return column.getColDef();
  }
  return null;
}
function getColumnDefs(beans) {
  return beans.columnModel.getColumnDefs();
}
function sizeColumnsToFit(beans, paramsOrGridWidth) {
  if (typeof paramsOrGridWidth === "number") {
    beans.columnSizeService.sizeColumnsToFit(paramsOrGridWidth, "api");
  } else {
    beans.ctrlsService.getGridBodyCtrl().sizeColumnsToFit(paramsOrGridWidth);
  }
}
function setColumnGroupOpened(beans, group, newValue) {
  beans.columnModel.setColumnGroupOpened(group, newValue, "api");
}
function getColumnGroup(beans, name, instanceId) {
  return beans.visibleColsService.getColumnGroup(name, instanceId);
}
function getProvidedColumnGroup(beans, name) {
  return beans.columnModel.getProvidedColGroup(name);
}
function getDisplayNameForColumn(beans, column, location) {
  return beans.columnNameService.getDisplayNameForColumn(column, location) || "";
}
function getDisplayNameForColumnGroup(beans, columnGroup, location) {
  return beans.columnNameService.getDisplayNameForColumnGroup(columnGroup, location) || "";
}
function getColumn(beans, key) {
  return beans.columnModel.getColDefCol(key);
}
function getColumns(beans) {
  return beans.columnModel.getColDefCols();
}
function applyColumnState(beans, params) {
  return beans.columnApplyStateService.applyColumnState(params, "api");
}
function getColumnState(beans) {
  return beans.columnGetStateService.getColumnState();
}
function resetColumnState(beans) {
  beans.columnApplyStateService.resetColumnState("api");
}
function getColumnGroupState(beans) {
  return beans.columnGroupStateService.getColumnGroupState();
}
function setColumnGroupState(beans, stateItems) {
  beans.columnGroupStateService.setColumnGroupState(stateItems, "api");
}
function resetColumnGroupState(beans) {
  beans.columnGroupStateService.resetColumnGroupState("api");
}
function isPinning(beans) {
  return beans.visibleColsService.isPinningLeft() || beans.visibleColsService.isPinningRight();
}
function isPinningLeft(beans) {
  return beans.visibleColsService.isPinningLeft();
}
function isPinningRight(beans) {
  return beans.visibleColsService.isPinningRight();
}
function getDisplayedColAfter(beans, col) {
  return beans.visibleColsService.getColAfter(col);
}
function getDisplayedColBefore(beans, col) {
  return beans.visibleColsService.getColBefore(col);
}
function setColumnVisible(beans, key, visible) {
  beans.columnModel.setColsVisible([key], visible, "api");
}
function setColumnsVisible(beans, keys, visible) {
  beans.columnModel.setColsVisible(keys, visible, "api");
}
function setColumnPinned(beans, key, pinned) {
  beans.columnModel.setColsPinned([key], pinned, "api");
}
function setColumnsPinned(beans, keys, pinned) {
  beans.columnModel.setColsPinned(keys, pinned, "api");
}
function getAllGridColumns(beans) {
  return beans.columnModel.getCols();
}
function getDisplayedLeftColumns(beans) {
  return beans.visibleColsService.getLeftCols();
}
function getDisplayedCenterColumns(beans) {
  return beans.visibleColsService.getCenterCols();
}
function getDisplayedRightColumns(beans) {
  return beans.visibleColsService.getRightCols();
}
function getAllDisplayedColumns(beans) {
  return beans.visibleColsService.getAllCols();
}
function getAllDisplayedVirtualColumns(beans) {
  return beans.columnViewportService.getViewportColumns();
}
function moveColumn(beans, key, toIndex) {
  beans.columnMoveService.moveColumns([key], toIndex, "api");
}
function moveColumnByIndex(beans, fromIndex, toIndex) {
  beans.columnMoveService.moveColumnByIndex(fromIndex, toIndex, "api");
}
function moveColumns2(beans, columnsToMoveKeys, toIndex) {
  beans.columnMoveService.moveColumns(columnsToMoveKeys, toIndex, "api");
}
function setColumnWidth(beans, key, newWidth, finished = true, source = "api") {
  beans.columnSizeService.setColumnWidths([{ key, newWidth }], false, finished, source);
}
function setColumnWidths(beans, columnWidths, finished = true, source = "api") {
  beans.columnSizeService.setColumnWidths(columnWidths, false, finished, source);
}
function getLeftDisplayedColumnGroups(beans) {
  return beans.visibleColsService.getTreeLeft();
}
function getCenterDisplayedColumnGroups(beans) {
  return beans.visibleColsService.getTreeCenter();
}
function getRightDisplayedColumnGroups(beans) {
  return beans.visibleColsService.getTreeRight();
}
function getAllDisplayedColumnGroups(beans) {
  return beans.visibleColsService.getAllTrees();
}
function autoSizeColumn(beans, key, skipHeader) {
  return beans.columnAutosizeService.autoSizeCols({ colKeys: [key], skipHeader, source: "api" });
}
function autoSizeColumns(beans, keys, skipHeader) {
  beans.columnAutosizeService.autoSizeCols({ colKeys: keys, skipHeader, source: "api" });
}
function autoSizeAllColumns(beans, skipHeader) {
  beans.columnAutosizeService.autoSizeAllColumns("api", skipHeader);
}

// community-modules/core/src/columns/dataTypeService.ts
var MONTH_LOCALE_TEXT = {
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December"
};
var MONTH_KEYS = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december"
];
var DataTypeService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "dataTypeService";
    this.dataTypeDefinitions = {};
    this.isWaitingForRowData = false;
    this.isColumnTypeOverrideInDataTypeDefinitions = false;
    // keep track of any column state updates whilst waiting for data types to be inferred
    this.columnStateUpdatesPendingInference = {};
    this.columnStateUpdateListenerDestroyFuncs = [];
  }
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
    this.columnModel = beans.columnModel;
    this.funcColsService = beans.funcColsService;
    this.valueService = beans.valueService;
    this.columnApplyStateService = beans.columnApplyStateService;
  }
  postConstruct() {
    this.groupHideOpenParents = this.gos.get("groupHideOpenParents");
    this.addManagedPropertyListener("groupHideOpenParents", () => {
      this.groupHideOpenParents = this.gos.get("groupHideOpenParents");
    });
    this.processDataTypeDefinitions();
    this.addManagedPropertyListener("dataTypeDefinitions", (event) => {
      this.processDataTypeDefinitions();
      this.columnModel.recreateColumnDefs(convertSourceType(event.source));
    });
  }
  processDataTypeDefinitions() {
    const defaultDataTypes = this.getDefaultDataTypes();
    this.dataTypeDefinitions = {};
    this.formatValueFuncs = {};
    const generateFormatValueFunc = (dataTypeDefinition) => {
      return (params) => {
        const { column, node, value } = params;
        let valueFormatter = column.getColDef().valueFormatter;
        if (valueFormatter === dataTypeDefinition.groupSafeValueFormatter) {
          valueFormatter = dataTypeDefinition.valueFormatter;
        }
        return this.valueService.formatValue(column, node, value, valueFormatter);
      };
    };
    Object.entries(defaultDataTypes).forEach(([cellDataType, dataTypeDefinition]) => {
      const mergedDataTypeDefinition = {
        ...dataTypeDefinition,
        groupSafeValueFormatter: this.createGroupSafeValueFormatter(dataTypeDefinition)
      };
      this.dataTypeDefinitions[cellDataType] = mergedDataTypeDefinition;
      this.formatValueFuncs[cellDataType] = generateFormatValueFunc(mergedDataTypeDefinition);
    });
    const dataTypeDefinitions = this.gos.get("dataTypeDefinitions") ?? {};
    this.dataTypeMatchers = {};
    Object.entries(dataTypeDefinitions).forEach(([cellDataType, dataTypeDefinition]) => {
      const mergedDataTypeDefinition = this.processDataTypeDefinition(
        dataTypeDefinition,
        dataTypeDefinitions,
        [cellDataType],
        defaultDataTypes
      );
      if (mergedDataTypeDefinition) {
        this.dataTypeDefinitions[cellDataType] = mergedDataTypeDefinition;
        if (dataTypeDefinition.dataTypeMatcher) {
          this.dataTypeMatchers[cellDataType] = dataTypeDefinition.dataTypeMatcher;
        }
        this.formatValueFuncs[cellDataType] = generateFormatValueFunc(mergedDataTypeDefinition);
      }
    });
    this.checkObjectValueHandlers(defaultDataTypes);
    ["dateString", "text", "number", "boolean", "date"].forEach((cellDataType) => {
      const overriddenDataTypeMatcher = this.dataTypeMatchers[cellDataType];
      if (overriddenDataTypeMatcher) {
        delete this.dataTypeMatchers[cellDataType];
      }
      this.dataTypeMatchers[cellDataType] = overriddenDataTypeMatcher ?? defaultDataTypes[cellDataType].dataTypeMatcher;
    });
  }
  mergeDataTypeDefinitions(parentDataTypeDefinition, childDataTypeDefinition) {
    const mergedDataTypeDefinition = {
      ...parentDataTypeDefinition,
      ...childDataTypeDefinition
    };
    if (parentDataTypeDefinition.columnTypes && childDataTypeDefinition.columnTypes && childDataTypeDefinition.appendColumnTypes) {
      mergedDataTypeDefinition.columnTypes = [
        ...convertColumnTypes(parentDataTypeDefinition.columnTypes),
        ...convertColumnTypes(childDataTypeDefinition.columnTypes)
      ];
    }
    return mergedDataTypeDefinition;
  }
  processDataTypeDefinition(dataTypeDefinition, dataTypeDefinitions, alreadyProcessedDataTypes, defaultDataTypes) {
    let mergedDataTypeDefinition;
    const extendsCellDataType = dataTypeDefinition.extendsDataType;
    if (dataTypeDefinition.columnTypes) {
      this.isColumnTypeOverrideInDataTypeDefinitions = true;
    }
    if (dataTypeDefinition.extendsDataType === dataTypeDefinition.baseDataType) {
      let baseDataTypeDefinition = defaultDataTypes[extendsCellDataType];
      const overriddenBaseDataTypeDefinition = dataTypeDefinitions[extendsCellDataType];
      if (baseDataTypeDefinition && overriddenBaseDataTypeDefinition) {
        baseDataTypeDefinition = overriddenBaseDataTypeDefinition;
      }
      if (!this.validateDataTypeDefinition(dataTypeDefinition, baseDataTypeDefinition, extendsCellDataType)) {
        return void 0;
      }
      mergedDataTypeDefinition = this.mergeDataTypeDefinitions(baseDataTypeDefinition, dataTypeDefinition);
    } else {
      if (alreadyProcessedDataTypes.includes(extendsCellDataType)) {
        _warnOnce(
          'Data type definition hierarchies (via the "extendsDataType" property) cannot contain circular references.'
        );
        return void 0;
      }
      const extendedDataTypeDefinition = dataTypeDefinitions[extendsCellDataType];
      if (!this.validateDataTypeDefinition(dataTypeDefinition, extendedDataTypeDefinition, extendsCellDataType)) {
        return void 0;
      }
      const mergedExtendedDataTypeDefinition = this.processDataTypeDefinition(
        extendedDataTypeDefinition,
        dataTypeDefinitions,
        [...alreadyProcessedDataTypes, extendsCellDataType],
        defaultDataTypes
      );
      if (!mergedExtendedDataTypeDefinition) {
        return void 0;
      }
      mergedDataTypeDefinition = this.mergeDataTypeDefinitions(
        mergedExtendedDataTypeDefinition,
        dataTypeDefinition
      );
    }
    return {
      ...mergedDataTypeDefinition,
      groupSafeValueFormatter: this.createGroupSafeValueFormatter(mergedDataTypeDefinition)
    };
  }
  validateDataTypeDefinition(dataTypeDefinition, parentDataTypeDefinition, parentCellDataType) {
    if (!parentDataTypeDefinition) {
      _warnOnce(`The data type definition ${parentCellDataType} does not exist.`);
      return false;
    }
    if (parentDataTypeDefinition.baseDataType !== dataTypeDefinition.baseDataType) {
      _warnOnce('The "baseDataType" property of a data type definition must match that of its parent.');
      return false;
    }
    return true;
  }
  createGroupSafeValueFormatter(dataTypeDefinition) {
    if (!dataTypeDefinition.valueFormatter) {
      return void 0;
    }
    return (params) => {
      if (params.node?.group) {
        const aggFunc = params.column.getAggFunc();
        if (aggFunc) {
          if (aggFunc === "first" || aggFunc === "last") {
            return dataTypeDefinition.valueFormatter(params);
          }
          if (dataTypeDefinition.baseDataType === "number" && aggFunc !== "count") {
            if (typeof params.value === "number") {
              return dataTypeDefinition.valueFormatter(params);
            }
            if (typeof params.value === "object") {
              if (!params.value) {
                return void 0;
              }
              if ("toNumber" in params.value) {
                return dataTypeDefinition.valueFormatter({
                  ...params,
                  value: params.value.toNumber()
                });
              }
              if ("value" in params.value) {
                return dataTypeDefinition.valueFormatter({
                  ...params,
                  value: params.value.value
                });
              }
            }
          }
        }
        if (!this.gos.get("suppressGroupMaintainValueType")) {
          return void 0;
        }
      } else if (this.groupHideOpenParents && params.column.isRowGroupActive()) {
        if (typeof params.value !== "string" || dataTypeDefinition.dataTypeMatcher?.(params.value)) {
          return dataTypeDefinition.valueFormatter(params);
        }
        if (!this.gos.get("suppressGroupMaintainValueType")) {
          return void 0;
        }
      }
      return dataTypeDefinition.valueFormatter(params);
    };
  }
  updateColDefAndGetColumnType(colDef, userColDef, colId) {
    let { cellDataType } = userColDef;
    const { field } = userColDef;
    if (cellDataType === void 0) {
      cellDataType = colDef.cellDataType;
    }
    if (cellDataType == null || cellDataType === true) {
      cellDataType = this.canInferCellDataType(colDef, userColDef) ? this.inferCellDataType(field, colId) : false;
    }
    if (!cellDataType) {
      colDef.cellDataType = false;
      return void 0;
    }
    const dataTypeDefinition = this.dataTypeDefinitions[cellDataType];
    if (!dataTypeDefinition) {
      _warnOnce(`Missing data type definition - "${cellDataType}"`);
      return void 0;
    }
    colDef.cellDataType = cellDataType;
    if (dataTypeDefinition.groupSafeValueFormatter) {
      colDef.valueFormatter = dataTypeDefinition.groupSafeValueFormatter;
    }
    if (dataTypeDefinition.valueParser) {
      colDef.valueParser = dataTypeDefinition.valueParser;
    }
    if (!dataTypeDefinition.suppressDefaultProperties) {
      this.setColDefPropertiesForBaseDataType(colDef, cellDataType, dataTypeDefinition, colId);
    }
    return dataTypeDefinition.columnTypes;
  }
  addColumnListeners(column) {
    if (!this.isWaitingForRowData) {
      return;
    }
    const columnStateUpdates = this.columnStateUpdatesPendingInference[column.getColId()];
    if (!columnStateUpdates) {
      return;
    }
    const columnListener = (event) => {
      columnStateUpdates.add(event.key);
    };
    column.addEventListener("columnStateUpdated", columnListener);
    this.columnStateUpdateListenerDestroyFuncs.push(
      () => column.removeEventListener("columnStateUpdated", columnListener)
    );
  }
  canInferCellDataType(colDef, userColDef) {
    if (this.rowModel.getType() !== "clientSide") {
      return false;
    }
    const propsToCheckForInference = { cellRenderer: true, valueGetter: true, valueParser: true, refData: true };
    if (this.doColDefPropsPreventInference(userColDef, propsToCheckForInference)) {
      return false;
    }
    const columnTypes = userColDef.type === null ? colDef.type : userColDef.type;
    if (columnTypes) {
      const columnTypeDefs = this.gos.get("columnTypes") ?? {};
      const hasPropsPreventingInference = convertColumnTypes(columnTypes).some((columnType) => {
        const columnTypeDef = columnTypeDefs[columnType.trim()];
        return columnTypeDef && this.doColDefPropsPreventInference(columnTypeDef, propsToCheckForInference);
      });
      if (hasPropsPreventingInference) {
        return false;
      }
    }
    return !this.doColDefPropsPreventInference(colDef, propsToCheckForInference);
  }
  doColDefPropsPreventInference(colDef, propsToCheckForInference) {
    return [
      ["cellRenderer", "agSparklineCellRenderer"],
      ["valueGetter", void 0],
      ["valueParser", void 0],
      ["refData", void 0]
    ].some(
      ([prop, comparisonValue]) => this.doesColDefPropPreventInference(colDef, propsToCheckForInference, prop, comparisonValue)
    );
  }
  doesColDefPropPreventInference(colDef, checkProps, prop, comparisonValue) {
    if (!checkProps[prop]) {
      return false;
    }
    const value = colDef[prop];
    if (value === null) {
      checkProps[prop] = false;
      return false;
    } else {
      return comparisonValue === void 0 ? !!value : value === comparisonValue;
    }
  }
  inferCellDataType(field, colId) {
    if (!field) {
      return void 0;
    }
    let value;
    const initialData = this.getInitialData();
    if (initialData) {
      const fieldContainsDots = field.indexOf(".") >= 0 && !this.gos.get("suppressFieldDotNotation");
      value = _getValueUsingField(initialData, field, fieldContainsDots);
    } else {
      this.initWaitForRowData(colId);
    }
    if (value == null) {
      return void 0;
    }
    const [cellDataType] = Object.entries(this.dataTypeMatchers).find(
      ([_cellDataType, dataTypeMatcher]) => dataTypeMatcher(value)
    ) ?? ["object"];
    return cellDataType;
  }
  getInitialData() {
    const rowData = this.gos.get("rowData");
    if (rowData?.length) {
      return rowData[0];
    } else if (this.initialData) {
      return this.initialData;
    } else {
      const rowNodes = this.rowModel.getRootNode().allLeafChildren;
      if (rowNodes?.length) {
        return rowNodes[0].data;
      }
    }
    return null;
  }
  initWaitForRowData(colId) {
    this.columnStateUpdatesPendingInference[colId] = /* @__PURE__ */ new Set();
    if (this.isWaitingForRowData) {
      return;
    }
    this.isWaitingForRowData = true;
    const columnTypeOverridesExist = this.isColumnTypeOverrideInDataTypeDefinitions;
    if (columnTypeOverridesExist) {
      this.columnModel.queueResizeOperations();
    }
    const [destroyFunc] = this.addManagedEventListeners({
      rowDataUpdateStarted: (event) => {
        const { firstRowData } = event;
        if (!firstRowData) {
          return;
        }
        destroyFunc?.();
        this.isWaitingForRowData = false;
        this.processColumnsPendingInference(firstRowData, columnTypeOverridesExist);
        this.columnStateUpdatesPendingInference = {};
        if (columnTypeOverridesExist) {
          this.columnModel.processResizeOperations();
        }
        const dataTypesInferredEvent = {
          type: "dataTypesInferred"
        };
        this.eventService.dispatchEvent(dataTypesInferredEvent);
      }
    });
  }
  isPendingInference() {
    return this.isWaitingForRowData;
  }
  processColumnsPendingInference(firstRowData, columnTypeOverridesExist) {
    this.initialData = firstRowData;
    const state = [];
    this.destroyColumnStateUpdateListeners();
    const newRowGroupColumnStateWithoutIndex = {};
    const newPivotColumnStateWithoutIndex = {};
    Object.entries(this.columnStateUpdatesPendingInference).forEach(([colId, columnStateUpdates]) => {
      const column = this.columnModel.getCol(colId);
      if (!column) {
        return;
      }
      const oldColDef = column.getColDef();
      if (!this.columnModel.resetColDefIntoCol(column, "cellDataTypeInferred")) {
        return;
      }
      const newColDef = column.getColDef();
      if (columnTypeOverridesExist && newColDef.type && newColDef.type !== oldColDef.type) {
        const updatedColumnState = this.getUpdatedColumnState(column, columnStateUpdates);
        if (updatedColumnState.rowGroup && updatedColumnState.rowGroupIndex == null) {
          newRowGroupColumnStateWithoutIndex[colId] = updatedColumnState;
        }
        if (updatedColumnState.pivot && updatedColumnState.pivotIndex == null) {
          newPivotColumnStateWithoutIndex[colId] = updatedColumnState;
        }
        state.push(updatedColumnState);
      }
    });
    if (columnTypeOverridesExist) {
      state.push(
        ...this.funcColsService.generateColumnStateForRowGroupAndPivotIndexes(
          newRowGroupColumnStateWithoutIndex,
          newPivotColumnStateWithoutIndex
        )
      );
    }
    if (state.length) {
      this.columnApplyStateService.applyColumnState({ state }, "cellDataTypeInferred");
    }
    this.initialData = null;
  }
  getUpdatedColumnState(column, columnStateUpdates) {
    const columnState = this.columnApplyStateService.getColumnStateFromColDef(column);
    columnStateUpdates.forEach((key) => {
      delete columnState[key];
      if (key === "rowGroup") {
        delete columnState.rowGroupIndex;
      } else if (key === "pivot") {
        delete columnState.pivotIndex;
      }
    });
    return columnState;
  }
  checkObjectValueHandlers(defaultDataTypes) {
    const resolvedObjectDataTypeDefinition = this.dataTypeDefinitions.object;
    const defaultObjectDataTypeDefinition = defaultDataTypes.object;
    this.hasObjectValueParser = resolvedObjectDataTypeDefinition.valueParser !== defaultObjectDataTypeDefinition.valueParser;
    this.hasObjectValueFormatter = resolvedObjectDataTypeDefinition.valueFormatter !== defaultObjectDataTypeDefinition.valueFormatter;
  }
  getDateStringTypeDefinition(column) {
    if (!column) {
      return this.dataTypeDefinitions.dateString;
    }
    return this.getDataTypeDefinition(column) ?? this.dataTypeDefinitions.dateString;
  }
  getDateParserFunction(column) {
    return this.getDateStringTypeDefinition(column).dateParser;
  }
  getDateFormatterFunction(column) {
    return this.getDateStringTypeDefinition(column).dateFormatter;
  }
  getDataTypeDefinition(column) {
    const colDef = column.getColDef();
    if (!colDef.cellDataType) {
      return void 0;
    }
    return this.dataTypeDefinitions[colDef.cellDataType];
  }
  getBaseDataType(column) {
    return this.getDataTypeDefinition(column)?.baseDataType;
  }
  checkType(column, value) {
    if (value == null) {
      return true;
    }
    const dataTypeMatcher = this.getDataTypeDefinition(column)?.dataTypeMatcher;
    if (!dataTypeMatcher) {
      return true;
    }
    return dataTypeMatcher(value);
  }
  validateColDef(colDef) {
    if (colDef.cellDataType === "object") {
      if (colDef.valueFormatter === this.dataTypeDefinitions.object.groupSafeValueFormatter && !this.hasObjectValueFormatter) {
        _warnOnce(
          'Cell data type is "object" but no value formatter has been provided. Please either provide an object data type definition with a value formatter, or set "colDef.valueFormatter"'
        );
      }
      if (colDef.editable && colDef.valueParser === this.dataTypeDefinitions.object.valueParser && !this.hasObjectValueParser) {
        _warnOnce(
          'Cell data type is "object" but no value parser has been provided. Please either provide an object data type definition with a value parser, or set "colDef.valueParser"'
        );
      }
    }
  }
  getFormatValue(cellDataType) {
    return this.formatValueFuncs[cellDataType];
  }
  setColDefPropertiesForBaseDataType(colDef, cellDataType, dataTypeDefinition, colId) {
    const formatValue = this.formatValueFuncs[cellDataType];
    const usingSetFilter = ModuleRegistry.__isRegistered("@ag-grid-enterprise/set-filter" /* SetFilterModule */, this.gridId);
    const translate = this.localeService.getLocaleTextFunc();
    const mergeFilterParams = (params) => {
      const { filterParams } = colDef;
      colDef.filterParams = typeof filterParams === "object" ? {
        ...filterParams,
        ...params
      } : params;
    };
    switch (dataTypeDefinition.baseDataType) {
      case "number": {
        colDef.cellEditor = "agNumberCellEditor";
        if (usingSetFilter) {
          mergeFilterParams({
            comparator: (a, b) => {
              const valA = a == null ? 0 : parseInt(a);
              const valB = b == null ? 0 : parseInt(b);
              if (valA === valB)
                return 0;
              return valA > valB ? 1 : -1;
            }
          });
        }
        break;
      }
      case "boolean": {
        colDef.cellEditor = "agCheckboxCellEditor";
        colDef.cellRenderer = "agCheckboxCellRenderer";
        colDef.suppressKeyboardEvent = (params) => !!params.colDef.editable && params.event.key === KeyCode.SPACE;
        if (usingSetFilter) {
          mergeFilterParams({
            valueFormatter: (params) => {
              if (!_exists(params.value)) {
                return translate("blanks", "(Blanks)");
              }
              return translate(String(params.value), params.value ? "True" : "False");
            }
          });
        } else {
          mergeFilterParams({
            maxNumConditions: 1,
            debounceMs: 0,
            filterOptions: [
              "empty",
              {
                displayKey: "true",
                displayName: "True",
                predicate: (_filterValues, cellValue) => cellValue,
                numberOfInputs: 0
              },
              {
                displayKey: "false",
                displayName: "False",
                predicate: (_filterValues, cellValue) => cellValue === false,
                numberOfInputs: 0
              }
            ]
          });
        }
        break;
      }
      case "date": {
        colDef.cellEditor = "agDateCellEditor";
        colDef.keyCreator = formatValue;
        if (usingSetFilter) {
          mergeFilterParams({
            valueFormatter: (params) => {
              const valueFormatted = formatValue(params);
              return _exists(valueFormatted) ? valueFormatted : translate("blanks", "(Blanks)");
            },
            treeList: true,
            treeListFormatter: (pathKey, level) => {
              if (level === 1 && pathKey != null) {
                const monthKey = MONTH_KEYS[Number(pathKey) - 1];
                return translate(monthKey, MONTH_LOCALE_TEXT[monthKey]);
              }
              return pathKey ?? translate("blanks", "(Blanks)");
            }
          });
        }
        break;
      }
      case "dateString": {
        colDef.cellEditor = "agDateStringCellEditor";
        colDef.keyCreator = formatValue;
        const convertToDate = dataTypeDefinition.dateParser;
        if (usingSetFilter) {
          mergeFilterParams({
            valueFormatter: (params) => {
              const valueFormatted = formatValue(params);
              return _exists(valueFormatted) ? valueFormatted : translate("blanks", "(Blanks)");
            },
            treeList: true,
            treeListPathGetter: (value) => {
              const date = convertToDate(value ?? void 0);
              return date ? [String(date.getFullYear()), String(date.getMonth() + 1), String(date.getDate())] : null;
            },
            treeListFormatter: (pathKey, level) => {
              if (level === 1 && pathKey != null) {
                const monthKey = MONTH_KEYS[Number(pathKey) - 1];
                return translate(monthKey, MONTH_LOCALE_TEXT[monthKey]);
              }
              return pathKey ?? translate("blanks", "(Blanks)");
            }
          });
        } else {
          mergeFilterParams({
            comparator: (filterDate, cellValue) => {
              const cellAsDate = convertToDate(cellValue);
              if (cellValue == null || cellAsDate < filterDate) {
                return -1;
              }
              if (cellAsDate > filterDate) {
                return 1;
              }
              return 0;
            }
          });
        }
        break;
      }
      case "object": {
        colDef.cellEditorParams = {
          useFormatter: true
        };
        colDef.comparator = (a, b) => {
          const column = this.columnModel.getColDefCol(colId);
          const colDef2 = column?.getColDef();
          if (!column || !colDef2) {
            return 0;
          }
          const valA = a == null ? "" : formatValue({ column, node: null, value: a });
          const valB = b == null ? "" : formatValue({ column, node: null, value: b });
          if (valA === valB)
            return 0;
          return valA > valB ? 1 : -1;
        };
        colDef.keyCreator = formatValue;
        if (usingSetFilter) {
          mergeFilterParams({
            valueFormatter: (params) => {
              const valueFormatted = formatValue(params);
              return _exists(valueFormatted) ? valueFormatted : translate("blanks", "(Blanks)");
            }
          });
        } else {
          colDef.filterValueGetter = (params) => formatValue({
            column: params.column,
            node: params.node,
            value: this.valueService.getValue(params.column, params.node)
          });
        }
        break;
      }
    }
  }
  getDefaultDataTypes() {
    const defaultDateFormatMatcher = (value) => !!value.match("^\\d{4}-\\d{2}-\\d{2}$");
    const translate = this.localeService.getLocaleTextFunc();
    return {
      number: {
        baseDataType: "number",
        // can be empty space with legacy copy
        valueParser: (params) => params.newValue?.trim?.() === "" ? null : Number(params.newValue),
        valueFormatter: (params) => {
          if (params.value == null) {
            return "";
          }
          if (typeof params.value !== "number" || isNaN(params.value)) {
            return translate("invalidNumber", "Invalid Number");
          }
          return String(params.value);
        },
        dataTypeMatcher: (value) => typeof value === "number"
      },
      text: {
        baseDataType: "text",
        valueParser: (params) => params.newValue === "" ? null : _toStringOrNull(params.newValue),
        dataTypeMatcher: (value) => typeof value === "string"
      },
      boolean: {
        baseDataType: "boolean",
        valueParser: (params) => {
          if (params.newValue == null) {
            return params.newValue;
          }
          return params.newValue?.trim?.() === "" ? null : String(params.newValue).toLowerCase() === "true";
        },
        valueFormatter: (params) => params.value == null ? "" : String(params.value),
        dataTypeMatcher: (value) => typeof value === "boolean"
      },
      date: {
        baseDataType: "date",
        valueParser: (params) => _parseDateTimeFromString(params.newValue == null ? null : String(params.newValue)),
        valueFormatter: (params) => {
          if (params.value == null) {
            return "";
          }
          if (!(params.value instanceof Date) || isNaN(params.value.getTime())) {
            return translate("invalidDate", "Invalid Date");
          }
          return _serialiseDate(params.value, false) ?? "";
        },
        dataTypeMatcher: (value) => value instanceof Date
      },
      dateString: {
        baseDataType: "dateString",
        dateParser: (value) => _parseDateTimeFromString(value) ?? void 0,
        dateFormatter: (value) => _serialiseDate(value ?? null, false) ?? void 0,
        valueParser: (params) => defaultDateFormatMatcher(String(params.newValue)) ? params.newValue : null,
        valueFormatter: (params) => defaultDateFormatMatcher(String(params.value)) ? params.value : "",
        dataTypeMatcher: (value) => typeof value === "string" && defaultDateFormatMatcher(value)
      },
      object: {
        baseDataType: "object",
        valueParser: () => null,
        valueFormatter: (params) => _toStringOrNull(params.value) ?? ""
      }
    };
  }
  destroyColumnStateUpdateListeners() {
    this.columnStateUpdateListenerDestroyFuncs.forEach((destroyFunc) => destroyFunc());
    this.columnStateUpdateListenerDestroyFuncs = [];
  }
  destroy() {
    this.dataTypeDefinitions = {};
    this.dataTypeMatchers = {};
    this.formatValueFuncs = {};
    this.columnStateUpdatesPendingInference = {};
    this.destroyColumnStateUpdateListeners();
    super.destroy();
  }
};

// community-modules/core/src/columns/columnModule.ts
var DataTypeModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/data-type",
  beans: [DataTypeService]
};
var ColumnApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/column-api",
  apiFunctions: {
    getColumnDef,
    getColumnDefs,
    sizeColumnsToFit,
    setColumnGroupOpened,
    getColumnGroup,
    getProvidedColumnGroup,
    getDisplayNameForColumn,
    getDisplayNameForColumnGroup,
    getColumn,
    getColumns,
    applyColumnState,
    getColumnState,
    resetColumnState,
    getColumnGroupState,
    setColumnGroupState,
    resetColumnGroupState,
    isPinning,
    isPinningLeft,
    isPinningRight,
    getDisplayedColAfter,
    getDisplayedColBefore,
    setColumnVisible,
    setColumnsVisible,
    setColumnPinned,
    setColumnsPinned,
    getAllGridColumns,
    getDisplayedLeftColumns,
    getDisplayedCenterColumns,
    getDisplayedRightColumns,
    getAllDisplayedColumns,
    getAllDisplayedVirtualColumns,
    moveColumn,
    moveColumnByIndex,
    moveColumns: moveColumns2,
    setColumnWidth,
    setColumnWidths,
    getLeftDisplayedColumnGroups,
    getCenterDisplayedColumnGroups,
    getRightDisplayedColumnGroups,
    getAllDisplayedColumnGroups,
    autoSizeColumn,
    autoSizeColumns,
    autoSizeAllColumns
  }
};

// community-modules/core/src/dragAndDrop/dragApi.ts
function addRowDropZone(beans, params) {
  beans.ctrlsService.getGridBodyCtrl().getRowDragFeature().addRowDropZone(params);
}
function removeRowDropZone(beans, params) {
  const activeDropTarget = beans.dragAndDropService.findExternalZone(params);
  if (activeDropTarget) {
    beans.dragAndDropService.removeDropTarget(activeDropTarget);
  }
}
function getRowDropZoneParams(beans, events) {
  return beans.ctrlsService.getGridBodyCtrl().getRowDragFeature().getRowDropZone(events);
}

// community-modules/core/src/dragAndDrop/dragModule.ts
var DragApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/drag-api",
  apiFunctions: {
    addRowDropZone,
    removeRowDropZone,
    getRowDropZoneParams
  }
};

// community-modules/core/src/pinnedRowModel/pinnedRowApi.ts
function getPinnedTopRowCount(beans) {
  return beans.pinnedRowModel.getPinnedTopRowCount();
}
function getPinnedBottomRowCount(beans) {
  return beans.pinnedRowModel.getPinnedBottomRowCount();
}
function getPinnedTopRow(beans, index) {
  return beans.pinnedRowModel.getPinnedTopRow(index);
}
function getPinnedBottomRow(beans, index) {
  return beans.pinnedRowModel.getPinnedBottomRow(index);
}

// community-modules/core/src/pinnedRowModel/pinnedRowModule.ts
var PinnedRowApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/pinned-row-api",
  apiFunctions: {
    getPinnedTopRowCount,
    getPinnedBottomRowCount,
    getPinnedTopRow,
    getPinnedBottomRow
  }
};

// community-modules/core/src/rendering/overlays/overlayApi.ts
function showLoadingOverlay(beans) {
  beans.overlayService.showLoadingOverlay();
}
function showNoRowsOverlay(beans) {
  beans.overlayService.showNoRowsOverlay();
}
function hideOverlay(beans) {
  beans.overlayService.hideOverlay();
}

// community-modules/core/src/rendering/overlays/overlayModule.ts
var OverlayApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/overlay-api",
  apiFunctions: {
    showLoadingOverlay,
    showNoRowsOverlay,
    hideOverlay
  }
};

// community-modules/core/src/rendering/renderApi.ts
function setGridAriaProperty(beans, property, value) {
  if (!property) {
    return;
  }
  const eGrid = beans.ctrlsService.getGridBodyCtrl().getGui();
  const ariaProperty = `aria-${property}`;
  if (value === null) {
    eGrid.removeAttribute(ariaProperty);
  } else {
    eGrid.setAttribute(ariaProperty, value);
  }
}
function refreshCells(beans, params = {}) {
  beans.frameworkOverrides.wrapIncoming(() => beans.rowRenderer.refreshCells(params));
}
function flashCells(beans, params = {}) {
  const warning = (prop) => _warnOnce(
    `Since v31.1 api.flashCells parameter '${prop}Delay' is deprecated. Please use '${prop}Duration' instead.`
  );
  if (_exists(params.fadeDelay)) {
    warning("fade");
  }
  if (_exists(params.flashDelay)) {
    warning("flash");
  }
  beans.frameworkOverrides.wrapIncoming(() => beans.rowRenderer.flashCells(params));
}
function refreshHeader(beans) {
  beans.frameworkOverrides.wrapIncoming(
    () => beans.ctrlsService.getHeaderRowContainerCtrls().forEach((c) => c.refresh())
  );
}
function isAnimationFrameQueueEmpty(beans) {
  return beans.animationFrameService.isQueueEmpty();
}
function flushAllAnimationFrames(beans) {
  beans.animationFrameService.flushAllFrames();
}
function getSizesForCurrentTheme(beans) {
  return {
    rowHeight: beans.gos.getRowHeightAsNumber(),
    headerHeight: beans.columnModel.getHeaderHeight()
  };
}
function getCellRendererInstances(beans, params = {}) {
  const res = beans.rowRenderer.getCellRendererInstances(params);
  const unwrapped = res.map(_unwrapUserComp);
  return unwrapped;
}

// community-modules/core/src/rendering/renderModule.ts
var RenderApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/render-api",
  apiFunctions: {
    setGridAriaProperty,
    refreshCells,
    flashCells,
    refreshHeader,
    isAnimationFrameQueueEmpty,
    flushAllAnimationFrames,
    getSizesForCurrentTheme,
    getCellRendererInstances
  }
};

// community-modules/core/src/api/cellApi.ts
function expireValueCache(beans) {
  beans.valueCache.expire();
}
function getValue(beans, colKey, rowNode) {
  return getCellValue(beans, { colKey, rowNode });
}
function getCellValue(beans, params) {
  const { colKey, rowNode, useFormatter } = params;
  const column = beans.columnModel.getColDefCol(colKey) ?? beans.columnModel.getCol(colKey);
  if (_missing(column)) {
    return null;
  }
  const value = beans.valueService.getValue(column, rowNode);
  if (useFormatter) {
    const formattedValue = beans.valueService.formatValue(column, rowNode, value);
    return formattedValue ?? _escapeString(value, true);
  }
  return value;
}

// community-modules/core/src/api/coreApi.ts
function getGridId(beans) {
  return beans.context.getGridId();
}
function destroy(beans) {
  beans.gridDestroyService.destroy();
}
function isDestroyed(beans) {
  return beans.gridDestroyService.isDestroyCalled();
}
function getGridOption(beans, key) {
  return beans.gos.get(key);
}
function setGridOption(beans, key, value) {
  updateGridOptions(beans, { [key]: value });
}
function updateGridOptions(beans, options) {
  beans.gos.updateGridOptions({ options });
}

// community-modules/core/src/api/eventApi.ts
function addEventListener(beans, eventType, listener) {
  beans.apiEventService.addEventListener(eventType, listener);
}
function removeEventListener(beans, eventType, listener) {
  beans.apiEventService.removeEventListener(eventType, listener);
}
function addGlobalListener(beans, listener) {
  beans.apiEventService.addGlobalListener(listener);
}
function removeGlobalListener(beans, listener) {
  beans.apiEventService.removeGlobalListener(listener);
}

// community-modules/core/src/api/keyboardNavigationApi.ts
function getFocusedCell(beans) {
  return beans.focusService.getFocusedCell();
}
function clearFocusedCell(beans) {
  return beans.focusService.clearFocusedCell();
}
function setFocusedCell(beans, rowIndex, colKey, rowPinned) {
  beans.focusService.setFocusedCell({ rowIndex, column: colKey, rowPinned, forceBrowserFocus: true });
}
function tabToNextCell(beans, event) {
  return beans.navigationService.tabToNextCell(false, event);
}
function tabToPreviousCell(beans, event) {
  return beans.navigationService.tabToNextCell(true, event);
}
function setFocusedHeader(beans, colKey, floatingFilter = false) {
  const headerPosition = beans.headerNavigationService.getHeaderPositionForColumn(colKey, floatingFilter);
  if (!headerPosition) {
    return;
  }
  beans.focusService.focusHeaderPosition({ headerPosition });
}

// community-modules/core/src/api/menuApi.ts
function showColumnMenuAfterButtonClick(beans, colKey, buttonElement) {
  const column = beans.columnModel.getCol(colKey);
  beans.menuService.showColumnMenu({
    column,
    buttonElement,
    positionBy: "button"
  });
}
function showColumnMenuAfterMouseClick(beans, colKey, mouseEvent) {
  let column = beans.columnModel.getCol(colKey);
  if (!column) {
    column = beans.columnModel.getColDefCol(colKey);
  }
  if (!column) {
    _errorOnce(`column '${colKey}' not found`);
    return;
  }
  beans.menuService.showColumnMenu({
    column,
    mouseEvent,
    positionBy: "mouse"
  });
}
function showColumnMenu(beans, colKey) {
  const column = beans.columnModel.getCol(colKey);
  if (!column) {
    _errorOnce(`column '${colKey}' not found`);
    return;
  }
  beans.menuService.showColumnMenu({
    column,
    positionBy: "auto"
  });
}
function hidePopupMenu(beans) {
  beans.menuService.hidePopupMenu();
}

// community-modules/core/src/api/rowApi.ts
function redrawRows(beans, params = {}) {
  const rowNodes = params ? params.rowNodes : void 0;
  beans.frameworkOverrides.wrapIncoming(() => beans.rowRenderer.redrawRows(rowNodes));
}
function setRowNodeExpanded(beans, rowNode, expanded, expandParents, forceSync) {
  beans.expansionService.setRowNodeExpanded(rowNode, expanded, expandParents, forceSync);
}
function getRowNode(beans, id) {
  return beans.rowModel.getRowNode(id);
}
function addRenderedRowListener(beans, eventName, rowIndex, callback) {
  beans.rowRenderer.addRenderedRowListener(eventName, rowIndex, callback);
}
function getRenderedNodes(beans) {
  return beans.rowRenderer.getRenderedNodes();
}
function forEachNode(beans, callback, includeFooterNodes) {
  beans.rowModel.forEachNode(callback, includeFooterNodes);
}
function getFirstDisplayedRow(beans) {
  return getFirstDisplayedRowIndex(beans);
}
function getFirstDisplayedRowIndex(beans) {
  return beans.rowRenderer.getFirstVirtualRenderedRow();
}
function getLastDisplayedRow(beans) {
  return getLastDisplayedRowIndex(beans);
}
function getLastDisplayedRowIndex(beans) {
  return beans.rowRenderer.getLastVirtualRenderedRow();
}
function getDisplayedRowAtIndex(beans, index) {
  return beans.rowModel.getRow(index);
}
function getDisplayedRowCount(beans) {
  return beans.rowModel.getRowCount();
}
function getModel(beans) {
  return beans.rowModel;
}

// community-modules/core/src/api/rowSelectionApi.ts
function setNodesSelected(beans, params) {
  const allNodesValid = params.nodes.every((node) => {
    if (node.rowPinned) {
      _warnOnce("cannot select pinned rows");
      return false;
    }
    if (node.id === void 0) {
      _warnOnce("cannot select node until id for node is known");
      return false;
    }
    return true;
  });
  if (!allNodesValid) {
    return;
  }
  const { nodes, source, newValue } = params;
  const nodesAsRowNode = nodes;
  beans.selectionService.setNodesSelected({ nodes: nodesAsRowNode, source: source ?? "api", newValue });
}
function selectAll(beans, source = "apiSelectAll") {
  beans.selectionService.selectAllRowNodes({ source });
}
function deselectAll(beans, source = "apiSelectAll") {
  beans.selectionService.deselectAllRowNodes({ source });
}
function selectAllFiltered(beans, source = "apiSelectAllFiltered") {
  beans.selectionService.selectAllRowNodes({ source, justFiltered: true });
}
function deselectAllFiltered(beans, source = "apiSelectAllFiltered") {
  beans.selectionService.deselectAllRowNodes({ source, justFiltered: true });
}
function selectAllOnCurrentPage(beans, source = "apiSelectAllCurrentPage") {
  beans.selectionService.selectAllRowNodes({ source, justCurrentPage: true });
}
function deselectAllOnCurrentPage(beans, source = "apiSelectAllCurrentPage") {
  beans.selectionService.deselectAllRowNodes({ source, justCurrentPage: true });
}
function getSelectedNodes(beans) {
  return beans.selectionService.getSelectedNodes();
}
function getSelectedRows(beans) {
  return beans.selectionService.getSelectedRows();
}

// community-modules/core/src/api/sortApi.ts
function onSortChanged(beans) {
  beans.sortController.onSortChanged("api");
}

// community-modules/core/src/api/apiModule.ts
var CoreApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/core-api",
  apiFunctions: {
    getGridId,
    destroy,
    isDestroyed,
    getGridOption,
    setGridOption,
    updateGridOptions
  }
};
var RowSelectionApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/row-selection-api",
  apiFunctions: {
    setNodesSelected,
    selectAll,
    deselectAll,
    selectAllFiltered,
    deselectAllFiltered,
    selectAllOnCurrentPage,
    deselectAllOnCurrentPage,
    getSelectedNodes,
    getSelectedRows
  }
};
var RowApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/row-api",
  apiFunctions: {
    redrawRows,
    setRowNodeExpanded,
    getRowNode,
    addRenderedRowListener,
    getRenderedNodes,
    forEachNode,
    getFirstDisplayedRow,
    getFirstDisplayedRowIndex,
    getLastDisplayedRow,
    getLastDisplayedRowIndex,
    getDisplayedRowAtIndex,
    getDisplayedRowCount,
    getModel
  }
};
var ScrollApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/scroll-api",
  apiFunctions: {
    getVerticalPixelRange,
    getHorizontalPixelRange,
    ensureColumnVisible,
    ensureIndexVisible,
    ensureNodeVisible
  }
};
var KeyboardNavigationApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/keyboard-navigation-api",
  apiFunctions: {
    getFocusedCell,
    clearFocusedCell,
    setFocusedCell,
    setFocusedHeader,
    tabToNextCell,
    tabToPreviousCell
  }
};
var EventApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/event-api",
  apiFunctions: {
    addEventListener,
    addGlobalListener,
    removeEventListener,
    removeGlobalListener
  }
};
var CellApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/cell-api",
  apiFunctions: {
    expireValueCache,
    getValue,
    getCellValue
  }
};
var CommunityMenuApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/menu-api",
  apiFunctions: {
    showColumnMenuAfterButtonClick,
    showColumnMenuAfterMouseClick,
    showColumnMenu,
    hidePopupMenu
  }
};
var SortApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/sort-api",
  apiFunctions: {
    onSortChanged
  }
};
var CommunityApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/api",
  dependantModules: [
    CoreApiModule,
    PinnedRowApiModule,
    RowSelectionApiModule,
    ColumnApiModule,
    RowApiModule,
    DragApiModule,
    ScrollApiModule,
    OverlayApiModule,
    KeyboardNavigationApiModule,
    EventApiModule,
    RenderApiModule,
    CellApiModule,
    CommunityMenuApiModule,
    SortApiModule
  ]
};

// community-modules/core/src/misc/state/stateApi.ts
function getState(beans) {
  return beans.stateService?.getState() ?? {};
}

// community-modules/core/src/misc/state/stateService.ts
var StateService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "stateService";
    this.suppressEvents = true;
    this.queuedUpdateSources = /* @__PURE__ */ new Set();
    this.dispatchStateUpdateEventDebounced = _debounce(() => this.dispatchQueuedStateUpdateEvents(), 0);
    // If user is doing a manual expand all node by node, we don't want to process one at a time.
    // EVENT_ROW_GROUP_OPENED is already async, so no impact of making the state async here.
    this.onRowGroupOpenedDebounced = _debounce(
      () => this.updateCachedState("rowGroupExpansion", this.getRowGroupExpansionState()),
      0
    );
    // similar to row expansion, want to debounce. However, selection is synchronous, so need to mark as stale in case `getState` is called.
    this.onRowSelectedDebounced = _debounce(() => {
      this.staleStateKeys.delete("rowSelection");
      this.updateCachedState("rowSelection", this.getRowSelectionState());
    }, 0);
    this.staleStateKeys = /* @__PURE__ */ new Set();
  }
  wireBeans(beans) {
    this.filterManager = beans.filterManager;
    this.ctrlsService = beans.ctrlsService;
    this.pivotResultColsService = beans.pivotResultColsService;
    this.focusService = beans.focusService;
    this.columnModel = beans.columnModel;
    this.visibleColsService = beans.visibleColsService;
    this.columnGroupStateService = beans.columnGroupStateService;
    this.columnGetStateService = beans.columnGetStateService;
    this.paginationService = beans.paginationService;
    this.rowModel = beans.rowModel;
    this.selectionService = beans.selectionService;
    this.expansionService = beans.expansionService;
    this.columnAnimationService = beans.columnAnimationService;
    this.columnApplyStateService = beans.columnApplyStateService;
    this.sideBarService = beans.sideBarService;
    this.rangeService = beans.rangeService;
  }
  postConstruct() {
    this.isClientSideRowModel = this.rowModel.getType() === "clientSide";
    this.cachedState = this.gos.get("initialState") ?? {};
    this.ctrlsService.whenReady(() => this.suppressEventsAndDispatchInitEvent(() => this.setupStateOnGridReady()));
    const [newColumnsLoadedDestroyFunc, rowCountReadyDestroyFunc, firstDataRenderedDestroyFunc] = this.addManagedEventListeners({
      newColumnsLoaded: ({ source }) => {
        if (source === "gridInitializing") {
          newColumnsLoadedDestroyFunc();
          this.suppressEventsAndDispatchInitEvent(() => this.setupStateOnColumnsInitialised());
        }
      },
      rowCountReady: () => {
        rowCountReadyDestroyFunc?.();
        this.suppressEventsAndDispatchInitEvent(() => this.setupStateOnRowCountReady());
      },
      firstDataRendered: () => {
        firstDataRenderedDestroyFunc?.();
        this.suppressEventsAndDispatchInitEvent(() => this.setupStateOnFirstDataRendered());
      }
    });
  }
  getState() {
    if (this.staleStateKeys.size) {
      this.refreshStaleState();
    }
    return this.cachedState;
  }
  setupStateOnGridReady() {
    this.updateCachedState("sideBar", this.getSideBarState());
    const stateUpdater = () => this.updateCachedState("sideBar", this.getSideBarState());
    this.addManagedEventListeners({
      toolPanelVisibleChanged: stateUpdater,
      sideBarUpdated: stateUpdater
    });
  }
  setupStateOnColumnsInitialised() {
    const initialState = this.gos.get("initialState") ?? {};
    this.setColumnState(initialState);
    this.setColumnGroupState(initialState);
    this.updateColumnState([
      "aggregation",
      "columnOrder",
      "columnPinning",
      "columnSizing",
      "columnVisibility",
      "pivot",
      "pivot",
      "rowGroup",
      "sort"
    ]);
    this.updateCachedState("columnGroup", this.getColumnGroupState());
    this.addManagedEventListeners({
      columnValueChanged: () => this.updateColumnState(["aggregation"]),
      columnMoved: () => this.updateColumnState(["columnOrder"]),
      columnPinned: () => this.updateColumnState(["columnPinning"]),
      columnResized: () => this.updateColumnState(["columnSizing"]),
      columnVisible: () => this.updateColumnState(["columnVisibility"]),
      columnPivotChanged: () => this.updateColumnState(["pivot"]),
      columnPivotModeChanged: () => this.updateColumnState(["pivot"]),
      columnRowGroupChanged: () => this.updateColumnState(["rowGroup"]),
      sortChanged: () => this.updateColumnState(["sort"]),
      newColumnsLoaded: () => this.updateColumnState([
        "aggregation",
        "columnOrder",
        "columnPinning",
        "columnSizing",
        "columnVisibility",
        "pivot",
        "rowGroup",
        "sort"
      ]),
      columnGroupOpened: () => this.updateCachedState("columnGroup", this.getColumnGroupState())
    });
  }
  setupStateOnRowCountReady() {
    const {
      filter: filterState,
      rowGroupExpansion: rowGroupExpansionState,
      rowSelection: rowSelectionState,
      pagination: paginationState
    } = this.gos.get("initialState") ?? {};
    const advancedFilterModel = this.gos.get("advancedFilterModel");
    if (filterState || advancedFilterModel) {
      this.setFilterState(filterState, advancedFilterModel);
    }
    if (rowGroupExpansionState) {
      this.setRowGroupExpansionState(rowGroupExpansionState);
    }
    if (rowSelectionState) {
      this.setRowSelectionState(rowSelectionState);
    }
    if (paginationState) {
      this.setPaginationState(paginationState);
    }
    this.updateCachedState("filter", this.getFilterState());
    this.updateCachedState("rowGroupExpansion", this.getRowGroupExpansionState());
    this.updateCachedState("rowSelection", this.getRowSelectionState());
    this.updateCachedState("pagination", this.getPaginationState());
    this.addManagedEventListeners({
      filterChanged: () => this.updateCachedState("filter", this.getFilterState()),
      rowGroupOpened: () => this.onRowGroupOpenedDebounced(),
      expandOrCollapseAll: () => this.updateCachedState("rowGroupExpansion", this.getRowGroupExpansionState()),
      selectionChanged: () => {
        this.staleStateKeys.add("rowSelection");
        this.onRowSelectedDebounced();
      },
      paginationChanged: (event) => {
        if (event.newPage || event.newPageSize) {
          this.updateCachedState("pagination", this.getPaginationState());
        }
      }
    });
  }
  setupStateOnFirstDataRendered() {
    const {
      scroll: scrollState,
      rangeSelection: rangeSelectionState,
      focusedCell: focusedCellState,
      columnOrder: columnOrderState
    } = this.gos.get("initialState") ?? {};
    if (focusedCellState) {
      this.setFocusedCellState(focusedCellState);
    }
    if (rangeSelectionState) {
      this.setRangeSelectionState(rangeSelectionState);
    }
    if (scrollState) {
      this.setScrollState(scrollState);
    }
    this.setColumnPivotState(!!columnOrderState?.orderedColIds);
    this.updateCachedState("sideBar", this.getSideBarState());
    this.updateCachedState("focusedCell", this.getFocusedCellState());
    this.updateCachedState("rangeSelection", this.getRangeSelectionState());
    this.updateCachedState("scroll", this.getScrollState());
    this.addManagedEventListeners({
      cellFocused: () => this.updateCachedState("focusedCell", this.getFocusedCellState()),
      rangeSelectionChanged: (event) => {
        if (event.finished) {
          this.updateCachedState("rangeSelection", this.getRangeSelectionState());
        }
      },
      bodyScrollEnd: () => this.updateCachedState("scroll", this.getScrollState())
    });
  }
  getColumnState() {
    const pivotMode = this.columnModel.isPivotMode();
    const sortColumns = [];
    const groupColIds = [];
    const aggregationColumns = [];
    const pivotColIds = [];
    const leftColIds = [];
    const rightColIds = [];
    const hiddenColIds = [];
    const columnSizes = [];
    const columns = [];
    const columnState = this.columnGetStateService.getColumnState();
    for (let i = 0; i < columnState.length; i++) {
      const {
        colId,
        sort,
        sortIndex,
        rowGroup,
        rowGroupIndex,
        aggFunc,
        pivot,
        pivotIndex,
        pinned,
        hide,
        width,
        flex
      } = columnState[i];
      columns.push(colId);
      if (sort) {
        sortColumns[sortIndex ?? 0] = { colId, sort };
      }
      if (rowGroup) {
        groupColIds[rowGroupIndex ?? 0] = colId;
      }
      if (typeof aggFunc === "string") {
        aggregationColumns.push({ colId, aggFunc });
      }
      if (pivot) {
        pivotColIds[pivotIndex ?? 0] = colId;
      }
      if (pinned) {
        (pinned === "right" ? rightColIds : leftColIds).push(colId);
      }
      if (hide) {
        hiddenColIds.push(colId);
      }
      if (flex || width) {
        columnSizes.push({ colId, flex: flex ?? void 0, width });
      }
    }
    return {
      sort: sortColumns.length ? { sortModel: sortColumns } : void 0,
      rowGroup: groupColIds.length ? { groupColIds } : void 0,
      aggregation: aggregationColumns.length ? { aggregationModel: aggregationColumns } : void 0,
      pivot: pivotColIds.length || pivotMode ? { pivotMode, pivotColIds } : void 0,
      columnPinning: leftColIds.length || rightColIds.length ? { leftColIds, rightColIds } : void 0,
      columnVisibility: hiddenColIds.length ? { hiddenColIds } : void 0,
      columnSizing: columnSizes.length ? { columnSizingModel: columnSizes } : void 0,
      columnOrder: columns.length ? { orderedColIds: columns } : void 0
    };
  }
  setColumnState(initialState) {
    const {
      sort: sortState,
      rowGroup: groupState,
      aggregation: aggregationState,
      pivot: pivotState,
      columnPinning: columnPinningState,
      columnVisibility: columnVisibilityState,
      columnSizing: columnSizingState,
      columnOrder: columnOrderState
    } = initialState;
    const columnStateMap = {};
    const getColumnState2 = (colId) => {
      let columnState = columnStateMap[colId];
      if (columnState) {
        return columnState;
      }
      columnState = { colId };
      columnStateMap[colId] = columnState;
      return columnState;
    };
    if (sortState) {
      sortState.sortModel.forEach(({ colId, sort }, sortIndex) => {
        const columnState = getColumnState2(colId);
        columnState.sort = sort;
        columnState.sortIndex = sortIndex;
      });
    }
    if (groupState) {
      groupState.groupColIds.forEach((colId, rowGroupIndex) => {
        const columnState = getColumnState2(colId);
        columnState.rowGroup = true;
        columnState.rowGroupIndex = rowGroupIndex;
      });
    }
    if (aggregationState) {
      aggregationState.aggregationModel.forEach(({ colId, aggFunc }) => {
        getColumnState2(colId).aggFunc = aggFunc;
      });
    }
    if (pivotState) {
      pivotState.pivotColIds.forEach((colId, pivotIndex) => {
        const columnState = getColumnState2(colId);
        columnState.pivot = true;
        columnState.pivotIndex = pivotIndex;
      });
      this.gos.updateGridOptions({
        options: { pivotMode: pivotState.pivotMode },
        source: "gridInitializing"
      });
    }
    if (columnPinningState) {
      columnPinningState.leftColIds.forEach((colId) => {
        getColumnState2(colId).pinned = "left";
      });
      columnPinningState.rightColIds.forEach((colId) => {
        getColumnState2(colId).pinned = "right";
      });
    }
    if (columnVisibilityState) {
      columnVisibilityState.hiddenColIds.forEach((colId) => {
        getColumnState2(colId).hide = true;
      });
    }
    if (columnSizingState) {
      columnSizingState.columnSizingModel.forEach(({ colId, flex, width }) => {
        const columnState = getColumnState2(colId);
        columnState.flex = flex ?? null;
        columnState.width = width;
      });
    }
    const columns = columnOrderState?.orderedColIds;
    const applyOrder = !!columns?.length;
    const columnStates = applyOrder ? columns.map((colId) => getColumnState2(colId)) : Object.values(columnStateMap);
    if (columnStates.length) {
      this.columnStates = columnStates;
      const defaultState = {
        sort: null,
        sortIndex: null,
        rowGroup: null,
        rowGroupIndex: null,
        aggFunc: null,
        pivot: null,
        pivotIndex: null,
        pinned: null,
        hide: null,
        flex: null
      };
      this.columnApplyStateService.applyColumnState(
        {
          state: columnStates,
          applyOrder,
          defaultState
        },
        "gridInitializing"
      );
    }
  }
  setColumnPivotState(applyOrder) {
    const columnStates = this.columnStates;
    this.columnStates = void 0;
    const columnGroupStates = this.columnGroupStates;
    this.columnGroupStates = void 0;
    if (!this.pivotResultColsService.isPivotResultColsPresent()) {
      return;
    }
    if (columnStates) {
      const secondaryColumnStates = [];
      for (const columnState of columnStates) {
        if (this.pivotResultColsService.getPivotResultCol(columnState.colId)) {
          secondaryColumnStates.push(columnState);
        }
      }
      this.columnApplyStateService.applyColumnState(
        {
          state: secondaryColumnStates,
          applyOrder
        },
        "gridInitializing"
      );
    }
    if (columnGroupStates) {
      this.columnGroupStateService.setColumnGroupState(columnGroupStates, "gridInitializing");
    }
  }
  getColumnGroupState() {
    const columnGroupState = this.columnGroupStateService.getColumnGroupState();
    const openColumnGroups = [];
    columnGroupState.forEach(({ groupId, open }) => {
      if (open) {
        openColumnGroups.push(groupId);
      }
    });
    return openColumnGroups.length ? { openColumnGroupIds: openColumnGroups } : void 0;
  }
  setColumnGroupState(initialState) {
    if (!Object.prototype.hasOwnProperty.call(initialState, "columnGroup")) {
      return;
    }
    const openColumnGroups = new Set(initialState.columnGroup?.openColumnGroupIds);
    const existingColumnGroupState = this.columnGroupStateService.getColumnGroupState();
    const stateItems = existingColumnGroupState.map(({ groupId }) => {
      const open = openColumnGroups.has(groupId);
      if (open) {
        openColumnGroups.delete(groupId);
      }
      return {
        groupId,
        open
      };
    });
    openColumnGroups.forEach((groupId) => {
      stateItems.push({
        groupId,
        open: true
      });
    });
    if (stateItems.length) {
      this.columnGroupStates = stateItems;
    }
    this.columnGroupStateService.setColumnGroupState(stateItems, "gridInitializing");
  }
  getFilterState() {
    let filterModel = this.filterManager?.getFilterModel();
    if (filterModel && Object.keys(filterModel).length === 0) {
      filterModel = void 0;
    }
    const advancedFilterModel = this.filterManager?.getAdvancedFilterModel() ?? void 0;
    return filterModel || advancedFilterModel ? { filterModel, advancedFilterModel } : void 0;
  }
  setFilterState(filterState, gridOptionAdvancedFilterModel) {
    const { filterModel, advancedFilterModel } = filterState ?? {
      advancedFilterModel: gridOptionAdvancedFilterModel
    };
    if (filterModel) {
      this.filterManager?.setFilterModel(filterModel, "columnFilter");
    }
    if (advancedFilterModel) {
      this.filterManager?.setAdvancedFilterModel(advancedFilterModel);
    }
  }
  getRangeSelectionState() {
    const cellRanges = this.rangeService?.getCellRanges().map((cellRange) => {
      const { id, type, startRow, endRow, columns, startColumn } = cellRange;
      return {
        id,
        type,
        startRow,
        endRow,
        colIds: columns.map((column) => column.getColId()),
        startColId: startColumn.getColId()
      };
    });
    return cellRanges?.length ? { cellRanges } : void 0;
  }
  setRangeSelectionState(rangeSelectionState) {
    if (!this.gos.get("enableRangeSelection") || !this.rangeService) {
      return;
    }
    const cellRanges = [];
    rangeSelectionState.cellRanges.forEach((cellRange) => {
      const columns = [];
      cellRange.colIds.forEach((colId) => {
        const column = this.columnModel.getCol(colId);
        if (column) {
          columns.push(column);
        }
      });
      if (!columns.length) {
        return;
      }
      let startColumn = this.columnModel.getCol(cellRange.startColId);
      if (!startColumn) {
        const allColumns = this.visibleColsService.getAllCols();
        const columnSet = new Set(columns);
        startColumn = allColumns.find((column) => columnSet.has(column));
      }
      cellRanges.push({
        ...cellRange,
        columns,
        startColumn
      });
    });
    this.rangeService.setCellRanges(cellRanges);
  }
  getScrollState() {
    if (!this.isClientSideRowModel) {
      return void 0;
    }
    const scrollFeature = this.ctrlsService.getGridBodyCtrl()?.getScrollFeature();
    const { left } = scrollFeature?.getHScrollPosition() ?? { left: 0 };
    const { top } = scrollFeature?.getVScrollPosition() ?? { top: 0 };
    return top || left ? {
      top,
      left
    } : void 0;
  }
  setScrollState(scrollState) {
    if (!this.isClientSideRowModel) {
      return;
    }
    const { top, left } = scrollState;
    this.ctrlsService.getGridBodyCtrl()?.getScrollFeature().setScrollPosition(top, left);
  }
  getSideBarState() {
    return this.sideBarService?.getSideBarComp()?.getState();
  }
  getFocusedCellState() {
    if (!this.isClientSideRowModel) {
      return void 0;
    }
    const focusedCell = this.focusService.getFocusedCell();
    if (focusedCell) {
      const { column, rowIndex, rowPinned } = focusedCell;
      return {
        colId: column.getColId(),
        rowIndex,
        rowPinned
      };
    }
    return void 0;
  }
  setFocusedCellState(focusedCellState) {
    if (!this.isClientSideRowModel) {
      return;
    }
    const { colId, rowIndex, rowPinned } = focusedCellState;
    this.focusService.setFocusedCell({
      column: this.columnModel.getCol(colId),
      rowIndex,
      rowPinned,
      forceBrowserFocus: true,
      preventScrollOnBrowserFocus: true
    });
  }
  getPaginationState() {
    if (!this.paginationService) {
      return void 0;
    }
    const page = this.paginationService.getCurrentPage();
    const pageSize = !this.gos.get("paginationAutoPageSize") ? this.paginationService.getPageSize() : void 0;
    if (!page && !pageSize) {
      return;
    }
    return { page, pageSize };
  }
  setPaginationState(paginationState) {
    if (!this.paginationService) {
      return;
    }
    if (paginationState.pageSize && !this.gos.get("paginationAutoPageSize")) {
      this.paginationService.setPageSize(paginationState.pageSize, "initialState");
    }
    if (typeof paginationState.page === "number") {
      this.paginationService.setPage(paginationState.page);
    }
  }
  getRowSelectionState() {
    const selectionState = this.selectionService.getSelectionState();
    const noSelections = !selectionState || !Array.isArray(selectionState) && (selectionState.selectAll === false || selectionState.selectAllChildren === false) && !selectionState?.toggledNodes?.length;
    return noSelections ? void 0 : selectionState;
  }
  setRowSelectionState(rowSelectionState) {
    this.selectionService.setSelectionState(rowSelectionState, "gridInitializing");
  }
  getRowGroupExpansionState() {
    const expandedRowGroups = this.expansionService.getExpandedRows();
    return expandedRowGroups.length ? {
      expandedRowGroupIds: expandedRowGroups
    } : void 0;
  }
  setRowGroupExpansionState(rowGroupExpansionState) {
    this.expansionService.expandRows(rowGroupExpansionState.expandedRowGroupIds);
  }
  updateColumnState(features) {
    const newColumnState = this.getColumnState();
    let hasChanged = false;
    Object.entries(newColumnState).forEach(([key, value]) => {
      if (!_jsonEquals(value, this.cachedState[key])) {
        hasChanged = true;
      }
    });
    this.cachedState = {
      ...this.cachedState,
      ...newColumnState
    };
    if (hasChanged) {
      this.dispatchStateUpdateEvent(features);
    }
  }
  updateCachedState(key, value) {
    const existingValue = this.cachedState[key];
    this.setCachedStateValue(key, value);
    if (!_jsonEquals(value, existingValue)) {
      this.dispatchStateUpdateEvent([key]);
    }
  }
  setCachedStateValue(key, value) {
    this.cachedState = {
      ...this.cachedState,
      [key]: value
    };
  }
  refreshStaleState() {
    this.staleStateKeys.forEach((key) => {
      switch (key) {
        case "rowSelection":
          this.setCachedStateValue(key, this.getRowSelectionState());
          break;
      }
    });
    this.staleStateKeys.clear();
  }
  dispatchStateUpdateEvent(sources) {
    if (this.suppressEvents) {
      return;
    }
    sources.forEach((source) => this.queuedUpdateSources.add(source));
    this.dispatchStateUpdateEventDebounced();
  }
  dispatchQueuedStateUpdateEvents() {
    const sources = Array.from(this.queuedUpdateSources);
    this.queuedUpdateSources.clear();
    const event = {
      type: "stateUpdated",
      sources,
      state: this.cachedState
    };
    this.eventService.dispatchEvent(event);
  }
  suppressEventsAndDispatchInitEvent(updateFunc) {
    this.suppressEvents = true;
    this.columnAnimationService.setSuppressAnimation(true);
    updateFunc();
    setTimeout(() => {
      this.suppressEvents = false;
      this.queuedUpdateSources.clear();
      if (!this.isAlive()) {
        return;
      }
      this.columnAnimationService.setSuppressAnimation(false);
      this.dispatchStateUpdateEvent(["gridInitializing"]);
    });
  }
};

// community-modules/core/src/misc/state/stateModule.ts
var StateCoreModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/state-core",
  beans: [StateService]
};
var StateApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/state-api",
  apiFunctions: {
    getState
  },
  dependantModules: [StateCoreModule]
};
var StateModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/state",
  dependantModules: [StateCoreModule, StateApiModule]
};

// community-modules/core/src/pagination/paginationApi.ts
function paginationIsLastPageFound(beans) {
  return beans.rowModel.isLastRowIndexKnown();
}
function paginationGetPageSize(beans) {
  return beans.paginationService?.getPageSize() ?? 100;
}
function paginationGetCurrentPage(beans) {
  return beans.paginationService?.getCurrentPage() ?? 0;
}
function paginationGetTotalPages(beans) {
  return beans.paginationService?.getTotalPages() ?? 1;
}
function paginationGetRowCount(beans) {
  return beans.paginationService ? beans.paginationService.getMasterRowCount() : beans.rowModel.getRowCount();
}
function paginationGoToNextPage(beans) {
  beans.paginationService?.goToNextPage();
}
function paginationGoToPreviousPage(beans) {
  beans.paginationService?.goToPreviousPage();
}
function paginationGoToFirstPage(beans) {
  beans.paginationService?.goToFirstPage();
}
function paginationGoToLastPage(beans) {
  beans.paginationService?.goToLastPage();
}
function paginationGoToPage(beans, page) {
  beans.paginationService?.goToPage(page);
}

// community-modules/core/src/pagination/paginationAutoPageSizeService.ts
var PaginationAutoPageSizeService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "paginationAutoPageSizeService";
  }
  wireBeans(beans) {
    this.ctrlsService = beans.ctrlsService;
    this.paginationService = beans.paginationService;
  }
  postConstruct() {
    this.ctrlsService.whenReady((p) => {
      this.centerRowsCtrl = p.center;
      const listener = this.checkPageSize.bind(this);
      this.addManagedEventListeners({
        bodyHeightChanged: listener,
        scrollVisibilityChanged: listener
      });
      this.addManagedPropertyListener("paginationAutoPageSize", this.onPaginationAutoSizeChanged.bind(this));
      this.checkPageSize();
    });
  }
  notActive() {
    return !this.gos.get("paginationAutoPageSize") || this.centerRowsCtrl == null;
  }
  onPaginationAutoSizeChanged() {
    if (this.notActive()) {
      this.paginationService.unsetAutoCalculatedPageSize();
    } else {
      this.checkPageSize();
    }
  }
  checkPageSize() {
    if (this.notActive()) {
      return;
    }
    const bodyHeight = this.centerRowsCtrl.getViewportSizeFeature().getBodyHeight();
    if (bodyHeight > 0) {
      const update = () => {
        const rowHeight = Math.max(this.gos.getRowHeightAsNumber(), 1);
        const newPageSize = Math.floor(bodyHeight / rowHeight);
        this.paginationService.setPageSize(newPageSize, "autoCalculated");
      };
      if (!this.isBodyRendered) {
        update();
        this.isBodyRendered = true;
      } else {
        _debounce(() => update(), 50)();
      }
    } else {
      this.isBodyRendered = false;
    }
  }
};

// community-modules/core/src/utils/focus.ts
function _addFocusableContainerListener(comp, eGui, focusService) {
  comp.addManagedElementListeners(eGui, {
    keydown: (e) => {
      if (!e.defaultPrevented && e.key === KeyCode.TAB) {
        const backwards = e.shiftKey;
        if (!focusService.findNextFocusableElement(eGui, false, backwards)) {
          if (focusService.focusNextGridCoreContainer(backwards)) {
            e.preventDefault();
          }
        }
      }
    }
  });
}

// community-modules/core/src/pagination/pageSizeSelector/pageSizeSelectorComp.ts
var PageSizeSelectorComp = class extends Component {
  constructor() {
    super(
      /* html */
      `<span class="ag-paging-page-size"></span>`
    );
    this.hasEmptyOption = false;
    this.handlePageSizeItemSelected = () => {
      if (!this.selectPageSizeComp) {
        return;
      }
      const newValue = this.selectPageSizeComp.getValue();
      if (!newValue) {
        return;
      }
      const paginationPageSize = Number(newValue);
      if (isNaN(paginationPageSize) || paginationPageSize < 1 || paginationPageSize === this.paginationService.getPageSize()) {
        return;
      }
      this.paginationService.setPageSize(paginationPageSize, "pageSizeSelector");
      if (this.hasEmptyOption) {
        this.toggleSelectDisplay(true);
      }
      this.selectPageSizeComp.getFocusableElement().focus();
    };
  }
  wireBeans(beans) {
    this.paginationService = beans.paginationService;
  }
  postConstruct() {
    this.addManagedPropertyListener("paginationPageSizeSelector", () => {
      this.onPageSizeSelectorValuesChange();
    });
    this.addManagedEventListeners({ paginationChanged: (event) => this.handlePaginationChanged(event) });
  }
  handlePaginationChanged(paginationChangedEvent) {
    if (!this.selectPageSizeComp || !paginationChangedEvent?.newPageSize) {
      return;
    }
    const paginationPageSize = this.paginationService.getPageSize();
    if (this.getPageSizeSelectorValues().includes(paginationPageSize)) {
      this.selectPageSizeComp.setValue(paginationPageSize.toString());
    } else {
      if (this.hasEmptyOption) {
        this.selectPageSizeComp.setValue("");
      } else {
        this.toggleSelectDisplay(true);
      }
    }
  }
  toggleSelectDisplay(show) {
    if (this.selectPageSizeComp) {
      this.reset();
    }
    if (!show) {
      return;
    }
    this.reloadPageSizesSelector();
    if (!this.selectPageSizeComp) {
      return;
    }
    this.appendChild(this.selectPageSizeComp);
  }
  reset() {
    _clearElement(this.getGui());
    if (!this.selectPageSizeComp) {
      return;
    }
    this.selectPageSizeComp = this.destroyBean(this.selectPageSizeComp);
  }
  onPageSizeSelectorValuesChange() {
    if (!this.selectPageSizeComp) {
      return;
    }
    if (this.shouldShowPageSizeSelector()) {
      this.reloadPageSizesSelector();
    }
  }
  shouldShowPageSizeSelector() {
    return this.gos.get("pagination") && !this.gos.get("suppressPaginationPanel") && !this.gos.get("paginationAutoPageSize") && this.gos.get("paginationPageSizeSelector") !== false;
  }
  reloadPageSizesSelector() {
    const pageSizeOptions = this.getPageSizeSelectorValues();
    const paginationPageSizeOption = this.paginationService.getPageSize();
    const shouldAddAndSelectEmptyOption = !paginationPageSizeOption || !pageSizeOptions.includes(paginationPageSizeOption);
    if (shouldAddAndSelectEmptyOption) {
      pageSizeOptions.unshift("");
      _warnOnce(
        `The paginationPageSize grid option is set to a value that is not in the list of page size options.
                Please make sure that the paginationPageSize grid option is set to one of the values in the 
                paginationPageSizeSelector array, or set the paginationPageSizeSelector to false to hide the page size selector.`
      );
    }
    if (this.selectPageSizeComp) {
      this.selectPageSizeComp = this.destroyBean(this.selectPageSizeComp);
    }
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const localisedLabel = localeTextFunc("pageSizeSelectorLabel", "Page Size:");
    const options = pageSizeOptions.map((value) => ({
      value: String(value),
      text: String(value)
    }));
    const localisedAriaLabel = localeTextFunc("ariaPageSizeSelectorLabel", "Page Size");
    this.selectPageSizeComp = this.createManagedBean(new AgSelect()).addOptions(options).setValue(String(shouldAddAndSelectEmptyOption ? "" : paginationPageSizeOption)).setAriaLabel(localisedAriaLabel).setLabel(localisedLabel).onValueChange(() => this.handlePageSizeItemSelected());
    this.hasEmptyOption = shouldAddAndSelectEmptyOption;
  }
  getPageSizeSelectorValues() {
    const defaultValues = [20, 50, 100];
    const paginationPageSizeSelectorValues = this.gos.get("paginationPageSizeSelector");
    if (!Array.isArray(paginationPageSizeSelectorValues) || !this.validateValues(paginationPageSizeSelectorValues)) {
      return defaultValues;
    }
    return [...paginationPageSizeSelectorValues].sort((a, b) => a - b);
  }
  validateValues(values) {
    if (!values.length) {
      _warnOnce(
        `The paginationPageSizeSelector grid option is an empty array. This is most likely a mistake.
                If you want to hide the page size selector, please set the paginationPageSizeSelector to false.`
      );
      return false;
    }
    for (let i = 0; i < values.length; i++) {
      const value = values[i];
      const isNumber = typeof value === "number";
      const isPositive = value > 0;
      if (!isNumber) {
        _warnOnce(
          `The paginationPageSizeSelector grid option contains a non-numeric value.
                    Please make sure that all values in the paginationPageSizeSelector array are numbers.`
        );
        return false;
      }
      if (!isPositive) {
        _warnOnce(
          `The paginationPageSizeSelector grid option contains a negative number or zero.
                    Please make sure that all values in the paginationPageSizeSelector array are positive.`
        );
        return false;
      }
    }
    return true;
  }
  destroy() {
    this.toggleSelectDisplay(false);
    super.destroy();
  }
};
var PageSizeSelectorSelector = {
  selector: "AG-PAGE-SIZE-SELECTOR",
  component: PageSizeSelectorComp
};

// community-modules/core/src/pagination/paginationComp.ts
var PaginationComp = class extends TabGuardComp {
  constructor() {
    super();
    this.btFirst = RefPlaceholder;
    this.btPrevious = RefPlaceholder;
    this.btNext = RefPlaceholder;
    this.btLast = RefPlaceholder;
    this.lbRecordCount = RefPlaceholder;
    this.lbFirstRowOnPage = RefPlaceholder;
    this.lbLastRowOnPage = RefPlaceholder;
    this.lbCurrent = RefPlaceholder;
    this.lbTotal = RefPlaceholder;
    this.pageSizeComp = RefPlaceholder;
    this.previousAndFirstButtonsDisabled = false;
    this.nextButtonDisabled = false;
    this.lastButtonDisabled = false;
    this.areListenersSetup = false;
    this.allowFocusInnerElement = false;
  }
  wireBeans(beans) {
    this.rowNodeBlockLoader = beans.rowNodeBlockLoader;
    this.rowModel = beans.rowModel;
    this.paginationService = beans.paginationService;
    this.focusService = beans.focusService;
  }
  postConstruct() {
    const isRtl = this.gos.get("enableRtl");
    this.setTemplate(this.getTemplate(), [PageSizeSelectorSelector]);
    const { btFirst, btPrevious, btNext, btLast } = this;
    this.activateTabIndex([btFirst, btPrevious, btNext, btLast]);
    btFirst.insertAdjacentElement("afterbegin", _createIconNoSpan(isRtl ? "last" : "first", this.gos));
    btPrevious.insertAdjacentElement("afterbegin", _createIconNoSpan(isRtl ? "next" : "previous", this.gos));
    btNext.insertAdjacentElement("afterbegin", _createIconNoSpan(isRtl ? "previous" : "next", this.gos));
    btLast.insertAdjacentElement("afterbegin", _createIconNoSpan(isRtl ? "first" : "last", this.gos));
    this.addManagedPropertyListener("pagination", this.onPaginationChanged.bind(this));
    this.addManagedPropertyListener("suppressPaginationPanel", this.onPaginationChanged.bind(this));
    this.addManagedPropertyListeners(
      ["paginationPageSizeSelector", "paginationAutoPageSize", "suppressPaginationPanel"],
      () => this.onPageSizeRelatedOptionsChange()
    );
    this.pageSizeComp.toggleSelectDisplay(this.pageSizeComp.shouldShowPageSizeSelector());
    this.initialiseTabGuard({
      // prevent tab guard default logic
      onTabKeyDown: () => {
      },
      focusInnerElement: (fromBottom) => {
        if (this.allowFocusInnerElement) {
          this.tabGuardFeature.getTabGuardCtrl().focusInnerElement(fromBottom);
        } else {
          this.focusService.focusGridInnerElement(fromBottom);
        }
      },
      forceFocusOutWhenTabGuardsAreEmpty: true
    });
    this.onPaginationChanged();
  }
  setAllowFocus(allowFocus) {
    this.allowFocusInnerElement = allowFocus;
  }
  onPaginationChanged() {
    const isPaging = this.gos.get("pagination");
    const paginationPanelEnabled = isPaging && !this.gos.get("suppressPaginationPanel");
    this.setDisplayed(paginationPanelEnabled);
    if (!paginationPanelEnabled) {
      return;
    }
    this.setupListeners();
    this.enableOrDisableButtons();
    this.updateRowLabels();
    this.setCurrentPageLabel();
    this.setTotalLabels();
    this.onPageSizeRelatedOptionsChange();
  }
  onPageSizeRelatedOptionsChange() {
    this.pageSizeComp.toggleSelectDisplay(this.pageSizeComp.shouldShowPageSizeSelector());
  }
  setupListeners() {
    if (!this.areListenersSetup) {
      this.addManagedEventListeners({ paginationChanged: this.onPaginationChanged.bind(this) });
      [
        { el: this.btFirst, fn: this.onBtFirst.bind(this) },
        { el: this.btPrevious, fn: this.onBtPrevious.bind(this) },
        { el: this.btNext, fn: this.onBtNext.bind(this) },
        { el: this.btLast, fn: this.onBtLast.bind(this) }
      ].forEach((item) => {
        const { el, fn } = item;
        this.addManagedListeners(el, {
          click: fn,
          keydown: (e) => {
            if (e.key === KeyCode.ENTER || e.key === KeyCode.SPACE) {
              e.preventDefault();
              fn();
            }
          }
        });
      });
      _addFocusableContainerListener(this, this.getGui(), this.focusService);
      this.areListenersSetup = true;
    }
  }
  onBtFirst() {
    if (!this.previousAndFirstButtonsDisabled) {
      this.paginationService.goToFirstPage();
    }
  }
  setCurrentPageLabel() {
    const pagesExist = this.paginationService.getTotalPages() > 0;
    const currentPage = this.paginationService.getCurrentPage();
    const toDisplay = pagesExist ? currentPage + 1 : 0;
    this.lbCurrent.textContent = this.formatNumber(toDisplay);
  }
  formatNumber(value) {
    const userFunc = this.gos.getCallback("paginationNumberFormatter");
    if (userFunc) {
      const params = { value };
      return userFunc(params);
    }
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const thousandSeparator = localeTextFunc("thousandSeparator", ",");
    const decimalSeparator = localeTextFunc("decimalSeparator", ".");
    return _formatNumberCommas(value, thousandSeparator, decimalSeparator);
  }
  getTemplate() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const strPage = localeTextFunc("page", "Page");
    const strTo = localeTextFunc("to", "to");
    const strOf = localeTextFunc("of", "of");
    const strFirst = localeTextFunc("firstPage", "First Page");
    const strPrevious = localeTextFunc("previousPage", "Previous Page");
    const strNext = localeTextFunc("nextPage", "Next Page");
    const strLast = localeTextFunc("lastPage", "Last Page");
    const compId = this.getCompId();
    return (
      /* html */
      `<div class="ag-paging-panel ag-unselectable" id="ag-${compId}">
                <ag-page-size-selector data-ref="pageSizeComp"></ag-page-size-selector>
                <span class="ag-paging-row-summary-panel" role="status">
                    <span id="ag-${compId}-first-row" data-ref="lbFirstRowOnPage" class="ag-paging-row-summary-panel-number"></span>
                    <span id="ag-${compId}-to">${strTo}</span>
                    <span id="ag-${compId}-last-row" data-ref="lbLastRowOnPage" class="ag-paging-row-summary-panel-number"></span>
                    <span id="ag-${compId}-of">${strOf}</span>
                    <span id="ag-${compId}-row-count" data-ref="lbRecordCount" class="ag-paging-row-summary-panel-number"></span>
                </span>
                <span class="ag-paging-page-summary-panel" role="presentation">
                    <div data-ref="btFirst" class="ag-button ag-paging-button" role="button" aria-label="${strFirst}"></div>
                    <div data-ref="btPrevious" class="ag-button ag-paging-button" role="button" aria-label="${strPrevious}"></div>
                    <span class="ag-paging-description" role="status">
                        <span id="ag-${compId}-start-page">${strPage}</span>
                        <span id="ag-${compId}-start-page-number" data-ref="lbCurrent" class="ag-paging-number"></span>
                        <span id="ag-${compId}-of-page">${strOf}</span>
                        <span id="ag-${compId}-of-page-number" data-ref="lbTotal" class="ag-paging-number"></span>
                    </span>
                    <div data-ref="btNext" class="ag-button ag-paging-button" role="button" aria-label="${strNext}"></div>
                    <div data-ref="btLast" class="ag-button ag-paging-button" role="button" aria-label="${strLast}"></div>
                </span>
            </div>`
    );
  }
  onBtNext() {
    if (!this.nextButtonDisabled) {
      this.paginationService.goToNextPage();
    }
  }
  onBtPrevious() {
    if (!this.previousAndFirstButtonsDisabled) {
      this.paginationService.goToPreviousPage();
    }
  }
  onBtLast() {
    if (!this.lastButtonDisabled) {
      this.paginationService.goToLastPage();
    }
  }
  enableOrDisableButtons() {
    const currentPage = this.paginationService.getCurrentPage();
    const maxRowFound = this.rowModel.isLastRowIndexKnown();
    const totalPages = this.paginationService.getTotalPages();
    this.previousAndFirstButtonsDisabled = currentPage === 0;
    this.toggleButtonDisabled(this.btFirst, this.previousAndFirstButtonsDisabled);
    this.toggleButtonDisabled(this.btPrevious, this.previousAndFirstButtonsDisabled);
    const zeroPagesToDisplay = this.isZeroPagesToDisplay();
    const onLastPage = currentPage === totalPages - 1;
    this.nextButtonDisabled = onLastPage || zeroPagesToDisplay;
    this.lastButtonDisabled = !maxRowFound || zeroPagesToDisplay || currentPage === totalPages - 1;
    this.toggleButtonDisabled(this.btNext, this.nextButtonDisabled);
    this.toggleButtonDisabled(this.btLast, this.lastButtonDisabled);
  }
  toggleButtonDisabled(button, disabled) {
    _setAriaDisabled(button, disabled);
    button.classList.toggle("ag-disabled", disabled);
  }
  updateRowLabels() {
    const currentPage = this.paginationService.getCurrentPage();
    const pageSize = this.paginationService.getPageSize();
    const maxRowFound = this.rowModel.isLastRowIndexKnown();
    const rowCount = this.rowModel.isLastRowIndexKnown() ? this.paginationService.getMasterRowCount() : null;
    let startRow;
    let endRow;
    if (this.isZeroPagesToDisplay()) {
      startRow = endRow = 0;
    } else {
      startRow = pageSize * currentPage + 1;
      endRow = startRow + pageSize - 1;
      if (maxRowFound && endRow > rowCount) {
        endRow = rowCount;
      }
    }
    this.lbFirstRowOnPage.textContent = this.formatNumber(startRow);
    if (this.rowNodeBlockLoader?.isLoading()) {
      const translate = this.localeService.getLocaleTextFunc();
      this.lbLastRowOnPage.innerHTML = translate("pageLastRowUnknown", "?");
    } else {
      this.lbLastRowOnPage.textContent = this.formatNumber(endRow);
    }
  }
  isZeroPagesToDisplay() {
    const maxRowFound = this.rowModel.isLastRowIndexKnown();
    const totalPages = this.paginationService.getTotalPages();
    return maxRowFound && totalPages === 0;
  }
  setTotalLabels() {
    const lastPageFound = this.rowModel.isLastRowIndexKnown();
    const totalPages = this.paginationService.getTotalPages();
    const rowCount = lastPageFound ? this.paginationService.getMasterRowCount() : null;
    if (rowCount === 1) {
      const firstRow = this.rowModel.getRow(0);
      const hiddenGroupRow = firstRow && firstRow.group && !(firstRow.groupData || firstRow.aggData);
      if (hiddenGroupRow) {
        this.setTotalLabelsToZero();
        return;
      }
    }
    if (lastPageFound) {
      this.lbTotal.textContent = this.formatNumber(totalPages);
      this.lbRecordCount.textContent = this.formatNumber(rowCount);
    } else {
      const moreText = this.localeService.getLocaleTextFunc()("more", "more");
      this.lbTotal.innerHTML = moreText;
      this.lbRecordCount.innerHTML = moreText;
    }
  }
  setTotalLabelsToZero() {
    this.lbFirstRowOnPage.textContent = this.formatNumber(0);
    this.lbCurrent.textContent = this.formatNumber(0);
    this.lbLastRowOnPage.textContent = this.formatNumber(0);
    this.lbTotal.textContent = this.formatNumber(0);
    this.lbRecordCount.textContent = this.formatNumber(0);
  }
};
var PaginationSelector = {
  selector: "AG-PAGINATION",
  component: PaginationComp
};

// community-modules/core/src/pagination/paginationService.ts
var PaginationService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "paginationService";
    this.currentPage = 0;
    this.topDisplayedRowIndex = 0;
    this.bottomDisplayedRowIndex = 0;
    this.masterRowCount = 0;
  }
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
    this.pageBoundsService = beans.pageBoundsService;
  }
  postConstruct() {
    this.active = this.gos.get("pagination");
    this.pageSizeFromGridOptions = this.gos.get("paginationPageSize");
    this.paginateChildRows = this.isPaginateChildRows();
    this.addManagedPropertyListener("pagination", this.onPaginationGridOptionChanged.bind(this));
    this.addManagedPropertyListener("paginationPageSize", this.onPageSizeGridOptionChanged.bind(this));
  }
  getPaginationSelector() {
    return PaginationSelector;
  }
  isPaginateChildRows() {
    const shouldPaginate = this.gos.get("groupRemoveSingleChildren") || this.gos.get("groupRemoveLowestSingleChildren");
    if (shouldPaginate) {
      return true;
    }
    return this.gos.get("paginateChildRows");
  }
  onPaginationGridOptionChanged() {
    this.active = this.gos.get("pagination");
    this.calculatePages();
    this.dispatchPaginationChangedEvent({ keepRenderedRows: true });
  }
  onPageSizeGridOptionChanged() {
    this.setPageSize(this.gos.get("paginationPageSize"), "gridOptions");
  }
  goToPage(page) {
    if (!this.active || this.currentPage === page || typeof this.currentPage !== "number") {
      return;
    }
    this.currentPage = page;
    this.calculatePages();
    this.dispatchPaginationChangedEvent({ newPage: true });
  }
  isRowPresent(rowNode) {
    const nodeIsInPage = rowNode.rowIndex >= this.topDisplayedRowIndex && rowNode.rowIndex <= this.bottomDisplayedRowIndex;
    return nodeIsInPage;
  }
  getPageForIndex(index) {
    return Math.floor(index / this.pageSize);
  }
  goToPageWithIndex(index) {
    if (!this.active) {
      return;
    }
    const pageNumber = this.getPageForIndex(index);
    this.goToPage(pageNumber);
  }
  isRowInPage(row) {
    if (!this.active) {
      return true;
    }
    const rowPage = this.getPageForIndex(row.rowIndex);
    return rowPage === this.currentPage;
  }
  getCurrentPage() {
    return this.currentPage;
  }
  goToNextPage() {
    this.goToPage(this.currentPage + 1);
  }
  goToPreviousPage() {
    this.goToPage(this.currentPage - 1);
  }
  goToFirstPage() {
    this.goToPage(0);
  }
  goToLastPage() {
    const rowCount = this.rowModel.getRowCount();
    const lastPage = Math.floor(rowCount / this.pageSize);
    this.goToPage(lastPage);
  }
  getPageSize() {
    return this.pageSize;
  }
  getTotalPages() {
    return this.totalPages;
  }
  /** This is only for state setting before data has been loaded */
  setPage(page) {
    this.currentPage = page;
  }
  get pageSize() {
    if (_exists(this.pageSizeAutoCalculated)) {
      return this.pageSizeAutoCalculated;
    }
    if (_exists(this.pageSizeFromPageSizeSelector)) {
      return this.pageSizeFromPageSizeSelector;
    }
    if (_exists(this.pageSizeFromInitialState)) {
      return this.pageSizeFromInitialState;
    }
    if (_exists(this.pageSizeFromGridOptions)) {
      return this.pageSizeFromGridOptions;
    }
    return this.defaultPageSize;
  }
  calculatePages() {
    if (this.active) {
      if (this.paginateChildRows) {
        this.calculatePagesAllRows();
      } else {
        this.calculatePagesMasterRowsOnly();
      }
    } else {
      this.calculatedPagesNotActive();
    }
    this.pageBoundsService.calculateBounds(this.topDisplayedRowIndex, this.bottomDisplayedRowIndex);
  }
  unsetAutoCalculatedPageSize() {
    if (this.pageSizeAutoCalculated === void 0) {
      return;
    }
    const oldPageSize = this.pageSizeAutoCalculated;
    this.pageSizeAutoCalculated = void 0;
    if (this.pageSize === oldPageSize) {
      return;
    }
    this.calculatePages();
    this.dispatchPaginationChangedEvent({ newPageSize: true });
  }
  setPageSize(size, source) {
    const currentSize = this.pageSize;
    switch (source) {
      case "autoCalculated":
        this.pageSizeAutoCalculated = size;
        break;
      case "pageSizeSelector":
        this.pageSizeFromPageSizeSelector = size;
        if (this.currentPage !== 0) {
          this.goToFirstPage();
        }
        break;
      case "initialState":
        this.pageSizeFromInitialState = size;
        break;
      case "gridOptions":
        this.pageSizeFromGridOptions = size;
        this.pageSizeFromInitialState = void 0;
        this.pageSizeFromPageSizeSelector = void 0;
        if (this.currentPage !== 0) {
          this.goToFirstPage();
        }
        break;
    }
    if (currentSize !== this.pageSize) {
      this.calculatePages();
      this.dispatchPaginationChangedEvent({ newPageSize: true, keepRenderedRows: true });
    }
  }
  setZeroRows() {
    this.masterRowCount = 0;
    this.topDisplayedRowIndex = 0;
    this.bottomDisplayedRowIndex = -1;
    this.currentPage = 0;
    this.totalPages = 0;
  }
  adjustCurrentPageIfInvalid() {
    if (this.currentPage >= this.totalPages) {
      this.currentPage = this.totalPages - 1;
    }
    if (!isFinite(this.currentPage) || isNaN(this.currentPage) || this.currentPage < 0) {
      this.currentPage = 0;
    }
  }
  calculatePagesMasterRowsOnly() {
    this.masterRowCount = this.rowModel.getTopLevelRowCount();
    if (this.masterRowCount <= 0) {
      this.setZeroRows();
      return;
    }
    const masterLastRowIndex = this.masterRowCount - 1;
    this.totalPages = Math.floor(masterLastRowIndex / this.pageSize) + 1;
    this.adjustCurrentPageIfInvalid();
    const masterPageStartIndex = this.pageSize * this.currentPage;
    let masterPageEndIndex = this.pageSize * (this.currentPage + 1) - 1;
    if (masterPageEndIndex > masterLastRowIndex) {
      masterPageEndIndex = masterLastRowIndex;
    }
    this.topDisplayedRowIndex = this.rowModel.getTopLevelRowDisplayedIndex(masterPageStartIndex);
    if (masterPageEndIndex === masterLastRowIndex) {
      this.bottomDisplayedRowIndex = this.rowModel.getRowCount() - 1;
    } else {
      const firstIndexNotToShow = this.rowModel.getTopLevelRowDisplayedIndex(masterPageEndIndex + 1);
      this.bottomDisplayedRowIndex = firstIndexNotToShow - 1;
    }
  }
  getMasterRowCount() {
    return this.masterRowCount;
  }
  calculatePagesAllRows() {
    this.masterRowCount = this.rowModel.getRowCount();
    if (this.masterRowCount === 0) {
      this.setZeroRows();
      return;
    }
    const maxRowIndex = this.masterRowCount - 1;
    this.totalPages = Math.floor(maxRowIndex / this.pageSize) + 1;
    this.adjustCurrentPageIfInvalid();
    this.topDisplayedRowIndex = this.pageSize * this.currentPage;
    this.bottomDisplayedRowIndex = this.pageSize * (this.currentPage + 1) - 1;
    if (this.bottomDisplayedRowIndex > maxRowIndex) {
      this.bottomDisplayedRowIndex = maxRowIndex;
    }
  }
  calculatedPagesNotActive() {
    this.setPageSize(void 0, "autoCalculated");
    this.totalPages = 1;
    this.currentPage = 0;
    this.topDisplayedRowIndex = 0;
    this.bottomDisplayedRowIndex = this.rowModel.getRowCount() - 1;
  }
  dispatchPaginationChangedEvent(params) {
    const { keepRenderedRows = false, newPage = false, newPageSize = false } = params;
    const paginationChangedEvent = {
      type: "paginationChanged",
      animate: false,
      newData: false,
      newPage,
      newPageSize,
      keepRenderedRows
    };
    this.eventService.dispatchEvent(paginationChangedEvent);
  }
};

// community-modules/core/src/pagination/paginationModule.ts
var PaginationCoreModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/pagination-core",
  beans: [PaginationService, PaginationAutoPageSizeService]
};
var PaginationApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/pagination-api",
  dependantModules: [PaginationCoreModule],
  apiFunctions: {
    paginationIsLastPageFound,
    paginationGetPageSize,
    paginationGetCurrentPage,
    paginationGetTotalPages,
    paginationGetRowCount,
    paginationGoToNextPage,
    paginationGoToPreviousPage,
    paginationGoToFirstPage,
    paginationGoToLastPage,
    paginationGoToPage
  }
};
var PaginationModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/pagination",
  dependantModules: [PaginationCoreModule, PaginationApiModule]
};

// community-modules/core/src/validation/apiFunctionValidator.ts
var coreModule = "@ag-grid-community/core" /* CommunityCoreModule */;
var clientSideRowModelModule = "@ag-grid-community/client-side-row-model" /* ClientSideRowModelModule */;
var csvExportModule = "@ag-grid-community/csv-export" /* CsvExportModule */;
var infiniteRowModelModule = "@ag-grid-community/infinite-row-model" /* InfiniteRowModelModule */;
var advancedFilterModule = "@ag-grid-enterprise/advanced-filter" /* AdvancedFilterModule */;
var gridChartsModule = "@ag-grid-enterprise/charts" /* GridChartsModule */;
var clipboardModule = "@ag-grid-enterprise/clipboard" /* ClipboardModule */;
var excelExportModule = "@ag-grid-enterprise/excel-export" /* ExcelExportModule */;
var masterDetailModule = "@ag-grid-enterprise/master-detail" /* MasterDetailModule */;
var menuModule = "@ag-grid-enterprise/menu" /* MenuModule */;
var rangeSelectionModule = "@ag-grid-enterprise/range-selection" /* RangeSelectionModule */;
var rowGroupingModule = "@ag-grid-enterprise/row-grouping" /* RowGroupingModule */;
var serverSideRowModelModule = "@ag-grid-enterprise/server-side-row-model" /* ServerSideRowModelModule */;
var sideBarModule = "@ag-grid-enterprise/side-bar" /* SideBarModule */;
var statusBarModule = "@ag-grid-enterprise/status-bar" /* StatusBarModule */;
var functionModules = {
  dispatchEvent: coreModule,
  getState: coreModule,
  getGridId: coreModule,
  destroy: coreModule,
  isDestroyed: coreModule,
  getGridOption: coreModule,
  setGridOption: coreModule,
  updateGridOptions: coreModule,
  setNodesSelected: coreModule,
  selectAll: coreModule,
  deselectAll: coreModule,
  selectAllFiltered: coreModule,
  deselectAllFiltered: coreModule,
  selectAllOnCurrentPage: coreModule,
  deselectAllOnCurrentPage: coreModule,
  getSelectedNodes: coreModule,
  getSelectedRows: coreModule,
  redrawRows: coreModule,
  setRowNodeExpanded: coreModule,
  getRowNode: coreModule,
  addRenderedRowListener: coreModule,
  getRenderedNodes: coreModule,
  forEachNode: coreModule,
  getFirstDisplayedRow: coreModule,
  getFirstDisplayedRowIndex: coreModule,
  getLastDisplayedRow: coreModule,
  getLastDisplayedRowIndex: coreModule,
  getDisplayedRowAtIndex: coreModule,
  getDisplayedRowCount: coreModule,
  getModel: coreModule,
  getVerticalPixelRange: coreModule,
  getHorizontalPixelRange: coreModule,
  ensureColumnVisible: coreModule,
  ensureIndexVisible: coreModule,
  ensureNodeVisible: coreModule,
  getFocusedCell: coreModule,
  clearFocusedCell: coreModule,
  setFocusedCell: coreModule,
  tabToNextCell: coreModule,
  tabToPreviousCell: coreModule,
  setFocusedHeader: coreModule,
  addEventListener: coreModule,
  addGlobalListener: coreModule,
  removeEventListener: coreModule,
  removeGlobalListener: coreModule,
  expireValueCache: coreModule,
  getValue: coreModule,
  getCellValue: coreModule,
  showColumnMenuAfterButtonClick: coreModule,
  showColumnMenuAfterMouseClick: coreModule,
  showColumnMenu: coreModule,
  hidePopupMenu: coreModule,
  onSortChanged: coreModule,
  getPinnedTopRowCount: coreModule,
  getPinnedBottomRowCount: coreModule,
  getPinnedTopRow: coreModule,
  getPinnedBottomRow: coreModule,
  showLoadingOverlay: coreModule,
  showNoRowsOverlay: coreModule,
  hideOverlay: coreModule,
  setGridAriaProperty: coreModule,
  refreshCells: coreModule,
  flashCells: coreModule,
  refreshHeader: coreModule,
  isAnimationFrameQueueEmpty: coreModule,
  flushAllAnimationFrames: coreModule,
  getSizesForCurrentTheme: coreModule,
  getCellRendererInstances: coreModule,
  addRowDropZone: coreModule,
  removeRowDropZone: coreModule,
  getRowDropZoneParams: coreModule,
  getColumnDef: coreModule,
  getColumnDefs: coreModule,
  sizeColumnsToFit: coreModule,
  setColumnGroupOpened: coreModule,
  getColumnGroup: coreModule,
  getProvidedColumnGroup: coreModule,
  getDisplayNameForColumn: coreModule,
  getDisplayNameForColumnGroup: coreModule,
  getColumn: coreModule,
  getColumns: coreModule,
  applyColumnState: coreModule,
  getColumnState: coreModule,
  resetColumnState: coreModule,
  getColumnGroupState: coreModule,
  setColumnGroupState: coreModule,
  resetColumnGroupState: coreModule,
  isPinning: coreModule,
  isPinningLeft: coreModule,
  isPinningRight: coreModule,
  getDisplayedColAfter: coreModule,
  getDisplayedColBefore: coreModule,
  setColumnVisible: coreModule,
  setColumnsVisible: coreModule,
  setColumnPinned: coreModule,
  setColumnsPinned: coreModule,
  getAllGridColumns: coreModule,
  getDisplayedLeftColumns: coreModule,
  getDisplayedCenterColumns: coreModule,
  getDisplayedRightColumns: coreModule,
  getAllDisplayedColumns: coreModule,
  getAllDisplayedVirtualColumns: coreModule,
  moveColumn: coreModule,
  moveColumnByIndex: coreModule,
  moveColumns: coreModule,
  setColumnWidth: coreModule,
  setColumnWidths: coreModule,
  getLeftDisplayedColumnGroups: coreModule,
  getCenterDisplayedColumnGroups: coreModule,
  getRightDisplayedColumnGroups: coreModule,
  getAllDisplayedColumnGroups: coreModule,
  autoSizeColumn: coreModule,
  autoSizeColumns: coreModule,
  autoSizeAllColumns: coreModule,
  undoCellEditing: coreModule,
  redoCellEditing: coreModule,
  getCellEditorInstances: coreModule,
  getEditingCells: coreModule,
  stopEditing: coreModule,
  startEditingCell: coreModule,
  getCurrentUndoSize: coreModule,
  getCurrentRedoSize: coreModule,
  isAnyFilterPresent: coreModule,
  onFilterChanged: coreModule,
  isColumnFilterPresent: coreModule,
  getFilterInstance: coreModule,
  getColumnFilterInstance: coreModule,
  destroyFilter: coreModule,
  setFilterModel: coreModule,
  getFilterModel: coreModule,
  getColumnFilterModel: coreModule,
  setColumnFilterModel: coreModule,
  showColumnFilter: coreModule,
  isQuickFilterPresent: coreModule,
  getQuickFilter: coreModule,
  resetQuickFilter: coreModule,
  paginationIsLastPageFound: coreModule,
  paginationGetPageSize: coreModule,
  paginationGetCurrentPage: coreModule,
  paginationGetTotalPages: coreModule,
  paginationGetRowCount: coreModule,
  paginationGoToNextPage: coreModule,
  paginationGoToPreviousPage: coreModule,
  paginationGoToFirstPage: coreModule,
  paginationGoToLastPage: coreModule,
  paginationGoToPage: coreModule,
  // These may need updating to say which of multiple possible modules they could be missing from.
  expandAll: coreModule,
  collapseAll: coreModule,
  onRowHeightChanged: coreModule,
  setRowCount: coreModule,
  getCacheBlockState: coreModule,
  onGroupExpandedOrCollapsed: clientSideRowModelModule,
  refreshClientSideRowModel: clientSideRowModelModule,
  forEachLeafNode: clientSideRowModelModule,
  forEachNodeAfterFilter: clientSideRowModelModule,
  forEachNodeAfterFilterAndSort: clientSideRowModelModule,
  resetRowHeights: clientSideRowModelModule,
  applyTransaction: clientSideRowModelModule,
  applyTransactionAsync: clientSideRowModelModule,
  flushAsyncTransactions: clientSideRowModelModule,
  getBestCostNodeSelection: clientSideRowModelModule,
  getDataAsCsv: csvExportModule,
  exportDataAsCsv: csvExportModule,
  refreshInfiniteCache: infiniteRowModelModule,
  purgeInfiniteCache: infiniteRowModelModule,
  getInfiniteRowCount: infiniteRowModelModule,
  isLastRowIndexKnown: infiniteRowModelModule,
  getAdvancedFilterModel: advancedFilterModule,
  setAdvancedFilterModel: advancedFilterModule,
  showAdvancedFilterBuilder: advancedFilterModule,
  hideAdvancedFilterBuilder: advancedFilterModule,
  getChartModels: gridChartsModule,
  getChartRef: gridChartsModule,
  getChartImageDataURL: gridChartsModule,
  downloadChart: gridChartsModule,
  openChartToolPanel: gridChartsModule,
  closeChartToolPanel: gridChartsModule,
  createRangeChart: gridChartsModule,
  createPivotChart: gridChartsModule,
  createCrossFilterChart: gridChartsModule,
  updateChart: gridChartsModule,
  restoreChart: gridChartsModule,
  copyToClipboard: clipboardModule,
  cutToClipboard: clipboardModule,
  copySelectedRowsToClipboard: clipboardModule,
  copySelectedRangeToClipboard: clipboardModule,
  copySelectedRangeDown: clipboardModule,
  pasteFromClipboard: clipboardModule,
  getDataAsExcel: excelExportModule,
  exportDataAsExcel: excelExportModule,
  getSheetDataForExcel: excelExportModule,
  getMultipleSheetsAsExcel: excelExportModule,
  exportMultipleSheetsAsExcel: excelExportModule,
  addDetailGridInfo: masterDetailModule,
  removeDetailGridInfo: masterDetailModule,
  getDetailGridInfo: masterDetailModule,
  forEachDetailGridInfo: masterDetailModule,
  showContextMenu: menuModule,
  showColumnChooser: menuModule,
  hideColumnChooser: menuModule,
  getCellRanges: rangeSelectionModule,
  addCellRange: rangeSelectionModule,
  clearRangeSelection: rangeSelectionModule,
  addAggFunc: rowGroupingModule,
  addAggFuncs: rowGroupingModule,
  clearAggFuncs: rowGroupingModule,
  setColumnAggFunc: rowGroupingModule,
  isPivotMode: rowGroupingModule,
  getPivotResultColumn: rowGroupingModule,
  setValueColumns: rowGroupingModule,
  getValueColumns: rowGroupingModule,
  removeValueColumn: rowGroupingModule,
  removeValueColumns: rowGroupingModule,
  addValueColumn: rowGroupingModule,
  addValueColumns: rowGroupingModule,
  setRowGroupColumns: rowGroupingModule,
  removeRowGroupColumn: rowGroupingModule,
  removeRowGroupColumns: rowGroupingModule,
  addRowGroupColumn: rowGroupingModule,
  addRowGroupColumns: rowGroupingModule,
  getRowGroupColumns: rowGroupingModule,
  moveRowGroupColumn: rowGroupingModule,
  setPivotColumns: rowGroupingModule,
  removePivotColumn: rowGroupingModule,
  removePivotColumns: rowGroupingModule,
  addPivotColumn: rowGroupingModule,
  addPivotColumns: rowGroupingModule,
  getPivotColumns: rowGroupingModule,
  setPivotResultColumns: rowGroupingModule,
  getPivotResultColumns: rowGroupingModule,
  getServerSideSelectionState: serverSideRowModelModule,
  setServerSideSelectionState: serverSideRowModelModule,
  applyServerSideTransaction: serverSideRowModelModule,
  applyServerSideTransactionAsync: serverSideRowModelModule,
  applyServerSideRowData: serverSideRowModelModule,
  retryServerSideLoads: serverSideRowModelModule,
  flushServerSideAsyncTransactions: serverSideRowModelModule,
  refreshServerSide: serverSideRowModelModule,
  getServerSideGroupLevelState: serverSideRowModelModule,
  isSideBarVisible: sideBarModule,
  setSideBarVisible: sideBarModule,
  setSideBarPosition: sideBarModule,
  openToolPanel: sideBarModule,
  closeToolPanel: sideBarModule,
  getOpenedToolPanel: sideBarModule,
  refreshToolPanel: sideBarModule,
  isToolPanelShowing: sideBarModule,
  getToolPanelInstance: sideBarModule,
  getSideBar: sideBarModule,
  getStatusPanel: statusBarModule
};
var clientSide = "clientSide";
var serverSide = "serverSide";
var infinite = "infinite";
var functionRowModels = {
  onGroupExpandedOrCollapsed: [clientSide],
  refreshClientSideRowModel: [clientSide],
  forEachLeafNode: [clientSide],
  forEachNodeAfterFilter: [clientSide],
  forEachNodeAfterFilterAndSort: [clientSide],
  resetRowHeights: [clientSide],
  applyTransaction: [clientSide],
  applyTransactionAsync: [clientSide],
  flushAsyncTransactions: [clientSide],
  getBestCostNodeSelection: [clientSide],
  getServerSideSelectionState: [serverSide],
  setServerSideSelectionState: [serverSide],
  applyServerSideTransaction: [serverSide],
  applyServerSideTransactionAsync: [serverSide],
  applyServerSideRowData: [serverSide],
  retryServerSideLoads: [serverSide],
  flushServerSideAsyncTransactions: [serverSide],
  refreshServerSide: [serverSide],
  getServerSideGroupLevelState: [serverSide],
  refreshInfiniteCache: [infinite],
  purgeInfiniteCache: [infinite],
  getInfiniteRowCount: [infinite],
  isLastRowIndexKnown: [infinite],
  expandAll: [clientSide, serverSide],
  collapseAll: [clientSide, serverSide],
  onRowHeightChanged: [clientSide, serverSide],
  setRowCount: [infinite, serverSide],
  getCacheBlockState: [infinite, serverSide]
};
var deprecatedFunctions = {
  getValue: {
    version: "v31.3",
    new: "getCellValue"
  },
  getFirstDisplayedRow: {
    version: "v31.1",
    new: "getFirstDisplayedRowIndex"
  },
  getLastDisplayedRow: {
    version: "v31.1",
    new: "getLastDisplayedRowIndex"
  },
  getModel: {
    version: "v31.1",
    message: "Please use the appropriate grid API methods instead."
  },
  setColumnVisible: {
    version: "v31.1",
    old: "setColumnVisible(key,visible)",
    new: "setColumnsVisible([key],visible)"
  },
  setColumnPinned: {
    version: "v31.1",
    old: "setColumnPinned(key,pinned)",
    new: "setColumnsPinned([key],pinned)"
  },
  moveColumn: {
    version: "v31.1",
    old: "moveColumn(key, toIndex)",
    new: "moveColumns([key], toIndex)"
  },
  setColumnWidth: {
    version: "v31.1",
    old: "setColumnWidth(col, width)",
    new: "setColumnWidths([{key: col, newWidth: width}])"
  },
  autoSizeColumn: {
    version: "v31.1",
    old: "autoSizeColumn(key, skipHeader)",
    new: "autoSizeColumns([key], skipHeader)"
  },
  addAggFunc: {
    version: "v31.1",
    old: "addAggFunc(key, func)",
    new: "addAggFuncs({ key: func })"
  },
  removeValueColumn: {
    version: "v31.1",
    old: "removeValueColumn(colKey)",
    new: "removeValueColumns([colKey])"
  },
  addValueColumn: {
    version: "v31.1",
    old: "addValueColumn(colKey)",
    new: "addValueColumns([colKey])"
  },
  removeRowGroupColumn: {
    version: "v31.1",
    old: "removeRowGroupColumn(colKey)",
    new: "removeRowGroupColumns([colKey])"
  },
  addRowGroupColumn: {
    version: "v31.1",
    old: "addRowGroupColumn(colKey)",
    new: "addRowGroupColumns([colKey])"
  },
  removePivotColumn: {
    version: "v31.1",
    old: "removePivotColumn(colKey)",
    new: "removePivotColumns([colKey])"
  },
  addPivotColumn: {
    version: "v31.1",
    old: "addPivotColumn(colKey)",
    new: "addPivotColumns([colKey])"
  },
  showColumnMenuAfterButtonClick: {
    version: "v31.1",
    message: `Use 'IHeaderParams.showColumnMenu' within a header component, or 'api.showColumnMenu' elsewhere.`
  },
  showColumnMenuAfterMouseClick: {
    version: "v31.1",
    message: `Use 'IHeaderParams.showColumnMenuAfterMouseClick' within a header component, or 'api.showColumnMenu' elsewhere.`
  },
  getFilterInstance: {
    version: "v31.1",
    message: `'getFilterInstance' is deprecated. To get/set individual filter models, use 'getColumnFilterModel' or 'setColumnFilterModel' instead. To get hold of the filter instance, use 'getColumnFilterInstance' which returns the instance asynchronously.`
  },
  showLoadingOverlay: {
    version: "v32",
    message: '`showLoadingOverlay` is deprecated. Use the grid option "loading"=true instead or setGridOption("loading", true).'
  }
};
function warnMissingApiFunction(functionName, gridId) {
  const module = functionModules[functionName];
  if (module) {
    if (ModuleRegistry.__assertRegistered(module, `api.${functionName}`, gridId)) {
      _warnOnce(`API function '${functionName}' not registered to module '${module}'`);
    }
  } else {
    _errorOnce(`Unknown API function: '${functionName}' on GridApi.`);
  }
}
function validateApiFunction(functionName, apiFunction, beans) {
  const deprecation = deprecatedFunctions[functionName];
  if (deprecation) {
    const { version, new: replacement, old, message } = deprecation;
    const apiMethod = old ?? functionName;
    return (...args) => {
      const replacementMessage = replacement ? `Please use ${replacement} instead. ` : "";
      _warnOnce(`Since ${version} api.${apiMethod} is deprecated. ${replacementMessage}${message ?? ""}`);
      return apiFunction.apply(apiFunction, args);
    };
  }
  const rowModels = functionRowModels[functionName];
  if (rowModels) {
    return (...args) => {
      const rowModel = beans.rowModel.getType();
      if (!rowModels.includes(rowModel)) {
        _errorOnce(
          `api.${functionName} can only be called when gridOptions.rowModelType is ${rowModels.join(" or ")}`
        );
        return void 0;
      }
      return apiFunction.apply(apiFunction, args);
    };
  }
  return apiFunction;
}

// community-modules/core/src/validation/rules/colDefValidations.ts
var COLUMN_DEFINITION_DEPRECATIONS = {
  columnsMenuParams: { version: "31.1", message: "Use `columnChooserParams` instead." },
  suppressMenu: { version: "31.1", message: "Use `suppressHeaderMenuButton` instead." },
  suppressCellFlash: { version: "31.2", message: "Use `enableCellChangeFlash={false}` in the ColDef" }
};
var CSRM_REQUIRES_ROW_GROUP_MODULE = (_options, gridOptions) => {
  if ((gridOptions.rowModelType ?? "clientSide") === "clientSide") {
    return { module: "@ag-grid-enterprise/row-grouping" /* RowGroupingModule */ };
  }
  return null;
};
var COLUMN_DEFINITION_VALIDATIONS = {
  // supported on all row models, but need module for client side.
  enableRowGroup: CSRM_REQUIRES_ROW_GROUP_MODULE,
  rowGroup: CSRM_REQUIRES_ROW_GROUP_MODULE,
  rowGroupIndex: CSRM_REQUIRES_ROW_GROUP_MODULE,
  enablePivot: CSRM_REQUIRES_ROW_GROUP_MODULE,
  enableValue: CSRM_REQUIRES_ROW_GROUP_MODULE,
  pivot: CSRM_REQUIRES_ROW_GROUP_MODULE,
  pivotIndex: CSRM_REQUIRES_ROW_GROUP_MODULE,
  aggFunc: CSRM_REQUIRES_ROW_GROUP_MODULE,
  cellEditor: (options) => {
    if (options.cellEditor === "agRichSelect" || options.cellEditor === "agRichSelectCellEditor") {
      return { module: "@ag-grid-enterprise/rich-select" /* RichSelectModule */ };
    }
    return null;
  },
  menuTabs: (options) => {
    const enterpriseMenuTabs = ["columnsMenuTab", "generalMenuTab"];
    if (options.menuTabs?.some((tab) => enterpriseMenuTabs.includes(tab))) {
      return {
        module: "@ag-grid-enterprise/menu" /* MenuModule */
      };
    }
    return null;
  },
  columnsMenuParams: {
    module: ["@ag-grid-enterprise/menu" /* MenuModule */, "@ag-grid-enterprise/column-tool-panel" /* ColumnsToolPanelModule */]
  },
  columnChooserParams: {
    module: ["@ag-grid-enterprise/menu" /* MenuModule */, "@ag-grid-enterprise/column-tool-panel" /* ColumnsToolPanelModule */]
  },
  headerCheckboxSelection: {
    supportedRowModels: ["clientSide", "serverSide"],
    dependencies: (_options, { rowSelection }) => rowSelection === "multiple" ? null : "headerCheckboxSelection is only supported with rowSelection=multiple"
  },
  headerCheckboxSelectionFilteredOnly: {
    supportedRowModels: ["clientSide"],
    dependencies: (_options, { rowSelection }) => rowSelection === "multiple" ? null : "headerCheckboxSelectionFilteredOnly is only supported with rowSelection=multiple"
  },
  headerCheckboxSelectionCurrentPageOnly: {
    supportedRowModels: ["clientSide"],
    dependencies: (_options, { rowSelection }) => rowSelection === "multiple" ? null : "headerCheckboxSelectionCurrentPageOnly is only supported with rowSelection=multiple"
  },
  children: () => COL_DEF_VALIDATORS
};
var colDefPropertyMap = {
  headerName: void 0,
  columnGroupShow: void 0,
  headerClass: void 0,
  toolPanelClass: void 0,
  headerValueGetter: void 0,
  pivotKeys: void 0,
  groupId: void 0,
  colId: void 0,
  sort: void 0,
  initialSort: void 0,
  field: void 0,
  type: void 0,
  cellDataType: void 0,
  tooltipComponent: void 0,
  tooltipField: void 0,
  headerTooltip: void 0,
  cellClass: void 0,
  showRowGroup: void 0,
  filter: void 0,
  initialAggFunc: void 0,
  defaultAggFunc: void 0,
  aggFunc: void 0,
  pinned: void 0,
  initialPinned: void 0,
  chartDataType: void 0,
  cellAriaRole: void 0,
  cellEditorPopupPosition: void 0,
  headerGroupComponent: void 0,
  headerGroupComponentParams: void 0,
  cellStyle: void 0,
  cellRenderer: void 0,
  cellRendererParams: void 0,
  cellEditor: void 0,
  cellEditorParams: void 0,
  filterParams: void 0,
  pivotValueColumn: void 0,
  headerComponent: void 0,
  headerComponentParams: void 0,
  floatingFilterComponent: void 0,
  floatingFilterComponentParams: void 0,
  tooltipComponentParams: void 0,
  refData: void 0,
  columnsMenuParams: void 0,
  columnChooserParams: void 0,
  children: void 0,
  sortingOrder: void 0,
  allowedAggFuncs: void 0,
  menuTabs: void 0,
  pivotTotalColumnIds: void 0,
  cellClassRules: void 0,
  icons: void 0,
  sortIndex: void 0,
  initialSortIndex: void 0,
  flex: void 0,
  initialFlex: void 0,
  width: void 0,
  initialWidth: void 0,
  minWidth: void 0,
  maxWidth: void 0,
  rowGroupIndex: void 0,
  initialRowGroupIndex: void 0,
  pivotIndex: void 0,
  initialPivotIndex: void 0,
  suppressCellFlash: void 0,
  suppressColumnsToolPanel: void 0,
  suppressFiltersToolPanel: void 0,
  openByDefault: void 0,
  marryChildren: void 0,
  suppressStickyLabel: void 0,
  hide: void 0,
  initialHide: void 0,
  rowGroup: void 0,
  initialRowGroup: void 0,
  pivot: void 0,
  initialPivot: void 0,
  checkboxSelection: void 0,
  showDisabledCheckboxes: void 0,
  headerCheckboxSelection: void 0,
  headerCheckboxSelectionFilteredOnly: void 0,
  headerCheckboxSelectionCurrentPageOnly: void 0,
  suppressMenu: void 0,
  suppressHeaderMenuButton: void 0,
  suppressMovable: void 0,
  lockPosition: void 0,
  lockVisible: void 0,
  lockPinned: void 0,
  unSortIcon: void 0,
  suppressSizeToFit: void 0,
  suppressAutoSize: void 0,
  enableRowGroup: void 0,
  enablePivot: void 0,
  enableValue: void 0,
  editable: void 0,
  suppressPaste: void 0,
  suppressNavigable: void 0,
  enableCellChangeFlash: void 0,
  rowDrag: void 0,
  dndSource: void 0,
  autoHeight: void 0,
  wrapText: void 0,
  sortable: void 0,
  resizable: void 0,
  singleClickEdit: void 0,
  floatingFilter: void 0,
  cellEditorPopup: void 0,
  suppressFillHandle: void 0,
  wrapHeaderText: void 0,
  autoHeaderHeight: void 0,
  dndSourceOnRowDrag: void 0,
  valueGetter: void 0,
  valueSetter: void 0,
  filterValueGetter: void 0,
  keyCreator: void 0,
  valueFormatter: void 0,
  valueParser: void 0,
  comparator: void 0,
  equals: void 0,
  pivotComparator: void 0,
  suppressKeyboardEvent: void 0,
  suppressHeaderKeyboardEvent: void 0,
  colSpan: void 0,
  rowSpan: void 0,
  getQuickFilterText: void 0,
  onCellValueChanged: void 0,
  onCellClicked: void 0,
  onCellDoubleClicked: void 0,
  onCellContextMenu: void 0,
  rowDragText: void 0,
  tooltipValueGetter: void 0,
  cellRendererSelector: void 0,
  cellEditorSelector: void 0,
  suppressSpanHeaderHeight: void 0,
  useValueFormatterForExport: void 0,
  useValueParserForImport: void 0,
  mainMenuItems: void 0,
  contextMenuItems: void 0,
  suppressFloatingFilterButton: void 0,
  suppressHeaderFilterButton: void 0,
  suppressHeaderContextMenu: void 0,
  loadingCellRenderer: void 0,
  loadingCellRendererParams: void 0,
  loadingCellRendererSelector: void 0,
  context: void 0
};
var ALL_PROPERTIES = Object.keys(colDefPropertyMap);
var COL_DEF_VALIDATORS = {
  objectName: "colDef",
  allProperties: ALL_PROPERTIES,
  docsUrl: "column-properties/",
  deprecations: COLUMN_DEFINITION_DEPRECATIONS,
  validations: COLUMN_DEFINITION_VALIDATIONS
};

// community-modules/core/src/validation/rules/gridOptionsValidations.ts
var GRID_OPTION_DEPRECATIONS = () => ({
  advancedFilterModel: { version: "31", message: "Use `initialState.filter.advancedFilterModel` instead." },
  suppressAsyncEvents: { version: "31", message: "Events should be handled asynchronously." },
  cellFadeDelay: { version: "31.1", renamed: "cellFadeDuration" },
  cellFlashDelay: { version: "31.1", renamed: "cellFlashDuration" },
  suppressServerSideInfiniteScroll: { version: "31.1" },
  serverSideSortOnServer: { version: "31.1" },
  serverSideFilterOnServer: { version: "31.1" },
  enableCellChangeFlash: {
    version: "31.2",
    message: "Use `enableCellChangeFlash` in the `ColDef` or `defaultColDef` for all columns."
  },
  groupIncludeFooter: { version: "31.3", message: "Use `groupTotalRow` instead." },
  groupIncludeTotalFooter: { version: "31.3", message: "Use `grandTotalRow` instead." },
  suppressLoadingOverlay: { version: "32", message: "Use `loading`=false instead." }
});
var GRID_OPTION_DEFAULTS = {
  suppressContextMenu: false,
  preventDefaultOnContextMenu: false,
  allowContextMenuWithControlKey: false,
  suppressMenuHide: true,
  enableBrowserTooltips: false,
  tooltipTrigger: "hover",
  tooltipShowDelay: 2e3,
  tooltipHideDelay: 1e4,
  tooltipMouseTrack: false,
  tooltipShowMode: "standard",
  tooltipInteraction: false,
  copyHeadersToClipboard: false,
  copyGroupHeadersToClipboard: false,
  clipboardDelimiter: "	",
  suppressCopyRowsToClipboard: false,
  suppressCopySingleCellRanges: false,
  suppressLastEmptyLineOnPaste: false,
  suppressClipboardPaste: false,
  suppressClipboardApi: false,
  suppressCutToClipboard: false,
  maintainColumnOrder: false,
  suppressFieldDotNotation: false,
  allowDragFromColumnsToolPanel: false,
  suppressMovableColumns: false,
  suppressColumnMoveAnimation: false,
  suppressDragLeaveHidesColumns: false,
  suppressRowGroupHidesColumns: false,
  suppressAutoSize: false,
  autoSizePadding: 20,
  skipHeaderOnAutoSize: false,
  singleClickEdit: false,
  suppressClickEdit: false,
  readOnlyEdit: false,
  stopEditingWhenCellsLoseFocus: false,
  enterNavigatesVertically: false,
  enterNavigatesVerticallyAfterEdit: false,
  enableCellEditingOnBackspace: false,
  undoRedoCellEditing: false,
  undoRedoCellEditingLimit: 10,
  suppressCsvExport: false,
  suppressExcelExport: false,
  cacheQuickFilter: false,
  includeHiddenColumnsInQuickFilter: false,
  excludeChildrenWhenTreeDataFiltering: false,
  enableAdvancedFilter: false,
  includeHiddenColumnsInAdvancedFilter: false,
  enableCharts: false,
  masterDetail: false,
  keepDetailRows: false,
  keepDetailRowsCount: 10,
  detailRowAutoHeight: false,
  tabIndex: 0,
  rowBuffer: 10,
  valueCache: false,
  valueCacheNeverExpires: false,
  enableCellExpressions: false,
  suppressTouch: false,
  suppressFocusAfterRefresh: false,
  suppressAsyncEvents: false,
  suppressBrowserResizeObserver: false,
  suppressPropertyNamesCheck: false,
  suppressChangeDetection: false,
  debug: false,
  suppressLoadingOverlay: false,
  suppressNoRowsOverlay: false,
  pagination: false,
  paginationPageSize: 100,
  paginationPageSizeSelector: true,
  paginationAutoPageSize: false,
  paginateChildRows: false,
  suppressPaginationPanel: false,
  pivotMode: false,
  pivotPanelShow: "never",
  pivotDefaultExpanded: 0,
  pivotSuppressAutoColumn: false,
  suppressExpandablePivotGroups: false,
  functionsReadOnly: false,
  suppressAggFuncInHeader: false,
  alwaysAggregateAtRootLevel: false,
  aggregateOnlyChangedColumns: false,
  suppressAggFilteredOnly: false,
  removePivotHeaderRowWhenSingleValueColumn: false,
  animateRows: true,
  enableCellChangeFlash: false,
  cellFlashDelay: 500,
  cellFlashDuration: 500,
  cellFadeDelay: 1e3,
  cellFadeDuration: 1e3,
  allowShowChangeAfterFilter: false,
  domLayout: "normal",
  ensureDomOrder: false,
  enableRtl: false,
  suppressColumnVirtualisation: false,
  suppressMaxRenderedRowRestriction: false,
  suppressRowVirtualisation: false,
  rowDragManaged: false,
  suppressRowDrag: false,
  suppressMoveWhenRowDragging: false,
  rowDragEntireRow: false,
  rowDragMultiRow: false,
  embedFullWidthRows: false,
  groupDisplayType: "singleColumn",
  groupDefaultExpanded: 0,
  groupMaintainOrder: false,
  groupSelectsChildren: false,
  groupIncludeTotalFooter: false,
  groupSuppressBlankHeader: false,
  groupSelectsFiltered: false,
  showOpenedGroup: false,
  groupRemoveSingleChildren: false,
  groupRemoveLowestSingleChildren: false,
  groupHideOpenParents: false,
  groupAllowUnbalanced: false,
  rowGroupPanelShow: "never",
  suppressMakeColumnVisibleAfterUnGroup: false,
  treeData: false,
  rowGroupPanelSuppressSort: false,
  suppressGroupRowsSticky: false,
  rowModelType: "clientSide",
  asyncTransactionWaitMillis: 50,
  suppressModelUpdateAfterUpdateTransaction: false,
  cacheOverflowSize: 1,
  infiniteInitialRowCount: 1,
  serverSideInitialRowCount: 1,
  suppressServerSideInfiniteScroll: false,
  cacheBlockSize: 100,
  maxBlocksInCache: -1,
  maxConcurrentDatasourceRequests: 2,
  blockLoadDebounceMillis: 0,
  purgeClosedRowNodes: false,
  serverSideSortAllLevels: false,
  serverSideOnlyRefreshFilteredGroups: false,
  serverSideSortOnServer: false,
  serverSideFilterOnServer: false,
  serverSidePivotResultFieldSeparator: "_",
  viewportRowModelPageSize: 5,
  viewportRowModelBufferSize: 5,
  alwaysShowHorizontalScroll: false,
  alwaysShowVerticalScroll: false,
  debounceVerticalScrollbar: false,
  suppressHorizontalScroll: false,
  suppressScrollOnNewData: false,
  suppressScrollWhenPopupsAreOpen: false,
  suppressAnimationFrame: false,
  suppressMiddleClickScrolls: false,
  suppressPreventDefaultOnMouseWheel: false,
  rowMultiSelectWithClick: false,
  suppressRowDeselection: false,
  suppressRowClickSelection: false,
  suppressCellFocus: false,
  suppressHeaderFocus: false,
  suppressMultiRangeSelection: false,
  enableCellTextSelection: false,
  enableRangeSelection: false,
  enableRangeHandle: false,
  enableFillHandle: false,
  fillHandleDirection: "xy",
  suppressClearOnFillReduction: false,
  accentedSort: false,
  unSortIcon: false,
  suppressMultiSort: false,
  alwaysMultiSort: false,
  suppressMaintainUnsortedOrder: false,
  suppressRowHoverHighlight: false,
  suppressRowTransform: false,
  columnHoverHighlight: false,
  deltaSort: false,
  enableGroupEdit: false,
  suppressGroupMaintainValueType: false,
  groupLockGroupColumns: 0,
  serverSideEnableClientSideSort: false,
  suppressServerSideFullWidthLoadingRow: false,
  pivotMaxGeneratedColumns: -1,
  columnMenu: "new",
  reactiveCustomComponents: true
};
var GRID_OPTION_VALIDATIONS = () => ({
  sideBar: { module: "@ag-grid-enterprise/side-bar" /* SideBarModule */ },
  statusBar: { module: "@ag-grid-enterprise/status-bar" /* StatusBarModule */ },
  enableCharts: { module: "@ag-grid-enterprise/charts" /* GridChartsModule */ },
  getMainMenuItems: { module: "@ag-grid-enterprise/menu" /* MenuModule */ },
  getContextMenuItems: { module: "@ag-grid-enterprise/menu" /* MenuModule */ },
  allowContextMenuWithControlKey: { module: "@ag-grid-enterprise/menu" /* MenuModule */ },
  enableAdvancedFilter: { module: "@ag-grid-enterprise/advanced-filter" /* AdvancedFilterModule */ },
  treeData: {
    supportedRowModels: ["clientSide", "serverSide"],
    module: "@ag-grid-enterprise/row-grouping" /* RowGroupingModule */,
    dependencies: (options) => {
      const rowModel = options.rowModelType ?? "clientSide";
      switch (rowModel) {
        case "clientSide": {
          const csrmWarning = `treeData requires 'getDataPath' in the ${rowModel} row model.`;
          return options.getDataPath ? null : csrmWarning;
        }
        case "serverSide": {
          const ssrmWarning = `treeData requires 'isServerSideGroup' and 'getServerSideGroupKey' in the ${rowModel} row model.`;
          return options.isServerSideGroup && options.getServerSideGroupKey ? null : ssrmWarning;
        }
      }
      return null;
    }
  },
  masterDetail: { module: "@ag-grid-enterprise/master-detail" /* MasterDetailModule */ },
  enableRangeSelection: { module: "@ag-grid-enterprise/range-selection" /* RangeSelectionModule */ },
  enableRangeHandle: {
    dependencies: {
      enableRangeSelection: [true]
    }
  },
  enableFillHandle: {
    dependencies: {
      enableRangeSelection: [true]
    }
  },
  groupDefaultExpanded: {
    supportedRowModels: ["clientSide"]
  },
  groupIncludeFooter: {
    supportedRowModels: ["clientSide", "serverSide"],
    dependencies: (options) => {
      const rowModel = options.rowModelType ?? "clientSide";
      switch (rowModel) {
        case "clientSide":
          return null;
        case "serverSide": {
          const warning = "groupIncludeFooter is not supported alongside suppressServerSideInfiniteScroll";
          return options.suppressServerSideInfiniteScroll ? warning : null;
        }
      }
      return null;
    }
  },
  groupHideOpenParents: {
    supportedRowModels: ["clientSide"],
    dependencies: {
      groupTotalRow: [void 0, "bottom"]
    }
  },
  groupIncludeTotalFooter: {
    supportedRowModels: ["clientSide"]
  },
  groupRemoveSingleChildren: {
    dependencies: {
      groupHideOpenParents: [void 0, false],
      groupRemoveLowestSingleChildren: [void 0, false]
    }
  },
  groupRemoveLowestSingleChildren: {
    dependencies: {
      groupHideOpenParents: [void 0, false],
      groupRemoveSingleChildren: [void 0, false]
    }
  },
  groupSelectsChildren: {
    dependencies: {
      rowSelection: ["multiple"]
    }
  },
  viewportDatasource: {
    supportedRowModels: ["viewport"],
    module: "@ag-grid-enterprise/viewport-row-model" /* ViewportRowModelModule */
  },
  serverSideDatasource: {
    supportedRowModels: ["serverSide"],
    module: "@ag-grid-enterprise/server-side-row-model" /* ServerSideRowModelModule */
  },
  cacheBlockSize: {
    supportedRowModels: ["serverSide", "infinite"]
  },
  datasource: {
    supportedRowModels: ["infinite"],
    module: "@ag-grid-community/infinite-row-model" /* InfiniteRowModelModule */
  },
  rowData: {
    supportedRowModels: ["clientSide"],
    module: "@ag-grid-community/client-side-row-model" /* ClientSideRowModelModule */
  },
  columnDefs: () => COL_DEF_VALIDATORS,
  defaultColDef: () => COL_DEF_VALIDATORS,
  defaultColGroupDef: () => COL_DEF_VALIDATORS,
  autoGroupColumnDef: () => COL_DEF_VALIDATORS
});
var GRID_OPTIONS_VALIDATORS = () => ({
  objectName: "gridOptions",
  allProperties: [...PropertyKeys.ALL_PROPERTIES, ...ComponentUtil.EVENT_CALLBACKS],
  propertyExceptions: ["api"],
  docsUrl: "grid-options/",
  deprecations: GRID_OPTION_DEPRECATIONS(),
  validations: GRID_OPTION_VALIDATIONS()
});

// community-modules/core/src/validation/validationService.ts
var ValidationService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "validationService";
  }
  wireBeans(beans) {
    this.beans = beans;
    this.gridOptions = beans.gridOptions;
  }
  postConstruct() {
    this.processGridOptions(this.gridOptions);
  }
  processGridOptions(options) {
    this.processOptions(options, GRID_OPTIONS_VALIDATORS());
  }
  processColumnDefs(options) {
    this.processOptions(options, COL_DEF_VALIDATORS);
  }
  warnMissingApiFunction(functionName) {
    warnMissingApiFunction(functionName, this.gridId);
  }
  validateApiFunction(functionName, apiFunction) {
    return validateApiFunction(functionName, apiFunction, this.beans);
  }
  processOptions(options, validator) {
    const { validations, deprecations, allProperties, propertyExceptions, objectName, docsUrl } = validator;
    if (allProperties && this.gridOptions.suppressPropertyNamesCheck !== true) {
      this.checkProperties(
        options,
        [...propertyExceptions ?? [], ...Object.keys(deprecations)],
        allProperties,
        objectName,
        docsUrl
      );
    }
    const warnings = /* @__PURE__ */ new Set();
    const optionKeys = Object.keys(options);
    optionKeys.forEach((key) => {
      const deprecation = deprecations[key];
      if (deprecation) {
        if ("renamed" in deprecation) {
          const { renamed, version } = deprecation;
          warnings.add(
            `As of v${version}, ${String(key)} is deprecated. Please use ${String(renamed)} instead.`
          );
          options[renamed] = options[key];
        } else {
          const { message, version } = deprecation;
          warnings.add(`As of v${version}, ${String(key)} is deprecated. ${message ?? ""}`);
        }
      }
      const value = options[key];
      if (value == null || value === false) {
        return;
      }
      const rulesOrGetter = validations[key];
      let rules;
      if (!rulesOrGetter) {
        return;
      } else if (typeof rulesOrGetter === "function") {
        const fromGetter = rulesOrGetter(options, this.gridOptions);
        if (!fromGetter) {
          return;
        }
        if ("objectName" in fromGetter) {
          const value2 = options[key];
          if (Array.isArray(value2)) {
            value2.forEach((item) => {
              this.processOptions(item, fromGetter);
            });
            return;
          }
          this.processOptions(options[key], fromGetter);
          return;
        }
        rules = fromGetter;
      } else {
        rules = rulesOrGetter;
      }
      const { module, dependencies, supportedRowModels } = rules;
      if (supportedRowModels) {
        const rowModel = this.gridOptions.rowModelType ?? "clientSide";
        if (!supportedRowModels.includes(rowModel)) {
          warnings.add(`${String(key)} is not supported with the '${rowModel}' row model.`);
          return;
        }
      }
      if (module) {
        const modules = Array.isArray(module) ? module : [module];
        let allRegistered = true;
        modules.forEach((m) => {
          if (!ModuleRegistry.__assertRegistered(m, String(key), this.gridId)) {
            allRegistered = false;
            warnings.add(`${String(key)} is only available when ${m} is loaded.`);
          }
        });
        if (!allRegistered) {
          return;
        }
      }
      if (dependencies) {
        const warning = this.checkForWarning(key, dependencies, options);
        if (warning) {
          warnings.add(warning);
          return;
        }
      }
    });
    if (warnings.size > 0) {
      warnings.forEach((warning) => {
        _warnOnce(warning);
      });
    }
  }
  checkForWarning(key, validator, options) {
    if (typeof validator === "function") {
      return validator(options, this.gridOptions);
    }
    const optionEntries = Object.entries(validator);
    const failed = optionEntries.find(([key2, value]) => {
      const gridOptionValue = options[key2];
      return !value.includes(gridOptionValue);
    });
    if (!failed) {
      return null;
    }
    const [failedKey, possibleOptions] = failed;
    if (possibleOptions.length > 1) {
      return `'${String(key)}' requires '${failedKey}' to be one of [${possibleOptions.join(", ")}].`;
    }
    return `'${String(key)}' requires '${failedKey}' to be ${possibleOptions[0]}.`;
  }
  checkProperties(object, exceptions, validProperties, containerName, docsUrl) {
    const VUE_FRAMEWORK_PROPS = ["__ob__", "__v_skip", "__metadata__"];
    const invalidProperties = _fuzzyCheckStrings(
      Object.getOwnPropertyNames(object),
      [...VUE_FRAMEWORK_PROPS, ...exceptions, ...validProperties],
      validProperties
    );
    _iterateObject(invalidProperties, (key, value) => {
      let message = `invalid ${containerName} property '${key}' did you mean any of these: ${value.slice(0, 8).join(", ")}.`;
      if (validProperties.includes("context")) {
        message += `
If you are trying to annotate ${containerName} with application data, use the '${containerName}.context' property instead.`;
      }
      _warnOnce(message);
    });
    if (Object.keys(invalidProperties).length > 0 && docsUrl) {
      const url = this.getFrameworkOverrides().getDocLink(docsUrl);
      _warnOnce(`to see all the valid ${containerName} properties please check: ${url}`);
    }
  }
};

// community-modules/core/src/gridCoreModule.ts
var GridCoreModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/core" /* CommunityCoreModule */
};
var ValidationsModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/core-validations",
  beans: [ValidationService]
};
var CommunityFeaturesModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/core-community-features",
  dependantModules: [
    GridCoreModule,
    ValidationsModule,
    EditModule,
    FilterModule,
    StateModule,
    DataTypeModule,
    AlignedGridsModule,
    PaginationModule,
    CommunityApiModule
  ]
};

// community-modules/core/src/gridDestroyService.ts
var GridDestroyService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "gridDestroyService";
    this.destroyCalled = false;
  }
  wireBeans(beans) {
    this.beans = beans;
  }
  destroy() {
    if (this.destroyCalled) {
      return;
    }
    const event = {
      type: "gridPreDestroyed",
      state: this.beans.stateService?.getState() ?? {}
    };
    this.eventService.dispatchEvent(event);
    this.destroyCalled = true;
    this.beans.ctrlsService.get("gridCtrl")?.destroyGridUi();
    this.beans.context.destroy();
    super.destroy();
  }
  isDestroyCalled() {
    return this.destroyCalled;
  }
};

// community-modules/core/src/events.ts
var ALWAYS_SYNC_GLOBAL_EVENTS = /* @__PURE__ */ new Set(["gridPreDestroyed", "fillStart", "pasteStart"]);
var isSelectionUIEvent = (source) => {
  return source === "checkboxSelected" || source === "rowClicked" || source === "spaceKey" || source === "uiSelectAll" || source === "uiSelectAllCurrentPage" || source === "uiSelectAllFiltered";
};

// community-modules/core/src/gridOptionsService.ts
var PROPERTY_COERCIONS = new Map([
  ...PropertyKeys.BOOLEAN_PROPERTIES.map((key) => [key, toBoolean]),
  ...PropertyKeys.NUMBER_PROPERTIES.map((key) => [key, toNumber]),
  ["groupAggFiltering", (val) => typeof val === "function" ? val : toBoolean(val)],
  ["pageSize", toConstrainedNum(1)],
  ["autoSizePadding", toConstrainedNum(0)],
  ["keepDetailRowsCount", toConstrainedNum(1)],
  ["rowBuffer", toConstrainedNum(0)],
  ["infiniteInitialRowCount", toConstrainedNum(1)],
  ["cacheOverflowSize", toConstrainedNum(1)],
  ["cacheBlockSize", toConstrainedNum(1)],
  ["serverSideInitialRowCount", toConstrainedNum(1)],
  ["viewportRowModelPageSize", toConstrainedNum(1)],
  ["viewportRowModelBufferSize", toConstrainedNum(0)]
]);
function getCoercedValue(key, value) {
  const coerceFunc = PROPERTY_COERCIONS.get(key);
  if (!coerceFunc) {
    return value;
  }
  return coerceFunc(value);
}
function getCoercedGridOptions(gridOptions) {
  const newGo = {};
  Object.entries(gridOptions).forEach(([key, value]) => {
    const coercedValue = getCoercedValue(key, value);
    newGo[key] = coercedValue;
  });
  return newGo;
}
var _GridOptionsService = class _GridOptionsService extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "gos";
    this.domDataKey = "__AG_" + Math.random().toString();
    this.propertyEventService = new LocalEventService();
    // responsible for calling the onXXX functions on gridOptions
    // It forces events defined in GridOptionsService.alwaysSyncGlobalEvents to be fired synchronously.
    // This is required for events such as GridPreDestroyed.
    // Other events can be fired asynchronously or synchronously depending on config.
    this.globalEventHandlerFactory = (restrictToSyncOnly) => {
      return (eventName, event) => {
        if (!this.isAlive()) {
          return;
        }
        const alwaysSync = ALWAYS_SYNC_GLOBAL_EVENTS.has(eventName);
        if (alwaysSync && !restrictToSyncOnly || !alwaysSync && restrictToSyncOnly) {
          return;
        }
        const eventHandlerName = ComponentUtil.getCallbackForEvent(eventName);
        const eventHandler = this.gridOptions[eventHandlerName];
        if (typeof eventHandler === "function") {
          this.frameworkOverrides.wrapOutgoing(() => {
            eventHandler(event);
          });
        }
      };
    };
  }
  wireBeans(beans) {
    this.gridOptions = beans.gridOptions;
    this.eGridDiv = beans.eGridDiv;
    this.validationService = beans.validationService;
    this.environment = beans.environment;
    this.api = beans.gridApi;
  }
  // This is quicker then having code call gridOptionsService.get('context')
  get gridOptionsContext() {
    return this.gridOptions["context"];
  }
  postConstruct() {
    const async = !this.get("suppressAsyncEvents");
    this.eventService.addGlobalListener(this.globalEventHandlerFactory().bind(this), async);
    this.eventService.addGlobalListener(this.globalEventHandlerFactory(true).bind(this), false);
    this.propertyEventService.setFrameworkOverrides(this.frameworkOverrides);
    this.getScrollbarWidth();
    this.addManagedEventListeners({
      gridOptionsChanged: ({ options }) => {
        this.updateGridOptions({ options, force: true, source: "gridOptionsUpdated" });
      }
    });
  }
  /**
   * Get the raw value of the GridOptions property provided.
   * @param property
   */
  get(property) {
    return this.gridOptions[property] ?? GRID_OPTION_DEFAULTS[property];
  }
  /**
   * Get the GridOption callback but wrapped so that the common params of api and context are automatically applied to the params.
   * @param property GridOption callback properties based on the fact that this property has a callback with params extending AgGridCommon
   */
  getCallback(property) {
    return this.mergeGridCommonParams(this.gridOptions[property]);
  }
  /**
   * Returns `true` if a value has been specified for this GridOption.
   * @param property GridOption property
   */
  exists(property) {
    return _exists(this.gridOptions[property]);
  }
  /**
   * Wrap the user callback and attach the api and context to the params object on the way through.
   * @param callback User provided callback
   * @returns Wrapped callback where the params object not require api and context
   */
  mergeGridCommonParams(callback) {
    if (callback) {
      const wrapped = (callbackParams) => {
        const mergedParams = callbackParams;
        mergedParams.api = this.api;
        mergedParams.context = this.gridOptionsContext;
        return callback(mergedParams);
      };
      return wrapped;
    }
    return callback;
  }
  updateGridOptions({
    options,
    force,
    source = "api"
  }) {
    const changeSet = { id: _GridOptionsService.changeSetId++, properties: [] };
    const events = [];
    Object.entries(options).forEach(([key, value]) => {
      if (source === "api" && INITIAL_GRID_OPTION_KEYS[key]) {
        _warnOnce(`${key} is an initial property and cannot be updated.`);
      }
      const coercedValue = getCoercedValue(key, value);
      const shouldForce = force || typeof coercedValue === "object" && source === "api";
      const previousValue = this.gridOptions[key];
      if (shouldForce || previousValue !== coercedValue) {
        this.gridOptions[key] = coercedValue;
        const event = {
          type: key,
          currentValue: coercedValue,
          previousValue,
          changeSet,
          source
        };
        events.push(event);
      }
    });
    this.validationService?.processGridOptions(this.gridOptions);
    changeSet.properties = events.map((event) => event.type);
    events.forEach((event) => {
      if (this.gridOptions.debug) {
        _log(`Updated property ${event.type} from`, event.previousValue, ` to `, event.currentValue);
      }
      this.propertyEventService.dispatchEvent(event);
    });
  }
  addPropertyEventListener(key, listener) {
    this.propertyEventService.addEventListener(key, listener);
  }
  removePropertyEventListener(key, listener) {
    this.propertyEventService.removeEventListener(key, listener);
  }
  // *************** Helper methods ************************** //
  // Methods to share common GridOptions related logic that goes above accessing a single property
  // the user might be using some non-standard scrollbar, eg a scrollbar that has zero
  // width and overlays (like the Safari scrollbar, but presented in Chrome). so we
  // allow the user to provide the scroll width before we work it out.
  getScrollbarWidth() {
    if (this.scrollbarWidth == null) {
      const useGridOptions = typeof this.gridOptions.scrollbarWidth === "number" && this.gridOptions.scrollbarWidth >= 0;
      const scrollbarWidth = useGridOptions ? this.gridOptions.scrollbarWidth : _getScrollbarWidth();
      if (scrollbarWidth != null) {
        this.scrollbarWidth = scrollbarWidth;
        this.eventService.dispatchEvent({
          type: "scrollbarWidthChanged"
        });
      }
    }
    return this.scrollbarWidth;
  }
  isRowModelType(rowModelType) {
    return this.gridOptions.rowModelType === rowModelType || rowModelType === "clientSide" && _missing(this.gridOptions.rowModelType);
  }
  isDomLayout(domLayout) {
    const gridLayout = this.gridOptions.domLayout ?? "normal";
    return gridLayout === domLayout;
  }
  isRowSelection() {
    return this.gridOptions.rowSelection === "single" || this.gridOptions.rowSelection === "multiple";
  }
  useAsyncEvents() {
    return !this.get("suppressAsyncEvents");
  }
  isGetRowHeightFunction() {
    return typeof this.gridOptions.getRowHeight === "function";
  }
  getRowHeightForNode(rowNode, allowEstimate = false, defaultRowHeight) {
    if (defaultRowHeight == null) {
      defaultRowHeight = this.environment.getDefaultRowHeight();
    }
    if (this.isGetRowHeightFunction()) {
      if (allowEstimate) {
        return { height: defaultRowHeight, estimated: true };
      }
      const params = {
        node: rowNode,
        data: rowNode.data
      };
      const height = this.getCallback("getRowHeight")(params);
      if (this.isNumeric(height)) {
        if (height === 0) {
          _warnOnce(
            "The return of `getRowHeight` cannot be zero. If the intention is to hide rows, use a filter instead."
          );
        }
        return { height: Math.max(1, height), estimated: false };
      }
    }
    if (rowNode.detail && this.get("masterDetail")) {
      return this.getMasterDetailRowHeight();
    }
    const rowHeight = this.gridOptions.rowHeight && this.isNumeric(this.gridOptions.rowHeight) ? this.gridOptions.rowHeight : defaultRowHeight;
    return { height: rowHeight, estimated: false };
  }
  getMasterDetailRowHeight() {
    if (this.get("detailRowAutoHeight")) {
      return { height: 1, estimated: false };
    }
    if (this.isNumeric(this.gridOptions.detailRowHeight)) {
      return { height: this.gridOptions.detailRowHeight, estimated: false };
    }
    return { height: 300, estimated: false };
  }
  // we don't allow dynamic row height for virtual paging
  getRowHeightAsNumber() {
    if (!this.gridOptions.rowHeight || _missing(this.gridOptions.rowHeight)) {
      return this.environment.getDefaultRowHeight();
    }
    const rowHeight = this.environment.refreshRowHeightVariable();
    if (rowHeight !== -1) {
      return rowHeight;
    }
    _warnOnce("row height must be a number if not using standard row model");
    return this.environment.getDefaultRowHeight();
  }
  isNumeric(value) {
    return !isNaN(value) && typeof value === "number" && isFinite(value);
  }
  getDomDataKey() {
    return this.domDataKey;
  }
  // returns the dom data, or undefined if not found
  getDomData(element, key) {
    const domData = element[this.getDomDataKey()];
    return domData ? domData[key] : void 0;
  }
  setDomData(element, key, value) {
    const domDataKey = this.getDomDataKey();
    let domData = element[domDataKey];
    if (_missing(domData)) {
      domData = {};
      element[domDataKey] = domData;
    }
    domData[key] = value;
  }
  getDocument() {
    let result = null;
    if (this.gridOptions.getDocument && _exists(this.gridOptions.getDocument)) {
      result = this.gridOptions.getDocument();
    } else if (this.eGridDiv) {
      result = this.eGridDiv.ownerDocument;
    }
    if (result && _exists(result)) {
      return result;
    }
    return document;
  }
  getWindow() {
    const eDocument = this.getDocument();
    return eDocument.defaultView || window;
  }
  getRootNode() {
    return this.eGridDiv.getRootNode();
  }
  getActiveDomElement() {
    return this.getRootNode().activeElement;
  }
  getAsyncTransactionWaitMillis() {
    return _exists(this.gridOptions.asyncTransactionWaitMillis) ? this.gridOptions.asyncTransactionWaitMillis : 50;
  }
  isAnimateRows() {
    if (this.get("ensureDomOrder")) {
      return false;
    }
    return this.get("animateRows");
  }
  isGroupRowsSticky() {
    if (this.get("paginateChildRows") || this.get("groupHideOpenParents") || this.isDomLayout("print")) {
      return false;
    }
    return true;
  }
  isColumnsSortingCoupledToGroup() {
    const autoGroupColumnDef = this.gridOptions.autoGroupColumnDef;
    return !autoGroupColumnDef?.comparator && !this.get("treeData");
  }
  getGroupAggFiltering() {
    const userValue = this.gridOptions.groupAggFiltering;
    if (typeof userValue === "function") {
      return this.getCallback("groupAggFiltering");
    }
    if (userValue === true) {
      return () => true;
    }
    return void 0;
  }
  getGrandTotalRow() {
    const userValue = this.gridOptions.grandTotalRow;
    if (userValue) {
      return userValue;
    }
    const legacyValue = this.gridOptions.groupIncludeTotalFooter;
    if (legacyValue) {
      return "bottom";
    }
    return void 0;
  }
  getGroupTotalRowCallback() {
    const userValue = this.get("groupTotalRow");
    if (typeof userValue === "function") {
      return this.getCallback("groupTotalRow");
    }
    if (userValue) {
      return () => userValue;
    }
    const legacyValue = this.get("groupIncludeFooter");
    if (typeof legacyValue === "function") {
      const legacyCallback = this.getCallback("groupIncludeFooter");
      return (p) => {
        return legacyCallback(p) ? "bottom" : void 0;
      };
    }
    return () => legacyValue ? "bottom" : void 0;
  }
  isGroupMultiAutoColumn() {
    if (this.gridOptions.groupDisplayType) {
      return this.gridOptions.groupDisplayType === "multipleColumns";
    }
    return this.get("groupHideOpenParents");
  }
  isGroupUseEntireRow(pivotMode) {
    if (pivotMode) {
      return false;
    }
    return this.gridOptions.groupDisplayType === "groupRows";
  }
  getGridCommonParams() {
    return {
      api: this.api,
      context: this.gridOptionsContext
    };
  }
  addGridCommonParams(params) {
    const updatedParams = params;
    updatedParams.api = this.api;
    updatedParams.context = this.gridOptionsContext;
    return updatedParams;
  }
  // AG-9259 Can't use `WrappedCallback<'getRowId', ...>` here because of a strange typescript bug
  getRowIdCallback() {
    const getRowId = this.getCallback("getRowId");
    if (getRowId === void 0) {
      return getRowId;
    }
    return (params) => {
      let id = getRowId(params);
      if (typeof id !== "string") {
        _warnOnce(`The getRowId callback must return a string. The ID ${id} is being cast to a string.`);
        id = String(id);
      }
      return id;
    };
  }
};
_GridOptionsService.changeSetId = 0;
var GridOptionsService = _GridOptionsService;

// community-modules/core/src/headerRendering/common/headerPosition.ts
var HeaderPositionUtils = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "headerPositionUtils";
  }
  wireBeans(beans) {
    this.visibleColsService = beans.visibleColsService;
    this.ctrlsService = beans.ctrlsService;
  }
  findHeader(focusedHeader, direction) {
    let nextColumn;
    let getColMethod;
    if (isColumnGroup(focusedHeader.column)) {
      nextColumn = this.visibleColsService.getGroupAtDirection(focusedHeader.column, direction);
    } else {
      getColMethod = `getCol${direction}`;
      nextColumn = this.visibleColsService[getColMethod](focusedHeader.column);
    }
    if (!nextColumn) {
      return;
    }
    const { headerRowIndex } = focusedHeader;
    if (this.getHeaderRowType(headerRowIndex) !== "filter" /* FLOATING_FILTER */) {
      const columnsInPath = [nextColumn];
      while (nextColumn.getParent()) {
        nextColumn = nextColumn.getParent();
        columnsInPath.push(nextColumn);
      }
      nextColumn = columnsInPath[columnsInPath.length - 1 - headerRowIndex];
    }
    const { column, headerRowIndex: indexToFocus } = this.getHeaderIndexToFocus(nextColumn, headerRowIndex);
    return {
      column,
      headerRowIndex: indexToFocus
    };
  }
  getHeaderIndexToFocus(column, currentIndex) {
    let nextColumn;
    if (isColumnGroup(column) && this.isAnyChildSpanningHeaderHeight(column) && column.isPadding()) {
      const targetColumn = column;
      nextColumn = targetColumn.getLeafColumns()[0];
      let col = nextColumn;
      while (col !== targetColumn) {
        currentIndex++;
        col = col.getParent();
      }
    }
    return {
      column: nextColumn || column,
      headerRowIndex: currentIndex
    };
  }
  isAnyChildSpanningHeaderHeight(columnGroup) {
    if (!columnGroup) {
      return false;
    }
    return columnGroup.getLeafColumns().some((col) => col.isSpanHeaderHeight());
  }
  getColumnVisibleParent(currentColumn, currentIndex) {
    const currentRowType = this.getHeaderRowType(currentIndex);
    const isFloatingFilter = currentRowType === "filter" /* FLOATING_FILTER */;
    const isColumn2 = currentRowType === "column" /* COLUMN */;
    let nextFocusColumn = isFloatingFilter ? currentColumn : currentColumn.getParent();
    let nextRow = currentIndex - 1;
    let headerRowIndexWithoutSpan = nextRow;
    if (isColumn2 && this.isAnyChildSpanningHeaderHeight(currentColumn.getParent())) {
      while (nextFocusColumn && nextFocusColumn.isPadding()) {
        nextFocusColumn = nextFocusColumn.getParent();
        nextRow--;
      }
      headerRowIndexWithoutSpan = nextRow;
      if (nextRow < 0) {
        nextFocusColumn = currentColumn;
        nextRow = currentIndex;
        headerRowIndexWithoutSpan = void 0;
      }
    }
    return { column: nextFocusColumn, headerRowIndex: nextRow, headerRowIndexWithoutSpan };
  }
  getColumnVisibleChild(column, currentIndex, direction = "After") {
    const currentRowType = this.getHeaderRowType(currentIndex);
    let nextFocusColumn = column;
    let nextRow = currentIndex + 1;
    const headerRowIndexWithoutSpan = nextRow;
    if (currentRowType === "group" /* COLUMN_GROUP */) {
      const leafColumns = column.getDisplayedLeafColumns();
      const leafColumn = direction === "After" ? leafColumns[0] : _last(leafColumns);
      const columnsInTheWay = [];
      let currentColumn = leafColumn;
      while (currentColumn.getParent() !== column) {
        currentColumn = currentColumn.getParent();
        columnsInTheWay.push(currentColumn);
      }
      nextFocusColumn = leafColumn;
      if (leafColumn.isSpanHeaderHeight()) {
        for (let i = columnsInTheWay.length - 1; i >= 0; i--) {
          const colToFocus = columnsInTheWay[i];
          if (!colToFocus.isPadding()) {
            nextFocusColumn = colToFocus;
            break;
          }
          nextRow++;
        }
      } else {
        nextFocusColumn = _last(columnsInTheWay);
        if (!nextFocusColumn) {
          nextFocusColumn = leafColumn;
        }
      }
    }
    return { column: nextFocusColumn, headerRowIndex: nextRow, headerRowIndexWithoutSpan };
  }
  getHeaderRowType(rowIndex) {
    const centerHeaderContainer = this.ctrlsService.getHeaderRowContainerCtrl();
    if (centerHeaderContainer) {
      return centerHeaderContainer.getRowType(rowIndex);
    }
  }
  findColAtEdgeForHeaderRow(level, position) {
    const displayedColumns = this.visibleColsService.getAllCols();
    const column = displayedColumns[position === "start" ? 0 : displayedColumns.length - 1];
    if (!column) {
      return;
    }
    const childContainer = this.ctrlsService.getHeaderRowContainerCtrl(column.getPinned());
    const type = childContainer.getRowType(level);
    if (type == "group" /* COLUMN_GROUP */) {
      const columnGroup = this.visibleColsService.getColGroupAtLevel(column, level);
      return {
        headerRowIndex: level,
        column: columnGroup
      };
    }
    return {
      // if type==null, means the header level didn't exist
      headerRowIndex: type == null ? -1 : level,
      column
    };
  }
};

// community-modules/core/src/localeService.ts
var LocaleService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "localeService";
  }
  getLocaleTextFunc() {
    const getLocaleText = this.gos.getCallback("getLocaleText");
    if (getLocaleText) {
      return (key, defaultValue, variableValues) => {
        const params = {
          key,
          defaultValue,
          variableValues
        };
        return getLocaleText(params);
      };
    }
    const localeText = this.gos.get("localeText");
    return (key, defaultValue, variableValues) => {
      let localisedText = localeText && localeText[key];
      if (localisedText && variableValues && variableValues.length) {
        let found = 0;
        while (true) {
          if (found >= variableValues.length) {
            break;
          }
          const idx = localisedText.indexOf("${variable}");
          if (idx === -1) {
            break;
          }
          localisedText = localisedText.replace("${variable}", variableValues[found++]);
        }
      }
      return localisedText ?? defaultValue;
    };
  }
};

// community-modules/core/src/misc/apiEventService.ts
var ApiEventService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "apiEventService";
    this.syncEventListeners = /* @__PURE__ */ new Map();
    this.asyncEventListeners = /* @__PURE__ */ new Map();
    this.syncGlobalEventListeners = /* @__PURE__ */ new Set();
    this.globalEventListenerPairs = /* @__PURE__ */ new Map();
  }
  postConstruct() {
    this.frameworkEventWrappingService = new FrameworkEventListenerService(this.getFrameworkOverrides());
  }
  addEventListener(eventType, userListener) {
    const listener = this.frameworkEventWrappingService.wrap(userListener);
    const async = this.gos.useAsyncEvents() && !ALWAYS_SYNC_GLOBAL_EVENTS.has(eventType);
    const listeners = async ? this.asyncEventListeners : this.syncEventListeners;
    if (!listeners.has(eventType)) {
      listeners.set(eventType, /* @__PURE__ */ new Set());
    }
    listeners.get(eventType).add(listener);
    this.eventService.addEventListener(eventType, listener, async);
  }
  removeEventListener(eventType, userListener) {
    const listener = this.frameworkEventWrappingService.unwrap(userListener);
    const asyncListeners = this.asyncEventListeners.get(eventType);
    const hasAsync = !!asyncListeners?.delete(listener);
    if (!hasAsync) {
      this.syncEventListeners.get(eventType)?.delete(listener);
    }
    this.eventService.removeEventListener(eventType, listener, hasAsync);
  }
  addGlobalListener(userListener) {
    const listener = this.frameworkEventWrappingService.wrapGlobal(userListener);
    const async = this.gos.useAsyncEvents();
    if (async) {
      const syncListener = (eventType, event) => {
        if (ALWAYS_SYNC_GLOBAL_EVENTS.has(eventType)) {
          listener(eventType, event);
        }
      };
      const asyncListener = (eventType, event) => {
        if (!ALWAYS_SYNC_GLOBAL_EVENTS.has(eventType)) {
          listener(eventType, event);
        }
      };
      this.globalEventListenerPairs.set(userListener, { syncListener, asyncListener });
      this.eventService.addGlobalListener(syncListener, false);
      this.eventService.addGlobalListener(asyncListener, true);
    } else {
      this.syncGlobalEventListeners.add(listener);
      this.eventService.addGlobalListener(listener, false);
    }
  }
  removeGlobalListener(userListener) {
    const listener = this.frameworkEventWrappingService.unwrapGlobal(userListener);
    const hasAsync = this.globalEventListenerPairs.has(listener);
    if (hasAsync) {
      const { syncListener, asyncListener } = this.globalEventListenerPairs.get(listener);
      this.eventService.removeGlobalListener(syncListener, false);
      this.eventService.removeGlobalListener(asyncListener, true);
      this.globalEventListenerPairs.delete(userListener);
    } else {
      this.syncGlobalEventListeners.delete(listener);
      this.eventService.removeGlobalListener(listener, false);
    }
  }
  destroyEventListeners(map, async) {
    map.forEach((listeners, eventType) => {
      listeners.forEach((listener) => this.eventService.removeEventListener(eventType, listener, async));
      listeners.clear();
    });
    map.clear();
  }
  destroyGlobalListeners(set, async) {
    set.forEach((listener) => this.eventService.removeGlobalListener(listener, async));
    set.clear();
  }
  destroy() {
    super.destroy();
    this.destroyEventListeners(this.syncEventListeners, false);
    this.destroyEventListeners(this.asyncEventListeners, true);
    this.destroyGlobalListeners(this.syncGlobalEventListeners, false);
    this.globalEventListenerPairs.forEach(({ syncListener, asyncListener }) => {
      this.eventService.removeGlobalListener(syncListener, false);
      this.eventService.removeGlobalListener(asyncListener, true);
    });
    this.globalEventListenerPairs.clear();
  }
};

// community-modules/core/src/pagination/pageBoundsListener.ts
var PageBoundsListener = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "pageBoundsListener";
  }
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
    this.paginationService = beans.paginationService;
    this.pageBoundsService = beans.pageBoundsService;
  }
  postConstruct() {
    this.addManagedEventListeners({
      modelUpdated: this.onModelUpdated.bind(this),
      recalculateRowBounds: this.calculatePages.bind(this)
    });
    this.onModelUpdated();
  }
  onModelUpdated(modelUpdatedEvent) {
    this.calculatePages();
    const paginationChangedEvent = {
      type: "paginationChanged",
      animate: modelUpdatedEvent ? modelUpdatedEvent.animate : false,
      newData: modelUpdatedEvent ? modelUpdatedEvent.newData : false,
      newPage: modelUpdatedEvent ? modelUpdatedEvent.newPage : false,
      newPageSize: modelUpdatedEvent ? modelUpdatedEvent.newPageSize : false,
      keepRenderedRows: modelUpdatedEvent ? modelUpdatedEvent.keepRenderedRows : false
    };
    this.eventService.dispatchEvent(paginationChangedEvent);
  }
  calculatePages() {
    if (this.paginationService) {
      this.paginationService.calculatePages();
    } else {
      this.pageBoundsService.calculateBounds(0, this.rowModel.getRowCount() - 1);
    }
  }
};

// community-modules/core/src/pagination/pageBoundsService.ts
var PageBoundsService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "pageBoundsService";
    this.pixelOffset = 0;
  }
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
  }
  getFirstRow() {
    return this.topRowBounds ? this.topRowBounds.rowIndex : -1;
  }
  getLastRow() {
    return this.bottomRowBounds ? this.bottomRowBounds.rowIndex : -1;
  }
  getCurrentPageHeight() {
    if (_missing(this.topRowBounds) || _missing(this.bottomRowBounds)) {
      return 0;
    }
    return Math.max(this.bottomRowBounds.rowTop + this.bottomRowBounds.rowHeight - this.topRowBounds.rowTop, 0);
  }
  getCurrentPagePixelRange() {
    const pageFirstPixel = this.topRowBounds ? this.topRowBounds.rowTop : 0;
    const pageLastPixel = this.bottomRowBounds ? this.bottomRowBounds.rowTop + this.bottomRowBounds.rowHeight : 0;
    return { pageFirstPixel, pageLastPixel };
  }
  calculateBounds(topDisplayedRowIndex, bottomDisplayedRowIndex) {
    this.topRowBounds = this.rowModel.getRowBounds(topDisplayedRowIndex);
    if (this.topRowBounds) {
      this.topRowBounds.rowIndex = topDisplayedRowIndex;
    }
    this.bottomRowBounds = this.rowModel.getRowBounds(bottomDisplayedRowIndex);
    if (this.bottomRowBounds) {
      this.bottomRowBounds.rowIndex = bottomDisplayedRowIndex;
    }
    this.calculatePixelOffset();
  }
  getPixelOffset() {
    return this.pixelOffset;
  }
  calculatePixelOffset() {
    const value = _exists(this.topRowBounds) ? this.topRowBounds.rowTop : 0;
    if (this.pixelOffset === value) {
      return;
    }
    this.pixelOffset = value;
    this.eventService.dispatchEvent({ type: "paginationPixelOffsetChanged" });
  }
};

// community-modules/core/src/rendering/ariaAnnouncementService.ts
var AriaAnnouncementService = class extends BeanStub {
  constructor() {
    super();
    this.beanName = "ariaAnnouncementService";
    this.descriptionContainer = null;
    this.announceValue = _debounce(this.announceValue.bind(this), 200);
  }
  wireBeans(beans) {
    this.eGridDiv = beans.eGridDiv;
  }
  postConstruct() {
    const eDocument = this.gos.getDocument();
    const div = this.descriptionContainer = eDocument.createElement("div");
    div.classList.add("ag-aria-description-container");
    _setAriaLive(div, "polite");
    _setAriaRelevant(div, "additions text");
    _setAriaAtomic(div, true);
    this.eGridDiv.appendChild(div);
  }
  announceValue(value) {
    if (!this.descriptionContainer) {
      return;
    }
    this.descriptionContainer.textContent = "";
    setTimeout(() => {
      if (this.isAlive() && this.descriptionContainer) {
        this.descriptionContainer.textContent = value;
      }
    }, 50);
  }
  destroy() {
    super.destroy();
    const { descriptionContainer } = this;
    if (descriptionContainer) {
      _clearElement(descriptionContainer);
      if (descriptionContainer.parentElement) {
        descriptionContainer.parentElement.removeChild(descriptionContainer);
      }
    }
    this.descriptionContainer = null;
    this.eGridDiv = null;
  }
};

// community-modules/core/src/rendering/columnAnimationService.ts
var ColumnAnimationService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "columnAnimationService";
    this.executeNextFuncs = [];
    this.executeLaterFuncs = [];
    this.active = false;
    this.suppressAnimation = false;
    this.animationThreadCount = 0;
  }
  wireBeans(beans) {
    this.ctrlsService = beans.ctrlsService;
  }
  postConstruct() {
    this.ctrlsService.whenReady((p) => this.gridBodyCtrl = p.gridBodyCtrl);
  }
  isActive() {
    return this.active && !this.suppressAnimation;
  }
  setSuppressAnimation(suppress) {
    this.suppressAnimation = suppress;
  }
  start() {
    if (this.active) {
      return;
    }
    if (this.gos.get("suppressColumnMoveAnimation")) {
      return;
    }
    if (this.gos.get("enableRtl")) {
      return;
    }
    this.ensureAnimationCssClassPresent();
    this.active = true;
  }
  finish() {
    if (!this.active) {
      return;
    }
    this.flush(() => {
      this.active = false;
    });
  }
  executeNextVMTurn(func) {
    if (this.active) {
      this.executeNextFuncs.push(func);
    } else {
      func();
    }
  }
  executeLaterVMTurn(func) {
    if (this.active) {
      this.executeLaterFuncs.push(func);
    } else {
      func();
    }
  }
  ensureAnimationCssClassPresent() {
    this.animationThreadCount++;
    const animationThreadCountCopy = this.animationThreadCount;
    this.gridBodyCtrl.setColumnMovingCss(true);
    this.executeLaterFuncs.push(() => {
      if (this.animationThreadCount === animationThreadCountCopy) {
        this.gridBodyCtrl.setColumnMovingCss(false);
      }
    });
  }
  flush(callback) {
    if (this.executeNextFuncs.length === 0 && this.executeLaterFuncs.length === 0) {
      callback();
      return;
    }
    const runFuncs = (queue) => {
      while (queue.length) {
        const func = queue.pop();
        if (func) {
          func();
        }
      }
    };
    this.getFrameworkOverrides().wrapIncoming(() => {
      window.setTimeout(() => runFuncs(this.executeNextFuncs), 0);
      window.setTimeout(() => {
        callback();
        runFuncs(this.executeLaterFuncs);
      }, 200);
    });
  }
};

// community-modules/core/src/rendering/columnHoverService.ts
var ColumnHoverService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "columnHoverService";
  }
  setMouseOver(columns) {
    this.selectedColumns = columns;
    const event = {
      type: "columnHoverChanged"
    };
    this.eventService.dispatchEvent(event);
  }
  clearMouseOver() {
    this.selectedColumns = null;
    const event = {
      type: "columnHoverChanged"
    };
    this.eventService.dispatchEvent(event);
  }
  isHovered(column) {
    return !!this.selectedColumns && this.selectedColumns.indexOf(column) >= 0;
  }
};

// community-modules/core/src/rendering/overlays/overlayService.ts
var OverlayService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "overlayService";
    this.state = 0 /* Hidden */;
    this.showInitialOverlay = true;
  }
  wireBeans(beans) {
    this.userComponentFactory = beans.userComponentFactory;
    this.rowModel = beans.rowModel;
    this.columnModel = beans.columnModel;
  }
  postConstruct() {
    const updateOverlayVisibility = () => this.updateOverlayVisibility();
    this.addManagedEventListeners({
      newColumnsLoaded: updateOverlayVisibility,
      rowDataUpdated: updateOverlayVisibility
    });
    this.addManagedPropertyListener("loading", updateOverlayVisibility);
  }
  registerOverlayWrapperComp(overlayWrapperComp) {
    this.overlayWrapperComp = overlayWrapperComp;
    this.updateOverlayVisibility();
  }
  showLoadingOverlay() {
    this.showInitialOverlay = false;
    const loading = this.gos.get("loading");
    if (!loading && (loading !== void 0 || this.gos.get("suppressLoadingOverlay"))) {
      return;
    }
    this.doShowLoadingOverlay();
  }
  showNoRowsOverlay() {
    this.showInitialOverlay = false;
    if (this.gos.get("loading") || this.gos.get("suppressNoRowsOverlay")) {
      return;
    }
    this.doShowNoRowsOverlay();
  }
  hideOverlay() {
    this.showInitialOverlay = false;
    if (this.gos.get("loading")) {
      return;
    }
    this.doHideOverlay();
  }
  updateOverlayVisibility() {
    let loading = this.gos.get("loading");
    if (this.showInitialOverlay && loading === void 0 && !this.gos.get("suppressLoadingOverlay")) {
      loading = !this.gos.get("columnDefs") || !this.columnModel.isReady() || !this.gos.get("rowData") && this.gos.isRowModelType("clientSide");
    }
    if (loading) {
      if (this.state !== 1 /* Loading */) {
        this.doShowLoadingOverlay();
      }
    } else {
      this.showInitialOverlay = false;
      if (this.rowModel.isEmpty() && !this.gos.get("suppressNoRowsOverlay") && this.gos.isRowModelType("clientSide")) {
        if (this.state !== 2 /* NoRows */) {
          this.doShowNoRowsOverlay();
        }
      } else if (this.state !== 0 /* Hidden */) {
        this.doHideOverlay();
      }
    }
  }
  doShowLoadingOverlay() {
    this.state = 1 /* Loading */;
    this.showOverlay(
      this.userComponentFactory.getLoadingOverlayCompDetails({}),
      "ag-overlay-loading-wrapper",
      "loadingOverlayComponentParams"
    );
  }
  doShowNoRowsOverlay() {
    this.state = 2 /* NoRows */;
    this.showOverlay(
      this.userComponentFactory.getNoRowsOverlayCompDetails({}),
      "ag-overlay-no-rows-wrapper",
      "noRowsOverlayComponentParams"
    );
  }
  doHideOverlay() {
    this.state = 0 /* Hidden */;
    this.overlayWrapperComp.hideOverlay();
  }
  showOverlay(compDetails, wrapperCssClass, gridOption) {
    const promise = compDetails.newAgStackInstance();
    this.overlayWrapperComp.showOverlay(promise, wrapperCssClass, gridOption);
  }
};

// community-modules/core/src/rendering/row/rowCssClassCalculator.ts
var RowCssClassCalculator = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "rowCssClassCalculator";
  }
  wireBeans(beans) {
    this.stylingService = beans.stylingService;
  }
  getInitialRowClasses(params) {
    const classes = [];
    if (_exists(params.extraCssClass)) {
      classes.push(params.extraCssClass);
    }
    classes.push("ag-row");
    classes.push(params.rowFocused ? "ag-row-focus" : "ag-row-no-focus");
    if (params.fadeRowIn) {
      classes.push("ag-opacity-zero");
    }
    classes.push(params.rowIsEven ? "ag-row-even" : "ag-row-odd");
    if (params.rowNode.isRowPinned()) {
      classes.push("ag-row-pinned");
    }
    if (params.rowNode.isSelected()) {
      classes.push("ag-row-selected");
    }
    if (params.rowNode.footer) {
      classes.push("ag-row-footer");
    }
    classes.push("ag-row-level-" + params.rowLevel);
    if (params.rowNode.stub) {
      classes.push("ag-row-loading");
    }
    if (params.fullWidthRow) {
      classes.push("ag-full-width-row");
    }
    if (params.expandable) {
      classes.push("ag-row-group");
      classes.push(params.rowNode.expanded ? "ag-row-group-expanded" : "ag-row-group-contracted");
    }
    if (params.rowNode.dragging) {
      classes.push("ag-row-dragging");
    }
    _pushAll(classes, this.processClassesFromGridOptions(params.rowNode));
    _pushAll(classes, this.preProcessRowClassRules(params.rowNode));
    classes.push(params.printLayout ? "ag-row-position-relative" : "ag-row-position-absolute");
    if (params.firstRowOnPage) {
      classes.push("ag-row-first");
    }
    if (params.lastRowOnPage) {
      classes.push("ag-row-last");
    }
    if (params.fullWidthRow) {
      if (params.pinned === "left") {
        classes.push("ag-cell-last-left-pinned");
      }
      if (params.pinned === "right") {
        classes.push("ag-cell-first-right-pinned");
      }
    }
    return classes;
  }
  processClassesFromGridOptions(rowNode) {
    const res = [];
    const process = (rowCls) => {
      if (typeof rowCls === "string") {
        res.push(rowCls);
      } else if (Array.isArray(rowCls)) {
        rowCls.forEach((e) => res.push(e));
      }
    };
    const rowClass = this.gos.get("rowClass");
    if (rowClass) {
      if (typeof rowClass === "function") {
        _warnOnce("rowClass should not be a function, please use getRowClass instead");
        return [];
      }
      process(rowClass);
    }
    const rowClassFunc = this.gos.getCallback("getRowClass");
    if (rowClassFunc) {
      const params = {
        data: rowNode.data,
        node: rowNode,
        rowIndex: rowNode.rowIndex
      };
      const rowClassFuncResult = rowClassFunc(params);
      process(rowClassFuncResult);
    }
    return res;
  }
  preProcessRowClassRules(rowNode) {
    const res = [];
    this.processRowClassRules(
      rowNode,
      (className) => {
        res.push(className);
      },
      () => {
      }
    );
    return res;
  }
  processRowClassRules(rowNode, onApplicableClass, onNotApplicableClass) {
    const rowClassParams = this.gos.addGridCommonParams({
      data: rowNode.data,
      node: rowNode,
      rowIndex: rowNode.rowIndex
    });
    this.stylingService.processClassRules(
      void 0,
      this.gos.get("rowClassRules"),
      rowClassParams,
      onApplicableClass,
      onNotApplicableClass
    );
  }
  calculateRowLevel(rowNode) {
    if (rowNode.group) {
      return rowNode.level;
    }
    return rowNode.parent ? rowNode.parent.level + 1 : 0;
  }
};

// community-modules/core/src/rendering/rowContainerHeightService.ts
var RowContainerHeightService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "rowContainerHeightService";
    // the scrollY position
    this.scrollY = 0;
    // how tall the body is
    this.uiBodyHeight = 0;
  }
  wireBeans(beans) {
    this.ctrlsService = beans.ctrlsService;
  }
  postConstruct() {
    this.addManagedEventListeners({ bodyHeightChanged: this.updateOffset.bind(this) });
    this.maxDivHeight = _getMaxDivHeight();
    if (this.gos.get("debug")) {
      _log("RowContainerHeightService - maxDivHeight = " + this.maxDivHeight);
    }
  }
  isStretching() {
    return this.stretching;
  }
  getDivStretchOffset() {
    return this.divStretchOffset;
  }
  updateOffset() {
    if (!this.stretching) {
      return;
    }
    const gridBodyCon = this.ctrlsService.getGridBodyCtrl();
    const newScrollY = gridBodyCon.getScrollFeature().getVScrollPosition().top;
    const newBodyHeight = this.getUiBodyHeight();
    const atLeastOneChanged = newScrollY !== this.scrollY || newBodyHeight !== this.uiBodyHeight;
    if (atLeastOneChanged) {
      this.scrollY = newScrollY;
      this.uiBodyHeight = newBodyHeight;
      this.calculateOffset();
    }
  }
  calculateOffset() {
    this.setUiContainerHeight(this.maxDivHeight);
    this.pixelsToShave = this.modelHeight - this.uiContainerHeight;
    this.maxScrollY = this.uiContainerHeight - this.uiBodyHeight;
    const scrollPercent = this.scrollY / this.maxScrollY;
    const divStretchOffset = scrollPercent * this.pixelsToShave;
    if (this.gos.get("debug")) {
      _log(
        `RowContainerHeightService - Div Stretch Offset = ${divStretchOffset} (${this.pixelsToShave} * ${scrollPercent})`
      );
    }
    this.setDivStretchOffset(divStretchOffset);
  }
  setUiContainerHeight(height) {
    if (height !== this.uiContainerHeight) {
      this.uiContainerHeight = height;
      this.eventService.dispatchEvent({ type: "rowContainerHeightChanged" });
    }
  }
  clearOffset() {
    this.setUiContainerHeight(this.modelHeight);
    this.pixelsToShave = 0;
    this.setDivStretchOffset(0);
  }
  setDivStretchOffset(newOffset) {
    const newOffsetFloor = typeof newOffset === "number" ? Math.floor(newOffset) : null;
    if (this.divStretchOffset === newOffsetFloor) {
      return;
    }
    this.divStretchOffset = newOffsetFloor;
    this.eventService.dispatchEvent({ type: "heightScaleChanged" });
  }
  setModelHeight(modelHeight) {
    this.modelHeight = modelHeight;
    this.stretching = modelHeight != null && // null happens when in print layout
    this.maxDivHeight > 0 && modelHeight > this.maxDivHeight;
    if (this.stretching) {
      this.calculateOffset();
    } else {
      this.clearOffset();
    }
  }
  getUiContainerHeight() {
    return this.uiContainerHeight;
  }
  getRealPixelPosition(modelPixel) {
    return modelPixel - this.divStretchOffset;
  }
  getUiBodyHeight() {
    const gridBodyCon = this.ctrlsService.getGridBodyCtrl();
    const pos = gridBodyCon.getScrollFeature().getVScrollPosition();
    return pos.bottom - pos.top;
  }
  getScrollPositionForPixel(rowTop) {
    if (this.pixelsToShave <= 0) {
      return rowTop;
    }
    const modelMaxScroll = this.modelHeight - this.getUiBodyHeight();
    const scrollPercent = rowTop / modelMaxScroll;
    const scrollPixel = this.maxScrollY * scrollPercent;
    return scrollPixel;
  }
};

// community-modules/core/src/rowNodes/rowNodeSorter.ts
var RowNodeSorter = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "rowNodeSorter";
  }
  wireBeans(beans) {
    this.valueService = beans.valueService;
    this.columnModel = beans.columnModel;
    this.showRowGroupColsService = beans.showRowGroupColsService;
  }
  postConstruct() {
    this.isAccentedSort = this.gos.get("accentedSort");
    this.primaryColumnsSortGroups = this.gos.isColumnsSortingCoupledToGroup();
    this.addManagedPropertyListener(
      "accentedSort",
      (propChange) => this.isAccentedSort = propChange.currentValue
    );
    this.addManagedPropertyListener(
      "autoGroupColumnDef",
      () => this.primaryColumnsSortGroups = this.gos.isColumnsSortingCoupledToGroup()
    );
  }
  doFullSort(rowNodes, sortOptions) {
    const mapper = (rowNode, pos) => ({ currentPos: pos, rowNode });
    const sortedRowNodes = rowNodes.map(mapper);
    sortedRowNodes.sort(this.compareRowNodes.bind(this, sortOptions));
    return sortedRowNodes.map((item) => item.rowNode);
  }
  compareRowNodes(sortOptions, sortedNodeA, sortedNodeB) {
    const nodeA = sortedNodeA.rowNode;
    const nodeB = sortedNodeB.rowNode;
    for (let i = 0, len = sortOptions.length; i < len; i++) {
      const sortOption = sortOptions[i];
      const isDescending = sortOption.sort === "desc";
      const valueA = this.getValue(nodeA, sortOption.column);
      const valueB = this.getValue(nodeB, sortOption.column);
      let comparatorResult;
      const providedComparator = this.getComparator(sortOption, nodeA);
      if (providedComparator) {
        comparatorResult = providedComparator(valueA, valueB, nodeA, nodeB, isDescending);
      } else {
        comparatorResult = _defaultComparator(valueA, valueB, this.isAccentedSort);
      }
      const validResult = !isNaN(comparatorResult);
      if (validResult && comparatorResult !== 0) {
        return sortOption.sort === "asc" ? comparatorResult : comparatorResult * -1;
      }
    }
    return sortedNodeA.currentPos - sortedNodeB.currentPos;
  }
  getComparator(sortOption, rowNode) {
    const column = sortOption.column;
    const comparatorOnCol = column.getColDef().comparator;
    if (comparatorOnCol != null) {
      return comparatorOnCol;
    }
    if (!column.getColDef().showRowGroup) {
      return;
    }
    const groupLeafField = !rowNode.group && column.getColDef().field;
    if (!groupLeafField) {
      return;
    }
    const primaryColumn = this.columnModel.getColDefCol(groupLeafField);
    if (!primaryColumn) {
      return;
    }
    return primaryColumn.getColDef().comparator;
  }
  getValue(node, column) {
    if (!this.primaryColumnsSortGroups) {
      return this.valueService.getValue(column, node, false, false);
    }
    const isNodeGroupedAtLevel = node.rowGroupColumn === column;
    if (isNodeGroupedAtLevel) {
      const isGroupRows = this.gos.isGroupUseEntireRow(this.columnModel.isPivotActive());
      if (isGroupRows) {
        const leafChild = node.allLeafChildren?.[0];
        if (leafChild) {
          return this.valueService.getValue(column, leafChild, false, false);
        }
        return void 0;
      }
      const displayCol = this.showRowGroupColsService?.getShowRowGroupCol(column.getId());
      if (!displayCol) {
        return void 0;
      }
      return node.groupData?.[displayCol.getId()];
    }
    if (node.group && column.getColDef().showRowGroup) {
      return void 0;
    }
    return this.valueService.getValue(column, node, false, false);
  }
};

// community-modules/core/src/utils/changedPath.ts
var ChangedPath = class {
  constructor(keepingColumns, rootNode) {
    // whether changed path is active of not. it is active when a) doing
    // a transaction update or b) doing change detection. if we are doing
    // a CSRM refresh for other reasons (after sort or filter, or user calling
    // setRowData() without delta mode) then we are not active. we are also
    // marked as not active if secondary columns change in pivot (as this impacts
    // aggregations)
    this.active = true;
    // for each node in the change path, we also store which columns need
    // to be re-aggregated.
    this.nodeIdsToColumns = {};
    // for quick lookup, all items in the change path are mapped by nodeId
    this.mapToItems = {};
    this.keepingColumns = keepingColumns;
    this.pathRoot = {
      rowNode: rootNode,
      children: null
    };
    this.mapToItems[rootNode.id] = this.pathRoot;
  }
  // can be set inactive by:
  // a) ClientSideRowModel, if no transactions or
  // b) PivotService, if secondary columns changed
  setInactive() {
    this.active = false;
  }
  isActive() {
    return this.active;
  }
  depthFirstSearchChangedPath(pathItem, callback) {
    if (pathItem.children) {
      for (let i = 0; i < pathItem.children.length; i++) {
        this.depthFirstSearchChangedPath(pathItem.children[i], callback);
      }
    }
    callback(pathItem.rowNode);
  }
  depthFirstSearchEverything(rowNode, callback, traverseEverything) {
    if (rowNode.childrenAfterGroup) {
      for (let i = 0; i < rowNode.childrenAfterGroup.length; i++) {
        const childNode = rowNode.childrenAfterGroup[i];
        if (childNode.childrenAfterGroup) {
          this.depthFirstSearchEverything(rowNode.childrenAfterGroup[i], callback, traverseEverything);
        } else if (traverseEverything) {
          callback(childNode);
        }
      }
    }
    callback(rowNode);
  }
  // traverseLeafNodes -> used when NOT doing changed path, ie traversing everything. the callback
  // will be called for child nodes in addition to parent nodes.
  forEachChangedNodeDepthFirst(callback, traverseLeafNodes = false, includeUnchangedNodes = false) {
    if (this.active && !includeUnchangedNodes) {
      this.depthFirstSearchChangedPath(this.pathRoot, callback);
    } else {
      this.depthFirstSearchEverything(this.pathRoot.rowNode, callback, traverseLeafNodes);
    }
  }
  executeFromRootNode(callback) {
    callback(this.pathRoot.rowNode);
  }
  createPathItems(rowNode) {
    let pointer = rowNode;
    let newEntryCount = 0;
    while (!this.mapToItems[pointer.id]) {
      const newEntry = {
        rowNode: pointer,
        children: null
      };
      this.mapToItems[pointer.id] = newEntry;
      newEntryCount++;
      pointer = pointer.parent;
    }
    return newEntryCount;
  }
  populateColumnsMap(rowNode, columns) {
    if (!this.keepingColumns || !columns) {
      return;
    }
    let pointer = rowNode;
    while (pointer) {
      if (!this.nodeIdsToColumns[pointer.id]) {
        this.nodeIdsToColumns[pointer.id] = {};
      }
      columns.forEach((col) => this.nodeIdsToColumns[pointer.id][col.getId()] = true);
      pointer = pointer.parent;
    }
  }
  linkPathItems(rowNode, newEntryCount) {
    let pointer = rowNode;
    for (let i = 0; i < newEntryCount; i++) {
      const thisItem = this.mapToItems[pointer.id];
      const parentItem = this.mapToItems[pointer.parent.id];
      if (!parentItem.children) {
        parentItem.children = [];
      }
      parentItem.children.push(thisItem);
      pointer = pointer.parent;
    }
  }
  // called by
  // 1) change detection (provides cols) and
  // 2) groupStage if doing transaction update (doesn't provide cols)
  addParentNode(rowNode, columns) {
    if (!rowNode || rowNode.isRowPinned()) {
      return;
    }
    const newEntryCount = this.createPathItems(rowNode);
    this.linkPathItems(rowNode, newEntryCount);
    this.populateColumnsMap(rowNode, columns);
  }
  canSkip(rowNode) {
    return this.active && !this.mapToItems[rowNode.id];
  }
  getValueColumnsForNode(rowNode, valueColumns) {
    if (!this.keepingColumns) {
      return valueColumns;
    }
    const colsForThisNode = this.nodeIdsToColumns[rowNode.id];
    const result = valueColumns.filter((col) => colsForThisNode[col.getId()]);
    return result;
  }
  getNotValueColumnsForNode(rowNode, valueColumns) {
    if (!this.keepingColumns) {
      return null;
    }
    const colsForThisNode = this.nodeIdsToColumns[rowNode.id];
    const result = valueColumns.filter((col) => !colsForThisNode[col.getId()]);
    return result;
  }
};

// community-modules/core/src/selection/selectionService.ts
var SelectionService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "selectionService";
    this.selectedNodes = /* @__PURE__ */ new Map();
    this.selectionCtx = new RowRangeSelectionContext();
  }
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
    this.pageBoundsService = beans.pageBoundsService;
  }
  postConstruct() {
    this.selectionCtx.init(this.rowModel);
    this.rowSelection = this.gos.get("rowSelection");
    this.groupSelectsChildren = this.gos.get("groupSelectsChildren");
    this.addManagedPropertyListeners(["groupSelectsChildren", "rowSelection"], () => {
      this.groupSelectsChildren = this.gos.get("groupSelectsChildren");
      this.rowSelection = this.gos.get("rowSelection");
      this.deselectAllRowNodes({ source: "api" });
    });
    this.addManagedEventListeners({ rowSelected: this.onRowSelected.bind(this) });
  }
  destroy() {
    super.destroy();
    this.resetNodes();
    this.selectionCtx.reset();
  }
  isMultiselect() {
    return this.rowSelection === "multiple";
  }
  /**
   * We override the selection value for UI-triggered events because it's the
   * current selection state that should determine the next selection state. This
   * is a stepping stone towards removing selection logic from event listeners and
   * other code external to the selection service(s).
   */
  overrideSelectionValue(newValue, source) {
    if (!isSelectionUIEvent(source)) {
      return newValue;
    }
    const root = this.selectionCtx.getRoot();
    return root ? root.isSelected() ?? false : true;
  }
  setNodesSelected(params) {
    const { newValue, clearSelection, suppressFinishActions, rangeSelect, nodes, event, source } = params;
    if (nodes.length === 0)
      return 0;
    if (nodes.length > 1 && !this.isMultiselect()) {
      _warnOnce(`cannot multi select while rowSelection='single'`);
      return 0;
    }
    const groupSelectsFiltered = this.groupSelectsChildren && params.groupSelectsFiltered === true;
    const filteredNodes = nodes.map((node) => node.footer ? node.sibling : node);
    if (rangeSelect) {
      if (filteredNodes.length > 1) {
        _warnOnce("cannot range select while selecting multiple rows");
        return 0;
      }
      const node = filteredNodes[0];
      const newSelectionValue = this.overrideSelectionValue(newValue, source);
      if (!this.isMultiselect()) {
      } else if (this.selectionCtx.isInRange(node)) {
        const partition = this.selectionCtx.truncate(node);
        if (newSelectionValue) {
          this.selectRange(partition.discard, false, source);
        }
        return this.selectRange(partition.keep, newSelectionValue, source);
      } else {
        const fromNode = this.selectionCtx.getRoot();
        const toNode = node;
        if (fromNode !== toNode) {
          const partition = this.selectionCtx.extend(node, this.groupSelectsChildren);
          if (newSelectionValue) {
            this.selectRange(partition.discard, false, source);
          }
          return this.selectRange(partition.keep, newSelectionValue, source);
        }
      }
    }
    if (!suppressFinishActions) {
      this.selectionCtx.setRoot(filteredNodes[0]);
    }
    let updatedCount = 0;
    for (let i = 0; i < filteredNodes.length; i++) {
      const node = filteredNodes[i];
      const skipThisNode = groupSelectsFiltered && node.group;
      if (!skipThisNode) {
        const thisNodeWasSelected = node.selectThisNode(newValue, event, source);
        if (thisNodeWasSelected) {
          updatedCount++;
        }
      }
      if (this.groupSelectsChildren && node.childrenAfterGroup?.length) {
        updatedCount += this.selectChildren(node, newValue, groupSelectsFiltered, source);
      }
    }
    if (!suppressFinishActions) {
      const clearOtherNodes = newValue && (clearSelection || !this.isMultiselect());
      if (clearOtherNodes) {
        updatedCount += this.clearOtherNodes(filteredNodes[0], source);
      }
      if (updatedCount > 0) {
        this.updateGroupsFromChildrenSelections(source);
        this.dispatchSelectionChanged(source);
      }
    }
    return updatedCount;
  }
  // not to be mixed up with 'cell range selection' where you drag the mouse, this is row range selection, by
  // holding down 'shift'.
  selectRange(nodesToSelect, value, source) {
    let updatedCount = 0;
    nodesToSelect.forEach((rowNode) => {
      if (rowNode.group && this.groupSelectsChildren) {
        return;
      }
      const nodeWasSelected = rowNode.selectThisNode(value, void 0, source);
      if (nodeWasSelected) {
        updatedCount++;
      }
    });
    if (updatedCount > 0) {
      this.updateGroupsFromChildrenSelections(source);
      this.dispatchSelectionChanged(source);
    }
    return updatedCount;
  }
  selectChildren(node, newValue, groupSelectsFiltered, source) {
    const children = groupSelectsFiltered ? node.childrenAfterAggFilter : node.childrenAfterGroup;
    if (_missing(children)) {
      return 0;
    }
    return this.setNodesSelected({
      newValue,
      clearSelection: false,
      suppressFinishActions: true,
      groupSelectsFiltered,
      source,
      nodes: children
    });
  }
  getSelectedNodes() {
    const selectedNodes = [];
    this.selectedNodes.forEach((rowNode) => {
      if (rowNode) {
        selectedNodes.push(rowNode);
      }
    });
    return selectedNodes;
  }
  getSelectedRows() {
    const selectedRows = [];
    this.selectedNodes.forEach((rowNode) => {
      if (rowNode && rowNode.data) {
        selectedRows.push(rowNode.data);
      }
    });
    return selectedRows;
  }
  getSelectionCount() {
    return this.selectedNodes.size;
  }
  /**
   * This method is used by the CSRM to remove groups which are being disposed of,
   * events do not need fired in this case
   */
  filterFromSelection(predicate) {
    const newSelectedNodes = /* @__PURE__ */ new Map();
    this.selectedNodes.forEach((rowNode, key) => {
      const passesPredicate = rowNode && predicate(rowNode);
      if (passesPredicate) {
        newSelectedNodes.set(key, rowNode);
      }
    });
    this.selectedNodes = newSelectedNodes;
  }
  // should only be called if groupSelectsChildren=true
  updateGroupsFromChildrenSelections(source, changedPath) {
    if (!this.groupSelectsChildren) {
      return false;
    }
    if (this.rowModel.getType() !== "clientSide") {
      return false;
    }
    const clientSideRowModel = this.rowModel;
    const rootNode = clientSideRowModel.getRootNode();
    if (!changedPath) {
      changedPath = new ChangedPath(true, rootNode);
      changedPath.setInactive();
    }
    let selectionChanged = false;
    changedPath.forEachChangedNodeDepthFirst((rowNode) => {
      if (rowNode !== rootNode) {
        const selected = rowNode.calculateSelectedFromChildren();
        selectionChanged = rowNode.selectThisNode(selected === null ? false : selected, void 0, source) || selectionChanged;
      }
    });
    return selectionChanged;
  }
  clearOtherNodes(rowNodeToKeepSelected, source) {
    const groupsToRefresh = /* @__PURE__ */ new Map();
    let updatedCount = 0;
    this.selectedNodes.forEach((otherRowNode) => {
      if (otherRowNode && otherRowNode.id !== rowNodeToKeepSelected.id) {
        const rowNode = this.selectedNodes.get(otherRowNode.id);
        updatedCount += rowNode.setSelectedParams({
          newValue: false,
          clearSelection: false,
          suppressFinishActions: true,
          source
        });
        if (this.groupSelectsChildren && otherRowNode.parent) {
          groupsToRefresh.set(otherRowNode.parent.id, otherRowNode.parent);
        }
      }
    });
    groupsToRefresh.forEach((group) => {
      const selected = group.calculateSelectedFromChildren();
      group.selectThisNode(selected === null ? false : selected, void 0, source);
    });
    return updatedCount;
  }
  onRowSelected(event) {
    const rowNode = event.node;
    if (this.groupSelectsChildren && rowNode.group) {
      return;
    }
    if (rowNode.isSelected()) {
      this.selectedNodes.set(rowNode.id, rowNode);
    } else {
      this.selectedNodes.delete(rowNode.id);
    }
  }
  syncInRowNode(rowNode, oldNode) {
    this.syncInOldRowNode(rowNode, oldNode);
    this.syncInNewRowNode(rowNode);
  }
  // if the id has changed for the node, then this means the rowNode
  // is getting used for a different data item, which breaks
  // our selectedNodes, as the node now is mapped by the old id
  // which is inconsistent. so to keep the old node as selected,
  // we swap in the clone (with the old id and old data). this means
  // the oldNode is effectively a daemon we keep a reference to,
  // so if client calls api.getSelectedNodes(), it gets the daemon
  // in the result. when the client un-selects, the reference to the
  // daemon is removed. the daemon, because it's an oldNode, is not
  // used by the grid for rendering, it's a copy of what the node used
  // to be like before the id was changed.
  syncInOldRowNode(rowNode, oldNode) {
    const oldNodeHasDifferentId = _exists(oldNode) && rowNode.id !== oldNode.id;
    if (oldNodeHasDifferentId && oldNode) {
      const id = oldNode.id;
      const oldNodeSelected = this.selectedNodes.get(id) == rowNode;
      if (oldNodeSelected) {
        this.selectedNodes.set(oldNode.id, oldNode);
      }
    }
  }
  syncInNewRowNode(rowNode) {
    if (this.selectedNodes.has(rowNode.id)) {
      rowNode.setSelectedInitialValue(true);
      this.selectedNodes.set(rowNode.id, rowNode);
    } else {
      rowNode.setSelectedInitialValue(false);
    }
  }
  reset(source) {
    const selectionCount = this.getSelectionCount();
    this.resetNodes();
    if (selectionCount) {
      this.dispatchSelectionChanged(source);
    }
  }
  resetNodes() {
    this.selectedNodes?.clear();
  }
  // returns a list of all nodes at 'best cost' - a feature to be used
  // with groups / trees. if a group has all it's children selected,
  // then the group appears in the result, but not the children.
  // Designed for use with 'children' as the group selection type,
  // where groups don't actually appear in the selection normally.
  getBestCostNodeSelection() {
    if (this.rowModel.getType() !== "clientSide") {
      return;
    }
    const clientSideRowModel = this.rowModel;
    const topLevelNodes = clientSideRowModel.getTopLevelNodes();
    if (topLevelNodes === null) {
      return;
    }
    const result = [];
    function traverse(nodes) {
      for (let i = 0, l = nodes.length; i < l; i++) {
        const node = nodes[i];
        if (node.isSelected()) {
          result.push(node);
        } else {
          const maybeGroup = node;
          if (maybeGroup.group && maybeGroup.children) {
            traverse(maybeGroup.children);
          }
        }
      }
    }
    traverse(topLevelNodes);
    return result;
  }
  isEmpty() {
    let count = 0;
    this.selectedNodes.forEach((rowNode) => {
      if (rowNode) {
        count++;
      }
    });
    return count === 0;
  }
  deselectAllRowNodes(params) {
    const callback = (rowNode) => rowNode.selectThisNode(false, void 0, source);
    const rowModelClientSide = this.rowModel.getType() === "clientSide";
    const { source, justFiltered, justCurrentPage } = params;
    if (justCurrentPage || justFiltered) {
      if (!rowModelClientSide) {
        _errorOnce("selecting just filtered only works when gridOptions.rowModelType='clientSide'");
        return;
      }
      this.getNodesToSelect(justFiltered, justCurrentPage).forEach(callback);
    } else {
      this.selectedNodes.forEach((rowNode) => {
        if (rowNode) {
          callback(rowNode);
        }
      });
      this.reset(source);
    }
    this.selectionCtx.reset();
    if (rowModelClientSide && this.groupSelectsChildren) {
      this.updateGroupsFromChildrenSelections(source);
    }
    this.dispatchSelectionChanged(source);
  }
  getSelectedCounts(justFiltered, justCurrentPage) {
    let selectedCount = 0;
    let notSelectedCount = 0;
    const callback = (node) => {
      if (this.groupSelectsChildren && node.group) {
        return;
      }
      if (node.isSelected()) {
        selectedCount++;
      } else if (!node.selectable) {
      } else {
        notSelectedCount++;
      }
    };
    this.getNodesToSelect(justFiltered, justCurrentPage).forEach(callback);
    return { selectedCount, notSelectedCount };
  }
  getSelectAllState(justFiltered, justCurrentPage) {
    const { selectedCount, notSelectedCount } = this.getSelectedCounts(justFiltered, justCurrentPage);
    if (selectedCount === 0 && notSelectedCount === 0) {
      return false;
    }
    if (selectedCount > 0 && notSelectedCount > 0) {
      return null;
    }
    return selectedCount > 0;
  }
  hasNodesToSelect(justFiltered = false, justCurrentPage = false) {
    return this.getNodesToSelect(justFiltered, justCurrentPage).filter((node) => node.selectable).length > 0;
  }
  /**
   * @param justFiltered whether to just include nodes which have passed the filter
   * @param justCurrentPage whether to just include nodes on the current page
   * @returns all nodes including unselectable nodes which are the target of this selection attempt
   */
  getNodesToSelect(justFiltered = false, justCurrentPage = false) {
    if (this.rowModel.getType() !== "clientSide") {
      throw new Error(
        `selectAll only available when rowModelType='clientSide', ie not ${this.rowModel.getType()}`
      );
    }
    const nodes = [];
    if (justCurrentPage) {
      this.forEachNodeOnPage((node) => {
        if (!node.group) {
          nodes.push(node);
          return;
        }
        if (!node.expanded) {
          const recursivelyAddChildren = (child) => {
            nodes.push(child);
            if (child.childrenAfterFilter?.length) {
              child.childrenAfterFilter.forEach(recursivelyAddChildren);
            }
          };
          recursivelyAddChildren(node);
          return;
        }
        if (!this.groupSelectsChildren) {
          nodes.push(node);
        }
      });
      return nodes;
    }
    const clientSideRowModel = this.rowModel;
    if (justFiltered) {
      clientSideRowModel.forEachNodeAfterFilter((node) => {
        nodes.push(node);
      });
      return nodes;
    }
    clientSideRowModel.forEachNode((node) => {
      nodes.push(node);
    });
    return nodes;
  }
  forEachNodeOnPage(callback) {
    const firstRow = this.pageBoundsService.getFirstRow();
    const lastRow = this.pageBoundsService.getLastRow();
    for (let i = firstRow; i <= lastRow; i++) {
      const node = this.rowModel.getRow(i);
      if (node) {
        callback(node);
      }
    }
  }
  selectAllRowNodes(params) {
    if (this.rowModel.getType() !== "clientSide") {
      throw new Error(
        `selectAll only available when rowModelType='clientSide', ie not ${this.rowModel.getType()}`
      );
    }
    const { source, justFiltered, justCurrentPage } = params;
    const nodes = this.getNodesToSelect(justFiltered, justCurrentPage);
    nodes.forEach((rowNode) => rowNode.selectThisNode(true, void 0, source));
    this.selectionCtx.setRoot(nodes[0] ?? null);
    this.selectionCtx.setEndRange(_last(nodes) ?? null);
    if (this.rowModel.getType() === "clientSide" && this.groupSelectsChildren) {
      this.updateGroupsFromChildrenSelections(source);
    }
    this.dispatchSelectionChanged(source);
  }
  getSelectionState() {
    const selectedIds = [];
    this.selectedNodes.forEach((node) => {
      if (node?.id) {
        selectedIds.push(node.id);
      }
    });
    return selectedIds.length ? selectedIds : null;
  }
  setSelectionState(state, source) {
    if (!Array.isArray(state)) {
      return;
    }
    const rowIds = new Set(state);
    const nodes = [];
    this.rowModel.forEachNode((node) => {
      if (rowIds.has(node.id)) {
        nodes.push(node);
      }
    });
    this.setNodesSelected({
      newValue: true,
      nodes,
      source
    });
  }
  dispatchSelectionChanged(source) {
    const event = {
      type: "selectionChanged",
      source
    };
    this.eventService.dispatchEvent(event);
  }
};

// community-modules/core/src/rowNodes/selectableService.ts
var SelectableService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "selectableService";
  }
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
    this.selectionService = beans.selectionService;
  }
  postConstruct() {
    this.addManagedPropertyListener("isRowSelectable", () => this.updateSelectable());
  }
  /**
   * Used by CSRM only, to update selectable state after group state changes.
   */
  updateSelectableAfterGrouping() {
    this.updateSelectable(true);
  }
  updateSelectable(skipLeafNodes = false) {
    const isRowSelecting = !!this.gos.get("rowSelection");
    const isRowSelectable = this.gos.get("isRowSelectable");
    if (!isRowSelecting || !isRowSelectable) {
      return;
    }
    const isGroupSelectsChildren = this.gos.get("groupSelectsChildren");
    const isCsrmGroupSelectsChildren = this.rowModel.getType() === "clientSide" && isGroupSelectsChildren;
    const nodesToDeselect = [];
    const nodeCallback = (node) => {
      if (skipLeafNodes && !node.group) {
        return;
      }
      if (isCsrmGroupSelectsChildren && node.group) {
        const hasSelectableChild = node.childrenAfterGroup.some((rowNode) => rowNode.selectable === true);
        node.setRowSelectable(hasSelectableChild, true);
        return;
      }
      const rowSelectable = isRowSelectable ? isRowSelectable(node) : true;
      node.setRowSelectable(rowSelectable, true);
      if (!rowSelectable && node.isSelected()) {
        nodesToDeselect.push(node);
      }
    };
    if (isCsrmGroupSelectsChildren) {
      const csrm = this.rowModel;
      const changedPath = new ChangedPath(false, csrm.getRootNode());
      changedPath.forEachChangedNodeDepthFirst(nodeCallback, true, true);
    } else {
      this.rowModel.forEachNode(nodeCallback);
    }
    if (nodesToDeselect.length) {
      this.selectionService.setNodesSelected({
        nodes: nodesToDeselect,
        newValue: false,
        source: "selectableChanged"
      });
    }
    if (isCsrmGroupSelectsChildren && this.selectionService instanceof SelectionService) {
      this.selectionService.updateGroupsFromChildrenSelections("selectableChanged");
    }
  }
};

// community-modules/core/src/sortController.ts
var DEFAULT_SORTING_ORDER = ["asc", "desc", null];
var SortController = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "sortController";
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.funcColsService = beans.funcColsService;
    this.showRowGroupColsService = beans.showRowGroupColsService;
  }
  progressSort(column, multiSort, source) {
    const nextDirection = this.getNextSortDirection(column);
    this.setSortForColumn(column, nextDirection, multiSort, source);
  }
  setSortForColumn(column, sort, multiSort, source) {
    if (sort !== "asc" && sort !== "desc") {
      sort = null;
    }
    const isColumnsSortingCoupledToGroup = this.gos.isColumnsSortingCoupledToGroup();
    let columnsToUpdate = [column];
    if (isColumnsSortingCoupledToGroup) {
      if (column.getColDef().showRowGroup) {
        const rowGroupColumns = this.funcColsService.getSourceColumnsForGroupColumn(column);
        const sortableRowGroupColumns = rowGroupColumns?.filter((col) => col.isSortable());
        if (sortableRowGroupColumns) {
          columnsToUpdate = [column, ...sortableRowGroupColumns];
        }
      }
    }
    columnsToUpdate.forEach((col) => col.setSort(sort, source));
    const doingMultiSort = (multiSort || this.gos.get("alwaysMultiSort")) && !this.gos.get("suppressMultiSort");
    const updatedColumns = [];
    if (!doingMultiSort) {
      const clearedColumns = this.clearSortBarTheseColumns(columnsToUpdate, source);
      updatedColumns.push(...clearedColumns);
    }
    this.updateSortIndex(column);
    updatedColumns.push(...columnsToUpdate);
    this.dispatchSortChangedEvents(source, updatedColumns);
  }
  updateSortIndex(lastColToChange) {
    const isCoupled = this.gos.isColumnsSortingCoupledToGroup();
    const groupParent = this.showRowGroupColsService?.getShowRowGroupCol(lastColToChange.getId());
    const lastSortIndexCol = isCoupled ? groupParent || lastColToChange : lastColToChange;
    const allSortedCols = this.getColumnsWithSortingOrdered();
    this.columnModel.getAllCols().forEach((col) => col.setSortIndex(null));
    const allSortedColsWithoutChangesOrGroups = allSortedCols.filter((col) => {
      if (isCoupled && col.getColDef().showRowGroup) {
        return false;
      }
      return col !== lastSortIndexCol;
    });
    const sortedColsWithIndices = lastSortIndexCol.getSort() ? [...allSortedColsWithoutChangesOrGroups, lastSortIndexCol] : allSortedColsWithoutChangesOrGroups;
    sortedColsWithIndices.forEach((col, idx) => {
      col.setSortIndex(idx);
    });
  }
  // gets called by API, so if data changes, use can call this, which will end up
  // working out the sort order again of the rows.
  onSortChanged(source, columns) {
    this.dispatchSortChangedEvents(source, columns);
  }
  isSortActive() {
    const allCols = this.columnModel.getAllCols();
    const sortedCols = allCols.filter((column) => !!column.getSort());
    return sortedCols && sortedCols.length > 0;
  }
  dispatchSortChangedEvents(source, columns) {
    const event = {
      type: "sortChanged",
      source
    };
    if (columns) {
      event.columns = columns;
    }
    this.eventService.dispatchEvent(event);
  }
  clearSortBarTheseColumns(columnsToSkip, source) {
    const clearedColumns = [];
    this.columnModel.getAllCols().forEach((columnToClear) => {
      if (!columnsToSkip.includes(columnToClear)) {
        if (columnToClear.getSort()) {
          clearedColumns.push(columnToClear);
        }
        columnToClear.setSort(void 0, source);
      }
    });
    return clearedColumns;
  }
  getNextSortDirection(column) {
    let sortingOrder;
    if (column.getColDef().sortingOrder) {
      sortingOrder = column.getColDef().sortingOrder;
    } else if (this.gos.get("sortingOrder")) {
      sortingOrder = this.gos.get("sortingOrder");
    } else {
      sortingOrder = DEFAULT_SORTING_ORDER;
    }
    if (!Array.isArray(sortingOrder) || sortingOrder.length <= 0) {
      _warnOnce(`sortingOrder must be an array with at least one element, currently it's ${sortingOrder}`);
      return null;
    }
    const currentIndex = sortingOrder.indexOf(column.getSort());
    const notInArray = currentIndex < 0;
    const lastItemInArray = currentIndex == sortingOrder.length - 1;
    let result;
    if (notInArray || lastItemInArray) {
      result = sortingOrder[0];
    } else {
      result = sortingOrder[currentIndex + 1];
    }
    if (DEFAULT_SORTING_ORDER.indexOf(result) < 0) {
      _warnOnce("invalid sort type ", result);
      return null;
    }
    return result;
  }
  /**
   * @returns a map of sort indexes for every sorted column, if groups sort primaries then they will have equivalent indices
   */
  getIndexedSortMap() {
    let allSortedCols = this.columnModel.getAllCols().filter((col) => !!col.getSort());
    if (this.columnModel.isPivotMode()) {
      const isSortingLinked = this.gos.isColumnsSortingCoupledToGroup();
      allSortedCols = allSortedCols.filter((col) => {
        const isAggregated = !!col.getAggFunc();
        const isSecondary = !col.isPrimary();
        const isGroup = isSortingLinked ? this.showRowGroupColsService?.getShowRowGroupCol(col.getId()) : col.getColDef().showRowGroup;
        return isAggregated || isSecondary || isGroup;
      });
    }
    const sortedRowGroupCols = this.funcColsService.getRowGroupColumns().filter((col) => !!col.getSort());
    const allColsIndexes = {};
    allSortedCols.forEach((col, index) => allColsIndexes[col.getId()] = index);
    allSortedCols.sort((a, b) => {
      const iA = a.getSortIndex();
      const iB = b.getSortIndex();
      if (iA != null && iB != null) {
        return iA - iB;
      } else if (iA == null && iB == null) {
        const posA = allColsIndexes[a.getId()];
        const posB = allColsIndexes[b.getId()];
        return posA > posB ? 1 : -1;
      } else if (iB == null) {
        return -1;
      } else {
        return 1;
      }
    });
    const isSortLinked = this.gos.isColumnsSortingCoupledToGroup() && !!sortedRowGroupCols.length;
    if (isSortLinked) {
      allSortedCols = [
        ...new Set(
          // if linked sorting, replace all columns with the display group column for index purposes, and ensure uniqueness
          allSortedCols.map((col) => this.showRowGroupColsService?.getShowRowGroupCol(col.getId()) ?? col)
        )
      ];
    }
    const indexMap = /* @__PURE__ */ new Map();
    allSortedCols.forEach((col, idx) => indexMap.set(col, idx));
    if (isSortLinked) {
      sortedRowGroupCols.forEach((col) => {
        const groupDisplayCol = this.showRowGroupColsService.getShowRowGroupCol(col.getId());
        indexMap.set(col, indexMap.get(groupDisplayCol));
      });
    }
    return indexMap;
  }
  getColumnsWithSortingOrdered() {
    return [...this.getIndexedSortMap().entries()].sort(([col1, idx1], [col2, idx2]) => idx1 - idx2).map(([col]) => col);
  }
  // used by server side row models, to sent sort to server
  getSortModel() {
    return this.getColumnsWithSortingOrdered().filter((column) => column.getSort()).map((column) => ({
      sort: column.getSort(),
      colId: column.getId()
    }));
  }
  getSortOptions() {
    return this.getColumnsWithSortingOrdered().filter((column) => column.getSort()).map((column) => ({
      sort: column.getSort(),
      column
    }));
  }
  canColumnDisplayMixedSort(column) {
    const isColumnSortCouplingActive = this.gos.isColumnsSortingCoupledToGroup();
    const isGroupDisplayColumn = !!column.getColDef().showRowGroup;
    return isColumnSortCouplingActive && isGroupDisplayColumn;
  }
  getDisplaySortForColumn(column) {
    const linkedColumns = this.funcColsService.getSourceColumnsForGroupColumn(column);
    if (!this.canColumnDisplayMixedSort(column) || !linkedColumns?.length) {
      return column.getSort();
    }
    const columnHasUniqueData = column.getColDef().field != null || !!column.getColDef().valueGetter;
    const sortableColumns = columnHasUniqueData ? [column, ...linkedColumns] : linkedColumns;
    const firstSort = sortableColumns[0].getSort();
    const allMatch = sortableColumns.every((col) => col.getSort() == firstSort);
    if (!allMatch) {
      return "mixed";
    }
    return firstSort;
  }
  getDisplaySortIndexForColumn(column) {
    return this.getIndexedSortMap().get(column);
  }
};

// community-modules/core/src/syncService.ts
var SyncService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "syncService";
    this.waitingForColumns = false;
  }
  wireBeans(beans) {
    this.ctrlsService = beans.ctrlsService;
    this.columnModel = beans.columnModel;
    this.rowModel = beans.rowModel;
  }
  postConstruct() {
    this.addManagedPropertyListener("columnDefs", (event) => this.setColumnDefs(event));
  }
  start() {
    this.ctrlsService.whenReady(() => {
      const columnDefs = this.gos.get("columnDefs");
      if (columnDefs) {
        this.setColumnsAndData(columnDefs);
      } else {
        this.waitingForColumns = true;
      }
      this.gridReady();
    });
  }
  setColumnsAndData(columnDefs) {
    this.columnModel.setColumnDefs(columnDefs ?? [], "gridInitializing");
    this.rowModel.start();
  }
  gridReady() {
    this.dispatchGridReadyEvent();
    const isEnterprise = ModuleRegistry.__isRegistered("@ag-grid-enterprise/core" /* EnterpriseCoreModule */, this.gridId);
    if (this.gos.get("debug")) {
      _log(`initialised successfully, enterprise = ${isEnterprise}`);
    }
  }
  dispatchGridReadyEvent() {
    const readyEvent = {
      type: "gridReady"
    };
    this.eventService.dispatchEvent(readyEvent);
  }
  setColumnDefs(event) {
    const columnDefs = this.gos.get("columnDefs");
    if (!columnDefs) {
      return;
    }
    if (this.waitingForColumns) {
      this.waitingForColumns = false;
      this.setColumnsAndData(columnDefs);
      return;
    }
    this.columnModel.setColumnDefs(columnDefs, convertSourceType(event.source));
  }
};

// community-modules/core/src/valueService/changeDetectionService.ts
var SOURCE_PASTE = "paste";
var ChangeDetectionService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "changeDetectionService";
  }
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
    this.rowRenderer = beans.rowRenderer;
  }
  postConstruct() {
    if (this.rowModel.getType() === "clientSide") {
      this.clientSideRowModel = this.rowModel;
    }
    this.addManagedEventListeners({ cellValueChanged: this.onCellValueChanged.bind(this) });
  }
  onCellValueChanged(event) {
    if (event.source === SOURCE_PASTE) {
      return;
    }
    this.doChangeDetection(event.node, event.column);
  }
  doChangeDetection(rowNode, column) {
    if (this.gos.get("suppressChangeDetection")) {
      return;
    }
    const nodesToRefresh = [rowNode];
    if (this.clientSideRowModel && !rowNode.isRowPinned()) {
      const onlyChangedColumns = this.gos.get("aggregateOnlyChangedColumns");
      const changedPath = new ChangedPath(onlyChangedColumns, this.clientSideRowModel.getRootNode());
      changedPath.addParentNode(rowNode.parent, [column]);
      this.clientSideRowModel.doAggregate(changedPath);
      changedPath.forEachChangedNodeDepthFirst((rowNode2) => {
        nodesToRefresh.push(rowNode2);
      });
    }
    this.rowRenderer.refreshCells({ rowNodes: nodesToRefresh });
  }
};

// community-modules/core/src/valueService/expressionService.ts
var ExpressionService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "expressionService";
    this.expressionToFunctionCache = {};
  }
  evaluate(expression, params) {
    if (typeof expression === "string") {
      return this.evaluateExpression(expression, params);
    } else {
      _errorOnce("value should be either a string or a function", expression);
    }
  }
  evaluateExpression(expression, params) {
    try {
      const javaScriptFunction = this.createExpressionFunction(expression);
      const result = javaScriptFunction(
        params.value,
        params.context,
        params.oldValue,
        params.newValue,
        params.value,
        params.node,
        params.data,
        params.colDef,
        params.rowIndex,
        params.api,
        params.getValue,
        params.column,
        params.columnGroup
      );
      return result;
    } catch (e) {
      _log("Processing of the expression failed");
      _log("Expression = ", expression);
      _log("Params = ", params);
      _log("Exception = ", e);
      return null;
    }
  }
  createExpressionFunction(expression) {
    if (this.expressionToFunctionCache[expression]) {
      return this.expressionToFunctionCache[expression];
    }
    const functionBody = this.createFunctionBody(expression);
    const theFunction = new Function(
      "x, ctx, oldValue, newValue, value, node, data, colDef, rowIndex, api, getValue, column, columnGroup",
      functionBody
    );
    this.expressionToFunctionCache[expression] = theFunction;
    return theFunction;
  }
  createFunctionBody(expression) {
    if (expression.indexOf("return") >= 0) {
      return expression;
    } else {
      return "return " + expression + ";";
    }
  }
};

// community-modules/core/src/valueService/valueCache.ts
var ValueCache = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "valueCache";
    this.cacheVersion = 0;
  }
  postConstruct() {
    this.active = this.gos.get("valueCache");
    this.neverExpires = this.gos.get("valueCacheNeverExpires");
  }
  onDataChanged() {
    if (this.neverExpires) {
      return;
    }
    this.expire();
  }
  expire() {
    this.cacheVersion++;
  }
  setValue(rowNode, colId, value) {
    if (this.active) {
      if (rowNode.__cacheVersion !== this.cacheVersion) {
        rowNode.__cacheVersion = this.cacheVersion;
        rowNode.__cacheData = {};
      }
      rowNode.__cacheData[colId] = value;
    }
  }
  getValue(rowNode, colId) {
    if (!this.active || rowNode.__cacheVersion !== this.cacheVersion) {
      return void 0;
    }
    return rowNode.__cacheData[colId];
  }
};

// community-modules/core/src/valueService/valueService.ts
var ValueService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "valueService";
    this.initialised = false;
    this.isSsrm = false;
  }
  wireBeans(beans) {
    this.expressionService = beans.expressionService;
    this.columnModel = beans.columnModel;
    this.valueCache = beans.valueCache;
    this.dataTypeService = beans.dataTypeService;
  }
  postConstruct() {
    if (!this.initialised) {
      this.init();
    }
  }
  init() {
    this.isSsrm = this.gos.isRowModelType("serverSide");
    this.cellExpressions = this.gos.get("enableCellExpressions");
    this.isTreeData = this.gos.get("treeData");
    this.initialised = true;
    const listener = (event) => this.callColumnCellValueChangedHandler(event);
    const async = this.gos.useAsyncEvents();
    this.eventService.addEventListener("cellValueChanged", listener, async);
    this.addDestroyFunc(() => this.eventService.removeEventListener("cellValueChanged", listener, async));
    this.addManagedPropertyListener("treeData", (propChange) => this.isTreeData = propChange.currentValue);
  }
  getValue(column, rowNode, forFilter = false, ignoreAggData = false) {
    if (!this.initialised) {
      this.init();
    }
    if (!rowNode) {
      return;
    }
    const colDef = column.getColDef();
    const field = colDef.field;
    const colId = column.getColId();
    const data = rowNode.data;
    let result;
    const groupDataExists = rowNode.groupData && rowNode.groupData[colId] !== void 0;
    const aggDataExists = !ignoreAggData && rowNode.aggData && rowNode.aggData[colId] !== void 0;
    const ignoreSsrmAggData = this.isSsrm && ignoreAggData && !!column.getColDef().aggFunc;
    const ssrmFooterGroupCol = this.isSsrm && rowNode.footer && rowNode.field && (column.getColDef().showRowGroup === true || column.getColDef().showRowGroup === rowNode.field);
    if (forFilter && colDef.filterValueGetter) {
      result = this.executeFilterValueGetter(colDef.filterValueGetter, data, column, rowNode);
    } else if (this.isTreeData && aggDataExists) {
      result = rowNode.aggData[colId];
    } else if (this.isTreeData && colDef.valueGetter) {
      result = this.executeValueGetter(colDef.valueGetter, data, column, rowNode);
    } else if (this.isTreeData && field && data) {
      result = _getValueUsingField(data, field, column.isFieldContainsDots());
    } else if (groupDataExists) {
      result = rowNode.groupData[colId];
    } else if (aggDataExists) {
      result = rowNode.aggData[colId];
    } else if (colDef.valueGetter) {
      result = this.executeValueGetter(colDef.valueGetter, data, column, rowNode);
    } else if (ssrmFooterGroupCol) {
      result = _getValueUsingField(data, rowNode.field, column.isFieldContainsDots());
    } else if (field && data && !ignoreSsrmAggData) {
      result = _getValueUsingField(data, field, column.isFieldContainsDots());
    }
    if (this.cellExpressions && typeof result === "string" && result.indexOf("=") === 0) {
      const cellValueGetter = result.substring(1);
      result = this.executeValueGetter(cellValueGetter, data, column, rowNode);
    }
    if (result == null) {
      const openedGroup = this.getOpenedGroup(rowNode, column);
      if (openedGroup != null) {
        return openedGroup;
      }
    }
    return result;
  }
  parseValue(column, rowNode, newValue, oldValue) {
    const colDef = column.getColDef();
    const params = this.gos.addGridCommonParams({
      node: rowNode,
      data: rowNode?.data,
      oldValue,
      newValue,
      colDef,
      column
    });
    const valueParser = colDef.valueParser;
    if (_exists(valueParser)) {
      if (typeof valueParser === "function") {
        return valueParser(params);
      }
      return this.expressionService.evaluate(valueParser, params);
    }
    return newValue;
  }
  formatValue(column, node, value, suppliedFormatter, useFormatterFromColumn = true) {
    let result = null;
    let formatter;
    const colDef = column.getColDef();
    if (suppliedFormatter) {
      formatter = suppliedFormatter;
    } else if (useFormatterFromColumn) {
      formatter = colDef.valueFormatter;
    }
    if (formatter) {
      const params = this.gos.addGridCommonParams({
        value,
        node,
        data: node ? node.data : null,
        colDef,
        column
      });
      if (typeof formatter === "function") {
        result = formatter(params);
      } else {
        result = this.expressionService.evaluate(formatter, params);
      }
    } else if (colDef.refData) {
      return colDef.refData[value] || "";
    }
    if (result == null && Array.isArray(value)) {
      result = value.join(", ");
    }
    return result;
  }
  getOpenedGroup(rowNode, column) {
    if (!this.gos.get("showOpenedGroup")) {
      return;
    }
    const colDef = column.getColDef();
    if (!colDef.showRowGroup) {
      return;
    }
    const showRowGroup = column.getColDef().showRowGroup;
    let pointer = rowNode.parent;
    while (pointer != null) {
      if (pointer.rowGroupColumn && (showRowGroup === true || showRowGroup === pointer.rowGroupColumn.getColId())) {
        return pointer.key;
      }
      pointer = pointer.parent;
    }
    return void 0;
  }
  /**
   * Sets the value of a GridCell
   * @param rowNode The `RowNode` to be updated
   * @param colKey The `Column` to be updated
   * @param newValue The new value to be set
   * @param eventSource The event source
   * @returns `True` if the value has been updated, otherwise`False`.
   */
  setValue(rowNode, colKey, newValue, eventSource) {
    const column = this.columnModel.getColDefCol(colKey);
    if (!rowNode || !column) {
      return false;
    }
    if (_missing(rowNode.data)) {
      rowNode.data = {};
    }
    const { field, valueSetter } = column.getColDef();
    if (_missing(field) && _missing(valueSetter)) {
      _warnOnce(`you need either field or valueSetter set on colDef for editing to work`);
      return false;
    }
    if (this.dataTypeService && !this.dataTypeService.checkType(column, newValue)) {
      _warnOnce(`Data type of the new value does not match the cell data type of the column`);
      return false;
    }
    const params = this.gos.addGridCommonParams({
      node: rowNode,
      data: rowNode.data,
      oldValue: this.getValue(column, rowNode),
      newValue,
      colDef: column.getColDef(),
      column
    });
    params.newValue = newValue;
    let valueWasDifferent;
    if (_exists(valueSetter)) {
      if (typeof valueSetter === "function") {
        valueWasDifferent = valueSetter(params);
      } else {
        valueWasDifferent = this.expressionService.evaluate(valueSetter, params);
      }
    } else {
      valueWasDifferent = this.setValueUsingField(rowNode.data, field, newValue, column.isFieldContainsDots());
    }
    if (valueWasDifferent === void 0) {
      valueWasDifferent = true;
    }
    if (!valueWasDifferent) {
      return false;
    }
    rowNode.resetQuickFilterAggregateText();
    this.valueCache.onDataChanged();
    params.newValue = this.getValue(column, rowNode);
    const event = {
      type: "cellValueChanged",
      event: null,
      rowIndex: rowNode.rowIndex,
      rowPinned: rowNode.rowPinned,
      column: params.column,
      api: params.api,
      colDef: params.colDef,
      context: params.context,
      data: rowNode.data,
      node: rowNode,
      oldValue: params.oldValue,
      newValue: params.newValue,
      value: params.newValue,
      source: eventSource
    };
    this.eventService.dispatchEvent(event);
    return true;
  }
  callColumnCellValueChangedHandler(event) {
    const onCellValueChanged = event.colDef.onCellValueChanged;
    if (typeof onCellValueChanged === "function") {
      this.getFrameworkOverrides().wrapOutgoing(() => {
        onCellValueChanged({
          node: event.node,
          data: event.data,
          oldValue: event.oldValue,
          newValue: event.newValue,
          colDef: event.colDef,
          column: event.column,
          api: event.api,
          context: event.context
        });
      });
    }
  }
  setValueUsingField(data, field, newValue, isFieldContainsDots) {
    if (!field) {
      return false;
    }
    let valuesAreSame = false;
    if (!isFieldContainsDots) {
      valuesAreSame = data[field] === newValue;
      if (!valuesAreSame) {
        data[field] = newValue;
      }
    } else {
      const fieldPieces = field.split(".");
      let currentObject = data;
      while (fieldPieces.length > 0 && currentObject) {
        const fieldPiece = fieldPieces.shift();
        if (fieldPieces.length === 0) {
          valuesAreSame = currentObject[fieldPiece] === newValue;
          if (!valuesAreSame) {
            currentObject[fieldPiece] = newValue;
          }
        } else {
          currentObject = currentObject[fieldPiece];
        }
      }
    }
    return !valuesAreSame;
  }
  executeFilterValueGetter(valueGetter, data, column, rowNode) {
    const params = this.gos.addGridCommonParams({
      data,
      node: rowNode,
      column,
      colDef: column.getColDef(),
      getValue: this.getValueCallback.bind(this, rowNode)
    });
    if (typeof valueGetter === "function") {
      return valueGetter(params);
    }
    return this.expressionService.evaluate(valueGetter, params);
  }
  executeValueGetter(valueGetter, data, column, rowNode) {
    const colId = column.getColId();
    const valueFromCache = this.valueCache.getValue(rowNode, colId);
    if (valueFromCache !== void 0) {
      return valueFromCache;
    }
    const params = this.gos.addGridCommonParams({
      data,
      node: rowNode,
      column,
      colDef: column.getColDef(),
      getValue: this.getValueCallback.bind(this, rowNode)
    });
    let result;
    if (typeof valueGetter === "function") {
      result = valueGetter(params);
    } else {
      result = this.expressionService.evaluate(valueGetter, params);
    }
    this.valueCache.setValue(rowNode, colId, result);
    return result;
  }
  getValueCallback(node, field) {
    const otherColumn = this.columnModel.getColDefCol(field);
    if (otherColumn) {
      return this.getValue(otherColumn, node);
    }
    return null;
  }
  // used by row grouping and pivot, to get key for a row. col can be a pivot col or a row grouping col
  getKeyForNode(col, rowNode) {
    const value = this.getValue(col, rowNode);
    const keyCreator = col.getColDef().keyCreator;
    let result = value;
    if (keyCreator) {
      const keyParams = this.gos.addGridCommonParams({
        value,
        colDef: col.getColDef(),
        column: col,
        node: rowNode,
        data: rowNode.data
      });
      result = keyCreator(keyParams);
    }
    if (typeof result === "string" || result == null) {
      return result;
    }
    result = String(result);
    if (result === "[object Object]") {
      _warnOnce(
        "a column you are grouping or pivoting by has objects as values. If you want to group by complex objects then either a) use a colDef.keyCreator (se AG Grid docs) or b) to toString() on the object to return a key"
      );
    }
    return result;
  }
};

// community-modules/core/src/grid.ts
var GlobalGridOptions = class {
};
GlobalGridOptions.gridOptions = void 0;
function provideGlobalGridOptions(gridOptions) {
  GlobalGridOptions.gridOptions = gridOptions;
}
function createGrid(eGridDiv, gridOptions, params) {
  if (!gridOptions) {
    _errorOnce("No gridOptions provided to createGrid");
    return {};
  }
  const api = new GridCoreCreator().create(
    eGridDiv,
    gridOptions,
    (context) => {
      const gridComp = new GridComp(eGridDiv);
      context.createBean(gridComp);
    },
    void 0,
    params
  );
  if (!Object.isFrozen(gridOptions) && !params?.frameworkOverrides) {
    const apiUrl = "https://ag-grid.com/javascript-data-grid/grid-interface/#grid-api";
    Object.defineProperty(gridOptions, "api", {
      get: () => {
        _errorOnce(`gridOptions.api is no longer supported. See ${apiUrl}.`);
        return void 0;
      },
      configurable: true
    });
  }
  return api;
}
var Grid = class {
  // Not typed to enable setting api for backwards compatibility
  constructor(eGridDiv, gridOptions, params) {
    _warnOnce(
      "Since v31 new Grid(...) is deprecated. Use createGrid instead: `const gridApi = createGrid(...)`. The grid api is returned from createGrid and will not be available on gridOptions."
    );
    if (!gridOptions) {
      _errorOnce("No gridOptions provided to the grid");
      return;
    }
    this.gridOptions = gridOptions;
    const api = new GridCoreCreator().create(
      eGridDiv,
      gridOptions,
      (context) => {
        const gridComp = new GridComp(eGridDiv);
        const bean = context.createBean(gridComp);
        bean.addDestroyFunc(() => {
          this.destroy();
        });
      },
      void 0,
      params
    );
    this.gridOptions.api = api;
  }
  destroy() {
    if (this.gridOptions) {
      this.gridOptions.api?.destroy();
      delete this.gridOptions.api;
    }
  }
};
var nextGridId = 1;
var GridCoreCreator = class {
  create(eGridDiv, providedOptions, createUi, acceptChanges, params) {
    let mergedGridOps = {};
    if (GlobalGridOptions.gridOptions) {
      _mergeDeep(mergedGridOps, GlobalGridOptions.gridOptions, true, true);
      mergedGridOps = { ...mergedGridOps, ...providedOptions };
    } else {
      mergedGridOps = providedOptions;
    }
    const gridOptions = getCoercedGridOptions(mergedGridOps);
    const gridId = gridOptions.gridId ?? String(nextGridId++);
    const registeredModules = this.getRegisteredModules(params, gridId);
    const beanClasses = this.createBeansList(gridOptions.rowModelType, registeredModules, gridId);
    const providedBeanInstances = this.createProvidedBeans(eGridDiv, gridOptions, params);
    if (!beanClasses) {
      _errorOnce("Failed to create grid.");
      return void 0;
    }
    const contextParams = {
      providedBeanInstances,
      beanClasses,
      gridId,
      beanInitComparator: gridBeanInitComparator,
      beanDestroyComparator: gridBeanDestroyComparator,
      derivedBeans: [createApiProxy]
    };
    const context = new Context(contextParams);
    this.registerModuleUserComponents(context, registeredModules);
    this.registerControllers(context, registeredModules);
    this.registerModuleApiFunctions(context, registeredModules);
    createUi(context);
    context.getBean("syncService").start();
    if (acceptChanges) {
      acceptChanges(context);
    }
    return context.getBean("gridApi");
  }
  registerControllers(context, registeredModules) {
    const factory = context.getBean("ctrlsFactory");
    registeredModules.forEach((module) => {
      if (module.controllers) {
        module.controllers.forEach((meta) => factory.register(meta));
      }
    });
  }
  getRegisteredModules(params, gridId) {
    const passedViaConstructor = params ? params.modules : null;
    const registered = ModuleRegistry.__getRegisteredModules(gridId);
    const allModules = [];
    const mapNames = {};
    const addModule = (moduleBased, mod, gridId2) => {
      const addIndividualModule = (currentModule) => {
        if (!mapNames[currentModule.moduleName]) {
          mapNames[currentModule.moduleName] = true;
          allModules.push(currentModule);
          ModuleRegistry.__register(currentModule, moduleBased, gridId2);
        }
      };
      addIndividualModule(mod);
      if (mod.dependantModules) {
        mod.dependantModules.forEach((m) => addModule(moduleBased, m, gridId2));
      }
    };
    addModule(
      !!passedViaConstructor?.length || !ModuleRegistry.__isPackageBased(),
      CommunityFeaturesModule,
      void 0
    );
    if (passedViaConstructor) {
      passedViaConstructor.forEach((m) => addModule(true, m, gridId));
    }
    if (registered) {
      registered.forEach((m) => addModule(!ModuleRegistry.__isPackageBased(), m, void 0));
    }
    return allModules;
  }
  registerModuleUserComponents(context, registeredModules) {
    const moduleUserComps = this.extractModuleEntity(
      registeredModules,
      (module) => module.userComponents ? module.userComponents : []
    );
    const registry = context.getBean("userComponentRegistry");
    moduleUserComps.forEach((compMeta) => {
      registry.registerDefaultComponent(compMeta.name, compMeta.classImp);
    });
  }
  registerModuleApiFunctions(context, registeredModules) {
    const apiFunctionService = context.getBean("apiFunctionService");
    registeredModules.forEach((module) => {
      const apiFunctions = module.apiFunctions ?? {};
      const names = Object.keys(apiFunctions);
      names.forEach((name) => {
        apiFunctionService?.addFunction(name, apiFunctions[name]);
      });
    });
  }
  createProvidedBeans(eGridDiv, gridOptions, params) {
    let frameworkOverrides = params ? params.frameworkOverrides : null;
    if (_missing(frameworkOverrides)) {
      frameworkOverrides = new VanillaFrameworkOverrides();
    }
    const seed = {
      gridOptions,
      eGridDiv,
      globalEventListener: params ? params.globalEventListener : null,
      globalSyncEventListener: params ? params.globalSyncEventListener : null,
      frameworkOverrides
    };
    if (params && params.providedBeanInstances) {
      Object.assign(seed, params.providedBeanInstances);
    }
    return seed;
  }
  createBeansList(rowModelType = "clientSide", registeredModules, gridId) {
    const rowModelModules = registeredModules.filter(
      (module) => !module.rowModel || module.rowModel === rowModelType
    );
    const rowModelModuleNames = {
      clientSide: "@ag-grid-community/client-side-row-model" /* ClientSideRowModelModule */,
      infinite: "@ag-grid-community/infinite-row-model" /* InfiniteRowModelModule */,
      serverSide: "@ag-grid-enterprise/server-side-row-model" /* ServerSideRowModelModule */,
      viewport: "@ag-grid-enterprise/viewport-row-model" /* ViewportRowModelModule */
    };
    if (!rowModelModuleNames[rowModelType]) {
      _errorOnce("Could not find row model for rowModelType = ", rowModelType);
      return;
    }
    if (!ModuleRegistry.__assertRegistered(
      rowModelModuleNames[rowModelType],
      `rowModelType = '${rowModelType}'`,
      gridId
    )) {
      return;
    }
    const beans = [
      RowPositionUtils,
      CellPositionUtils,
      HeaderPositionUtils,
      GridDestroyService,
      ApiFunctionService,
      UserComponentRegistry,
      AgComponentUtils,
      ComponentMetadataProvider,
      ResizeObserverService,
      UserComponentFactory,
      RowContainerHeightService,
      HorizontalResizeService,
      LocaleService,
      PinnedRowModel,
      DragService,
      VisibleColsService,
      EventService,
      GridOptionsService,
      PopupService,
      SelectionService,
      ColumnModel,
      HeaderNavigationService,
      PageBoundsService,
      PageBoundsListener,
      RowRenderer,
      ExpressionService,
      ColumnFactory,
      NavigationService,
      ValueCache,
      ValueService,
      AutoWidthCalculator,
      StandardMenuFactory,
      DragAndDropService,
      FocusService,
      MouseEventService,
      Environment,
      CellNavigationService,
      StylingService,
      ScrollVisibleService,
      SortController,
      ColumnHoverService,
      ColumnAnimationService,
      SelectableService,
      ChangeDetectionService,
      AnimationFrameService,
      ColumnDefFactory,
      RowCssClassCalculator,
      RowNodeSorter,
      CtrlsService,
      PinnedWidthService,
      RowNodeEventThrottle,
      CtrlsFactory,
      SyncService,
      OverlayService,
      ExpansionService,
      ApiEventService,
      AriaAnnouncementService,
      MenuService,
      ColumnApplyStateService,
      ColumnEventDispatcher,
      ColumnMoveService,
      ColumnAutosizeService,
      ColumnGetStateService,
      ColumnGroupStateService,
      ColumnSizeService,
      FuncColsService,
      ColumnNameService,
      ColumnViewportService,
      PivotResultColsService
    ];
    const moduleBeans = this.extractModuleEntity(rowModelModules, (module) => module.beans ? module.beans : []);
    beans.push(...moduleBeans);
    const beansNoDuplicates = [];
    beans.forEach((bean) => {
      if (beansNoDuplicates.indexOf(bean) < 0) {
        beansNoDuplicates.push(bean);
      }
    });
    return beansNoDuplicates;
  }
  extractModuleEntity(moduleEntities, extractor) {
    return [].concat(...moduleEntities.map(extractor));
  }
};

// community-modules/core/src/api/rowModelHelperService.ts
var RowModelHelperService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "rowModelHelperService";
  }
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
  }
  postConstruct() {
    const rowModel = this.rowModel;
    switch (rowModel.getType()) {
      case "clientSide":
        this.clientSideRowModel = rowModel;
        break;
      case "infinite":
        this.infiniteRowModel = rowModel;
        break;
      case "serverSide":
        this.serverSideRowModel = rowModel;
        break;
    }
  }
  getClientSideRowModel() {
    return this.clientSideRowModel;
  }
  getInfiniteRowModel() {
    return this.infiniteRowModel;
  }
  getServerSideRowModel() {
    return this.serverSideRowModel;
  }
};

// community-modules/core/src/api/csrmSsrmSharedApi.ts
function expandAll(beans) {
  beans.expansionService.expandAll(true);
}
function collapseAll(beans) {
  beans.expansionService.expandAll(false);
}
function onRowHeightChanged(beans) {
  const clientSideRowModel = beans.rowModelHelperService?.getClientSideRowModel();
  const serverSideRowModel = beans.rowModelHelperService?.getServerSideRowModel();
  if (clientSideRowModel) {
    clientSideRowModel.onRowHeightChanged();
  } else if (serverSideRowModel) {
    serverSideRowModel.onRowHeightChanged();
  }
}

// community-modules/core/src/api/ssrmInfiniteSharedApi.ts
function setRowCount(beans, rowCount, maxRowFound) {
  const serverSideRowModel = beans.rowModelHelperService?.getServerSideRowModel();
  if (serverSideRowModel) {
    if (beans.funcColsService.isRowGroupEmpty()) {
      serverSideRowModel.setRowCount(rowCount, maxRowFound);
      return;
    }
    _errorOnce("setRowCount cannot be used while using row grouping.");
    return;
  }
  const infiniteRowModel = beans.rowModelHelperService?.getInfiniteRowModel();
  if (infiniteRowModel) {
    infiniteRowModel.setRowCount(rowCount, maxRowFound);
    return;
  }
}
function getCacheBlockState(beans) {
  return beans.rowNodeBlockLoader?.getBlockState() ?? {};
}

// community-modules/core/src/api/sharedApiModule.ts
var CsrmSsrmSharedApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/csrm-ssrm-shared-api",
  apiFunctions: {
    expandAll,
    collapseAll,
    onRowHeightChanged
  }
};
var SsrmInfiniteSharedApiModule = {
  version: VERSION,
  moduleName: "@ag-grid-community/ssrm-infinite-shared-api",
  apiFunctions: {
    setRowCount,
    getCacheBlockState
  }
};

// community-modules/core/src/components/framework/frameworkComponentWrapper.ts
var BaseComponentWrapper = class {
  wrap(OriginalConstructor, mandatoryMethodList, optionalMethodList = [], componentType) {
    const wrapper = this.createWrapper(OriginalConstructor, componentType);
    mandatoryMethodList.forEach((methodName) => {
      this.createMethod(wrapper, methodName, true);
    });
    optionalMethodList.forEach((methodName) => {
      this.createMethod(wrapper, methodName, false);
    });
    return wrapper;
  }
  unwrap(comp) {
    return comp;
  }
  createMethod(wrapper, methodName, mandatory) {
    wrapper.addMethod(methodName, this.createMethodProxy(wrapper, methodName, mandatory));
  }
  createMethodProxy(wrapper, methodName, mandatory) {
    return function() {
      if (wrapper.hasMethod(methodName)) {
        return wrapper.callMethod(methodName, arguments);
      }
      if (mandatory) {
        _warnOnce("Framework component is missing the method " + methodName + "()");
      }
      return null;
    };
  }
};

// community-modules/core/src/utils/rowNode.ts
function _sortRowNodesByOrder(rowNodes, rowNodeOrder) {
  if (!rowNodes) {
    return false;
  }
  const comparator = (nodeA, nodeB) => {
    const positionA = rowNodeOrder[nodeA.id];
    const positionB = rowNodeOrder[nodeB.id];
    const aHasIndex = positionA !== void 0;
    const bHasIndex = positionB !== void 0;
    const bothNodesAreUserNodes = aHasIndex && bHasIndex;
    const bothNodesAreFillerNodes = !aHasIndex && !bHasIndex;
    if (bothNodesAreUserNodes) {
      return positionA - positionB;
    }
    if (bothNodesAreFillerNodes) {
      return nodeA.__objectId - nodeB.__objectId;
    }
    if (aHasIndex) {
      return 1;
    }
    return -1;
  };
  let rowNodeA;
  let rowNodeB;
  let atLeastOneOutOfOrder = false;
  for (let i = 0; i < rowNodes.length - 1; i++) {
    rowNodeA = rowNodes[i];
    rowNodeB = rowNodes[i + 1];
    if (comparator(rowNodeA, rowNodeB) > 0) {
      atLeastOneOutOfOrder = true;
      break;
    }
  }
  if (atLeastOneOutOfOrder) {
    rowNodes.sort(comparator);
    return true;
  }
  return false;
}

// community-modules/core/src/interfaces/iChartOptions.ts
var ChartMappings = class {
};
ChartMappings.CHART_TYPE_TO_SERIES_TYPE = {
  column: "bar",
  groupedColumn: "bar",
  stackedColumn: "bar",
  normalizedColumn: "bar",
  bar: "bar",
  groupedBar: "bar",
  stackedBar: "bar",
  normalizedBar: "bar",
  line: "line",
  scatter: "scatter",
  bubble: "bubble",
  pie: "pie",
  donut: "donut",
  doughnut: "donut",
  area: "area",
  stackedArea: "area",
  normalizedArea: "area",
  histogram: "histogram",
  radarLine: "radar-line",
  radarArea: "radar-area",
  nightingale: "nightingale",
  radialColumn: "radial-column",
  radialBar: "radial-bar",
  sunburst: "sunburst",
  rangeBar: "range-bar",
  rangeArea: "range-area",
  boxPlot: "box-plot",
  treemap: "treemap",
  heatmap: "heatmap",
  waterfall: "waterfall"
};
ChartMappings.COMBO_CHART_TYPES = ["columnLineCombo", "areaColumnCombo", "customCombo"];
ChartMappings.SERIES_GROUP_TYPES = ["grouped", "stacked", "normalized"];

// community-modules/core/src/interfaces/iSparklineCellRendererParams.ts
var BarColumnLabelPlacement = /* @__PURE__ */ ((BarColumnLabelPlacement2) => {
  BarColumnLabelPlacement2["InsideBase"] = "insideBase";
  BarColumnLabelPlacement2["InsideEnd"] = "insideEnd";
  BarColumnLabelPlacement2["Center"] = "center";
  BarColumnLabelPlacement2["OutsideEnd"] = "outsideEnd";
  return BarColumnLabelPlacement2;
})(BarColumnLabelPlacement || {});

// community-modules/core/src/main.ts
var globalObj = typeof global === "undefined" ? {} : global;
globalObj.HTMLElement = typeof HTMLElement === "undefined" ? {} : HTMLElement;
globalObj.HTMLButtonElement = typeof HTMLButtonElement === "undefined" ? {} : HTMLButtonElement;
globalObj.HTMLSelectElement = typeof HTMLSelectElement === "undefined" ? {} : HTMLSelectElement;
globalObj.HTMLInputElement = typeof HTMLInputElement === "undefined" ? {} : HTMLInputElement;
globalObj.Node = typeof Node === "undefined" ? {} : Node;
globalObj.MouseEvent = typeof MouseEvent === "undefined" ? {} : MouseEvent;

// community-modules/client-side-row-model/src/clientSideRowModel/clientSideNodeManager.ts
var ROOT_NODE_ID = "ROOT_NODE_ID";
var TOP_LEVEL = 0;
var ClientSideNodeManager = class {
  constructor(rootNode, gos, eventService, funcColsService, selectionService, beans) {
    this.nextId = 0;
    // has row data actually been set
    this.rowCountReady = false;
    // when user is provide the id's, we also keep a map of ids to row nodes for convenience
    this.allNodesMap = {};
    this.rootNode = rootNode;
    this.gos = gos;
    this.eventService = eventService;
    this.funcColsService = funcColsService;
    this.beans = beans;
    this.selectionService = selectionService;
    this.rootNode.group = true;
    this.rootNode.level = -1;
    this.rootNode.id = ROOT_NODE_ID;
    this.rootNode.allLeafChildren = [];
    this.rootNode.childrenAfterGroup = [];
    this.rootNode.childrenAfterSort = [];
    this.rootNode.childrenAfterAggFilter = [];
    this.rootNode.childrenAfterFilter = [];
  }
  getCopyOfNodesMap() {
    return _cloneObject(this.allNodesMap);
  }
  getRowNode(id) {
    return this.allNodesMap[id];
  }
  setRowData(rowData) {
    if (typeof rowData === "string") {
      _warnOnce("rowData must be an array.");
      return;
    }
    this.rowCountReady = true;
    this.dispatchRowDataUpdateStartedEvent(rowData);
    const rootNode = this.rootNode;
    const sibling = this.rootNode.sibling;
    rootNode.childrenAfterFilter = null;
    rootNode.childrenAfterGroup = null;
    rootNode.childrenAfterAggFilter = null;
    rootNode.childrenAfterSort = null;
    rootNode.childrenMapped = null;
    rootNode.updateHasChildren();
    this.nextId = 0;
    this.allNodesMap = {};
    if (rowData) {
      rootNode.allLeafChildren = rowData.map((dataItem) => this.createNode(dataItem, this.rootNode, TOP_LEVEL));
    } else {
      rootNode.allLeafChildren = [];
      rootNode.childrenAfterGroup = [];
    }
    if (sibling) {
      sibling.childrenAfterFilter = rootNode.childrenAfterFilter;
      sibling.childrenAfterGroup = rootNode.childrenAfterGroup;
      sibling.childrenAfterAggFilter = rootNode.childrenAfterAggFilter;
      sibling.childrenAfterSort = rootNode.childrenAfterSort;
      sibling.childrenMapped = rootNode.childrenMapped;
      sibling.allLeafChildren = rootNode.allLeafChildren;
    }
  }
  updateRowData(rowDataTran, rowNodeOrder) {
    this.rowCountReady = true;
    this.dispatchRowDataUpdateStartedEvent(rowDataTran.add);
    const rowNodeTransaction = {
      remove: [],
      update: [],
      add: []
    };
    const nodesToUnselect = [];
    this.executeRemove(rowDataTran, rowNodeTransaction, nodesToUnselect);
    this.executeUpdate(rowDataTran, rowNodeTransaction, nodesToUnselect);
    this.executeAdd(rowDataTran, rowNodeTransaction);
    this.updateSelection(nodesToUnselect, "rowDataChanged");
    if (rowNodeOrder) {
      _sortRowNodesByOrder(this.rootNode.allLeafChildren, rowNodeOrder);
    }
    return rowNodeTransaction;
  }
  isRowCountReady() {
    return this.rowCountReady;
  }
  dispatchRowDataUpdateStartedEvent(rowData) {
    const event = {
      type: "rowDataUpdateStarted",
      firstRowData: rowData?.length ? rowData[0] : null
    };
    this.eventService.dispatchEvent(event);
  }
  updateSelection(nodesToUnselect, source) {
    const selectionChanged = nodesToUnselect.length > 0;
    if (selectionChanged) {
      this.selectionService.setNodesSelected({
        newValue: false,
        nodes: nodesToUnselect,
        suppressFinishActions: true,
        source
      });
    }
    this.selectionService.updateGroupsFromChildrenSelections(source);
    if (selectionChanged) {
      const event = {
        type: "selectionChanged",
        source
      };
      this.eventService.dispatchEvent(event);
    }
  }
  executeAdd(rowDataTran, rowNodeTransaction) {
    const { add, addIndex } = rowDataTran;
    if (_missingOrEmpty(add)) {
      return;
    }
    const newNodes = add.map((item) => this.createNode(item, this.rootNode, TOP_LEVEL));
    const allLeafChildren = this.rootNode.allLeafChildren;
    if (typeof addIndex === "number" && addIndex >= 0) {
      const len = allLeafChildren.length;
      let normalisedAddIndex = addIndex;
      const isTreeData = this.gos.get("treeData");
      if (isTreeData && addIndex > 0 && len > 0) {
        for (let i = 0; i < len; i++) {
          if (allLeafChildren[i]?.rowIndex == addIndex - 1) {
            normalisedAddIndex = i + 1;
            break;
          }
        }
      }
      const nodesBeforeIndex = allLeafChildren.slice(0, normalisedAddIndex);
      const nodesAfterIndex = allLeafChildren.slice(normalisedAddIndex, allLeafChildren.length);
      this.rootNode.allLeafChildren = [...nodesBeforeIndex, ...newNodes, ...nodesAfterIndex];
    } else {
      this.rootNode.allLeafChildren = [...allLeafChildren, ...newNodes];
    }
    if (this.rootNode.sibling) {
      this.rootNode.sibling.allLeafChildren = allLeafChildren;
    }
    rowNodeTransaction.add = newNodes;
  }
  executeRemove(rowDataTran, rowNodeTransaction, nodesToUnselect) {
    const { remove } = rowDataTran;
    if (_missingOrEmpty(remove)) {
      return;
    }
    const rowIdsRemoved = {};
    remove.forEach((item) => {
      const rowNode = this.lookupRowNode(item);
      if (!rowNode) {
        return;
      }
      if (rowNode.isSelected()) {
        nodesToUnselect.push(rowNode);
      }
      rowNode.clearRowTopAndRowIndex();
      rowIdsRemoved[rowNode.id] = true;
      delete this.allNodesMap[rowNode.id];
      rowNodeTransaction.remove.push(rowNode);
    });
    this.rootNode.allLeafChildren = this.rootNode.allLeafChildren?.filter((rowNode) => !rowIdsRemoved[rowNode.id]) ?? null;
    if (this.rootNode.sibling) {
      this.rootNode.sibling.allLeafChildren = this.rootNode.allLeafChildren;
    }
  }
  executeUpdate(rowDataTran, rowNodeTransaction, nodesToUnselect) {
    const { update } = rowDataTran;
    if (_missingOrEmpty(update)) {
      return;
    }
    update.forEach((item) => {
      const rowNode = this.lookupRowNode(item);
      if (!rowNode) {
        return;
      }
      rowNode.updateData(item);
      if (!rowNode.selectable && rowNode.isSelected()) {
        nodesToUnselect.push(rowNode);
      }
      this.setMasterForRow(rowNode, item, TOP_LEVEL, false);
      rowNodeTransaction.update.push(rowNode);
    });
  }
  lookupRowNode(data) {
    const getRowIdFunc = this.gos.getRowIdCallback();
    let rowNode;
    if (getRowIdFunc) {
      const id = getRowIdFunc({ data, level: 0 });
      rowNode = this.allNodesMap[id];
      if (!rowNode) {
        _errorOnce(`could not find row id=${id}, data item was not found for this id`);
        return null;
      }
    } else {
      rowNode = this.rootNode.allLeafChildren?.find((node) => node.data === data);
      if (!rowNode) {
        _errorOnce(`could not find data item as object was not found`, data);
        _errorOnce(`Consider using getRowId to help the Grid find matching row data`);
        return null;
      }
    }
    return rowNode || null;
  }
  createNode(dataItem, parent, level) {
    const node = new RowNode(this.beans);
    node.group = false;
    this.setMasterForRow(node, dataItem, level, true);
    if (parent) {
      node.parent = parent;
    }
    node.level = level;
    node.setDataAndId(dataItem, this.nextId.toString());
    if (this.allNodesMap[node.id]) {
      _warnOnce(
        `duplicate node id '${node.id}' detected from getRowId callback, this could cause issues in your grid.`
      );
    }
    this.allNodesMap[node.id] = node;
    this.nextId++;
    return node;
  }
  setMasterForRow(rowNode, data, level, setExpanded) {
    const isTreeData = this.gos.get("treeData");
    if (isTreeData) {
      rowNode.setMaster(false);
      if (setExpanded) {
        rowNode.expanded = false;
      }
    } else {
      const masterDetail = this.gos.get("masterDetail");
      if (masterDetail) {
        const isRowMasterFunc = this.gos.get("isRowMaster");
        if (isRowMasterFunc) {
          rowNode.setMaster(isRowMasterFunc(data));
        } else {
          rowNode.setMaster(true);
        }
      } else {
        rowNode.setMaster(false);
      }
      if (setExpanded) {
        const rowGroupColumns = this.funcColsService.getRowGroupColumns();
        const numRowGroupColumns = rowGroupColumns ? rowGroupColumns.length : 0;
        const masterRowLevel = level + numRowGroupColumns;
        rowNode.expanded = rowNode.master ? this.isExpanded(masterRowLevel) : false;
      }
    }
  }
  isExpanded(level) {
    const expandByDefault = this.gos.get("groupDefaultExpanded");
    if (expandByDefault === -1) {
      return true;
    }
    return level < expandByDefault;
  }
};

// community-modules/client-side-row-model/src/clientSideRowModel/clientSideRowModel.ts
var ClientSideRowModel = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "rowModel";
    this.onRowHeightChanged_debounced = _debounce(this.onRowHeightChanged.bind(this), 100);
    this.rowsToDisplay = [];
    /** Has the start method been called */
    this.hasStarted = false;
    /** E.g. data has been set into the node manager already */
    this.shouldSkipSettingDataOnStart = false;
    /**
     * This is to prevent refresh model being called when it's already being called.
     * E.g. the group stage can trigger initial state filter model to be applied. This fires onFilterChanged,
     * which then triggers the listener here that calls refresh model again but at the filter stage
     * (which is about to be run by the original call).
     */
    this.isRefreshingModel = false;
    this.rowCountReady = false;
  }
  wireBeans(beans) {
    this.beans = beans;
    this.columnModel = beans.columnModel;
    this.funcColsService = beans.funcColsService;
    this.selectionService = beans.selectionService;
    this.valueCache = beans.valueCache;
    this.environment = beans.environment;
    this.filterStage = beans.filterStage;
    this.sortStage = beans.sortStage;
    this.flattenStage = beans.flattenStage;
    this.groupStage = beans.groupStage;
    this.aggregationStage = beans.aggregationStage;
    this.pivotStage = beans.pivotStage;
    this.filterAggregatesStage = beans.filterAggregatesStage;
  }
  postConstruct() {
    const refreshEverythingFunc = this.refreshModel.bind(this, { step: "group" /* EVERYTHING */ });
    const animate = !this.gos.get("suppressAnimationFrame");
    const refreshEverythingAfterColsChangedFunc = this.refreshModel.bind(this, {
      step: "group" /* EVERYTHING */,
      // after cols change, row grouping (the first stage) could of changed
      afterColumnsChanged: true,
      keepRenderedRows: true,
      // we want animations cos sorting or filtering could be applied
      animate
    });
    this.addManagedEventListeners({
      newColumnsLoaded: refreshEverythingAfterColsChangedFunc,
      columnRowGroupChanged: refreshEverythingFunc,
      columnValueChanged: this.onValueChanged.bind(this),
      columnPivotChanged: this.refreshModel.bind(this, { step: "pivot" /* PIVOT */ }),
      filterChanged: this.onFilterChanged.bind(this),
      sortChanged: this.onSortChanged.bind(this),
      columnPivotModeChanged: refreshEverythingFunc,
      gridStylesChanged: this.onGridStylesChanges.bind(this),
      gridReady: this.onGridReady.bind(this)
    });
    this.addPropertyListeners();
    this.rootNode = new RowNode(this.beans);
    this.nodeManager = new ClientSideNodeManager(
      this.rootNode,
      this.gos,
      this.eventService,
      this.funcColsService,
      this.selectionService,
      this.beans
    );
  }
  addPropertyListeners() {
    const resetProps = /* @__PURE__ */ new Set(["treeData", "masterDetail"]);
    const groupStageRefreshProps = /* @__PURE__ */ new Set([
      "groupDefaultExpanded",
      "groupAllowUnbalanced",
      "initialGroupOrderComparator",
      "groupHideOpenParents",
      "groupDisplayType"
    ]);
    const filterStageRefreshProps = /* @__PURE__ */ new Set(["excludeChildrenWhenTreeDataFiltering"]);
    const pivotStageRefreshProps = /* @__PURE__ */ new Set([
      "removePivotHeaderRowWhenSingleValueColumn",
      "pivotRowTotals",
      "pivotColumnGroupTotals",
      "suppressExpandablePivotGroups"
    ]);
    const aggregateStageRefreshProps = /* @__PURE__ */ new Set([
      "getGroupRowAgg",
      "alwaysAggregateAtRootLevel",
      "groupIncludeTotalFooter",
      "suppressAggFilteredOnly",
      "grandTotalRow"
    ]);
    const sortStageRefreshProps = /* @__PURE__ */ new Set([
      "postSortRows",
      "groupDisplayType",
      "accentedSort"
    ]);
    const filterAggStageRefreshProps = /* @__PURE__ */ new Set([]);
    const flattenStageRefreshProps = /* @__PURE__ */ new Set([
      "groupRemoveSingleChildren",
      "groupRemoveLowestSingleChildren",
      "groupIncludeFooter",
      "groupTotalRow"
    ]);
    const allProps = [
      ...resetProps,
      ...groupStageRefreshProps,
      ...filterStageRefreshProps,
      ...pivotStageRefreshProps,
      ...pivotStageRefreshProps,
      ...aggregateStageRefreshProps,
      ...sortStageRefreshProps,
      ...filterAggStageRefreshProps,
      ...flattenStageRefreshProps
    ];
    this.addManagedPropertyListeners(allProps, (params) => {
      const properties = params.changeSet?.properties;
      if (!properties) {
        return;
      }
      const arePropertiesImpacted = (propSet) => properties.some((prop) => propSet.has(prop));
      if (arePropertiesImpacted(resetProps)) {
        this.setRowData(this.rootNode.allLeafChildren.map((child) => child.data));
        return;
      }
      if (arePropertiesImpacted(groupStageRefreshProps)) {
        this.refreshModel({ step: "group" /* EVERYTHING */ });
        return;
      }
      if (arePropertiesImpacted(filterStageRefreshProps)) {
        this.refreshModel({ step: "filter" /* FILTER */ });
        return;
      }
      if (arePropertiesImpacted(pivotStageRefreshProps)) {
        this.refreshModel({ step: "pivot" /* PIVOT */ });
        return;
      }
      if (arePropertiesImpacted(aggregateStageRefreshProps)) {
        this.refreshModel({ step: "aggregate" /* AGGREGATE */ });
        return;
      }
      if (arePropertiesImpacted(sortStageRefreshProps)) {
        this.refreshModel({ step: "sort" /* SORT */ });
        return;
      }
      if (arePropertiesImpacted(filterAggStageRefreshProps)) {
        this.refreshModel({ step: "filter_aggregates" /* FILTER_AGGREGATES */ });
        return;
      }
      if (arePropertiesImpacted(flattenStageRefreshProps)) {
        this.refreshModel({ step: "map" /* MAP */ });
      }
    });
    this.addManagedPropertyListener("rowHeight", () => this.resetRowHeights());
  }
  start() {
    this.hasStarted = true;
    if (this.shouldSkipSettingDataOnStart) {
      this.dispatchUpdateEventsAndRefresh();
    } else {
      this.setInitialData();
    }
  }
  setInitialData() {
    const rowData = this.gos.get("rowData");
    if (rowData) {
      this.shouldSkipSettingDataOnStart = true;
      this.setRowData(rowData);
    }
  }
  ensureRowHeightsValid(startPixel, endPixel, startLimitIndex, endLimitIndex) {
    let atLeastOneChange;
    let res = false;
    do {
      atLeastOneChange = false;
      const rowAtStartPixel = this.getRowIndexAtPixel(startPixel);
      const rowAtEndPixel = this.getRowIndexAtPixel(endPixel);
      const firstRow = Math.max(rowAtStartPixel, startLimitIndex);
      const lastRow = Math.min(rowAtEndPixel, endLimitIndex);
      for (let rowIndex = firstRow; rowIndex <= lastRow; rowIndex++) {
        const rowNode = this.getRow(rowIndex);
        if (rowNode.rowHeightEstimated) {
          const rowHeight = this.gos.getRowHeightForNode(rowNode);
          rowNode.setRowHeight(rowHeight.height);
          atLeastOneChange = true;
          res = true;
        }
      }
      if (atLeastOneChange) {
        this.setRowTopAndRowIndex();
      }
    } while (atLeastOneChange);
    return res;
  }
  setRowTopAndRowIndex() {
    const defaultRowHeight = this.environment.getDefaultRowHeight();
    let nextRowTop = 0;
    const displayedRowsMapped = /* @__PURE__ */ new Set();
    const allowEstimate = this.gos.isDomLayout("normal");
    for (let i = 0; i < this.rowsToDisplay.length; i++) {
      const rowNode = this.rowsToDisplay[i];
      if (rowNode.id != null) {
        displayedRowsMapped.add(rowNode.id);
      }
      if (rowNode.rowHeight == null) {
        const rowHeight = this.gos.getRowHeightForNode(rowNode, allowEstimate, defaultRowHeight);
        rowNode.setRowHeight(rowHeight.height, rowHeight.estimated);
      }
      rowNode.setRowTop(nextRowTop);
      rowNode.setRowIndex(i);
      nextRowTop += rowNode.rowHeight;
    }
    return displayedRowsMapped;
  }
  clearRowTopAndRowIndex(changedPath, displayedRowsMapped) {
    const changedPathActive = changedPath.isActive();
    const clearIfNotDisplayed = (rowNode) => {
      if (rowNode && rowNode.id != null && !displayedRowsMapped.has(rowNode.id)) {
        rowNode.clearRowTopAndRowIndex();
      }
    };
    const recurse = (rowNode) => {
      clearIfNotDisplayed(rowNode);
      clearIfNotDisplayed(rowNode.detailNode);
      clearIfNotDisplayed(rowNode.sibling);
      if (rowNode.hasChildren()) {
        if (rowNode.childrenAfterGroup) {
          const isRootNode = rowNode.level == -1;
          const skipChildren = changedPathActive && !isRootNode && !rowNode.expanded;
          if (!skipChildren) {
            rowNode.childrenAfterGroup.forEach(recurse);
          }
        }
      }
    };
    recurse(this.rootNode);
  }
  // returns false if row was moved, otherwise true
  ensureRowsAtPixel(rowNodes, pixel, increment = 0) {
    const indexAtPixelNow = this.getRowIndexAtPixel(pixel);
    const rowNodeAtPixelNow = this.getRow(indexAtPixelNow);
    const animate = !this.gos.get("suppressAnimationFrame");
    if (rowNodeAtPixelNow === rowNodes[0]) {
      return false;
    }
    rowNodes.forEach((rowNode) => {
      _removeFromArray(this.rootNode.allLeafChildren, rowNode);
    });
    rowNodes.forEach((rowNode, idx) => {
      _insertIntoArray(this.rootNode.allLeafChildren, rowNode, Math.max(indexAtPixelNow + increment, 0) + idx);
    });
    this.refreshModel({
      step: "group" /* EVERYTHING */,
      keepRenderedRows: true,
      keepEditingRows: true,
      animate
    });
    return true;
  }
  highlightRowAtPixel(rowNode, pixel) {
    const indexAtPixelNow = pixel != null ? this.getRowIndexAtPixel(pixel) : null;
    const rowNodeAtPixelNow = indexAtPixelNow != null ? this.getRow(indexAtPixelNow) : null;
    if (!rowNodeAtPixelNow || !rowNode || rowNodeAtPixelNow === rowNode || pixel == null) {
      if (this.lastHighlightedRow) {
        this.lastHighlightedRow.setHighlighted(null);
        this.lastHighlightedRow = null;
      }
      return;
    }
    const highlight = this.getHighlightPosition(pixel, rowNodeAtPixelNow);
    if (this.lastHighlightedRow && this.lastHighlightedRow !== rowNodeAtPixelNow) {
      this.lastHighlightedRow.setHighlighted(null);
      this.lastHighlightedRow = null;
    }
    rowNodeAtPixelNow.setHighlighted(highlight);
    this.lastHighlightedRow = rowNodeAtPixelNow;
  }
  getHighlightPosition(pixel, rowNode) {
    if (!rowNode) {
      const index = this.getRowIndexAtPixel(pixel);
      rowNode = this.getRow(index || 0);
      if (!rowNode) {
        return 1 /* Below */;
      }
    }
    const { rowTop, rowHeight } = rowNode;
    return pixel - rowTop < rowHeight / 2 ? 0 /* Above */ : 1 /* Below */;
  }
  getLastHighlightedRowNode() {
    return this.lastHighlightedRow;
  }
  isLastRowIndexKnown() {
    return true;
  }
  getRowCount() {
    if (this.rowsToDisplay) {
      return this.rowsToDisplay.length;
    }
    return 0;
  }
  getTopLevelRowCount() {
    const showingRootNode = this.rowsToDisplay && this.rowsToDisplay[0] === this.rootNode;
    if (showingRootNode) {
      return 1;
    }
    const totalFooterInc = this.rootNode.sibling ? 1 : 0;
    const filteredChildren = this.rootNode.childrenAfterAggFilter;
    return (filteredChildren ? filteredChildren.length : 0) + totalFooterInc;
  }
  getTopLevelRowDisplayedIndex(topLevelIndex) {
    const showingRootNode = this.rowsToDisplay && this.rowsToDisplay[0] === this.rootNode;
    if (showingRootNode) {
      return topLevelIndex;
    }
    let adjustedIndex = topLevelIndex;
    if (this.rowsToDisplay[0].footer) {
      if (topLevelIndex === 0) {
        return 0;
      }
      adjustedIndex -= 1;
    }
    let rowNode = this.rootNode.childrenAfterSort[adjustedIndex];
    if (this.gos.get("groupHideOpenParents")) {
      while (rowNode.expanded && rowNode.childrenAfterSort && rowNode.childrenAfterSort.length > 0) {
        rowNode = rowNode.childrenAfterSort[0];
      }
    }
    return rowNode.rowIndex;
  }
  getRowBounds(index) {
    if (_missing(this.rowsToDisplay)) {
      return null;
    }
    const rowNode = this.rowsToDisplay[index];
    if (rowNode) {
      return {
        rowTop: rowNode.rowTop,
        rowHeight: rowNode.rowHeight
      };
    }
    return null;
  }
  onRowGroupOpened() {
    const animate = this.gos.isAnimateRows();
    this.refreshModel({ step: "map" /* MAP */, keepRenderedRows: true, animate });
  }
  onFilterChanged(event) {
    if (event.afterDataChange) {
      return;
    }
    const animate = this.gos.isAnimateRows();
    const primaryOrQuickFilterChanged = event.columns.length === 0 || event.columns.some((col) => col.isPrimary());
    const step = primaryOrQuickFilterChanged ? "filter" /* FILTER */ : "filter_aggregates" /* FILTER_AGGREGATES */;
    this.refreshModel({ step, keepRenderedRows: true, animate });
  }
  onSortChanged() {
    const animate = this.gos.isAnimateRows();
    this.refreshModel({
      step: "sort" /* SORT */,
      keepRenderedRows: true,
      animate,
      keepEditingRows: true
    });
  }
  getType() {
    return "clientSide";
  }
  onValueChanged() {
    if (this.columnModel.isPivotActive()) {
      this.refreshModel({ step: "pivot" /* PIVOT */ });
    } else {
      this.refreshModel({ step: "aggregate" /* AGGREGATE */ });
    }
  }
  createChangePath(rowNodeTransactions) {
    const noTransactions = _missingOrEmpty(rowNodeTransactions);
    const changedPath = new ChangedPath(false, this.rootNode);
    if (noTransactions || this.gos.get("treeData")) {
      changedPath.setInactive();
    }
    return changedPath;
  }
  isSuppressModelUpdateAfterUpdateTransaction(params) {
    if (!this.gos.get("suppressModelUpdateAfterUpdateTransaction")) {
      return false;
    }
    if (params.rowNodeTransactions == null) {
      return false;
    }
    const transWithAddsOrDeletes = params.rowNodeTransactions.filter(
      (tx) => tx.add != null && tx.add.length > 0 || tx.remove != null && tx.remove.length > 0
    );
    const transactionsContainUpdatesOnly = transWithAddsOrDeletes == null || transWithAddsOrDeletes.length == 0;
    return transactionsContainUpdatesOnly;
  }
  buildRefreshModelParams(step) {
    let paramsStep = "group" /* EVERYTHING */;
    const stepsMapped = {
      everything: "group" /* EVERYTHING */,
      group: "group" /* EVERYTHING */,
      filter: "filter" /* FILTER */,
      map: "map" /* MAP */,
      aggregate: "aggregate" /* AGGREGATE */,
      sort: "sort" /* SORT */,
      pivot: "pivot" /* PIVOT */
    };
    if (_exists(step)) {
      paramsStep = stepsMapped[step];
    }
    if (_missing(paramsStep)) {
      _errorOnce(`invalid step ${step}, available steps are ${Object.keys(stepsMapped).join(", ")}`);
      return void 0;
    }
    const animate = !this.gos.get("suppressAnimationFrame");
    const modelParams = {
      step: paramsStep,
      keepRenderedRows: true,
      keepEditingRows: true,
      animate
    };
    return modelParams;
  }
  refreshModel(paramsOrStep) {
    if (!this.hasStarted || this.isRefreshingModel || this.columnModel.isChangeEventsDispatching()) {
      return;
    }
    const params = typeof paramsOrStep === "object" && "step" in paramsOrStep ? paramsOrStep : this.buildRefreshModelParams(paramsOrStep);
    if (!params) {
      return;
    }
    if (this.isSuppressModelUpdateAfterUpdateTransaction(params)) {
      return;
    }
    const changedPath = this.createChangePath(params.rowNodeTransactions);
    this.isRefreshingModel = true;
    switch (params.step) {
      case "group" /* EVERYTHING */:
        this.doRowGrouping(
          params.rowNodeTransactions,
          params.rowNodeOrder,
          changedPath,
          !!params.afterColumnsChanged
        );
      case "filter" /* FILTER */:
        this.doFilter(changedPath);
      case "pivot" /* PIVOT */:
        this.doPivot(changedPath);
      case "aggregate" /* AGGREGATE */:
        this.doAggregate(changedPath);
      case "filter_aggregates" /* FILTER_AGGREGATES */:
        this.doFilterAggregates(changedPath);
      case "sort" /* SORT */:
        this.doSort(params.rowNodeTransactions, changedPath);
      case "map" /* MAP */:
        this.doRowsToDisplay();
    }
    const displayedNodesMapped = this.setRowTopAndRowIndex();
    this.clearRowTopAndRowIndex(changedPath, displayedNodesMapped);
    this.isRefreshingModel = false;
    const event = {
      type: "modelUpdated",
      animate: params.animate,
      keepRenderedRows: params.keepRenderedRows,
      newData: params.newData,
      newPage: false,
      keepUndoRedoStack: params.keepUndoRedoStack
    };
    this.eventService.dispatchEvent(event);
  }
  isEmpty() {
    const rowsMissing = _missing(this.rootNode.allLeafChildren) || this.rootNode.allLeafChildren.length === 0;
    return _missing(this.rootNode) || rowsMissing || !this.columnModel.isReady();
  }
  isRowsToRender() {
    return _exists(this.rowsToDisplay) && this.rowsToDisplay.length > 0;
  }
  getNodesInRangeForSelection(firstInRange, lastInRange) {
    let started = false;
    let finished = false;
    const result = [];
    const groupsSelectChildren = this.gos.get("groupSelectsChildren");
    this.forEachNodeAfterFilterAndSort((rowNode) => {
      if (finished) {
        return;
      }
      if (started) {
        if (rowNode === lastInRange || rowNode === firstInRange) {
          finished = true;
          if (rowNode.group && groupsSelectChildren) {
            result.push(...rowNode.allLeafChildren);
            return;
          }
        }
      }
      if (!started) {
        if (rowNode !== lastInRange && rowNode !== firstInRange) {
          return;
        }
        started = true;
      }
      const includeThisNode = !rowNode.group || !groupsSelectChildren;
      if (includeThisNode) {
        result.push(rowNode);
        return;
      }
    });
    return result;
  }
  // eslint-disable-next-line
  setDatasource(datasource) {
    _errorOnce("should never call setDatasource on clientSideRowController");
  }
  getTopLevelNodes() {
    return this.rootNode ? this.rootNode.childrenAfterGroup : null;
  }
  getRootNode() {
    return this.rootNode;
  }
  getRow(index) {
    return this.rowsToDisplay[index];
  }
  isRowPresent(rowNode) {
    return this.rowsToDisplay.indexOf(rowNode) >= 0;
  }
  getRowIndexAtPixel(pixelToMatch) {
    if (this.isEmpty() || this.rowsToDisplay.length === 0) {
      return -1;
    }
    let bottomPointer = 0;
    let topPointer = this.rowsToDisplay.length - 1;
    if (pixelToMatch <= 0) {
      return 0;
    }
    const lastNode = _last(this.rowsToDisplay);
    if (lastNode.rowTop <= pixelToMatch) {
      return this.rowsToDisplay.length - 1;
    }
    let oldBottomPointer = -1;
    let oldTopPointer = -1;
    while (true) {
      const midPointer = Math.floor((bottomPointer + topPointer) / 2);
      const currentRowNode = this.rowsToDisplay[midPointer];
      if (this.isRowInPixel(currentRowNode, pixelToMatch)) {
        return midPointer;
      }
      if (currentRowNode.rowTop < pixelToMatch) {
        bottomPointer = midPointer + 1;
      } else if (currentRowNode.rowTop > pixelToMatch) {
        topPointer = midPointer - 1;
      }
      const caughtInInfiniteLoop = oldBottomPointer === bottomPointer && oldTopPointer === topPointer;
      if (caughtInInfiniteLoop) {
        return midPointer;
      }
      oldBottomPointer = bottomPointer;
      oldTopPointer = topPointer;
    }
  }
  isRowInPixel(rowNode, pixelToMatch) {
    const topPixel = rowNode.rowTop;
    const bottomPixel = rowNode.rowTop + rowNode.rowHeight;
    const pixelInRow = topPixel <= pixelToMatch && bottomPixel > pixelToMatch;
    return pixelInRow;
  }
  forEachLeafNode(callback) {
    if (this.rootNode.allLeafChildren) {
      this.rootNode.allLeafChildren.forEach((rowNode, index) => callback(rowNode, index));
    }
  }
  forEachNode(callback, includeFooterNodes = false) {
    this.recursivelyWalkNodesAndCallback({
      nodes: [...this.rootNode.childrenAfterGroup || []],
      callback,
      recursionType: 0 /* Normal */,
      index: 0,
      includeFooterNodes
    });
  }
  forEachNodeAfterFilter(callback, includeFooterNodes = false) {
    this.recursivelyWalkNodesAndCallback({
      nodes: [...this.rootNode.childrenAfterAggFilter || []],
      callback,
      recursionType: 1 /* AfterFilter */,
      index: 0,
      includeFooterNodes
    });
  }
  forEachNodeAfterFilterAndSort(callback, includeFooterNodes = false) {
    this.recursivelyWalkNodesAndCallback({
      nodes: [...this.rootNode.childrenAfterSort || []],
      callback,
      recursionType: 2 /* AfterFilterAndSort */,
      index: 0,
      includeFooterNodes
    });
  }
  forEachPivotNode(callback, includeFooterNodes = false) {
    this.recursivelyWalkNodesAndCallback({
      nodes: [this.rootNode],
      callback,
      recursionType: 3 /* PivotNodes */,
      index: 0,
      includeFooterNodes
    });
  }
  // iterates through each item in memory, and calls the callback function
  // nodes - the rowNodes to traverse
  // callback - the user provided callback
  // recursion type - need this to know what child nodes to recurse, eg if looking at all nodes, or filtered notes etc
  // index - works similar to the index in forEach in javascript's array function
  recursivelyWalkNodesAndCallback(params) {
    const { nodes, callback, recursionType, includeFooterNodes } = params;
    let { index } = params;
    const addFooters = (position) => {
      const parentNode = nodes[0]?.parent;
      if (!parentNode)
        return;
      const grandTotal = includeFooterNodes && this.gos.getGrandTotalRow();
      const isGroupIncludeFooter = this.gos.getGroupTotalRowCallback();
      const groupTotal = includeFooterNodes && isGroupIncludeFooter({ node: parentNode });
      const isRootNode = parentNode === this.rootNode;
      if (isRootNode) {
        if (grandTotal === position) {
          parentNode.createFooter();
          callback(parentNode.sibling, index++);
        }
        return;
      }
      if (groupTotal === position) {
        parentNode.createFooter();
        callback(parentNode.sibling, index++);
      }
    };
    addFooters("top");
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      callback(node, index++);
      if (node.hasChildren() && !node.footer) {
        let nodeChildren = null;
        switch (recursionType) {
          case 0 /* Normal */:
            nodeChildren = node.childrenAfterGroup;
            break;
          case 1 /* AfterFilter */:
            nodeChildren = node.childrenAfterAggFilter;
            break;
          case 2 /* AfterFilterAndSort */:
            nodeChildren = node.childrenAfterSort;
            break;
          case 3 /* PivotNodes */:
            nodeChildren = !node.leafGroup ? node.childrenAfterSort : null;
            break;
        }
        if (nodeChildren) {
          index = this.recursivelyWalkNodesAndCallback({
            nodes: [...nodeChildren],
            callback,
            recursionType,
            index,
            includeFooterNodes
          });
        }
      }
    }
    addFooters("bottom");
    return index;
  }
  // it's possible to recompute the aggregate without doing the other parts
  // + api.refreshClientSideRowModel('aggregate')
  doAggregate(changedPath) {
    this.aggregationStage?.execute({ rowNode: this.rootNode, changedPath });
  }
  doFilterAggregates(changedPath) {
    if (this.filterAggregatesStage) {
      this.filterAggregatesStage.execute({ rowNode: this.rootNode, changedPath });
    } else {
      this.rootNode.childrenAfterAggFilter = this.rootNode.childrenAfterFilter;
    }
  }
  // + gridApi.expandAll()
  // + gridApi.collapseAll()
  expandOrCollapseAll(expand) {
    const usingTreeData = this.gos.get("treeData");
    const usingPivotMode = this.columnModel.isPivotActive();
    const recursiveExpandOrCollapse = (rowNodes) => {
      if (!rowNodes) {
        return;
      }
      rowNodes.forEach((rowNode) => {
        const actionRow = () => {
          rowNode.expanded = expand;
          recursiveExpandOrCollapse(rowNode.childrenAfterGroup);
        };
        if (usingTreeData) {
          const hasChildren = _exists(rowNode.childrenAfterGroup);
          if (hasChildren) {
            actionRow();
          }
          return;
        }
        if (usingPivotMode) {
          const notLeafGroup = !rowNode.leafGroup;
          if (notLeafGroup) {
            actionRow();
          }
          return;
        }
        const isRowGroup = rowNode.group;
        if (isRowGroup) {
          actionRow();
        }
      });
    };
    if (this.rootNode) {
      recursiveExpandOrCollapse(this.rootNode.childrenAfterGroup);
    }
    this.refreshModel({ step: "map" /* MAP */ });
    const eventSource = expand ? "expandAll" : "collapseAll";
    const event = {
      type: "expandOrCollapseAll",
      source: eventSource
    };
    this.eventService.dispatchEvent(event);
  }
  doSort(rowNodeTransactions, changedPath) {
    this.sortStage.execute({
      rowNode: this.rootNode,
      rowNodeTransactions,
      changedPath
    });
  }
  doRowGrouping(rowNodeTransactions, rowNodeOrder, changedPath, afterColumnsChanged) {
    if (this.groupStage) {
      if (rowNodeTransactions) {
        this.groupStage.execute({
          rowNode: this.rootNode,
          rowNodeTransactions,
          rowNodeOrder,
          changedPath
        });
      } else {
        this.groupStage.execute({
          rowNode: this.rootNode,
          changedPath,
          afterColumnsChanged
        });
      }
      if (this.gos.get("groupSelectsChildren")) {
        const selectionChanged = this.selectionService.updateGroupsFromChildrenSelections(
          "rowGroupChanged",
          changedPath
        );
        if (selectionChanged) {
          const event = {
            type: "selectionChanged",
            source: "rowGroupChanged"
          };
          this.eventService.dispatchEvent(event);
        }
      }
    } else {
      this.rootNode.childrenAfterGroup = this.rootNode.allLeafChildren;
      if (this.rootNode.sibling) {
        this.rootNode.sibling.childrenAfterGroup = this.rootNode.childrenAfterGroup;
      }
      this.rootNode.updateHasChildren();
    }
    if (this.nodeManager.isRowCountReady()) {
      this.rowCountReady = true;
      this.eventService.dispatchEventOnce({
        type: "rowCountReady"
      });
    }
  }
  doFilter(changedPath) {
    this.filterStage.execute({ rowNode: this.rootNode, changedPath });
  }
  doPivot(changedPath) {
    this.pivotStage?.execute({ rowNode: this.rootNode, changedPath });
  }
  getCopyOfNodesMap() {
    return this.nodeManager.getCopyOfNodesMap();
  }
  getRowNode(id) {
    const idIsGroup = typeof id == "string" && id.indexOf(RowNode.ID_PREFIX_ROW_GROUP) == 0;
    if (idIsGroup) {
      let res = void 0;
      this.forEachNode((node) => {
        if (node.id === id) {
          res = node;
        }
      });
      return res;
    }
    return this.nodeManager.getRowNode(id);
  }
  // rows: the rows to put into the model
  setRowData(rowData) {
    this.selectionService.reset("rowDataChanged");
    this.nodeManager.setRowData(rowData);
    if (this.hasStarted) {
      this.dispatchUpdateEventsAndRefresh();
    }
  }
  dispatchUpdateEventsAndRefresh() {
    const rowDataUpdatedEvent = {
      type: "rowDataUpdated"
    };
    this.eventService.dispatchEvent(rowDataUpdatedEvent);
    this.refreshModel({
      step: "group" /* EVERYTHING */,
      newData: true
    });
  }
  batchUpdateRowData(rowDataTransaction, callback) {
    if (this.applyAsyncTransactionsTimeout == null) {
      this.rowDataTransactionBatch = [];
      const waitMillis = this.gos.getAsyncTransactionWaitMillis();
      this.applyAsyncTransactionsTimeout = window.setTimeout(() => {
        this.executeBatchUpdateRowData();
      }, waitMillis);
    }
    this.rowDataTransactionBatch.push({ rowDataTransaction, callback });
  }
  flushAsyncTransactions() {
    if (this.applyAsyncTransactionsTimeout != null) {
      clearTimeout(this.applyAsyncTransactionsTimeout);
      this.executeBatchUpdateRowData();
    }
  }
  executeBatchUpdateRowData() {
    this.valueCache.onDataChanged();
    const callbackFuncsBound = [];
    const rowNodeTrans = [];
    let forceRowNodeOrder = false;
    if (this.rowDataTransactionBatch) {
      this.rowDataTransactionBatch.forEach((tranItem) => {
        const rowNodeTran = this.nodeManager.updateRowData(tranItem.rowDataTransaction, void 0);
        rowNodeTrans.push(rowNodeTran);
        if (tranItem.callback) {
          callbackFuncsBound.push(tranItem.callback.bind(null, rowNodeTran));
        }
        if (typeof tranItem.rowDataTransaction.addIndex === "number") {
          forceRowNodeOrder = true;
        }
      });
    }
    this.commonUpdateRowData(rowNodeTrans, void 0, forceRowNodeOrder);
    if (callbackFuncsBound.length > 0) {
      window.setTimeout(() => {
        callbackFuncsBound.forEach((func) => func());
      }, 0);
    }
    if (rowNodeTrans.length > 0) {
      const event = {
        type: "asyncTransactionsFlushed",
        results: rowNodeTrans
      };
      this.eventService.dispatchEvent(event);
    }
    this.rowDataTransactionBatch = null;
    this.applyAsyncTransactionsTimeout = void 0;
  }
  updateRowData(rowDataTran, rowNodeOrder) {
    this.valueCache.onDataChanged();
    const rowNodeTran = this.nodeManager.updateRowData(rowDataTran, rowNodeOrder);
    const forceRowNodeOrder = typeof rowDataTran.addIndex === "number";
    this.commonUpdateRowData([rowNodeTran], rowNodeOrder, forceRowNodeOrder);
    return rowNodeTran;
  }
  createRowNodeOrder() {
    const suppressSortOrder = this.gos.get("suppressMaintainUnsortedOrder");
    if (suppressSortOrder) {
      return;
    }
    const orderMap = {};
    if (this.rootNode && this.rootNode.allLeafChildren) {
      for (let index = 0; index < this.rootNode.allLeafChildren.length; index++) {
        const node = this.rootNode.allLeafChildren[index];
        orderMap[node.id] = index;
      }
    }
    return orderMap;
  }
  // common to updateRowData and batchUpdateRowData
  commonUpdateRowData(rowNodeTrans, rowNodeOrder, forceRowNodeOrder) {
    if (!this.hasStarted) {
      return;
    }
    const animate = !this.gos.get("suppressAnimationFrame");
    if (forceRowNodeOrder) {
      rowNodeOrder = this.createRowNodeOrder();
    }
    const event = {
      type: "rowDataUpdated"
    };
    this.eventService.dispatchEvent(event);
    this.refreshModel({
      step: "group" /* EVERYTHING */,
      rowNodeTransactions: rowNodeTrans,
      rowNodeOrder,
      keepRenderedRows: true,
      keepEditingRows: true,
      animate
    });
  }
  doRowsToDisplay() {
    this.rowsToDisplay = this.flattenStage.execute({ rowNode: this.rootNode });
  }
  onRowHeightChanged() {
    this.refreshModel({
      step: "map" /* MAP */,
      keepRenderedRows: true,
      keepEditingRows: true,
      keepUndoRedoStack: true
    });
  }
  /** This method is debounced. It is used for row auto-height. If we don't debounce,
   * then the Row Models will end up recalculating each row position
   * for each row height change and result in the Row Renderer laying out rows.
   * This is particularly bad if using print layout, and showing eg 1,000 rows,
   * each row will change it's height, causing Row Model to update 1,000 times.
   */
  onRowHeightChangedDebounced() {
    this.onRowHeightChanged_debounced();
  }
  resetRowHeights() {
    const atLeastOne = this.resetRowHeightsForAllRowNodes();
    this.rootNode.setRowHeight(this.rootNode.rowHeight, true);
    if (this.rootNode.sibling) {
      this.rootNode.sibling.setRowHeight(this.rootNode.sibling.rowHeight, true);
    }
    if (atLeastOne) {
      this.onRowHeightChanged();
    }
  }
  resetRowHeightsForAllRowNodes() {
    let atLeastOne = false;
    this.forEachNode((rowNode) => {
      rowNode.setRowHeight(rowNode.rowHeight, true);
      const detailNode = rowNode.detailNode;
      if (detailNode) {
        detailNode.setRowHeight(detailNode.rowHeight, true);
      }
      if (rowNode.sibling) {
        rowNode.sibling.setRowHeight(rowNode.sibling.rowHeight, true);
      }
      atLeastOne = true;
    });
    return atLeastOne;
  }
  onGridStylesChanges(e) {
    if (e.rowHeightChanged) {
      if (this.columnModel.isAutoRowHeightActive()) {
        return;
      }
      this.resetRowHeights();
    }
  }
  onGridReady() {
    if (this.hasStarted) {
      return;
    }
    this.setInitialData();
  }
  isRowDataLoaded() {
    return this.rowCountReady;
  }
};

// community-modules/client-side-row-model/src/clientSideRowModel/clientSideRowModelApi.ts
function onGroupExpandedOrCollapsed(beans) {
  beans.expansionService.onGroupExpandedOrCollapsed();
}
function refreshClientSideRowModel(beans, step) {
  beans.rowModelHelperService?.getClientSideRowModel()?.refreshModel(step);
}
function forEachLeafNode(beans, callback) {
  beans.rowModelHelperService?.getClientSideRowModel()?.forEachLeafNode(callback);
}
function forEachNodeAfterFilter(beans, callback) {
  beans.rowModelHelperService?.getClientSideRowModel()?.forEachNodeAfterFilter(callback);
}
function forEachNodeAfterFilterAndSort(beans, callback) {
  beans.rowModelHelperService?.getClientSideRowModel()?.forEachNodeAfterFilterAndSort(callback);
}
function resetRowHeights(beans) {
  if (beans.columnModel.isAutoRowHeightActive()) {
    _warnOnce("calling gridApi.resetRowHeights() makes no sense when using Auto Row Height.");
    return;
  }
  beans.rowModelHelperService?.getClientSideRowModel()?.resetRowHeights();
}
function applyTransaction(beans, rowDataTransaction) {
  return beans.frameworkOverrides.wrapIncoming(
    () => beans.rowModelHelperService?.getClientSideRowModel()?.updateRowData(rowDataTransaction)
  );
}
function applyTransactionAsync(beans, rowDataTransaction, callback) {
  beans.frameworkOverrides.wrapIncoming(
    () => beans.rowModelHelperService?.getClientSideRowModel()?.batchUpdateRowData(rowDataTransaction, callback)
  );
}
function flushAsyncTransactions(beans) {
  beans.frameworkOverrides.wrapIncoming(
    () => beans.rowModelHelperService?.getClientSideRowModel()?.flushAsyncTransactions()
  );
}
function getBestCostNodeSelection(beans) {
  return beans.selectionService.getBestCostNodeSelection();
}

// community-modules/client-side-row-model/src/clientSideRowModel/filterStage.ts
var FilterStage = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "filterStage";
  }
  wireBeans(beans) {
    this.filterManager = beans.filterManager;
  }
  execute(params) {
    const { changedPath } = params;
    this.filter(changedPath);
  }
  filter(changedPath) {
    const filterActive = !!this.filterManager?.isChildFilterPresent();
    this.filterNodes(filterActive, changedPath);
  }
  filterNodes(filterActive, changedPath) {
    const filterCallback = (rowNode, includeChildNodes) => {
      if (rowNode.hasChildren()) {
        if (filterActive && !includeChildNodes) {
          rowNode.childrenAfterFilter = rowNode.childrenAfterGroup.filter((childNode) => {
            const passBecauseChildren = childNode.childrenAfterFilter && childNode.childrenAfterFilter.length > 0;
            const passBecauseDataPasses = childNode.data && this.filterManager.doesRowPassFilter({ rowNode: childNode });
            return passBecauseChildren || passBecauseDataPasses;
          });
        } else {
          rowNode.childrenAfterFilter = rowNode.childrenAfterGroup;
        }
      } else {
        rowNode.childrenAfterFilter = rowNode.childrenAfterGroup;
      }
      if (rowNode.sibling) {
        rowNode.sibling.childrenAfterFilter = rowNode.childrenAfterFilter;
      }
    };
    if (this.doingTreeDataFiltering()) {
      const treeDataDepthFirstFilter = (rowNode, alreadyFoundInParent) => {
        if (rowNode.childrenAfterGroup) {
          for (let i = 0; i < rowNode.childrenAfterGroup.length; i++) {
            const childNode = rowNode.childrenAfterGroup[i];
            const foundInParent = alreadyFoundInParent || this.filterManager.doesRowPassFilter({ rowNode: childNode });
            if (childNode.childrenAfterGroup) {
              treeDataDepthFirstFilter(rowNode.childrenAfterGroup[i], foundInParent);
            } else {
              filterCallback(childNode, foundInParent);
            }
          }
        }
        filterCallback(rowNode, alreadyFoundInParent);
      };
      const treeDataFilterCallback = (rowNode) => treeDataDepthFirstFilter(rowNode, false);
      changedPath.executeFromRootNode(treeDataFilterCallback);
    } else {
      const defaultFilterCallback = (rowNode) => filterCallback(rowNode, false);
      changedPath.forEachChangedNodeDepthFirst(defaultFilterCallback, true);
    }
  }
  doingTreeDataFiltering() {
    return this.gos.get("treeData") && !this.gos.get("excludeChildrenWhenTreeDataFiltering");
  }
};

// community-modules/client-side-row-model/src/clientSideRowModel/flattenStage.ts
var FlattenStage = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "flattenStage";
  }
  wireBeans(beans) {
    this.beans = beans;
    this.columnModel = beans.columnModel;
  }
  execute(params) {
    const rootNode = params.rowNode;
    const result = [];
    const skipLeafNodes = this.columnModel.isPivotMode();
    const showRootNode = skipLeafNodes && rootNode.leafGroup;
    const topList = showRootNode ? [rootNode] : rootNode.childrenAfterSort;
    const details = this.getFlattenDetails();
    this.recursivelyAddToRowsToDisplay(details, topList, result, skipLeafNodes, 0);
    const atLeastOneRowPresent = result.length > 0;
    const includeGrandTotalRow = !showRootNode && // don't show total footer when showRootNode is true (i.e. in pivot mode and no groups)
    atLeastOneRowPresent && details.grandTotalRow;
    if (includeGrandTotalRow) {
      rootNode.createFooter();
      const addToTop = details.grandTotalRow === "top";
      this.addRowNodeToRowsToDisplay(details, rootNode.sibling, result, 0, addToTop);
    }
    return result;
  }
  getFlattenDetails() {
    const groupRemoveSingleChildren = this.gos.get("groupRemoveSingleChildren");
    const groupRemoveLowestSingleChildren = !groupRemoveSingleChildren && this.gos.get("groupRemoveLowestSingleChildren");
    return {
      groupRemoveLowestSingleChildren,
      groupRemoveSingleChildren,
      isGroupMultiAutoColumn: this.gos.isGroupMultiAutoColumn(),
      hideOpenParents: this.gos.get("groupHideOpenParents"),
      grandTotalRow: this.gos.getGrandTotalRow(),
      groupTotalRow: this.gos.getGroupTotalRowCallback()
    };
  }
  recursivelyAddToRowsToDisplay(details, rowsToFlatten, result, skipLeafNodes, uiLevel) {
    if (_missingOrEmpty(rowsToFlatten)) {
      return;
    }
    for (let i = 0; i < rowsToFlatten.length; i++) {
      const rowNode = rowsToFlatten[i];
      const isParent = rowNode.hasChildren();
      const isSkippedLeafNode = skipLeafNodes && !isParent;
      const isRemovedSingleChildrenGroup = details.groupRemoveSingleChildren && isParent && rowNode.childrenAfterGroup.length === 1;
      const isRemovedLowestSingleChildrenGroup = details.groupRemoveLowestSingleChildren && isParent && rowNode.leafGroup && rowNode.childrenAfterGroup.length === 1;
      const neverAllowToExpand = skipLeafNodes && rowNode.leafGroup;
      const isHiddenOpenParent = details.hideOpenParents && rowNode.expanded && !rowNode.master && !neverAllowToExpand;
      const thisRowShouldBeRendered = !isSkippedLeafNode && !isHiddenOpenParent && !isRemovedSingleChildrenGroup && !isRemovedLowestSingleChildrenGroup;
      if (thisRowShouldBeRendered) {
        this.addRowNodeToRowsToDisplay(details, rowNode, result, uiLevel);
      }
      if (skipLeafNodes && rowNode.leafGroup) {
        continue;
      }
      if (isParent) {
        const excludedParent = isRemovedSingleChildrenGroup || isRemovedLowestSingleChildrenGroup;
        if (rowNode.expanded || excludedParent) {
          const doesRowShowFooter = details.groupTotalRow({ node: rowNode });
          if (!doesRowShowFooter) {
            rowNode.destroyFooter();
          }
          const uiLevelForChildren = excludedParent ? uiLevel : uiLevel + 1;
          if (doesRowShowFooter === "top") {
            rowNode.createFooter();
            this.addRowNodeToRowsToDisplay(details, rowNode.sibling, result, uiLevelForChildren);
          }
          this.recursivelyAddToRowsToDisplay(
            details,
            rowNode.childrenAfterSort,
            result,
            skipLeafNodes,
            uiLevelForChildren
          );
          if (doesRowShowFooter === "bottom") {
            rowNode.createFooter();
            this.addRowNodeToRowsToDisplay(details, rowNode.sibling, result, uiLevelForChildren);
          }
        }
      } else if (rowNode.master && rowNode.expanded) {
        const detailNode = this.createDetailNode(rowNode);
        this.addRowNodeToRowsToDisplay(details, detailNode, result, uiLevel);
      }
    }
  }
  // duplicated method, it's also in floatingRowModel
  addRowNodeToRowsToDisplay(details, rowNode, result, uiLevel, addToTop) {
    if (addToTop) {
      result.unshift(rowNode);
    } else {
      result.push(rowNode);
    }
    rowNode.setUiLevel(details.isGroupMultiAutoColumn ? 0 : uiLevel);
  }
  createDetailNode(masterNode) {
    if (_exists(masterNode.detailNode)) {
      return masterNode.detailNode;
    }
    const detailNode = new RowNode(this.beans);
    detailNode.detail = true;
    detailNode.selectable = false;
    detailNode.parent = masterNode;
    if (_exists(masterNode.id)) {
      detailNode.id = "detail_" + masterNode.id;
    }
    detailNode.data = masterNode.data;
    detailNode.level = masterNode.level + 1;
    masterNode.detailNode = detailNode;
    return detailNode;
  }
};

// community-modules/client-side-row-model/src/clientSideRowModel/immutableService.ts
var ImmutableService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "immutableService";
  }
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
    this.selectionService = beans.selectionService;
  }
  postConstruct() {
    if (this.rowModel.getType() === "clientSide") {
      this.clientSideRowModel = this.rowModel;
      this.addManagedPropertyListener("rowData", () => this.onRowDataUpdated());
    }
  }
  isActive() {
    const getRowIdProvided = this.gos.exists("getRowId");
    const resetRowDataOnUpdate = this.gos.get("resetRowDataOnUpdate");
    if (resetRowDataOnUpdate) {
      return false;
    }
    return getRowIdProvided;
  }
  setRowData(rowData) {
    const transactionAndMap = this.createTransactionForRowData(rowData);
    if (!transactionAndMap) {
      return;
    }
    const [transaction, orderIdMap] = transactionAndMap;
    this.clientSideRowModel.updateRowData(transaction, orderIdMap);
  }
  // converts the setRowData() command to a transaction
  createTransactionForRowData(rowData) {
    if (_missing(this.clientSideRowModel)) {
      _errorOnce("ImmutableService only works with ClientSideRowModel");
      return;
    }
    const getRowIdFunc = this.gos.getRowIdCallback();
    if (getRowIdFunc == null) {
      _errorOnce("ImmutableService requires getRowId() callback to be implemented, your row data needs IDs!");
      return;
    }
    const transaction = {
      remove: [],
      update: [],
      add: []
    };
    const existingNodesMap = this.clientSideRowModel.getCopyOfNodesMap();
    const suppressSortOrder = this.gos.get("suppressMaintainUnsortedOrder");
    const orderMap = suppressSortOrder ? void 0 : {};
    if (_exists(rowData)) {
      rowData.forEach((data, index) => {
        const id = getRowIdFunc({ data, level: 0 });
        const existingNode = existingNodesMap[id];
        if (orderMap) {
          orderMap[id] = index;
        }
        if (existingNode) {
          const dataHasChanged = existingNode.data !== data;
          if (dataHasChanged) {
            transaction.update.push(data);
          }
          existingNodesMap[id] = void 0;
        } else {
          transaction.add.push(data);
        }
      });
    }
    _iterateObject(existingNodesMap, (id, rowNode) => {
      if (rowNode) {
        transaction.remove.push(rowNode.data);
      }
    });
    return [transaction, orderMap];
  }
  onRowDataUpdated() {
    const rowData = this.gos.get("rowData");
    if (!rowData) {
      return;
    }
    if (this.isActive()) {
      this.setRowData(rowData);
    } else {
      this.selectionService.reset("rowDataChanged");
      this.clientSideRowModel.setRowData(rowData);
    }
  }
};

// community-modules/client-side-row-model/src/clientSideRowModel/sortService.ts
var SortService = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "sortService";
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.funcColsService = beans.funcColsService;
    this.rowNodeSorter = beans.rowNodeSorter;
    this.showRowGroupColsService = beans.showRowGroupColsService;
  }
  sort(sortOptions, sortActive, useDeltaSort, rowNodeTransactions, changedPath, sortContainsGroupColumns) {
    const groupMaintainOrder = this.gos.get("groupMaintainOrder");
    const groupColumnsPresent = this.columnModel.getCols().some((c) => c.isRowGroupActive());
    let allDirtyNodes = {};
    if (useDeltaSort && rowNodeTransactions) {
      allDirtyNodes = this.calculateDirtyNodes(rowNodeTransactions);
    }
    const isPivotMode = this.columnModel.isPivotMode();
    const postSortFunc = this.gos.getCallback("postSortRows");
    const callback = (rowNode) => {
      this.pullDownGroupDataForHideOpenParents(rowNode.childrenAfterAggFilter, true);
      const skipSortingPivotLeafs = isPivotMode && rowNode.leafGroup;
      const skipSortingGroups = groupMaintainOrder && groupColumnsPresent && !rowNode.leafGroup && !sortContainsGroupColumns;
      if (skipSortingGroups) {
        const nextGroup = this.funcColsService.getRowGroupColumns()?.[rowNode.level + 1];
        const wasSortExplicitlyRemoved = nextGroup?.getSort() === null;
        const childrenToBeSorted = rowNode.childrenAfterAggFilter.slice(0);
        if (rowNode.childrenAfterSort && !wasSortExplicitlyRemoved) {
          const indexedOrders = {};
          rowNode.childrenAfterSort.forEach((node, idx) => {
            indexedOrders[node.id] = idx;
          });
          childrenToBeSorted.sort(
            (row1, row2) => (indexedOrders[row1.id] ?? 0) - (indexedOrders[row2.id] ?? 0)
          );
        }
        rowNode.childrenAfterSort = childrenToBeSorted;
      } else if (!sortActive || skipSortingPivotLeafs) {
        rowNode.childrenAfterSort = rowNode.childrenAfterAggFilter.slice(0);
      } else if (useDeltaSort) {
        rowNode.childrenAfterSort = this.doDeltaSort(rowNode, allDirtyNodes, changedPath, sortOptions);
      } else {
        rowNode.childrenAfterSort = this.rowNodeSorter.doFullSort(rowNode.childrenAfterAggFilter, sortOptions);
      }
      if (rowNode.sibling) {
        rowNode.sibling.childrenAfterSort = rowNode.childrenAfterSort;
      }
      this.updateChildIndexes(rowNode);
      if (postSortFunc) {
        const params = { nodes: rowNode.childrenAfterSort };
        postSortFunc(params);
      }
    };
    if (changedPath) {
      changedPath.forEachChangedNodeDepthFirst(callback);
    }
    this.updateGroupDataForHideOpenParents(changedPath);
  }
  calculateDirtyNodes(rowNodeTransactions) {
    const dirtyNodes = {};
    const addNodesFunc = (rowNodes) => {
      if (rowNodes) {
        rowNodes.forEach((rowNode) => dirtyNodes[rowNode.id] = true);
      }
    };
    if (rowNodeTransactions) {
      rowNodeTransactions.forEach((tran) => {
        addNodesFunc(tran.add);
        addNodesFunc(tran.update);
        addNodesFunc(tran.remove);
      });
    }
    return dirtyNodes;
  }
  doDeltaSort(rowNode, allTouchedNodes, changedPath, sortOptions) {
    const unsortedRows = rowNode.childrenAfterAggFilter;
    const oldSortedRows = rowNode.childrenAfterSort;
    if (!oldSortedRows) {
      return this.rowNodeSorter.doFullSort(unsortedRows, sortOptions);
    }
    const untouchedRowsMap = {};
    const touchedRows = [];
    unsortedRows.forEach((row) => {
      if (allTouchedNodes[row.id] || !changedPath.canSkip(row)) {
        touchedRows.push(row);
      } else {
        untouchedRowsMap[row.id] = true;
      }
    });
    const sortedUntouchedRows = oldSortedRows.filter((child) => untouchedRowsMap[child.id]);
    const mapNodeToSortedNode = (rowNode2, pos) => ({
      currentPos: pos,
      rowNode: rowNode2
    });
    const sortedChangedRows = touchedRows.map(mapNodeToSortedNode).sort((a, b) => this.rowNodeSorter.compareRowNodes(sortOptions, a, b));
    return this.mergeSortedArrays(sortOptions, sortedChangedRows, sortedUntouchedRows.map(mapNodeToSortedNode)).map(
      ({ rowNode: rowNode2 }) => rowNode2
    );
  }
  // Merge two sorted arrays into each other
  mergeSortedArrays(sortOptions, arr1, arr2) {
    const res = [];
    let i = 0;
    let j = 0;
    while (i < arr1.length && j < arr2.length) {
      const compareResult = this.rowNodeSorter.compareRowNodes(sortOptions, arr1[i], arr2[j]);
      if (compareResult < 0) {
        res.push(arr1[i++]);
      } else {
        res.push(arr2[j++]);
      }
    }
    while (i < arr1.length) {
      res.push(arr1[i++]);
    }
    while (j < arr2.length) {
      res.push(arr2[j++]);
    }
    return res;
  }
  updateChildIndexes(rowNode) {
    if (_missing(rowNode.childrenAfterSort)) {
      return;
    }
    const listToSort = rowNode.childrenAfterSort;
    for (let i = 0; i < listToSort.length; i++) {
      const child = listToSort[i];
      const firstChild = i === 0;
      const lastChild = i === rowNode.childrenAfterSort.length - 1;
      child.setFirstChild(firstChild);
      child.setLastChild(lastChild);
      child.setChildIndex(i);
    }
  }
  updateGroupDataForHideOpenParents(changedPath) {
    if (!this.gos.get("groupHideOpenParents")) {
      return;
    }
    if (this.gos.get("treeData")) {
      _warnOnce(
        `The property hideOpenParents dose not work with Tree Data. This is because Tree Data has values at the group level, it doesn't make sense to hide them.`
      );
      return false;
    }
    const callback = (rowNode) => {
      this.pullDownGroupDataForHideOpenParents(rowNode.childrenAfterSort, false);
      rowNode.childrenAfterSort.forEach((child) => {
        if (child.hasChildren()) {
          callback(child);
        }
      });
    };
    if (changedPath) {
      changedPath.executeFromRootNode((rowNode) => callback(rowNode));
    }
  }
  pullDownGroupDataForHideOpenParents(rowNodes, clearOperation) {
    if (!this.gos.get("groupHideOpenParents") || _missing(rowNodes)) {
      return;
    }
    rowNodes.forEach((childRowNode) => {
      const groupDisplayCols = this.showRowGroupColsService?.getShowRowGroupCols() ?? [];
      groupDisplayCols.forEach((groupDisplayCol) => {
        const showRowGroup = groupDisplayCol.getColDef().showRowGroup;
        if (typeof showRowGroup !== "string") {
          _errorOnce(
            "groupHideOpenParents only works when specifying specific columns for colDef.showRowGroup"
          );
          return;
        }
        const displayingGroupKey = showRowGroup;
        const rowGroupColumn = this.columnModel.getColDefCol(displayingGroupKey);
        const thisRowNodeMatches = rowGroupColumn === childRowNode.rowGroupColumn;
        if (thisRowNodeMatches) {
          return;
        }
        if (clearOperation) {
          childRowNode.setGroupValue(groupDisplayCol.getId(), void 0);
        } else {
          const parentToStealFrom = childRowNode.getFirstChildOfFirstChild(rowGroupColumn);
          if (parentToStealFrom) {
            childRowNode.setGroupValue(groupDisplayCol.getId(), parentToStealFrom.key);
          }
        }
      });
    });
  }
};

// community-modules/client-side-row-model/src/clientSideRowModel/sortStage.ts
var SortStage = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "sortStage";
  }
  wireBeans(beans) {
    this.sortService = beans.sortService;
    this.sortController = beans.sortController;
  }
  execute(params) {
    const sortOptions = this.sortController.getSortOptions();
    const sortActive = _exists(sortOptions) && sortOptions.length > 0;
    const deltaSort = sortActive && _exists(params.rowNodeTransactions) && // in time we can remove this check, so that delta sort is always
    // on if transactions are present. it's off for now so that we can
    // selectively turn it on and test it with some select users before
    // rolling out to everyone.
    this.gos.get("deltaSort");
    const sortContainsGroupColumns = sortOptions.some((opt) => {
      const isSortingCoupled = this.gos.isColumnsSortingCoupledToGroup();
      if (isSortingCoupled) {
        return opt.column.isPrimary() && opt.column.isRowGroupActive();
      }
      return !!opt.column.getColDef().showRowGroup;
    });
    this.sortService.sort(
      sortOptions,
      sortActive,
      deltaSort,
      params.rowNodeTransactions,
      params.changedPath,
      sortContainsGroupColumns
    );
  }
};

// community-modules/client-side-row-model/src/version.ts
var VERSION2 = "32.0.0";

// community-modules/client-side-row-model/src/clientSideRowModelModule.ts
var ClientSideRowModelCoreModule = {
  version: VERSION2,
  moduleName: `${"@ag-grid-community/client-side-row-model" /* ClientSideRowModelModule */}-core`,
  rowModel: "clientSide",
  beans: [ClientSideRowModel, FilterStage, SortStage, FlattenStage, SortService, ImmutableService]
};
var ClientSideRowModelApiModule = {
  version: VERSION2,
  moduleName: `${"@ag-grid-community/client-side-row-model" /* ClientSideRowModelModule */}-api`,
  beans: [RowModelHelperService],
  apiFunctions: {
    onGroupExpandedOrCollapsed,
    refreshClientSideRowModel,
    forEachLeafNode,
    forEachNodeAfterFilter,
    forEachNodeAfterFilterAndSort,
    resetRowHeights,
    applyTransaction,
    applyTransactionAsync,
    flushAsyncTransactions,
    getBestCostNodeSelection
  },
  dependantModules: [ClientSideRowModelCoreModule, CsrmSsrmSharedApiModule]
};
var ClientSideRowModelModule = {
  version: VERSION2,
  moduleName: "@ag-grid-community/client-side-row-model" /* ClientSideRowModelModule */,
  dependantModules: [ClientSideRowModelCoreModule, ClientSideRowModelApiModule]
};

// community-modules/csv-export/dist/package/main.esm.mjs
var BaseCreator = class extends BeanStub {
  setBeans(beans) {
    this.beans = beans;
  }
  getFileName(fileName) {
    const extension = this.getDefaultFileExtension();
    if (fileName == null || !fileName.length) {
      fileName = this.getDefaultFileName();
    }
    return fileName.indexOf(".") === -1 ? `${fileName}.${extension}` : fileName;
  }
  getData(params) {
    const serializingSession = this.createSerializingSession(params);
    return this.beans.gridSerializer.serialize(serializingSession, params);
  }
  getDefaultFileName() {
    return `export.${this.getDefaultFileExtension()}`;
  }
};
var BaseGridSerializingSession = class {
  constructor(config) {
    this.groupColumns = [];
    const {
      columnModel,
      funcColsService,
      columnNameService,
      valueService,
      gos,
      processCellCallback,
      processHeaderCallback,
      processGroupHeaderCallback,
      processRowGroupCallback
    } = config;
    this.columnModel = columnModel;
    this.funcColsService = funcColsService;
    this.columnNameService = columnNameService;
    this.valueService = valueService;
    this.gos = gos;
    this.processCellCallback = processCellCallback;
    this.processHeaderCallback = processHeaderCallback;
    this.processGroupHeaderCallback = processGroupHeaderCallback;
    this.processRowGroupCallback = processRowGroupCallback;
  }
  prepare(columnsToExport) {
    this.groupColumns = columnsToExport.filter((col) => !!col.getColDef().showRowGroup);
  }
  extractHeaderValue(column) {
    const value = this.getHeaderName(this.processHeaderCallback, column);
    return value ?? "";
  }
  extractRowCellValue(column, index, accumulatedRowIndex, type, node) {
    const hideOpenParents = this.gos.get("groupHideOpenParents");
    const value = (!hideOpenParents || node.footer) && this.shouldRenderGroupSummaryCell(node, column, index) ? this.createValueForGroupNode(column, node) : this.valueService.getValue(column, node);
    const processedValue = this.processCell({
      accumulatedRowIndex,
      rowNode: node,
      column,
      value,
      processCellCallback: this.processCellCallback,
      type
    });
    return processedValue;
  }
  shouldRenderGroupSummaryCell(node, column, currentColumnIndex) {
    const isGroupNode = node && node.group;
    if (!isGroupNode) {
      return false;
    }
    const currentColumnGroupIndex = this.groupColumns.indexOf(column);
    if (currentColumnGroupIndex !== -1) {
      if (node.groupData?.[column.getId()] != null) {
        return true;
      }
      if (this.gos.isRowModelType("serverSide") && node.group) {
        return true;
      }
      if (node.footer && node.level === -1) {
        const colDef = column.getColDef();
        const isFullWidth = colDef == null || colDef.showRowGroup === true;
        return isFullWidth || colDef.showRowGroup === this.funcColsService.getRowGroupColumns()[0].getId();
      }
    }
    const isGroupUseEntireRow = this.gos.isGroupUseEntireRow(this.columnModel.isPivotMode());
    return currentColumnIndex === 0 && isGroupUseEntireRow;
  }
  getHeaderName(callback, column) {
    if (callback) {
      return callback(this.gos.addGridCommonParams({ column }));
    }
    return this.columnNameService.getDisplayNameForColumn(column, "csv", true);
  }
  createValueForGroupNode(column, node) {
    if (this.processRowGroupCallback) {
      return this.processRowGroupCallback(this.gos.addGridCommonParams({ column, node }));
    }
    const isTreeData = this.gos.get("treeData");
    const isSuppressGroupMaintainValueType = this.gos.get("suppressGroupMaintainValueType");
    const getValueFromNode = (node2) => {
      if (isTreeData || isSuppressGroupMaintainValueType) {
        return node2.key;
      }
      const value = node2.groupData?.[column.getId()];
      if (!value || !node2.rowGroupColumn || node2.rowGroupColumn.getColDef().useValueFormatterForExport === false) {
        return value;
      }
      return this.valueService.formatValue(node2.rowGroupColumn, node2, value) ?? value;
    };
    const isFooter = node.footer;
    const keys = [getValueFromNode(node)];
    if (!this.gos.isGroupMultiAutoColumn()) {
      while (node.parent) {
        node = node.parent;
        keys.push(getValueFromNode(node));
      }
    }
    const groupValue = keys.reverse().join(" -> ");
    return isFooter ? `Total ${groupValue}` : groupValue;
  }
  processCell(params) {
    const { accumulatedRowIndex, rowNode, column, value, processCellCallback, type } = params;
    if (processCellCallback) {
      return {
        value: processCellCallback(
          this.gos.addGridCommonParams({
            accumulatedRowIndex,
            column,
            node: rowNode,
            value,
            type,
            parseValue: (valueToParse) => this.valueService.parseValue(
              column,
              rowNode,
              valueToParse,
              this.valueService.getValue(column, rowNode)
            ),
            formatValue: (valueToFormat) => this.valueService.formatValue(column, rowNode, valueToFormat) ?? valueToFormat
          })
        ) ?? ""
      };
    }
    if (column.getColDef().useValueFormatterForExport !== false) {
      return {
        value: value ?? "",
        valueFormatted: this.valueService.formatValue(column, rowNode, value)
      };
    }
    return { value: value ?? "" };
  }
};
var Downloader = class {
  static download(fileName, content) {
    const win = document.defaultView || window;
    if (!win) {
      _warnOnce("There is no `window` associated with the current `document`");
      return;
    }
    const element = document.createElement("a");
    const url = win.URL.createObjectURL(content);
    element.setAttribute("href", url);
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.dispatchEvent(
      new MouseEvent("click", {
        bubbles: false,
        cancelable: true,
        view: win
      })
    );
    document.body.removeChild(element);
    win.setTimeout(() => {
      win.URL.revokeObjectURL(url);
    }, 0);
  }
};
var LINE_SEPARATOR = "\r\n";
var CsvSerializingSession = class extends BaseGridSerializingSession {
  constructor(config) {
    super(config);
    this.isFirstLine = true;
    this.result = "";
    const { suppressQuotes, columnSeparator } = config;
    this.suppressQuotes = suppressQuotes;
    this.columnSeparator = columnSeparator;
  }
  addCustomContent(content) {
    if (!content) {
      return;
    }
    if (typeof content === "string") {
      if (!/^\s*\n/.test(content)) {
        this.beginNewLine();
      }
      content = content.replace(/\r?\n/g, LINE_SEPARATOR);
      this.result += content;
    } else {
      content.forEach((row) => {
        this.beginNewLine();
        row.forEach((cell, index) => {
          if (index !== 0) {
            this.result += this.columnSeparator;
          }
          this.result += this.putInQuotes(cell.data.value || "");
          if (cell.mergeAcross) {
            this.appendEmptyCells(cell.mergeAcross);
          }
        });
      });
    }
  }
  onNewHeaderGroupingRow() {
    this.beginNewLine();
    return {
      onColumn: this.onNewHeaderGroupingRowColumn.bind(this)
    };
  }
  onNewHeaderGroupingRowColumn(columnGroup, header, index, span) {
    if (index != 0) {
      this.result += this.columnSeparator;
    }
    this.result += this.putInQuotes(header);
    this.appendEmptyCells(span);
  }
  appendEmptyCells(count) {
    for (let i = 1; i <= count; i++) {
      this.result += this.columnSeparator + this.putInQuotes("");
    }
  }
  onNewHeaderRow() {
    this.beginNewLine();
    return {
      onColumn: this.onNewHeaderRowColumn.bind(this)
    };
  }
  onNewHeaderRowColumn(column, index) {
    if (index != 0) {
      this.result += this.columnSeparator;
    }
    this.result += this.putInQuotes(this.extractHeaderValue(column));
  }
  onNewBodyRow() {
    this.beginNewLine();
    return {
      onColumn: this.onNewBodyRowColumn.bind(this)
    };
  }
  onNewBodyRowColumn(column, index, node) {
    if (index != 0) {
      this.result += this.columnSeparator;
    }
    const rowCellValue = this.extractRowCellValue(column, index, index, "csv", node);
    this.result += this.putInQuotes(rowCellValue.valueFormatted ?? rowCellValue.value);
  }
  putInQuotes(value) {
    if (this.suppressQuotes) {
      return value;
    }
    if (value === null || value === void 0) {
      return '""';
    }
    let stringValue;
    if (typeof value === "string") {
      stringValue = value;
    } else if (typeof value.toString === "function") {
      stringValue = value.toString();
    } else {
      _warnOnce("unknown value type during csv conversion");
      stringValue = "";
    }
    const valueEscaped = stringValue.replace(/"/g, '""');
    return '"' + valueEscaped + '"';
  }
  parse() {
    return this.result;
  }
  beginNewLine() {
    if (!this.isFirstLine) {
      this.result += LINE_SEPARATOR;
    }
    this.isFirstLine = false;
  }
};
var CsvCreator = class extends BaseCreator {
  constructor() {
    super(...arguments);
    this.beanName = "csvCreator";
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.columnNameService = beans.columnNameService;
    this.funcColsService = beans.funcColsService;
    this.valueService = beans.valueService;
    this.gridSerializer = beans.gridSerializer;
  }
  postConstruct() {
    this.setBeans({
      gridSerializer: this.gridSerializer,
      gos: this.gos
    });
  }
  getMergedParams(params) {
    const baseParams = this.gos.get("defaultCsvExportParams");
    return Object.assign({}, baseParams, params);
  }
  export(userParams) {
    if (this.isExportSuppressed()) {
      _warnOnce(`Export cancelled. Export is not allowed as per your configuration.`);
      return;
    }
    const mergedParams = this.getMergedParams(userParams);
    const data = this.getData(mergedParams);
    const packagedFile = new Blob(["\uFEFF", data], { type: "text/plain" });
    const fileName = typeof mergedParams.fileName === "function" ? mergedParams.fileName(this.gos.getGridCommonParams()) : mergedParams.fileName;
    Downloader.download(this.getFileName(fileName), packagedFile);
  }
  exportDataAsCsv(params) {
    this.export(params);
  }
  getDataAsCsv(params, skipDefaultParams = false) {
    const mergedParams = skipDefaultParams ? Object.assign({}, params) : this.getMergedParams(params);
    return this.getData(mergedParams);
  }
  getDefaultFileExtension() {
    return "csv";
  }
  createSerializingSession(params) {
    const { columnModel, columnNameService, funcColsService, valueService, gos } = this;
    const {
      processCellCallback,
      processHeaderCallback,
      processGroupHeaderCallback,
      processRowGroupCallback,
      suppressQuotes,
      columnSeparator
    } = params;
    return new CsvSerializingSession({
      columnModel,
      columnNameService,
      funcColsService,
      valueService,
      gos,
      processCellCallback: processCellCallback || void 0,
      processHeaderCallback: processHeaderCallback || void 0,
      processGroupHeaderCallback: processGroupHeaderCallback || void 0,
      processRowGroupCallback: processRowGroupCallback || void 0,
      suppressQuotes: suppressQuotes || false,
      columnSeparator: columnSeparator || ","
    });
  }
  isExportSuppressed() {
    return this.gos.get("suppressCsvExport");
  }
};
function getDataAsCsv(beans, params) {
  return beans.csvCreator?.getDataAsCsv(params);
}
function exportDataAsCsv(beans, params) {
  beans.csvCreator?.exportDataAsCsv(params);
}
var RowType = /* @__PURE__ */ ((RowType2) => {
  RowType2[RowType2["HEADER_GROUPING"] = 0] = "HEADER_GROUPING";
  RowType2[RowType2["HEADER"] = 1] = "HEADER";
  RowType2[RowType2["BODY"] = 2] = "BODY";
  return RowType2;
})(RowType || {});
var GridSerializer = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "gridSerializer";
  }
  wireBeans(beans) {
    this.visibleColsService = beans.visibleColsService;
    this.columnModel = beans.columnModel;
    this.columnNameService = beans.columnNameService;
    this.rowModel = beans.rowModel;
    this.pinnedRowModel = beans.pinnedRowModel;
    this.selectionService = beans.selectionService;
    this.rowNodeSorter = beans.rowNodeSorter;
    this.sortController = beans.sortController;
  }
  serialize(gridSerializingSession, params = {}) {
    const { allColumns, columnKeys, skipRowGroups } = params;
    const columnsToExport = this.getColumnsToExport(
      allColumns,
      skipRowGroups,
      columnKeys
    );
    const serializeChain = _compose(
      // first pass, put in the header names of the cols
      this.prepareSession(columnsToExport),
      this.prependContent(params),
      this.exportColumnGroups(params, columnsToExport),
      this.exportHeaders(params, columnsToExport),
      this.processPinnedTopRows(params, columnsToExport),
      this.processRows(params, columnsToExport),
      this.processPinnedBottomRows(params, columnsToExport),
      this.appendContent(params)
    );
    return serializeChain(gridSerializingSession).parse();
  }
  processRow(gridSerializingSession, params, columnsToExport, node) {
    const rowSkipper = params.shouldRowBeSkipped || (() => false);
    const skipSingleChildrenGroup = this.gos.get("groupRemoveSingleChildren");
    const skipLowestSingleChildrenGroup = this.gos.get("groupRemoveLowestSingleChildren");
    const isClipboardExport = params.rowPositions != null;
    const isExplicitExportSelection = isClipboardExport || !!params.onlySelected;
    const hideOpenParents = this.gos.get("groupHideOpenParents") && !isExplicitExportSelection;
    const isLeafNode = this.columnModel.isPivotMode() ? node.leafGroup : !node.group;
    const isFooter = !!node.footer;
    const shouldSkipLowestGroup = skipLowestSingleChildrenGroup && node.leafGroup;
    const shouldSkipCurrentGroup = node.allChildrenCount === 1 && node.childrenAfterGroup?.length === 1 && (skipSingleChildrenGroup || shouldSkipLowestGroup);
    if (!isLeafNode && !isFooter && (params.skipRowGroups || shouldSkipCurrentGroup || hideOpenParents) || params.onlySelected && !node.isSelected() || params.skipPinnedTop && node.rowPinned === "top" || params.skipPinnedBottom && node.rowPinned === "bottom") {
      return;
    }
    const nodeIsRootNode = node.level === -1;
    if (nodeIsRootNode && !isLeafNode && !isFooter) {
      return;
    }
    const shouldRowBeSkipped = rowSkipper(this.gos.addGridCommonParams({ node }));
    if (shouldRowBeSkipped) {
      return;
    }
    const rowAccumulator = gridSerializingSession.onNewBodyRow(node);
    columnsToExport.forEach((column, index) => {
      rowAccumulator.onColumn(column, index, node);
    });
    if (params.getCustomContentBelowRow) {
      const content = params.getCustomContentBelowRow(this.gos.addGridCommonParams({ node }));
      if (content) {
        gridSerializingSession.addCustomContent(content);
      }
    }
  }
  appendContent(params) {
    return (gridSerializingSession) => {
      const appendContent = params.appendContent;
      if (appendContent) {
        gridSerializingSession.addCustomContent(appendContent);
      }
      return gridSerializingSession;
    };
  }
  prependContent(params) {
    return (gridSerializingSession) => {
      const prependContent = params.prependContent;
      if (prependContent) {
        gridSerializingSession.addCustomContent(prependContent);
      }
      return gridSerializingSession;
    };
  }
  prepareSession(columnsToExport) {
    return (gridSerializingSession) => {
      gridSerializingSession.prepare(columnsToExport);
      return gridSerializingSession;
    };
  }
  exportColumnGroups(params, columnsToExport) {
    return (gridSerializingSession) => {
      if (!params.skipColumnGroupHeaders) {
        const idCreator = new GroupInstanceIdCreator();
        const displayedGroups = this.visibleColsService.createGroups({
          columns: columnsToExport,
          idCreator,
          pinned: null,
          isStandaloneStructure: true
        });
        this.recursivelyAddHeaderGroups(
          displayedGroups,
          gridSerializingSession,
          params.processGroupHeaderCallback
        );
      }
      return gridSerializingSession;
    };
  }
  exportHeaders(params, columnsToExport) {
    return (gridSerializingSession) => {
      if (!params.skipColumnHeaders) {
        const gridRowIterator = gridSerializingSession.onNewHeaderRow();
        columnsToExport.forEach((column, index) => {
          gridRowIterator.onColumn(column, index, void 0);
        });
      }
      return gridSerializingSession;
    };
  }
  processPinnedTopRows(params, columnsToExport) {
    return (gridSerializingSession) => {
      const processRow = this.processRow.bind(this, gridSerializingSession, params, columnsToExport);
      if (params.rowPositions) {
        params.rowPositions.filter((position) => position.rowPinned === "top").sort((a, b) => a.rowIndex - b.rowIndex).map((position) => this.pinnedRowModel.getPinnedTopRow(position.rowIndex)).forEach(processRow);
      } else {
        this.pinnedRowModel.forEachPinnedTopRow(processRow);
      }
      return gridSerializingSession;
    };
  }
  processRows(params, columnsToExport) {
    return (gridSerializingSession) => {
      const rowModel = this.rowModel;
      const rowModelType = rowModel.getType();
      const usingCsrm = rowModelType === "clientSide";
      const usingSsrm = rowModelType === "serverSide";
      const onlySelectedNonStandardModel = !usingCsrm && params.onlySelected;
      const processRow = this.processRow.bind(this, gridSerializingSession, params, columnsToExport);
      const { exportedRows = "filteredAndSorted" } = params;
      if (params.rowPositions) {
        params.rowPositions.filter((position) => position.rowPinned == null).sort((a, b) => a.rowIndex - b.rowIndex).map((position) => rowModel.getRow(position.rowIndex)).forEach(processRow);
      } else if (this.columnModel.isPivotMode()) {
        if (usingCsrm) {
          rowModel.forEachPivotNode(processRow, true);
        } else if (usingSsrm) {
          rowModel.forEachNodeAfterFilterAndSort(processRow, true);
        } else {
          rowModel.forEachNode(processRow);
        }
      } else {
        if (params.onlySelectedAllPages || onlySelectedNonStandardModel) {
          const selectedNodes = this.selectionService.getSelectedNodes();
          this.replicateSortedOrder(selectedNodes);
          selectedNodes.forEach(processRow);
        } else {
          if (exportedRows === "all") {
            rowModel.forEachNode(processRow);
          } else if (usingCsrm) {
            rowModel.forEachNodeAfterFilterAndSort(processRow, true);
          } else if (usingSsrm) {
            rowModel.forEachNodeAfterFilterAndSort(processRow, true);
          } else {
            rowModel.forEachNode(processRow);
          }
        }
      }
      return gridSerializingSession;
    };
  }
  replicateSortedOrder(rows) {
    const sortOptions = this.sortController.getSortOptions();
    const compareNodes = (rowA, rowB) => {
      if (rowA.rowIndex != null && rowB.rowIndex != null) {
        return rowA.rowIndex - rowB.rowIndex;
      }
      if (rowA.level === rowB.level) {
        if (rowA.parent?.id === rowB.parent?.id) {
          return this.rowNodeSorter.compareRowNodes(
            sortOptions,
            {
              rowNode: rowA,
              currentPos: rowA.rowIndex ?? -1
            },
            {
              rowNode: rowB,
              currentPos: rowB.rowIndex ?? -1
            }
          );
        }
        return compareNodes(rowA.parent, rowB.parent);
      }
      if (rowA.level > rowB.level) {
        return compareNodes(rowA.parent, rowB);
      }
      return compareNodes(rowA, rowB.parent);
    };
    rows.sort(compareNodes);
  }
  processPinnedBottomRows(params, columnsToExport) {
    return (gridSerializingSession) => {
      const processRow = this.processRow.bind(this, gridSerializingSession, params, columnsToExport);
      if (params.rowPositions) {
        params.rowPositions.filter((position) => position.rowPinned === "bottom").sort((a, b) => a.rowIndex - b.rowIndex).map((position) => this.pinnedRowModel.getPinnedBottomRow(position.rowIndex)).forEach(processRow);
      } else {
        this.pinnedRowModel.forEachPinnedBottomRow(processRow);
      }
      return gridSerializingSession;
    };
  }
  getColumnsToExport(allColumns = false, skipRowGroups = false, columnKeys) {
    const isPivotMode = this.columnModel.isPivotMode();
    if (columnKeys && columnKeys.length) {
      return this.columnModel.getColsForKeys(columnKeys);
    }
    const isTreeData = this.gos.get("treeData");
    let columnsToExport = [];
    if (allColumns && !isPivotMode) {
      columnsToExport = this.columnModel.getCols();
    } else {
      columnsToExport = this.visibleColsService.getAllCols();
    }
    if (skipRowGroups && !isTreeData) {
      columnsToExport = columnsToExport.filter((column) => column.getColId() !== GROUP_AUTO_COLUMN_ID);
    }
    return columnsToExport;
  }
  recursivelyAddHeaderGroups(displayedGroups, gridSerializingSession, processGroupHeaderCallback) {
    const directChildrenHeaderGroups = [];
    displayedGroups.forEach((columnGroupChild) => {
      const columnGroup = columnGroupChild;
      if (!columnGroup.getChildren) {
        return;
      }
      columnGroup.getChildren().forEach((it) => directChildrenHeaderGroups.push(it));
    });
    if (displayedGroups.length > 0 && isColumnGroup(displayedGroups[0])) {
      this.doAddHeaderHeader(gridSerializingSession, displayedGroups, processGroupHeaderCallback);
    }
    if (directChildrenHeaderGroups && directChildrenHeaderGroups.length > 0) {
      this.recursivelyAddHeaderGroups(
        directChildrenHeaderGroups,
        gridSerializingSession,
        processGroupHeaderCallback
      );
    }
  }
  doAddHeaderHeader(gridSerializingSession, displayedGroups, processGroupHeaderCallback) {
    const gridRowIterator = gridSerializingSession.onNewHeaderGroupingRow();
    let columnIndex = 0;
    displayedGroups.forEach((columnGroupChild) => {
      const columnGroup = columnGroupChild;
      let name;
      if (processGroupHeaderCallback) {
        name = processGroupHeaderCallback(
          this.gos.addGridCommonParams({
            columnGroup
          })
        );
      } else {
        name = this.columnNameService.getDisplayNameForColumnGroup(columnGroup, "header");
      }
      const collapsibleGroupRanges = columnGroup.getLeafColumns().reduce((collapsibleGroups, currentColumn, currentIdx, arr) => {
        let lastGroup = _last(collapsibleGroups);
        const groupShow = currentColumn.getColumnGroupShow() === "open";
        if (!groupShow) {
          if (lastGroup && lastGroup[1] == null) {
            lastGroup[1] = currentIdx - 1;
          }
        } else if (!lastGroup || lastGroup[1] != null) {
          lastGroup = [currentIdx];
          collapsibleGroups.push(lastGroup);
        }
        if (currentIdx === arr.length - 1 && lastGroup && lastGroup[1] == null) {
          lastGroup[1] = currentIdx;
        }
        return collapsibleGroups;
      }, []);
      gridRowIterator.onColumn(
        columnGroup,
        name || "",
        columnIndex++,
        columnGroup.getLeafColumns().length - 1,
        collapsibleGroupRanges
      );
    });
  }
};
var VERSION3 = "32.0.0";
var CsvExportCoreModule = {
  version: VERSION3,
  moduleName: `${"@ag-grid-community/csv-export" /* CsvExportModule */}-core`,
  beans: [CsvCreator, GridSerializer]
};
var CsvExportApiModule = {
  version: VERSION3,
  moduleName: `${"@ag-grid-community/csv-export" /* CsvExportModule */}-api`,
  apiFunctions: {
    getDataAsCsv,
    exportDataAsCsv
  },
  dependantModules: [CsvExportCoreModule]
};
var CsvExportModule = {
  version: VERSION3,
  moduleName: "@ag-grid-community/csv-export" /* CsvExportModule */,
  dependantModules: [CsvExportCoreModule, CsvExportApiModule]
};
var LINE_SEPARATOR2 = "\r\n";
function returnAttributeIfPopulated(key, value, booleanTransformer) {
  if (!value && value !== "" && value !== 0) {
    return "";
  }
  let xmlValue = value;
  if (typeof value === "boolean") {
    if (booleanTransformer) {
      xmlValue = booleanTransformer(value);
    }
  }
  return ` ${key}="${xmlValue}"`;
}
var XmlFactory = class {
  static createHeader(headerElement = {}) {
    const headerStart = "<?";
    const headerEnd = "?>";
    const keys = ["version"];
    if (!headerElement.version) {
      headerElement.version = "1.0";
    }
    if (headerElement.encoding) {
      keys.push("encoding");
    }
    if (headerElement.standalone) {
      keys.push("standalone");
    }
    const att = keys.map((key) => `${key}="${headerElement[key]}"`).join(" ");
    return `${headerStart}xml ${att} ${headerEnd}`;
  }
  static createXml(xmlElement, booleanTransformer) {
    let props = "";
    if (xmlElement.properties) {
      if (xmlElement.properties.prefixedAttributes) {
        xmlElement.properties.prefixedAttributes.forEach((prefixedSet) => {
          Object.keys(prefixedSet.map).forEach((key) => {
            props += returnAttributeIfPopulated(
              prefixedSet.prefix + key,
              prefixedSet.map[key],
              booleanTransformer
            );
          });
        });
      }
      if (xmlElement.properties.rawMap) {
        Object.keys(xmlElement.properties.rawMap).forEach((key) => {
          props += returnAttributeIfPopulated(key, xmlElement.properties.rawMap[key], booleanTransformer);
        });
      }
    }
    let result = "<" + xmlElement.name + props;
    if (!xmlElement.children && xmlElement.textNode == null) {
      return result + "/>" + LINE_SEPARATOR2;
    }
    if (xmlElement.textNode != null) {
      return result + ">" + xmlElement.textNode + "</" + xmlElement.name + ">" + LINE_SEPARATOR2;
    }
    result += ">" + LINE_SEPARATOR2;
    if (xmlElement.children) {
      xmlElement.children.forEach((it) => {
        result += this.createXml(it, booleanTransformer);
      });
    }
    return result + "</" + xmlElement.name + ">" + LINE_SEPARATOR2;
  }
};
var compressBlob = async (data) => {
  let chunksSize = 0;
  const chunks = [];
  const writeCompressedData = new WritableStream({
    write: (chunk) => {
      chunks.push(chunk);
      chunksSize += chunk.length;
    }
  });
  const readable = new ReadableStream({
    start: (controller) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          controller.enqueue(e.target.result);
        }
        controller.close();
      };
      reader.readAsArrayBuffer(data);
    }
  });
  const compressStream = new window.CompressionStream("deflate-raw");
  await readable.pipeThrough(compressStream).pipeTo(writeCompressedData);
  return {
    size: chunksSize,
    content: new Blob(chunks)
  };
};
var deflateLocalFile = async (rawContent) => {
  const contentAsBlob = new Blob([rawContent]);
  const { size: compressedSize, content: compressedContent } = await compressBlob(contentAsBlob);
  const compressedContentAsUint8Array = new Uint8Array(await compressedContent.arrayBuffer());
  return {
    size: compressedSize,
    content: compressedContentAsUint8Array
  };
};
var convertTime = (date) => {
  let time = date.getHours();
  time <<= 6;
  time = time | date.getMinutes();
  time <<= 5;
  time = time | date.getSeconds() / 2;
  return time;
};
var convertDate = (date) => {
  let dt = date.getFullYear() - 1980;
  dt <<= 4;
  dt = dt | date.getMonth() + 1;
  dt <<= 5;
  dt = dt | date.getDate();
  return dt;
};
function convertDecToHex(number, bytes) {
  let hex = "";
  for (let i = 0; i < bytes; i++) {
    hex += String.fromCharCode(number & 255);
    number >>>= 8;
  }
  return hex;
}
var getCrcFromCrc32TableAndByteArray = (content) => {
  if (!content.length) {
    return 0;
  }
  let crc = 0 ^ -1;
  let j = 0;
  let k = 0;
  let l = 0;
  for (let i = 0; i < content.length; i++) {
    j = content[i];
    k = (crc ^ j) & 255;
    l = crcTable[k];
    crc = crc >>> 8 ^ l;
  }
  return crc ^ -1;
};
var getCrcFromCrc32Table = (content) => {
  if (!content.length) {
    return 0;
  }
  if (typeof content === "string") {
    return getCrcFromCrc32TableAndByteArray(new TextEncoder().encode(content));
  }
  return getCrcFromCrc32TableAndByteArray(content);
};
var crcTable = new Uint32Array([
  0,
  1996959894,
  3993919788,
  2567524794,
  124634137,
  1886057615,
  3915621685,
  2657392035,
  249268274,
  2044508324,
  3772115230,
  2547177864,
  162941995,
  2125561021,
  3887607047,
  2428444049,
  498536548,
  1789927666,
  4089016648,
  2227061214,
  450548861,
  1843258603,
  4107580753,
  2211677639,
  325883990,
  1684777152,
  4251122042,
  2321926636,
  335633487,
  1661365465,
  4195302755,
  2366115317,
  997073096,
  1281953886,
  3579855332,
  2724688242,
  1006888145,
  1258607687,
  3524101629,
  2768942443,
  901097722,
  1119000684,
  3686517206,
  2898065728,
  853044451,
  1172266101,
  3705015759,
  2882616665,
  651767980,
  1373503546,
  3369554304,
  3218104598,
  565507253,
  1454621731,
  3485111705,
  3099436303,
  671266974,
  1594198024,
  3322730930,
  2970347812,
  795835527,
  1483230225,
  3244367275,
  3060149565,
  1994146192,
  31158534,
  2563907772,
  4023717930,
  1907459465,
  112637215,
  2680153253,
  3904427059,
  2013776290,
  251722036,
  2517215374,
  3775830040,
  2137656763,
  141376813,
  2439277719,
  3865271297,
  1802195444,
  476864866,
  2238001368,
  4066508878,
  1812370925,
  453092731,
  2181625025,
  4111451223,
  1706088902,
  314042704,
  2344532202,
  4240017532,
  1658658271,
  366619977,
  2362670323,
  4224994405,
  1303535960,
  984961486,
  2747007092,
  3569037538,
  1256170817,
  1037604311,
  2765210733,
  3554079995,
  1131014506,
  879679996,
  2909243462,
  3663771856,
  1141124467,
  855842277,
  2852801631,
  3708648649,
  1342533948,
  654459306,
  3188396048,
  3373015174,
  1466479909,
  544179635,
  3110523913,
  3462522015,
  1591671054,
  702138776,
  2966460450,
  3352799412,
  1504918807,
  783551873,
  3082640443,
  3233442989,
  3988292384,
  2596254646,
  62317068,
  1957810842,
  3939845945,
  2647816111,
  81470997,
  1943803523,
  3814918930,
  2489596804,
  225274430,
  2053790376,
  3826175755,
  2466906013,
  167816743,
  2097651377,
  4027552580,
  2265490386,
  503444072,
  1762050814,
  4150417245,
  2154129355,
  426522225,
  1852507879,
  4275313526,
  2312317920,
  282753626,
  1742555852,
  4189708143,
  2394877945,
  397917763,
  1622183637,
  3604390888,
  2714866558,
  953729732,
  1340076626,
  3518719985,
  2797360999,
  1068828381,
  1219638859,
  3624741850,
  2936675148,
  906185462,
  1090812512,
  3747672003,
  2825379669,
  829329135,
  1181335161,
  3412177804,
  3160834842,
  628085408,
  1382605366,
  3423369109,
  3138078467,
  570562233,
  1426400815,
  3317316542,
  2998733608,
  733239954,
  1555261956,
  3268935591,
  3050360625,
  752459403,
  1541320221,
  2607071920,
  3965973030,
  1969922972,
  40735498,
  2617837225,
  3943577151,
  1913087877,
  83908371,
  2512341634,
  3803740692,
  2075208622,
  213261112,
  2463272603,
  3855990285,
  2094854071,
  198958881,
  2262029012,
  4057260610,
  1759359992,
  534414190,
  2176718541,
  4139329115,
  1873836001,
  414664567,
  2282248934,
  4279200368,
  1711684554,
  285281116,
  2405801727,
  4167216745,
  1634467795,
  376229701,
  2685067896,
  3608007406,
  1308918612,
  956543938,
  2808555105,
  3495958263,
  1231636301,
  1047427035,
  2932959818,
  3654703836,
  1088359270,
  936918e3,
  2847714899,
  3736837829,
  1202900863,
  817233897,
  3183342108,
  3401237130,
  1404277552,
  615818150,
  3134207493,
  3453421203,
  1423857449,
  601450431,
  3009837614,
  3294710456,
  1567103746,
  711928724,
  3020668471,
  3272380065,
  1510334235,
  755167117
]);
var getHeaders = (currentFile, isCompressed, offset, rawSize, rawContent, deflatedSize) => {
  const { content, path, created: creationDate } = currentFile;
  const time = convertTime(creationDate);
  const dt = convertDate(creationDate);
  const crcFlag = getCrcFromCrc32Table(rawContent);
  const zipSize = deflatedSize !== void 0 ? deflatedSize : rawSize;
  const utfPath = _utf8_encode(path);
  const isUTF8 = utfPath !== path;
  let extraFields = "";
  if (isUTF8) {
    const uExtraFieldPath = convertDecToHex(1, 1) + convertDecToHex(getCrcFromCrc32Table(utfPath), 4) + utfPath;
    extraFields = "up" + convertDecToHex(uExtraFieldPath.length, 2) + uExtraFieldPath;
  }
  const commonHeader = "\0" + // version needed to extract
  (isUTF8 ? "\0\b" : "\0\0") + // Language encoding flag (EFS) (12th bit turned on)
  convertDecToHex(isCompressed ? 8 : 0, 2) + // As per ECMA-376 Part 2 specs
  convertDecToHex(time, 2) + // last modified time
  convertDecToHex(dt, 2) + // last modified date
  convertDecToHex(zipSize ? crcFlag : 0, 4) + convertDecToHex(deflatedSize ?? rawSize, 4) + // compressed size
  convertDecToHex(rawSize, 4) + // uncompressed size
  convertDecToHex(utfPath.length, 2) + // file name length
  convertDecToHex(extraFields.length, 2);
  const localFileHeader = "PK" + commonHeader + utfPath + extraFields;
  const centralDirectoryHeader = "PK\0" + commonHeader + // file header
  "\0\0\0\0\0\0" + (content ? "\0\0\0\0" : "\0\0\0") + // external file attributes
  convertDecToHex(offset, 4) + // relative offset of local header
  utfPath + // file name
  extraFields;
  return {
    localFileHeader: Uint8Array.from(localFileHeader, (c) => c.charCodeAt(0)),
    centralDirectoryHeader: Uint8Array.from(centralDirectoryHeader, (c) => c.charCodeAt(0))
  };
};
var getDecodedContent = (content) => {
  let contentToUse;
  if (typeof content === "string") {
    const base64String = atob(content.split(";base64,")[1]);
    contentToUse = Uint8Array.from(base64String, (c) => c.charCodeAt(0));
  } else {
    contentToUse = content;
  }
  return {
    size: contentToUse.length,
    content: contentToUse
  };
};
var getDeflatedHeaderAndContent = async (currentFile, offset) => {
  const { content } = currentFile;
  const { size, content: rawContent } = !content ? { size: 0, content: Uint8Array.from([]) } : getDecodedContent(content);
  let deflatedContent = void 0;
  let deflatedSize = void 0;
  let deflationPerformed = false;
  const shouldDeflate = currentFile.type === "file" && rawContent && size > 0;
  if (shouldDeflate) {
    const result = await deflateLocalFile(rawContent);
    deflatedContent = result.content;
    deflatedSize = result.size;
    deflationPerformed = true;
  }
  const headers = getHeaders(currentFile, deflationPerformed, offset, size, rawContent, deflatedSize);
  return {
    ...headers,
    content: deflatedContent || rawContent,
    isCompressed: deflationPerformed
  };
};
var getHeaderAndContent = (currentFile, offset) => {
  const { content } = currentFile;
  const { content: rawContent } = !content ? { content: Uint8Array.from([]) } : getDecodedContent(content);
  const headers = getHeaders(currentFile, false, offset, rawContent.length, rawContent, void 0);
  return {
    ...headers,
    content: rawContent,
    isCompressed: false
  };
};
var buildCentralDirectoryEnd = (tLen, cLen, lLen) => {
  const str = "PK\0\0\0\0" + convertDecToHex(tLen, 2) + // total number of entries in the central folder
  convertDecToHex(tLen, 2) + // total number of entries in the central folder
  convertDecToHex(cLen, 4) + // size of the central folder
  convertDecToHex(lLen, 4) + // central folder start offset
  "\0\0";
  return Uint8Array.from(str, (c) => c.charCodeAt(0));
};
var ZipContainer = class {
  static addFolders(paths) {
    paths.forEach(this.addFolder.bind(this));
  }
  static addFolder(path) {
    this.folders.push({
      path,
      created: /* @__PURE__ */ new Date(),
      isBase64: false,
      type: "folder"
    });
  }
  static addFile(path, content, isBase64 = false) {
    this.files.push({
      path,
      created: /* @__PURE__ */ new Date(),
      content: isBase64 ? content : new TextEncoder().encode(content),
      isBase64,
      type: "file"
    });
  }
  static async getZipFile(mimeType = "application/zip") {
    const textOutput = await this.buildCompressedFileStream();
    this.clearStream();
    return new Blob([textOutput], { type: mimeType });
  }
  static getUncompressedZipFile(mimeType = "application/zip") {
    const textOutput = this.buildFileStream();
    this.clearStream();
    return new Blob([textOutput], { type: mimeType });
  }
  static clearStream() {
    this.folders = [];
    this.files = [];
  }
  static packageFiles(files) {
    let fileLen = 0;
    let folderLen = 0;
    for (const currentFile of files) {
      const { localFileHeader, centralDirectoryHeader, content } = currentFile;
      fileLen += localFileHeader.length + content.length;
      folderLen += centralDirectoryHeader.length;
    }
    const fileData = new Uint8Array(fileLen);
    const folderData = new Uint8Array(folderLen);
    let fileOffset = 0;
    let folderOffset = 0;
    for (const currentFile of files) {
      const { localFileHeader, centralDirectoryHeader, content } = currentFile;
      fileData.set(localFileHeader, fileOffset);
      fileOffset += localFileHeader.length;
      fileData.set(content, fileOffset);
      fileOffset += content.length;
      folderData.set(centralDirectoryHeader, folderOffset);
      folderOffset += centralDirectoryHeader.length;
    }
    const folderEnd = buildCentralDirectoryEnd(files.length, folderLen, fileLen);
    const result = new Uint8Array(fileData.length + folderData.length + folderEnd.length);
    result.set(fileData);
    result.set(folderData, fileData.length);
    result.set(folderEnd, fileData.length + folderData.length);
    return result;
  }
  static async buildCompressedFileStream() {
    const totalFiles = [...this.folders, ...this.files];
    const readyFiles = [];
    let lL = 0;
    for (const currentFile of totalFiles) {
      const output = await getDeflatedHeaderAndContent(currentFile, lL);
      const { localFileHeader, content } = output;
      readyFiles.push(output);
      lL += localFileHeader.length + content.length;
    }
    return this.packageFiles(readyFiles);
  }
  static buildFileStream() {
    const totalFiles = [...this.folders, ...this.files];
    const readyFiles = [];
    let lL = 0;
    for (const currentFile of totalFiles) {
      const readyFile = getHeaderAndContent(currentFile, lL);
      const { localFileHeader, content } = readyFile;
      readyFiles.push(readyFile);
      lL += localFileHeader.length + content.length;
    }
    return this.packageFiles(readyFiles);
  }
};
ZipContainer.folders = [];
ZipContainer.files = [];

// community-modules/infinite-row-model/dist/package/main.esm.mjs
var InfiniteBlock = class extends RowNodeBlock {
  wireBeans(beans) {
    this.beans = beans;
  }
  constructor(id, parentCache, params) {
    super(id);
    this.parentCache = parentCache;
    this.params = params;
    this.startRow = id * params.blockSize;
    this.endRow = this.startRow + params.blockSize;
  }
  postConstruct() {
    this.createRowNodes();
  }
  getBlockStateJson() {
    return {
      id: "" + this.getId(),
      state: {
        blockNumber: this.getId(),
        startRow: this.getStartRow(),
        endRow: this.getEndRow(),
        pageStatus: this.getState()
      }
    };
  }
  setDataAndId(rowNode, data, index) {
    if (_exists(data)) {
      rowNode.setDataAndId(data, index.toString());
    } else {
      rowNode.setDataAndId(void 0, void 0);
    }
  }
  loadFromDatasource() {
    const params = this.createLoadParams();
    if (_missing(this.params.datasource.getRows)) {
      _warnOnce(`datasource is missing getRows method`);
      return;
    }
    window.setTimeout(() => {
      this.params.datasource.getRows(params);
    }, 0);
  }
  processServerFail() {
  }
  createLoadParams() {
    const params = {
      startRow: this.getStartRow(),
      endRow: this.getEndRow(),
      successCallback: this.pageLoaded.bind(this, this.getVersion()),
      failCallback: this.pageLoadFailed.bind(this, this.getVersion()),
      sortModel: this.params.sortModel,
      filterModel: this.params.filterModel,
      context: this.gos.getGridCommonParams().context
    };
    return params;
  }
  forEachNode(callback, sequence, rowCount) {
    this.rowNodes.forEach((rowNode, index) => {
      const rowIndex = this.startRow + index;
      if (rowIndex < rowCount) {
        callback(rowNode, sequence.next());
      }
    });
  }
  getLastAccessed() {
    return this.lastAccessed;
  }
  getRow(rowIndex, dontTouchLastAccessed = false) {
    if (!dontTouchLastAccessed) {
      this.lastAccessed = this.params.lastAccessedSequence.next();
    }
    const localIndex = rowIndex - this.startRow;
    return this.rowNodes[localIndex];
  }
  getStartRow() {
    return this.startRow;
  }
  getEndRow() {
    return this.endRow;
  }
  // creates empty row nodes, data is missing as not loaded yet
  createRowNodes() {
    this.rowNodes = [];
    for (let i = 0; i < this.params.blockSize; i++) {
      const rowIndex = this.startRow + i;
      const rowNode = new RowNode(this.beans);
      rowNode.setRowHeight(this.params.rowHeight);
      rowNode.uiLevel = 0;
      rowNode.setRowIndex(rowIndex);
      rowNode.setRowTop(this.params.rowHeight * rowIndex);
      this.rowNodes.push(rowNode);
    }
  }
  processServerResult(params) {
    this.rowNodes.forEach((rowNode, index) => {
      const data = params.rowData ? params.rowData[index] : void 0;
      if (!rowNode.id && rowNode.alreadyRendered && data) {
        this.rowNodes[index] = new RowNode(this.beans);
        this.rowNodes[index].setRowIndex(rowNode.rowIndex);
        this.rowNodes[index].setRowTop(rowNode.rowTop);
        this.rowNodes[index].setRowHeight(rowNode.rowHeight);
        rowNode.clearRowTopAndRowIndex();
      }
      this.setDataAndId(this.rowNodes[index], data, this.startRow + index);
    });
    const finalRowCount = params.rowCount != null && params.rowCount >= 0 ? params.rowCount : void 0;
    this.parentCache.pageLoaded(this, finalRowCount);
  }
  destroy() {
    this.rowNodes.forEach((rowNode) => {
      rowNode.clearRowTopAndRowIndex();
    });
    super.destroy();
  }
};
var MAX_EMPTY_BLOCKS_TO_KEEP = 2;
var InfiniteCache = class extends BeanStub {
  constructor(params) {
    super();
    this.lastRowIndexKnown = false;
    this.blocks = {};
    this.blockCount = 0;
    this.rowCount = params.initialRowCount;
    this.params = params;
  }
  wireBeans(beans) {
    this.rowRenderer = beans.rowRenderer;
    this.focusService = beans.focusService;
  }
  // the rowRenderer will not pass dontCreatePage, meaning when rendering the grid,
  // it will want new pages in the cache as it asks for rows. only when we are inserting /
  // removing rows via the api is dontCreatePage set, where we move rows between the pages.
  getRow(rowIndex, dontCreatePage = false) {
    const blockId = Math.floor(rowIndex / this.params.blockSize);
    let block = this.blocks[blockId];
    if (!block) {
      if (dontCreatePage) {
        return void 0;
      }
      block = this.createBlock(blockId);
    }
    return block.getRow(rowIndex);
  }
  createBlock(blockNumber) {
    const newBlock = this.createBean(new InfiniteBlock(blockNumber, this, this.params));
    this.blocks[newBlock.getId()] = newBlock;
    this.blockCount++;
    this.purgeBlocksIfNeeded(newBlock);
    this.params.rowNodeBlockLoader.addBlock(newBlock);
    return newBlock;
  }
  // we have this on infinite row model only, not server side row model,
  // because for server side, it would leave the children in inconsistent
  // state - eg if a node had children, but after the refresh it had data
  // for a different row, then the children would be with the wrong row node.
  refreshCache() {
    const nothingToRefresh = this.blockCount == 0;
    if (nothingToRefresh) {
      this.purgeCache();
      return;
    }
    this.getBlocksInOrder().forEach((block) => block.setStateWaitingToLoad());
    this.params.rowNodeBlockLoader.checkBlockToLoad();
  }
  destroy() {
    this.getBlocksInOrder().forEach((block) => this.destroyBlock(block));
    super.destroy();
  }
  getRowCount() {
    return this.rowCount;
  }
  isLastRowIndexKnown() {
    return this.lastRowIndexKnown;
  }
  // block calls this, when page loaded
  pageLoaded(block, lastRow) {
    if (!this.isAlive()) {
      return;
    }
    if (this.gos.get("debug")) {
      _log(`InfiniteCache - onPageLoaded: page = ${block.getId()}, lastRow = ${lastRow}`);
    }
    this.checkRowCount(block, lastRow);
    this.onCacheUpdated();
  }
  purgeBlocksIfNeeded(blockToExclude) {
    const blocksForPurging = this.getBlocksInOrder().filter((b) => b != blockToExclude);
    const lastAccessedComparator = (a, b) => b.getLastAccessed() - a.getLastAccessed();
    blocksForPurging.sort(lastAccessedComparator);
    const maxBlocksProvided = this.params.maxBlocksInCache > 0;
    const blocksToKeep = maxBlocksProvided ? this.params.maxBlocksInCache - 1 : null;
    const emptyBlocksToKeep = MAX_EMPTY_BLOCKS_TO_KEEP - 1;
    blocksForPurging.forEach((block, index) => {
      const purgeBecauseBlockEmpty = block.getState() === "needsLoading" && index >= emptyBlocksToKeep;
      const purgeBecauseCacheFull = maxBlocksProvided ? index >= blocksToKeep : false;
      if (purgeBecauseBlockEmpty || purgeBecauseCacheFull) {
        if (this.isBlockCurrentlyDisplayed(block)) {
          return;
        }
        if (this.isBlockFocused(block)) {
          return;
        }
        this.removeBlockFromCache(block);
      }
    });
  }
  isBlockFocused(block) {
    const focusedCell = this.focusService.getFocusCellToUseAfterRefresh();
    if (!focusedCell) {
      return false;
    }
    if (focusedCell.rowPinned != null) {
      return false;
    }
    const blockIndexStart = block.getStartRow();
    const blockIndexEnd = block.getEndRow();
    const hasFocus = focusedCell.rowIndex >= blockIndexStart && focusedCell.rowIndex < blockIndexEnd;
    return hasFocus;
  }
  isBlockCurrentlyDisplayed(block) {
    const startIndex = block.getStartRow();
    const endIndex = block.getEndRow() - 1;
    return this.rowRenderer.isRangeInRenderedViewport(startIndex, endIndex);
  }
  removeBlockFromCache(blockToRemove) {
    if (!blockToRemove) {
      return;
    }
    this.destroyBlock(blockToRemove);
  }
  checkRowCount(block, lastRow) {
    if (typeof lastRow === "number" && lastRow >= 0) {
      this.rowCount = lastRow;
      this.lastRowIndexKnown = true;
    } else if (!this.lastRowIndexKnown) {
      const lastRowIndex = (block.getId() + 1) * this.params.blockSize;
      const lastRowIndexPlusOverflow = lastRowIndex + this.params.overflowSize;
      if (this.rowCount < lastRowIndexPlusOverflow) {
        this.rowCount = lastRowIndexPlusOverflow;
      }
    }
  }
  setRowCount(rowCount, lastRowIndexKnown) {
    this.rowCount = rowCount;
    if (_exists(lastRowIndexKnown)) {
      this.lastRowIndexKnown = lastRowIndexKnown;
    }
    if (!this.lastRowIndexKnown) {
      if (this.rowCount % this.params.blockSize === 0) {
        this.rowCount++;
      }
    }
    this.onCacheUpdated();
  }
  forEachNodeDeep(callback) {
    const sequence = new NumberSequence();
    this.getBlocksInOrder().forEach((block) => block.forEachNode(callback, sequence, this.rowCount));
  }
  getBlocksInOrder() {
    const blockComparator = (a, b) => a.getId() - b.getId();
    const blocks = _getAllValuesInObject(this.blocks).sort(blockComparator);
    return blocks;
  }
  destroyBlock(block) {
    delete this.blocks[block.getId()];
    this.destroyBean(block);
    this.blockCount--;
    this.params.rowNodeBlockLoader.removeBlock(block);
  }
  // gets called 1) row count changed 2) cache purged 3) items inserted
  onCacheUpdated() {
    if (this.isAlive()) {
      this.destroyAllBlocksPastVirtualRowCount();
      const event = {
        type: "storeUpdated"
      };
      this.eventService.dispatchEvent(event);
    }
  }
  destroyAllBlocksPastVirtualRowCount() {
    const blocksToDestroy = [];
    this.getBlocksInOrder().forEach((block) => {
      const startRow = block.getId() * this.params.blockSize;
      if (startRow >= this.rowCount) {
        blocksToDestroy.push(block);
      }
    });
    if (blocksToDestroy.length > 0) {
      blocksToDestroy.forEach((block) => this.destroyBlock(block));
    }
  }
  purgeCache() {
    this.getBlocksInOrder().forEach((block) => this.removeBlockFromCache(block));
    this.lastRowIndexKnown = false;
    if (this.rowCount === 0) {
      this.rowCount = this.params.initialRowCount;
    }
    this.onCacheUpdated();
  }
  getRowNodesInRange(firstInRange, lastInRange) {
    const result = [];
    let lastBlockId = -1;
    let inActiveRange = false;
    const numberSequence = new NumberSequence();
    let foundGapInSelection = false;
    this.getBlocksInOrder().forEach((block) => {
      if (foundGapInSelection) {
        return;
      }
      if (inActiveRange && lastBlockId + 1 !== block.getId()) {
        foundGapInSelection = true;
        return;
      }
      lastBlockId = block.getId();
      block.forEachNode(
        (rowNode) => {
          const hitFirstOrLast = rowNode === firstInRange || rowNode === lastInRange;
          if (inActiveRange || hitFirstOrLast) {
            result.push(rowNode);
          }
          if (hitFirstOrLast) {
            inActiveRange = !inActiveRange;
          }
        },
        numberSequence,
        this.rowCount
      );
    });
    const invalidRange = foundGapInSelection || inActiveRange;
    return invalidRange ? [] : result;
  }
};
var InfiniteRowModel = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "rowModel";
  }
  wireBeans(beans) {
    this.filterManager = beans.filterManager;
    this.sortController = beans.sortController;
    this.selectionService = beans.selectionService;
    this.rowRenderer = beans.rowRenderer;
    this.rowNodeBlockLoader = beans.rowNodeBlockLoader;
  }
  getRowBounds(index) {
    return {
      rowHeight: this.rowHeight,
      rowTop: this.rowHeight * index
    };
  }
  // we don't implement as lazy row heights is not supported in this row model
  ensureRowHeightsValid() {
    return false;
  }
  postConstruct() {
    if (!this.gos.isRowModelType("infinite")) {
      return;
    }
    this.rowHeight = this.gos.getRowHeightAsNumber();
    this.addEventListeners();
    this.addDestroyFunc(() => this.destroyCache());
    this.verifyProps();
  }
  verifyProps() {
    if (this.gos.exists("initialGroupOrderComparator")) {
      _warnOnce(
        "initialGroupOrderComparator cannot be used with Infinite Row Model as sorting is done on the server side"
      );
    }
  }
  start() {
    this.setDatasource(this.gos.get("datasource"));
  }
  destroy() {
    this.destroyDatasource();
    super.destroy();
  }
  destroyDatasource() {
    if (this.datasource) {
      this.destroyBean(this.datasource);
      this.rowRenderer.datasourceChanged();
      this.datasource = null;
    }
  }
  addEventListeners() {
    this.addManagedEventListeners({
      filterChanged: this.onFilterChanged.bind(this),
      sortChanged: this.onSortChanged.bind(this),
      newColumnsLoaded: this.onColumnEverything.bind(this),
      storeUpdated: this.onCacheUpdated.bind(this)
    });
    this.addManagedPropertyListener("datasource", () => this.setDatasource(this.gos.get("datasource")));
    this.addManagedPropertyListener("cacheBlockSize", () => this.resetCache());
    this.addManagedPropertyListener("rowHeight", () => {
      this.rowHeight = this.gos.getRowHeightAsNumber();
      this.cacheParams.rowHeight = this.rowHeight;
      this.updateRowHeights();
    });
  }
  onFilterChanged() {
    this.reset();
  }
  onSortChanged() {
    this.reset();
  }
  onColumnEverything() {
    let resetRequired;
    if (this.cacheParams) {
      resetRequired = this.isSortModelDifferent();
    } else {
      resetRequired = true;
    }
    if (resetRequired) {
      this.reset();
    }
  }
  isSortModelDifferent() {
    return !_jsonEquals(this.cacheParams.sortModel, this.sortController.getSortModel());
  }
  getType() {
    return "infinite";
  }
  setDatasource(datasource) {
    this.destroyDatasource();
    this.datasource = datasource;
    if (datasource) {
      this.reset();
    }
  }
  isEmpty() {
    return !this.infiniteCache;
  }
  isRowsToRender() {
    return !!this.infiniteCache;
  }
  getNodesInRangeForSelection(firstInRange, lastInRange) {
    return this.infiniteCache ? this.infiniteCache.getRowNodesInRange(firstInRange, lastInRange) : [];
  }
  reset() {
    if (!this.datasource) {
      return;
    }
    const getRowIdFunc = this.gos.getRowIdCallback();
    const userGeneratingIds = getRowIdFunc != null;
    if (!userGeneratingIds) {
      this.selectionService.reset("rowDataChanged");
    }
    this.resetCache();
  }
  createModelUpdatedEvent() {
    return {
      type: "modelUpdated",
      // not sure if these should all be false - noticed if after implementing,
      // maybe they should be true?
      newPage: false,
      newPageSize: false,
      newData: false,
      keepRenderedRows: true,
      animate: false
    };
  }
  resetCache() {
    this.destroyCache();
    this.cacheParams = {
      // the user provided datasource
      datasource: this.datasource,
      // sort and filter model
      filterModel: this.filterManager?.getFilterModel() ?? {},
      sortModel: this.sortController.getSortModel(),
      rowNodeBlockLoader: this.rowNodeBlockLoader,
      // properties - this way we take a snapshot of them, so if user changes any, they will be
      // used next time we create a new cache, which is generally after a filter or sort change,
      // or a new datasource is set
      initialRowCount: this.gos.get("infiniteInitialRowCount"),
      maxBlocksInCache: this.gos.get("maxBlocksInCache"),
      rowHeight: this.gos.getRowHeightAsNumber(),
      // if user doesn't provide overflow, we use default overflow of 1, so user can scroll past
      // the current page and request first row of next page
      overflowSize: this.gos.get("cacheOverflowSize"),
      // page size needs to be 1 or greater. having it at 1 would be silly, as you would be hitting the
      // server for one page at a time. so the default if not specified is 100.
      blockSize: this.gos.get("cacheBlockSize"),
      // the cache could create this, however it is also used by the pages, so handy to create it
      // here as the settings are also passed to the pages
      lastAccessedSequence: new NumberSequence()
    };
    this.infiniteCache = this.createBean(new InfiniteCache(this.cacheParams));
    this.eventService.dispatchEventOnce({
      type: "rowCountReady"
    });
    const event = this.createModelUpdatedEvent();
    this.eventService.dispatchEvent(event);
  }
  updateRowHeights() {
    this.forEachNode((node) => {
      node.setRowHeight(this.rowHeight);
      node.setRowTop(this.rowHeight * node.rowIndex);
    });
    const event = this.createModelUpdatedEvent();
    this.eventService.dispatchEvent(event);
  }
  destroyCache() {
    if (this.infiniteCache) {
      this.infiniteCache = this.destroyBean(this.infiniteCache);
    }
  }
  onCacheUpdated() {
    const event = this.createModelUpdatedEvent();
    this.eventService.dispatchEvent(event);
  }
  getRow(rowIndex) {
    if (!this.infiniteCache) {
      return void 0;
    }
    if (rowIndex >= this.infiniteCache.getRowCount()) {
      return void 0;
    }
    return this.infiniteCache.getRow(rowIndex);
  }
  getRowNode(id) {
    let result;
    this.forEachNode((rowNode) => {
      if (rowNode.id === id) {
        result = rowNode;
      }
    });
    return result;
  }
  forEachNode(callback) {
    if (this.infiniteCache) {
      this.infiniteCache.forEachNodeDeep(callback);
    }
  }
  getTopLevelRowCount() {
    return this.getRowCount();
  }
  getTopLevelRowDisplayedIndex(topLevelIndex) {
    return topLevelIndex;
  }
  getRowIndexAtPixel(pixel) {
    if (this.rowHeight !== 0) {
      const rowIndexForPixel = Math.floor(pixel / this.rowHeight);
      const lastRowIndex = this.getRowCount() - 1;
      if (rowIndexForPixel > lastRowIndex) {
        return lastRowIndex;
      }
      return rowIndexForPixel;
    }
    return 0;
  }
  getRowCount() {
    return this.infiniteCache ? this.infiniteCache.getRowCount() : 0;
  }
  isRowPresent(rowNode) {
    const foundRowNode = this.getRowNode(rowNode.id);
    return !!foundRowNode;
  }
  refreshCache() {
    if (this.infiniteCache) {
      this.infiniteCache.refreshCache();
    }
  }
  purgeCache() {
    if (this.infiniteCache) {
      this.infiniteCache.purgeCache();
    }
  }
  // for iRowModel
  isLastRowIndexKnown() {
    if (this.infiniteCache) {
      return this.infiniteCache.isLastRowIndexKnown();
    }
    return false;
  }
  setRowCount(rowCount, lastRowIndexKnown) {
    if (this.infiniteCache) {
      this.infiniteCache.setRowCount(rowCount, lastRowIndexKnown);
    }
  }
};
function refreshInfiniteCache(beans) {
  beans.rowModelHelperService?.getInfiniteRowModel()?.refreshCache();
}
function purgeInfiniteCache(beans) {
  beans.rowModelHelperService?.getInfiniteRowModel()?.purgeCache();
}
function getInfiniteRowCount(beans) {
  return beans.rowModelHelperService?.getInfiniteRowModel()?.getRowCount();
}
function isLastRowIndexKnown(beans) {
  return beans.rowModelHelperService?.getInfiniteRowModel()?.isLastRowIndexKnown();
}
var VERSION4 = "32.0.0";
var InfiniteRowModelCoreModule = {
  version: VERSION4,
  moduleName: `${"@ag-grid-community/infinite-row-model" /* InfiniteRowModelModule */}-core`,
  rowModel: "infinite",
  beans: [InfiniteRowModel],
  dependantModules: [RowNodeBlockModule]
};
var InfiniteRowModelApiModule = {
  version: VERSION4,
  moduleName: `${"@ag-grid-community/infinite-row-model" /* InfiniteRowModelModule */}-api`,
  beans: [RowModelHelperService],
  apiFunctions: {
    refreshInfiniteCache,
    purgeInfiniteCache,
    getInfiniteRowCount,
    isLastRowIndexKnown
  },
  dependantModules: [InfiniteRowModelCoreModule, SsrmInfiniteSharedApiModule]
};
var InfiniteRowModelModule = {
  version: VERSION4,
  moduleName: "@ag-grid-community/infinite-row-model" /* InfiniteRowModelModule */,
  dependantModules: [InfiniteRowModelCoreModule, InfiniteRowModelApiModule]
};

// packages/ag-grid-community/src/main.ts
ModuleRegistry.__registerModules(
  [CommunityFeaturesModule, ClientSideRowModelModule, InfiniteRowModelModule, CsvExportModule],
  false,
  void 0
);
export {
  ALWAYS_SYNC_GLOBAL_EVENTS,
  AbstractHeaderCellCtrl,
  AgAbstractField,
  AgAbstractInputField,
  AgAbstractLabel,
  AgCheckbox,
  AgCheckboxSelector,
  AgColumn,
  AgColumnGroup,
  AgInputDateField,
  AgInputNumberField,
  AgInputNumberFieldSelector,
  AgInputTextArea,
  AgInputTextField,
  AgInputTextFieldSelector,
  AgPickerField,
  AgPromise,
  AgProvidedColumnGroup,
  AgRadioButton,
  AgSelect,
  AgSelectSelector,
  AgToggleButton,
  AgToggleButtonSelector,
  AnimateShowChangeCellRenderer,
  AnimateSlideCellRenderer,
  AnimationFrameService,
  AriaAnnouncementService,
  AutoScrollService,
  AutoWidthCalculator,
  BarColumnLabelPlacement,
  BaseComponentWrapper,
  BaseCreator,
  BaseGridSerializingSession,
  BeanStub,
  BodyDropPivotTarget,
  BodyDropTarget,
  CellComp,
  CellCtrl,
  CellNavigationService,
  CellPositionUtils,
  CellRangeType,
  ChangedPath,
  ChartMappings,
  CheckboxCellEditor,
  CheckboxCellRenderer,
  CheckboxSelectionComponent,
  ClientSideRowModelModule,
  ClientSideRowModelSteps,
  ColumnApplyStateService,
  ColumnAutosizeService,
  ColumnFactory,
  ColumnKeyCreator,
  ColumnModel,
  ColumnMoveService,
  ColumnNameService,
  ColumnSizeService,
  CommunityFeaturesModule,
  Component,
  ComponentUtil,
  Context,
  CssClassManager,
  CsvCreator,
  CsvExportModule,
  CtrlsService,
  DataTypeService,
  DateCellEditor,
  DateFilter,
  DateStringCellEditor,
  Downloader,
  DragAndDropService,
  DragService,
  DragSourceType,
  Environment,
  EventService,
  ExcelFactoryMode,
  ExpansionService,
  ExpressionService,
  FakeHScrollComp,
  FakeVScrollComp,
  FilterManager,
  FilterWrapperComp,
  FocusService,
  FuncColsService,
  GROUP_AUTO_COLUMN_ID,
  Grid,
  GridBodyComp,
  GridBodyCtrl,
  GridComp,
  GridCoreCreator,
  GridCoreModule,
  GridCtrl,
  GridHeaderComp,
  GridHeaderCtrl,
  GridOptionsService,
  GridSerializer,
  GroupInstanceIdCreator,
  HeaderCellCtrl,
  HeaderFilterCellComp,
  HeaderFilterCellCtrl,
  HeaderGroupCellCtrl,
  HeaderNavigationDirection,
  HeaderNavigationService,
  HeaderPositionUtils,
  HeaderRowComp,
  HeaderRowContainerComp,
  HeaderRowContainerCtrl,
  HeaderRowCtrl,
  HeaderRowType,
  HorizontalDirection,
  HorizontalResizeService,
  InfiniteRowModelModule,
  KeyCode,
  LargeTextCellEditor,
  LayoutCssClasses,
  LocalEventService,
  LocaleService,
  ManagedFocusFeature,
  MenuService,
  ModuleNames,
  ModuleRegistry,
  MouseEventService,
  MoveColumnFeature,
  NavigationService,
  NumberCellEditor,
  NumberFilter,
  NumberSequence,
  OverlayWrapperComponent,
  PinnedRowModel,
  PivotResultColsService,
  PopupComponent,
  PopupEditorWrapper,
  PopupService,
  PositionableFeature,
  PropertyKeys,
  ProvidedFilter,
  RefPlaceholder,
  ResizeObserverService,
  RowContainerComp,
  RowContainerCtrl,
  RowCtrl,
  RowDragComp,
  RowHighlightPosition,
  RowModelHelperService,
  RowNode,
  RowNodeBlock,
  RowNodeBlockLoader,
  RowNodeSorter,
  RowPositionUtils,
  RowRenderer,
  RowType,
  ScalarFilter,
  ScrollVisibleService,
  SelectCellEditor,
  SelectableService,
  SelectionHandleType,
  ServerSideTransactionResultStatus,
  SetLeftFeature,
  SimpleFilter,
  SortController,
  SortIndicatorComp,
  SortIndicatorSelector,
  StandardMenuFactory,
  StylingService,
  TabGuardClassNames,
  TabGuardComp,
  TabGuardCtrl,
  TabGuardFeature,
  TextCellEditor,
  TextFilter,
  TextFloatingFilter,
  TooltipFeature,
  TooltipStateManager,
  TouchListener,
  UserComponentFactory,
  UserComponentRegistry,
  ValueCache,
  ValueService,
  VanillaFrameworkOverrides,
  VerticalDirection,
  VisibleColsService,
  XmlFactory,
  ZipContainer,
  ColumnFilterModule as _ColumnFilterModule,
  CommunityMenuApiModule as _CommunityMenuApiModule,
  CsrmSsrmSharedApiModule as _CsrmSsrmSharedApiModule,
  CsvExportCoreModule as _CsvExportCoreModule,
  EditCoreModule as _EditCoreModule,
  FilterCoreModule as _FilterCoreModule,
  FloatingFilterModule as _FloatingFilterModule,
  ReadOnlyFloatingFilterModule as _ReadOnlyFloatingFilterModule,
  RowNodeBlockModule as _RowNodeBlockModule,
  SsrmInfiniteSharedApiModule as _SsrmInfiniteSharedApiModule,
  _addFocusableContainerListener,
  _areEqual,
  _bindCellRendererToHtmlElement,
  _capitalise,
  _clearElement,
  _cloneObject,
  _combineAttributesAndGridOptions,
  _compose,
  _createIcon,
  _createIconNoSpan,
  _debounce,
  _defaultComparator,
  _doOnce,
  _errorOnce,
  _escapeString,
  _exists,
  _existsAndNotEmpty,
  _flatten,
  _forEachReverse,
  _formatNumberCommas,
  _formatNumberTwoDecimalPlacesAndCommas,
  _fuzzySuggestions,
  _getAbsoluteHeight,
  _getAbsoluteWidth,
  _getAllValuesInObject,
  _getAriaPosInSet,
  _getCtrlForEventTarget,
  _getHeaderClassesFromColDef,
  _getInnerHeight,
  _getInnerWidth,
  _getRowContainerOptions,
  _getToolPanelClassesFromColDef,
  _includes,
  _insertArrayIntoArray,
  _insertIntoArray,
  _isElementInEventPath,
  _isEventFromPrintableCharacter,
  _isIOSUserAgent,
  _isNodeOrElement,
  _isStopPropagationForAgGrid,
  _isVisible,
  _iterateObject,
  _jsonEquals,
  _last,
  _loadTemplate,
  _log,
  _makeNull,
  _mergeDeep,
  _missing,
  _missingOrEmpty,
  _parseDateTimeFromString,
  _processOnChange,
  _radioCssClass,
  _removeAriaExpanded,
  _removeAriaSort,
  _removeFromArray,
  _removeFromParent,
  _removeRepeatsFromArray,
  _serialiseDate,
  _setAriaActiveDescendant,
  _setAriaChecked,
  _setAriaColCount,
  _setAriaColIndex,
  _setAriaColSpan,
  _setAriaControls,
  _setAriaDescribedBy,
  _setAriaDisabled,
  _setAriaExpanded,
  _setAriaHidden,
  _setAriaLabel,
  _setAriaLabelledBy,
  _setAriaLevel,
  _setAriaPosInSet,
  _setAriaRole,
  _setAriaRowCount,
  _setAriaRowIndex,
  _setAriaSelected,
  _setAriaSetSize,
  _setAriaSort,
  _setDisabled,
  _setDisplayed,
  _setFixedWidth,
  _setVisible,
  _shallowCompare,
  _sortRowNodesByOrder,
  _stopPropagationForAgGrid,
  _toStringOrNull,
  _unwrapUserComp,
  _utf8_encode,
  _values,
  _waitUntil,
  _warnOnce,
  createGrid,
  getDefaultFloatingFilterType,
  isColumn,
  isColumnGroup,
  isProvidedColumnGroup,
  isSelectionUIEvent,
  provideGlobalGridOptions
};
