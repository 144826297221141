import { useRecoilState, useRecoilValue } from 'recoil';
import { authAtom, userMetaAtom } from '../../../recoil/atoms/atoms';
import { ThreedotsSvg } from '../../../components/svg/threedots';
import ProfileButtonAvatar from './profile_button_avatar';

export const CompanyButton = (props) => {
  const authInfo = useRecoilValue(authAtom);
  // TODO temprarily put this here, need to fix this later
  const [userMeta,] = useRecoilState(userMetaAtom);
  const { sm } = props;

  if (!authInfo) {
    return null;
  }

  return (
    <div className={`flex flex-row rounded cursor-pointer items-center justify-center ${sm === 'false' && 'mx-6 border border-active_hover'}`} {...props}>
      <ProfileButtonAvatar profileLink={userMeta?.photoUrl} username={authInfo.username?.at(0).toUpperCase()} />
      {
        (!sm || sm !== 'true') && (
          <div
            className="flex flex-row justify-between cursor-pointer"
            {...props}
          >
            <span className="ml-2 text-xs text-semibold">{authInfo.username}</span>
            <ThreedotsSvg className="ml-6 stroke-gray-500" />
          </div>
        )
      }
    </div>
  );
};
