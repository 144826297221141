import React, { useState, useReducer, useCallback } from 'react';
import { Form, Button } from 'antd';
import {
  includes,
  isFunction,
  filter,
  find,
  difference,
  isEmpty,
  mapValues,
} from 'lodash';
import DynamicFormField from './DynamicFormField';
import getFieldLabel from './getFieldLabel';
import {
  DatabaseDto,
  DatabaseType,
} from '../../../../../apis/datasources/interfaces';
import {
  addDatabaseRedashAtom,
  curDatabaseAtom,
} from '../../../../../recoil/atoms/atoms';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { upsertDatabase } from '../../../../../apis/datasources/apis';
import Swal from 'sweetalert2';

const fieldRules = ({ type, required, minLength }) => {
  const requiredRule = required;
  const minLengthRule =
    minLength && includes(['text', 'email', 'password'], type);
  const emailTypeRule = type === 'email';

  return [
    requiredRule && { required, message: 'This field is required.' },
    minLengthRule && { min: minLength, message: 'This field is too short.' },
    emailTypeRule && {
      type: 'email',
      message: 'This field must be a valid email.',
    },
  ].filter((rule) => rule);
};

function normalizeEmptyValuesToNull(fields, values) {
  return mapValues(values, (value, key) => {
    const { initialValue } = find(fields, { name: key }) || {};
    if (
      (initialValue === null ||
        initialValue === undefined ||
        initialValue === '') &&
      value === ''
    ) {
      return null;
    }
    return value;
  });
}

function DynamicFormFields({ dbType, fields, feedbackIcons, form }) {
  return fields.map((field) => {
    const { name, type, initialValue, contentAfter } = field;
    const fieldLabel = getFieldLabel(field);

    const formItemProps = {
      name,
      className: 'mb-10',
      hasFeedback: type !== 'checkbox' && type !== 'file' && feedbackIcons,
      label: type === 'checkbox' ? '' : fieldLabel,
      rules: fieldRules(field),
      valuePropName: type === 'checkbox' ? 'checked' : 'value',
      initialValue,
    };

    const normFile = (e: any) => {
      return e?.fileList;
    };

    const otherFieldProps = {};

    if (type === 'file') {
      formItemProps.valuePropName = 'fileList';
      formItemProps.getValueFromEvent = normFile;
      otherFieldProps.maxCount = 1;
      if (dbType === 'excel') {
        otherFieldProps.typeCheck = 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        otherFieldProps.accept = '.xls, .xlsx';
      } else if (dbType === 'csv') {
        otherFieldProps.typeCheck = 'text/csv';
        otherFieldProps.accept = '.csv';
      }
    }

    return (
      <React.Fragment key={name}>
        <Form.Item {...formItemProps}>
          <DynamicFormField field={field} form={form} {...otherFieldProps} />
        </Form.Item>
        {isFunction(contentAfter)
          ? contentAfter(form.getFieldValue(name))
          : contentAfter}
      </React.Fragment>
    );
  });
}

const reducerForActionSet = (state, action) => {
  if (action.inProgress) {
    state.add(action.actionName);
  } else {
    state.delete(action.actionName);
  }
  return new Set(state);
};

function DynamicFormActions({ actions, isFormDirty }) {
  const [inProgressActions, setActionInProgress] = useReducer(
    reducerForActionSet,
    new Set()
  );

  const handleAction = useCallback((action) => {
    const actionName = action.name;
    if (isFunction(action.callback)) {
      setActionInProgress({ actionName, inProgress: true });
      action.callback(() => {
        setActionInProgress({ actionName, inProgress: false });
      });
    }
  }, []);

  return actions
    ? actions.map((action) => (
      <Button
        key={action.name}
        htmlType="button"
        className={`m-t-10 ${action.pullRight ? 'pull-right' : ''}`}
        type={action.type}
        disabled={isFormDirty && action.disableWhenDirty}
        loading={inProgressActions.has(action.name)}
        onClick={() => handleAction(action)}
      >
        {action.name}
      </Button>
    ))
    : null;
}

export default function DynamicForm({
  id,
  fields,
  actions,
  feedbackIcons,
  hideSubmitButton,
  defaultShowExtraFields,
  saveText,
  onSubmit,
  type,
}) {
  const setAddDatabaseRedash = useSetRecoilState(addDatabaseRedashAtom);
  const [curDatabase, setCurDatabase] = useRecoilState(curDatabaseAtom);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [showExtraFields, setShowExtraFields] = useState(
    defaultShowExtraFields
  );
  const [form] = Form.useForm();
  const extraFields = filter(fields, { extra: true });
  const regularFields = difference(fields, extraFields);

  const handleFinish = useCallback(
    (values: Record<string, any>) => {
      setIsSubmitting(true);
      values = normalizeEmptyValuesToNull(fields, values);

      const database: DatabaseDto = {
        name: values.name,
        type: type as DatabaseType,
        connJson: values ? Object.fromEntries(Object.entries(values).filter(([key]) => (key !== 'name' && key !== 'file'))) : null,
        createdOn: Date.now().toString(),
      };

      if (curDatabase) {
        database.id = curDatabase.id;
      }

      if (values['file']) {
        database.file = values.file[0].originFileObj;
        database.connJson['file'] = values.file;
      }

      upsertDatabase(database).then(() => {
      }).catch((err) => {
        console.log(err);
        Swal.fire('Error', 'Unable to upsert database', 'error');
      }).finally(() => {
        setIsSubmitting(false);
        setIsTouched(false);
        setAddDatabaseRedash(false);
        setCurDatabase(null);
      });
    },
    [fields, onSubmit]
  );

  const handleFinishFailed = useCallback(
    ({ errorFields }) => {
      form.scrollToField(errorFields[0].name);
    },
    [form]
  );

  return (
    <Form
      form={form}
      onFieldsChange={() => {
        setIsTouched(true);
      }}
      id={id}
      className="dynamic-form"
      layout="vertical"
      onFinish={handleFinish}
      onFinishFailed={handleFinishFailed}
    >
      <DynamicFormFields
        dbType={type}
        fields={regularFields}
        feedbackIcons={feedbackIcons}
        form={form}
      />
      {!isEmpty(extraFields) && (
        <div className="extra-options">
          <Button
            type="dashed"
            block
            className="extra-options-button"
            onClick={() =>
              setShowExtraFields(
                (currentShowExtraFields) => !currentShowExtraFields
              )
            }
          >
            Additional Settings
            <i
              className={`fa m-l-5 ${showExtraFields ? 'fa-caret-up' : 'fa-caret-down'
              }`}
              aria-hidden="true"
            />
          </Button>
          {showExtraFields && (
            <DynamicFormFields
              fields={extraFields}
              feedbackIcons={feedbackIcons}
              form={form}
            />
          )}
        </div>
      )}
      {!hideSubmitButton && (
        <Button
          className="w-100 mt-20"
          type="primary"
          htmlType="submit"
          disabled={isSubmitting}
        >
          {saveText}
        </Button>
      )}
      <DynamicFormActions actions={actions} isFormDirty={isTouched} />
    </Form>
  );
}
