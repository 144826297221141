import { each, includes, isUndefined, isEmpty, isNil, map, get, some } from 'lodash';

interface Property {
  name: string;
  title: string;
  type: string;
  placeholder?: string | null;
  required?: boolean;
  extra?: any;
  initialValue?: any;
  options?: Array<{ value: string; name: string }>;
  default?: any; // Add default property
}

interface ConfigurationSchema {
  properties: Record<string, Property>;
  order?: string[];
  required?: string[];
  extra_options?: string[];
}

interface Type {
  name: string;
  configuration_schema: ConfigurationSchema;
}

export interface Target {
  id?: string;
  name: string;
  options: Record<string, any>;
}

interface OrderedInput {
  name: string;
  title: string;
  type: string;
  placeholder?: string | null;
  required?: boolean;
  extra?: any;
  initialValue?: any;
  options?: Array<{ value: string; name: string }>;
  autoFocus?: boolean;
}

function orderedInputs(properties: Record<string, Property>, order: string[], targetOptions: Record<string, any>): OrderedInput[] {
  const inputs: OrderedInput[] = new Array(order.length);

  Object.keys(properties).forEach(key => {
    const position = order.indexOf(key);
    const input: OrderedInput = {
      name: key,
      title: properties[key].title,
      type: properties[key].type,
      placeholder: isNil(properties[key].default) ? null : properties[key].default.toString(),
      required: properties[key].required,
      extra: properties[key].extra,
      initialValue: targetOptions[key],
    };

    if (input.type === 'select') {
      input.placeholder = 'Select an option';
      input.options = properties[key].options;
    }

    if (position > -1) {
      inputs[position] = input;
    } else {
      inputs.push(input);
    }
  });

  return inputs;
}

function normalizeSchema(configurationSchema: ConfigurationSchema): void {
  each(configurationSchema.properties, (prop, name) => {
    if (name === 'password' || name === 'passwd') {
      prop.type = 'password';
    }

    if (name.endsWith('File')) {
      prop.type = 'file';
    }

    if (prop.type === 'boolean') {
      prop.type = 'checkbox';
    }

    if (prop.type === 'string') {
      prop.type = 'text';
    }

    if (!isEmpty(prop.enum)) {
      prop.type = 'select';
      prop.options = map(prop.enum, value => ({ value, name: value }));
    }

    if (!isEmpty(prop.extendedEnum)) {
      prop.type = 'select';
      prop.options = prop.extendedEnum;
    }

    prop.required = includes(configurationSchema.required, name);
    prop.extra = includes(configurationSchema.extra_options, name);
  });

  configurationSchema.order = configurationSchema.order || [];
}

function setDefaultValueToFields(configurationSchema: ConfigurationSchema, options: Record<string, any> = {}): void {
  const properties = configurationSchema.properties;
  Object.keys(properties).forEach(key => {
    const property = properties[key];

    // set default value for checkboxes
    if (!isUndefined(property.default) && property.type === 'checkbox') {
      options[key] = property.default;
    }

    // set default or first value when value has predefined options
    if (property.type === 'select') {
      const optionValues = map(property.options, option => option.value);
      options[key] = includes(optionValues, property.default) ? property.default : optionValues[0];
    }
  });
}

function getFields(type: Type = {} as Type, target: Target = { name: '', options: {} }): OrderedInput[] {
  const configurationSchema = type.configuration_schema;
  normalizeSchema(configurationSchema);
  const hasTargetObject = Object.keys(target.options).length > 0;

  if (!hasTargetObject) {
    setDefaultValueToFields(configurationSchema, target.options);
  }

  const isNewTarget = !target.id;
  const inputs: OrderedInput[] = [
    {
      name: 'name',
      title: 'Name',
      type: 'text',
      required: true,
      initialValue: target.name,
      placeholder: `My ${type.name}`,
      autoFocus: isNewTarget,
    },
    ...orderedInputs(configurationSchema.properties, configurationSchema.order, target.options),
  ];

  return inputs;
}

function updateTargetWithValues(target: Target, values: Record<string, any>): void {
  target.name = values.name;
  Object.keys(values).forEach(key => {
    if (key !== 'name') {
      target.options[key] = values[key];
    }
  });
}

function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() ?? '');
    reader.onerror = error => reject(error);
  });
}

function hasFilledExtraField(type: Type, target: Target): boolean {
  const extraOptions = get(type, 'configuration_schema.extra_options', []);
  return some(extraOptions, optionName => {
    const defaultOptionValue = get(type, ['configuration_schema', 'properties', optionName, 'default']);
    const targetOptionValue = get(target, ['options', optionName]);
    return !isNil(targetOptionValue) && targetOptionValue !== defaultOptionValue;
  });
}

export default {
  getFields,
  updateTargetWithValues,
  getBase64,
  hasFilledExtraField,
};
