import { DatabaseType, DatasetType } from '../datasources/interfaces';

export class Subscription {
  id: number;
  start: Date;
  end: Date;
  subscription_id: string;
  sizeOfTeam: number;
  maxDbConn: number;
  subscription_level: SubscriptionLevel;
  user: UserMeta;
}

export enum SubscriptionLevel {
    Free = 'Free',
    Starter = 'Starter',
    Team = 'Team',
    Team_Pro = 'Team Pro',
    Enterprise = 'Enterprise',
  }

export class UserMeta {
  id: string;
  username: string;
  email: string;
  userType: UserType;

  firstname: string;
  lastname: string;
  phone: string;
  photoUrl: string;

  adminUser: UserMeta;
  nonAdminUsers: UserMeta[];

  supportedDatabases: DatabaseType[];
  supportedDatasets: DatasetType[];
}

export class UserEntity_DONTUSE {
  // USER AUTHENTICATION AND BASIC INFO
  id: string;
  username: string;
  password: string;
  email: string;
  type: AccountType;
  userType: UserType;

  // USER METADATA
  firstname: string;
  lastname: string;
  locale: string;
  picture: string;
}

export enum AccountType {
    FIRST = 'FIRST',
    GOOGLE = 'GOOGLE',
}

export enum UserType {
    ADMIN = 'ADMIN',
    NON_ADMIN = 'NON_ADMIN',
}

