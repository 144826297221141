import { useEffect, useState } from 'react';
import {
  deleteDataset,
  reloadDataset,
  upsertDataset,
} from '../../../../apis/datasources/apis';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  addDatasetModalAtom,
  allDatasetsAtom,
  curDatasetAtom,
  userMetaAtom,
} from '../../../../recoil/atoms/atoms';
import {
  DatasetDto,
  DatasetType,
} from '../../../../apis/datasources/interfaces';
import { FileUploader } from './file_uploader';
import { ConnectionMapping, datasetTypeMapping } from '../displayNameMapping';
import { ModalContainer } from '../../../components/modal_container';

export const AddDatasetModal = () => {
  const setAddDatasetModal = useSetRecoilState(addDatasetModalAtom);
  const [curDataset, setCurDataset] = useRecoilState(curDatasetAtom);
  const [datasetName, setDatasetName] = useState('');
  const [userMeta] = useRecoilState(userMetaAtom);
  const [dataFormatType, setDataFormatType] = useState<DatasetType>();
  const [isSearchingDataFormat, setIsSearchingDataFormat] = useState(false);
  const [finish, setFinish] = useState(false);
  const [deletingDataset, setDeletingDataset] = useState(false);
  const [connection, setConnection] = useState<Record<string, any>>({});
  const [allDatasets, setAllDatasets] = useRecoilState(allDatasetsAtom);

  useEffect(() => {
    if (curDataset) {
      setDatasetName(curDataset.name);
      setDataFormatType(curDataset.type);
    }
  }, []);

  const onDatasetNameChange = (event) => {
    setDatasetName(event.target.value);
  };

  const onConnectionChange = (event, input) => {
    setConnection({
      ...connection,
      [ConnectionMapping[input]]: event.target.value,
    });
  };

  const handleUpsertClick = () => {
    const dataset: DatasetDto = {
      name: datasetName,
      type: dataFormatType,
      connJson: connection,
    };
    if (curDataset) {
      dataset.id = curDataset.id;
    }

    upsertDataset(dataset).then((res) => {
      if (res) {
        setFinish(true);
        reloadDataset(res.id);
        setAllDatasets([...allDatasets, res]);
      }
    });
  };

  const handleDeleteClick = () => {
    deleteDataset(curDataset.id).then(() => {
      setAddDatasetModal(false);
      const updatedDatasets = allDatasets.filter(
        (dataset) => dataset.id !== curDataset.id
      );
      setAllDatasets(updatedDatasets);
      setCurDataset(null);
    });
  };

  const renderAllDatasetTypes = () => {
    return userMeta.supportedDatasets.map((type) => {
      return (
        <div
          key={type}
          className="h-8 w-full pl-4 flex items-center hover:bg-layer1 cursor-pointer select-none"
          onClick={() => {
            setIsSearchingDataFormat(false);
            setDataFormatType(type);
          }}
        >
          <span className="text-sm">{datasetTypeMapping[type]}</span>
        </div>
      );
    });
  };

  const renderDataFormatInputs = () => {
    const inputStyle =
      'mt-2 h-10 rounded border-active_hover bg-transparent border pl-4';

    const renderInputBoxes = (inputs: string[], customStyles?: string[]) => {
      return inputs.map((input, idx) => {
        return renderInput(
          input,
          idx,
          customStyles ? customStyles[idx] : 'w-full'
        );
      });
    };

    const renderInput = (input: string, idx: number, customStyle?: string) => {
      return (
        <input
          key={idx}
          className={`${inputStyle} ${customStyle}`}
          placeholder={`Enter ${input}`}
          onChange={(e) => onConnectionChange(e, input)}
        />
      );
    };

    switch (dataFormatType) {
    case DatasetType.Confluence:
      return renderInputBoxes(
        ['Username', 'API Token', 'Base Url', 'Space Key'],
        ['w-full', 'w-full', 'w-[63%]', 'w-[34%]']
      );
    case DatasetType.Github:
      return (
        <>
          {renderInputBoxes(['Url', 'Branch Name'])}
          <div className="my-2 h-10 flex items-center gap-2">
            <label>
              <input
                className="mx-2"
                type="checkbox"
                checked={connection.recursive}
                onChange={() => {
                  setConnection({
                    ...connection,
                    recursive: !connection.recursive,
                  });
                }}
              />
                Recursive
            </label>
          </div>
        </>
      );
    case DatasetType.PDF:
      return <FileUploader heightForSelected="h-20" fileType="PDF" />;
    default:
      return [];
    }
  };

  return (
    <>
      <div className="absolute w-screen h-screen bg-black bg-opacity-70 flex items-center justify-center">
        {finish ? (
          <div className="w-1/3 h-auto bg-layer1 rounded">
            <div className="my-10 w-full h-auto flex flex-col items-center justify-center text-center">
              <div className="w-12 h-12 bg-bluecheck" />
              <span className="mt-6 text-xl font-bold">
                Document Uploaded Successful
              </span>
              <span className="mt-4 text-sm font-normal text-text_unselected">
                Check your dataset and use it for <br /> enhanced experiences
              </span>
              <div
                className="mt-8 w-32 h-10 bg-white rounded flex items-center justify-center cursor-pointer"
                onClick={() => setAddDatasetModal(false)}
              >
                <span className="text-black font-semibold text-sm">Finish</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-[500px] h-[500px] bg-layer1 rounded">
            <div className=" mx-10 my-8">
              <div className="w-full flex items-center justify-center">
                <span className="text-lg font-semibold">
                  {curDataset ? 'Manage Document' : 'Add New Document'}
                </span>
              </div>

              <div className="flex flex-col mt-4 h-auto">
                <span className="font-medium text-sm">Document Name</span>
                <input
                  className="mt-2 h-10 w-full rounded border-active_hover bg-transparent border pl-4"
                  placeholder="Enter Document Name"
                  value={datasetName}
                  onChange={onDatasetNameChange}
                />
              </div>

              <div className="flex flex-col mt-4 h-auto">
                <span className="font-medium text-sm">Data Format</span>
                <div
                  className="mt-2 h-10 w-full rounded border-active_hover bg-transparent border pl-4 flex items-center cursor-pointer"
                  onClick={() =>
                    setIsSearchingDataFormat(!isSearchingDataFormat)
                  }
                >
                  {dataFormatType ? (
                    <span>{datasetTypeMapping[dataFormatType]}</span>
                  ) : (
                    <>
                      <span className="w-6 h-6 bg-lightsearch" />
                      <span className="ml-2 text-text_unselected">
                        Search Data Format
                      </span>
                    </>
                  )}
                </div>
              </div>
              {isSearchingDataFormat && (
                <div className="absolute mt-2 py-1 w-[420px] h-auto max-h-24 bg-layer2 rounded overflow-auto">
                  {renderAllDatasetTypes()}
                </div>
              )}

              <div className="h-40 mt-4">
                {dataFormatType && (
                  <div className="flex h-auto flex-wrap gap-x-3">
                    <span className="font-medium text-sm">
                      {datasetTypeMapping[dataFormatType]}
                    </span>
                    {renderDataFormatInputs()}
                  </div>
                )}
              </div>

              <div
                className={`mt-6 h-10 w-full flex ${
                  curDataset ? 'justify-between' : 'justify-end'
                }`}
              >
                {curDataset && (
                  <div
                    className="w-28 h-full flex items-center justify-center border border-active_hover rounded cursor-pointer"
                    onClick={() => setDeletingDataset(true)}
                  >
                    <span>Delete</span>
                  </div>
                )}
                <div className="flex items-center">
                  <span
                    className="text-sm text-text_unselected cursor-pointer"
                    onClick={() => setAddDatasetModal(false)}
                  >
                    Cancel
                  </span>
                  <div
                    className="ml-6 w-28 h-full flex items-center justify-center border border-active_hover rounded bg-white cursor-pointer"
                    onClick={handleUpsertClick}
                  >
                    <span className="text-black font-semibold">
                      {curDataset ? 'Update' : 'Add'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {deletingDataset && (
          <ModalContainer>
            <div className="my-10 w-full flex flex-col items-center justify-center text-center">
              <span className="mt-6 text-lg font-bold">
                Are you sure you want to delete this dataset?
              </span>
              <div className="mt-6 w-full flex justify-center items-center">
                <div
                  className="w-28 h-10 border border-active_hover rounded flex items-center justify-center cursor-pointer"
                  onClick={() => setDeletingDataset(false)}
                >
                  <span className="text-tigrey font-semibold text-sm">
                    Cancel
                  </span>
                </div>
                <div
                  className="ml-2 w-28 h-10 bg-white rounded flex items-center justify-center cursor-pointer"
                  onClick={handleDeleteClick}
                >
                  <span className="text-black font-semibold text-sm">
                    Delete
                  </span>
                </div>
              </div>
            </div>
          </ModalContainer>
        )}
      </div>
    </>
  );
};
