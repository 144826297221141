import { useState } from 'react';
import Swal from 'sweetalert2';
import { resetPassword } from '../../../apis/auth/apis';
import { useNavigate, useParams } from 'react-router-dom';

export const NewPassword = () => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const params = useParams<{ userId: string; token: string }>();
  const navigate = useNavigate();

  const onSubmitClick = () => {
    if (!password) {
      Swal.fire('Error', "Password can't be null", 'error');
      return;
    }

    if (!confirmPassword) {
      Swal.fire('Error', "Confirm Password can't be null", 'error');
      return;
    }

    if (password !== confirmPassword) {
      Swal.fire('Error', "Password doesn't match", 'error');
      return;
    }

    resetPassword(params.userId, params.token, { password: password }).then(
      (res) => {
        if (res.success) {
          Swal.fire('Success', 'Password has been reset', 'success');
          navigate('/sign-in');
        }
      }
    );
  };

  return (
    <div className="grid justify-items-center justify-self-center self-center w-1/3 h-auto bg-layer2 max-w-md">
      <div className="text-xl font-bold text-text_selected w-10/12 my-10 text-center flex flex-col items-center gap-2">
        <span>New Password</span>
        <div className="flex justify-between items-center mt-2 w-full h-12 rounded border-solid border-active_hover border bg-transparent">
          <div className="grow h-full pl-6 pr-6">
            <input
              className="h-full w-full text-sm font-normal bg-transparent outline-none"
              placeholder="New Password"
              title="New Password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          {showPassword ? (
            <div
              className="mr-6 w-6 h-6 bg-eye"
              onClick={() => setShowPassword(false)}
            />
          ) : (
            <div
              className="mr-6 w-6 h-6 bg-eyeslash"
              onClick={() => setShowPassword(true)}
            />
          )}
        </div>
        <div className="flex justify-between items-center mt-2 w-full h-12 rounded border-solid border-active_hover border bg-transparent">
          <div className="grow h-full pl-6 pr-6">
            <input
              className="h-full w-full text-sm font-normal bg-transparent outline-none"
              placeholder="Confirm New Password"
              title="Confirm New Password"
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
            />
          </div>
          {showConfirmPassword ? (
            <div
              className="mr-6 w-6 h-6 bg-eye"
              onClick={() => {
                setShowConfirmPassword(false);
              }}
            />
          ) : (
            <div
              className="mr-6 w-6 h-6 bg-eyeslash"
              onClick={() => {
                setShowConfirmPassword(true);
              }}
            />
          )}
        </div>
        <div
          className="mt-6 w-full h-12 bg-text_selected grid items-center cursor-pointer rounded select-none"
          onClick={onSubmitClick}
        >
          <span className="text-sm text-text_unselected_secondary font-semibold">Submit</span>
        </div>
      </div>
    </div>
  );
};
