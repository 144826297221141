import { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Navigate } from 'react-router-dom';
import { authAtom } from '../recoil/atoms/atoms';
import Swal from 'sweetalert2';

const ProtectedRoute = ({ children }) => {
  const authInfo = useRecoilValue(authAtom);
  const cushion = 6 * 60 * 1000;

  const resetAuthInfo = useResetRecoilState(authAtom);
  const checkValidity = () => {
    if (authInfo) {
      const currentTime = new Date().getTime();
      const expireTime = authInfo.expireAt ?? 0;
      if (currentTime + cushion < expireTime) {
        return true;
      } else {
        localStorage.removeItem('authInfo');
        resetAuthInfo();
        Swal.fire('Error', 'Session Ended, Please login in again!', 'error');
        return false;
      }
    }
  };

  useEffect(() => {
    checkValidity();
    const intervalId = setInterval(checkValidity, 1000 * 60 * 60); // Check validity every hour

    return () => {
      clearInterval(intervalId); // Cleanup the interval
    };
  }, []);

  return checkValidity() ? children : <Navigate to="/landing" replace />;
};

export default ProtectedRoute;
