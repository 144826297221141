export const Step2 = () => {
  return (
    <div className="flex flex-col gap-5">
      <div className="h-[230px] bg-step2" />

      <div className="flex flex-col gap-1">
        <span className="text-green text-center">Conversational B.I.</span>
        <span className="text-text_selected font-semibold text-xl text-center">
          Data Clarity Made Easy
        </span>
      </div>

      <div className="text-text_unselected font-semibold text-sm w-[490px] m-auto">
        Gain clear insights effortlessly! Our tool combines data from your
        database, helping you generate SQL queries and visuals for a better
        understanding of your business.
      </div>
    </div>
  );
};
