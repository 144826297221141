import { Avatar as AvatarOriginal } from 'antd';

interface AvatarProps {
  isLegion: boolean;
  profileLink?: string;
  username?: string;
  isLoading?: boolean;
}

export default function Avatar({ isLegion, profileLink, username, isLoading = false }: AvatarProps) {
  return (
    <div className="ml-6 mt-6 items-center w-20 rounded">
      {
        isLegion ? (
          <div className='w-8 h-8 bg-cover rounded-md bg-lightlegion dark:bg-legion' />
        ) : (profileLink ? (
          <AvatarOriginal src={<img src={profileLink} alt={username?.at(0).toUpperCase()} />} />
        ) : (
          <div className={`w-8 h-8 rounded-full flex justify-center items-center select-none text-layer1 ${isLoading ? 'bg-layer2' : 'bg-green'}`}>
            <span>{username?.at(0).toUpperCase()}</span>
          </div>
        ))
      }
    </div>
  );
}
