import { useGoogleLogin } from '@react-oauth/google';
import { loginWithGoogle } from '../../../apis/auth/apis';
import { useSetRecoilState } from 'recoil';
import { authAtom } from '../../../recoil/atoms/atoms';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

export const Started = () => {
  const setAuthInfo = useSetRecoilState(authAtom);
  const navigate = useNavigate();
  const onGoogleLogin = useGoogleLogin({
    onSuccess: (res) => handleGoogleAuthCode(res.access_token),
    onError: (err) =>
      Swal.fire({ title: '', text: err.error_description, icon: 'error' }),
  });

  const handleGoogleAuthCode = (access_token: string) => {
    loginWithGoogle(access_token).then((authInfo) => {
      setAuthInfo(authInfo);
      localStorage.setItem('authInfo', JSON.stringify(authInfo));
      navigate('/');
    });
  };

  return (
    <div className="justify-self-center self-center w-96 h-60 select-none">
      <div className="grid justify-items-center text-xl font-bold text-text_selected text-center">
        <span>Get Started with Legion AI</span>
        <div
          className="grid text-center mt-8 w-full h-12 bg-text_selected rounded text-sidebar font-semibold cursor-pointer hover:shadow-2xl"
          onClick={() => navigate('/sign-up')}
        >
          <span className="pt-2">Sign Up with Email</span>
        </div>
        <div className="flex justify-between mt-4 w-full h-4 items-center">
          <div className="w-40 border-t border-active_hover" />
          <span className="text-text_unselected font-normal font-sm">or</span>
          <div className="w-40 border-t border-active_hover" />
        </div>
        <div
          className="flex justify-center mt-4 w-full h-12 items-center text-center bg-layer2 cursor-pointer"
          onClick={() => onGoogleLogin()}
        >
          <div className="inline-block w-6 h-6 bg-google" />
          <span className="pl-2 inline-block font-sm font-semibold">
            Continue with Google
          </span>
        </div>
        <div className="mt-4 w-full text-xs font-normal text-text_unselected items-center">
          Already have an account?{' '}
          <span
            className="underline underline-offset-auto font-mono cursor-pointer"
            onClick={() => navigate('/sign-in')}
          >
            Sign In
          </span>
        </div>
      </div>
    </div>
  );
};
