import { useRecoilState, useRecoilValue } from 'recoil';
import { asyncDialogAtom, authAtom, curConversationAtom } from '../../../recoil/atoms/atoms';

import { useEffect, useState } from 'react';
import {
  CreateDialogDto,
  DialogDto,
  DialogStatusEnum,
} from '../../../apis/conversations/interfaces';
import { sendDialog } from '../../../apis/conversations/apis';
import Swal from 'sweetalert2';
import { Title } from './components/title';
import { startAsyncDialog, endAsyncDialog } from '../../../utils/dialog_async';
import DialogInput from './components/dialog_input';
import ChatHistory from './components/dialogs/chat_history';

export const Conversations = () => {
  const [curConversation, setCurConversation] =
    useRecoilState(curConversationAtom);
  const authInfo = useRecoilValue(authAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [sentInput, setSentInput] = useState('');
  // const startedPreprocessing = useRef(false);
  const [, setAsyncDialog] = useRecoilState(asyncDialogAtom);

  const displayType =
    curConversation && curConversation.connectedDataset
      ? 'dataset'
      : 'database';
  const datasource =
    curConversation?.connectedDatabase ?? curConversation?.connectedDataset;

  // TODO: Clean up asyncDialog and stop long polling
  useEffect(() => {
    return () => {
      if (isLoading) {
        // call api to stop generating
      }
      setSentInput('');
      setIsLoading(false);
      setAsyncDialog(null);
      endAsyncDialog();
    };
  }, [curConversation]);

  // Start pre-processing when a new data entry conversation is created
  // Stopping first messages from server to client for data entry
  // useEffect(() => {
  //   if (
  //     curConversation &&
  //     curConversation.conversationType === ConversationType.DATAENTRY &&
  //     curConversation.chatHistory &&
  //     !isLoading &&
  //     !startedPreprocessing.current
  //   ) {
  //     setIsLoading(true);
  //     Swal.fire({
  //       title: 'Scanning dataset',
  //       text: 'Scanning the dataset for preprocessing may take a while, please wait',
  //       timer: 10000,
  //       timerProgressBar: true,
  //       showCloseButton: true,
  //       didOpen: () => {
  //         Swal.showLoading();
  //       },
  //     });
  //     startPreprocessing();
  //     startedPreprocessing.current = true;
  //   }
  // }, [curConversation, isLoading]);

  // const startPreprocessing = async () => {
  //   const createDialogDto: CreateDialogDto = {
  //     message: 'Start Preprocessing',
  //     conversationType: curConversation.conversationType,
  //     dataEntryRequest: {
  //       datasetName: curConversation.connectedDataset?.connJson?.key,
  //     },
  //   };
  //   sendDialog(curConversation.conversationId, createDialogDto)
  //     .then((res) => {
  //       const updatedDialogs = curConversation.chatHistory.concat(res);
  //       setCurConversation({
  //         ...curConversation,
  //         chatHistory: updatedDialogs,
  //       });
  //     })
  //     .catch((error) => {
  //       Swal.fire('Error!', error?.message ?? 'Something went wrong', 'error');
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  // const submitDialogWithAgent = () => {
  //   return doSubmitDialog(true);
  // };

  const submitDialog = (userInput) => {
    // return doSubmitDialog(false);
    return doSubmitDialogAsync(userInput, false, true);
  };

  const doSubmitDialogAsync = (userInput: string, useAgent: boolean, isAsync: boolean) => {
    const createDialogDto: CreateDialogDto = {
      message: userInput,
      conversationType: curConversation.conversationType,
      text2SqlRequest: {
        executeSQL: true,
        useAgent: useAgent,
      },
      isAsync: isAsync,
    };

    setIsLoading(true);
    setSentInput(userInput);

    const onFetchSuccess = (res: DialogDto) => {
      setAsyncDialog(res);
    };

    const onFetchError = (error) => {
      Swal.fire({
        title: 'Error',
        text: error?.data ?? 'Something went wrong',
        icon: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
    };

    const onFetchFinish = (res) => {
      const updatedDialogs = curConversation.chatHistory.concat(res);
      setCurConversation({
        ...curConversation,
        chatHistory: updatedDialogs,
      });
    };

    sendDialog(curConversation.conversationId, createDialogDto)
      .then(async (res) => {
        onFetchSuccess(res);
        if (res.dialogStatus && res.dialogStatus === DialogStatusEnum.IN_PROGRESS) {
          await startAsyncDialog(curConversation.conversationId, res.dialogId, onFetchError, onFetchSuccess, onFetchFinish);
        } else {
          const updatedDialogs = curConversation.chatHistory.concat(res);
          setCurConversation({
            ...curConversation,
            chatHistory: updatedDialogs,
          });
        }
      })
      .catch((error) => {
        onFetchError(error);
      })
      .finally(() => {
        setIsLoading(false);
        setAsyncDialog(null);
      });
  };

  if (!curConversation || !authInfo) return null;

  return (
    <div className="relative flex flex-col overflow-y-hidden min-h-min h-full w-full">
      <Title displayType={displayType} datasource={datasource} />
      <ChatHistory
        dialogs={curConversation.chatHistory}
        sentInput={sentInput}
        isLoading={isLoading}
        username={authInfo.username} />

      {/* {curConversation &&
          curConversation.conversationType === ConversationType.DATAENTRY &&
          !isLoading && (
             */}

      {!isLoading && <DialogInput
        key={curConversation.conversationId}
        initInput={sentInput}
        placeholder={curConversation?.chatHistory?.length === 0 ? 'Let\'s start a conversation' : ''}
        handleSubmitDialog={submitDialog}
        followUps={curConversation?.chatHistory?.at(curConversation.chatHistory.length - 1)?.followUps ?? []}
      />}
    </div>
  );
};
