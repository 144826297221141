import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs, vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useRecoilValue } from 'recoil';
import { colorthemeAtom } from '../../../../../../recoil/atoms/atoms';
import { useState } from 'react';

interface QueryProps {
  query: String
}

export const Query = ({ query }: QueryProps) => {
  const colorTheme = useRecoilValue(colorthemeAtom);
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(query.toString()).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className='w-full overflow-x-hidden'>
      <span>Query Generated:</span>
      <div className='w-auto h-auto mt-4 border border-outline_unselected rounded-lg flex flex-row'>
        <div className='flex-1'>
          <SyntaxHighlighter language="sql"
            style={colorTheme === 'dark' ? vs2015 : vs}
            wrapLongLines={true}
            customStyle={{ padding: '0.75em', background: 'none', fontSize: '1rem', lineHeight: '1.5rem', letterSpacing: '0.025em' }}>
            {query}
          </SyntaxHighlighter>
        </div>
        {copied ? (
          <span className="min-w-6 h-6 text-xs my-3 mx-1">
            Copied
          </span>
        ) : (
          <div
            className='min-w-6 h-6 m-3 bg-lightcopy dark:bg-copy bg-cover cursor-pointer justify-self-end'
            onClick={handleCopy} />
        )
        }
      </div>
    </div>
  );
};
