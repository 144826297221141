import { useRecoilValue } from 'recoil';
import { DialogRating } from '../../../../../../apis/conversations/interfaces';
import { curConversationAtom } from '../../../../../../recoil/atoms/atoms';
import Swal from 'sweetalert2';
import { rateDialog } from '../../../../../../apis/conversations/apis';
import { useState } from 'react';

interface RateButtonProps {
    dialogId: string,
    dialogRating: DialogRating,
}

export default function RateButton({ dialogId, dialogRating }: RateButtonProps) {
  const curConversation = useRecoilValue(curConversationAtom);
  const [rating, setRating] = useState(dialogRating);

  const handleRateDialog = (rating: DialogRating) => {
    rateDialog(curConversation.conversationId, dialogId, rating).then((res) => {
      if (res.success) {
        setRating((prev) => prev === rating ? DialogRating.UNSET : rating);
      }
    })
      .catch((error) => {
        Swal.fire('Error!', error?.message ?? 'Something went wrong', 'error');
      });
  };

  return (
    <div className="mt-5 mb-10 ml-6 flex flex-row justify-items-start space-x-4">
      <div
        className={`w-6 h-6 hover:bg-bglightest rounded ${rating === DialogRating.THUMBSUP
          ? 'dark:bg-filledthumbsup bg-lightfilledthumbsup'
          : 'bg-darkthumbsup'
        }`}
        onClick={() =>
          handleRateDialog(DialogRating.THUMBSUP)
        }
      />
      <div
        className={`w-6 h-6 hover:bg-bglightest rounded ${rating === DialogRating.THUMBSDOWN
          ? 'dark:bg-filledthumbsdown bg-lightfilledthumbsdown'
          : 'bg-darkthumbsdown'
        }`}
        onClick={() =>
          handleRateDialog(DialogRating.THUMBSDOWN)
        }
      />
    </div>
  );
}
