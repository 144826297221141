import { useRecoilValue } from 'recoil';
import { curConversationAtom } from '../../../../recoil/atoms/atoms';
import { GeneralChatSvg } from '../../../../components/svg/generalchat';

export const Title = ({ displayType, datasource }) => {
  const curConversation = useRecoilValue(curConversationAtom);

  return (
    <div className="self-start bg-sidebar text-white px-16 min-h-[4.5rem] w-full flex place-items-center">
      <div className="container flex items-center justify-between text-gray-400 ml-6">
        <div className="flex flex-none items-center w-[28%] overflow-hidden mr-10">
          <div className="-mr-3">
            <GeneralChatSvg />
          </div>
          <span className="font-bold truncate text-xl pl-2 ml-3">
            {curConversation.conversationName}
          </span>
        </div>

        {datasource && (
          <div className="flex flex-none items-center w-[23%] overflow-hidden ml-20 mr-0.5">
            <span className="text-xs sm:text-sm whitespace-nowrap mr-5 flex-shrink-0">
              {displayType === 'dataset' ? 'Dataset: ' : 'Database: '}
            </span>
            <span className="font-medium text-xs sm:text-sm truncate flex-grow">
              {datasource.name}
            </span>
          </div>
        )}

        {datasource && (
          <div className="flex flex-none items-center w-[14%] mr-20">
            <span className="text-xs sm:text-sm truncate mr-3">Type:</span>
            <span className="font-medium text-xs sm:text-sm truncate uppercase">
              {datasource.type}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
