export const Step4 = () => {
  return (
    <div className="flex flex-col gap-5">
      <div className="h-[250px] bg-step4" />

      <div className="flex flex-col gap-1">
        <span className="text-text_selected font-semibold text-xl text-center">
          Intuitive Natural Language Queries
        </span>
      </div>

      <div className="text-text_unselected font-semibold text-sm w-[490px] m-auto">
        Easily ask your queries using natural language to get quick and
        intuitive results.
      </div>
    </div>
  );
};
