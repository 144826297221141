import { Outlet, useNavigate } from 'react-router-dom';
import { colorthemeAtom } from '../../recoil/atoms/atoms';

import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';


export const AuthLayout = () => {
  const navigate = useNavigate();
  const colortheme = useRecoilValue(colorthemeAtom);

  useEffect(() => {
    const newTheme = localStorage.getItem('theme') ?? 'light';
    document.documentElement.setAttribute('data-theme', newTheme);
    document.body.className = newTheme;
  }, [colortheme]);

  return (
    <div className="flex flex-col w-screen h-full min-h-screen bg-layer1 nowrap">
      <div className="flex pt-2 w-screen justify-between">
        <div
          className="flex pt-10 pl-20 justify-center align-middle cursor-pointer"
          onClick={() => navigate('/landing')}
        >
          <div className="w-7 h-7 bg-lightlegion dark:bg-legion bg-cover" />
          <p className="pl-5 text-xl text-text_selected font-mono font-bold">
            Legion AI
          </p>
        </div>
        <div className="flex pt-8 pr-20 h-20 justify-center align-middle">
          <p
            className="text-xl text-text_selected pt-2 font-mono align-middle hover:shadow-2xl cursor-pointer"
            onClick={() => navigate('/sign-in')}
          >
            Sign In
          </p>
          <div
            className="ml-5 w-28 rounded-lg h-10 bg-gradient-to-r from-gleft to-gright p-0.5 hover:shadow-2xl cursor-pointer"
            onClick={() => navigate('/sign-up')}
          >
            <div className="flex w-full h-full bg-layer1 rounded-lg justify-center">
              <p className="text-xl pt-1 text-text_selected font-mono align-middle prevent-select">
                Sign Up
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="grow grid w-full h-full prevent-select">
        <Outlet />
      </div>
    </div>
  );
};
