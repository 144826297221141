import { Databases } from './components/databases';
import { Datasets } from './components/datasets';

export const Datasources = () => {
  return (
    <div className="h-full w-full overflow-auto">
      <div className="mt-12 mb-6 mx-auto gap-y-36 flex flex-col self-center justify-center items-center w-11/12 h-4/6">
        <Databases />
        <Datasets />
      </div>
    </div>
  );
};
