export const GeneralChatSvg = () => {
  return (
    <svg width="24"
      height="24"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 43V39.381H36.4444V37.5714H29.3333V23.0952H36.4444V21.2857C36.4444 17.7873 35.2296 14.8016 32.8 12.3286C30.3704 9.85556 27.437 8.61905 24 8.61905C20.563 8.61905 17.6296 9.85556 15.2 12.3286C12.7704 14.8016 11.5556 17.7873 11.5556 21.2857V23.0952H18.6667V37.5714H11.5556C10.5778 37.5714 9.74074 37.2171 9.04444 36.5083C8.34815 35.7996 8 34.9476 8 33.9524V21.2857C8 19.054 8.42222 16.9504 9.26667 14.975C10.1111 12.9996 11.2593 11.273 12.7111 9.79524C14.163 8.31746 15.8593 7.14881 17.8 6.28929C19.7407 5.42976 21.8074 5 24 5C26.1926 5 28.2593 5.42976 30.2 6.28929C32.1407 7.14881 33.837 8.31746 35.2889 9.79524C36.7407 11.273 37.8889 12.9996 38.7333 14.975C39.5778 16.9504 40 19.054 40 21.2857V39.381C40 40.3762 39.6519 41.2282 38.9556 41.9369C38.2593 42.6456 37.4222 43 36.4444 43H24Z"
        fill="#50B2EC" />
    </svg>
  );
};
