import { getConversation } from '../apis/conversations/apis';

export const fetchConversationWithId = async (
  convId,
  maxRetries = 3,
  retryDelay = 300,
  onFetchSuccess,
  onFetchError
) => {
  let attempts = 0;

  const attemptFetch = async () => {
    try {
      const conv = await getConversation(convId);
      onFetchSuccess(conv);
    } catch (err) {
      if (attempts < maxRetries){
        attempts++;
        setTimeout(() => {
          attemptFetch();
        }, retryDelay * attempts);
      } else {
        onFetchError(err);
      }
    }
  };

  attemptFetch();
};
