import ReactGA from 'react-ga4';

export const setupGoogleAnalytics = () => {
  console.log('process.env.REACT_APP_GAID:' + process.env.REACT_APP_GAID);
  ReactGA.initialize(process.env.REACT_APP_GAID);
};

export const analyzePageGA = (
  page: string,
  title: string,
  hittype = 'pageView'
) => {
  ReactGA.send({
    hitType: hittype,
    page: page,
    title: title,
  });
};
