import { useRecoilState, useRecoilValue } from 'recoil';
import {
  curConversationAtom,
  featureAtom,
  featureExpandAtom,
  mainOptionAtom,
  historyExpandAtom,
  allConversationsAtom,
  historyModalAtom,
  colorthemeAtom,
} from '../../recoil/atoms/atoms';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  getAllConversations,
  getConversation,
} from '../../apis/conversations/apis';
import { ChatSvg } from '../../components/svg/chat';
import { DatabaseSvg } from '../../components/svg/database';
import { ClockSvg } from '../../components/svg/clock';
import { UpSvg } from '../../components/svg/up';
import { ThreedotsSvg } from '../../components/svg/threedots';
import { DownSvg } from '../../components/svg/down';
import { useEffect, useState } from 'react';
import { PanelClose } from '../../components/svg/panelclose';
import { PanelOpen } from '../../components/svg/panelopen';
import { ConversationalBi } from '../../components/svg/conversationalbi';
import { GeneralQA } from '../../components/svg/generalqa';
import { ProfileDropdown } from './components/profile_dropdown';
import { DataEntry } from '../../components/svg/dataentry';

export enum MainOption {
  Features = 'features',
  Datasource = 'datasource',
  History = 'history',
  Conversation = 'conversation',
  Profile = 'profile',
  Billing = 'billing',
}

export enum FeatureOption {
  NONE = 'none',
  ConversationalBI = 'Conversational B.I.',
  GENERALQA = 'GeneralQ&A',
  DATAENTRY = 'DATAENTRY',
  WEBSCRAPER = 'Web Scraper',
}

export const Navigation = () => {
  const [mainOption, setMainOption] = useRecoilState(mainOptionAtom);
  const navigate = useNavigate();
  const { convId } = useParams<{ params: string; convId: string | undefined }>();
  const [, setHistoryModal] = useRecoilState(historyModalAtom);
  const colortheme = useRecoilValue(colorthemeAtom);

  const [featureOption, setFeatureOption] = useRecoilState(featureAtom);
  const [featureExpand, setFeatureExpand] = useRecoilState(featureExpandAtom);

  const [historyExpand, setHistoryExpand] = useRecoilState(historyExpandAtom);
  const [curConversation, setCurConversation] =
    useRecoilState(curConversationAtom);
  const [allConversations, setAllConversations] =
    useRecoilState(allConversationsAtom);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const renderFeature = () => {
    if (mainOption !== MainOption.Features) {
      return;
    }

    if (
      mainOption === MainOption.Features &&
      featureOption === FeatureOption.NONE
    ) {
      return 'bg-active text-text_selected';
    }

    if (mainOption === MainOption.Features) {
      return 'bg-border_selected text-text_unselected';
    }

    return;
  };

  useEffect(() => {
    const newTheme = localStorage.getItem('theme') ?? 'light';
    document.documentElement.setAttribute('data-theme', newTheme);
    document.body.className = newTheme;
  }, [colortheme]);

  useEffect(() => {
    if (mainOption === MainOption.Conversation) {
      setFeatureOption(FeatureOption.NONE);
      setFeatureExpand(false);
      setHistoryExpand(true);
      getAllConversations()
        .then((convs) => {
          setAllConversations(convs);
          if (!convId && convs.length > 0) {
            setCurConversation(convs[0]);
            navigate(`/history/${convs[0].conversationId}`);
          } else if (convId && convs.length === 0) {
            setCurConversation(null);
            navigate('/history');
          } else if (convId && convs.length > 0) {
            const targetConversation = convs.find(conversation => conversation.conversationId === convId);
            if (targetConversation) {
              setHistoryExpand(true);
              setCurConversation(targetConversation);
            } else {
              setCurConversation(convs[0]);
              navigate(`/history/${convs[0].conversationId}`);
            }
          }
        });
    }
  }, [mainOption]);

  useEffect(() => {
    if (curConversation) {
      navigate(`/history/${curConversation.conversationId}`);
    }
  }, [curConversation]);

  return (
    <div>
      {!isCollapsed ? (
        <div className="flex flex-col w-60 h-screen bg-sidebar border-r border-active_hover select-none overflow-hidden">
          <div className="flex justify-evenly w-full h-6 mt-6 pl-2 items-center">
            <div className="h-full w-6 dark:bg-legion bg-lightlegion" />
            <span className="font-mono font-bold text-lg">Legion AI</span>
            <button
              className="bg-transparent border-0 hover:cursor-pointer"
              onClick={() => setIsCollapsed(true)}
            >
              <PanelClose />
            </button>
          </div>
          <div className="mt-6 w-full border-t border-active_hover" />
          <div className="flex flex-col justify-between mt-4 mb-6 w-full h-full text-text_unselected_secondary overflow-hidden">
            <div className="flex flex-col w-full grow text-medium overflow-hidden">
              <Link
                to="/features"
                style={{ textDecoration: 'none' }}
                className={`flex flex-col h-auto ${renderFeature()}`}
                onClick={() => {
                  setMainOption(MainOption.Features);
                  setFeatureOption(FeatureOption.NONE);
                  setFeatureExpand(!featureExpand);
                  setCurConversation(null);
                  setHistoryExpand(false);
                }}
              >
                <div className={`flex items-center h-10 cursor-pointer ${featureExpand && 'bg-border_selected'}`}>
                  <ChatSvg
                    className={`ml-8  ${mainOption === MainOption.Features
                      ? 'stroke-icon_selected'
                      : 'stroke-icon_unselected'
                    }`}
                  />
                  <span className={`ml-4 ${mainOption === MainOption.Features && 'font-semibold'}`}>Features</span>
                  <div className="flex flex-grow items-center justify-end mr-8">
                    {featureExpand ? (
                      <UpSvg
                        className={`${mainOption === MainOption.Features || featureExpand
                          ? 'stroke-icon_selected'
                          : 'stroke-icon_unselected'
                        }`}
                        onClick={() => {
                          setFeatureExpand(!featureExpand);
                          setHistoryExpand(false);
                        }}
                      />
                    ) : (
                      <DownSvg
                        className={`${mainOption === MainOption.Features || featureExpand
                          ? 'fill-icon_selected'
                          : 'fill-icon_unselected'
                        }`}
                        onClick={() => {
                          setFeatureExpand(!featureExpand);
                          setHistoryExpand(false);
                        }}
                      />
                    )}
                  </div>
                </div>
              </Link>

              {featureExpand && (
                <>
                  <div
                    className={`flex items-center h-10 cursor-pointer ${featureOption === FeatureOption.ConversationalBI
                      ? 'bg-active text-text_selected'
                      : 'bg-border_selected2 text-text_unselected'
                    }`}
                    onClick={() => {
                      setFeatureOption(FeatureOption.ConversationalBI);
                    }}
                  >
                    <span className="ml-16 pl-2">Conversational B.I.</span>
                  </div>
                  <div
                    className={`flex items-center cursor-pointer h-10 ${featureOption === FeatureOption.GENERALQA
                      ? 'bg-active text-text_selected'
                      : 'bg-border_selected2 text-text_unselected'
                    }`}
                    onClick={() => setFeatureOption(FeatureOption.GENERALQA)}
                  >
                    <span className="ml-16 pl-2">General QA</span>
                  </div>
                  <div
                    className={`flex items-center h-10 cursor-pointer ${featureOption === FeatureOption.DATAENTRY
                      ? 'bg-active text-text_selected'
                      : 'bg-border_selected2 text-text_unselected'
                    }`}
                    onClick={() => {
                      setFeatureOption(FeatureOption.DATAENTRY);
                    }}
                  >
                    <span className="ml-16 pl-2">Data Entry</span>
                  </div>
                  <div
                    className={`flex items-center h-10 cursor-pointer ${featureOption === FeatureOption.WEBSCRAPER
                      ? 'bg-active text-text_selected'
                      : 'bg-border_selected2 text-text_unselected'
                    }`}
                    onClick={() => {
                      setFeatureOption(FeatureOption.WEBSCRAPER);
                    }}
                  >
                    <span className="ml-16 pl-2">Web Scraper</span>
                  </div>
                </>
              )}
              <Link
                to="/datasource"
                style={{ textDecoration: 'none' }}
                className={`flex items-center h-10 cursor-pointer ${mainOption === MainOption.Datasource &&
                  'bg-active text-text_selected'
                }`}
                onClick={() => {
                  setMainOption(MainOption.Datasource);
                  setFeatureExpand(false);
                  setHistoryExpand(false);
                  setCurConversation(null);
                }}
              >
                <DatabaseSvg
                  className={`h-10 ml-8  ${mainOption === MainOption.Datasource
                    ? 'stroke-icon_selected'
                    : 'stroke-icon_unselected'
                  }`}
                />
                <span className={`ml-4 ${mainOption === MainOption.Datasource && 'font-semibold'}`}>Data Source</span>
              </Link>

              <div
                style={{ textDecoration: 'none' }}
                className={`flex items-center cursor-pointer h-10 ${(mainOption === MainOption.History ||
                  mainOption === MainOption.Conversation) &&
                  !curConversation &&
                  'bg-active text-text_selected'
                } ${curConversation && 'bg-border_selected text-text_selected'}`}
                onClick={() => {
                  setHistoryExpand(!historyExpand);
                  if (!curConversation) {
                    navigate('/history');
                  }
                }}
              >
                <ClockSvg
                  className={`h-10 ml-8  ${historyExpand || mainOption === MainOption.Conversation
                    ? 'stroke-icon_selected'
                    : 'stroke-icon_unselected'
                  }`}
                />

                <span className={`ml-4 ${mainOption === MainOption.Conversation && 'font-semibold'}`}>History</span>
                <div className="flex flex-grow items-center justify-end mr-3 gap-1">
                  {historyExpand ? (
                    <UpSvg
                      className={`${historyExpand || mainOption === MainOption.Conversation
                        ? 'stroke-icon_selected'
                        : 'stroke-icon_unselected'
                      }`}
                      onClick={() => {
                        setHistoryExpand(!historyExpand);
                        setFeatureExpand(false);
                      }}
                    />
                  ) : (
                    <DownSvg
                      className={`${historyExpand || mainOption === MainOption.Conversation
                        ? 'fill-icon_selected'
                        : 'fill-icon_unselected'
                      }`}
                      onClick={() => {
                        setHistoryExpand(!historyExpand);
                        setFeatureExpand(false);
                      }}
                    />
                  )}
                  <ThreedotsSvg
                    className={`${historyExpand || mainOption === MainOption.Conversation
                      ? 'stroke-icon_selected'
                      : 'stroke-icon_unselected'
                    }`}
                    onClick={() => {
                      setHistoryModal(true);
                    }}
                  />
                </div>
              </div>

              {historyExpand && (
                <div
                  className="bg-layer2 h-fit mb-6 overflow-hidden"
                  onClick={() => {
                    setMainOption(MainOption.Conversation);
                  }}
                >
                  <div className='h-full overflow-y-auto'>
                    {allConversations.map((conversation) => {
                      return (
                        <div
                          key={conversation.conversationId}
                          className={`flex items-center h-10 cursor-pointer ${mainOption === MainOption.Conversation &&
                            curConversation &&
                            curConversation.conversationId ===
                            conversation.conversationId &&
                            'bg-active text-text_selected'
                          }`}
                          onClick={() => {
                            getConversation(conversation.conversationId).then(
                              setCurConversation
                            );
                          }
                          }
                        >
                          <div
                            className="ml-16 pl-2 mr-8 w-4/5 truncate"
                            title={conversation.conversationName || '<Empty>'}
                          >
                            {conversation.conversationName || '<Empty>'}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>

            {/* For full services, the h should be 48 */}
            <div className="flex flex-col w-full h-auto text-medium justify-between text-text_selected">
              {/* <div className="flex items-center h-6 select-none">
                <div className="ml-8 w-6 h-6 bg-darkquestion" />
                <span className="ml-4">FAQ</span>
              </div>
              <div className="flex items-center h-6 select-none">
                <div className="ml-8 w-6 h-6 bg-darksundim" />
                <span className="ml-4">Light Mode</span>
              </div>
              <div className="flex items-center h-6 select-none">
                <div className="ml-8 w-6 h-6 bg-darkgear" />
                <span className="ml-4">Settings</span>
              </div> */}
              <ProfileDropdown />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center w-16 h-screen bg-sidebar border-r border-active_hover select-none overflow-hidden">
          <button
            className="mt-6 bg-transparent border-0 hover:cursor-pointer"
            onClick={() => setIsCollapsed(false)}
          >
            <PanelOpen />
          </button>

          <div className="flex flex-col justify-between mt-4 mb-6 w-full h-full text-text_unselected_secondary overflow-hidden">
            <div className="flex flex-col w-full grow text-medium overflow-hidden">
              <Link
                to="/features"
                style={{ textDecoration: 'none' }}
                className={`flex flex-col justify-center items-center h-auto ${renderFeature()}`}
                onClick={() => {
                  setMainOption(MainOption.Features);
                  setFeatureOption(FeatureOption.NONE);
                  setFeatureExpand(!featureExpand);
                  setCurConversation(null);
                  setHistoryExpand(false);
                }}
              >
                <div className="flex items-center h-10 cursor-pointer">
                  <ChatSvg
                    className={`${mainOption === MainOption.Features
                      ? 'stroke-icon_selected'
                      : 'stroke-icon_unselected'
                    }`}
                  />
                </div>
              </Link>

              {featureExpand && (
                <>
                  <div
                    className={`flex items-center h-10 justify-center cursor-pointer ${featureOption === FeatureOption.ConversationalBI
                      ? 'bg-active text-text_selected'
                      : 'bg-border_selected text_unselected'
                    }`}
                    onClick={() => {
                      setFeatureOption(FeatureOption.ConversationalBI);
                    }}
                  >
                    <ConversationalBi />
                  </div>
                  <div
                    className={`flex items-center justify-center cursor-pointer h-10 ${featureOption === FeatureOption.GENERALQA
                      ? 'bg-active text-text_selected'
                      : 'bg-border_selected text_unselected'
                    }`}
                    onClick={() => setFeatureOption(FeatureOption.GENERALQA)}
                  >
                    <GeneralQA />
                  </div>
                  <div
                    className={`flex items-center justify-center cursor-pointer h-10 ${featureOption === FeatureOption.DATAENTRY
                      ? 'bg-active text-text_selected'
                      : 'bg-border_selected text_unselected'
                    }`}
                    onClick={() => setFeatureOption(FeatureOption.DATAENTRY)}
                  >
                    <DataEntry />
                  </div>
                  <div
                    className={`flex items-center justify-center cursor-pointer h-10 ${featureOption === FeatureOption.WEBSCRAPER
                      ? 'bg-active text-text_selected'
                      : 'bg-border_selected text_unselected'
                    }`}
                    onClick={() => setFeatureOption(FeatureOption.WEBSCRAPER)}
                  >
                    <div className="w-4 h-4 bg-webscraper bg-cover" />
                  </div>
                </>
              )}
              <Link
                to="/datasource"
                style={{ textDecoration: 'none' }}
                className={`flex items-center justify-center h-10 cursor-pointer ${mainOption === MainOption.Datasource &&
                  'bg-active text-text_selected'
                }`}
                onClick={() => {
                  setMainOption(MainOption.Datasource);
                  setFeatureExpand(false);
                  setHistoryExpand(false);
                  setCurConversation(null);
                }}
              >
                <DatabaseSvg
                  className={`h-10 ${mainOption === MainOption.Datasource
                    ? 'stroke-icon_selected'
                    : 'stroke-icon_unselected'
                  }`}
                />
              </Link>

              <div
                style={{ textDecoration: 'none' }}
                className={`flex items-center pl-4 justify-center cursor-pointer h-10 ${(mainOption === MainOption.History ||
                  mainOption === MainOption.Conversation) &&
                  !curConversation &&
                  'bg-active text-text_selected'
                } ${curConversation && 'bg-border_selected text-text_selected'}`}
                onClick={() => {
                  setHistoryExpand(!historyExpand);
                  if (!curConversation) {
                    navigate('/history');
                  }
                }}
              >
                <ClockSvg
                  className={`h-10 ${historyExpand || mainOption === MainOption.Conversation
                    ? 'stroke-icon_selected'
                    : 'stroke-icon_unselected'
                  }`}
                />
                <div className="flex items-center">
                  <ThreedotsSvg
                    className={`${historyExpand || mainOption === MainOption.Conversation
                      ? 'stroke-icon_selected'
                      : 'stroke-icon_unselected'
                    }`}
                    onClick={() => {
                      setHistoryModal(true);
                    }}
                  />
                </div>
              </div>

              {historyExpand && (
                <div
                  className="bg-layer2 mb-6 overflow-y-auto"
                  onClick={() => {
                    setMainOption(MainOption.Conversation);
                  }}
                >
                  <div>
                    {allConversations.map((conversation) => {
                      return (
                        <div
                          key={conversation.conversationId}
                          className={`flex items-center h-10 justify-center cursor-pointer ${mainOption === MainOption.Conversation &&
                            curConversation &&
                            curConversation.conversationId ===
                            conversation.conversationId &&
                            'bg-active text-text_selected'
                          }`}
                          onClick={() =>
                            getConversation(conversation.conversationId).then(
                              setCurConversation
                            )
                          }
                        >
                          <div
                            className="flex truncate"
                            title={conversation.conversationName || '<Empty>'}
                          >
                            {conversation.conversationName || '<Empty>'}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>

            <div className="flex flex-col w-full h-auto text-medium justify-between text-text_selected">
              {/* <div className="flex items-center h-6 select-none">
                <div className="ml-8 w-6 h-6 bg-darkquestion" />
                <span className="ml-4">FAQ</span>
              </div>
              <div className="flex items-center h-6 select-none">
                <div className="ml-8 w-6 h-6 bg-darksundim" />
                <span className="ml-4">Light Mode</span>
              </div>
              <div className="flex items-center h-6 select-none">
                <div className="ml-8 w-6 h-6 bg-darkgear" />
                <span className="ml-4">Settings</span>
              </div> */}
              <ProfileDropdown sm={true} />
            </div>
          </div>
        </div>
      )
      }
    </div >
  );
};
