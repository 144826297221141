import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../../../recoil/atoms/atoms';

export interface PricingCardProps {
  title: string;
  price: string;
  features: string[];
  paymentLink?: string;
  isCurrentPlan?: boolean;
}

export const PricingCard: React.FC<PricingCardProps> = (props) => {
  const authInfo = useRecoilValue(authAtom);

  if (!authInfo) return null;

  return (
    <div
      className="w-64 h-auto flex flex-col items-center rounded-lg hover:bg-layer2
    border-2 border-active_hover p-5 text-base text-text_unselected font-mono tracking-tighter"
    >
      <div className="self-start">
        <h3 className="my-2">{props.title}</h3>
        <span className="text-text_selected text-3xl font-mono font-black">
          {props.price}
        </span>
        {props.price.startsWith('$') && ' / month'}
      </div>
      <div className="w-52 border-t border-active_hover mt-6 mb-5" />
      <ul className="flex flex-col flex-grow list-disc ml-6">
        {props.features.map((feature, index) => (
          <li key={index} className="text-left">
            {feature}
          </li>
        ))}
      </ul>
      <Link
        to={
          props.paymentLink
            ? `${props.paymentLink}?client_reference_id=${authInfo.username}`
            : 'https://www.thelegionai.com/contact'
        }
      >
        <button
          className={`w-52 h-12 text-center rounded-lg text-text_selected font-bold hover:text-text_unselected mt-3 ${
            props.isCurrentPlan ? 'bg-binactive' : 'bg-border_unselected'
          }`}
          disabled={props.isCurrentPlan}
        >
          {props.isCurrentPlan
            ? 'Current Plan'
            : props.price.startsWith('$')
              ? 'Get Started'
              : 'Contact Us'}
        </button>
      </Link>
    </div>
  );
};
