interface Ai_ChartProps {
  ai_chart: string;
}

interface Analytic_ChartProps {
  analytic_chart: string
}

export const Ai_Chart = ({ ai_chart }: Ai_ChartProps) => {
  return (
    <>
      <span>Chart Generated:</span>
      <div className="my-4 w-[530px] h-[440px] overflow-hidden border rounded">
        <iframe
          title="chart"
          srcDoc={ai_chart}
          className="w-[548px] h-[460px]"
        />
      </div>
    </>
  );
};

export const Analytic_Chart = ({ analytic_chart }: Analytic_ChartProps) => {
  return (
    <>
      <span>Chart Generated:</span>
      <div className="my-4 w-[530px] h-[440px] overflow-hidden border rounded">
        <img
          src={`data:image/png;base64,${analytic_chart}`} // Adjust the MIME type based on the image format
          alt="Generated Chart"
          className="w-full h-full object-cover"
        />
      </div>
    </>
  );
};
