import { useRecoilState, useRecoilValue } from 'recoil';
import {
  addDatabaseModalAtom,
  addDatasetModalAtom,
  addDatabaseRedashAtom,
  teamAccessModalAtom,
  mainOptionAtom,
  onbroadingModalAtom,
  historyModalAtom,
  colorthemeAtom,
} from '../../recoil/atoms/atoms';
import { useNavigate, useParams } from 'react-router-dom';
import { Features } from './features/features';
import { Datasources } from './datasources/datasources';
import { Conversations } from './conversations/conversations';
import { TeamAccessModal } from './team_access/team_access_modal';
import { BillingPage } from './billing/billing_page';
import { ProfilePage } from './profile/profile_page';
import { AddDatabaseModal } from './datasources/components/add_database_modal';
import { AddDatasetModal } from './datasources/components/add_dataset_modal';
import { Stepper } from './onbroading/stepper';
import { HistoryModal } from './history/history_modal';
import AddDatabaseRedash from './datasources/components/add_database_redash';
import { useEffect } from 'react';
import { MainOption, Navigation } from './navigation';
import { SUPPORTED_DATABASES } from '../../utils/supported-database';

export const MainPage = () => {

  const navigate = useNavigate();
  const [mainOption, setMainOption] = useRecoilState(mainOptionAtom);
  const { param } = useParams();
  useEffect(() => {
    if (param !== 'history') {
      const option = Object.values(MainOption).find((item) => item === param) as
        | MainOption
        | undefined;
      if (option) {
        setMainOption(
          Object.values(MainOption).find((item) => item === param) as
          | MainOption
          | undefined
        );
      } else {
        navigate('/features');
      }
    } else {
      setMainOption(MainOption.Conversation);
    }
  });

  const addDatabaseModal = useRecoilValue(addDatabaseModalAtom);
  const addDatabaseRedash = useRecoilValue(addDatabaseRedashAtom);
  const addDatasetModal = useRecoilValue(addDatasetModalAtom);
  const teamAccessModal = useRecoilValue(teamAccessModalAtom);
  const [historyModal] = useRecoilState(historyModalAtom);
  const onbroadingStepperModal = useRecoilValue(onbroadingModalAtom);
  const [colortheme, setColortheme] = useRecoilState(colorthemeAtom);

  const renderBody = () => {
    switch (mainOption) {
    case MainOption.Features:
      return <Features />;
    case MainOption.Datasource:
      return <Datasources />;
    case MainOption.Conversation || MainOption.History:
      return <Conversations />;
    case MainOption.Billing:
      return <BillingPage />;
    case MainOption.Profile:
      return <ProfilePage />;
    }
  };

  useEffect(() => {
    const newTheme = localStorage.getItem('theme') ?? 'light';
    document.documentElement.setAttribute('data-theme', newTheme);
    document.body.className = newTheme;
    setColortheme(newTheme);
  }, [colortheme]);

  return (
    <div className="flex w-screen h-screen bg-layer1 text-sm text-text_selected leading-4 text-semibold min-h-fit">
      <Navigation />

      <div className="flex-1 max-w-full overflow-hidden">{renderBody()}</div>

      {addDatabaseRedash && <AddDatabaseRedash data={SUPPORTED_DATABASES} />}
      {addDatabaseModal && <AddDatabaseModal />}
      {addDatasetModal && <AddDatasetModal />}
      {teamAccessModal && <TeamAccessModal />}
      {historyModal && <HistoryModal />}
      {localStorage.getItem('onbroadShown') !== 'true' &&
        onbroadingStepperModal && <Stepper />}
    </div>
  );
};
