import { useCallback, useEffect, useRef } from 'react';
import { DialogDto } from '../../../../../apis/conversations/interfaces';
import Avatar from './components/avatar';
import { Response } from './components/response';
import { useRecoilValue } from 'recoil';
import { asyncDialogAtom } from '../../../../../recoil/atoms/atoms';
import RateButton from './components/rate_button';

interface ChatHistoryProps {
  dialogs: DialogDto[] | undefined;
  sentInput: string;
  isLoading: boolean;
  profileLink?: string;
  username: string;
}

export default function ChatHistory({ dialogs, isLoading, sentInput, profileLink, username }: ChatHistoryProps) {
  const asyncDialog = useRecoilValue<DialogDto>(asyncDialogAtom);

  const dialogEndRef = useRef(null);

  const scrollToBottom = () => {
    dialogEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  useEffect(() => {
    if (isLoading) {
      scrollToBottom();
    }
  }, [isLoading]);

  useEffect(() => {
    if (dialogs?.length !== 0) {
      scrollToBottom();
    }
  });

  // TODO: Implement event listener to change dialog request
  const renderDialogs = useCallback((dialogs: DialogDto[], isAsyncDialog: boolean) => {
    return dialogs?.map((dialog) => {
      return (
        <div key={'dialog' + dialog?.dialogId}>
          <div className="flex w-full min-h-20 h-auto border-b-2 border-b-border_selected">
            <Avatar isLegion={false} profileLink={profileLink} username={username} isLoading={isAsyncDialog} />
            <div className='flex-1 max-w-fit my-4 p-3 h-auto text-text_unselected text-base text-balance bg-layer2 rounded-md'>
              <span>
                {isLoading && isAsyncDialog ? sentInput : dialog?.userInput}
              </span>
            </div>
            {/* <div className='pt-7 pl-3'>
              <div className='w-6 h-6 bg-lightedit dark:bg-edit cursor-pointer' />
            </div> */}
          </div>
          <div className="flex flex-col w-full">
            <Response responses={dialog?.responses} isAsyncDialog={isAsyncDialog} />
          </div>
          {
            !isAsyncDialog && <RateButton dialogId={dialog.dialogId} dialogRating={dialog.dialogRating} />
          }
        </div>
      );
    });
  }, [profileLink, username, sentInput]);

  return (
    <div className="h-full flex flex-col px-16 overflow-y-auto">
      {renderDialogs(dialogs, false)}
      {/* When loading */}
      {isLoading && sentInput && renderDialogs([asyncDialog], true)}
      <div ref={dialogEndRef} />
    </div>
  );
}
