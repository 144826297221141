const PreviewCard = ({ children, title, body }) => {
  // const path = `../../../../../public/db-logos/${imageName}.png`;

  return (
    <div className="w-100 flex items-center">
      {/* <img
        src={path}
        width="32"
        height="32"
        alt="Logo/Avatar"
      /> */}

      {/* <div className="bg-athena dark:bg-athena w-[32px] h-[32px]" /> */}

      <div className="flex-grow">
        <div>{title}</div>
        {body && <div className="text-gray-700">{body}</div>}
      </div>
      {children}
    </div>
  );
};

export default PreviewCard;
