import axios from 'axios';
export const instance = axios.create({
  baseURL: process.env.APIBASE + ':' + process.env.APIPORT,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 120 * 1000
});

instance.interceptors.request.use(
  config => {
    const authInfo = JSON.parse(localStorage.getItem('authInfo'));
    if (authInfo) {
      config.headers.Authorization = 'Bearer ' + authInfo.accessToken;
    }
    return config;
  }
);

instance.interceptors.response.use((response) => response, (error) => {
  throw error.response?.data;
});
