import { RecoilRoot } from 'recoil';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import ReactGA from 'react-ga4';
import { MainPage } from './pages/main/main_page';
import ProtectedRoute from './components/protected_route';
import { AuthLayout } from './pages/auth/auth_layout';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useEffect, StrictMode } from 'react';
import NotFound from './pages/notfound_page';
import { Started } from './pages/auth/components/started';
import { Signout } from './pages/auth/components/sign_out';
import { SignIn } from './pages/auth/components/sign_in';
import { SignUp } from './pages/auth/components/sign_up';
import { Email } from './pages/auth/components/email';
import { ForgetPassword } from './pages/auth/components/forget_password';
import { NewPassword } from './pages/auth/components/new_password';
import { analyzePageGA, setupGoogleAnalytics } from './utils/ga';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route element={<AuthLayout />}>
        <Route path="landing" element={<Started />} />
        <Route path="sign-in" element={<SignIn />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="sign-out" element={<Signout />} />
        <Route path="verfiy-email" element={<Email />} />
        <Route path="forget-password" element={<ForgetPassword />} />
        <Route path="reset-password/:userId/:token" element={<NewPassword />} />
      </Route>
      <Route
        element={
          <ProtectedRoute>
            <MainPage />
          </ProtectedRoute>
        }>
        <Route path="/:param?/:convId?" />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

const App = () => {
  useEffect(() => {
    setupGoogleAnalytics();
    router.subscribe((state) => {
      analyzePageGA(state.location.pathname, document.title);
      ReactGA.send({
        hitType: 'pageview',
        page: state.location,
        title: document.title,
      });
    });
  });

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLEID}>
      <StrictMode>
        <RecoilRoot>
          <RouterProvider router={router} />
        </RecoilRoot>
      </StrictMode>
    </GoogleOAuthProvider>
  );
};

export default App;
