import { atom } from 'recoil';
import { CreateUserDto, LoginStatusDto } from '../../apis/auth/interfaces';
import { DatabaseDto, DatasetDto } from '../../apis/datasources/interfaces';
import { ConversationDto, DialogDto } from '../../apis/conversations/interfaces';
import { MainOption } from '../../pages/main/navigation';
import { Subscription, UserMeta } from '../../apis/billing/interfaces';

export const registerAtom = atom({
  key: 'registerAtom',
  default: null as CreateUserDto,
});
export const authAtom = atom({
  key: 'authAtom',
  default: JSON.parse(localStorage.getItem('authInfo')) as LoginStatusDto,
});

export const featureAtom = atom({
  key: 'featureAtom',
  default: 'none',
});

export const curDatabaseAtom = atom({
  key: 'curDatabaseAtom',
  default: null as DatabaseDto,
});

export const allDatabasesAtom = atom({
  key: 'allDatabasesAtom',
  default: [] as DatabaseDto[],
});

export const curDatasetAtom = atom({
  key: 'curDatasetAtom',
  default: null as DatasetDto,
});

export const allDatasetsAtom = atom({
  key: 'allDatasetsAtom',
  default: [] as DatasetDto[],
});

export const curConversationAtom = atom({
  key: 'curConversationAtom',
  default: null as ConversationDto,
});

export const allConversationsAtom = atom({
  key: 'allConversationsAtom',
  default: [] as ConversationDto[],
});

export const featureExpandAtom = atom({
  key: 'featureExpandAtom',
  default: false,
});

export const historyExpandAtom = atom({
  key: 'historyExpandAtom',
  default: false,
});

export const addDatabaseModalAtom = atom({
  key: 'addDatabaseModalAtom',
  default: false,
});

export const addDatabaseRedashAtom = atom({
  key: 'addDatabaseRedashAtom',
  default: false,
});

export const addDatasetModalAtom = atom({
  key: 'addDatasetModalAtom',
  default: false,
});

export const teamAccessModalAtom = atom({
  key: 'teamAccessModalAtom',
  default: false,
});

export const historyModalAtom = atom({
  key: 'historyModalAtom',
  default: false,
});

export const mainOptionAtom = atom({
  key: 'mainOptionAtom',
  default: 'features' as MainOption,
});

export const onbroadingModalAtom = atom({
  key: 'onbroadingModalAtom',
  default: true,
});

export const colorthemeAtom = atom({
  key: 'colorthemeAtom',
  default: 'light',
});

export const userMetaAtom = atom({
  key: 'userMetaAtom',
  default: null as UserMeta,
});

export const selectedFilesAtom = atom({
  key: 'selectedFilesAtom',
  default: [] as File[],
});

export const subscriptionInfoAtom = atom({
  key: 'subscriptionInfoAtom',
  default: null as Subscription,
});

export const databasesAtom = atom({
  key: 'databasesAtom',
  default: new Map(),
});

export const datasetsAtom = atom({
  key: 'datasetsAtom',
  default: new Map(),
});

export const asyncDialogAtom = atom({
  key: 'asyncDialogAtom',
  default: null as DialogDto,
});
