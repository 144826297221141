import { useRef } from 'react';
import { useRecoilState } from 'recoil';
import { selectedFilesAtom } from '../../../../recoil/atoms/atoms';

interface FileUploaderProps {
  heightForSelected?: string;
  fileType?: string;
}

export const FileUploader: React.FC<FileUploaderProps> = ({
  heightForSelected,
  fileType,
}) => {
  const [selectedFiles, setSelectedFiles] = useRecoilState(selectedFilesAtom);
  const filesInputRef = useRef();

  const handleChange = (event) => {
    if (filesInputRef.current) {
      const addedFiles = event.target.files;
      setSelectedFiles([...selectedFiles, ...addedFiles]);
    }
  };

  const selectedFileType = () => {
    switch (fileType) {
    case 'PDF':
      return '.pdf';
    case 'EXCEL':
      return '.xls .xlsx .csv';
    default:
      return '';
    }
  };

  return (
    <>
      <div
        className="w-full flex gap-2 flex-col justify-center items-center h-12 border-solid border-outline_unselected border bg-transparent rounded my-2 text-text_selected select-none"
        onClick={() => (filesInputRef.current as HTMLInputElement).click()}
      >
        <div className="flex gap-1 items-center justify-center h-10 w-40 cursor-pointer">
          <div className="w-6 h-6 bg-blueupload" />
          <span className=" text-text_unselected text-sm text-semibold">
            Upload files
          </span>
          <input
            multiple={true}
            ref={filesInputRef}
            type="file"
            accept={selectedFileType()}
            hidden
            onChange={handleChange}
          />
        </div>
      </div>
      <div
        className={`bg-layer2 ${
          heightForSelected ?? 'h-36'
        } rounded flex flex-col w-full overflow-auto gap-y-1`}
      >
        {selectedFiles.length > 0 ? (
          <>
            {selectedFiles.map((curFile, idx) => (
              <div
                key={idx}
                className="flex p-7 justify-between items-center w-full h-12 rounded hover:bg-active text-text_unselected hover:text-text_selected bg-outline_unselected"
              >
                <div className="w-2/5 text-text_selected font-bold">
                  <div className="w-full truncate">{curFile.name}</div>
                </div>
                <p className="w-1/6">{curFile.type}</p>
                <div
                  onClick={() => {
                    const updatedFiles = selectedFiles.filter(
                      (_, index) => index !== idx
                    );
                    setSelectedFiles(updatedFiles);
                  }}
                >
                  <div className="w-6 h-6 dark:bg-close bg-lightclose cursor-pointer" />
                </div>
              </div>
            ))}
          </>
        ) : (
          <span className="text-text_unselected m-auto">No data chosen</span>
        )}
      </div>
    </>
  );
};
