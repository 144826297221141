import { useEffect, useState } from 'react';
import {
  deleteDatabase,
  upsertDatabase,
} from '../../../../apis/datasources/apis';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  addDatabaseModalAtom,
  allDatabasesAtom,
  curDatabaseAtom,
  userMetaAtom,
} from '../../../../recoil/atoms/atoms';
import {
  DatabaseDto,
  DatabaseType,
} from '../../../../apis/datasources/interfaces';
import { ModalContainer } from '../../../components/modal_container';
import { FileUploader } from './file_uploader';

export const AddDatabaseModal = () => {
  const setAddDatabaseModal = useSetRecoilState(addDatabaseModalAtom);
  const [curDatabase, setCurDatabase] = useRecoilState(curDatabaseAtom);
  const [databaseName, setDatabaseName] = useState('');
  const [connectionURL, setConnectionURL] = useState('');
  const [userMeta] = useRecoilState(userMetaAtom);
  const [databaseType, setDatabaseType] = useState('');
  const [selectDBType, setSelectDBType] = useState(false);
  const [finish, setFinish] = useState(false);
  const [deletingDatabase, setDeletingDatabase] = useState(false);
  const [allDatabases, setAllDatabases] = useRecoilState(allDatabasesAtom);

  useEffect(() => {
    if (curDatabase) {
      setDatabaseName(curDatabase.name);
      setConnectionURL(JSON.stringify(curDatabase.connJson));
      setDatabaseType(curDatabase.type);
    }
  }, []);

  const onDatabaseNameChange = (event) => {
    setDatabaseName(event.target.value);
  };

  const onURLChange = (event) => {
    setConnectionURL(event.target.value);
  };

  const handleUpsertClick = () => {
    const database: DatabaseDto = {
      name: databaseName,
      type: databaseType as DatabaseType,
      connJson: connectionURL ? JSON.parse(connectionURL) : null,
    };
    // we want to keep this field UNSET instead of null when creating a new database
    if (curDatabase) {
      database.id = curDatabase.id;
    }

    upsertDatabase(database).then((res) => {
      if (res) {
        setFinish(true);
        setAllDatabases([...allDatabases, res]);
      }
    });
  };

  const handleDeleteClick = () => {
    deleteDatabase(curDatabase.id).then(() => {
      const updatedDatabases = allDatabases.filter(
        (database) => database.id !== curDatabase.id
      );
      setAllDatabases(updatedDatabases);
      setAddDatabaseModal(false);
      setCurDatabase(null);
    });
  };

  const renderAllDatabases = () => {
    return userMeta.supportedDatabases.map((type) => {
      return (
        <div
          key={type}
          className="h-8 w-full pl-4 flex items-center hover:bg-layer1"
          onClick={() => {
            setSelectDBType(false);
            setDatabaseType(type);
          }}
        >
          <span className="text-sm">{type}</span>
        </div>
      );
    });
  };
  return (
    <>
      <ModalContainer>
        {finish ? (
          <div className="my-10 w-full flex flex-col items-center justify-center text-center">
            <div className="w-12 h-12 bg-bluecheck" />
            <span className="mt-6 text-xl font-bold">
              Database Uploaded Successful
            </span>
            <span className="mt-4 text-sm font-normal text-text_unselected">
              Check your database and use it for <br /> enhanced experiences
            </span>
            <div
              className="mt-8 w-32 h-10 bg-white rounded flex items-center justify-center cursor-pointer"
              onClick={() => setAddDatabaseModal(false)}
            >
              <span className="text-black font-semibold text-sm">Finish</span>
            </div>
          </div>
        ) : (
          <div className="h-full mx-10 my-10">
            <div className="w-full flex items-center justify-center">
              <span className="text-xl font-bold">
                {curDatabase ? 'Manage Database' : 'Add New Database'}
              </span>
            </div>
            <div className="flex flex-col mt-10 h-auto">
              <span className="font-semibold text-sm">Database Name</span>
              <input
                className="mt-2 h-10 w-full rounded border-active_hover bg-transparent border pl-4"
                placeholder="Enter Database Name"
                value={databaseName}
                onChange={onDatabaseNameChange}
              />
            </div>
            <div className="flex flex-col mt-6 h-auto">
              <span className="font-semibold text-sm">Database Type</span>
              <div
                className="mt-2 h-10 w-full rounded border-active_hover bg-transparent border pl-4 flex items-center cursor-pointer"
                onClick={() => setSelectDBType(!selectDBType)}
              >
                {databaseType ? (
                  <span>{databaseType}</span>
                ) : (
                  <span className="text-text_unselected">
                    Select Database Type
                  </span>
                )}
              </div>
            </div>
            {selectDBType && (
              <div className="mt-2 w-full h-auto max-h-36 bg-layer2 rounded">
                <div className="py-2 max-h-36 overflow-auto">
                  {renderAllDatabases()}
                </div>
              </div>
            )}

            {databaseType === DatabaseType.Excel ? (
              <FileUploader heightForSelected="h-20" fileType="EXCEL" />
            ) : (
              <div className="flex flex-col mt-6 h-auto">
                <span className="font-semibold text-sm">Connection Param</span>
                <input
                  className="mt-2 h-10 w-full rounded border-active_hover bg-transparent border pl-4"
                  placeholder="Enter URL"
                  value={connectionURL}
                  onChange={onURLChange}
                />
              </div>
            )}

            <div
              className={`mt-6 h-10 w-full flex ${
                curDatabase ? 'justify-between' : 'justify-end'
              }`}
            >
              {curDatabase && (
                <div
                  className="w-28 h-full flex items-center justify-center border border-active_hover rounded cursor-pointer"
                  onClick={() => setDeletingDatabase(true)}
                >
                  <span>Delete</span>
                </div>
              )}
              <div className="flex items-center">
                <span
                  className="text-sm text-text_unselected cursor-pointer"
                  onClick={() => setAddDatabaseModal(false)}
                >
                  Cancel
                </span>
                <div
                  className="ml-6 w-28 h-full flex items-center justify-center border border-active_hover rounded bg-white cursor-pointer"
                  onClick={handleUpsertClick}
                >
                  <span className="text-black font-semibold">
                    {curDatabase ? 'Update' : 'Add'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalContainer>

      {deletingDatabase && (
        <ModalContainer>
          <div className="my-10 w-full flex flex-col items-center justify-center text-center">
            <span className="mt-6 text-lg font-bold">
              Are you sure you want to delete this database?
            </span>
            <div className="mt-6 w-full flex justify-center items-center">
              <div
                className="w-28 h-10 border border-active_hover rounded flex items-center justify-center cursor-pointer"
                onClick={() => setDeletingDatabase(false)}
              >
                <span className="text-text_unselected font-semibold text-sm">
                  Cancel
                </span>
              </div>
              <div
                className="ml-2 w-28 h-10 bg-white rounded flex items-center justify-center cursor-pointer"
                onClick={handleDeleteClick}
              >
                <span className="text-black font-semibold text-sm">Delete</span>
              </div>
            </div>
          </div>
        </ModalContainer>
      )}
    </>
  );
};
