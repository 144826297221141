export const Step3 = () => {
  return (
    <div className="flex flex-col gap-5">
      <div className="h-[230px] bg-step3" />

      <div className="flex flex-col gap-1">
        <span className="text-blue text-center">General Q&A: </span>
        <span className="text-text_selected font-semibold text-xl text-center">
          Effortless Conversation Creation
        </span>
      </div>

      <div className="text-text_unselected font-semibold text-sm w-[490px] m-auto">
        Crafting an intuitive and efficient chatbot assistant by seamlessly
        syncing information from our document set.
      </div>
    </div>
  );
};
