/* https://stackoverflow.com/questions/67605723/triple-dot-css-animation-on-a-loading-screen */

.loading {
  font-weight: bold;
  display: inline-block;
  font-family: monospace;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(10) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}
