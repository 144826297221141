import { instance } from '../base/instance';
import { DatasetDto } from '../datasources/interfaces';

export const uploadFiles = async (formData: FormData): Promise<Array<DatasetDto>> => {
  const res = await instance.post('/files/uploads', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};
