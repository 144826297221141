import { useEffect, useRef, useState } from 'react';
import { FollowUp } from '../../../../apis/conversations/interfaces';

interface DialogInputProps {
  initInput: string | undefined;
  placeholder: string | undefined;
  handleSubmitDialog: (string) => void;
  followUps?: FollowUp[];
}

export default function DialogInput({ initInput, placeholder, handleSubmitDialog, followUps }: DialogInputProps) {
  const [userInput, setUserInput] = useState<string>(initInput ?? '');
  const [onFocus, setOnFocus] = useState<boolean>(false);
  const followUpsRef = useRef(null);

  // set onFucus back to false when click away
  const handleClickOutside = (event) => {
    // Check if the click was outside the dropdown
    if (followUpsRef.current && !followUpsRef.current.contains(event.target)) {
      setOnFocus(false);
    }
  };

  // Set up the event listener on component mount and clean up on unmount
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      handleSubmitDialog(userInput);
    }
  };

  const handleFollowUpClick = (followUp: string) => {
    setUserInput(followUp);
    handleSubmitDialog(followUp);
  };

  return (
    <div className="self-end flex flex-col justify-center gap-4 items-center w-full px-16 pt-4" ref={followUpsRef}>
      {
        onFocus &&
        <div className="flex flex-row gap-3 flex-wrap text-text_unselected w-full overflow-x-auto self-start cursor-pointer">
          {followUps?.map((step, index) => {
            return (
              <div
                className="px-4 py-2 rounded-lg border border-outline_unselected text-sm "
                key={index}
                onClick={() => handleFollowUpClick(step.text)}
              >
                {step.text}
              </div>
            );
          })}
        </div>
      }
      <div className="w-full h-14 border rounded-lg border-active_hover flex justify-between items-center px-6 mb-8">

        <input
          className="text-lg w-11/12 font-normal bg-transparent outline-none text-text_selected placeholder:text-text_unselected"
          placeholder={placeholder}
          value={userInput}
          onChange={(event) => setUserInput(event.target.value)}
          onKeyDown={handleEnter}
          onFocus={() => setOnFocus(true)}
        />
        <div
          className="w-6 h-6 bg-paperplane dark:bg-lightpaperplane bg-cover cursor-pointer"
          onClick={() => handleSubmitDialog(userInput)}
        />
      </div>
    </div>
  );
}
