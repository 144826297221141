import { Avatar as AvatarOriginal } from 'antd';

interface ProfileButtonAvatarProps {
    profileLink: string;
    username: string;
}

export default function ProfileButtonAvatar({ profileLink, username }: ProfileButtonAvatarProps) {
  return (
    <div className="flex items-center justify-center w-fit m-2 rounded">
      {profileLink ? (
        <AvatarOriginal src={<img src={profileLink} alt={username?.at(0).toUpperCase()} />} shape='square' />
      ) : (
        <div className='w-8 h-8 rounded-md flex justify-center items-center select-none text-layer1 bg-green'>
          <span>{username?.at(0).toUpperCase()}</span>
        </div>
      )
      }
    </div>
  );
}
