import { useEffect, useState } from 'react';
import {
  deleteConversation,
  getAllConversations,
  getConversation,
} from '../../../apis/conversations/apis';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  allConversationsAtom,
  curConversationAtom,
  historyModalAtom,
  mainOptionAtom,
} from '../../../recoil/atoms/atoms';
import { MainOption } from '../navigation';

export const HistoryModal = () => {
  const [allConversations, setAllConversations] =
    useRecoilState(allConversationsAtom);
  const [curConversationId, setCurConversationId] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const setCurConversation = useSetRecoilState(curConversationAtom);
  const setMainOption = useSetRecoilState(mainOptionAtom);
  const setHistoryModal = useSetRecoilState(historyModalAtom);

  useEffect(() => {
    getAllConversations().then(setAllConversations);
  }, [confirmDelete]);

  const handleDeleteClick = (id: string) => {
    setCurConversationId(id);
    setConfirmDelete(true);
  };

  const handleConfirmClick = () => {
    deleteConversation(curConversationId).then(() => setConfirmDelete(false));
  };

  const renderAllConversations = () => {
    return allConversations.map((conversation, i) => {
      return (
        <div
          key={conversation.conversationId}
          className={`flex w-full justify-between items-center h-14 text-text_unselected font-bold ${
            i % 2 && 'bg-layer2'
          }`}
        >
          <div className="w-10" />
          <span className="w-28">
            {new Date(conversation.createdTimestamp).toLocaleDateString()}
          </span>
          <span className="w-1/6 text-text_selected">
            {conversation.conversationName}
          </span>
          <span className="w-1/4 truncate">
            {conversation.conversationDescription || 'null'}
          </span>
          <div className="w-1/12" />
          <div className="flex justify-between w-20">
            <div
              className="w-8 h-8 flex justify-center items-center border rounded cursor-pointer"
              onClick={() => handleDeleteClick(conversation.conversationId)}
            >
              <div className="w-4 h-4 bg-trash" />
            </div>
            <div
              className="w-8 h-8 flex justify-center items-center border rounded bg-white cursor-pointer"
              onClick={() => {
                getConversation(conversation.conversationId).then(
                  setCurConversation
                );
                setMainOption(MainOption.Conversation);
                setHistoryModal(false);
              }}
            >
              <div className="w-4 h-4 bg-paperplane" />
            </div>
          </div>
          <div className="w-10" />
        </div>
      );
    });
  };
  return (
    <div className="absolute w-screen h-screen bg-black bg-opacity-70 flex items-center justify-center">
      <div className="relative h-[500px] bg-layer1 rounded p-9">
        <div
          className="absolute top-5 right-5 w-6 h-6 dark:bg-close bg-lightclose cursor-pointer scale-150"
          onClick={() => setHistoryModal(false)}
        />
        <div className="flex flex-col h-full w-full">
          <div className="flex flex-col self-center justify-center items-center mt-10">
            {confirmDelete && (
              <div className="flex justify-center items-center absolute w-[80vh] h-screen">
                <div className="w-96 h-48 bg-layer2 flex flex-col justify-center items-center ">
                  <span className="text-lg font-bold">
                    Are you sure you want to delete this chat?
                  </span>
                  <div className="flex mt-6 h-10">
                    <div
                      className="w-32 h-full border rounded flex justify-center items-center cursor-pointer"
                      onClick={() => {
                        setConfirmDelete(false);
                      }}
                    >
                      <span className="text-text_unselected font-bold">
                        Cancel
                      </span>
                    </div>
                    <div className="w-10" />
                    <div
                      className="w-32 h-full bg-white rounded flex justify-center items-center cursor-pointer"
                      onClick={handleConfirmClick}
                    >
                      <span className="text-black font-bold">Delete</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {allConversations.length > 0 ? (
              <>
                <span className="text-xl font-bold">Chat History</span>
                <div className="flex w-full justify-between mt-10 text-text_unselected font-bold">
                  <div className="w-10" />
                  <span className="w-28">Date</span>
                  <span className="w-1/6">Database</span>
                  <span className="w-1/4">About</span>
                  <div className="w-1/12" />
                  <div className="w-20" />
                  <div className="w-10" />
                </div>
                <div className="w-full mt-6 border-t border-active_hover" />
                <div className="w-full h-72 overflow-y-auto">
                  {renderAllConversations()}
                </div>
              </>
            ) : (
              <span className="text-text_unselected">
                There are currently no conversations found for your account.
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
