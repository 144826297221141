import { getAsyncDialog } from '../apis/conversations/apis';
import { DialogStatusEnum } from '../apis/conversations/interfaces';

let isRunning = false;

export const endAsyncDialog = () => {
  isRunning = false;
};

/**
 * This function will keep requesting from webserver until the response's status is FINISHED or ERROR or it reaches the maximum timeout.
 * @param convId Conversation ID that Dialog belongs to
 * @param dialogId Dialog ID
 * @param onFetchError Function to call when response's status is ERROR or failed
 * @param onFetchSuccess Function to call when response is successful
 * @param onFetchFinish Function to call when the response's status is FINISHED
 * @param initIntervalInSecond Interval of requests
 * @param maxTimeOutInSecond Maximum time for the task to process
 */
export const startAsyncDialog = async (
  convId: string,
  dialogId: string,
  onFetchError,
  onFetchSuccess,
  onFetchFinish,
  initIntervalInSecond = 1,
  maxTimeOutInSecond = 180,
) => {

  const millisecond = 1000;
  const intervalInSecond = initIntervalInSecond;
  const requestTime = new Date();

  isRunning = true;

  while (isRunning) {
    const interval = intervalInSecond * millisecond;
    await new Promise(resolve => setTimeout(resolve, interval));

    try {
      const res = await getAsyncDialog(convId, dialogId);
      onFetchSuccess(res);
      if (res.dialogStatus !== DialogStatusEnum.IN_PROGRESS) {
        onFetchFinish(res);
        break;
      }
    } catch (err) {
      onFetchError(err);
      break;
    }

    const now = new Date();
    const processingTime = (now.getTime() - requestTime.getTime()) / millisecond;
    if (processingTime >= maxTimeOutInSecond) {
      const err = 'Something went wrong';
      // TODO: send request to stop generating responses
      onFetchError(err);
      break;
    }
  }

  isRunning = false;
};
