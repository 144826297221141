import { Intro } from './components/intro';
import { useRecoilValue } from 'recoil';
import { featureAtom } from '../../../recoil/atoms/atoms';
import { FeatureOption } from '../navigation';
import { GeneralChat } from './components/general_chat';
import { ConversationalBI } from './components/conversational_bi';
import { DataEnty } from './components/data_entry';
import { WebScraper } from './components/web_scraper';

export const Features = () => {
  const option = useRecoilValue(featureAtom);
  const renderBody = () => {
    switch (option) {
    case FeatureOption.NONE:
      return <Intro />;
    case FeatureOption.ConversationalBI:
      return <ConversationalBI />;
    case FeatureOption.DATAENTRY:
      return <DataEnty />;
    case FeatureOption.WEBSCRAPER:
      return <WebScraper />;
    default:
      return <GeneralChat />;
    }
  };
  return (
    <div className="flex flex-col px-6 w-full h-full overflow-auto">
      {renderBody()}
    </div>
  );
};
