export enum DatabaseType {
  MySQL = 'mysql',
  PostgreSQL = 'pg',
  SQLite = 'sqlite',
  Oracle = 'orcale',
  MsSQL = 'mssql',
  Excel = 'excel',
}

export interface DatabaseDto {
  id?: string;
  connVia?: string;
  name: string;
  type: DatabaseType;
  connJson: Record<string, any>;
  createdOn?: string;
  file?: File;
}

export enum DatasetType {
  S3File = 's3file',
  Confluence = 'confluence',
  Github = 'github',
  PDF = 'pdf',
}

export class DatasetDto {
  id?: string;
  name: string;
  type: DatasetType;
  connJson: Record<string, any>;
  createdOn?: string;
}

export class UploadDataset {
  files: File[];
  type: DatasetType;
}
