import Button from 'antd/lib/button';
import Upload from 'antd/lib/upload';
import UploadOutlinedIcon from '@ant-design/icons/UploadOutlined';

export default function FileField({ form, field, ...otherProps }) {
  const { name } = field;
  const { getFieldValue } = form;
  const { maxCount, typeCheck } = otherProps;
  const disabled = getFieldValue(name) !== undefined && getFieldValue(name).length >= maxCount;

  const beforeUpload = (file: File) => {
    const typeAllowed = typeCheck.includes(file.type);
    if (!typeAllowed) {
      alert('File type is not accepted');
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  return (
    <Upload {...otherProps} beforeUpload={beforeUpload}>
      <Button disabled={disabled}>
        <UploadOutlinedIcon /> Click to upload
      </Button>
    </Upload>
  );
}
