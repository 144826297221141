import { useState, useEffect } from 'react';
import { CompanyButton } from './company_button';
import { Dropdown } from './dropdown';
import { getUserMeta } from '../../../apis/datasources/apis';
import { userMetaAtom } from '../../../recoil/atoms/atoms';
import { useRecoilState } from 'recoil';

interface ProfileDropdownProps {
  sm?: boolean;
}

export const ProfileDropdown = ({ sm }: ProfileDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [, setUserMeta] = useRecoilState(userMetaAtom);

  useEffect(() => {
    const handleClick = () => setIsOpen(false);
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const toggleDropdown = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  useEffect(() => {
    getUserMeta().then(setUserMeta);
  }, []);

  return (
    <div className="flex flex-col gap-2 h-auto select-none z-50 my-2 justify-center">
      <Dropdown isOpen={isOpen} side={sm} />
      <CompanyButton
        onClick={(e) => {
          e.stopPropagation();
          toggleDropdown();
        }}
        sm={sm ? 'true' : 'false'}
      />
    </div>
  );
};
