import { useCallback, useMemo, useRef } from 'react';
// import { download } from '../../../../apis/export/apis';

import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid
import { ColDef } from 'ag-grid-community';

import { DownloadSvg } from '../../../../../../components/svg/download';
import { DarkDownloadSvg } from '../../../../../../components/svg/darkdownload';
import { SqlResult } from '../../../../../../apis/conversations/interfaces';


interface TableResultProps {
  sqlResult: SqlResult;
}

export const TableResult = ({ sqlResult }: TableResultProps) => {

  const theme = document.querySelector('html').getAttribute('data-theme');
  const gridRef = useRef<AgGridReact>(null);

  // const handleDownloadDialog = async (convId, dialogId) => {
  //   const fileType = 'csv';

  //   await download(convId, dialogId, fileType);
  // };

  // convert tableKeys and tablevals to ag-grid forma
  const tableKeys = useMemo(() => (sqlResult?.columns?.map((column) => {
    const parsedColumn: ColDef = {
      field: column.name,
      type: column.type,
    };
    return parsedColumn;
  })), [sqlResult]);

  const tableVals = useMemo(() => (sqlResult?.rows as JSON[] ?? []), [sqlResult]);

  const onBtnExport = useCallback(() => {
    gridRef.current!.api.exportDataAsCsv();
  }, []);


  return (
    <div className="w-full overflow-y-hidden">
      {tableKeys && tableKeys.length > 0 && tableVals && tableVals.length > 0 ? (
        <div className="overflow-hidden w-full">
          <div className="flex justify-between items-center">
            <span>Query Result:</span>
            <button
              onClick={onBtnExport}
              className="ml-auto mr-4 border-gray-400 inline-flex items-center"
            >
              Download
              {theme === 'dark' ? (
                <DownloadSvg className="ml-2" />
              ) : (
                <DarkDownloadSvg className="ml-2" />
              )}
            </button>
          </div>
          <div
            className={`${theme === 'dark' ? 'ag-theme-quartz-dark' : 'ag-theme-quartz'} mt-2 max-w-full`} // applying the Data Grid theme
            style={{ height: Math.min((tableVals.length + 1), 8) * 60 }} // the Data Grid will fill the size of the parent container
          >
            <AgGridReact
              rowData={tableVals}
              columnDefs={tableKeys}
              ref={gridRef}
              suppressExcelExport={true}
            />
          </div>
        </div >) : <div>Unable to display malformed data...</div>
      }
    </div >
  );
};
