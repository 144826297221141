import { DialogResponse, SqlResult } from '../../../../../../apis/conversations/interfaces';
import { Ai_Chart, Analytic_Chart } from './ai_chart';
import { Ai_Chart_V1 } from './ai_chart_v1';
import { TableResult } from './table_result';
import { Query } from './query';
import { useCallback } from 'react';
import Avatar from './avatar';
import { Loading } from '../../loading';
import Markdown from 'react-markdown';


enum ResponseTypeEnum {
  result = 'Result',
  sql_query = 'Query',
  sql_result = 'Query Result',
  visualization = 'Visualization',
  analytic_charts = 'Data Analytic Charts'
  // formatted_ai_response = 'Insight',
}

interface ResponseProps {
  responses: DialogResponse[];
  isAsyncDialog?: boolean;
}

export const Response = ({ responses, isAsyncDialog = false }: ResponseProps) => {

  const result = [] as string[];
  const sql_query = [] as string[];
  const sql_result = [] as SqlResult[];
  const visualization = [] as string[];
  const analytic_charts = [] as string[];
  // const formatted_ai_response = [] as string[][];

  responses?.map((response: DialogResponse) => {
    if (response?.text) {
      result.push(response.text);
    }
    if (response?.sql_query) {
      sql_query.push(response.sql_query);
    }
    if (response?.sql_result) {
      sql_result.push(response.sql_result);
    }
    if (response?.ai_chart) {
      visualization.push(response.ai_chart);
    }
    if (response?.analytic_charts) {
      response.analytic_charts.forEach(chart => {
        analytic_charts.push(chart);
      });
    }
    if (response?.formatted_ai_response) {
      result.push(...response.formatted_ai_response);
    }
  });

  const renderByType = useCallback((responseType: ResponseTypeEnum, input: string[] | SqlResult[]) => {
    try {
      switch (responseType) {
      case ResponseTypeEnum.result:
        return (input.map((res, i) => (<div key={i} style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
          <Markdown>{res}</Markdown>
        </div>)));
      case ResponseTypeEnum.sql_query:
        return (input.map((res, i) => (<Query query={res} key={i} />)));
      case ResponseTypeEnum.sql_result:
        return (input.map((res, i) => (<TableResult sqlResult={res} key={i} />)));
      case ResponseTypeEnum.visualization:
        return input.map((res, i) => {
          if (typeof res === 'object') {
            // V1
            return <Ai_Chart_V1 ai_chart={JSON.stringify(res)} key={i} />;
          } else {
            // V0
            return <Ai_Chart ai_chart={res.toString()} key={i} />;
          }
        });
      case ResponseTypeEnum.analytic_charts:
        return (input.map((res, i) => (<Analytic_Chart analytic_chart={res} key={i} />)));
        // case ResponseTypeEnum.formatted_ai_response:
        //   return (input.map((res, i) => (<div key={i} style={{ whiteSpace: 'pre-wrap' }}>{res}</div>)));
      default:
        return null;
      }
    } catch (err) {
      return <div>Malformed Data</div>;
    }
  }, []);

  return (
    <div className='flex flex-col space-y-5 w-full min-h-20 h-auto my-5 text-base'>
      {result?.length !== 0 &&
        <div className='flex flex-col space-y-5'>
          <div className='flex flex-row justify-start w-full'>
            <Avatar isLegion={true} isLoading={isAsyncDialog} />
            <span className='text-xl font-bold font-roboto mt-6'>Result</span>
          </div>
          <div className='ml-6 flex flex-col space-y-4'>
            {renderByType(ResponseTypeEnum.result, result)}
            {isAsyncDialog &&
              <div className="h-auto ml-2 pb-5">
                <Loading />
              </div>
            }
          </div>
        </div>
      }
      {/* Query */}
      {sql_query?.length !== 0 &&
        <div className='flex flex-col space-y-5'>
          <div className='flex flex-row justify-start w-full'>
            <Avatar isLegion={true} isLoading={isAsyncDialog} />
            <span className='text-xl font-bold font-roboto mt-6'>Query</span>
          </div>
          <div className='ml-6 flex flex-col space-y-4'>
            {renderByType(ResponseTypeEnum.sql_query, sql_query)}
            {isAsyncDialog &&
              <div className="h-auto ml-2 pb-5">
                <Loading />
              </div>
            }
          </div>
        </div>
      }
      {/* Query-Result */}
      {sql_result?.length !== 0 &&
        <div className='flex flex-col space-y-5'>
          <div className='flex flex-row justify-start w-full'>
            <Avatar isLegion={true} isLoading={isAsyncDialog} />
            <span className='text-xl font-bold font-roboto mt-6'>Query Result</span>
          </div>
          <div className='ml-6 flex flex-col space-y-4'>
            {renderByType(ResponseTypeEnum.sql_result, sql_result)}
            {isAsyncDialog &&
              <div className="h-auto ml-2 pb-5">
                <Loading />
              </div>
            }
          </div>
        </div>
      }
      {/* Visualization */}
      {visualization?.length !== 0 &&
        <div className='flex flex-col space-y-5'>
          <div className='flex flex-row justify-start w-full'>
            <Avatar isLegion={true} isLoading={isAsyncDialog} />
            <span className='text-xl font-bold font-roboto mt-6'>Visualization</span>
          </div>
          <div className='ml-6 flex flex-col space-y-4'>
            {renderByType(ResponseTypeEnum.visualization, visualization)}
            {isAsyncDialog &&
              <div className="h-auto ml-2 pb-5">
                <Loading />
              </div>
            }
          </div>
        </div>
      }
      {/* Data Analytic Chart */}
      {analytic_charts?.length !== 0 &&
        <div className='flex flex-col space-y-5'>
          <div className='flex flex-row justify-start w-full'>
            <Avatar isLegion={true} isLoading={isAsyncDialog} />
            <span className='text-xl font-bold font-roboto mt-6'>Visualization</span>
          </div>
          <div className='ml-6 flex flex-col space-y-4'>
            {renderByType(ResponseTypeEnum.analytic_charts, analytic_charts)}
            {isAsyncDialog &&
              <div className="h-auto ml-2 pb-5">
                <Loading />
              </div>
            }
          </div>
        </div>
      }
      {isAsyncDialog && (!responses || responses.length === 0) &&
        <div className='flex flex-col space-y-5'>
          <div className='flex flex-row justify-start w-full'>
            <Avatar isLegion={true} isLoading={isAsyncDialog} />
            {isAsyncDialog &&
              <div className="h-auto ml-2 pt-8 pb-5">
                <Loading />
              </div>
            }
          </div>
        </div>
      }
    </div>
  );
};
