import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { authAtom } from '../../../recoil/atoms/atoms';
import { LoginDto } from '../../../apis/auth/interfaces';
import { loginWithCreds } from '../../../apis/auth/apis';
import Swal from 'sweetalert2';

export const SignIn = () => {
  const navigate = useNavigate();
  const setAuthInfo = useSetRecoilState(authAtom);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const onUsernameInput = (event) => {
    setUsername(event.target.value);
  };

  const onPasswordInput = (event) => {
    setPassword(event.target.value);
  };

  const onEyeClick = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const onSignInClick = () => {
    if (!username) {
      Swal.fire('Error', "username can't be null", 'error');
      return;
    }

    if (!password) {
      Swal.fire('Error', "Password can't be null", 'error');
      return;
    }

    const loginInfo: LoginDto = { password: password };
    if (username.includes('@')) {
      loginInfo.email = username;
    } else {
      loginInfo.username = username;
    }

    loginWithCreds(loginInfo).then((authInfo) => {
      localStorage.setItem('authInfo', JSON.stringify(
        {...authInfo,
          authInfoTime: new Date().getTime().toString()}
      ));
      setAuthInfo(authInfo);
      navigate('/');
    }).catch((err) => {
      if (err.status && err.status === 401) {
        Swal.fire('Error', err.data, 'error');
      } else {
        Swal.fire('Error', 'Please try again', 'error');
      }
    });
  };

  const onSignUpClick = () => {
    navigate('/sign-up');
  };

  return (
    <div className="grid justify-items-center justify-self-center self-center w-1/3 h-auto bg-layer2 max-w-md">
      <div className="text-xl font-bold text-text_selected w-10/12 my-10 text-center flex flex-col items-center">
        <span>Sign In</span>
        <input
          className="mt-8 w-full h-12 rounded border-solid border-active_hover border bg-transparent pl-6 text-sm font-normal outline-none"
          placeholder="Username/Email"
          type="text"
          value={username}
          onChange={onUsernameInput}
        />
        <div className="flex justify-between items-center mt-2 w-full h-12 rounded border-solid border-active_hover border bg-transparent">
          <div className="grow h-full pl-6 pr-6">
            <input
              className="h-full w-full text-sm font-normal bg-transparent outline-none"
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={onPasswordInput}
            />
          </div>
          {showPassword ? (
            <div className="mr-6 w-6 h-6 bg-eye" onClick={onEyeClick} />
          ) : (
            <div className="mr-6 w-6 h-6 bg-eyeslash" onClick={onEyeClick} />
          )}
        </div>
        <div
          className="mt-6 w-full h-12 bg-active_hover grid items-center cursor-pointer rounded"
          onClick={onSignInClick}
        >
          <span className="text-sm text-text_selected font-semibold">Sign In</span>
        </div>
        <div className="mt-2 text-text_unselected text-sm font-normal">
          <span>
            Don&apos;t have an account?&nbsp;
            <span
              className="underline underline-offset-auto font-mono cursor-pointer"
              onClick={onSignUpClick}
            >
              Sign Up
            </span>
          </span>
          <br />
          <span
            className="underline underline-offset-auto font-mono cursor-pointer"
            onClick={() => navigate('/forget-password')}
          >
            Forget password?
          </span>
        </div>
      </div>
    </div>
  );
};
